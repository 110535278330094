import React from "react";
import {
  Select,
  Checkbox,
  Row,
  Col,
  Button,
  Space,
  DatePicker,
  Dropdown,
  Modal,
  Image,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import config from "../config";
import RuleTriggerStore from "../store/RuleTriggerStore";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import VehicleStore from "../store/VehicleStore";
import { MapContainer, Marker, Popup, Tooltip } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import dayjs from "dayjs";
import L from "leaflet";

import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
const { RangePicker } = DatePicker;
const dotIcon = L.divIcon({
  className: "dot-icon",
  iconSize: [8, 8],
  html: ' <div style="width: 20px; height: 20px; background-color: red; border-radius: 50%;"></div>',
});
const { Option } = Select;
class RuleTriggerList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.vehicleStore = new VehicleStore();
    this.ruleTriggerStore = new RuleTriggerStore();

    this.state = {
      loading: false,
      pageSize: 15,
      offset: 0,
      pageNo: 1,
      limit: 15,
      showMap: false,
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      modalInfoObj: {},
      modalVisible: false,
      showMonthPopup: false,
      Notcompany: false,
      Notasset: false,
      selectAllVehicles: false,
      selectedVehicles: [],
      ruleSelectorId: "",
      vehicleObj: false,
      selectedColumns: [
        {
          // title: "Vehicle No",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          // fixed: 'left',
          width: "15%",
          key: "vehicleNo",
        },
        {
          // title: "  Date/Time",
          title: context.translate("_FLEET_REPORTS_EVENTREPORT_DATETIME_"),
          dataIndex: "time",
          // fixed: 'left',
          width: "15%",
          key: "time",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
        },
        {
          // title: "  Address",
          title: context.translate("_FLEET_REPORTS_EVENTREPOR_ADDRESS_"),
          dataIndex: "Address",
          width: "15%",
          key: "Address",
        },
        {
          // title: "Speed",
          title: context.translate("_FLEET_REPORTS_EVENTREPOR_SPEED_"),
          dataIndex: "Speed",
          //fixed: 'left',
          width: "15%",
          key: "Current Speed",
        },
        {
          // title: "Odometer",
          title: context.translate("_FLEET_REPORTS_EVENTREPOR_ODOMETER_"),
          dataIndex: "Distance",
          //fixed: 'left',
          width: "15%",
          key: "Distance",
        },

        {
          // title: " Ignition Status ",
          title: context.translate("_FLEET_DASHBORD_SETTING_INGNITION_"),
          dataIndex: "Ignition",
          width: "15%",
          key: "Ignition Status",
        },
        {
          // title: " Rule Triggered  ",
          title: context.translate(
            "_FLEET_REPORTS_RULETRIGGERREPORT_RULETRIGGER_"
          ),
          dataIndex: "ruleselector",
          //fixed: 'left',
          width: "15%",
          key: "ruleselector",
        },

        {
          // title: "",
          title: context.translate("_FLEET_DASHBORD_SETTING_TEMPERATURE_"),
          dataIndex: "Event",
          //fixed: 'left',
          width: "15%",
          key: "Event",
        },
        {
          // title: " View On Map   ",
          title: context.translate("_FLEET_SERVICE_POILIST_VIEWMAP_"),
          dataIndex: "View Map   ",
          // fixed: 'right',
          width: "15%",
          key: "View Map   ",
          render: (value, obj) => {
            return (
              <>
                {/* <Image
                src={require("../assets/images/mapIcon/gps_icon.png")}
                width={25}
                height={25}
                onClick={async() => {
               await this.setState({ vehicleObj: {...obj,icon:"map-car-icon-green.png"},heading:this.context.translate("_FLEET_RULETRIGGER_REPORT_") });
                await this.setState({loadData:1})
              }}
              /> */}
                <Image
                  src={require("../assets/images/mapIcon/gps_icon.png")}
                  width={25}
                  height={25}
                  onClick={async () => {
                    this.setState({ showMap: true, modalInfoObj: obj });
                    await this.setState({ loadData: 1 });
                  }}
                />
              </>
            );
          },
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            ruleSelectorId: this.state.ruleSelectorId,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.ruleTriggerStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.ruleTriggerStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.ruleTriggerStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.ruleTriggerStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    await this.ruleTriggerStore.getRuleSelectorNames();
    // await this.ruleTriggerStore.loadData({ offset: 0, limit: 50 });

    this.setState({ pageSize: 15 });

    await this.setState({ column: this.state.selectedColumns });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.showMap) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({
      selectAllVehicles: false,
    });

    await this.handleAllSelectVehicles();
    await this.setState({ companyId: comp?.value });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    // await this.handleSearch();
    await this.setState({ loadData: 1 });
    // console.log("data", data);
  }

  async handleSearch() {
    this.setState({ Notasset: false });
    this.setState({ loading: true });
    await this.ruleTriggerStore.loadData({
      offset: (this.state.pageNo - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      pageNo: this.state.pageNo,
    });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }
  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  vehicleSelect = async (val) => {
    console.log(val);
    await this.setState({ selectedVehicles: val });
  };

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };
  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }
  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false, pageSize: 15 });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.ruleTriggerStore.loadData({
          offset: (this.state.pageNo - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,
          ruleSelectorId: this.state.ruleSelectorId,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          pageNo: this.state.pageNo,
        });
        await this.setState({ loadData: 1 });
      }
    }
  };

  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss,";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };
  render() {
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const assetInputStyle = this.state.Notasset
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed">
            <Col span={12}>
              {/* <div className="heading">Rule Trigger</div> */}
              <div className="heading">
                {this.context.translate("_RULE_TRIGGER_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.ruleTriggerStore?.ruleTriggerStore?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={22}>
              <Space>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={this.handleChange}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
                {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles: [],
                        selectAllVehicles: false,
                      })
                    }
                  />
                )}
                <Select
                  mode="multiple"
                  // onSearch={this.handleSearch}
                  onChange={(value) => {
                    console.log(value);
                    this.setState({ ruleSelectorId: value });
                  }}
                  maxTagCount="responsive"
                  fieldNames={{ label: "name", value: "ruleSelectorId" }}
                  // placeholder="Rule Selector"
                  placeholder={this.context.translate("_RULE_SELECTOR_")}
                  optionFilterProp="children"
                  options={this.ruleTriggerStore?.ruleList}
                  style={{ width: 160 }}
                />

                <Checkbox
                  checked={this.state.selectAllVehicles}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllVehicles: e.target.checked,
                    });
                    console.log(e.target.checked);
                    await this.handleAllSelectVehicles();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                <Select
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }

                    if (val?.length > 1) {
                      console.log(val?.length > 1);
                      this.setState({ showAlertPopup: true });
                    }
                  }}
                  style={{ width: 180 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  mode="multiple"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 380 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    this.showData();
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={2}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          {/* <ListTable */}
          <ListTable
            currentPage={this.state.pageNo}
            total={this.ruleTriggerStore?.total}
            pageSize={this.state.pageSize}
            columns={this.state.column}
            dataSource={this.ruleTriggerStore?.ruleTriggerStore}
            loading={this.ruleTriggerStore?.loading}
            onChange={this.handleTableChange}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   total:this.ruleTriggerStore.total || 0

            // }}

            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />
        </div>

        <Modal
          open={this.state.modalVisible}
          width={300}
          // title="Select Columns"
          title={this.context.translate("_SELECT_COLUMNS_")}
          onCancel={() => this.setState({ modalVisible: false })}
          onOk={() => {
            this.setState({ modalVisible: false });
          }}
          footer={null}
        >
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            {console.log(this.newColumns, "Df")}
            {this.newColumns.map((column, v) => {
              return (
                <Row key={v}>
                  <Col>
                    <Checkbox
                      onChange={() => this.handleCheckboxChange(column)}
                      checked={this.state.selectedColumns.some(
                        (c) => c.key === column.key
                      )}
                    >
                      {column.title}
                    </Checkbox>
                  </Col>
                </Row>
              );
            })}
          </div>
        </Modal>

        {/* {this.state.vehicleObj && (
          <MapForVehiclePosition
            vehicleObj={this.state.vehicleObj}
            heading={this.state.heading}
            closeMapModal={(resp) => {
              this.setState({ vehicleObj: resp });
            }}
          />
          
        )} */}
        {this.state.showMap && (
          <Modal
            className="multiTracking-popup"
            title={
              <div>
                {this.context.translate("_FLEET_EVENT_REPORT_")}
                <CloseOutlined
                  onClick={() => this.setState({ showMap: false })}
                />
              </div>
            }
            centered
            maskClosable={false}
            bodyStyle={{
              height: "60vh",
              //width: "900px",
            }} // Adjust the dimensions as needed
            width={1000}
            open={this.state.showMap}
            onCancel={() => this.setState({ closeModal: true })}
            closable={false}
            footer={null}
          >
            <div
              dir={this.context.locale.language === "arabic" ? "ltr" : "ltr"}
            >
              <MapContainer
                ref={this.mapRef}
                zoom={30}
                center={[
                  this.state.modalInfoObj?.latitude,
                  this.state.modalInfoObj?.longitude,
                ]}
                style={{
                  position: "relative",
                  height: "60vh",

                  // borderRadius: "0px 0px 5px 5px",
                }}
                bounds={
                  this.state.modalInfoObj
                    ? [
                        [
                          this.state.modalInfoObj.latitude,
                          this.state.modalInfoObj.longitude,
                        ],
                        [
                          this.state.modalInfoObj.latitude,
                          this.state.modalInfoObj.longitude,
                        ],
                      ]
                    : []
                }
              >
                <ReactLeafletGoogleLayer />
                {/* <AdditionalContentInMap This={this} size="small" /> */}
                <Marker
                  icon={L.icon({
                    iconUrl: require("../assets/images/map-car-icon-green.png"),
                    iconSize: [25, 40], // size of the icon
                  })}
                  position={[
                    this.state.modalInfoObj?.latitude,
                    this.state.modalInfoObj?.longitude,
                  ]}
                  eventHandlers={{
                    mouseover: (event) => event.target.openPopup(),
                    mouseout: (event) => event.target.closePopup(),
                  }}
                >
                  <Tooltip permanent={true} direction="top">
                    <div
                      style={{
                        backgroundColor: "#00ff00",
                        border: "solid 1px #000",
                        textAlign: "center",
                        padding: 0,
                      }}
                    >
                      <b>{this.state.modalInfoObj?.vehicleNo}</b>
                      <br />
                      {/* <b>Speed : {this.state.modalInfoObj?.speed}Kmh</b> */}
                    </div>
                  </Tooltip>
                  <Popup direction="top">
                    <b>
                      Rule Triggered : {this.state.modalInfoObj?.ruleselector}
                    </b>
                  </Popup>
                </Marker>
              </MapContainer>
            </div>
          </Modal>
        )}
        <Modal
          width={350}
          className="alert-model"
          title={
            <div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              {/* Alert */}
              {this.context.translate("_FLEET_ALERT_")}
            </div>
          }
          open={this.state.showMonthPopup}
          onOk={() => this.setState({ showMonthPopup: false })}
          onCancel={() => this.setState({ showMonthPopup: false })}
          footer={false}
        >
          {/* For Reports prior to 6 months please contact administrator */}
          {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
        </Modal>
      </>
    );
  }
}
RuleTriggerList.contextType = StoreContext;
export default observer(withRouter(RuleTriggerList));
