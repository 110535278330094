import React, { useState } from "react";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Cookies from "js-cookie";
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table,
  Button,
  Typography,
  Space,
  Row,
  Col,
  Checkbox,
  Dropdown,
  DatePicker,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import withRouter from "../withRouter";
import config from "../config";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";

import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ExportOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import MachineryReportStore from "../store/MachineryReportStore";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import VehicleStore from "../store/VehicleStore";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
const { RangePicker } = DatePicker;
const { Option } = Select;
dayjs.extend(duration);

class MachineryReportList extends React.Component {
  constructor(props, context) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.machineryReportStore = new MachineryReportStore();
    this.vehicleStore = new VehicleStore();

    this.state = {
      loading: false,
      pageSize: 50,
      pageNo: 1,
      offset: 0,
      limit: 50,
      loadData: 0,
      selectedVehicles: [],
      selectAllVehicles: false,
      companyId: "",
      modalVisible: false,
      filter: "",
      column: "",
      showEmail: false,
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      Notcompany: false,
      Notasset: false,
      showMonthPopup: false,
      selectedColumns: [
        {
          // title: "Equipment Name",
          title: context.translate("_EQUIPMENT_NAME_"),
          dataIndex: "vehicleNo",
          // width: "15%",
          key: "equipmentName",
        },
        {
          // title: "Brand",
          title: context.translate("_BRAND_"),
          dataIndex: "vehicleBrand",
          // width: "15%",
          key: "vehicleBrand",
        },

        {
          // title: "Start Date/Time",
          title: context.translate(
            "_FLEET_REPORTS_STARTSTOPREPORT_STARTDATETIME_"
          ),
          dataIndex: "startTime",
          // width: "15%",
          key: "startTime",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            if (value) {
              return <>{formattedDateTime}</>;
            } else {
              return <></>;
            }
          },
        },
        {
          // title: "Stop Date/Time",
          title: context.translate(
            "_FLEET_REPORTS_STARTSTOPREPORT_STOPDATETIME_"
          ),
          dataIndex: "stopTime",
          // width: "15%",
          key: "stopTime",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            if (value) {
              return <>{formattedDateTime}</>;
            } else {
              return <></>;
            }
          },
        },
        {
          // title: "Total Running Hours",
          title: context.translate("_TOTAL_RUNNING_HOURS_"),
          // dataIndex: "totalrunning",
          dataIndex: "timeDiff",
          // width: "15%",
          // key: "runninghour",
          key: "timeDiff",
          // render: (value) => {
          //   console.log(value);

          //   const formattedTime = this.formatSecondsToTime(value);

          //   return <div>{formattedTime}</div>;
          // },
        },
        {
          // title: "Regular Running Hours",
          title: context.translate("_REGULAR_RUNNING_HOUR_"),
          dataIndex: "regularRunningHours",
          // width: "15%",
          key: "regularRunningHours",
        },
        {
          // title: "Excess Running Hours",
          title: context.translate("_EXCESS_RUNNING_HOURS_"),
          dataIndex: "excessRunningHour",
          // width: "15%",
          key: "excessRunningHour",
        },
      ],
    };

    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        // {
        //   // label: "CSV",
        //   label: context.translate("_CSV_"),
        //   key: "csv",
        //   icon: <PlusOutlined />,
        // },
        // {
        //   // label: "XML",
        //   label: context.translate("_XML_"),
        //   key: "xml",
        //   icon: <PlusOutlined />,
        // },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          console.log(result.toString());

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            interval: 0,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            colList: result.toString(),
          };
          // console.log(this.state.travelInt)
          // console.log("asfsdgfhdfghfg",parseInt(JSON.parse(base64_decode(Cookies.get("data"))).userId))
          if (val.key == "excel") {
            // console.log("afd")
            this.machineryReportStore.exportExcel(getData);
          }
          if (val.key == "csv") {
            this.machineryReportStore.exportcsv(getData);
          }
          if (val.key == "pdf") {
            this.machineryReportStore.exportpdf(getData);
          }

          if (val.key == "xml") {
            this.machineryReportStore.exportxml(getData);
          }
        }
      },
    };

    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    this.setState({ pageSize: 50 });
    // await this.machineryReportStore.loadData({ offset: 0, limit: 50 });
    if(Number(this.props.getUserData().isadmin)!==-1){
    
      let companyId = this.props.getUserData().userCompanyId;
   
        this.setState({companyId:companyId})
        this.handleChange({value:companyId});
    }

    await this.setState({ column: this.state.selectedColumns });
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp.value });
    await this.setState({ selectedVehicles: [], selectAllVehicles: false });
    await this.vehicleStore.getVehiclesCompanyId(comp.value);
    await this.machineryReportStore.getEmailIds({
      companyId: comp.value,
    });
    await this.handleSearch();

    // console.log("data", data);
  }

  async handleSearch() {
    // await this.machineryReportStore.loadData({
    //   offset: (this.state.pageNo - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,
    // });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.showData();
  }

  // vehicleSelect = async (val) => {
  //   console.log(val);
  //   await this.setState({ selectedVehicles: val });
  // };

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }

    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      // this.setState({mandatory:false})
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        this.setState({ Notcompany: false, Notasset: false });
        await this.machineryReportStore.loadData({
          offset: (this.state.pageNo - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          pageNo: this.state.pageNo,
        });
        await this.setState({ listData: 1 });

      }
    }
  };

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  formatSecondsToTime = (val) => {
    const duration = dayjs.duration(val, "seconds");
    const days = duration.days().toString().padStart(2, "0");
    const hours = duration.hours().toString().padStart(2, "0");
    const minutes = duration.minutes().toString().padStart(2, "0");
    const seconds = duration.seconds().toString().padStart(2, "0");

    return `${days} days, ${hours}:${minutes}:${seconds}`;
  };

  onDateTimeChange = (dates) => {
    console.log(dates);
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };
  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }

  // onEmailSend=(values)=>{
  //   console.log(values)
  // }

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Machinery Report</div>{" "} */}
              <div className="heading">
                {this.context.translate("_GENSET_WORKING_HOUR_REPORT_")}
              </div>{" "}
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <Button
                  onClick={() => {
                    if (!this.state.companyId) {
                      this.setState({ Notcompany: true });
                    } else {
                      this.setState({ Notcompany: false });
                    }
                    if (this.state.selectedVehicles.length === 0) {
                      this.setState({ Notasset: true });
                    } else {
                      this.setState({ Notasset: false });
                    }

                    if (
                      this.state.companyId &&
                      this.state.selectedVehicles.length > 0
                    ) {
                      // this.setState({mandatory:false})

                      this.setState({ Notcompany: false, Notasset: false });
                      this.setState({ showEmail: true });
                    }
                  }}
                >
           
                  {this.context.translate("_FLEET_LOGIN_EMAILLBL_")}
                </Button> */}
                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.machineryReportStore?.machineryReport?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={this.handleChange}
                  style={{ width: 200 }}
                  status={this.state.Notcompany ? "error" : false}
                />
                 {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles: [],
                        selectAllVehicles:false
                      })
                    }
                  />
                )}
                <Checkbox
                  checked={this.state.selectAllVehicles}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllVehicles: e.target.checked,
                    });
                    console.log(e.target.checked);
                    await this.handleAllSelectVehicles();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                {/* <Select
                  mode="multiple"
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }
                    this.setState({Notasset:false})
                  }}
                  // onSearch={this.handleSearch}
                  // onChange={(val) => this.vehicleSelect(val)}
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 200 }}
                  status={this.state.Notasset ? "error" : false}
                /> */}

                <Select
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }

                    if (val?.length > 1) {
                      console.log(val?.length > 1);
                      this.setState({ showAlertPopup: true });
                    }
                  }}
                  style={{ width: 200 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  mode="multiple"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
                <RangePicker
                  placeholder={[
                    this.context.translate("_FROM_DATE_TIME_"),
                    this.context.translate("_TO_DATETIME_"),
                  ]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
                <Button
                  type="primary"
                  onClick={async () => {
                    await this.setState({ pageSize: 50, pageNo: 1 });
                    this.showData();
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.pageNo}
            pageSize={this.state.pageSize}
            total={this.machineryReportStore?.total}
            columns={this.state.column}
            dataSource={this.machineryReportStore?.machineryReport}
            loading={this.machineryReportStore?.loading}
            onChange={this.handleTableChange}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   total: this.machineryReportStore.total || 0,
            // }}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />

          <Modal
            open={this.state.showEmail}
            // title="Send Email"
            title={this.context.translate("_SEND_EMAIL_")}
            onOk={() => this.setState({ showEmail: false })}
            onCancel={() => this.setState({ showEmail: false })}
            footer={false}
            width={400}
          >
            <Form
              name="Email"
              layout="vertical"
              onFinish={this.onEmailSend}
              style={{ maxWidth: "100%" }}
            >
              <Row>
                <Col span={12}></Col>
                <Col
                  span={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                ></Col>
              </Row>
              <Form.Item
                name="Email"
                // label="Email"
                label={this.context.translate("_FLEET_LOGIN_EMAILLBL_")}
                rules={[{ required: true }]}
              >
                <Select
                  onSearch={this.handleSearch}
                  fieldNames={{ label: "emailIds", value: "emailIds" }}
                  // placeholder="Email Ids"
                  placeholder={this.context.translate("_EMAIL_IDS_")}
                  optionFilterProp="children"
                  options={this.machineryReportStore?.emailIdsList}
                  style={{ width: 350 }}
                />
              </Form.Item>
              <div className="formBtnGroup">
                {/* css class */}

                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  // loading={this.state.loading}
                >
                  {/* css class */}
                  {/* Save */}
                  {this.context.translate("_SUBMIT_")}
                </Button>
              </div>
              {/* <Row justify={"end"}>
                <Space style={{}}>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Space>
              </Row> */}
            </Form>
          </Modal>

          <Modal
            width={350}
            className="alert-model"
            title={
              <div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-exclamation-triangle"
                ></i>{" "}
                {/* Alert */}
                {this.context.translate("_FLEET_ALERT_")}
              </div>
            }
            open={this.state.showMonthPopup}
            onOk={() => this.setState({ showMonthPopup: false })}
            onCancel={() => this.setState({ showMonthPopup: false })}
            footer={false}
          >
            {/* For reports prior to 6 months please contact administrator */}
            {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
          </Modal>
        </div>
      </>
    );
  }
}
MachineryReportList.contextType = StoreContext;
export default observer(withRouter(MachineryReportList));
