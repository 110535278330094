import React, { Component } from "react";

import {
  Form,
  Select,
  Button,
  message,
  Input,
  DatePicker,
  Modal,
  Checkbox,
  Upload,
  Row,
  Col,
  TimePicker,
  Space,
  Spin,
} from "antd";

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import CompanyAutocomplete from "./CompanyAutocomplete";
import GradeTimingStore from "../store/GradeTimingStore";
import moment from "moment";
import { Link } from "react-router-dom";
import CompanyStore from "../store/CompanyStore";
import config from "../config";
import dayjs from "dayjs";
import NotificationStore from "../store/NotificationStore";

const gradeArray = [];
gradeArray.push("KG1", "KG2");
for (let i = 1; i <= 12; i++) {
  gradeArray.push(`Grade${i}`);
}
class GradeTimingAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      companyId: "",
      grade_Id: -1,
      loadList: 0,
      loadingSpin: false
    };
    this.gradeTimeStore = new GradeTimingStore(this);
    this.companyStore = new CompanyStore(this);

  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    if (!this.props.grade_Id) {
      this.context.viewStateStore.setGradeTimingStates({
        companyId: comp.value,
      });
    }
    await this.handleSearch();
  }

  async handleSearch() {
    await this.gradeTimeStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }
  async componentDidMount() {
    if(Number(this.props.getUserData().isadmin)!==-1){
      this.context.viewStateStore.setGradeTimingStates({ companyId: this.props.getUserData().userCompanyId});
      await this.setState({companyId:this.props.getUserData().userCompanyId})
      console.log(this.state.companyId)
      // await this.setState({userId:this.props.getUserData().userId})
      await this.setState({isadmin:this.props.getUserData().isadmin})
    }
    const getClass = await this.gradeTimeStore.getAllClass();
    const grade_Id = this.props.grade_Id;

    if (grade_Id) {
      await this.setState({ loadingSpin: true })
      const getGradeData = await this.gradeTimeStore.getGradeTimingData({
        grade_Id: grade_Id,
      });
      console.log(getGradeData, "grade");
      await this.setState({
        companyId: getGradeData.companyId,
        grade_Id: getGradeData.grade_Id,
      });

      const singleData = await this.companyStore.getCompanyDataById(
        getGradeData.companyId
      );
      console.log(singleData[0], "sing");
      console.log(getGradeData);
      await this.formRef.current.setFieldsValue({
        companyId: singleData[0],
        grade: getGradeData.classId,
        startTime: dayjs(getGradeData.startTime, "HH:mm"),
        stopTime: dayjs(getGradeData.stopTime, "HH:mm"),
      });
      await this.setState({ loadingSpin: false })
    }

    console.log(grade_Id);
    this.setState({ loadData: 1 })
  }

  onFinish = async (values) => {
    this.setState({ loading: true });
    console.log(values);
    const data = {
      ...values,
      companyId: this.state.companyId,
      grade_Id: this.state.grade_Id,
      startTime: values.startTime ? values.startTime.format("HH:mm") : "00:00",
      stopTime: values.stopTime ? values.stopTime.format("HH:mm") : "00:00",
    };
    console.log(data);
    const response = await this.gradeTimeStore.addData(data);
    // if (response?.success === "S") {
    //   message.success(response.message);
    //   this.props.navigate("/gradetiminglist");
    //   // this.props.navigate("/gradetiminglist/"+this.state.companyId);

    // }
    // else if(response.success == 'F0') {
    //     response.errors.map((value) => {
    //         message.error(value['grade']);
    //     })
    // console.log(response.data.errors[0].grade)
    // message.error(response.data.errors[0].grade)
    // }
    this.props.response(response);
    this.setState({ loading: false });
  };

  closeModal = () => {
    this.props.closeModal()
  }

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen >
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              ref={this.formRef}
              layout="vertical"
              onFinish={this.onFinish}
              // {...this.layout}
              name="nest-message"
              className="formClass"
            >
              {/* <h1>Grade Timing Details</h1> */}

              {/* <div className="formBorder"> */}
              {/* <div className="headingTitle"> Grade Timing Details</div> */}
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 || this.props.getUserData().isadmin === 3) &&
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={async (value) => {
                          await this.handleChange(value);
                          //   this.getVehicles();
                        }}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  }

                  <Form.Item
                    name="grade"
                    label={this.context.translate("_CLASS_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Select

                      name="classId"
                      placeholder={this.context.translate("_SELECT_CLASS_")}
                      fieldNames={{ value: "classId", label: "className" }}
                      optionFilterProp="children"
                      options={this.gradeTimeStore?.getClass}
                    />
                    {/* <Select>
                    {gradeArray.map((val, ind) => {
                      return (
                        <Select.Option value={ind + 1}>{val}</Select.Option>
                      );
                    })}
                  </Select> */}
                  </Form.Item>
                </Col>

                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="startTime"
                    label={this.context.translate("_FLEET_START_TIME_")}
                    initialValue={dayjs("00:00", "HH:mm")}
                    // rules={[{ required: true }]}
                  >
                    <TimePicker format="HH:mm" style={{ width: "90%" }} />
                  </Form.Item>

                  <Form.Item
                    name="stopTime"
                    // label={"End Time"}
                    label={this.context.translate("_END_TIME_")}
                    initialValue={dayjs("00:00", "HH:mm")}
                    // rules={[{ required: true }]}
                  >
                    <TimePicker format="HH:mm" style={{ width: "90%" }} />
                  </Form.Item>
                </Col>
              </Row>
              <div className="formBtnGroup">
                {/* <Link to="/gradetiminglist">
                  {" "}
                  <Button ghost type="primary" htmlType="">
                    Back
                  </Button>
                </Link> */}

                <Button
                  // ghost
                  // danger
                  // type="primary"
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal()
                    // this.formRef.current?.resetFields();
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="formSaveButton"
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>

              {/* </div> */}
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

GradeTimingAdd.contextType = StoreContext;
export default observer(withRouter(GradeTimingAdd));
