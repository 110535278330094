import React, { Component } from "react";

import {
  Form,
  Select,
  Button,
  Space,
  DatePicker,
  message,
  Upload,
  Row,
  Col,
  Input,
  Alert,
  Spin,
} from "antd";
import moment from "moment";

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import { Link } from "react-router-dom";
import config from "../config";
import VehicleLiveLocationStore from "../store/VehicleLiveLocationStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import VehicleStore from "../store/VehicleStore";
import CompanyStore from "../store/CompanyStore";
import VehicleWorkingHoursStore from "../store/VehicleWorkingHoursStore";
import dayjs from "dayjs";
import { format, addMinutes } from "date-fns";
const dateFormat = "YYYY-MM-DD";
const currentDate = moment().format(dateFormat);

class VehicleLiveLocationAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      locationUrlDiv: false,
      Id: -1,
      companyId: "",
      loadList: 0,
      vehiclesAssetArray: [],
      vehicleId: "",
      locationUrl: "",
      getUrl: "",
      errorShow: false,
      linkError: "",
      loadingSpin: false
    };
    this.setLocationUrl = this.setLocationUrl.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    this.vehicleLiveLocationStore = new VehicleLiveLocationStore();
    this.vehicleStore = new VehicleStore();
    this.companyStore = new CompanyStore();
    this.workingHoursStore = new VehicleWorkingHoursStore();
  }

  async componentDidMount() {
    if ( Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3) {
      this.context.viewStateStore.setLiveLocationStates({ companyId: this.props.getUserData().userCompanyId });
      await this.setState({ companyId: this.props.getUserData().userCompanyId })
      await this.setState({ isadmin: this.props.getUserData().isadmin })
      const data = await this.workingHoursStore.getAssignAsset({
        companyId: this.props.getUserData().userCompanyId
      });
      this.setState({ update: 1 })
    }
    await this.setState({ loadList: 1 });
    const Id = this.props.vehicleLiveLocationId;
    console.log(Id);

    if (Id) {
      console.log(Id);
      this.filldata(Id);
      this.setState({ id: Id });
    }

    console.log(this.props.vehicleLiveLocationId, "cehil");
    // if (this.props.vehicleLiveLocationId) {
    //   const url = await this.vehicleStore.getVehicleId(
    //     this.props.vehicleLiveLocationId
    //   );
    //   // console.log(url,"url")
    //   await this.setState({ getUrl: url });
    // }
  }

  async filldata(Id) {
    console.log(Id);
    await this.setState({ loadingSpin: true })
    const getLocationDetails =
      await this.vehicleLiveLocationStore.getLiveLocationData(Id);
    console.log("getLocationDetails", getLocationDetails);

    const singleData = await this.companyStore.getCompanyDataById(
      getLocationDetails.companyId
    );
    await this.setState({
      companyId: getLocationDetails.companyId,
      Id: getLocationDetails.id,
    });

    await this.workingHoursStore.getAssignAsset({
      companyId: getLocationDetails.companyId,
    });

    await this.formRef.current.setFieldsValue({
      id: getLocationDetails.id,
      companyId: singleData,
      name: getLocationDetails.name,
      vehicleId: getLocationDetails.vehicleId,
      // vehicleId: getLocationDetails.vehicleNo,
      expiryDate: dayjs(getLocationDetails.expiryDate),
      locationUrl: getLocationDetails.locationUrl,
      startTime: getLocationDetails.startTime,
      endTime: getLocationDetails.endTime
    });
    this.setState({ locationUrlDiv: true });
    await this.setState({ loadingSpin: false })
    await this.setState({ loadData: 1 });
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  onLiveLocationSubmit = async (values) => {
    this.setState({ loading: true });
    const data = {
      ...values,
      Id: this.state.Id,
      expiryDate: values?.expiryDate.format("YYYY-MM-DD"),
      status: 1,
      companyId: this.state.companyId,
    };
    console.log("data", data);
    const resp = await this.vehicleLiveLocationStore.addData(data);
    console.log(resp)

    await this.props.response(resp);
    //  if((resp.data.errors[0]?.vehicleNo)==="Already Exist For this Date!"){
    //   this.setState({linkError:"Link already created for selected vehicle"})
    //   // this.formRef.current.setFieldsValue({vehicleId:""})
    // }
    // if (resp.data.success == "S") {
    //    message.success(resp.data.message);
    //   this.props.navigate("/vehiclelivelocationlist");
    // } else if (resp.data.success == "F0") {
    //   resp.data.errors.map((value) => {
    //     // message.error(value["vehicleNo"]);
    //     message.error(["Link Alredy Created For Selected Vehicle"]);
    //   });
    // }
    this.setState({ loading: false });
  };

  async handleChange(comp) {
    console.log("comp", comp);
    if (!this.props.vehicleLiveLocationId) {
      this.context.viewStateStore.setLiveLocationStates({
        companyId: comp.value,
      });
    }
    await this.setState({ companyId: comp.value });
    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });

    const data = await this.workingHoursStore.getAssignAsset({
      companyId: comp.value,
    });
    this.setState({ update: 1 })
    // const vehicleAsset = await this.vehicleStore.getVehicles(comp.value)
    // await this.setState({ vehiclesAssetArray: vehicleAsset })
    // console.log(comp.value)

    // await this.handleSearch();
  }

  async handleSearch() {
    await this.vehicleLiveLocationStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }
  // fillAssets = () => {
  //     console.log(this.state.vehiclesAssetArray)
  //     if (this.state.vehiclesAssetArray) {
  //         return this.state.vehiclesAssetArray.map((value) => {
  //             return (
  //                 <Select.Option value={value.vehicleId}>
  //                     {value.vehicleNo}
  //                 </Select.Option>
  //             )
  //         })
  //     }
  // }

  async setLocationUrl(vid) {
    console.log("vehicleId", vid);
    const url = await this.vehicleLiveLocationStore.getVehicleId(vid);
    console.log(url);
    this.setState({ locationUrlDiv: true });
    await this.formRef.current.setFieldsValue({ locationUrl: url.locationUrl });
  }

  timeOptions = () => {
    const timeArray = [];

    let startTime = new Date(2000, 0, 1, 0, 0); // 12:00 AM
    console.log();
    let endTime = new Date(2000, 0, 1, 24, 0);
    for (let time = startTime; time < endTime; time = addMinutes(time, 15)) {
      // const formattedTime = format(time, 'h:mm aa');
      const formattedTime = format(time, "HH:mm");
      var ob = {
        formattedTime: formattedTime,
        time: time,
      };
      timeArray.push(ob);
    }
    // console.log(timeArray);
    return timeArray.map((data) => {
      return (
        <Select.Option key={data.formattedTime} value={data.formattedTime}>
          {format(data.time, "HH:mm")}
        </Select.Option>
      );
    });
  };

  closeModal = () => {
    this.props.closeModal()
  }

  render() {
    return (

      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen >
            {this.state.errorShow && (
              <Alert
                style={{ marginLeft: "140px", width: "60%", height: "7%" }}
                // message="Please Create Link"
                message={this.context.translate("_PLEASE_CREATE_LINK_")}
                type="error"
              />
            )}
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              ref={this.formRef}
              layout="vertical"
              onFinish={this.onLiveLocationSubmit}
              onFinishFailed={(e) => this.setState({ errorShow: true })}
              name="nest-message"
              initialValues={{ expiryDate: dayjs() }}
            >
              {/* <h1>Live Location</h1> */}

              {/* <div className="formBorder"> */}
              {/* <div className="headingTitle"> Live Location</div> */}



              <Row className="overflowFormInputs" style={{ height: "540px" }}>




                {/* css class */}
                <Col span={12} className="formColumnClass">


                  {(this.props.getUserData().isadmin === -1 || this.props.getUserData().isadmin === 3) &&
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        disabled={this.props.vehicleLiveLocationId ? true : false}
                        onChange={(value) => this.handleChange(value)}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  }

                  <Form.Item
                    name="vehicleId"
                    label={this.context.translate("_ASSET_")}
                    rules={[{ required: true }]}
                  // help={this.state.linkError ? this.state.linkError :null} validateStatus={this.state.linkError?"error":""}
                  >
                    <Select
                      // placeholder="Select Asset"
                      placeholder={this.context.translate("_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_")}
                      disabled={this.props.vehicleLiveLocationId ? true : false}
                      onChange={(e) => {
                        this.setLocationUrl(e);
                        console.log(e);
                      }}
                      style={{ width: "100%" }}
                      // mode="multiple"
                      showSearch
                      fieldNames={{ value: "vehicleId", label: "vehicleNo" }}
                      optionFilterProp="children"
                      options={this.workingHoursStore?.assignAssetList}
                    />

                    {/* <Select placeholder="Select Asset" onChange={(e)=>this.setLocationUrl(e)}>
                                    {this.fillAssets()}
                                </Select> */}
                  </Form.Item>
                  <Form.Item
                    name="expiryDate"
                    label={this.context.translate("_EXPIRY_DATE_")}
                    rules={[{ required: true }]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    // label="Start Time" 
                    label={this.context.translate("_FLEET_START_TIME_")}
                    name="startTime" rules={[{ required: true }]}>
                    <Select>{this.timeOptions()}</Select>
                  </Form.Item>

                  <Form.Item
                    // label="End Time" 
                    label={this.context.translate("_END_TIME_")}
                    name="endTime" rules={[{ required: true }]}>
                    <Select>{this.timeOptions()}</Select>
                  </Form.Item>

                  {this.state.getUrl && (
                    <Form.Item
                      name="locationUrl"
                      label="Link"
                      rules={[{ required: true }]}
                    >
                      <Input
                        disabled={this.props.vehicleLiveLocationId ? true : false}
                      />
                    </Form.Item>
                  )}

                  <div
                    style={{ display: this.state.locationUrlDiv ? "" : "none" }}
                  >
                    <Form.Item name="locationUrl" label="Link">
                      <Input
                        disabled={this.props.vehicleLiveLocationId ? true : false}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <div className="formBtnGroup">
                {/* <Link to={"/vehiclelivelocationlist"}>
                  {" "}
                  <Button ghost type="primary" htmlType="">
                    {" "}
                    Back
                  </Button>
                </Link> */}
                <Button
                  className="formCancelButton"
                  type="primary"
                  onClick={(e) => {
                    this.closeModal()
                    // this.formRef.current?.resetFields();
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}

                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

VehicleLiveLocationAdd.contextType = StoreContext;
export default observer(withRouter(VehicleLiveLocationAdd));
