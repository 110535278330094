import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import config from "../config";

class NewMaintenanceStore {
  maintainVehicles = [];
  serviceData = [];
  maintenanceData=[]
  loading = false;
  total = 0;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      maintainVehicles: observable,
      loading: observable,
      total: observable,
      loadData: action,
      addData: action,
      getMaintenanceGridData: action,
      getServiceData: action,
      getVehicleMaitenanceData: action,
      deleteRecord: action,
      sampleExcel: action,
      addVehMainByExcel:action,
      getMaintenanceData:action,
      addMaintenance:action,
      addService:action,
      deleteGridRecord: action,
      serviceData: observable,
      maintenanceData:observable,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    companyId,
    searchField,
    servicedateFrom,
    servicedateTo,
    vehicleId,
    offset,
    limit,
    isadmin,
    userId
  }) {
    this.loading = true;
    var postData = {
      tokenId: "80bovn8u800tg1aq9bvr73kbhh",
      searchField: searchField,
      servicedateFrom:servicedateFrom,
      servicedateTo:servicedateTo,
      companyId: companyId,
      vehicleNo: vehicleId,
      userId:userId,
      isadmin:isadmin,
      offset: offset,
      limit: limit,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=maintenance&a=newGetMain", "postData=" + base64Input);

    this.maintainVehicles = response?.data?.data?.maintenancedetails;
    this.total = parseInt(response.data.data?.TotalCount);
    this.loading = false;
  }

  async addData(maintenanceDetails) {
    var postData = maintenanceDetails;

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=maintenance&a=newMaintenanceUpdate", "postData=" + base64Input);

    return response;
  }

  async getServiceData(Id) {
    var postData = {Id:Id}

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=maintenance&a=getService", "postData=" + base64Input);

    this.serviceData = response?.data?.data;
    return response?.data?.data;
  }

  async getMaintenanceData() {
   
    var response = await postApi
      .getClient()
      .post("index.php?c=maintenance&a=getMaintenanceType");

    this.maintenanceData = response?.data?.data;
    return response?.data?.data;
  }

  async getVehicleMaitenanceData({ Id, limit, offset, companyId }) {
    const postData = { Id: Id, limit: 1, offset: 0, companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=maintenance&a=newGetMain", "postData=" + base64Input);

    return response?.data?.data.maintenancedetails[0];
  }

  async deleteRecord({ Id }) {
    var postData = { tokenId: "", Id: Id };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=maintenance&a=delete", "postData=" + base64Input);
    return response;
  }

  async deleteGridRecord({ aId,maintenanceId }) {
    var postData = { aId: aId, maintenanceId: maintenanceId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=maintenance&a=deleteAdditionalService",
        "postData=" + base64Input
      );
    return response;
  }

  async getMaintenanceGridData(id) {
    var postData = { maintenanceId: id };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=maintenance&a=getnewServiceGridData",
        "postData=" + base64Input
      );

    return response?.data?.data;
  }

  async sampleExcel() {
    window.location.href =
      config.fleetURL + "/appRes/excel/SampleMaintainance.xlsx";
  }

  async addVehMainByExcel(excelFile) {
    console.log(excelFile)
    this.excelLoadig = true;
    var postData = excelFile;

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=maintenance&a=maintenanceExcel", "postData=" + base64Input);
    this.excelLoadig = false;
    return response.data;
  }

 async addMaintenance(data){
  var postData = data;

  const base64Input = await base64_encode(JSON.stringify(postData));
  var response = await postApi
    .getClient()
    .post("index.php?c=maintenance&a=saveMaintenanceType", "postData=" + base64Input);

  return response.data;
 }

 async addService(data){
  var postData = data;

  const base64Input = await base64_encode(JSON.stringify(postData));
  var response = await postApi
    .getClient()
    .post("index.php?c=maintenance&a=saveServices", "postData=" + base64Input);

  return response.data;
 }
}

export default NewMaintenanceStore;
