import React, { Component } from "react";

import {
  Form,
  Select,
  Button,
  Input,
  DatePicker,
  Modal,
  Row,
  Col,
  Space,
  message,
  TimePicker,
  Tooltip,
  Spin,
} from "antd";
import config from "../config";

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import JobRequestStore from "../store/JobRequestStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import DepartmentStore from "../store/DepartmentStore";
import JobTypeStore from "../store/JobTypeStore";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import Autocomplete from "react-google-autocomplete";
import DisplayLocationMap from "./DisplayLocationMap";
import CompanyStore from "../store/CompanyStore";
const currentDate = dayjs();

const currentTime = dayjs().format("HH:mm");
const { Option } = Select;
class JobRequestAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      showDeptForm: false,
      showJobForm: false,
      jobTypeAdd: "",
      loadState: 0,
      departmentId: "",
      jobId: "",
      companyId: "",
      Id: -1,
      collectionFields: false,
      requestTime: dayjs().format("HH:mm"),
      addressInfo: [],
      addressMap: false,
      loadingSpin: false,
    };
    this.jobRequestStore = new JobRequestStore(this);
    this.departmentStore = new DepartmentStore(this);
    this.jobTypeStore = new JobTypeStore(this);
    this.companyStore = new CompanyStore(this);
  }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      this.context.viewStateStore.setJobRequestStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      console.log(this.state.companyId);
      // await this.setState({userId:this.props.getUserData().userId})
      await this.setState({ isadmin: this.props.getUserData().isadmin });
    }
    await this.departmentStore.getUserDepartmentData();
    await this.jobTypeStore.getJobData();

    console.log(this.props.params);
    console.log(this.props.Id);
    const Id = this.props.Id;
    console.log("id", Id);

    if (Id) {
      this.filldata(Id);
      this.setState({ Id: Id });
    }
    await this.setState({ loadState: 1 });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.addressMap &&
      prevState.addressMap !== this.state.addressMap
    ) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 10);
    }
  }

  async filldata(Id) {
    await this.setState({ loadingSpin: true });
    const getJobReqData = await this.jobRequestStore.getJobRequestData(Id);
    console.log("getdata", getJobReqData);

    const singleData = await this.companyStore.getCompanyDataById(
      getJobReqData.companyId
    );

    await this.departmentStore.getUserDepartmentData();

    await this.jobTypeStore.getJobData();

    await this.setState({
      companyId: getJobReqData.companyId,
      Id: getJobReqData.Id,
    });

    this.formRef.current.setFieldsValue({
      Id: getJobReqData.Id,
      companyId: singleData,
      departmentName: getJobReqData.departmentId,
      jobtypeName: getJobReqData.jobId,

      requestDate: getJobReqData.requestDate
        ? dayjs(getJobReqData.requestDate)
        : null,
      requestTime: dayjs(getJobReqData.requestTime, "HH:mm"),
      contactPerson: getJobReqData.contactPerson,
      contactNumber: getJobReqData.contactNo,
      location: getJobReqData.location,
      description: getJobReqData.description,
      invoiceAmount: getJobReqData.invoiceAmt,
      invoiceNo: getJobReqData.invoiceNo,
      countryCode: getJobReqData.countryCode,
    });
    await this.setState({ loadingSpin: false });
    console.log(getJobReqData.jobId);
    if (getJobReqData.jobId == 1) {
      this.setState({ collectionFields: true });
    } else {
      this.setState({ collectionFields: false });
    }
  }

  async handleChange(comp) {
    console.log(comp.value);

    console.log(comp.value);
    console.log(this.props.id, "EDITRRRRR");
    await this.setState({ companyId: comp.value });
    if (!this.props.Id) {
      this.context.viewStateStore.setJobRequestStates(comp.value);
    }
    await this.setState({ companyId: comp.value });

    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });
    await this.handleSearch();
  }

  async handleSearch() {
    await this.jobRequestStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  onJobRequestSubmit = async (values) => {
    console.log(values, values.departmentName);
    this.setState({ loading: true });
    // console.log(values.departmentName.length>0?values.departmentName[0].value: (values.departmentName===undefined?null:values.departmentName))

    const jobRequest = {
      ...values,
      companyId: this.state.companyId,
      Id: this.state.Id,

      // departmentName:
      //   values.departmentName?.length > 0
      //     ? values.departmentName[0].value
      //     : values.departmentName === undefined
      //     ? null
      //     : values.departmentName,
      // jobtypeName:
      //   values.jobtypeName?.length > 0
      //     ? values.jobtypeName[0].value
      //     : values.jobtypeName === undefined
      //     ? null
      //     : values.jobtypeName,
      requestDate: values?.requestDate.format("YYYY-MM-DD"),
      requestTime: this.state.requestTime,
    };
    const data = {
      tokenId: "",
      JobRequest: jobRequest,
    };

    const response = await this.jobRequestStore.addData(data);

    // if (response?.success === "S") {
    //   message.success(response.message);
    //   this.props.navigate("/jobrequestlist");
    // } else {
    //   message.error(response.message);
    // }
    await this.props.response(response);

    this.setState({ loading: false });
  };

  departmentNameSubmit = async (fieldValues) => {
    console.log(fieldValues);
    const data = { department: fieldValues };
    // await this.departmentStore.addDepartment(data);
    await this.departmentStore.getUserDepartmentData();
    const response = await this.departmentStore.addDepartment(data);
    await this.departmentStore.getUserDepartmentData();

    console.log(response);
    if (response.data.success === "S") {
      await this.formRef.current.setFieldsValue({
        departmentName: Number(response.data.data.departmentId),
        // departmentName: {
        //        label: response.data.data.departmentName,
        //        value: response.data.data.departmentId,
        //      }
        // departmentName: [
        //   {
        //     label: response.data.data.departmentName,
        //     value: response.data.data.departmentId,
        //   },
        // ],
      });
      this.setState({ departmentName: Number(response.data.data.departmentId) });
      await this.departmentStore.getUserDepartmentData();
      // this.formRef.current.setFieldsValue({departmentName:parseInt(response.data.data.departmentId)})
      message.success(response.data.message);

      await this.setState({ showDeptForm: false });
      this.setState({ loadList: 1 });
      // await console.log(response);
    } else {
      await message.error(response.data.message);
    }

    // await this.setState({ loadList: 1});
    this.setState({ loadList: 1 });
  };

  selectDepartment = async (value) => {
    console.log(value);
    await this.setState({ departmentName: value });
    this.formRef.current.setFieldsValue({ departmentName: value });
  };

  jobNameSubmit = async (fieldValues) => {
    console.log(fieldValues);
    const data = { jobType: fieldValues };
    // await this.departmentStore.addData(data);

    const response = await this.jobTypeStore.addData(data);
    await this.jobTypeStore.getJobData();

    console.log(response);
    if (response.data.success === "S") {
      await this.formRef.current.setFieldsValue({
        jobtypeName: Number(response.data.data.jobId),
        // jobtypeName: [
        //   {
        //     label: response.data.data.jobName,
        //     value: response.data.data.jobId,
        //   },
        // ],
      });
      this.setState({ jobtypeName: Number(response.data.data.jobId) });
      message.success(response.data.message);
      await this.jobTypeStore.getJobData();
      await this.setState({ showJobForm: false });
      this.setState({ loadList: 1 });
    
    } else {
      await message.error(response.data.message);
    }

    // await this.setState({ loadList: 1});
    this.setState({ loadList: 1 });
  };

  selectJob = async (value) => {
    await this.setState({ jobtypeName: value });
  };

  openCollectionFields = (value) => {
    console.log(value);
    if (value == 1) {
      this.setState({ collectionFields: true });
    } else {
      this.setState({ collectionFields: false });
    }
  };

  validateNumber = (value, field1) => {
    // Check if value contains only numbers
    const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1.length);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field1]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field1]: "" });
    }
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              ref={this.formRef}
              // style={{ maxWidth: "100%", padding: 0 }}
              layout="vertical"
              onFinish={this.onJobRequestSubmit}
              // {...this.layout}
              name="nest-message"
              className="formClass"
              initialValues={{ requestDate: dayjs(currentDate) }}
            >
              {/* <div className="formBorder"> */}
              {/* <div className="headingTitle"> Add Job Request Details</div> */}
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => this.handleChange(value)}
                        style={{ width: "100%" }}
                        companyId={this.state.companyId}
                      />
                    </Form.Item>
                  )}
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {/* <Col span={12} > */}
                    <Form.Item
                      name="departmentName"
                      label={this.context.translate("_DEPARTMENT_")}
                      style={{ width: "80%" }}
                      rules={[{ required: true }]}
                    >
                      <Select
                        value={this.state.departmentId}
                        // placeholder="Departments"
                        placeholder={this.context.translate(
                          "_SELECT_DEPARTMENT_"
                        )}
                        showSearch
                        onSelect={this.selectDepartment}
                        filterOption={(inputValue, option) => {
                          if (option.children) {
                            return (
                              option.children
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            );
                          }
                        }}
                      >
                        {this.departmentStore?.departments?.map((val) => {
                          return (
                            <Option value={val.departmentId}>
                              {val.departmentName}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    {/* </Col> */}
                    {/* <Col span={2} /> */}
                    <Form.Item label=" ">
                      <Tooltip
                        // title="Add New Department"
                        title={this.context.translate("_ADD_NEW_DEPARTMENT_")}
                        color={config.tooltipColorEdit}
                        placement="bottom"
                      >
                        <Button
                          style={{ height: "48px" }}
                          type="primary"
                          onClick={() => {
                            this.setState({ showDeptForm: true });
                          }}
                        >
                          <PlusOutlined />
                        </Button>
                      </Tooltip>
                    </Form.Item>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {/* <Col span={17}> */}
                    <Form.Item
                      name="jobtypeName"
                      label={this.context.translate("_JOB_TYPE_")}
                      style={{ width: "80%" }}
                      rules={[{ required: true }]}
                    >
                      <Select
                        // fieldNames={{
                        //   label: "jobName",
                        //   value: "jobId",
                        // }}
                        onChange={(val) => {
                          console.log(val);
                          this.openCollectionFields(val);
                        }}
                        value={this.state.jobId}
                        // placeholder="Job Type"
                        showSearch
                        placeholder={this.context.translate("_JOB_TYPE_")}
                        onSelect={this.selectJob}
                        // optionFilterProp="children"
                        // options={this.jobTypeStore?.jobList}
                        filterOption={(inputValue, option) => {
                          if (option.children) {
                            return (
                              option.children
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            );
                          }
                        }}
                      >
                        {this.jobTypeStore?.jobList?.map((val) => {
                          return (
                            <Option value={val.jobId}>{val.jobName}</Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    {/* </Col> */}

                    <Form.Item label=" ">
                      <Tooltip
                        // title="Add New Job"
                        title={this.context.translate("_ADD_NEW_JOB_")}
                        color={config.tooltipColorEdit}
                        placement="bottom"
                      >
                        <Button
                          style={{ height: "48px" }}
                          type="primary"
                          onClick={() => {
                            this.setState({ showJobForm: true });
                          }}
                        >
                          <PlusOutlined />
                        </Button>
                      </Tooltip>
                    </Form.Item>
                  </div>

                  {this.state.collectionFields && (
                    <>
                      <Form.Item
                        name="invoiceAmount"
                        label={this.context.translate("_INVOICE_AMOUNT_")}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="invoiceNo"
                        label={this.context.translate("_INVOICE_NUMBER_")}
                      >
                        <Input />
                      </Form.Item>
                    </>
                  )}

                  <Form.Item
                    name="requestDate"
                    label={this.context.translate("_REQUEST_DATE_")}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    name="requestTime"
                    label={this.context.translate("_REQUEST_TIME_")}
                  >
                    <TimePicker
                      defaultValue={dayjs()}
                      onChange={(time) => {
                        console.log(time);
                        if (time === null) {
                          this.setState({ requestTime: "" });
                        } else {
                          const format = "HH:mm";
                          this.setState({
                            requestTime: time.format(format),
                          });
                          console.log(time.format(format));
                        }
                      }}
                      // value={selectedTime}
                      // onChange={handleTimeChange}
                      format="HH:mm"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="contactPerson"
                    label={this.context.translate("_CONTACT_PERSON_")}
                  >
                    <Input />
                  </Form.Item>

                  {/* <Form.Item
                  name="contactNumber"
                  label={this.context.translate("_CONTACT_NO_")}
                  rules={[
                    {
                      pattern: /^(\d{7}|\d{10})$/,
                      message:this.context.translate("_Enter_7_or_10_Digit_Number_"),
                      // message: "Enter 7 or 10 Digit Number",
                      required: true,
                    },
                  ]}
                >
                  <Input
                    maxLength={10}
                    onChange={(e) => {
                      this.validateNumber(e.target.value, "contactNumber");
                    }}
                  />
                </Form.Item> */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      style={{ width: "30%" }}
                      name="countryCode"
                      // label="Contact 1"
                      label={" "}
                      // rules={[{ required: true }]}
                      rules={[{ required: true }]}
                      initialValue={+966}
                    >
                      <Select>
                        <Select.Option value={91}>+91</Select.Option>
                        <Select.Option value={966}>+966</Select.Option>

                        <Select.Option value={971}>+971</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      style={{ width: "65%" }}
                      name="contactNumber"
                      // label="Phone Number 1"
                      label={this.context.translate("_CONTACT_NO_")}
                      rules={[
                        {
                          required: true,
                          pattern: /^(\d{7}|\d{10})$/,
                          // message: "Enter a 7 or 10 digit number",
                          message: this.context.translate(
                            "_Enter_7_or_10_Digit_Number_"
                          ),
                        },
                      ]}
                      // rules={[{ required: true}]}
                    >
                      <Input
                        onChange={(e) =>
                          this.validateNumber(e.target.value, "contactNumber")
                        }
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="location"
                    label={this.context.translate("_LOCATION_")}
                  >
                    <Autocomplete
                      style={{
                        width: "100%",
                        height: "48px",
                        boxShadow: "none",
                        padding: "4px 11px",
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#d9d9d9",
                        borderRadius: "5px",
                      }}
                      placeholder={this.context.translate("_ENTER_LOCATION_")}
                      apiKey={config.googleMapKey}
                      // onChange={(val)=>console.log(val)}

                      onPlaceSelected={async (val) => {
                        console.log(val, val.geometry.location.lat());
                        this.formRef.current.setFieldsValue({
                          location: val.formatted_address,
                          Locationlat: val.geometry.location.lat(),
                          Locationlong: val.geometry.location.lng(),
                        });
                        await this.setState({ addressMap: true });
                        await this.setState({
                          addressInfo: [
                            val.geometry.location.lat(),
                            val.geometry.location.lng(),
                          ],
                        });
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="description"
                    label={this.context.translate("_DESCRIPTION_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <div className="formBtnGroup">
                {/* <Link to="/jobrequestlist">
                  {" "}
                  <Button ghost type="primary">
                    Back
                  </Button>
                </Link> */}

                <Button
                  // ghost
                  // danger
                  className="formCancelButton"
                  // type="primary"
                  onClick={(e) => {
                    this.closeModal();
                    // this.formRef.current?.resetFields();
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="formSaveButton"
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>

              {/* </div> */}
            </Form>
          </Spin>

          <Modal
            open={this.state.showDeptForm}
            // title="Add Department"
            title={this.context.translate("_ADD_DEPARTMENT_")}
            onOk={() => this.setState({ showDeptForm: false })}
            onCancel={() => this.setState({ showDeptForm: false })}
            footer={false}
            width={400}
          >
            <Form
              name="nest-messages"
              layout="vertical"
              onFinish={this.departmentNameSubmit}
              style={{ padding: "0px 10px" }}
            >
              <Form.Item
                name="dptname"
                label={this.context.translate("_NAME_")}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  onClick={() => this.setState({ showDeptForm: false })}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_DASHBORD_SHOWPOI_CANCELBTN_")}
                </Button>
                <Button
                  className="formSaveButton"
                  type="primary"
                  htmlType="submit"
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Modal>

          <Modal
            open={this.state.showJobForm}
            // title="Add Job Type"
            title={this.context.translate("_ADD_JOB_TYPE_")}
            onOk={() => this.setState({ showJobForm: false })}
            onCancel={() => this.setState({ showJobForm: false })}
            footer={false}
            width={400}
          >
            <Form
              name="nest-messages"
              layout="vertical"
              onFinish={this.jobNameSubmit}
              style={{ padding: "0px 10px" }}
            >
              <Form.Item
                name="jTypeName"
                label={this.context.translate("_NAME_")}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <div className="formBtnGroup">
                <Space style={{ padding: 10 }}>
                  <Button
                    type="primary"
                    className="formSaveButton"
                    htmlType="submit"
                  >
                    {/* Save */}
                    {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                  </Button>
                  <Button
                    className="formCancelButton"
                    onClick={() => this.setState({ showJobForm: false })}
                  >
                    {/* Cancel */}
                    {this.context.translate(
                      "_FLEET_DASHBORD_SHOWPOI_CANCELBTN_"
                    )}
                  </Button>
                </Space>
              </div>
            </Form>
          </Modal>

          <Modal
            className="multiTracking-popup"
            style={{ padding: "0px !important" }}
            title=<div>
              Location On Map
              <CloseOutlined
                onClick={() => this.setState({ addressMap: false })}
              />
            </div>
            maskClosable={false}
            centered
            bodyStyle={{ height: "60vh", padding: "0px" }} // Adjust the dimensions as needed
            width={1000}
            visible={this.state.addressMap}
            onCancel={() => this.setState({ addressMap: false })}
            footer={null}
          >
            <DisplayLocationMap
              addressInfo={
                this.state.addressInfo?.length > 0 && this.state.addressInfo
              }
              address={(address) =>
                this.formRef.current.setFieldsValue({ location: address })
              }
              formRef={this.formRef}
            />
          </Modal>

          {/* <Modal
          title="Add Job Type"
          open={this.state.showJobType}
          onOk={() => this.setState({ showJobType: false })}
          onCancel={() => this.setState({ showJobType: false })}
        >
          <Input
            value={this.state.jobTypeAdd}
            onChange={async (e) => {
              await this.setState({ jobTypeAdd: e.target.value });
            }}
          />
          <Button
            onClick={() => {
              this.JobTypeAddClick();
            }}
          >
            save
          </Button>
        </Modal> */}
        </div>
      </div>
    );
  }
}

JobRequestAdd.contextType = StoreContext;
export default observer(withRouter(JobRequestAdd));
