import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  
  makeObservable,
  observable,
 
} from 'mobx';


class JobTypeStore {
  jobTypeList=[]
  jobList=[]
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
      jobTypeList: observable,
      total:observable,
      jobList:observable,
      loading:observable,
     
      addData:action,
      getJobTypeData:action,
      getJobData:action
     
    });
     this.rootStore = rootStore;
    
  }

  async addData(values){
    var postData = values;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=delivery&a=addJobtype", "postData="+base64Input);
    console.log("fuel detis " ,response.data.data)
    return response
  }

  async getJobTypeData(){
    var postData = null
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=salesperson&a=getJobType", "postData="+base64Input);
    console.log(response)
    const allJobType = [{jobId: "0", jobName: 'All'}]
    this.jobTypeList = [...allJobType,...response?.data?.data]
    
  }

  async getJobData(){
    var postData = null
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=salesperson&a=getJobType", "postData="+base64Input);
    console.log(response)
   
    this.jobList = response?.data?.data
    
  }
  

  
  

}

export default JobTypeStore;
