import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi,baseURL} from '../components/Utility'
import {
  action,
  
  makeObservable,
  observable,
 
} from 'mobx';


class IdleReportStore {
  idleReport=[]
  totalIdleArray=[]
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        idleReport: observable,
        totalIdleArray:observable,
      total:observable,
      loading:observable,
      loadData:action,
      exportExcel:action,
      exportcsv:action,
      exportpdf:action,
      exportxml:action,
      totalIdleReport:action,

     
    });
     this.rootStore = rootStore;
    
  }

  async loadData({companyId,offset,limit,vehicleId,fromDate,toDate,interval,pageNo}) {
    this.loading=true
    var postData = {"companyId":companyId,"vehicleId":vehicleId,"fromDate":fromDate,"toDate":toDate,"interval":interval,"offset":offset,"limit":limit,"page":pageNo}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicleidle&a=idlingreport", "postData="+base64Input);
    this.idleReport =response?.data?.data?.idlingReport
  
     this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }


  async totalIdleReport({offset,limit,companyId,fromDate,toDate,vehicleId,interval}) {
    this.loadingData=true
    var postData = {"companyId":companyId,"vehicleId":vehicleId,"fromDate":fromDate,"toDate":toDate,interval:interval,offset:offset,limit:limit}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=totalIdleRep", "postData="+base64Input);   
    this.totalIdleArray = response?.data?.data
    // this.total = parseInt(response?.data?.data?.TotalCount);
    this.loadingData=false;
  }


  async exportExcel(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicleidle&a=exportidleexcel&cid=" +
      getData.companyId+
      "&userId=" +
     getData.userId+
      "&vid=" +
      getData.vehicleId+
      "&int="+
      getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicleidle&a=exportidlepdf&cid=" +
      getData.companyId+
      "&userId=" +
     getData.userId+
      "&vid=" +
      getData.vehicleId+
      "&int="+
      getData.interval+
      "&fD=" +
      getData.fromDate+
      "&tD=" +
      getData.toDate+
      "&colList=" +
      getData.colList+
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicleidle&a=exportidlecsv&cid=" +
      getData.companyId +
      "&userId=" +
     getData.userId+
      "&vid=" +
      getData.vehicleId +
      "&int="+
      getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportxml(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicleidle&a=exportidlexml&cid=" +
      getData.companyId +
      " &userId=" +
     getData.userId+
      "&vid=" +
      getData.vehicleId +
      "&int="+
      getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  
  

}

export default IdleReportStore;
