import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
  observe,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class DeviceTestingStore {
  deviceTesting=[]
 

  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
      deviceTesting: observable,
      total:observable,
      loading:observable,
      loadData:action,
      addData:action
     
    });
     this.rootStore = rootStore;
    
  }

  async loadData({offset,limit}) {
    console.log("device testing called")
    // this.loading = true;
    // var postData = {"companyId":"1","cstatus":status,"isadmin":"-1","cdType":distributorType,"distCompanyId":distributor,"cname":company_name,"email":emailId,"offset":offset,"limit":limit}  
    // const base64Input = await base64_encode(JSON.stringify(postData));
    // var response = await postApi.getClient().post("index.php?c=company&a=newCompanyList", "postData="+base64Input);
    // // console.log("company Data",response?.data?.data?.companydata)
    // this.companies = response?.data?.data?.companydata
    // //  this.setCompanies(response?.data?.data?.companydata);
    //  this.total = parseInt(response?.data?.data?.TotalCount);
    // this.loading=false;
  }

  async addData(values) {
    console.log("data", values);
    var postData = values;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=api&a=getVehicle", "postData=" + base64Input);
    console.log(response);
    
    return response;
  }


  

  
  

}

export default DeviceTestingStore;
