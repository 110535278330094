import React from "react";
import {
  Row,
  Col,
  Input,
  Tag,
  Avatar,
  Button,
  Space,
  Dropdown,
  message,
  Modal,
  Form,
  Select,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import DisplayLocationMap from "./DisplayLocationMap";
import {
  PushpinFilled,
  EditFilled,
  DeleteFilled,
  DownOutlined,
  SearchOutlined,
  DownloadOutlined,
  CloudUploadOutlined,
  DashboardOutlined,
  ExportOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import AddButton from "./AddButton";
import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import withRouter from "../withRouter";
import Cookies from "js-cookie";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import StationStore from "../store/StationStore";
const { Option } = Select;

class StationList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.stationStore = new StationStore(this);

    // console.log(this.context);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      loadList: 0,
      showStationReg: false,
      update: 0,
      stationCode: "",
      addressMap: false,
      addressInfo: [],
    };
    this.columns = [
      {
        title: context.translate("_STATION_CODE_"),
        dataIndex: "stationCode",
      },
      {
        title: context.translate("_ARABIC_NAME_"),
        dataIndex: "arabicName",
      },
      {
        title: context.translate("_ENGLISH_NAME_"),
        dataIndex: "englishName",
      },

      {
        title: context.translate("_FLEET_DASHBORD_VEHICLE_INFORMATION_LONGI_"),
        dataIndex: "longitude",
      },
      {
        title: context.translate("_FLEET_DASHBORD_SETTING_LATITUDE_"),
        dataIndex: "latitude",
      },
      {
        title: context.translate("_CITY_CODE_"),
        dataIndex: "cityCode",
      },
      {
        title: context.translate("_STATIONS_TATUS_"),
        dataIndex: "stationStatus",
      },
      {
        title: context.translate("_EMAIL_"),
        dataIndex: "email",
      },
      {
        title: context.translate("_FLEET_DASHBORD_NEWPOI_PHONE_"),
        dataIndex: "phone",
      },
      {
        title: context.translate("_PHONE_EXTENSION_"),
        dataIndex: "phoneExtension",
      },
      {
        title: context.translate("_MANAGER_PHONE_"),
        dataIndex: "managerPhone",
      },
      {
        title: context.translate("_REPLY_"),
        dataIndex: "reply",
      },
    ];
    this.formRef = React.createRef();
    // this.ExportMenuProps = {
    //   items: [
    //     {
    //       label: "Excel",
    //       key: "excel",
    //     },

    //   ],
    //   onClick: (val) => {
    //     const result = [];
    //         this.columns.map((obj) => result.push(obj.dataIndex));
    //       //  console.log(result.toString())
    //        var getData = {
    //         stationCode:this.state.stationCode,
    //       colList:result
    //     };
    //       console.log(getData)
    //       if (val.key == "excel") {
    //         this.stationStore.exportExcel(getData);
    //       }

    //   },
    // };
  }
  componentDidUpdate(prevProps, prevState) {

    if(Number(this.props.getUserData().isadmin)===0 || Number(this.props.getUserData().isadmin)===1){
      // console.log('innnnnn');
        this.props.navigate("/login");
       
     }
    if (
      this.state.addressMap &&
      prevState.addressMap !== this.state.addressMap
    ) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 10);
    }
  }
  async componentDidMount() {
    await this.stationStore.loadData({ limit: 50, offset: 0 });
    await this.stationStore.getCityCode();
    await this.setState({ pageSize: 50 });

    this.setState({ update: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value, loadList: 1 });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.stationStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 0 });
  }
  onStationRefFinish = async (values) => {
    console.log(values);
    const response = await this.stationStore.addRegistration(values);
console.log(response)
    if (response?.success === "S") {
      message.success(response.data.message);
      console.log(response);
    } else {
      await message.error(response.data.message);
    }

    if (response.data.message) {
      this.setState({ showStationReg: false });
      this.formRef.current?.resetFields();
    }
  };
  validateNumber = (value, field1) => {
    // Check if value contains only numbers
    const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1.length);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field1]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field1]: "" });
    }
  };
  searchStation = async (e) => {
    const stationCode = e.target.value;
    this.setState({ stationCode: stationCode });
    if (stationCode.length > 3 || stationCode.length === 0) {
      await this.stationStore.loadData({
        limit: 50,
        offset: 0,
        stationCode: stationCode,
      });
      await this.setState({ update: 1 });
    }
  };
  render() {
    return (
      <>
        <div>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_STATION_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                <Link to={"/companylist"}>
                  <Button type="primary">
                    {this.context.translate("_BACK_")}
                  </Button>
                </Link>
                <Button
                  type="primary"
                  onClick={() => this.setState({ showStationReg: true })}
                >
                  + {this.context.translate("_REGISTER_")}
                </Button>
                {/* <Dropdown menu={this.ExportMenuProps} disabled={this.stationStore.StationListArray.length>0 ? false :true}>
                <Button>
                  <Space>
                    <DownloadOutlined />
                  </Space>
                </Button>
              </Dropdown> */}

                <Button
                  style={{ backgroundColor: config.activeColor }}
                  disabled={
                    this.stationStore.StationListArray.length > 0 ? false : true
                  }
                  onClick={async (val) => {
                    const result = [];
                    this.columns.map((obj) => result.push(obj.dataIndex));
                    //  console.log(result.toString())
                    var getData = {
                      stationCode: this.state.stationCode,
                      colList: result,
                    };
                    await this.stationStore.exportExcel(getData);
                    await this.setState({ loadList: 1 });
                  }}
                >
                  <DownloadOutlined />
                  {this.context.translate("_EXPORT_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <Input
                  placeholder={this.context.translate("_STATION_CODE_")}
                  // value={this.state.stationCode}
                  onChange={(e) => this.searchStation(e)}
                />
                <Button
                  type="primary"
                  onClick={async () => {
                    await this.stationStore.loadData({
                      limit: 50,
                      offset: 0,
                      stationCode: this.state.stationCode,
                    });
                    await this.setState({ update: 1 });
                  }}
                >
                  <SearchOutlined />
                </Button>
              </Space>
            </Col>
          </Row>

          <ListTable
            current={this.state.currentPage}
            total={this.stationStore?.total}
            columns={this.columns}
            dataSource={this.stationStore?.StationListArray}
            loading={this.stationStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        <Modal
          bodyStyle={{ height: "645px" }}
          className="formShowModal"
          title={this.context.translate("_STATION_REGISTRATION_")}
          open={this.state.showStationReg}
          onCancel={() => this.setState({ showStationReg: false })}
          footer={null}
          width={665}
          centered
          // onOk={}
        >
          <Form
            ref={this.formRef}
            layout="vertical"
            name="companyFrm"
            onFinish={this.onStationRefFinish}
            initialValues={{ mapSetting: false, mapSetting2: false }}
            style={{ maxWidth: "100%", padding: 0 }}
            className="formClass"
          >
            <Row className="overflowFormInputs">
              <Col span={12} className="formColumnClass">
                <Form.Item
                  name="stationCode"
                  label={this.context.translate("_STATION_CODE_")}
                  rules={[{ required: true }]}
                >
                  <Input
                    onChange={(e) =>
                      this.validateNumber(e.target.value, "stationCode")
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="arabicName"
                  label={this.context.translate("_ARABIC_NAME_")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="englishName"
                  label={this.context.translate("_ENGLISH_NAME_")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="markLocation"
                  label={this.context.translate("_MARK_LOCATION_")}
                >
                  <Autocomplete
                    style={{
                      width: "100%",
                      height: "47px",
                      boxShadow: "none",
                      padding: "4px 11px",
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: "#d9d9d9",
                      borderRadius: "6px",
                    }}
                    apiKey={config.googleMapKey}
                    onPlaceSelected={async (place) => {
                      await this.setState({ addressMap: true });
                      await this.setState({
                        addressInfo: [
                          place.geometry.location.lat(),
                          place.geometry.location.lng(),
                        ],
                      });
                      console.log(place.geometry.location.lat());
                      this.formRef.current.setFieldsValue({
                        markLocation: place.formatted_address,
                        latitude: place.geometry.location.lat(),
                        longitude: place.geometry.location.lng(),
                      });
                    }}
                    placeholder={this.context.translate(
                      "_PLEASE_ENTER_LOCATION_"
                    )}
                  />
                </Form.Item>

                <Form.Item
                  name="latitude"
                  label={this.context.translate(
                    "_FLEET_DASHBORD_SETTING_LATITUDE_"
                  )}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="longitude"
                  label={this.context.translate(
                    "_FLEET_DASHBORD_SETTING_LONGITUDE_"
                  )}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              {/* <Col span={2}></Col> */}
              <Col span={12} className="formColumnClass">
                <Form.Item
                  name="cityCode"
                  label={this.context.translate("_CITY_CODE_")}
                  rules={[{ required: true }]}
                >
                  <Select placeholder={this.context.translate("_SELECT_TYPE_")}>
                    {this.stationStore?.cityCodeArray?.map((val) => {
                      return (
                        <Select.Option value={val.id}>{val.name}</Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="status"
                  label={this.context.translate("_STATUS_")}
                  rules={[{ required: true }]}
                  initialValue={"1"}
                >
                  <Select>
                    <Select.Option value="1">
                      {this.context.translate("_ACTIVE_")}
                    </Select.Option>
                    <Select.Option value="0">
                      {this.context.translate(
                        "_FLEET_USER_USERLIST_STATUS_INACTIVE_"
                      )}
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="emailAddress"
                  label={this.context.translate("_EMAIL_ID_")}
                  rules={[{ required: true, type: "email" }]}
                >
                  <Input />
                </Form.Item>

                <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      style={{ width: "30%" }}
                      name="countryCode"
                      // label="Contact 1"
                      label={" "}
                      // rules={[{ required: true }]}
                      rules={[{ required: true }]}
                      initialValue={+966}
                    >
                      <Select>
                        <Select.Option value={91}>+91</Select.Option>
                        <Select.Option value={966}>+966</Select.Option>
                        {/* 966 = saudi arabia */}
                        <Select.Option value={971}>+971</Select.Option>
                        {/* 971 = uae */}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      style={{ width: "65%" }}
                      name="phoneNo"
                      // label="Phone Number 1"
                      label={this.context.translate("_MOBILE_NUMBER_")}
                       rules={[
                      {
                        pattern: /^\d{8,10}$/,
                        required: true,
                        message: this.context.translate(
                          "_Enter_7_or_10_Digit_Number_"
                        ),
                      },
                    ]}
                      // rules={[{ required: true}]}
                    >
                      <Input
                        onChange={(e) =>
                          this.validateNumber(e.target.value,"mobilenumber")
                        }
                      />
                    </Form.Item>
                  </div>

                {/* <Form.Item
                  name="phoneNo"
                  label={this.context.translate(
                    "_FLEET_DASHBORD_NEWPOI_PHONE_"
                  )}
                  rules={[
                    {
                      required: true,
                      pattern: /^(\d{7}|\d{10})$/,
                      // message: "Enter 7 or 10 Digit Number",
                      message: this.context.translate(
                        "_Enter_7_or_10_Digit_Number_"
                      ),
                    },
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      this.validateNumber(e.target.value, "phoneNo")
                    }
                    maxLength={12}
                    minLength={5}
                  />
                </Form.Item> */}
                <Form.Item
                  name="extensionNo"
                  label={this.context.translate("_EXTENSION_NUMBER_")}
                  // rules={[{ required: true }]}
                >
                  <Input
                    onChange={(e) =>
                      this.validateNumber(e.target.value, "extensionNo")
                    }
                  />
                </Form.Item>


                {/* <Form.Item
                  name="managerPhone"
                  label={this.context.translate("_MANAGER_PHONE_")}
                  // rules={[{ required: true }]}
                  rules={[
                    {
                      required: true,
                      pattern: /^(\d{7}|\d{10})$/,
                      // message: "Enter 7 or 10 Digit Number",
                      message: this.context.translate(
                        "_Enter_7_or_10_Digit_Number_"
                      ),
                    },
                  ]}
                >
                  <Input
                    type="tel"
                    onChange={(e) =>
                      this.validateNumber(e.target.value, "managerPhone")
                    }
                    maxLength={12}
                    minLength={5}
                  />
                </Form.Item> */}

                  <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Form.Item
                        style={{ width: "30%" }}
                        name="managerPhoneCountryCode"
                        // label="Contact 1"
                        label={" "}
                        // rules={[{ required: true }]}
                        rules={[{ required: true }]}
                        initialValue={+966}
                      >
                        <Select>
                          <Select.Option value={91}>+91</Select.Option>
                          <Select.Option value={966}>+966</Select.Option>

                          <Select.Option value={971}>+971</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        style={{ width: "65%" }}
                        name="managerPhone"
                        // label="Phone Number 1"
                        label={this.context.translate("_MANAGER_PHONE_")}
                        rules={[
                          {
                            pattern: /^\d{8,10}$/,
                            required: true,
                            message: this.context.translate(
                              "_Enter_7_or_10_Digit_Number_"
                            ),
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            this.validateNumber(
                              e.target.value,
                              "managerPhone"
                            )
                          }
                          // maxLength={13}
                        />
                      </Form.Item>
                    </div>
              </Col>
            </Row>
            {/* <Row justify={"end"}> */}
            <div className="formBtnGroup">
              <Button
                type="primary"
                htmlType="submit"
                onClick={(val) => console.log(val)}
                className="formSaveButton"
              >
                {this.context.translate("_REGISTER_")}
              </Button>
            </div>
            {/* </Row> */}
          </Form>
        </Modal>

        <Modal
          className="multiTracking-popup"
          style={{ padding: "0px !important" }}
          title=<div>
            Location On Map
            <CloseOutlined
              onClick={() => this.setState({ addressMap: false })}
            />
          </div>
          maskClosable={false}
          centered
          bodyStyle={{ height: "60vh", padding: "0px" }} // Adjust the dimensions as needed
          width={1000}
          visible={this.state.addressMap}
          onCancel={() => this.setState({ addressMap: false })}
          footer={null}
        >
          <DisplayLocationMap
            addressInfo={
              this.state.addressInfo?.length > 0 && this.state.addressInfo
            }
            formRef={this.formRef}
            address={(address) =>
              this.formRef.current.setFieldsValue({ markLocation: address })
            }
          />
        </Modal>
      </>
    );
  }
}
StationList.contextType = StoreContext;
export default observer(withRouter(StationList));
