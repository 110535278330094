import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';

import { postApi,baseURL} from '../components/Utility'
import {
  action,
  
  makeObservable,
  observable,
 
} from 'mobx';


class EventReportStore {
  eventReport=[]
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        eventReport: observable,
      total:observable,
      loading:observable,
      loadData:action,
      exportExcel:action,
      exportpdf:action,
      exportcsv:action,
      exportxml:action
    });
     this.rootStore = rootStore;
    
  }

  async loadData({offset,limit,companyId,eventCode,fromDate,toDate,vehicleId}) {
    this.loading=true;
    console.log("event Report")
    var postData = {"companyId":companyId,"vehicleId":vehicleId,"eventCode":eventCode,"fromDate":fromDate,"toDate":toDate,"offset":offset,"limit":limit,"page":1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=eventreport", "postData="+base64Input);
    console.log("event " ,response?.data?.data)
   
    this.eventReport =response?.data?.data?.eventreport
  
     this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }

  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exporteventexcel&cid=" +
      getData.companyId +
    //   " &userId=" +
    //  getData.userId +
      "&vid=" +
      getData.vehicleId +
      // "&int=" +
      // getData.interval +
      "&eventCode=" +
      getData.eventCode +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exporteventpdf&cid=" +
      getData.companyId +
      // " &userId=" +
      // 1 +
      "&vid=" +
      getData.vehicleId +
      // "&int=" +
      // getData.interval +
      "&eventCode=" +
      getData.eventCode +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exporteventcsv&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      // "&int=" +
      // getData.interval +
      "&eventCode=" +
      getData.eventCode +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exportxml(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exporteventxml&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      // "&int=" +
      // getData.interval +
      "&eventCode=" +
      getData.eventCode +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  

  
  

}

export default EventReportStore;
