import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import React, { Component } from "react";
import withRouter from "../withRouter";
import Cookies from "js-cookie";

import { DownOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import TemperatureDashboardStore from "../store/TemperatureDashboardStore";
import { decode as base64_decode } from "base-64";
import dayjs from "dayjs";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { Link } from "react-router-dom";
const { Option } = Select;
class TemperatureDashboard extends Component {
  constructor(props, context) {
    super(props);

    this.handleLegendClick = this.handleLegendClick.bind(this);

    this.temperatureDashboardStore = new TemperatureDashboardStore(this);
    this.state = {
      offset: 0,
      limit: 50,
      update: 0,
      showSensorTable: false,
      type: 0,
      loadData: 0,
      showTempReportTable: false,
      showTempGraph: false,
      sensorsDetailsModal: false,
      vehiclesArray: [],
      vehicleId: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD 23:59:00"),
      showTemp1Legend: true,
      showTemp2Legend: true,
      showTemp3Legend: true,
      showTemp4Legend: true,
      temperatureArray: [],
      sensorsArray: [],
      notvehicle: false,
      // fromDate:"2024-01-03 00:00:00",
      // toDate:"2024-01-03 23:59:00",
      sensorTableColumns: [
        {
          // title: "Asset Name",
          title: context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"),
          dataIndex: "vehicleNo",
        },
        {
          // title: "Date/Time",
          title: context.translate("_FLEET_REPORTS_EVENTREPORT_DATETIME_"),
          dataIndex: "currtime",
        },
        {
          // title: "Location",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_LOCATION_"),
          dataIndex: "location",
        },
        {
          title: "Acc",
          dataIndex: "acc",
          render: (value) => {
            if (value == 1) {
              return <>ON</>;
            } else {
              return <>OFF</>;
            }
          },
        },
        {
          // title: "BLE Temp",
          title: context.translate("_BLE_TEMP_"),
          dataIndex: "status",
        },
        {
          // title: "BLE Humidity",
          title: context.translate("_BLE_HUMIDITY_"),
          dataIndex: "message",
        },
      ],

      tempTableColumns: [
        {
          // title: "Date/Time",
          title: context.translate("_FLEET_REPORTS_EVENTREPORT_DATETIME_"),
          dataIndex: "updatedate",
          width: 100,
        },
        {
          // title: "Vehicle No",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          width: 100,
        },
        {
          // title: "Driver",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
          dataIndex: "name",
          width: 100,
        },
        {
          // title: "Sensor 1",
          title: context.translate("_SENSOR1_"),
          dataIndex: "sensor1",
          width: 100,
          render: (text, record) => {
            const humidityValues = record.BLEHumidity
              ? record.BLEHumidity.split(", ")
              : "";
            let imgSrc;
            let humidityText;

            if (humidityValues[0] === "NA") {
              imgSrc = "thermometer-half-black.png";
              humidityText = "NA";
            } else {
              imgSrc = "thermometer-half-green.png";
              humidityText = humidityValues[0];
            }
            // const firstHumidityValue = humidityValues[0];

            const tempValue = record.BLETemp ? record.BLETemp.split(", ") : "";

            let tempImg;
            let tempText;

            if (tempValue[0] === "NA") {
              tempImg = "humidity.png";

              tempText = "NA";
            } else {
              tempImg = "humidity-green.png";
              tempText = tempValue[0];
            }

            // const firstTempValue = tempValue[0];
            return (
              <span>
                <img src={require("../assets/images/" + imgSrc)} />
                {tempText}% &nbsp;{" "}
                <img src={require("../assets/images/" + tempImg)} />
                {/* {tempText}% */}
                {humidityText}&deg;
              </span>
            );
          },
        },

        {
          // title: "Sensor 2",
          title: context.translate("_SENSOR2_"),
          width: 100,
          dataIndex: "sensor2",
          render: (text, record) => {
            const humidityValues = record.BLEHumidity
              ? record.BLEHumidity.split(", ")
              : "";
            let imgSrc;
            let humidityText;

            if (humidityValues[1] === "NA") {
              imgSrc = "thermometer-half-black.png";
              humidityText = "NA";
            } else {
              imgSrc = "thermometer-half-green.png";
              humidityText = humidityValues[1];
            }
            // const firstHumidityValue = humidityValues[0];

            const tempValue = record.BLETemp ? record.BLETemp.split(", ") : "";

            let tempImg;
            let tempText;

            if (tempValue[1] === "NA") {
              tempImg = "humidity.png";

              tempText = "NA";
            } else {
              tempImg = "humidity-green.png";
              tempText = tempValue[1];
            }

            // const firstTempValue = tempValue[0];
            return (
              <span>
                <img src={require("../assets/images/" + tempImg)} />
                {tempText}% &nbsp; {" "}
                <img src={require("../assets/images/" + imgSrc)} />
                {humidityText}&deg;
              </span>
            );
          },
        },
        {
          // title: "Sensor 3",
          title: context.translate("_SENSOR3_"),
          dataIndex: "sensor3",
          width: 100,
          render: (text, record) => {
            const humidityValues = record.BLEHumidity
              ? record.BLEHumidity.split(", ")
              : "";
            let imgSrc;
            let humidityText;

            if (humidityValues[2] === "NA") {
              imgSrc = "thermometer-half-black.png";
              humidityText = "NA";
            } else {
              imgSrc = "thermometer-half-green.png";
              humidityText = humidityValues[2];
            }
            // const firstHumidityValue = humidityValues[0];

            const tempValue = record.BLETemp ? record.BLETemp.split(", ") : "";

            let tempImg;
            let tempText;

            if (tempValue[2] === "NA") {
              tempImg = "humidity.png";

              tempText = "NA";
            } else {
              tempImg = "humidity-green.png";
              tempText = tempValue[2];
            }

            // const firstTempValue = tempValue[0];
            return (
              <span>
                <img src={require("../assets/images/" + imgSrc)} />
                {humidityText}&deg; &nbsp;{" "}
                <img src={require("../assets/images/" + tempImg)} />
                {tempText}%
              </span>
            );
          },
        },
        {
          title: context.translate("_SENSOR4_"),
          dataIndex: "sensor4",
          width: 100,
          render: (text, record) => {
            const humidityValues = record.BLEHumidity
              ? record.BLEHumidity.split(", ")
              : "";
            let imgSrc;
            let humidityText;

            if (humidityValues[3] === "NA") {
              imgSrc = "thermometer-half-black.png";
              humidityText = "NA";
            } else {
              imgSrc = "thermometer-half-green.png";
              humidityText = humidityValues[3];
            }
            // const firstHumidityValue = humidityValues[0];

            const tempValue = record.BLETemp ? record.BLETemp.split(", ") : "";

            let tempImg;
            let tempText;

            if (tempValue[3] === "NA") {
              tempImg = "humidity.png";

              tempText = "NA";
            } else {
              tempImg = "humidity-green.png";
              tempText = tempValue[3];
            }

            // const firstTempValue = tempValue[0];
            return (
              <span>
                <img src={require("../assets/images/" + tempImg)} />
                {tempText}% &nbsp;{" "}
                <img src={require("../assets/images/" + imgSrc)} />
                {humidityText}&deg;
              </span>
            );
          },
        },
        {
          // title: "Status",
          title: context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
          dataIndex: "status",
          width: 100,
        },
        {
          // title: "Speed",
          title: context.translate("_FLEET_DASHBORD_SETTING_SPEED_"),
          dataIndex: "speed",
          width: 100,
        },
        {
          // title: "Location",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_LOCATION_"),
          dataIndex: "location",
          width: 200,
        },
      ],

      tempGraphColumns: [
        {
          // title: "Vehicle No",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
        },
        {
          // title: "Location",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_LOCATION_"),
          dataIndex: "location",
        },
        {
          // title: "Date/Time",
          title: context.translate("_FLEET_REPORTS_EVENTREPORT_DATETIME_"),
          dataIndex: "timestamp",
        },
        {
          // title: "Speed",
          title: context.translate("_FLEET_REPORTS_EVENTREPOR_SPEED_"),
          dataIndex: "speed",
        },
        {
          // title: "Temperature",
          title: context.translate("_FLEET_DASHBORD_SETTING_TEMPERATURE_"),
          dataIndex: "message",
          render: (text, record) => {
            const humidityValues = record.status
              ? record.status.split(", ")
              : "";

            let imgSrc;
            let humidityText;
            let humidityValue1 = humidityValues[1];
            let humidityValue2 = humidityValues[2];
            let humidityValue3 = humidityValues[3];
            if (humidityValues[0] === "NA") {
              imgSrc = "thermometer-half-black.png";
              humidityText = " ";
            } else {
              imgSrc = "thermometer-half-green.png";
              humidityText = `${humidityValues[0]},`;
              if (humidityValues[1] === "NA") {
                humidityValue1 = "";
              } else {
                humidityValue1 = `${humidityValues[1]},`;
              }
              if (humidityValues[2] === "NA") {
                humidityValue2 = "";
              } else {
                humidityValue2 = `${humidityValues[2]},`;
              }
              if (humidityValues[3] === "NA") {
                humidityValue3 = "";
              } else {
                humidityValue3 = humidityValues[3];
              }
            }
            // const firstHumidityValue = humidityValues[0];

            const tempValue = record.message ? record.message.split(", ") : "";

            let tempImg;
            let tempText;
            let temp1 = tempValue[1];
            let temp2 = tempValue[2];
            let temp3 = tempValue[3];

            if (tempValue[0] === "NA") {
              tempImg = "humidity.png";

              tempText = "";
            } else {
              tempImg = "humidity-green.png";
              tempText = `${tempValue[0]},`;
              if (tempValue[1] === "NA") {
                temp1 = "";
              } else {
                temp1 = `${tempValue[1]},`;
              }
              if (tempValue[2] === "NA") {
                temp2 = "";
              } else {
                temp2 = `${tempValue[2]},`;
              }
              if (tempValue[3] === "NA") {
                temp3 = "";
              } else {
                temp3 = tempValue[3];
              }
            }

            // const firstTempValue = tempValue[0];
            return (
              <span>
                <img src={require("../assets/images/" + imgSrc)} />
                {humidityText}
                {humidityValue1}
                {humidityValue2}
                {humidityValue3}&deg;&nbsp;{" "}
                <img src={require("../assets/images/" + tempImg)} />
                {tempText}
                {temp1}
                {temp2}
                {temp2}%
              </span>
            );
          },
        },
      ],
    };
  }

  async componentDidMount() {
    await this.temperatureDashboardStore.getroutedata({
      companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
    });
    await this.temperatureDashboardStore.loadData({
      companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      offset: this.state.offset,
      limit: this.state.limit,
    });

    await this.setState({
      temperatureArray: this.temperatureDashboardStore?.temperatureArray,
      sensorsArray: this.temperatureDashboardStore?.temperatureArray,
    });

    await this.temperatureDashboardStore.getDeviceGroups({
      companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
    });

    await this.temperatureDashboardStore.loadVehiclesData({
      companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
    });

    await this.temperatureDashboardStore.loadSensorTableData({
      companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
    });

    // await this.setState({ update: 1 });
    this.columns = [
      {
        // title: "Vehicle No",
        title: this.context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
        dataIndex: "vehicleNo",
      },
      {
        // title: "Status",
        title: this.context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "status",
        render: (text, record) => {
          if (Number(record.acc) === 0 && Number(record.speed) === 0.0) {
            return <span style={{ color: "red" }}>Stopped</span>;
          } else if (Number(record.acc) === 1 && Number(record.speed) === 0.0) {
            return <span style={{ color: "orange" }}>Idle</span>;
          } else if (Number(record.acc) === 1 && Number(record.speed) >= 0) {
            return <span style={{ color: "green" }}>Running</span>;
          }
        },
      },

      {
        // title: "Sensor 1",
        title: this.context.translate("_SENSOR1_"),
        dataIndex: "sensor1",
        render: (text, record) => {
          const humidityValues = record.BLETemp
            ? record.BLETemp.split(", ")
            : "";
          let imgSrc;
          let humidityText;

          if (humidityValues[0] === "NA") {
            imgSrc = "thermometer-half-black.png";
            humidityText = "NA";
          } else {
            imgSrc = "thermometer-half-green.png";
            humidityText = humidityValues[0];
          }
          // const firstHumidityValue = humidityValues[0];

          const tempValue = record.BLEHumidity
            ? record.BLEHumidity.split(", ")
            : "";

          let tempImg;
          let tempText;

          if (tempValue[0] === "NA") {
            tempImg = "humidity.png";

            tempText = "NA";
          } else {
            tempImg = "humidity-green.png";
            tempText = tempValue[0];
          }

          // const firstTempValue = tempValue[0];
          return (
            <span>
              <img src={require("../assets/images/" + imgSrc)} />
              {humidityText}&deg; &nbsp;{" "}
              <img src={require("../assets/images/" + tempImg)} />
              {tempText}%
            </span>
          );
        },
      },

      {
        // title: "Sensor 2",
        title: this.context.translate("_SENSOR2_"),
        dataIndex: "sensor2",
        render: (text, record) => {
          const humidityValues = record.BLETemp
            ? record.BLETemp.split(", ")
            : "";
          let imgSrc;
          let humidityText;

          if (humidityValues[1] === "NA") {
            imgSrc = "thermometer-half-black.png";
            humidityText = "NA";
          } else {
            imgSrc = "thermometer-half-green.png";
            humidityText = humidityValues[1];
          }
          // const firstHumidityValue = humidityValues[0];

          const tempValue = record.BLEHumidity
            ? record.BLEHumidity.split(", ")
            : "";

          let tempImg;
          let tempText;

          if (tempValue[1] === "NA") {
            tempImg = "humidity.png";

            tempText = "NA";
          } else {
            tempImg = "humidity-green.png";
            tempText = tempValue[1];
          }

          // const firstTempValue = tempValue[0];
          return (
            <span>
              <img src={require("../assets/images/" + imgSrc)} />
              {humidityText}&deg; &nbsp;{" "}
              <img src={require("../assets/images/" + tempImg)} />
              {tempText}%
            </span>
          );
        },
      },
      {
        // title: "Sensor 3",
        title: this.context.translate("_SENSOR3_"),
        dataIndex: "sensor3",
        render: (text, record) => {
          const humidityValues = record.BLETemp
            ? record.BLETemp.split(", ")
            : "";
          let imgSrc;
          let humidityText;

          if (humidityValues[2] === "NA") {
            imgSrc = "thermometer-half-black.png";
            humidityText = "NA";
          } else {
            imgSrc = "thermometer-half-green.png";
            humidityText = humidityValues[2];
          }
          // const firstHumidityValue = humidityValues[0];

          const tempValue = record.BLEHumidity
            ? record.BLEHumidity.split(", ")
            : "";

          let tempImg;
          let tempText;

          if (tempValue[2] === "NA") {
            tempImg = "humidity.png";

            tempText = "NA";
          } else {
            tempImg = "humidity-green.png";
            tempText = tempValue[2];
          }

          // const firstTempValue = tempValue[0];
          return (
            <span>
              <img src={require("../assets/images/" + imgSrc)} />
              {humidityText}&deg;&nbsp;{" "}
              <img src={require("../assets/images/" + tempImg)} />
              {tempText}%
            </span>
          );
        },
      },
      {
        title: this.context.translate("_SENSOR4_"),
        dataIndex: "sensor4",
        render: (text, record) => {
          const humidityValues = record.BLETemp
            ? record.BLETemp.split(", ")
            : "";
          let imgSrc;
          let humidityText;

          if (humidityValues[3] === "NA") {
            imgSrc = "thermometer-half-black.png";
            humidityText = "NA";
          } else {
            imgSrc = "thermometer-half-green.png";
            humidityText = humidityValues[3];
          }
          // const firstHumidityValue = humidityValues[0];

          const tempValue = record.BLEHumidity
            ? record.BLEHumidity.split(", ")
            : "";

          let tempImg;
          let tempText;

          if (tempValue[3] === "NA") {
            tempImg = "humidity.png";

            tempText = "NA";
          } else {
            tempImg = "humidity-green.png";
            tempText = tempValue[3];
          }

          // const firstTempValue = tempValue[0];
          return (
            <span>
              <img src={require("../assets/images/" + imgSrc)} />
              {humidityText}&deg; &nbsp;{" "}
              <img src={require("../assets/images/" + tempImg)} />
              {tempText}%
            </span>
          );
        },
      },
    ];
    this.menuItmList = {
      items: [
        {
          // label: "Temperature Report",
          label: this.context.translate("_FLEET_REPORTS_TEMPREPORT_TITLE_"),
          key: "tempReport",
        },
        {
          // label: "Temperature Graph",
          label: this.context.translate("_TEMPERATURE_GRAPH_"),
          key: "tempGraph",
        },
      ],
      onClick: (val) => {
        if (val?.key == "tempReport") {
          this.setState({ showTempReportTable: true });
        }
        if (val?.key == "tempGraph") {
          this.setState({ showTempGraph: true });
        }
      },
    };

    await this.setState({ update: 1 });
  }

  loadSensorData = async () => {
    await this.temperatureDashboardStore.loadSensorTableData({
      companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      type: this.state.type,
    });

    await this.setState({ update: 1 });
  };

  showTemperatureReportData = async () => {
    await this.temperatureDashboardStore.temperatureReportData({
      companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
      vehicleId: this.state.vehicleId,
      offset: this.state.offset,
      limit: this.state.limit,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    });
    await this.setState({ update: 1 });
  };

  showTemperatureGraph = async () => {
    if (this.state.vehicleId) {
      this.setState({ notvehicle: false });
      await this.temperatureDashboardStore.loadTemperatureGraphData({
        companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
        vehicleId: this.state.vehicleId,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      });

      await this.setState({ update: 1 });
    } else {
      this.setState({ notvehicle: true });
    }
  };

  loadTemperatureTableData = async () => {
    await this.temperatureDashboardStore.loadTemperatureGraphData({
      companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
      vehicleId: this.state.vehicleId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    });
  };

  closeModal = () => {
    this.setState({
      showSensorTable: false,
      sensorsDetailsModal: false,
      showTempReportTable: false,
      showTempGraph: false,
    });
  };
  handleLegendClick = (legendItem, index) => {
    const dataKey = legendItem.dataKey;

    this.setState((prevState) => ({
      [dataKey]: !prevState[dataKey],
    }));
  };

  searchByVehicleNo = async (value) => {
    let inputData = value.toLowerCase();

    const data = this.temperatureDashboardStore.temperatureArray.filter(
      (vehicleNo) => {
        return vehicleNo.vehicleNo.toLowerCase().includes(inputData);
      }
    );

    await this.setState({ temperatureArray: data });

    // else{
    //   return(
    //     this.temperatureDashboardStore.temperatureArray
    //   )
    // }
  };

  searchByVehicleNoinsensors = async (value) => {
    let inputData = value.toLowerCase();

    const data = this.temperatureDashboardStore.temperatureArray.filter(
      (vehicleNo) => {
        return vehicleNo.vehicleNo.toLowerCase().includes(inputData);
      }
    );

    await this.setState({ sensorsArray: data });
  };

  CustomTooltip = ({ active, payload, label }) => {
    let res = "";
    payload.map((entry, index) => {
      let speed = parseFloat(entry.payload.speed);
      let acc = parseInt(entry.payload.acc);

      if (acc === 0 && speed === 0.0) {
        res = "(Stopped)";
      } else if (acc === 1 && speed === 0.0) {
        res = "(Idle)";
      } else if (acc === 1 && speed > 0) {
        res = "(Running)";
      }
    });

    if (active && payload) {
      // if (recordEntry.acc === "0" && recordEntry.speed === "0.00") {
      return (
        <div className="custom-tooltip">
          {/* <p>{`Time: ${label}`}</p> */}

          {payload.map((entry, index) => (
            <p
              key={`value-${index}`}
              style={{ background: "white", padding: "10px" }}
            >
              {`${this.context.translate("_TEMPERATURE_SENSOR_")} ${
                index + 1
              }: ${entry.value} ${res}`}
            </p>
          ))}
        </div>
      );
      // }
    }
    return null;
  };

  render() {
    const legendProps = {
      onClick: this.handleLegendClick,

      // onMouseEnter: () => this.setState({ hoveredLegendKey: null }),
      // onMouseLeave: () => this.setState({ hoveredLegendKey: null }),
    };
    const customLegendPayload = [
      {
        // value: "Temp Sensor1",
        value: this.context.translate("_SENSOR1_"),
        color: this.state.showTemp1Legend ? "blue" : "#d8d8d8", 
        dataKey: "showTemp1Legend",
      },
      {
        // value: "Temp Sensor1",
        value: this.context.translate("_SENSOR2_"),
        color: this.state.showTemp2Legend ? "green" : "#d8d8d8",
        dataKey: "showTemp2Legend",
      },
      {
        // value: "Temp Sensor1",
        value: this.context.translate("_SENSOR3_"),
        color: this.state.showTemp3Legend ? "red" : "#d8d8d8",
        dataKey: "showTemp3Legend",
      },
      {
        // value: "Temp Sensor1",
        value: this.context.translate("_SENSOR4_"),
        color:this.state.showTemp4Legend ? "orange" : "#d8d8d8",
        dataKey: "showTemp4Legend",
      },
    ];
    return (
      <div className="temperature-dashboard">
        <div style={{ padding: 0 }}>
          <Row
            className="headerFixed listInputRow"
            style={{ height: "50px", margin: "0px" }}
          >
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_TEMPERATURE_DASHBOARD_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                <Link to="/livetracking">
                  {" "}
                  <Button>{this.context.translate("_HOME_")}</Button>{" "}
                </Link>
                <Button
                  onClick={() => {
                    this.setState({ sensorsDetailsModal: true });
                  }}
                >
                  {this.context.translate("_SENSOR_")}
                </Button>

                <Dropdown menu={this.menuItmList}>
                  <Button>
                    <Space>
                      {/* Reports */}
                      {this.context.translate("_FLEET_REPORTS_TAB_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>

          <Modal
            title={
              <div className="modal-topbar">
                <h1> Sensors</h1>{" "}
                <div className="right-align">
                  {" "}
                  <Input
                    placeholder={this.context.translate("_SEARCH_BY_VEHICLE_")}
                    style={{ width: 200 }}
                    onChange={(e) =>
                      this.searchByVehicleNoinsensors(e.target.value)
                    }
                  />
                  <i
                    class="modal-close las la-times"
                    onClick={this.closeModal}
                  ></i>
                </div>
              </div>
            }
            className="black-modal-window"
            open={this.state.sensorsDetailsModal}
            footer={null}
            centered
            width="95%"
            // icon={}
            onCancel={this.closeModal}
            bodyStyle={{ height: "60vh", overflowY: "auto" }}
            // className="custom-modal"
          >
            {/* <Modal
            open={this.state.sensorsDetailsModal}
            footer={null}
            centered
            width={1000}
            onCancel={() => this.setState({ sensorsDetailsModal: false })}
          > */}
            {/* <div className="heading">
              Sensors
             
            </div>
            <Input
              placeholder="Search By Vehicle"
              style={{ width: 200, height: 40 }}
            /> */}

            <Row
              className="sensors-container"
              bodyStyle={{ overflowY: "scroll", height: "600px" }}
            >
              {/* {this.temperatureDashboardStore?.temperatureArray.map( */}
              {this.state.sensorsArray?.map((val, index) => (
                <Col className="sensors-box" key={index}>
                  <Card>
                    {/* <img
                        src={require("../assets/images/thermometer-half.png")}
                        alt="Thermometer"
                      /> */}
                    {/* <Image
                        src={require("../assets/images/thermometer-half.png")}
                        style={{
                          fontSize: "40px",
                          color: "black",
                          background: "black",
                          margin: "5px",
                        }}
                      />
                      <p>Vehicle No: {val.vehicleNo}</p> */}

                    <div class="sensors-info">
                      <div class="icon">
                        <img
                          src={require("../assets/images/thermometer-half.png")}
                          // style={{
                          //   fontSize: "40px",
                          //   color: "black",
                          //   background: "black",
                          //   margin: "5px",
                          // }}
                        />
                      </div>
                      <div class="vehicle-details">
                        <b>Vehicle No:</b>
                        <small>{val.vehicleNo}</small>
                      </div>
                    </div>
                    <div class="btns-block">
                      <div class="btn">
                        <b>SR.1:</b>
                        <p>
                          <small>
                            {val?.BLETemp ? val?.BLETemp?.split(", ")[0] : ""}
                            &deg;
                          </small>
                          <small>
                            {val?.BLEHumidity
                              ? val?.BLEHumidity?.split(", ")[0]
                              : ""}
                            %
                          </small>
                        </p>
                      </div>
                      <div class="btn">
                        <b>SR.2:</b>
                        <p>
                          <small>
                            {val?.BLETemp ? val?.BLETemp?.split(", ")[1] : ""}
                            &deg;
                          </small>
                          <small>
                            {val?.BLEHumidity
                              ? val?.BLEHumidity?.split(", ")[1]
                              : ""}
                            %
                          </small>
                        </p>
                      </div>
                      <div class="btn">
                        <b>SR.3:</b>
                        <p>
                          <small>
                            {val?.BLETemp ? val?.BLETemp?.split(", ")[2] : ""}
                            &deg;
                          </small>
                          <small>
                            {val?.BLEHumidity
                              ? val?.BLEHumidity.split(", ")[2]
                              : ""}
                            %
                          </small>
                        </p>
                      </div>
                      <div class="btn">
                        <b>SR.4:</b>
                        <p>
                          <small>
                            {val?.BLEHumidity
                              ? val?.BLEHumidity.split(", ")[3]
                              : ""}
                            &deg;
                          </small>
                          <small>
                            {val?.BLETemp ? val?.BLETemp.split(", ")[3] : ""}%
                          </small>
                        </p>
                      </div>
                    </div>
                    {/* <Space>
                        <div>
                          <div>SR.1:</div>
                          <span>{val.BLEHumidity.split(", ")[0]}&deg;</span>
                          <span>{val.BLETemp.split(", ")[0]}%</span>
                        </div>

                        <div>
                          <div>SR.2:</div>
                          <span>{val.BLEHumidity.split(", ")[1]}&deg;</span>
                          <span>{val.BLETemp.split(", ")[1]}%</span>
                        </div>

                        <div>
                          <div>SR.3:</div>
                          <span>{val.BLEHumidity.split(", ")[2]}&deg;</span>
                          <span>{val.BLETemp.split(", ")[2]}%</span>
                        </div>

                        <div>
                          <div>SR.4:</div>
                          <span>{val.BLEHumidity.split(", ")[3]}&deg;</span>
                          <span>{val.BLETemp.split(", ")[3]}%</span>
                        </div>
                      </Space> */}
                  </Card>
                </Col>
              ))}
            </Row>
          </Modal>

          <Modal
            title={
              <div className="modal-topbar">
                <h1>
                  {this.context.translate("_FLEET_REPORTS_TEMPREPORT_TITLE_")}
                </h1>{" "}
                <div className="right-align">
                  {" "}
                  <i
                    class="modal-close las la-times"
                    onClick={this.closeModal}
                  ></i>
                </div>
              </div>
            }
            className="black-modal-window"
            open={this.state.showTempReportTable}
            footer={null}
            centered
            width="90%"
            onCancel={this.closeModal}
          >
            <div className="temp-report-container">
              <div className="form-row">
                <Select
                  className="sensors-field"
                  fieldNames={{
                    label: "text",
                    value: "deviceGroupId",
                  }}
                  placeholder={this.context.translate("_SELECT_GROUP_")}
                  optionFilterProp="children"
                  options={this.temperatureDashboardStore?.deviceDataArray}
                  // onChange={(val)=>this.setState({vehiclesArray:[]})}
                  onSelect={async (val, data) => {
                    await this.temperatureDashboardStore.getSubGroups({
                      companyId: JSON.parse(base64_decode(Cookies.get("data")))
                        .userCompanyId,
                      userId: JSON.parse(base64_decode(Cookies.get("data")))
                        .userId,
                      isadmin: JSON.parse(base64_decode(Cookies.get("data")))
                        .isadmin,
                    });
                    this.setState({
                      vehiclesArray: data.children,
                      loadData: 1,
                    });
                  }}

                  // style={assetInputStyle}
                />

                <Select
                  className="sensors-field"
                  fieldNames={{
                    label: "groupname",
                    value: "deviceGroupId",
                  }}
                  placeholder={this.context.translate("_SELECT_SUB_GROUP_")}
                  optionFilterProp="children"
                  options={
                    this.temperatureDashboardStore?.getVehiclesUsingCompanyId
                  }

                  // style={assetInputStyle}
                />

                <Select
                  className="sensors-field"
                  showSearch
                  placeholder={this.context.translate("_SELECT_VEHICLE_")}
                  optionFilterProp="children"
                  onSelect={(val) => {
                    this.setState({ vehicleId: val });
                  }}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.temperatureDashboardStore?.vehiclesDataArray?.map(
                    (val) => {
                      return (
                        <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                      );
                    }
                  )}
                </Select>

                {/* <Select
                      fieldNames={{
                        label: "text",
                        value: "vehicleId",
                      }}
                      placeholder="Select Vehicle"
                      optionFilterProp="children"
                      // options={
                      //   this.temperatureDashboardStore
                      //     ?.getVehiclesUsingCompanyId
                      // }
                      options={this.state.vehiclesArray}
                      onSelect={(val) => {
                        console.log(val);
                        this.setState({ vehicleId: val });
                      }}
                      style={{ width: 150 }}

                      // style={assetInputStyle}
                    /> */}
              </div>
              <div className="form-row">
                <DatePicker
                  className="sensors-field"
                  defaultValue={dayjs()}
                  format="MM-DD-YYYY"
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  onChange={(date) => {
                    if (date === null) {
                      this.setState({ fromDate: "" });
                    } else {
                      const format = "YYYY-MM-DD 00:00:00";
                      this.setState({ fromDate: date.format(format) });
                      // console.log(date.format("YYYY-MM-DD"));
                    }
                  }}
                />
                <DatePicker
                  className="sensors-field"
                  defaultValue={dayjs()}
                  format="MM-DD-YYYY"
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  onChange={(date) => {
                    if (date === null) {
                      this.setState({ toDate: "" });
                    } else {
                      const format = "YYYY-MM-DD 23:59:00";
                      this.setState({ toDate: date.format(format) });
                      // console.log(date.format("YYYY-MM-DD"));
                    }
                  }}
                />
              </div>
              <div className="btn-row">
                <Button
                  type="primary"
                  onClick={async (pagination) => {
                    this.showTemperatureReportData();
                  }}
                >
                  {this.context.translate("_SEARCH_")}
                </Button>
              </div>

              <div className="sensors-col-wrapper">
                <div className="sensors-col">
                  <div className="lbl">
                    {this.context.translate("_SENSOR1_")}:
                  </div>

                  <Space>
                    <Input placeholder={this.context.translate("_MINIMUM_")} />
                    <Input placeholder={this.context.translate("_MAXIMUM_")} />
                  </Space>
                </div>

                <div className="sensors-col">
                  <div className="lbl">
                    {this.context.translate("_SENSOR2_")}:
                  </div>
                  <Space>
                    <Input placeholder={this.context.translate("_MINIMUM_")} />
                    <Input placeholder={this.context.translate("_MAXIMUM_")} />
                  </Space>
                </div>
                <div className="sensors-col">
                  <div className="lbl">
                    {this.context.translate("_SENSOR3_")}:
                  </div>
                  <Space>
                    <Input placeholder={this.context.translate("_MINIMUM_")} />
                    <Input placeholder={this.context.translate("_MAXIMUM_")} />
                  </Space>
                </div>

                <div className="sensors-col">
                  <div className="lbl">
                    {this.context.translate("_SENSOR4_")}:
                  </div>
                  <Space>
                    <Input placeholder={this.context.translate("_MINIMUM_")} />
                    <Input placeholder={this.context.translate("_MAXIMUM_")} />
                  </Space>
                </div>
              </div>

              <Table
                bordered
                // current={this.state.currentPage}
                total={this.temperatureDashboardStore?.total}
                columns={this.state.tempTableColumns}
                dataSource={
                  this.temperatureDashboardStore?.temperatureReportArray
                }
                // loading={this.temperatureDashboardStore?.loading}
                pagination={false}
                scroll={{
                  // x: 1500,
                  y: 150,
                }}
              />
            </div>
          </Modal>

          <Modal
            title={
              <div className="modal-topbar">
                <h1>{this.context.translate("_TEMPERATURE_GRAPH_")}</h1>{" "}
                <div className="right-align">
                  {" "}
                  <Button
                    type="primary"
                    onClick={async (pagination) => {
                      this.showTemperatureGraph();
                      this.loadTemperatureTableData();
                    }}
                  >
                    {/* Show */}
                    {this.context.translate("_FLEET_SHOW_BTN_")}
                  </Button>
                  <i
                    class="modal-close las la-times"
                    onClick={this.closeModal}
                  ></i>
                </div>
              </div>
            }
            className="black-modal-window"
            open={this.state.showTempGraph}
            footer={null}
            centered
            width="90%"
            onCancel={this.closeModal}
            bodyStyle={{ maxHeight: "60vh", overflowY: "auto" }}
          >
            <div className="temp-report-container">
              <div className="form-row">
                <Select
                  className="sensors-field"
                  fieldNames={{
                    label: "vehicleNo",
                    value: "vehicleId",
                  }}
                  placeholder={this.context.translate("_SELECT_VEHICLE_")}
                  optionFilterProp="children"
                  options={this.temperatureDashboardStore?.vehiclesDataArray}
                  onSelect={(val) => {
                    this.setState({ vehicleId: val });
                  }}
                  style={{
                    border: this.state.notvehicle ? "2px solid red" : "",
                  }}

                  // style={assetInputStyle}
                />

                <DatePicker
                  className="sensors-field"
                  defaultValue={dayjs()}
                  format="MM-DD-YYYY"
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  onChange={(date) => {
                    if (date === null) {
                      this.setState({ fromDate: "" });
                    } else {
                      const format = "YYYY-MM-DD 00:00:00";
                      this.setState({ fromDate: date.format(format) });
                    }
                  }}
                />
                <DatePicker
                  className="sensors-field"
                  defaultValue={dayjs()}
                  format="MM-DD-YYYY"
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  onChange={(date) => {
                    if (date === null) {
                      this.setState({ toDate: "" });
                    } else {
                      const format = "YYYY-MM-DD 23:59:00";
                      this.setState({ toDate: date.format(format) });
                    }
                  }}
                />
              </div>
              <div style={{ marginBottom: "50px" }}>
                <ResponsiveContainer height={400}>
                  <LineChart
                    width={500}
                    data={this.temperatureDashboardStore?.temperatureGraphArray}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 50,
                    }}
                  >
                    {/* <CartesianGrid strokeDasharray="2 2" /> */}
                    <XAxis
                      dataKey="timestamp"
                      // maxHeight={100}
                      angle={-90}
                      // interval={0}
                      textAnchor="end"
                      label={{ value: "Time", position: "bottom" }}
                      height={150}
                    />

                    <YAxis
                      label={{
                        value: "Temprature",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Tooltip  cursor={false} content={<this.CustomTooltip />} />
                    {
                      <Legend
                        verticalAlign="top"
                        {...legendProps}
                        payload={customLegendPayload}
                      />
                    }

                    <Line
                      type="monotone"
                      dataKey="temp1"
                      stroke="blue"
                      hide={!this.state.showTemp1Legend}
                    />
                    <Line
                      type="monotone"
                      dataKey="temp2"
                      stroke="green"
                      hide={!this.state.showTemp2Legend}
                    />
                    <Line
                      // type="monotone"
                      dataKey="temp3"
                      stroke="red"
                      hide={!this.state.showTemp3Legend}
                    />
                    <Line
                      // type="monotone"
                      dataKey="temp4"
                      stroke="orange"
                      hide={!this.state.showTemp4Legend}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>

              <Table
                bordered
                // current={this.state.currentPage}
                total={this.temperatureDashboardStore?.total}
                columns={this.state.tempGraphColumns}
                dataSource={
                  this.temperatureDashboardStore?.temperatureTableArray
                }
                // loading={this.temperatureDashboardStore?.loading}
                pagination={false}
                scroll={{
                  // x: 1500,
                  y: 150,
                }}
              />
            </div>
          </Modal>

          <Row>
            <Col span={16}>
              <div style={{ display: "flex" }}>
                <Row className="temp-top-box">
                  <Col span={4}>
                    <Image
                      src={require("../assets/images/vehicle_temp_dashboard.png")}
                    />
                  </Col>
                  <Col span={20}>
                    <div style={{ marginInlineStart: "10px" }}>
                      <b>
                        <span>{this.context.translate("_VEHICLES_")}</span>
                        <span>
                          {
                            this.temperatureDashboardStore?.dataarray
                              ?.TotalVehCount
                          }
                        </span>
                      </b>
                      <p>
                        <span>{this.context.translate("_ACTIVE_")}</span>
                        <span>
                          {
                            this.temperatureDashboardStore?.dataarray
                              ?.TotalVehCount
                          }
                        </span>
                      </p>
                      <p>
                        <span>{this.context.translate("_IN_ACTIVE_")}</span>
                        <span>0</span>
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row
                  className="temp-top-box"
                  onClick={() => {
                    this.loadSensorData();
                    this.setState({ showSensorTable: true, loadData: 1 });
                  }}
                >
                  <Col span={4}>
                    <Image
                      src={require("../assets/images/sensor_temp_dashboard.png")}
                    />
                  </Col>
                  <Col span={20}>
                    <div style={{ marginInlineStart: "10px" }}>
                      <b>
                        <span>{this.context.translate("_SENSORS_")}</span>
                        <span>
                          {
                            this.temperatureDashboardStore?.dataarray
                              ?.activeSensor
                          }
                        </span>
                      </b>
                      <p>
                        <span>{this.context.translate("_ACTIVE_")}</span>
                        <span>
                          {
                            // this.temperatureDashboardStore?.sensorDataArray
                            //   ?.length
                            this.temperatureDashboardStore?.dataarray
                              ?.activeSensor
                          }
                        </span>
                      </p>
                      <p>
                        <span>{this.context.translate("_FAULTY_")}</span>
                        <span>
                          {
                            // this.temperatureDashboardStore?.sensorDataArray
                            //   ?.length
                            this.temperatureDashboardStore?.dataarray
                              ?.faultySensor
                          }
                        </span>

                        {/* <span>0</span> */}
                      </p>
                    </div>
                  </Col>
                </Row>

                <Modal
                  title=<div className="modal-topbar">
                    <h1>{this.context.translate("_SENSOR_DETAILS_")}</h1>{" "}
                    <div className="right-align">
                      {" "}
                      <Select
                        style={{ width: 150 }}
                        onSelect={async (type) => {
                          this.setState({ type: type });

                          await this.temperatureDashboardStore.loadSensorTableData(
                            {
                              companyId: JSON.parse(
                                base64_decode(Cookies.get("data"))
                              ).userCompanyId,
                              userId: JSON.parse(
                                base64_decode(Cookies.get("data"))
                              ).userId,
                              isadmin: JSON.parse(
                                base64_decode(Cookies.get("data"))
                              ).isadmin,
                              type: type,
                            }
                          );
                          await this.setState({ update: 1 });
                        }}
                        placeholder={this.context.translate("_SENSORS_")}
                      >
                        <Select.Option value={1}>
                          {this.context.translate("_ACTIVE_")}
                        </Select.Option>
                        <Select.Option value={2}>
                          {this.context.translate("_FAULTY_")}
                        </Select.Option>
                      </Select>{" "}
                      <i
                        class="modal-close las la-times"
                        onClick={this.closeModal}
                      ></i>
                    </div>
                  </div>
                  className="black-modal-window"
                  open={this.state.showSensorTable}
                  footer={null}
                  centered
                  width="85%"
                  // icon={}
                  onCancel={this.closeModal}

                  // className="custom-modal"
                >
                  {/* <div className="heading" style={{ color: 'white' }}>
                    Sensor Details
              
                  </div> */}
                  <Row style={{ padding: "10px 0px" }}>
                    <Col span={22}>
                      <Space>
                        {/* {this.state.radioButtonGroup()} */}

                        {/* <Select
                          style={{ width: 150 }}
                          onSelect={async (type) => {
                            this.setState({ type: type });
                            console.log(type);
                            await this.temperatureDashboardStore.loadSensorTableData(
                              {
                                companyId: JSON.parse(
                                  base64_decode(Cookies.get("data"))
                                ).userCompanyId,
                                userId: JSON.parse(
                                  base64_decode(Cookies.get("data"))
                                ).userId,
                                isadmin: JSON.parse(
                                  base64_decode(Cookies.get("data"))
                                ).isadmin,
                                type: type,
                              }
                            );
                            await this.setState({ update: 1 });
                          }}
                          placeholder="Sensors"
                        >
                          <Select.Option value={1}>Active</Select.Option>
                          <Select.Option value={2}>Faulty</Select.Option>
                        </Select> */}
                      </Space>
                    </Col>
                  </Row>
                  <div style={{ height: "400px" }}>
                    <Table
                      bordered
                      // current={this.state.currentPage}
                      total={this.temperatureDashboardStore?.total}
                      columns={this.state.sensorTableColumns}
                      dataSource={
                        this.temperatureDashboardStore?.sensorDataArray
                      }
                      // loading={this.temperatureDashboardStore?.loading}
                      pagination={false}
                      scroll={{
                        // x: 1500,
                        y: 360,
                      }}
                    />
                  </div>
                </Modal>
              </div>

              <div className="vehicle-details-panel">
                <div className="listInputRow" style={{ margin: "20px" }}>
                  {/* <b>Vehicle Details</b> */}
                  <b>{this.context.translate("_VEHICLE_DETAILS_")}</b>
                  <Input
                    style={{ width: "150px", height: "38px" }}
                    placeholder={this.context.translate("_SEARCH_BY_VEHICLE_")}
                    onChange={(e) => this.searchByVehicleNo(e.target.value)}
                  />
                </div>
                <div>
                  <Table
                    bordered
                    style={{ padding: "0px 13px 0px 13px", height: "410px" }}
                    // currentPage={this.state.currentPage}
                    // pageSize={this.state.pageSize}
                    // total={this.WarehouseMonitoringStore?.total}
                    columns={this.columns}
                    dataSource={this.state.temperatureArray}
                    pagination={false}
                    // style={{  }}
                    scroll={{
                      y: 370,
                    }}
                    // size="small"
                    // loading={this.WarehouseMonitoringStore.loading}
                    // onChange={this.handleTableChange}
                    // pagination={{ currentPage: this.state.currentPage }}
                  />
                </div>
              </div>
            </Col>
            <Col span={8}>
              <Row className="temp-top-box">
                <Col span={4}>
                  <Image
                    src={require("../assets/images/temprature_icon_temp_dashboard.png")}
                  />
                </Col>
                <Col span={20}>
                  {/* <b>
                    <span>Vehicle</span>
                    <span>0</span>
                  </b> */}

                  <p>
                    <span>{this.context.translate("_MIN_TEMP_ALERT_")}</span>
                    <span>0</span>
                  </p>
                  <p>
                    <span>{this.context.translate("_MAX_TEMP_ALERT_")}</span>
                    <span>0</span>
                  </p>
                </Col>
              </Row>
              <div className="temperature-alert-panel">
                <h6 style={{ marginInlineStart: "20px" }}>
                  <b>{this.context.translate("_TEMPERATURE_ALERTS_")}</b>
                </h6>
                <div className="alert-block">
                  <p>{this.context.translate("_NO_ALERTS_GENERATED_")}</p>
                  <p>
                    {/* All temperature and humidity range violation alerts will be
                    reflected here. */}
                    {this.context.translate("_RANGE_VIOLATION_ALERTS_")}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
TemperatureDashboard.contextType = StoreContext;
export default observer(withRouter(TemperatureDashboard));
