import React, { Component } from "react";

import {
  Form,
  Button,
  Input,
  Checkbox,
  Row,
  Col,
  Spin,
  Table,
  Tooltip,
  Space,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import VehicleGroupStore from "../store/VehicleGroupStore";
import withRouter from "../withRouter";

import CompanyAutocomplete from "./CompanyAutocomplete";

import CompanyStore from "../store/CompanyStore";
import { DeleteFilled } from "@ant-design/icons";

import config from "../config";

class VehicleGroupAdd extends Component {
  formRef = React.createRef();

  constructor(props, context) {
    super(props);
    this.state = {
      loading: false,
      showAsset: false,
      selectedChecked: false,
      selectedValues: [],
      deviceGroupId: "",
      companyId: "",

      loadingSpin: false,
      assignVehiclesArray: [],
      selectedAssignedVehicleId: [],
      searchVehicleArray: [],
      selectedSearchVehicleId: [],
    };
    this.groupStore = new VehicleGroupStore();
    this.companyStore = new CompanyStore();

    this.columns = [
      {
        // title: context.translate("_VEHICLE_"),
        title: "Company Vehicles",
        dataIndex: "vehicleNo",
      },
    ];

    this.assignedColumns = [
      {
        title: "Group Vehicles",
        dataIndex: "vehicleNo",
      },
      {
        title: "Remove",
        dataIndex: "action",
        render: (text, record, index) => {
          // console.log(text, record, index);
          return (
            <>
              <Space>
                <Tooltip
                  // title="Remove"
                  title={this.context.translate("_REMOVE_")}
                  color={config.tooltipColorDelete}
                >
                  <DeleteFilled
                    style={{ color: config.deleteColor }}
                    onClick={async () => {
                      await this.onDeleteRow(index, Number(record.vehicleId));
                    }}
                  />
                </Tooltip>
              </Space>
            </>
          );
        },
      },
    ];
  }

  onDeleteRow = (index) => {
    const filterData = this.state.assignVehiclesArray.filter(
      (val, indexx) => indexx !== index
    );

    const selectedAssignedVehicleId = filterData.map((item) => item.vehicleId);
    const selectedSearchVehicleId = this.state.selectedSearchVehicleId.filter(
      (id) => selectedAssignedVehicleId.includes(id)
    );

    this.setState({
      assignVehiclesArray: filterData,
      selectedAssignedVehicleId: selectedAssignedVehicleId,
      selectedSearchVehicleId: selectedSearchVehicleId,
    });
  };

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {

      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ isadmin: this.props.getUserData().isadmin });
      const data = await this.groupStore.getAssetData(
        this.props.getUserData().userCompanyId
      );
      console.log(data);
      await this.setState({ update: 1 });
    }
    const deviceGroupId = this.props.vehicleGroupId;

    console.log(deviceGroupId);
    // const companyId=this.props.companyId
    // console.log(companyId)
    this.setState({ searchVehicleArray: this.groupStore.assetList });


    if (deviceGroupId) {
      await this.filldata(deviceGroupId);
      await this.setState({ deviceGroupId: deviceGroupId });
    }

    // console.log(this.groupStore.assetList)
  }

  filldata = async (deviceGroupId) => {
    await this.setState({ loadingSpin: true });
    const getVehicleGroup = await this.groupStore.getVehicleGroupData(
      deviceGroupId,
      this.props.companyId
    );

    const allVehicles = await this.groupStore.getAssetData(
      this.props.companyId
    );

    const singleData = await this.companyStore.getCompanyDataById(
      this.props.companyId
    );

    await this.setState({
      companyId: getVehicleGroup.companyId,
      deviceGroupId: getVehicleGroup.deviceGroupId,
    });

    await this.setState({ searchVehicleArray: this.groupStore.assetList });

    // const vehicles = await this.groupStore.vehicleArray;
    // console.log(vehicles);

    const vehicleData =
      getVehicleGroup.vehicles?.map((val) => val.vehicleId) || [];
   
    const assignedVehiclesArray = allVehicles.filter((vehicle) =>
      vehicleData.includes(vehicle.vehicleId)
    );

    // this.setState({assignVehiclesArray:assignedVehiclesArray})

    await this.setState({
      assignVehiclesArray: assignedVehiclesArray,
      // selectedSearchVehicleId: [],
      selectedAssignedVehicleId: vehicleData,
    });

    await this.formRef.current.setFieldsValue({
      deviceGroupId: getVehicleGroup.deviceGroupId,
      companyId: singleData,
      groupname: getVehicleGroup.groupname,
      allCheck: getVehicleGroup.allCheck ? 1 : 0,
      vehicle: vehicleData,
    });
    await this.setState({ loadingSpin: false });
  };
  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  onDeviceDetailsSubmit = async (values) => {
    this.setState({ loading: true });

    const selectedData = this.state.assignVehiclesArray.map((val) => {
      return val.vehicleId;
    });
    console.log(selectedData);

    const data = {
      ...values,
      companyId: this.state.companyId,
      devicegroupId: this.state.deviceGroupId,
      deviceGroupparentId: 0,
      // devicegroupId: "",
      tokenId: "",
      vehicle: selectedData,
    };
    console.log(data);
    const resp = await this.groupStore.addData(data);
    await this.props.response(resp);
    // if (resp.data.success == "S") {
    //   message.success(resp.data.message);
    //   this.props.navigate("/vehiclegrouplist");
    // } else if (resp.data.success == "F0") {
    //   resp.data.errors.map((value) => {
    //     message.error(value["username"]);
    //   });
    // }
    this.setState({ loading: false });
  };

  async handleChange(comp) {
    console.log(comp.value);
    await this.setState({ companyId: comp.value });
    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });

    const data = await this.groupStore.getAssetData(this.state.companyId);
    console.log("data", data);

    this.setState({ searchVehicleArray: this.groupStore.assetList });
    await this.handleSearch();
  }

  async handleSearch() {
    await this.groupStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  handleSelectAllChange = async (e) => {
    this.setState({ selectedChecked: e.target.checked });

    if (e.target.checked) {
      const allValues = this.groupStore?.assetList.map(
        (option) => option.vehicleId
      );
      this.formRef.current.setFieldsValue({ vehicle: allValues.vehicleNo });

      await this.setState({ selectedValues: allValues });

      console.log(allValues);
    } else {
      await this.setState({ selectedChecked: e.target.checked });
      await this.setState({ selectedValues: [] });
    }
  };

  handleCheckboxChange = async (checkedValues) => {
    if (checkedValues?.length === this.groupStore.assetList.length) {
      console.log("hoo");
      await this.setState({ selectedChecked: true });
    } else {
      await this.setState({ selectedChecked: false });
    }
    await this.setState({ selectedValues: checkedValues });

    console.log(this.state.selectedValues);
  };

  closeModal = () => {
    this.props.closeModal();
  };

  onSearchVehicleNo = async (value) => {
    let inputData = value.toLowerCase();

    const data = this.groupStore.assetList.filter((vehicleNo) => {
      return vehicleNo.vehicleNo.toLowerCase().includes(inputData);
    });

    await this.setState({ searchVehicleArray: data });
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              layout="vertical"
              ref={this.formRef}
              onFinish={this.onDeviceDetailsSubmit}
              // {...this.layout}
              name="nest-message"
              className="formClass" //change
            >
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {/* css class */}
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => this.handleChange(value)}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="allCheck"
                    // className="formCheckboxCss"
                    label=" "
                    valuePropName="checked"
                    style={{ marginBottom: "22px" }}
                  >
                    <Checkbox>
                      {this.context.translate("_ASSIGN_ALL_VEHICLES_")}
                    </Checkbox>
                  </Form.Item>

                  <Input
                    placeholder={this.context.translate("_SEARCH_VEHICLE_")}
                    style={{ marginBottom: "10px" }}
                    onChange={(e) => {
                      this.onSearchVehicleNo(e.target.value);
                      this.setState({ searchInputData: e.target.value });
                    }}
                  />
                  <Table
                    bordered
                    columns={this.columns}
                    dataSource={this.state.searchVehicleArray}
                    pagination={false}
                    rowKey={"vehicleId"}
                    scroll={{
                      x: 100,
                      y: 350,
                    }}
                    rowSelection={{
                      selectedRowKeys: this.state.selectedAssignedVehicleId,
                      onChange: async (selectedRowKeys, selectedRows) => {
                        await this.setState({
                          assignVehiclesArray: selectedRows,

                          selectedAssignedVehicleId: selectedRowKeys,
                        });
                      },
                    }}
                  />
                </Col>
                <Col span={12} className="formColumnClass">
                  {/* css class */}

                  <Form.Item
                    name="groupname"
                    label={this.context.translate(
                      "_FLEET_GROUP_GROUPDETAIL_DEVICEGROUP_"
                    )}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <br />

                  <Table
                    bordered
                    columns={this.assignedColumns}
                    dataSource={this.state.assignVehiclesArray}
                    pagination={false}
                    rowKey={"vehicleId"}
                    scroll={{
                      x: 100,
                      y: 350,
                    }}
                    rowSelection={{
                      selectedRowKeys: this.state.selectedSearchVehicleId,
                      onChange: async (selectedRowKeys, selectedRows) => {
                        console.log(selectedRowKeys, selectedRows);
                        // await this.setState({})
                        await this.setState({
                          selectedSearchVehicleId: selectedRowKeys,
                        });
                      },
                    }}
                  />
                </Col>
              </Row>
              <div className="formBtnGroup">
                <Button
                  type="primary"
                  className="formCancelButton"
                  onClick={(e) => {
                    // this.formRef.current?.resetFields();
                    this.closeModal();
                    this.setState({
                      selectedChecked: false,
                      selectedValues: [],
                    });
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>

                <Button
                  type="primary"
                  className="formCancelButton"
                  onClick={async (e) => {
                    await this.setState({
                      selectedAssignedVehicleId: [],
                      selectedSearchVehicleId: [],
                      assignVehiclesArray: [],
                    });
                  }}
                >
                  {this.context.translate("_REMOVE_")}
                </Button>
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

VehicleGroupAdd.contextType = StoreContext;
export default observer(withRouter(VehicleGroupAdd));
