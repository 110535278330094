import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import RootStore, { StoreContext, StoreProvider } from "./store/RootStore";
import ProtectedRoute from "./components/ProtectedRoute.js";
import Login from "./components/login";
import LoginStarling from "./components/loginStarling";
import Home from "./components/Home";

import AddAccount from "./components/AddAccount";

import EmployeeDetails from "./components/EmployeeDetails";

import PassengerAdd from "./components/PassengerAdd";

import { CookiesProvider } from "react-cookie";

import RequireAuth from "./components/RequireAuth";
import CompanyList from "./components/CompanyList";
import CompanyUserList from "./components/CompanyUserList";

import VehicleList from "./components/VehicleList";
import VehicleAllocateList from "./components/VehicleAllocateList";

import WASLCompanyList from "./components/WASLCompanyList";

import CompanyDriversList from "./components/CompanyDriversList";
import CompanyStaffList from "./components/CompanyStaffList";
import PoliceOfficerList from "./components/PoliceOfficerList";
import PassengerList from "./components/PassengerList";

import VehicleTempSensorList from "./components/VehicleTempSensorList";
import VehicleGroupList from "./components/VehicleGroupList";
import VehicleWorkingHoursList from "./components/VehicleWorkingHoursList";
import VehicleDistanceList from "./components/VehicleDistanceList";
import VehicleLiveLocationList from "./components/VehicleLiveLocationList";
import VehicleOdometerList from "./components/VehicleOdometerList";
import VehicleTrailerList from "./components/VehicleTrailerList";
import VehicleFuelCalibrationList from "./components/VehicleFuelCalibrationList";
import AlertList from "./components/AlertList";
import EmailReportList from "./components/EmailReportList";

import CompanyDeviceTestingList from "./components/CompanyDeviceTestingList";
import CompanyDriverBehaviourSetting from "./components/CompanyDriverBehaviourSetting";
import CompanyChangePassword from "./components/CompanyChangePassword";
import CompanyMenuSetting from "./components/CompanyMenuSettingList";
import FuelDetailsList from "./components/FuelDetailsList";
import FuelFilledList from "./components/FuelFilledList";
import JobManagementList from "./components/JobManagementList";
import RouteManagementList from "./components/RouteManagementList";
import JobRequestList from "./components/JobRequestList";
import PaymentList from "./components/PaymentList";
import WareHouseList from "./components/WareHouseList";
import InventoryList from "./components/InventoryList";
import NotificationList from "./components/NotificationList";
import SimList from "./components/SimList";
import DeviceLogList from "./components/DeviceLogList";

import EmailMessage from "./components/EmailMessage";
import DeviceList from "./components/DeviceList";

import FleetSummaryReportList from "./components/FleetSummaryReportList";
import FuelFilledReportList from "./components/FuelFilledReportList";
import MileageReportList from "./components/MileageReportList";

import TrackDataList from "./components/TrackDataList";
import TravelReportList from "./components/TravelReportList";
import StoppageReportList from "./components/StoppageReportList";
import TripReportList from "./components/TripReportList";
import IdleReportList from "./components/IdleReportList";
import ExIdleReportList from "./components/ExIdleReportList";
import DistanceReportList from "./components/DistanceReportList";
import CommandsSentReportList from "./components/CommandsSentReportList";
import CanBusReportList from "./components/CanBusReportList";
import MachineryReportList from "./components/MachineryReportList";
import StationaryReportList from "./components/StationaryReportList";
import WeightReportList from "./components/WeightReportList";
import UserLoginReportList from "./components/UserLoginReportList";
import { ConfigProvider } from "antd";
import { observer } from "mobx-react";

import AssetRegisteredReportList from "./components/AssetRegisteredReportList";
import EventReportList from "./components/EventReportList";
import RawDataReportList from "./components/RawDataReportList";
import GeofencesList from "./components/GeofencesList";
import PassengerLogReportList from "./components/PassengerLogReportList";
import AttendanceReportList from "./components/AttendanceReportList";
import VehiclePerformanceList from "./components/VehiclePerformanceList";
import WorkingHoursReport from "./components/WorkingHoursReport";
import MDVRAlarmReport from "./components/MDVRAlarmReport";
import BlacklistPersonReport from "./components/BlacklistPersonReport";
import WASLReportList from "./components/WASLReportList";
import WaslBusTripReport from "./components/WaslBusTripReport";
import SensorReportList from "./components/SensorReportList";
import InventoryStatusReport from "./components/InventoryStatusReport";
import UserActivityReport from "./components/UserActivityReport";
import DriverScoreReport from "./components/DriverScoreReport";
import OverTimeReport from "./components/OverTimeReport";

import MaintenanceFleetReportList from "./components/MaintenanceFleetReportList";
import SpeedViolationReportList from "./components/SpeedViolationReportList";
import GeofenceReportList from "./components/GeofenceReportList";
import TempReportList from "./components/TempReportList";
import GeofenceSummaryList from "./components/GeofenceSummaryList";
import VehicleNotPollReportList from "./components/VehicleNotPollReportList";
import RuleTriggerList from "./components/RuleTriggerList";
import GeofenceVisitReportList from "./components/GeofenceVisitReportList";
import DriverLoginReportList from "./components/DriverLoginReportList";
import DriverPerformanceReportList from "./components/DriverPerformanceReportList";
import DriverComplainReportList from "./components/DriverComplainReportList";

import CompanyAutocomplete from "./components/CompanyAutocomplete";

import UserAdd from "./components/UserAdd";
import DriverAdd from "./components/DriverAdd";

import StaffAdd from "./components/StaffAdd";
import MenuSettingAdd from "./components/MenuSettingAdd";
import BlackListPersonAdd from "./components/BlackListPersonAdd";
import PoliceOfficerAdd from "./components/PoliceOfficerAdd";
import DriverBehaviourSettingsAdd from "./components/DriverBehaviourSettingsAdd";
import VehicleDistanceAdd from "./components/VehicleDistanceAdd";
import VehicleWorkingHoursAdd from "./components/VehicleWorkingHoursAdd";
import WarehouseAdd from "./components/WarehouseAdd";
import VehicleGroupAdd from "./components/VehicleGroupAdd";
import VehicleMaintenanceAdd from "./components/VehicleMaintenanceAdd";
import VehicleMaintenanceList from "./components/VehicleMaintenanceList";
import VehicleReturnAssetsAdd from "./components/VehicleReturnAssetsAdd";
import VehicleReturnAssetList from "./components/VehicleReturnAssetList";

import SimDetailsAdd from "./components/SimDetailsAdd";
import NotificationAdd from "./components/NotificationAdd";
import DeviceDetailsAdd from "./components/DeviceDetailsAdd";
import InspectionAdd from "./components/InspectionAdd";
import EmailReportAdd from "./components/EmailReportAdd";
import AlertAdd from "./components/AlertAdd";
import JobRequestAdd from "./components/JobRequestAdd";
import JobManagementAdd from "./components/JobManagementAdd";
import RouteManagementAdd from "./components/RouteManagementAdd";
import InspectionList from "./components/InspectionList";
import FuelDetailsAdd from "./components/FuelDetailsAdd";
import VehicleAdd from "./components/VehicleAdd";
import AllocateVehicleAdd from "./components/AllocateVehicleAdd";
import StudentAdd from "./components/StudentAdd";
import GradeTimingAdd from "./components/GradeTimingAdd";
import GradeTimingList from "./components/GradeTimingList";
import SchoolCalenderList from "./components/SchoolCalenderList";
import SchoolCalenderAdd from "./components/SchoolCalenderAdd";
import AssignTripList from "./components/AssignTripList";
import AssignTripAdd from "./components/AssignTripAdd";
import AssignTripAddNew from "./components/AssignTripAddNew";
import ParentList from "./components/ParentList";
import ParentsAdd from "./components/ParentsAdd";
import FuelFilledAdd from "./components/FuelFilledAdd";
import GprsCommand from "./components/GprsCommand";
import InventoryAdd from "./components/InventoryAdd";
import VehicleFuelCalibrationAdd from "./components/VehicleFuelCalibrationAdd";
import VehicleTrailerAdd from "./components/vehicleTrailerAdd";
import VehicleLiveLocationAdd from "./components/VehicleLiveLocationAdd";

import VehicleOdometerAdd from "./components/VehicleOdometerAdd";

import CompanyBlackListPersonList from "./components/CompanyBlackListPersonList";
import LiveTracking from "./components/LiveTracking";
import DetailTrackingList from "./components/DetailTrackingList";
import TrackingDashboard from "./components/TrackingDashboard";
import MaintenanceDashboard from "./components/MaintenanceDashboard";
import MultiTracking from "./components/MultiTracking";
import AssignVehicleToCompany from "./components/AssignVehicleToCompany";
import LiveRoutePath from "./components/LiveRoutePath";
import StationList from "./components/StationList";
import WASLDriverList from "./components/WASLDriverList";
import WASLVehicleList from "./components/WASLVehicleList";
import WASLTrip from "./components/WASLTrip";
import TripCostList from "./components/TripCostList";
import TripCostAdd from "./components/TripCostAdd";
import AssignVehicleToUser from "./components/AssignVehicleToUser";
import PaymentVehicleList from "./components/PaymentVehicleList";
import AssignVehicleToJobRequest from "./components/AssignVehicleToJobRequest";
import AssignBusToStudent from "./components/AssignBusToStudent";
import DashboardPage from "./components/LounchingDashboard";
import HistoryTracking from "./components/HistoryTracking";
import AdvanceDashboard from "./components/AdvanceDashboard";
import DistanceGraph from "./components/DistanceGraph";
import UsageGraph from "./components/UsageGraph";
import IdleGraph from "./components/IdleGraph";
import ExIdleGraph from "./components/ExIdleGraph";
import DashboardUsageGraph from "./components/DashboardUsageGraph";
import DashboardGraph from "./components/DashboardGraph";
import MaxSpeedGraph from "./components/MaxSpeedGraph";
import DriverScoreGraph from "./components/DriverScoreGraph";
import FuelGraph from "./components/FuelGraph";
import TempGraph from "./components/TempGraph";
import SpeedGraph from "./components/SpeedGraph";
import WarehouseMonitoring from "./components/WarehouseMonitoring";
import TrackByGroup from "./components/TrackByGroup";
import FleetDaySummaryReport from "./components/FleetDaySummaryReport";
import TemperatureDashboard from "./components/TemperatureDashboard";
import HeatMapShow from "./components/HeatMapShow";
import VideoMonitoring from "./components/VideoMonitoring";
import MultiVideoMonitoring from "./components/MultiVideoMonitoring";
import LounchingDashboard from "./components/LounchingDashboard";
import NewWindow from "./components/NewWindow";
import config from "./config.js";

import VehicleOdometerRecords from "./components/VehicleOdometerRecords.js";
import TaxiCustomers from "./components/TaxiCustomers.js";
import DiscountList from "./components/DiscountList.js";
import AdvertisementList from "./components/AdvertisementList.js";
import Audiomessage from "./components/Audiomessage.js";
import PointofInterestList from "./components/PointofInterestList.js";
import EventList from "./components/EventList.js";
import News from "./components/News.js";
import SpecialOffer from "./components/SpecialOffer.js";
import NewMaintenanceList from "./components/NewMaintenanceList.js";
import VehicleSubgroup from "./components/VehicleSubgroup.js";
import ServiceTemplate from "./components/ServiceTemplate.js";
import VehicleAudit from "./components/VehicleAudit.js";
import TaxiType from "./components/TaxiType.js";
import QueueList from "./components/QueueList.js";
import TaxiDefaultParameters from "./components/TaxiDefaultParameters.js";
import FareDetails from "./components/FareDetails.js";
import AddLocation from "./components/AddLocation.js";
import FAQList from "./components/FAQList.js";
import PredefinedMessage from "./components/PredefinedMessage.js";
import DeliveryDashboard from "./components/DeliveryDashboard.js";
import AssignVehicleToAdvertise from "./components/AssignVehicleToAdvertise.js";
import DeliveryManagementReport from "./components/DeliveryManagementReport.js";
import DetailedJobReport from "./components/DetailedJobReport.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
class BaseApp extends React.Component {
  constructor(props) {
    super(props);
    this.rootStore = new RootStore();
    this.state = { project: config.project };
  }
  checkIsAllowedOrnot = () => false;

  render() {
    return (
      <StoreProvider rootStore={this.rootStore}>
        <ConfigProvider
          direction={this.rootStore.locale.direction}
          locale={this.rootStore.locale.antLocale}
        >
          <CookiesProvider>
            <BrowserRouter>
              {/*<BrowserRouter>*/}
              <Routes>
                <Route path="/" element={<LoginStarling />}></Route>
                <Route
                  path="/login"
                  element={<LoginStarling></LoginStarling>}
                ></Route>

                <Route
                  element={
                    <RequireAuth>
                      <App />
                    </RequireAuth>
                  }
                >
                  {/* driver behaviour setting */}

                  <Route
                    path="/driverbehavioursettingadd"
                    element={<DriverBehaviourSettingsAdd />}
                  ></Route>
                  <Route
                    path="/driverbehavioursettingadd/:id"
                    element={<DriverBehaviourSettingsAdd />}
                  ></Route>
                  <Route
                    path="/companydriverbehaviorsetting"
                    element={
                      <ProtectedRoute path={"companydriverbehaviorsetting"}>
                        <CompanyDriverBehaviourSetting />
                      </ProtectedRoute>
                    }
                  ></Route>

                  {/* Police officer */}
                  <Route
                    path="/policeofficeradd"
                    element={<PoliceOfficerAdd />}
                  ></Route>
                  <Route
                    path="/policeofficeradd/:pOfficerId"
                    element={<PoliceOfficerAdd />}
                  ></Route>
                  <Route
                    path="/policeofficer"
                    element={<PoliceOfficerList />}
                  ></Route>

                  {/*Menu Setting  */}
                  <Route
                    path="/menusettingadd"
                    element={<MenuSettingAdd />}
                  ></Route>
                  <Route
                    path="/menusettingadd/:roleId"
                    element={<MenuSettingAdd />}
                  ></Route>

                  {/* Account  */}
                  <Route path="/addaccount" element={<AddAccount />}></Route>
                  <Route
                    path="/addaccount/:companyId"
                    element={<AddAccount />}
                  ></Route>

                  {/* <Route path="/companylist" element={<CompanyList />}></Route> */}

                  {/* User */}

                  <Route path="/useradd/:userId" element={<UserAdd />}></Route>
                  <Route path="/useradd" element={<UserAdd />}></Route>
                  <Route
                    path="/companyuserlist"
                    // element={<CompanyUserList />}
                    element={
                      <ProtectedRoute path={"companyuserlist"}>
                        <CompanyUserList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* Driver */}
                  <Route
                    path="/driveradd/:driverId"
                    element={<DriverAdd />}
                  ></Route>
                  <Route
                    path="/companydriverslist"
                    element={
                      <ProtectedRoute path={"companydriverslist"}>
                        <CompanyDriversList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route path="/driveradd" element={<DriverAdd />}></Route>

                  <Route
                    path="/wasldriverlist"
                    element={<WASLDriverList />}
                  ></Route>

                  {/* Passenger */}
                  <Route
                    path="/passengeradd"
                    element={<PassengerAdd />}
                  ></Route>
                  <Route
                    path="/passengeradd/:passengerId"
                    element={<PassengerAdd />}
                  ></Route>

                  {/*Blacklist person  */}
                  <Route
                    path="/companyblacklistpersonlist"
                    element={<CompanyBlackListPersonList />}
                  ></Route>
                  <Route
                    path="/blacklistpersonadd"
                    element={<BlackListPersonAdd />}
                  ></Route>
                  <Route
                    path="/blacklistpersonadd/:id"
                    element={<BlackListPersonAdd />}
                  ></Route>

                  {/* Staff  */}
                  <Route path="/staffadd" element={<StaffAdd />}></Route>
                  <Route
                    path="/staffadd/:staffId"
                    element={<StaffAdd />}
                  ></Route>
                  <Route
                    path="/companystafflist"
                    // element={<CompanyStaffList />}
                    element={
                      <ProtectedRoute path={"companystafflist"}>
                        <CompanyStaffList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path="/passengeradd"
                    element={<PassengerAdd />}
                  ></Route>

                  <Route
                    path="/employeedetails"
                    element={<EmployeeDetails />}
                  ></Route>
                  <Route path="/home" element={<Home />}></Route>

                  <Route path="/addaccount" element={<AddAccount />}></Route>
                  {/* <Route
                    path="/companyuserlist"
                    element={<CompanyUserList />}
                  ></Route> */}

                  <Route
                    path="/taxiCustomers"
                    element={<TaxiCustomers />}
                  ></Route>

                  <Route
                    path="/discountList"
                    element={<DiscountList />}
                  ></Route>

                  <Route
                    path="/advertisementList"
                    element={<AdvertisementList />}
                  ></Route>

                  <Route
                    path="/assignvehicleadvertise"
                    element={<AssignVehicleToAdvertise />}
                  ></Route>

                  <Route
                    path="/audiomessage"
                    element={<Audiomessage />}
                  ></Route>

                  <Route
                    path="/poiList"
                    element={<PointofInterestList />}
                  ></Route>

                  <Route path="/eventList" element={<EventList />}></Route>

                  <Route path="/newsList" element={<News />}></Route>

                  <Route
                    path="/specialOfferList"
                    element={<SpecialOffer />}
                  ></Route>

                  {/* Vehicle list */}

                  <Route
                    path="/vehiclelist"
                    element={
                      <ProtectedRoute path={"vehiclelist"}>
                        <VehicleList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route path="/vehicleadd" element={<VehicleAdd />}></Route>
                  <Route
                    path="/vehicleadd/:id"
                    element={<VehicleAdd />}
                  ></Route>

                  <Route
                    path="/vehiclelist/:status"
                    element={
                      <ProtectedRoute path={"vehiclelist"}>
                        <VehicleList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path="/waslvehiclelist"
                    element={<WASLVehicleList />}
                  ></Route>
                  <Route path="/wasltrip" element={<WASLTrip />}></Route>

                  <Route
                    path="/assignvehicletocompany"
                    element={<AssignVehicleToCompany />}
                  ></Route>

                  {/* Allocate Vehicle */}
                  <Route
                    path="/vehicleallocatelist"
                    element={
                      <ProtectedRoute path={"VehicleAllocateList"}>
                        <VehicleAllocateList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/allocatevehicleadd"
                    element={<AllocateVehicleAdd />}
                  ></Route>
                  <Route
                    path="/allocatevehicleadd/:id"
                    element={<AllocateVehicleAdd />}
                  ></Route>

                  {/* Student Add */}
                  <Route path="/studentadd" element={<StudentAdd />}></Route>

                  {/* <Route
                    path="/vehicletempsensorlist"
                    element={<VehicleTempSensorList />}
                  ></Route> */}
                  <Route
                    path="/vehiclegrouplist"
                    element={
                      <ProtectedRoute path={"VehicleGroupList"}>
                        <VehicleGroupList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path="/vehiclesubgrouplist"
                    element={<VehicleSubgroup />}
                  ></Route>
                  <Route
                    path="/vehicleworkinghourslist"
                    element={
                      <ProtectedRoute path={"VehicleWorkingHoursList"}>
                        <VehicleWorkingHoursList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  {/* <Route
                    path="/vehicledistancelist"
                    element={<VehicleDistanceList />}
                  ></Route>
                  <Route
                    path="/vehicledistancelist/:id"
                    element={<VehicleDistanceList />}
                  ></Route> */}
                  <Route
                    path="/vehiclelivelocationlist"
                    element={
                      <ProtectedRoute path={"VehicleLiveLocationList"}>
                        <VehicleLiveLocationList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/vehicleodometerlist"
                    element={
                      <ProtectedRoute path={"VehicleOdometerList"}>
                        <VehicleOdometerList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path="/vehicleodometerrecords"
                    element={<VehicleOdometerRecords />}
                  ></Route>

                  <Route
                    path="/serviceTemplate"
                    element={<ServiceTemplate />}
                  ></Route>

                  <Route
                    path="/vehicleAudit"
                    element={<VehicleAudit />}
                  ></Route>

                  <Route path="/taxiTypeList" element={<TaxiType />}></Route>

                  <Route path="/queueList" element={<QueueList />}></Route>

                  <Route
                    path="/newMaintenanceList"
                    element={<NewMaintenanceList />}
                  ></Route>

                  <Route
                    path="/taxiDefaultParameters"
                    element={<TaxiDefaultParameters />}
                  ></Route>

                  <Route path="/fareDetails" element={<FareDetails />}></Route>

                  {/* <Route
                    path="/vehicletrailerlist"
                    element={
                      <ProtectedRoute path={"VehicleTrailerList"}>
                        <VehicleTrailerList />
                      </ProtectedRoute>
                    }
                  ></Route> */}
                  {/* <Route path='/vehiclefuelcalibrationlist' element={<VehicleFuelCalibrationList />}></Route> */}

                  <Route
                    path="/driverbehavioursettingadd"
                    element={<DriverBehaviourSettingsAdd />}
                  ></Route>
                  <Route
                    path="/driverbehavioursettingadd/:companyId"
                    element={<DriverBehaviourSettingsAdd />}
                  ></Route>

                  <Route
                    path="/fleetsummaryreportlist"
                    element={
                      <ProtectedRoute path={"FleetSummaryReportList"}>
                        {" "}
                        <FleetSummaryReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/fuelfilledreportlist"
                    element={
                      <ProtectedRoute path={"FuelFilledReportList"}>
                        <FuelFilledReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/mileagereportlist"
                    element={<MileageReportList />}
                  ></Route>

                  {/* Inventory */}
                  {/* <Route
                    path="/inventorylist"
                    element={<InventoryList />}
                  ></Route> */}
                  <Route
                    path="/inventoryadd"
                    element={<InventoryAdd />}
                  ></Route>
                  <Route
                    path="/inventoryadd/:id"
                    element={<InventoryAdd />}
                  ></Route>

                  <Route
                    path="/policeofficeradd"
                    element={<PoliceOfficerAdd />}
                  ></Route>
                  <Route
                    path="/policeofficeradd/:pOfficerId"
                    element={<PoliceOfficerAdd />}
                  ></Route>

                  <Route
                    path="/vehiclereturnassetlist"
                    element={
                      <ProtectedRoute path={"vehiclereturnassetlist"}>
                        <VehicleReturnAssetList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/vehiclereturnassetadd"
                    element={<VehicleReturnAssetsAdd />}
                  ></Route>
                  <Route
                    path="/vehiclereturnassetadd/:id"
                    element={<VehicleReturnAssetsAdd />}
                  ></Route>

                  {/* <Route
                    path="/device"
                    element={<CompanyDeviceTestingList />}
                  ></Route> */}

                  {/* <Route
                    path="/companydevicetestinglist"
                    element={
                      <ProtectedRoute path={"companydevicetestinglist"}>
                        <CompanyDeviceTestingList />
                      </ProtectedRoute>
                    }
                  ></Route> */}

                  {/* <Route path='/waslcompanylist' element={<WASLCompanyList/>}></Route> */}

                  {/* <Route path="/companylist" element={<CompanyList />}></Route> */}
                  <Route
                    path="/companychangepassword"
                    element={
                      <ProtectedRoute path={"companychangepassword"}>
                        <CompanyChangePassword />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/companymenusetting"
                    element={<CompanyMenuSetting />}
                  ></Route>

                  {/* <Route
                    path="/fuelfilled"
                    element={
                      <ProtectedRoute path={"fuelfilledlist"}>
                        <FuelFilledList />
                      </ProtectedRoute>
                    }
                  ></Route> */}

                  <Route
                    path="/assignbustostudent"
                    element={<AssignBusToStudent />}
                  ></Route>
                  <Route
                    path="/paymentlist"
                    element={
                      <ProtectedRoute path={"paymentlist"}>
                        <PaymentList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path="/assignbustostudent/:tripData"
                    element={<AssignBusToStudent />}
                  ></Route>
                  {/* <Route path="/paymentlist" element={<PaymentList />}></Route> */}
                  <Route
                    path="/vehiclepaymentlist/:id"
                    element={
                      <ProtectedRoute path={"paymentlist"}>
                        <PaymentVehicleList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  {/* <Route
                    path="/warehouselist"
                    element={<WareHouseList />}
                  ></Route> */}

                  <Route
                    path="/warehouseadd"
                    element={<WarehouseAdd />}
                  ></Route>
                  <Route
                    path="/warehouseadd/:companyId"
                    element={<WarehouseAdd />}
                  ></Route>

                  <Route
                    path="/simlist"
                    element={
                      <ProtectedRoute path={"simlist"}>
                        <SimList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/deviceloglist"
                    element={<DeviceLogList />}
                  ></Route> */}
                  <Route
                    path="/gprscommand"
                    element={
                      <ProtectedRoute path={"gprscommand"}>
                        <GprsCommand />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route path="/addLocation" element={<AddLocation />}></Route>

                  <Route path="/faqList" element={<FAQList />}></Route>

                  <Route
                    path="/predefinedMesssage"
                    element={<PredefinedMessage />}
                  ></Route>

                  <Route
                    path="/emailmessage"
                    element={
                      <ProtectedRoute path={"emailmessage"}>
                        <EmailMessage />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/devicelist"
                    element={
                      <ProtectedRoute path={"devicelist"}>
                        <DeviceList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path="/trackdatalist"
                    element={
                      <ProtectedRoute path={"trackdatalist"}>
                        <TrackDataList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/travelreportlist"
                    element={<TravelReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/stoppagereportlist"
                    element={<StoppageReportList />}
                  ></Route> */}
                  <Route
                    path="/tripreportlist"
                    element={
                      <ProtectedRoute path={"tripreportlist"}>
                        <TripReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/idlereportlist"
                    element={
                      <ProtectedRoute path={"idlereportlist"}>
                        <IdleReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/exidlereportlist"
                    element={
                      <ProtectedRoute path={"exidlereportlist"}>
                        <ExIdleReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/distancereportlist"
                    element={<DistanceReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/commandsentreportlist"
                    element={<CommandsSentReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/canbusreportstorelist"
                    element={<CanBusReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/machineryseportlist"
                    element={<MachineryReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/stationaryreportlist"
                    element={<StationaryReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/weightreportlist"
                    element={<WeightReportList />}
                  ></Route> */}

                  {/* Micellaneous Report */}

                  {/* <Route
                    path="/userloginreportlist"
                    element={<UserLoginReportList />}
                  ></Route> */}

                  {/* <Route
                    path="/warehouselist"
                    element={<WareHouseList />}
                  ></Route> */}

                  {/* <Route path="/simlist" element={<SimList />}></Route> */}
                  <Route
                    path="/deviceloglist"
                    element={
                      <ProtectedRoute path={"deviceloglist"}>
                        <DeviceLogList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path="/emailmessage"
                    element={<EmailMessage />}
                  ></Route>
                  {/* <Route path="/devicelist" element={<DeviceList />}></Route> */}

                  <Route
                    path="/menusettingadd"
                    element={<MenuSettingAdd />}
                  ></Route>
                  <Route
                    path="/menusettingadd/:roleId"
                    element={<MenuSettingAdd />}
                  ></Route>

                  {/* <Route
                    path="/trackdatalist"
                    element={<TrackDataList />}
                  ></Route> */}
                  <Route
                    path="/travelreportlist"
                    element={
                      <ProtectedRoute path={"travelreportlist"}>
                        <TravelReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/stoppagereportlist"
                    element={
                      <ProtectedRoute path={"stoppagereportlist"}>
                        <StoppageReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/tripreportlist"
                    element={<TripReportList />}
                  ></Route> */}

                  <Route
                    path="/distancereportlist"
                    element={
                      <ProtectedRoute path={"distancereportlist"}>
                        <DistanceReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/commandsentreportlist"
                    element={<CommandsSentReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/canbusreportstorelist"
                    element={<CanBusReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/machineryseportlist"
                    element={<MachineryReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/stationaryreportlist"
                    element={<StationaryReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/weightreportlist"
                    element={<WeightReportList />}
                  ></Route> */}

                  {/* Micellaneous Report */}

                  {/* <Route
                    path="/userloginreportlist"
                    element={<UserLoginReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/assetregisterreportlist"
                    element={<AssetRegisteredReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/commandsentreportlist"
                    element={
                      <ProtectedRoute path={"commandsentreportlist"}>
                        <CommandsSentReportList />
                      </ProtectedRoute>
                    }
                  ></Route> */}
                  {/* <Route
                    path="/eventreportlist"
                    element={<EventReportList />}
                  ></Route> */}
                  <Route
                    path="/rawdatareportlist"
                    element={<RawDataReportList />}
                  ></Route>
                  <Route
                    path="/geofencereportlist"
                    element={
                      <ProtectedRoute path={"geofencereportlist"}>
                        {" "}
                        <GeofencesList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/passengerlogreportlist"
                    element={
                      <ProtectedRoute path="passengerlogreportlist">
                        <PassengerLogReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/attendencereportlist"
                    element={<AttendanceReportList />}
                  ></Route> */}
                  <Route
                    path="/vehicleperformancelist"
                    element={
                      <ProtectedRoute path={"vehicleperformancelist"}>
                        {" "}
                        <VehiclePerformanceList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/workinghoursreportlist"
                    element={<WorkingHoursReport />}
                  ></Route> */}
                  <Route
                    path="/mdvralarmreportlist"
                    element={
                      <ProtectedRoute path={"mdvralarmreportlist"}>
                        <MDVRAlarmReport />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/blacklistpersonreportlist"
                    element={<BlacklistPersonReport />}
                  ></Route> */}
                  {/* <Route
                    path="/waslreportlist"
                    element={
                      <ProtectedRoute path={"waslreportlist"}>
                        <WASLReportList />
                      </ProtectedRoute>
                    }
                  ></Route> */}
                  {/* <Route
                    path="/waslbustripresponselist"
                    element={<WaslBusTripReport />}
                  ></Route> */}
                  <Route
                    path="/sensorreportlist"
                    element={
                      <ProtectedRoute path={"sensorreportlist"}>
                        <SensorReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/inventorystatusrepostlist"
                    element={
                      <ProtectedRoute path={"inventorystatusrepostlist"}>
                        <InventoryStatusReport />
                      </ProtectedRoute>
                    }
                  ></Route> */}
                  <Route
                    path="/useractivityreportlist"
                    element={
                      <ProtectedRoute path={"useractivityreportlist"}>
                        <UserActivityReport />
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path="/driverscorereportlist"
                    element={
                      <ProtectedRoute path={"driverscorereportlist"}>
                        <DriverScoreReport />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/overtimereportlist"
                    element={
                      <ProtectedRoute path={"overtimereportlist"}>
                        <OverTimeReport />
                      </ProtectedRoute>
                    }
                  ></Route>

                  {/* new*/}
                  <Route
                    path="/maintenancefleetreportlist"
                    element={
                      <ProtectedRoute path={"MaintenanceFleetReportList"}>
                        <MaintenanceFleetReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/speedviolationreportlist"
                    element={
                      <ProtectedRoute path={"SpeedViolationReportList"}>
                        <SpeedViolationReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/geofenceinoroutreportlist"
                    element={
                      <ProtectedRoute path="geofenceinoroutreportlist">
                        <GeofenceReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/tempreportlist"
                    element={<TempReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/geofencesummarylist"
                    element={<GeofenceSummaryList />}
                  ></Route> */}
                  <Route
                    path="/vehiclenotpollreportlist"
                    element={
                      <ProtectedRoute path="vehiclenotpollreportlist">
                        <VehicleNotPollReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/ruletriggerlist"
                    element={
                      <ProtectedRoute path={"RuleTriggerList"}>
                        <RuleTriggerList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/geofencevisitreportlist"
                    element={<GeofenceVisitReportList />}
                  ></Route> */}
                  <Route
                    path="/driverloginreportlist"
                    element={
                      <ProtectedRoute path={"DriverLoginReportList"}>
                        <DriverLoginReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/driverperformancereportlist"
                    element={
                      <ProtectedRoute path={"DriverPerformanceReportList"}>
                        <DriverPerformanceReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/drivercomplainreportlist"
                    element={<DriverComplainReportList />}
                  ></Route> */}

                  <Route
                    path="/vehiclegroupadd"
                    element={<VehicleGroupAdd />}
                  ></Route>
                  <Route
                    path="/vehiclegroupadd/:id"
                    element={<VehicleGroupAdd />}
                  ></Route>

                  <Route
                    path="/vehicleworkinghoursadd"
                    element={<VehicleWorkingHoursAdd />}
                  ></Route>
                  <Route
                    path="/vehicleworkinghoursadd/:id"
                    element={<VehicleWorkingHoursAdd />}
                  ></Route>

                  <Route
                    path="/vehicledistanceadd"
                    element={<VehicleDistanceAdd />}
                  ></Route>
                  <Route
                    path="/vehicledistanceadd/:id"
                    element={<VehicleDistanceAdd />}
                  ></Route>

                  <Route
                    path="/vehiclelivelocationadd"
                    element={<VehicleLiveLocationAdd />}
                  ></Route>
                  <Route
                    path="/vehiclelivelocationadd/:id/:vehicleid"
                    element={<VehicleLiveLocationAdd />}
                  ></Route>

                  <Route
                    path="/vehicleodometeradd"
                    element={<VehicleOdometerAdd />}
                  ></Route>

                  <Route
                    path="/addaccount/:companyId"
                    element={<AddAccount />}
                  ></Route>
                  <Route path="/useradd/:userId" element={<UserAdd />}></Route>
                  <Route path="/useradd" element={<UserAdd />}></Route>
                  <Route
                    path="/driveradd/:driverId"
                    element={<DriverAdd />}
                  ></Route>

                  <Route
                    path="/passengeradd"
                    element={<PassengerAdd />}
                  ></Route>
                  <Route
                    path="/passengeradd/:passengerId"
                    element={<PassengerAdd />}
                  ></Route>

                  <Route
                    path="/blacklistpersonadd"
                    element={<BlackListPersonAdd />}
                  ></Route>
                  <Route
                    path="/blacklistpersonadd/:bPersonId"
                    element={<BlackListPersonAdd />}
                  ></Route>

                  <Route path="/staffadd" element={<StaffAdd />}></Route>
                  <Route
                    path="/staffadd/:staffId"
                    element={<StaffAdd />}
                  ></Route>

                  <Route
                    path="/employeedetails"
                    element={<EmployeeDetails />}
                  ></Route>
                  <Route path="/home" element={<Home />}></Route>
                  <Route path="/addaccount" element={<AddAccount />}></Route>
                  {/* <Route
                    path="/companyuserlist"
                    element={<CompanyUserList />}
                  ></Route> */}

                  <Route
                    path="/assignvehicletouser/:compId/:userId"
                    element={<AssignVehicleToUser />}
                  ></Route>

                  {/* <Route path="/vehiclelist" element={<VehicleList />}></Route> */}

                  <Route
                    path="/vehiclemaintenancelist"
                    element={
                      <ProtectedRoute path={"vehiclemaintenancelist"}>
                        <VehicleMaintenanceList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path="/vehicletempsensorlist"
                    element={
                      <ProtectedRoute path={"VehicleTempSensorList"}>
                        <VehicleTempSensorList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/vehiclegrouplist"
                    element={<VehicleGroupList />}
                  ></Route> */}
                  {/* <Route
                    path="/vehicleworkinghourslist"
                    element={<VehicleWorkingHoursList />}
                  ></Route> */}

                  <Route
                    path="/vehicledistancelist"
                    element={
                      <ProtectedRoute path={"vehicledistancelist"}>
                        <VehicleDistanceList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/vehicledistancelist/:id"
                    element={
                      <ProtectedRoute path={"vehicledistancelist"}>
                        {" "}
                        <VehicleDistanceList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/vehiclelivelocationlist"
                    element={<VehicleLiveLocationList />}
                  ></Route> */}
                  {/* <Route
                    path="/vehicleodometerlist"
                    element={<VehicleOdometerList />}
                  ></Route> */}
                  {/* <Route path='/vehicletrailerlist' element={<VehicleTrailerList />}></Route> */}
                  <Route
                    path="/vehiclefuelcalibrationlist"
                    element={
                      <ProtectedRoute path={"VehicleFuelCalibrationList"}>
                        {" "}
                        <VehicleFuelCalibrationList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  {/* <Route
                    path="/fleetsummaryreportlist"
                    element={<FleetSummaryReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/fuelfilledreportlist"
                    element={<FuelFilledReportList />}
                  ></Route> */}
                  <Route
                    path="/mileagereportlist"
                    element={<MileageReportList />}
                  ></Route>

                  {/* <Route
                    path="/vehicleallocatelist"
                    element={<VehicleAllocateList />}
                  ></Route> */}
                  {/* <Route
                    path="/vehiclereturnassetlist"
                    element={<VehicleReturnAssetList />}
                  ></Route> */}
                  <Route
                    path="/vehiclereturnassetadd"
                    element={<VehicleReturnAssetsAdd />}
                  ></Route>
                  <Route
                    path="/vehiclereturnassetadd/:id"
                    element={<VehicleReturnAssetsAdd />}
                  ></Route>

                  {/* <Route
                    path="/device"
                    element={<CompanyDeviceTestingList />}
                  ></Route> */}

                  <Route
                    path="/companylist"
                    element={
                      <ProtectedRoute path={"companyList"}>
                        <CompanyList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/passengerlist"
                    // element={<PassengerList />}
                    element={
                      <ProtectedRoute path={"passengerList"}>
                        <PassengerList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* 
                  <Route
                    path="/companydevicetestinglist"
                    element={<CompanyDeviceTestingList />}
                  ></Route> */}
                  {/* <Route
                    path="/companydriverslist"
                    element={<CompanyDriversList />}
                  ></Route> */}
                  {/* <Route
                    path="/companystafflist"
                    element={<CompanyStaffList />}
                  ></Route> */}

                  <Route
                    path="/policeofficer"
                    element={<PoliceOfficerList />}
                  ></Route>
                  {/* <Route path='/waslcompanylist' element={<WASLCompanyList/>}></Route> */}

                  {/* <Route path="/companylist" element={<CompanyList />}></Route> */}
                  {/* <Route
                    path="/companydriverbehaviorsetting"
                    element={<CompanyDriverBehaviourSetting />}
                  ></Route> */}
                  {/* <Route
                    path="/companychangepassword"
                    element={<CompanyChangePassword />}
                  ></Route> */}
                  {/* <Route
                    path="/companymenusetting"
                    element={<CompanyMenuSetting />}
                  ></Route> */}

                  {/* <Route
                    path="/jobrequestlist"
                    element={<JobRequestList />}
                  ></Route> */}
                  <Route
                    path="/assignvehicletojobrequest/:companyId/:Id"
                    element={<AssignVehicleToJobRequest />}
                  >
                    {" "}
                  </Route>

                  {/* <Route path='/warehouselist' element={<WareHouseList />}></Route> */}

                  <Route
                    path="/warehouseadd"
                    element={<WarehouseAdd />}
                  ></Route>
                  <Route
                    path="/warehouseadd/:companyId"
                    element={<WarehouseAdd />}
                  ></Route>

                  {/* <Route
                    path="/inventorylist"
                    element={<InventoryList />}
                  ></Route> */}

                  {/* <Route
                    path="/deviceloglist"
                    element={<DeviceLogList />}
                  ></Route> */}

                  <Route
                    path="/emailmessage"
                    element={<EmailMessage />}
                  ></Route>

                  {/* <Route
                    path="/trackdatalist"
                    element={<TrackDataList />}
                  ></Route> */}
                  {/* <Route
                    path="/travelreportlist"
                    element={<TravelReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/stoppagereportlist"
                    element={<StoppageReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/tripreportlist"
                    element={<TripReportList />}
                  ></Route> */}

                  {/* <Route
                    path="/distancereportlist"
                    element={<DistanceReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/commandsentreportlist"
                    element={<CommandsSentReportList />}
                  ></Route> */}
                  <Route
                    path="/canbusreportstorelist"
                    element={
                      <ProtectedRoute path={"canbusreportstorelist"}>
                        <CanBusReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/machineryseportlist"
                    element={
                      <ProtectedRoute path={"machineryseportlist"}>
                        <MachineryReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/stationaryreportlist"
                    element={
                      <ProtectedRoute path={"stationaryreportlist"}>
                        <StationaryReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/weightreportlist"
                    element={<WeightReportList />}
                  ></Route> */}

                  {/* Micellaneous Report */}

                  <Route
                    path="/userloginreportlist"
                    element={
                      <ProtectedRoute path={"userloginreportlist"}>
                        <UserLoginReportList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  {/* <Route
                    path="/warehouselist"
                    element={
                      <ProtectedRoute path={"warehouselist"}>
                        <WareHouseList />
                      </ProtectedRoute>
                    }
                  ></Route> */}

                  {/* <Route
                    path="/inventorylist"
                    element={
                      <ProtectedRoute path={"inventorylist"}>
                        <InventoryList />
                      </ProtectedRoute>
                    }
                  ></Route> */}

                  {/* <Route
                    path="/deviceloglist"
                    element={<DeviceLogList />}
                  ></Route> */}

                  <Route
                    path="/emailmessage"
                    element={<EmailMessage />}
                  ></Route>

                  {/* <Route
                    path="/trackdatalist"
                    element={<TrackDataList />}
                  ></Route> */}
                  {/* <Route
                    path="/travelreportlist"
                    element={<TravelReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/stoppagereportlist"
                    element={<StoppageReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/tripreportlist"
                    element={<TripReportList />}
                  ></Route> */}

                  {/* <Route
                    path="/distancereportlist"
                    element={<DistanceReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/commandsentreportlist"
                    element={<CommandsSentReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/canbusreportstorelist"
                    element={<CanBusReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/machineryseportlist"
                    element={<MachineryReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/stationaryreportlist"
                    element={<StationaryReportList />}
                  ></Route> */}
                  <Route
                    path="/weightreportlist"
                    element={
                      <ProtectedRoute path={"weightreportlist"}>
                        <WeightReportList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  {/* Micellaneous Report */}

                  {/* <Route
                    path="/userloginreportlist"
                    element={<UserLoginReportList />}
                  ></Route> */}
                  <Route
                    path="/assetregisterreportlist"
                    element={
                      <ProtectedRoute path={"assetregisterreportlist"}>
                        <AssetRegisteredReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/commandsendreportlist"
                    element={
                      <ProtectedRoute path={"commandsendreportlist"}>
                        <CommandsSentReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/eventreportlist"
                    element={
                      <ProtectedRoute path={"eventreportlist"}>
                        <EventReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/rawdatareportlist"
                    element={<RawDataReportList />}
                  ></Route>

                  {/* change  */}

                  {/* ----------------------------------------- */}
                  {/* <Route
                    path="/geofencereportlist"
                    element={
                      <ProtectedRoute path={"geofencereportlist"}>
                        <GeofencesList />
                      </ProtectedRoute>
                    }
                  ></Route> */}
                  {/* <Route
                    path="/passengerlogreportlist"
                    element={
                      <ProtectedRoute path={"passengerlogreportlist"}>
                        <PassengerLogReportList />
                      </ProtectedRoute>
                    }
                  ></Route> */}

                  {/* ----------------------------------------- */}
                  <Route
                    path="/attendencereportlist"
                    element={
                      <ProtectedRoute path={"attendencereportlist"}>
                        <AttendanceReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/vehicleperformancelist"
                    element={<VehiclePerformanceList />}
                  ></Route> */}
                  <Route
                    path="/workinghoursreportlist"
                    element={
                      <ProtectedRoute path={"workinghoursreportlist"}>
                        <WorkingHoursReport />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/mdvrlalarmreportlist"
                    element={<MDVRAlarmReport />}
                  ></Route>
                  <Route
                    path="/blacklistpersonreportlist"
                    element={
                      <ProtectedRoute path={"blacklistpersonreportlist"}>
                        <BlacklistPersonReport />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/waslreportlist"
                    element={<WASLReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/waslbustripresponselist"
                    element={
                      <ProtectedRoute path={"waslbustripresponselist"}>
                        <WaslBusTripReport />
                      </ProtectedRoute>
                    }
                  ></Route> */}
                  {/* <Route
                    path="/sensorreportlist"
                    element={<SensorReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/inventorystatusrepostlist"
                    element={<InventoryStatusReport />}
                  ></Route> */}
                  {/* <Route
                    path="/useractivityreportlist"
                    element={<UserActivityReport />}
                  ></Route> */}

                  {/* <Route
                    path="/driverscorereportlist"
                    element={<DriverScoreReport />}
                  ></Route> */}

                  {/* check again */}
                  {/* ------------------------ */}
                  {/* <Route
                    path="/overtimereportlist"
                    element={<OverTimeReport />}
                  ></Route> */}
                  {/* --------------------------- */}

                  {/* new*/}
                  {/* <Route
                    path="/maintenancefleetreportlist"
                    element={<MaintenanceFleetReportList />}
                  ></Route> */}
                  <Route
                    path="/fleetdaysummaryreport"
                    element={
                      <ProtectedRoute path={"fleetdaysummaryreport"}>
                        <FleetDaySummaryReport />
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path="/deliverymanagementreport"
                    element={<DeliveryManagementReport />}
                  ></Route>

                  <Route
                    path="/detailedjobreport"
                    element={<DetailedJobReport />}
                  ></Route>

                  {/* <Route
                    path="/speedviolationreportlist"
                    element={<SpeedViolationReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/geofenceinoroutreportlist"
                    // element={<GeofenceReportList />}
                    element={
                      <ProtectedRoute path={"geofenceinoroutreportlist"}>
                        <GeofenceReportList />
                      </ProtectedRoute>
                    }
                  ></Route> */}
                  <Route
                    path="/tempreportlist"
                    element={
                      <ProtectedRoute path={"TempReportList"}>
                        <TempReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/geofencesummarylist"
                    element={
                      <ProtectedRoute path={"GeofenceSummaryList"}>
                        {" "}
                        <GeofenceSummaryList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/vehiclenotpollreportlist"
                    element={
                      <ProtectedRoute path={"VehicleNotPollReportList"}>
                        {" "}
                        <VehicleNotPollReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/ruletriggerlist"
                    element={<RuleTriggerList />}
                  ></Route> */}
                  <Route
                    path="/geofencevisitreportlist"
                    element={
                      <ProtectedRoute path={"GeofenceVisitReportList"}>
                        <GeofenceVisitReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route
                    path="/driverloginreportlist"
                    element={<DriverLoginReportList />}
                  ></Route> */}
                  {/* <Route
                    path="/driverperformancereportlist"
                    element={<DriverPerformanceReportList />}
                  ></Route> */}
                  <Route
                    path="/drivercomplainreportlist"
                    element={
                      <ProtectedRoute path={"DriverComplainReportList"}>
                        <DriverComplainReportList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route path="/driveradd" element={<DriverAdd />}></Route>

                  <Route
                    path="/vehiclegroupadd"
                    element={<VehicleGroupAdd />}
                  ></Route>
                  <Route
                    path="/vehiclegroupadd/:id"
                    element={<VehicleGroupAdd />}
                  ></Route>

                  <Route
                    path="/vehicleworkinghoursadd"
                    element={<VehicleWorkingHoursAdd />}
                  ></Route>
                  <Route
                    path="/vehicleworkinghoursadd/:id"
                    element={<VehicleWorkingHoursAdd />}
                  ></Route>

                  <Route
                    path="/vehicledistanceadd"
                    element={<VehicleDistanceAdd />}
                  ></Route>
                  <Route
                    path="/vehicledistanceadd/:id"
                    element={<VehicleDistanceAdd />}
                  ></Route>

                  <Route
                    path="/vehicletraileradd"
                    element={<VehicleTrailerAdd />}
                  ></Route>
                  <Route
                    path="/vehicletraileradd/:id"
                    element={<VehicleTrailerAdd />}
                  ></Route>

                  <Route
                    path="/vehiclefuelcalibration"
                    element={<VehicleFuelCalibrationAdd />}
                  ></Route>

                  <Route
                    path="/vehiclefuelcalibration/:id"
                    element={<VehicleFuelCalibrationAdd />}
                  ></Route>

                  <Route
                    path="/vehiclemaintenanceadd"
                    element={<VehicleMaintenanceAdd />}
                  ></Route>
                  <Route
                    path="/vehiclemaintenanceadd/:id"
                    element={<VehicleMaintenanceAdd />}
                  ></Route>

                  <Route
                    path="/companyautocomplete"
                    element={<CompanyAutocomplete />}
                  ></Route>

                  {/* Job Management */}

                  <Route
                    path="/jobmanagementadd"
                    element={<JobManagementAdd />}
                  ></Route>
                  <Route
                    path="/jobmanagementadd/:id"
                    element={<JobManagementAdd />}
                  ></Route>
                  <Route
                    path="/jobmanagementlist"
                    element={
                      <ProtectedRoute path={"jobmanagementlist"}>
                        <JobManagementList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path="/deliveryDashboard"
                    element={<DeliveryDashboard />}
                  ></Route>

                  {/* Job Request  */}
                  <Route
                    path="/jobrequestadd"
                    element={<JobRequestAdd />}
                  ></Route>
                  <Route
                    path="/jobrequestadd/:id"
                    element={<JobRequestAdd />}
                  ></Route>
                  <Route
                    path="/jobrequestlist"
                    element={
                      <ProtectedRoute path={"jobrequestlist"}>
                        <JobRequestList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* Route management */}
                  <Route
                    path="/routemanagementadd"
                    element={<RouteManagementAdd />}
                  ></Route>
                  <Route
                    path="/jobmanagementadd/:id"
                    element={<RouteManagementAdd />}
                  ></Route>
                  <Route
                    path="/routemanagementlist"
                    element={<RouteManagementList />}
                  ></Route>
                  {/* Fuel Management */}

                  <Route
                    path="/fueldetailslist"
                    // element={<FuelDetailsList />}
                    element={
                      <ProtectedRoute path={"fueldetailslist"}>
                        <FuelDetailsList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/fueldetailslist/:id"
                    element={<FuelDetailsList />}
                  ></Route>
                  <Route
                    path="/fueldetailsadd"
                    element={<FuelDetailsAdd />}
                  ></Route>
                  <Route
                    path="/fueldetailsadd/:id"
                    element={<FuelDetailsAdd />}
                  ></Route>
                  {/* Fuel Filled */}
                  <Route
                    path="/fuelfilledlist"
                    element={
                      <ProtectedRoute path={"fuelfilledlist"}>
                        <FuelFilledList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  {/* <Route path='/fuelfilledlist/:companyId' element={<FuelFilledList />}></Route> */}
                  <Route
                    path="/fuelfilledadd"
                    element={<FuelFilledAdd />}
                  ></Route>
                  <Route
                    path="/fuelfilledadd/:id"
                    element={<FuelFilledAdd />}
                  ></Route>

                  {/* Grade Timing Add */}
                  <Route
                    path="/gradetimingadd"
                    element={<GradeTimingAdd />}
                  ></Route>
                  <Route
                    path="/gradetimingadd/:gradeId"
                    element={<GradeTimingAdd />}
                  ></Route>
                  <Route
                    path="/gradetiminglist"
                    element={
                      <ProtectedRoute path={"gradetiminglist"}>
                        <GradeTimingList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/gradetiminglist/:companyId"
                    element={
                      <ProtectedRoute path={"gradetiminglist"}>
                        <GradeTimingList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  {/* School Calendar */}
                  <Route
                    path="/schoolcalenderlist"
                    element={
                      <ProtectedRoute path={"schoolcalenderlist"}>
                        <SchoolCalenderList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/schoolcalenderadd"
                    element={<SchoolCalenderAdd />}
                  ></Route>
                  <Route
                    path="/schoolcalenderadd/:id"
                    element={<SchoolCalenderAdd />}
                  ></Route>

                  {/* Assign/Create Trip */}
                  <Route
                    path="/assigntripaddnew"
                    element={<AssignTripAddNew />}
                  ></Route>
                  <Route
                    path="/assigntripaddnew/:id"
                    element={<AssignTripAddNew />}
                  ></Route>
                  <Route
                    path="/assigntriplist"
                    element={
                      <ProtectedRoute path={"assigntriplist"}>
                        {" "}
                        <AssignTripList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  {/* Parent List */}
                  <Route
                    path="/parentlist"
                    element={
                      <ProtectedRoute path={"parentlist"}>
                        <ParentList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route path="/parentadd" element={<ParentsAdd />}></Route>
                  <Route path="/parentadd/:id" element={<ParentsAdd />}></Route>

                  <Route
                    path="/notificationadd"
                    element={<NotificationAdd />}
                  ></Route>

                  {/* Email Report */}

                  <Route
                    path="/emailreportlist"
                    element={
                      <ProtectedRoute path={"EmailReportList"}>
                        <EmailReportList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/emailreportadd"
                    element={<EmailReportAdd />}
                  ></Route>
                  <Route
                    path="/emailreportadd/:id"
                    element={<EmailReportAdd />}
                  ></Route>

                  {/* Alert Report */}

                  <Route
                    path="/alertlist"
                    element={
                      <ProtectedRoute path={"alertlist"}>
                        <AlertList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route path="/alertadd" element={<AlertAdd />}></Route>
                  <Route path="/alertadd/:id" element={<AlertAdd />}></Route>

                  {/* Manage */}

                  {/* NotificationList */}
                  <Route
                    path="/notificationlist"
                    element={
                      <ProtectedRoute path={"notificationlist"}>
                        <NotificationList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/notificationadd"
                    element={<NotificationAdd />}
                  ></Route>
                  <Route
                    path="/notificationadd/:id"
                    element={<NotificationAdd />}
                  ></Route>

                  {/* SimList */}

                  {/* <Route path="/simlist" element={<SimList />}></Route> */}
                  <Route
                    path="/simdetailsadd"
                    element={<SimDetailsAdd />}
                  ></Route>
                  <Route
                    path="/simdetailsadd/:id"
                    element={<SimDetailsAdd />}
                  ></Route>

                  {/* InspectionList */}

                  <Route
                    path="/inspectionadd"
                    element={<InspectionAdd />}
                  ></Route>
                  <Route
                    path="/inspectionadd/:id"
                    element={<InspectionAdd />}
                  ></Route>
                  <Route
                    path="/inspectionlist"
                    element={
                      <ProtectedRoute path={"inspectionlist"}>
                        <InspectionList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  {/* Devicelist */}

                  {/* <Route path="/devicelist" element={<DeviceList />}></Route> */}
                  <Route
                    path="/devicedetailsadd"
                    element={<DeviceDetailsAdd />}
                  ></Route>
                  <Route
                    path="/devicedetailsadd/:id"
                    element={<DeviceDetailsAdd />}
                  ></Route>

                  {/* Student Management  */}
                  <Route path="/studentadd" element={<StudentAdd />}></Route>
                  <Route path="/studentadd/id" element={<StudentAdd />}></Route>
                  <Route
                    path="/passengerlist2/:id"
                    element={
                      <ProtectedRoute path={"passengerList2"}>
                        <PassengerList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  {/* Live Tracking */}
                  <Route
                    path="/livetracking"
                    element={
                      <ProtectedRoute path={"livetracking"}>
                        <LiveTracking />
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path="/detailtracking"
                    element={
                      <ProtectedRoute path={"detailtracking"}>
                        <DetailTrackingList />
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path="/trackbygroup"
                    element={<TrackByGroup />}
                  ></Route>
                  <Route
                    path="/dashboard"
                    element={
                      <ProtectedRoute path={"dashboard"}>
                        <TrackingDashboard />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/maintenancedashboard"
                    element={
                      <ProtectedRoute path={"maintenancedashboard"}>
                        <MaintenanceDashboard />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/multitracking"
                    element={
                      <ProtectedRoute path={"multitracking"}>
                        {" "}
                        <MultiTracking />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/liveroutepath"
                    element={
                      <ProtectedRoute path={"liveroutepath"}>
                        <LiveRoutePath />
                      </ProtectedRoute>
                    }
                  ></Route>

                  {/* WASL STATION */}
                  <Route path="/waslstation" element={<StationList />}></Route>

                  {/* WASL Company List  */}

                  <Route
                    path="/waslcompanylist"
                    element={<WASLCompanyList />}
                  ></Route>

                  <Route
                    path="/tripCostList"
                    element={
                      <ProtectedRoute path={"tripCostList"}>
                        <TripCostList />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/tripCostAdd"
                    element={
                      <ProtectedRoute path="tripCostAdd">
                        <TripCostAdd />
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path="/dashboardpage"
                    element={<DashboardPage />}
                  ></Route>

                  {/* <Route
                    path="/"
                    element={<Navigate to={"/livetracking"}></Navigate>}
                  ></Route> */}

                  <Route
                    path="/historytracking"
                    element={
                      <ProtectedRoute path={"historytracking"}>
                        {" "}
                        <HistoryTracking />
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path="/AdvanceDashboard"
                    element={
                      <ProtectedRoute path={"AdvanceDashboard"}>
                        <AdvanceDashboard />
                      </ProtectedRoute>
                    }
                  ></Route>

                  {/* Graphs */}
                  <Route
                    path="/DistanceGraph"
                    element={<DistanceGraph />}
                  ></Route>
                  <Route path="/ExIdleGraph" element={<ExIdleGraph />}></Route>
                  <Route path="/UsageGraph" element={<UsageGraph />}></Route>
                  <Route path="/IdleGraph" element={<IdleGraph />}></Route>
                  <Route
                    path="/MaxSpeedGraph"
                    element={<MaxSpeedGraph />}
                  ></Route>
                  <Route
                    path="/DashboardGraph"
                    element={<DashboardGraph />}
                  ></Route>
                  <Route
                    path="/DriverScoreGraph"
                    element={<DriverScoreGraph />}
                  ></Route>
                  <Route path="/FuelGraph" element={<FuelGraph />}></Route>
                  <Route path="/TempGraph" element={<TempGraph />}></Route>
                  <Route path="/SpeedGraph" element={<SpeedGraph />}></Route>

                  {/* warehouse monitoring */}
                  <Route
                    path="/warehousemonitoring"
                    element={<WarehouseMonitoring />}
                  ></Route>
                  {/* TemperatureDashboard */}
                  <Route
                    path="/temperaturedashboard"
                    element={<TemperatureDashboard />}
                  ></Route>
                  {/* Heat Map */}

                  <Route path="/heatmap" element={<HeatMapShow />}></Route>
                  {/* video monitoring */}
                  <Route
                    path="/videomonitoring"
                    element={<VideoMonitoring />}
                  ></Route>
                  {/* MultiVideo Monitoring */}
                  <Route
                    path="/multivideomonitoring"
                    element={
                      <ProtectedRoute path={"multivideomonitoring"}>
                        <MultiVideoMonitoring />
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path="/lounchingDashboard"
                    element={<LounchingDashboard />}
                  >
                    {" "}
                  </Route>

                  {/* <Route
                    path="/newwindow"
                    element={<NewWindow />}
                  >
                    {" "}
                  </Route> */}
                </Route>
              </Routes>
            </BrowserRouter>
          </CookiesProvider>
        </ConfigProvider>
      </StoreProvider>
    );
  }
}
BaseApp.contextType = StoreContext;
observer(BaseApp);

root.render(<BaseApp></BaseApp>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
