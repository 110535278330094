import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi,baseURL } from '../components/Utility'
import {
  action,
  
  makeObservable,
  observable,
 
} from 'mobx';


class AssetRegisteredReportStore {
  assetReport=[]
  total=0
  loading=false
  rootStore
  rec=0
  constructor(rootStore) {
    makeObservable(this, {
        assetReport: observable,
      total:observable,
      loading:observable,
      rec:observable,
      loadData:action,
      exportExcel:action,
      exportcsv:action,
      exportpdf:action,
      exportxml:action
     
    });
     this.rootStore = rootStore;
    
  }

  async loadData({offset,limit,companyId,fromDate,toDate}) {
    console.log("assetReport")
    this.loading=true;
    var postData = {"companyId":companyId,"fromDate":fromDate,"toDate":toDate,"offset":offset,"limit":limit,"page":1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=vehicleregisterreport", "postData="+base64Input);
    console.log("assetre[prt] " ,response?.data?.data)
   
    this.assetReport =response?.data?.data?.vehicleregister
    if(this.assetReport.length >0){
      this.rec=1
    }
     this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }

  async exportExcel(getDta){
    console.log(getDta)
    window.location.href = baseURL + "index.php?c=vehicle&a=exportvehicleregisterexcel&cid=" +getDta.companyId + '&fD=' + getDta.fromDate + '&tD=' + getDta.toDate + 
    // '&uId=' + getDta.userid + 
    '&colList=' + getDta.colList
  
  }
  async exportpdf(getDta){
    window.location.href = baseURL + "index.php?c=vehicle&a=exportvehicleregisterpdf&cid=" +getDta.companyId + '&fD=' + getDta.fromDate + '&tD=' + getDta.toDate + '&uId=' + getDta.userid + '&colList=' + getDta.colList
   
  }
  async exportcsv(getDta){
    window.location.href = baseURL + "index.php?c=vehicle&a=exportvehicleregistercsv&cid=" +getDta.companyId + '&fD=' + getDta.fromDate + '&tD=' + getDta.toDate + '&uId=' + getDta.userid + '&colList=' + getDta.colList
  }
  async exportxml(getDta){
    window.location.href = baseURL + "index.php?c=vehicle&a=exportvehicleregisterxml&cid=" +getDta.companyId + '&fD=' + getDta.fromDate + '&tD=' + getDta.toDate + '&uId=' + getDta.userid + '&colList=' + getDta.colList
  }
  

  
  

}

export default AssetRegisteredReportStore;
