import { encode as base64_encode } from "base-64";

import { postApi, baseURL } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class VehicleOdometerStore {
  vehicleOdometerVehicles = [];
  loading = false;
  total = 0;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      vehicleOdometerVehicles: observable,
      loading: observable,
      total: observable,
      loadData: action,
      exportExcel: action,
      addData: action,
      addOdometerByExcel:action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit, vehicleId }) {
    this.loading = true;

    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      offset: offset,
      limit: limit,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=odometer&a=getOdometerDetails",
        "postData=" + base64Input
      );

    this.vehicleOdometerVehicles = response?.data?.data?.odometer;
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async addData(vehicleOdometer) {
    var postData = vehicleOdometer;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=odometer&a=saveOdometer", "postData=" + base64Input);

    return response;
  }

  async exportExcel(getData) {
    console.log("getData", getData);
    window.location.href =
      baseURL +
      "index.php?c=odometer&a=sampleExcel&cid=" +
      getData.companyId +
      "&isadmin=" +
      getData.isadmin +
      "&userId=" +
      getData.userId +
      "&colList=" +
      getData.colList;
  }

  async addOdometerByExcel(excelFile) {
    console.log(excelFile)
    this.excelLoadig = true;
    var postData = excelFile;

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=odometer&a=odometerexcel", "postData=" + base64Input);
    this.excelLoadig = false;
    return response.data;
  }
}

export default VehicleOdometerStore;
