import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class JobRequestStore {
  jobRequest = [];
  jobState=[]
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      jobRequest: observable,
      jobState:observable,
      total: observable,
      loading: observable,
      loadData: action,
      addData: action,
      getJobRequestData:action
    });
    this.rootStore = rootStore;
  }

  async loadData({
    offset,
    limit,
    companyId,
    departmentId,
    jobTypeId,
    jobrequestDateFrom,
    jobrequestDateTo,
    proceesed,finish,pending
  }) {
    this.loading = true;
    console.log("Job requst",proceesed,finish,pending);

    var postData = {
      companyId: companyId,
      departmentId: departmentId,
      jobrequestDateFrom: jobrequestDateFrom,
      jobrequestDateTo: jobrequestDateTo,
      jobTypeId: jobTypeId,
      proceesed: proceesed,
      finish: finish,
      pending: pending,
      offset: 0,
      limit: 50,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=jobRequest&a=getJobRequest",
        "postData=" + base64Input
      );
    console.log("fuel detis ", response);
    this.jobRequest = response?.data?.data?.jobRequest;
    this.jobState = response?.data?.data?.jobState;
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async addData(jobReqDetails) {
    var postData = jobReqDetails;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=jobRequest&a=update", "postData=" + base64Input);
   console.log(response)
   return response.data;
  }

  async getJobRequestData(id) {
    console.log("Id", id);
    var postData = { jid: id, offset: 0, limit: 1 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=jobRequest&a=getJobRequest", "postData=" + base64Input);
    console.log("job Management ", response);
    // return response
    return response.data?.data?.jobRequest[0];
  }

  async deleteRecord({companyId,Id}){
    var postData ={"tokenId":"","companyId":companyId,"Id":Id}

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=jobRequest&a=delete", "postData="+base64Input);
    return response
  }


}

export default JobRequestStore;
