import React, { Component } from "react";
import { Button, Col, Form, Input, Row, Select, Spin, TimePicker } from "antd";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";

import CompanyAutocomplete from "./CompanyAutocomplete";

import CompanyStore from "../store/CompanyStore";
import TaxiDefaultParameterStore from "../store/TaxiDefaultParameterStore";
import dayjs from "dayjs";

class TaxiDefaultParameterAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = { loadingSpin: false, companyId: "", parameterId: -1 };

    this.taxiDefaultParaStore = new TaxiDefaultParameterStore(this);
    this.companyStore = new CompanyStore(this);
  }
  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      this.context.viewStateStore.setTaxiDefaultParaStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
    }
    const parameterId = this.props.parameterId;

    if (parameterId) {
      await this.filldata(parameterId);
    }

    this.setState({ update: 1 });
  }

  async filldata(parameterId) {
    const getTaxiData = await this.taxiDefaultParaStore.getTaxiData({
      parameterId,
      companyId: this.props.companyId,
    });

    await this.setState({
      companyId: getTaxiData.companyId,
      parameterId: getTaxiData.parameterId,
    });
    const singleData = await this.companyStore.getCompanyDataById(
      getTaxiData.companyId
    );

    this.formRef.current.setFieldsValue({
      companyId: singleData,
      parameterId: getTaxiData.parameterId,
      DigitalInput1: getTaxiData.DigitalInput1,
      DigitalInput2: getTaxiData.DigitalInput2,
      DigitalInput3: getTaxiData.DigitalInput3,
      DigitalInput4: getTaxiData.DigitalInput4,
      Speed_Distance: getTaxiData.Speed_Distance,
      analogInput1: getTaxiData.analogInput1,
      analogInput2: getTaxiData.analogInput2,
      dayTimeFrom: getTaxiData.dayTimeFrom
        ? dayjs(getTaxiData.dayTimeFrom, "HH:mm:ss")
        : null,
      dayTimeTo: getTaxiData.dayTimeTo
        ? dayjs(getTaxiData.dayTimeTo, "HH:mm:ss")
        : null,
      maxSpeed: getTaxiData.maxSpeed,
      maxSpeedInterval: getTaxiData.maxSpeedInterval,
      printer: getTaxiData.printer,
    });
  }

  onTaxiDetailsSubmit = async (fieldsValue) => {
    const data = {
      ...fieldsValue,
      TaxiParameterId: this.state.parameterId,
      companyId: this.state.companyId,
      dayTimeFrom: fieldsValue.dayTimeFrom
        ? fieldsValue.dayTimeFrom.format("HH:mm:ss")
        : "",
      dayTimeTo: fieldsValue.dayTimeTo
        ? fieldsValue.dayTimeTo.format("HH:mm:ss")
        : "",
    };

    const taxiDetails = {
      TaxiParameters: data,
    };

    this.setState({ loading: true });
    const response = await this.taxiDefaultParaStore.addData(taxiDetails);
    await this.props.response(response);

    this.setState({ loading: false });
  };
  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    if (!this.props.parameterId) {
      this.context.viewStateStore.setTaxiDefaultParaStates(comp.value);
    }
    await this.setState({ companyId: comp.value });
  }

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              ref={this.formRef}
              layout="vertical"
              name="nest-messages"
              onFinish={this.onTaxiDetailsSubmit}
              style={{ maxWidth: "100%", padding: 0 }}
              className="formClass"
            >
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate(
                        "_FLEET_DRIVER_DRIVERDETAIL_COMPANY_"
                      )}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => this.handleChange(value)}
                        style={{ width: "100%" }}
                        value={this.state.companyId}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="dayTimeFrom"
                    label={this.context.translate("_DAY_TIME_FROM_")}
                    rules={[{ required: true }]}
                  >
                    <TimePicker
                      format="HH:mm:ss"
                      style={{ width: "100%" }}
                    ></TimePicker>
                  </Form.Item>

                  <Form.Item
                    name="dayTimeTo"
                    label={this.context.translate("_DAY_TIME_TO_")}
                    rules={[{ required: true }]}
                  >
                    <TimePicker
                      format="HH:mm:ss"
                      style={{ width: "100%" }}
                    ></TimePicker>
                  </Form.Item>

                  <Form.Item
                    name="maxSpeed"
                    label={this.context.translate("_MAX_SPEED_1")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="maxSpeedInterval"
                    label={this.context.translate("_MAX_SPEED_INTERVAL_SEC_")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="Speed_Distance"
                    label={this.context.translate("_SPEED_DISTANCE_")}
                  >
                    <Select
                      options={[
                        {
                          value: "GPS",
                          label: "GPS",
                        },
                        {
                          value: "CAN/OBD",
                          label: "CAN/OBD",
                        },
                        {
                          value: "Pulse",
                          label: "Pulse",
                        },
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>

                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="DigitalInput1"
                    label={this.context.translate("_DIGITAL_INPUT_1")}
                  >
                    <Select
                      options={[
                        {
                          value: "Seat Sensor 1",
                          label: "Seat Sensor 1",
                        },
                        {
                          value: "Seat Sensor 2",
                          label: "Seat Sensor 2",
                        },
                        {
                          value: "Seat Sensor 3",
                          label: "Seat Sensor 3",
                        },
                        {
                          value: "Seat Sensor 4",
                          label: "Seat Sensor 4",
                        },
                        {
                          value: "Roof Light",
                          label: "Roof Light",
                        },
                        {
                          value: "Panic",
                          label: "Panic",
                        },
                      ]}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    name="DigitalInput2"
                    label={this.context.translate("_DIGITAL_INPUT_2")}
                  >
                    <Select
                      options={[
                        {
                          value: "Seat Sensor 1",
                          label: "Seat Sensor 1",
                        },
                        {
                          value: "Seat Sensor 2",
                          label: "Seat Sensor 2",
                        },
                        {
                          value: "Seat Sensor 3",
                          label: "Seat Sensor 3",
                        },
                        {
                          value: "Seat Sensor 4",
                          label: "Seat Sensor 4",
                        },
                        {
                          value: "Roof Light",
                          label: "Roof Light",
                        },
                        {
                          value: "Panic",
                          label: "Panic",
                        },
                      ]}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    name="DigitalInput3"
                    label={this.context.translate("_DIGITAL_INPUT_3")}
                  >
                    <Select
                      options={[
                        {
                          value: "Seat Sensor 1",
                          label: "Seat Sensor 1",
                        },
                        {
                          value: "Seat Sensor 2",
                          label: "Seat Sensor 2",
                        },
                        {
                          value: "Seat Sensor 3",
                          label: "Seat Sensor 3",
                        },
                        {
                          value: "Seat Sensor 4",
                          label: "Seat Sensor 4",
                        },
                        {
                          value: "Roof Light",
                          label: "Roof Light",
                        },
                        {
                          value: "Panic",
                          label: "Panic",
                        },
                      ]}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    name="DigitalInput4"
                    label={this.context.translate("_DIGITAL_INPUT_4")}
                  >
                    <Select
                      options={[
                        {
                          value: "Seat Sensor 1",
                          label: "Seat Sensor 1",
                        },
                        {
                          value: "Seat Sensor 2",
                          label: "Seat Sensor 2",
                        },
                        {
                          value: "Seat Sensor 3",
                          label: "Seat Sensor 3",
                        },
                        {
                          value: "Seat Sensor 4",
                          label: "Seat Sensor 4",
                        },
                        {
                          value: "Roof Light",
                          label: "Roof Light",
                        },
                        {
                          value: "Panic",
                          label: "Panic",
                        },
                      ]}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    name="analogInput1"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_SETTING_FUELVOLUME1_"
                    )}
                  >
                    <Select
                      options={[
                        {
                          value: "Seat Sensor 1",
                          label: "Seat Sensor 1",
                        },
                        {
                          value: "Seat Sensor 2",
                          label: "Seat Sensor 2",
                        },
                        {
                          value: "Seat Sensor 3",
                          label: "Seat Sensor 3",
                        },
                        {
                          value: "Seat Sensor 4",
                          label: "Seat Sensor 4",
                        },
                        {
                          value: "Roof Light",
                          label: "Roof Light",
                        },
                        {
                          value: "Panic",
                          label: "Panic",
                        },
                      ]}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    name="analogInput2"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_SETTING_FUELVOLUME2_"
                    )}
                  >
                    <Select
                      options={[
                        {
                          value: "Seat Sensor 1",
                          label: "Seat Sensor 1",
                        },
                        {
                          value: "Seat Sensor 2",
                          label: "Seat Sensor 2",
                        },
                        {
                          value: "Seat Sensor 3",
                          label: "Seat Sensor 3",
                        },
                        {
                          value: "Seat Sensor 4",
                          label: "Seat Sensor 4",
                        },
                        {
                          value: "Roof Light",
                          label: "Roof Light",
                        },
                        {
                          value: "Panic",
                          label: "Panic",
                        },
                      ]}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    name="printer"
                    label={this.context.translate("_PRINTER_")}
                  >
                    <Select
                      options={[
                        {
                          value: "USB",
                          label: "USB",
                        },
                        {
                          value: "Bluetooth",
                          label: "Bluetooth",
                        },
                        {
                          value: "RS232(ttsy1)",
                          label: "RS232(ttsy1)",
                        },
                        {
                          value: "RSR232(ttys4)",
                          label: "RSR232(ttys4)",
                        },
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>

              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal();
                  }}
                >
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="formSaveButton"
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}
TaxiDefaultParameterAdd.contextType = StoreContext;
export default observer(withRouter(TaxiDefaultParameterAdd));
