import React, { Component } from "react";
import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";
import dayjs from "dayjs";

import PredefinedMesgStore from "../store/PredefinedMessageStore";
const { TextArea } = Input;

class FAQAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loadingSpin: false,
      Id: -1,
    };

    this.predefinedMessageStore = new PredefinedMesgStore(this);
  }

  async componentDidMount() {
    const id = this.props.id;

    if (id) {
      await this.filldata(id);
    }

    this.setState({ update: 1 });
  }

  async filldata(id) {
    const getMsgData = await this.predefinedMessageStore.getMessageData(id);

    this.setState({ Id: getMsgData.id });

    this.formRef.current.setFieldsValue({
      // Id: getMsgData.id,
      message: getMsgData.message,

      status: getMsgData.status,
    });
  }

  onMsgFormSubmit = async (fieldsValue) => {
    const data = {
      predefinedMessageDetails: {
        Id: this.state.Id,
        message: fieldsValue.message,
        status: fieldsValue.status,
        dateTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      },
    };

    this.setState({ loading: true });
    const response = await this.predefinedMessageStore.addData(data);
    await this.props.response(response);

    this.setState({ loading: false });
  };

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
  }

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              ref={this.formRef}
              layout="vertical"
              name="nest-messages"
              onFinish={this.onMsgFormSubmit}
              style={{ maxWidth: "100%", padding: 0 }}
              //   className="formClass"
            >
              <Row className="overflowFormInputs">
                <Col span={24} className="formColumnClass">
                  <Form.Item
                    name="message"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_SETTING_MESSAGE_"
                    )}
                    rules={[{ required: true }]}
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <TextArea
                      style={{
                        position: "relative",
                        bottom: "-25px",
                        zIndex: 999,
                      }}
                      autoSize={{
                        minRows: 3,
                        maxRows: 5,
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="status"
                    label={this.context.translate("_STATUS_")}
                    rules={[{ required: true }]}
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    <Select
                      options={[
                        {
                          value: 1,
                          label: this.context.translate("_ACTIVE_"),
                        },
                        {
                          value: 0,
                          label: this.context.translate(
                            "_FLEET_USER_USERLIST_STATUS_INACTIVE_"
                          ),
                        },
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>

              <div className="formBtnGroup">
                <Button className="formCancelButton" onClick={this.closeModal}>
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="formSaveButton"
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

FAQAdd.contextType = StoreContext;
export default observer(withRouter(FAQAdd));
