import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";

import config from "../config";
import { action, makeObservable, observable } from "mobx";

class DashboardGraphStore {
  dashboardGraph = [];
  totalCount=[]
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      dashboardGraph: observable,
      totalCount:observable,
      total: observable,
      loading: observable,
      loadDashboardGraphData: action,
    });
    this.rootStore = rootStore;
  }

  async loadDashboardGraphData({ companyId }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=dashboard&a=getdashVehcount",
        "postData=" + base64Input
      );
    console.log("fuel detis ", response?.data?.data?.dashgraphvehcount);
    var dashboardGraphData = response?.data?.data?.dashgraphvehcount;
    var totalC=response?.data?.data?.dashgraphvehcount['TotalCount'];
    console.log(totalC)
    const convertedData = Object.entries(dashboardGraphData)
  .filter(([key]) => key !== 'TotalCount')
  .map(([name, value]) => ({ name,value: parseInt(value),TotalVehicle:totalC}));

console.log(convertedData);
    
    //  let element = dashboardGraphData
    //     element.Moving = parseInt(element.Moving);
    //     element.Idle = parseInt(element.Idle);
    //     element.NoData = parseInt(element.NoData);
    //     element.Stopped = parseInt(element.Stopped);

    //  console.log(element)
   this.totalCount =response?.data?.data?.dashgraphvehcount.TotalCount;
    this.dashboardGraph =convertedData;

    // this.distanceGraph = response?.data?.data?.distancegraph;
    return response?.data?.data?.dashgraphvehcount;
  }
}

export default DashboardGraphStore;


