import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'

import config from "../config";
import {
  action,

  makeObservable,
  observable,

} from 'mobx';


class UsageGraphStore {
    usageGraph=[]
  total = 0
  loading = false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        usageGraph:observable, 
      total: observable,
      loading: observable,
      loadUsageGraphData: action,
    
      
    });
    this.rootStore = rootStore;

  }

  async loadUsageGraphData({ offset, limit,companyId,vehicleId,distance,fromDate,toDate}) {
    this.loading = true;
    var postData = {"companyId":companyId,"vehicleId":vehicleId,"fromDate":fromDate,"toDate":toDate,"offset":0,"limit":15,"page":1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=usagegraphreport", "postData=" + base64Input);
    console.log("fuel detis ", response?.data?.data?.usagedata)
    var usageGraphData =response?.data?.data?.usagedata
    if (usageGraphData && (usageGraphData)) {
        usageGraphData.forEach((element) => {
        // Convert specific properties to floats
        element.TotalTravelDistance = parseFloat(element.TotalTravelDistance);
      });
    }
    this.usageGraph = usageGraphData

    // this.distanceGraph = response?.data?.data?.distancegraph;
    return response?.data?.data?.usagedata;
  }

  




 
}

export default UsageGraphStore;
