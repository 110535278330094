import React, { Component } from "react";

import { Form, Button, Input, Row, Col, Spin, Modal } from "antd";
import config from "../config";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";

import FuelDetailsStore from "../store/FuelDetailsStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { Link } from "react-router-dom";
import CompanyStore from "../store/CompanyStore";

class FuelDetailsAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      fuelId: -1,
      companyId: "",
      loadingSpin: false,
      disabledSaveButton: false,
    };

    this.fuelStore = new FuelDetailsStore(this);
    this.companyStore = new CompanyStore(this);
  }

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      this.context.viewStateStore.setFuelDetailsStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      console.log(this.state.companyId);
      // await this.setState({userId:this.props.getUserData().userId})
      // await this.setState({isadmin:this.props.getUserData().isadmin})
    }
    console.log(this.props.params);
    const Id = this.props.fuelId;

    if (Id) {
      await this.setState({ loadingSpin: true });
      console.log("tt", Id);
      const getFuelData = await this.fuelStore.getFuelDetails({ fuelId: Id });
      await this.setState({
        companyId: getFuelData.companyId,
        fuelId: getFuelData.fuelId,
      });
      const singleData = await this.companyStore.getCompanyDataById(
        getFuelData.companyId
      );
      console.log("getdata", getFuelData);
      this.setState({ selectedFuelData: getFuelData });
      this.formRef.current.setFieldsValue({
        Diesel: getFuelData.Diesel,
        petrol: getFuelData.petrol,
        companyId: singleData,
      });
      await this.setState({ loadingSpin: false });
    }
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  onFuelDetailsSubmit = async (values) => {
    this.setState({ loading: true });
    console.log(values);
    const FuelDetails = {
      ...values,
      fuelId: this.state.fuelId,
      companyId: this.state.companyId,
    };
    const data = {
      tokenId: "",
      FuelDetails: FuelDetails,
    };
    const response = await this.fuelStore.addData(data);
    this.props.response(response);
    // if (response?.success === "S") {
    //   message.success("Fuel Details Saved");
    // //   this.props.navigate("/fueldetailslist/" + this.state.companyId);
    // this.props.navigate("/fueldetailslist");
    // } else {
    //   message.error(response.message);
    // }
    this.setState({ loading: false });
  };
  async handleChange(comp) {
    await this.setState({ companyId: comp.value });

    await this.formRef.current.setFieldsValue({ companyId: comp.value });
    if (!this.props.fuelId) {
      this.context.viewStateStore.setFuelDetailsStates({
        companyId: comp.value,
      });
    }
    const data = await this.fuelStore.checkCompany({
      companyId: comp.value,
      fuelId: this.props.fuelId,
      fCompanyId: this.state.selectedFuelData?.companyId,
    });

    if (data?.count > 0) {
      Modal.warning({
        title: "Alert",
        content: "Company is selected for this fuel details."
      });

      this.setState({ disabledSaveButton: true });
    } else {
      this.setState({ disabledSaveButton: false });
    }
    await this.handleSearch();
  }

  async handleSearch() {
    await this.fuelStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  validateFieldsValue = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9.]*/g, "");
    console.log(value1);
    if (value1) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
      //   this.setState({ [field]: value1 })
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
      //   this.setState({ [field]: value1 })
    }
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              layout="vertical"
              onFinish={this.onFuelDetailsSubmit}
              // {...this.layout}
              name="nest-message"
              ref={this.formRef}
              className="formClass" //change
            >
              {/* <h1>Fuel details:</h1> */}

              {/* <div className="formBorder">
            <div className="headingTitle"> Fuel Details</div> */}
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => {
                          this.handleChange(value);
                        }}
                        companyId={this.state.companyId}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="petrol"
                    label={this.context.translate("_PETROL_")}
                  >
                    <Input
                      onChange={async (e) => {
                        await this.validateFieldsValue(
                          e.target.value,
                          "petrol"
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="Diesel"
                    label={this.context.translate("_DIESEL_")}
                  >
                    <Input
                      onChange={async (e) => {
                        await this.validateFieldsValue(
                          e.target.value,
                          "Diesel"
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  // type="primary"
                  onClick={(e) => {
                    this.closeModal();
                    // this.formRef.current?.resetFields();
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  className="formSaveButton"
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  disabled={this.state.disabledSaveButton}
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

FuelDetailsAdd.contextType = StoreContext;
export default observer(withRouter(FuelDetailsAdd));
