import {Loading3QuartersOutlined} from '@ant-design/icons';
import axios from 'axios';
import Cookies from 'universal-cookie';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import config from "../config";
import {

  message

} from "antd";

// import baseURL from '../config';

function LoadingIndicator() {
  return <Loading3QuartersOutlined 
    style={{
        fontSize: 24,
    }}
    spin
    />
}

 const baseURL= config.baseURL;






const postApi ={  
   

    getClient(){

      var cookies = new Cookies();
      let pData = cookies.get('data');
      let data = pData ? JSON.parse (base64_decode(cookies.get('data'))) : "";
    
        let objAxios = axios.create({
          baseURL: baseURL,
          headers: {'token': (data.userToken || "")}
  
        });

        
       

        objAxios.interceptors.response.use((response) => response, (error) => {
          console.log(error.response.status);
          if(error.response.status===403)
          {
            console.log(data);
         // const removeCookie = useCookies();
         cookies.remove(data);
          console.log(data);
           message.error("Token Expired, Please Login Again", 2);
           window.location =  window.location.protocol + "//" + window.location.host + "/newUI/login";

          }
          throw error;
        });

        return objAxios;

    }
}

export {postApi,baseURL,LoadingIndicator};