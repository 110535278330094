import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
// import { postApi } from '../components/Utility'
import {
    action,
    computed,
    makeObservable,
    observable,
    autorun,
    runInAction,
} from 'mobx';
import { act } from 'react-dom/test-utils';
import { postApi, baseURL } from "../components/Utility";

class MultiVideoMonitoringStore {
  
    vehicleArray=[]
    deviceObj={}
    constructor(rootStore) {
        makeObservable(this, {
            deviceObj:observable,
            vehicleArray:observable,
            getMultiDevice: action,
            getVehicles:action
          
        });
        this.rootStore = rootStore;

    }



async getVehicles (data){
   
    var postData = data
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);

this.vehicleArray = response.data?.data
}

    async getMultiDevice(data) {
        var postData = data
        const base64Input = await base64_encode(JSON.stringify(postData));
        var response = await postApi.getClient().post("index.php?c=videoStreaming&a=getMultiDevice", "postData=" + base64Input);
        this.deviceObj = {token:"54545454",imei:"5454"}
        console.log(response.data.data.vehicles)
        
    }

 


    
}

export default MultiVideoMonitoringStore;
