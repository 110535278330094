import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import config from "../config";

class VehicleMaintenanceStore {
  maintainVehicles = [];
  serviceData = [];
  loading = false;
  total = 0;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      maintainVehicles: observable,
      loading: observable,
      total: observable,
      loadData: action,
      addData: action,
      getMaintenanceGridData: action,
      getServiceData: action,
      getVehicleMaitenanceData: action,
      deleteRecord: action,
      sampleExcel: action,
      addVehMainByExcel:action,
      serviceData: observable,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    companyId,
    searchField,
    searchFieldDate,
    vehicleId,
    offset,
    limit,
  }) {
    this.loading = true;
    var postData = {
      tokenId: "80bovn8u800tg1aq9bvr73kbhh",
      searchField: searchField,
      // searchFieldDate: searchFieldDate,
      companyId: companyId,
      vehicleNo: vehicleId,
      userId: 1,
      isadmin: "-1",
      offset: offset,
      limit: limit,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=maintenance&a=getMain", "postData=" + base64Input);

    this.maintainVehicles = response?.data?.data?.maintenancedetails;
    this.total = parseInt(response.data.data?.TotalCount);
    this.loading = false;
  }

  async addData(maintenanceDetails) {
    var postData = maintenanceDetails;

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=maintenance&a=Newupdate", "postData=" + base64Input);

    return response;
  }

  async getServiceData() {
    var postData = "";

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=maintenance&a=getService", "postData=" + base64Input);

    this.serviceData = response?.data?.data;
    return response?.data?.data;
  }

  async getVehicleMaitenanceData({ Id, limit, offset, companyId }) {
    const postData = { mid: Id, limit: 1, offset: 0, companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=maintenance&a=getMain", "postData=" + base64Input);

    return response?.data?.data.maintenancedetails[0];
  }

  async deleteRecord({ Id }) {
    var postData = { tokenId: "", Id: Id };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=maintenance&a=delete", "postData=" + base64Input);
    return response;
  }

  async getMaintenanceGridData(id, companyId) {
    var postData = { maintenanceId: id };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=maintenance&a=getServiceGridData",
        "postData=" + base64Input
      );

    return response?.data?.data;
  }

  async sampleExcel() {
    window.location.href =
      config.fleetURL + "/appRes/excel/SampleMaintainance.xlsx";
  }

  async addVehMainByExcel(excelFile) {
    console.log(excelFile)
    this.excelLoadig = true;
    var postData = excelFile;

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=maintenance&a=maintenanceExcel", "postData=" + base64Input);
    this.excelLoadig = false;
    return response.data;
  }
}

export default VehicleMaintenanceStore;
