import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi,baseURL } from '../components/Utility'
import {
  action,
  
  makeObservable,
  observable,
 
} from 'mobx';


class RawDataReportStore {
  rawDataReport=[]
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        rawDataReport: observable,
      total:observable,
      loading:observable,
      loadData:action,
      exportExcel:action,
      exportcsv:action,
      exportpdf:action,
      exportxml:action
     
    });
     this.rootStore = rootStore;
    
  }

  async loadData({offset,limit,companyId,vehicleId,fromDate,toDate}) {
    this.loading =true;
    console.log("raw Data Report ")
    var postData = {"companyId":companyId,"vehicleId":vehicleId,"fromDate":fromDate,"toDate":toDate,"offset":offset,"limit":limit,"page":1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=rawdatareport", "postData="+base64Input);
    console.log("rawdata " ,response?.data?.data)
   
    this.rawDataReport =response?.data?.data?.rawdata
  
     this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }


  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportrawexcel&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportrawpdf&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportrawcsv&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportxml(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportrawxml&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }


  
  

}

export default RawDataReportStore;
