import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from "mobx";
import { act } from "react-dom/test-utils";
import moment from "moment";

class TrackByGroupStore {
  TrackData = [];
  total = 0;
  loading = false;
  Trackbygroupdata = [];
  newGroupdata = [];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      //  TrackData: observable,
      loading: observable,
      total: observable,
      Trackbygroupdata: observable,
      loadData: action,
      newGroupdata: observable,
      trackgroupdata: action,
      //   getTrackByGroupData: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ offset, limit, searchField, companyId }) {
    this.loading = true;
    console.log(companyId, "id");
    var postData = {
      companyId: companyId,
      userId: 1,
      isadmin: -1,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()

      .post(
        "index.php?c=devicegroup&a=trackByGroup",
        "postData=" + base64Input
      );

    console.log(response?.data?.data);
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.Trackbygroupdata = response?.data?.data;
    this.loading = false;
  }

  async trackgroupdata({ deviceGroupId, offset, limit, companyId }) {
    this.loading = true;
    // console.log(companyId, "id",deviceGroupId);
    var postData = {
      deviceGroupId: deviceGroupId,
      companyId: companyId,
      userCompany: 1,
      isadmin: -1,
      status: "normal",
      offset: 0,
      limit: 50,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()

      .post(
        "index.php?c=devicegroup&a=trackByGroupDetails",
        "postData=" + base64Input
      );

    var groupdata = response?.data?.data;
    if (groupdata && Object.keys(groupdata).length > 0) {
      groupdata = Object.values(groupdata).filter((value) => value !== false);

      Object.keys(groupdata).map((e, i) => {
        // console.log(i)

        let r = groupdata[i];
        //  var diff = new Date(r.currtime) - new Date(r.updatedate);
        const diff = moment(r.currtime).diff(r.updatedate, "minutes");
        var vehicleTypeNew = r.vehicleTypeNew?.split(" ").join("-");
        if (diff >= 90) {
          groupdata[i].listImage = vehicleTypeNew + "-gray.png";
          groupdata[i].rowBackground = "gray";
          groupdata[i].currentStatus = "nodata";
        } else if (r.acc == 1 && r.speed == 0) {
          //idle)
          groupdata[i].listImage = vehicleTypeNew + "-yellow.png";
          groupdata[i].rowBackground = "yellow";
          groupdata[i].currentStatus = "idle";
        } else if (r.acc == 0) {
          groupdata[i].listImage = vehicleTypeNew + "-red.png";
          groupdata[i].rowBackground = "red";
          groupdata[i].currentStatus = "stop";
        } else if (r.acc == 1 && r.speed > 0) {
          groupdata[i].listImage = vehicleTypeNew + "-green.png";
          groupdata[i].rowBackground = "green";
          groupdata[i].currentStatus = "running";
        }
        groupdata[i].mapImage = groupdata[i]?.listImage;

        try {
          require("../assets/images/detailtracking/" + groupdata[i].listImage);
        } catch (err) {
          groupdata[i].listImage = "car-icon-blue.png";
        }

        try {
          require("../assets/images/livetracking/" + groupdata[i].mapImage);
        } catch (err) {
          groupdata[i].mapImage = "car-icon-blue.png";
        }
      });
      this.newGroupdata = groupdata;
      // console.log(this.newGroupdata);
      this.loading = false;
    }
  }
}

export default TrackByGroupStore;
