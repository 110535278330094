import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import config from "../config";

class AudioMeassage {
  loading = false;
  total = 0;
  audiomessageList = [];
  photoFromDb = {};
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      total: observable,
      audiomessageList: observable,
      photoFromDb: observable,
      loadData: action,
      deleteRecord: action,
      addData: action,
      getAudioMesgData: action,
      setPhotoFromDb: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      offset: offset,
      limit: limit,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=audio&a=getAudioMessage", "postData=" + base64Input);

    this.audiomessageList = response?.data?.data?.audioMessage;
    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
  }

  async addData(audioDetails) {
    var postData = audioDetails;

    const base64Input = await base64_encode(JSON.stringify(postData));

    var response = await postApi
      .getClient()
      .post("index.php?c=audio&a=uploadAudio", "postData=" + base64Input);

    return response.data;
  }

  async getAudioMesgData({ id, companyId }) {
    var postData = {
      aId: id,
      companyId: companyId,
      limit: 1,
      offset: 0,
    };
    // console.log(postData)

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=audio&a=getAudioMessage", "postData=" + base64Input);

      const singleRow = response?.data?.data?.audioMessage[0];
      
      if(singleRow.path){
          
        const fileList = [{
            name: singleRow.path,
            status: 'success',
            url: config.fleetURLAdvertise+singleRow.path,
        }]
      
        this.setPhotoFromDb(fileList)
            
      }
      return singleRow

    
    // return response?.data?.data?.audioMessage[0];
  }

  async setPhotoFromDb(photo) {
   
    this.photoFromDb = { fileList: photo };
  }

  async deleteRecord({ id }) {
    const postData = {
      id: id,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=audio&a=delete", "postData=" + base64Input);

    return response;
  }
}

export default AudioMeassage;
