import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";

import config from "../config";
import { action, makeObservable, observable } from "mobx";

class DriverScoreGraphStore {
  driverScoreGraph = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      driverScoreGraph: observable,
      total: observable,
      loading: observable,
      loadDriverScoreGraphData: action,
    });
    this.rootStore = rootStore;
  }

  async loadDriverScoreGraphData({
    offset,
    limit,
    companyId,
    driverId,
    fromDate,
    toDate,
    Date,
  }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      driverId: driverId,
      fromDate: fromDate,
      toDate: toDate,
      reportType: 4,
      offset: 0,
      limit: 50,
      page: 1,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=driver&a=driverScoreGraph", "postData=" + base64Input);
    console.log("fuel", response?.data?.data?.driverscore);
    // var driverScoreGraphData = response?.data?.data?.driverscore;
    // if (maxSpeedGraphData && maxSpeedGraphData) {
    //   maxSpeedGraphData.forEach((element) => {
    //     element.maxSpeed = parseInt(element.maxSpeed);
    //   });
    // }
    this.driverScoreGraph = response?.data?.data?.driverscore;

    // this.distanceGraph = response?.data?.data?.distancegraph;
    return response?.data?.data?.driverscore;
  }

  async toGetDriverName(companyId) {
    // console.log(companyId,"dwee")
    var postData = {
      companyId: companyId,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=driver&a=getDriverData", "postData=" + base64Input);
    console.log(response, "response2135");
    this.getDriverName = response?.data?.data;
    console.log(this.getDriverName);
    // this.total = parseInt(response?.data?.data?.TotalCount);
    return response?.data?.data;
  }
}

export default DriverScoreGraphStore;
