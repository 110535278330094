import { Button, Col, Form, Row, Select, Space, message } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import withRouter from "../withRouter";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import AssignVehicleToJobRequestStore from "../store/AssignVehicleToJobRequest";

class AssignVehicleToJobRequest extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.assignVehicleToJobRequest = new AssignVehicleToJobRequestStore();
    this.state = {
      loadList: 0,
    };
  }
  async componentDidMount() {
    console.log(this.props);

    const data = await this.assignVehicleToJobRequest.getAssetData({
      companyId: this.props.companyId,
    });
    console.log(data, this.props.companyId);
    const data1 = await this.assignVehicleToJobRequest.getDriverData({
      companyId: this.props.companyId,
    });
    console.log(data1);
    this.setState({ loadList: 1 });
  }

  onAssignVehicleToJobRequest = async (values) => {
    console.log(values);
    const data = {
      ...values,
      JobRequestId: this.props.ID,
      companyId:this.props.companyId
    };
    console.log(data);
    const response = await this.assignVehicleToJobRequest.addData(data);
    console.log(response);

    await this.props.response(response);
    this.setState({ loading: false });
    // if (response.data.success === "S") {
    //   message.success("Vehicle assigned successfully");


    
    //   await this.props.navigate("/jobrequestlist");
    //   this.handleSearch();
    // } else {
    //   await message.error(response.data.message);
    // }
  };
  render() {
    return (
      <div style={{ padding: 0 }}>
      <div style={{ paddingBottom: "10px" }}>
        <Form
          style={{ maxWidth: "100%" }}
          ref={this.formRef}
          layout="vertical"
          onFinish={this.onAssignVehicleToJobRequest}
          {...this.layout}
          name="nest-message"
          className="formClass"
        >
          {/* <Row className="headerFixed"> */}
            {/* <Col span={12}>
              <div className="heading">Assign Vehicle To Job Request</div>
            </Col> */}

            {/* <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            > */}
              {/* <Space style={{}}>
                <Link to={"/jobrequestlist"}>
                  <Button ghost type="primary">
                    {" "}
                    Back{" "}
                  </Button>
                </Link>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button
                  ghost
                  danger
                  type="primary"
                  onClick={(e) => {
                    this.formRef.current?.resetFields();
                  }}
                >
                  Cancel
                </Button>
              </Space> */}
            {/* </Col>
          </Row> */}
          <Row className="overflowFormInputs">
            <Col span={24} className="formColumnClass">
              <Form.Item
                name="vehicleIds"
                // label="Asset Name"
                label={this.context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_")}
                rules={[{ required: true }]}
              >
                <Select
                  // style={{ width: 400 }}
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Select Asset"
                  placeholder={this.context.translate("_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_")}

                  optionFilterProp="children"
                  options={
                    this.assignVehicleToJobRequest?.getVehiclesUsingCompanyId
                  }
                />
              </Form.Item>

              <Form.Item
                name="driverId"
                // label="Driver"
                label={this.context.translate("_FLEET_VEHICLE_VEHICLELIST_DRIVER_")}
                rules={[{ required: true }]}
              >
                <Select
                  // style={{ width: 400 }}
                  fieldNames={{ label: "name", value: "driverId" }}
                  // placeholder="Select Driver"
                  placeholder={this.context.translate("_SELECT_DRIVER_")}

                  optionFilterProp="children"
                  options={
                    this.assignVehicleToJobRequest?.getDriversUsingCompanyId
                  }
                />
              </Form.Item>
            </Col>
            </Row>
            <div  className="formBtnGroup">
                {/* <Link to={"/jobrequestlist"}>
                  <Button ghost type="primary">
                    {" "}
                    Back{" "}
                  </Button>
                </Link> */}
               
                <Button
                  // ghost
                  // danger
                  // type="primary"
                  className="formCancelButton"
                  onClick={(e) => {
                    this.formRef.current?.resetFields();
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_DASHBORD_SHOWPOI_CANCELBTN_")}
                </Button>
                <Button type="primary" htmlType="submit"  className="formSaveButton">
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
          
        </Form>
      </div>
      </div>
    );
  }
}

AssignVehicleToJobRequest.contextType = StoreContext;
export default observer(withRouter(AssignVehicleToJobRequest));
