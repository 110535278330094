import React from "react";
import { Row, Col, Space, Tooltip, message } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import ListTable from "./ListTable";

import config from "../config";

import withRouter from "../withRouter";
import TaxiCustomersStore from "../store/TaxiCustomersStore";
import DeleteConfirm from "./DeleteConfirm";
import { DeleteFilled } from "@ant-design/icons";

class TaxiCustomers extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.taxiCustStore = new TaxiCustomersStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      loadData: 1,
    };
  }
  async componentDidMount() {
    await this.taxiCustStore.loadData({ offset: 0, limit: 50 });

    this.setState({ pageSize: 50, loadData: 1 });

    this.columns = [
      {
        title: this.context.translate("_USER_ID_"),
        dataIndex: "userId",
        width: "15%",
      },
      {
        title: this.context.translate("_NAME_"),
        dataIndex: "name",
        width: "15%",
      },
      {
        title: this.context.translate("_EMAIL_ADDRESS_"),
        dataIndex: "emailId",
        width: "20%",
      },
      {
        title: this.context.translate("_FLEET_COMPANY_USERDETAIL_PASSWORD_"),
        dataIndex: "password",
        width: "15%",
      },
      {
        title: this.context.translate("_FLEET_DRIVER_DRIVERLIST_MOBILE_"),
        dataIndex: "mobileNo",
        width: "20%",
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "action",
        width: "10%",
        render: (_, record) => {
          return (
            <>
              <Space>
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    // title="Delete Record"
                    title={this.context.translate("_DELETE_RECORD_")}
                    color={config.tooltipColorDelete}
                  >
                    <DeleteFilled style={{ color: config.deleteColor }} />
                  </Tooltip>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  async onDelete(record) {
    console.log(record);
    const response = await this.taxiCustStore.deleteRecord({
      userId: record.userId,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.taxiCustStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      page: this.state.currentPage,
    });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_TAXI_CUSTOMERS_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <ListTable
            currentPage={this.state.currentPage}
            total={this.taxiCustStore?.total}
            columns={this.columns}
            pageSize={this.state.pageSize}
            dataSource={this.taxiCustStore?.taxiCustList}
            loading={this.taxiCustStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>
      </>
    );
  }
}
TaxiCustomers.contextType = StoreContext;
export default observer(withRouter(TaxiCustomers));
