import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi,baseURL } from '../components/Utility'
import {
    action,
    computed,
    makeObservable,
    observable,
    autorun,
    runInAction,
} from 'mobx';
import { act } from 'react-dom/test-utils';


class DriverPerformanceReportStore {
    total=0
    driverPerformanceStore = []
    driverPerformanceArray=[]
    rootStore
    loading=false
    loadingData=false
    constructor(rootStore) {
        makeObservable(this, {

            total:observable,
            driverPerformanceArray:observable,
            driverPerformanceStore: observable,
            loadData: action,
            driverPerformanceTotal:action,
            loading:observable

        });
        this.rootStore = rootStore;

    }



    async loadData({companyId,driverId,offset,limit,fromDate,toDate,page}) {
      this.loading=true
        console.log(page)
        var postData ={"companyId":companyId,"driverId":driverId,"fromDate":fromDate,"toDate":toDate,"offset":offset,"limit":limit,"page":page}

        const base64Input = await base64_encode(JSON.stringify(postData));
        var response = await postApi.getClient().post("index.php?c=driver&a=driverPerformance", "postData="+base64Input);

        console.log(response?.data?.data,"driver")
        this.driverPerformanceStore = response?.data?.data?.driverperformance
        this.total = parseInt(response?.data?.data?.TotalCount)
        this.loading=false

    }

    async driverPerformanceTotal({companyId,driverId,offset,limit,fromDate,toDate,page}) {
      this.loading=true
        console.log(page)
        var postData ={"companyId":companyId,"driverName":driverId,"fromDate":fromDate,"toDate":toDate}

        const base64Input = await base64_encode(JSON.stringify(postData));
        var response = await postApi.getClient().post("index.php?c=driver&a=driverTotalperformance", "postData="+base64Input);

        console.log(response?.data?.data,"driver")
        this.driverPerformanceArray = response?.data?.data
        // this.total = parseInt(response?.data?.data?.TotalCount)
        this.loading=false

    }




    async exportExcel(getData) {
        console.log("getData", getData);
        let lang = "english";
        window.location.href =
          baseURL +
          "index.php?c=driver&a=exportdriverPerformanceExcel&cid=" +
          getData.companyId +
          "&userId=" +
          getData.userId +
          "&did=" +
          getData.Driver +
          
          "&fD=" +
          getData.fromDate +
          "&tD=" +
          getData.toDate +
          "&colList=" +
          getData.colList +
          "&language=" +
          lang;
      }
      async exportpdf(getData) {
        let lang = "english";
        window.location.href =
          baseURL +
          "index.php?c=driver&a=exportdriverPerformancePdf&cid=" +
          getData.companyId +
          "&userId=" +
          getData.userId +
          "&did=" +
          getData.Driver +
          
          "&fD=" +
          getData.fromDate +
          "&tD=" +
          getData.toDate +
          "&colList=" +
          getData.colList +
          "&language=" +
          lang;
      }
      async exportcsv(getData) {
        let lang = "english";
        window.location.href =
          baseURL +
          "index.php?c=driver&a=exportdriverPerformanceCsv&cid=" +
          getData.companyId +
          "&userId=" +
          getData.userId +
          "&did=" +
          getData.Driver +
          
          "&fD=" +
          getData.fromDate +
          "&tD=" +
          getData.toDate +
          "&colList=" +
          getData.colList +
          "&language=" +
          lang;
      }
      async exportxml(getData) {
        let lang = "english";
        window.location.href =
          baseURL +
          "index.php?c=driver&a=exportdriverPerformanceXml&cid=" +
          getData.companyId +
          "&userId=" +
          getData.userId +
          "&did=" +
          getData.Driver +
          
          "&fD=" +
          getData.fromDate +
          "&tD=" +
          getData.toDate +
          "&colList=" +
          getData.colList +
          "&language=" +
          lang;
      }
}

export default DriverPerformanceReportStore;
