import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class PaymentStore {
  payment = [];
  vehiclePayment = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      payment: observable,
      vehiclePayment: observable,
      total: observable,
      loading: observable,
      loadData: action,
      loadPaymentList: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ offset, limit, companyId }) {
    this.loading = true;
    console.log("Payment");

    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=payments&a=getPayment", "postData=" + base64Input);
    console.log("paymentd ", response.data.data.paymentList);

    this.payment = response?.data?.data.paymentList;
    // //  this.setCompanies(response?.data?.data?.companydata);
    //  this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async loadPaymentList({ companyId }) {
    this.loading = true;
    console.log("Payment",companyId);

    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=payments&a=getVehiclePayment",
        "postData=" + base64Input
      );
    console.log("paymentd ", response.data.data.paymentVehicleList);

    this.vehiclePayment = response?.data?.data.paymentVehicleList;

    this.loading = false;
  }
}

export default PaymentStore;
