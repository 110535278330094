import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'

import config from "../config";
import {
  action,

  makeObservable,
  observable,

} from 'mobx';


class EXIdleGraphStore {
    exidleGraph=[]
  total = 0
  loading = false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        exidleGraph:observable, 
      total: observable,
      loading: observable,
      loadExIdleGraphData: action,
    
      
    });
    this.rootStore = rootStore;

  }

  async loadExIdleGraphData({ offset, limit,companyId,vehicleId,fromDate,toDate}) {
    this.loading = true;
    var postData = {"companyId":companyId,"vehicleId":vehicleId,"fromDate":fromDate,"toDate":toDate,"offset":0,"limit":15,"page":1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicleidle&a=ExIdleGraph", "postData=" + base64Input);
    console.log("fuel detis ", response?.data?.data?.exIdlingReport)
    var exidleGraphData =response?.data?.data?.exIdlingReport
    if (exidleGraphData && (exidleGraphData)) {
        exidleGraphData.forEach((element) => {
          const timeComponents = element.duration.split(":");
        const hours = parseInt(timeComponents[0], 10);
        const minutes = parseInt(timeComponents[1], 10);
        const seconds = parseInt(timeComponents[2], 10);
          const durationInMinutes = hours * 60 + minutes + seconds / 60;

        element.duration = durationInMinutes;
      });
    }
    this.exidleGraph = exidleGraphData

    // this.distanceGraph = response?.data?.data?.distancegraph;
    return response?.data?.data?.exIdlingReport;
  }

  




 
}

export default EXIdleGraphStore;
