import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi, baseURL } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class CanBustReportStore {
  canBusReport = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      canBusReport: observable,
      total: observable,
      loading: observable,
      loadData: action,
      exportExcel: action,
      exportcsv: action,
      exportpdf: action,
      exportxml:action
    });
    this.rootStore = rootStore;
  }

  async loadData({companyId,offset, limit, vehicleId, fromDate, toDate,pageNo }) {
    this.loading = true;
    console.log("CanBus Report");
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      offset: offset,
      limit: limit,
      page: pageNo,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=canbusreport", "postData=" + base64Input);
    console.log("canbust ", response?.data?.data);

    this.canBusReport = response?.data?.data?.canbusdetail;

    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }
  async exportExcel(getData) {
    console.log(getData);
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportcanbusreportexcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exportpdf(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportcanbusreportpdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exportcsv(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportcanbusreportcsv&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exportxml(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportcanbusreportxml&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
}

export default CanBustReportStore;
