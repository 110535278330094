import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class TaxiDefaultParameterStore {
  loading = false;
  total = 0;
  taxiParameterList = [];

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      total: observable,
      taxiParameterList: observable,

      loadData: action,
      deleteRecord: action,
      getTaxiData: action,
      addData: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit, page }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      offset: offset,
      limit: limit,
      page: page,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=taxi&a=getDefaultTaxiParameter",
        "postData=" + base64Input
      );

    this.taxiParameterList = response?.data?.data?.defaultTaxiParameter;
    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
  }

  async addData(taxiDetails) {
    var postData = taxiDetails;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=taxi&a=updateDefaultTaxiParameter",
        "postData=" + base64Input
      );

    return response.data;
  }

  async getTaxiData({ parameterId, companyId }) {
    var postData = {
      parameterId: parameterId,
      companyId: companyId,
      limit: 1,
      offset: 0,
    };
    // console.log(postData)

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=taxi&a=getDefaultTaxiParameter",
        "postData=" + base64Input
      );

    return response?.data?.data?.defaultTaxiParameter[0];
  }

  async deleteRecord({ parameterId }) {
    const postData = { tokenId: "", taxiData: { parameterId: parameterId } };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=taxi&a=deleteDefaultTaxiParameter",
        "postData=" + base64Input
      );

    return response;
  }
}

export default TaxiDefaultParameterStore;
