import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,

  makeObservable,
  observable,

} from 'mobx';


class RoleStore {
  roles = []
  total = 0
  loading = false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
      roles: observable,
      total: observable,
      loading: observable,
      getRole:action,
      setRoles : action
     

    });
    this.rootStore = rootStore;

  }

  setRoles(value){
    this.roles = value;
  }

  async getRole(companyId) {
    // this.loading = true;
  
    var postData = {"companyId":companyId,"page":"userpage","isadmin":-1,"userId":1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=user&a=getroles", "postData=" + base64Input);
     
    let roles = [...response?.data?.data];
    

    this.setRoles(roles)
    
    return response?.data?.data

    
  }



}

export default RoleStore;
