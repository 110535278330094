import { encode as base64_encode } from "base-64";

import { postApi, baseURL } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class MaintenanceDashboardStore {
  maintenanceData = [];
  piChartDta = [];
  monthlyMaintenanceData = [];
  weeklyMaintenanceData = [];

  rootStore;
  constructor(rootStore) {
    makeObservable(this, {
      maintenanceData: observable,
      piChartDta: observable,
      getDashboardCount: action,
      getMaintenancePieChart: action,
      getData: action,
      getVehicleWeeklyGraph: action,
      getVehicleMonthlyGraph: action,
      addaction: action,
      updatedistanceAll: action,
    });
    this.rootStore = rootStore;
  }

  async getDashboardCount({ companyId, userId, isadmin }) {
    // this.loading = true
    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));

    var response = await postApi
      .getClient()
      .post(
        "index.php?c=maintenance&a=getDashboardCount",
        "postData=" + base64Input
      );
    // console.log(response?.data?.data, "vehicle");
    this.maintenanceData = response?.data?.data;
    this.loading = false;
  }

  async getData({ companyId, userId, isadmin, status,dueLimitTyre,dueLimit}) {
    if (status == "Running") {
      console.log("status", status);
      var postData = {
        companyId: companyId,
        userId: userId,
        vehicleStatus: status,
        isadmin: isadmin,
      };
      const base64Input = await base64_encode(JSON.stringify(postData));

      var response = await postApi
        .getClient()
        .post(
          "index.php?c=maintenance&a=getServiceReminderData",
          "postData=" + base64Input
        );
      // console.log(response?.data?.data, "vehicle");
      this.serviceReminderData = response?.data?.data?.maintenancereport;
    } else if (status == "Idle") {
      var postData = {
        companyId: companyId,
        userId: userId,
        vehicleStatus: status,
        isadmin: isadmin,
        dueLimitTyre:dueLimitTyre
      };
      const base64Input = await base64_encode(JSON.stringify(postData));

      var response = await postApi
        .getClient()
        .post(
          "index.php?c=maintenance&a=getTyreReminderData",
          "postData=" + base64Input
        );
      // console.log(response?.data?.data, "vehicle");
      this.tyreReminderData = response?.data?.data?.maintenancereport;
    } else if (status == "Stop") {
      var postData = {
        companyId: companyId,
        userId: userId,
        vehicleStatus: status,
        isadmin: isadmin,
        dueLimit:dueLimit
      };
      const base64Input = await base64_encode(JSON.stringify(postData));

      var response = await postApi
        .getClient()
        .post(
          "index.php?c=maintenance&a=getOilReminderData",
          "postData=" + base64Input
        );
      // console.log(response?.data?.data, "vehicle");
      this.oilReminderData = response?.data?.data?.maintenancereport;
    } else if (status == "exIdle") {
      var postData = {
        companyId: companyId,
        userId: userId,
        vehicleStatus: status,
        isadmin: isadmin,
      };
      const base64Input = await base64_encode(JSON.stringify(postData));

      var response = await postApi
        .getClient()
        .post(
          "index.php?c=maintenance&a=getMulkiyaExpiryData",
          "postData=" + base64Input
        );
      // console.log(response?.data?.data, "vehicle");
      this.mulkiyaReminderData = response?.data?.data?.maintenancereport;
    } else if (status == "NoData") {
      var postData = {
        companyId: companyId,
        userId: userId,
        vehicleStatus: status,
        isadmin: isadmin,
      };
      const base64Input = await base64_encode(JSON.stringify(postData));

      var response = await postApi
        .getClient()
        .post(
          "index.php?c=maintenance&a=getInsuranceExpiryData",
          "postData=" + base64Input
        );
      // console.log(response?.data?.data, "vehicle");
      this.insuranceExpiryData = response?.data?.data?.maintenancereport;
    } else if (status == "batteryData") {
      var postData = {
        companyId: companyId,
        userId: userId,
        vehicleStatus: status,
        isadmin: isadmin,
      };
      const base64Input = await base64_encode(JSON.stringify(postData));

      var response = await postApi
        .getClient()
        .post(
          "index.php?c=maintenance&a=getBatteryExpiryData",
          "postData=" + base64Input
        );
      // console.log(response?.data?.data, "vehicle");
      this.batteryExpiryData = response?.data?.data?.maintenancereport;
    }

    this.loading = false;
  }

  async getVehicleMonthlyGraph({ companyId, userId, isadmin }) {
    var postData = { companyId: companyId, userId: userId, isadmin: isadmin };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=maintenance&a=getVehicleMonthlyGraph",
        "postData=" + base64Input
      );
    console.log(response?.data?.data, "vehicle");
    // this.monthlyMaintenanceData = (response?.data?.data);
    var monthlyGraphData = response?.data?.data;
    console.log(monthlyGraphData);
    if (monthlyGraphData) {
      monthlyGraphData.forEach((element) => {
        element.sumAmount = Number(element.sumAmount);
      });
    }
    this.monthlyMaintenanceData = monthlyGraphData;

    console.log(this.monthlyMaintenanceData);
    this.loading = false;
  }

  async getVehicleWeeklyGraph({ companyId, userId, isadmin }) {
    var postData = { companyId, userId, isadmin };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=maintenance&a=getVehicleWeeklyGraph",
        "postData=" + base64Input
      );
    console.log(response?.data?.data, "vehicle");
    // this.weeklyMaintenanceData = (response?.data?.data);
    var weeklyGraphData = response?.data?.data;
    if (weeklyGraphData) {
      weeklyGraphData.forEach((element) => {
        element.sumAmount = Number(element.sumAmount);
      });
    }
    this.weeklyMaintenanceData = weeklyGraphData;

    console.log(this.weeklyMaintenanceData);
    this.loading = false;
  }

  async getMaintenancePieChart(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=maintenance&a=getMaintenancePieChart",
        "postData=" + base64Input
      );
    console.log(response?.data?.data, "vehicle");
    let piChartDta = response?.data?.data?.pieChart;

    piChartDta.forEach((val) => (val.value = val.data));
    this.piChartDta = piChartDta;
  }

  async addaction(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=updatedistanceService",
        "postData=" + base64Input
      );
    console.log(response, "adddept");
    return response;
  }

  async addactionfortyre(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=updatedistanceTyre",
        "postData=" + base64Input
      );
    console.log(response, "adddept");
    return response;
  }

  async addactionforoil(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=updatedistanceOil",
        "postData=" + base64Input
      );
    console.log(response, "adddept");
    return response;
  }

  async updatedistanceAll(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=updatedistanceAll",
        "postData=" + base64Input
      );

    return response;
  }

  async exportSerRemExcel(getData) {
    console.log("getData", getData);
    window.location.href =
      baseURL +
      "index.php?c=maintenance&a=exportServiceReminderDataExcel&cid=" +
      getData.companyId +
      "&userCompany=" +
      getData.userCompany +
      "&userId=" +
      getData.userId +
      "&vehicleStatus=" +
      getData.vehicleStatus +
      "&isadmin=" +
      getData.isadmin +
      "&colList=" +
      getData.colList;
  }

  async exportTyreRemExcel(getData) {
    console.log("getData", getData);
    window.location.href =
      baseURL +
      "index.php?c=maintenance&a=exportTyreReminderDataExcel&cid=" +
      getData.companyId +
      "&userCompany=" +
      getData.userCompany +
      "&userId=" +
      getData.userId +
      "&vehicleStatus=" +
      getData.vehicleStatus +
      "&dueLimitTyre=" +
      getData.dueLimitTyre +
      "&isadmin=" +
      getData.isadmin +
      "&colList=" +
      getData.colList;
  }

  async exportOilExcel(getData) {
    console.log("getData", getData);
    window.location.href =
      baseURL +
      "index.php?c=maintenance&a=exportOilReminderDataExcel&cid=" +
      getData.companyId +
      "&userCompany=" +
      getData.userCompany +
      "&userId=" +
      getData.userId +
      "&dueLimit=" +
      getData.dueLimit +
      "&vehicleStatus=" +
      getData.vehicleStatus +
      "&isadmin=" +
      getData.isadmin +
      "&colList=" +
      getData.colList;
  }

  async exportMulkiyaExcel(getData) {
    console.log("getData", getData);
    window.location.href =
      baseURL +
      "index.php?c=maintenance&a=exportMulkiyaExpiryExcel&cid=" +
      getData.companyId +
      "&userCompany=" +
      getData.userCompany +
      "&userId=" +
      getData.userId +
      "&vehicleStatus=" +
      getData.vehicleStatus +
      "&isadmin=" +
      getData.isadmin +
      "&colList=" +
      getData.colList;
  }

  async exportInsuranceExcel(getData) {
    console.log("getData", getData);
    window.location.href =
      baseURL +
      "index.php?c=maintenance&a=exportInsuranceExpiryDataExcel&cid=" +
      getData.companyId +
      "&userCompany=" +
      getData.userCompany +
      "&userId=" +
      getData.userId +
      "&vehicleStatus=" +
      getData.vehicleStatus +
      "&isadmin=" +
      getData.isadmin +
      "&colList=" +
      getData.colList;
  }

  async exportBatteryExcel(getData) {
    console.log("getData", getData);
    window.location.href =
      baseURL +
      "index.php?c=maintenance&a=exportBatteryExpiryDataExcel&cid=" +
      getData.companyId +
      "&userCompany=" +
      getData.userCompany +
      "&userId=" +
      getData.userId +
      "&vehicleStatus=" +
      getData.vehicleStatus +
      "&isadmin=" +
      getData.isadmin +
      "&colList=" +
      getData.colList;
  }
}

export default MaintenanceDashboardStore;
