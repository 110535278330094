import React from "react";
import { Row, Col, Space, Image, Collapse } from "antd";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import TrackByGroupStore from "../store/TrackByGroupStore";
import { unset } from "lodash";
import { Link } from "react-router-dom";
import MapForVehiclePosition from "./MapForVehiclePosition";
import withRouter from "../withRouter";

const { Panel } = Collapse;
class TrackByGroup extends React.Component {
  constructor(props) {
    super(props);
    this.TrackByGroupStore = new TrackByGroupStore(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.state = {
      companyId: "",
      loading: false,
      loadData: 0,
      pageSize: 50,
      offset: 0,
      limit: 50,
      pageNo: 1,
      vehicleObj: false,
      show: false,
      id: "",
    };
  }

  // Assuming 'data' is part of the component's state or class property

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleSearch();
    }
    this.columns = [
      {
        // title: "Company",
        title: this.context.translate("_FLEET_DASHBORD_NEWPOI_COMPANY_"),
        dataIndex: "companyName",
        // width: "15%",
        key: "companyName",
      },
      {
        // title: "Vehicle No",
        title: this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        // width: "15%",
        key: "vehicleNo",
        render: (_, record) => {
          //   console.log("recrd", record.listImage);
          return (
            <Space>
              <Image
                width={30}
                height={15}
                src={require("../assets/images/detailtracking/" +
                  record.listImage)}
              />

              {record.vehicleNo}
            </Space>
          );
        },
      },
      {
        // title: "Type",
        title: this.context.translate("_FLEET_DASHBORD_NEWPOI_TYPE_"),
        dataIndex: "vehicleTypeNew",
        // width: "15%",
        key: "vehicleTypeNew",
      },
      {
        // title: "Driver",
        title: this.context.translate(
          "_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"
        ),
        dataIndex: "name",
        // width: "15%",
        key: "name",
      },
      {
        // title: "Location",
        title: this.context.translate(
          "_FLEET_DASHBORD_HISTORY_TOOLTIP_LOCATION_"
        ),
        dataIndex: "formatted_address",
        // width: "15%",
        key: "formatted_address",
        render: (value, obj) => {
          return (
            <Link
              onClick={async (e) => {
                // await this.setState({ showMap: true, modalInfoObj: obj });
                await this.setState({
                  vehicleObj: { ...obj, icon: "logo_icon.png" },
                  heading: "Location On Map",
                });
                await this.setState({ loadData: 1 });
              }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        // title: "Ignition",
        title: this.context.translate("_IGNITION_"),
        dataIndex: "acc",
        // width: "15%",
        key: "acc",
        render: (value) => {
          if (value == 1) {
            var img = "ignition-icon-on.png";
          } else {
            var img = "ignition-icon-off-red.png";
          }
          return (
            <>
              <Image
                src={require("../assets/images/detailtracking/" + img)}
                width={20}
                height={20}
              />
            </>
          );
        },
      },
      {
        // title: "Speed (km)",
        title: this.context.translate("_SPEED_KM_"),
        dataIndex: "speed",
        // width: "15%",
        key: "Speed",
      },
      {
        // title: "Time",
        title: this.context.translate("_FLEET_DASHBORD_RULETRIGGER_TIME_"),
        dataIndex: "updatedate",
        // width: "15%",
        key: "updatedate",
        render: (value) => {
          const formattedDateTime = dayjs(value).format(
            "DD-MM-YYYY hh:mm:ss A"
          );
          return <>{formattedDateTime}</>;
        },
      },
      {
        // title: "GPS",
        title: this.context.translate("_GPS_"),
        dataIndex: "GPS",
        // width: "15%",
        key: "GPS",
        render: (value) => {
          return (
            <>
              <Image
                src={require("../assets/images/detailtracking/gps-icon-on-green.png")}
                width={20}
                height={20}
              />
            </>
          );
        },
      },
      {
        // title: "Alert",
        title: this.context.translate("_FLEET_ALERT_"),
        dataIndex: "alert",
        // width: "15%",
        key: "alert",
        render: (value) => {
          if (value == 0) {
            var img = "alert-icon-green.png";
          } else {
            var img = "alert-icon-red.png";
          }
          return (
            <>
              <Image
                src={require("../assets/images/detailtracking/" + img)}
                width={20}
                height={20}
              />
            </>
          );
        },
      },
      {
        // title: "Odometer",
        title: this.context.translate("_FLEET_DASHBORD_SETTING_ODOMETER_"),
        dataIndex: "distance",
        // width: "15%",
        key: "distance",
      },
    ];
    await this.setState({ loadList: 1 });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.vehicleObj !== prevState.vehicleObj) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }
  }
  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      // currentPage: 1,
      // pagesize: 50,
    });
    // await this.vehicleStore.getVehiclesCompanyId({ companyId: comp.value });
    await this.handleSearch();
    await this.setState({ update: 1 });
    // console.log("data", data);
  }

  async handleSearch() {
    await this.TrackByGroupStore.loadData({
      companyId: this.state.companyId,
    });
    await this.setState({ loadData: 1 });
  }

  // handleclick = async (key) => {
  //     console.log(key)
  //     this.setState({ id: deviceGroupId })
  //     this.setState({ show: true })
  //     console.log(deviceGroupId)
  //     await this.TrackByGroupStore.trackgroupdata({
  //         deviceGroupId: deviceGroupId,
  //         companyId: this.state.companyId,
  //         offset: this.state.offset,
  //         limit: this.state.limit
  //     });

  // };
  onChange = async (key) => {
    // console.log(key);
    // await this.setState({ id: key });
    // await this.setState({ show: true });
    // console.log(key);
    await this.TrackByGroupStore.trackgroupdata({
      deviceGroupId: key[0],
      companyId: this.state.companyId,
      // offset: this.state.offset,
      // limit: this.state.limit,
    });
    await this.setState({ loadData: 1 });
  };
  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Track By Group</div> */}
              <div className="heading">
                {this.context.translate("_TRACK_BY_GROUP_")}

              </div>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={this.handleChange}
                  style={{ width: 200 }}
                />
              </Space>
            </Col>
          </Row>

          <Row>
            <div style={{ width: "100%", borderWidth: 0 }}>
             <div className="pageTitle">   {this.context.translate("_GROUP_NAME_")}</div>
             
             
              {/* {this.TrackByGroupStore?.Trackbygroupdata.map((item) => (
                            <Collapse accordion  onChange={()=>{console.log('change'); this.handleclick(item.deviceGroupId)}}>
                                
                                    <Panel
                                        
                                        // onClick={() => this.handleclick(item.deviceGroupId)}
                                        key={item.deviceGroupId}
                                        header={
                                            <p style={{ margin: 0 }}>
                                                {item.groupname}({item.vehicleCount})
                                            </p>
                                        }
                                       
                                        // showArrow={false}
                                        forceRender={true}
                                        style={{ background: '#4096ffe3', border: '1px solid #d9d9d9', borderRadius: 4, marginBottom: 12, overflow: 'hidden', borderCollapse: unset }}

                                       
                                    >
                                        {
                                         <ListTable
                                            // currentPage={this.state.pageNo}
                                            // pageSize={this.state.pageSize}
                                            pagination={false}
                                            total={this.TrackByGroupStore?.total}
                                            columns={this.columns}
                                            loading={this.TrackByGroupStore?.loading}
                                            dataSource={this.TrackByGroupStore?.groupdata}
                                            // Pass any additional props or data needed by ListTable
                                            deviceGroupId={item.deviceGroupId}
                                        />
    }
                                    </Panel>
                                    </Collapse>
                                ))} */}

              <Collapse
                size="small"
                accordion
                items={this.TrackByGroupStore?.Trackbygroupdata?.map(
                  (item) => ({
                    key: item.deviceGroupId,
                    label: (
                      <div>
                        {item.groupname} ({item.vehicleCount})
                      </div>
                    ),

                    children: (
                      <ListTable
                        pagination={false}
                        // total={this.TrackByGroupStore?.total}
                        columns={this.columns}
                        // loading={this.TrackByGroupStore?.loading}
                        dataSource={this.TrackByGroupStore?.newGroupdata}
                        deviceGroupId={item.deviceGroupId}
                      />
                    ),
                  })
                )}
                onChange={this.onChange}
                className="collapse-panel"
                // style={{
                //   background: "#4096ffe3",
                //   border: "1px solid #d9d9d9",
                //   borderRadius: 4,
                //   marginBottom: 12,
                //   overflow: "hidden",
                //   borderCollapse: unset,
                // }}
              />
            </div>
          </Row>

          {/* <ListTable
                        currentPage={this.state.pageNo}
                        pageSize={this.state.pageSize}
                        total={this.TrackByGroupStore?.total}
                        columns={this.columns}
                        // dataSource={this.TrackByGroupStore?.Trackbygroupdata}
                        loading={this.TrackByGroupStore?.loading}
                        onChange={this.handleTableChange}
                  
                    /> */}

          {this.state.vehicleObj && (
            <MapForVehiclePosition
              vehicleObj={this.state.vehicleObj}
              heading={this.state.heading}
              closeMapModal={(resp) => {
                this.setState({ vehicleObj: resp });
              }}
            />
          )}
        </div>
      </>
    );
  }
}

TrackByGroup.contextType = StoreContext;
export default observer(withRouter(TrackByGroup));
