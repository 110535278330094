import { observer } from "mobx-react";
import React, { Component } from "react";
import withRouter from "../withRouter";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Empty,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { StoreContext } from "../store/RootStore";
import DistanceGraphStore from "../store/DistanceGraphStore";
import dayjs from "dayjs";

import IdleExIdleGraphComponent from "./IdleExIdleGraphComponent";
import EXIdleGraphStore from "../store/ExIdleGraphStore";
import VehicleStore from "../store/VehicleStore";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";

const { Option } = Select;
class ExIdleGraph extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.distanceGraphStore = new DistanceGraphStore(this);
    this.exidleGraphStore = new EXIdleGraphStore(this);
    this.vehicleStore =new VehicleStore(this);

    this.state = {
      selectAllVehicles: false,
      selectedVehicles: [],
      update: 0,
      companyId: "",
      distance: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      loadData: 0,

      displayVehicleNo: [],
      newToDate: dayjs().format("DD-MM-YYYY hh:mm A"),
      newFromDate: dayjs().startOf("day").format("YYYY-MM-DD hh:mmA"),
      // newFromDate: dayjs().format("DD-MM-YYYY 12:00 A"),
      showExIdleGraph: false,
      Notcompany: false,
      Notasset: false,
      loadingSpin: false,
    };
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });
    await this.setState({ selectedVehicles: [], selectAllVehicles: false });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);


    this.setState({ loadData: 1 });
  }

  

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map((val) => {
        return val.vehicleId;
      });
      const allVehiclesArr = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleNo;
        }
      );
      await this.setState({
        selectedVehicles: allVehicles,
        displayVehicleNo: allVehiclesArr,
      });
    } else {
      await this.setState({ selectedVehicles: [], displayVehicleNo: [] });
    }
  };

  getRandomColor = () => {
    // Generate a random hex color
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  };

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      // this.setState({mandatory:false})
      await this.setState({ loadingSpin: true });

      this.setState({ Notcompany: false, Notasset: false });
      const data = await this.exidleGraphStore.loadExIdleGraphData({
        companyId: this.state.companyId,
        vehicleId: this.state.selectedVehicles,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        distance: this.state.distance,
        // pageNo: this.state.pageNo,
      });
      console.log(data);
      this.setState({ loadData: 1 });
      await this.setState({ loadingSpin: false });
    }
  };

  handleDateChange = (date) => {
    if (date === null) {
      this.setState({ fromDate: "" });
    } else {
      // const formattedDate = date.startOf("day");
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({ fromDate: date.format(format) });
      console.log(date.format(format));
      const newFromDate = date;
      const newDateFormat = "DD-MM-YYYY hh:mm A";
      this.setState({ newFromDate: newFromDate.format(newDateFormat) });
    }
  };

  handleToDateChange = (date) => {
    if (date === null) {
      this.setState({ toDate: "" });
    } else {
      const formattedDate = date;
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({ toDate: formattedDate.format(format) });
      console.log(formattedDate.format(format));
      const newToDate = date;
      const newDateFormat = "DD-MM-YYYY hh:mm A";
      this.setState({ newToDate: newToDate.format(newDateFormat) });
    }
  };


  async componentDidMount(){
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId)
      await this.setState({ companyId: this.props.getUserData().userCompanyId })
      await this.setState({ userId: this.props.getUserData().userId })
      await this.vehicleStore.getVehiclesCompanyId(
        this.props.getUserData().userCompanyId
      );
      await this.setState({ update: 1 });
    }
  }

  render() {
    return (
      <>
        <Spin spinning={this.state.loadingSpin} fullscreen>
          <div style={{ padding: 0 }}>
            <Row className="headerFixed" style={{ height: "50px" }}>
              <Col span={12}>
                <div className="heading">
                  {this.context.translate("_EXIDLE_GRAPH_")}
                </div>
              </Col>
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>
          </div>
          <div
            style={{
              paddingTop: "10px",
              paddingRight: "24px",
              paddingLeft: "24px",
            }}
          >
            <Row className="listInputRow">
              <Col span={22}>
                <Space>
                  <CompanyAutocomplete
                    onChange={this.handleChange}
                    allowClear={true}
                    style={{ width: 175 }}
                    status={this.state.Notcompany ? "error" : false}
                  />
                  {this.props.getUserData().isadmin === 1 && (
                    <DeviceGroupsForVehicles
                      vehicleStore={this.vehicleStore}
                      setLoadDta={() => this.setState({ loadData: 1 })}
                      resetVehicles={() =>
                        this.setState({
                          selectedVehicles: [],
                          selectAllVehicles:false
                        })
                      }
                    />
                  )}
                  <Checkbox
                    checked={this.state.selectAllVehicles}
                    onChange={async (e) => {
                      await this.setState({
                        selectAllVehicles: e.target.checked,
                      });
                      console.log(e.target.checked);
                      await this.handleAllSelectVehicles();
                    }}
                  />
                  {/* All */}
                  {this.context.translate("_ALL_")}
                 
                    <Select
                    mode="multiple"
                    maxTagCount="responsive"
                    value={this.state.selectedVehicles}
                    onChange={async (val,x) => {
                      this.setState({
                        selectedVehicles: val,
                        Notasset: false,
                        vertical: false,
                        horizontal: false,
                      });
                      await this.setState({
                        displayVehicleNo: x.map((item) => item.children),
                      });
                      if (
                        this.vehicleStore?.getVehiclesUsingCompanyId.length >
                        val.length
                      ) {
                        this.setState({ selectAllVehicles: false });
                      } else {
                        this.setState({ selectAllVehicles: true });
                      }
                    }}
                    // fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                    // placeholder="Asset Name"
                    placeholder={this.context.translate(
                      "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                    )}
                    optionFilterProp="children"
                    // options={this.distanceGraphStore?.vehiclesArray}
                    style={{ width: 175 }}
                    status={this.state.Notasset ? "error" : false}

                    filterOption={(inputValue, option) => {
                      if (option.children) {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                      return (
                        <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                      );
                    })}
                  </Select>
                  <DatePicker
                    showTime
                    format="DD-MM-YYYY hh:mmA"
                    placeholder={this.context.translate("_FROM_DATE_")}
                    //   onChange={this.handleDateChange}
                    value={
                      this.state.fromDate ? dayjs(this.state.fromDate) : null
                    }
                    onChange={(val) => {
                      this.handleDateChange(val);
                    }}
                    disabledDate={(current) => {
                      return current && current > dayjs();
                    }}
                  />
                  <DatePicker
                    showTime
                    format="DD-MM-YYYY hh:mmA"
                    placeholder={this.context.translate("_TO_DATE_")}
                    onChange={this.handleToDateChange}
                    value={this.state.toDate ? dayjs(this.state.toDate) : null}
                    disabledDate={(current) => {
                      return current && current > dayjs();
                    }}
                  />
                  <Button
                    type="primary"
                    onClick={async () => {
                      await this.showData();
                      await this.setState({ showExIdleGraph: true });
                      // console.log(this.distanceGraphStore?.distanceGraph);
                    }}
                  >
                    {/* Show */}
                    {this.context.translate("_FLEET_SHOW_BTN_")}
                  </Button>
                </Space>
              </Col>
              <Col span={2}>
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>

            <div
              style={{
                paddingTop: "10px",
                paddingRight: "24px",
                paddingLeft: "24px",
              }}
            >
              {this.state.showExIdleGraph &&
                this.exidleGraphStore.exidleGraph?.length > 0 && (
                  <Row className="listInputRow">
                    <Col span={22}>
                      <div className="heading">
                        {this.context.translate("_EXIDLE_GRAPH_")}
                      </div>
                      <b>
                        {this.context.translate(
                          "_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"
                        )}
                      </b>
                      : <span>{this.state.displayVehicleNo?.join(",")}</span>
                      <br />
                      <b>
                        {this.context.translate("_FROM_DATETIME_")} :{" "}
                        {this.state.newFromDate}
                      </b>
                      <br />
                      <b>
                        {this.context.translate("_TO_DATETIME_")}:{" "}
                        {this.state.newToDate}
                      </b>
                      <br />
                    </Col>
                    <Col
                      span={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      <Space
                        style={{
                          float:
                            this.context.locale.direction == "ltr"
                              ? "right"
                              : "left",
                        }}
                      ></Space>
                    </Col>
                  </Row>
                )}
            </div>
            {this.state.showExIdleGraph &&
            this.exidleGraphStore.exidleGraph?.length > 0 ? (
              <IdleExIdleGraphComponent
                data={this.exidleGraphStore.exidleGraph}
                // yAxisLabel={"Ex-Idle Duration(In Min)"}
                yAxisLabel={this.context.translate(
                  "_EXIDLE_DURATION_IN_MINUTE_"
                )}
                graphName={this.context.translate("_EX_IDLE_")}
              />
            ) : (
              <Empty></Empty>
            )}
          </div>
        </Spin>
      </>
    );
  }
}
ExIdleGraph.contextType = StoreContext;
export default observer(withRouter(ExIdleGraph));
