import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Col,
  Space,
  Form,
  Input,
  Row,
  Select,
  Upload,
  message,
} from "antd";

import { makeObservable, observable, computed, action, flow } from "mobx";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";

import EmailMessageStore from "../store/EmailMessageStore";
import TextArea from "antd/es/input/TextArea";
import withRouter from "../withRouter";

const { Option } = Select;
/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

class EmailMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      allCompany: false,
      loadList: 0,
      companyId: [],
    };

    this.emailMsgStore = new EmailMessageStore(this);
    this.formRef = React.createRef();
  }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleSearch();
    }
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    await this.handleSearch();
  }

  async handleSearch() {
    await this.emailMsgStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      // warehouseId:this.state.warehouseId,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 0 });
  }

  emailSubmit = async (fieldsValue) => {
    console.log(fieldsValue);
    const data = { ...fieldsValue, companyId: this.state.companyId };
    const response = await this.emailMsgStore.sendEmailMessage(data);
    console.log(response);
    // console.log(JSON.parse(base64_decode(response.data)))
    if (response?.data.success === "S") {
      message.success(response.data.message);
      console.log(response.data.message);
    } else if (response.data.error) {
      message.error(response.data.error);
      console.log(response.data.error);
    } else {
      // message.error("Invalid Address");
      message.error(this.context.translate("_INVALID_ADDRESS_"));
    }
  };

  getCompany = async (e) => {
    this.setState({ allCompany: e.target.checked });
    if (e.target.checked) {
      const companyId = this.emailMsgStore.allCompanyArray.map((val) => {
        return parseInt(val.companyId);
      });
      await this.setState({ allCompany: companyId });
      await this.formRef.current.setFieldsValue({ companyId: companyId });
      console.log(companyId);
    } else {
      await this.formRef.current.setFieldsValue({ companyId: [] });
    }
    // await this.handleSearch();
    console.log(this.emailMsgStore.allCompanyArray);
    await this.setState({ loadList: 1 });
  };

  render() {
    return (
      <>
        {/* <div className="changePassHeadline">Email Message</div> */}
        <div className="changePassHeadline">
          {this.context.translate("_FLEET_RULEADMIN_RULEDETAIL_EMAILMESSAGE_")}
        </div>
        <div className="changePassLayout">
          <Form
            ref={this.formRef}
            layout="vertical"
            name="nest-messages"
            onFinish={this.emailSubmit}
            className="changePassForm"
          >
            {/* <div className="formBorder"> */}
            {/* <div className="headingTitle">Email Message</div> */}

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {(this.props.getUserData().isadmin === -1 ||
                this.props.getUserData().isadmin === 3) && (
                <>
                  <Form.Item
                    name="companyId"
                    className="search-field"
                    label={this.context.translate("_ACCOUNT_")}
                    rules={[{ required: true }]}
                    style={{ width: "80%" }}
                  >
                    <Select
                      value={this.state.companyId}
                      showSearch
                      mode="multiple"
                      maxTagCount={"responsive"}
                      onChange={async (val) => {
                     
                        await this.setState({ companyId: val });
                       
                      }}
                      // placeholder=" Select Account"
                      placeholder={this.context.translate(
                        "_FLEET_ROLE_ROLEDETAIL_COMPANY_"
                      )}
                      filterOption={(inputValue, option) => {
                        if (option.children) {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) >= 0
                          );
                        }
                      }}
                    >
                      {this.emailMsgStore?.allCompanyArray?.map((val) => {
                        return (
                          <Option value={parseInt(val.companyId)}>
                            {val.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item label=" ">
                    <Checkbox
                      checked={this.state.allCompany}
                      onChange={async (e) => {
                        await this.setState({ allCompany: e.target.checked });
                        this.getCompany(e);
                      }}
                    >
                      {/* All */}
                      {this.context.translate("_ALL_")}
                    </Checkbox>
                  </Form.Item>
                </>
              )}
            </div>

            <Form.Item
              name="subject"
              // label="Subject"
              label={this.context.translate("_SUBJECT_")}
              rules={[{ required: true }]}
            >
              <Input placeholder={this.context.translate("_SUBJECT_")} />
            </Form.Item>
            <Form.Item
              name="emailMessage"
              // label="Email Body"
              label={this.context.translate("_EMAIL_BODY_")}
              rules={[{ required: true }]}
            >
              <TextArea
                // showCount
                maxLength={1000}
                style={{
                  resize: "none",
                  height: "85px",
                }}
              />
              {/* <Input  placeholder="Email Message" /> */}
            </Form.Item>

            <div className="formBtnGroup">
              <Button
                className="formCancelButton"
                onClick={(e) => {
                  this.formRef.current?.resetFields();
                  this.setState({ allCompany: false });
                }}
              >
                {/* Cancel */}
                {this.context.translate("_FLEET_CLEAR_BTN_")}
              </Button>
              <Button
                className="formSaveButton"
                type="primary"
                htmlType="submit"
              >
                {/* Send */}
                {this.context.translate(
                  "_FLEET_DASHBORD_VEHICLE_COMMAND_SENDBTN_"
                )}
              </Button>
            </div>

            {/* </div> */}
            {/* <div className="formBorder">
           
            <Row style={{ padding: "24px" }}>
              <Col span={11}>
                <Row>
                  <Col span={15}>
                    <Form.Item
                      name="companyId"
                      label="Company Name"
                      rules={[{ required: true }]}
                    >
                      <Select
                        style={{ width: "300px" }}
                        showSearch
                        mode="multiple"
                        maxTagCount={"responsive"}
                        placeholder="Company"
                        filterOption={(inputValue, option) => {
                          if (option.children) {
                            return (
                              option.children
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            );
                          }
                        }}
                      >
                        {this.emailMsgStore?.allCompanyArray?.map((val) => {
                          return (
                            <Option value={parseInt(val.companyId)}>
                              {val.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label=" ">
                      <Checkbox
                        checked={this.state.allCompany}
                        onChange={async (e) => {
                          await this.setState({ allCompany: e.target.checked });
                          this.getCompany(e);
                        }}
                      >
                        All
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              
              </Col>
              <Col span={13} />
              <Space className="formButtons">
                <Button type="primary" htmlType="submit">
                  Send
                </Button>
                <Button
                  ghost
                  danger
                  type="primary"
                  onClick={(e) => {
                    this.formRef.current?.resetFields();
                  }}
                >
                  Cancel
                </Button>
              </Space>
            </Row>
          </div> */}
          </Form>
        </div>
      </>
    );
  }
}
EmailMessage.contextType = StoreContext;
export default observer(withRouter(EmailMessage));
