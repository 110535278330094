import React from "react";
import { message, Row, Col, Button, Space, Tooltip, Select, Modal } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";

import { PlusOutlined, DeleteFilled } from "@ant-design/icons";
import DeleteConfirm from "./DeleteConfirm";
import CompanyAutocomplete from "./CompanyAutocomplete";
import config from "../config";

import VehicleWorkingHoursStore from "../store/VehicleWorkingHoursStore";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import CompanyStore from "../store/CompanyStore";
import VehicleWorkingHoursAdd from "./VehicleWorkingHoursAdd";
const { Option } = Select;
class VehicleWorkingHoursList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.companyStore = new CompanyStore(this);
    this.handleChange = this.handleChange.bind(this);
    this.vehicleWorkingHoursStore = new VehicleWorkingHoursStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      companyId: "",
      loadData: 0,
      companyVal_Lab: "",
      filter: "",
      Vehicle: false,
      Driver: false,
      openVehicleHoursForm: false,
      vehicleWorkingHoursId: "",
      listDataUpdate: false,
      vehicleId: "",
      driverId: "",
    };
  }
  async componentDidUpdate(prevProps, prevState) {
    console.log("asdf");
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const setWorkingHours = this.context.viewStateStore.workingHoursObject;
      if (setWorkingHours.companyId !== "") {
        console.log(setWorkingHours);
        await this.vehicleWorkingHoursStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: setWorkingHours.companyId,
        });

        const singleData = await this.companyStore.getCompanyDataById(
          setWorkingHours.companyId
        );

        await this.setState({ loadList: 1 });
        console.log("singleData", singleData);
        await this.setState({
          companyVal_Lab: singleData,
          companyId: setWorkingHours.companyId,
        });
        console.log("singleData", singleData);
      }
      await this.setState({ listDataUpdate: false });
    }
  }

  async componentDidMount() {
    this.setState({ pageSize: 50 });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      const data = await this.vehicleWorkingHoursStore.getAssignAsset({
        companyId: this.props.getUserData().userCompanyId,
      });
      console.log("data", data);
      const driverData = await this.vehicleWorkingHoursStore.getDriver(
        this.props.getUserData().userCompanyId
      );
      await this.handleSearch();
    }

    this.columns = [
      {
        // title: "Account",
        title: this.context.translate("_FLEET_DASHBORD_SEARCHPOI_COMPANY_"),
        dataIndex: "name",
        width: "15%",
        render: (value, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {value}
            </Link>
          );
        },
      },

      {
        // title: "Working Days",
        title: this.context.translate("_WORKING_DAYS_"),
        dataIndex: "workingDays",
        width: "10%",
        render:(value)=>{
          // console.log(value)
          return(
              value.join(",")
          )
        }
      },

      {
        // title: "Working Time From ",
        title: this.context.translate("_WORKING_TIME_FROM_"),
        dataIndex: "workingHourFrom",
        width: "10%",
      },

      {
        // title: "Working Time To",
        title: this.context.translate("_WORKING_TIME_TO_"),
        dataIndex: "workingHourTo",
        width: "10%",
      },

      {
        // title: "  Shift Name  ",
        title: this.context.translate("_SHIFT_NAME_"),
        dataIndex: "shiftName",
        width: "10%",
      },

      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        // fixed: 'right',
        width: "5%",
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    // title="Edit Record"
                    title={this.context.translate("_EDIT_RECORD_")}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      // title="Delete Record"
                      title={this.context.translate("_DELETE_RECORD_")}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];

    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });

    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ companyVal_Lab: singleData, companyId: comp.value });
    // await this.setState({ companyId: comp.value });
    // const data = await this.vehicleWorkingHoursStore.getAssignAsset({
    //   companyId: comp.value,
    // });
    // console.log("data", data);
    // const driverData = await this.vehicleWorkingHoursStore.getDriver(
    //   comp.value
    // );
    // console.log(driverData);
    await this.setState({ loadData: 1 });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.vehicleWorkingHoursStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      // searchField: this.state.searchField,
      companyId: this.state.companyId,
      // filter: this.state.filter,
      // vehicleId: this.state.vehicleId,
      // driverId: this.state.driverId,
    });
    await this.setState({ loadData: 1 });
  }

  onEdit = async (record) => {
    console.log(record);
    await this.context.viewStateStore.setWorkingHoursStates({
      companyId: record.companyId,
    });
    await this.setState({ vehicleWorkingHoursId: record.workingHourId });
    await this.setState({ openVehicleHoursForm: true });
    // this.props.navigate("/vehicleworkinghoursadd/" + record.workingHourId);
  };

  async onDelete(record) {
    console.log(record.workingHourId, "record");
    const response = await this.vehicleWorkingHoursStore.deleteRecord({
      workingHourId: record.workingHourId,
    });
    //  console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  
  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.data.success === "S") {
      message.success(resp.data.message);
      await this.setState({ listDataUpdate: true });
      await this.handleSearch();
      await this.setState({ openVehicleHoursForm: false });
      // this.props.navigate("/vehicleworkinghourslist");
    } else if (resp.data.success == "F0") {
      resp.data.errors.map((value) => {
        message.error(value["fromTime"]);
      });
    }
  };
  closeModal = () => {
    this.setState({
      openVehicleHoursForm: false,
      vehicleWorkingHoursId: "",
    });
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_WORKING_HOUR_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
               
                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  placement="left"
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      this.setState({
                        openVehicleHoursForm: true,
                        vehicleWorkingHoursId: "",
                      });
                    }}
                  ></Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  // allowClear={true}
                  value={
                    this.state.companyVal_Lab ? this.state.companyVal_Lab : null
                  }
                  onChange={(value) => this.handleChange(value)}
                  style={{ width: 200 }}
                />
              </Space>
            </Col>
            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            current={this.state.currentPage}
            total={this.vehicleWorkingHoursStore?.total}
            columns={this.columns}
            dataSource={
              this.vehicleWorkingHoursStore?.vehicleWoringHoursVehicles
            }
            loading={this.vehicleWorkingHoursStore?.loading}
            onChange={this.handleTableChange}
           
          />
        </div>

        {this.state.openVehicleHoursForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Add Working Hours"
            title={this.context.translate("_ADD_WORKING_HOURS_")}
            centered
            open={this.state.openVehicleHoursForm}
            footer={null}
            onCancel={() => {
              this.setState({
                openVehicleHoursForm: false,
                vehicleWorkingHoursId: "",
              });
            }}
            width={665}
          >
            <div>
              <VehicleWorkingHoursAdd
                vehicleWorkingHoursId={this.state.vehicleWorkingHoursId}
                response={this.responseMsg}
                companyId={this.state.companyId}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
VehicleWorkingHoursList.contextType = StoreContext;
export default observer(withRouter(VehicleWorkingHoursList));
