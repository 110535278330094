import { Button, FloatButton, Popover, Radio, Switch } from "antd";
import React, { Component, memo } from "react";
import { GlobeCentralSouthAsia } from "react-bootstrap-icons";
import { FullscreenControl } from "react-leaflet-fullscreen";

class AdditionalContentInMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingSwitch: false,
      mapBtn: false,
      type: "",
      satelliteBtn: false,
      traffic: false,
      // mapType: "google",
    };
  }

  handleBtnFunc = (state) => {
    this.props.This.setState({ [state]: true });
    setTimeout(() => {
      this.props.This.setState({ [state]: false });
    }, 1000);
  };

  handleSwitchChange = (value) => {
    this.props.This?.setState({ loadingSwitch: true });
    setTimeout(() => {
      console.log(value);
      this.props.This?.setState({ loadingSwitch: false });
    }, 1000);
  };

  // handleSwitchChange = (value) => {
  //   this.setState({ loadingSwitch: true });
  //   setTimeout(() => {
  //     console.log(value);
  //     this.setState({ loadingSwitch: false });
  //   }, 1000);
  // };
  render() {
    return (
      <div className="map-whiteBtn">
        {this.props.size !== "full" && (
          <FullscreenControl position="topright" title="Go Fullscreen" />
        )}
        {/* <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="Map data © OpenStreetMap contributors"
          /> */}
        <FloatButton.Group
          shape="square"
          style={
            this.props.size === "full"
              ? {
                  top: "1%",
                  width: "40px,",
                  // left: "1%",
                  zIndex: 999,
                  height: "0%",
                  position: "relative",
                  display: "flex",
                  //insetInlineStart: '1%'
                }
              : {
                  top: "1%",
                  width: "0px",
                  // left: "1%",
                  //  insetInlineStart: '1%',
                  zIndex: 999,
                  height: "13%",
                  position: "absolute",
                  transition: "top 0.5s",
                }
          }
        >
          <Popover
            className="locationShowingWindowBtns"
            onClick={() => {
              this.props.This?.setState({ type: "", mapBtn: false });
              this.handleBtnFunc("mapBtn");
            }}
            content={
              <Switch
                checkedChildren="Map"
                unCheckedChildren="Terrain"
                style={{ zIndex: "999" }}
                onChange={async (e) => {
                  console.log(e);
                  await this.handleSwitchChange(e);
                  await this.props.This?.setState({ type: e ? "terrain" : "" });
                  await this.setState({ update: 1 });
                }}
                loading={this.props.This?.state.loadingSwitch}
              >
                Terrian
              </Switch>
            }
            // title="Map"
            trigger="click"
            placement="right"
          >
            <Button
              icon={<i class="bi bi-map"></i>}
              loading={this.props.This?.state.mapBtn}
              style={{}}
            ></Button>
          </Popover>

          <Popover
            style={{ padding: "0px" }}
            onClick={() => {
              this.props.This?.setState({
                type: "hybrid",
                satelliteBtn: false,
              });
              this.handleBtnFunc("satelliteBtn");
            }}
            content={
              this.props.This?.state.satelliteBtn === false && (
                <Switch
                  checkedChildren="Labels"
                  defaultChecked
                  unCheckedChildren="Satellite"
                  onChange={async (e) => {
                    await this.handleSwitchChange(e);
                    await this.props.This.setState({
                      type: e ? "hybrid" : "satellite",
                    });
                  }}
                  loading={this.props.This.state.loadingSwitch}
                  style={{ zIndex: 999 }}
                />
              )
            }
            trigger="click"
            placement="right"
            // style={{ zIndex: 1000 }}
          >
            <Button
              icon={<i class="bi bi-globe-asia-australia"></i>}
              loading={this.props.This.state.satelliteBtn}
              style={{}}
            ></Button>
          </Popover>
          <Popover>
            <Button
              style={{}}
              icon={<i class="bi bi-car-front"></i>}
              onClick={async () => {
                await this.props.This.setState({
                  traffic: !this.props.This.state.traffic,
                });
              }}
            >
              {/* Traffic */}
            </Button>
          </Popover>
          {!this.props.trackingMapBoolean && this.props.showMapOptions && (
            <Popover
              className="locationShowingWindowBtns"
              content={
                <Radio.Group
                  // defaultValue={this.props.This.mapType}
                  options={this.props.This.mapOptions}
                  onChange={async (comp) => {
                    await this.props.This.setState({
                      mapType: comp.target.value,
                      listUpdated: 1,
                    });
                  }}
                  value={this.props.This.mapType}
                  optionType="button"
                  buttonStyle="solid"
                />
              }
              placement="right"

              //   onOpenChange={handleOpenChange}
            >
              <Button icon={<GlobeCentralSouthAsia />} />
            </Popover>
          )}

          {/* <FloatButton />
      <FloatButton icon={<SyncOutlined />} />
      <FloatButton.BackTop visibilityHeight={0} /> */}
        </FloatButton.Group>
      </div>
    );
  }
}
export default memo(AdditionalContentInMap);
