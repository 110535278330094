import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class EmailReportStore {
  emailStore = [];
  reportNames = [];
  reportsList = [];
  groupList = [];
  subGroupList = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      emailStore: observable,
      loading: observable,
      reportNames: observable,
      reportsList: observable,
      total: observable,
      groupList: observable,
      subGroupList: observable,
      loadData: action,
      addData: action,
      getVehicles: action,
      deleteRecord: action,
      getEmailReportData: action,
      getReportsName: action,
      getVehicleGroup: action,
      getVehicleSubGroup: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ offset, limit, searchField, companyId }) {
    this.loading = true;

    var postData = { companyId: companyId, offset: offset, limit: limit };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=emailreport&a=getErList", "postData=" + base64Input);

    this.emailStore = response?.data?.data?.erList;
    this.total = parseInt(response.data.data.TotalCount);
    this.loading = false;
  }
  async addData(emailReportData) {
    var postData = emailReportData;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=emailreport&a=update", "postData=" + base64Input);
    return response.data;
  }

  async getVehicles({ companyId }) {
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);
    this.vehicles = response.data.data;
    return response.data.data;
  }

  async deleteRecord({ er_Id }) {
    const postData = { er_Id: er_Id };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=emailreport&a=delete", "postData=" + base64Input);

    return response;
  }

  async getEmailReportData(Id, companyId) {
    var postData = { Id: Id, companyId: companyId, limit: 1, offset: 0 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=emailreport&a=getErList", "postData=" + base64Input);

    // return response?.data?.data?.drivers[0];
    return response?.data?.data?.erList[0];
  }

  async getReportsName() {
    var postData = {};
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=report&a=getReport", "postData=" + base64Input);

    this.reportsList = response.data.data.reports;
    return response.data.data.reports;
  }

  async getVehicleGroup({ companyId, userId, isadmin }) {
    console.log("getdata");
    var postData = { companyId: companyId, userId: userId, isadmin: isadmin };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=devicegroup&a=getVehicleGropups",
        "postData=" + base64Input
      );

    this.groupList = response.data.data;
  }

  async getVehicleSubGroup({
    deviceGroupId,
    deviceSubGroupId,
    companyId,
    userId,
    isadmin,
    subgroup,
  }) {
    var postData = {
      deviceGroupId: deviceGroupId,
      deviceSubGroupId: deviceSubGroupId,
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=devicegroup&a=getGroups", "postData=" + base64Input);
    if (subgroup) {
      this.subGroupList = response.data.data?.subgroup;
    } else {
      this.vehicles = response.data.data?.vehicles;
    }

    return response.data.data?.subgroup
  }
}

export default EmailReportStore;
