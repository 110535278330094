import { encode as base64_encode } from "base-64";

import { postApi, baseURL } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import  config  from '../config';

class ServiceTemplateStore {
  loading = false;
  total = 0;
  mainServiceList = [];
  photoFromDb = {};

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      total: observable,
      mainServiceList: observable,
      photoFromDb: observable,

      loadData: action,
      deleteRecord: action,
      addData: action,
      exportExcel: action,
      setPhotoFromDb: action,
      getServiceTempData: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ offset, limit }) {
    this.loading = true;
    var postData = {
      offset: offset,
      limit: limit,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=maintenance&a=getService", "postData=" + base64Input);

    this.mainServiceList = response?.data?.data;
    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
  }

  async exportExcel() {
    window.location.href =
      baseURL + "index.php?c=vehicle&a=exportServiceTempExcel";
  }
  async addData(serviceDetails) {
    var postData = serviceDetails;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=addServiceType", "postData=" + base64Input);

    return response.data;
  }

  async setPhotoFromDb(photo) {
    // console.log(photo)
    this.photoFromDb = { fileList: photo };
  }

  async getServiceTempData(serviceId) {
    var postData = {
      serviceId: serviceId,
      limit: 1,
      offset: 0,
    };
   
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=maintenance&a=getService", "postData=" + base64Input);
      const singleRow = response?.data?.data[0];
    
      if(singleRow.logo){
          
        const fileList = [{
            name: singleRow.logo,
            status: 'success',
            url: config.fleetURL+'/images/drivers/'+singleRow.logo,
        }]
      
        this.setPhotoFromDb(fileList)
            
      }
      return singleRow
    // return response?.data?.data[0];
  }

  async deleteRecord({serviceId}) {
    const postData = {
      serviceId: serviceId,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=removeServiceType", "postData=" + base64Input);

    return response;
  }
}

export default ServiceTemplateStore;
