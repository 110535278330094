import React, { useState } from "react";
import {
  Form,
  Col,
  Row,
  message,
  Select,
  Table,
  Button,
  Typography,
  Space,
  DatePicker,
  Tooltip,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  EditFilled,
  DeleteFilled,
  DownloadOutlined,
} from "@ant-design/icons";
import AddButton from "./AddButton";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import config from "../config";
import DeleteConfirm from "./DeleteConfirm";

import VehicleDistanceStore from "../store/VehicleDistanceStore";
import withRouter from "../withRouter";
import CompanyStore from "../store/CompanyStore";
import VehicleDistanceAdd from "./VehicleDistanceAdd";

class VehicleDistanceList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.companyStore = new CompanyStore(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.vehicleDistanceStore = new VehicleDistanceStore();
    this.onDelete = this.onDelete.bind(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      companyId: "",
      loadData: 0,
      openConfirm: false,
      confirmLoading: false,
      company_value_label: "",
      listUpdated: 0,
      vehicleDistanceId: "",
      openVehicleDistanceForm: false,
      listDataUpdate: false,
    };
  }
  onEdit = async (record) => {
    console.log(record);
    console.log("manualDistanceId", record.manualDistanceId);
    await this.context.viewStateStore.setVehicleDistanceStates({
      companyId: record.companyId,
    });
    await this.setState({ vehicleDistanceId: record.manualDistanceId });
    await this.setState({ openVehicleDistanceForm: true });
    // await this.props.navigate("/vehicledistanceadd/" + record.manualDistanceId);
  };

  async onDelete(record) {
    console.log(record, "record");
    const response = await this.vehicleDistanceStore.deleteRecord({
      manualDistanceId: record.manualDistanceId,
    });
    //  console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const beforeEditCompanyId =
        this.context.viewStateStore.vehicleDistanceObject.companyId;
      if (beforeEditCompanyId !== "") {
        await this.vehicleDistanceStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: beforeEditCompanyId,
        });
        const singleData = await this.companyStore.getCompanyDataById(
          this.context.viewStateStore.vehicleDistanceObject.companyId
        );
        await this.setState({ company_value_label: singleData });
        this.setState({ listDataUpdate: false });
      }
    }
  }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId)
      await this.setState({ companyId: this.props.getUserData().userCompanyId })
      await this.setState({ userId: this.props.getUserData().userId })
      await this.handleSearch();
    }
    // await this.vehicleDistanceStore.loadData({ offset: 0, limit: 50 });

    await this.setState({ listUpdated: 1 }); //need to reload data

    this.setState({ pageSize: 50 });

    this.columns = [
      {
        // title: "  Account",
        title:this.context.translate("_FLEET_DASHBORD_NEWPOI_CUSTOMER_"),
        dataIndex: "companyName",
        width: "20%",
        render: (value, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        // title: " Vehicle No ",
        title:this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        width: "20%",
      },
      {
        // title: " Start Odometer",
        title:this.context.translate("_START_ODOMETER_"),
        dataIndex: "startOdometer",
        width: "20%",
      },
      {
        // title: " Stop Odometer",
        title:this.context.translate("_STOP_ODOMETER_"),
        dataIndex: "endOdometer",
        width: "20%",
      },
      {
        // title: " Distance Travelled",
        title:this.context.translate("_FLEET_RULESELECTORDETAIL_RULE13_TITLE_"),
        dataIndex: "distanceTravel",
        width: "20%",
      },
      {
        // title: "   Date ",
        title:this.context.translate("_FLEET_REPORTS_DATE_"),
        dataIndex: "entryDate",
        width: "20%",
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        // fixed: 'right',
        width: "20%",
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    // title="Edit Details"
                    title={this.context.translate("_EDIT_DETAILS_")}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      // title="Delete"
                      title={this.context.translate("_FLEET_DASHBORD_RULETRIGGER_DELETE_")}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }
  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
   await this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value,currentPage:1,pageSize:50});
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ company_value_label: singleData });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.vehicleDistanceStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      // searchField: this.state.searchField,
      companyId: this.state.companyId,
      currentPage:this.state.currentPage
    });
    await this.setState({ loadData: 1 });
  }

  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.data.success == "S") {
      message.success(resp.data.message);
      // this.props.navigate("/vehicledistancelist");
      await this.setState({ listDataUpdate: true });
      await this.setState({ openVehicleDistanceForm: false });
    } else if (resp.data.success == "F0") {
      resp.data.errors.map((value) => {
        message.error(
          "You have already entered readings for selected vehicle for this date"
        );
      });
    }
  };

  closeModal=()=>{
    this.setState({
      openVehicleDistanceForm: false,
      vehicleDistanceId: "",
    });
  }
  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_VEHICLE_DISTANCE_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  title={this.context.translate("_ADD_")}u

                  link="/vehicledistanceadd"
                /> */}
                <Tooltip placement="left" 
                // title="Add" 
                title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}

                color={config.primaryColor}>
                <Button className="addButton"  icon={< PlusOutlined />}
                type="primary"
                  onClick={() =>
                    this.setState({
                      openVehicleDistanceForm: true,
                      vehicleDistanceId: "",
                    })
                  }
                >
                 
                </Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  //  allowClear={true}
                  value={
                    this.state.company_value_label
                      ? this.state.company_value_label
                      : null
                  }
                  onChange={(value) => this.handleChange(value)}
                  style={{ width: 200 }}
                />
              </Space>
            </Col>
            {/* <Col span={8}>
                    <Space style={{ float: this.context.locale.direction == "ltr" ? "right" : "left" }}>
                      
                    </Space>
                </Col> */}
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.vehicleDistanceStore?.total}
            columns={this.columns}
            dataSource={this.vehicleDistanceStore?.vehicleDistanceVehicles}
            loading={this.vehicleDistanceStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openVehicleDistanceForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Assign Bus To Student"
            title={this.context.translate("_VEHICLE_DISTANCE_TRAVELLED_DETAILS_")}
            centered
            visible={this.state.openVehicleDistanceForm}
            footer={null}
            onCancel={() => {
              this.setState({
                openVehicleDistanceForm: false,
                vehicleDistanceId: "",
              });
            }}
            width={665}
          >
            <div>
              <VehicleDistanceAdd
                vehicleDistanceId={this.state.vehicleDistanceId}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
VehicleDistanceList.contextType = StoreContext;
export default observer(withRouter(VehicleDistanceList));
