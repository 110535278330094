import React from "react";

import { Input, Space, Button, Col, Row, message, Tooltip, Modal } from "antd";
import { Link } from "react-router-dom";
//import { decode as base64_decode, encode as base64_encode } from 'base-64';
//import { postApi } from './Utility';

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import {
  SearchOutlined,
  DeleteFilled,
  DownloadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import VehicleAllocateStore from "../store/VehicleAllocateStore";
import withRouter from "../withRouter";
import config from "../config";
import DeleteConfirm from "./DeleteConfirm";
import CompanyAutocomplete from "./CompanyAutocomplete";

import ListTable from "./ListTable";
import CompanyStore from "../store/CompanyStore";
import AllocateVehicleAdd from "./AllocateVehicleAdd";
import dayjs from "dayjs";

class VehicleAllocateList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.allocateStore = new VehicleAllocateStore();
    this.companyStore = new CompanyStore(this);

    this.state = {
      loading: false,
      offset: 50,
      limit: 0,
      pageSize: 50,
      currentPage: 1,
      status: "",
      companyVal_Lab: "",
      searchField: "",
      // companyLoading: false,
      loadList: 0,
      openVehicleAllocateForm: false,
      allocateVehicleId: "",
      listDataUpdate: false,
    };
  }

  
  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      console.log(this.context.viewStateStore.value, "vale");
      const setVehicleAllocateStates =
        this.context.viewStateStore.vehicleAllocateObject;
      console.log(setVehicleAllocateStates);
      await this.setState({ companyId: setVehicleAllocateStates.companyId });
      if (setVehicleAllocateStates !== "") {
        await this.allocateStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          // searchField: setVehicleAllocateStates.searchText,
          companyId: setVehicleAllocateStates.companyId,
          isadmin: this.props.getUserData().isadmin,
          userId: this.props.getUserData().userId,
        });
        const singleData = await this.companyStore.getCompanyDataById(
          setVehicleAllocateStates.companyId
        );

        await this.setState({ loadData: 1 });
        console.log("singleData", singleData);
        await this.setState({ companyVal_Lab: singleData });
        console.log("singleData", singleData);
      }
      this.setState({ listDataUpdate: false });
    }
  }

  async componentDidMount() {
    // await this.allocateStore.loadData({ offset: 0, limit: 50 });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      //  console.log(this.state.companyId)
      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleSearch();
    }

    this.setState({ pageSize: 50 });

    this.columns = [
      {
        // title: "Driver Name",
        title: this.context.translate("_DRIVER_NAME_"),
        dataIndex: "drivername",
        width: 15,
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        // title: "Vehicle No",
        title: this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        width: 15,
      },
      {
        // title: "  Allocate Date",
        title: this.context.translate("_ALLOCATE_DATE_"),
        dataIndex: "allocateDate",
        width: 15,
        render: (value) => {
          if (value) {
            const formattedDate = dayjs(value).format("YYYY-MM-DD");
            return <>{formattedDate}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        // title: " Days Used",
        title: this.context.translate("_DAYS_USED_"),
        dataIndex: "dayUsed",
        width: 10,
      },
      {
        // title: "Total Amount",
        title: this.context.translate("_AMOUNT_"),
        dataIndex: "totalAmount",
        width: 10,
      },
      {
        // title: "Project Name",
        title: this.context.translate("_PROJECT_NAME_"),
        dataIndex: "pcName",
        width: 10,
      },
      {
        // title: "Allocate To",
        title: this.context.translate("_ALLOCATE_TO_"),
        dataIndex: "allocateTo",
        width: 10,
        render: (val) => {
          if (val == 1) {
            return "Driver";
          } else {
            return "Project";
          }
        },
      },
      {
        // title: "Status",
        title: this.context.translate("_FLEET_USER_USERLIST_STATUS_"),
        dataIndex: "returnStatus",
        width: 10,
        render: (val) => {
          if (val == 0) return "Vehicle Allocated";
        },
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        width: 5,
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip 
                  // title="Edit Vehicle Details" 
                  title={this.context.translate("_EDIT_VEHICLE_DETAILS_")}
                  placement="bottom" color={config.tooltipColorEdit}>
                  <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      //  title="Delete Vehicle"
                      title={this.context.translate("_DELETE_VEHICLE_DETAILS_")}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      currentPage: 1,
      pageSize: 50,
    });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    console.log(singleData, "singleData");
    await this.setState({ companyVal_Lab: singleData });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.allocateStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
      isadmin: this.props.getUserData().isadmin,
      userId: this.props.getUserData().userId,
    });
    await this.setState({ loadList: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  onEdit = async (record) => {
    console.log(record);
    const Id = record.Id;
    console.log(Id, "wereeeeeeee");
    if (Id) {
      this.context.viewStateStore.setVehicleAllocateStates({
        companyId: record.companyId,
      });
      await this.setState({ allocateVehicleId: Id });
      await this.setState({ openVehicleAllocateForm: true });
      // this.props.navigate("/allocatevehicleadd/" + Id);
    }
  };

  onSearchClick = async () => {
    if (!this.state.companyId) {
      console.log(
        this.state.companyId,
        "sfsdgdfgdfhfg",
        this.state.companyFieldHighlight
      );
      this.setState({ companyFieldHighlight: true });
    } else {
      this.setState({ companyFieldHighlight: false });
    }
    if (this.state.companyId) {
      this.setState({ companyFieldHighlight: false, loading: true });
      if (this.state.companyId && this.state.searchField.length > 0) {
        await this.allocateStore.loadData({
          offset: 0,
          limit: 10,
          searchField: this.state.searchField,
          companyId: this.state.companyId,
        });
      }

      await this.setState({ loadList: 1 });
    }
  };

  searchInput = async (e) => {
    const value = e.target.value;
    // await this.setState({searchField:value})
    if (value.length >= 3 || value.length === 0) {
      await this.allocateStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        searchField: value,
        companyId: this.state.companyId,
      });
      await this.setState({ loadList: 1 });
    }
  };
  async onDelete(record) {
    console.log(record.Id, "record");
    const response = await this.allocateStore.deleteRecord({
      Id: record.Id,
    });
    //  console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.data.success == "S") {
      message.success(resp.data.message);
      // this.props.navigate("/vehicleallocatelist");
      await this.setState({ openVehicleAllocateForm: false });
      await this.setState({ listDataUpdate: true });
    } else if (resp.data.success == "F0") {
      resp.data.errors.map((value) => {
        message.error(value["username"]);
      });
    }
  };

  closeModal = () => {
    this.setState({ openVehicleAllocateForm: false, userId: "" });
  };

  render() {
    const companyInputBox = this.state.companyFieldHighlight
      ? { border: "2px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_ALLOCATE_VEHICLE_DETAILS_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  title={this.context.translate("_ADD_")}
                  link="/allocatevehicleadd"
                /> */}
                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  placement="left"
                  color={config.primaryColor}
                >
                  <Button
                    type="primary"
                    className="addButton"
                    icon={<PlusOutlined />}
                    onClick={() =>
                      this.setState({
                        openVehicleAllocateForm: true,
                        allocateVehicleId: "",
                      })
                    }
                  ></Button>
                </Tooltip>
                <Button
                  disabled={
                    this.allocateStore?.allocateVehicles?.length > 0
                      ? false
                      : true
                  }
                  style={{ backgroundColor: config.activeColor }}
                  onClick={(val) => {
                    console.log(val);
                    const result = [];
                    this.columns.map((obj) => result.push(obj.dataIndex));
                    //  console.log(result.toString())
                    var getData = {
                      cid: this.state.companyId,
                      searchField: this.state.searchField,
                      colList: result,
                    };
                    console.log(getData);
                    if (val) {
                      this.allocateStore.exportExcel(getData);
                    }
                  }}
                >
                  <DownloadOutlined />
                  {/* Export */}
                  {this.context.translate("_EXPORT_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  // allowClear={true}
                  onChange={(value) => this.handleChange(value)}
                  // disabled={this.state.companyLoading}
                  value={
                    this.state.companyVal_Lab ? this.state.companyVal_Lab : null
                  }
                  // style={{ width: 200 }}

                  style={companyInputBox}
                />

                <Input
                  // placeholder="Search by Vehicle No,Driver Name"
                  placeholder={this.context.translate(
                    "_SEARCH_BY_VEHICLE_NO_DRIVER_NAME_"
                  )}
                  style={{ width: 400 }}
                  onChange={(e) => {
                    this.setState({ searchField: e.target.value });
                    this.searchInput(e);
                  }}
                />
                <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  onClick={this.onSearchClick}
                />
              </Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.allocateStore?.total}
            columns={this.columns}
            dataSource={this.allocateStore?.allocateVehicles}
            loading={this.allocateStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openVehicleAllocateForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Allocate Vehicle"
            title={this.context.translate("_ALLOCATE_VEHICLE_")}
            centered
            visible={this.state.openVehicleAllocateForm}
            footer={null}
            onCancel={() => {
              this.setState({ openVehicleAllocateForm: false, userId: "" });
            }}
            width={665}
          >
            <div>
              {/* <UserAdd
                  userId={this.state.userId}
                  response={this.responseMsg} */}
              {/* /> */}
              <AllocateVehicleAdd
                allocateVehicleId={this.state.allocateVehicleId}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
VehicleAllocateList.contextType = StoreContext;
export default observer(withRouter(VehicleAllocateList));
