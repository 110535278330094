import React, { Component } from 'react'
import { Button, Col, DatePicker, Form, Input, Row, Select, Upload, } from 'antd';
import {  UploadOutlined } from '@ant-design/icons';

import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import axios from 'axios';

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 20 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
/* eslint-enable no-template-curly-in-string */



class EmployeeDetails extends Component {
    constructor(prop) {
        super(prop)
        this.state = ({ dateFormatList: ['DD/MM/YYYY'] })
    }
    normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };


    onEmployeeDetails = async (fieldsValue) => {
        const data = fieldsValue.employee
        console.log(data)
        let values
        if (!data.dateOfBirth || !data.visaExpiryDate || !data.permitExpiryDate || !data.licenceExpiryDate) {
            values = data
        } else {
            values = {
                ...data,
                'dateOfBirth': data['dateOfBirth'].format('YYYY-MM-DD'),
                'visaExpiryDate': data["visaExpiryDate"].format('YYYY-MM-DD'),
                'licenceExpiryDate': data['licenceExpiryDate'].format('YYYY-MM-DD'),
                'permitExpiryDate': data['permitExpiryDate'].format('YYYY-MM-DD')

            }
        }
        console.log("data", values)
        axios.post("http://192.168.1.100:8088/phpmyadmin/sql.php?server=1&db=pmistrack_react&table=fm_company&pos=0", values)
            .then((res) => console.log(res)).catch((err) => console.log(err))

    };

    render() {
        return (
            <div>


                <Form
                    {...layout}
                    name="nest-messages"
                    onFinish={this.onEmployeeDetails}
                    style={{ maxWidth: "100%" }}
                    validateMessages={validateMessages}
                >
                    <h1>Employee Details :</h1>
                    <Row>
                        <Col span={11}>

                    <Form.Item name={['employee', 'account']} label="Account" >
                        <Select placeholder="Select Account">
                            <Select.Option value="demo">Demo</Select.Option>
                            <Select.Option value="demo1">Demo1</Select.Option>
                            <Select.Option value="demo2">Demo2</Select.Option>
                            <Select.Option value="demo3">Demo3</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name={['employee', 'employeeType']} label="Employee Type" >
                        <Select placeholder="Select Employee Type">
                            <Select.Option value="demo">Demo</Select.Option>
                            <Select.Option value="demo1">Demo1</Select.Option>
                            <Select.Option value="demo2">Demo2</Select.Option>
                            <Select.Option value="demo3">Demo3</Select.Option>
                        </Select>
                    </Form.Item>


                    <Form.Item name={['employee', 'department']} label="Department Type" >
                        <Select placeholder="Select Department Type">
                            <Select.Option value="demo">Demo</Select.Option>
                            <Select.Option value="demo1">Demo1</Select.Option>
                            <Select.Option value="demo2">Demo2</Select.Option>
                            <Select.Option value="demo3">Demo3</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name={['employee', 'name']} label="Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>

                    
                    <Form.Item name={['employee', 'userName']} label="User Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>


                    <Form.Item
                        label="Password"
                        name={['employee', 'password']}
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password />
                    </Form.Item> 


                    <Form.Item name={['employee', 'mobile']} label="Mobile No" rules={[{type:Number, required: true }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name={['employee', 'employeeId']} label="Employee Id" rules={[{ type: 'number' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name={['employee', 'employeeStatus']} label="Employee Status" >
                        <Select placeholder="Select Status">
                            <Select.Option value="Active">Active</Select.Option>
                            <Select.Option value="InActive">InActive</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name={['employee', 'platNo']} label="Plat Number" rules={[{ type: 'number' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name={['employee', 'visaExpiryDate']} label="Visa Expiry Date" rules={[{ type: 'date' }]}>
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>

                    <Form.Item name={['employee', 'nationality']} label="Nationality" rules={[{ required: true, message: 'Please select country!' }]}  >
                        <Select placeholder="Select Country"  >
                            <Select.Option value="ind">India</Select.Option>
                            <Select.Option value="usa">USA</Select.Option>
                            <Select.Option value="china">China</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name={['employee', 'address']} label="Address">
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item
                        name={['employee', 'uploadPhotoId']}
                        label="Upload Photo Id"
                        valuePropName="fileList"
                        getValueFromEvent={this.normFile}
                        extra="longer"
                    >
                        <Upload name="logo" action="/upload.do" listType="picture">
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>
                    </Col>
<Col span={2}></Col>
<Col span={11}>
                    <Form.Item name={['employee', 'workingHours']} label="Working Hours" rules={[{ type: 'number' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name={['employee', 'overTimeCharge']} label="OverTime Charge" rules={[{ type: 'number' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name={['employee', 'trackingIntervalMin']} label="Tracking Interval Min" rules={[{ type: 'number' }]}>
                        <Input />
                    </Form.Item>


                    <Form.Item name={['employee', 'trackingTimeFrom']} label="Tracking Time Form" >
                        <Select placeholder="Select tacking Time">
                            <Select.Option value="12:00">Demo</Select.Option>
                            <Select.Option value="02:00">Demo1</Select.Option>
                            <Select.Option value="03:00">Demo2</Select.Option>
                            <Select.Option value="04:00">Demo3</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name={['employee', 'licenseNumber']} label="licenseNumber" rules={[{ type: 'number' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name={['employee', 'licenseExpiryDate']} label="license Expiry Date" rules={[{ type: 'date' }]}>
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>

                    <Form.Item name={['driver', 'emergencyContactName']} label="Emergency contatc Name" rules={[{  }]}>
                        <Input />
                    </Form.Item>


                    <Form.Item name={['employee', 'emergencyContactNumber']} label="EmergencyContact Number" rules={[{ type: 'number' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name={['employee', 'bloodGroup']} label="Blood Group" rules={[{  }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name={['employee', 'notes']} label="Notes">
                        <Input.TextArea />
                    </Form.Item>


                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                    </Col>
                    </Row>
                </Form>

            </div>
        )
    }
}
EmployeeDetails.contextType = StoreContext;
export default observer(EmployeeDetails);
