import { decode as base64_decode, encode as base64_encode } from "base-64";
import { postApi,baseURL} from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class WorkingHoursReportStore {
  workingHoursReport = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      workingHoursReport: observable,
      total: observable,
      loading: observable,
      loadData: action,
      exportExcel: action,
      exportpdf: action,
      exportcsv: action,
      exportxml: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    offset,
    limit,
    companyId,
    vehicleId,
    fromDate,
    toDate,
    week,
  }) {
    this.loading = true;

    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      offset: offset,
      limit: limit,
      week: week,
      page: 1,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=workingHourReport",
        "postData=" + base64Input
      );

    this.workingHoursReport = response?.data?.data?.workinghour;

    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async exportExcel(getData) {
    // console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportworkinghourexcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&week=" +
      getData.week +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportworkinghourpdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&week=" +
      getData.week +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportcsv(getData) {
    let lang = "english";

    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportworkinghourcsv&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      // "&week=" +
      // getData.week +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportxml(getData) {
    let lang = "english";

    window.location.href =
    baseURL +
    "index.php?c=vehicle&a=exportworkinghourxml&cid=" +
    getData.companyId +
    "&userId=" +
    getData.userId +
    "&vid=" +
    getData.vehicleId +
    "&fD=" +
    getData.fromDate +
    "&tD=" +
    getData.toDate +
    // "&week=" +
    // getData.week +
    "&colList=" +
    getData.colList +
    "&language=" +
    lang;
  }
}

export default WorkingHoursReportStore;
