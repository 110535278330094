
import {
  action,
  computed,
  makeObservable,
  observable,
 
} from "mobx";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { postApi,baseURL } from '../components/Utility'

class HeatMapStore {
 vehicleArray = []
 heatMapArray=[]
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
        vehicleArray: observable,
        heatMapArray:observable,
        getVehicle:action
    });
    this.rootStore = rootStore;
  }

  
//   async addData(values) {
//     console.log("data", values);
//     var postData = values;
//     const base64Input = await base64_encode(JSON.stringify(postData));
//     var response = await postApi
//       .getClient()
//       .post("index.php?c=rule&a=create", "postData=" + base64Input);
//     console.log(response);
    
//     return response.data;
//   }
async getVehicle(data){
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
          .getClient()
          .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);
    this.vehicleArray = response.data?.data
}

async getHeatMapData(data){
  this.heatMapArray=[]
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
          .getClient()
          .post("index.php?c=map&a=heatmap", "postData=" + base64Input);
          // console.log(response.data?.data?.heatmapdata  )
          let heatMapArr =[]
         await response.data?.data?.heatmapdata.forEach((val)=>heatMapArr?.push({lat:parseFloat(val.latitude),lng:parseFloat(val.longitude)}))
          console.log(heatMapArr)
    this.heatMapArray = heatMapArr
    return  this.heatMapArray.length 
}


}

export default HeatMapStore;
