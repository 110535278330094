import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class ViewStateStore {
  value = "sammek";
  passengerStatesObj = { companyId: "", searchText: "" };
  userAddStatesObj = { companyId: "", searchText: "" };
  accountStatesObj = {
    distCompanyId: "",
    distributorType: "",
    status: "",
    companyId: "",
    emailId: "",
    createdBy: "",
  };
  driverStateObj = { companyId: "", searchText: "", showWaslList: false };
  staffStateObject = { companyId: "", searchText: "" };
  menuSettingStateObject = { companyId: "" };
  blackListPersonStatesObject = { companyId: "" };
  vehicleLiveLatLong = [];

  vehicleDetailsObject = { companyId: "", searchText: "" };
  vehicleAllocateObject = { companyId: "" };
  VehicleMaintenanceObject = { companyId: "" };
  vehicleDistanceObject = { companyId: "" };
  returnAssetObject = { companyId: "" };
  liveLocationObject = { companyId: "" };
  workingHoursObject = { companyId: "" };
  vehicleTrailerObject = { companyId: "" };

  warehouseObject = { companyId: "" };
  inventoryObject = { companyId: "", warehouseId: "" };

  fuelDetailsObject = { companyId: "" };
  fuelFilledObject = { companyId: "" };
  gradeTimingObject = { companyId: "" };
  schoolCalendarObject = { companyId: "" };
  assignTripObject = { companyId: null };

  inspectionListObject = { companyId: "" };
  notificationListObject = { companyId: "" };
  vehicleOdometerObject = { companyId: "" };

  paymentObject = { companyId: "" };
  jobRequestObject = { companyId: "" };
  jobManagementObject = {
    companyId: "",
    vehicleId: "",
    salesExecutive: "",
    driverName: "",
  };
  emailReportObject = { companyId: "" };
  alertObject = { companyId: "" };
  tripCostObject = { companyId: "" };

  discountObject = { companyId: "" };
  advertisementObject = { companyId: "" };
  poiObject = { companyId: "" };
  audioMsgObject = { companyId: "" };
  vehAuditObject ={companyId:''}
  
  taxiDefaultParaObject={companyId:''}

  subGroupObject={companyId:''}
  newMaintenanceObject={companyId:''}
  fareDetailsObject={companyid:''}

  routeManagementObject={companyId:''}

  sliderChecker = false;

  rootStore;
  constructor(rootStore) {
    makeObservable(this, {
      value: observable,
      passengerStatesObj: observable,
      userAddStatesObj: observable,
      accountStatesObj: observable,
      driverStateObj: observable,
      staffStateObject: observable,
      menuSettingStateObject: observable,
      blackListPersonStatesObject: observable,
      vehicleLiveLatLong: observable,
      vehicleDetailsObject: observable,
      vehicleAllocateObject: observable,
      VehicleMaintenanceObject: observable,
      vehicleDistanceObject: observable,
      returnAssetObject: observable,
      liveLocationObject: observable,
      workingHoursObject: observable,
      vehicleTrailerObject: observable,
      vehicleOdometerObject: observable,
      warehouseObject: observable,
      inventoryObject: observable,
      fuelDetailsObject: observable,
      fuelFilledObject: observable,
      gradeTimingObject: observable,
      schoolCalendarObject: observable,
      assignTripObject: observable,
      paymentObject: observable,
      inspectionListObject: observable,
      notificationListObject: observable,
      jobRequestObject: observable,
      jobManagementObject: observable,
      emailReportObject: observable,
      alertObject: observable,
      tripCostObject: observable,
      sliderChecker: observable,

      discountObject: observable,
      advertisementObject: observable,
      poiObject: observable,
      audioMsgObject: observable,
      vehAuditObject:observable,

      taxiDefaultParaObject:observable,
      subGroupObject:observable,
      newMaintenanceObject:observable,
      fareDetailsObject:observable,

      setPassengerStates: action,
      setDriverState: action,
      setUserAddStates: action,
      setAccountStates: action,
      setStaffStates: action,
      setMenuSettingStates: action,
      setBlackListPerson: action,
      setLiveLatLong: action,
      setVehicleStates: action,
      setVehicleAllocateStates: action,
      setVehicleMaintenanceStates: action,
      setVehicleDistanceStates: action,
      setReturnAssetStates: action,
      setLiveLocationStates: action,
      setWorkingHoursStates: action,
      setVehicleTrailerStates: action,
      setOdometerStates: action,
      setSubgroupStates:action,

      setWarehouseStates: action,
      setInventoryStates: action,
      setFuelDetailsStates: action,
      setFuelFilledStates: action,
      setGradeTimingStates: action,
      setSchoolCalendarStates: action,
      setAssignTripStates: action,

      setInspectionStates: action,
      setNotificationStates: action,
      setPaymentStates: action,

      setJobRequestStates: action,
      setJobManagementStates: action,
      setEmailReportStates: action,
      setAlertStates: action,
      setTripCostStates: action,
      setSliderChecker: action,

      setDiscountStates: action,
      setAdvertisementStates: action,
      setpoiStates: action,

      setVehAuditStates:action,
      setNewVehMaintenanceStates:action,

      setTaxiDefaultParaStates:action,

      setFareDetails:action,

      setRouteManagementStates:action

    });
    this.rootStore = rootStore;
  }

  async setPassengerStates(value) {
    this.passengerStatesObj = value;
  }

  async setUserAddStates(value) {
    this.userAddStatesObj = value;
  }

  async setAccountStates(value) {
    this.accountStatesObj = value;
  }

  async setDriverState(value) {
    this.driverStateObj = value;
  }

  async setStaffStates(value) {
    this.staffStateObject = value;
  }

  async setMenuSettingStates(value) {
    this.menuSettingStateObject = value;
  }

  async setBlackListPerson(value) {
    this.blackListPersonStatesObject = value;
  }

  async setLiveLatLong(val) {
    this.vehicleLiveLatLong = val;
  }

  async setVehicleStates(value) {
    this.vehicleDetailsObject = value;
  }

  async setVehicleAllocateStates(value) {
    this.vehicleAllocateObject = value;
  }

  async setVehicleMaintenanceStates(value) {
    this.VehicleMaintenanceObject = value;
  }

  async setVehicleDistanceStates(value) {
    this.vehicleDistanceObject = value;
  }

  async setReturnAssetStates(value) {
    this.returnAssetObject = value;
  }

  async setLiveLocationStates(value) {
    this.liveLocationObject = value;
  }

  async setWorkingHoursStates(value) {
    this.workingHoursObject = value;
  }

  async setWarehouseStates(value) {
    this.warehouseObject = value;
  }

  async setFuelDetailsStates(value) {
    this.fuelDetailsObject = value;
  }

  async setFuelFilledStates(value) {
    this.fuelFilledObject = value;
  }

  async setGradeTimingStates(value) {
    this.gradeTimingObject = value;
  }

  async setSchoolCalendarStates(value) {
    this.schoolCalendarObject = value;
  }

  async setVehicleTrailerStates(value) {
    this.vehicleTrailerObject = value;
  }

  async setAssignTripStates(value) {
    this.assignTripObject = value;
  }

  async setInspectionStates(value) {
    this.inspectionListObject = value;
  }

  async setNotificationStates(value) {
    this.notificationListObject = value;
  }

  async setOdometerStates(value) {
    this.vehicleOdometerObject = value;
  }

  async setInventoryStates(value, wareHouseId) {
    this.inventoryObject = { companyId: value, warehouseId: wareHouseId };
  }

  async setPaymentStates(value) {
    this.paymentObject = value;
  }

  async setJobRequestStates(value) {
    this.jobRequestObject = { companyId: value };
  }

  async setJobManagementStates(value, vehicleId, salesExecutive, driverName) {
    this.jobManagementObject = {
      companyId: value,
      vehicleId: vehicleId,
      salesExecutive: salesExecutive,
      driverName: driverName,
    };
  }

  async setEmailReportStates(value) {
    this.emailReportObject = value;
  }

  async setAlertStates(value) {
    this.alertObject = value;
  }

  async setTripCostStates(value) {
    this.tripCostObject = value;
  }

  async setSliderChecker(value) {
    this.sliderChecker = value;
  }

  async setDiscountStates(value) {
    this.discountObject = value;
  }

  async setAdvertisementStates(value) {
    this.advertisementObject = value;
  }

  async setAudioMsgStates(value) {
    this.audioMsgObject = value;
  }

  async setpoiStates(value) {
    this.poiObject = value;
  }

  async setVehAuditStates(value) {
    this.vehAuditObject = value;
  }

  async setTaxiDefaultParaStates(value) {
    this.taxiDefaultParaObject = value;
  }


  async setSubgroupStates(value) {
    this.subGroupObject = value;
  }

  async setNewVehMaintenanceStates(value) {
    this.newMaintenanceObject = value;
  }

  async setFareDetails(value){
    this.fareDetailsObject = value;
  }


  async setRouteManagementStates(value) {
    this.routeManagementObject = value;
  }

}

export default ViewStateStore;
