import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi, baseURL } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class TripReportStore {
  tripReport = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      tripReport: observable,
      total: observable,
      loading: observable,
      loadData: action,
      exportExcel: action,
      exportcsv: action,
      exportpdf: action,
      exportxml: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    companyId,
    offset,
    limit,
    vehicleId,
    fromDate,
    toDate,
    travelInt,
    stopInt,
    idleInt,
    pageNo,
  }) {
    this.loading = true;
    console.log(companyId, vehicleId);
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      travelInt: travelInt,
      stopInt: stopInt,
      idleInt: idleInt,
      offset: offset,
      limit: limit,
      page: pageNo,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=tripreport", "postData=" + base64Input);
    this.tripReport = response?.data?.data?.tripreport;
    // console.log( response?.data?.data?.tripreport)
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async exportExcel(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exporttripexcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&tid=" +
      getData.tid +
      "&sid=" +
      getData.sid +
      "&iid=" +
      getData.iid +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exporttrippdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&tid=" +
      getData.tid +
      "&sid=" +
      getData.sid +
      "&iid=" +
      getData.iid +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exporttripcsv&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&tid=" +
      getData.tid +
      "&sid=" +
      getData.sid +
      "&iid=" +
      getData.iid +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exportxml(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exporttripxml&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&tid=" +
      getData.tid +
      "&sid=" +
      getData.sid +
      "&iid=" +
      getData.iid +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
}

export default TripReportStore;
