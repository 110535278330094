import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi, baseURL } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class ExIdleReportStore {
  exIdleReport = [];
  totalReport = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      exIdleReport: observable,
      totalReport : observable,
      total: observable,
      loading: observable,
      loadData: action,
      exportcsv: action,
      exportExcel: action,
      exportpdf: action,
      exportxml: action,
      loadDataTotal: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    companyId,
    offset,
    limit,
    vehicleId,
    fromDate,
    toDate,
    interval,
    pageNo,
  }) {
    this.loading = true;
    console.log("Ex-Idle Report", fromDate, toDate);
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      interval: interval,
      offset: offset,
      limit: limit,
      page: pageNo,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicleidle&a=exidlerep", "postData=" + base64Input);
    console.log("exidle ", response?.data?.data?.ExidleRep);

    this.exIdleReport = response?.data?.data?.ExidleRep;

    this.total = parseInt(response?.data?.data?.TotalCount);
    console.log(this.total);
    this.loading = false;
  }

  async loadDataTotal({
    companyId,
    offset,
    limit,
    vehicleId,
    fromDate,
    toDate,
    interval,
   
  }) {
    this.loading = true;
    console.log("Ex-Idle Report", fromDate, toDate);
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      interval: interval,
      offset: offset,
      limit: limit,
     
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=totalExIdleRep", "postData=" + base64Input);
    // console.log("exidle ",response?.data?.data);

    this.totalReport = response?.data?.data;

    
    this.loading = false;

  }
  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicleidle&a=exportexidleexcel&cid=" +
      getData.companyId +
      "&userId=" +
     getData.userId+
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicleidle&a=exportexidlecsv&cid=" +
      getData.companyId +
      "&userId=" +
     getData.userId+
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicleidle&a=exportexidlepdf&cid=" +
      getData.companyId +
      "&userId=" +
     getData.userId+
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportxml(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicleidle&a=exportexidlexml&cid=" +
      getData.companyId +
      "&userId=" +
     getData.userId+
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
}

export default ExIdleReportStore;
