import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi,baseURL } from '../components/Utility'
import {
    action,
    computed,
    makeObservable,
    observable,
    autorun,
    runInAction,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class GeofenceReportStore {
    geofenceReportStore = []
    geofenceData=[]
    rootStore
    loading=false
    total =""

    constructor(rootStore) {
        makeObservable(this, {
            geofenceReportStore: observable,
            total:observable,

            loadData: action,
            getGeofenceDataByCompany:action,
            loading:observable

        });
        this.rootStore = rootStore;

    }



    async loadData({companyId,vehicleId,geoZoneId,fromDate,toDate,offset,limit}) {
        this.loading=true
        console.log("vehicle list")
        var postData = { "companyId": companyId, "vehicleId": vehicleId, "geoZoneId": geoZoneId,"fromDate": fromDate, "toDate": toDate, "offset": offset, "limit": limit, "page": 1 }
        const base64Input = await base64_encode(JSON.stringify(postData));
        var response = await postApi.getClient().post("index.php?c=vehicle&a=geofenceinout", "postData=" + base64Input);

        console.log(response?.data?.data)
        this.geofenceReportStore = response?.data?.data?.geofenceinout
        console.log( this.geofenceReportStore )
        this.total = response.data.data?.TotalCount
        console.log(response.data.data.TotalCount,response
        )
        this.loading=false

    }

    async getGeofenceDataByCompany({companyId}){
        console.log("vehicle list")
        var postData ={"companyId":companyId}
        const base64Input = await base64_encode(JSON.stringify(postData));
        var response = await postApi.getClient().post("index.php?c=geofence&a=getdatabycompany", "postData=" + base64Input);

        console.log(response?.data?.data)
        this.geofenceData = response?.data?.data
        return response;
    }

    async exportExcel(getData) {
        console.log("getData", getData.allGeo);
       
        let lang = "english";
      
       
        // window.location.href =
        //   baseURL +
        //   "index.php?c=vehicle&a=exportgeofenceinoutexceltest&cid=" +
        //   getData.companyId +
        //   "&userId=" +
        //    getData.userId +
        //   "&vid=" +
        //   getData.vehicleId.join(',') +
        //   "&geoZoneId=" +
        //   geoZoneId +
        //   "&fD=" +
        //   getData.fromDate +
        //   "&tD=" +
        //   getData.toDate +
        //   "&colList=" +
        //   getData.colList +
        //   "&language=" +
        //   lang;
       


        var postData ={"companyId":getData.companyId,"vehicleId":getData.vehicleId,"geoZoneId":getData.geoZoneId,"fromDate":getData.fromDate,"toDate":getData.toDate,"colList":getData.colList,"language":lang}

      
            const newWindow1 = window.open('', '_blank');
            const form = newWindow1.document.createElement('form');
            form.action = baseURL +'index.php?c=vehicle&a=exportgeofenceinoutexcel';
            form.method = 'POST';
            const dataInput = newWindow1.document.createElement('input');
            dataInput.type = 'hidden';
            dataInput.name = 'postData';
            dataInput.value = base64_encode(JSON.stringify(postData)); 
            form.appendChild(dataInput);
            newWindow1.document.body.appendChild(form);
            form.submit();
            setTimeout(() => {
              newWindow1.close();
            }, 3000);
            // window.close();
        // const base64Input = await base64_encode(JSON.stringify(postData));
        // var response = await postApi.getClient().post("index.php?c=vehicle&a=exportgeofenceinoutpdf", "postData=" + base64Input);
 




      }
      async exportpdf(getData) {
        let lang = "english";
        var postData ={"companyId":getData.companyId,"vehicleId":getData.vehicleId,"geoZoneId":getData.geoZoneId,"fromDate":getData.fromDate,"toDate":getData.toDate,"colList":getData.colList,"language":lang}

      
        const newWindow2 = window.open('', '_blank');
        const form = newWindow2.document.createElement('form');
        form.action = baseURL +'index.php?c=vehicle&a=exportgeofenceinoutpdf';
        form.method = 'POST';
        const dataInput = newWindow2.document.createElement('input');
        dataInput.type = 'hidden';
        dataInput.name = 'postData';
        dataInput.value = base64_encode(JSON.stringify(postData)); 
        form.appendChild(dataInput);
        newWindow2.document.body.appendChild(form);
        form.submit();
        setTimeout(() => {
          newWindow2.close();
        }, 6000);
       
      }
      async exportcsv(getData) {
        let lang = "english";
        var postData ={"companyId":getData.companyId,"vehicleId":getData.vehicleId,"geoZoneId":getData.geoZoneId,"fromDate":getData.fromDate,"toDate":getData.toDate,"colList":getData.colList,"language":lang}

      
        const newWindow3 = window.open('', '_blank');
        const form = newWindow3.document.createElement('form');
        form.action = baseURL +'index.php?c=vehicle&a=exportgeofenceinoutcsv';
        form.method = 'POST';
        const dataInput = newWindow3.document.createElement('input');
        dataInput.type = 'hidden';
        dataInput.name = 'postData';
        dataInput.value = base64_encode(JSON.stringify(postData)); 
        form.appendChild(dataInput);
        newWindow3.document.body.appendChild(form);
        form.submit();
        // window.close();
        setTimeout(() => {
          newWindow3.close();
        }, 3000);
        
      }
      async exportxml(getData) {
        let lang = "english";
        var postData ={"companyId":getData.companyId,"vehicleId":getData.vehicleId,"geoZoneId":getData.geoZoneId,"fromDate":getData.fromDate,"toDate":getData.toDate,"colList":getData.colList,"language":lang}

      
        const newWindow4 = window.open('', '_blank');
        const form = newWindow4.document.createElement('form');
        form.action = baseURL +'index.php?c=vehicle&a=exportgeofenceinoutxml';
        form.method = 'POST';
        const dataInput = newWindow4.document.createElement('input');
        dataInput.type = 'hidden';
        dataInput.name = 'postData';
        dataInput.value = base64_encode(JSON.stringify(postData)); 
        form.appendChild(dataInput);
        newWindow4.document.body.appendChild(form);
        form.submit();
        setTimeout(() => {
          newWindow4.close();
        }, 3000);
        // window.close();
       
      }
}

export default GeofenceReportStore;
