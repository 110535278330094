import React, { Component } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select, Spin } from "antd";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";

import CompanyAutocomplete from "./CompanyAutocomplete";

import DiscountStore from "../store/DiscountStore";
import dayjs from "dayjs";
import CompanyStore from "../store/CompanyStore";

class DiscountAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = { loadingSpin: false, companyId: "", discountId: -1 };

    this.discountStore = new DiscountStore(this);
    this.companyStore = new CompanyStore(this);
  }
  async componentDidMount() {
    await this.discountStore.vehicleTypeData();
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      this.context.viewStateStore.setDiscountStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
    }

    const discountId = this.props.discountId;

    if (discountId) {
      await this.filldata(discountId);
    }

    this.setState({ update: 1 });
  }

  async filldata(discountId) {
    const getDiscountData = await this.discountStore.getDiscountData({
      discountId,
      companyId: this.props.companyId,
    });

    await this.setState({
      companyId: getDiscountData.companyId,
      discountId: getDiscountData.discountId,
    });
    const singleData = await this.companyStore.getCompanyDataById(
      getDiscountData.companyId
    );

    this.formRef.current.setFieldsValue({
      companyId: singleData,
      discountId: getDiscountData.discountId,
      discountName: getDiscountData.discountName,
      discountType: getDiscountData.discountType,
      validFrom: getDiscountData.validFrom
        ? dayjs(getDiscountData.validFrom)
        : null,
      validTo: getDiscountData.validTo ? dayjs(getDiscountData.validTo) : null,
      vehicleTypeId: getDiscountData.vehicleTypeId,
      discountAmount: getDiscountData.discountAmount,
    });
  }

  onDiscountDetailSubmit = async (fieldsValue) => {
    const data = {
      ...fieldsValue,
      discountId: this.state.discountId,
      companyId: this.state.companyId,
      validFrom: fieldsValue.validFrom
        ? fieldsValue.validFrom.format("YYYY-MM-DD")
        : "",
      validTo: fieldsValue.validTo
        ? fieldsValue.validTo.format("YYYY-MM-DD")
        : "",
    };

    this.setState({ loading: true });
    const response = await this.discountStore.addData(data);
    await this.props.response(response);

    this.setState({ loading: false });
  };
  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    if (!this.props.discountId) {
      this.context.viewStateStore.setDiscountStates(comp.value);
    }
    await this.setState({ companyId: comp.value });
  }

  closeModal = () => {
    this.props.closeModal();
  };

  validateNumberAndDecimal = (value, field) => {
    const value1 = value.replace(/[^0-9.,]+/g, "");
 
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              ref={this.formRef}
              layout="vertical"
              name="nest-messages"
              onFinish={this.onDiscountDetailSubmit}
              style={{ maxWidth: "100%", padding: 0 }}
              className="formClass"
            >
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate(
                        "_FLEET_DRIVER_DRIVERDETAIL_COMPANY_"
                      )}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => this.handleChange(value)}
                        style={{ width: "100%" }}
                        value={this.state.companyId}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="vehicleTypeId"
                    label={this.context.translate("_VEHICLE_TYPE_")}
                  >
                    <Select
                      options={this.discountStore.vehicleTypeArr}
                      fieldNames={{
                        value: "vehicleTypeId",
                        label: "vehicleType",
                      }}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    name="discountName"
                    label={this.context.translate("_DISCOUNT_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="discountType"
                    // label="Account Name"
                    label={this.context.translate("_TYPE_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={[
                        {
                          value: "1",
                          label: "%",
                        },
                        {
                          value: "2",
                          label: this.context.translate("_FLAT_AMOUNT_"),
                        },
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>

                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="discountAmount"
                    label={this.context.translate("_DISCOUNT_AMOUNT_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      onChange={(e) =>
                        this.validateNumberAndDecimal(
                          e.target.value,
                          "discountAmount"
                        )
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name="validFrom"
                    label={this.context.translate("_VALID_FROM_")}
                    rules={[{ type: Date }]}
                  >
                    <DatePicker
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                      disabledDate={(current) => {
                        return current && current < dayjs();
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="validTo"
                    label={this.context.translate("_VALID_TO_")}
                    rules={[{ type: Date }]}
                  >
                    <DatePicker
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                      disabledDate={(current) => {
                        return current && current < dayjs();
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal();
                  }}
                >
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="formSaveButton"
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}
DiscountAdd.contextType = StoreContext;
export default observer(withRouter(DiscountAdd));
