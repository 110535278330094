import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";

import config from "../config";
import { action, makeObservable, observable } from "mobx";

class SpeedGraphStore {
    speedGraph = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
        speedGraph: observable,
      total: observable,
      loading: observable,
      loadSpeedGraphData: action,
    });
    this.rootStore = rootStore;
  }

  async loadSpeedGraphData({
    offset,
    limit,
    companyId,
    vehicleId,
    fromDate,
    toDate,
    Date,
  }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,

      offset: 0,
      limit: 15,
      page: 1,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=speedvstime", "postData=" + base64Input);
    console.log("fuel", response?.data?.data?.speedvstime);
    var speedGraphData = response?.data?.data?.speedvstime;
    if (speedGraphData && speedGraphData) {
        speedGraphData.forEach((element) => {
        element.speed = parseFloat(element.speed);
      });
    }
    this.speedGraph = response?.data?.data?.speedvstime;

    // this.distanceGraph = response?.data?.data?.distancegraph;
    return response?.data?.data?.speedvstime;
  }
}

export default SpeedGraphStore;
