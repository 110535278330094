import React, { useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table,
  Button,
  Typography,
  Space,
  Row,
  Col,
  Checkbox,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";

import WeightReportStore from "../store/WeightReportStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import dayjs from "dayjs";
import withRouter from "../withRouter";
class WeightReportList extends React.Component {
  constructor(props,context) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.weightReportStore = new WeightReportStore();

    this.state = {
      loading: false,
      loadData: 0,
      pageSize: 50,
      offset: 0,
      limit: 50,
      pageNo: 1,
      modalVisible: false,
      column: "",
      weight: "",
      Notcompany: false,
      Notweight: false,

      selectedColumns: [
        {
          // title: "Company",
          title:context.translate("_FLEET_DASHBORD_NEWPOI_COMPANY_"),
          dataIndex: "companyName",
          width: "15%",
          key: "company",
        },
        {
          // title: "Vehicle No",
          title:context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          width: "15%",
          key: "vehicleNo",
        },
        {
          // title: "Time",
          title:context.translate("_FLEET_DASHBORD_RULETRIGGER_TIME_"),
          dataIndex: "updatedate",
          width: "15%",
          key: "time",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
        },

        {
          // title: "Location",
          title:context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_LOCATION_"),
          dataIndex: "formatted_address",
          width: "15%",
          key: "location",
        },
        {
          // title: "Load Voltage",
          title:context.translate("_LOAD_VOLTAGE_"),
          dataIndex: "loadVoltage",
          width: "15%",
          key: "loadVoltage",
        },
        {
          // title: "Total Weight(Kg)",
          title:context.translate("_TOTAL_TRUCK_WEIGHT_"),
          dataIndex: "weightFactor",
          width: "15%",
          key: "totalWeight",
          render: function (value, r) {
            // console.log(r);
            var loadVoltage = r.loadVoltage;
            var weightFactor = r.weightFactor;

            if (loadVoltage != null && weightFactor != null) {
              var loadKg = loadVoltage * weightFactor;

              if (loadKg > r.emptyWeight) {
                return Math.round(loadKg);
              } else {
                return Math.round(r.emptyWeight);
              }
            } else {
              return "NA";
            }
          },
        },
      ],
    };
    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    this.setState({ pageSize: 50 });
    // await this.weightReportStore.loadData({ offset: 0, limit: 50 });
    await this.setState({ column: this.state.selectedColumns });
    console.log('isadmin',this.props.getUserData().isadmin)
    if(Number(this.props.getUserData().isadmin)!==-1){
    
      let companyId = this.props.getUserData().userCompanyId;
        await this.setState({companyId:companyId})
        this.handleChange({value:companyId});
    }
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
   
    await this.setState({ companyId: comp?.value});
    // await this.vehicleStore.getVehiclesCompanyId({ companyId: comp.value });
    await this.handleSearch();

    // console.log("data", data);
  }

  async handleSearch() {
    // await this.weightReportStore.loadData({
    //   offset: (this.state.pageNo - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,
    //   weight: this.state.weight,
    // });
    // await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.showData();
  }

  showData = async () => {
    console.log(this.state.companyId)
  if(!this.state.companyId){
    this.setState({Notcompany:true})
    console.log(this.state.companyId)
  }
  else{
    this.setState({Notcompany:false})
    console.log(this.state.companyId)
  }
  if(!this.state.weight){
    this.setState({Notweight:true})
  }
  else{
    this.setState({Notweight:false})
  }
  if(this.state.companyId && this.state.weight){
    this.setState({Notcompany: false});
    this.setState({Notweight: false});
    await this.weightReportStore.loadData({
      offset: (this.state.pageNo - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      weight: this.state.weight,
      pageNo:this.state.pageNo
    });
    await this.setState({ loadData: 1 });
  }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Weight Report</div> */}
              <div className="heading">{this.context.translate("_WEIGHT_REPORT_")}</div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          {/* <Row style={{ padding: "10px 0px" }}> */}
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={this.handleChange}
                  style={{ width: 200 }}
                  status={this.state.Notcompany ? 'error' : false}
                />

                <Input
                  style={{ width: 100 }}
                  // placeholder="Weight"
                  placeholder={this.context.translate("_WEIGHT_")}
                  value={this.state.weight}
                  onChange={(e) => this.setState({ weight: e.target.value ,Notweight:false})}
                  status={this.state.Notweight ? 'error' : false}
                />

                <Button
                  type="primary"
                  onClick={async() => {
                    await this.setState({ pageSize: 50, pageNo: 1 });
                    this.showData();
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.pageNo}
            pageSize={this.state.pageSize}
            total={this.weightReportStore?.total}
            columns={this.state.column}
            dataSource={this.weightReportStore?.weightReport}
            loading={this.weightReportStore?.loading}
            onChange={this.handleTableChange}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   total: this.weightReportStore.total || 0,
            // }}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />
        </div>
      </>
    );
  }
}
WeightReportList.contextType = StoreContext;
export default observer(withRouter(WeightReportList));
