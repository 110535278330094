import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
    action,
    makeObservable,
    observable,

} from 'mobx';


class InspectionStore {
    inspection = []
    getVehiclesUsingCompanyId=[]
    total = 0
    loading = false
    rootStore

    constructor(rootStore) {
        makeObservable(this, {
            inspection: observable,
            getVehiclesUsingCompanyId:observable,
            total: observable,
            loading: observable,
            loadData: action,
            addData: action,
            getInspectionData:action,
            getVehiclesByCompanyId:action
        });
        this.rootStore = rootStore;

    }

    async loadData({companyId,offset, limit, }) {
         this.loading = true;

        var postData = { "companyId": companyId, "offset": 0, "limit": 15, "page": 1 }
        const base64Input = await base64_encode(JSON.stringify(postData));
        var response = await postApi.getClient().post("index.php?c=inspection&a=getInspection", "postData=" + base64Input);
        console.log("Inspection", response?.data?.data)

        this.inspection = response?.data?.data

        // this.total = parseInt(response?.data?.data?.TotalCount);
        this.loading = false;
    }

    async addData(values) {
        var postData = values
        const base64Input = await base64_encode(JSON.stringify(postData));
        var response = await postApi.getClient().post("index.php?c=inspection&a=update", "postData=" + base64Input);
        // console.log(response)
        return response.data
    }

 async getInspectionData(id){
        console.log("Id",id)
        var postData = {"id":id,limit:1,offset:0}
        const base64Input = await base64_encode(JSON.stringify(postData));
        var response = await postApi.getClient().post("index.php?c=inspection&a=getInspection", "postData="+base64Input);
        console.log("response",response?.data?.data[0])
        return response?.data?.data[0]
    }

    async deleteRecord({ ins_Id, companyId }) {
        var postData = {"tokenId":"","ins_Id":ins_Id,"companyId":companyId}
        const base64Input = await base64_encode(JSON.stringify(postData));
        var response = await postApi
          .getClient()
          .post("index.php?c=inspection&a=delete", "postData=" + base64Input);
        return response;
      }

    async getVehiclesByCompanyId( {companyId} ) {
        console.log("companyId"+companyId);
        // var postData = { companyId: "96" };
        var postData = { companyId: companyId };
        const base64Input = await base64_encode(JSON.stringify(postData));
        var response = await postApi
          .getClient()
          .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);
        console.log(response, "post");
        this.getVehiclesUsingCompanyId = response?.data?.data;
        return response?.data?.data;
      }

}

export default InspectionStore;
