import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from "mobx";
import { act } from "react-dom/test-utils";

class VehicleFuelCalibrationStore {
  vehicleFuelCalibrationVehicles = [];
  vehicleCalibrationGridEdit=[]
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      vehicleFuelCalibrationVehicles: observable,
      vehicleCalibrationGridEdit:observable,

      loading: observable,
      loadData: action,
      addData: action,
      getFuelDetails: action,
      deleteRecord: action,
      getGridDetails:action,
      deleteGridData:action
    });
    this.rootStore = rootStore;
  }

  async loadData({ offset, limit, currentPage }) {
    this.loading = true;
    console.log("vehicle list",offset, limit, currentPage);
    var postData = { offset: offset, limit: limit, page: currentPage };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=fuelcalibration&a=getFuelCalibrationDetails",
        "postData=" + base64Input
      );

    console.log("loadData**", response?.data?.data);
    this.vehicleFuelCalibrationVehicles = response?.data?.data;
    this.loading = false;
  }

  async addData(vehiclefuel) {
    console.log("data", vehiclefuel);
    var postData = vehiclefuel;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=fuelcalibration&a=update", "postData=" + base64Input);
    console.log(response, "sss");
    return response;
  }

  async deleteRecord({ Id }) {
    var postData = { Id: Id };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=fuelcalibration&a=delete", "postData=" + base64Input);
    return response;
  }
  async getFuelDetails(Id) {
    console.log("getfueldata", Id);
    var postData = { Id: Id };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=fuelcalibration&a=getFuelCalibrationDetails",
        "postData=" + base64Input
      );
    return response.data.data[0];
  }

  async deleteGridData({ Id,fuelCalibrationId }) {
    var postData = {"Id":Id,"fuelCalibrationId":fuelCalibrationId};
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=fuelcalibration&a=deleteAdditionalRecord", "postData=" + base64Input);
    return response;
  }

  async getGridDetails(Id) {
    console.log("getfueldata", Id);
    var postData = { Id: Id };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=fuelcalibration&a=getGridData",
        "postData=" + base64Input
      );
 
    this.vehicleCalibrationGridEdit = response.data.data;
    return response.data.data;
  }
}



export default VehicleFuelCalibrationStore;
