import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  Row,
  Col,
  Space,
  Card,
} from "antd";

import Draggable from "react-draggable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import LiveTrackingStore from "../store/LiveTrackingStore";
import { Icon } from "leaflet";
import L from "leaflet";
import Autocomplete from "react-google-autocomplete";
import config from "../config";
import Geocode from "react-geocode";
import { CloseOutlined } from "@ant-design/icons";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";
const { Option } = Select;

class DraggableModal extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);

    this.state = {
      disabled: false,
      open: true,
      bounds: { left: 0, top: 0, bottom: 0, right: 0 },
      companyId: null,
      vehicleLat: null,
      vehicleLng: null,
      geoLat: null,
      geoLng: null,
      LocationLat: null,
      LocationLong: null,
      distance: null,
      vehicleDisable: false,
      GeoDisable: false,
      LocDisable: false,
      markerShape: [],
      ShapeValue: [],
      formatted_address: "",
      searchBy: false,
      clickOnMapBoolean: true,
    };
    this.formRef = React.createRef();

    this.dragRef = React.createRef();
    this.LiveTrackingStore = new LiveTrackingStore();

    this.handleInput = this.handleInput.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handlePlaceSelected = this.handlePlaceSelected.bind(this);
    this.customIconFunc = this.customIconFunc.bind(this);
  }

  componentDidMount() {
    this.LiveTrackingStore.getVehicleData(
      this.props.getUserData().userCompanyId
    );

    this.setState({ companyId: this.props.getUserData().userCompanyId });
  }
  handleMouseHour = async () => {
    // if (this.state.disabled) {
    //   this.setState({ disabled: false })
    // }
  };
  handleMouseOut = async () => {
    // if (this.state.disabled) {
    //   this.setState({ disabled: false })
    // }
  };
  customIconFunc(data) {
    var customIcon = new Icon({
      // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
      // iconUrl:  require("../assets/images/car.png"),
      // iconUrl:  require("'"+(this.LiveTrackingStore.getIcon(data))+"'"),
      iconUrl: this.LiveTrackingStore.getIcon(data),

      // iconUrl:  require("../assets/images/livetracking/Car-yellow.png"),
      iconSize: [21, 52], // size of the icon
    });

    return customIcon;
  }
  //  customIcon =  new Icon({
  //   iconUrl: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|',
  //   iconSize: [21, 34],
  //   iconAnchor: [10, 34],
  //   popupAnchor: [0, -34],
  //   shadowUrl: 'https://chart.apis.google.com/chart?chst=d_map_pin_shadow',
  //   shadowSize: [40, 37],
  //   shadowAnchor: [12, 35]
  // });

  handleOk = async () => {
    var map = this.props.mapRef.current;
    if (this.state.ShapeValue.length > 0) {
      this.state.ShapeValue.map((s) => {
        map.removeLayer(s);
      });
    }
    // if (this.state.markerShape != undefined && this.state.markerShape != null && this.state.markerShape != "") {
    //   map.removeLayer(this.state.markerShape);
    // };
    if (this.state.markerShape.length > 0) {
      this.state.markerShape.map((m) => {
        map.removeLayer(m);
      });
    }
    if (
      this.LiveTrackingStore.Marker != undefined &&
      this.LiveTrackingStore.Marker != null &&
      this.LiveTrackingStore.Marker != ""
    ) {
      map.removeLayer(this.LiveTrackingStore.Marker);
    }

    if (this.state.companyId === null) {
      Modal.warning({
        // title: "Alert",
        // content: "Please Enter Company Name",
        title: this.context.translate("_FLEET_ALERT_"),
        content: this.context.translate("_PLEASE_ENTER_COMPANY_NAME_"),
      });
    } else if (this.state.searchBy === false && this.state.companyId) {
      Modal.warning({
        // title: "Alert",
        // content: "Please Select Search By Option",
        title: this.context.translate("_FLEET_ALERT_"),
        content: this.context.translate("_PLEASE_SEARCH_BY_OPTION_"),
      });
    } else if (
      this.state.searchBy === "vehicle" &&
      this.state.vehicleLat === null &&
      this.state.vehicleLng === null
    ) {
      Modal.warning({
        // title: "Alert",
        // content: "Please Select Vehicle",
        title: this.context.translate("_FLEET_ALERT_"),
        content: this.context.translate("_PLEASE_SELECT_VEHICLE_"),
      });
    } else if (
      this.state.searchBy === "geofence" &&
      this.state.geoLat === null &&
      this.state.geoLng == null
    ) {
      Modal.warning({
        // title: "Alert",
        // content: "Please Select Geofence",
        title: this.context.translate("_FLEET_ALERT_"),
        content: this.context.translate("_PLEASE_SELECT_GEOFENCE_"),
      });
    } else if (
      this.state.searchBy === "location" &&
      this.state.LocationLat === null &&
      this.state.LocationLong == null
    ) {
      Modal.warning({
        // title: "Alert",
        // content: "Please Enter Location",
        title: this.context.translate("_FLEET_ALERT_"),
        content: this.context.translate("_PLEASE_ENTER_LOCATION_"),
      });
    } else if (this.state.searchBy === "map" && this.state.clickOnMapBoolean) {
      Modal.warning({
        // title: "Alert",
        // content: "Please Click On Map",
        title: this.context.translate("_FLEET_ALERT_"),
        content: this.context.translate("_PLEASE_CLICK_ON_MAP_"),
      });
    } else if (
      this.state.distance === null ||
      (this.state.distance === null &&
        (this.state.geoLat || this.state.LocationLat || this.state.vehicleLat))
    ) {
      Modal.warning({
        // title: "Alert",
        // content: "Please Add Distance",
        title: this.context.translate("_FLEET_ALERT_"),
        content: this.context.translate("_PLEASE_ADD_DISTANCE_"),
      });
    } else if (
      (this.state.companyId && this.state.vehicleLat) ||
      this.state.geoLat ||
      this.state.LocationLat ||
      this.state.distance
    ) {
      var nearAsset = [];
      // this.props.mapRef.current.setView([50.5, 30.5], 13);

      var data = {};
      data.CompanyId = this.state.companyId;
      data.SerchVehLat = this.state.vehicleLat;
      data.SerchVehLong = this.state.vehicleLng;
      data.geoFenceLat = this.state.geoLat;
      data.geoFenceLong = this.state.geoLng;
      data.LocationLat = this.state.LocationLat;
      data.LocationLong = this.state.LocationLong;
      data.mapClickLat = this.LiveTrackingStore.MapClickPoints?.lat
        ? this.LiveTrackingStore.MapClickPoints?.lat
        : null;
      data.mapClickLong = this.LiveTrackingStore.MapClickPoints?.lng
        ? this.LiveTrackingStore.MapClickPoints?.lng
        : null;
      data.distance = this.state.distance;
      if (this.state.companyId) {
        const response = await this.LiveTrackingStore.getNearestVehicleServ(
          data
        );

        nearAsset = response?.data?.data;
        console.log(nearAsset);
        var markerCar;
        nearAsset?.map((n) => {
          var popup = L.popup().setContent("vehicleNo =" + n.vehicleNo);
          markerCar = L.marker([Number(n.latitude), Number(n.longitude)])
            .setIcon(this.customIconFunc(n))
            .bindTooltip(
              "<div style='background:#00ff00;border:solid 1px #000;text-align:center;padding:0'><b>" +
                n.vehicleNo +
                "</b></div>",
              {
                permanent: true,
                direction: "bottom",
              }
            )
            .addTo(this.props.mapRef.current);
          this.state.markerShape.push(markerCar);
        });
        if (nearAsset?.length > 0) {
          this.props.mapRef.current.setView(
            [Number(nearAsset[0]?.latitude), Number(nearAsset[0]?.longitude)],
            14
          );
        } else {
          Modal.warning({
            // title: "Alert",
            // content: "Vehicle not Available",
            title: this.context.translate("_FLEET_ALERT_"),
            content: this.context.translate("_VEHICLE_NOT_AVAILABLE_"),
          });
        }
      }
    }
  };
  handleCancel = async () => {
    var map = this.props.mapRef.current;
    if (this.state.ShapeValue.length > 0) {
      this.state.ShapeValue.map((s) => {
        map.removeLayer(s);
      });
    }
    if (this.state.markerShape.length > 0) {
      this.state.markerShape.map((m) => {
        map.removeLayer(m);
      });
    }
    if (
      this.LiveTrackingStore.Marker != undefined &&
      this.LiveTrackingStore.Marker != null &&
      this.LiveTrackingStore.Marker != ""
    ) {
      map.removeLayer(this.LiveTrackingStore.Marker);
    }
    await this.setState({
      companyId: null,
      vehicleLat: null,
      vehicleLng: null,
      geoLat: null,
      geoLng: null,
      LocationLat: null,
      LocationLong: null,
      distance: null,
      loadState: 1,
    });
    var arr = null;
    this.LiveTrackingStore.setOnMapClickPoints(arr);

    this.LiveTrackingStore.setShowNearVehiclePopup(false);
    // this.setState({ open: this.LiveTrackingStore.showNearVehiclePopup })
    this.LiveTrackingStore.setAutorunPopup(true);
  };
  handleVehicleChange = async (val, x) => {
    // await this.setState({ vehicleId: val });

    var selectedVehicle = (this.LiveTrackingStore?.veicleList?.filter(
      (vehicle) => {
        return val == vehicle.vehicleId;
      }
    ))[0];

    await this.setState({
      vehicleLat: selectedVehicle.latitude,
      vehicleLng: selectedVehicle.longitude,
      geoLat: null,
      geoLng: null,
      mapLat: null,
      mapLng: null,
      LocationLat: null,
      LocationLong: null,
    });
  };

  handleGeoFenceChange = async (val, x) => {
    console.log(val, x);
    console.log(this.LiveTrackingStore.GeofenceList);
    let getAllInfo = this.LiveTrackingStore?.GeofenceList?.filter((val2) => {
      return val2.zoneId === val;
    })[0];
    console.log(getAllInfo);

    console.log({ ...getAllInfo, points: getAllInfo?.points?.split(",") });
    this.props.getGeoFenceObj({
      ...getAllInfo,
      points: [getAllInfo?.points?.split(",")],
    });
    await this.setState({
      // geoLat: points[0],
      // geoLng: points[1],
      geoLat: parseFloat(getAllInfo.points.split(",")[0]),
      geoLng: parseFloat(getAllInfo.points.split(",")[1]),
      vehicleLat: null,
      vehicleLng: null,
      mapLat: null,
      mapLng: null,
      LocationLat: null,
      LocationLong: null,
    });

    //     var selectedGeoFence = (this.LiveTrackingStore?.GeofenceList?.filter((Geo) => {
    //       return (val == Geo.zoneId)
    //     }))[0];
    // console.log(selectedGeoFence)

    //     var points = selectedGeoFence.points.split(",0,");
    //     var path = [];
    // points.map((p)=>{
    //   var pa = p.split(",");
    //   if (!isNaN(parseFloat(pa[0])) && !isNaN(parseFloat(pa[1]))) {
    //     path.push(parseFloat(pa[0]), parseFloat(pa[1]));
    // }
    // })

    //     // var points = selectedGeoFence.points.split(",");
    //     await this.setState({
    //       // geoLat: points[0],
    //       // geoLng: points[1],
    //       geoLat: parseFloat(path[0]),
    //       geoLng: parseFloat(path[1]),
    //       vehicleLat: null,
    //       vehicleLng: null,
    //       mapLat: null,
    //       mapLng: null,
    //       LocationLat: null,
    //       LocationLong: null,
    //     })

    //     if (selectedGeoFence.type == 1) {
    //       var latlngs = [
    //         [25.385044, 55.486671],
    //         [27.506174, 57.648015],
    //         [18.686816, 50.218482]
    //       ];

    //       var polygon = L.polygon(latlngs, { color: 'red' });
    //       polygon.addTo(this.props.mapRef.current);
    //       // this.setState({
    //       //   ShapeValue: polygon
    //       // })

    //       this.state.ShapeValue.push(polygon);
    //     } else if (selectedGeoFence.type == 2) {
    //       // var circleCenter = [points[0], points[1]];
    //       var circleCenter = [path[0],path[1]];
    //       var circleOptions = {
    //         color: 'red',
    //         fillColor: '#FF0000',
    //         fillOpacity: 0.35
    //       }
    //       var circle = L.circle(circleCenter, 50000, circleOptions);
    //       circle.addTo(this.props.mapRef.current);
    //       // this.setState({
    //       //   ShapeValue: circle
    //       // })
    //       this.state.ShapeValue.push(circle);
    //     } else if (selectedGeoFence.type == 3) {
    //       var latlngs = [[25.739046, 55.505755], [29.892787, 59.236081]];
    //       // var latlngs = [
    //       //   [points[0], points[1]],
    //       //    [points[2], points[3]],
    //       //    [points[4], points[5]],
    //       //    [points[6], points[7]],
    //       //   ];
    //       var rectOptions = { color: 'Red', weight: 1 }
    //       var rectangle = L.rectangle(latlngs, rectOptions);
    //       rectangle.addTo(this.props.mapRef.current);
    //       // this.setState({
    //       //   ShapeValue: rectangle
    //       // })
    //       this.state.ShapeValue.push(rectangle);
    //     }
  };
  async handleChange(comp) {
    await this.setState({ companyId: comp.value });

    await this.LiveTrackingStore.loadData({
      offset: 0,
      limit: 5000,
      companyId: this.state.companyId,
      status: this.state.status,
      searchField: this.state.searchField,
    });

    await this.LiveTrackingStore.getVehicleData(this.state.companyId);
    this.setState({
      loadState: 1,
    });
  }

  onStart = async (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = this.dragRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    this.setState({
      bounds: {
        left: -targetRect.left + uiData.x,
        right: clientWidth - (targetRect.right - uiData.x),
        top: -targetRect.top + uiData.y,
        bottom: clientHeight - (targetRect.bottom - uiData.y),
      },
    });
  };
  async handleInput(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleOptionChange = async (val, store) => {
    console.log(val);
    this.setState({ searchBy: val });
    var map = this.props.mapRef.current;
    var locationIcon = new Icon({
      // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
      iconUrl: require("../assets/images/location-pin.png"),
      iconSize: [38, 38], // size of the icon
    });
    if (this.state.markerShape.length > 0) {
      this.state.markerShape.map((m) => {
        map.removeLayer(m);
      });
    }

    if (val === "map") {
      this.setState({
        vehicleDisable: true,
        GeoDisable: true,
        LocDisable: true,
      });
      if (this.state.ShapeValue.length > 0) {
        this.state.ShapeValue.map((s) => {
          map.removeLayer(s);
        });
      }
      var mapLat;
      var mapLng;

      var theMarker = {};
      await map.on("click", (e) => {
        mapLat = e.latlng.lat;
        mapLng = e.latlng.lng;
        //  store.setOnMapClickPoints(mapLat,mapLng);
        var arr = {
          lat: mapLat,
          lng: mapLng,
        };
        console.log("in map");

        this.setState({ clickOnMapBoolean: false });
        if (theMarker != undefined && theMarker != null) {
          map.removeLayer(theMarker);
        }

        store.setOnMapClickPoints(arr);
        if (store.MapClickPoints) {
          theMarker = L.marker([
            store.MapClickPoints.lat,
            store.MapClickPoints.lng,
          ])
            .setIcon(locationIcon)
            .addTo(map);
        }
        store.setMarker(theMarker);
      });

      this.setState({
        geoLat: null,
        geoLng: null,
        vehicleLat: null,
        vehicleLng: null,
        LocationLat: null,
        LocationLong: null,
      });
    }

    if (val === "vehicle") {
      this.setState({
        vehicleDisable: false,
        GeoDisable: true,
        LocDisable: true,
      });
      if (this.state.ShapeValue.length > 0) {
        this.state.ShapeValue.map((s) => {
          map.removeLayer(s);
        });
      }
      if (
        this.LiveTrackingStore.Marker != undefined &&
        this.LiveTrackingStore.Marker != null &&
        this.LiveTrackingStore.Marker != ""
      ) {
        map.removeLayer(this.LiveTrackingStore.Marker);
      }
      this.setState({
        geoLat: null,
        geoLng: null,
      });
      var arr = null;
      this.LiveTrackingStore.setOnMapClickPoints(arr);
    }
    if (val === "geofence") {
      this.setState({
        vehicleDisable: true,
        GeoDisable: false,
        LocDisable: true,
      });
      // this.props.mapRef.current.eachLayer((layer) => {
      //   if (layer instanceof L.Marker) {
      //     this.props.mapRef.current.removeLayer(layer);
      //   }
      // });

      if (
        this.LiveTrackingStore.Marker != undefined &&
        this.LiveTrackingStore.Marker != null &&
        this.LiveTrackingStore.Marker != ""
      ) {
        map.removeLayer(this.LiveTrackingStore.Marker);
      }
    }
    if (val === "location") {
      this.setState({
        vehicleDisable: true,
        GeoDisable: true,
        LocDisable: false,
      });

      // if (this.state.ShapeValue != undefined && this.state.ShapeValue != null && this.state.ShapeValue != "") {
      //   map.removeLayer(this.state.ShapeValue);
      // };
      if (this.state.ShapeValue.length > 0) {
        this.state.ShapeValue.map((s) => {
          map.removeLayer(s);
        });
      }
      if (
        this.LiveTrackingStore.Marker != undefined &&
        this.LiveTrackingStore.Marker != null &&
        this.LiveTrackingStore.Marker != ""
      ) {
        map.removeLayer(this.LiveTrackingStore.Marker);
      }
    }
  };
  handlePlaceSelected = async (place) => {
    console.log(place);
    const { address_components } = place;

    // Geocode.setApiKey(config.googleMapKey);
    // let addressString = place?.formatted_address
    // await this.setState({ isLoading: true })
    // const response = await Geocode.fromAddress(addressString)
    console.log();
    await this.setState({
      LocationLat: place.geometry.location.lat(),
      LocationLong: place.geometry.location.lng(),
      geoLat: null,
      geoLng: null,
      vehicleLat: null,
      vehicleLng: null,
      mapLat: null,
      mapLng: null,
    });
  };
  render() {
   
    return (
      <>
        <Draggable handle=".handle">
          <Card
            className="right-menu-panel headerGroup-panel"
            // title=<div
            //   style={{
            //     display: "flex",
            //     justifyContent: "space-between",
            //     alignItems: "center",
            //   }}
            // >

            // Nearest Asset
            // <Button onClick={()=>this.props.handleCancel("showNearVehiclePopup")} >
            //     <CloseOutlined />
            //   </Button>
            //  </div>

            title={
              <div
                className="handle"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* <div> Nearest Asset</div>  */}
                <div>{this.context.translate("_NEAREST_ASSET_")}</div>
                <CloseOutlined
                  onClick={() =>
                    this.props.handleCancel("showNearVehiclePopup")
                  }
                />
              </div>
            }

            // style={{ height:"519px",  }}
          >
            <Form
              name="control-hooks"
              layout="vertical"
              // onFinish={onFinish}
              style={{
                maxWidth: 600,
              }}
              ref={this.formRef}
            >
              {(this.props.getUserData().isadmin === -1 ||
                this.props.getUserData().isadmin === 3) && (
                <Form.Item
                  name="Select Account"
                  // label="Account"
                  label={this.context.translate(
                    "_FLEET_DASHBORD_NEWPOI_CUSTOMER_"
                  )}
                  style={{ marginBottom: "2px", marginTop: "2px" }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <CompanyAutocomplete
                    onChange={(value) => {
                      this.handleChange(value);
                      this.formRef.current.setFieldsValue({SelectVehicle:null,SelectGeofence:null})
                    }}
                  />
                </Form.Item>
              )}
              <Form.Item
                name="Search"
                // label="Search By"
                label={this.context.translate("_SEARCHBY_")}
                style={{ marginBottom: "2px", marginTop: "2px" }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Search"
                  onSelect={async (e) =>
                    await this.handleOptionChange(e, this.LiveTrackingStore)
                  }
                  options={[
                    {
                      value: "vehicle",
                      label: this.context.translate("_VEHICLE_NO_"),
                      // label: 'Vehicle No',
                    },
                    {
                      value: "geofence",
                      // label: 'Geofence',
                      label: this.context.translate("_GEOFENCE_"),
                    },
                    {
                      value: "location",
                      // label: 'Location',
                      label: this.context.translate(
                        "_FLEET_DASHBORD_HISTORY_TOOLTIP_LOCATION_"
                      ),
                    },
                    {
                      value: "map",
                      // label: 'Click on Map',
                      label: this.context.translate("_CLICK_ON_MAP_"),
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="SelectVehicle"
                style={{ marginBottom: "2px", marginTop: "2px" }}
                // label="Asset Name"
                label={this.context.translate(
                  "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                )}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  //showSearch
                  // placeholder="Select Vehicle"
                  placeholder={this.context.translate("_SELECT_VEHICLE_")}
                  // onChange={(a,b)=>console.log(a,b)}
                  onSelect={this.handleVehicleChange}
                  disabled={this.state.vehicleDisable}
                  filterOption={(inputValue, option) => {
                    return (
                      option.children
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0
                    );
                  }}
                >
                  {this.LiveTrackingStore?.veicleList?.map((vehicle) => {
                    return (
                      <Option
                        key={vehicle.vehicleId}
                        value={parseInt(vehicle.vehicleId)}
                        // value={vehicle}
                      >
                        {vehicle.vehicleNo}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                name="SelectGeofence"
                style={{ marginBottom: "2px", marginTop: "2px" }}
                // label="Select Geofence"
                label={this.context.translate(
                  "_FLEET_RULESELECTORDETAIL_RULE6_GEOFENCE_"
                )}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  //showSearch
                  // placeholder="Select Geofence"
                  placeholder={this.context.translate(
                    "_FLEET_RULESELECTORDETAIL_RULE6_GEOFENCE_"
                  )}
                  // onChange={(a,b)=>console.log(a,b)}
                  onSelect={this.handleGeoFenceChange}
                  disabled={this.state.GeoDisable}
                  filterOption={(inputValue, option) => {
                    return (
                      option.children
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0
                    );
                  }}
                >
                  {/* {this.LiveTrackingStore?.liveTrackingData?.map((vehicle) => {
             
              return (
                <Option key={vehicle.vehicleId} value={parseInt(vehicle.vehicleId)}>
                  {vehicle.vehicleNo}
                </Option>
              );
            })} */}

                  {this.LiveTrackingStore?.GeofenceList?.map((Geo) => {
                    return (
                      <Option
                        key={Geo.zoneId}
                        value={parseInt(Geo.zoneId)}

                        // value={vehicle}
                      >
                        {Geo.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                // label="Enter Location"
                label={this.context.translate("_ENTER_LOCATION_")}
                style={{ marginBottom: "2px", marginTop: "2px" }}
              >
                <Autocomplete
                  style={{
                    background: this.state.LocDisable ? "#cbcbcb2b" : "white",
                    border: "solid 1px #CBCBCB",
                    borderRadius: "5px",
                    width: "100%",
                    padding: "0 10px",
                    height: "46px",
                  }}
                  className="autocomplete-location-textbox"
                  disabled={this.state.LocDisable}
                  apiKey={config.googleMapKey}
                  componentRestrictions={{ country: "us" }}
                  options={{
                    types: ["geocode", "establishment"],
                  }}
                  onPlaceSelected={(val) => {
                    this.setState({ formatted_address: val.formatted_address });
                    this.handlePlaceSelected(val);
                  }}
                />
              </Form.Item>

              <Form.Item
                name="Distance"
                style={{ marginBottom: "2px", marginTop: "2px" }}
                // label="Enter Distance"
                label={this.context.translate("_ENTER_DISTANCE_")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  name="distance"
                  // placeholder="Enter Distance"
                  placeholder={this.context.translate("_ENTER_DISTANCE_")}
                  onChange={this.handleInput}
                />
              </Form.Item>
            </Form>

            <div className="formBtnGroup">
              <Button
                className="formCancelButton"
                onClick={() => this.props.handleCancel("showNearVehiclePopup")}
              >
                {this.context.translate("_FLEET_DASHBORD_SHOWPOI_CANCELBTN_")}{" "}
              </Button>
              <Button
                className="formSaveButton"
                type="primary"
                onClick={this.handleOk}
              >
                {this.context.translate("_SEARCH_")}
              </Button>
            </div>
          </Card>
        </Draggable>
      </>
    );
  }
}

DraggableModal.contextType = StoreContext;
export default observer(withRouter(DraggableModal));
