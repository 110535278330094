import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Space,
  Button,
  Input,
  Table,
  Tooltip,
  Checkbox,
  message,
} from "antd";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";

import { DeleteFilled } from "@ant-design/icons";
import config from "../config";
import AssignZoneToFairStore from "../store/AssignZoneToFareStore";

class AssignZoneToFair extends Component {
  constructor(props) {
    super(props);

    this.assignZoneStore = new AssignZoneToFairStore(this);

    this.formRef = React.createRef();
    this.state = {
      loading: false,
      loadData: 1,
      searchGeofenceData: [],
      assignedZoneId: [],
      assignZoneArray: [],
      selectedSearchZoneId: [],
    };
  }

  async componentDidMount() {
    await this.assignZoneStore.getGeofenceData(this.props.companyId);

    await this.setState({
      searchGeofenceData: this.assignZoneStore.geoDataArr,
    });
    await this.assignZoneStore.getSelectedZone({
      companyId: this.props.companyId,
      parameterId: this.props.parameterId,
    });

    this.columns = [
      {
        title: this.context.translate("_GEOFENCE_"),
        dataIndex: "name",
      },
    ];
    this.assignedColumns = [
      {
        title: this.context.translate("_ASSIGN_ZONE_"),
        dataIndex: "name",
        key: "zoneId",
      },
      {
        title: this.context.translate("_REMOVE_"),
        dataIndex: "action",
        render: (text, record, index) => {
          return (
            <>
              <Space>
                <Tooltip
                  title={this.context.translate("_REMOVE_")}
                  color={config.tooltipColorDelete}
                >
                  <Link
                    onClick={async () => {
                      await this.onDeleteRow(index, Number(record.zoneId));
                    }}
                  >
                    {" "}
                    <DeleteFilled style={{ color: config.deleteColor }} />
                  </Link>
                </Tooltip>
              </Space>
            </>
          );
        },
      },
    ];
    this.setState({ loadData: 1 });
  }

  onDeleteRow = async (index, zoneId) => {
    const filterData = this.state.assignZoneArray.filter(
      (val, indexx) => indexx !== index
    );
    const selectedAssignedGeoId = filterData.map((item) => item.zoneId);
    const selectedSearchGeoId = this.state.selectedSearchZoneId.filter(
      (id) => id !== zoneId
    );

    await this.setState({
      assignZoneArray: filterData,
      selectedSearchZoneId: selectedSearchGeoId,
      assignedZoneId: selectedAssignedGeoId,
    });
  };

  searchInput = async (value) => {
    let inputData = value.toLowerCase();

    const data = this.assignZoneStore.geoDataArr.filter((zone) => {
      return zone.name.toLowerCase().includes(inputData);
    });

    await this.setState({ searchGeofenceData: data });
  };

  onAssignZoneToFare = async (val) => {
    const zoneData = this.state.assignZoneArray.map((val) => {
      return val.zoneId;
    });
    const data = {
      tokenId: "",
      companyId: this.props.companyId,
      vehicleTypeId: this.props.assignData.vehicleTypeId,
      fromDate: this.props.assignData.fromDate,
      toDate: this.props.assignData.toDate,
      parameterId: this.props.parameterId,
      isadmin: "-1",
      zoneId: zoneData,
      all: this.state.all,
    };

    const response = await this.assignZoneStore.addZoneData(data);

    if (response.success === "S") {
      message.success(response.message);
      this.props.closeModal();
    }
  };

  handleRemoveAll = () => {
    this.setState({ selectedSearchZoneId: [], assignedZoneId: [],assignZoneArray:[] });
  };

  handleRemoveSelected =()=>{
    this.setState({assignZoneArray:[]})
  }

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Form
            style={{ maxWidth: "100%" }}
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onAssignZoneToFare}
            {...this.layout}
            name="nest-message"
            className="formClass"
          >
            <Row className="overflowFormInputs">
              <Col span={24} className="formColumnClass">
                <Form.Item name="all" label="">
                  <Checkbox
                    checked={this.state.all}
                    onChange={async (e) => {
                      await this.setState({
                        all: e.target.checked,
                      });
                    }}
                  >
                    Assign All Geofence
                  </Checkbox>
                </Form.Item>

                <Form.Item
                  name=""
                  label={this.context.translate("_SEARCH_GEOFENCE_")}
                >
                  <Input
                    onChange={async (e) => {
                      await this.searchInput(e.target.value);
                    }}
                  />
                </Form.Item>
                <br></br>
                <Row>
                  <Col span={11}>
                    <Table
                      bordered
                      columns={this.columns}
                      dataSource={this.state.searchGeofenceData}
                      pagination={false}
                      rowKey={"zoneId"}
                      scroll={{
                        x: 100,
                        y: 350,
                      }}
                      rowSelection={{
                        selectedRowKeys: this.state.assignedZoneId,
                        onChange: async (selectedRowKeys, selectedRows) => {
                          await this.setState({
                            assignZoneArray: selectedRows,
                            assignedZoneId: selectedRowKeys,
                          });
                        },
                      }}
                    />
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Table
                      bordered
                      columns={this.assignedColumns}
                      dataSource={
                        this.state.assignZoneArray
                        //  ||
                        // this.assignZoneStore.selectedZoneArr
                      }
                      pagination={false}
                      rowKey={"zoneId"}
                      scroll={{
                        x: 100,
                        y: 350,
                      }}
                      rowSelection={{
                        selectedRowKeys: this.state.selectedSearchZoneId,
                        onChange: async (selectedRowKeys, selectedRows) => {
                      
                          await this.setState({
                            selectedSearchZoneId: selectedRowKeys,
                          });
                        },
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className="formBtnGroup">
              <Button
                type="primary"
                className="formSaveButton"
                onClick={this.handleRemoveSelected}
              >
                {this.context.translate("_REMOVE_")}
              </Button>
              <Button
                type="primary"
                style={{ width: "113px" }}
                className="formSaveButton"
                onClick={this.handleRemoveAll}
              >
                {this.context.translate("_REMOVE_ALL_")}
              </Button>

              <Button
                loading={this.state.loading}
                type="primary"
                htmlType="submit"
                className="formSaveButton"
              >
                {/* Save */}
                {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

AssignZoneToFair.contextType = StoreContext;
export default observer(withRouter(AssignZoneToFair));
