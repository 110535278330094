import { decode as base64_decode, encode as base64_encode } from "base-64";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import conf from "../config";
class WASLTripStore {
  WASLTripArray = [];
  getDrivers = [];

  getStationList = [];

  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      WASLTripArray: observable,
      getDrivers: observable,
      total: observable,
      loading: observable,
      loadData: action,
      getDriverName: action,
      getAllStation: action,
      registerTrip: action,
      getDriverInfo: action,
      //   registerVehicle:action
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit }) {
    this.loading = true;
    console.log(companyId,)

    var postData = {
      companyId: companyId,
      sequenceNo: "",
      plateNo: "",
      isTrimNumber: 0,
      offset: offset,
      limit: limit,
    };

    var base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=trip&a=getWaslTrip", "postData=" + base64Input);

    console.log("company Data", response?.data?.data?.tripList);
    this.WASLTripArray = response?.data?.data?.tripList;
    this.loading = false;
  }

  async getDriverName({ companyId }) {
    console.log(companyId);
    var postData = {
      tokenId: "",
      companyId: companyId,
      isadmin: -1,
      deviceGrpOffSet: 0,
      deviceGrpSize: 10,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=getdata", "postData=" + base64Input);
    console.log(response, "post");
    this.getDrivers = response?.data?.data?.company[0]?.drivers;
    console.log(response?.data?.data?.company[0]?.drivers);
    // return response?.data?.data?.company[0]
  }

  async getAllStation() {
    var postData = null;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=station&a=getAllStation", "postData=" + base64Input);
    console.log(response, "post");
    this.getStationList = response?.data?.data;
    console.log(response?.data?.data);
    // return response?.data?.data?.company[0]
  }

  async registerTrip(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=trip&a=waslTripInsert", "postData=" + base64Input);
    console.log(response);

    this.total = parseInt(response?.data?.data?.TotalCount);
    return response;
  }

  async getDriverInfo(driverId) {
    console.log(driverId);
    var postData = { driverId: driverId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=wasl&a=getDriverInfo", "postData=" + base64Input);
    console.log(response);

    this.total = parseInt(response?.data?.data?.TotalCount);
    return response.data.data;
  }

  async exportExcel(getData) {
    window.location.href =
      conf.baseURL +
      "index.php?c=wasl&a=exportwaslvehicleexcel&cid=" +
      getData.cid +
      "&UcompanyId=" +
      getData.UcompanyId +
      "&colList=" +
      getData.colList;
  }

  //   async deleteRecord({Id,companyId}){
  //     console.log(Id,companyId)

  //     var postData = {"waslId":Id,"companyId":companyId,"waslKey":null,"userId":1}
  //     console.log(postData);
  //     const base64Input = await base64_encode(JSON.stringify(postData));
  //     var response = await postApi.getClient().post("index.php?c=wasl&a=deleteCompany", "postData="+base64Input);
  //     return response;
  //   }

  //   async getWASLCompanyStatus({Id}){
  //     console.log(Id)

  //     var postData = {"Id":Id,"waslKey":null}
  //     console.log(postData);
  //     const base64Input = await base64_encode(JSON.stringify(postData));
  //     var response = await postApi.getClient().post("index.php?c=wasl&a=getwaslcompanystatus", "postData="+base64Input);
  //     this.WASLCompanyStatus = response.data.errors[0][0]
  //     // return response;
  //   }
}

export default WASLTripStore;
