import React, { Component } from "react";
import { Row, Col, Form, Space, Button, Select, Input, message } from "antd";
import { Link } from "react-router-dom";
import CompanyAutocomplete from "./CompanyAutocomplete";
import withRouter from "../withRouter";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import AssignVehicleToCompanyStore from "../store/AssignVehicleToCompanyStore";

class AssignVehicleToCompany extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.assignVehicleToCompany = new AssignVehicleToCompanyStore()
    this.formRef = React.createRef();
    this.state={
      loadList:0,
      companyId:"",
      assigncompanyId:""
    }
  }
  async handleChange(comp) {
    console.log(comp.value);
    await this.setState({ companyId: comp.value });
    // await this.formRef.current.setFieldsValue({ companyId: this.state.companyId })
    const data = await this.assignVehicleToCompany.getAssetData({companyId:comp.value})
    console.log(data,"data")

    await this.setState({ loadList: 1 });
   
    // await this.handleSearch()
  }
  async handleCompanyChange(comp) {
    console.log(comp.value);
    await this.setState({ assigncompanyId: comp.value });
    // await this.formRef.current.setFieldsValue({ companyId: this.state.companyId })
    // const data = await this.assignVehicleToCompany.getAssetData({companyId:comp.value})
    // console.log(data,"data")

    await this.setState({ loadList: 1 });
   
    // await this.handleSearch()
  }

  // async handleSearch() {
  //   this.setState({loading:true})
  //   await this.assignVehicleToCompany.loadData({
  //     assigncompanyId:this.state.companyId,
  //     vehicleIds:this.state.selectedVehicles,
  //     companyId: this.state.companyId,
  //   });
  //   await this.setState({ loadData: 1 });
  // }

  onAssignVehicleToCompany = async(values) => {
    // console.log(values);
    const data={...values,companyId:this.state.companyId,assigncompanyId:this.state.assigncompanyId}
    console.log(data);
    const response = await this.assignVehicleToCompany.addData(data);
    console.log(response)
    if (response.data.success === "S") {
      message.success("Vehicle successfully assigned to company");
      console.log(response);
    } else {
      message.error(response.data.message);
    }
    if (response.data.message) {
      this.formRef.current?.resetFields();
    }
  };

  vehicleSelect = async (val) => {
    console.log(val);
    await this.setState({ selectedVehicles: val });
  };


  render() {
    return (
      <div  style={{ padding: 0 }}>
      <div style={{ paddingBottom: "10px" }}>
        <Form
          style={{ maxWidth: "100%" }}
          ref={this.formRef}
          layout="vertical"
          onFinish={this.onAssignVehicleToCompany}
          {...this.layout}
          name="nest-message"
          className="formClass"
        >
          {/* <Row className="headerFixed"> */}
            {/* <Col span={12}>
              <div className="heading">Assign Vehicle To Company</div>
            </Col> */}

            {/* <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            > */}
              {/* <Space style={{}}>
              <Link to={"/vehiclelist"}><Button ghost type="primary">
                  {" "}
                 Back{" "}
                </Button></Link>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button
                  ghost
                  danger
                  type="primary"
                  htmlType=""
                  onClick={(e) => {
                    this.formRef.current?.resetFields();
                  }}
                >
                  Cancel
                </Button>
              </Space> */}
            {/* </Col>
          </Row> */}
          <Row  className="overflowFormInputs">
            <Col span={24} className="formColumnClass">
              <Form.Item
                name="companyId"
                rules={[{ required: true }]}
                label={this.context.translate("_ACCOUNT_")}
              >
                <CompanyAutocomplete
                  onChange={(value) => {
                    this.handleChange(value);
                  }}
                  // style={{ width: 400 }}
                />
              </Form.Item>

              <Form.Item
                    name="vehicleIds"
                    // label="Asset Name"
                    label={this.context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_")}
                    rules={[{ required: true }]}
                    className="search-field"
                  >
                    <Select
                    mode="multiple"
                    maxTagCount="responsive"
                    onChange={(val) => this.vehicleSelect(val)}
                      name="vehicleIds"
                      // style={{ width: 400 }}
                      fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                      // placeholder="Asset Name"
                      placeholder={this.context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_")}
                      optionFilterProp="children"
                      options={this.assignVehicleToCompany?.getVehiclesUsingCompanyId}
                      
                    />
                  </Form.Item>

              <Form.Item
                name="assigncompanyId"
                rules={[{ required: true }]}
                // label="Assign Vehicle To Company"
               label={this.context.translate("_ASSIGN_VEHICLE_TO_COMPANY_")}
              >
                <CompanyAutocomplete
                  onChange={(value) => {
                    this.handleCompanyChange(value);
                  }}
                  // style={{ width: 400 }}
                />
              </Form.Item>
            </Col>
            </Row>
            <div className="formBtnGroup">
              {/* <Link to={"/vehiclelist"}><Button ghost type="primary">
                  {" "}
                 Back{" "}
                </Button></Link> */}
                
                <Button
                  // ghost
                  // danger
                  // type="primary"
                  className="formCancelButton"
                  htmlType=""
                  onClick={(e) => {
                    this.formRef.current?.resetFields();
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_DASHBORD_SHOWPOI_CANCELBTN_")}
                </Button>
                <Button type="primary" htmlType="submit" className="formSaveButton">
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
          
        </Form>
      </div>
      </div>
    );
  }
}

AssignVehicleToCompany.contextType = StoreContext;
export default observer(withRouter(AssignVehicleToCompany));
