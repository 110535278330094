import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class QueueListStore {
  loading = false;
  total = 0;
  queueList = [];
  counts=[]
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      total: observable,
      queueList: observable,
      counts:observable,
      loadData: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ offset, limit, page, companyId, searchField, queueStatus }) {
    this.loading = true;
    var postData = {
      queueStatus: queueStatus,
      searchField: searchField,
      companyId: companyId,
      offset: offset,
      limit: limit,
      page: page,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=driver&a=getAllQueueList", "postData=" + base64Input);

    this.queueList = response?.data?.data?.queueList;
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.counts = response?.data?.data
    this.loading = false;

    return response?.data?.data
  }
}

export default QueueListStore;
