import React, { Component } from "react";
import config from "../config";
import {
  Form,
  Select,
  Button,
  Input,
  DatePicker,
  Space,
  Modal,
  Checkbox,
  Upload,
  Row,
  Col,
  message,
  Spin
} from "antd";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import InspectionStore from "../store/InspectionStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import dayjs from "dayjs";
import VehicleStore from "../store/VehicleStore";
import CompanyStore from "../store/CompanyStore";
const dateFormat = "DD-MM-YYYY HH:mm";
const currentDateTime = dayjs();
class InspectionAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      type: [],
      loading: false,
      companyId: "",
      ins_Id: -1,
      date: dayjs().format("DD-MM-YYYY HH:mm"),
      loadData: 0,
      loadingSpin: false
    };

    this.inspectionStore = new InspectionStore();
    this.vehicleStore = new VehicleStore();
    this.companyStore = new CompanyStore();
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onInspectionDetailsSubmit = async (values) => {
    this.setState({ loading: true });
    console.log(values);
    const InspectionDetails = {
      ...values,
      date: values?.date ? values?.date?.format("DD-MM-YYYY HH:mm") : null,

      // date: values?.date?.format("DD-MM-YYYY HH:mm"),
      ins_Id: this.state.ins_Id,
      companyId: this.state.companyId,
    };
    const data = {
      tokenId: "",
      InspectionDetails: InspectionDetails,
    };
    console.log(data, "tyytyyyyy");
    const response = await this.inspectionStore.addData(data);
    // if (response?.success === "S") {
    //    message.success(response.message);
    //   this.props.navigate("/inspectionlist");
    // } else {
    //   message.error(response.message);
    // }
    await this.props.response(response);
    await this.setState({ loading: false });
  };

  async handleChange(comp) {
    console.log(comp.value);
    console.log(this.props.inspectionId, "EDITRRRRR");
    await this.setState({ companyId: comp.value });
    if (!this.props.inspectionId) {
      this.context.viewStateStore.setInspectionStates({
        companyId: comp.value,
      });
    }
    await this.setState({ companyId: comp.value });
    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });
    const data = await this.inspectionStore.getVehiclesByCompanyId({
      companyId: comp.value,
    });
    console.log(data, "weretrtryrtytu");
    await this.handleSearch();
  }

  async handleSearch() {
    await this.inspectionStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      this.context.viewStateStore.setInspectionStates({ companyId: this.props.getUserData().userCompanyId });
      await this.setState({ companyId: this.props.getUserData().userCompanyId })
      console.log(this.state.companyId)
      const data = await this.inspectionStore.getVehiclesByCompanyId({
        companyId: this.props.getUserData().userCompanyId ,
      });
      await this.setState({ update: 1 });
    }
    console.log(this.props.params);
    const Id = this.props.inspectionId;

    console.log("id", Id);

    if (Id) {
      this.filldata(Id);
      this.setState({ Id: Id });
    }
  }

  async filldata(Id) {
    await this.setState({ loadingSpin: true })
    const getInspectionData = await this.inspectionStore.getInspectionData(Id);
    console.log("getdata", getInspectionData);

    await this.setState({
      companyId: getInspectionData.companyId,
      ins_Id: getInspectionData.ins_Id,
    });
    const singleData = await this.companyStore.getCompanyDataById(
      getInspectionData.companyId
    );

    const data = await this.inspectionStore.getVehiclesByCompanyId({
      companyId: getInspectionData.companyId,
    });
    console.log(data, "WWWWWWWW");

    this.formRef.current.setFieldsValue({
      companyId: singleData,
      vehicleId: getInspectionData.vehicleId,
      comment: getInspectionData.comment,
      techname: getInspectionData.techname,
      types: getInspectionData.types?.split(","),
      // date: getInspectionData?.date
      //   ? dayjs(getInspectionData?.date)
      //   : null,
      date: getInspectionData.date
        ? dayjs(getInspectionData.date, "DD-MM-YYYY HH:mm")
        : null,
    });
    await this.setState({ types: getInspectionData.types });
    await this.setState({ loadingSpin: false })
    await this.setState({ loadData: 1 });
  }

  //   onDateTimeChange=(dates)=>{
  //     console.log(dates,"weretrtryrtytu")
  //     if (dates) {
  //       const format = "DD-MM-YYYY HH:mm";
  //       this.setState({
  //         date: dates.format(format)
  //       });
  //   }
  // }

  closeModal = () => {
    this.props.closeModal()
  }

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen >
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              layout="vertical"
              ref={this.formRef}
              onFinish={this.onInspectionDetailsSubmit}
              // {...this.layout}
              name="nest-message"
              className="formClass"
              initialValues={{ date: currentDateTime }}
            >
              {/* <div className="formBorder">
            <div className="headingTitle">Add Inspection</div> */}
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 || this.props.getUserData().isadmin === 3) &&
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => this.handleChange(value)}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  }

                  <Form.Item
                    name="vehicleId"
                    label={this.context.translate("_ASSET_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      // placeholder="Select Asset"
                      placeholder={this.context.translate("_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_")}
                      showSearch
                      fieldNames={{ value: "vehicleId", label: "vehicleNo" }}
                      optionFilterProp="children"
                      options={this.inspectionStore?.getVehiclesUsingCompanyId}
                    />
                  </Form.Item>

                  <Form.Item
                    name="types"
                    className="formCheckboxCss"
                    label={this.context.translate("_TYPE_")}
                    style={{ marginTop: "16px" }}
                  >
                    <Checkbox.Group
                      value={this.state.type}
                      style={{ display: "inherit", height: "60px" }}
                    >
                      <Checkbox
                        value="Repair"
                        onChange={(val) => {
                          console.log(val);
                        }}
                      >
                        {this.context.translate("_REPAIR_")}
                      </Checkbox>
                      <Checkbox value="Removal">
                        {this.context.translate("_REMOVAL_")}
                      </Checkbox>
                      <Checkbox value="Checking">
                        {this.context.translate("_CHECKING_")}
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="techname"
                    label={this.context.translate("_TECHNICIAN_NAME_")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="comment"
                    label={this.context.translate(
                      "_FLEET_CREATEROUTE_ROUTEADD_COMMENT_"
                    )}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="date"
                    // label="Date"
                    label={this.context.translate("_FLEET_REPORTS_DATE_")}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      // value="topLeft"
                      // showNow={false}
                      showTime
                      style={{ width: "100%" }}
                      format="DD-MM-YYYY hh:mmA"
                      onChange={(date) => {
                        // Check if the date is valid
                        if (date && date.isValid()) {
                          // Update the form field value
                          this.formRef.current.setFieldsValue({ date: date });
                        }
                      }}
                    />
                    {/* <DatePicker
                  // showTime
                  showToday
                    format={"DD-MM-YYYY HH:mm"}
                    onChange={(date) => {
                      if (date && date.isValid()) {
                        const startOfDay = date.startOf("day");

                        this.formRef.current.setFieldsValue({
                          date: startOfDay,
                        });
                      }
                    }}
                    style={{ width: "100%" }}
                  /> */}
                  </Form.Item>
                </Col>
              </Row>
              <div className="formBtnGroup">
                {/* <Link to={"/inspectionlist"}>
                  {" "}
                  <Button ghost type="primary">
                    {" "}
                    Back{" "}
                  </Button>
                </Link> */}

                <Button
                  // ghost
                  // danger
                  // type="primary"
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal()
                    // this.formRef.current?.resetFields();
                  }}
                >
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                  {/* Cancel */}
                  {/* {this.context.translate("_FLEET_DASHBORD_SHOWPOI_CANCELBTN_")} */}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="formSaveButton"
                  loading={this.state.loading}
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>

              {/* <Form.Item wrapperCol={{ ...this.layout.wrapperCol, offset: 8 }}>
                        <Button htmlType="submit">
                            Save
                        </Button>
                    </Form.Item> */}
              {/* </div> */}
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

InspectionAdd.contextType = StoreContext;
export default observer(withRouter(InspectionAdd));
