import React from "react";
import {
  Select,
  Button,
  Space,
  DatePicker,
  Row,
  Col,
  Dropdown,
  Checkbox,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";

import InventoryStatusReportStore from "../store/InventoryStatusReportStore";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import VehicleStore from "../store/VehicleStore";
import config from "../config";
import { decode as base64_decode } from "base-64";
import Cookies from "js-cookie";
const { RangePicker } = DatePicker;
dayjs.extend(customParseFormat);

const { Option } = Select;
class InventoryStatusReport extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.inventoryStatusReportStore = new InventoryStatusReportStore();
    this.vehicleStore = new VehicleStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      companyId: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      selectedWarehouse: [],
      selectedInventories: [],
      selectAllInventory: false,
      modalVisible: false,
      column: "",
      filter: "",
      Notcompany: false,

      selectedColumns: [
        {
          // title: "Asset Name",
          title: context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"),
          dataIndex: "vehicleNo",
          width: "15%",
          key: "assetname",
        },
        {
          // title: "Date",
          title: context.translate("_FLEET_REPORTS_DATE_"),
          dataIndex: "timestamp",
          key: "date",
          width: "15%",
        },
        {
          // title: "WareHouse",
          title: context.translate("_FLEET_DASHBORD_NEWPOI_TYPEDDL_WAREHOUSE_"),
          dataIndex: "warehouse",
          key: "wareHouse",
          width: "15%",
        },

        {
          // title: "Inventory",
          title: context.translate("_INVENTORY_"),
          dataIndex: "inventory",
          key: "inventory",
          width: "15%",
        },
        {
          // title: "Temp",
          title: context.translate("_TEMP_"),
          dataIndex: "temp",
          key: "temp",
          width: "15%",
        },
        {
          // title: "Humidity",
          title: context.translate("_HUMIDITY_"),
          dataIndex: "humidity",
          key: "humidity",
          width: "15%",
        },
        {
          // title: "Update Date",
          title: context.translate("_UPDATE_DATE_"),
          dataIndex: "updateDate",
          key: "updatedate",
          width: "15%",
        },
        {
          // title: "Response",
          title: context.translate("_RESPONSE_"),
          dataIndex: "waslResponse",
          key: "response",
          width: "15%",
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "1",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "2",
          icon: <PlusOutlined />,
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "3",
          icon: <PlusOutlined />,
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "4",
          icon: <PlusOutlined />,
        },
      ],
      onClick: () => {},
    };
    this.newColumns = [
      ...this.state.selectedColumns,
      {
        // title: "Inventory WASL Key",
        title: context.translate("_INVENTORY_WASL_KEY_"),
        dataIndex: "waslInventoryKey",
        key: "inventoryWASLKey",
        width: "15%",
      },
      {
        // title: "Warehouse WASL Key",
        title: context.translate("_WASREHOUSE_WASL_KEY_"),
        dataIndex: "waslWarehouseKey",
        key: "waslWarehouseKey",
        width: "15%",
      },
      {
        // title: "Sensor Id",
        title: context.translate("_SENSOR_ID_"),
        dataIndex: "SensorId",
        key: "SensorId",
        width: "15%",
      },
    ];

    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        // {
        //   // label: "CSV",
        //   label: context.translate("_CSV_"),
        //   key: "csv",
        //   icon: <PlusOutlined />,
        // },
        // {
        //   label: "XML",
        //   key: "xml",
        //   icon: <PlusOutlined />,
        // },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            companyId: this.state.companyId,
            warehouseId: this.state.selectedWarehouse,
            fromDate: this.state.fromDate,
            inventoryId: this.state.selectedInventories,
            toDate: this.state.toDate,
            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.inventoryStatusReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.inventoryStatusReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.inventoryStatusReportStore.exportcsv(getData);
          }
          // if (val.key == "xml") {
          //   this.userActivityReportStore.exportxml(getData);
          // }
        }
      },
    };
  }

  async componentDidMount() {
    this.setState({ pageSize: 50 });
    await this.setState({ column: this.state.selectedColumns });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.showData();
  }
  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };
  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ currentPage: 1, companyId: comp?.value });
    await this.setState({ selectedWarehouse: [] });

    await this.inventoryStatusReportStore.getWarehouseByCompanyId({
      companyId: comp.value,
    });
    await this.setState({ listUpdated: 1 });
    // this.handleSearch();
  }

  // async onSearch(value) {
  //     console.log(value)
  //     await this.setState({ emailId: value });
  //     this.handleSearch();
  // };

  async handleSearch() {
    await this.inventoryStatusReportStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });

    await this.setState({ listUpdated: 1 }); //need to reload data
  }

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }

    if (this.state.companyId) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false });
      await this.inventoryStatusReportStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
        warehouseId: this.state.selectedWarehouse,
        fromDate: this.state.fromDate,
        inventoryId: this.state.selectedInventories,
        toDate: this.state.toDate,
        currentPage: this.state.currentPage,
        // toDate: defaultValue.format("YYYY-MM-DD HH:mm:ss"),
      });
      // console.log(this.state.fromDate,this.state.toDate,)
      await this.setState({ listUpdated: 1 });
    }
  };
  handleAllSelectInventory = async (e) => {
    if (this.state.selectAllInventory) {
      const inventories = this.inventoryStatusReportStore?.inventoryArray.map(
        (val) => {
          return val.id;
        }
      );
      await this.setState({ selectedInventories: inventories });
    } else {
      await this.setState({ selectedInventories: [] });
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  render() {
    //console.log('in render1')
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Inventory Status Report</div> */}
              <div className="heading">
                {this.context.translate("_INVENTORY_STATUS_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Dropdown
                  disabled={
                    this.inventoryStatusReportStore?.inventoryArray?.length > 0
                      ? false
                      : true
                  }
                  menu={this.menuProps}
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Space>
              <Col span={20}>
                <CompanyAutocomplete
                  onChange={(value) => this.handleChange(value)}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
              </Col>

              <Col>
                <Select
                  onSearch={this.handleSearch}
                  value={this.state.selectedWarehouse}
                  onChange={(val) => this.setState({ selectedWarehouse: val })}
                  onSelect={async (val) => {
                    await this.inventoryStatusReportStore.getInventoryByCompanyId(
                      {
                        companyId: this.state.companyId,
                        warehouseId: val,
                      }
                    );
                    await this.setState({ listUpdated: 1 });
                  }}
                  showSearch
                  maxTagCount="responsive"
                  // fieldNames={{ label: "warehouse", value: "id" }}
                  // placeholder="Ware House"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_NEWPOI_TYPEDDL_WAREHOUSE_"
                  )}
                  optionFilterProp="children"
                  // options={this.inventoryStatusReportStore?.warehouseArray}
                  style={{ width: 160 }}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.inventoryStatusReportStore?.warehouseArray?.map(
                    (val) => {
                      return <Option value={val.id}>{val.warehouse}</Option>;
                    }
                  )}
                </Select>
              </Col>

              <Checkbox
                checked={this.state.selectAllInventory}
                onChange={async (e) => {
                  await this.setState({
                    selectAllInventory: e.target.checked,
                  });
                  console.log(e.target.checked);
                  await this.handleAllSelectInventory();
                }}
              />
              {/* All */}
              {this.context.translate("_ALL_")}
              {"  "}

              <Col>
                <Select
                  mode="multiple"
                  onSearch={this.handleSearch}
                  value={this.state.selectedInventories}
                  onChange={(val) => {
                    this.setState({ selectedInventories: val });
                    if (
                      this.inventoryStatusReportStore?.inventoryArray.length >
                      val.length
                    ) {
                      this.setState({ selectAllInventory: false });
                    } else {
                      this.setState({ selectAllInventory: true });
                    }
                  }}
                  maxTagCount="responsive"
                  fieldNames={{ label: "inventory", value: "id" }}
                  // placeholder="Inventory"
                  placeholder={this.context.translate("_INVENTORY_")}
                  optionFilterProp="children"
                  options={this.inventoryStatusReportStore?.inventoryArray}
                  style={{ width: 160 }}
                />
                {/* {this.vehicleStore?.getVehiclesUsingCompanyId.map((val)=>{
                    return (<Select.Option value={val.vehicleId}>{val.vehicleNo}</Select.Option>)
                })}
              </Select> */}
              </Col>
              <Col>
                {/* <RangePicker
                  style={{ width: 300 }}
                  onChange={(dates) => {
                    // console.log(val)
                    // this.onDateTimeChange(val)
                    const format = "YYYY-MM-DD HH:mm:ss";
                    this.setState({
                      fromDate: dates[0].format(format),
                      toDate: dates[1].format(format),
                    });
                  }}
                  // renderExtraFooter={() => "extra footer"}
                  showTime={{ format: "h:mm A" }}
                /> */}

                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
              </Col>

              <Col>
                <Button
                  type="primary"
                  onClick={async() => {
                  
                    await this.setState({ currentPage: 1,pageSize:50 });
                   await this.showData();
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Col>
            </Space>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.inventoryStatusReportStore?.total}
            columns={this.state.column}
            dataSource={this.inventoryStatusReportStore?.inventory}
            loading={this.inventoryStatusReportStore?.loading}
            onChange={this.handleTableChange}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />
        </div>
      </>
    );
  }
}
InventoryStatusReport.contextType = StoreContext;
export default observer(withRouter(InventoryStatusReport));
