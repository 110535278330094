import React, { Component } from "react";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Modal,
  message,
  Checkbox,
  Upload,
  Button,
  Tabs,
  Row,
  Col,
  ColorPicker,
  Tooltip,
  Spin,
} from "antd";
// import { ColorPicker } from 'antd'
import dayjs from "dayjs";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { StoreContext } from "../store/RootStore";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import DriversStore from "../store/DriversStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";
import VehicleStore from "../store/VehicleStore";

import CompanyStore from "../store/CompanyStore";
import CountryStore from "../store/CountryStore";
import "./AddAccount.css";
import config from "../config";
import conf from "../config";
import DriverAdd from "./DriverAdd";

const { Option } = Select;
const dateFormat = "YYYY-MM-DD";

const { TabPane } = Tabs;

class VehicleAdd extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      tabErrors: {},
      formError: false,

      loading: false,
      vehicleId: -1,
      vehiCalSpeed: false,
      companyId: "",
      fuelSensor: false,
      DriverId: false,
      loadSensor: false,
      temperatureSensor: false,
      rfid: false,
      pta: false,
      sfda: false,
      basic: false,
      driverId: "",
      // getpollimie: [],
      timezoneId: Number(config.defaultTimezoneId),
      one: true,
      two: true,
      three: true,

      horizontalrectangle: false,
      verticalcylinder: false,
      horizontalcylinder: false,

      horizontalrectangle2: false,
      verticalcylinder2: false,
      horizontalcylinder2: false,

      horizontalrectangle3: false,
      verticalcylinder3: false,
      horizontalcylinder3: false,

      vehicleType: "",

      school: false,
      loadData: 0,

      colorHex: "",
      formatHex: "hex",

      icon: "",
      headWeight: "",
      imeiError: false,
      openDriverDetailsForm: false,
      tagIdShow: false,

      showtIdField: true,
      redfield: false,

      loadingSpin: false,

      disabledField: false,
      pulseFields: false,
      selectRules: false,
      mdvrFields: false,
    };
    this.driverStore = new DriversStore();
    this.vehicleStore = new VehicleStore();
    this.companyStore = new CompanyStore();

    this.countryStore = new CountryStore();
  }

  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      this.context.viewStateStore.setVehicleStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });

      // await this.setState({userId:this.props.getUserData().userId})
      await this.setState({ isadmin: this.props.getUserData().isadmin });

      const pollingData = await this.vehicleStore.getPollingImeiData();

      await this.setState({ getpollimie: pollingData });

      await this.setState({ full_imei: pollingData[0].imei });

      // this.formRef.current.setFieldsValue({ full_imei: pollingData[0].imei });

      await this.driverStore.toGetDriverName(
        this.props.getUserData().userCompanyId
      );

      const vehicleData = this.driverStore.getVehicles(
        this.props.getUserData().userCompanyId
      );
    }
    await this.countryStore.getCountry();
    await this.countryStore.getTimeZone();

    await this.setState({ loadList: 1 });

    const vehicleId = this.props.vehicleIdForEdit;

    if (vehicleId) {
      await this.filldata(vehicleId);
      this.setState({ vehicleId: vehicleId });
    }

    await this.vehicleStore.getPollingImeiData();

    await this.vehicleStore.getFuelCaliDetails();
  }

  filldata = async (vehicleId) => {
    await this.setState({ loadingSpin: true });
    const getVehicleDetails = await this.vehicleStore.getVehicleData(vehicleId);

    await this.setState({
      companyId: getVehicleDetails.companyId,
      vehicleId: getVehicleDetails.vehicleId,
    });
    const singleData = await this.companyStore.getCompanyDataById(
      getVehicleDetails.companyId
    );
    await this.setState({ companyLable: singleData });

    await this.driverStore.toGetDriverName(getVehicleDetails.companyId);

    await this.vehicleStore.getVehicleRules(getVehicleDetails.companyId);

    const pollingData = await this.vehicleStore.getPollingImeiData();
    await this.setState({ getpollimie: pollingData });

    await this.formRef.current.setFieldsValue({
      fid1: getVehicleDetails.Fid1,
      fid2: getVehicleDetails.Fid2,
      fid3: getVehicleDetails.Fid3,

      installationspace1: getVehicleDetails.InstallationSpace1,
      installationspace2: getVehicleDetails.InstallationSpace2,
      installationspace3: getVehicleDetails.InstallationSpace3,
      InstallationType: getVehicleDetails.InstallationType,
      nofueltank: getVehicleDetails.No_FuelTank,

      Sensor1: getVehicleDetails.Sensor1,
      Sensor2: getVehicleDetails.Sensor2,
      Sensor3: getVehicleDetails.Sensor3,
      addOn: getVehicleDetails.addOn,

      batteryExpire: getVehicleDetails.batteryExpire,
      branch: getVehicleDetails.branch,
      capacity: getVehicleDetails.capacity,
      ColorCode: getVehicleDetails.colorCode,
      comment: getVehicleDetails.comment,
      companyId: singleData,

      department: getVehicleDetails.department,
      deviceGroupId: getVehicleDetails.deviceGroupId,
      deviceId: Number(getVehicleDetails.deviceId),
      // deviceName: getVehicleDetails.deviceName,
      diffOdo: getVehicleDetails.diffOdo,
      DigInpt1: getVehicleDetails.digital_Input1,
      DigInpt2: getVehicleDetails.digital_Input2,
      DigInpt3: getVehicleDetails.digital_Input3,
      DigInpt4: getVehicleDetails.digital_Input4,
      AngInpt1: getVehicleDetails.analog_Input1,
      AngInpt2: getVehicleDetails.analog_Input2,
      AngInpt3: getVehicleDetails.analog_Input3,
      AngInpt4: getVehicleDetails.analog_Input4,

      // digital_Input4: parseInt(getVehicleDetails.digital_Input4),
      distance: getVehicleDetails.distance,
      dname: getVehicleDetails.dname,
      downPayment: getVehicleDetails.downPayment,
      // driverId: getVehicleDetails.dname,
      driverId: Number(getVehicleDetails.driverId),

      emiDueDate: getVehicleDetails?.emiDueDate
        ? dayjs(getVehicleDetails.emiDueDate)
        : null,
      emptyWeight: getVehicleDetails.emptyWeight,

      expiryDate: getVehicleDetails?.expiryDate
        ? dayjs(getVehicleDetails?.expiryDate)
        : null,
      fuelConsumption: getVehicleDetails.fuelConsumption,
      minimumFineAmount: getVehicleDetails.minimumFineAmount,
      finePerMinute: getVehicleDetails.finePerMinute,

      fuelId1: getVehicleDetails.fuelId1,
      fuelId2: getVehicleDetails.fuelId2,
      fuel: getVehicleDetails.fuelType,
      full_imei: getVehicleDetails.full_imei,
      mdvrfull_imei: getVehicleDetails.mdvrfull_imei,
      headWeight: getVehicleDetails.headWeight,

      highLimit1: getVehicleDetails.highLimit1,
      highLimit2: getVehicleDetails.highLimit2,
      highLimit3: getVehicleDetails.highLimit3,

      hourMeterReading: getVehicleDetails.hourMeterReading,
      hourMeterReading2: getVehicleDetails.hourMeterReading2,

      idleLimit: getVehicleDetails.idleLimit,
      installDate: getVehicleDetails?.installDate
        ? dayjs(getVehicleDetails?.installDate)
        : null,
      installedLocation: getVehicleDetails.installedLocation,
      installer: getVehicleDetails.installer,
      insuranceRenewal: getVehicleDetails?.insuranceRenewal
        ? dayjs(getVehicleDetails?.insuranceRenewal)
        : null,

      isCanbus: getVehicleDetails.isCanbus ? 1 : 0,
      lastBatteryDate: getVehicleDetails?.lastBatteryDate
        ? dayjs(getVehicleDetails?.lastBatteryDate)
        : null,
      lastDistanceOil: getVehicleDetails.lastDistanceOil,
      lastDistanceService: getVehicleDetails.lastDistanceService,
      lastDistanceTire: getVehicleDetails.lastDistanceTire,
      lastExpiryDate: getVehicleDetails?.lastExpiryDate
        ? dayjs(getVehicleDetails?.lastExpiryDate)
        : null,
      oilchangeKm: getVehicleDetails.lastOil,

      lastoilchange: getVehicleDetails?.lastOilDate
        ? dayjs(getVehicleDetails?.lastOilDate)
        : null,
      lastservicedone: getVehicleDetails?.lastServiceDate
        ? dayjs(getVehicleDetails?.lastServiceDate)
        : null,
      tirechangeKm: getVehicleDetails.lastTire,
      lasttirechange: getVehicleDetails?.lastTireDate
        ? dayjs(getVehicleDetails?.lastTireDate)
        : null,
      machineservicehours: getVehicleDetails.machineServiceHours,
      manufactureYear: getVehicleDetails.manufactureYear,
      maxHoursPerDay: getVehicleDetails.maxHoursPerDay,

      maxVolt: getVehicleDetails.maxVolt,
      minVolt: getVehicleDetails.minVolt,
      mobileno: getVehicleDetails.mobileno,
      vehicleModel: getVehicleDetails.model,
      monthlyEmi: getVehicleDetails.monthlyEmi,

      mulkiyaRenewal: getVehicleDetails?.mulkiyaRenewal
        ? dayjs(getVehicleDetails?.mulkiyaRenewal)
        : null,
      noOfEmi: getVehicleDetails.noOfEmi,
      odometerReading: getVehicleDetails.odometerReading,
      ownership: getVehicleDetails.ownership,
      password: getVehicleDetails.password,
      paymentType: getVehicleDetails.paymentType,
      permitExpiryDate: getVehicleDetails?.permitExpiryDate
        ? dayjs(getVehicleDetails?.permitExpiryDate)
        : null,
      nationalPermitExpiryDate: getVehicleDetails?.nationalPermitExpiryDate
        ? dayjs(getVehicleDetails?.nationalPermitExpiryDate)
        : null,
      pollutionExpiryDate: getVehicleDetails?.pollutionExpiryDate
        ? dayjs(getVehicleDetails?.pollutionExpiryDate)
        : null,
      plateType: parseInt(getVehicleDetails.plateType),
      plateno: getVehicleDetails.vehicleNo,
      region: getVehicleDetails.region,
      regularRunningHours: getVehicleDetails.regularRunningHours,
      renewalPrice: getVehicleDetails.renewalPrice,
      seater: getVehicleDetails.seater,
      sequenceNo: getVehicleDetails.sequenceNo,
      serviceexpireKm: getVehicleDetails.serviceexpire,
      vehiclesimno: getVehicleDetails.simNo,
      speedlimit: getVehicleDetails.speedLimit,

      tId: getVehicleDetails.tId,
      tankdiameter1: getVehicleDetails.tankDiameter,
      tankdiameter2: getVehicleDetails.tankDiameter2,
      tankdiameter3: getVehicleDetails.tankDiameter3,
      tankheight1: getVehicleDetails.tankHeight,
      tankheight2: getVehicleDetails.tankHeight2,

      tankheight3: getVehicleDetails.tankHeight3,
      tanklength1: getVehicleDetails.tankLength,
      tanklength2: getVehicleDetails.tankLength2,
      tanklength3: getVehicleDetails.tankLength3,
      tankshape1: getVehicleDetails.tankShape,
      tankshape2: getVehicleDetails.tankShape2,
      tankshape3: getVehicleDetails.tankShape3,

      tankvolume: getVehicleDetails.tankVolume,
      tankwidth1: getVehicleDetails.tankWidth,
      tankwidth2: getVehicleDetails.tankWidth2,
      tankwidth3: getVehicleDetails.tankWidth3,
      tempId1: getVehicleDetails.tempId1,
      tempId2: getVehicleDetails.tempId2,
      tempId3: getVehicleDetails.tempId3,
      timezoneId: Number(getVehicleDetails.timezoneId),
      totalVehicleCost: getVehicleDetails.totalVehicleCost,

      // fuel: getVehicleDetails.type,
      username: getVehicleDetails.username,
      vehicleBrand: getVehicleDetails.vehicleBrand,
      vehicleId: getVehicleDetails.vehicleNo,
      vehicleName: getVehicleDetails.vehicleName,
      vehicleNoArabic: getVehicleDetails.vehicleNoArabic,

      vehiclePurchaseDate: getVehicleDetails.vehiclePurchaseDate
        ? dayjs(getVehicleDetails?.vehiclePurchaseDate)
        : null,
      vehicleType: getVehicleDetails.vehicleType,
      vehicleTypeNew: getVehicleDetails.vehicleTypeNew,
      weightFactor: getVehicleDetails.weightFactor,
      pollingimei: getVehicleDetails.pollingimei,
      renewedAt: getVehicleDetails.renewedAt,
      mobileno: getVehicleDetails.mobileno,
      attendantName: getVehicleDetails.attendantName,
      icon: getVehicleDetails.icon,
      rpm: getVehicleDetails.rpm,
      movingRPM: getVehicleDetails.movingRPM,
      idelRPM: getVehicleDetails.idelRPM,

      UDID: getVehicleDetails.UDID,
      UDID2: getVehicleDetails.UDID2,
      seatCapacity: getVehicleDetails.seatCapacity,
      noOfBaggage: getVehicleDetails.noOfBaggage,
      harshAcce: getVehicleDetails.harshAcce,
      harshBrack: getVehicleDetails.harshBrack,
      exIdeling: getVehicleDetails.exIdeling,
      speeding: getVehicleDetails.speeding,
      rpmExcess: getVehicleDetails.rpmExcess,
      inputdigital: getVehicleDetails.inputdigital,
      rule: getVehicleDetails.ruleId,
      speedmileage: getVehicleDetails.speedmileage,
      mileage: getVehicleDetails.mileage,
      pulseCount: getVehicleDetails.pulseCount,
      speedConstant: getVehicleDetails.speedConstant,

      MDVR: getVehicleDetails.MDVR,
      cmsvssWebHost: getVehicleDetails.cmsvssWebHost,
      cmsvssWebPort: getVehicleDetails.cmsvssWebPort,
      cmsvssLoginServerPort: getVehicleDetails.cmsvssLoginServerPort,
      cmsvssPlaybachPort: getVehicleDetails.cmsvssPlaybachPort,
      cmsvssUserName: getVehicleDetails.cmsvssUserName,
      cmsvssPassword: getVehicleDetails.cmsvssUserName,

      fuelConsumption: getVehicleDetails.fuelConsumption,
      fuelCalibrationId: getVehicleDetails.fuelCalibrationId,
      companyLable: "",
    });
    this.setState({ icon: getVehicleDetails.icon });
    if (getVehicleDetails.No_FuelTank == 1) {
      this.setState({ one: true });
      this.setState({ two: false });
      this.setState({ three: false });
    } else if (getVehicleDetails.No_FuelTank == 2) {
      this.setState({ one: true });
      this.setState({ two: true });
      this.setState({ three: false });
    } else if (getVehicleDetails.No_FuelTank == 3) {
      this.setState({ one: true });
      this.setState({ two: true });
      this.setState({ three: true });
    }

    if (getVehicleDetails.tankShape == "Horizontal Rectangle") {
      this.setState({ horizontalrectangle: true });
    } else if (getVehicleDetails.tankShape == "Vertical Cylinder") {
      this.setState({ verticalcylinder: true });
    } else if (getVehicleDetails.tankShape == "Horizontal Cylinder") {
      this.setState({ horizontalcylinder: true });
    }

    if (getVehicleDetails.tankShape2 == "Horizontal Rectangle") {
      this.setState({ horizontalrectangle2: true });
    } else if (getVehicleDetails.tankShape2 == "Vertical Cylinder") {
      this.setState({ verticalcylinder2: true });
    } else if (getVehicleDetails.tankShape2 == "Horizontal Cylinder") {
      this.setState({ horizontalcylinder2: true });
    }

    if (getVehicleDetails.tankShape3 == "Horizontal Rectangle") {
      this.setState({ horizontalrectangle3: true });
    } else if (getVehicleDetails.tankShape3 == "Vertical Cylinder") {
      this.setState({ verticalcylinder3: true });
    } else if (getVehicleDetails.tankShape3 == "Horizontal Cylinder") {
      this.setState({ horizontalcylinder3: true });
    }

    const addonStr = getVehicleDetails.addOn;
    console.log(getVehicleDetails.addOn);
    if (getVehicleDetails.addOn) {
      const addonArray = addonStr?.split(",");

      addonArray[0] === "1" && this.setState({ fuelSensor: true });
      addonArray[2] === "3" && this.setState({ DriverId: true });
      addonArray[3] === "4" && this.setState({ rfid: true });
      addonArray[1] === "2" && this.setState({ temperatureSensor: true });
      // addonArray[4] === "5" && this.setState({ rfid: true });
    }

    if (getVehicleDetails.vehicleType === 3) {
      this.setState({ school: true });
    }

    if (getVehicleDetails.deviceId === 6) {
      this.setState({ mdvrFields: true });
    }

    if (getVehicleDetails.speedmileage === 2) {
      this.setState({ pulseFields: true });
    }
    if (
      (this.props.getUserData().isadmin === 1 ||
        this.props.getUserData().isadmin === 0) &&
      this.props.vehicleIdForEdit
    ) {
      this.setState({ disabledField: true });
    }

    this.setState({ loadData: 1, loadingSpin: false });
  };

  validateNumberAndDecimal = (value, field) => {
    const value1 = value.replace(/[^0-9.,]+/g, "");

    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  handleSelectAllRules = async (e) => {
    var arr = [];
    this.setState({ selectRules: e.target.checked });

    if (e.target.checked) {
      const data = await this.vehicleStore?.vehicleRules.map((option) =>
        arr.push(option.ruleId)
      );

      this.formRef.current.setFieldsValue({ rule: arr });
    } else {
      arr = [];
      this.formRef.current.setFieldsValue({ rule: arr });
    }
  };

  closeDriverModal = () => {
    this.setState({ openDriverDetailsForm: false });
  };

  responseMsg = async (response) => {
    const DriverName = await this.driverStore.toGetDriverName(
      this.state.companyId
    );

    if (response.success == "S") {
      message.success(response.message);
      await this.driverStore.toGetDriverName(this.state.companyId);
      await this.formRef.current.setFieldsValue({
        driverId: Number(response?.data.driverId),
        // driverId:response.data.name
      });

      await this.setState({ openDriverDetailsForm: false });
      // await this.formRef.current?.resetFields();
    } else if (response.success === "F5000") {
      response.errors.map((value) => {
        message.error(this.context.translate(value["username"]));
        // console.log(this.context.translate(value["driver"]),value["driver"])
      });
    }

    this.setState({ loadData: 1 });
    this.setState({ loading: false });

    const companyData = await this.companyStore.getCompanyDataById(
      this.state.companyId
    );

    await this.setState({ loadData: 1 });
  };

  selectDriver = async (value) => {
    await this.setState({ driverId: value });
    this.formRef.current.setFieldsValue({ driverId: value });
  };

  showPulseFields = (val) => {
    if (val == 2) {
      this.setState({ pulseFields: true });
    } else {
      this.setState({ pulseFields: false });
    }
  };

  onVehicleDetailsSubmit = async (values) => {
    this.setState({ loading: true });

    const vehicle = {
      ...values,
      tokenId: "",
      companyId: this.state.companyId,
      vehicleType: this.state.vehicleType,
      vehicleId: this.state.vehicleId,
      userId: 1,
      ColorCode: this.state.colorHex,
      installDate: values?.installDate
        ? values?.installDate?.format(dateFormat)
        : null,
      expiryDate: values?.expiryDate
        ? values?.expiryDate?.format(dateFormat)
        : null,
      mulkiyaRenewal: values?.mulkiyaRenewal
        ? values?.mulkiyaRenewal?.format(dateFormat)
        : null,
      insuranceRenewal: values?.insuranceRenewal
        ? values?.insuranceRenewal?.format(dateFormat)
        : null,
      lastservicedone: values?.lastservicedone
        ? values?.lastservicedone?.format(dateFormat)
        : null,
      lastoilchange: values?.lastoilchange
        ? values?.lastoilchange?.format(dateFormat)
        : null,
      lasttirechange: values?.lasttirechange
        ? values?.lasttirechange?.format(dateFormat)
        : null,
      lastBatteryDate: values?.lastBatteryDate
        ? values?.lastBatteryDate?.format(dateFormat)
        : null,
      emiDueDate: values?.emiDueDate
        ? values?.emiDueDate?.format(dateFormat)
        : null,
      vehiclePurchaseDate: values?.vehiclePurchaseDate
        ? values?.vehiclePurchaseDate?.format(dateFormat)
        : null,
      permitExpiryDate: values?.permitExpiryDate
        ? values?.permitExpiryDate?.format(dateFormat)
        : null,
      nationalPermitExpiryDate: values?.nationalPermitExpiryDate
        ? values?.nationalPermitExpiryDate?.format(dateFormat)
        : null,
      pollutionExpiryDate: values?.pollutionExpiryDate
        ? values?.pollutionExpiryDate?.format(dateFormat)
        : null,

      addOn: [
        this.state.fuelSensor ? "1" : "0",
        this.state.temperatureSensor ? "2" : "0",
        this.state.DriverId ? "3" : "0",
        this.state.rfid ? "4" : "0",
      ],

      icon: this.state.icon,
    };

    const data = { tokenId: "", vehicle: vehicle };

    const resp = await this.vehicleStore.addData(data);

    await this.props.response(resp);
    if (
      this.context.translate(resp.data?.errors[0]?.full_imei) ===
      "IMEI NO. Already Exist for Asset"
    ) {
      // this.setState({ imeiError:"IMEI NO. Already Exist for Asset"});
      this.setState({
        imeiError: (
          <div style={{ marginTop: 5, color: "red", fontSize: 12 }}>
            IMEI NO. Already Exist for Asset
          </div>
        ),
      });
    }
    this.setState({ loading: false });
  };

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    if (!this.props.vehicleIdForEdit) {
      this.context.viewStateStore.setVehicleStates({ companyId: comp.value });
    }
    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });

    await this.setState({
      companyLable: { label: comp.label, value: comp.value },
    });

    const pollingData = await this.vehicleStore.getPollingImeiData();

    await this.setState({ getpollimie: pollingData });

    await this.setState({ full_imei: pollingData[0].imei });

    // this.formRef.current.setFieldsValue({ full_imei: pollingData[0].imei });

    await this.driverStore.toGetDriverName(comp.value);

    const vehicleData = await this.driverStore.getVehicles(comp.value);

    const ruleData = await this.vehicleStore.getVehicleRules(comp.value);

    await this.handleSearch();
  }

  async handleSearch() {
    await this.vehicleStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
      isadmin: this.props.getUserData().isadmin,
      userId: this.props.getUserData().userId,
      usercompanyId: this.props.getUserData().userCompanyId,
    });
    await this.setState({ loadList: 1 });
  }

  getPollingData = () => {
    if (this.state.getpollimie) {
      return this.state.getpollimie.map((value) => {
        return (
          <Select.Option value={value.imei}>{/* {value.imei} */}</Select.Option>
        );
      });
    }
  };

  addOn = (value) => {
    for (let i = 0; i < value.length; i++) {
      if (value[i] === 1) {
        this.setState({ fuelSensor: true });
      } else if (value[i] === 3) {
        this.setState({ DriverId: true });
      } else if (value[i] === 2) {
        this.setState({ temperatureSensor: true });
      } else if (value[i] === 4) {
        this.setState({ rfid: true });
      } else {
        this.setState({ fuelSensor: false });
        this.setState({ DriverId: false });

        this.setState({ temperatureSensor: false });
        this.setState({ rfid: false });
      }
    }
  };

  noOfFuelTanks = (value) => {
    if (value === 1) {
      this.setState({ one: true });
      this.setState({ two: false });
      this.setState({ three: false });
    } else if (value === 2) {
      this.setState({ one: true });
      this.setState({ two: true });
      this.setState({ three: false });
    } else if (value === 3) {
      this.setState({ one: true });
      this.setState({ two: true });
      this.setState({ three: true });
    } else {
      this.setState({ one: false });
      this.setState({ two: false });
      this.setState({ three: false });
    }
  };
  tankShape1 = (value) => {
    if (value === "Horizontal Rectangle") {
      this.setState({ horizontalrectangle: true });
      this.setState({ verticalcylinder: false });
      this.setState({ horizontalcylinder: false });
    } else if (value === "Vertical Cylinder") {
      this.setState({ verticalcylinder: true });
      this.setState({ horizontalrectangle: false });
      this.setState({ horizontalcylinder: false });
    } else if (value === "Horizontal Cylinder") {
      this.setState({ horizontalcylinder: true });
      this.setState({ verticalcylinder: false });
      this.setState({ horizontalrectangle: false });
    } else {
      this.setState({ verticalcylinder: false });
      this.setState({ horizontalrectangle: false });
      this.setState({ horizontalcylinder: false });
    }
  };

  tankShape2 = (value) => {
    if (value === "Horizontal Rectangle") {
      this.setState({ horizontalrectangle2: true });
      this.setState({ horizontalcylinder2: false });
      this.setState({ verticalcylinder2: false });
    } else if (value === "Vertical Cylinder") {
      this.setState({ verticalcylinder2: true });
      this.setState({ horizontalrectangle2: false });
      this.setState({ horizontalcylinder2: false });
    } else if (value === "Horizontal Cylinder") {
      this.setState({ horizontalcylinder2: true });
      this.setState({ verticalcylinder2: false });
      this.setState({ horizontalrectangle2: false });
    } else {
      this.setState({ verticalcylinder2: false });
      this.setState({ horizontalrectangle2: false });
      this.setState({ horizontalcylinder2: false });
    }
  };

  tankShape3 = (value) => {
    if (value === "Horizontal Rectangle") {
      this.setState({ horizontalrectangle3: true });
      this.setState({ horizontalcylinder3: false });
      this.setState({ verticalcylinder3: false });
    } else if (value === "Vertical Cylinder") {
      this.setState({ verticalcylinder3: true });
      this.setState({ horizontalrectangle3: false });
      this.setState({ horizontalcylinder3: false });
    } else if (value === "Horizontal Cylinder") {
      this.setState({ horizontalcylinder3: true });
      this.setState({ verticalcylinder3: false });
      this.setState({ horizontalrectangle3: false });
    } else {
      this.setState({ verticalcylinder3: false });
      this.setState({ horizontalrectangle3: false });
      this.setState({ horizontalcylinder3: false });
    }
  };

  handleVTypeChange = async (value, option) => {
    await this.setState({ vehicleType: option.data });
    await this.formRef.current.setFieldsValue({ vehicleTypeNew: option.value });
  };
  onSelectSchool = (value) => {
    if (value === "SchoolBus") {
      this.setState({ school: true });
    } else {
      this.setState({ school: false });
    }
  };

  validateFieldsValue = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9.]*/g, "");

    if (value1) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  validateYearField = (value, field) => {
    const value1 = value.replace(/[^0-9+]*/g, "");
    if (value1) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  getTypeByCategory = async (categoryId) => {
    const data = await this.vehicleStore.getTypeByCategory(categoryId);

    await this.setState({ loadData: 1 });
  };

  getWeightsUsingTid = async (tId) => {
    const data = await this.vehicleStore.getWeightBytId(tId);

    await this.formRef.current.setFieldsValue({
      emptyWeight: data.trailerEmptyWeight,
    });

    const total = parseFloat(this.state.headWeight) + data.trailerEmptyWeight;

    await this.setState({ loadData: 1 });
  };

  warning = () => {
    Modal.warning({
      // title: "Alert",
      title: this.context.translate("_FLEET_ALERT_"),
      // content: "Please select installation type as PTA to enable load Sensor",
      content: this.context.translate("_INSTALLATION_TYPE_MESSAGE_"),
    });
  };

  handleChangeHex = (color) => {
    const colorHex = typeof color === "string" ? color : color.toHexString();
    this.setState({ colorHex });
  };

  handleFormatChangeHex = (formatHex) => {
    this.setState({ formatHex });
  };
  handleFormError = (err) => {
    const { errorFields } = err;

    const tabErrors = {};

    errorFields.forEach(({ name }) => {
      const [fieldName] = name;
      const tabFieldMappings = {
        companyId: 1,
        plateno: 1,
        vehicleTypeNew: 1,
        timezoneId: 1,
        installDate: 1,
        renewalPrice: 1,
        pollingimei: 1,
        vehiclesimno: 1,
        full_imei: 1,

        // second tab

        // third tab
        installer: 3,
        installedLocation: 3,
      };
      const tab = tabFieldMappings[fieldName];
      console.warn(tab);
      if (tab) {
        tabErrors[tab] = true;
      }
    });

    this.setState({ tabErrors }); // Update the state with tabErrors
  };

  handleNextClick = async () => {
    const form = this.formRef.current;

    if (form) {
      try {
        if (this.state.activeTab === "1") {
          // Validate only the fields in the 'User Information' tab
          await form.validateFields([
            "companyId",
            "plateno",
            "vehicleTypeNew",
            "timezoneId",
            "installDate",
            "renewalPrice",
            "pollingimei",
            "vehiclesimno",
            "full_imei",
          ]);
        } else {
          // Validate all fields in other tabs
          await form.validateFields();
        }

        const { activeTab } = this.state;
        const nextTab = String(Number(activeTab) + 1);
        this.setState({ activeTab: nextTab });
      } catch (err) {
        console.error("Form Validation Error:", err);
        message.error(
          this.context.translate("_FLEET_GROUP_GROUPDETAIL_ERRORMSG_")
          // "Please fill correct data in the fields that are marked red."
        );

        this.setState({ formError: true });
      }
    }
  };
  handleTabChange = (key) => {
    this.setState({
      activeTab: key,
      formError: false, // Reset formError when switching tabs
    });
  };

  handleBackClick = () => {
    const { activeTab } = this.state;
    const previousTab = String(Number(activeTab) - 1);

    this.setState({ activeTab: previousTab });
  };

  getUploadData = async (fileUp) => {
    let fileinfo = fileUp.file.response;

    if (fileinfo?.success == "S") {
      // console.log(fileinfo.data)

      await this.setState({ icon: fileinfo.data });
    }
    fileUp.fileList[0].url = config.fleetURL + fileinfo?.folderName;
    this.vehicleStore.setLogoFromDb(fileUp.fileList);

    this.setState({ update: 1 });
  };

  validatePhoneNumber = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");

    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  closeModal = () => {
    this.props.closeModal();
  };

  handleDeviceFields = (val) => {
    console.log(val);
    if (val == 6) {
      this.setState({ mdvrFields: true });
    } else {
      this.setState({ mdvrFields: false });
    }
  };

  render() {
    const { colorHex, formatHex } = this.state;

    return (
      <>
        <div style={{ padding: 0 }}>
          <div>
            <Spin spinning={this.state.loadingSpin} fullscreen>
              <Form
                ref={this.formRef}
                // {...layout}
                // labelCol={{
                //   span: 20,
                // }}
                className="formClass" //change
                layout="vertical"
                onFinish={this.onVehicleDetailsSubmit}
                onFinishFailed={this.handleFormError}
                // {...this.layout}
                name="nest-message"
              >
                <Tabs
                  activeKey={this.state.activeTab}
                  onChange={this.handleTabChange}
                >
                  <TabPane
                    tab={
                      <span
                        className={
                          this.state.tabErrors["1"] && "validation-failed"
                        }
                      >
                        <Button type="text">
                          {/* Account */}
                          {this.context.translate(
                            "_FLEET_DASHBORD_NEWPOI_CUSTOMER_"
                          )}
                        </Button>
                      </span>
                    }
                    key="1"
                    // style={{ marginLeft: "30px" }}
                  >
                    <Row style={{ overflowY: "scroll", height: "480px" }}>
                      <Col span={12} className="formColumnClass">
                        {(this.props.getUserData().isadmin === -1 ||
                          this.props.getUserData().isadmin === 3) && (
                          <Form.Item
                            name="companyId"
                            label={this.context.translate("_ACCOUNT_")}
                            rules={[{ required: true }]}
                          >
                            <CompanyAutocomplete
                              onChange={(value) => {
                                this.handleChange(value);
                                // this.formRef.current.setFieldsValue({
                                //   full_imei: undefined,
                                //   //  driverId:9750
                                // });
                              }}
                              companyId={this.state.companyId}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        )}

                        {this.props.getUserData().isadmin !== 1 && (
                          <Form.Item
                            name="pollingimei"
                            label={this.context.translate("_POLLING_IMEI_")}
                          >
                            <Select
                              onSelect={() =>
                                this.formRef.current.setFieldsValue({
                                  full_imei: this.state.full_imei,
                                })
                              }
                            >
                              {this.getPollingData()}{" "}
                            </Select>
                          </Form.Item>
                        )}
                        <Form.Item
                          name="isCanbus"
                          // className="formCheckboxCss"
                          label=" "
                          valuePropName="checked"
                        >
                          <Checkbox>
                            {this.context.translate("_CANBUS_")}
                          </Checkbox>
                        </Form.Item>

                        <Form.Item
                          name="fuelConsumption"
                          // label="Vehicle Mileage"
                          label={this.context.translate("_VEHICLE_MILAGE_")}
                        >
                          <Input
                            onChange={(e) =>
                              this.validateFieldsValue(
                                e.target.value,
                                "fuelConsumption"
                              )
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          name="comment"
                          // label="Comment"
                          label={this.context.translate(
                            "_FLEET_CREATEROUTE_ROUTEADD_COMMENT_"
                          )}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="plateno"
                          label={this.context.translate("_PLATE_NO_")}
                          rules={[{ required: true }]}
                        >
                          <Input disabled={this.state.disabledField} />
                        </Form.Item>

                        <Form.Item
                          name="vehicleName"
                          // label="Asset Name/Alias"
                          label={this.context.translate(
                            "_FLEET_VEHICLE_VEHICLEDETAIL_VEHICLENAME_"
                          )}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="vehicleNoArabic"
                          // label="Asset Name/Alias"
                          label={this.context.translate("ARABIC_VEHICLE_NO_")}
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="ownership"
                          label={this.context.translate("_VEHICLE_OWNERSHIP_")}
                          // rules={[{ required: true }]}
                        >
                          <Select
                            options={[
                              {
                                // label: "Vertical Cylinder",
                                label: this.context.translate("_OWN_"),
                                value: "Own",
                              },
                              {
                                // label: "Horizontal Rectangle",
                                label: this.context.translate("_RENTED_"),
                                value: "Rented",
                              },
                            ]}
                          />
                        </Form.Item>

                        <Form.Item
                          name="vehicleTypeNew"
                          label={this.context.translate("_VEHICLE_TYPE_")}
                          rules={[{ required: true }]}
                        >
                          <Select
                            onChange={(value) =>
                              this.onSelectSchool(value.value)
                            }
                            onSelect={this.handleVTypeChange}
                            labelInValue
                            options={[
                              {
                                value: "Truck",
                                // label: "Truck",
                                label: this.context.translate("_TRUCK_"),
                                data: "1",
                              },
                              {
                                value: "Car",
                                // label: "Car",
                                label: this.context.translate("_car_"),
                                data: "2",
                              },
                              {
                                value: "SchoolBus",
                                // label: "SchoolBus",
                                label: this.context.translate("_SCHOOL_BUS_"),
                                data: "3",
                              },
                              {
                                value: "PickUp",
                                // label: "PickUp",
                                label: this.context.translate("_PICKUP_"),
                                data: "4",
                              },
                              {
                                value: "2Ton PickUp",
                                // label: "2Ton PickUp",
                                label: this.context.translate("_2TON_PICKUP_"),
                                data: "29",
                              },
                              {
                                value: "3Ton PickUp",
                                // label: "3Ton PickUp",
                                label: this.context.translate("_3TON_PICKUP_"),
                                data: "5",
                              },
                              {
                                value: "5Ton PickUp",
                                // label: "5Ton PickUp",
                                label: this.context.translate("_5TON_PICKUP_"),
                                data: "6",
                              },
                              {
                                value: "10Ton PickUp",
                                // label: "10Ton PickUp",
                                label: this.context.translate("_10TON_PICKUP_"),
                                data: "7",
                              },
                              {
                                value: "Crane",
                                // label: "Crane",
                                label: this.context.translate("_CRANE_"),
                                data: "8",
                              },
                              {
                                value: "JCB",
                                // label: "JCB",
                                label: this.context.translate("_JCB_"),
                                data: "9",
                              },
                              {
                                value: "Van",
                                // label: "Van",
                                label: this.context.translate("_VAN_"),
                                data: "10",
                              },
                              {
                                value: "Hiace",
                                // label: "Hiace",
                                label: this.context.translate("_HIACE_"),
                                data: "11",
                              },
                              {
                                value: "Urvan",
                                // label: "Urvan",
                                label: this.context.translate("_URVAN_"),
                                data: "12",
                              },
                              {
                                value: "Tanker",
                                // label: "Tanker",
                                label: this.context.translate("_TANKER_"),
                                data: "13",
                              },
                              {
                                value: "Bulldozer",
                                // label: "Bulldozer",
                                label: this.context.translate("_BULLDOZER_"),
                                data: "14",
                              },
                              {
                                value: "Dumper",
                                // label: "Dumper",
                                label: this.context.translate("_DUMPER_"),
                                data: "15",
                              },
                              {
                                value: "Reefer",
                                // label: "Reefer",
                                label: this.context.translate("_REEFER_"),
                                data: "16",
                              },
                              {
                                value: "Shovel",
                                // label: "Shovel",
                                label: this.context.translate("_SHOVEL_"),
                                data: "17",
                              },
                              {
                                value: "Excavator",
                                // label: "Excavator",
                                label: this.context.translate("_EXCAVATOR_"),
                                data: "18",
                              },
                              {
                                value: "Trailer",
                                label: this.context.translate("_TAILER_"),
                                // label: "Trailer",
                                data: "19",
                              },
                              {
                                value: "Forklif",
                                // label: "Forklif",
                                label: this.context.translate("_FORKLIF_"),
                                data: "20",
                              },
                              {
                                value: "Bobcat",
                                // label: "Bobcat",
                                label: this.context.translate("_BOBCAT_"),
                                data: "21",
                              },
                              {
                                value: "Roller",
                                // label: "Roller",
                                label: this.context.translate("_ROLLER_"),
                                data: "22",
                              },
                              {
                                value: "Canter",
                                // label: "Canter",
                                label: this.context.translate("_CANTER_"),
                                data: "23",
                              },
                              {
                                value: "Mini Bus",
                                // label: "Mini Bus",
                                label: this.context.translate("_MINIBUS_"),
                                data: "24",
                              },
                              {
                                value: "Tourist Bus",
                                // label: "Tourist Bus",
                                label: this.context.translate("_TOURIST_BUS_"),
                                data: "25",
                              },
                              {
                                value: "Boom Loader",
                                // label: "Boom Loader",
                                label: this.context.translate("_BOOM_LOADER_"),
                                data: "26",
                              },
                              {
                                value: "Generator",
                                // label: "Generator",
                                label: this.context.translate("_GENERATOR_"),
                                data: "27",
                              },
                              {
                                value: "Hilux",
                                // label: "Hilux",
                                label: this.context.translate("_HILUX_"),
                                data: "28",
                              },
                              {
                                value: "Motorcycle",
                                // label: "Motorcycle",
                                label: this.context.translate("_MOTORCYCLE_"),
                                data: "29",
                              },
                              {
                                value: "Taxi",

                                label: this.context.translate("_TAXI_"),
                                data: "30",
                              },
                              {
                                value: "SmartPhone",
                                // label: "Motorcycle",
                                label: this.context.translate("_SMART_PHONE_"),
                                data: "31",
                              },
                              {
                                data: "32",
                                label: "Icon Image",
                                value: "Icon Image",
                              },
                            ]}
                          />
                        </Form.Item>

                        {this.state.school && (
                          <>
                            <Form.Item
                              name="seater"
                              label={this.context.translate("_SERATER_")}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              name="mobileno"
                              // label="Attendant Mobile No"
                              label={this.context.translate(
                                "_ATTENDANT_MOBILE_NO_"
                              )}
                              rules={[
                                {
                                  pattern: /^(\d{7}|\d{10})$/,
                                  message: "Enter 7 or 10 Digit Number",
                                },
                              ]}
                            >
                              <Input
                                onChange={(e) =>
                                  this.validatePhoneNumber(
                                    e.target.value,
                                    "mobileno"
                                  )
                                }
                              />
                            </Form.Item>

                            <Form.Item
                              name="attendantName"
                              // label="Attendant Name"
                              label={this.context.translate("_ATTENDANT_NAME_")}
                            >
                              <Input />
                            </Form.Item>
                          </>
                        )}

                        <Form.Item
                          name="timezoneId"
                          label={this.context.translate(
                            "_FLEET_COMPANY_COMPANYDETAIL_TIME_"
                          )}
                          rules={[{ required: true }]}
                          initialValue={this.state.timezoneId}
                        >
                          {/* <Select
                      placeholder="Select TimeZone"
                      onSearch={this.handleSearch}
                      showSearch
                      fieldNames={{ label: "name", value: "timezoneId" }}
                      optionFilterProp="children"
                      options={this.countryStore?.timeZones}
                    /> */}

                          <Select
                            showSearch
                            placeholder="Select TimeZone"
                            filterOption={(inputValue, option) => {
                              return (
                                option.children
                                  .toLowerCase()
                                  .indexOf(inputValue.toLowerCase()) >= 0
                              );
                            }}
                            // defaultValue={"AMST - GMT+03:00"}
                          >
                            {this.countryStore?.timeZones.map((country) => {
                              return (
                                <Option value={Number(country.timezoneId)}>
                                  {country.abbr}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name="installDate"
                          label={this.context.translate("_INSTALLATION_DATE_")}
                          rules={[
                            {
                              required: true,
                              // message: "Please enter Installation Date",
                            },
                          ]}
                          initialValue={dayjs()}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format={dateFormat}
                            disabled={this.state.disabledField}
                          />
                        </Form.Item>

                        {/* <Form.Item
                          name="renewalPrice"
                          label={this.context.translate("_RENEWAL_PRICE_")}
                          rules={[{ required: true }]}
                          initialValue={360}
                        >
                          <Input disabled={this.state.disabledField} />
                        </Form.Item> */}

                        <Form.Item
                          name="paymentType"
                          label={this.context.translate("_PAYMENT_TYPE_")}
                          initialValue={"Yearly"}
                          rules={[{ required: true }]}
                        >
                          <Select
                            disabled={this.state.disabledField}
                            options={[
                              {
                                // label: "Monthly",
                                label: this.context.translate("_MONTHLY_"),
                                value: "Monthly",
                              },
                              // {
                              //   // label: "Quarterly",
                              //   label: this.context.translate("_QUARTERLY_"),
                              //   value: "Quarterly",
                              // },
                              // {
                              //   // label: "6 Months",
                              //   label: this.context.translate("_6_MONTHS_"),
                              //   value: "6Months",
                              // },
                              {
                                // label: "Yearly",
                                label: this.context.translate("_YEARLY_"),
                                value: "Yearly",
                              },
                            ]}
                            onChange={(value) => {
                              if (value == "Monthly") {
                                this.formRef.current.setFieldsValue({
                                  expiryDate: dayjs().add(1, "month"),
                                });
                                // } else if (value == "Quarterly") {
                                //   this.formRef.current.setFieldsValue({
                                //     expiryDate: dayjs().add(4, "month"),
                                //   });
                                // } else if (value == "6Months") {
                                //   this.formRef.current.setFieldsValue({
                                //     expiryDate: dayjs().add(6, "month"),
                                //   });
                              } else if (value == "Yearly") {
                                this.formRef.current.setFieldsValue({
                                  expiryDate: dayjs().add(1, "year"),
                                });
                              }
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="expiryDate"
                          label={this.context.translate(
                            "_VEHICLE_EXPIRY_DATE_"
                          )}
                          rules={[{ type: Date }]}
                          initialValue={dayjs().add(1, "years")}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format="YYYY-MM-DD"
                            disabled={this.state.disabledField}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="formColumnClass">
                        <Form.Item
                          name="renewedAt"
                          label={this.context.translate("_RENEWED_AT_")}
                        >
                          <Input disabled />
                        </Form.Item>

                        <Form.Item
                          name="mulkiyaRenewal"
                          // label="Istimara Renewal Date"
                          label={this.context.translate(
                            "_MULKIYA_RENEWAL_DATE_1"
                          )}
                          rules={[{ type: Date }]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format={dateFormat}
                          />
                        </Form.Item>

                        <Form.Item
                          name="insuranceRenewal"
                          // label="Insurance Expiry Date"
                          label={this.context.translate(
                            "_INSURANCE_EXPIRY_DATE_"
                          )}
                          rules={[{ type: Date }]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format={dateFormat}
                          />
                        </Form.Item>

                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col span={16}>
                            <Form.Item
                              name="driverId"
                              label={this.context.translate("_DRIVER_")}
                              // rules={[{ required: this.state.loadSensor }]}
                            >
                              <Select
                                showSearch
                                // placeholder="Select Country"
                                placeholder={this.context.translate(
                                  "_SELECT_DRIVER_"
                                )}
                                filterOption={(inputValue, option) => {
                                  return (
                                    option.children
                                      .toLowerCase()
                                      .indexOf(inputValue.toLowerCase()) >= 0
                                  );
                                }}
                                // placeholder="Select Driver"
                                value={this.state.driverId}
                                onSelect={this.selectDriver}
                                // showSearch
                                // fieldNames={{ value: "driverId", label: "name" }}
                                // optionFilterProp="children"
                                // options={this.driverStore?.getDriverName}
                              >
                                {this.driverStore?.getDriverName?.map(
                                  (driver) => {
                                    return (
                                      <Option value={parseInt(driver.driverId)}>
                                        {driver.name}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                            </Form.Item>
                          </Col>
                          {/* <Col span={1}></Col> */}
                          <Col span={4}>
                            <Form.Item name=" " label={" "}>
                              <Tooltip
                                // title="Add New Driver"
                                title={this.context.translate(
                                  "_FLEET_VEHICLE_VEHICLEDETAIL_ADDDRIVER_"
                                )}
                                color={config.tooltipColorEdit}
                                placement="bottom"
                              >
                                <Button
                                  type="primary"
                                  style={{ height: "48px" }}
                                  onClick={(e) => {
                                    this.setState({
                                      openDriverDetailsForm: true,
                                    });
                                  }}
                                >
                                  <PlusOutlined />
                                </Button>
                              </Tooltip>
                            </Form.Item>
                          </Col>
                        </Row>
                        {this.props.getUserData().isadmin !== 1 && (
                          <>
                            <Form.Item
                              name="full_imei"
                              label={this.context.translate("_IMEI_")}
                              rules={[{ required: true }]}
                              help={
                                this.state.imeiError
                                  ? this.state.imeiError
                                  : null
                              }
                              // validateStatus={this.state.imeiError ? "error" :''}
                              // status={this.state.imeiError ? "error" : false}
                            >
                              <Input
                                status={this.state.imeiError ? "error" : null}
                                onChange={(e) =>
                                  this.validateYearField(
                                    e.target.value,
                                    "full_imei"
                                  )
                                }
                              />
                            </Form.Item>

                            <Form.Item
                              name="mdvrfull_imei"
                              label={this.context.translate("_MDVR_IMEI_")}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              name="vehiclesimno"
                              label={this.context.translate("_SIM_NO_")}
                              rules={[{ required: true }]}
                            >
                              <Input
                                onChange={(e) =>
                                  this.validateYearField(
                                    e.target.value,
                                    "vehiclesimno"
                                  )
                                }
                              />
                            </Form.Item>
                          </>
                        )}
                        <Form.Item
                          name="speedlimit"
                          // label="Speed Limit"
                          label={this.context.translate("_SPEED_LIMIT_")}
                          initialValue={100}
                          rules={[{ required: true }]}
                        >
                          <Input
                            onChange={(e) =>
                              this.validateFieldsValue(
                                e.target.value,
                                "speedlimit"
                              )
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          name="idleLimit"
                          // label="Idle Limit"
                          label={this.context.translate("_MAX_IDLE_LIMIT_")}
                          initialValue={20}
                          rules={[{ required: true }]}
                        >
                          <Input
                            onChange={(e) =>
                              this.validateFieldsValue(
                                e.target.value,
                                "idleLimit"
                              )
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          name="minimumFineAmount"
                          label={this.context.translate("_MIN_FINE_AMOUNT_")}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="finePerMinute"
                          label={this.context.translate("_FINE_PER_MINUTE_")}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="vehicleBrand"
                          label={this.context.translate("_BRAND_")}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="vehicleModel"
                          label={this.context.translate("_VEHICLE_MODEL_")}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="ColorCode"
                          label={this.context.translate(
                            "_FLEET_VEHICLE_VEHICLEDETAIL_COLOR_"
                          )}
                          initialValue={"FFFFFF"}
                        >
                          <ColorPicker
                            style={{ width: "100%", height: "48px" }}
                            format={formatHex}
                            color={colorHex}
                            onChange={this.handleChangeHex}
                            onFormatChange={this.handleFormatChangeHex}
                          />
                        </Form.Item>

                        {(this.props.getUserData().isadmin == -1 ||
                          this.props.getUserData().isadmin == 3) && (
                          <Form.Item
                            name="deviceId"
                            label={this.context.translate("_DEVICE_")}
                          >
                            <Select
                              options={[
                                { label: "Ruptela", value: 1 },
                                { label: "GT06 ", value: 5 },
                                { label: "MDVR", value: 6 },
                                { label: "GV300", value: 7 },
                                { label: "PCBA", value: 8 },
                                { label: "Atelematics", value: 9 },
                                { label: "Teltonika", value: 10 },
                                { label: "Meitrack", value: 11 },
                                { label: "AK11", value: 13 },
                                { label: "Howen MDVR", value: 14 },
                                { label: "BCE", value: 15 },
                                { label: "Wanway", value: 16 },
                                { label: "Rutela Trace5", value: 17 },
                              ]}
                              onSelect={(val) => this.handleDeviceFields(val)}
                            />
                          </Form.Item>
                        )}
                      </Col>
                    </Row>

                    <div className="formBtnGroup">
                      <Button
                        className="formCancelButton"
                        onClick={(e) => {
                          this.closeModal();
                          // this.formRef.current?.resetFields();
                        }}
                      >
                        {this.context.translate("_FLEET_CLOSE_BTN_")}
                      </Button>
                      <Button
                        type="primary"
                        className="formSaveButton"
                        onClick={this.handleNextClick}
                      >
                        {/* Next */}
                        {this.context.translate("_NEXT_")}
                      </Button>
                    </div>
                  </TabPane>

                  <TabPane
                    tab={
                      <span
                        className={
                          this.state.tabErrors["2"] && "validation-failed"
                        }
                      >
                        <Button type="text">
                          {this.context.translate("_DETAILS_")}
                        </Button>
                      </span>
                    }
                    key="2"
                    // style={{ marginLeft: "30px" }}
                  >
                    <Row style={{ overflowY: "scroll", height: "480px" }}>
                      <Col span={12} className="formColumnClass">
                        <Form.Item
                          name="speedmileage"
                          label={this.context.translate("_SPEEDMILEAGE_")}
                          initialValue={"GPS"}
                          rules={[{ required: true }]}
                        >
                          <Select
                            options={[
                              { label: "GPS", value: "GPS" },
                              { label: "Pulse", value: "Pulse" },
                            ]}
                            onSelect={(val) => {
                              // console.log(val)
                              this.showPulseFields(val);
                            }}
                          />
                        </Form.Item>

                        {this.state.pulseFields && (
                          <>
                            {" "}
                            <Form.Item
                              name="speedConstant"
                              label={this.context.translate("_SPEED_CONSTANT_")}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              name="pulseCount"
                              label={this.context.translate(
                                "_PULSE_COUNT_PER_CIRCLE_"
                              )}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              name="mileage"
                              label={this.context.translate(
                                "_MILEAGE_PER_CIRCLE_"
                              )}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>{" "}
                          </>
                        )}

                        <Form.Item
                          name="odometerReading"
                          label={this.context.translate(
                            "_FLEET_VEHICLE_VEHICLEDETAIL_ODOMETER_"
                          )}
                        >
                          <Input
                            onChange={(e) =>
                              this.validateFieldsValue(
                                e.target.value,
                                "odometerReading"
                              )
                            }
                          />
                        </Form.Item>
                        {this.props.getUserData().isadmin == 0 ||
                          this.props.getUserData().isadmin == 1 ||
                          (!this.props.vehicleIdForEdit && (
                            <Form.Item
                              name="diffOdo"
                              label={this.context.translate("_DIFF_ODOMETER_")}
                            >
                              <Input
                                onChange={(e) =>
                                  this.validateFieldsValue(
                                    e.target.value,
                                    "diffOdo"
                                  )
                                }
                              />
                            </Form.Item>
                          ))}
                        <Form.Item
                          name="lastservicedone"
                          label={this.context.translate(
                            "_FLEET_VEHICLE_VEHICLEDETAIL_LASTSERVICE_"
                          )}
                          rules={[{ type: Date }]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format={dateFormat}
                          />
                        </Form.Item>

                        <Form.Item
                          name="lastoilchange"
                          label={this.context.translate(
                            "_FLEET_VEHICLE_VEHICLEDETAIL_LASTOILCHANGE_"
                          )}
                          rules={[{ type: Date }]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format={dateFormat}
                          />
                        </Form.Item>
                        <Form.Item
                          name="lasttirechange"
                          label={this.context.translate(
                            "_FLEET_VEHICLE_VEHICLEDETAIL_LASTTIRE_"
                          )}
                          rules={[{ type: Date }]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format={dateFormat}
                          />
                        </Form.Item>

                        <Form.Item
                          name="lastBatteryDate"
                          label={this.context.translate(
                            "_FLEET_VEHICLE_VEHICLEDETAIL_LASTBATTERY_"
                          )}
                          rules={[{ type: Date }]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format={dateFormat}
                          />
                        </Form.Item>

                        <Form.Item
                          name="batteryExpire"
                          label={this.context.translate(
                            "_FLEET_VEHICLE_VEHICLEDETAIL_BATTERY_CHANGE_INT_"
                          )}
                          // label={"Battery Change Interval(Days)"}
                        >
                          <Input
                            onChange={(e) =>
                              this.validateFieldsValue(
                                e.target.value,
                                "batteryExpire"
                              )
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          name="tirechangeKm"
                          label={this.context.translate(
                            "_FLEET_VEHICLE_VEHICLEDETAIL_TIRECHANGE_"
                          )}
                        >
                          <Input
                            placeholder={this.context.translate(
                              "_FLEET_VEHICLE_VEHICLEDETAIL_TIRECHANGE_"
                            )}
                            // placeholder="Tire C.(Km)"
                            onChange={(e) =>
                              this.validateFieldsValue(
                                e.target.value,
                                "tirechangeKm"
                              )
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          name="oilchangeKm"
                          label={this.context.translate(
                            "_FLEET_VEHICLE_VEHICLEDETAIL_OILCHANGE_"
                          )}
                        >
                          <Input
                            placeholder={this.context.translate(
                              "_FLEET_VEHICLE_VEHICLEDETAIL_OILCHANGE_"
                            )}
                            // placeholder="Oil C.(Km)"
                            onChange={(e) =>
                              this.validateFieldsValue(
                                e.target.value,
                                "oilchangeKm"
                              )
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          name="serviceexpireKm"
                          label={this.context.translate(
                            "_FLEET_VEHICLE_VEHICLEDETAIL_SERVICEEXPIRE_"
                          )}
                        >
                          <Input
                            onChange={(e) =>
                              this.validateFieldsValue(
                                e.target.value,
                                "serviceexpireKm"
                              )
                            }
                          />
                        </Form.Item>
                        {!this.props.vehicleIdForEdit && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Form.Item
                              name="rule"
                              label={this.context.translate("_RULE_")}
                              style={{ width: "80%" }}
                            >
                              <Select
                                mode="multiple"
                                className="search-field"
                                maxTagCount={"responsive"}
                                options={this.vehicleStore?.vehicleRules}
                                fieldNames={{ label: "name", value: "ruleId" }}
                              />
                            </Form.Item>

                            <Form.Item label=" ">
                              <Checkbox
                                checked={this.state.selectRules}
                                onChange={(e) => {
                                  this.handleSelectAllRules(e);
                                }}
                              >
                                {this.context.translate("_ALL_")}{" "}
                              </Checkbox>
                            </Form.Item>
                          </div>
                        )}
                        <Form.Item
                          name="machineservicehours"
                          label={this.context.translate(
                            "_MACHINE_SERVICE_HOURS_"
                          )}
                        >
                          <Input
                            onChange={(e) =>
                              this.validateFieldsValue(
                                e.target.value,
                                "machineservicehours"
                              )
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          name="vehiclePurchaseDate"
                          label={this.context.translate(
                            "_VEHICLE_PURCHASE_DATE_"
                          )}
                          rules={[{ type: Date }]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format={dateFormat}
                          />
                        </Form.Item>

                        <Form.Item
                          name="totalVehicleCost"
                          label={this.context.translate("_TOTAL_VEHICLE_COST_")}
                        >
                          <Input
                            onChange={(e) =>
                              this.validateFieldsValue(
                                e.target.value,
                                "totalVehicleCost"
                              )
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          name="downPayment"
                          label={this.context.translate("_DOWN_PAYMENT_")}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="monthlyEmi"
                          label={this.context.translate("_MONTHLY_EMI_")}
                        >
                          <Input
                            onChange={(e) =>
                              this.validateFieldsValue(
                                e.target.value,
                                "monthlyEmi"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="formColumnClass">
                        <Form.Item
                          name="noOfEmi"
                          label={this.context.translate("_NO_OF_EMI_")}
                        >
                          <Input
                            onChange={(e) =>
                              this.validateFieldsValue(
                                e.target.value,
                                "noOfEmi"
                              )
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          name="emiDueDate"
                          label={this.context.translate("_EMI_DUE_DATE_")}
                          rules={[{ type: Date }]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format={dateFormat}
                          />
                        </Form.Item>
                        <Form.Item
                          name="permitExpiryDate"
                          label={this.context.translate("_PERMIT_EXPIRY_DATE_")}
                        >
                          <DatePicker
                            format="YYYY-MM-DD"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="nationalPermitExpiryDate"
                          label={this.context.translate(
                            "_NATIONAL_PERMIT_EXPIRY_DATE_"
                          )}
                          rules={[{ type: Date }]}
                        >
                          <DatePicker
                            format="YYYY-MM-DD"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>

                        <Form.Item
                          name="pollutionExpiryDate"
                          label={this.context.translate(
                            "_POLLUTION_EXPIRY_DATE_"
                          )}
                          rules={[{ type: Date }]}
                        >
                          <DatePicker
                            format="YYYY-MM-DD"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>

                        <Form.Item
                          name="manufactureYear"
                          label={this.context.translate(
                            "_FLEET_VEHICLE_VEHICLEDETAIL_MANUFACTURE_"
                          )}
                        >
                          <Input
                            // placeholder="Year"
                            placeholder={this.context.translate(
                              "_FLEET_VEHICLE_VEHICLEDETAIL_MANUFACTURE_"
                            )}
                            onChange={(e) =>
                              this.validateYearField(
                                e.target.value,
                                "manufactureYear"
                              )
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          name="capacity"
                          //  label="Fuel Capacity"
                          label={this.context.translate(
                            "_FLEET_VEHICLE_VEHICLEDETAIL_FUELCAPACITY_"
                          )}
                        >
                          <Input
                            placeholder={this.context.translate(
                              "_FLEET_VEHICLE_VEHICLEDETAIL_FUELCAPACITY_"
                            )}
                            // placeholder="Capacity"
                            // label="Capacity"
                            onChange={(e) =>
                              this.validateFieldsValue(
                                e.target.value,
                                "capacity"
                              )
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          name="fuelConsumption"
                          label={this.context.translate("_VEHICLE_MILAGE_")}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="fuel"
                          //  label="Fuel Type:"
                          label={this.context.translate(
                            "_FLEET_VEHICLE_VEHICLEDETAIL_FUELTYPE_"
                          )}
                        >
                          <Select>
                            <Select.Option value="Petrol">
                              {this.context.translate(
                                "_FLEET_VEHICLEDETAIL_FUELTYPEDDL_PETROL"
                              )}
                            </Select.Option>
                            <Select.Option value="Diesel">
                              {this.context.translate(
                                "_FLEET_VEHICLEDETAIL_FUELTYPE_DIESEL_"
                              )}
                            </Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name="fuelCalibrationId"
                          //  label="Fuel Type:"
                          label={this.context.translate("_FUEL_CALIBRATION_")}
                        >
                          <Select
                            options={this.vehicleStore?.fuelDetails}
                            fieldNames={{
                              label: "fuelCalibrationName",
                              value: "Id",
                            }}
                          />
                        </Form.Item>

                        {this.props.getUserData().isadmin !== 1 && (
                          <>
                            <Form.Item
                              name="DigInpt1"
                              // label=" DigiInput1:"
                              label={this.context.translate(
                                "_FLEET_VEHICLE_VEHICLEDETAIL_DIGITAL1_"
                              )}
                            >
                              <Select
                                options={[
                                  {
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_DOOR_"
                                    ),
                                    value: "Door",
                                  },
                                  {
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_SEATBELT_"
                                    ),
                                    value: "Seatbelt",
                                  },
                                  {
                                    // label: "ACC",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_ACC_"
                                    ),
                                    value: "ACC",
                                  },
                                  {
                                    // label: "Panic",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_PANIC_"
                                    ),
                                    value: "Panic",
                                  },
                                  {
                                    // label: "AC ",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_AC_"
                                    ),
                                    value: "AC",
                                  },
                                  {
                                    // label: "Passenger Seat Belt",
                                    label:
                                      this.context.translate("_SEAT_SENSORS_"),
                                    value: "Seatsensor",
                                  },
                                ]}
                              />
                            </Form.Item>

                            <Form.Item
                              name="DigInpt2"
                              // label=" DigiInput2:"
                              label={this.context.translate(
                                "_FLEET_VEHICLE_VEHICLEDETAIL_DIGITAL2_"
                              )}
                            >
                              <Select
                                options={[
                                  {
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_DOOR_"
                                    ),
                                    value: "Door",
                                  },
                                  {
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_SEATBELT_"
                                    ),
                                    value: "Seatbelt",
                                  },
                                  {
                                    // label: "ACC",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_ACC_"
                                    ),
                                    value: "ACC",
                                  },
                                  {
                                    // label: "Panic",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_PANIC_"
                                    ),
                                    value: "Panic",
                                  },
                                  {
                                    // label: "AC ",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_AC_"
                                    ),
                                    value: "AC",
                                  },
                                  {
                                    // label: "Passenger Seat Belt",
                                    label:
                                      this.context.translate("_SEAT_SENSORS_"),
                                    value: "Seatsensor",
                                  },
                                ]}
                              />
                            </Form.Item>

                            <Form.Item
                              name="DigInpt3"
                              // label=" DigiInput2:"
                              label={this.context.translate(
                                "_FLEET_VEHICLE_VEHICLEDETAIL_DIGITAL3_"
                              )}
                            >
                              <Select
                                options={[
                                  {
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_DOOR_"
                                    ),
                                    value: "Door",
                                  },
                                  {
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_SEATBELT_"
                                    ),
                                    value: "Seatbelt",
                                  },
                                  {
                                    // label: "ACC",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_ACC_"
                                    ),
                                    value: "ACC",
                                  },
                                  {
                                    // label: "Panic",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_PANIC_"
                                    ),
                                    value: "Panic",
                                  },
                                  {
                                    // label: "AC ",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_AC_"
                                    ),
                                    value: "AC",
                                  },
                                  {
                                    // label: "Passenger Seat Belt",
                                    label:
                                      this.context.translate("_SEAT_SENSORS_"),
                                    value: "Seatsensor",
                                  },
                                ]}
                              />
                            </Form.Item>

                            <Form.Item
                              name="DigInpt4"
                              // label=" DigiInput2:"
                              label={this.context.translate(
                                "_FLEET_VEHICLE_VEHICLEDETAIL_DIGITAL4_"
                              )}
                            >
                              <Select
                                options={[
                                  {
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_DOOR_"
                                    ),
                                    value: "Door",
                                  },
                                  {
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_SEATBELT_"
                                    ),
                                    value: "Seatbelt",
                                  },
                                  {
                                    // label: "ACC",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_ACC_"
                                    ),
                                    value: "ACC",
                                  },
                                  {
                                    // label: "Panic",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_PANIC_"
                                    ),
                                    value: "Panic",
                                  },
                                  {
                                    // label: "AC ",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_AC_"
                                    ),
                                    value: "AC",
                                  },
                                  {
                                    // label: "Passenger Seat Belt",
                                    label:
                                      this.context.translate("_SEAT_SENSORS_"),
                                    value: "Seatsensor",
                                  },
                                ]}
                              />
                            </Form.Item>
                          </>
                        )}
                        {this.props.getUserData().isadmin !== 1 && (
                          <>
                            <Form.Item
                              name="AngInpt1"
                              // label=" DigiInput1:"
                              label={this.context.translate(
                                "_FLEET_DASHBORD_SETTING_FUELVOLUME1_"
                              )}
                            >
                              <Select
                                options={[
                                  {
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_DOOR_"
                                    ),
                                    value: "Door",
                                  },
                                  {
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_SEATBELT_"
                                    ),
                                    value: "Seatbelt",
                                  },
                                  {
                                    // label: "ACC",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_ACC_"
                                    ),
                                    value: "ACC",
                                  },
                                  {
                                    // label: "Panic",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_PANIC_"
                                    ),
                                    value: "Panic",
                                  },
                                  {
                                    // label: "AC ",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_AC_"
                                    ),
                                    value: "AC",
                                  },
                                  {
                                    // label: "Passenger Seat Belt",
                                    label:
                                      this.context.translate("_SEAT_SENSORS_"),
                                    value: "Seatsensor",
                                  },
                                ]}
                              />
                            </Form.Item>

                            <Form.Item
                              name="AngInpt2"
                              // label=" DigiInput2:"
                              label={this.context.translate(
                                "_FLEET_DASHBORD_SETTING_FUELVOLUME2_"
                              )}
                            >
                              <Select
                                options={[
                                  {
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_DOOR_"
                                    ),
                                    value: "Door",
                                  },
                                  {
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_SEATBELT_"
                                    ),
                                    value: "Seatbelt",
                                  },
                                  {
                                    // label: "ACC",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_ACC_"
                                    ),
                                    value: "ACC",
                                  },
                                  {
                                    // label: "Panic",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_PANIC_"
                                    ),
                                    value: "Panic",
                                  },
                                  {
                                    // label: "AC ",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_AC_"
                                    ),
                                    value: "AC",
                                  },
                                  {
                                    // label: "Passenger Seat Belt",
                                    label:
                                      this.context.translate("_SEAT_SENSORS_"),
                                    value: "Seatsensor",
                                  },
                                ]}
                              />
                            </Form.Item>

                            <Form.Item
                              name="AngInpt3"
                              // label=" DigiInput2:"
                              label={this.context.translate(
                                "_FLEET_DASHBORD_SETTING_FUELVOLUM3_"
                              )}
                            >
                              <Select
                                options={[
                                  {
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_DOOR_"
                                    ),
                                    value: "Door",
                                  },
                                  {
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_SEATBELT_"
                                    ),
                                    value: "Seatbelt",
                                  },
                                  {
                                    // label: "ACC",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_ACC_"
                                    ),
                                    value: "ACC",
                                  },
                                  {
                                    // label: "Panic",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_PANIC_"
                                    ),
                                    value: "Panic",
                                  },
                                  {
                                    // label: "AC ",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_AC_"
                                    ),
                                    value: "AC",
                                  },
                                  {
                                    // label: "Passenger Seat Belt",
                                    label:
                                      this.context.translate("_SEAT_SENSORS_"),
                                    value: "Seatsensor",
                                  },
                                ]}
                              />
                            </Form.Item>

                            <Form.Item
                              name="AngInpt4"
                              // label=" DigiInput2:"
                              label={this.context.translate(
                                "_FLEET_DASHBORD_SETTING_FUELVOLUME4_"
                              )}
                            >
                              <Select
                                options={[
                                  {
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_DOOR_"
                                    ),
                                    value: "Door",
                                  },
                                  {
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_SEATBELT_"
                                    ),
                                    value: "Seatbelt",
                                  },
                                  {
                                    // label: "ACC",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_ACC_"
                                    ),
                                    value: "ACC",
                                  },
                                  {
                                    // label: "Panic",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_PANIC_"
                                    ),
                                    value: "Panic",
                                  },
                                  {
                                    // label: "AC ",
                                    label: this.context.translate(
                                      "_FLEET_DASHBORD_SETTING_AC_"
                                    ),
                                    value: "AC",
                                  },
                                  {
                                    // label: "Passenger Seat Belt",
                                    label:
                                      this.context.translate("_SEAT_SENSORS_"),
                                    value: "Seatsensor",
                                  },
                                ]}
                              />
                            </Form.Item>
                          </>
                        )}

                        <Form.Item
                          className="formCheckboxCss"
                          name="icon"
                          label=" "
                          // label={this.context.translate("_FLEET_DASHBORD_NEWPOI_PHOTO_")}
                          //  valuePropName="fileList"
                          style={{ marginTop: "14px" }}
                          getValueFromEvent={this.normFile}
                        >
                          <Upload
                            style={{ backgroundColor: "black" }}
                            name="icon"
                            action={
                              config.baseURL +
                              "/index.php?c=company&a=fileupload"
                            }
                            listType="picture"
                            data={{
                              fileType: "vehicleicon",
                              fieldName: "icon",
                            }}
                            onChange={this.getUploadData}
                            onRemove={async () => {
                              await this.setState({ photo: "", fileList: [] });
                              await this.vehicleStore.setLogoFromDb([]);
                            }}
                            maxCount={1}
                            // fileList={this.companyStore.logoFromDb}
                            // onRemove={this.removeImage}
                            {...this.vehicleStore.logoFromDb}
                          >
                            <Button
                              className="imgupload"
                              icon={<UploadOutlined />}
                            >
                              {/* Click to upload */}
                              {this.context.translate("_CLICK_TO_UPLOAD_")}
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>

                    <div className="formBtnGroup">
                      <Button
                        className="formCancelButton"
                        onClick={this.handleBackClick}
                      >
                        {/* Back */}
                        {this.context.translate(
                          "_FLEET_USER_USERLIST_ASSIGNVEHICLE_BACKBTN_"
                        )}
                      </Button>
                      <Button
                        type="primary"
                        // htmlType=""
                        className="formSaveButton"
                        onClick={this.handleNextClick}
                      >
                        {/* Next */}
                        {this.context.translate("_NEXT_")}
                      </Button>
                    </div>
                  </TabPane>

                  <TabPane
                    tab={
                      <span
                        className={
                          this.state.tabErrors["3"] && "validation-failed"
                        }
                      >
                        <Button type="text">
                          {this.context.translate("_VEHICLE_INFO_")}
                        </Button>
                      </span>
                    }
                    key="3"
                    // style={{ marginLeft: "30px" }}
                  >
                    <Row style={{ overflowY: "scroll", height: "480px" }}>
                      <Col span={12} className="formColumnClass">
                        {this.props.getUserData().isadmin !== 1 && (
                          <>
                            <Form.Item
                              name="installer"
                              // label=" Installer"
                              label={this.context.translate("_INSTALLER_")}
                              // rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              name="installedLocation"
                              // label=" Installed Location"
                              label={this.context.translate(
                                "_INSATALLED_LOCATION_"
                              )}
                              // rules={[{ required: true }]}
                            >
                              <Input></Input>
                            </Form.Item>
                          </>
                        )}

                        <div className="vehicleFormCheckbox">
                          {this.props.getUserData().isadmin !== 1 && (
                            <>
                              <Form.Item
                                name="addOn"
                                // label="Add On"
                                label={this.context.translate("_ADDON_")}
                                style={{ marginTop: "14px" }}
                              >
                                <Checkbox
                                  // value={this.state.DriverId}
                                  checked={this.state.DriverId}
                                  onChange={(e) => {
                                    this.setState({
                                      DriverId: e.target.checked,
                                    });
                                  }}
                                >
                                  {/* Driver Id */}
                                  {this.context.translate(
                                    "_FLEET_REPORTS_DRIVER_ID_"
                                  )}
                                </Checkbox>

                                <Checkbox
                                  value={4}
                                  checked={this.state.rfid}
                                  onChange={(e) => {
                                    this.setState({ rfid: e.target.checked });
                                  }}
                                >
                                  {/* RFID */}
                                  {this.context.translate(
                                    "_FLEET_DASHBORD_SETTING_RFID_"
                                  )}
                                </Checkbox>
                              </Form.Item>
                            </>
                          )}
                        </div>

                        <Form.Item
                          name="password"
                          // label="Hour Meter Reading"
                          label={this.context.translate(
                            "_FLEET_LOGIN_PASSWORDLBL_"
                          )}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="UDID"
                          // label="Hour Meter Reading"
                          label={this.context.translate("_DEVICE_UDID_")}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="UDID2"
                          // label="Hour Meter Reading"
                          label={this.context.translate("_DEVICE_UDID2_")}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="seatCapacity"
                          // label="Hour Meter Reading"
                          label={this.context.translate("_SEATING_CAPACITY_")}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="noOfBaggage"
                          // label="Hour Meter Reading"
                          label={this.context.translate("_MAX_NO_BAGGAGE_")}
                        >
                          <Input />
                        </Form.Item>

                        {this.state.mdvrFields && (
                          <>
                            <Form.Item
                              name="cmsvssWebHost"
                              // label={this.context.translate("_MDVR_IMEI_")}
                              label="CMS/VSS Web Host"
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              name="MDVR"
                              // label={this.context.translate("_MDVR_IMEI_")}
                              label="MDVR Server"
                            >
                              <Select
                                placeholder="Select Type"
                                options={[
                                  {
                                    label: "CMS",
                                    value: "CMS",
                                  },
                                  {
                                    label: "VSS",
                                    value: "VSS",
                                  },
                                ]}
                              />
                            </Form.Item>

                            <Form.Item
                              name="cmsvssWebPort"
                              // label={this.context.translate("_MDVR_IMEI_")}
                              label="CMS/VSS Web Port"
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              name="cmsvssLoginServerPort"
                              label="CMS/VSS Login Server Port"
                              // label={this.context.translate("_MDVR_IMEI_")}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              name="cmsvssPlaybachPort"
                              // label={this.context.translate("_MDVR_IMEI_")}
                              label="CCMS/VSS Login Playback Port"
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              name="cmsvssUserName"
                              // label={this.context.translate("_MDVR_IMEI_")}
                              label="CMS/VSS Username"
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              name="cmsvssPassword"
                              // label={this.context.translate("_MDVR_IMEI_")}
                              label="CMS/VSS Password"
                            >
                              <Input />
                            </Form.Item>
                          </>
                        )}

                        <Form.Item
                          name="harshAcce"
                          // label="Hour Meter Reading"
                          label={this.context.translate("_HARSH_ACCELERATION_")}
                          initialValue={2}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="harshBrack"
                          // label="Hour Meter Reading"
                          label={this.context.translate("_HARSH_BRACKING_")}
                          initialValue={2}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="formColumnClass">
                        <Form.Item
                          name="exIdeling"
                          // label="Hour Meter Reading"
                          label={this.context.translate("_EX_IDELING_")}
                          initialValue={2}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="speeding"
                          // label="Hour Meter Reading"
                          label={this.context.translate("_SPEEDING_")}
                          initialValue={2}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="rpmExcess"
                          // label="Hour Meter Reading"
                          label={this.context.translate("_RPM_EXCESS_")}
                          initialValue={2}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="inputdigital"
                          label={this.context.translate("_INPUT_DIGITAL_")}
                        >
                          <Select
                            options={[
                              {
                                label: this.context.translate(
                                  "_FLEET_DASHBORD_SETTING_DOOR_"
                                ),
                                value: "Door",
                              },
                              {
                                label: this.context.translate(
                                  "_FLEET_DASHBORD_SETTING_SEATBELT_"
                                ),
                                value: "Seatbelt",
                              },
                              {
                                // label: "ACC",
                                label: this.context.translate(
                                  "_FLEET_DASHBORD_SETTING_ACC_"
                                ),
                                value: "ACC",
                              },
                              {
                                // label: "Panic",
                                label: this.context.translate(
                                  "_FLEET_DASHBORD_SETTING_PANIC_"
                                ),
                                value: "Panic",
                              },
                              {
                                // label: "AC ",
                                label: this.context.translate(
                                  "_FLEET_DASHBORD_SETTING_AC_"
                                ),
                                value: "AC",
                              },
                            ]}
                          />
                        </Form.Item>
                        {(Number(this.props.getUserData().isadmin) === -1 ||
                          Number(this.props.getUserData().isadmin) === 3) && (
                          <>
                            <Checkbox
                              style={{ marginTop: "14px" }}
                              // value={this.state.fuelSensor}
                              checked={this.state.fuelSensor}
                              onChange={(e) => {
                                this.setState({ fuelSensor: e.target.checked });
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                {this.context.translate("_FUEL_SENSOR_")}
                              </span>
                            </Checkbox>

                            <Form.Item
                              name="fuelId1"
                              // label="Fuel Id1"
                              label={this.context.translate("_FUEL_ID_1_")}
                              className={
                                !this.state.fuelSensor && "disabledLabel"
                              }
                            >
                              <Input
                                disabled={this.state.fuelSensor ? false : true}
                              />
                            </Form.Item>

                            <Form.Item
                              name="fuelId2"
                              // label="Fuel Id2"
                              label={this.context.translate("_FUEL_ID_2_")}
                              className={
                                !this.state.fuelSensor && "disabledLabel"
                              }
                            >
                              <Input
                                disabled={this.state.fuelSensor ? false : true}
                              />
                            </Form.Item>

                            <Checkbox
                              style={{ marginTop: "14px" }}
                              // value={4}
                              checked={this.state.temperatureSensor}
                              onChange={(e) => {
                                this.setState({
                                  temperatureSensor: e.target.checked,
                                });
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                {/* Temperature Sensor */}
                                {this.context.translate("_TEMPERATURE_SENSOR_")}
                              </span>
                            </Checkbox>

                            <>
                              <Form.Item
                                name="tempId1"
                                // label="Temp Id1"
                                label={this.context.translate("_TEMP_ID1_")}
                                className={
                                  !this.state.temperatureSensor &&
                                  "disabledLabel"
                                }
                              >
                                <Select
                                  disabled={
                                    this.state.temperatureSensor ? false : true
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                name="tempId2"
                                // label="Temp Id2"
                                label={this.context.translate("_TEMP_ID2_")}
                                className={
                                  !this.state.temperatureSensor &&
                                  "disabledLabel"
                                }
                              >
                                <Select
                                  disabled={
                                    this.state.temperatureSensor ? false : true
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                name="tempId3"
                                // label="Temp Id3"
                                label={this.context.translate("_TEMP_ID3_")}
                                className={
                                  !this.state.temperatureSensor &&
                                  "disabledLabel"
                                }
                              >
                                <Select
                                  disabled={
                                    this.state.temperatureSensor ? false : true
                                  }
                                />
                              </Form.Item>
                            </>
                          </>
                        )}
                      </Col>
                    </Row>

                    <div className="formBtnGroup">
                      <Button
                        className="formCancelButton"
                        onClick={this.handleBackClick}
                      >
                        {/* Back */}
                        {this.context.translate(
                          "_FLEET_USER_USERLIST_ASSIGNVEHICLE_BACKBTN_"
                        )}
                      </Button>
                      <Button
                        type="primary"
                        className="formSaveButton"
                        onClick={this.handleNextClick}
                      >
                        {/* Next */}
                        {this.context.translate("_NEXT_")}
                      </Button>
                    </div>
                  </TabPane>

                  <TabPane
                    tab={
                      <span
                        className={
                          this.state.tabErrors["4"] && "validation-failed"
                        }
                      >
                        <Button type="text">
                          {/* Tank Details */}
                          {this.context.translate("_TANK_DETAILS_")}
                        </Button>
                      </span>
                    }
                    key="4"
                    // style={{ marginLeft: "30px" }}
                  >
                    <Row style={{ overflowY: "scroll", height: "480px" }}>
                      <Col span={12} className="formColumnClass">
                        <Form.Item
                          name="nofueltank"
                          // label="Number of Fuel Tanks"
                          label={this.context.translate(
                            "_FLEET_VEHICLE_VEHICLEDETAIL_FUELTANK_"
                          )}
                          initialValue={3}
                        >
                          <Select
                            onChange={(value) => {
                              this.noOfFuelTanks(value);
                            }}
                            options={[
                              { label: "1", value: 1 },
                              { label: "2", value: 2 },
                              { label: "3", value: 3 },
                            ]}
                          />
                        </Form.Item>

                        <Form.Item
                          name="tankvolume"
                          //  label=" Tank Volume"
                          label={this.context.translate(
                            "_FLEET_VEHICLE_VEHICLEDETAIL_TANKVOLUME_"
                          )}
                        >
                          <Input
                            onChange={(e) =>
                              this.validateFieldsValue(
                                e.target.value,
                                "tankvolume"
                              )
                            }
                          />
                        </Form.Item>
                        {this.state.one && (
                          <>
                            <Form.Item
                              name="tankshape1"
                              label={
                                <label style={{ color: "red" }}>
                                  {/* Tank 1 Shape */}
                                  {this.context.translate(
                                    "_FLEET_VEHICLE_VEHICLEDETAIL_TANK1SHAPE_"
                                  )}
                                </label>
                              }
                            >
                              <Select
                                // placeholder="Select Tank Shape"
                                placeholder={this.context.translate(
                                  "_SELECT_TANK_SHAPE_"
                                )}
                                onChange={(value) => {
                                  this.tankShape1(value);
                                }}
                                options={[
                                  {
                                    // label: "Horizontal Rectangle",
                                    label: this.context.translate(
                                      "_FLEET_VEHICLE_VEHICLEDETAIL_TANKSHAPE_HORIZONTAL_"
                                    ),
                                    value: "Horizontal Rectangle",
                                  },
                                  {
                                    // label: "Vertical Cylinder",
                                    label: this.context.translate(
                                      "_FLEET_VEHICLE_VEHICLEDETAIL_TANKSHAPE_VERTICAL_"
                                    ),
                                    value: "Vertical Cylinder",
                                  },
                                  {
                                    // label: "Horizontal Cylinder",
                                    label: this.context.translate(
                                      "_FLEET_VEHICLE_VEHICLEDETAIL_TANKSHAPE_CYLINDRICAL_"
                                    ),
                                    value: "Horizontal Cylinder",
                                  },
                                ]}
                              />
                            </Form.Item>
                            {this.state.horizontalrectangle && (
                              <>
                                <Row>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tanklength1"
                                      label={
                                        <label style={{ color: "red" }}>
                                          {/* Tank (L) in Cm. */}
                                          {this.context.translate(
                                            "_TANK_L_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_LENGTH_"
                                        )}
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tanklength1"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={1}></Col>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tankwidth1"
                                      label={
                                        <label style={{ color: "red" }}>
                                          {/* Tank (W) in Cm. */}
                                          {this.context.translate(
                                            "_TANK_W_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_WIDTH_"
                                        )}
                                        // placeholder="Width"
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tankwidth1"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Form.Item
                                  name="tankheight1"
                                  label={
                                    <label style={{ color: "red" }}>
                                      {/* Tank (Height) in Cm. */}
                                      {this.context.translate(
                                        "_TANK_HEIGHT_IN_CM_"
                                      )}
                                    </label>
                                  }
                                  // label={" "}
                                >
                                  <Input
                                    placeholder={this.context.translate(
                                      "_HEIGHT__"
                                    )}
                                    // placeholder="Height"
                                    onChange={(e) =>
                                      this.validateFieldsValue(
                                        e.target.value,
                                        "tankheight1"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </>
                            )}

                            {this.state.verticalcylinder && (
                              <>
                                <Row>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tanklength1"
                                      label={
                                        <label style={{ color: "red" }}>
                                          {/* Tank1(L) in Cm. */}
                                          {this.context.translate(
                                            "_TANK1_l_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_LENGTH_"
                                        )}
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tanklength1"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={2}></Col>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tankdiameter1"
                                      label={
                                        <label style={{ color: "red" }}>
                                          {/* Tank1(D) in Cm. */}
                                          {this.context.translate(
                                            "_TANK1_D_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_DIAMETER_"
                                        )}
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tankdiameter1"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            )}

                            {this.state.horizontalcylinder && (
                              <>
                                <Row>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tanklength1"
                                      label={
                                        <label style={{ color: "red" }}>
                                          {/* Tank1(L) in Cm. */}
                                          {this.context.translate(
                                            "_TANK1_l_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_LENGTH_"
                                        )}
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tanklength1"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={2}></Col>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tankdiameter1"
                                      label={
                                        <label style={{ color: "red" }}>
                                          {/* Tank1(D) in Cm. */}
                                          {this.context.translate(
                                            "_TANK1_D_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_DIAMETER_"
                                        )}
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tankdiameter1"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            )}

                            <Form.Item
                              name="fid1"
                              // label="F Id 1"
                              // label={<label style={{ color: "red" }}>F Id 1</label>}
                              label={this.context.translate(
                                "_FLEET_VEHICLE_VEHICLEDETAIL_FID1_"
                              )}
                              // label={
                              //   <label style={{ color: "red" }}>F Id 1</label>
                              // }
                            >
                              <Input
                                placeholder="F Id 1"
                                onChange={(e) =>
                                  this.validateFieldsValue(
                                    e.target.value,
                                    "fid1"
                                  )
                                }
                              />
                            </Form.Item>

                            <Form.Item
                              name="installationspace1"
                              // label="Installation Space1(Cm)"
                              label={
                                <label style={{ color: "red" }}>
                                  {/* Installation Space1(Cm) */}
                                  {this.context.translate(
                                    "_FLEET_VEHICLE_VEHICLEDETAIL_INSTALLATIONSPACE1_"
                                  )}
                                </label>
                              }
                            >
                              <Input
                                // placeholder="Space 1(Cm)"
                                placeholder={this.context.translate(
                                  "_SPACE_1_CM_"
                                )}
                                onChange={(e) =>
                                  this.validateFieldsValue(
                                    e.target.value,
                                    "installationspace1"
                                  )
                                }
                              />
                            </Form.Item>
                          </>
                        )}
                      </Col>
                      <Col span={12} className="formColumnClass">
                        {this.state.two && (
                          <>
                            {/* #00e600 */}

                            <Form.Item
                              name="tankshape2"
                              label={
                                <label style={{ color: "green" }}>
                                  {/* Tank 2 Shape */}
                                  {this.context.translate(
                                    "_FLEET_VEHICLE_VEHICLEDETAIL_TANK2SHAPE_"
                                  )}
                                </label>
                              }
                            >
                              <Select
                                // placeholder="Select Tank2 Shape"
                                placeholder={this.context.translate(
                                  "_SELECT_TANK2_SHAPE_"
                                )}
                                onChange={(value) => {
                                  this.tankShape2(value);
                                }}
                                options={[
                                  {
                                    // label: "Horizontal Rectangle",
                                    label: this.context.translate(
                                      "_FLEET_VEHICLE_VEHICLEDETAIL_TANKSHAPE_HORIZONTAL_"
                                    ),
                                    value: "Horizontal Rectangle",
                                  },
                                  {
                                    // label: "Vertical Cylinder",
                                    label: this.context.translate(
                                      "_FLEET_VEHICLE_VEHICLEDETAIL_TANKSHAPE_VERTICAL_"
                                    ),
                                    value: "Vertical Cylinder",
                                  },
                                  {
                                    // label: "Horizontal Cylinder",
                                    label: this.context.translate(
                                      "_FLEET_VEHICLE_VEHICLEDETAIL_TANKSHAPE_CYLINDRICAL_"
                                    ),
                                    value: "Horizontal Cylinder",
                                  },
                                ]}
                              />
                            </Form.Item>

                            {this.state.horizontalrectangle2 && (
                              <>
                                <Row>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tanklength2"
                                      label={
                                        <label style={{ color: "green" }}>
                                          {/* Tank2(L) in Cm. */}
                                          {this.context.translate(
                                            "_TANK2_L_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_LENGTH_"
                                        )}
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tanklength2"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={1}></Col>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tankwidth2"
                                      label={
                                        <label style={{ color: "green" }}>
                                          {/* Tank2(W) in Cm. */}
                                          {this.context.translate(
                                            "_TANK2_W_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_WIDTH_"
                                        )}
                                        // placeholder="Width"
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tankwidth2"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Form.Item
                                  name="tankheight2"
                                  label={
                                    <label style={{ color: "green" }}>
                                      {/* Tank2(H) in Cm. */}
                                      {this.context.translate(
                                        "_TANK2_H_IN_CM_"
                                      )}
                                    </label>
                                  }
                                >
                                  <Input
                                    // placeholder="Height"
                                    placeholder={this.context.translate(
                                      "_HEIGHT__"
                                    )}
                                    onChange={(e) =>
                                      this.validateFieldsValue(
                                        e.target.value,
                                        "tankheight2"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </>
                            )}

                            {this.state.verticalcylinder2 && (
                              <>
                                <Row>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tanklength2"
                                      label={
                                        <label style={{ color: "green" }}>
                                          {/* Tank2(L) in Cm. */}
                                          {this.context.translate(
                                            "_TANK2_L_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_LENGTH_"
                                        )}
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tanklength2"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={2}></Col>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tankdiameter2"
                                      label={
                                        <label style={{ color: "green" }}>
                                          {/* Tank2(D) in Cm. */}
                                          {this.context.translate(
                                            "_TANK2_D_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_DIAMETER_"
                                        )}
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tankdiameter2"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            )}

                            {this.state.horizontalcylinder2 && (
                              <>
                                <Row>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tanklength2"
                                      label={
                                        <label style={{ color: "green" }}>
                                          {/* Tank2(L) in Cm. */}
                                          {this.context.translate(
                                            "_TANK2_L_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_LENGTH_"
                                        )}
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tanklength2"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={2}></Col>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tankdiameter2"
                                      label={
                                        <label style={{ color: "green" }}>
                                          {/* Tank 2 (D) in Cm. */}
                                          {this.context.translate(
                                            "_TANK2_D_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_DIAMETER_"
                                        )}
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tankdiameter2"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            )}

                            <Row>
                              <Col span={11}>
                                <Form.Item
                                  name="fid2"
                                  label={
                                    <label style={{ color: "green" }}>
                                      {/* F Id 2 */}
                                      {this.context.translate("_F_ID_2_")}
                                    </label>
                                  }
                                >
                                  <Input
                                    // placeholder="F Id 2"
                                    placeholder={this.context.translate(
                                      "_F_ID_2_"
                                    )}
                                    onChange={(e) =>
                                      this.validateFieldsValue(
                                        e.target.value,
                                        "fid2"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={2}></Col>
                              <Col span={11}>
                                <Form.Item
                                  name="installationspace2"
                                  label={
                                    <label style={{ color: "green" }}>
                                      {/* Space 2(Cm) */}
                                      {this.context.translate("_SPACE_2_CM_")}
                                    </label>
                                  }
                                >
                                  <Input
                                    // placeholder=" Space 2(Cm)"
                                    placeholder={this.context.translate(
                                      "_SPACE_2_CM_"
                                    )}
                                    onChange={(e) =>
                                      this.validateFieldsValue(
                                        e.target.value,
                                        "installationspace2"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            {/* <Form.Item
                          name="fid2"
                          label={
                            <label style={{ color: "green" }}>F Id 2</label>
                          }
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="installationspace2"
                          label={
                            <label style={{ color: "green" }}>
                              Installation Space 2(Cm)
                            </label>
                          }
                        >
                          <Input />
                        </Form.Item> */}
                          </>
                        )}

                        {this.state.three && (
                          <>
                            <Form.Item
                              name="tankshape3"
                              label={
                                <label style={{ color: "#FF33C7" }}>
                                  {/* Tank 3 Shape */}
                                  {this.context.translate(
                                    "_FLEET_VEHICLE_VEHICLEDETAIL_TANK3SHAPE_"
                                  )}
                                </label>
                              }
                            >
                              <Select
                                // placeholder="Select Tank 3 Shape"
                                placeholder={this.context.translate(
                                  "_SELECT_TANK3_SHAPE_"
                                )}
                                onChange={(value) => {
                                  this.tankShape3(value);
                                }}
                                options={[
                                  {
                                    // label: "Horizontal Rectangle",
                                    label: this.context.translate(
                                      "_FLEET_VEHICLE_VEHICLEDETAIL_TANKSHAPE_HORIZONTAL_"
                                    ),
                                    value: "Horizontal Rectangle",
                                  },
                                  {
                                    // label: "Vertical Cylinder",
                                    label: this.context.translate(
                                      "_FLEET_VEHICLE_VEHICLEDETAIL_TANKSHAPE_VERTICAL_"
                                    ),
                                    value: "Vertical Cylinder",
                                  },
                                  {
                                    // label: "Horizontal Cylinder",
                                    label: this.context.translate(
                                      "_FLEET_VEHICLE_VEHICLEDETAIL_TANKSHAPE_CYLINDRICAL_"
                                    ),
                                    value: "Horizontal Cylinder",
                                  },
                                ]}
                              />
                            </Form.Item>

                            {this.state.horizontalrectangle3 && (
                              <>
                                <Row>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tanklength3"
                                      label={
                                        <label style={{ color: "#FF33C7" }}>
                                          {/* Tank3(L) in Cm. */}
                                          {this.context.translate(
                                            "_TANK3_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_LENGTH_"
                                        )}
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tanklength3"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={1}></Col>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tankwidth3"
                                      label={
                                        <label style={{ color: "#FF33C7" }}>
                                          {/* Tank3(W) in Cm. */}
                                          {this.context.translate(
                                            "_TANK3_W_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_WIDTH_"
                                        )}
                                        // placeholder="Width"
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tankwidth3"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Form.Item
                                  name="tankheight3"
                                  label={
                                    <label style={{ color: "#FF33C7" }}>
                                      {/* Tank3(H) in Cm. */}
                                      {this.context.translate(
                                        "_TANK3_H_IN_CM_"
                                      )}
                                    </label>
                                  }
                                >
                                  <Input
                                    placeholder={this.context.translate(
                                      "_HEIGHT__"
                                    )}
                                    // placeholder="Height"
                                    onChange={(e) =>
                                      this.validateFieldsValue(
                                        e.target.value,
                                        "tankheight3"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </>
                            )}

                            {this.state.verticalcylinder3 && (
                              <>
                                <Row>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tanklength3"
                                      label={
                                        <label style={{ color: "#FF33C7" }}>
                                          {/* Tank3(L) in Cm. */}
                                          {this.context.translate(
                                            "_TANK3_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_LENGTH_"
                                        )}
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tanklength3"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={2}></Col>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tankdiameter3"
                                      label={
                                        <label style={{ color: "#FF33C7" }}>
                                          {/* Tank3(D) in Cm. */}
                                          {this.context.translate(
                                            "_TANK3_D_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_DIAMETER_"
                                        )}
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tankdiameter3"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            )}

                            {this.state.horizontalcylinder3 && (
                              <>
                                <Row>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tanklength3"
                                      label={
                                        <label style={{ color: "#FF33C7" }}>
                                          {/* Tank3(L) in Cm. */}
                                          {this.context.translate(
                                            "_TANK3_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_LENGTH_"
                                        )}
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tanklength3"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={2}></Col>
                                  <Col span={11}>
                                    <Form.Item
                                      name="tankdiameter3"
                                      label={
                                        <label style={{ color: "#FF33C7" }}>
                                          {/* Tank3(D) in Cm. */}
                                          {this.context.translate(
                                            "_TANK3_D_IN_CM_"
                                          )}
                                        </label>
                                      }
                                    >
                                      <Input
                                        placeholder={this.context.translate(
                                          "_DIAMETER_"
                                        )}
                                        // placeholder="Diameter"
                                        onChange={(e) =>
                                          this.validateFieldsValue(
                                            e.target.value,
                                            "tankdiameter3"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            )}

                            <Row>
                              <Col span={11}>
                                <Form.Item
                                  name="fid3"
                                  label={
                                    <label style={{ color: "#FF33C7" }}>
                                      {/* F Id 3 */}
                                      {this.context.translate("_F_ID_3_")}
                                    </label>
                                  }
                                >
                                  <Input
                                    // placeholder="F Id 3"
                                    placeholder={this.context.translate(
                                      "_F_ID_3_"
                                    )}
                                    onChange={(e) =>
                                      this.validateFieldsValue(
                                        e.target.value,
                                        "fid3"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={2}></Col>
                              <Col span={11}>
                                <Form.Item
                                  name="installationspace3"
                                  label={
                                    <label style={{ color: "#FF33C7" }}>
                                      {/* Space 3(Cm) */}
                                      {this.context.translate("_SPACE_3_CM_")}
                                    </label>
                                  }
                                >
                                  <Input
                                    // placeholder=" Space 3(Cm)"
                                    placeholder={this.context.translate(
                                      "_SPACE_3_CM_"
                                    )}
                                    onChange={(e) =>
                                      this.validateFieldsValue(
                                        e.target.value,
                                        "installationspace3"
                                      )
                                    }
                                  />
                                </Form.Item>

                                {/* <Form.Item
                          name="fid3"
                          label={
                            <label style={{ color: "#FF33C7" }}>F Id 3</label>
                          }
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="installationspace3"
                          label={
                            <label style={{ color: "#FF33C7" }}>
                              Installation Space 3(Cm)
                            </label>
                          }
                        >
                          <Input />
                        </Form.Item> */}
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                    {/* <div className="formbuttons" style={{ textAlign: "center" }}>
                    <Space>
                      <Button
                        className="backbtn"
                        onClick={this.handleBackClick}
                      >
                        Back
                      </Button>
                      <Button className="nextbtn" htmlType="submit">
                        Submit
                      </Button>
                    </Space>
                  </div> */}
                    <div className="formBtnGroup">
                      <Button
                        className="formCancelButton"
                        onClick={this.handleBackClick}
                      >
                        {/* Back */}
                        {this.context.translate(
                          "_FLEET_USER_USERLIST_ASSIGNVEHICLE_BACKBTN_"
                        )}
                      </Button>
                      <Button
                        type="primary"
                        className="formSaveButton"
                        htmlType="submit"
                      >
                        {/* Submit */}
                        {this.context.translate("_SUBMIT_")}
                      </Button>
                    </div>
                  </TabPane>
                </Tabs>
              </Form>
            </Spin>
          </div>

          <Modal
            bodyStyle={{ height: "100%" }}
            className="formShowModal" //
            // title="Driver Details"
            title={this.context.translate("_FLEET_DRIVER_DRIVERDETAIL_TITLE_")}
            centered
            open={this.state.openDriverDetailsForm}
            footer={null}
            onCancel={() => {
              this.setState({ openDriverDetailsForm: false });
            }}
            width={665}
          >
            <DriverAdd
              response={this.responseMsg}
              closeModal={this.closeDriverModal}
            ></DriverAdd>
          </Modal>
        </div>
      </>
    );
  }
}

VehicleAdd.contextType = StoreContext;
export default observer(withRouter(VehicleAdd));
