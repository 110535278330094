import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi,baseURL } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from 'mobx';


class MaintenanceFleetReportStore {
  maintenanceReportStore=[]
  maintenanceService=[]
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
      maintenanceReportStore: observable,
      loadData: action,
getServiceData:action
    });
    this.rootStore = rootStore;

  }



  async loadData({companyId,vehicleId,offset,limit,serviceId}) {
    console.log("vehicle list")
    var postData ={"companyId":companyId,"vehicleId":vehicleId,"serviceId":serviceId,"offset":offset,"limit":limit,"page":1}

    const base64Input = await base64_encode(JSON.stringify(postData));
       var response = await postApi.getClient().post("index.php?c=vehicle&a=maintenancereport", "postData="+base64Input);
   
   console.log(response?.data?.data?.maintenancereport)
     this.maintenanceReportStore =response?.data?.data?.maintenancereport

  }

  async getServiceData() {

    console.log("vehicle list")
    var postData = ""
   
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=maintenance&a=getService", "postData="+base64Input);
   
    console.log(response, "post")
    this.maintenanceService =response?.data?.data
    return response?.data?.data
    
  }


  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportmaintenanceexcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
     
      "&colList=" +
      getData.colList +
      "&sid=" +
      getData.sid +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportmaintenancepdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      // "&fD=" +
      // getData.fromDate +
      // "&tD=" +
      // getData.toDate +
      "&colList=" +
      getData.colList +
      "&sid=" +
      getData.sid +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportmaintenancecsv&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      // "&fD=" +
      // getData.fromDate +
      // "&tD=" +
      // getData.toDate +
      "&colList=" +
      getData.colList +
      "&sid=" +
      getData.sid +
      "&language=" +
      lang;
  }
  async exportxml(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportmaintenancexml&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      // "&fD=" +
      // getData.fromDate +
      // "&tD=" +
      // getData.toDate +
      "&colList=" +
      getData.colList +
      "&sid=" +
      getData.sid +
      "&language=" +
      lang;
  }

  
  

}

export default MaintenanceFleetReportStore;