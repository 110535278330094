import { observer } from "mobx-react";
import React, { Component } from "react";
import withRouter from "../withRouter";
import { Button, Col, Empty, Row, Space, Spin } from "antd";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { StoreContext } from "../store/RootStore";
import DistanceGraphStore from "../store/DistanceGraphStore";

import { Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import DashboardGraphStore from "../store/DashboardGraphStore";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  payload,
}) => {
  // Check if the value is non-zero
  if (payload.value !== 0) {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
         {/* <tspan fontWeight="bold">{payload.name}</tspan>{`: ${payload.value}`} */}
        {`${payload.name}: ${payload.value}`}
      </text>
    );
  } else {
    return null; // Return null if the value is zero
  }
};

class DashboardGraph extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.distanceGraphStore = new DistanceGraphStore(this);
    this.dashboardGraphStore = new DashboardGraphStore(this);

    this.state = {
      update: 0,
      companyId: "",

      loadData: 0,
      showDashboardGraph: false,
      loadingSpin: false,
      Notcompany: false,
    };
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });

    this.setState({ update: 1 });
  }

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.setState({ update: 1 });
    }
  }

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.companyId) {
      await this.setState({ loadingSpin: true });
      this.setState({ Notcompany: false });
      const data = await this.dashboardGraphStore.loadDashboardGraphData({
        companyId: this.state.companyId,
      });

      this.setState({ loadData: 1 });
      await this.setState({ loadingSpin: false });
    }
  };

  getColorByName = (name) => {
    // Add your conditions here
    if (name === "Stop") {
      return "red";
    } else if (name === "Idle") {
      return "orange";
    } else if (name === "Running") {
      return "green";
    } else if (name === "No Data") {
      return "gray";
    }
  };

  CustomTooltip = ({ active, payload }) => {
    var total = 0;
    this.dashboardGraphStore.dashboardGraph.map((val) => {
      console.log(val.value);
      total += val.value;
    });

    if (active && payload && payload.length) {
      const label = payload[0].name;

      console.log(payload[0]); // Assuming 'name' is the property in your data
      return (
        <div
          className="custom-tooltip"
          style={{ border: "1px solid", background: "white", padding: "10px" }}
        >
          <small className="label">{`${this.context.translate(
            "_TOTAL_"
          )} ${label} ${this.context.translate("_VEHICLES_")}: ${
            payload[0].value ? payload[0].value : ""
          }`}</small>
          <br />
          {
            <small className="label">
              {` ${label} : ${Math.round((payload[0].value / total) * 100)}%`}{" "}
            </small>
          }

          {/* <small className="intro">{this.getIntroOfGraph(label)}</small> */}
        </div>
      );
    }
  };

  render() {
    // const filteredData = this.dashboardGraphStore.dashboardGraph.filter(entry => entry.value !== 0);
    return (
      <>
        <Spin spinning={this.state.loadingSpin} fullscreen>
          <div style={{ padding: 0 }}>
            <Row className="headerFixed" style={{ height: "50px" }}>
              <Col span={12}>
                {/* <div className="heading">Dashboard Graph</div> */}
                <div className="heading">
                  {this.context.translate("_DASHBOARD_GRAPH_")}
                </div>
              </Col>
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>
          </div>
          <div
            style={{
              paddingTop: "10px",
              paddingRight: "24px",
              paddingLeft: "24px",
            }}
          >
            <Row className="listInputRow">
              <Col span={20}>
                <Space>
                  <CompanyAutocomplete
                    onChange={this.handleChange}
                    allowClear={true}
                    style={{ width: 175 }}
                    status={this.state.Notcompany ? "error" : false}
                  />

                  <Button
                    type="primary"
                    onClick={async () => {
                      await this.showData();
                      await this.setState({ showDashboardGraph: true });
                      console.log(this.dashboardGraphStore.dashboardGraph);
                    }}
                  >
                    {/* Show */}
                    {this.context.translate("_FLEET_SHOW_BTN_")}
                  </Button>
                </Space>
              </Col>
              <Col span={4}>
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>
          </div>

          <div
            style={{
              paddingTop: "10px",
              paddingRight: "24px",
              paddingLeft: "24px",
            }}
          >
            {this.dashboardGraphStore.dashboardGraph?.length > 0 && (
              <Row className="listInputRow">
                <Col span={18}>
                  {/* <div className="heading">Dashboard Graph</div> */}
                  <div className="heading">
                    {this.context.translate("_DASHBOARD_GRAPH_")}
                  </div>

                  <b>
                    {/* Total Number of Vehicle : {this.dashboardGraphStore.totalCount} */}
                    {this.context.translate("_TOTAL_NO_VEHICLE_")} :{" "}
                    {this.dashboardGraphStore.totalCount}
                  </b>
                  <br />
                </Col>
                <Col
                  span={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <Space
                    style={{
                      float:
                        this.context.locale.direction == "ltr"
                          ? "right"
                          : "left",
                    }}
                  ></Space>
                </Col>
              </Row>
            )}
          </div>
          {this.state.showDashboardGraph &&
          this.dashboardGraphStore.dashboardGraph?.length ? (
            <ResponsiveContainer height={500}>
              <PieChart width={600}>
                <Pie
                  data={this.dashboardGraphStore.dashboardGraph}
                  dataKey="value"
                  isAnimationActive={true}
                  labelLine={false}
                  label={renderCustomizedLabel}
                >
                  {this.dashboardGraphStore.dashboardGraph.map(
                    (entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={this.getColorByName(entry.name)}
                      />
                    )
                  )}
                </Pie>

                <Tooltip content={<this.CustomTooltip />} />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <Empty></Empty>
          )}
        </Spin>
      </>
    );
  }
}
DashboardGraph.contextType = StoreContext;
export default observer(withRouter(DashboardGraph));
