import React from "react";
import {
  Input,
  DatePicker,
  Button,
  Space,
  Row,
  Col,
  Dropdown,
  Checkbox,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import UserLoginReportStore from "../store/UserLoginReportStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import moment from "moment";
import config from "../config";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { decode as base64_decode } from "base-64";
import Cookies from "js-cookie";
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;
class UserLoginReportList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.userLoginReportStore = new UserLoginReportStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      companyId: "",
      date: "",
      time: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      modalVisible: false,
      column: "",
      filter: "",
      showMonthPopup: false,
      Notcompany: false,
      selectedColumns: [
        {
          // title: "User Name",
          title: context.translate("_FLEET_COMPANY_USERDETAIL_USERNAME_"),
          dataIndex: "username",
          key: "username",

          width: "15%",
        },
        {
          // title: "Password",
          title: context.translate("_FLEET_COMPANY_USERDETAIL_PASSWORD_"),
          dataIndex: "password",
          key: "password",

          width: "15%",
        },
        {
          // title: "Login DateTime",
          title: context.translate("_LOGIN_DATETIME_"),
          dataIndex: "loginDatetime",
          key: "loginDatetime",

          width: "15%",
          render: (value) => {
            if (value) {
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            } else {
              return <></>;
            }
          },
        },
        {
          // title: "Login IP ",
          title: context.translate("_LOGIN_IP_"),
          dataIndex: "loginIP",
          key: "loginIP",

          width: "15%",
        },
        {
          // title: "Login Location",
          title: context.translate("_LOGIN_LOCATION_"),
          dataIndex: "location",
          key: "location",

          width: "15%",
        },
        {
          // title: "Email Id",
          title: context.translate("_FLEET_USER_USERLIST_EMAIL_"),
          dataIndex: "emailId",
          key: "emailId",

          width: "15%",
        },
        {
          // title: "Contact No",
          title: context.translate("_CONTACT_NO_"),
          dataIndex: "mobileno",
          key: "mobileno",

          width: "15%",
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
          icon: <PlusOutlined />,
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
          icon: <PlusOutlined />,
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        const result = [];
        if (this.userLoginReportStore.rec) {
          this.newColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getDta = {
            companyId: this.state.companyId,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            colList: result.toString(),
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
          };
          if (val.key == "excel") {
            this.userLoginReportStore.exportExcel(getDta);
          }
          if (val.key == "pdf") {
            this.userLoginReportStore.exportpdf(getDta);
          }
          if (val.key == "csv") {
            this.userLoginReportStore.exportcsv(getDta);
          }
          if (val.key == "xml") {
            this.userLoginReportStore.exportxml(getDta);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }

  async componentDidMount() {
    this.setState({ pageSize: 50 });
    await this.setState({ column: this.state.selectedColumns });

    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }

    await this.setState({ listUpdated: 1 });

    // await this.setState({ listUpdated: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ currentPage: 1, companyId: comp?.value });
    // this.handleSearch();
  }

  async handleSearch() {
    await this.userLoginReportStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    });
    await this.setState({ listUpdated: 1 }); //need to reload data
  }
  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.companyId) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.userLoginReportStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
        });
        await this.setState({ listUpdated: 1 }); //need to reload data

        // console.log(this.state.fromDate,this.state.toDate,)
      }
    }
  };
  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };

  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }
  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss,";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0]?.format(format),
        toDate: dates[1]?.format(format),
      });
    }
  };
  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  updateVisibleColumns = () => {
    console.log(this.state.selectedColumns);

    // setColumns();
    this.setState({ column: this.state.selectedColumns });
  };

  render() {
    //console.log('in render1')
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">User Login Report</div> */}
              <div className="heading">
                {this.context.translate("_USER_LOGIN_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.userLoginReportStore?.userLoginReport?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Space>
              <Col span={16}>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={(value) => this.handleChange(value)}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
              </Col>

              <Col>
                {/* <RangePicker
                  style={{ width: 300 }}
                  onChange={(dates) => {
                    // console.log(val)
                    // this.onDateTimeChange(val)
                    const format = "YYYY-MM-DD HH:mm:ss";
                    this.setState({
                      fromDate: dates[0].format(format),
                      toDate: dates[1].format(format),
                    });
                  }}
                  // renderExtraFooter={() => "extra footer"}
                  showTime={{ format: "h:mm A" }}
                /> */}

                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
              </Col>

              {/* <TimePicker onChange={this.onTimeChange} defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')} /> */}

              <Col>
                <Button
                  type="primary"
                  onClick={async () => {
                    await this.setState({ currentPage: 1, pageSize: 50 });
                    await this.showData();
                  
                  }}
                >
                  {" "}
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Col>
            </Space>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.userLoginReportStore?.total}
            columns={this.state.column}
            dataSource={this.userLoginReportStore?.userLoginReport}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   //  current:this.state.pageNo
            //   total:this.userLoginReportStore?.total || 0

            // }}
            loading={this.userLoginReportStore?.loading}
            onChange={this.handleTableChange}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />

          <Modal
            open={this.state.showMonthPopup}
            title={
              <div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-exclamation-triangle"
                ></i>{" "}
                {this.context.translate("_FLEET_ALERT_")}
              </div>
            }
            onOk={() => this.setState({ showMonthPopup: false })}
            onCancel={() => this.setState({ showMonthPopup: false })}
            footer={false}
          >
            {/* For Reports prior to 6 months please contact administrator */}
            {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
          </Modal>
        </div>
      </>
    );
  }
}
UserLoginReportList.contextType = StoreContext;
export default observer(withRouter(UserLoginReportList));
