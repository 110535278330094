import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import config from "../config";
import { postApi } from "../components/Utility";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
  observe,
} from "mobx";
import { act } from "react-dom/test-utils";

class PassengerStore {
  passengers = [];
  rootTripListArray = [];
  assetVehicleList = [];
  getClass = [];
  // users=[]
  // drivers=[]
  // staff=[]
  // passengers=[]
  total = 0;
  loading = false;
  rootStore;
  photoFromDb = {};

  constructor(rootStore) {
    makeObservable(this, {
      passengers: observable,
      rootTripListArray: observable,
      assetVehicleList: observable,
      getClass: observable,
      total: observable,
      loading: observable,
      photoFromDb: observable,
      loadData: action,
      deleteRecord: action,
      getRouteTripList: action,
      addData: action,
      sampleExcelFormat: action,
      addStudentByExcel: action,
      reAssignVehicle: action,
      getAllClass: action,
      getBusNo: action,
    });
    this.rootStore = rootStore;
  }
  async getCompany({ companyName }) {
    var postData = { name: companyName };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=searchCompany", "postData=" + base64Input);
    console.log(response);
  }
  async loadData({ offset, limit, searchField, companyId }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      offset: offset,
      limit: limit,
      searchField: searchField,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=passenger&a=passdetails", "postData=" + base64Input);
    console.log("pass", response);
    //console.log("company Data",response?.data?.data?.passdetails)
    this.passengers = response?.data?.data?.passdetails;
    //  this.setCompanies(response?.data?.data?.companydata);
    this.total = parseInt(response?.data?.data?.TotalCount);
    // console.log(this.total);
    this.loading = false;
  }
  async setPhotoFromDb(photo) {
    console.log(photo);
    this.photoFromDb = { fileList: photo };
  }
  async getPassenger(passengerId, limit, offset) {
    console.log(passengerId, "id");
    const postData = { passengerId: passengerId, limit: 1, offset: 0 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=passenger&a=passdetails", "postData=" + base64Input);
    console.log(response);
    // return response?.data?.data?.passdetails[0]
    const singleRow = response?.data?.data?.passdetails[0];

    if (singleRow.photo) {
      const fileList = [
        {
          name: singleRow.photo,
          status: "success",
          url: config.fleetURL + "/images/students/" + singleRow.photo,
        },
      ];
      console.log(fileList);
      await this.setPhotoFromDb(fileList);
      // this.photoFromDb = {fileList :fileList}
      // console.log(this.photoFromDb)
    }
    return singleRow;
  }

  async deleteRecord({ passengerId }) {
    var postData = { passengerId: passengerId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=passenger&a=delete", "postData=" + base64Input);
    return response;
  }

  async addData(studentData) {
    var postData = {
      tokenId: "80bovn8u800tg1aq9bvr73kbhh",
      AddPassenger: studentData,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=passenger&a=update", "postData=" + base64Input);
    console.log(response);
    return response.data;
  }

  async getRouteTripList({
    companyId,
    pickuplatitude,
    pickuplongitude,
    classId,
    offset,
    limit,
    userId,
    isadmin,
  }) {
    var postData = {
      companyId: companyId,
      pickuplatitude: pickuplatitude,
      pickuplongitude: pickuplongitude,
      classId: classId,
      userId: userId,
      isadmin: isadmin,
      offset: offset,
      limit: limit,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=passenger&a=getRouteTripList",
        "postData=" + base64Input
      );
    this.rootTripListArray = response;
    console.log(response);
  }

  async sampleExcelFormat() {
    // window.location.href = config.baseURL + "index.php?c=vehicle&a=exportloginexcel&cid=" +getDta.companyId + '&fD=' + getDta.fromDate + '&tD=' + getDta.toDate + '&uId=' + getDta.userid + '&colList=' + getDta.colList
    window.location.href =
      "https://awtltrack.com/public/fleet/appRes/excel/Student.xlsx";
  }

  // async sampleExcelFormat() {
  //   window.location.href = config.fleetURL + "/appRes/excel/Student.xlsx";
  // }

  async addStudentByExcel(excelFile) {
    this.excelLoadig = true;
    var postData = excelFile;
    console.log(postData);
    console.log(excelFile);
    console.log(this.excelLoadig);
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=passenger&a=studentexcel2", "postData=" + base64Input);
    this.excelLoadig = false;
    return response.data;
  }

  async reAssignVehicle(renewData) {
    // console.log("vehicle list")
    var postData = renewData;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=passenger&a=reassignvehicle",
        "postData=" + base64Input
      );
    return response.data;
  }

  async getAllClass() {
    // console.log("vehicle list")
    var postData = null;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=notification&a=getAllClass",
        "postData=" + base64Input
      );
    // console.log(response?.data?.data, "post")
    this.getClass = response?.data?.data;
    return response?.data?.data;
  }

  async getBusNo(companyId) {
    // console.log("companyId", companyId)
    var postData = {
      companyId: companyId,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);
    console.log(response, "post");
    // let allVehicles = [{ "vehicleId": 0, "vehicleNo": "ALL" }];

    this.assetVehicleList = response?.data?.data;
    return response?.data?.data;
  }
}

export default PassengerStore;
