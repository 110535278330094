import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi,baseURL } from '../components/Utility'
import {
  action,
  makeObservable,
  observable,
  
} from 'mobx';
import { act } from 'react-dom/test-utils';

class GeofencesStore {
    geofencesReport=[]
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        geofencesReport: observable,
      total:observable,
      loading:observable,
      loadData:action,
     
    });
     this.rootStore = rootStore;
    
  }

  async loadData({offset,limit,companyId,geofence}) {
    this.loading = true;
    console.log("geofencesReport")

    var postData = {"tokenId":"","companyId":companyId,"geofence":geofence,limit:limit,offset:offset}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=geofence&a=search", "postData="+base64Input);
    console.log(response.data.data)
    this.geofencesReport = response?.data?.data?.geofencelist
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }


  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportgeofencelistexcel&cid=" +
      getData.companyId +
      // "&vid=" +
      // getData.vehicleId +
      // "&int="+
      // getData.interval+
      // "&fD=" +
      // getData.fromDate +
      // "&tD=" +
      // getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportgeofencelistpdf&cid=" +
      getData.companyId +
      // "&vid=" +
      // getData.vehicleId +
      // "&int="+
      // getData.interval+
      // "&fD=" +
      // getData.fromDate +
      // "&tD=" +
      // getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportgeofencelistcsv&cid=" +
      getData.companyId +
      // "&vid=" +
      // getData.vehicleId +
      // "&int="+
      // getData.interval+
      // "&fD=" +
      // getData.fromDate +
      // "&tD=" +
      // getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportxml(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportgeofencelistxml&cid=" +
      getData.companyId +
      // "&vid=" +
      // getData.vehicleId +
      // "&int="+
      // getData.interval+
      // "&fD=" +
      // getData.fromDate +
      // "&tD=" +
      // getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  
}

export default GeofencesStore;
