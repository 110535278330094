import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import dayjs from "dayjs";
import config from "../config";
import { action, makeObservable, observable } from "mobx";

class MaxSpeedGraphStore {
  maxSpeedGraph = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      maxSpeedGraph: observable,
      total: observable,
      loading: observable,
      loadMaxSpeedGraphData: action,
    });
    this.rootStore = rootStore;
  }

  async loadMaxSpeedGraphData({
    offset,
    limit,
    companyId,
    vehicleId,
    fromDate,
    toDate,
    Date,
  }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      Date: Date,
      offset: 0,
      limit: 15,
      page: 1,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=maxspeedgraph", "postData=" + base64Input);
    console.log("fuel detis ", response?.data?.data?.MaxSpeedGraph);
    var maxSpeedGraphData = response?.data?.data?.MaxSpeedGraph;
    if (maxSpeedGraphData && maxSpeedGraphData) {
      maxSpeedGraphData.forEach((element) => {
        if(element.startTime){
          const formattedStartTime = dayjs(element.startTime).format('YYYY-MM-DD');
          element.startTime=formattedStartTime;
        }
   
        element.maxSpeed = parseInt(element.maxSpeed);
      });
    }
    this.maxSpeedGraph = maxSpeedGraphData;

    // this.distanceGraph = response?.data?.data?.distancegraph;
    return response?.data?.data?.MaxSpeedGraph;
  }
}

export default MaxSpeedGraphStore;
