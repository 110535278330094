import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
  observe,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class DeviceLogStore {
  deviceLog=[]
 

  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        deviceLog: observable,
      total:observable,
      loading:observable,
      loadData:action,
     
    });
     this.rootStore = rootStore;
    
  }

  async loadData({offset,limit,searchField}) {
    console.log("device log called",searchField)
    this.loading = true;
    var postData = {"tokenId":"","searchField":searchField,"isadmin":"-1","offset":offset,"limit":limit}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=deviceLogDetail", "postData="+base64Input);
    console.log("device log Data",response?.data?.data.vehicleLog)
    this.deviceLog = response?.data?.data?.vehicleLog
    // //  this.setCompanies(response?.data?.data?.companydata);
     this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }
 
  

  
  

}

export default DeviceLogStore;
