import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import conf from "../config";
class WASLCompanyStore {
  WASLCompanyArray = [];
  WASLCompanyStatus =[]
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      WASLCompanyArray: observable,
      total: observable,
      loading: observable,
      loadData: action,
      getCompanyInfo: action,
      getWASLCompanyStatus:action,
      deleteRecord:action
    });
    this.rootStore = rootStore;
  }

  async loadData({ searchField, offset, limit,UcompanyId }) {
    this.loading = true;
    console.log(offset, limit)

    var postData = { "searchField": searchField,UcompanyId:UcompanyId, "offset": offset, "limit": limit };

    var base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=wasl&a=companyList", "postData=" + base64Input);

    console.log("company Data", response?.data?.data?.company);
    this.WASLCompanyArray = response?.data?.data?.company;
    this.total = parseInt( response?.data?.data?.TotalCount)
    this.loading = false;
  }

  async registerCompany(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=wasl&a=registerCompany", "postData=" + base64Input);
    console.log(response);

    // this.total = parseInt(response?.data?.data?.TotalCount);
    return response;
  }

  async getCompanyInfo(companyId) {
    console.log(companyId);
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=wasl&a=getCompanyInfo", "postData=" + base64Input);
    console.log(response);

    // this.total = parseInt(response?.data?.data?.TotalCount);
    return response.data.data;
  }


  async exportExcel(getData){
    
    // window.location.href = conf.baseURL + "index.php?c=company&a=exportCompanyExcel&dType=" +getData.dType+ '&isadmin='+getData.isadmin+ '&cid='+getData.cid+ '&cType='+getData.cType +'&cName='+getData.cName + '&email='+getData.email+ '&colList='+getData.colList
    window.location.href = conf.baseURL + "index.php?c=wasl&a=exportwaslcompanyexcel&cid="+getData.cid+ "&UcompanyId="+getData.UcompanyId+ "&colList="+getData.colList+"&searchField="+getData.searchField 
  
  }

  async deleteRecord({Id,companyId}){
    console.log(Id,companyId)

    var postData = {"waslId":Id,"companyId":companyId,"waslKey":null,"userId":1}
    console.log(postData);
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=wasl&a=deleteCompany", "postData="+base64Input);
    return response;
  }

  async getWASLCompanyStatus({Id}){
    console.log(Id)
    
    var postData = {"Id":Id,"waslKey":null}
    console.log(postData);
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=wasl&a=getwaslcompanystatus", "postData="+base64Input);
    this.WASLCompanyStatus = response.data.errors[0][0]
    // return response;
  }

}

export default WASLCompanyStore;
