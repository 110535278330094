import React from "react";
import {
  Select,
  Checkbox,
  Row,
  Col,
  Button,
  Space,
  DatePicker,
  Dropdown,
  Modal,
  Table,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import config from "../config";
import CompanyAutocomplete from "./CompanyAutocomplete";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import dayjs from "dayjs";

import { Link } from "react-router-dom";
import DeliveryManagementStore from "../store/DeliveryManagementReportStore";
const { RangePicker } = DatePicker;
const { Option } = Select;
let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 250;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 150;
    } else if (val.title?.length > 8) {
      val.width = 125;
    } else {
      val.width = 110;
    }
    // console.log(val.title.length)
  });

  return arr;
};
class DeliveryManagementReport extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.DeliveryManagementStore = new DeliveryManagementStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      selectedVehicles: [],
      openLogTbl: false,
      selectAllVehicles: false,
      companyId: "",
      modalVisible: false,
      column: "",
      filter: "",
      showMonthPopup: false,
      Notcompany: false,
      Notasset: false,
      NotJob: false,
      selectAllJobs: false,
      jobType: [],
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      expandedRowKeys: [],
      loadedInvoiceData: {},
      selectedColumns: widthAdderFunc([
        {
          title: context.translate("_CUSTOMER_NAME_"),
          dataIndex: "customerName",
          key: "customerName",
        },
        {
          title: context.translate("_INVOICE_NO_"),
          dataIndex: "invoiceNo",
          key: "invoiceNo",
        },
        {
          title: context.translate("_DRIVERID_"),
          dataIndex: "name",
          key: "name",
        },
        {
          title: context.translate("_DRIVER_MOBILE_"),
          dataIndex: "mobileno",
          key: "mobileno",
        },
        {
          title: context.translate("_JOB_"),
          dataIndex: "pickupDelivery",
          key: "pickupDelivery",
        },
        {
          title: context.translate("_DELIVERY_TIME_"),
          dataIndex: "deliveryTime",
          key: "deliveryTime",
        },
        {
          title: context.translate("_DELIVERED_AT_"),
          dataIndex: "deliveredAt",
          key: "deliveredAt",
        },

        {
          title: context.translate("_DELIVERY_STATUS_"),
          dataIndex: "deliveryStatus",
          key: "deliveryStatus",
        },
        {
          title: context.translate("_FEEDBACK_"),
          dataIndex: "feedback",
          key: "feedback",
        },
        {
          title: context.translate("_ORDER_STATUS1_"),
          dataIndex: "status",
          key: "status",
        },

        {
          title: context.translate("_ATTACHED_IMAGE_"),
          dataIndex: "bCardPath",
          key: "bCardPath",
        },

        {
          title: context.translate("_UNDELIVERED_REASON_"),
          dataIndex: "idleduration",
          key: "idleduration",
        },
        {
          title: context.translate("_NO_OF_ATTEMPTS_"),
          dataIndex: "attempt_number",
          key: "attempt_number",
        },
        {
          title: context.translate("_LOG_"),
          dataIndex: "deliverylog",
          width: "10%",
          render: (val, record) => {
            return (
              <Link
                onClick={async () => {
                  await this.setState({
                    openLogTbl: true,
                    jobNo: record.jobNo,
                  });
                  // await this.showDeliveryLogCol();
                  await this.showDeliveryLogData();
                }}
              >
                Delivery Log
              </Link>
            );
          },
        },
      ]),
    };

    this.deliverylogcols = [
      {
        title: context.translate("_CREATED_AT_"),
        dataIndex: "registeredAt",
        width: "10%",
      },
      {
        // title: "Driver Name",
        title: context.translate("_CREATED_BY_"),
        dataIndex: "registeredBy",
        width: "10%",
      },
      {
        // title: "Vehicle Number",
        title: context.translate("_UPDATED_AT_"),
        dataIndex: "updatedAt",
        width: "10%",
      },
      {
        // title: "Customer Name ",
        title: context.translate("_UPDATED_BY_"),
        dataIndex: "updatedBy",
        width: "10%",
      },
      {
        // title: "Job Type",
        title: context.translate("_UPDATED_FIELD_"),
        dataIndex: "updatedField",
        width: "10%",
      },
      {
        title: context.translate("_CANCELLED_AT_"),
        dataIndex: "cancelledAt",
        width: "10%",
      },
      {
        title: context.translate("_CANCELLED_BY_"),
        dataIndex: "cancelledBy",
        width: "10%",
      },
    ];
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
          icon: <PlusOutlined />,
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
          icon: <PlusOutlined />,
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            jobType: this.state.jobType,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            colList: result.toString(),
          };
          console.log(getData);
          if (val.key == "excel") {
            // console.log("afd")
            this.DeliveryManagementStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.DeliveryManagementStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.DeliveryManagementStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.DeliveryManagementStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    // await this.fuelFilledReportStore.loadData({ offset: 0, limit: 20 });

    this.setState({ pageSize: 50 });

    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }

    if (Number(this.props.getUserData().isadmin) == 1) {
      await this.DeliveryManagementStore.loadUsersData(
        this.props.getUserData().userCompanyId
      );
    }

    await this.setState({ column: this.state.selectedColumns });
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    console.log(this.state.selectedVehicles);

    await this.setState({ selectedVehicles: [], selectAllVehicles: false });

    await this.setState({ companyId: comp?.value });
    await this.DeliveryManagementStore.getVehiclesCompanyId(comp?.value);

    await this.handleSearch();
    //  console.log(data,"sdcd")
  }

  async handleSearch() {
    this.setState({ Notasset: false });

    await this.setState({ loadData: 1 });
  }

  showDeliveryLogData = async () => {
    await this.DeliveryManagementStore.loadDeliveryLogData({
      jobNo: this.state.jobNo,
    });
    await this.setState({ loadData: 1 });
  };

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }

    if (this.state.jobType.length === 0) {
      this.setState({ NotJob: true });
    } else {
      this.setState({ NotJob: false });
    }

    if (
      this.state.companyId &&
      this.state.selectedVehicles.length > 0 &&
      this.state.jobType.length > 0
    ) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false, NotJob: false });
      if (this.checkFromDate(this.state.fromDate)) {
        await this.DeliveryManagementStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,
          jobType: this.state.jobType,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          pageNo: this.state.currentPage,
        });
        await this.setState({ listData: 1 });
      }
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };

  checkFromDate(dates) {
    console.log(dates);
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles =
        this.DeliveryManagementStore?.getVehiclesUsingCompanyId.map((val) => {
          return val.vehicleId;
        });
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  handleSelectAllJobs = async () => {
    if (this.state.selectAllJobs) {
      this.setState({
        jobType: ['Pickup', 'Delivery', 'Service', 'Meeting'],
      });
    } else {
      this.setState({
        jobType: [],
      });
    }
  };


  onDateTimeChange = (dates) => {
    console.log(dates);
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  handleExpandRow = async (id) => {
    if (!this.state.loadedInvoiceData[id]) {
      await this.DeliveryManagementStore.loadInvoiceData(id);
      this.setState((prevState) => ({
        loadedInvoiceData: { ...prevState.loadedInvoiceData, [id]: true },
      }));
    }
  };
  expandedRowRender = (id) => {
    // this.handleExpandRow(id);
    // console.log(x);
    const columns = [
      {
        title: this.context.translate("Sr No."),
        dataIndex: "sno",
        key: "sno",
        width: "10%",
      },
      {
        title: this.context.translate("_INVOICE_NO_"),
        dataIndex: "invoiceNo",
        key: "invoiceNo",
        width: "10%",
      },
      {
        title: this.context.translate("_INVOICE_AMOUNT_"),
        dataIndex: "invoiceAmount",
        key: "invoiceAmount",
        width: "10%",
      },
      {
        title: this.context.translate("_INVOICE_DATE_"),
        dataIndex: "invoiceDate",
        key: "invoiceDate",
        width: "10%",
      },
      {
        title: this.context.translate("_CUSTOMER_NAME_"),
        dataIndex: "travelTime",
        key: "travelTime",
        width: "10%",
      },
      {
        title: this.context.translate("_DEPOSITOR_"),
        dataIndex: "depositor",
        key: "depositor",
        width: "10%",
      },
      {
        title: this.context.translate("_UPDATE_DATE_"),
        dataIndex: "updateDate",
        key: "updateDate",
        width: "10%",
      },
      {
        title: this.context.translate("_STATUS_"),
        dataIndex: "status",
        key: "status",
        width: "10%",
      },
    ];

    return (
      <Table
        bordered
        columns={columns}
        scroll={{ x: 50 }}
        dataSource={this.DeliveryManagementStore.invoiceData}
        pagination={false}
      />
    );
  };

  onExpand = async (expanded, record) => {
    const expandedRowKeys = expanded ? [record.Id] : [];
    this.setState({ expandedRowKeys });

    if (expanded) {
      await this.handleExpandRow(record.Id);
    }
  };
  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_DELIVERY_MANAGEMENT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.DeliveryManagementStore?.deliveryTableData?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={this.handleChange}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
                {this.props.getUserData().isadmin === 1 && (
                  <>
                    <Select
                      placeholder="Users"
                      fieldNames={{ label: "username", value: "userId" }}
                      options={this.DeliveryManagementStore.usersData}
                      style={{ width: 150 }}
                      onChange={async (val) => {
                        await this.DeliveryManagementStore.loadUsersVehiclesData(
                          {
                            companyId: this.props.getUserData().userCompanyId,
                            searchbyUserId: val,
                            userId: this.props.getUserData().userId,
                          }
                        );
                        this.setState({ loadData: 1 });
                      }}
                    />
                  </>
                )}
                <Checkbox
                  checked={this.state.selectAllVehicles}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllVehicles: e.target.checked,
                    });
                    // console.log(e.target.checked);
                    await this.handleAllSelectVehicles();
                  }}
                />

                {this.context.translate("_ALL_")}

                <Select
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    if (
                      this.DeliveryManagementStore?.getVehiclesUsingCompanyId
                        .length > val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }
                  }}
                  style={{ width: 200 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  mode="multiple"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.DeliveryManagementStore?.getVehiclesUsingCompanyId?.map(
                    (val) => {
                      return (
                        <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                      );
                    }
                  )}
                </Select>

                <RangePicker
                  placeholder={[
                    this.context.translate("_FROM_DATE_TIME_"),
                    this.context.translate("_TO_DATETIME_"),
                  ]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />

                <Checkbox
                  checked={this.state.selectAllJobs}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllJobs: e.target.checked,
                    });

                    await this.handleSelectAllJobs();
                  }}
                />
                {this.context.translate("_ALL_")}

                <Select
                  mode="multiple"
                  maxTagCount={"responsive"}
                  placeholder="Job"
                  style={{ width: 130 }}
                  value={this.state.jobType}
                  status={this.state.NotJob ? "error" : false}
                  onChange={async (val) => {
                    console.log(val)
                    await this.setState({ jobType: val });
                    if (val?.length === 4) {
                      console.log(val?.length)
                      this.setState({ selectAllJobs: true });
                    } else {
                      this.setState({ selectAllJobs: false });
                    }
                  }}
                  options={[
                    {
                      label: "Pickup",
                      value: "Pickup",
                    },
                    {
                      label: "Delivery",
                      value: "Delivery",
                    },
                    {
                      label: "Service",
                      value: "Service",
                    },
                    {
                      label: "Meeting",
                      value: "Meeting",
                    },
                  ]}
                />

                <Button
                  type="primary"
                  onClick={async () => {
                    await this.setState({ currentPage: 1, pageSize: 50 });
                    await this.showData();
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            rowKey="Id"
            // expandable={{
            //   expandedRowRender: (record) => this.expandedRowRender(record.Id), // Pass record as a parameter
            // }}
            expandable={{
              expandedRowRender: this.expandedRowRender,
              onExpand: this.onExpand,
              expandedRowKeys: this.state.expandedRowKeys,
            }}
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.DeliveryManagementStore?.total}
            columns={this.state.column}
            dataSource={this.DeliveryManagementStore?.deliveryTableData}
            loading={this.DeliveryManagementStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        <Modal
          open={this.state.openLogTbl}
          footer={null}
          centered
          width="60%"
          style={{ height: "250px" }}
          onCancel={() => this.setState({ openLogTbl: false })}
        >
          <div className="heading">
            {this.context.translate("_DELIVERY_LOG_")}
          </div>
          <div style={{ height: "250px" }}>
            {" "}
            <Table
              columns={this.deliverylogcols}
              bordered
              dataSource={this.DeliveryManagementStore?.deliverylogTblData}
              pagination={false}
              scroll={{ y: 250 }}
            />
          </div>
        </Modal>
        <Modal
          open={this.state.modalVisible}
          width={300}
          // title="Select Columns"
          title={this.context.translate("_SELECT_COLUMNS_")}
          onCancel={() => this.setState({ modalVisible: false })}
          onOk={() => {
            this.setState({ modalVisible: false });
          }}
          footer={null}
        >
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            {this.newColumns.map((column, v) => {
              return (
                <Row key={v}>
                  <Col>
                    <Checkbox
                      onChange={() => this.handleCheckboxChange(column)}
                      checked={this.state.selectedColumns.some(
                        (c) => c.key === column.key
                      )}
                    >
                      {column.title}
                    </Checkbox>
                  </Col>
                </Row>
              );
            })}
          </div>
        </Modal>

        <Modal
          width={350}
          className="alert-model"
          title={
            <div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              {/* Alert */}
              {this.context.translate("_FLEET_ALERT_")}
            </div>
          }
          open={this.state.showMonthPopup}
          onOk={() => this.setState({ showMonthPopup: false })}
          onCancel={() => this.setState({ showMonthPopup: false })}
          footer={false}
        >
          {/* For reports prior to 6 months please contact administrator */}
          {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
        </Modal>
      </>
    );
  }
}
DeliveryManagementReport.contextType = StoreContext;
export default observer(withRouter(DeliveryManagementReport));
