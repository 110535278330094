import React from "react";
import {
  message,
  Space,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Tooltip,
  Checkbox,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Await, Link } from "react-router-dom";
import { EditFilled, DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { QuestionCircleOutlined } from "@ant-design/icons";
import WareHouseStore from "../store/WareHouseStore";
import withRouter from "../withRouter";
import config from "../config";
import DeleteConfirm from "./DeleteConfirm";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import AddButton from "./AddButton";
import CompanyStore from "../store/CompanyStore";
import WarehouseAdd from "../components/WarehouseAdd";

let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 250;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 150;
    } else if (val.title?.length > 8) {
      val.width = 125;
    } else {
      val.width = 110;
    }
    // console.log(val.title.length)
  });

  return arr;
};
class WareHouseList extends React.Component {
  constructor(props, context) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.wareHouseStore = new WareHouseStore();
    this.companyStore = new CompanyStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      companyId: "",
      loadData: 0,
      companyVal_Lab: "",
      waslModal: false,
      warehouseName: "",
      warehouseId: "",
      openWarehouseForm: false,
      vehicleId: "",
      listDataUpdate: false,
      modalVisible: false,

      selectedColumns: [
        {
          // title:
          title: context.translate("_FLEET_USER_USERLIST_COMPANYDDL_"),
          dataIndex: "companyName",
          width: "10%",
          key: "companyName",
          render: (value, record) => {
            return (
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  this.onEdit(record);
                }}
              >
                {value}
              </Link>
            );
          },
        },
        {
          // title: "Warehouse",
          title: context.translate("_FLEET_DASHBORD_NEWPOI_TYPEDDL_WAREHOUSE_"),
          dataIndex: "warehouse",
          width: "10%",
          key: "warehouse",
        },
        {
          // title: "City",
          title: context.translate("_FLEET_COMPANY_COMPANYDETAIL_CITY_"),
          dataIndex: "city",
          key: "city",
          width: "10%",
        },
        {
          // title: "License Number",
          title: context.translate("_FLEET_DRIVER_DRIVERLIST_LICENCE_"),
          dataIndex: "licenseNo",
          key: "licenseNo",
          width: "10%",
        },
        {
          // title: "Issue Date",
          title: context.translate("_LICENSE_GRID_ISSUE_DATE_"),
          dataIndex: "licenseIssueDate",
          key: "licenseIssueDate",
          width: "10%",
        },
        {
          // title: "Expiry Date",
          title: context.translate("_EXPIRY_DATE_"),
          dataIndex: "licenseExpiryDate",
          key: "licenseExpiryDate",
          width: "10%",
        },

        {
          // title: "Phone",
          title: context.translate("_PHONE_"),
          dataIndex: "phone",
          width: "10%",
          key: "phone",
        },
        {
          // title: "Manager Mobile",
          title: context.translate("_MANAGER_MOBILE_"),
          dataIndex: "managerMobile",
          key: "managerMobile",
          width: "10%",
        },
        {
          // title: "Manager Email",
          title: context.translate("_EMAIL_GRID_"),
          dataIndex: "email",
          key: "email",
          width: "10%",
        },
        {
          // title: "Land Area",
          title: context.translate("_WAREHOUSE_GRID_LAND_AREA_"),
          dataIndex: "landArea",
          key: "landArea",
          width: "10%",
        },
        {
          // title: "WASL Reply",
          title: context.translate("_WAREHOUSE_WASL_REPLY_"),
          dataIndex: "waslReply",
          key: "waslReply",
          width: "10%",
        },
        {
          // title: "WASL Response",
          title: context.translate("_WAREHOUSE_WASL_RESPONSE_"),
          dataIndex: "waslResponse",
          key: "waslResponse",
          width: "10%",
        },
        {
          // title: "Action",
          title: context.translate("_ACTION_"),
          dataIndex: "Action",
          key: "Action",
          // fixed: "right",
          width: "10%",
          render: (_, record) => {
            return (
              <>
                <Space>
                  {/* <Link
                    onClick={(e) => {
                      e.preventDefault();
                      this.onEdit(record);
                    }}
                  >
                    <Tooltip
                      // title="Edit Record"
                      title={this.context.translate("_EDIT_RECORD_")}
                      placement="bottom"
                      color={config.tooltipColorEdit}
                    >
                      <EditFilled />
                    </Tooltip>
                  </Link> */}
                  <DeleteConfirm
                    onConfirm={(e) => {
                      e.preventDefault();
                      this.onDelete(record);
                    }}
                  >
                    <Link>
                      <Tooltip
                        // title="Delete Record"
                        title={this.context.translate("_DELETE_RECORD_")}
                        placement="bottom"
                        color={config.tooltipColorDelete}
                      >
                        <DeleteFilled style={{ color: config.deleteColor }} />
                      </Tooltip>
                    </Link>
                  </DeleteConfirm>

                  <Link
                    onClick={async (e) => {
                      this.setState({ waslModal: true });
                      //  await this.getwarehouseId(record);
                      this.setState({ warehouseName: record.warehouse });
                      console.log(record.warehouse);
                      this.setState({ warehouseId: record.id });
                      console.log(record.id);
                    }}
                  >
                    <Tooltip
                      // title="Register in WASL"
                      title={this.context.translate("_REGISTER_IN_WASL_")}
                      color="green"
                    >
                      <PlusOutlined />
                    </Tooltip>
                  </Link>
                </Space>
              </>
            );
          },
        },
      ],
    };
    this.newColumns = widthAdderFunc([
      ...this.state.selectedColumns,
      {
        // title: "Address Grid Of Warehouse",
        title: context.translate("_ADDRESS_GRID_OF_WAREHOUSE_"),
        dataIndex: "address",
        key: "address",
      },
      {
        // title: "Latitude",
        title: context.translate("_FLEET_DASHBORD_SETTING_LATITUDE_"),
        dataIndex: "latitude",
        key: "latitude",
      },
      {
        // title: "Longitude",
        title: context.translate("_FLEET_DASHBORD_SETTING_LONGITUDE_"),
        dataIndex: "longitude",
        key: "sensorId",
      },
      {
        // title: "Land Coordinates",
        title: context.translate("_LAND_CORDINATES_"),
        dataIndex: "landCoordinates",
        key: "landCoordinates",
      },
    ]);
  }
  //   onEdit = (record) => {
  //     console.log(record);
  //     this.props.navigate("/warehouseadd/" + record.id);
  //   };

  onEdit = async (record) => {
    console.log(record);
    const vehicleId = record.id;
    if (vehicleId) {
      this.context.viewStateStore.setWarehouseStates({
        companyId: record.companyId,
      });
      await this.setState({ vehicleId: vehicleId });
      await this.setState({ openWarehouseForm: true });

      // await this.props.navigate("/warehouseadd/" + vehicleId);
    }
  };

  async onDelete(record) {
    console.log(record.id, "record", record.companyId);

    
    const response = await this.wareHouseStore.deleteRecord({
      companyId: record.companyId,
      warehouseId: record.id,
    });
    this.setState({companyId:record.companyId})
    //  console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.setState({ loading: true });
      await this.wareHouseStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: record.companyId,
      });
      await this.setState({ loadData: 1 });
      // this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  async componentDidMount() {
    if(Number(this.props.getUserData().isadmin)!==-1 ){
       console.log(this.props.getUserData().userCompanyId)
       await this.setState({companyId:this.props.getUserData().userCompanyId})
      //  console.log(this.state.companyId)
       await this.setState({userId:this.props.getUserData().userId})
       await this.handleSearch();
  }
    console.log(this.context.viewStateStore.value, "vale");
    await this.setState({ column: this.state.selectedColumns });
    this.setState({ pageSize: 50 });
  }

  regiterWarehouse = async (warehouseId) => {
    console.log(warehouseId);
    const data = {
      warehouseId: warehouseId,
    };
    console.log(data);
    const response = await this.wareHouseStore.addWarehouse(data);
    if (response?.success === "S") {
      message.success(response.message);
      this.props.navigate("/warehouselist");
    } else {
      message.error("No Response From WASL");
    }
  };
  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination, "pagi");
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async handleChange(comp) {
    console.log(comp.value)
    await this.setState({ companyId: comp.value });
    // await this.setState({
    //   companyId: comp.value,
    //   pageSize: 50,
    //   currentPage: 1,
    // });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ companyVal_Lab: singleData });
    // await this.setState({ companyId: comp.value });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.wareHouseStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });
    await this.setState({ loadData: 1 });
  }

  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.success == "S") {
      message.success("Warehouse Details Saved");
      await this.setState({ listDataUpdate: true });
      await this.setState({ openWarehouseForm: false });
      // await this.handleSearch();

      //  await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    }
    // else if (resp.data.success == "F0") {
    //   resp.data.errors.map((value) => {
    //     message.error(value["account"]);
    //   });
    // }
    else { await this.setState({ listDataUpdate: true });
      message.error(resp.message);
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.state.openWarehouseForm &&
      prevState.openWarehouseForm !== this.state.openWarehouseForm
    ) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 10);
    }

    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const setWarehouseStates = this.context.viewStateStore.warehouseObject;
      console.log(setWarehouseStates);
      if (setWarehouseStates.companyId !== "") {
        await this.wareHouseStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          //   searchField: setVehicleStates.searchText,
          companyId: setWarehouseStates.companyId,
        });

        const singleData = await this.companyStore.getCompanyDataById(
          setWarehouseStates.companyId
        );

        await this.setState({ loadList: 1 });
        console.log("singleData", singleData);
        await this.setState({
          companyVal_Lab: singleData,
          companyId: singleData[0].value,
        });
        console.log("singleData", singleData);
      }
      await this.setState({ listDataUpdate: false });
    }
  }

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
closeModal=()=>{
  this.setState({ openWarehouseForm: false, companyId: "" });

}

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_WAREHOUSE_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  title={this.context.translate("_ADD_")}
                  link="/warehouseadd"
                /> */}
                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  placement="left"
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({ openWarehouseForm: true, vehicleId: "" })
                    }
                  ></Button>
                </Tooltip>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  // allowClear={true}
                  onChange={(value) => this.handleChange(value)}
                  style={{ width: 200 }}
                  value={
                    this.state.companyVal_Lab ? this.state.companyVal_Lab : null
                  }
                />
              </Space>
            </Col>
            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>
          {console.log(this.wareHouseStore.total)}
          <ListTable
            currentPage={this.state.currentPage}
            total={this.wareHouseStore?.total}
            columns={this.state.selectedColumns}
            pageSize={this.state.pageSize}
            dataSource={this.wareHouseStore?.wareHouse}
            loading={this.wareHouseStore?.loading}
            onChange={this.handleTableChange}
          />

          <Modal
            // title="Confirm"

            title={
              <span>
                <QuestionCircleOutlined style={{ color: "red" }} />
                &nbsp;
                {/* Confirm */}
                {this.context.translate("_CONFIRM_")}
              </span>
            }
            width={400}
            open={this.state.waslModal}
            onCancel={() => this.setState({ waslModal: false })}
            // style={{}}

            footer={[
              <div style={{ padding: 10 }}>
                <Button
                  // key="yes"
                  onClick={(e) => {
                    this.regiterWarehouse(this.state.warehouseId);
                    console.log(this.state.warehouseId);
                    this.setState({ waslModal: false });
                  }}
                  type="primary"
                >
                  {/* Yes */}
                  {this.context.translate("_FLEET_RULEDETAIL_ACTIVEDDL_YES_")}
                </Button>
                <Button
                  // key="no"
                  onClick={() => this.setState({ waslModal: false })}
                >
                  {/* No */}
                  {this.context.translate("_FLEET_RULELIST_CRONRULE_NO_")}
                </Button>
              </div>,
            ]}
          >
            <p style={{ margin: "0px 20px" }}>
              {" "}
              {/* Do you want to Register {this.state.warehouseName} in WASL ? */}
              {this.context.translate("_DOYOU_WANT_TO_REGISTER_")}{" "}
              {this.state.warehouseName} {this.context.translate("_IN_WASL_")}
            </p>
          </Modal>

          {this.state.openWarehouseForm && (
            <Modal
              maskClosable={false}
              bodyStyle={{ height: "645px" }}
              className="formShowModal" //  {/* css class */}
              // title="Warehouse"
              title={this.context.translate(
                "_FLEET_DASHBORD_NEWPOI_TYPEDDL_WAREHOUSE_"
              )}
              centered
              visible={this.state.openWarehouseForm}
              footer={null}
              onCancel={() => {
                this.setState({ openWarehouseForm: false, companyId: "" });
              }}
              width={665}
            >
              <div>
                <WarehouseAdd
                  vehicleId={this.state.vehicleId}
                  response={this.responseMsg}
                  closeModal={this.closeModal}
                />
              </div>
            </Modal>
          )}

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>
        </div>
      </>
    );
  }
}
WareHouseList.contextType = StoreContext;
export default observer(withRouter(WareHouseList));
