import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class AddLocationStore {
  loading = false;
  total = 0;
 
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      total: observable,
     
      addData: action,
    });
    this.rootStore = rootStore;
  }

 
 

  async addData(addLocation) {
    var postData = addLocation;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=liveLocation&a=getLocationData", "postData=" + base64Input);

    return response.data;
  }

 

}

export default AddLocationStore;
