import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from "mobx";

class HistoryTrackingStore {
  polyLineArray = [];
  count = 0;
  lengthOfPolyLineArray = 0;
  newPolyLineArray = [];
  allArray = [];
  offlineDataNewObj = [];
  offlineData = [];
  historyTrackingTableData = [];
  totalCount = null;
  total = 0;
  postData = {};
  loading = false;
  vehiclesArray = null;
  pgcount = 1;
  offset = 0;
  checked = true;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      vehiclesArray: observable,
      offlineDataNewObj: observable,
      newPolyLineArray: observable,
      historyTrackingTableData: observable,
      offlineData: observable,
      checked: observable,
      totalCount: observable,
      postData: observable,
      pgcount: observable,
      offset: observable,
      lengthOfPolyLineArray: observable,
      allArray: observable,
      count: observable,
      polyLineArray: observable,
      getVehicles: action,
      handleOfflineData: action,
      setOfflineData: action,
    });

    this.rootStore = rootStore;
  }

  async getHistoryTrackingData(obj) {
    var postData = obj;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicletracking&a=getHistoryTrackingData",
        "postData=" + base64Input
      );
    let arr = await response.data?.data?.vehicleposition;
    this.allArray = await response.data?.data?.vehicleposition;
    this.historyTrackingTableData = await response.data?.data?.vehicleposition;
    this.polyLineArray = await arr.map((val) => [val.latitude, val.longitude]);
    this.lengthOfPolyLineArray = this.polyLineArray.length;
    return this.allArray;
  }

  async getVehicles(obj) {
    var postData = obj;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);
    this.vehiclesArray = response?.data?.data;
  }

  // async offlineData(obj){
  //   var postData ={...obj,"offset":0,"limit":10,"page":1} ;
  //   const base64Input = await base64_encode(JSON.stringify(postData));
  //   var response = await postApi.getClient().post("index.php?c=vehicle&a=offlinedata", "postData=" + base64Input);

  // }

  async setOfflineData(data) {
    // this.vehicleId = data.vehicleId;
    var postData = {
      ...data,
      limit: 10,
      page: this.pgcount,
      offset: this.offset,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=offlinedata", "postData=" + base64Input);

    this.postData = data;
    let totalCount = response?.data?.data?.totalcount;
    this.offlineData = response?.data?.data;

    // this.historyTrackingTableData = this.offlineDataNewObj

    if (this.checked) {
      if (this.totalCount === null) {
        this.totalCount = totalCount;
      }

      if (
        this.totalCount >= 10 ||
        (this.totalCount < 10 && this.totalCount >= 0)
      ) {
        // console.log(await this.offlineData.vehicleposition)
        if (await this.offlineData.vehicleposition) {
          await this.offlineData.vehicleposition?.forEach((val) => {
            // this.offlineLatLongArray.push(val);

            this.offlineDataNewObj.push(val);
          });

          await this.handleOfflineData();
        }
      } else if (this.totalCount === 0) {
        console.log("end");
      }
    }
  }

  async handleOfflineData() {
    this.totalCount = this.totalCount - 10;
    this.pgcount = ++this.pgcount;
    this.offset = this.offset + 10;
    this.setOfflineData(this.postData);
  }
}

export default HistoryTrackingStore;
