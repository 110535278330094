import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,

  makeObservable,
  observable,

} from 'mobx';


class ForgetPassStore {
  jobTypeList = []
  jobList = []
  total = 0
  loading = false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
      jobTypeList: observable,
      total: observable,
      jobList: observable,
      loading: observable,

      forgetPass: action,
      // getJobTypeData:action,
      // getJobData:action

    });
    this.rootStore = rootStore;

  }

  async forgetPass(values) {
    console.log(values)
    var postData = values;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=user&a=forgotPass", "postData=" + base64Input);
    console.log("details", response.data.data)
    return response
  }






}

export default ForgetPassStore;
