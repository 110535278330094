import React from "react";
import {
  Row,
  Col,
  Input,
  Tag,
  Avatar,
  Button,
  Space,
  Dropdown,
  Popconfirm,
  message,
  Tooltip,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import {
  PushpinFilled,
  EditFilled,
  DeleteFilled,
  QuestionCircleOutlined,
  DownOutlined,
  SearchOutlined,
  DownloadOutlined,
  CloudUploadOutlined,
  DashboardOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import passengerStore from "../store/PassengerStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import AddButton from "./AddButton";
import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import withRouter from "../withRouter";
import GradeTimingStore from "../store/GradeTimingStore";
import CompanyStore from "../store/CompanyStore";
import GradeTimingAdd from "./GradeTimingAdd";

class GradeTimingList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.companyStore = new CompanyStore(this);

    this.onDelete = this.onDelete.bind(this);

    this.gradTimingStore = new GradeTimingStore();

    // console.log(this.context);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      companyId: "",
      loadList: 0,
      company_value_label: "",
      openGradeForm: false,
      listDataUpdate: false,
      grade_Id: "",
    };
  }

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId)
      await this.setState({ companyId: this.props.getUserData().userCompanyId })
      await this.setState({ userId: this.props.getUserData().userId })
      await this.handleSearch();
    }
    // await this.gradTimingStore.loadData({ offset: 0, limit: 50 });
    // const companyId = this.props.params.companyId
    // console.log(companyId,this.props.params)
    // if(companyId){
    //   await this.gradTimingStore.loadData({
    //     offset: ((this.state.currentPage - 1) * this.state.pageSize),
    //     limit: this.state.pageSize,
    //     // searchField: this.state.searchField,
    //     companyId : companyId

    // });
    // }

    // const beforeEditCompanyId =
    //   this.context.viewStateStore.gradeTimingObject.companyId;
    // await this.gradTimingStore.loadData({
    //   offset: (this.state.currentPage - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: beforeEditCompanyId,
    // });
    // const singleData = await this.companyStore.getCompanyDataById(
    //   this.context.viewStateStore.gradeTimingObject.companyId
    // );
    // await this.setState({ loadList: 1 }); //need to reload data
    // await this.setState({ company_value_label: singleData });

    this.columns = [
      {
        // title: "Grade",
        title: this.context.translate("_GRADE_"),
        width: 150,
        dataIndex: "className",
        key: "grade",
        fixed: "left",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        // title: "Start Time",
        title: this.context.translate("_FLEET_START_TIME_"),
        width: 150,
        dataIndex: "startTime",
        key: "startTime",
        fixed: "left",
      },
      {
        // title: "End Time",
        title: this.context.translate("_END_TIME_"),
        width: 150,
        dataIndex: "stopTime",
        key: "endtime",
        fixed: "left",
      },

      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        fixed: "right",
        width: 50,
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    // title="Edit Grade Timing"
                    title={this.context.translate("_EDIT_GRID_TIMING_")}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      // title="Delete Grade Timing"
                      title={this.context.translate("_DELETE_GRID_TIMING_")}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
    await this.setState({ pageSize: 50 });
  }
  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      console.log("in");
      const beforeEditCompanyId =
        this.context.viewStateStore.gradeTimingObject.companyId;
      await this.gradTimingStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: beforeEditCompanyId,
      });
      const singleData = await this.companyStore.getCompanyDataById(
        this.context.viewStateStore.gradeTimingObject.companyId
      );
      await this.setState({ loadList: 1 }); //need to reload data
      await this.setState({ company_value_label: singleData });
      await this.setState({ listDataUpdate: false });
      // await this.setState({ pageSize: 50 });
    }
  }

  onEdit = async (record) => {
    console.log(record.passengerId);
    const gradeId = record.grade_Id;
    await this.context.viewStateStore.setGradeTimingStates({
      companyId: record.companyId,
    });
    // if (gradeId) {
    //   this.props.navigate("/gradetimingadd/" + gradeId)
    // }
    await this.setState({ grade_Id: gradeId });
    await this.setState({ openGradeForm: true });
  };
  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      currentPage: 1,
      pageSize: 50,
    });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ company_value_label: singleData });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.gradTimingStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }
  async onDelete(record) {
    console.log(record);
    const response = await this.gradTimingStore.deleteRecord({
      grade_Id: record.grade_Id,
    });
    // console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }
closeModal=()=>{
  this.setState({ openGradeForm: false, grade_Id: "" });

}
  
  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.success == "S") {
      message.success(resp.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openGradeForm: false });

      //  await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    } else if (resp.success == "F0") {
      resp.errors.map((value) => {
        message.error(value["grade"]);
      });
      console.log(resp.data.errors[0].grade);
      message.error(resp.data.errors[0].grade);
    }
  };

  render() {
    console.log("in render");
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_GRADE_TIMING_LIST_")}
                {/* Grade Timings List */}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                {/* <AddButton title={this.context.translate('_ADD_')} link="/gradetimingadd" /> */}
                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  placement="left"
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      this.setState({ openGradeForm: true, grade_Id: "" });
                    }}
                  ></Button>
                </Tooltip>
                {/* <Button>
                 
                    {this.context.translate('_DASHBOARD_')}
                  </Button> */}
                <Button type="primary">
                  <i
                    class="bi bi-speedometer2"
                    style={{ marginRight: "5px" }}
                  ></i>

                  {this.context.translate("_DASH_BOARD_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          {/* <div className='heading'>Grade Timings List</div> */}
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  value={
                    this.state.company_value_label
                      ? this.state.company_value_label
                      : null
                  }
                  // allowClear={true}
                  style={{ width: 200 }}
                />
              </Space>
            </Col>
            {/* <Col span={8}>
                  <Space style={{float:this.context.locale.direction=="ltr"?"right":"left"}}> 
                    <AddButton title={this.context.translate('_ADD_')} link ="/gradetimingadd" />
                      <Button>  
                     DashBoard
                      </Button>
                  </Space>
                </Col> */}
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.gradTimingStore?.total}
            columns={this.columns}
            dataSource={this.gradTimingStore?.gradeTiming}
            loading={this.gradTimingStore?.loading}
            onChange={this.handleTableChange}
          />

          {this.state.openGradeForm && (
            <Modal
              bodyStyle={{ height: "637px" }}
              className="formShowModal" //  {/* css class */}
              // title="Grade Timing Details"
              title={this.context.translate("_GRADE_TIMING_DETAILS_")}
              centered
              visible={this.state.openGradeForm}
              footer={null}
              onCancel={() => {
                this.setState({ openGradeForm: false, grade_Id: "" });
              }}
              width={665}
            >
              <div>
                <GradeTimingAdd
                  grade_Id={this.state.grade_Id}
                  response={this.responseMsg}
                  closeModal={this.closeModal}
                />
              </div>
            </Modal>
          )}
        </div>
      </>
    );
  }
}
GradeTimingList.contextType = StoreContext;
export default observer(withRouter(GradeTimingList));
