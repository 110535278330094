import React, { Component } from "react";

import {
  Form,
  Select,
  Button,
  Input,
  message,
  DatePicker,
  Modal,
  Checkbox,
  Upload,
  Row,
  Col,
  Space,
  Spin,
} from "antd";
import config from "../config";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import moment from "moment";
import SchoolCalenderStore from "../store/SchoolCalenderStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { Calendar } from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import { Link } from "react-router-dom";
import CompanyStore from "../store/CompanyStore";
import dayjs from "dayjs";

const dateFormat = "YYYY-MM-DD";
const years = [];
for (let i = 2016; i <= 2030; i++) {
  years.push(i);
}

const currentDate = moment().format("YYYY-MM-DD");
console.log(currentDate);
class SchoolCalenderAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    //  this.state = ({ show: false })

    this.state = {
      loading: false,
      companyId: "",
      holiday1: false,
      holiday2: false,
      holiday3: false,
      selectedDates: [],
      calenderId: -1,

      loadingSpin: false,
      // reOpen:new Date(),
      // parentMeet:new Date(),
      // winterBreakFrom:new Date(),
      // winterBreakTo:new Date(),
      // secreOpen:new Date(),
      // secparentMeet:new Date(),
      // springbreakfrom:new Date(),
      // springbreakto:new Date(),
      // thdreOpen:new Date(),
      // thdparentMeet:new Date(),
      // summerbreakfrom:new Date(),
      // summerbreakto:new Date(),
    };
    this.schoolCalenderStore = new SchoolCalenderStore(this);
    this.companyStore = new CompanyStore(this);
    this.termSectionCss = {
      marginTop: "10px",
      paddingBottom: "22px",
      borderBottom: "1px solid",
    };
  }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      this.context.viewStateStore.setSchoolCalendarStates({ companyId: this.props.getUserData().userCompanyId });
      await this.setState({ companyId: this.props.getUserData().userCompanyId })
      console.log(this.state.companyId)
      // await this.setState({userId:this.props.getUserData().userId})
      await this.setState({ isadmin: this.props.getUserData().isadmin })
    }
    const id = this.props.calenderId;
    console.log(id);

    if (id) {
      await this.setState({ loadingSpin: true });
      const getSchoolCalendar =
        await this.schoolCalenderStore.getSchoolCalender(id);
      await this.setState({
        companyId: getSchoolCalendar.companyId,
        calenderId: getSchoolCalendar.calenderId,
      });
      const singleData = await this.companyStore.getCompanyDataById(
        getSchoolCalendar.companyId
      );
      console.log(getSchoolCalendar.SchoolCalHoliday, "eedf");
      const holidayArray = getSchoolCalendar?.SchoolCalHoliday?.map((val) => {
        return val.termHoliday;
      });
      console.log(holidayArray);

      // for(let i=0;i<getSchoolCalendar.SchoolCalHoliday.length;i++){
      //     let holidays =[]
      //     if(getSchoolCalendar.SchoolCalHoliday[i].term=="first"){
      //         // console.log(getSchoolCalendar.SchoolCalHoliday[i].termHoliday)
      //         holidays[i]['first'].push(getSchoolCalendar.SchoolCalHoliday[i].termHoliday)
      //     }
      //     console.log(holidays)
      // }
      let firstTermHolyday = [];
      let secondTermHolyday = [];
      let thirdTermHolyday = [];
      const holydaysAry = getSchoolCalendar.SchoolCalHoliday;
      getSchoolCalendar.SchoolCalHoliday.map((val, ind) => {
        if (val.term == "first") {
          firstTermHolyday.push(val.termHoliday);
        } else if (val.term === "second") {
          secondTermHolyday.push(val.termHoliday);
        } else if (val.term == "third") {
          thirdTermHolyday.push(val.termHoliday);
        }
      });
      console.log(firstTermHolyday, secondTermHolyday);

      // await this.setState({selectedDates:holidayArray})
      await this.formRef.current.setFieldsValue({
        companyId: singleData,
        holiday1:
          parseInt(getSchoolCalendar.holiday1) > 0
            ? this.setState({ holiday1: true })
            : this.setState({ holiday1: false }),
        holiday2:
          parseInt(getSchoolCalendar.holiday2) > 0
            ? this.setState({ holiday2: true })
            : this.setState({ holiday2: false }),
        holiday3:
          parseInt(getSchoolCalendar.holiday3) > 0
            ? this.setState({ holiday3: true })
            : this.setState({ holiday3: false }),
        holidayEvent: getSchoolCalendar.holidayEvent,

        parentMeet:getSchoolCalendar.parentMeet ? dayjs(getSchoolCalendar.parentMeet) : null,
        reOpen: getSchoolCalendar.reOpen ? dayjs(getSchoolCalendar.reOpen) :null,
        secholidayEvent: getSchoolCalendar.secholidayEvent,
        secparentMeet:getSchoolCalendar.secparentMeet ? dayjs(getSchoolCalendar.secparentMeet):null,
        secreOpen:getSchoolCalendar.secreOpen ? dayjs(getSchoolCalendar.secreOpen):null,
        springbreakfrom:getSchoolCalendar.springbreakfrom ? dayjs(getSchoolCalendar.springbreakfrom):null,
        springbreakto: getSchoolCalendar.springbreakto ? dayjs(getSchoolCalendar.springbreakto):null,
        summerbreakfrom:getSchoolCalendar.summerbreakfrom ? dayjs(getSchoolCalendar.summerbreakfrom):null,
        summerbreakto:getSchoolCalendar.summerbreakto ? dayjs(getSchoolCalendar.summerbreakto):null,
        thdholidayEvent: getSchoolCalendar.thdholidayEvent,
        thdparentMeet:getSchoolCalendar.thdparentMeet ? dayjs(getSchoolCalendar.thdparentMeet):null,
        thdreOpen:getSchoolCalendar.thdreOpen ? dayjs(getSchoolCalendar.thdreOpen):null,
        winterbreakfrom:getSchoolCalendar.winterbreakfrom ? dayjs(getSchoolCalendar.winterbreakfrom):null,
        winterbreakto:getSchoolCalendar.winterbreakto ? dayjs(getSchoolCalendar.winterbreakto):null,
        yearFrom: getSchoolCalendar.yearFrom,
        yearTo: getSchoolCalendar.yearTo,
        firstTermHoliday: firstTermHolyday,
        secTermHoliday: secondTermHolyday,
        thdTermHoliday: thirdTermHolyday,
      });
      await this.setState({ loadingSpin: false });
    }
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    console.log(comp.value);
    if (!this.props.calenderId) {
      this.context.viewStateStore.setSchoolCalendarStates({
        companyId: comp.value,
      });
    }
    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });
  }
  onFinish = async (values) => {
    this.setState({ loading: true });
    console.log(this.state.reOpen);
    const data = await {
      ...values,
      holiday1: this.state.holiday1 ? "1" : "0",
      holiday2: this.state.holiday2 ? "1" : "0",
      holiday3: this.state.holiday3 ? "1" : "0",
      companyId: this.state.companyId,
      reOpen: values.reOpen?.format(dateFormat),
      calenderId: -1,
      parentMeet: values.parentMeet?.format(dateFormat),
      winterbreakfrom: values.winterbreakfrom?.format(dateFormat),
      winterbreakto: values.winterbreakto?.format(dateFormat),
      secreOpen: values.secreOpen?.format(dateFormat),
      secparentMeet: values.secparentMeet?.format(dateFormat),
      springbreakfrom: values.springbreakfrom?.format(dateFormat),
      springbreakto: values.springbreakto?.format(dateFormat),
      thdreOpen: values.thdreOpen?.format(dateFormat),
      thdparentMeet: values.thdparentMeet?.format(dateFormat),
      summerbreakfrom: values.summerbreakfrom?.format(dateFormat),
      summerbreakto: values.summerbreakto?.format(dateFormat),
      calenderId: this.state.calenderId,
      holidayEvent: values.holidayEvent ? values.holidayEvent : "",
      secholidayEvent: values.secholidayEvent ? values.secholidayEvent : "",
      thdholidayEvent: values.thdholidayEvent ? values.thdholidayEvent : "",
    };

    console.log(data);
    const response = await this.schoolCalenderStore.addData(data);
    this.props.response(response);
    this.setState({ loading: false });
    // if (response?.success === 'S') {
    //     await message.success(response.message)
    //     this.props.navigate("/schoolcalenderlist");

    // } else {
    //     message.error(response.message);
    // }
  };
  firstTermHolidayDateChange = async (value, event) => {
    console.log(value);
    const newDates = value.map((date) => date.format("YYYY-MM-DD"));
    console.log(newDates);
    this.formRef.current.setFieldsValue({ firstTermHoliday: newDates });
  };

  secondTermHolidayDateChange = async (value, event) => {
    const newDates = value.map((date) => date.format("YYYY-MM-DD"));
    this.formRef.current.setFieldsValue({ secTermHoliday: newDates });
  };
  thirdTermHolidayDateChange = async (value, event) => {
    const newDates = value.map((date) => date.format("YYYY-MM-DD"));
    this.formRef.current.setFieldsValue({ thdTermHoliday: newDates });
  };

  closeModal = () => {
    this.props.closeModal()
  }

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              ref={this.formRef}
              layout="vertical"
              onFinish={this.onFinish}
              // {...this.layout}
              name="nest-message"
              className="formClass"
              // initialValues={{
              //     reOpen: moment(currentDate, dateFormat),
              //     parentMeet: moment(currentDate, dateFormat),
              //     winterbreakfrom: moment(currentDate, dateFormat),
              //     winterbreakto: moment(currentDate, dateFormat),
              //     secreOpen: moment(currentDate, dateFormat),
              //     secparentMeet: moment(currentDate, dateFormat),
              //     springbreakfrom: moment(currentDate, dateFormat),
              //     springbreakto: moment(currentDate, dateFormat),
              //     thdreOpen: moment(currentDate, dateFormat),
              //     thdparentMeet: moment(currentDate, dateFormat),
              //     summerbreakfrom: moment(currentDate, dateFormat),
              //     summerbreakto: moment(currentDate, dateFormat)
              // }}
              initialValues={{
                reOpen: dayjs(),
                parentMeet: dayjs(),
                winterbreakfrom: dayjs(),
                winterbreakto: dayjs(),
                secreOpen: dayjs(),
                secparentMeet: dayjs(),
                springbreakfrom: dayjs(),
                springbreakto: dayjs(),
                thdreOpen: dayjs(),
                thdparentMeet: dayjs(),
                summerbreakfrom: dayjs(),
                summerbreakto: dayjs(),
              }}
            >
              {/* <div className="formBorder"> */}
              {/* <div className="headingTitle"> School Calender Details</div> */}
              <div className="overflowFormInputs">
                <Row>
                  <Col span={8} className="formColumnClass">
                    {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3)  &&
                      <Form.Item
                        name="companyId"
                        label={this.context.translate("_ACCOUNT_")}
                        rules={[{ required: true }]}
                      >
                        <CompanyAutocomplete
                          onChange={async (value) => {
                            await this.handleChange(value);
                          }}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    }
                  </Col>

                  <Col span={8} className="formColumnClass">
                    <Form.Item
                      name="yearFrom"
                      label={this.context.translate("_ACADEMIC_YEAR_FROM_")}
                      rules={[
                        {
                          required: true,
                          message: this.context.translate(
                            "_ENTER_ACADEMIC_YEAR_FROM_"
                          ),
                        },
                      ]}
                    >
                      <Select>
                        {years.map((year) => {
                          return (
                            <Select.Option value={year}>{year}</Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="formColumnClass">
                    <Form.Item
                      name="yearTo"
                      label={this.context.translate("_ACADEMIC_YEAR_TO_")}
                      rules={[{ required: true }]}
                    >
                      <Select>
                        {years.map((year) => {
                          return (
                            <Select.Option value={year}>{year}</Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                {/* <h2 style={{ margin: "10px 0 0 0 " }}>First Term</h2> */}
                <div
                  style={{
                    ...this.termSectionCss,
                    height: this.state.holiday1 && "407px",
                  }}
                >
                  <h5 style={{ margin: "23px 0 0 0 " }}>
                    {this.context.translate("_FIRST_TERM_")}
                  </h5>

                  <Row>
                    {/* <Form.Item
                  name="companyId"
                  label={this.context.translate("_ACCOUNT_")}
                  rules={[{ required: true }]}
                >
                  <CompanyAutocomplete
                    onChange={async (value) => {
                      await this.handleChange(value);
                      this.getVehicles();
                    }}
                    style={{ width: "100%" }}
                  />
                </Form.Item> */}
                    <Col span={6} className="formColumnClass">
                      <Form.Item
                        name="reOpen"
                        label={this.context.translate("_SCHOOL_REOPEN_")}
                      >
                        <DatePicker format={"YYYY-MM-DD"} />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="formColumnClass">
                      <Form.Item
                        name="parentMeet"
                        label={this.context.translate("_PARENTS_MEETING_")}
                      >
                        <DatePicker format={"YYYY-MM-DD"} />
                      </Form.Item>
                    </Col>
                    <Col span={12} className="formColumnClass">
                      <Form.Item name="holiday1" label={" "}>
                        <Checkbox
                          checked={this.state.holiday1}
                          onChange={(e) =>
                            this.setState({ holiday1: e.target.checked })
                          }
                        >
                          {this.context.translate("_HOLYDAYS_")}
                        </Checkbox>
                      </Form.Item>

                      <div
                        style={{ display: this.state.holiday1 ? "" : "none" }}
                      >
                        <Form.Item
                          name="firstTermHoliday"
                          label=""
                          style={{
                            position: "absolute",
                            zIndex: "100",
                            right: "3%",
                            bottom: "-122px",
                          }}
                        >
                          <Calendar
                            style={{ marginTop: "86px" }}
                            value={this.selectedDates?.map(
                              (date) => new DateObject(date)
                            )}
                            multiple
                            onChange={this.firstTermHolidayDateChange}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={6} className="formColumnClass">
                      <Form.Item
                        name="winterbreakfrom"
                        label={this.context.translate("_WINTER_BREAK_FROM_")}
                      >
                        <DatePicker format={"YYYY-MM-DD"} />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="formColumnClass">
                      <Form.Item
                        name="winterbreakto"
                        label={this.context.translate("_WINTER_BREAK_TO_")}
                      >
                        <DatePicker format={"YYYY-MM-DD"} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={6} className="formColumnClass">
                      <div
                        style={{ display: this.state.holiday1 ? "" : "none" }}
                      >
                        <Form.Item name="holidayEvent"
                          // label="Holiday Event"
                          label={this.context.translate("_HOLIDAY_EVENT_")}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  style={{
                    ...this.termSectionCss,
                    height: this.state.holiday2 && "407px",
                  }}
                >
                  {/* <h2 style={{ margin: "30px 0 0 0 " }}>Second Term</h2> */}
                  <h5 style={{ margin: "10px 0 0 0 " }}>
                    {this.context.translate("_SECOND_TERM_")}
                  </h5>

                  <Row>
                    <Col span={6} className="formColumnClass">
                      <Form.Item
                        name="secreOpen"
                        label={this.context.translate("_SCHOOL_REOPEN_")}
                      >
                        <DatePicker format={"YYYY-MM-DD"} />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="formColumnClass">
                      <Form.Item
                        name="secparentMeet"
                        label={this.context.translate("_PARENTS_MEETING_")}
                      >
                        <DatePicker format={"YYYY-MM-DD"} />
                      </Form.Item>
                    </Col>

                    <Col span={12} className="formColumnClass">
                      <Form.Item name="holiday2" label={" "}>
                        <Checkbox
                          checked={this.state.holiday2}
                          onChange={(e) =>
                            this.setState({ holiday2: e.target.checked })
                          }
                        >
                          {this.context.translate("_HOLYDAYS_")}
                        </Checkbox>
                      </Form.Item>

                      <div
                        style={{ display: this.state.holiday2 ? "" : "none" }}
                      >
                        <Form.Item
                          name="secTermHoliday"
                          label=""
                          style={{
                            position: "absolute",
                            zIndex: "100",
                            right: "3%",
                            bottom: "-122px",
                          }}
                        >
                          <Calendar
                            style={{ marginTop: "86px" }}
                            value={this.selectedDates?.map(
                              (date) => new DateObject(date)
                            )}
                            multiple
                            onChange={this.secondTermHolidayDateChange}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={6} className="formColumnClass">
                      <Form.Item
                        name="springbreakfrom"
                        label={this.context.translate("_SPRING_BREAK_FROM_")}
                      >
                        <DatePicker format={"YYYY-MM-DD"} />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="formColumnClass">
                      <Form.Item
                        name="springbreakto"
                        label={this.context.translate("_SPRING_BREAK_TO_")}
                      >
                        <DatePicker format={"YYYY-MM-DD"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6} className="formColumnClass">
                      <div
                        style={{ display: this.state.holiday2 ? "" : "none" }}
                      >
                        <Form.Item name="secholidayEvent" label={this.context.translate("_HOLIDAY_EVENT_")}>
                          <Input />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    ...this.termSectionCss,
                    height: this.state.holiday3 && "407px",
                  }}
                >
                  {/* <h2 style={{ margin: "10px 0 0 0 " }}>Third Term</h2> */}
                  <h5 style={{ margin: "10px 0 0 0 " }}>
                    {this.context.translate("_THIRD_TERM_")}
                  </h5>
                  <Row>
                    <Col span={6} className="formColumnClass">
                      <Form.Item
                        name="thdreOpen"
                        label={this.context.translate("_SCHOOL_REOPEN_")}
                      >
                        <DatePicker format={"YYYY-MM-DD"} />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="formColumnClass">
                      <Form.Item
                        name="thdparentMeet"
                        label={this.context.translate("_PARENTS_MEETING_")}
                      >
                        <DatePicker format={"YYYY-MM-DD"} />
                      </Form.Item>
                    </Col>

                    <Col span={12} className="formColumnClass">
                      <Form.Item name="holiday3" label={" "}>
                        <Checkbox
                          checked={this.state.holiday3}
                          onChange={(e) =>
                            this.setState({ holiday3: e.target.checked })
                          }
                        >
                          {this.context.translate("_HOLYDAYS_")}
                        </Checkbox>
                      </Form.Item>

                      <div
                        style={{ display: this.state.holiday3 ? "" : "none" }}
                      >
                        <Form.Item
                          name="thdTermHoliday"
                          label=""
                          style={{
                            position: "absolute",
                            zIndex: "100",
                            right: "3%",
                            bottom: "-122px",
                          }}
                        >
                          <Calendar
                            style={{ marginTop: "86px" }}
                            value={this.selectedDates?.map(
                              (date) => new DateObject(date)
                            )}
                            multiple
                            onChange={this.thirdTermHolidayDateChange}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6} className="formColumnClass">
                      <Form.Item
                        name="summerbreakfrom"
                        label={this.context.translate("_SUMMER_BREAK_FROM_")}
                      >
                        <DatePicker format={"YYYY-MM-DD"} />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="formColumnClass">
                      <Form.Item
                        name="summerbreakto"
                        label={this.context.translate("_SUMMER_BREAK_TO_")}
                      >
                        <DatePicker format={"YYYY-MM-DD"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6} className="formColumnClass">
                      <div
                        style={{ display: this.state.holiday3 ? "" : "none" }}
                      >
                        <Form.Item name="thdholidayEvent" label={this.context.translate("_HOLIDAY_EVENT_")}>
                          <Input />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="formBtnGroup">
                {/* <Link to="/schoolcalenderlist">  <Button >Back</Button></Link> */}

                <Button
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal()
                    // this.formRef.current?.resetFields();
                    this.setState({
                      holiday1: false,
                      holiday2: false,
                      holiday3: false,
                    });
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  className="formSaveButton"
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>

              {/* </div> */}
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

SchoolCalenderAdd.contextType = StoreContext;
export default observer(withRouter(SchoolCalenderAdd));
