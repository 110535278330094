import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  
  makeObservable,
  observable,
 
} from 'mobx';
import { act } from 'react-dom/test-utils';


class AttendenceReportStore {
  attendenceReport=[]
  salesPersonArray=[]
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        attendenceReport: observable,
        salesPersonArray:observable,
      total:observable,
      loading:observable,
      loadData:action,
      getSalesPersonExecutive:action
     
    });
     this.rootStore = rootStore;
    
  }

  async loadData({companyId,offset,limit,salespersonId,fromDate,toDate}) {
    console.log("attendenceReport")
    var postData = {"companyId":companyId,"salespersonId":salespersonId,"fromDate":fromDate,"toDate":toDate,"offset":offset,"limit":limit,"page":1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=salesperson&a=attendaceReport", "postData="+base64Input);
    this.attendenceReport =response?.data?.data
     this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }


  async getSalesPersonExecutive({companyId,offset,limit,}) {
    console.log("attendenceReport")
    var postData = {"companyId":companyId}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=salesperson&a=getSalesExecutive", "postData="+base64Input);
    this.salesPersonArray =response?.data?.data
    console.log(response.data.data)
     this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }


  

  
  

}

export default AttendenceReportStore;
