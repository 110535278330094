import React from "react";
import { Row, Col, Space, Tooltip, message, Button, Modal } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import ListTable from "./ListTable";

import config from "../config";

import withRouter from "../withRouter";
import DeleteConfirm from "./DeleteConfirm";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import CompanyAutocomplete from "./CompanyAutocomplete";
import PointofInterestAdd from "./PointofInterestAdd";
import PointofInterest from "../store/PointofInterestStore";
import CompanyStore from "../store/CompanyStore";
import { Link } from "react-router-dom";

class AdvertisementList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.poiStore = new PointofInterest(this);
    this.companyStore = new CompanyStore(this);
    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      loadData: 1,
      companyId: "",
      comp_value: "",
      openPOIForm: false,
    };
  }
  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });

      await this.handleSearch();
    }
    this.setState({ pageSize: 50 });

    this.columns = [
      {
        title: this.context.translate("_COMAPNY_NAME_"),
        dataIndex: "companyName",
        width: "20%",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        title: this.context.translate("_NAME_"),
        dataIndex: "poiname",
        width: "15%",
      },
      {
        title: this.context.translate("_SEQUENCE_"),
        dataIndex: "sequence",
        width: "15%",
      },
      {
        title: this.context.translate("_INTERVAL_SEC_"),
        dataIndex: "playInterval",
        width: "15%",
      },
      {
        title: this.context.translate("_IMAGE_VIDEO_TYPE_"),
        dataIndex: "showType",
        width: "15%",
        render: (value) => {
          if (value === "1") {
            return "Full Banner";
          }
          if (value === "2") {
            return "Middle Banner";
          }
          if (value === "3") {
            return "Bottom Banner";
          }
        },
      },
      {
        title: this.context.translate("_DESCRIPTION_"),
        dataIndex: "description",
        width: "15%",
      },
      {
        title: this.context.translate("_PICTURE_VIEW_"),
        dataIndex: "path",
        width: "30%",
        render: (value, record) => {
        // console.log(config.fleetURL + record.path) 
          var imagePath = config.fleetURL + record.path;
          var showType = Number(record.showType);
          var isImgVideo = Number(record.isImgVideo);
          if (showType === 1 && isImgVideo === 1) {
            return (
              <img
                src={imagePath}
                alt="Image"
                style={{ width: "100px", height: "auto" }}
              />
            );
          }
          if (showType === 2 && isImgVideo === 1) {
            return (
              <img
                src={imagePath}
                alt="Image"
                style={{ width: "100px", height: "auto" }}
              />
            );
          }
          if (showType === 3 && isImgVideo === 1) {
            return (
              <img
                src={imagePath}
                alt="Image"
                style={{ width: "100px", height: "auto" }}
              />
            );
          }
          if (isImgVideo === 2) {
            
            //return "<img src=" + imagePath + " height='70px' width ='70px'></img>";
            return (
              <video width="280" height="70" controls>
                <source src={imagePath} type="video/mp4" />
              </video>
            );
          }
        },
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "action",
        width: "6%",
        render: (_, record) => {
          return (
            <>
              <Space>
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    // title="Delete Record"
                    title={this.context.translate("_DELETE_RECORD_")}
                    color={config.tooltipColorDelete}
                  >
                    <DeleteFilled style={{ color: config.deleteColor }} />
                  </Tooltip>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const setPOIData = this.context.viewStateStore.poiObject;
      console.log(setPOIData);
      if (setPOIData !== "") {
        await this.poiStore.loadData({
          companyId: this.state.companyId,
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          page: this.state.currentPage,
        });

        const singleData = await this.companyStore.getCompanyDataById(
          setPOIData
        );

        await this.setState({ loadList: 1 });

        await this.setState({
          comp_value: singleData,
          companyId: setPOIData,
        });
      }
      await this.setState({ listDataUpdate: false });
    }
  }

  onEdit = async (record) => {
    await this.context.viewStateStore.setpoiStates({
      companyId: record.companyId,
    });
    await this.setState({ id: record.id });
    await this.setState({ openPOIForm: true });
  };

  async onDelete(record) {
    console.log(record);
    const response = await this.poiStore.deleteRecord({
      id: record.id,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.poiStore.loadData({
      companyId: this.state.companyId,
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      page: this.state.currentPage,
    });
    await this.setState({ loadData: 1, loading: false });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      currentPage: 1,
      pageSize: 50,
    });

    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    console.log(singleData);

    await this.setState({ comp_value: singleData });

    await this.handleSearch();
    await this.setState({ loadList: 1 });
  }

  closeModal = () => {
    this.setState({ openPOIForm: false });
  };

  responseMsg = async (resp) => {
    if (resp.success === "S") {
      message.success(resp.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openPOIForm: false });
      await this.handleSearch();
    }
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_POINT_OF_INTEREST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Tooltip
                  placement="left"
                  //  title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => this.setState({ openPOIForm: true, id: "" })}
                  ></Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  style={{ width: 200 }}
                  value={this.state.comp_value ? this.state.comp_value : null}
                />
              </Space>
            </Col>
          </Row>
          <ListTable
            currentPage={this.state.currentPage}
            total={this.poiStore?.total}
            columns={this.columns}
            pageSize={this.state.pageSize}
            dataSource={this.poiStore?.poiList}
            loading={this.poiStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openPOIForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal"
            title={this.context.translate("_ADD_POI_")}
            centered
            open={this.state.openPOIForm}
            footer={null}
            onCancel={() => {
              this.setState({ openPOIForm: false, id: "" });
            }}
            width={665}
          >
            <div>
              <PointofInterestAdd
                companyId={this.state.companyId}
                id={this.state.id}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
AdvertisementList.contextType = StoreContext;
export default observer(withRouter(AdvertisementList));
