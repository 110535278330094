import React from "react";
import { Button, Space, Row, Col } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import PaymentStore from "../store/PaymentStore";

import ListTable from "./ListTable";
import withRouter from "../withRouter";
class PaymentVehicleList extends React.Component {
  constructor(props) {
    super(props);

    // this.handleTableChange = this.handleTableChange.bind(this);
    this.paymentStore = new PaymentStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      showDetails: false,
      loadList:0
    };
  }
  async componentDidMount() {
    
      // this.context.viewStateStore.setPassengerStates({
      //   companyId: this.props.params.id,
      
      // });
    
    await this.paymentStore.loadPaymentList({  companyId: this.props.params.id, });

    this.setState({ pageSize: 50 });

    this.columns1 = [
      {
        // title: "Vehicle No",
        title:this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        width: "15%",
      },
      {
        // title: "Expiry Date",
        title:this.context.translate("_EXPIRY_DATE_"),
        dataIndex: "expiryDate",
        width: "15%",
      },
      {
        // title: "New Expiry Date",
        title:this.context.translate("_NEW_EXPIRY_DATE_"),
        dataIndex: "newExpiryDate",
        width: "15%",
      },
      {
        // title: "Amount",
        title:this.context.translate("_AMOUNT_"),
        dataIndex: "amount",
        width: "15%",
      },
    ];
    this.setState({loadList:1})
  }

//   async handleTableChange(pagination, filters, sorter) {
//     console.log(pagination, "pagi");
//     await this.setState({
//       currentPage: pagination.current,
//       pageSize: pagination.pageSize,
//     });
//     this.handleSearch();
//   }

//   async handleSearch() {
//     this.setState({ loading: true });
//     await this.paymentStore.loadPaymentList({
//       companyId: this.props.params.id,
//       offset: this.state.currentPage - 1,
//       limit: this.state.pageSize,
//     });
//   }
//   async handleChange(comp) {
//     await this.setState({ currentPage: 1, companyId: comp.value });
//     this.handleSearch();
//   }

  render() {
    console.log(this.props)
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Payment List</div> */}
              <div className="heading">{this.context.translate("_PAYMENT_LIST_")} </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                
                <Link to="/paymentlist">
                  <Button ghost type="primary">
                    {/* Back */}
                    {this.context.translate("_FLEET_COMPANY_COMPANYDETAIL_BACKBTN_")}
                  </Button>
                </Link>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
         

          <>
            <ListTable
              current={this.state.currentPage}
              pageSize={this.state.pageSize}
              total={this.paymentStore?.total}
              columns={this.columns1}
              dataSource={this.paymentStore?.vehiclePayment}
              loading={this.paymentStore?.loading}
              onChange={this.handleTableChange}
            />
          </>
        </div>
      </>
    );
  }
}
PaymentVehicleList.contextType = StoreContext;
export default observer(withRouter(PaymentVehicleList));
