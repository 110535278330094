import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi, baseURL } from "../components/Utility";

import {
  action,
  
  makeObservable,
  observable,
 
} from 'mobx';


class InventoryStatusReportStore {
  inventoryStatusReport=[]
  warehouseArray = []
  inventoryArray = []
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        inventoryStatusReport: observable,
        inventoryArray: observable,
        warehouseArray: observable,
      total:observable,
      loading:observable,
      
      loadData:action,
      getInventoryByCompanyId:action,
      getWarehouseByCompanyId:action,
     
    });
     this.rootStore = rootStore;
    
  }

  async loadData({offset,limit,companyId,vehicleId,fromDate,toDate,warehouseId,inventoryId,currentPage}) {
    console.log("workingHoursReport")
    this.loading=true;
    var postData = {"companyId":companyId,"vehicleId":vehicleId,"fromDate":fromDate,warehouseId:warehouseId,inventoryId:inventoryId,"toDate":toDate,"offset":offset,"limit":limit,"page":currentPage}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=InventoryStatusReport", "postData="+base64Input);
    console.log("Inventory" ,response.data.data)
    // console.log("company Data",response?.data?.data?.inventorydata)
    this.inventory =response?.data?.data?.inventorystatusdata
    //  this.setCompanies(response?.data?.data?.companydata);
     this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }

  async getWarehouseByCompanyId({companyId}){
     this.loading = true;
     console.log("inventoryStatusReport")

     var postData ={companyId:companyId}
     const base64Input = await base64_encode(JSON.stringify(postData));
     var response = await postApi.getClient().post("index.php?c=warehouse&a=getWarehouseByCompany", "postData="+base64Input);
     console.log("warehouse" ,response.data.data)
     // console.log("company Data",response?.data?.data?.inventorydata)
     this.warehouseArray =response?.data?.data
  
      this.total = parseInt(response?.data?.data?.TotalCount);
     this.loading=false;
   }
   
  async getInventoryByCompanyId({companyId,warehouseId}){
    //  this.loading = true;
     console.log("inventoryStatusReport")

     var postData ={companyId:companyId,warehouseId:warehouseId}
     const base64Input = await base64_encode(JSON.stringify(postData));
     var response = await postApi.getClient().post("index.php?c=warehouse&a=getInventoryByCompany", "postData="+base64Input);
     console.log("warehouse" ,response.data.data)
     // console.log("company Data",response?.data?.data?.inventorydata)
     this.inventoryArray =response?.data?.data
  
      this.total = parseInt(response?.data?.data?.TotalCount);
     this.loading=false;
   }
   async exportExcel(getData) {
    let lang = "english";
    var postData ={"companyId":getData.companyId,"warehouseId":getData.warehouseId,"inventoryId":getData.inventoryId,"fromDate":getData.fromDate,"toDate":getData.toDate,"colList":getData.colList,"language":lang}
    console.log(baseURL +'index.php?c=vehicle&a=exportInventoryStatusExcel')
      
            const newWindow1 = window.open('', '_blank');
            const form = newWindow1.document.createElement('form');
            form.action = baseURL +'index.php?c=vehicle&a=exportInventoryStatusExcel';
            form.method = 'POST';
            const dataInput = newWindow1.document.createElement('input');
            dataInput.type = 'hidden';
            dataInput.name = 'postData';
            dataInput.value = base64_encode(JSON.stringify(postData)); 
            form.appendChild(dataInput);
            newWindow1.document.body.appendChild(form);
            form.submit();
            setTimeout(() => {
              newWindow1.close();
            }, 3000);
  }

  async exportpdf(getData) {
    let lang = "english";
    var postData ={"companyId":getData.companyId,"vehicleId":getData.vehicleId,"warehouseId":getData.warehouseId,"inventoryId":getData.inventoryId,"fromDate":getData.fromDate,"toDate":getData.toDate,"colList":getData.colList,"language":lang}

      
            const newWindow1 = window.open('', '_blank');
            const form = newWindow1.document.createElement('form');
            form.action = baseURL +'index.php?c=vehicle&a=exportInventoryStatusPdf';
            form.method = 'POST';
            const dataInput = newWindow1.document.createElement('input');
            dataInput.type = 'hidden';
            dataInput.name = 'postData';
            dataInput.value = base64_encode(JSON.stringify(postData)); 
            form.appendChild(dataInput);
            newWindow1.document.body.appendChild(form);
            form.submit();
            setTimeout(() => {
              newWindow1.close();
            }, 3000);
  }

  async exportcsv(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportInventoryStatuscsv&cid=" +
      getData.companyId +
      "&activity=" +
      getData.activity +
      // "&vid=" +
      // getData.vehicleId +
      // "&int="+
      // getData.interval+
      " &userId=" +
      getData.userId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  }
  


export default InventoryStatusReportStore;
