import React, { Component } from "react";
import ListTable from "./ListTable";
import WarehouseMonitoringStore from "../store/WarehouseMonitoringStore";
import {
  Button,
  Col,
  Dropdown,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import config from "../config";
import { DownOutlined } from "@ant-design/icons";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { Link } from "react-router-dom";
import MapForVehiclePosition from "./MapForVehiclePosition";

import withRouter from "../withRouter";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

class WarehouseMonitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      loadList: 0,
      companyId: "",
      warehouseId: "",
      vehicleObj: false,
    };
    this.WarehouseMonitoringStore = new WarehouseMonitoringStore();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.vehicleObj !== prevState.vehicleObj) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }
  }
  async componentDidMount() {
    //    await this.WarehouseMonitoringStore.loadData({
    //       companyId: null,
    //       warehouseId: null,
    //       offset: 0,
    //       limit: 50,
    //     });

    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.WarehouseMonitoringStore.getWarehouseByCompany({
        companyId: this.props.getUserData().userCompanyId,
      });
      // await this.handleSearch();

      // await this.handleChange();
      await this.setState({ update: 1 });
    }
    setInterval(() => {
      this.WarehouseMonitoringStore.loadData({
        companyId: this.state.companyId,
        warehouseId: this.state.warehouseId,
        offset: 0,
        limit: 50,
      });
    }, 60000);
    this.columns = [
      {
        // title: "Account Name",
        title: this.context.translate(
          "_FLEET_COMPANY_COMPANYLIST_COMPANYNAME_"
        ),
        dataIndex: "companyName",
      },
      {
        // title: "Warehouse Name",
        title: this.context.translate("_WAREHOUSE_NAME_"),
        dataIndex: "warehouse",
      },
      {
        // title: "License Number",
        title: this.context.translate("_FLEET_DRIVER_DRIVERLIST_LICENCE_"),
        dataIndex: "licenseNo",
      },

      {
        title:this.context.translate('_INVENTORY_ONE_'),
        dataIndex: "inventory1",
        render: (inventory1) => {
          return <div dangerouslySetInnerHTML={{ __html: inventory1 }} />;
        },
      },
      {
        title: this.context.translate('_INVENTORY_TWO_'),
        dataIndex: "inventory2",
        render: (inventory2) => {
          return <div dangerouslySetInnerHTML={{ __html: inventory2 }} />;
        },
      },
      {
        title:this.context.translate('_INVENTORY_THREE_'),
        dataIndex: "inventory3",
        render: (inventory3) => {
          return <div dangerouslySetInnerHTML={{ __html: inventory3 }} />;
        },
      },
      {
        title:this.context.translate('_INVENTORY_FOUR_'),
        dataIndex: "inventory4",
        render: (inventory4) => {
          return <div dangerouslySetInnerHTML={{ __html: inventory4 }} />;
        },
      },
      {
        // title: "Address",
        dataIndex: "address",
        title: this.context.translate("_FLEET_DASHBORD_NEWPOI_ADDRESS_"),
        render: (text, obj) => {
          return (
            <Link
              onClick={async () => {
                await this.setState({
                  vehicleObj: { ...obj, icon: "logo_icon.png" },
                  heading: this.context.translate("_LOCATION_ON_MAP_"),
                });
                await this.setState({ loadData: 1 });
              }}
            >
              {text}
            </Link>
          );
        },
      },
    ];

    this.ExportMenuProps = {
      items: [
        {
          // label: "Excel",
          label: this.context.translate("_EXCEL_"),
          key: "excel",
        },
        {
          // label: "PDF",
          label: this.context.translate("_PDF_"),
          key: "pdf",
        },
      ],
      onClick: (val) => {
        const result = [];

        var getData = {
          cid: this.state.companyId,
          warehouseId: this.state.warehouseId,
        };
        console.log(getData);
        if (val.key == "excel") {
          this.WarehouseMonitoringStore.exportExcel(getData);
        }
        if (val.key == "pdf") {
          this.WarehouseMonitoringStore.exportPdf(getData);
        }
      },
    };
    await this.setState({ update: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  handleChange = async (val) => {
    // console.log(val.comp.value,comp)
    this.WarehouseMonitoringStore.loadData({
      companyId: val.comp.value,
      warehouseId: this.state.warehouseId,
      offset: 0,
      limit: 50,
    });
    await this.setState({ warehouseId: " " });
    // this.WarehouseMonitoringStore.CompanyWiseWarehouseData = []
    await this.WarehouseMonitoringStore.getWarehouseByCompany({
      companyId: val.comp.value,
    });
    await this.setState({ companyId: val.comp.value });
  };
  render() {
    return (
      <div>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Warehouse Monitoring</div> */}
              <div className="heading">
                {this.context.translate("_WAREHOUSE_MONITORING_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            ></Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={24}>
              <Space>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={(comp) => this.handleChange({ comp })}
                  style={{ width: 200 }}
                />

                <Select
                  style={{
                    width: 170,
                  }}
                  fieldNames={{ label: "warehouse", value: "id" }}
                  //   value={
                  //     this.state.distCompanyId ? this.state.distCompanyId : null
                  //   }
                  // placeholder="Warehouse"
                  placeholder={this.context.translate("_WAREHOUSE_")}
                  onSelect={async (warehouseId) => {
                    await this.WarehouseMonitoringStore.loadData({
                      companyId: this.state.companyId,
                      warehouseId: warehouseId,
                      offset: 0,
                      limit: 50,
                    });
                    await this.setState({ warehouseId });
                  }}
                  optionFilterProp="children"
                  options={
                    this.WarehouseMonitoringStore?.CompanyWiseWarehouseData
                  }
                />

                {/* <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  onClick={async () => {
                    // this.handleSearch();
                    await this.UsersStore.loadData({
                      offset: 0,
                      limit: this.state.pageSize,
                      searchField: this.state.searchField,
                      companyId: this.state.companyId,
                    });

                    await this.setState({ loadData: 1 });
                  }}
                /> */}
                <Dropdown
                  menu={this.ExportMenuProps}
                  disabled={
                    this.WarehouseMonitoringStore?.warehouseData.length > 0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>

        <div style={{ margin: "0px 20px 20px" }}>
          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.WarehouseMonitoringStore?.total}
            columns={this.columns}
            dataSource={this.WarehouseMonitoringStore?.warehouseData}
            loading={this.WarehouseMonitoringStore.loading}
            onChange={this.handleTableChange}
            pagination={{ currentPage: this.state.currentPage }}
          />
        </div>

        {this.state.vehicleObj && (
          <MapForVehiclePosition
            vehicleObj={this.state.vehicleObj}
            heading={this.state.heading}
            closeMapModal={(resp) => {
              this.setState({ vehicleObj: resp });
            }}
          />
        )}
      </div>
    );
  }
}
WarehouseMonitoring.contextType = StoreContext;
export default observer(withRouter(WarehouseMonitoring));
