import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React, { Component } from "react";

import { MapContainer, Marker, Tooltip, TileLayer, Popup } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import "leaflet-draw/dist/leaflet.draw.css";

import AdditionalContentInMap from "./AdditionalContentInMap";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import { observer } from "mobx-react";
import config from "../config";
import dayjs from "dayjs";
// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
class MapForVehiclePosition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closeModal: false,
      addressInfo: this.props.addressInfo,
      update: 0,
      type: "",
      key: new Date(),
      traffic: false,
      flag: true,
      mapBtn: false,
      satelliteBtn: false,
      trafficBtn: false,
    };
    console.log(this.props.vehicleObj?.listImage);
  }
  handleBtnFunc = (state) => {
    this.setState({ [state]: true });
    setTimeout(() => {
      this.setState({ [state]: false });
    }, 1000);
  };
  componentDidUpdate() {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    });
  }
  // rotateIcon = (iconUrl, rotation) => {
  //   // console.log(rotation);
  //   const icon = L.divIcon({
  //     iconAnchor: [0, 0],
  //     iconSize: [0, 0],
  //     // iconAnchor: [10, 20], // Center of the icon
  //     html: `<img src=${require(`../assets/images/livetracking/${iconUrl}`)} style="transform: rotate(${rotation}deg) ;  width: 20px; height: 35px;">`,
  //   });

  //   return icon;
  // };

  rotateIcon = (iconUrl, rotation) => {
    const safeIconUrl = this.getImageUrl(iconUrl);
    const icon = L.divIcon({
      iconAnchor: [0, 0],
      iconSize: [0, 0],
      html: `<img src=${safeIconUrl} style="transform: rotate(${rotation}deg); width: 20px; height: 35px;">`,
    });

    return icon;
  };

  getImageUrl = (imageName) => {
    try {
      return require(`../assets/images/livetracking/${imageName}`);
    } catch (e) {
      return require("../assets/images/logo_icon.png");
    }
  };
  render() {
    return (
      <div>
        <Modal
          className="multiTracking-popup"
          title=<div>
            {this.props?.heading}
            <CloseOutlined onClick={() => this.props.closeMapModal(false)} />
          </div>
          centered
          maskClosable={false}
          bodyStyle={{
            height: "60vh",
            //width: "900px",
          }} // Adjust the dimensions as needed
          width={1000}
          open={this.state.closeModal ? false : this.props.vehicleObj}
          onCancel={() => this.setState({ closeModal: true })}
          closable={false}
          footer={null}
        >
          <div
            dir={this.context.locale.language === "arabic" ? "ltr" : "ltr"}
            className={
              this.context.locale.language === "arabic"
                ? "arabic-map-container"
                : ""
            }
          >
            {this.props.vehicleObj?.latitude &&
            this.props.vehicleObj?.longitude ? (
              <>
                <MapContainer
                  ref={this.mapRef}
                  zoom={this.props.zoom ? this.props.zoom : 17}
                  minZoom={3}
                  center={[
                    this.props.vehicleObj?.latitude,
                    this.props.vehicleObj?.longitude,
                  ]}
                  style={{
                    position: "relative",
                    height: "60vh",
                  }}
                >
                  <ReactLeafletGoogleLayer />
                  {this.props.vehicleObj?.listImage ? (
                    <Marker
                      position={[
                        this.props.vehicleObj?.latitude,
                        this.props.vehicleObj?.longitude,
                      ]}
                      icon={
                        this.props.rotateDeg || this.props.vehicleObj?.direction
                          ? this.rotateIcon(
                              this.props.vehicleObj.listImage,
                              this.props.vehicleObj.direction
                            )
                          : L.icon({
                              iconUrl: this.getImageUrl(
                                this.props.vehicleObj?.listImage
                              ),
                              iconSize: [15, 20],
                            })
                      }
                    >
                      <Tooltip permanent direction="top" offset={[1, -10]}>
                        <b>{this.props.vehicleObj?.vehicleNo}</b>
                      </Tooltip>
                      {this.props.showTooltip ? (
                        <Popup direction="top">
                          <b>{this.context.translate("_VEHICLE_NO_")} : </b>
                          {this.props.vehicleObj?.vehicleNo}
                          <br />
                          <b>{this.context.translate("_LOCATION_")} :</b>{" "}
                          {this.props.vehicleObj?.formatted_address}
                          <br />
                          <b>{this.context.translate("_SPEED_")} : </b>
                          {this.props.vehicleObj?.speed}Kmh
                          <br />
                          <b>Driver :</b>{" "}
                          {this.props.vehicleObj?.name
                            ? this.props.vehicleObj?.name
                            : ""}
                          <br />
                          <b>Mobile No : </b>
                          {this.props.vehicleObj?.driverphone
                            ? this.props.vehicleObj?.driverphone
                            : ""}
                          <br />
                          <b>Last Reported Date :</b>{" "}
                          {dayjs(this.props.vehicleObj?.updatedate).format(
                            "DD-MM-YYYY hh:mm:ss A"
                          )}
                        </Popup>
                      ) : (
                        ""
                      )}
                    </Marker>
                  ) : (
                    <Marker
                      position={[
                        this.props.vehicleObj?.latitude,
                        this.props.vehicleObj?.longitude,
                      ]}
                      icon={L.icon({
                        iconUrl: this.getImageUrl(
                          this.props.vehicleObj?.listImage
                        ),
                        iconSize: [28, 38],
                      })}
                      // icon={L.icon({
                      //   iconUrl: this.props.icon,

                      //   iconSize: [28, 38],
                      // })}
                    >
                      {this.props.vehicleObj?.vehicleNo ? (
                        <Tooltip permanent direction="top" offset={[1, -10]}>
                          <b>{this.props.vehicleObj?.vehicleNo}</b>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </Marker>
                  )}

                  {this.state.type || this.state.traffic ? (
                    <ReactLeafletGoogleLayer
                      key={this.state.type ? this.state.type : ""}
                      type={this.state.type ? this.state.type : "roadmap"}
                      googleMapsAddLayers={
                        this.state.traffic ? [{ name: "TrafficLayer" }] : null
                      }
                    />
                  ) : (
                    <ReactLeafletGoogleLayer
                      googleMapsAddLayers={
                        this.state.traffic ? [{ name: "TrafficLayer" }] : null
                      }
                    />
                  )}

                  <AdditionalContentInMap This={this} size="small" />
                </MapContainer>
              </>
            ) : (
              <MapContainer
                ref={this.mapRef}
                zoom={17}
                minZoom={3}
                center={config.mapCenter}
                style={{
                  position: "relative",
                  height: "60vh",
                }}
                zoomControl={false}
              >
                <ReactLeafletGoogleLayer />
              </MapContainer>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}
MapForVehiclePosition.contextType = StoreContext;
export default observer(withRouter(MapForVehiclePosition));
