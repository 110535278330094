import React from "react";
import {
  Form,
  Select,
  Table,
  Space,
  Row,
  Col,
  message,
  Button,
  Modal,
  Tooltip,
  Checkbox,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import { DeleteFilled, EditFilled, PlusOutlined } from "@ant-design/icons";
import config from "../config";
import InventoryStore from "../store/InventoryStore";
import withRouter from "../withRouter";
import DeleteConfirm from "./DeleteConfirm";
import CompanyAutocomplete from "./CompanyAutocomplete";
import AddButton from "./AddButton";
import WareHouseStore from "../store/WareHouseStore";
import ListTable from "./ListTable";
import CompanyStore from "../store/CompanyStore";
import InventoryAdd from "./InventoryAdd";
import { QuestionCircleOutlined } from "@ant-design/icons";
let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 250;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 150;
    } else if (val.title?.length > 8) {
      val.width = 125;
    } else {
      val.width = 110;
    }
    // console.log(val.title.length)
  });

  return arr;
};

class InventoryList extends React.Component {
  constructor(props,context) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.inventoryStore = new InventoryStore();
    this.wareHouseStore = new WareHouseStore();
    this.companyStore = new CompanyStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      loadList: 0,
      warehouseList: [],
      warehouseId: "",
      companyVal_Lab: "",
      waslModal: false,
      inventoryName: "",
      inventoryId: "",
      openInventoryForm: false,
      listDataUpdate: false,
      modalVisible: false,

      selectedColumns: [
        {
          // title: "Account",
          title:context.translate("_FLEET_DASHBORD_NEWPOI_CUSTOMER_"),
          dataIndex: "company",
          key:"company",
          width: "15%",
          render: (value, record) => {
            return (
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  this.onEdit(record);
                }}
              >
                {value}
              </Link>
            );
          },
        },
        {
          // title: "Device",
          title:context.translate("_FLEET_GROUP_GROUPLIST_DEVICENAME_"),
          dataIndex: "device",
          key:"device",
          width: "15%",
        },
        {
          // title: "Warehouse",
          title:context.translate("_FLEET_DASHBORD_NEWPOI_TYPEDDL_WAREHOUSE_"),
          dataIndex: "warehouse",
          key:"warehouse",
          width: "15%",
        },
        {
          // title: "Inventory",
          title:context.translate("_INVENTORY_"),
          dataIndex: "inventory",
          key:"inventory",
          width: "15%",
        },
        {
          // title: "Inventory Number",
          title:context.translate("_INVENTORY_NUMBER_"),
          dataIndex: "inventoryNo",
          key:"inventoryNo",
          width: "15%",
        },
        {
          // title: "Storing Category",
          title:context.translate("_STORING_CATEGORY_"),
          dataIndex: "storingCategory",
          key:"storingCategory",
          width: "15%",
        },

        {
          // title: "Storing Type",
          title:context.translate("_STORING_TYPE_"),
          dataIndex: "storingType",
          key: "storingType",
          width: "15%",
        },
        {
          // title: "WASL Reply",
          title:context.translate("_WAREHOUSE_WASL_REPLY_"),
          dataIndex: "waslReply",
          key: "waslReply",
          width: "15%",
        },
        {
          // title: "WASL Response",
          title:context.translate("_WAREHOUSE_WASL_RESPONSE_"),
          dataIndex: "waslresponse",
          key: "waslresponse",
          width: "15%",
        },

        {
          // title: "Action",
          title:context.translate("_ACTION_"),
          dataIndex: "action",
          key:"action",
          width: "15%",
          render: (_, record) => {
            // console.log(record)
            return (
              <>
                <Space>
                  {/* <Link
                    onClick={(e) => {
                      e.preventDefault();
                      this.onEdit(record);
                    }}
                  >
                    <Tooltip
                      // title="Edit Record"
                      title={this.context.translate("_EDIT_RECORD_")}
                      placement="bottom"
                      color={config.tooltipColorEdit}
                    >
                      <EditFilled />
                    </Tooltip>
                  </Link> */}
                  <DeleteConfirm
                    onConfirm={(e) => {
                      e.preventDefault();
                      this.onDelete(record);
                    }}
                  >
                    <Link>
                      <Tooltip
                        // title="Delete Record"
                        title={this.context.translate("_DELETE_RECORD_")}
                        placement="bottom"
                        color={config.tooltipColorDelete}
                      >
                        <DeleteFilled style={{ color: config.deleteColor }} />
                      </Tooltip>
                    </Link>
                  </DeleteConfirm>
                  <Link
                    onClick={async (e) => {
                      this.setState({ waslModal: true });
                      //  await this.getwarehouseId(record);
                      this.setState({ inventoryName: record.inventory });
                      console.log(record.inventory);
                      this.setState({ inventoryId: record.id });
                      console.log(record.id);
                    }}
                  >
                    <Tooltip
                      // title="Register in WASL"
                      title={this.context.translate("_REGISTER_IN_WASL_")}
                      placement="bottom"
                      color={config.tooltipColorEdit}
                    >
                      <PlusOutlined />
                    </Tooltip>
                  </Link>
                </Space>
              </>
            );
          },
        },
      ],
    };

    this.newColumns =  widthAdderFunc([
      ...this.state.selectedColumns,
      {
        // title: "Temperature",
        title:context.translate("_FLEET_REPORTS_TEMPREPORT_TEMPERATURE_"),
        dataIndex: "temperature",
        key: "temperature",
      },
      {
        // title: "Humidity",
        title:context.translate("_HUMIDITY_"),
        dataIndex: "humidity",
        key: "humidity",
      },
      {
        // title: "Sensor Id",
        title:context.translate("_SENSOR_ID_"),
        dataIndex: "sensorId",
        key: "sensorId",
      },
    ])
  }

  onEdit = async (record) => {
    console.log(record.id);
    const Id = record.id;

    if (Id) {
      await this.context.viewStateStore.setInventoryStates(
        this.state.companyId,
        this.state.warehouseId
      );
      await this.setState({ inventoryId: Id });
      await this.setState({ openInventoryForm: true });
      // await this.props.navigate("/inventoryadd/" + Id);
    }
  };

  onDelete = async (record) => {
    console.log(record);
    const response = await this.inventoryStore.deleteRecord({
      inventoryId: record.id,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  };

  async componentDidMount() {
    console.log(this.context.viewStateStore.value, "vale");
    await this.setState({ column: this.state.selectedColumns });
    // await this.inventoryStore.loadData({ offset: 0, limit: 50 });
    this.setState({ pageSize: 50 });
  }

  regiterWarehouse = async (inventoryId) => {
    console.log(inventoryId);
    const data = {
      inventoryId: inventoryId,
    };
    console.log(data);
    const response = await this.inventoryStore.addInventory(data);
    if (response?.success === "S") {
      message.success(response.message);
      this.props.navigate("/inventorylist");
    } else {
      message.error("Warehouse Not Registered!");
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    // if (
    //   this.state.openFuelFilledForm &&
    //   prevState.openFuelFilledForm !== this.state.openFuelFilledForm
    // ) {
    //   console.log("ilocasd");
    //   setTimeout(() => {
    //     window.dispatchEvent(new Event("resize"));
    //   }, 10);
    // }

    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const setInventoryStates = this.context.viewStateStore.inventoryObject;
      if (setInventoryStates.companyId !== "") {
        console.log(setInventoryStates);
        await this.inventoryStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: setInventoryStates.companyId,
          warehouseId: setInventoryStates.warehouseId,
        });
        const data = await this.inventoryStore.getWarehouselist({
          companyId: setInventoryStates.companyId,
        });

        await this.setState({ warehouseId: setInventoryStates.warehouseId });
        const singleData = await this.companyStore.getCompanyDataById(
          setInventoryStates.companyId
        );

        await this.setState({ loadList: 1 });
        console.log("singleData", singleData);
        await this.setState({
          companyVal_Lab: singleData,
          companyId: setInventoryStates.companyId,
        });
        console.log("singleData", singleData);
      }

      await this.setState({ listDataUpdate: false });
    }
  }
  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination, "pagi");
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }
  async handleChange(comp) {
    await this.setState({ companyId: comp.value, warehouseId: "" });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    const data = await this.inventoryStore.getWarehouselist({
      companyId: comp.value,
    });
    await this.setState({ companyVal_Lab: singleData, companyId: comp.value });

    await this.handleSearch();

    console.log("getdata", data);
    await this.setState({ loadList: 1 });
  }

  async handleSearch() {
    await this.inventoryStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      warehouseId: this.state.warehouseId,
      companyId: this.state.companyId,
    });
    console.log(this.state.warehouseId);
    await this.setState({ loadList: 1 });
  }

  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.success == "S") {
      message.success(resp.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openInventoryForm: false });

      //  await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    } else {
      message.error("Inventory no Already Exist");
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  closeModal=()=>{
    this.setState({ openInventoryForm: false, inventoryId: "" });
  }

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_INVENTORY_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  title={this.context.translate("_ADD_")}
                  link="/inventoryadd"
                /> */}
                <Tooltip
                  placement="left"
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({
                        openInventoryForm: true,
                        inventoryId: "",
                      })
                    }
                  ></Button>
                </Tooltip>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={(value) => this.handleChange(value)}
                  // allowClear={true}
                  style={{ width: 200 }}
                  value={
                    this.state.companyVal_Lab ? this.state.companyVal_Lab : null
                  }
                />
                <Select
                  // allowClear
                  style={{ width: 200 }}
                  value={this.state.warehouseId ? this.state.warehouseId : null}
                  onSelect={async (id) => {
                    await this.setState({ warehouseId: id });

                    await this.handleSearch();
                    this.setState({ loadList: 1 });

                    console.log(id);
                  }}
                  // placeholder="Warehouse"
                  placeholder={this.context.translate("_NAME_GRID_OF_WAREHOUSE_")}
                  fieldNames={{ value: "id", label: "warehouse" }}
                  optionFilterProp="children"
                  options={this.inventoryStore?.warehouseList}
                />
              </Space>
            </Col>
            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            current={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.inventoryStore?.total}
            columns={this.state.selectedColumns}
            dataSource={this.inventoryStore?.inventory}
            loading={this.inventoryStore?.loading}
            onChange={this.handleTableChange}
          />

          <Modal
            // title="Confirm"
            title={
              <span>
                <QuestionCircleOutlined style={{ color: "red" }} />
                &nbsp;
                {/* Confirm */}
                {this.context.translate("_CONFIRM_")}
              </span>
            }
            open={this.state.waslModal}
            onCancel={() => this.setState({ waslModal: false })}
            width={400}
            footer={[
              <div style={{ padding: 10 }}>
                <Button
                  // key="yes"
                  onClick={(e) => {
                    this.regiterWarehouse(this.state.inventoryId);
                    console.log(this.state.inventoryId);
                    this.setState({ waslModal: false });
                  }}
                  type="primary"
                >
                  {/* Yes */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_REMOVEYES_")}
                </Button>
                <Button
                  // key="no"
                  onClick={() => this.setState({ waslModal: false })}
                >
                  {/* No */}
                  {this.context.translate("_FLEET_RULELIST_CRONRULE_NO_")}
                </Button>
              </div>,
            ]}
          >
            <p style={{ margin: "0px 20px" }}>
              {/* Do you want to Register {this.state.inventoryName} in WASL ? */}
              {this.context.translate("_DOYOU_WANT_TO_REGISTER_")}{" "}{this.state.inventoryName} {this.context.translate("_IN_WASL_")}
            </p>
          </Modal>

          {this.state.openInventoryForm && (
            <Modal
              bodyStyle={{ height: "637px" }}
              className="formShowModal" //  {/* css class */}
              // title="Add Inventory"
              title={this.context.translate("_ADD_INVENTORY_")}
              centered
              visible={this.state.openInventoryForm}
              footer={null}
              onCancel={() => {
                this.setState({ openInventoryForm: false, inventoryId: "" });
              }}
              width={665}
            >
              <div>
                <InventoryAdd
                  inventoryId={this.state.inventoryId}
                  response={this.responseMsg}
                  closeModal={this.closeModal}
                />
              </div>
            </Modal>
          )}

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>
        </div>
      </>
    );
  }
}
InventoryList.contextType = StoreContext;
export default observer(withRouter(InventoryList));
