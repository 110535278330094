import React, { Component } from "react";

import {
  Form,
  Space,
  Button,
  Input,
  DatePicker,
  Modal,
  Checkbox,
  message,
  Row,
  Col,
  Spin,
} from "antd";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import CompanyAutocomplete from "./CompanyAutocomplete";
import config from "../config";
import VehicleTrailerStore from "../store/VehicleTrailerStore";
import CompanyStore from "../store/CompanyStore";

class VehicleTrailerAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      editFormShow: true,
      Id: "",
      tId: -1,
      loadingSpin: false,
    };

    this.state = { dateFormatList: ["DD/MM/YYYY"] };
    this.vehicleTrailerStore = new VehicleTrailerStore();
    this.companyStore = new CompanyStore();
  }

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      this.context.viewStateStore.setVehicleTrailerStates({ companyId: this.props.getUserData().userCompanyId });
      await this.setState({ companyId: this.props.getUserData().userCompanyId })
      console.log(this.state.companyId)
      // await this.setState({userId:this.props.getUserData().userId})
      await this.setState({ isadmin: this.props.getUserData().isadmin })
    }
    await this.setState({ loadList: 1 });
    const tId = this.props.trailerId;

    if (tId) {
      this.filldata(tId);
      this.setState({ tId: tId });
    }
  }

  async filldata(tId) {

    console.log(tId);
    // await this.setState({ loadingSpin: true });
    const getTrailerDetails = await this.vehicleTrailerStore.getTrailerDetails(
      tId
    );
    console.log("getTrailerDetails", getTrailerDetails);

    await this.setState({
      companyId: getTrailerDetails.companyId,
      tId: getTrailerDetails.tId,
    });
    const singleData = await this.companyStore.getCompanyDataById(
      getTrailerDetails.companyId
    );

    this.formRef.current.setFieldsValue({
      tId: getTrailerDetails.tId,
      companyId: singleData,
      trailerType: getTrailerDetails.trailerType,
      trailerId: getTrailerDetails.trailerId,
      trailerEmptyWeight: getTrailerDetails.trailerEmptyWeight,
    });
    // await this.setState({ loadingSpin: false });
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  onTrailerDetailSubmit = async (values) => {
    this.setState({ loading: true });
    console.log(values);
    const data = {
      ...values,
      tId: this.state.tId,
      companyId: this.props.trailerId ? "" : this.state.companyId,
    };
    console.log("Data", data);
    const resp = await this.vehicleTrailerStore.addData(data);
    await this.props.response(resp);
    // if (resp.data.success == "S") {
    //    message.success(resp.data.message);
    //   this.props.navigate("/vehicletrailerlist");
    // } else if (resp.data.success == "F0") {
    //   resp.data.errors.map((value) => {
    //     message.error(value["username"]);
    //   });
    // }
    this.setState({ loading: false });
  };

  async handleChange(comp) {
    // console.log(comp.value);
    console.log(comp.value);
    console.log(this.props.trailerId, "EDITRRRRR");
    await this.setState({ companyId: comp.value });
    if (!this.props.trailerId) {
      this.context.viewStateStore.setVehicleTrailerStates({
        companyId: comp.value,
      });
    }
    await this.setState({ companyId: comp.value });
    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });
    await this.handleSearch();
  }

  async handleSearch() {
    await this.vehicleTrailerStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  validateFieldsValue = (value, field) => {
    const value1 = value.replace(/[^0-9.,]+/g, "");
    console.log(value1);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  closeModal = () => {
    this.props.closeModal()
  }

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          {/* <Spin spinning={this.state.loadingSpin} fullscreen> */}
          <Form
            style={{ maxWidth: "100%", padding: 0 }}
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onTrailerDetailSubmit}
            className="formClass" //change
          // {...this.layout}
          // name="nest-message"
          >
            {/* <div className="headingTitle">Add Trailer</div> */}
            <Row className="overflowFormInputs">
              {/* css class */}
              <Col span={12} className="formColumnClass">
                <div style={{ display: this.props.trailerId ? "none" : "" }}>
                  {(this.props.getUserData().isadmin === -1 || this.props.getUserData().isadmin === 3) &&
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => this.handleChange(value)}
                      // style={{ width: 200 }}
                      />
                    </Form.Item>
                  }
                </div>

                <Form.Item
                  name="trailerId"
                  label={this.context.translate("_TRAILER_ID_")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} className="formColumnClass">
                <Form.Item
                  name="trailerType"
                  label={this.context.translate("_TRAILER_TYPE_")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="trailerEmptyWeight"
                  label={this.context.translate("_TRAILER_EMPTY_WEIGHT_")}
                  rules={[{ required: true }]}
                >
                  <Input
                    onChange={(e) =>
                      this.validateFieldsValue(
                        e.target.value,
                        "trailerEmptyWeight"
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <div className="formBtnGroup">
              {/* <Link to={"/vehicletrailerlist"}>
                  {" "}
                  <Button ghost type="primary">
                    {" "}
                    Back{" "}
                  </Button>
                </Link> */}
              <Button
                className="formCancelButton"
                type="primary"
                onClick={(e) => {
                  this.closeModal()
                  // this.formRef.current?.resetFields();
                }}
              >
                {/* Cancel */}
                {this.context.translate("_FLEET_CLOSE_BTN_")}
              </Button>
              <Button
                type="primary"
                className="formSaveButton"
                htmlType="submit"
                loading={this.state.loading}
              >
                {/* Save */}
                {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
              </Button>
            </div>
          </Form>
          {/* </Spin> */}
        </div>
      </div>
    );
  }
}

VehicleTrailerAdd.contextType = StoreContext;
export default observer(withRouter(VehicleTrailerAdd));
