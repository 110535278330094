import React, { Component } from "react";
import { Button, Col, Form, Input, Row, Select, Spin, Upload } from "antd";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";

import CompanyAutocomplete from "./CompanyAutocomplete";
import config from "../config";

import CompanyStore from "../store/CompanyStore";
import { UploadOutlined } from "@ant-design/icons";
import AudioMeassage from "../store/AudiomessageStore";

class AudioMeassageAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = { loadingSpin: false, companyId: "", id: -1, photo: "",folderName:'' };

    this.audiomsgStore = new AudioMeassage(this);
    this.companyStore = new CompanyStore(this);
  }
  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      this.context.viewStateStore.setAudioMsgStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
    }

    const id = this.props.id;

    if (id) {
      await this.filldata(id);
    }

    this.setState({ update: 1 });
  }

  async filldata(id) {
    const audiomsgData = await this.audiomsgStore.getAudioMesgData({
      id,
      companyId: this.props.companyId,
    });

    await this.setState({
      companyId: audiomsgData.companyId,
      id: audiomsgData.id,
    });
    const singleData = await this.companyStore.getCompanyDataById(
      audiomsgData.companyId
    );

    this.formRef.current.setFieldsValue({
      companyId: singleData,
      id: audiomsgData.id,
      description: audiomsgData.description,
      name: audiomsgData.name,
    });
    this.setState({ folderName: audiomsgData.path });
  }

  handleFormSubmit = async (values) => {
    const data = {
      ...values,
      id: this.state.id,
      companyId: this.state.companyId,
      photo: this.state.photo,
      folderName:this.state.folderName
    };
    const response = await this.audiomsgStore.addData(data);
    await this.props.response(response);
  };
  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    if (!this.props.id) {
      this.context.viewStateStore.setAudioMsgStates(comp.value);
    }
    await this.setState({ companyId: comp.value });
  }

  closeModal = () => {
    this.props.closeModal();
  };

  getUploadData = async (fileUp) => {
    // if (fileUp.fileList[0]?.type == "image/png") {
    let fileinfo = fileUp.file.response;

    if (fileinfo?.success == "S") {
      await this.setState({ photo: fileinfo.data });
      await this.setState({ folderName: fileinfo.folderName });
    }
    // console.log(fileUp.fileList)
    // fileUp.fileList[0]= config.fleetURLAdvertise + fileinfo?.folderName;
    this.audiomsgStore.setPhotoFromDb(fileUp.fileList);
    this.setState({ update: 1 });
    // }
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              name="fileimport"
              layout="vertical"
              onFinish={this.handleFormSubmit}
              style={{ padding: "0px 10px" }}
              ref={this.formRef}
               className="formClass"
            >
              <Row className="overflowFormInputs">
              <Col span={12} className="formColumnClass">
              {(this.props.getUserData().isadmin === -1 ||
                this.props.getUserData().isadmin === 3) && (
                <Form.Item
                  name="companyId"
                  label={this.context.translate("_ACCOUNT_")}
                  rules={[{ required: true }]}
                >
                  <CompanyAutocomplete
                    onChange={(value) => this.handleChange(value)}
                    style={{ width: "100%" }}
                    value={this.state.companyId}
                  />
                </Form.Item>
              )}

              <Form.Item
                name="name"
                label={this.context.translate("_NAME_")}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              </Col>
              <Col span={12} className="formColumnClass">
              <Form.Item
                name="description"
                label={this.context.translate("_DESCRIPTION_")}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="photo"
                label="Upload"
                className="formCheckboxCss"
                getValueFromEvent={this.normFile}
                style={{ marginTop: "16px" }}
                rules={[
                  {
                    required: !this.state.folderName,
                    message: "Please upload audio",
                  },
                ]}
              >
                <Upload
                  name="photo"
                  action={config.baseURL + "/index.php?c=company&a=fileupload"}
                  listType="picture"
                  data={{
                    fileType: "audio",
                    fieldName: "photo",
                    // showType: this.state.showType,
                  }}
                  onChange={this.getUploadData}
                  onRemove={async () => {
                    await this.setState({ photo: "",fileList: [] });
                    await this.audiomsgStore.setPhotoFromDb([]);
                  }}
                  maxCount={1}
                  accept=".mp3"
                  // accept=".jpg,.png"
                  // Add the beforeUpload prop to perform file extension validation

                  {...this.audiomsgStore.photoFromDb}
                >
                  <Button className="imgupload" icon={<UploadOutlined />}>
                    {this.context.translate("_PLEASE_SELECT_AUDIO_")}
                  </Button>
                </Upload>
              </Form.Item>
</Col>
</Row>
              <div className="formBtnGroup">
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  // loading={this.state.loading}
                >
                  {this.context.translate("_UPLOAD_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}
AudioMeassageAdd.contextType = StoreContext;
export default observer(withRouter(AudioMeassageAdd));
