import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi, baseURL } from "../components/Utility";
import { action, get, makeObservable, observable } from "mobx";

class CommandSentReportStore {
  commandSentReport = [];
  total = 0;
  loading = false;
  rootStore;
  // rec = 0;
  constructor(rootStore) {
    makeObservable(this, {
      commandSentReport: observable,
      total: observable,
      loading: observable,
      // rec: observable,
      loadData: action,
      exportExcel: action,
      exportcsv: action,
      exportpdf: action,
      exportxml: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    offset,
    limit,
    companyId,
    fromDate,
    toDate,
    vehicleId,
    pageNo,
  }) {
    this.loading = true;
    console.log("Command Sent Report", vehicleId, pageNo);
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      offset: offset,
      limit: limit,
      page: pageNo,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=commandreport", "postData=" + base64Input);
      this.total = parseInt(response?.data?.data?.TotalCount);
      console.log("command ", response?.data?.data);

    this.commandSentReport = response?.data?.data?.commandreport;
    // if(this.commandSentReport.length >0){
    //   this.rec=1
    // }
  
    this.loading = false;
  }
  async exportExcel(getData) {
    console.log(getData);
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportcommandsentexcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exportpdf(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportcommandsentpdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exportcsv(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportcommandsentcsv&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exportxml(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportcommandsentxml&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
}

export default CommandSentReportStore;
