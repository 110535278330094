import {
  Card,
  Space,
  Row,
  Col,
  Modal,
  Input,
  Button,
  Image,
  Spin,
  Table,
  Select,
} from "antd";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import React from "react";

import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";

import { decode as base64_decode, encode as base64_encode } from "base-64";
import "./AdvanceDasboardStyle.css";
import "../assets/css/global.css";
import "../assets/css/bootstrap.min.css";
import Cookies from "js-cookie";
import AdvanceDashboardStore from "../store/AdvanceDashboardStore";

import { DownloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import MapForVehiclePosition from "./MapForVehiclePosition";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

class AdvanceDashboard extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      MaxSpeedBars: "",
      loading: false,
      displayFleetStatusTable: false,
      statusPowerTable: false,
      insideOutsideTable: false,
      fleetOverspeedTable: false,
      displayOverspeedTable: false,
      loadData: "",
      status: "normal",
      vehicleObj: false,
      searchVehicle: [],
      fleetStatus: "",
      percent: 20,
      powerStatus: "power",

      fleetStatusColumns: [
        {
          title: context.translate("_VEHICLE_NO_"),
          dataIndex: "vehicleNo",
          render: (_, record) => {
            // console.log("recrd", record.listImage);
            return (
              <Space>
                <Image
                  width={30}
                  height={15}
                  src={require("../assets/images/detailtracking/" +
                    record.listImage)}
                />

                {record.vehicleNo}
              </Space>
            );
          },
        },
        {
          title: context.translate("_SPEED_"),
          dataIndex: "speed",
          // width:"10%",
        },
        {
          title: context.translate("_LOCATION_"),
          dataIndex: "formatted_address",
          // width:"10%",
        },
        {
          title: context.translate("_TIME_"),
          dataIndex: "updatedate",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
          // width:"10%",
        },
        {
          title: context.translate("_FLEET_SERVICE_POILIST_MAP_"),
          dataIndex: "",
          render: (value) => {
            return (
              <>
                <Image
                  // onClick={()=>}
                  src={require("../assets/images/map-icon.png")}
                  width={25}
                  onClick={() => {
                    this.setState({ vehicleObj: value });
                  }}
                />
              </>
            );
          },
          // width:"10%",
        },
      ],

      overspeedColumns: [
        {
          title: context.translate("_VEHICLE_NO_"),
          dataIndex: "vehicleNo",
          render: (_, record) => {
            // console.log("recrd", record.listImage);
            return (
              <Space>
                <Image
                  width={30}
                  height={15}
                  src={require("../assets/images/detailtracking/" +
                    record.listImage)}
                />

                {record.vehicleNo}
              </Space>
            );
          },
        },
        {
          title: context.translate("_SPEED_"),
          dataIndex: "speed",
          // width:"10%",
        },
        {
          title: context.translate("_SPEED_LIMIT_"),
          dataIndex: "speedLimit",
          // width:"10%",
        },

        {
          title: context.translate("_LOCATION_"),
          dataIndex: "formatted_address",
          // width:"10%",
        },
        {
          title: context.translate("_TIME_"),
          dataIndex: "updatedate",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
          // width:"10%",
        },
        {
          title: context.translate("_FLEET_SERVICE_POILIST_MAP_"),
          dataIndex: "",
          render: (value) => {
            return (
              <>
                <Image
                  // onClick={()=>}
                  src={require("../assets/images/map-icon.png")}
                  width={25}
                  onClick={() => {
                    this.setState({ vehicleObj: value });
                  }}
                />
              </>
            );
          },
          // width:"10%",
        },
      ],

      AlertColumns: [
        {
          title: context.translate("_VEHICLE_NO_"),
          dataIndex: "vehicleNo",
          render: (_, record) => {
            // console.log("recrd", record.listImage);
            return (
              <Space>
                <Image
                  width={30}
                  height={15}
                  src={require("../assets/images/detailtracking/" +
                    record.listImage)}
                />

                {record.vehicleNo}
              </Space>
            );
          },
        },
        {
          title: context.translate("_SPEED_"),
          dataIndex: "speed",
          // width:"10%",
        },
        {
          title: context.translate("_SPEED_LIMIT_"),
          dataIndex: "speedLimit",
          // width:"10%",
        },
        {
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_NAME_"),
          dataIndex: "rulename",
          // width:"10%",
        },
        {
          title: context.translate("_LOCATION_"),
          dataIndex: "formatted_address",
          // width:"10%",
        },
        {
          title: context.translate("_TIME_"),
          dataIndex: "updatedate",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
          // width:"10%",
        },
        {
          title: context.translate("_FLEET_SERVICE_POILIST_MAP_"),
          dataIndex: "",
          render: (value) => {
            return (
              <>
                <Image
                  // onClick={()=>}
                  src={require("../assets/images/map-icon.png")}
                  width={25}
                  onClick={() => {
                    this.setState({ vehicleObj: value });
                  }}
                />
              </>
            );
          },
          // width:"10%",
        },
      ],

      insideOutsideGeofenceColumns: [
        {
          title: context.translate("_VEHICLE_NO_"),
          dataIndex: "vehicleNo",
          render: (_, record) => {
            // console.log("recrd", record.listImage);
            return (
              <Space>
                <Image
                  width={30}
                  height={15}
                  src={require("../assets/images/detailtracking/" +
                    record.listImage)}
                />

                {record.vehicleNo}
              </Space>
            );
          },
        },
        {
          title: context.translate("_GEOFENCE_NAME_"),
          dataIndex: "speed",
          // width:"10%",
        },

        {
          title: context.translate("_LOCATION_"),
          dataIndex: "formatted_address",
          // width:"10%",
        },
        {
          title: context.translate("_TIME_"),
          dataIndex: "updatedate",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
          // width:"10%",
        },
        {
          title: context.translate("_FLEET_SERVICE_POILIST_MAP_"),
          dataIndex: "",
          render: (value) => {
            return (
              <>
                <Image
                  // onClick={()=>}
                  src={require("../assets/images/map-icon.png")}
                  width={25}
                  onClick={() => {
                    this.setState({ vehicleObj: value });
                  }}
                />
              </>
            );
          },
          // width:"10%",
        },
      ],

      powerColumns: [
        {
          title: context.translate("_VEHICLE_NO_"),
          dataIndex: "vehicleNo",
          render: (_, record) => {
            // console.log("recrd", record.listImage);
            return (
              <Space>
                <Image
                  width={30}
                  height={15}
                  src={require("../assets/images/detailtracking/" +
                    record.listImage)}
                />

                {record.vehicleNo}
              </Space>
            );
          },
        },

        {
          title: context.translate("_LOCATION_"),
          dataIndex: "formatted_address",
          // width:"10%",
        },
        {
          title: context.translate("_TIME_"),
          dataIndex: "updatedate",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
          // width:"10%",
        },
        {
          title: context.translate("_FLEET_SERVICE_POILIST_MAP_"),
          dataIndex: "",
          render: (value) => {
            return (
              <>
                <Image
                  // onClick={()=>}
                  src={require("../assets/images/map-icon.png")}
                  width={25}
                  onClick={() => {
                    this.setState({ vehicleObj: value });
                  }}
                />
              </>
            );
          },
          // width:"10%",
        },
        {
          title: context.translate("_STATUS_"),
          dataIndex: "power",
          render: (value) => {
            if (value === 1) {
              return (
                <div
                  style={{
                    backgroundColor: "red",
                    display: "inline-block",
                    width: 12,
                    height: 10,
                  }}
                ></div>
              );
            }else{
              return (
                <div
                  style={{
                    backgroundColor: "green",
                    display: "inline-block",
                    width: 12,
                    height: 10,
                  }}
                ></div>
              );
            }
          },
          // width:"10%",
        },
      ],
    };

    this.AdvanceDashboardStore = new AdvanceDashboardStore();
  }

  loadFleetStatusData = async () => {
    await this.AdvanceDashboardStore.loadData({
      companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      vehicleStatus: this.state.status,
    });
    this.setState({
      loadData: 1,
      searchVehicle: this.AdvanceDashboardStore.trackData,
    });
  
  };

  loadCardStatusData = async () => {
    await this.AdvanceDashboardStore.getFleetCardsStatusData({
      companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      fleetStatus: this.state.fleetStatus,
    });

    this.setState({
      loadData: 1,
      searchVehicle: this.AdvanceDashboardStore.fleetStatusArr,
    });
   
  };

  loadPowerStatusData = async () => {
    await this.AdvanceDashboardStore.getFleetCardsStatusData({
      companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      fleetStatus: this.state.powerStatus,
    });

    this.setState({
      loadData: 1,
      searchVehicle: this.AdvanceDashboardStore.fleetStatusArr,
    });
  };

  async componentDidUpdate(prevProps, prevState) {
    // When the modal is visible, trigger a map refresh to ensure it renders properly
    // await this.liveLatLongGetting()
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    });
    if (this.state.vehicleObj !== prevState.vehicleObj) {
    
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }
  }

  async componentDidMount() {
    var CompanyId = JSON.parse(
      base64_decode(Cookies.get("data"))
    ).userCompanyId;
    var userId = JSON.parse(base64_decode(Cookies.get("data"))).userId;
    var isadmin = JSON.parse(base64_decode(Cookies.get("data"))).isadmin;

    // await this.AdvanceDashboardStore.loadData({
    //   companyId: CompanyId,
    //   userId: userId,
    //   isadmin: isadmin,
    // });

    await this.AdvanceDashboardStore.getAlertData({
      companyId: CompanyId,
      userId: userId,
      isadmin: isadmin,
    });

    await this.AdvanceDashboardStore.getFleetIdleData({
      companyId: CompanyId,
      userId: userId,
      isadmin: isadmin,
    });

    await this.AdvanceDashboardStore.getJobInfoData({
      companyId: CompanyId,
      userId: userId,
      isadmin: isadmin,
    });

    // console.log(this.AdvanceDashboardStore?.jobState?.TotalPendingJob);

    await this.AdvanceDashboardStore.getWorkingHoursData({
      companyId: CompanyId,
      userId: userId,
      isadmin: isadmin,
    });

    await this.AdvanceDashboardStore.getMaintenanceRemiderData({
      companyId: CompanyId,
      userId: userId,
      isadmin: isadmin,
    });

    await this.AdvanceDashboardStore.getRenewalRemiderData({
      companyId: CompanyId,
      userId: userId,
      isadmin: isadmin,
    });

    await this.AdvanceDashboardStore.getDistClsfctnData({
      companyId: CompanyId,
      userId: userId,
      isadmin: isadmin,
    });

    await this.AdvanceDashboardStore.getMaxSpeedData({
      companyId: CompanyId,
      isadmin: isadmin,
    });

    await this.AdvanceDashboardStore.getVehScoreData({
      companyId: CompanyId,
      userId: userId,
      isadmin: isadmin,
    });

    await this.AdvanceDashboardStore.getFleetStatusData({
      companyId: CompanyId,
      userId: userId,
      isadmin: isadmin,
    });

    if (this.context.locale.language == "arabic") {
      document.body.classList.add("arabic-language-wrapper");
    } else {
      document.body.classList.remove("arabic-language-wrapper");
    }

  }

  handleSearchChange(query) {
    return this.AdvanceDashboardStore.trackData?.filter((vehicle) => {
      const normalizedQuery = query.toLowerCase();
      return (
        vehicle.vehicleNo &&
        vehicle.vehicleNo.toLowerCase().includes(normalizedQuery)
      );
    });
  }

  handleCardSearchChange(query) {
    return this.AdvanceDashboardStore.fleetStatusArr?.filter((vehicle) => {
      const normalizedQuery = query.toLowerCase();
      return (
        vehicle.vehicleNo &&
        vehicle.vehicleNo.toLowerCase().includes(normalizedQuery)
      );
    });
  }

  openModal = (title) => {
 
    this.setState({
      fleetOverspeedTable: true,
      modalTitle: title,
    });
  };

  openTitleModal = (title) => {

    this.setState({
      insideOutsideTable: true,
      InOutTitle: title,
    });
  };
  render() {
    const data = [
      {
        distanceTravel: 84.6,
        startTime: "2023-05-29 00:45:46",
        vehicleNo: "JSB-3158",
        vehicleId: "18749",
        date: "2023-05-29",
      },
      {
        distanceTravel: 80.3,
        startTime: "2023-05-29 06:07:50",
        vehicleNo: "ASB-1350",
        vehicleId: "18591",
        date: "2023-05-29",
      },
      {
        distanceTravel: 78.3,
        startTime: "2023-05-29 00:29:39",
        vehicleNo: "ASB-1302",
        vehicleId: "18615",
        date: "2023-05-29",
      },
      {
        distanceTravel: 75.53,
        startTime: "2023-05-29 05:46:34",
        vehicleNo: "ASB-1329",
        vehicleId: "18627",
        date: "2023-05-29",
      },
      {
        distanceTravel: 74.3,
        startTime: "2023-05-29 06:20:31",
        vehicleNo: "ASB-1330",
        vehicleId: "18611",
        date: "2023-05-29",
      },
      {
        distanceTravel: 72.78,
        startTime: "2023-05-29 04:51:50",
        vehicleNo: "ASB-1289",
        vehicleId: "18641",
        date: "2023-05-29",
      },
    ];
    const config = {
      data,
      xField: "vehicleNo",
      yField: "distanceTravel",
    };

    const customLegendPayload = [
      {
        // value: "Temp Sensor1",
        value: "Account",
        color: "#66c2a5",
        
      },
      {
        // value: "Temp Sensor1",
        value: "Breakecount",
        color: "#fc8d62",
        dataKey: "showTemp2Legend",
      },
      {
        // value: "Temp Sensor1",
        value: "Exidlecount",
        color: "#8da0cb",
        dataKey: "showTemp3Legend",
      },
      {
        // value: "Temp Sensor1",
        value: "Rpmcount",
        color: "#e78ac3",
        dataKey: "showTemp4Legend",
      },
      {
        // value: "Temp Sensor1",
        value: "Speedcount",
        color: "#a6d854",
        dataKey: "showTemp4Legend",
      },
    ];

    return (
      <>
        <Spin spinning={this.AdvanceDashboardStore.loading} fullscreen>
          <div style={{ height: "100vh", overflowY: "scroll" }}>
            <div
              style={{
                position: "absolute",
                zIndex: 1031,
                left: "50%",
                right: "50%",
                top: "50%",
                bottom: "50%",
                color: "white",
                background: "#666666",
                opacity: 0.8,
              }}
            ></div>

            <div className="dash" loading={this.state.loading}>
              <Row>
                <Col span={8} className="leftPanel">
                  <Card
                    className="fleetstatus"
                    title={this.context.translate("_FLEET_STATUS_")}
                    bordered={false}
                    style={{
                      height: 625,
                    }}
                  >
                    <div
                      className="total"
                      onClick={async () => {
                        await this.setState({
                          status: "normal",
                          displayFleetStatusTable: true,
                        });
                        await this.loadFleetStatusData();
                      }}
                    >
                      <div className="fleetstatus-progress-head">
                        <span>{this.context.translate("_TOTAL_")}</span>
                        <span>
                          {
                            this.AdvanceDashboardStore?.totalvehstate
                              ?.TotalVehCount
                          }
                        </span>
                      </div>
                      <div className="progress1">
                        <div
                          className="divprogress-bar1 progress-moved"
                          id="test2"
                        >
                          <div className="divprogress-bar1" id="test1"></div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="running"
                      onClick={async () => {
                        await this.setState({
                          status: "Running",
                          displayFleetStatusTable: true,
                        });
                        await this.loadFleetStatusData();
                      }}
                    >
                      <div className="fleetstatus-progress-head">
                        <span>{this.context.translate("_RUNNING_")}</span>
                        <span>
                          {this.AdvanceDashboardStore?.totalvehstate?.Moving}
                        </span>
                      </div>
                      <div className="progress2">
                        <div className="divprogress-bar2 progress-moved">
                          <div className="divprogress-bar2"></div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="stopped"
                      onClick={async () => {
                        await this.setState({
                          status: "Stop",
                          displayFleetStatusTable: true,
                        });
                        await this.loadFleetStatusData();
                      }}
                    >
                      <div className="fleetstatus-progress-head">
                        <span>{this.context.translate("_STOPPED_")}</span>
                        <span>
                          {this.AdvanceDashboardStore?.totalvehstate?.Stopped}
                        </span>
                      </div>
                      <div className="progress3">
                        <div className="divprogress-bar3 progress-moved">
                          <div className="divprogress-bar3"></div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="idle"
                      onClick={async () => {
                        await this.setState({
                          status: "Idle",
                          displayFleetStatusTable: true,
                        });
                        await this.loadFleetStatusData();
                      }}
                    >
                      <div className="fleetstatus-progress-head">
                        <span>{this.context.translate("_IDLE_")}</span>
                        <span>
                          {this.AdvanceDashboardStore?.totalvehstate?.Idle}
                        </span>
                      </div>
                      <div className="progress4">
                        <div className="divprogress-bar4 progress-moved">
                          <div className="divprogress-bar4"></div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="inactive"
                      onClick={async () => {
                        await this.setState({
                          status: "NoData",
                          displayFleetStatusTable: true,
                        });
                        await this.loadFleetStatusData();
                      }}
                    >
                      <div className="fleetstatus-progress-head">
                        <span>{this.context.translate("_NO_DATA_")}</span>
                        <span>
                          {this.AdvanceDashboardStore?.totalvehstate?.NoData}
                        </span>
                      </div>
                      <div className="progress5">
                        <div className="divprogress-bar5 progress-moved">
                          <div className="divprogress-bar5"></div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="nodata"
                      onClick={async () => {
                        // await this.setState({
                        //   status: "NoData",
                        //   displayFleetStatusTable: true,
                        // });
                        // await this.loadFleetStatusData();
                      }}
                    >
                      <div className="fleetstatus-progress-head">
                        <span>{this.context.translate("_IN_ACTIVE_")}</span>
                        <span>
                          {this.AdvanceDashboardStore?.totalvehstate?.expired}
                        </span>
                      </div>
                      <div className="progress6">
                        <div className="divprogress-bar6 progress-moved">
                          <div className="divprogress-bar6"></div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>

                <Col span={16}>
                  <Row>
                    <div className="alerts">
                      <div
                        className="overspeed"
                        onClick={async () => {
                          await this.setState({
                            fleetStatus: "overspeed",
                            displayOverspeedTable: true,
                          });
                          await this.loadCardStatusData();
                        }}
                      >
                        <div className="card">
                          {/* <Image src={require("../assets/images/highspeed-1@2x.png")} /> */}
                          <img
                            src={require("../assets/images/highspeed-1@2x.png")}
                            className="card-img"
                            alt="..."
                          ></img>
                          <div className="card-img-overlay">
                            <div className="card-leftdata">
                              <h5 className="card-title">
                                {this.context.translate("_OVERSPEED_")}
                              </h5>
                            </div>
                            <div className="card-rightdata">
                              <div>
                                {" "}
                                <img
                                  src={require("../assets/images/opt10.png")}
                                  className="card-img"
                                  alt="..."
                                ></img>
                              </div>
                              <h2>
                                {
                                  this.AdvanceDashboardStore?.counts
                                    ?.speedViolation
                                }
                                <span>
                                  {this.context.translate("_ALERTS_")}
                                </span>
                              </h2>
                              <p className="card-text">
                                <small>
                                  {
                                    this.AdvanceDashboardStore?.vehPer
                                      ?.speedViolation
                                  }
                                  % {this.context.translate("_VEHICLE_")}
                                </small>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="insidegeofence"
                        onClick={async () => {
                          await this.setState({
                            fleetStatus: "insideGeo",
                            insideOutsideTable: true,
                          });
                          await this.loadCardStatusData();
                          await this.openTitleModal(
                            this.context.translate("_INSIDE_GEOFENCE_")
                          );
                        }}
                      >
                        <div className="card">
                          <img
                            src={require("../assets/images/insidemap-1@2x.png")}
                            className="card-img"
                            alt="..."
                          ></img>
                          <div className="card-img-overlay">
                            <div className="card-leftdata">
                              <h5 className="card-title">
                                {this.context.translate("_INSIDE_GEOFENCE_")}
                              </h5>
                            </div>
                            <div className="card-rightdata">
                              <div>
                                <img
                                  src={require("../assets/images/opt10.png")}
                                  className="card-img"
                                  alt="..."
                                ></img>{" "}
                              </div>
                              <h2>
                                {this.AdvanceDashboardStore?.counts?.insideGeo}
                                <span>
                                  {this.context.translate("_ALERTS_")}
                                </span>
                              </h2>
                              <p className="card-text">
                                <small>
                                  {
                                    this.AdvanceDashboardStore?.vehPer
                                      ?.insideGeo
                                  }
                                  % {this.context.translate("_VEHICLE_")}
                                </small>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="outsidegeofence"
                        onClick={async () => {
                          await this.setState({
                            fleetStatus: "outsideGeo",
                            insideOutsideTable: true,
                          });
                          await this.loadCardStatusData();
                          await this.openTitleModal(
                            this.context.translate("_OUTSIDE_GEOFENCE_")
                          );
                        }}
                      >
                        <div className="card">
                          <img
                            src={require("../assets/images/outsidemap-1@2x.png")}
                            className="card-img"
                            alt="..."
                          ></img>
                          <div className="card-img-overlay">
                            <div className="card-leftdata">
                              <h5 className="card-title">
                                {" "}
                                {this.context.translate(
                                  "_OUTSIDE_GEOFENCE_"
                                )}{" "}
                              </h5>
                            </div>
                            <div className="card-rightdata">
                              <div>
                                <img
                                  src={require("../assets/images/opt10.png")}
                                  className="card-img"
                                  alt="..."
                                ></img>
                              </div>
                              <h2>
                                {this.AdvanceDashboardStore?.counts?.outsideGeo}
                                <span>
                                  {" "}
                                  {this.context.translate("_ALERTS_")}{" "}
                                </span>
                              </h2>
                              <p className="card-text">
                                <small>
                                  {
                                    this.AdvanceDashboardStore?.vehPer
                                      ?.outsideGeo
                                  }
                                  % {this.context.translate("_VEHICLE_")}
                                </small>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="power"
                        onClick={async () => {
                          await this.setState({
                            powerStatus: "power",
                            statusPowerTable: true,
                          });
                          await this.loadPowerStatusData();
                        }}
                      >
                        <div className="card">
                          <img
                            src={require("../assets/images/power-1@2x.png")}
                            className="card-img"
                            alt="..."
                          ></img>
                          <div className="card-img-overlay">
                            <div className="card-leftdata">
                              <h5 className="card-title">
                                {this.context.translate("_POWER_")}{" "}
                              </h5>
                            </div>
                            <div className="card-rightdata">
                              <div>
                                <img
                                  src={require("../assets/images/opt10.png")}
                                  className="card-img"
                                  alt="..."
                                ></img>
                              </div>
                              <h6>
                                {
                                  this.AdvanceDashboardStore?.counts
                                    ?.powerConnected
                                }
                                <span>
                                  {" "}
                                  {this.context.translate("_CONNECTED_")}{" "}
                                </span>
                              </h6>
                              <h6>
                                {
                                  this.AdvanceDashboardStore?.counts
                                    ?.powerDisconnected
                                }
                                <span>
                                  {" "}
                                  {this.context.translate(
                                    "_DISCONNECTED_"
                                  )}{" "}
                                </span>
                              </h6>
                              <p className="card-text">
                                <small>
                                  {
                                    this.AdvanceDashboardStore?.vehPer
                                      ?.powerDisconnected
                                  }
                                  % {this.context.translate("_VEHICLE_")}
                                </small>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="alertstriggered"
                        onClick={async () => {
                          await this.setState({
                            fleetStatus: "alertTrigger",
                            fleetOverspeedTable: true,
                          });
                          await this.loadCardStatusData();
                          await this.openModal(
                            this.context.translate("_ALERT_TRIGGER_")
                          );
                        }}
                      >
                        <div className="card">
                          <img
                            src={require("../assets/images/alert-1@2x.png")}
                            className="card-img"
                            alt="..."
                          ></img>
                          <div className="card-img-overlay">
                            <div className="card-leftdata">
                              <h5 className="card-title">
                                {" "}
                                {this.context.translate(
                                  "_ALERTS_TRIGGERED_"
                                )}{" "}
                              </h5>
                            </div>
                            <div className="card-rightdata">
                              <div>
                                {" "}
                                <img
                                  src={require("../assets/images/opt10.png")}
                                  className="card-img"
                                  alt="..."
                                ></img>
                              </div>
                              <h2>
                                {
                                  this.AdvanceDashboardStore?.counts
                                    ?.alertTrigger
                                }
                                <span>
                                  {" "}
                                  {this.context.translate("_ALERTS_")}
                                </span>
                              </h2>
                              <p className="card-text">
                                <small>
                                  {
                                    this.AdvanceDashboardStore?.vehPer
                                      ?.alertTriggered
                                  }
                                  % {this.context.translate("_VEHICLE_")}{" "}
                                </small>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="harshbreaking"
                        onClick={async () => {
                          await this.setState({
                            fleetStatus: "harshbreak",
                            fleetOverspeedTable: true,
                          });
                          await this.loadCardStatusData();
                          await this.openModal(
                            this.context.translate("_HARSH_BRACKING_")
                          );
                        }}
                      >
                        <div className="card">
                          <img
                            src={require("../assets/images/break@2x.png")}
                            className="card-img"
                            alt="..."
                          ></img>
                          <div className="card-img-overlay">
                            <div className="card-leftdata">
                              <h5 className="card-title">
                                {" "}
                                {this.context.translate("_HARSH_BREAKING_")}
                              </h5>
                            </div>
                            <div className="card-rightdata">
                              <div>
                                <img
                                  src={require("../assets/images/opt10.png")}
                                  className="card-img"
                                  alt="..."
                                ></img>
                              </div>
                              <h2>
                                {this.AdvanceDashboardStore?.counts?.Harshbrake}
                                <span>
                                  {" "}
                                  {this.context.translate("_ALERTS_")}
                                </span>
                              </h2>
                              <p className="card-text">
                                <small>
                                  {
                                    this.AdvanceDashboardStore?.vehPer
                                      ?.Harshbrake
                                  }
                                  % {this.context.translate("_VEHICLE_")}
                                </small>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="turning"
                        onClick={async () => {
                          await this.setState({
                            fleetStatus: "harshTurn",
                            fleetOverspeedTable: true,
                          });
                          await this.loadCardStatusData();
                          await this.openModal(
                            this.context.translate("_HARSH_TURNING_")
                          );
                        }}
                      >
                        <div className="card">
                          <img
                            src={require("../assets/images/arrow-angle-turning-to-right@2x.png")}
                            className="card-img"
                            alt="..."
                          ></img>
                          <div className="card-img-overlay">
                            <div className="card-leftdata">
                              <h5 className="card-title">
                                {" "}
                                {this.context.translate("_HARSH_TURNING_")}{" "}
                              </h5>
                            </div>
                            <div className="card-rightdata">
                              <div>
                                <img
                                  src={require("../assets/images/opt10.png")}
                                  className="card-img"
                                  alt="..."
                                ></img>
                              </div>
                              <h2>
                                {
                                  this.AdvanceDashboardStore?.counts
                                    ?.HarshCornering
                                }
                                <span>
                                  {" "}
                                  {this.context.translate("_ALERTS_")}{" "}
                                </span>
                              </h2>
                              <p className="card-text">
                                <small>
                                  {
                                    this.AdvanceDashboardStore?.vehPer
                                      ?.HarshCornering
                                  }
                                  % {this.context.translate("_VEHICLE_")}{" "}
                                </small>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="acceleration"
                        onClick={async () => {
                          await this.setState({
                            fleetStatus: "harshAcc",
                            fleetOverspeedTable: true,
                          });
                          await this.loadCardStatusData();
                          await this.openModal(
                            this.context.translate("_HARSH_ACCELERATION_")
                          );
                        }}
                      >
                        <div className="card">
                          <img
                            src={require("../assets/images/acceleration@2x.png")}
                            className="card-img"
                            alt="..."
                          ></img>
                          <div className="card-img-overlay">
                            <div className="card-leftdata">
                              <h5 className="card-title">
                                {" "}
                                {this.context.translate(
                                  "_HARSH_ACCELERATION_"
                                )}{" "}
                              </h5>
                            </div>
                            <div className="card-rightdata">
                              <div>
                                <img
                                  src={require("../assets/images/opt10.png")}
                                  className="card-img"
                                  alt="..."
                                ></img>
                              </div>
                              <h2>
                                {this.AdvanceDashboardStore?.counts?.HarshAcc}
                                <span>
                                  {" "}
                                  {this.context.translate("_ALERTS_")}
                                </span>
                              </h2>
                              <p className="card-text">
                                <small>
                                  {this.AdvanceDashboardStore?.vehPer?.HarshAcc}
                                  % {this.context.translate("_VEHICLE_")}
                                </small>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>

                  <Row className="idle-info-panel">
                    <Col span={12}>
                      <Card
                        className="close-window fleet-idle"
                        title={this.context.translate("_FLEET_IDLE_")}
                        style={{
                          height: 240,
                        }}
                      >
                        {/* <div className="fleetstatus-head"><span><img className="opt-icon7" alt=""  src={require("../assets/images/opt9.svg")}/></span></div> */}
                        <div className="parkpetrol">
                          <div className="fleetidle">
                            <div className="total-fleet-idle">
                              {this.context.translate(
                                "_TOTAL_FLEET_IDLE_HOUR_"
                              )}
                            </div>
                            <div className="park">
                              <img
                                className="parking-icon"
                                alt=""
                                src={require("../assets/images/parking@2x.png")}
                              />
                              <div className="digitsBlock">
                                <div className="div24">
                                  {this.AdvanceDashboardStore?.fleetHours}
                                </div>
                                <div className="hours3">
                                  {this.context.translate(
                                    "_STATIONARY_REPORT_HOURS_"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="petrol">
                            <div className="approx-fuel-wastage">
                              {" "}
                              {this.context.translate(
                                "_APPROX_FUEL_WASTE_LTR_"
                              )}{" "}
                            </div>
                            <div className="fuel-waste">
                              <img
                                className="fuel-icon"
                                alt=""
                                src={require("../assets/images/fuel@2x.png")}
                              />
                              <div className="digitsBlock">
                                <div className="div23">
                                  {this.AdvanceDashboardStore?.fuelWastage}
                                </div>
                                <div className="liters">
                                  {" "}
                                  {this.context.translate("_LITRES_")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="note-generally-an-container">
                          <span className="total-distance-covered-container1">
                            <b>{this.context.translate("_NOTE_")}:</b>
                            <span className="generally-an-idling">
                              {this.context.translate("_FLEET_IDLE_NOTE_MSG_")}{" "}
                            </span>
                          </span>
                        </div>
                      </Card>
                    </Col>
                    <Col span={12}>
                      <Card
                        className="job-info"
                        title={this.context.translate("_JOB_INFO_")}
                        bordered={false}
                        style={{
                          height: 242,
                        }}
                      >
                        {/* <div className="fleetstatus-head"><span>Job Information</span><span><img className="opt-icon7" alt="" src={require("../assets/images/opt9.svg")} /></span></div> */}
                        <div className="jobinformation">
                          <div className="yellow">
                            <img
                              className="jobinfo-icons"
                              alt=""
                              src={require("../assets/images/time-left@2x.png")}
                            />
                            <span className="on-going">
                              {this.context.translate("_ON_GOING_")}
                            </span>
                            <div>
                              <CircularProgressbar
                                value={
                                  this.AdvanceDashboardStore?.jobState
                                    ?.TotalProcessedJob
                                }
                                text={`${this.AdvanceDashboardStore?.jobState?.TotalProcessedJob}%`}
                              />
                            </div>
                            {/* <div
                            role="progressbar"
                            // aria-valuenow="0"
                          
                            // aria-valuemin={this.AdvanceDashboardStore?.jobState?.TotalProcessedJob}
                            // aria-valuemax="100"
                            style={{ margin: "20px 0 0 0" }}
                          ></div> */}
                          </div>
                          <div className="red">
                            <img
                              className="jobinfo-icons"
                              alt=""
                              src={require("../assets/images/cancelled@2x.png")}
                            />
                            <span className="canceled">
                              {this.context.translate("_CANCELLED_")}
                            </span>
                            {/* <div
                            role="red"
                            // aria-valuenow="5455"
                            aria-valuenow="5455"
                        
                            aria-valuemin={
                              this.AdvanceDashboardStore?.jobState?.TotalPendingJob
                            }
                            aria-valuemax="100"
                            style={{ margin: "20px 0 0 0" }}
                          ></div> */}
                            <div>
                              <CircularProgressbar
                                value={
                                  this.AdvanceDashboardStore?.jobState
                                    ?.TotalPendingJob
                                }
                                text={`${this.AdvanceDashboardStore?.jobState?.TotalPendingJob}%`}
                              />
                            </div>
                          </div>
                          <div className="green">
                            <img
                              className="jobinfo-icons"
                              alt=""
                              src={require("../assets/images/flag@2x.png")}
                            />
                            <span className="finished">
                              {this.context.translate("_FINISHED_")}
                            </span>
                            {/* <div
                            role="green"
                            aria-valuenow="0"
                            aria-valuemin={
                              this.AdvanceDashboardStore?.jobState
                                ?.TotalFinishedJob
                            }
                            aria-valuemax="100"
                            style={{ margin: "20px 0 0 0" }}
                          ></div> */}
                            <div>
                              <CircularProgressbar
                                value={
                                  this.AdvanceDashboardStore?.jobState
                                    ?.TotalFinishedJob
                                }
                                text={`${this.AdvanceDashboardStore?.jobState?.TotalFinishedJob}%`}
                              />
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* <Divider ></Divider> */}

              <Row>
                <Col span={8}>
                  <Card
                    className="maxspeed"
                    title={this.context.translate("_MAX_SPEED_1")}
                    bordered={false}
                    style={{
                      height: 585,
                    }}
                  >
                    {/* <div className="fleetstatus-head"><span>Max Speed</span><span><img className="opt-icon16" alt="" src="public/fleet/appRes/images/dashboard-images/opt.svg" /></span></div> */}
                    <div className="maxspeed-total-fleet">
                      <span>
                        {this.context.translate("_TOTAL_FLEET_USAGE_")}:{" "}
                      </span>
                      <span className="km">
                        {this.AdvanceDashboardStore?.distanceTravelled}km
                      </span>
                    </div>
                    <div className="maxspeed-avgditance">
                      <span>{this.context.translate("_AVG_DIST_VEH_")}: </span>
                      <span className="km">
                        {this.AdvanceDashboardStore?.avgDistperVeh}km
                      </span>
                    </div>
                    <div className="maxspeed-data">
                      <div className="vertical-number">
                        <p>240</p>
                        <p>180</p>
                        <p>120</p>
                        <p>60</p>
                        <p>0</p>
                      </div>
                      <div className="bars">
                        {this.AdvanceDashboardStore?.MaxSpeedBars}
                        {/* {MaxSpeedBars} */}
                      </div>
                    </div>
                  </Card>
                </Col>

                <Col span={16}>
                  <Row>
                    <Col span={12}>
                      <Card
                        className="working-hours"
                        title={this.context.translate("_WORKING_HOUR_")}
                        bordered={false}
                        style={{
                          height: 265,
                        }}
                      >
                        <div className="allcircles">
                          <div className="day-hours">
                            <p>
                              {this.context.translate("_DAY_HRS_")}
                              <span>
                                <b>{this.AdvanceDashboardStore?.dayHours}Hrs</b>
                              </span>
                            </p>
                          </div>
                          <div className="total-hours">
                            <p>
                              {this.context.translate("_TOTAL_WORKING_HOURS_")}
                              <span>
                                <b>
                                  {this.AdvanceDashboardStore?.totalWorkingHrs}
                                  Hrs
                                </b>
                              </span>
                            </p>
                          </div>
                          <div className="night-hours">
                            <p>
                              {this.context.translate("_NIGHT_HRS_")}
                              <span>
                                <b>
                                  {this.AdvanceDashboardStore?.nightHours}Hrs
                                </b>
                              </span>
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col span={6}>
                      <Card
                        className="maintanance-reminder"
                        // title="Maintenance Reminder"
                        bordered={false}
                        style={{
                          height: 265,
                        }}
                      >
                        <div className="maintanance-head">
                          <span>
                            {this.context.translate("_MAINTENANCE_")} <br />{" "}
                            {this.context.translate("_REMINDER_")}
                          </span>
                        </div>
                        <div className="maintain-due">
                          <div className="calen">
                            <img
                              src={require("../assets/images/calender-1@2x.png")}
                            />
                          </div>
                          <p>
                            {this.context.translate("_DUE_")}
                            <span>{this.AdvanceDashboardStore?.dueCount}</span>
                          </p>
                        </div>
                        <div className="maintain-due">
                          <div className="alarm">
                            <img
                              src={require("../assets/images/overdue-2@2x.png")}
                            />
                          </div>
                          <p>
                            {this.context.translate("_OVERDUE_")}
                            <span>{this.AdvanceDashboardStore?.overDue}</span>
                          </p>
                        </div>
                      </Card>
                    </Col>

                    <Col span={6}>
                      <Card
                        className="renew-reminder"
                        //title="Renewal Reminder"
                        bordered={false}
                        style={{
                          height: 265,
                        }}
                      >
                        <div className="maintanance-head">
                          <span>
                            {this.context.translate("_RENEWAL_")}
                            <br />
                            {this.context.translate("_REMINDER_")}
                          </span>
                        </div>
                        <div className="maintain-due">
                          <div className="calen">
                            <img
                              src={require("../assets/images/calender-1@2x.png")}
                            />
                          </div>
                          <p>
                            {this.context.translate("_DUE_")}
                            <span>
                              {this.AdvanceDashboardStore?.reminderDueCount}
                            </span>
                          </p>
                        </div>
                        <div className="maintain-due">
                          <div className="alarm">
                            <img
                              src={require("../assets/images/overdue-2@2x.png")}
                            />
                          </div>
                          <p>
                            {this.context.translate("_OVERDUE_")}
                            <span>
                              {this.AdvanceDashboardStore?.reminderOverDue}
                            </span>
                          </p>
                        </div>
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Card
                        title={this.context.translate(
                          "_DISTANCE_CLASSIFICATION_"
                        )}
                        className="distance-classification"
                        bordered={false}
                        style={{
                          height: 300,
                        }}
                      >
                        <div className="meter">
                          <div className="distance-leftcontent">
                            <div className="on-duty">
                              <p>
                                {this.context.translate("_ONDUTY_KM_TIME_")}:{" "}
                                <span>
                                  {
                                    this.AdvanceDashboardStore?.workinghour
                                      ?.onDutyDistance
                                  }
                                  km/
                                  {
                                    this.AdvanceDashboardStore?.workinghour
                                      ?.onDutyDuration
                                  }
                                  hrs
                                </span>
                              </p>
                            </div>
                            <div className="off-duty">
                              <p>
                                {this.context.translate("_OFFDUTY_KM_TIME_")}:{" "}
                                <span>
                                  {
                                    this.AdvanceDashboardStore?.workinghour
                                      ?.offDutyDistance
                                  }
                                  km/
                                  {
                                    this.AdvanceDashboardStore?.workinghour
                                      ?.offDutyDuration
                                  }
                                  hrs
                                </span>
                              </p>
                            </div>
                            <div className="total-duty">
                              <p>
                                {this.context.translate(
                                  "_TOTAL_DISTANCE_COVERED_"
                                )}
                                :{" "}
                                <span>
                                  {
                                    this.AdvanceDashboardStore?.workinghour
                                      ?.totalDistanceTravel
                                  }
                                  km/
                                  {
                                    this.AdvanceDashboardStore?.workinghour
                                      ?.totalduration
                                  }
                                  hrs
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="distance-rightcontent">
                            <img
                              className="meter-1-icon"
                              src={require("../assets/images/meter.png")}
                            />
                          </div>
                        </div>
                      </Card>
                    </Col>

                    <Col span={12}>
                      <Card
                        className="driver-score"
                        title={this.context.translate("_VEHICLE_SCORE_")}
                        bordered={false}
                        style={{
                          height: 300,
                        }}
                      >
                        <ResponsiveContainer height={300}>
                          <LineChart
                            width={330}
                            data={this.AdvanceDashboardStore?.vehicleScore}
                            margin={{
                              top: 10,
                              right: 30,
                              left: 0,
                              bottom: 100,
                            }}
                          >
                            <XAxis
                              dataKey="vehNo"
                              angle={ this.context.locale.language === "english" ? -90 : 90}
                              interval={0}
                              textAnchor="end"
                              // label={{ value: "Time", position: "bottom" }}
                            />

                            <YAxis
                            // label={{
                            //   value: "Temprature",
                            //   angle: -90,
                            //   position: "insideLeft",
                            // }}
                            />
                            {/* <Tooltip content={<this.CustomTooltip />} /> */}
                            <Legend
                              layout="vertical"
                              align="right"
                              verticalAlign="top"
                              payload={customLegendPayload}
                              
                            />

                            <Line
                              type="monotone"
                              dataKey="acccount"
                              stroke="#66c2a5"
                              strokeWidth={4}
                            />
                            <Line
                              type="monotone"
                              dataKey="brakecount"
                              stroke="#fc8d62"
                              strokeWidth={4}
                            />
                            <Line
                              // type="monotone"
                              dataKey="exidlecount"
                              stroke="#8da0cb"
                              strokeWidth={4}
                            />
                            <Line
                              // type="monotone"
                              dataKey="rpmcount"
                              stroke="#e78ac3"
                              strokeWidth={4}
                            />
                            <Line
                              // type="monotone"
                              dataKey="speedcount"
                              stroke="#a6d854"
                              strokeWidth={4}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                        {/* <div className="vehicle-score-graph-captions">
                        <ul>
                          <li className="account">
                            <span></span>
                            {this.context.translate("_VEHSCORE_AC_COUNT_")}
                          </li>
                          <li className="breakcount">
                            <span></span>
                            {this.context.translate("_VEHSCORE_BREAK_COUNT_")}
                          </li>
                          <li className="exidelcount">
                            <span></span>
                            {this.context.translate("_VEHSCORE_EXIDLE_COUNT_")}
                          </li>
                          <li className="rpmcount">
                            <span></span>
                            {this.context.translate("_VEHSCORE_RPM_COUNT_")}
                          </li>
                          <li className="speedcount">
                            <span></span>
                            {this.context.translate("_VEHSCORE_SPEED_COUNT_")}
                          </li>
                        </ul>
                      </div>
                      <div id="my_dataviz"></div> */}
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Spin>

        {this.state.vehicleObj && (
          <MapForVehiclePosition
            vehicleObj={this.state.vehicleObj}
            rotateDeg={true}
            heading="Detail Tracking on  Map"
            closeMapModal={(resp) => {
              this.setState({ vehicleObj: resp });
            }}
          />
        )}

        <Modal
          // title="Overspeeding"
          title={this.context.translate(
            "_FLEET_RULESELECTORDETAIL_RULE7_TITLE_"
          )}
          open={this.state.displayOverspeedTable}
          centered
          // title="Notifications"
          width={1000}
          style={{ height: "500px" }}
          onCancel={() => this.setState({ displayOverspeedTable: false })}
          footer={null}
        >
          <div>
            <Input
              placeholder={this.context.translate("_VEHICLE_NO_")}
              style={{ width: 200, height: 40 }}
              // value={this.state.searchText}
              onChange={async (e) => {
                let searchedArray = await this.handleCardSearchChange(
                  e.target.value
                );
               
                await this.setState({
                  searchVehicle:
                    searchedArray.length === 0 ? [] : searchedArray,
                });
              }}
            />
            &nbsp; &nbsp;
            <Button
              style={{ height: 40, backgroundColor: config.activeColor }}
              onClick={(val) => {
               
                const result = ["updatedate"];
                this.state.overspeedColumns.map((obj) =>
                  result.push(obj.dataIndex)
                );
              
                var getData = {
                  cid: JSON.parse(base64_decode(Cookies.get("data")))
                    .userCompanyId,
                  userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
                  isadmin: JSON.parse(base64_decode(Cookies.get("data")))
                    .isadmin,
                  fleetStatus: this.state.fleetStatus,
                  vehicleNo: "",
                  colList: result,
                };
              
                if (val) {
                  this.AdvanceDashboardStore.exportPowerExcel(getData);
                }
              }}
              // style={{ backgroundColor: config.activeColor }}
            >
              <Space>
                {/* Export */}
                {this.context.translate("_EXPORT_")}
                <DownloadOutlined />
              </Space>
            </Button>
          </div>
          <div style={{ height: "450px", marginTop: "10px" }}>
            <Table
              bordered
              columns={this.state.overspeedColumns}
              dataSource={this.state.searchVehicle}
              // dataSource={this.AdvanceDashboardStore.trackData}
              pagination={false}
              scroll={{ y: 360 }}
            />
          </div>
        </Modal>

        <Modal
          title={this.context.translate("_VEHICLE_DETAILS_")}
          // {this.context.translate("_VEHICLE_DETAILS_")}

          open={this.state.displayFleetStatusTable}
          centered
          // title="Notifications"
          width={1000}
          style={{ height: "500px" }}
          onCancel={() => this.setState({ displayFleetStatusTable: false })}
          footer={null}
        >
          <div>
            <Input
              placeholder={this.context.translate("_VEHICLE_NO_")}
              style={{ width: 200, height: 40 }}
              // value={this.state.searchText}
              onChange={async (e) => {
                let searchedArray = await this.handleSearchChange(
                  e.target.value
                );
               
                await this.setState({
                  searchVehicle:
                    searchedArray.length === 0 ? [] : searchedArray,
                });
              }}
            />
            &nbsp; &nbsp;
            <Button
              style={{ height: 40, backgroundColor: config.activeColor }}
              onClick={(val) => {
        
                const result = ["updatedate"];
                this.state.fleetStatusColumns.map((obj) =>
                  result.push(obj.dataIndex)
                );
                //  console.log(result.toString())
                var getData = {
                  cid: JSON.parse(base64_decode(Cookies.get("data")))
                    .userCompanyId,
                  userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
                  isadmin: JSON.parse(base64_decode(Cookies.get("data")))
                    .isadmin,
                  vehicleStatus: this.state.status,
                  vehicleNo: "",
                  colList: result,
                };
          
                if (val) {
                  this.AdvanceDashboardStore.exportExcel(getData);
                }
              }}
              // style={{ backgroundColor: config.activeColor }}
            >
              <Space>
                {/* Export */}
                {this.context.translate("_EXPORT_")}
                <DownloadOutlined />
              </Space>
            </Button>
          </div>
          <div style={{ height: "450px", marginTop: "10px" }}>
            <Table
              bordered
              columns={this.state.fleetStatusColumns}
              dataSource={this.state.searchVehicle}
              // dataSource={this.AdvanceDashboardStore.trackData}
              pagination={false}
              scroll={{ y: 360 }}
            />
          </div>
        </Modal>

        <Modal
          // title=<div
          //   style={{
          //     background: "white",
          //     display: "flex",
          //     justifyContent: "space-between",
          //     alignItems: "center",
          //   }}
          // >
          title={this.context.translate("_POWER_")}
          // </div>
          open={this.state.statusPowerTable}
          centered
          width={1000}
          style={{ height: "500px" }}
          onCancel={() => this.setState({ statusPowerTable: false })}
          footer={null}
        >
          <Space>
            <Input
              placeholder={this.context.translate("_VEHICLE_NO_")}
              style={{ height: 47 }}
              // style={{ width: 200, height: 40 }}
              // value={this.state.searchText}
              onChange={async (e) => {
                let searchedArray = await this.handleCardSearchChange(
                  e.target.value
                );
       
                await this.setState({
                  searchVehicle:
                    searchedArray.length === 0 ? [] : searchedArray,
                });
              }}
            />{" "}
            <Select
              style={{ width: 150 }}
              placeholder="Status"
              onChange={async (status) => {
                await this.setState({ powerStatus: status });
            
                this.loadPowerStatusData();
                // await this.AdvanceDashboardStore.getFleetCardsStatusData({
                //   companyId: JSON.parse(base64_decode(Cookies.get("data")))
                //     .userCompanyId,
                //   userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
                //   isadmin: JSON.parse(base64_decode(Cookies.get("data")))
                //     .isadmin,
                //   fleetStatus: status,
                // });

                await this.setState({ update: 1 });
              }}
            >
              <Select.Option value="Connected">Connected</Select.Option>
              <Select.Option value="Disconnected">Disconnected</Select.Option>
            </Select>{" "}
            <Button
              style={{ height: 47, backgroundColor: config.activeColor }}
              onClick={(val) => {
           
                const result = [];
                this.state.powerColumns.map((obj) =>
                  result.push(obj.dataIndex)
                );

                var getData = {
                  cid: JSON.parse(base64_decode(Cookies.get("data")))
                    .userCompanyId,
                  userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
                  isadmin: JSON.parse(base64_decode(Cookies.get("data")))
                    .isadmin,
                  fleetStatus: this.state.powerStatus,
                  vehicleNo: "",
                  colList: result,
                };

                if (val) {
                  this.AdvanceDashboardStore.exportPowerExcel(getData);
                }
              }}
              // style={{ backgroundColor: config.activeColor }}
            >
              <Space>
                {/* Export */}
                {this.context.translate("_EXPORT_")}
                <DownloadOutlined />
              </Space>
            </Button>
          </Space>
          <div style={{ height: "450px", marginTop: "10px" }}>
            <Table
              bordered
              columns={this.state.powerColumns}
              dataSource={this.state.searchVehicle}
              // dataSource={this.AdvanceDashboardStore.fleetStatusArr}
              pagination={false}
              scroll={{ y: 360 }}
            />
          </div>
        </Modal>

        <Modal
          title={this.state.modalTitle}
          open={this.state.fleetOverspeedTable}
          centered
          width={1000}
          style={{ height: "500px" }}
          onCancel={() => this.setState({ fleetOverspeedTable: false })}
          footer={null}
        >
          <div>
            <Input
              placeholder={this.context.translate("_VEHICLE_NO_")}
              style={{ width: 200, height: 40 }}
              // value={this.state.searchText}
              onChange={async (e) => {
                let searchedArray = await this.handleCardSearchChange(
                  e.target.value
                );
           
                await this.setState({
                  searchVehicle:
                    searchedArray.length === 0 ? [] : searchedArray,
                });
              }}
            />{" "}
            <Button
              style={{ height: 40, backgroundColor: config.activeColor }}
              onClick={(val) => {
             
                const result = [];
                this.state.AlertColumns.map((obj) =>
                  result.push(obj.dataIndex)
                );
                //  console.log(result.toString())
                var getData = {
                  cid: JSON.parse(base64_decode(Cookies.get("data")))
                    .userCompanyId,
                  userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
                  isadmin: JSON.parse(base64_decode(Cookies.get("data")))
                    .isadmin,
                  fleetStatus: this.state.fleetStatus,
                  vehicleNo: "",
                  colList: result,
                };
           
                if (val) {
                  this.AdvanceDashboardStore.exportPowerExcel(getData);
                }
              }}
              // style={{ backgroundColor: config.activeColor }}
            >
              <Space>
                {/* Export */}
                {this.context.translate("_EXPORT_")}
                <DownloadOutlined />
              </Space>
            </Button>
          </div>
          <div style={{ height: "450px", marginTop: "10px" }}>
            <Table
              bordered
              columns={this.state.AlertColumns}
              dataSource={this.state.searchVehicle}
              // dataSource={this.AdvanceDashboardStore.fleetStatusArr}
              pagination={false}
              scroll={{ y: 360 }}
            />
          </div>
        </Modal>

        <Modal
          // title=
          //   {this.context.translate("_VEHICLE_DETAILS_")}
          title={this.state.InOutTitle}
          open={this.state.insideOutsideTable}
          centered
          width={1000}
          style={{ height: "500px" }}
          onCancel={() => this.setState({ insideOutsideTable: false })}
          footer={null}
        >
          <div>
            <Input
              placeholder={this.context.translate("_VEHICLE_NO_")}
              style={{ width: 200, height: 40 }}
              // value={this.state.searchText}
              onChange={async (e) => {
                let searchedArray = await this.handleSearchChange(
                  e.target.value
                );
           
                await this.setState({
                  searchVehicle:
                    searchedArray.length === 0 ? [] : searchedArray,
                });
              }}
            />{" "}
            <Button
              style={{ height: 40, backgroundColor: config.activeColor }}
              onClick={(val) => {
               
                const result = [];
                this.state.fleetStatusColumns.map((obj) =>
                  result.push(obj.dataIndex)
                );
                //  console.log(result.toString())
                var getData = {
                  cid: JSON.parse(base64_decode(Cookies.get("data")))
                    .userCompanyId,
                  userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
                  isadmin: JSON.parse(base64_decode(Cookies.get("data")))
                    .isadmin,
                  fleetStatus: this.state.fleetStatus,
                  vehicleNo: "",
                  colList: result,
                };

                if (val) {
                  this.AdvanceDashboardStore.exportPowerExcel(getData);
                }
              }}
              // style={{ backgroundColor: config.activeColor }}
            >
              <Space>
                {/* Export */}
                {this.context.translate("_EXPORT_")}
                <DownloadOutlined />
              </Space>
            </Button>
          </div>
          <div style={{ height: "450px", marginTop: "10px" }}>
            <Table
              bordered
              columns={this.state.insideOutsideGeofenceColumns}
              // dataSource={this.state.searchVehicle}
              dataSource={this.AdvanceDashboardStore.fleetStatusArr}
              pagination={false}
              scroll={{ y: 360 }}
            />
          </div>
        </Modal>
      </>
    );
  }
}
AdvanceDashboard.contextType = StoreContext;
export default observer(AdvanceDashboard);
