import React, { Component } from "react";
import { Link } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from "base-64";

import { observer } from "mobx-react";
import withRouter from "../withRouter";
import { StoreContext } from "../store/RootStore";
import HelpModal from "./HelpModal";
import { Tooltip } from "antd";
import Cookies from "universal-cookie";
import conf from "../config";
class LounchingDashboard extends Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    // this.mapShow = Number(
    //   JSON.parse(JSON.stringifythis.cookies.get("mapShow"))
    // );
    this.state = {};
    this.showLaunchingItems = {
      livetracking: false,
      dashboard: false,
      historytracking: false,
      detailtracking: false,
      parentalertreportlist: false,
      master: false,
      AdvanceDashboard: false,
      vehiclelist: false,
      parentreport: false,
      maintenancedashboard: false,
      showHelpModal: false,
      showLaunchingDashboard: 1,
    };
    // console.log(this.mapShow);

    this.updateDashboard(1);
    let cookies = this.cookies.get("data");
    this.rolePermission = JSON.parse(base64_decode(cookies)).rolePermission;
    console.log(JSON.parse(base64_decode(cookies)));
    if (JSON.parse(base64_decode(cookies)).logo) {
      this.state.logo = JSON.parse(base64_decode(cookies)).logo;
      console.log(this.state.logo);
    }
    console.log(this.rolePermission);
    if (this.rolePermission !== "All") {
      this.roleSettingForUser();
    }
    for (let i in this.showLaunchingItems) {
      if (this.rolePermission?.includes(i) || this.rolePermission === "All") {
        this.showLaunchingItems[i] = true;
      }
      // console.log(i);
    }
  }
  roleSettingForUser = () => {
    // Master Section
    let obj = {
      master: {
        parentaccount: [
          "companyList",
          "companyuserlist",
          "companydriverslist",
          "companystafflist",
          "passengerList",
        ],
        vehiclemanagement: [
          "vehiclelist",
          "VehicleAllocateList",
          "vehiclereturnassetlist",
          "vehiclemaintenancelist",
          "VehicleTempSensorList",
          "VehicleGroupList",
          "VehicleWorkingHoursList",
          "vehicledistancelist",
          "VehicleLiveLocationList",
          "VehicleOdometerList",
          "VehicleTrailerList",
          "VehicleFuelCalibrationList",
        ],
        parentfuelmanagement: ["fueldetailslist", "fuelfilledlist"],
        parentschoolmanagement: [
          "passengerList2/1",
          "gradetiminglist",
          "schoolcalenderlist",
          "passengerList",
          "parentlist",
        ],
        parentjobmanagement: ["jobmanagementlist", "jobrequestlist"],
        parenttripmanagement: ["assigntriplist", "tripCostList"],
        parentpayment: ["paymentlist"],
        parentwarehouse: ["warehouselist"],
        parentinventory: ["inventorylist"],
      },

      parentreport: {
        parenttrackingreport: [
          "trackdatalist",
          "travelreportlist",
          "stoppagereportlist",
          "tripreportlist",
          "idlereportlist",
          "exidlereportlist",
          "distancereportlist",
          "commandsendreportlist",
          "canbusreportstorelist",
          "machineryseportlist",
          "stationaryreportlist",
          "weightreportlist",
          "vehicleperformancelist",
          "workinghoursreportlist",
        ],
        parentmiscellaneousreport: [
          "eventreportlist",
          "passengerlogreportlist",
          "attendencereportlist",
          "blacklistpersonreportlist",
        ],
        parentfleetreport: [
          "fleetdaysummaryreport",
          "FleetSummaryReportList",
          "FuelFilledReportList",
          "MileageReportList",
          "MaintenanceFleetReportList",
        ],
        parentalertreport: [
          "SpeedViolationReportList",
          "geofenceinoroutreportlist",
          "TempReportList",
          "GeofenceSummaryList",
          "VehicleNotPollReportList",
          "RuleTriggerList",
          "GeofenceVisitReportList",
          "mdvralarmreportlist",
        ],
        parentadminreport: [
          "userloginreportlist",
          "assetregisterreportlist",
          "commandsendreportlist",
          "rawdatareportlist",
          "useractivityreportlist",
        ],
        parentreport: [
          "waslreportlist",
          "waslbustripresponselist",
          "inventorystatusrepostlist",
          "parentwaslreport",
        ],
        parentdriverreport: [
          "driverscorereportlist",
          "overtimereportlist",
          "DriverLoginReportList",
          "DriverPerformanceReportList",
          "DriverComplainReportList",
        ],
      },
    };
    this.compareSubArrays(obj);
    //Tracking Section
    let parenttracking = [
      "livetracking",
      "detailtracking",
      "trackbygroup",
      "dashboard",
      "maintenancedashboard",
      "multitracking",
      "liveroutepath",
      "historytracking",
      "AdvanceDashboard",
      "sensorreportlist",
      "warehousemonitoring",
      "temperaturedashboard",
      "heatmap",
      "videomonitoring",
      "multivideomonitoring",
      "parenttracking",
    ];
    this.compareSubArrays(parenttracking, "parenttracking");
    //setting section
    let parentsettings = [
      "notificationlist",
      "simlist",
      "deviceloglist",
      "inspectionlist",
      "gprscommand",
      "emailmessage",
      "devicelist",
      "companychangepassword",
      "companymenusetting",
      "companydevicetestinglist",
      "companydriverbehaviorsetting",
      "geofencereportlist",
      "logout",
      "parentsettings",
    ];
    this.compareSubArrays(parentsettings, "parentsettings");
    // graph section
    let parentgraph = [
      "SpeedGraph",
      "DistanceGraph",
      "IdleGraph",
      "ExIdleGraph",
      "UsageGraph",
      "TempGraph",
      "DashboardGraph",
      "FuelGraph",
      "DriverScoreGraph",
      "MaxSpeedGraph",
      "parentgraph",
    ];
    this.compareSubArrays(parentgraph, "parentgraph");
  };

  compareSubArrays = async (obj, parent) => {
    if (parent) {
      if (this.rolePermission?.some((item) => obj?.includes(item))) {
        !this.rolePermission.includes(parent) &&
          this.rolePermission.push(parent);
      }
    } else {
      for (let x in obj) {
        let dataObj;
        if (x === "master") {
          dataObj = obj.master;
          for (let y in dataObj) {
            dataObj[y]?.forEach((val) => {
              if (
                this.rolePermission.includes(val) &&
                !this.rolePermission.includes(y)
              ) {
                this.rolePermission.push(y);
                !this.rolePermission.includes(x) && this.rolePermission.push(x);
              }
            });
          }
        }
        if (x === "parentreport") {
          dataObj = obj.parentreport;

          for (let y in dataObj) {
            console.log(this.rolePermission);
            console.log(dataObj);
            dataObj[y].map((val) => {
              if (
                this.rolePermission.includes(val) &&
                !this.rolePermission.includes(y)
              ) {
                this.rolePermission.push(y);
                !this.rolePermission.includes(x) && this.rolePermission.push(x);
              }
            });
          }
        }
      }
    }
    await this.updateRolepermisson(this.rolePermission);
  };

  updateRolepermisson = (value) => {
    const encodedCookieData = this.cookies.get("data");
    const decodedCookieData = JSON.parse(base64_decode(encodedCookieData));
    console.log(value);
    decodedCookieData.rolePermission = value;
    console.log(decodedCookieData, "decoded cookidata");
    const updatedEncodedCookieData = base64_encode(
      JSON.stringify(decodedCookieData)
    );
    this.cookies.set("data", updatedEncodedCookieData, { path: "/" });
    this.setState({ update: 1 });
  };
  updateDashboard = (value) => {
    const encodedCookieData = this.cookies.get("data");
    const decodedCookieData = JSON.parse(base64_decode(encodedCookieData));
    decodedCookieData.showLaunchingDashboard = value;
    console.log(decodedCookieData, "decoded cookidata");
    const updatedEncodedCookieData = base64_encode(
      JSON.stringify(decodedCookieData)
    );
    this.cookies.set("data", updatedEncodedCookieData, { path: "/" });
    this.setState({ update: 1 });
  };
  componentDidMount() {
    // this.updateDashboard(1);
  }

  render() {
    const formItemStyle = { marginBottom: "2px", marginTop: "2px" };

    return (
      <div
        className={
          this.context.locale.language === "english"
            ? "menuWrapper"
            : "arabicMenu"
        }
        style={{
          backgroundImage: `url(${require("../assets/images/innerDashboard/menuBg.jpg")})`,
          width: "100%",
          //position: "relative",
          height: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          overflow: "hidden",
        }}
      >
        {/* ///////////////////////////////////// */}
        {/* {console.log(this.state.logo)} */}
        <img
          className="logo-w-1-icon"
          alt=""
          // src={require("../assets/images/awtllogo.png")}
          // src={require(conf.launchingDashboardLogo)}
          src={
            this.state.logo
              ? conf.fleetURL + "/images/companylogo/" + this.state.logo
              : conf.project === "starlingeye"
              ? require("../assets/images/" + conf.dashboardLogo)
              : require("../assets/images/" + conf.loginlogo)
          }
          // src={this.state.logo?conf.fleetURL+'/images/companylogo/'+this.state.logo:require("../assets/images/"+conf.launchingDashboardLogo)}
          onError={(e) => {
            //  console.log(e)
            e.target.onerror = null; // Prevents infinite loop if the fallback image also fails to load
            e.target.src = require("../assets/images/" + conf.dashboardLogo);
          }}
        />

        <div
          className="menu-child"
          style={{
            backgroundImage: `url(${require("../assets/images/innerDashboard/circle.png")})`,
          }}
        >
          &nbsp;
        </div>
        <img
          className="menu-item"
          alt=""
          src={require("../assets/images/innerDashboard/ellipse-457.png")}
        />

        <img
          className="menu-inner"
          alt=""
          src={require("../assets/images/innerDashboard/ellipse-455.png")}
        />

        <img
          className="ellipse-icon"
          alt=""
          src={require("../assets/images/innerDashboard/ellipse-456.png")}
        />

        <Link
          className={
            this.showLaunchingItems["livetracking"] === false && "menu-disabled"
          }
          to={this.showLaunchingItems["livetracking"] && "/livetracking"}
          onClick={() => {
            this.showLaunchingItems["livetracking"] && this.updateDashboard(0);
          }}
        >
          <Tooltip
            placement="left"
            title={this.context.translate("_LIVE_TRACING_")}
          >
            <img
              className="group-icon"
              alt=""
              src={require("../assets/images/innerDashboard/group-372@2x.png")}
            />
          </Tooltip>
        </Link>

        <Link
          className={
            this.showLaunchingItems["AdvanceDashboard"] === false &&
            "menu-disabled"
          }
          to={
            this.showLaunchingItems["AdvanceDashboard"] && "/AdvanceDashboard"
          }
          onClick={() => {
            if (this.showLaunchingItems["AdvanceDashboard"]) {
              this.context.menuShow = true;
              this.updateDashboard(0);
            }
          }}
        >
          <Tooltip
            placement="top"
            title={this.context.translate("_ADVANCE_DASHBOARD_")}
          >
            <img
              className="menu-child1"
              alt=""
              src={require("../assets/images/innerDashboard/group-371@2x.png")}
            />
          </Tooltip>

          {/* <h5>{this.context.translate("_ADVANCE_DASHBOARD_")}</h5> */}
        </Link>

        <Link
          className={
            this.showLaunchingItems["master"] === false && "menu-disabled"
          }
          to={
            this.showLaunchingItems["master"] &&
            (Number(this.props.getUserData().isadmin) === 0
              ? "companyuserlist"
              : "/companylist")
          }
          onClick={() => {
            if (this.showLaunchingItems["master"]) {
              this.updateDashboard(0);
            }
          }}
        >
          <Tooltip placement="top" title={this.context.translate("_MASTERS_")}>
            <img
              className="menu-child2"
              alt=""
              src={require("../assets/images/innerDashboard/group-370@2x.png")}
            />
          </Tooltip>
        </Link>

        {/* <a href="#"> </a> */}

        <Link
          className={
            this.showLaunchingItems["parentalertreportlist"] === false &&
            "menu-disabled"
          }
          to={this.showLaunchingItems["parentalertreportlist"] && "/alertlist"}
          onClick={() => {
            this.showLaunchingItems["parentalertreportlist"] &&
              this.updateDashboard(0);
          }}
        >
          <Tooltip placement="right" title={this.context.translate("_ALERTS_")}>
            <img
              className="menu-child3"
              alt=""
              src={require("../assets/images/innerDashboard/group-369@2x.png")}
            />
          </Tooltip>
          {/* <h5>{this.context.translate("_ALERTS_")}</h5> */}
        </Link>

        <div className="help">
          <div
            onClick={() => {
              this.setState({ showHelpModal: true });
            }}
          >
            <Tooltip placement="right" title={this.context.translate("_HELP_")}>
              <img
                className="menu-child4"
                alt=""
                src={require("../assets/images/innerDashboard/group-368@2x.png")}
              />
            </Tooltip>

            {/* <h5>{this.context.translate("_HELP_")}</h5> */}
          </div>
        </div>

        <Link
          className={
            this.showLaunchingItems["maintenancedashboard"] === false &&
            "menu-disabled"
          }
          to={
            this.showLaunchingItems["maintenancedashboard"] &&
            "/maintenancedashboard"
          }
          onClick={() => {
            this.showLaunchingItems["maintenancedashboard"] &&
              this.updateDashboard(0);
          }}
        >
          <Tooltip
            placement="left"
            title={this.context.translate("_MAINTENANCE_DASHBOARD_")}
          >
            <img
              className="menu-child5"
              alt=""
              src={require("../assets/images/innerDashboard/group-363@2x.png")}
            />
          </Tooltip>
          {/* <h5>{this.context.translate("_MAINTENANCE_DASHBOARD_")}</h5> */}
        </Link>

        {/* <a href="#">
          <img
            className="menu-child5"
            alt=""
            src={require("../assets/images/innerDashboard/group-363@2x.png")}
          />
        </a> */}

        <Link
          className={
            this.showLaunchingItems["dashboard"] === false && "menu-disabled"
          }
          to={this.showLaunchingItems["dashboard"] && "/dashboard"}
          onClick={() => {
            if (this.showLaunchingItems["dashboard"]) {
              this.updateDashboard(0);
            }
          }}
        >
          <Tooltip
            placement="top"
            title={this.context.translate("_DASHBOARD_")}
          >
            <img
              className="menu-child6"
              alt=""
              src={require("../assets/images/innerDashboard/group-364@2x.png")}
            />
          </Tooltip>
          {/* <h5>{this.context.translate("_DASHBOARD_")}</h5> */}
        </Link>

        <Link
          className={
            this.showLaunchingItems["parentreport"] === false && "menu-disabled"
          }
          to={this.showLaunchingItems["parentreport"] && "/trackdatalist"}
          onClick={() => {
            if (this.showLaunchingItems["parentreport"]) {
              this.updateDashboard(0);
            }
          }}
        >
          <Tooltip
            placement="left"
            title={this.context.translate("_FLEET_REPORTS_TAB_")}
          >
            <img
              className="menu-child7"
              alt=""
              src={require("../assets/images/innerDashboard/group-362@2x.png")}
            />
          </Tooltip>

          {/* <h5>{this.context.translate("_FLEET_REPORTS_TAB_")}</h5> */}
        </Link>

        {/* <a href="#">
          {" "}
          <img
            className="menu-child7"
            alt=""
            src={require("../assets/images/innerDashboard/group-362@2x.png")}
          />
        </a> */}

        <Link
          className={
            this.showLaunchingItems["vehiclelist"] === false && "menu-disabled"
          }
          to={this.showLaunchingItems["vehiclelist"] && "/vehiclelist/4"}
          onClick={() => {
            if (this.showLaunchingItems["vehiclelist"]) {
              this.updateDashboard(0);
            }
          }}
        >
          <Tooltip
            placement="top"
            title={this.context.translate("_EXPIRINGSOON_")}
          >
            <img
              className="menu-child8"
              alt=""
              src={require("../assets/images/innerDashboard/group-365@2x.png")}
            />
          </Tooltip>
          {/* <h5>{this.context.translate("_EXPIRINGSOON_")}</h5> */}
        </Link>

        <Link
          className={
            this.showLaunchingItems["detailtracking"] === false &&
            "menu-disabled"
          }
          to={this.showLaunchingItems["detailtracking"] && "/detailtracking"}
          onClick={() => {
            if (this.showLaunchingItems["detailtracking"]) {
              this.updateDashboard(0);
            }
          }}
        >
          <Tooltip
            placement="top"
            title={this.context.translate("_DETAIL_TRACKING_")}
          >
            <img
              className="menu-child9"
              alt=""
              src={require("../assets/images/innerDashboard/group-366@2x.png")}
            />
          </Tooltip>
          {/* <h5>{this.context.translate("_DETAIL_TRACKING_")}</h5> */}
        </Link>

        <Link
          className={
            this.showLaunchingItems["historytracking"] === false &&
            "menu-disabled"
          }
          to={this.showLaunchingItems["historytracking"] && "/historytracking"}
          onClick={() => {
            if (this.showLaunchingItems["historytracking"]) {
              this.updateDashboard(0);
            }
          }}
        >
          <Tooltip
            placement="right"
            title={this.context.translate("_HISTORY_TRACKING_")}
          >
            <img
              className="menu-child10"
              alt=""
              src={require("../assets/images/innerDashboard/group-367@2x.png")}
            />
          </Tooltip>
        </Link>

        <HelpModal
          show={this.state.showHelpModal}
          closeModal={() => this.setState({ showHelpModal: false })}
        />
      </div>
    );
  }
}
LounchingDashboard.contextType = StoreContext;
export default observer(withRouter(LounchingDashboard));
