import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  
  makeObservable,
  observable,
 
} from 'mobx';


class InventoryStore {
  inventory=[]
  warehouseList=[]
  allCompanyArray=[]
  vehicles=[]
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        inventory: observable,
        vehicles:observable,
      total:observable,
      loading:observable,
      loadData:action,
      addData : action,
      getInventory:action,
      deleteRecord:action,
      getWarehouselist:action,
      getVehicles:action,
      getInventoryGridData:action,
      addInventory:action

      
     
    });
     this.rootStore = rootStore;
    
  }

  async loadData({offset,limit,companyId,warehouseId}) {
    this.loading = true;
    console.log("Inventory",warehouseId)

    var postData ={"companyId":companyId,"warehouseId":warehouseId,"offset":offset,"limit":limit}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=inventory&a=getInventories", "postData="+base64Input);
    console.log("Inventory" ,response.data.data)
    this.inventory =response?.data?.data?.inventorydata
     this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }

  async addData(inventoryData){
    var postData =inventoryData
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=inventory&a=update", "postData="+base64Input);
    console.log(response)
    return response.data
  }

  async deleteRecord(inventoryId){
    var postData = inventoryId
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=inventory&a=deleteNew", "postData="+base64Input);
    return response
  }

  async getInventory(id){
    console.log(id)
    var postData ={inventoryId:id,"offset":0,"limit":1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=inventory&a=getInventories", "postData="+base64Input);
    return response.data.data.inventorydata[0]
  }

  async getWarehouselist({companyId}){
    var postData ={companyId:companyId}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=warehouse&a=getWarehouseByCompany", "postData="+base64Input);
   
    this.warehouseList=response.data.data
    return response
  }

  async getVehicles({companyId}){
    console.log(companyId)
    var postData = {"companyId":companyId}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=getVehicles", "postData="+base64Input);
    this.vehicles = response.data.data
    console.log(response)
    return response
    
  }

  async getInventoryGridData(id){
    console.log(id)
    var postData ={Id:id}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=inventory&a=getGridData", "postData="+base64Input);
    console.log(response.data.data)
    return response.data.data;
    
  }
  
  async addInventory(inventoryData){
    var postData =inventoryData
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=wasl&a=registerInventory", "postData="+base64Input);
    console.log(response)
    return response.data
  }

}

export default InventoryStore;
