import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi, baseURL } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class StoppageReportStore {
  stoppageReport = [];
  stoppageArray = []
  total = 0;
  loading = false;
  loadingData = false
  pageSize = 0

  rootStore;


  constructor(rootStore) {
    makeObservable(this, {
      stoppageReport: observable,

      pageSize: observable,
      stoppageArray: observable,
      total: observable,
      loading: observable,
      loadingData: observable,
      loadData: action,
      exportExcel: action,
      exportpdf: action,
      exportcsv: action,
      exportxml: action,
      totalStoppageReport: action
    });
    this.rootStore = rootStore;
  }

  async loadData({
    companyId,
    offset,
    limit,
    vehicleId,
    toDate,
    fromDate,
    interval,
    pageNo,
  }) {
    this.loading = true;
    var postData = { "companyId": companyId, "vehicleId": vehicleId, "interval": interval, "fromDate": fromDate, "toDate": toDate, "offset": offset, "limit": limit, "page": pageNo }

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=stopreport", "postData=" + base64Input);
    this.stoppageReport = response?.data?.data?.StopData;

    this.pageSize = response?.data?.data?.StopData?.length
    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
  }

  async totalStoppageReport({
    companyId,
    offset,
    limit,
    vehicleId,
    toDate,
    fromDate,
    interval,

  }) {

    this.loadingData = true;
    var postData = { "companyId": companyId, "vehicleId": vehicleId, "interval": interval, "fromDate": fromDate, "toDate": toDate, "offset": offset, "limit": limit }

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=totalStopageReport", "postData=" + base64Input);


    this.stoppageArray = response?.data?.data;

    this.loadingData = false;
  }

  async exportExcel(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportstopexcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportstoppdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportstopcsv&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exportxml(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportstopxml&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
}

export default StoppageReportStore;
