import React from "react";
import BlackListPersonStore from "../store/BlackListPersonStore";
import { Input, message, Button, Space, Row, Col, Popconfirm,Tooltip } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import AddButton from "./AddButton";
import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import CompanyAutocomplete from "./CompanyAutocomplete";
import CompanyStore from "../store/CompanyStore";
const { Search } = Input;

class CompanyBlackListPersonList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSearch = this.onSearch.bind(this);

    this.blackListPersonsStore = new BlackListPersonStore(this);
    this.companyStore = new CompanyStore(this)

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      loadList: 0,
      companyId:"",
      companyId_value_label:""
    };
  }
  onEdit = async(record) => {
    await this.context.viewStateStore.setBlackListPerson({companyId:this.state.companyId})
    await this.props.navigate("/blacklistpersonadd/" + record.id);
  };
  async onDelete(record) {
    console.log(record);
    const response = await this.blackListPersonsStore.deleteRecord({
      Id: record.id,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);

      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  async componentDidMount() {
    // await this.companyStore.loadData({ offset: 0, limit: 50 });

    const blackListPersonStates= this.context.viewStateStore.blackListPersonStatesObject
    console.log(blackListPersonStates)
    
    const singleData = await this.companyStore.getCompanyDataById(blackListPersonStates.companyId);
    await this.setState({companyId_value_label:singleData,companyId:blackListPersonStates.companyId})
// if(blackListPersonStates.companyId !==""){
//   this.setState({companyId:blackListPersonStates.companyId})
// }
    await this.blackListPersonsStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: blackListPersonStates.companyId,
    });
    await this.setState({ listUpdated: 1 }); //need to reload data


    this.setState({ pageSize: 50 });
    // await this.handleSearch();

    this.columns = [
      {
        title: "Company",
        dataIndex: "companyName",
        width: "20%",
      },
      {
        title: "Name",
        dataIndex: "name",
        width: "20%",
        render: (value, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        title: "Description",
        dataIndex: "description",
        width: "20%",
      },
      {
        title: "Black Listed At",
        dataIndex: "blacklistedAt",
        width: "20%",
      },
      //   {
      //     title: "Block/Unblock",
      //     dataIndex: "block",
      //     width: "15%",
      //     render: (value, record) => {
      //       if (value == 1) {
      //         return (
      //           <Button
      //             size="5"
      //             onClick={() => {
      //               this.OnCheckBlockUnblock(record);
      //             }}
      //             style={{ backgroundColor: config.activeColor }}
      //             color={config.activeColor}
      //           >
      //             Block
      //           </Button>
      //         );
      //       } else if (value == 0) {
      //         return (
      //           <Button
      //             size="10"
      //             onClick={() => {
      //               this.OnCheckBlockUnblock(record);
      //             }}
      //             style={{ backgroundColor: "red" }}
      //             color={"white"}
      //           >
      //             Unblock
      //           </Button>
      //         );
      //       }
      //     },
      //   },
      {
        title: "Block/Unblock",
        dataIndex: "block",
        width: "15%",
        render: (value, record) => {
          if (value == 1) {
            return (
              <Popconfirm
                title="Block/Unblock"
                okText="YES"
                cancelText="NO"
                description="Are You Sure Wants To Block/Unblock ?"
                onConfirm={() => {
                  this.OnCheckBlockUnblock(record);
                }}

                //  onCancel={handleCancel}
              >
                 <Button size="5" style={{width:"82px",textAlign:"center", backgroundColor: config.deleteColor,color:config.whiteColor }} color={"white"}>
                  Block
                </Button>
              </Popconfirm>
            );
          } else if (value == 0) {
            return (
              <Popconfirm
                title="Block/Unblock"
                okText="YES"
                cancelText="NO"
                description="Are You Sure Wants To Block/Unblock ?"
                onConfirm={() => {
                    this.OnCheckBlockUnblock(record);
                  }}

                //  onCancel={handleCancel}
              >
                <Button size="5"  style={{width:"82px",textAlign:"center", backgroundColor: config.activeColor,color:config.whiteColor }} >
                  Unblock
                </Button>
              </Popconfirm>
            );
          }
        },
      },
      {
        title: "Action",

        width: "5%",
        render: (_, record) => {
          return (
            <>
              <Space>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                <Tooltip title="Edit Details" color={config.tooltipColorEdit}>
                  <EditFilled />
                  </Tooltip>
                </Link>
                <DeleteConfirm
                  onConfirm={(e) => {
                    this.onDelete(record);
                  }}
                >
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                   <Tooltip title="Delete Details" color={config.tooltipColorDelete}>
                    <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }
  OnCheckBlockUnblock = async (record) => {
    const response = await this.blackListPersonsStore.blockUnblockUpdate({
      id: record.id,
      block: record.block,
    });

    await this.blackListPersonsStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: record.companyId,
    });

    await this.setState({ loadList: 1 });
  };
  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({currentPage: 1, companyId: comp.value})

    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({companyId_value_label:singleData,})
   await this.handleSearch();
  }

  async onSearch(value) {
    console.log(value);
    await this.setState({ emailId: value });
    this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.blackListPersonsStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });
    await this.setState({ listUpdated: 1 }); //need to reload data
  }

  render() {
    //console.log('in render1')
    return (
      <>
      <div style={{padding:0}}>
        <Row className="headerFixed" style={{height:"50px"}}>
          <Col span={12}>
            <div className="heading">Black List</div>
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Space
              style={{
                float:
                  this.context.locale.direction == "ltr" ? "right" : "left",
              }}
            >
              <AddButton
                link="/blacklistpersonadd"
                title={this.context.translate("_ADD_")}
              />
            </Space>
          </Col>
        </Row>
        </div>
        <div
        style={{
          paddingTop: "10px",
          paddingRight: "24px",
          paddingLeft: "24px",
        }}
      >
        <Row style={{ padding: "10px 0px" }}>
          <Col span={16}>
            <Space>
              <CompanyAutocomplete
              value={this.state.companyId_value_label !=="" ?this.state.companyId_value_label :null}
                onChange={this.handleChange}
                allowClear={true}
                style={{ width: 200 }}
              />
            </Space>
          </Col>
          <Col span={8}>
            <Space
              style={{
                float:
                  this.context.locale.direction == "ltr" ? "right" : "left",
              }}
            ></Space>
          </Col>
        </Row>

        <ListTable
          current={this.state.currentPage}
          pageSize={this.state.pageSize}
          total={this.blackListPersonsStore?.total}
          columns={this.columns}
          dataSource={this.blackListPersonsStore?.blackListPersons}
          loading={this.blackListPersonsStore?.loading}
          onChange={this.handleTableChange}
        />
      </div>
      </>
    );
  }
}
CompanyBlackListPersonList.contextType = StoreContext;
export default observer(withRouter(CompanyBlackListPersonList));
