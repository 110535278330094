import { encode as base64_encode } from "base-64";

import { postApi, baseURL } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class DetailedJobStore {
  loading = false;
  total = 0;
  detailedJobData = [];
  tripData = [];

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      total: observable,
      detailedJobData: observable,
      tripData: observable,

      loadData: action,
      loadTripData: action,
      exportExcel: action,
      exportpdf: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    companyId,
    vehicleId,
    fromDate,
    toDate,
    offset,
    limit,
    pageNo,
  }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      offset: offset,
      limit: limit,
      pageNo: pageNo,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=dailyTripSheet", "postData=" + base64Input);

    this.detailedJobData = response?.data?.data?.dailytripreport;

    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
  }

  async loadTripData({ vehicleId, fromDate, toDate }) {
    this.loading = true;
    var postData = {
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      driverId: null,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=getDailyTripSheet",
        "postData=" + base64Input
      );

    this.tripData = response?.data?.data;

    this.loading = false;
  }

  async exportExcel(getData) {
    // console.log("getData", getData);
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportdailytripExcel&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }

  async exportpdf(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportdailytripdf&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&userId=" +
      getData.userId +
      "&colList=" +
      getData.colList;
  }
}

export default DetailedJobStore;
