import { encode as base64_encode } from "base-64";

import { postApi, baseURL } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class VehicleAuditSore {
  loading = false;
  total = 0;
  auditList = [];
  vehicleArr = [];
  driverArr = [];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      total: observable,
      auditList: observable,
      vehicleArr: observable,
      driverArr: observable,

      loadData: action,
      deleteRecord: action,
      addData: action,
      exportExcel: action,
      getVehicleData: action,
      getDriverData: action,
      getVehAuditData: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit, page }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      offset: offset,
      limit: limit,
      page: page,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicleAudit", "postData=" + base64Input);

    this.auditList = response?.data?.data?.AuditData;
    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
  }

  async addData(vehAuditDetails) {
    var postData = vehAuditDetails;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=updateAudit", "postData=" + base64Input);

    return response.data;
  }

  async getVehAuditData({ Id, companyId }) {
    var postData = {
     Id: Id,
      companyId: companyId,
      limit: 1,
      offset: 0,
    };
    // console.log(postData)

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicleAudit", "postData=" + base64Input);

    return response?.data?.data?.AuditData[0];
  }

  async deleteRecord({ Id }) {
    const postData = {
      Id: Id,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=removevehicleAudit", "postData=" + base64Input);

    return response;
  }

  async exportExcel(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportVehicleAudit&cid=" +
      getData.companyId +
      "&colList=" +
      getData.colList;
  }

  async getVehicleData(companyId) {
    var postData = {
      companyId: companyId,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=getvehicleDetails",
        "postData=" + base64Input
      );
    this.vehicleArr = response.data.data;
  }

  async getDriverData(companyId) {
    var postData = {
      companyId: companyId,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=driver&a=getDriver", "postData=" + base64Input);
    this.driverArr = response.data.data;
  }
}

export default VehicleAuditSore;
