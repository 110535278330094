import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi, baseURL } from "../components/Utility";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from "mobx";
import { act } from "react-dom/test-utils";

class SpeedViolationReportStore {
  total = 0;
  loading = false;
  speedViolationStore = [];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      total: observable,

      speedViolationStore: observable,
      loadData: action,
      sendExcelByEmail: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    companyId,
    vehicleId,
    offset,
    limit,
    toDate,
    fromDate,
    speedlimit,
    currentPage,
  }) {
    this.loading = true;
    console.log("vehicle list");
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      speedlimit: speedlimit,
      offset: offset,
      limit: limit,
      page: currentPage,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=speedexception", "postData=" + base64Input);

    console.log(response?.data?.data?.speedexception);
    this.speedViolationStore = response?.data?.data?.speedexception;
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportspeedexcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      // "&int=" +
      // getData.intervel +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&speedlimit=" +
      getData.speedlimit +
      "&colList=" +
      getData.colList;
  }
  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportspeedpdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      // "&int=" +
      // getData.intervel +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&speedlimit=" +
      getData.speedlimit +
      "&colList=" +
      getData.colList;
  }
  async exportcsv(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportspeedcsv&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      // "&int=" +
      // getData.intervel +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&speedlimit=" +
      getData.speedlimit +
      "&colList=" +
      getData.colList;
  }
  async exportxml(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportspeedxml&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      // "&int=" +
      // getData.intervel +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&speedlimit=" +
      getData.speedlimit +
      "&colList=" +
      getData.colList;
  }

  async sendExcelByEmail(data) {
    var postData = data;
    console.log(postData);
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=saveSpeedViolationDetails",
        "postData=" + base64Input
      );
    console.log(response);

    return response.data;
  }
}

export default SpeedViolationReportStore;
