import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import config from "../config";
import { action, makeObservable, observable } from "mobx";

class SendCommandPopupStore {
  constructor(rootStore) {
    makeObservable(this, {
      //   sim: observable,

      sendVehicleCmd: action,
    });
    this.rootStore = rootStore;
  }

  async sendVehicleCmd(data) {
    // console.log("store",simDetails)
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=sendVehicleCmd", "postData=" + base64Input);
    return response;
  }
}

export default SendCommandPopupStore;
