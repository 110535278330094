import { decode as base64_decode, encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";

import { action, makeObservable, observable } from "mobx";

class TempGraphStore {
  tempGraph = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      tempGraph: observable,
      total: observable,
      loading: observable,
      loadTempGraphData: action,
    });
    this.rootStore = rootStore;
  }

  async loadTempGraphData({
    companyId,
    vehicleId,
    fromDate,
    toDate,
    tempMax,
    tempMin,
    intervel,
  }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      tempMax: tempMax,
      tempMin: tempMin,

      intervel: intervel,
      fromDate: fromDate,
      toDate: toDate,

      offset: 0,
      limit: 15,
      page: 1,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=tempGraph", "postData=" + base64Input);

    var tempGraphData = response?.data?.data?.temprep;
    // if (tempGraphData && tempGraphData) {
    //   tempGraphData.forEach((element) => {
    //     element.temp1 = parseFloat(element.temp1);
    //   });
    // }

    if (tempGraphData) {
      tempGraphData.forEach((element) => {
        
        // if (element.temp1) {
          element.temp1 =element.temp1? parseInt(element.temp1) :0;
        // }
        if (element.temp2) {
          element.temp2 =element.temp2 ? parseInt(element.temp2):0;
        }
        if (element.temp4) {
          element.temp4 =element.temp4 ? parseInt(element.temp4):0;
        }
        if (element.temp3) {
          element.temp3 =element.temp3 ? parseInt(element.temp3):0;
        }
      });
    }
    this.tempGraph = tempGraphData;

    // this.distanceGraph = response?.data?.data?.distancegraph;
    return response?.data?.data?.temprep;
  }
}

export default TempGraphStore;
