import React, { Component } from "react";

import {
  Form,
  Space,
  Button,
  Input,
  DatePicker,
  Radio,
  Modal,
  Checkbox,
  Upload,
  Row,
  Col,
  message,
  Spin,
} from "antd";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import DeviceListStore from "../store/DeviceListStore";
import config from "../config";
import dayjs from "dayjs";
const dateFormat = "YYYY-MM-DD HH:mm:ss";
const currentDateTime = dayjs();
class DeviceDetailsAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = { Id: -1, show: false, loading: false, loadingSpin: false };
    this.deviceStore = new DeviceListStore();
  }

  onDeviceDetailsSubmit = async (values) => {
    this.setState({ loading: true });
    console.log(values);
    const DeviceDetails = {
      ...values,
      companyId: 1,
      status: 1,
      Id: this.state.Id,
      shipment: values.shipment
        ? values.shipment.format("YYYY-MM-DD HH:mm:ss")
        : null,
      warranty: values.warranty
        ? values.warranty.format("YYYY-MM-DD HH:mm:ss")
        : null,

      // shipment: values?.shipment?.format("YYYY-MM-DD hh:mm:ss"),
      // warranty: values?.warranty?.format("YYYY-MM-DD 00:00:00"),
    };
    console.log(DeviceDetails);
    const data = {
      tokenId: "80bovn8u800tg1aq9bvr73kbhh",
      DeviceDetails: DeviceDetails,
    };
    const response = await this.deviceStore.addData(data);
    // if (response?.success === "S") {
    //   message.success(response.message);
    //   this.props.navigate("/devicelist");
    // } else {
    //   message.error(response.message);
    // }
    await this.props.response(response);
    await this.setState({ loading: false });
  };

  async componentDidMount() {
    console.log(this.props.params);
    const Id = this.props.Id;
    console.log(Id);

    if (Id) {
      this.filldata(Id);
      this.setState({ Id: Id });
    }
    this.setState({ update: 1 });
  }

  async filldata(Id) {
    await this.setState({ loadingSpin: true });
    console.log(Id);
    const getDeviceDetails = await this.deviceStore.getDeviceDetails(Id);
    console.log("getdata", getDeviceDetails);

    await this.setState({
      companyId: getDeviceDetails.companyId,
      Id: getDeviceDetails.Id,
    });

    this.formRef.current.setFieldsValue({
      Id: getDeviceDetails.Id,
      imeiNo: getDeviceDetails.imeiNo,
      model: getDeviceDetails.model,
      stockstatus: getDeviceDetails.stockstatus,
      shipment: getDeviceDetails.shipment
        ? dayjs(getDeviceDetails.shipment)
        : null,
      warranty: getDeviceDetails.warranty
        ? dayjs(getDeviceDetails.warranty)
        : null,
      // shipment: getDeviceDetails.shipment,
      // warranty: getDeviceDetails.warranty,
    });
    await this.setState({ loadingSpin: false });
  }

  validateNumber = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1);
    if (value1 > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  closeModal=()=>{
    this.props.closeModal()
  }

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              layout="vertical"
              onFinish={this.onDeviceDetailsSubmit}
              ref={this.formRef}
              // {...this.layout}
              name="nest-message"
              initialValues={{
                shipment:  dayjs(),
                warranty:  dayjs(),
              }}
              className="formClass" //change
            >
              {/* <div className="headingTitle">Add Device Details</div> */}
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="imeiNo"
                    label={this.context.translate("_IMEI_NO_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      onChange={(e) =>
                        this.validateNumber(e.target.value, "imeiNo")
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name="model"
                    label={this.context.translate(
                      "_FLEET_VEHICLE_VEHICLEDETAIL_MODEL_"
                    )}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="shipment"
                    label={this.context.translate("_SHIPMENT_RECEIVED_DATE_")}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      showTime
                      // format = "YYYY-MM-DD 00:00:00"
                      format={dateFormat}
                      style={{ width: "100%" }}
                      onChange={(date) => {
                        console.log(date);
                        if (date === null) {
                          this.setState({ shipment: "" });
                        } else {
                          const format = "YYYY-MM-DD 00:00:00";
                          console.log(date.format(format));
                          this.setState({ shipment: date.format(format) });
                        }
                      }}
                    />
                    {/* <DatePicker
                    style={{ width: "100%" }}
                    format={"DD-MM-YYYY HH:mm:ss"}
                    onChange={(date) => {
                      // Check if the date is valid
                      if (date && date.isValid()) {
                        // Set the time to the beginning of the day (00:00:00)
                        const startOfDay = date.startOf("day");

                        // Update the form field value
                        this.formRef.current.setFieldsValue({
                          shipment: startOfDay,
                        });
                      }
                    }}
                  /> */}
                  </Form.Item>

                  <Form.Item
                    name="warranty"
                    label={this.context.translate("_WARRANTY_VALIDITY_DATE_")}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      showTime
                      format={dateFormat}
                      style={{ width: "100%" }}
                      onChange={(date) => {
                        console.log(date);
                        if (date === null) {
                          this.setState({ warranty: "" });
                        } else {
                          const format = "YYYY-MM-DD 00:00:00";
                          console.log(date.format(format));
                          this.setState({ warranty: date.format(format) });
                        }
                      }}
                    />

                    {/* <DatePicker
                    style={{ width: "100%" }}
                    format={"DD-MM-YYYY HH:mm:ss"}
                    onChange={(date) => {
                      // Check if the date is valid
                      if (date && date.isValid()) {
                        // Set the time to the beginning of the day (00:00:00)
                        const startOfDay = date.startOf("day");

                        // Update the form field value
                        this.formRef.current.setFieldsValue({
                          warranty: startOfDay,
                        });
                      }
                    }}
                  /> */}
                  </Form.Item>
                </Col>
              </Row>
              <div className="formBtnGroup">
                {/* <Link to={"/devicelist"}>
                  {" "}
                  <Button ghost type="primary">
                    {" "}
                    Back{" "}
                  </Button>
                </Link> */}
                <Button
                  className="formCancelButton"
                  type="primary"
                  onClick={(e) => {
                    this.closeModal()
                    // this.formRef.current?.resetFields();
                  }}
                >
                  {this.context.translate("_FLEET_CLOSE_BTN_")}

                  {/* Cancel */}
                  {/* {this.context.translate("_FLEET_DASHBORD_SHOWPOI_CANCELBTN_")} */}
                </Button>
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

DeviceDetailsAdd.contextType = StoreContext;
export default observer(withRouter(DeviceDetailsAdd));
