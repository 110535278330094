import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
  observe,
} from 'mobx';
import { act } from 'react-dom/test-utils';
import { concat } from 'lodash';

class BlackListPersonStore {

  blackListPersons=[]
  
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        
      blackListPersons:observable,
      total:observable,
      loading:observable,
      
      loadData:action,
      addData:action,
     
     
    });
     this.rootStore = rootStore;
    
  }

  async loadData({companyId,offset,limit}) {
    this.loading = true;
  console.log("black list")
    var postData = {"companyId":companyId,"offset":offset,"limit":limit}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=blacklist&a=getBlackListRecords", "postData="+base64Input);
    console.log("Blacklist Data",response?.data?.data?.blacklistdetails)
    this.blackListPersons = response?.data?.data?.blacklistdetails
  
     this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }

  async addData(blackListPerson){
    console.log(blackListPerson,"blacklistperson")
    var postData = blackListPerson
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=blacklist&a=update", "postData="+base64Input);
    console.log("response",response)
    return response.data
  }

  async getBlackListPerson(blacklistPersonId){
    console.log("blacklist person str",blacklistPersonId)
    var postData = {"bid":blacklistPersonId,offset:0,limit:1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=blacklist&a=getBlackListRecords", "postData="+base64Input);
    console.log("getBlacklist resp", response.data.data.blacklistdetails[0] )
    return response.data.data.blacklistdetails[0]
  }

  async deleteRecord(id){
    console.log(id)
    var postData = id
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=blacklist&a=delete", "postData="+base64Input);
    console.log("getBlacklist resp", response )
    return response
  }

  async blockUnblockUpdate({id,block}){
    var postData = {id:id,block:block}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=blacklist&a=blockUnblockUpdate", "postData="+base64Input);
    console.log("blcklist", response )
    return response
  }

}

export default BlackListPersonStore;
