import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import moment from "moment/moment";

class MultiTrackingStore {
  multiTrackingArray = [];
  vehiclePosition = [];
  vehicleUpdatedPosition = [];
  vehicleLiveLatLongArray = [];
  vehiCleLiveLatLongObj = {};
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      multiTrackingArray: observable,
      vehiclePosition: observable,
      vehicleUpdatedPosition: observable,
      vehicleLiveLatLongArray: observable,
      vehiCleLiveLatLongObj: observable,
      total: observable,
      loading: observable,
      loadData: action,
      getSelectedVehicles: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ offset, limit, companyId, warehouseId,poupSearch }) {
    this.loading = true;
    var postData = {
      tokenId: "",
      companyId: companyId,
      vehicleNo: "",
      driverName: "",
      offset: offset,
      limit: 5,
      "poupSearch": poupSearch,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicletracking&a=getdata", "postData=" + base64Input);
    console.log("multitracking ", response.data);
    // this.multiTrackingArray =response?.data?.data?.livetrack
    var multiTrackingArray = response?.data?.data?.livetrack;
    console.log(multiTrackingArray, "multitrackingArray");
    Object.keys(multiTrackingArray).map((e, i) => {
      let r = multiTrackingArray[i];

      // var diff = new Date(r.currtime) - new Date(r.updatedate);
      const diff = moment(r.currtime).diff(r.updatedate, "minutes");

      var vehicleTypeNew = r.vehicleTypeNew.split(" ").join("-");

      const acc = parseInt(r.acc);
      const speed = parseInt(r.speed);
      // console.log(r, diff, vehicleTypeNew);
      // console.log(differenceInHours)

      if (diff >= 90) {
        multiTrackingArray[i].listImage = vehicleTypeNew + "-gray.png";
        multiTrackingArray[i].rowBackground = "gray";
        multiTrackingArray[i].currentStatus = "nodata";
      } else if (acc == 1 && speed == 0) {
        //idle)
        multiTrackingArray[i].listImage = vehicleTypeNew + "-yellow.png";
        multiTrackingArray[i].rowBackground = "yellow";
        multiTrackingArray[i].currentStatus = "idle";
      } else if (acc == 0) {
        multiTrackingArray[i].listImage = vehicleTypeNew + "-red.png";
        multiTrackingArray[i].rowBackground = "red";
        multiTrackingArray[i].currentStatus = "stop";
      } else if (acc == 1 && speed > 0) {
        // console.log(multiTrackingArray[i]);
        multiTrackingArray[i].listImage = vehicleTypeNew + "-green.png";
        multiTrackingArray[i].rowBackground = "green";
        multiTrackingArray[i].currentStatus = "running";
      }
      multiTrackingArray[i].mapImage = multiTrackingArray[i].listImage;

      try {
        require("../assets/images/detailtracking/" +
          multiTrackingArray[i].listImage);
      } catch (err) {
        multiTrackingArray[i].listImage = "car-icon-blue.png";
      }

      try {
        require("../assets/images/livetracking/" +
          multiTrackingArray[i].mapImage);
      } catch (err) {
        multiTrackingArray[i].mapImage = "car-icon-blue.png";
      }
    });
    this.multiTrackingArray = multiTrackingArray;
    console.log(multiTrackingArray);
    //  this.totalvehstate = response?.data?.data?.totalvehstate;
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }
  async getVehiclePosition({ vehicleId }) {
    var postData = { tokenId: "", vehicleId: vehicleId, refreshduration: 30 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=getvehicleposition",
        "postData=" + base64Input
      );
    // this.vehiclePosition = response
    // console.log(response.data.data.vehicleposition)
    let vehicle = [
      response.data.data.vehicleposition[0].latitude,
      response.data.data.vehicleposition[0].longitude,
    ];
    console.log(vehicle);
    this.vehicleUpdatedPosition.push(vehicle);
    console.log(this.vehicleUpdatedPosition, "updated");
    this.vehiclePosition = [
      [21, 21],
      [54, 21],
    ];
    return [
      response.data.data.vehicleposition[0].latitude,
      response.data.data.vehicleposition[0].longitude,
    ];
  }

  async getSelectedVehicles({ vehicleIds, selectedVehicles }) {
    console.log(vehicleIds);
  
    vehicleIds.forEach((vehicleId) => {
      let filterVehicle = this.multiTrackingArray.find((val) => val.vehicleId === vehicleId);
      console.log(filterVehicle);
  
      if (filterVehicle) {
        const { vehicleId, latitude, longitude, mapImage,formatted_address } = filterVehicle;
        if (!this.vehiCleLiveLatLongObj[vehicleId]) {
          this.vehiCleLiveLatLongObj[vehicleId] = {}; // Initialize an empty object for the vehicleId
          this.vehiCleLiveLatLongObj[vehicleId].liveLatLongArray = [[latitude, longitude]];
          this.vehiCleLiveLatLongObj[vehicleId].mapImage = [mapImage];
          this.vehiCleLiveLatLongObj[vehicleId].formatted_address = [formatted_address];
        }
         else {
          // this.vehiCleLiveLatLongObj[vehicleId].liveLatLongArray.push([latitude, longitude]);
          this.vehiCleLiveLatLongObj[vehicleId].liveLatLongArray = [
            ...(this.vehiCleLiveLatLongObj[vehicleId].liveLatLongArray
              ? this.vehiCleLiveLatLongObj[vehicleId].liveLatLongArray
              : []),
            [latitude, longitude],
          ];
          this.vehiCleLiveLatLongObj[vehicleId].mapImage.push([mapImage]);
          this.vehiCleLiveLatLongObj[vehicleId].formatted_address.push([formatted_address]);
        }
      }
    });

   
  
    this.vehicleLiveLatLongArray = selectedVehicles;
  }


  
}

export default MultiTrackingStore;


