import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class AssignZoneToFairStore {
  geoDataArr = [];
  selectedZoneArr = [];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      geoDataArr: observable,
      selectedZoneArr: observable,

      getGeofenceData: action,
      getSelectedZone: action,
      addZoneData:action
    });
    this.rootStore = rootStore;
  }

  async getGeofenceData(companyId) {
    var postData = { companyId: companyId };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=geofence&a=geoData", "postData=" + base64Input);

    this.geoDataArr = response.data.data.geofence;
  }

  async getSelectedZone({ companyId, parameterId }) {
    var postData = {
      companyId: companyId,
      parameterId: parameterId,
      offset: 0,
      limit: 50,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=taxi&a=getSelectedZone", "postData=" + base64Input);

    this.selectedZoneArr = response.data.data;
  }

  async addZoneData(values) {
    var postData = values;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=taxi&a=assigneZone", "postData=" + base64Input);

    return response.data;
  }
}

export default AssignZoneToFairStore;
