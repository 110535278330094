import React from "react";
import { Input, Button, Space, Row, Col, Form, Modal, message } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import DeviceTestingStore from "../store/DeviceTestingStore";
import config from "../config";

class CompanyDeviceTestingList extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.deviceTestingStore = new DeviceTestingStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openDeviceTestingForm: true,
    };
  }
  // async componentDidMount() {
  //   await this.companyStore.loadData({ offset: 0, limit: 50 });
  //   await this.context.distributorStore.distributorList();
  //   this.setState({ pageSize: 50 });
  // }

  // async handleSearch() {
  //   await this.companyStore.loadData({
  //     offset: this.state.currentPage - 1,
  //     limit: this.state.pageSize,
  //   });
  // }
async componentDidUpdate(){

  if(Number(this.props.getUserData().isadmin)===1){
    this.props.navigate("/login");
  }
}
  onDeviceDetailsSubmit = async(values) => {
    this.setState({ loading: true });
    console.log(values);
    const response = await this.deviceTestingStore.addData(values);
    console.log(response)
    if (response?.success === "S") {
      message.success(response.message);
     
   } else {
     message.error("Device not connected to server");
   }
   this.setState({ loading: false });
  };

  render() {
    return (
      <>
        {/* <div className="changePassHeadline">Device Testing</div> */}
        <div className="changePassHeadline">{this.context.translate("_DEVICE_TESTING_")}</div>
        <div className="changePassLayout">
          <Form
            // {...layout}
            className="changePassForm"
            name="nest-messages"
            onFinish={this.onDeviceDetailsSubmit}
            layout="vertical"
            ref={this.formRef}
          >
            <Form.Item
              name="imei"
              // label="Enter IMEI"
                label={this.context.translate("_ENTER_IMEI_")}
              rules={[{ required: true, message: this.context.translate("_PLEASE_ENTER_IMEI_")}]}
            >
              <Input />
            </Form.Item>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "24px 0 0 0",
              }}
            >
              <Button
                className="changePassBtn"
                type="primary"
                onClick={() => this.formRef.current.resetFields()}
                style={{ background: "#181722" }}
              >
                {/* Cancel */}
                {this.context.translate("_FLEET_DASHBORD_SHOWPOI_CANCELBTN_")}
              </Button>
              <Button
                className="changePassBtn"
                type="primary"
                htmlType="submit"
                loading={this.state.loading}
              >
                {/* Submit */}
                {this.context.translate("_SUBMIT_")}
              </Button>
            </div>
          </Form>
        </div>
      </>
    );
  }
}
CompanyDeviceTestingList.contextType = StoreContext;
export default observer(CompanyDeviceTestingList);
