import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from "mobx";

class AssignVehicleToCompanyStore {
  total = 0;
  loading = false;
  vehicles = [];
  getVehiclesUsingCompanyId = [];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      vehicles: observable,
      // getVehiclesUsingCompanyId: observable,
      getAssetData: action,
      addData: action,
    });
    this.rootStore = rootStore;
  }

  async addData(data) {
    console.log("data", data);
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=assignVehicle", "postData=" + base64Input);
    console.log(response);
    return response;
  }

  async getAssetData({companyId}) {
    console.log(companyId, "id");
    const postData = { companyId: companyId, userId: 1, isadmin: -1 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);
    // console.log(response);

    this.getVehiclesUsingCompanyId = response?.data?.data;
    return response?.data?.data


  }
}

export default AssignVehicleToCompanyStore;
