import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Modal,
  Radio,
  Input,
  Image,
  Table,
  Popover,
  Spin,
  Switch,
  FloatButton,
} from "antd";
// import React, { PureComponent } from 'react';

import { Column } from "@ant-design/plots";
import React from "react";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import TrackingDashboardStore from "../store/TrackingDashboardStore";

import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import CompanyAutocomplete from "./CompanyAutocomplete";
import {
  MapContainer,
  Marker,
  Popup,
  Tooltip as MapTooltip,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Icon, divIcon, point } from "leaflet";
import withRouter from "../withRouter";
import config from "../config";

import {
  CloseOutlined,
  FullscreenOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import MapForVehiclePosition from "./MapForVehiclePosition";
import L from "leaflet";
import dayjs from "dayjs";
import DashboardUsageGraph from "./DashboardUsageGraph";
import { FullscreenControl } from "react-leaflet-fullscreen";
import { Link } from "react-router-dom";

class TrackingDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.trackingDashboardStore = new TrackingDashboardStore();
    this.mapRef = React.createRef();

    this.state = {
      showDetailTrackingModal: false,
      showDetailStatusModal: false,
      showExpired: false,
      showNotificationList: false,
      position: [],
      showMap: false,
      usageGraph: false,
      distanceGraph: false,
      stateUpdate: 0,
      vehicleObj: false,
      locationObj: false,
      showGeofence: false,
      mapBtn: false,
      satelliteBtn: false,
      trafficBtn: false,
      status: "normal",
      mapBtn_2: false,
      satelliteBtn_2: false,
      trafficBtn_2: false,
      type_2: "",
      driverOrVehicleSearchArray: [],
      searchText: "",
      companyId: "",
      loading: false,
    };
  }

  async handleChange() {
    // await this.handleSearch();

    const usageGraphData = await this.trackingDashboardStore.getUsageGraph({
      companyId: this.state.companyId,
    });

    const maxSpeed = await this.trackingDashboardStore.getMaxSpeedGraph({
      companyId: this.state.companyId,
      maxspeed: "maxspeed",
    });

    const DistanceGraph = await this.trackingDashboardStore.getDistanceGraph({
      companyId: this.state.companyId,
      distance: "distance",
    });

    await this.trackingDashboardStore.getNotificationData(this.state.companyId);
    await this.setState({ loading: true });
    await this.trackingDashboardStore.loadData({
      // offset: ((this.state.currentPage - 1) * this.state.pageSize),
      // limit: this.state.pageSize,
      companyId: this.state.companyId,
      status: this.state.status,
      // type:this.state.type
    });
    await this.setState({ loading: false });
    await this.trackingDashboardStore.getLiveRouteData({
      companyId: this.state.companyId,
    });
    this.setState({ update: 1 });
    let x = setInterval(() => {
      clearInterval(x);
      this.handleChange();
    }, 60000);
    // console.log("data",data)

    await this.setState({
      stateUpdate: 1,
      driverOrVehicleSearchArray: this.trackingDashboardStore?.trackData,
    });
  }

  async handleSearch() {
    await this.trackingDashboardStore.loadData({
      companyId: this.state.companyId,
      status: this.state.status,
    });

    await this.setState({
      loadData: 1,
      driverOrVehicleSearchArray: this.trackingDashboardStore?.trackData,
    });
  }

  detailTracking = () => {
    return (
      <div className="dataTbl-wrapper" style={{ height: "45vh" }}>
        <Table
          bordered
          current={this.state.currentPage}
          // total={this.trackingDashboardStore?.total}
          columns={this.columns}
          dataSource={this.state.driverOrVehicleSearchArray}
          loading={this.trackingDashboardStore?.loading}
          onChange={this.handleTableChange}
          pagination={false}
          scroll={{
            y: 360,
          }}
          // style={{ maxHeight: "450px" }}
        />
      </div>
    );
  };

  async handleTableChange(pagination, filters, sorter) {
    // console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }
  async componentDidUpdate(prevProps, prevState) {
    // When the modal is visible, trigger a map refresh to ensure it renders properly
    // await this.liveLatLongGetting()
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    });
    if (this.state.vehicleObj !== prevState.vehicleObj) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }
  }

  async componentDidMount() {
    this.setState({ pageSize: 50 });

    this.columns = [
      {
        title: this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        width: 100,

        render: (_, record) => {
          // console.log("recrd", record.listImage);
          return (
            <Space>
              <Image
                width={30}
                src={require("../assets/images/detailtracking/" +
                  record.listImage)}
              />

              {record.vehicleNo}
            </Space>
          );
        },
      },
      {
        title: this.context.translate("_DRIVER_"),
        dataIndex: "name",
        width: 100,
        // width:"10%",
      },
      {
        title: this.context.translate("_LOCATION_"),
        dataIndex: "formatted_address",
        width: 110,
      },
      {
        title: this.context.translate("_TIME_"),

        dataIndex: "currtime",
        width: 90,
        render: (value) => {
          if (value) {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          } else {
            return <></>;
          }
        },
        // width:"10%"
      },
      {
        title: this.context.translate("_FLEET_SERVICE_POILIST_MAP_"),
        dataIndex: "",
        width: 55,
        // width:"10%"
        render: (value) => {
          return (
            <>
              <Image
                // onClick={()=>}
                src={require("../assets/images/map-icon.png")}
                // style={{ width: "30px", height: "30px" }}
                width={20}
                onClick={() => {
                  this.setState({ vehicleObj: value });
                }}
              />
            </>
          );
        },
      },
    ];

    this.columns1 = [
      {
        title: this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        render: (_, record) => {
          // console.log("recrd", record.listImage);
          return (
            <Space>
              <Image
                width={35}
                src={require("../assets/images/detailtracking/" +
                  record.listImage)}
              />

              {record.vehicleNo}
            </Space>
          );
        },
      },
      {
        title: this.context.translate("_DRIVER_NAME_"),
        dataIndex: "name",
        // width:"10%",
      },
      {
        title: this.context.translate("_EXPIRY_DATE_"),
        dataIndex: "expiryDate",
        render: (value) => {
          if (value) {
            const formattedDate = dayjs(value).format("DD-MM-YYYY");
            return <>{formattedDate}</>;
          } else {
            return <></>;
          }
        },
        // width:"10%",
      },
      {
        title: this.context.translate("_STATUS_"),
        dataIndex: "status",
        render: (s, record, rowIndex) => {
          const currentDate = dayjs();
          const formattedDate = currentDate.format("YYYY-MM-DD");

          var expirydate = record.expiryDate;

          var dt1 = dayjs();
          const futureDate = dt1.add(15, "day");
          const nextday = futureDate.format("YYYY-MM-DD");

          return s == 1 && expirydate >= currentDate && expirydate <= nextday
            ? "Expiring Soon"
            : s == 1 && expirydate <= currentDate
            ? "Expired"
            : s == 1
            ? this.context.translate(
                "_FLEET_VEHICLE_VEHICLELIST_STATUS_ACTIVE_"
              )
            : this.context.translate(
                "_FLEET_VEHICLE_VEHICLELIST_STATUS_INACTIVE_"
              );
        },
      },
    ];

    this.columns2 = [
      {
        title: this.context.translate("_ASSET_NAME_"),
        dataIndex: "vehicleNo",
        width: 10,
      },
      {
        title: this.context.translate("_ALERT_TYPE_"),
        dataIndex: "ruleSelector",
        width: 12,
      },

      {
        title: this.context.translate("_DATE_TIME_"),
        dataIndex: "datetime",
        width: 12,
        render: (value) => {
          if (value) {
            const formattedDate = dayjs(value).format("DD-MM-YYYY hh:mm:ss A");
            return <>{formattedDate}</>;
          } else {
            return <></>;
          }
        },
      },
      // {
      //   title: this.context.translate("_SPEED_"),
      //   dataIndex: "Speed",
      //   // width:"10%",
      // },
      {
        title: this.context.translate("_FLEET_DASHBORD_SHOWGEO_ADDRESS_"),
        dataIndex: "address",
        width: 15,
        render: (value, obj) => {
          return (
            <Link
              onClick={async (e) => {
                // await this.setState({ showMap: true, modalInfoObj: obj });
                await this.setState({
                  locationObj: {
                    ...obj,
                    // latitude: obj.Latitude,
                    // longitude: obj.Longitude,
                    icon: "logo_icon.png",
                  },
                  heading: this.context.translate("_LOCATION_ON_MAP_"),
                });
                await this.setState({ loadData: 1 });
              }}
            >
              {value}
            </Link>
          );
        },
      },
    ];

    this.geofenceColumns = [
      {
        title: this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
      },
      {
        title: this.context.translate("_DRIVER_"),
        dataIndex: "name",
        // width:"10%",
      },
      {
        title: this.context.translate("_IN_TIME_"),
        dataIndex: "inTime",
        // width:"10%",
        render: (value) => {
          const formattedDateTime = dayjs(value).format(
            "DD-MM-YYYY hh:mm:ss A"
          );
          return <>{formattedDateTime}</>;
        },
      },
      {
        title: this.context.translate("_OUT_TIME_"),
        dataIndex: "outTime",
        // width:"10%",
        render: (value) => {
          if (value) {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        title: this.context.translate("_TYPE_"),
        dataIndex: "typeName",
        // width:"10%",
      },
      {
        title: this.context.translate("_FLEET_REPORTS_GEOFENCEREPORT_GEOPOI_"),
        dataIndex: "geoname",
        // width:"10%",
      },
      {
        title: this.context.translate("_ELAPSED_TIME_"),
        dataIndex: "elapsedTime",
        // width:"10%",
      },
    ];
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleChange();
      // await this.handleSearch();
      // const usageGraphData = await this.trackingDashboardStore.getUsageGraph({
      //   companyId: this.props.getUserData().userCompanyId,
      // });
      // const maxSpeed = await this.trackingDashboardStore.getMaxSpeedGraph({
      //   companyId: this.props.getUserData().userCompanyId,
      //   maxspeed: "maxspeed",
      // });
      // console.log(maxSpeed);
      // const DistanceGraph = await this.trackingDashboardStore.getDistanceGraph({
      //   companyId:this.props.getUserData().userCompanyId,
      //   distance: "distance",
      // });
      // await this.trackingDashboardStore.getLiveRouteData({
      //   companyId: this.props.getUserData().userCompanyId,
      // });

      // console.log(DistanceGraph);

      // console.log(this.trackingDashboardStore?.totalvehiclestate)
      // await this.setState({update:1})
    }
  }

  customIcon = new Icon({
    // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
    iconUrl: require("../assets/images/car.png"),
    iconSize: [38, 38], // size of the icon
  });
  createClusterCustomIcon = function (cluster) {
    return new divIcon({
      html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
      className: "custom-marker-cluster",
      iconSize: point(45, 45, true),
    });
  };
  rotateIcon = (iconUrl, rotation) => {
    const icon = L.divIcon({
      iconAnchor: [0, 0],
      iconSize: [0, 0],
      // iconAnchor: [10, 20], // Center of the icon
      html: `<img src=${require(`../assets/images/livetracking/${iconUrl}`)} style="transform: rotate(${Number(
        rotation
      )}deg) ;  width: 20px; height: 35px;">`,
    });

    return icon;
  };

  iconCreateFunction = function (cluster) {
    //   console.log('innn')
    //console.log(cluster._markers[0].options.currentStatus);
    const markers1 = cluster._markers;
    var idle = 0;
    var stopped = 0;
    var running = 0;
    var nodata = 0;
    var indexA = "";
    var clusterIconType = [];

    const markers = cluster.getAllChildMarkers();

    Object.keys(markers).map((e, i) => {
      var currentStatus = markers[i].options.currentStatus;

      var lastReportingTime = markers[i].options.options.obj?.updatedate;
      var serverCurrentTime = markers[i].options.options.obj?.currtime;

      var acc = markers[i].options.options.obj?.acc;
      var speed = markers[i].options.options.obj?.speed;

      var diff = new Date(serverCurrentTime) - new Date(lastReportingTime);

      diff /= 60000; //diff convert in minutes.

      if (diff >= 90) {
        if (!clusterIconType.includes(1)) {
          clusterIconType.push(1);
        }
      } else if (acc == 1 && speed == 0) {
        //idle
        if (!clusterIconType.includes(2)) {
          clusterIconType.push(2);
        }
      } else if (acc == 0 || speed == null) {
        //stopped
        if (!clusterIconType.includes(3)) {
          clusterIconType.push(3);
        }
      } else if (acc == 1 && speed > 0) {
        //running
        if (!clusterIconType.includes(4)) {
          clusterIconType.push(4);
        }
      }
      // if (currentStatus == "idle") {
      //   idle++;
      // }
      // if (currentStatus == "stopped") {
      //   stopped++;
      // }
      // if (currentStatus == "running") {
      //   running++;
      // }
      // if (currentStatus == "nodata") {
      //   nodata = nodata + 1;
      // }
    });

    switch (parseInt(clusterIconType.sort().join(""))) {
      case 1:
        indexA = 1;
        break;
      case 2:
        indexA = 2;
        break;
      case 3:
        indexA = 3;
        break;
      case 4:
        indexA = 4;
        break;
      case 12:
        indexA = 5;
        break;
      case 13:
        indexA = 6;
        break;
      case 14:
        indexA = 7;
        break;
      case 23:
        indexA = 8;
        break;
      case 24:
        indexA = 9;
        break;
      case 34:
        indexA = 10;
        break;
      case 123:
        indexA = 11;
        break;
      case 124:
        indexA = 12;
        break;
      case 134:
        indexA = 13;
        break;
      case 234:
        indexA = 14;
        break;
      case 1234:
        indexA = 15;
        break;

      default:
        indexA = 1;
        break;
    }

    var imagename = indexA + ".png";

    const imageUrl = require("../assets/images/clusterMarkers/" + imagename);
    return L.divIcon({
      html: `<div style="border-radius: 50%;  width: 40px; height: 40px;position: relative;text-align:center"><img src="${imageUrl}" /><span style="color: black;position: absolute;left: 0;right: 0;
          line-height: 36px;">${cluster.getChildCount()}</span></div>`,
      className: "marker-cluster-custom",
      iconSize: [40, 40],
    });
  };

  handleSwitchChange = (value) => {
    this.setState({ loadingSwitch: true });
    setTimeout(() => {
      console.log(value);
      this.setState({ loadingSwitch: false });
    }, 1000);
  };

  handleBtnFunc = (state) => {
    this.setState({ [state]: true });
    setTimeout(() => {
      this.setState({ [state]: false });
    }, 1000);
  };

  handleSearchChange(query) {
    return this.trackingDashboardStore?.trackData?.filter((vehicle) => {
      const normalizedQuery = query.toLowerCase();
      return (
        (vehicle.name &&
          vehicle.name.toLowerCase().includes(normalizedQuery)) ||
        (vehicle.vehicleNo &&
          vehicle.vehicleNo.toLowerCase().includes(normalizedQuery))
      );
    });
  }

  // // this.trackingDashboardStore?.trackData

  render() {
    // const axisTitleStyle = {
    //   fontWeight: 'Italic',
    //   // add other styles as needed
    // };
    return (
      <Spin spinning={this.state.loading} fullscreen>
        <div style={{ padding: "25px" }} className="dashboard-basic">
          <div className="dashboard-card-row">
            <Card
              className="dashboard-card running"
              onClick={async () => {
                await this.setState({
                  status: "Running",
                  showDetailStatusModal: true,
                });
                await this.handleSearch();
                await this.setState({
                  driverOrVehicleSearchArray:
                    this.trackingDashboardStore?.trackData,
                });
              }}
            >
              <i class="las la-car-side"></i>
              <b> {this.trackingDashboardStore?.totalvehiclestate?.Moving}</b>

              <p>{this.context.translate("_RUNNING_")}</p>
            </Card>

            <Card
              className="dashboard-card idle"
              onClick={async () => {
                await this.setState({
                  status: "Idle",
                  showDetailStatusModal: true,
                });
                await this.handleSearch();
                await this.setState({
                  driverOrVehicleSearchArray:
                    this.trackingDashboardStore?.trackData,
                });
              }}
            >
              <i class="las la-car-side"></i>
              <b> {this.trackingDashboardStore?.totalvehiclestate?.Idle}</b>
              <p>{this.context.translate("_IDLE_")}</p>
            </Card>

            <Card
              className="dashboard-card stop"
              onClick={async (e) => {
                await this.setState({
                  status: "Stop",
                  showDetailStatusModal: true,
                });
                await this.handleSearch();
                await this.setState({
                  driverOrVehicleSearchArray:
                    this.trackingDashboardStore?.trackData,
                });
              }}
            >
              <i class="las la-car-side"></i>
              <b> {this.trackingDashboardStore?.totalvehiclestate?.Stopped}</b>
              <p>{this.context.translate("_STOP_")}</p>
            </Card>

            <Card
              className="dashboard-card ex-idle"
              onClick={async (e) => {
                await this.setState({
                  status: "exIdle",
                  showDetailStatusModal: true,
                });
                await this.handleSearch();
                await this.setState({
                  driverOrVehicleSearchArray:
                    this.trackingDashboardStore?.trackData,
                });
              }}
            >
              <i class="las la-car-side"></i>
              <b> {this.trackingDashboardStore?.totalvehiclestate?.exidle}</b>

              <p>{this.context.translate("_EXIDLE_")}</p>
            </Card>

            <Card
              className="dashboard-card no-data"
              onClick={async (e) => {
                await this.setState({
                  status: "NoData",
                  showDetailStatusModal: true,
                });
                await this.handleSearch();
              }}
            >
              <i class="las la-car-side"></i>
              <b>{this.trackingDashboardStore?.totalvehiclestate?.NoData}</b>

              <p> {this.context.translate("_NO_DATA_")}</p>
            </Card>

            <Card
              className="dashboard-card total"
              onClick={async (e) => {
                await this.setState({
                  status: "normal",
                  showDetailStatusModal: true,
                });
                await this.handleSearch();
                await this.setState({
                  driverOrVehicleSearchArray:
                    this.trackingDashboardStore?.trackData,
                });
              }}
            >
              <i class="las la-car-side"></i>
              <b>
                {this.trackingDashboardStore?.totalvehiclestate?.TotalVehCount}
              </b>
              <p>{this.context.translate("_TOTAL_")}</p>
            </Card>

            <Card
              className="dashboard-card expired"
              onClick={async (e) => {
                await this.setState({ status: "Expired", showExpired: true });
                await this.handleSearch();
                await this.setState({
                  driverOrVehicleSearchArray:
                    this.trackingDashboardStore?.trackData,
                });
              }}
            >
              <i class="las la-car-side"></i>

              <b> {this.trackingDashboardStore?.totalvehiclestate?.expired}</b>
              <p>{this.context.translate("_EXPIRED_")}</p>
            </Card>

            <Card
              className="dashboard-card expiring-soon"
              onClick={async (e) => {
                await this.setState({
                  status: "Expiringsoon",
                  showExpired: true,
                });
                await this.handleSearch();
                await this.setState({
                  driverOrVehicleSearchArray:
                    this.trackingDashboardStore?.trackData,
                });
              }}
            >
              <i class="las la-car-side"></i>
              <b>
                {" "}
                {this.trackingDashboardStore?.totalvehiclestate?.expiringSoon}
              </b>

              <p>{this.context.translate("_EXPIRINGSOON_")}</p>
            </Card>

            <Card
              className="dashboard-card geofence-visit"
              onClick={async (e) => {
                await this.setState({
                  status: "geofence",
                  showGeofence: true,
                });
                await this.handleSearch();
                await this.setState({
                  driverOrVehicleSearchArray:
                    this.trackingDashboardStore?.trackData,
                });
              }}
            >
              <i class="las la-car-side"></i>
              <b> {this.trackingDashboardStore?.totalvehiclestate?.geofence}</b>

              <p>{this.context.translate("_GEOFENCEVISIT_")}</p>
            </Card>
          </div>

          <Modal
            open={this.state.showDetailStatusModal}
            footer={null}
            centered
            width={1000}
            style={{ height: "550px" }}
            onCancel={() => this.setState({ showDetailStatusModal: false })}
          >
            <div className="heading">
              {this.context.translate("_DETAIL_TRACKING_")}
            </div>
            <div style={{ height: "450px" }}>
              <Row style={{ padding: "10px 0px" }}>
                <Col span={22}>
                  <Space>
                    {/* {this.state.radioButtonGroup()} */}

                    <Radio.Group
                      onChange={async (e) => {
                        console.log(e.target.value);
                        await this.setState({ status: e.target.value });
                        await this.handleSearch();
                      }}
                    >
                      <Space>
                        <Radio.Button
                          value={"Stop"}
                          style={{ color: "white", backgroundColor: "#EF5350" }}
                        >
                          {" "}
                          {this.context.translate("_STOPPED_")}{" "}
                          {this.trackingDashboardStore?.vehicalState?.Stopped}
                        </Radio.Button>

                        <Radio.Button
                          value={"Running"}
                          style={{ color: "white", backgroundColor: "#1acaa1" }}
                        >
                          {this.context.translate("_RUNNING_")}{" "}
                          {this.trackingDashboardStore?.vehicalState?.Moving}
                        </Radio.Button>

                        <Radio.Button
                          value={"Idle"}
                          style={{ color: "white", backgroundColor: "#FFEE58" }}
                        >
                          {this.context.translate("_IDLE_")}{" "}
                          {this.trackingDashboardStore?.vehicalState?.Idle}
                        </Radio.Button>

                        <Radio.Button
                          value={"NoData"}
                          style={{ color: "white", backgroundColor: "#839192" }}
                        >
                          {this.context.translate("_NO_DATA_")}{" "}
                          {this.trackingDashboardStore?.vehicalState?.NoData}
                        </Radio.Button>

                        <Radio.Button
                          value={"normal"}
                          style={{ color: "white", backgroundColor: "#D0D3D4" }}
                        >
                          {this.context.translate("_ALL_")}
                          {this.trackingDashboardStore?.total}
                        </Radio.Button>

                        <Radio.Button
                          value={"geofence"}
                          style={{ color: "white", backgroundColor: "#D0D3D4" }}
                        >
                          {this.context.translate("_GEOFENCE_")}{" "}
                          {this.trackingDashboardStore?.vehicalState?.geofence}
                        </Radio.Button>
                      </Space>
                    </Radio.Group>

                    <Input
                      placeholder={this.context.translate(
                        "_SEARCH_BY_DRIVER_VEHICLE_"
                      )}
                      style={{ width: 200, height: 33 }}
                      // value={this.state.searchText}
                      onChange={async (e) => {
                        let searchedArray = await this.handleSearchChange(
                          e.target.value
                        );
                        await this.setState({
                          driverOrVehicleSearchArray:
                            searchedArray.length === 0 ? [] : searchedArray,
                        });
                      }}
                    />
                    <Button
                      icon={<SearchOutlined />}
                      type="primary"
                      onClick={async () => {}}
                    />
                  </Space>
                </Col>
              </Row>

              {this.detailTracking()}
            </div>
          </Modal>

          <Modal
            open={this.state.showGeofence}
            centered
            title={this.context.translate("_GEOFENCE_DETAILS_")}
            footer={null}
            width={1000}
            style={{ height: "550px" }}
            onCancel={() => this.setState({ showGeofence: false })}
          >
            <div style={{ height: "450px" }}>
              <Table
                bordered
                current={this.state.currentPage}
                total={this.trackingDashboardStore?.total}
                columns={this.geofenceColumns}
                dataSource={this.trackingDashboardStore?.trackData}
                loading={this.trackingDashboardStore?.loading}
                onChange={this.handleTableChange}
                pagination={false}
                scroll={{
                  // x: 1500,
                  y: 360,
                }}
              />
            </div>
          </Modal>

          <Modal
            title=<div
              style={{
                background: "white",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {this.context.translate("_VEHICLE_DETAILS_")}

              <div style={{ display: "flex", alignItems: "center" }}>
                {" "}
                <Input
                  placeholder={this.context.translate(
                    "_SEARCH_BY_DRIVER_VEHICLE_"
                  )}
                  style={{ width: 200, height: 40, marginInlineEnd: "15px" }}
                  // value={this.state.searchText}
                  onChange={async (e) => {
                    let searchedArray = await this.handleSearchChange(
                      e.target.value
                    );
                    await this.setState({
                      driverOrVehicleSearchArray:
                        searchedArray.length === 0 ? [] : searchedArray,
                    });
                  }}
                />
              </div>
            </div>
            open={this.state.showExpired}
            centered
            // title="Notifications"
            width={1000}
            onCancel={() => this.setState({ showExpired: false })}
            footer={null}
            style={{ height: "550px" }}
          >
            <div style={{ height: "450px" }}>
              <Table
                bordered
                // current={this.state.currentPage}
                // total={this.trackingDashboardStore?.total}
                pagination={false}
                columns={this.columns1}
                dataSource={this.state.driverOrVehicleSearchArray}
                // dataSource={this.trackingDashboardStore?.trackData}
                loading={this.trackingDashboardStore?.loading}
                scroll={{ y: 360 }}
                // onChange={this.handleTableChange}
              />
            </div>
          </Modal>

          <Row className="dashboard-card-wrapper">
            <Col span={12}>
              <Card
                className="dashboard-panel"
                title={
                  <div className="panel-head">
                    <h1>
                      {this.context.translate("_FLEET_DASHBORD_MAP_MAP_")}
                    </h1>
                    <div>
                      <CompanyAutocomplete
                        onChange={async (value) => {
                          // console.log(value);
                          await this.setState({ companyId: value.value });
                          this.trackingDashboardStore.total = 0;
                          this.trackingDashboardStore.livetrack = [];
                          this.trackingDashboardStore.trackData = [];
                          this.trackingDashboardStore.vehicalState = [];
                          await this.setState({
                            driverOrVehicleSearchArray: [],
                          });
                          await this.handleChange();
                        }}
                      />
                      <Button
                        className="transparent-btn topAlign"
                        onClick={(e) => this.setState({ showMap: true })}
                      >
                        <FullscreenOutlined />
                      </Button>
                    </div>
                  </div>
                }
              >
                <div
                  dir={
                    this.context.locale.language === "arabic" ? "ltr" : "ltr"
                  }
                  // className={
                  //   this.context.locale.language === "arabic"
                  //     ? "arabic-map-container"
                  //     : ""
                  // }
                >
                  <MapContainer
                    ref={this.mapRef}
                    zoom={config.mapZoom}
                    center={config.mapCenter}
                    // zoom={4}
                    // center={[18, 42]}
                    style={{ height: "64vh", width: "40wh" }}
                  >
                    {this.state.type || this.state.traffic ? (
                      <ReactLeafletGoogleLayer
                        key={this.state.type ? this.state.type : ""}
                        type={this.state.type ? this.state.type : "roadmap"}
                        googleMapsAddLayers={
                          this.state.traffic ? [{ name: "TrafficLayer" }] : null
                        }
                      />
                    ) : (
                      <ReactLeafletGoogleLayer
                        googleMapsAddLayers={
                          this.state.traffic ? [{ name: "TrafficLayer" }] : null
                        }
                      />
                    )}
                    <MarkerClusterGroup
                      chunkedLoading
                      iconCreateFunction={this.iconCreateFunction}
                    >
                      {this.trackingDashboardStore?.livetrack?.map((val) => {
                        return (
                          val.latitude &&
                          val.longitude && (
                            <Marker
                              position={[val?.latitude, val?.longitude]}
                              // icon={this.customIcon}
                              icon={this.rotateIcon(
                                val.mapImage,
                                val.direction
                              )}
                              options={{ obj: val }}
                              // onClick={() => this.rowClicked()}
                            >
                              {/* <Popup>{val?.formatted_address} </Popup> */}
                              <Popup direction="top">
                                <b>{this.context.translate("_VEHICLE_NO_")}:</b>{" "}
                                {val?.vehicleNo}
                                <br />
                                <b>
                                  {this.context.translate(
                                    "_FLEET_DASHBORD_SHOWGEO_ADDRESS_"
                                  )}{" "}
                                  :{" "}
                                </b>
                                {val?.formatted_address}
                                <br />
                                <b>
                                  {this.context.translate(
                                    "_FLEET_DASHBORD_HISTORY_TOOLTIP_MOBILE_"
                                  )}
                                  :
                                </b>{" "}
                                {val?.mobileno ? val?.mobileno : ""}
                                <br />
                                <b>{this.context.translate("_NAME_")}: </b>
                                {val?.name}
                              </Popup>
                              <MapTooltip direction="top" permanent>
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                  }}
                                >
                                  {" "}
                                  {val?.vehicleNo}
                                  {val?.name ? ` / ${val.name}` : ""}
                                </div>
                              </MapTooltip>
                            </Marker>
                          )
                        );
                      })}
                    </MarkerClusterGroup>

                    <FullscreenControl
                      position={"topright"}
                      title="Go Fullscreen"
                    />
                    <FloatButton.Group
                      shape="square"
                      style={{
                        top: "2%",
                        width: "0px",
                        insetInlineStart: "1%",
                        zIndex: 999,
                        height: "13%",

                        position: "relative",
                        direction:
                          this.context.locale.language === "arabic"
                            ? "ltr"
                            : "ltr",
                      }}
                      // float="right"
                    >
                      <Popover
                        className="locationShowingWindowBtns"
                        onClick={() => {
                          this.setState({ type: "", mapBtn: false });
                          this.handleBtnFunc("mapBtn");
                        }}
                        content={
                          <Switch
                            checkedChildren="Map"
                            unCheckedChildren="Terrain"
                            style={{ zIndex: "999" }}
                            onChange={(e) => {
                              this.handleSwitchChange();
                              this.setState({ type: e ? "terrain" : "" });
                            }}
                            loading={this.state.loadingSwitch}
                          >
                            Terrian
                          </Switch>
                        }
                        // title="Map"
                        trigger="click"
                        placement="right"
                      >
                        <Button
                          icon={<i class="bi bi-map"></i>}
                          loading={this.state.mapBtn}
                          style={{}}
                        ></Button>
                      </Popover>

                      <Popover
                        style={{ padding: "0px" }}
                        onClick={() => {
                          this.setState({
                            type: "hybrid",
                            satelliteBtn: false,
                          });
                          this.handleBtnFunc("satelliteBtn");
                        }}
                        content={
                          this.state.satelliteBtn === false && (
                            // <Checkbox
                            //   onChange={(e) =>
                            //     this.setState({
                            //       type: e.target.checked ? "hybrid" : "satellite",
                            //     })
                            //   }
                            // >
                            //   Labels{" "}
                            // </Checkbox>
                            <Switch
                              checkedChildren="Labels"
                              defaultChecked
                              unCheckedChildren="Satellite"
                              onChange={(e) => {
                                this.handleSwitchChange();
                                this.setState({
                                  type: e ? "hybrid" : "satellite",
                                });
                              }}
                              loading={this.state.loadingSwitch}
                              style={{ zIndex: 999 }}
                            />
                          )
                        }
                        trigger="click"
                        placement="right"
                        // style={{ zIndex: 1000 }}
                      >
                        <Button
                          icon={<i class="bi bi-globe-asia-australia"></i>}
                          loading={this.state.satelliteBtn}
                          style={{}}
                        ></Button>
                      </Popover>
                      <Popover>
                        <Button
                          style={{}}
                          icon={<i class="bi bi-car-front"></i>}
                          onClick={() =>
                            this.setState({ traffic: !this.state.traffic })
                          }
                        >
                          {/* Traffic */}
                        </Button>
                      </Popover>
                      {/* <FloatButton />
      <FloatButton icon={<SyncOutlined />} />
      <FloatButton.BackTop visibilityHeight={0} /> */}
                    </FloatButton.Group>
                  </MapContainer>
                </div>
              </Card>
            </Col>

            <Modal
              className="multiTracking-popup"
              open={this.state.showMap}
              centered
              title=<div>
                {this.context.translate("_FLEET_DASHBORD_MAP_MAP_")}
                <CloseOutlined
                  onClick={() => this.setState({ showMap: false })}
                />
              </div>
              footer={null}
              // bodyStyle={{ height: 1000 }}
              width={1000}
              onCancel={() => this.setState({ showMap: false })}
            >
              <div
                dir={this.context.locale.language === "arabic" ? "ltr" : "ltr"}
                // className={
                //   this.context.locale.language === "arabic"
                //     ? "arabic-map-container"
                //     : ""
                // }
              >
                <MapContainer
                  ref={this.mapRef}
                  zoom={config.mapZoom}
                  center={config.mapCenter}
                  // zoom={4}
                  // center={[18, 42]}
                  style={{ height: "64vh", width: "40wh" }}
                >
                  {this.state.type || this.state.traffic ? (
                    <ReactLeafletGoogleLayer
                      key={this.state.type ? this.state.type : ""}
                      type={this.state.type ? this.state.type : "roadmap"}
                      googleMapsAddLayers={
                        this.state.traffic ? [{ name: "TrafficLayer" }] : null
                      }
                    />
                  ) : (
                    <ReactLeafletGoogleLayer
                      googleMapsAddLayers={
                        this.state.traffic ? [{ name: "TrafficLayer" }] : null
                      }
                    />
                  )}
                  <MarkerClusterGroup
                    chunkedLoading
                    iconCreateFunction={this.iconCreateFunction}
                  >
                    {this.trackingDashboardStore?.livetrack?.map((val) => {
                      return (
                        val.latitude &&
                        val.longitude && (
                          <Marker
                            position={[val?.latitude, val?.longitude]}
                            // icon={this.customIcon}
                            icon={this.rotateIcon(val.mapImage, val.direction)}
                            options={{ obj: val }}
                            // onClick={() => this.rowClicked()}
                          >
                            {/* <Popup>{val?.formatted_address} </Popup> */}
                            <Popup direction="top">
                              <b>{this.context.translate("_VEHICLE_NO_")}:</b>{" "}
                              {val?.vehicleNo}
                              <br />
                              <b>
                                {this.context.translate(
                                  "_FLEET_DASHBORD_SHOWGEO_ADDRESS_"
                                )}{" "}
                                :{" "}
                              </b>
                              {val?.formatted_address}
                              <br />
                              <b>
                                {this.context.translate(
                                  "_FLEET_DASHBORD_HISTORY_TOOLTIP_MOBILE_"
                                )}
                                :
                              </b>{" "}
                              {val?.mobileno ? val?.mobileno : ""}
                              <br />
                              <b>{this.context.translate("_NAME_")}: </b>
                              {val?.name}
                            </Popup>
                            <MapTooltip direction="top" permanent>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "10px",
                                }}
                              >
                                {" "}
                                {val?.vehicleNo}
                                {val?.name ? ` / ${val.name}` : ""}
                              </div>
                            </MapTooltip>
                          </Marker>
                        )
                      );
                    })}
                  </MarkerClusterGroup>

                  <FullscreenControl
                    position={"topright"}
                    title="Go Fullscreen"
                  />
                  <FloatButton.Group
                    shape="square"
                    style={{
                      top: "2%",
                      width: "0px",
                      insetInlineStart: "1%",
                      zIndex: 999,
                      height: "13%",

                      position: "relative",
                      direction:
                        this.context.locale.language === "arabic"
                          ? "ltr"
                          : "ltr",
                    }}
                    // float="right"
                  >
                    <Popover
                      className="locationShowingWindowBtns"
                      onClick={() => {
                        this.setState({ type: "", mapBtn: false });
                        this.handleBtnFunc("mapBtn");
                      }}
                      content={
                        <Switch
                          checkedChildren="Map"
                          unCheckedChildren="Terrain"
                          style={{ zIndex: "999" }}
                          onChange={(e) => {
                            this.handleSwitchChange();
                            this.setState({ type: e ? "terrain" : "" });
                          }}
                          loading={this.state.loadingSwitch}
                        >
                          Terrian
                        </Switch>
                      }
                      // title="Map"
                      trigger="click"
                      placement="right"
                    >
                      <Button
                        icon={<i class="bi bi-map"></i>}
                        loading={this.state.mapBtn}
                        style={{}}
                      ></Button>
                    </Popover>

                    <Popover
                      style={{ padding: "0px" }}
                      onClick={() => {
                        this.setState({
                          type: "hybrid",
                          satelliteBtn: false,
                        });
                        this.handleBtnFunc("satelliteBtn");
                      }}
                      content={
                        this.state.satelliteBtn === false && (
                          <Switch
                            checkedChildren="Labels"
                            defaultChecked
                            unCheckedChildren="Satellite"
                            onChange={(e) => {
                              this.handleSwitchChange();
                              this.setState({
                                type: e ? "hybrid" : "satellite",
                              });
                            }}
                            loading={this.state.loadingSwitch}
                            style={{ zIndex: 999 }}
                          />
                        )
                      }
                      trigger="click"
                      placement="right"
                      // style={{ zIndex: 1000 }}
                    >
                      <Button
                        icon={<i class="bi bi-globe-asia-australia"></i>}
                        loading={this.state.satelliteBtn}
                        style={{}}
                      ></Button>
                    </Popover>
                    <Popover>
                      <Button
                        style={{}}
                        icon={<i class="bi bi-car-front"></i>}
                        onClick={() =>
                          this.setState({ traffic: !this.state.traffic })
                        }
                      >
                        {/* Traffic */}
                      </Button>
                    </Popover>
                  </FloatButton.Group>
                </MapContainer>
              </div>
            </Modal>
            <Col span={12}>
              <Card
                className="dashboard-panel window-padding"
                title={
                  <div className="panel-head">
                    <h1>{this.context.translate("_DETAIL_TRACKING_")}</h1>{" "}
                    <Button
                      className="transparent-btn"
                      onClick={(e) =>
                        this.setState({
                          showDetailTrackingModal: true,
                          driverOrVehicleSearchArray:
                            this.trackingDashboardStore?.trackData,
                        })
                      }
                    >
                      <FullscreenOutlined />
                    </Button>
                  </div>
                }
              >
                <Space className="body-toolbar">
                  <Radio.Group
                    onChange={async (e) => {
                      await this.setState({ status: e.target.value });
                      await this.handleSearch();
                    }}
                  >
                    <div>
                      <Radio.Button
                        value={"Stop"}
                        className="status-tag stop"
                        //   style={{ color: "black", backgroundColor: "#EF5350" }}
                      >
                        {" "}
                        <span style={{ fontSize: "10px" }}>
                          {" "}
                          {this.context.translate("_STOPPED_")}{" "}
                          {this.trackingDashboardStore?.vehicalState?.Stopped}
                        </span>
                      </Radio.Button>

                      <Radio.Button
                        value={"Running"}
                        className="status-tag running"

                        //   style={{ color: "black", backgroundColor: "#66BB6A" }}
                      >
                        <span style={{ fontSize: "10px" }}>
                          {" "}
                          {this.context.translate("_RUNNING_")}{" "}
                          {this.trackingDashboardStore?.vehicalState?.Moving}
                        </span>
                      </Radio.Button>

                      <Radio.Button
                        value={"Idle"}
                        className="status-tag idle"
                        //   style={{ color: "black", backgroundColor: "#FFEE58" }}
                      >
                        <span style={{ fontSize: "10px" }}>
                          {" "}
                          {this.context.translate("_IDLE_")}{" "}
                          {this.trackingDashboardStore?.vehicalState?.Idle}
                        </span>
                      </Radio.Button>

                      <Radio.Button
                        value={"NoData"}
                        className="status-tag no-data"
                        //   style={{ color: "black", backgroundColor: "#839192" }}
                      >
                        <span style={{ fontSize: "10px" }}>
                          {" "}
                          {this.context.translate("_NO_DATA_")}{" "}
                          {this.trackingDashboardStore?.vehicalState?.NoData}
                        </span>
                      </Radio.Button>

                      <Radio.Button
                        value={"normal"}
                        className="status-tag total"
                        //   style={{ color: "black", backgroundColor: "#D0D3D4" }}
                      >
                        <span style={{ fontSize: "10px" }}>
                          {" "}
                          {this.context.translate("_ALL_")}{" "}
                          {this.trackingDashboardStore?.total}
                        </span>
                      </Radio.Button>

                      <Radio.Button
                        value={"geofence"}
                        className="status-tag geofence-visit"
                        //   style={{ color: "black", backgroundColor: "#D0D3D4" }}
                      >
                        <span style={{ fontSize: "10px" }}>
                          {" "}
                          {this.context.translate("_GEOFENCE_")}{" "}
                          {this.trackingDashboardStore?.vehicalState?.geofence}
                        </span>
                      </Radio.Button>
                    </div>
                  </Radio.Group>

                  <div className="search-box">
                    <Input
                      placeholder={this.context.translate(
                        "Search Vehicle,Driver"
                      )}
                      // value={this.state.searchText}
                      style={{ width: 160 }}
                      onChange={async (e) => {
                        let searchedArray = await this.handleSearchChange(
                          e.target.value
                        );
                        await this.setState({
                          driverOrVehicleSearchArray:
                            searchedArray.length === 0 ? [] : searchedArray,
                        });
                      }}
                    />{" "}
                    &nbsp;
                    {/* <Button
                    icon={<SearchOutlined />}
                    type="primary"
                    onClick={async () => {
                      console.log("sdfsadfgsdf");
                    }}
                  /> */}
                  </div>
                </Space>

                {this.detailTracking()}
              </Card>
            </Col>

            <Modal
              open={this.state.showDetailTrackingModal}
              footer={null}
              centered
              width={1000}
              onCancel={() => this.setState({ showDetailTrackingModal: false })}
            >
              <div className="heading">
                {this.context.translate("_DETAIL_TRACKING_")}
              </div>
              <div style={{ height: "450px" }}>
                <Row style={{ padding: "10px 0px" }}>
                  <Col span={22}>
                    <Space>
                      {/* {this.state.radioButtonGroup()} */}

                      <Radio.Group
                        onChange={async (e) => {
                          await this.setState({ status: e.target.value });
                          await this.handleSearch();
                        }}
                      >
                        <Space>
                          <Radio.Button
                            value={"Stop"}
                            style={{
                              color: "white",
                              backgroundColor: "#EF5350",
                            }}
                          >
                            {" "}
                            {this.context.translate("_STOPPED_")}{" "}
                            {this.trackingDashboardStore?.vehicalState?.Stopped}
                          </Radio.Button>

                          <Radio.Button
                            value={"Running"}
                            style={{
                              color: "white",
                              backgroundColor: "#66BB6A",
                            }}
                          >
                            {this.context.translate("_RUNNING_")}{" "}
                            {this.trackingDashboardStore?.vehicalState?.Moving}
                          </Radio.Button>

                          <Radio.Button
                            value={"Idle"}
                            style={{
                              color: "white",
                              backgroundColor: "#FFEE58",
                            }}
                          >
                            {this.context.translate("_IDLE_")}{" "}
                            {this.trackingDashboardStore?.vehicalState?.Idle}
                          </Radio.Button>

                          <Radio.Button
                            value={"NoData"}
                            style={{
                              color: "white",
                              backgroundColor: "#839192",
                            }}
                          >
                            {this.context.translate("_NO_DATA_")}{" "}
                            {this.trackingDashboardStore?.vehicalState?.NoData}
                          </Radio.Button>

                          <Radio.Button
                            value={"normal"}
                            style={{
                              color: "white",
                              backgroundColor: "#D0D3D4",
                            }}
                          >
                            {this.context.translate("_ALL_")}{" "}
                            {this.trackingDashboardStore?.total}
                          </Radio.Button>

                          <Radio.Button
                            value={"geofence"}
                            style={{
                              color: "white",
                              backgroundColor: "#747a80",
                            }}
                          >
                            {this.context.translate("_GEOFENCE_")}{" "}
                            {
                              this.trackingDashboardStore?.vehicalState
                                ?.geofence
                            }
                          </Radio.Button>
                        </Space>
                      </Radio.Group>

                      <Input
                        placeholder={this.context.translate(
                          "_SEARCH_BY_DRIVER_VEHICLE_"
                        )}
                        style={{ width: 200, height: 33 }}
                        onChange={async (e) => {
                          let searchedArray = await this.handleSearchChange(
                            e.target.value
                          );
                          await this.setState({
                            driverOrVehicleSearchArray:
                              searchedArray.length === 0 ? [] : searchedArray,
                          });
                        }}
                      />
                      {/* <Button
                    icon={<SearchOutlined />}
                    type="primary"
                    onClick={async () => {
                      console.log("sdfsadfgsdf");
                    }}
                  /> */}
                    </Space>
                  </Col>
                </Row>
                {this.detailTracking()}
              </div>
            </Modal>
          </Row>

          <Row className="dashboard-card-wrapper">
            <Col span={12}>
              <Card
                className="dashboard-panel window-padding"
                title={
                  <div className="panel-head">
                    {" "}
                    <h1>{this.context.translate("_USAGE_GRAPH_")}</h1>{" "}
                    <Button
                      className="transparent-btn"
                      onClick={(e) => this.setState({ usageGraph: true })}
                    >
                      <FullscreenOutlined />
                    </Button>
                  </div>
                }
              >
                <DashboardUsageGraph
                  data={this.trackingDashboardStore?.usageGraphData}
                  layout="vertical"
                />
              </Card>
            </Col>
            <Modal
              className="multiTracking-popup"
              open={this.state.usageGraph}
              title=<div>
                {this.context.translate("_USAGE_GRAPH_")}
                <CloseOutlined
                  onClick={() => this.setState({ usageGraph: false })}
                />
              </div>
              centered
              // bodyStyle={{ height: 1000 }}
              width={1000}
              footer={null}
              onCancel={() => this.setState({ usageGraph: false })}
            >
              <DashboardUsageGraph
                data={this.trackingDashboardStore?.usageGraphData}
                layout="vertical"
              />
            </Modal>
            <Col span={12}>
              <Card
                className="dashboard-panel window-padding"
                title={
                  <div className="panel-head">
                    <h1>{this.context.translate("_MAX_SPEED_GRAPH_")}</h1>
                    <Button
                      className="transparent-btn"
                      onClick={(e) => this.setState({ maxSpeedGraph: true })}
                    >
                      <FullscreenOutlined />
                    </Button>
                  </div>
                }
              >
                <Column
                  style={{ padding: "20px" }}
                  {...{
                    data:
                      this.trackingDashboardStore?.maxSpeedGraph?.length > 0
                        ? this.trackingDashboardStore?.maxSpeedGraph
                        : [],

                    yField: "maxSpeed",
                    xField: "vehicleNo",
                    seriesField: "maxSpeed",
                    // toolbar: {
                    //   show: false,
                    // },
                    legend: false,
                    yAxis: {
                      label: {
                        style: {
                          fontWeight: "bold",
                        },
                      },
                      title: {
                        // text: "Max Speed",
                        text: this.context.translate(
                          "_FLEET_REPORTS_FLEETREPORT_MAXSPEED_"
                        ),
                        style: { fontWeight: "bold", fontSize: 18 },
                      },
                    },
                    xAxis: {
                      label: {
                        style: {
                          fontWeight: "bold",
                        },
                      },
                      title: {
                        // text: "Asset Name",
                        text: this.context.translate(
                          "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                        ),
                        style: { fontWeight: "bold", fontSize: 18 },
                      },
                    },
                    tooltip: {
                      // showMarkers: false,
                      // enterable: true,

                      customContent: (title, items) => {
                        const data = items[0]?.data || {};

                        const vehicleNo = (
                          <div class="custom-tooltip-title">
                            {this.context.translate("_ASSET_NAME_")}:
                            {data.vehicleNo}
                          </div>
                        );
                        const maxSpeed = (
                          <div class="custom-tooltip-title">
                            {this.context.translate("_MAX_SPEED_1")}:
                            {data.maxSpeed}
                          </div>
                        );
                        const name = (
                          <div class="custom-tooltip-title">
                            {this.context.translate("_DRIVER_NAME_")}:
                            {data.name}
                          </div>
                        );
                        const driverPhone = (
                          <div class="custom-tooltip-title">
                            {this.context.translate("_DRIVER_MOBILE_NO_")}:
                            {data.driverphone}
                          </div>
                        );
                        const date = (
                          <div class="custom-tooltip-title">
                            {this.context.translate("_DATE_")}:{data.date}
                          </div>
                        );

                        return (
                          <div style={{ background: "white", padding: "10px" }}>
                            {vehicleNo}
                            {maxSpeed}
                            {name}
                            {driverPhone}
                            {date}
                          </div>
                        );
                      },
                    },
                  }}
                />

                <Modal
                  className="multiTracking-popup"
                  title={
                    <div>
                      {this.context.translate("_MAX_SPEED_GRAPH_")}
                      <CloseOutlined
                        onClick={() => this.setState({ maxSpeedGraph: false })}
                      />
                    </div>
                  }
                  open={this.state.maxSpeedGraph}
                  centered
                  footer={null}
                  width={1000}
                  onCancel={() => this.setState({ maxSpeedGraph: false })}
                >
                  <Column
                    style={{ padding: "20px" }}
                    {...{
                      data:
                        this.trackingDashboardStore?.maxSpeedGraph?.length > 0
                          ? this.trackingDashboardStore?.maxSpeedGraph
                          : [],

                      yField: "maxSpeed",
                      xField: "vehicleNo",
                      seriesField: "maxSpeed",
                      // toolbar: {
                      //   show: false,
                      // },
                      legend: false,
                      yAxis: {
                        label: {
                          style: {
                            fontWeight: "bold",
                          },
                        },
                        title: {
                          // text: "Max Speed",
                          text: this.context.translate(
                            "_FLEET_REPORTS_FLEETREPORT_MAXSPEED_"
                          ),
                          style: { fontWeight: "bold", fontSize: 18 },
                        },
                      },
                      xAxis: {
                        label: {
                          style: {
                            fontWeight: "bold",
                          },
                        },
                        title: {
                          // text: "Asset Name",
                          text: this.context.translate(
                            "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                          ),
                          style: { fontWeight: "bold", fontSize: 18 },
                        },
                      },
                      tooltip: {
                        // showMarkers: false,
                        // enterable: true,

                        customContent: (title, items) => {
                          const data = items[0]?.data || {};

                          const vehicleNo = (
                            <div class="custom-tooltip-title">
                              {this.context.translate("_ASSET_NAME_")}:
                              {data.vehicleNo}
                            </div>
                          );
                          const maxSpeed = (
                            <div class="custom-tooltip-title">
                              {this.context.translate("_MAX_SPEED_1")}:
                              {data.maxSpeed}
                            </div>
                          );
                          const name = (
                            <div class="custom-tooltip-title">
                              {this.context.translate("_DRIVER_NAME_")}:
                              {data.name}
                            </div>
                          );
                          const driverPhone = (
                            <div class="custom-tooltip-title">
                              {this.context.translate("_DRIVER_MOBILE_NO_")}:
                              {data.driverphone}
                            </div>
                          );
                          const date = (
                            <div class="custom-tooltip-title">
                              {this.context.translate("_DATE_")}:{data.date}
                            </div>
                          );

                          return (
                            <div
                              style={{ background: "white", padding: "10px" }}
                            >
                              {vehicleNo}
                              {maxSpeed}
                              {name}
                              {driverPhone}
                              {date}
                            </div>
                          );
                        },
                      },
                    }}
                  />
                </Modal>
              </Card>
            </Col>
          </Row>

          <Row className="dashboard-card-wrapper mrgnNone">
            <Col span={12}>
              <Card
                className="dashboard-panel window-padding"
                title={
                  <div className="panel-head">
                    <h1>{this.context.translate("_DISTANCE_GRAPH_")}</h1>
                    <Button
                      className="transparent-btn"
                      onClick={(e) => this.setState({ distanceGraph: true })}
                    >
                      <FullscreenOutlined />
                    </Button>
                  </div>
                }
              >
                <Column
                  {...{
                    data:
                      this.trackingDashboardStore?.distaceGraph?.length > 0
                        ? this.trackingDashboardStore?.distaceGraph
                        : [],
                    //  this.state.usageGraphData,
                    yField: "distanceTravel",
                    xField: "vehicleNo",
                    legend: false,
                    seriesField: "distanceTravel",
                    // yAxis: {
                    //   title: {
                    //     // text: "Distance Travelled(Kms)",
                    //     text: this.context.translate("_DISTANCE_TRAVELED_KMS_"),
                    //   },
                    // },
                    // xAxis: {
                    //   title: {
                    //     // text: "Asset Name",
                    //     text: this.context.translate(
                    //       "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                    //     ),
                    //   },
                    //   // title={{ textStyle: axisTitleStyle }}
                    // },

                    yAxis: {
                      label: {
                        style: {
                          fontWeight: "bold",
                        },
                      },
                      title: {
                        text: this.context.translate("_DISTANCE_TRAVELED_KMS_"),
                        style: { fontWeight: "bold", fontSize: 18 },
                      },
                    },
                    xAxis: {
                      label: {
                        style: {
                          fontWeight: "bold",
                        },
                      },
                      title: {
                        text: this.context.translate(
                          "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                        ),
                        style: { fontWeight: "bold", fontSize: 18 },
                      },
                    },
                    tooltip: {
                      // showMarkers: false,
                      // enterable: true,

                      customContent: (title, items) => {
                        const data = items[0]?.data || {};

                        const maxSpeed = (
                          <div class="custom-tooltip-title">
                            {this.context.translate("_DISTANCE_")}:
                            {data.distanceTravel}
                          </div>
                        );
                        const vehicleNo = (
                          <div class="custom-tooltip-title">
                            {this.context.translate("_ASSET_NAME_")}:
                            {data.vehicleNo}
                          </div>
                        );

                        const name = (
                          <div class="custom-tooltip-title">
                            {this.context.translate("_DRIVER_NAME_")}:
                            {data.name}
                          </div>
                        );
                        const driverPhone = (
                          <div class="custom-tooltip-title">
                            {this.context.translate("_DRIVER_MOBILE_NO_")}:
                            {data.driverphone}
                          </div>
                        );
                        const date = (
                          <div class="custom-tooltip-title">
                            {this.context.translate("_DATE_")}:{data.date}
                          </div>
                        );

                        return (
                          <div style={{ background: "white", padding: "10px" }}>
                            {maxSpeed}
                            {date}
                            {vehicleNo}

                            {name}
                            {driverPhone}
                          </div>
                        );
                      },
                    },
                  }}
                />
              </Card>
            </Col>

            <Modal
              className="multiTracking-popup"
              title=<div>
                {this.context.translate("_DISTANCE_GRAPH_")}
                <CloseOutlined
                  onClick={() => this.setState({ distanceGraph: false })}
                />
              </div>
              open={this.state.distanceGraph}
              // title=""
              footer={null}
              centered
              // bodyStyle={{ height: 1000 }}
              width={1000}
              onCancel={() => this.setState({ distanceGraph: false })}
            >
              <Column
                style={{ padding: "20px" }}
                {...{
                  data:
                    this.trackingDashboardStore?.distaceGraph?.length > 0
                      ? this.trackingDashboardStore?.distaceGraph
                      : [],
                  //  this.state.usageGraphData,
                  yField: "distanceTravel",
                  xField: "vehicleNo",
                  legend: false,
                  seriesField: "distanceTravel",
                  yAxis: {
                    label: {
                      style: {
                        fontWeight: "bold",
                      },
                    },
                    title: {
                      // text: "Distance Travelled(Kms)",
                      text: this.context.translate("_DISTANCE_TRAVELED_KMS_"),
                      style: { fontWeight: "bold", fontSize: 18 },
                    },
                  },
                  xAxis: {
                    label: {
                      style: {
                        fontWeight: "bold",
                      },
                    },
                    title: {
                      // text: "Asset Name",
                      text: this.context.translate(
                        "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                      ),
                      style: { fontWeight: "bold", fontSize: 18 },
                    },
                  },
                  tooltip: {
                    // showMarkers: false,
                    // enterable: true,

                    customContent: (title, items) => {
                      const data = items[0]?.data || {};

                      const maxSpeed = (
                        <div class="custom-tooltip-title">
                          {this.context.translate("_DISTANCE_")}:
                          {data.distanceTravel}
                        </div>
                      );
                      const vehicleNo = (
                        <div class="custom-tooltip-title">
                          {this.context.translate("_ASSET_NAME_")}:
                          {data.vehicleNo}
                        </div>
                      );

                      const name = (
                        <div class="custom-tooltip-title">
                          {this.context.translate("_DRIVER_NAME_")}:{data.name}
                        </div>
                      );
                      const driverPhone = (
                        <div class="custom-tooltip-title">
                          {this.context.translate("_DRIVER_MOBILE_NO_")}:
                          {data.driverphone}
                        </div>
                      );
                      const date = (
                        <div class="custom-tooltip-title">
                          {this.context.translate("_DATE_")}:{data.date}
                        </div>
                      );
                      return (
                        <div style={{ background: "white", padding: "10px" }}>
                          {maxSpeed}
                          {date}
                          {vehicleNo}

                          {name}
                          {driverPhone}
                        </div>
                      );
                    },
                  },
                }}
              />
            </Modal>

            <Col span={12}>
              <Card
                className="dashboard-panel window-padding"
                title={
                  <div className="panel-head">
                    {" "}
                    <h1>{this.context.translate("_NOTIFICATION_")}</h1>{" "}
                    <Button
                      className="transparent-btn"
                      onClick={(e) =>
                        this.setState({ showNotificationList: true })
                      }
                    >
                      <FullscreenOutlined />
                    </Button>
                  </div>
                }
              >
                <div className="dataTbl-wrapper" style={{ height: "45vh" }}>
                  <Table
                    bordered
                    current={this.state.currentPage}
                    pagination={false}
                    // total={this.trackingDashboardStore?.total}
                    columns={this.columns2}
                    dataSource={this.trackingDashboardStore?.notificationData}
                    loading={this.trackingDashboardStore?.loading}
                    onChange={this.handleTableChange}
                    scroll={{ y: 360 }}
                  />
                </div>
              </Card>
            </Col>

            <Modal
              // className="multiTracking-popup"
              title={this.context.translate("_NOTIFICATION_")}
              // <CloseOutlined
              //   onClick={() => this.setState({ showNotificationList: false })}
              // />

              open={this.state.showNotificationList}
              centered
              // style={{ height: "750px" }}
              // title="Notifications"
              width={1000}
              onCancel={() => this.setState({ showNotificationList: false })}
              footer={null}
            >
              <div
                className="dataTbl-wrapper"
                style={{ height: "450px", padding: "10px" }}
              >
                <Table
                  bordered
                  current={this.state.currentPage}
                  pagination={false}
                  scroll={{ y: 370 }}
                  // total={this.trackingDashboardStore?.total}
                  columns={this.columns2}
                  dataSource={this.trackingDashboardStore?.notificationData}
                  loading={this.trackingDashboardStore?.loading}
                  onChange={this.handleTableChange}
                />
              </div>
            </Modal>
          </Row>

          {this.state.vehicleObj && (
            <MapForVehiclePosition
              vehicleObj={this.state.vehicleObj}
              rotateDeg={true}
              heading={this.context.translate("_DETAIL_TRACKING_MAP_")}
              closeMapModal={(resp) => {
                this.setState({ vehicleObj: resp });
              }}
            />
          )}

          {this.state.locationObj && (
            <MapForVehiclePosition
              vehicleObj={this.state.locationObj}
              // rotateDeg={true}
              zoom={15}
              heading={this.context.translate("_LOCATION_ON_MAP_")}
              closeMapModal={(resp) => {
                this.setState({ locationObj: resp });
              }}
            />
          )}
        </div>
      </Spin>
    );
  }
}
TrackingDashboard.contextType = StoreContext;
export default observer(withRouter(TrackingDashboard));
