// https://awtltrack.com/app/index.php?c=vehicletracking&a=detailtrackdata
// {"companyId":"3298","userId":31225,"isadmin":"1","driver":"","vehicleNo":"","status":"normal","offset":0,"limit":50}

import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class TemperatureDashboardStore {
  total = 0;
  temperatureArray = [];
  sensorDataArray = [];
  deviceDataArray = [];
  deviceSubgroupArray = [];
  temperatureReportArray = [];
  vehiclesDataArray = [];
  temperatureGraphArray = [];
  temperatureTableArray = [];
  rootStore;
  loading = false;
  dataarray = [];

  constructor(rootStore) {
    makeObservable(this, {
      total: observable,
      temperatureArray: observable,
      sensorDataArray: observable,
      loadData: action,
      loadSensorTableData: action,
      getDeviceGroups: action,
      getSubGroups: action,
      loadVehiclesData: action,
      loadTemperatureGraphData: action,
      deviceSubgroupArray: observable,
      loading: observable,
      deviceDataArray: observable,
      temperatureReportArray: observable,
      vehiclesDataArray: observable,
      temperatureGraphArray: observable,
      temperatureTableArray: observable,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, userId, isadmin, offset, limit }) {
    this.loading = true;

    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
      driver: "",
      vehicleNo: "",
      status: "normal",
      offset: offset,
      limit: limit,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicletracking&a=detailtrackdata",
        "postData=" + base64Input
      );

    // console.log(response?.data?.data);
    this.temperatureArray = response?.data?.data?.detailtrack;
    this.total = response?.data?.data?.TotalCount;
    // console.log(response?.data?.data?.detailtrack);
    this.loading = false;
    return response?.data?.data?.detailtrack;
  }
  async getroutedata({ companyId, userId, isadmin }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicletracking&a=getLiveRouteData",
        "postData=" + base64Input
      );

    // console.log(response?.data?.data);
    this.dataarray = response?.data?.data?.totalvehstate;
    // console.log(response?.data?.data?.totalvehstate);
    this.loading = false;
    return response?.data?.data?.totalvehstate;
  }

  async loadSensorTableData({ companyId, userId, isadmin, type }) {
    this.loading = true;
   
    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
      type: type,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicletracking&a=sensorFilterDetail",
        "postData=" + base64Input
      );

    this.sensorDataArray = response?.data?.data;
    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
    return response?.data?.data;
  }

  async getDeviceGroups({ companyId, userId, isadmin }) {
    this.loading = true;

    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=devicegroup&a=getVehicleGropups",
        "postData=" + base64Input
      );

    this.deviceDataArray = response?.data?.data;
    // this.total = parseInt(response?.data?.data?.TotalCount);
    // this.vehiclesArray=response?.data?.data?.children

    this.loading = false;
    return response?.data?.data;
  }

  async getSubGroups({
    deviceGroupId,
    deviceSubGroupId,
    companyId,
    userId,
    isadmin,
  }) {
    this.loading = true;
   
    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
      deviceSubGroupId: null,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=devicegroup&a=getGroups", "postData=" + base64Input);

    this.deviceSubgroupArray = response?.data?.data;
    // this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
    return response?.data?.data;
  }

  async temperatureReportData({
    companyId,
    vehicleId,
    userId,
    isadmin,
    offset,
    limit,
    fromDate,
    toDate,
  }) {
    this.loading = true;

    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      temp1min: "",
      temp1max: "",
      temp2min: "",
      temp2max: "",
      temp3min: "",
      temp3max: "",
      intervel: 5,
      fromDate: fromDate,
      showFlag: 0,
      toDate: toDate,
      offset: 0,
      limit: 15,
      page: 1,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=temprep", "postData=" + base64Input);

    // console.log(response?.data?.data);
    this.temperatureReportArray = response?.data?.data?.temprep;
    this.total = parseInt(response?.data?.data?.TotalCount);
    // console.log(response?.data?.data?.temprep);
    this.loading = false;
    return response?.data?.data?.temprep;
  }
  async loadVehiclesData({ companyId, userId, isadmin, type }) {
    this.loading = true;

    var postData = {
      companyId: companyId,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);

    this.vehiclesDataArray = response?.data?.data;
    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
    return response?.data?.data;
  }

  async loadTemperatureGraphData({
    companyId,
    userId,
    isadmin,
    vehicleId,
    fromDate,
    toDate,
  }) {
    this.loading = true;
   
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      intervel: 20,
      fromDate: fromDate,
      toDate: toDate,
      offset: 0,
      limit: 15,
      page: 1,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=tempGraph", "postData=" + base64Input);

    this.temperatureTableArray = response?.data?.data?.temprep;

    var tempGraphData = response?.data?.data?.temprep;

    if (tempGraphData) {
      tempGraphData.forEach((element) => {
        element.temp2 = element.temp2 ? Number(element.temp2) : 0;
        element.temp1 = Number(element.temp1);
        element.temp3 = element.temp3 ? Number(element.temp3) : 0;
        element.temp4 = element.temp4 ? Number(element.temp4) : 0;
      });
    }
    this.temperatureGraphArray = tempGraphData;

    // console.log( this.temperatureGraphArray )
  }
}
export default TemperatureDashboardStore;
