import React from "react";
import {
  Select,
  Checkbox,
  Row,
  Col,
  Button,
  Space,
  DatePicker,
  Dropdown,
  Modal,
  Tooltip,
} from "antd";
import { observer } from "mobx-react";
import withRouter from "../withRouter";
import { StoreContext } from "../store/RootStore";

import { CaretDownOutlined, CaretUpOutlined, DownOutlined } from "@ant-design/icons";
import config from "../config";
import GeofenceSummaryStore from "../store/GeofenceSummaryStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import VehicleStore from "../store/VehicleStore";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { decode as base64_decode } from "base-64";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
const cMargins = {
  vehicleNo:-20,
  geoname:-10,
  typeName:-10,
  startTime:-10,
  endTime:-5,
  inTime:0,
  outTime:-5,
  distanceTravel:-10,
  fuel:1,
  cost:5,
  Ridletime:-30,
  Rtraveltime:-30,
  Fidletime:-30,
  Ftraveltime:-30
}
const { RangePicker } = DatePicker;
const { Option } = Select;
let widthAdderFunc = (arr) => {
  let array = arr;
  // array.map((val) => {
  //   if (val.title?.length > 24) {
  //     val.width = 250;
  //   } else if (val.title?.length > 15) {
  //     val.width = 200;
  //   } else if (val.title?.length > 12) {
  //     val.width = 150;
  //   } else if (val.title?.length > 8) {
  //     val.width = 125;
  //   } else {
  //     val.width = 90;
  //   }
  //   // console.log(val.title.length)
  // });
  array.map((val) => {
    // console.log(val.dataIndex)

    val.width =
      Number(val.title.length) * 11 +
      (cMargins[val.dataIndex] ? cMargins[val.dataIndex] : 0);
    });
  return arr;
};

class GeofenceSummaryList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.geofenceSummaryStore = new GeofenceSummaryStore();
    this.vehicleStore = new VehicleStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      selectedVehicles: [],
      selectAllVehicles: false,
      companyId: "",
      modalVisible: false,
      column: "",
      filter: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),

      showMonthPopup: false,
      Notcompany: false,
      Notasset: false,
      showTable: true,
      showtotaltable: false,
      selectedColumns: widthAdderFunc([
        {
          // title: " Vehicle No ",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",

          // fixed: "left",
        },
        {
          // title: "  Geofence Name",
          title: context.translate("_FLEET_REPORTS_GEOFENCELIST_GEOFENCENAME_"),
          dataIndex: "geoname",
          key: "geoname",

          // fixed: "left",
        },
        {
          // title: "  Geofence Type",
          title: context.translate("_GEOFENCE_TYPE_"),
          dataIndex: "typeName",
          key: "typeName",
        },
        {
          // title: "  Start Time",
          title: context.translate("_FLEET_START_TIME_"),
          dataIndex: "startTime",
          key: "startTime",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },

          //fixed: 'left',
        },
        {
          // title: "  End Time",
          title: context.translate("_END_TIME_"),
          dataIndex: "endTime",
          key: "endTime",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },

          //fixed: 'left',
        },
        {
          // title: "  In Time",
          title: context.translate("_FLEET_REPORTS_GEOFENCEREPORT_INTIME_"),
          dataIndex: "inTime",
          key: "inTime",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },

          //fixed: 'left',
        },
        {
          // title: " Out Time ",
          title: context.translate("_FLEET_REPORTS_GEOFENCEREPORT_OUTTIME_"),
          dataIndex: "outTime",
          key: "outTime",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },

          //fixed: 'left',
        },
        {
          // title: "  Distance",
          title: context.translate(
            "_FLEET_RULEDETAIL_SELECTPARAMETER_DISTANCE_"
          ),
          dataIndex: "distanceTravel",
          key: "distanceTravel",
        },
        {
          // title: "  Fuel ",
          title: context.translate("_FUEL_"),
          dataIndex: "fuel",
          key: "fuel",
        },
        {
          // title: "  Cost ",
          title: context.translate("_COST_"),
          dataIndex: "cost",
          key: "cost",
        },
        {
          // title: " RT Idle Time ",
          title: (
            <Tooltip title="Retail Idle Time" color="green">
              {context.translate("_RT_IDLE_TIME_")}
            </Tooltip>
          ),
          dataIndex: "Ridletime",
          key: "Ridletime",

          //fixed: 'left',
        },
        // {
        //   // title: " RT Idle Time ",
        //   title:context.translate("_RT_IDLE_TIME_"),
        //   dataIndex: "Ridletime",
        //   key: "Ridletime",

        //   //fixed: 'left',
        // },
        {
          // title: " RT Travel Time ",
          title: (
            <Tooltip title="Retail Travel Time" color="green">
              {context.translate("_RT_TRAVEL_TIME_")}
            </Tooltip>
          ),
          dataIndex: "Rtraveltime",
          key: "Rtraveltime",

          //fixed: 'left',
        },
        {
          // title: " FS Idle Time ",
          title: (
            <Tooltip title="Food Service Idle Time" color="green">
              {context.translate("_FS_IDLE_TIME_")}
            </Tooltip>
          ),
          dataIndex: "Fidletime",
          key: "Fidletime",

          //fixed: 'left',
        },
        {
          // title: " FS Travel Time ",
          title: (
            <Tooltip title="Food Service Travel Time" color="green">
              {context.translate("_FS_TRAVEL_TIME_")}
            </Tooltip>
          ),
          dataIndex: "Ftraveltime",
          key: "Ftraveltime",

          // fixed: "right",
        },
      ]),
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
        },
        // {
        //   // label: "CSV",
        //   label: context.translate("_CSV_"),
        //   key: "csv",
        // },
        // {
        //   // label: "XML",
        //   label: context.translate("_XML_"),
        //   key: "xml",
        // },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            intervel: this.state.intervel,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            userId: 1,
            filter: this.state.filter,

            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.geofenceSummaryStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.geofenceSummaryStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.geofenceSummaryStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.geofenceSummaryStore.exportxml(getData);
          }
        }
      },
    };

    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    // await this.geofenceSummaryStore.loadData({ offset: 0, limit: 50 });

    this.setState({ pageSize: 50 });
    await this.setState({ column: this.state.selectedColumns });

    this.geofenceSummaryColumns = widthAdderFunc([
      {
        // title: " Vehicle No ",
        title: this.context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
        dataIndex: "vehicleNo",
        // fixed: "left",
      },
      {
        // title: "Distance Travel",
        title: this.context.translate("_DISTANCE_TRAVEL_"),
        dataIndex: "totalDist",
        // fixed: "left",
      },
      {
        // title: "Fuel",
        title: this.context.translate("_FUEL_"),
        dataIndex: "totalFuel",
      },
      {
        // title: "Cost",
        title: this.context.translate("_COST_"),
        dataIndex: "totalCost",
        //fixed: 'left',
      },
      {
        // title: "FS Idle",
        // title: this.context.translate("_FS_IDLE_"),
        title: (
          <Tooltip title="Food Service Idle" color="green">
            {this.context.translate("_FS_IDLE_")}
          </Tooltip>
        ),
        dataIndex: "totalfsIdle",
        //fixed: 'left',
      },
      {
        // title: "FS Travel",
        // title: this.context.translate("_FS_TRAVEL_"),
        title: (
          <Tooltip title="Food Service Travel" color="green">
            {this.context.translate("_FS_TRAVEL_")}
          </Tooltip>
        ),
        dataIndex: "totalfsTravel",
        //fixed: 'left',
      },
      {
        // title: "RT Idle",
        // title: this.context.translate("_RT_IDLE_"),
        title: (
          <Tooltip title="Retail Idle Time" color="green">
            {this.context.translate("_RT_IDLE_")}
          </Tooltip>
        ),
        dataIndex: "totalrtIdle",
        //fixed: 'left',
      },
      {
        // title: "RT Travel",
        // title: this.context.translate("_RT_TRAVEL_"),
        title: (
          <Tooltip title="Retail Travel Time" color="green">
            {this.context.translate("_RT_TRAVEL_")}
          </Tooltip>
        ),
        dataIndex: "totalrtTravel",
      },
      {
        // title: "Total RT Time(Minutes)",
        // title: this.context.translate("_TOTAL_RT_TIME_"),
        title: (
          <Tooltip title="Total Retail Time" color="green">
            {this.context.translate("_TOTAL_RT_TIME_")}
          </Tooltip>
        ),
        dataIndex: "totalRttime",
      },
      {
        // title: "Total FS Time(Minutes)",
        // title: this.context.translate("_TOTAL_FS_TIME_"),
        title: (
          <Tooltip title="Total Food Service Time" color="green">
            {this.context.translate("_TOTAL_FS_TIME_")}
          </Tooltip>
        ),
        dataIndex: "totalFstime",
      },
      {
        // title: "Total RT Cost",
        // title: this.context.translate("_TOTAL_RT_COST_"),
        title: (
          <Tooltip title="Total Retail Time Cost" color="green">
            {this.context.translate("_TOTAL_RT_COST_")}
          </Tooltip>
        ),
        dataIndex: "totalRtcost",
        //fixed: 'left',
      },
      {
        // title: "Total FS Cost",
        // title: this.context.translate("_TOTAL_FS_COST_"),
        title: (
          <Tooltip title="Total Food Service Cost" color="green">
            {this.context.translate("_TOTAL_FS_COST_")}
          </Tooltip>
        ),
        dataIndex: "totalFscost",
        //fixed: 'left',
      },
    ]);
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false, currentPage: 1 });
    this.setState({ selectedVehicles: [], selectAllVehicles: false });
    await this.setState({ companyId: comp?.value });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    // await this.handleSearch();
    await this.setState({ loadData: 1 });
    // console.log("data", data);
  }

  async handleSearch() {
    // if(e){
    //   const filterVehicle=[];

    //     this.vehicleStore?.getVehiclesUsingCompanyId.filter((f) => {
    //     if (f.vehicleNo.includes(e)){
    //        filterVehicle.push(f)
    //     }
    //   });

    //   this.filterVehicle = filterVehicle;
    // }

    this.setState({ Notasset: false });
    // this.setState({ loading: true });
    // await this.geofenceSummaryStore.loadData({
    //   offset: (this.state.currentPage - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,
    // });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.showData();
  }

  // vehicleSelect = async (val) => {
  //   console.log(val);
  //   await this.setState({ selectedVehicles: val });
  // };

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.geofenceSummaryStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          geoZoneId: this.state.sele,
        });
        this.setState({ loadData: 1 });
      }
    }
  };

  showSummaryData = async () => {
    if (this.checkFromDate(this.state.fromdateStdTime)) {
      await this.geofenceSummaryStore.loadGeofenceSummaryData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
        vehicleId: this.state.selectedVehicles,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        geoZoneId: this.state.sele,
      });
    }
  };
  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss,";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    } else {
      this.setState({
        fromDate: "",
        toDate: "",
      });
    }
  };

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };

  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }

  render() {
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const assetInputStyle = this.state.Notasset
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Geofence Summary</div> */}
              <div className="heading">
                {this.context.translate("_GEOFENCE_SUMMARY_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Button
                  onClick={async () => {
                    // this.setState({ showSummary: true });
                    await this.showSummaryData();
                    await this.setState({
                      showTable: false,
                      showtotaltable: true,
                    });
                  }}
                >
                  <Space>
                    {/* Summary */}
                    {this.context.translate("_SUMMARY_")}
                  </Space>
                </Button>
                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.geofenceSummaryStore?.geofenceSummaryStore?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
                {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles: [],
                        selectAllVehicles: false,
                      })
                    }
                  />
                )}
                <Checkbox
                  checked={this.state.selectAllVehicles}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllVehicles: e.target.checked,
                    });
                    console.log(e.target.checked);
                    await this.handleAllSelectVehicles();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                {/* <Select
                  mode="multiple"
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val, Notasset: false });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }
                  }}
                  onSearch={this.handleSearch}
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  optionFilterProp="children"
                  // options={this.filterVehicle ? this.filterVehicle : this.vehicleStore?.getVehiclesUsingCompanyId}
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 150 }}
                  // style={assetInputStyle }
                  status={this.state.Notasset ? "error" : false}
                /> */}

                <Select
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }

                    if (val?.length > 1) {
                      console.log(val?.length > 1);
                      this.setState({ showAlertPopup: true });
                    }
                  }}
                  style={{ width: 200 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  mode="multiple"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />

                <Button
                  type="primary"
                  onClick={async () => {
                    await this.setState({
                      showTable: true,
                      showtotaltable: false,
                    });
                    await this.setState({ currentPage: 1, pageSize: 50 });
                    await this.showData();
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>
          <>
            <div
              className="accordion-list"
              onClick={() =>
                this.setState({
                  showTable: !this.state.showTable,
                  showtotaltable: !this.state.showtotaltable,
                })
              }
            >
              {this.context.translate("_GEOFENCE_SUMMARY_")}

              <Button>
                {this.state.showTable ?  (<CaretDownOutlined />
                ) : (
                  <CaretUpOutlined />
                )}
              </Button>
            </div>
            {this.state.showTable && (
              <ListTable
                currentPage={this.state.currentPage}
                pageSize={this.state.pageSize}
                total={this.geofenceSummaryStore?.total}
                columns={this.state.column}
                dataSource={this.geofenceSummaryStore?.geofenceSummaryStore}
                loading={this.geofenceSummaryStore?.loading}
                onChange={this.handleTableChange}
              />
            )}
          </>
          <>
            <div
             className="accordion-list"
              onClick={() =>
                this.setState({
                  showtotaltable: !this.state.showtotaltable,
                  showTable: !this.state.showTable,
                })
              }
            >
              {this.context.translate("_GEOFENCE_SUMMARY_TOTAL_")}
              <Button >
                {this.state.showtotaltable ?  (<CaretDownOutlined />
                ) : (
                  <CaretUpOutlined />
                )}
              </Button>{" "}
            </div>
            {this.state.showtotaltable && (
              <ListTable
                columns={this.geofenceSummaryColumns}
                dataSource={this.geofenceSummaryStore?.geofenceSummary}
                loading={this.geofenceSummaryStore?.loadingData}
              />
            )}
          </>
        </div>
        <Modal
          open={this.state.modalVisible}
          width={300}
          // title="Select Columns"
          title={this.context.translate("_SELECT_COLUMNS_")}
          onCancel={() => this.setState({ modalVisible: false })}
          onOk={() => {
            this.setState({ modalVisible: false });
          }}
          footer={null}
        >
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            {this.newColumns.map((column, v) => {
              return (
                <Row key={v}>
                  <Col>
                    <Checkbox
                      onChange={() => this.handleCheckboxChange(column)}
                      checked={this.state.selectedColumns.some(
                        (c) => c.key === column.key
                      )}
                    >
                      {column.title}
                    </Checkbox>
                  </Col>
                </Row>
              );
            })}
          </div>
        </Modal>

        <Modal
          width={350}
          className="alert-model"
          title={
            <div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              {/* Alert */}
              {this.context.translate("_FLEET_ALERT_")}
            </div>
          }
          open={this.state.showMonthPopup}
          onOk={() => this.setState({ showMonthPopup: false })}
          onCancel={() => this.setState({ showMonthPopup: false })}
          footer={false}
        >
          {/* For Reports prior to 6 months please contact administrator */}
          {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
        </Modal>
      </>
    );
  }
}
GeofenceSummaryList.contextType = StoreContext;
export default observer(withRouter(GeofenceSummaryList));
