import React from "react";
import {
  Input,
  Select,
  Button,
  Space,
  DatePicker,
  Row,
  Col,
  Dropdown,
  Modal,
  Checkbox,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import { PlusOutlined, DownOutlined } from "@ant-design/icons";

import CommandSentReportStore from "../store/CommandSentReportStore";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import moment from "moment";
import config from "../config";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import VehicleStore from "../store/VehicleStore";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Cookies from "js-cookie";
import withRouter from "../withRouter";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;
const { Option } = Select;
class CommandsSentReportList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.commandSentReportStore = new CommandSentReportStore();
    this.vehicleStore = new VehicleStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      pageNo: 1,
      limit: 50,
      offset: 0,
      listUpdated: 0,
      companyId: "",
      selectedVehicles: [],
      modalVisible: false,
      column: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      Notcompany: false,
      Notasset: false,
      showMonthPopup: false,

      selectedColumns: [
        {
          // title: "Vehicle No",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",

          width: "15%",
        },
        {
          // title: "Date/Time",
          title: context.translate("_FLEET_REPORTS_EVENTREPORT_DATETIME_"),
          dataIndex: "sendDate",
          width: "15%",
          key: "sendDate",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
        },

        {
          // title: "Command Sent",
          title: context.translate("_FLEET_REPORTS_COMMANDREPORT_COMMANDSENT_"),
          dataIndex: "cmdString",
          width: "15%",
          key: "cmdString",
        },
        {
          // title: "Reply String",
          title: context.translate("_FLEET_REPORTS_COMMANDREPORT_REPLY_"),
          dataIndex: "reply",
          width: "15%",
          key: "reply",
        },
        {
          // title: "Did the device received the command?",
          title: context.translate(
            "_FLEET_REPORTS_COMMANDREPORT_DEVICECOMMAND_"
          ),
          dataIndex: "ackYesno",
          width: "20%",
          key: "ackYesno",
        },
        {
          // title: "User",
          title: context.translate("_FLEET_REPORTS_COMMANDREPORT_USER_"),
          dataIndex: "user",
          width: "15%",
          key: "user",
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        // {
        //   // label: "CSV",
        //   label:context.translate("_CSV_"),
        //   key: "csv",
        //   icon: <PlusOutlined />,
        // },
        // {
        //   // label: "XML",
        //   label:context.translate("_XML_"),
        //   key: "xml",
        //   icon: <PlusOutlined />,
        // },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        const result = [];
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          console.log(result.toString());

          var getData = {
            companyId: this.state.companyId,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            vehicleId: this.state.selectedVehicles,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            colList: result.toString(),
          };
          if (val.key == "excel") {
            this.commandSentReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.commandSentReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.commandSentReportStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.commandSentReportStore.exportxml(getData);
          }
        }
      },
    };

    this.newColumns = [...this.state.selectedColumns];
  }

  async componentDidMount() {
    this.setState({ pageSize: 15 });
    // await this.commandSentReportStore.loadData({ offset: 0, limit: 50 });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }

    await this.setState({ column: this.state.selectedColumns });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log("pagination", pagination);

    await this.setState({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.showData();
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });
    await this.setState({ selectedVehicles: [] });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    this.setState({ loadData: 1 });
    // await this.handleSearch();

    // console.log("data", data);
  }

  // async onSearch(value) {
  //     console.log(value)
  //     await this.setState({ emailId: value });
  //     this.handleSearch();
  // };

  async handleSearch() {
    this.setState({ loading: true });
    this.setState({ Notasset: false });
    // await this.commandSentReportStore.loadData({
    //   offset: (this.state.pageNo - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,

    //   pageNo: this.state.pageNo,
    //   vehicleId: this.state.selectedVehicles,
    // });
    await this.setState({ loadData: 1 });
    // await this.setState({ listUpdated: 1 });
  }
  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  vehicleSelect = async (val) => {
    console.log(val);
    await this.setState({ selectedVehicles: val });
  };
  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }

    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.commandSentReportStore.loadData({
          offset: (this.state.pageNo - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          pageNo: this.state.pageNo,
        });
        this.setState({ loadData: 1 });
        //  this.setState({ listUpdated: 1 });
      }
    }
  };

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };

  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }
  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  render() {
    //console.log('in render1')
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const assetInputStyle = this.state.Notasset
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Command Sent Report</div> */}
              <div className="heading">
                {this.context.translate("_COMMAND_SENT_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.commandSentReportStore?.commandSentReport?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          {/* <Row style={{ padding: "10px 0px" }}> */}
          <Row className="listInputRow">
            <Space>
              <Col span={16}>
                <CompanyAutocomplete
                  onChange={(value) => this.handleChange(value)}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
              </Col>
              {this.props.getUserData().isadmin === 1 && (
                <DeviceGroupsForVehicles
                  vehicleStore={this.vehicleStore}
                  setLoadDta={() => this.setState({ loadData: 1 })}
                  resetVehicles={() =>
                    this.setState({
                      selectedVehicles: [],
                    })
                  }
                />
              )}
              <Col>
                <Select
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }

                    if (val?.length > 1) {
                      console.log(val?.length > 1);
                      this.setState({ showAlertPopup: true });
                    }
                  }}
                  style={{ width: 150 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  mode="multiple"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
                {/* <Select
                  mode="multiple"
                  onSearch={this.handleSearch}
                  value={this.state.selectedVehicles}
                  maxTagCount="responsive"
                  onChange={(val) => {
                    this.vehicleSelect(val);
                    this.setState({ Notasset: false });
                  }}
                  // onSelect={(val) => this.setState({ selectedVehicles: val })}
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 150 }}
                  // style={assetInputStyle }
                  status={this.state.Notasset ? "error" : false}
                /> */}
              </Col>

              <Col>
                <RangePicker
                  placeholder={[
                    this.context.translate("_FROM_DATE_TIME_"),
                    this.context.translate("_TO_DATETIME_"),
                  ]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
              </Col>

              <Col></Col>
              <Col>
                {/* {" "} */}
                <Button
                  type="primary"
                  onClick={async () => {
                    await this.setState({ pageNo: 1, pageSize: 50 });
                    await this.showData();
                  }}
                >
                  {" "}
                  {/* Show */}
                  {this.context.translate("_FLEET_REPORTS_SHOWBTN_")}
                </Button>
                {/* <Button
                  type="primary"
                  onClick={async() => {
                    await this.setState({pageSize:50,pageNo: 1})
                    this.handleTableChange();
                    await this.showData();
                  }}
                >
                  Show
                </Button> */}
              </Col>
            </Space>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={350}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.pageNo}
            pageSize={this.state.pageSize}
            total={this.commandSentReportStore?.total}
            columns={this.state.column}
            dataSource={this.commandSentReportStore?.commandSentReport}
            loading={this.commandSentReportStore?.loading}
            onChange={this.handleTableChange}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   total: this.commandSentReportStore.total || 0,
            // }}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />

          <Modal
            width={350}
            className="alert-model"
            title={
              <div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-exclamation-triangle"
                ></i>{" "}
                {/* Alert */}
                {this.context.translate("_FLEET_ALERT_")}
              </div>
            }
            open={this.state.showMonthPopup}
            onOk={() => this.setState({ showMonthPopup: false })}
            onCancel={() => this.setState({ showMonthPopup: false })}
            footer={false}
          >
            {/* For reports prior to 6 months please contact administrator */}
            {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
          </Modal>
        </div>
      </>
    );
  }
}
CommandsSentReportList.contextType = StoreContext;
export default observer(withRouter(CommandsSentReportList));
