import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from 'mobx';

class VehicleDistanceStore {
  vehicleDistanceVehicles=[]
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        vehicleDistanceVehicles: observable,
        loading:observable,
        total:observable,
        loadData:action,
        addData:action,
        getVehicleDistanceData:action,
        deleteRecord:action
      
    });
     this.rootStore = rootStore;
    
  }

  async loadData({companyId,offset,limit,currentPage}) {
    console.log("vehicle list",currentPage)

    this.loading=true
    var postData = {"companyId":companyId,"offset":offset,"limit":limit,"page":currentPage}  
   
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=manualdistance&a=manualDistanceList", "postData="+base64Input);
   
    console.log("df",response?.data?.data?.manualDistance)
    this.vehicleDistanceVehicles = response?.data?.data?.manualDistance

    this.total=parseInt(response?.data?.data?.TotalCount)
    this.loading=false
  }

  async addData(vehicledistance){
    console.log("data",vehicledistance)
    var postData = vehicledistance
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=manualdistance&a=update", "postData="+base64Input);
     console.log(response)
     return response
  }

  async getVehicleDistanceData(mid){
    console.log("getvehicledata",mid)
    var postData = {"mid":mid,offset:0,limit:1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=manualdistance&a=manualDistanceList", "postData="+base64Input);
   // console.log("response",response.data)
   return response.data.data.manualDistance[0]

 }

async deleteRecord({ manualDistanceId }) {
  var postData = { "manualDistanceId": manualDistanceId }
  const base64Input = await base64_encode(JSON.stringify(postData));
  var response = await postApi.getClient().post("index.php?c=manualdistance&a=delete", "postData=" + base64Input);
  return response;

}


}

export default VehicleDistanceStore;
