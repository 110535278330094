import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class VehicleGroupStore {
  vehicleGroupVehicles = [];
  loading = false;
  assetList = [];
  vehicleArray = [];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      vehicleGroupVehicles: observable,
      loading: observable,
      vehicleArray: observable,
      loadData: action,
      addData: action,
      getVehicleGroupData: action,
      getAssetData: action,
      deleteRecord: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId }) {
    this.loading = true;

    var postData = { companyId: companyId, userId: null };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=getalldata", "postData=" + base64Input);

    this.vehicleGroupVehicles = response?.data?.data[0]?.devicegroups;
    this.loading = false;
  }

  async addData(vehicleGroupData) {
    var postData = vehicleGroupData;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=devicegroup&a=vehicles", "postData=" + base64Input);

    return response;
  }

  async getVehicleGroupData(deviceGroupId, companyId) {
    var postData = {
      deviceGroupId: deviceGroupId,
      limit: 1,
      offset: 0,
      companyId: companyId,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=getalldata", "postData=" + base64Input);
    this.vehicleArray = response.data.data[0].devicegroups[0].vehicles;
    // console.log(vehicleArray)
    return response.data.data[0].devicegroups[0];
  }

  // To get asset list
  async getAssetData(companyId) {
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);

    this.assetList = response?.data?.data;
    return response?.data?.data;
  }

  async deleteRecord({ deviceGroupId }) {
    var postData = { tokenId: "", deviceGroupId: deviceGroupId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=deviceGrp&a=deviceDelete", "postData=" + base64Input);
    return response;
  }
}

export default VehicleGroupStore;
