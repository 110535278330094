import React from "react";
import { Row, Col, message, Button, Space, Input, Tooltip, Modal, Image } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";

import ListTable from "./ListTable";
import config from "../config";
import DeleteConfirm from "./DeleteConfirm";

import CompanyAutocomplete from "./CompanyAutocomplete";
import { PlusOutlined, DeleteFilled, SearchOutlined } from "@ant-design/icons";

import VehicleLiveLocationStore from "../store/VehicleLiveLocationStore";
import CompanyStore from "../store/CompanyStore";
import VehicleLiveLocationAdd from "./VehicleLiveLocationAdd";
import ShareModalWindow from "./ShareModalWindow";
class VehicleLiveLocationList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.companyStore = new CompanyStore(this);
    this.onDelete = this.onDelete.bind(this);
    this.vehicleLiveLocationStore = new VehicleLiveLocationStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      companyId: "",
      // openConfirm: false,
      // confirmLoading: false,
      loadData: 0,
      company_value_label: "",
      listUpdated: 0,
      searchField: "",
      openLiveLocationAddForm: false,
      vehicleLiveLocationId: "",
      listDataUpdate: false,
      companyFieldHighlight: false,
      shareModalVisible: false,
    };
  }
  onEdit = async (record) => {
    await this.context.viewStateStore.setLiveLocationStates({
      companyId: record.companyId,
    });
    await this.setState({ vehicleLiveLocationId: record.id });
    await this.setState({ openLiveLocationAddForm: true });
    // this.props.navigate(
    //   "/vehiclelivelocationadd/" + record.id + "/" + record.vehicleId
    // );
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const beforeEditCompanyId =
        this.context.viewStateStore.liveLocationObject.companyId;
      if (beforeEditCompanyId !== "") {
        await this.vehicleLiveLocationStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: beforeEditCompanyId,
        });
        const singleData = await this.companyStore.getCompanyDataById(
          this.context.viewStateStore.liveLocationObject.companyId
        );
        await this.setState({ listUpdated: 1 }); //need to reload data
        await this.setState({
          company_value_label: singleData,
          companyId: beforeEditCompanyId,
        });
      }
      this.setState({ listDataUpdate: false });
    }
  }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      this.context.viewStateStore.setUserAddStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ isadmin: this.props.getUserData().isadmin });
      await this.handleSearch();
    }
    // await this.vehicleLiveLocationStore.loadData({ offset: 0, limit: 50 });

    this.setState({ pageSize: 50 });

    this.columns = [
      {
        // title: " Company Name ",
        title: this.context.translate("_COMPANY_NAME_"),
        dataIndex: "name",
        width: "20%",
        render: (value, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        // title: "Vehicle No ",
        title: this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        width: "20%",
      },
      {
        // title: "Account Expiry Date",
        title: this.context.translate(
          "_FLEET_COMPANY_COMPANYDETAIL_EXPIREDATE_"
        ),
        dataIndex: "expiryDate",
        width: "20%",
      },
      {
        // title: " Live Location URL ",
        title: this.context.translate("_LIVE_LOCATION_URL_"),
        dataIndex: "locationUrl",
        width: "20%",
        // render: (value) => {
        //   return <Link>{value}</Link>;
        // },
        render: (value) => {
          const handleLinkClick = () => {
            window.open(value, "_blank");
          };
          return (
            <span style={{ cursor: "pointer" }} onClick={handleLinkClick}>
              {value}
            </span>
          );
        },
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        // fixed: 'right',
        width: "20%",
        render: (_, record) => {
          console.log(record)
          return (
         
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    // title="Edit"
                    title={this.context.translate("_FLEET_COMPANY_COMPANYLIST_EDIT_")}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      // title="Delete"
                      title={this.context.translate(
                        "_FLEET_COMPANY_COMPANYLIST_DELETE_"
                      )}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>

                <Image
                  src={require("../assets/images/detailtracking/share-icon.png")}
                  height={15}
                  width={15}
                  onClick={() => {
                    this.setState({ shareModalVisible: record.locationUrl });
                    // console.log(data);
                  }}
                />
              </Space>
            </>
          );
        },
      },
    ];
    await this.setState({ listUpdated: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      currentPage: 1,
      pageSize: 50,
    });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ company_value_label: singleData });

    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.vehicleLiveLocationStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadData: 1 });
  }

  async onDelete(record) {
    const response = await this.vehicleLiveLocationStore.deleteRecord({
      id: record.id,
    });
    //  console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
    this.setState({ loadData: 1 });
  }
  searchInput = async (e) => {
    const value = e.target.value;
    if (value.length > 3 || value.length === 0) {
      await this.vehicleLiveLocationStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,

        searchField: value,
        companyId: this.state.companyId,
      });
      await this.setState({ loadData: 1 });
    }
  };

  onSearchClick = async () => {
    if (!this.state.companyId) {
      this.setState({ companyFieldHighlight: true });
    } else {
      this.setState({ companyFieldHighlight: false });
    }
    if (this.state.companyId) {
      this.setState({ companyFieldHighlight: false, loading: true });
      await this.vehicleLiveLocationStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        searchField: this.state.searchField,
        companyId: this.state.companyId,
      });
      await this.setState({ loadData: 1 });
    }
  };

  responseMsg = async (resp) => {
    // console.log(resp.data.errors)
    if (resp.data.success == "S") {
      message.success(resp.data.message);
      //  this.props.navigate("/vehiclelivelocationlist");
      await this.setState({ openLiveLocationAddForm: false });
      await this.setState({ listDataUpdate: true });
    } else if (resp.data.success == "F4000") {
      resp.data.errors.map((value) => {
        message.error(value["vehicleNo"]);
        // message.error(resp.data.message);
      });
    }
  };

  closeModal = () => {
    this.setState({
      openLiveLocationAddForm: false,
      vehicleLiveLocationId: "",
    });
  };

  render() {
    const companyInputBox = this.state.companyFieldHighlight
      ? { border: "2px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_LIVE_LOCATION_TITLE_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  title={this.context.translate("_ADD_")}
                  link="/vehiclelivelocationadd"
                /> */}
                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  placement="left"
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({
                        openLiveLocationAddForm: true,
                        vehicleLiveLocationId: "",
                      })
                    }
                  ></Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  // allowClear={true}
                  value={
                    this.state.company_value_label
                      ? this.state.company_value_label
                      : null
                  }
                  onChange={(value) => this.handleChange(value)}
                  style={companyInputBox}
                />
                <Input
                  style={{ width: 300 }}
                  // placeholder="Search By Vehicle No"
                  placeholder={this.context.translate("_SEARCH_BY_VEHICLE_NO_")}
                  onKeyUp={(e) => this.searchInput(e)}
                  onChange={async (e) => {
                    await this.setState({ searchField: e.target.value });
                  }}
                />
                <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  onClick={this.onSearchClick}
                />
              </Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            total={this.vehicleLiveLocationStore?.total}
            pageSize={this.state.pageSize}
            columns={this.columns}
            dataSource={
              this.vehicleLiveLocationStore?.vehicleLiveLocationVehicles
            }
            loading={this.vehicleLiveLocationStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openLiveLocationAddForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Live Location"
            title={this.context.translate("_LIVE_LOCATION_")}
            centered
            visible={this.state.openLiveLocationAddForm}
            footer={null}
            onCancel={() => {
              this.setState({
                openLiveLocationAddForm: false,
                vehicleLiveLocationId: "",
              });
            }}
            width={665}
          >
            <div>
              <VehicleLiveLocationAdd
                vehicleLiveLocationId={this.state.vehicleLiveLocationId}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}

{this.state.shareModalVisible && (
          <ShareModalWindow
            shareModalCloseFunc={(resp) =>
              this.setState({ shareModalVisible: resp })
            }
            shareLiveLocation={this.state.shareModalVisible}
          />
        )}
      </>
    );
  }
}
VehicleLiveLocationList.contextType = StoreContext;
export default observer(withRouter(VehicleLiveLocationList));
