import React, { Component } from "react";
import { Button, Form, Input, Row, Select, message, Col } from "antd";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import GPRSCommandStore from "../store/GPRSCommandStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import withRouter from "../withRouter";
import AddLocationStore from "../store/AddLocationStore";

class AddLocation extends Component {
  formRef = React.createRef();
  constructor(prop) {
    super(prop);
    this.state = { companyId: "" };
    this.gprsStore = new GPRSCommandStore(this);
    this.addLocationStore = new AddLocationStore(this);
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });

    const data = await this.gprsStore.getVehicles({ companyId: comp.value });
    console.log(data);

    this.setState({loadData:1})
  }
  //   async componentDidUpdate() {
  //     if (Number(this.props.getUserData().isadmin) === 1) {
  //       this.props.navigate("/login");
  //     }
  //   }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });

      const data = await this.gprsStore.getVehicles({
        companyId: this.props.getUserData().userCompanyId,
      });
      // await this.setState({userId:this.props.getUserData().userId})
      await this.setState({ isadmin: this.props.getUserData().isadmin });
    }
  }

  onAddLocation = async (fieldsValue) => {
    this.setState({ loading: true });

    const data = {
      reversegeo: {
        companyId: this.state.companyId,
        vehicleId: fieldsValue.vehicleId,
        latitude: fieldsValue.latitude,
        longitude: fieldsValue.longitude,
        address: fieldsValue.address,
      },
    };

    const response = await this.addLocationStore.addData(data);
    if (response?.success === "S") {
      await message.success(response.message);
    } else {
      message.error(response.message);
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <>
        <div className="changePassHeadline">
          {this.context.translate("_GPRS_COMMAND_")}
        </div>
        <div className="changePassLayout">
          <Form
            ref={this.formRef}
            layout="vertical"
            name="nest-messages"
            onFinish={this.onAddLocation}
            style={{ maxWidth: "100%", padding: 0 }}
            className="changePassForm"
          >
            <Row style={{ padding: "0px 20px" }}>
              {(this.props.getUserData().isadmin === -1 ||
                this.props.getUserData().isadmin === 3) && (
                <Form.Item
                  name="companyId"
                  label={this.context.translate("_ACCOUNT_")}
                  rules={[{ required: true }]}
                  style={{ width: "100%" }}
                >
                  <CompanyAutocomplete
                    style={{ background: "transparent" }}
                    onChange={(value) => {
                      this.handleChange(value);
                      this.formRef.current.setFieldsValue({
                        vehicleId: undefined,
                      });
                    }}
                  />
                </Form.Item>
              )}
            </Row>
            <Row
              style={{
                padding: "0px 20px",
                justifyContent: "space-between",
              }}
            >
              <Form.Item
                className="search-field"
                name="vehicleId"
                label={this.context.translate(
                  "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                )}
                rules={[{ required: true }]}
                style={{ width: "100%" }}
              >
                <Select
                  placeholder={this.context.translate(
                    "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                  )}
                  showSearch
                  fieldNames={{ value: "vehicleId", label: "vehicleNo" }}
                  optionFilterProp="children"
                  options={this.gprsStore?.getVehiclesUsingCompanyId}
                />
              </Form.Item>
            </Row>
            <Row style={{ padding: "0px 20px" }}>
              <Form.Item
                name="latitude"
                label={this.context.translate("_LATITUDE_")}
                style={{ width: "100%" }}
              >
                <Input />
              </Form.Item>
            </Row>
            <Row style={{ padding: "0px 20px" }}>
              <Form.Item
                name="longitude"
                label={this.context.translate("_LOGITUDE_")}
                style={{ width: "100%" }}
              >
                <Input />
              </Form.Item>
            </Row>
            <Row style={{ padding: "0px 20px" }}>
              <Form.Item
                name="address"
                label={this.context.translate("_LOCATION_")}
                rules={[{ required: true }]}
                style={{ width: "100%" }}
              >
                <Input />
              </Form.Item>
            </Row>
            <div className="formBtnGroup">
              <Button
                className="formCancelButton"
                style={{ marginBottom: "10px" }}
                type="primary"
                onClick={(e) => {
                  this.formRef.current?.resetFields();
                }}
              >
                {this.context.translate("_FLEET_DASHBORD_SHOWPOI_CANCELBTN_")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="formSaveButton"
                style={{ marginBottom: "10px" }}
              >
                {this.context.translate("_FLEET_CREATEROUTE_ROUTEADD_SAVEBTN_")}
              </Button>
            </div>
          </Form>
        </div>
      </>
    );
  }
}
AddLocation.contextType = StoreContext;
export default observer(withRouter(AddLocation));
