import React, { Component } from "react";
import {
  Button,
  Checkbox,
  message,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Upload,
  Spin,
} from "antd";
import config from "../config";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import DriversStore from "../store/DriversStore";
import withRouter from "../withRouter";
import { Link } from "react-router-dom";
import CompanyAutocomplete from "./CompanyAutocomplete";
import moment from "moment/moment";
import dayjs from "dayjs";
import CompanyStore from "../store/CompanyStore";
import CountryStore from "../store/CountryStore";
import { UploadOutlined } from "@ant-design/icons";
import conf from "../config";

// import moment from 'moment';

// const validateMessages = {
//   required: "${label} is required",
//   types: {
//     email: "${label} is not a valid email",
//     number: "${label} is not a valid number",
//   },
//   number: {
//     range: "${label} must be between ${min} and ${max}",
//   },
// };

class DriverAdd extends Component {
  formRef = React.createRef();
  constructor(prop) {
    super(prop);
    this.state = {
      loading: false,
      update: 0,
      driverId: -1,
      userId: -1,
      duserId: 1,
      companyId: "",
      loadList: 0,
      offset: "",
      limit: "",
      searchField: "",
      tagIdShow: false,
      photo: "",
      loadingSpin: false,
      // isadmin:0
      // vehicleType:[]
    };
    this.driverStore = new DriversStore(this);
    this.companyStore = new CompanyStore(this);
    this.countryStore = new CountryStore(this);
  }
  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      this.context.viewStateStore.setDriverState({
        companyId: this.props.getUserData().userCompanyId,
      });

      console.log(this.props.getUserData());
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      console.log(this.state.companyId);
      // await this.setState({userId:this.props.getUserData().userId})
      // await this.setState({isadmin:this.props.getUserData().isadmin})
    }
    console.log(this.props.params);
    await this.countryStore.getCountry();
    // const driverId = this.props.params.driverId;
    const driverId = this.props.driverId;

    if (driverId) {
      await this.fillData(driverId);
    }

    this.setState({ update: 1 });
  }
  fillData = async (driverId) => {
    await this.setState({ loadingSpin: true });
    const getDriverData = await this.driverStore.getDriverData(driverId);
    await this.setState({
      companyId: getDriverData.companyId,
      driverId: getDriverData.driverId,
    });
    const singleData = await this.companyStore.getCompanyDataById(
      getDriverData.companyId
    );

    await this.driverStore.getVehicles(getDriverData.companyId);

    console.log(getDriverData, "driverdata");
    this.formRef.current.setFieldsValue({
      driverId: getDriverData.driverId,
      drivername: getDriverData.name,
      driverNameArabic:getDriverData.driverNameArabic,
      licensenumber: getDriverData.licensenumber,
      licenseNoArabic:getDriverData.licenseNoArabic,
      vehicleId: getDriverData.vehicleId,

      // licenseexpirydate: moment(getDriverData.expireDate),

      licenseexpirydate: getDriverData?.expireDate
        ? dayjs(getDriverData.expireDate)
        : null,

      Nationality: getDriverData.Nationality
        ? parseInt(getDriverData.Nationality)
        : null,
      driveraddress: getDriverData.address,
      mobilenumber: getDriverData.mobileno,
      photo: getDriverData.photo,
      companyId: singleData,

      status: getDriverData.status,
      emergencyContName: getDriverData.e_contactName,
      emergencyContNumber: getDriverData.e_contactNo,
      bloodgroup: getDriverData.bloodGroup,
      employeeId: getDriverData.employeeId,

      DriverTagid: getDriverData.DriverTagid,
      notes: getDriverData.notes,
      otherPermit: getDriverData.otherPermit,
      permitNo: getDriverData.permitNo,

      permitExpiryDate: getDriverData?.permitExpiryDate
        ? dayjs(getDriverData.permitExpiryDate)
        : null,

      visaExpiryDate: getDriverData?.visaExpiryDate
        ? dayjs(getDriverData.visaExpiryDate)
        : null,

      // permitExpiryDate: moment(getDriverData.permitExpiryDate),
      // visaExpiryDate: moment(getDriverData.visaExpiryDate),

      Employementstatus: getDriverData.Employementstatus,
      // Nationality: getDriverData.Nationality,
      workingHours: getDriverData.workingHours,
      overtimeCharges: getDriverData.overtimeCharges,
      UUID: getDriverData.UUID,
      // dob: moment(getDriverData.dob),
      dob: getDriverData.dob ? dayjs(getDriverData.dob) : null,

      dobFormat: getDriverData.dobFormat,
      addedByIp: getDriverData.addedByIp,
      addedByUserId: getDriverData.addedByUserId,

      // addedByDateTime: moment(getDriverData.addedByDateTime),
      editedByIp: getDriverData.editedByIp,
      editedByUserId: getDriverData.editedByUserId,
      // editedByDateTime: moment(getDriverData.editedByDateTime),
      deleteByIp: getDriverData.deleteByIp,
      deleteByUserId: getDriverData.deleteByUserId,
      // deleteByDateTime: moment(getDriverData.deleteByDateTime),

      userName: getDriverData.username,
      password: getDriverData.password,
      userId: getDriverData.userId,
      vehicleType: getDriverData.vehicleType,
      tagIdcheckbox: getDriverData.tagIdcheckbox,
      countryCode: getDriverData.countryCode,
      emergencyContCountryCode: getDriverData.emergencyContCountryCode,
    });
    this.setState({ photo: getDriverData.photo, userId: getDriverData.userId });
    await this.setState({ loadingSpin: false });
    console.log(getDriverData.DriverTagid);
    if (getDriverData.DriverTagid !== "") {
      console.log("hello");
      this.setState({ tagIdShow: true });
    } else if (getDriverData.vehicleId !== null) {
      this.setState({ tagIdShow: true });
      console.log("vehi");
    } else {
      this.setState({ tagIdShow: false });
    }
  };

  onDriverDetailSubmit = async (fieldsValue) => {
    if (this.state.fileList?.size > conf.photoUploadSize) {
      message.error(this.context.translate("_FILE_SIZE_UPTO_10_MB"));
      return;
    }
    console.log(fieldsValue);
    const data = {
      ...fieldsValue,
      companyId: this.state.companyId,
      driverId: this.state.driverId,
      userId: this.state.userId,
      duserId: this.state.duserId,

      permitExpiryDate: fieldsValue?.permitExpiryDate
        ? fieldsValue?.permitExpiryDate?.format("YYYY-MM-DD")
        : null,

      licenseexpirydate: fieldsValue?.licenseexpirydate
        ? fieldsValue?.licenseexpirydate?.format("YYYY-MM-DD")
        : null,

      visaExpiryDate: fieldsValue?.visaExpiryDate
        ? fieldsValue?.visaExpiryDate?.format("YYYY-MM-DD")
        : null,

      dob: fieldsValue.dob ? fieldsValue?.dob?.format("YYYY-MM-DD") : null,
      photo: this.state.photo,
    };
    console.log(data);
    this.setState({ loading: true });
    const response = await this.driverStore.addData(data);
    await this.props.response(response);
   
    this.setState({ loading: false });
  };
  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    if (!this.props.driverId) {
      this.context.viewStateStore.setDriverState({
        companyId: this.state.companyId,
      });
    }
    await this.formRef.current.setFieldsValue({ companyId: comp.value });

    const data =await this.driverStore.getVehicles(comp.value);
    console.log(data);
    await this.handleSearch();
  }

  async handleSearch() {
    await this.driverStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
      isadmin: this.props.getUserData().isadmin,
    });
    await this.setState({ loadList: 1 });
  }
  showTagId = async (e) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      await this.setState({ tagIdShow: e.target.checked });
    } else {
      await this.setState({ tagIdShow: 0 });
    }
  };

  validatePhoneNumber = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1.length);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  validateNumber = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1);
    if (value1 > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };
  validateNumberAndDecimal = (value, field) => {
    const value1 = value.replace(/[^0-9.,]+/g, "");
    console.log(value1);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  validateFile = (file) => {
    this.setState({ fileList: file });
    console.log(file);
    const allowedExtensions = [".jpeg", ".jpg", ".png"];
    const fileName = file.name;
    const fileExtension = fileName.slice(
      ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
    );
    if (!allowedExtensions.includes("." + fileExtension.toLowerCase())) {
      message.error("Only JPEG and PNG files are allowed");
      return false;
    }
    const maxSize = conf.photoUploadSize;
    if (file.size > maxSize) {
      message.error(this.context.translate("_FILE_SIZE_UPTO_10_MB"));
      return false;
    }
    return true;
  };
  getUploadData = async (fileUp) => {
    console.log(fileUp.fileList);
    // if (fileUp.fileList[0]?.type == "image/png") {
    let fileinfo = fileUp.file.response;
    if (fileinfo?.success == "S") {
      // console.log(fileinfo.data)
      console.log("innnnnnnn");
      await this.setState({ photo: fileinfo.data });
    }
    fileUp.fileList[0].url = config.fleetURL + fileinfo?.folderName;
    this.driverStore.setPhotoFromDb(fileUp.fileList);
    this.setState({ update: 1 });
    // }
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              ref={this.formRef}
              layout="vertical"
              name="nest-messages"
              onFinish={this.onDriverDetailSubmit}
              style={{ maxWidth: "100%", padding: 0 }}
              // validateMessages={validateMessages}
              className="formClass"
            >
              {/* <div className="headingTitle">Driver Details</div> */}
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate(
                        "_FLEET_DRIVER_DRIVERDETAIL_COMPANY_"
                      )}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        allowClear={true}
                        onChange={(value) => this.handleChange(value)}
                        style={{ width: "100%" }}
                        companyId={this.state.companyId}
                      />
                    </Form.Item>
                  )}

                  {/* {this.context.translate("_FLEET_LOGIN_PASSWORDLBL_")} */}
                  {/* {this.context.translate("_VEHICLE_TYPE_")} */}

                  <Form.Item
                    style={{ marginTop: "7px" }}
                    name="vehicleType"
                    label={this.context.translate("_VEHICLE_TYPE_")}
                    className="label-seprator"
                  >
                    {/* <br/> */}
                    <Checkbox.Group>
                      <Checkbox
                        value="1"
                        onChange={(e) => {
                          console.log(e.target.value);
                        }}
                      >
                        {this.context.translate("_HEAVY_VEHICLE_")}
                      </Checkbox>
                      <Checkbox value="2">
                        {this.context.translate("_LIGHT_VEHICLE_")}
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>

                  <Form.Item
                    name="drivername"
                    label={this.context.translate("_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="driverNameArabic"
                    // label="Account Name"
                    label={this.context.translate("_DRIVER_NAME_ARABIC_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  {/* <Form.Item name={['driver', 'age']} label="Identity Number" rules={[{ type: Number }]}>
                                <Input />
                            </Form.Item> */}

                  <Form.Item
                    name="userName"
                    label={this.context.translate(
                      "_FLEET_COMPANY_USERDETAIL_USERNAME_"
                    )}
                    rules={[{ required: true }]}
                  >
                    <Input autoComplete="off" />
                  </Form.Item>

                  <Form.Item
                    label={this.context.translate("_FLEET_LOGIN_PASSWORDLBL_")}
                    name="password"
                    rules={[{ required: true }]}
                  >
                    <Input.Password autoComplete="off" id="form-password" />
                  </Form.Item>

                  {/* <Form.Item
                  name="mobilenumber"
                  label={this.context.translate(
                    "_FLEET_DRIVER_DRIVERDETAIL_MOBILE_"
                  )}
                  // rules={[{ required: true }]}
                  rules={[
                    {
                      required: true,
                      pattern: /^(\d{7}|\d{10})$/,
                      // message: "Enter 7 or 10 Digit Number",
                      message:this.context.translate("_Enter_7_or_10_Digit_Number_")
                    },
                  ]}
                  // rules={[{ type: Number, required: true }]}
                >
                  <Input 
                    onChange={(e) =>
                      this.validatePhoneNumber(e.target.value, "mobilenumber")
                    }
                    maxLength={13}
                  />
                </Form.Item> */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      style={{ width: "30%" }}
                      name="countryCode"
                      // label="Contact 1"
                      label={" "}
                      // rules={[{ required: true }]}
                      rules={[{ required: true }]}
                      initialValue={+966}
                    >
                      <Select>
                        <Select.Option value={91}>+91</Select.Option>
                        <Select.Option value={966}>+966</Select.Option>
                        {/* 966 = saudi arabia */}
                        <Select.Option value={971}>+971</Select.Option>
                        {/* 971 = uae */}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      style={{ width: "65%" }}
                      name="mobilenumber"
                      // label="Phone Number 1"
                      label={this.context.translate("_MOBILE_NUMBER_")}
                      rules={[
                        {
                          required: true,
                          pattern: /^(\d{7}|\d{10})$/,
                          // message: "Enter a 7 or 10 digit number",
                          message: this.context.translate(
                            "_Enter_7_or_10_Digit_Number_"
                          ),
                        },
                      ]}
                      // rules={[{ required: true}]}
                    >
                      <Input
                        onChange={(e) =>
                          this.validatePhoneNumber(
                            e.target.value,
                            "mobilenumber"
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="employeeId"
                    label={this.context.translate("_EMPLOYEE_ID_")}
                    rules={[{ type: Number }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="Employementstatus"
                    label={this.context.translate("_EMPLOYMENT_STATUS_")}
                  >
                    <Select
                      placeholder={this.context.translate(
                        "_FLEET_USER_USERDETAIL_STATUSDDL_SELECT_"
                      )}
                    >
                      <Select.Option value="Permanent">
                        {/* Permanent */}
                        {this.context.translate("_PERMANENT_")}
                      </Select.Option>
                      <Select.Option value="Temporary">
                        {/* Temporary */}
                        {this.context.translate("_TEMPORARY_")}
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                        name="visaExpiryDate"
                        label={this.context.translate("_VISA_EXPIRY_DATE_")}
                        rules={[{ type: Date }]}
                      >
                        <DatePicker
                          format={"YYYY-MM-DD"}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>

                      <Form.Item label=" ">
                        <Checkbox
                          name="tagIdcheckbox"
                          checked={this.state.tagIdShow}
                          onChange={(e) => {
                            this.showTagId(e);
                          }}
                        >
                          {this.context.translate("_TAG_ID_")}
                        </Checkbox>
                      </Form.Item>

                  {/* <Row>
                    <Col span={11}>
                     
                    </Col>
                    <Col span={2} />
                    <Col span={11}>
                      <Form.Item
                        name="dobFormat"
                        label={this.context.translate("_DOB_FORMATE_")}
                      >
                        <Select
                          placeholder={this.context.translate("_DOB_FORMATE_")}
                        >
                          <Select.Option value="Hijri">
                      
                            {this.context.translate("_HIJRI_")}
                          </Select.Option>
                          <Select.Option value="Gregorian">
                            
                            {this.context.translate("_GREGORIAN_")}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row> */}
                  {/* <Row>
                    <Col span={11}>
                      <Form.Item
                        name="dob"
                        label={this.context.translate("_DOB_LABEL_")}
                        rules={[{ type: Date }]}
                      >
                        <DatePicker
                          format="YYYY-MM-DD"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2} />
                    <Col span={11}>
                     
                    </Col>
                  </Row> */}

                  <Form.Item
                    name="Nationality"
                    label={this.context.translate("_NATIONALITY_")}
                    // rules={[{ required: true, message: "Please select country!" }]}
                  >
                    <Select
                      onSearch={this.handleSearch}
                      showSearch
                      // fieldNames={{ label: "country", value: "countryId" }}
                      // placeholder="Select Country"
                      placeholder={this.context.translate("_SELECT_COUNTRY_")}
                      optionFilterProp="children"
                      // options={this.countryStore?.country}
                      filterOption={(inputValue, option) => {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {this.countryStore?.country.map((val) => {
                        return (
                          <Select.Option value={Number(val.countryId)}>
                            {val.country}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="driveraddress"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_NEWPOI_ADDRESS_"
                    )}
                  >
                    <Input />
                  </Form.Item>

                  <Row>
                    <Col span={11}>
                      <Form.Item
                        name="workingHours"
                        label={this.context.translate("_WORKING_HOUR_")}
                        // rules={[{ type: Number }]}
                      >
                        <Input
                          onChange={(e) =>
                            this.validateNumberAndDecimal(
                              e.target.value,
                              "workingHours"
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2} />
                    <Col span={11}>
                      <Form.Item
                        name="overtimeCharges"
                        label={this.context.translate("_OVERTIME_CHARGE_")}
                        // rules={[{ type: Number }]}
                      >
                        <Input
                          onChange={(e) =>
                            this.validateNumberAndDecimal(
                              e.target.value,
                              "overtimeCharges"
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="licensenumber"
                    label={this.context.translate(
                      "_FLEET_DRIVER_DRIVERLIST_LICENCE_"
                    )}
                    rules={[{ type: Number }]}
                  >
                    <Input  onChange={(e) => {
                        const arabic = e.target.value.replace(
                          /[0-9]/g,
                          function (match) {
                            return String.fromCharCode(
                              match.charCodeAt(0) + 1584
                            );
                          }
                        );
                        this.formRef.current.setFieldsValue({
                          licenseNoArabic: arabic,
                        });
                      }}/>
                  </Form.Item>

                  <Form.Item
                    name="licenseNoArabic"
                    label={this.context.translate(
                      "_LICENSE_NUMBER_ARABIC_"
                    )}
                    rules={[{ type: Number }]}
                  >
                    <Input  disabled/>
                  </Form.Item>

                  <Form.Item
                    name="licenseexpirydate"
                    label={this.context.translate(
                      "_FLEET_DRIVER_DRIVERDETAIL_LICENSEEXPIRY_"
                    )}
                    rules={[{ type: Date }]}
                  >
                    <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
                  </Form.Item>

                  <Row>
                    <Col span={11}>
                      <Form.Item
                        name="otherPermit"
                        label={this.context.translate("_OTHER_PERMIT_")}
                        rules={[{}]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={2} />
                    <Col span={11}>
                      <Form.Item
                        name="permitNo"
                        label={this.context.translate("_PERMIT_NO_")}
                        rules={[{ type: Number }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={11}>
                      <Form.Item
                        name="permitExpiryDate"
                        label={this.context.translate("_PERMIT_EXPIRY_DATE_")}
                        rules={[{ type: Date }]}
                      >
                        <DatePicker
                          format="YYYY-MM-DD"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2} />
                    <Col span={11}>
                      <Form.Item
                        name="bloodgroup"
                        label={this.context.translate(
                          "_FLEET_DRIVER_DRIVERDETAIL_BLOODGROUP_"
                        )}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={11}>
                      <div
                        style={{ display: this.state.tagIdShow ? "" : "none" }}
                      >
                        <Form.Item
                          name="DriverTagid"
                          label={this.context.translate("_TAG_ID_")}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                      {/* </Form.Item> */}
                    </Col>
                    <Col span={2}></Col>
                    <Col span={11}>
                      <div
                        style={{ display: this.state.tagIdShow ? "" : "none" }}
                      >
                        <Form.Item
                          name="vehicleId"
                          // label="Vehicle No"
                          label={this.context.translate(
                            "_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"
                          )}
                        >
                          <Select
                            fieldNames={{
                              value: "vehicleId",
                              label: "vehicleNo",
                            }}
                            optionFilterProp="children"
                            options={this.driverStore?.vehicles}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Form.Item
                    name="emergencyContName"
                    label={this.context.translate(
                      "_FLEET_DRIVER_DRIVERDETAIL_EMERGENCYNAME_"
                    )}
                  >
                    <Input />
                  </Form.Item>

                  {/* <Form.Item
                    name="emergencyContNumber"
                    label={this.context.translate(
                      "_FLEET_DRIVER_DRIVERDETAIL_EMERGENCYNUMBER_"
                    )}
                    // rules={[{ type: Number, message: "Only Nuumbers" }]}
                    rules={[
                      {
                        pattern: /^(\d{7}|\d{10})$/,
                        message: "Enter 7 or 10 Digit Number",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) =>
                        this.validatePhoneNumber(
                          e.target.value,
                          "emergencyContNumber"
                        )
                      }
                      maxLength={13}
                    />
                  </Form.Item> */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      style={{ width: "30%" }}
                      name="emergencyContCountryCode"
                      // label="Contact 1"
                      label={" "}
                      // rules={[{ required: true }]}
                      initialValue={+966}
                    >
                      <Select>
                        <Select.Option value={91}>+91</Select.Option>
                        <Select.Option value={966}>+966</Select.Option>

                        <Select.Option value={971}>+971</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      style={{ width: "65%" }}
                      name="emergencyContNumber"
                      // label="Phone Number 1"
                      label={this.context.translate(
                        "_FLEET_DRIVER_DRIVERDETAIL_EMERGENCYNUMBER_"
                      )}
                      rules={[
                        {
                          // required: true,
                          pattern: /^(\d{7}|\d{10})$/,
                          message: this.context.translate(
                            "_Enter_7_or_10_Digit_Number_"
                          ),
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) =>
                          this.validatePhoneNumber(
                            e.target.value,
                            "emergencyContNumber"
                          )
                        }
                        maxLength={13}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="notes"
                    label={this.context.translate(
                      "_FLEET_DRIVER_DRIVERDETAIL_NOTES_"
                    )}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    // name="logo"
                    label=" "
                    className="formCheckboxCss"
                    //  valuePropName="fileList"

                    getValueFromEvent={this.normFile}
                  >
                    <Upload
                      name="photo"
                      action={
                        config.baseURL + "/index.php?c=company&a=fileupload"
                      }
                      listType="picture"
                      data={{ fileType: "driverphoto", fieldName: "photo" }}
                      onChange={this.getUploadData}
                      // onRemove={()=>this.setState({photo:"",fileList:[]})}
                      onRemove={async () => {
                        await this.setState({ photo: "", fileList: [] });
                        await this.driverStore.setPhotoFromDb([]);
                      }}
                      maxCount={1}
                      accept=".jpg,.png"
                      // Add the beforeUpload prop to perform file extension validation
                      beforeUpload={(file) => this.validateFile(file)}
                      {...this.driverStore.photoFromDb}
                    >
                      <Button className="imgupload" icon={<UploadOutlined />}>
                        {/* Click to Upload Photo Id */}
                        {this.context.translate("_CLICK_TO_UPLOAD_PHOTO_ID")}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <div className="formBtnGroup">
                {/* <Link to="/companydriverslist">
                  <Button ghost type="primary" htmlType="">
                    Back
                  </Button>
                </Link> */}
                <Button
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal();
                    // this.formRef.current?.resetFields();
                    this.setState({ tagIdShow: false });
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="formSaveButton"
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                  {/* Save */}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}
DriverAdd.contextType = StoreContext;
export default observer(withRouter(DriverAdd));
