
import {Button} from 'antd';
import { Link } from "react-router-dom";
import { PlusOutlined} from '@ant-design/icons';


 
  
  const AddButton = (props) => {
    return (
      <Link to={props.link}><Button type='primary'><PlusOutlined />{props.title}</Button></Link>
    );
  };

export default AddButton;
