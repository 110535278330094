import React, { Component } from "react";

import {
  Form,
  message,
  Select,
  Button,
  Input,
  DatePicker,
  Modal,
  Checkbox,
  Upload,
  Row,
  Col,
  Tooltip,
  Spin,
  TimePicker,
  Table,
  Space,
} from "antd";
import config from "../config";

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import JobManagementStore from "../store/JobManagementStore";

import CompanyAutocomplete from "./CompanyAutocomplete";
import VehicleStore from "../store/VehicleStore";
import DriversStore from "../store/DriversStore";
import CompanyStore from "../store/CompanyStore";
import {
  CloseOutlined,
  DeleteFilled,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import CountryStore from "../store/CountryStore";
import Autocomplete from "react-google-autocomplete";

import dayjs from "dayjs";
import { format, addMinutes } from "date-fns";
import DisplayLocationMap from "./DisplayLocationMap";
import DriverAdd from "./DriverAdd";
const { Option } = Select;
const currentDate = dayjs();

class JobManagementAdd extends Component {
  formRef = React.createRef();
  invoiceFormRef = React.createRef();
  constructor(props, context) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      showSales: false,

      showDriver: true,
      openDriverDetailsForm: false,
      customerAddress: false,
      selectGeofence: false,
      loadList: 0,
      companyId: "",
      Id: -1,
      Locationlat: "",
      Locationlong: "",
      extraFields: true,
      addressMap: false,
      addressInfo: [],
      tagIdShow: false,
      formLoading: false,
      invoice: "",
      openInvoiceNoForm: false,
      invoices: [],
      nextInvoiceId: 1,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.jobManagementStore = new JobManagementStore(this);
    this.vehicleStore = new VehicleStore(this);
    this.driverStore = new DriversStore(this);
    this.companyStore = new CompanyStore(this);
    this.countryStore = new CountryStore(this);

    this.columns = [
      {
        title: context.translate("_INVOICE_ID_"),
        dataIndex: "invoiceId",
        key: "invoiceId",
      },
      {
        title: context.translate("_INVOICE_NO_"),
        dataIndex: "invoiceNo",
        key: "invoiceNo",
      },
      {
        title: context.translate("_INVOICE_AMOUNT_"),
        dataIndex: "invoiceAmount",
        key: "invoiceAmount",
      },
      {
        title: context.translate("_INVOICE_DATE_"),
        dataIndex: "invoiceDate",
        key: "invoiceDate",
      },
      {
        title: context.translate("_DESCRIPTION_"),
        dataIndex: "description",
        key: "description",
      },
      {
        title: context.translate("_DEPOSITOR_"),
        dataIndex: "depositor",
        key: "depositor",
        render: (_, record) => {
          const depositor = this.jobManagementStore.depositorsList.find(
            (item) => item.userId === record.depositor
          );
          return depositor ? depositor.name : record.depositor;
        },
      },
      {
        title: context.translate("_ACTION_"),
        dataIndex: "action",
        key: "action",
        render: (text, record, index) => {
          // console.log(text, record, index);
          return (
            <>
              <Space>
                <Tooltip
                  // title="Remove"
                  title={this.context.translate("_REMOVE_")}
                  color={config.tooltipColorDelete}
                >
                  <DeleteFilled
                    style={{ color: config.deleteColor }}
                    onClick={async () => {
                      await this.onDeleteRow(index, Number(record.vehicleId));
                    }}
                  />
                </Tooltip>
              </Space>
            </>
          );
        },
      },
    ];
  }

  onDeleteRow = (index) => {
    const filterData = this.state.invoices.filter((val, idx) => idx !== index);

    this.setState({ invoices: filterData });
  };

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      this.context.viewStateStore.setJobManagementStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });

      // await this.setState({userId:this.props.getUserData().userId})
      await this.setState({ isadmin: this.props.getUserData().isadmin });
      await this.jobManagementStore.getVehicles(
        this.props.getUserData().userCompanyId
      );
      await this.driverStore.toGetDriverName(
        this.props.getUserData().userCompanyId
      );
      await this.jobManagementStore.getSalesPersonEx({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.jobManagementStore.getSalesPersonCoordinator({
        companyId: this.props.getUserData().userCompanyId,
      });

      const data = await this.jobManagementStore.getGeofenceData({
        companyId: this.props.getUserData().userCompanyId,
      });

      const vehicleData = await this.driverStore.getVehicles(
        this.props.getUserData().userCompanyId
      );

      await this.jobManagementStore.getRoutesData(
        this.props.getUserData().userCompanyId
      );

      await this.jobManagementStore.getDepositorsData(
        this.props.getUserData().userCompanyId
      );

      await this.setState({ loadList: 1 });
    }

    const Id = this.props.Id;

    await this.setState({ formLoading: true });
    if (Id) {
      const getJobManagementData =
        await this.jobManagementStore.getJobManagementData(Id);
      await this.setState({
        companyId: getJobManagementData.companyId,
        Id: getJobManagementData.Id,
      });
      const singleData = await this.companyStore.getCompanyDataById(
        getJobManagementData.companyId
      );
      await this.setState({ companyLable: singleData });
      await this.jobManagementStore.getVehicles(getJobManagementData.companyId);
      await this.driverStore.toGetDriverName(getJobManagementData.companyId);
      await this.jobManagementStore.getSalesPersonEx({
        companyId: getJobManagementData.companyId,
      });
      await this.jobManagementStore.getSalesPersonCoordinator({
        companyId: getJobManagementData.companyId,
      });
      await this.jobManagementStore.getGeofenceData({
        companyId: getJobManagementData.companyId,
      });

      await this.jobManagementStore.getDepositorsData(
        getJobManagementData.companyId
      );

      await this.jobManagementStore.getRoutesData(
        getJobManagementData.companyId
      );

      await this.formRef.current.setFieldsValue({
        companyId: singleData,
        JobTypeCombo: getJobManagementData.jtype,
        pickupDelivery: getJobManagementData.pickupDelivery,
        driverId: Number(getJobManagementData.driverId),
        vehicleId: getJobManagementData.vehicleId,
        mobileno: getJobManagementData.mobileno,
        customerName: getJobManagementData.customerName,
        customerMobile: getJobManagementData.customerMobile,
        notificationemail: getJobManagementData.notificationemail,
        SearchByOption: getJobManagementData.SearchByOption,
        autoFinish: getJobManagementData.autoFinish,
        contactName: getJobManagementData.contactName,
        landLineNo: getJobManagementData.landLineNo,
        coordinatorId: getJobManagementData.coordinatorId,
        salespersonId: getJobManagementData.salespersonId,
        location: getJobManagementData.customerAddress,
        noteToDriver: getJobManagementData.noteToDriver,
        invoiceNo: getJobManagementData.invoiceNo,
        invoiceAmount: getJobManagementData.invoiceAmount,
        materialDesc: getJobManagementData.materialDesc,
        deliveryTime: getJobManagementData.deliveryTime,
        deliveryDate: getJobManagementData.deliveryDate
          ? dayjs(getJobManagementData.deliveryDate)
          : null,
        invoiceDate: getJobManagementData.invoiceDate,
        //   ? dayjs(getJobManagementData.invoiceDate)
        //   : null,
        // deliveryDate: moment(getJobManagementData.deliveryDate),
        haltTime: dayjs(getJobManagementData.haltTime, "HH:mm"),
        noOfCartons: getJobManagementData.noOfCartons,
        NearestVehGeofence: getJobManagementData.NearestVehGeofence,
        zoneId: getJobManagementData.zoneId,
        invoice: getJobManagementData.invoice,
        mobilenoCountryCode: getJobManagementData.mobilenoCountryCode,
        countryCode: getJobManagementData.countryCode,
        depositor: getJobManagementData.depositor,
        customerCode: getJobManagementData.customerCode,
        routeId: getJobManagementData.routeId,
        companyLable: "",
      });
      this.setState({ invoice: getJobManagementData.invoice });
      this.setState({ showTable: true });

      console.log(this.state.invoices);

      if (
        getJobManagementData.SearchByOption === "Search Map" ||
        getJobManagementData.SearchByOption === "Select"
      ) {
        this.setState({ customerAddress: true });
        this.setState({ selectGeofence: false });
        this.formRef.current.setFieldsValue({ SearchByOption: "Search Map" });
      } else if (getJobManagementData.zoneId) {
        this.setState({ selectGeofence: true });
        this.setState({ customerAddress: false });
        this.formRef.current.setFieldsValue({ SearchByOption: "2" });
      }
    }
    await this.setState({ formLoading: false });
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.addressMap &&
      prevState.addressMap !== this.state.addressMap
    ) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 10);
    }
  }

  searchBy = (value) => {
    if (value === "1") {
      this.setState({ customerAddress: true });
      this.setState({ selectGeofence: false });
    } else if (value === "2") {
      this.setState({ selectGeofence: true });
      this.setState({ customerAddress: false });
    }
  };

  onInvoiceSubmit = async (values) => {
    console.log(values);
    const data = {
      ...values,
      invoiceId: this.state.nextInvoiceId,
      invoiceDate: values?.invoiceDate
        ? values?.invoiceDate.format("YYYY-MM-DD")
        : null,
    };

    await this.setState((prevState) => ({
      invoices: [...prevState.invoices, data],
      nextInvoiceId: prevState.nextInvoiceId + 1,
    }));

    this.invoiceFormRef.current.resetFields();

    const updatedInvoices = [...this.state.invoices];
    // console.log(updatedInvoices)
    // Concatenate values from the updated invoices array
    const concatenatedValues = updatedInvoices.reduce(
      (acc, invoice) => {
        const depositorName =
          this.jobManagementStore.depositorsList.find(
            (depositor) => depositor.userId === invoice.depositor
          )?.name || invoice.depositor;
        acc.invoiceNo += `${invoice.invoiceNo}, `;
        acc.invoiceAmount += `${invoice.invoiceAmount}, `;
        acc.materialDesc += `${invoice.invoiceNo} : ${
          invoice.description || ""
        }, `;
        acc.depositor += `${depositorName}, `;
        acc.invoiceDate += `${invoice.invoiceDate}, `;
        return acc;
      },
      {
        invoiceNo: "",
        invoiceAmount: "",
        materialDesc: "",
        depositor: "",
        invoiceDate: "",
      }
    );

    // Remove the trailing comma and space
    for (const key in concatenatedValues) {
      concatenatedValues[key] = concatenatedValues[key].replace(/, $/, "");
    }

    this.formRef.current.setFieldsValue(concatenatedValues);
    // this.formRef.current.setFieldsValue({
    //   invoiceNo: values.invoiceNo,
    //   invoiceAmount: values.invoiceAmount,
    //   materialDesc: values.description,
    //   depositor: values.depositor,
    //   invoiceDate: values.invoiceDate,
    // });
  };
  onJobManagementSubmit = async (values) => {
    this.setState({ loading: true });

    const DeliveryDetails = {
      ...values,
      companyId: this.state.companyId,
      deliveryDate: values?.deliveryDate
        ? values?.deliveryDate.format("YYYY-MM-DD")
        : null,
      // invoiceDate: values?.invoiceDate
      //   ? values?.invoiceDate.format("YYYY-MM-DD")
      //   : null,
      haltTime: values.haltTime ? values.haltTime.format("HH:mm") : "00:00",
      Locationlat: this.state.Locationlat,

      Locationlong: this.state.Locationlong,

      autoFinish: values.autoFinish ? 1 : 0,
      Id: this.state.Id,
      invoice: this.state.invoice,
    };

    const data = {
      tokenId: "80bovn8u800tg1aq9bvr73kbhh",
      DeliveryDetails: DeliveryDetails,
    };
    const response = await this.jobManagementStore.addData(data);

    await this.props.response(response);

    this.setState({ loading: false });
  };

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    if (!this.props.Id) {
      this.context.viewStateStore.setJobManagementStates(comp.value);
    }
    await this.setState({ companyId: comp.value });

    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });

    await this.setState({
      companyLable: { label: comp.label, value: comp.value },
    });

    await this.jobManagementStore.getVehicles(comp.value);
    await this.driverStore.toGetDriverName(comp.value);
    await this.jobManagementStore.getSalesPersonEx({
      companyId: comp.value,
    });
    await this.jobManagementStore.getSalesPersonCoordinator({
      companyId: comp.value,
    });

    const data = await this.jobManagementStore.getGeofenceData({
      companyId: comp.value,
    });

    await this.driverStore.getVehicles(comp.value);

    await this.jobManagementStore.getRoutesData(comp.value);

    await this.jobManagementStore.getDepositorsData(comp.value);

    await this.setState({ loadList: 1 });
    await this.handleSearch();
  }

  async handleSearch() {
    await this.jobManagementStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  timeOptions = () => {
    const timeArray = [];

    let startTime = new Date(2000, 0, 1, 0, 0); // 12:00 AM

    let endTime = new Date(2000, 0, 1, 24, 0);
    for (let time = startTime; time < endTime; time = addMinutes(time, 15)) {
      // const formattedTime = format(time, 'h:mm aa');
      const formattedTime = format(time, "HH:mm");
      var ob = {
        formattedTime: formattedTime,
        time: time,
      };
      timeArray.push(ob);
    }

    return timeArray.map((data) => {
      return (
        <Select.Option key={data.formattedTime} value={data.formattedTime}>
          {format(data.time, "h:mm aa")}
        </Select.Option>
      );
    });
  };

  SetLatLong = (values) => {
    this.setState({ Locationlat: values.lat(), Locationlong: values.lng() });
  };

  validateNumber = (value, field1) => {
    // Check if value contains only numbers
    const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");

    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field1]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field1]: "" });
    }
  };

  setFieldsValues = async (driverId) => {
    const getDriverData = this.driverStore?.getDriverName.filter(
      (val) => val.driverId === driverId
    );

    await this.formRef.current.setFieldsValue({
      mobileno: getDriverData[0].mobileno,
    });
  };

  setMobileNo = async (value) => {
    const getsalesPersonMobileNo =
      this.jobManagementStore?.getSalesPersonExArr.filter(
        (val) => val.salespersonId === value
      );

    await this.formRef.current.setFieldsValue({
      mobileno: getsalesPersonMobileNo[0].mobileno,
    });
  };

  selectDriver = async (value) => {
    await this.setState({ driverId: value });
    this.formRef.current.setFieldsValue({ driverId: value });
  };

  validateNumberAndDecimal = (value, field) => {
    const value1 = value.replace(/[^0-9.,]+/g, "");

    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  getUploadData = async (fileUp) => {
    let fileinfo = fileUp.file.response;
    if (fileinfo?.success == "S") {
      await this.setState({ invoice: fileinfo.data });
    }
    fileUp.fileList[0].url = config.fleetURL + fileinfo?.folderName;
    this.jobManagementStore.setPhotoFromDb(fileUp.fileList);
    this.setState({ update: 1 });
  };

  closeModal = () => {
    this.props.closeModal();
  };

  closeDriverModal = () => {
    this.setState({ openDriverDetailsForm: false });
  };

  responseMsg = async (response) => {
    const DriverName = await this.driverStore.toGetDriverName(
      this.state.companyId
    );

    if (response.success == "S") {
      message.success(response.message);
      await this.driverStore.toGetDriverName(this.state.companyId);
      await this.formRef.current.setFieldsValue({
        driverId: Number(response?.data.driverId),
        // driverId:response.data.name
      });

      await this.setState({ openDriverDetailsForm: false });
      // await this.formRef.current?.resetFields();
    } else if (response.success === "F0") {
      response.errors.map((value) => {
        message.error(this.context.translate(value["username"]));
      });
    }

    this.setState({ loadData: 1 });
    this.setState({ loading: false });

    const companyData = await this.companyStore.getCompanyDataById(
      this.state.companyId
    );

    await this.setState({ loadData: 1 });
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.formLoading} fullscreen>
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              layout="vertical"
              onFinish={this.onJobManagementSubmit}
              // {...this.layout}
              name="nest-message"
              ref={this.formRef}
              className="formClass"
              initialValues={{ deliveryDate: dayjs(currentDate) }}
            >
              <Row className="overflowFormInputs">
                <Col span={11} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => this.handleChange(value)}
                        style={{ width: "100%" }}
                        companyId={this.state.companyId}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="JobTypeCombo"
                    label={this.context.translate("_ASSIGN_JOB_")}
                    rules={[{ required: true }]}
                    initialValue={"Driver"}
                  >
                    <Select
                      options={[
                        {
                          label: this.context.translate(
                            "_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"
                          ),
                          value: "Driver",
                        },
                      ]}
                    />
                  </Form.Item>

                  <Form.Item
                    name="pickupDelivery"
                    label={this.context.translate("_JOB_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={[
                        {
                          // label: "Delivery",
                          label: this.context.translate("_DELIVERY_"),
                          value: "Delivery",
                        },
                        {
                          // label: "Pickup",
                          label: this.context.translate("_PICKUP_"),
                          value: "Pickup",
                        },
                        {
                          // label: "Service",
                          label: this.context.translate("_SERVICE_"),
                          value: "Service",
                        },
                        {
                          // label: "Meeting",
                          label: this.context.translate("_MEETING_"),
                          value: "Meeting",
                        },
                      ]}
                    />
                  </Form.Item>

                  <Form.Item
                    name="routeId"
                    label={this.context.translate("_ROUTE_")}
                  >
                    <Select
                      showSearch
                      // fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                      optionFilterProp="children"
                      // options={this.jobManagementStore?.assetVehicleList}
                      filterOption={(inputValue, option) => {
                        if (option.children) {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) >= 0
                          );
                        }
                      }}
                    >
                      {this.jobManagementStore?.routeData?.map((val) => {
                        return (
                          <Option value={val.routeId}>{val.routeName}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      name="driverId"
                      label={this.context.translate("_DRIVER_NAME_")}
                      style={{ width: "80%" }}
                    >
                      <Select
                        showSearch
                        placeholder={this.context.translate("_SELECT_DRIVER_")}
                        onChange={(e) => this.setFieldsValues(e)}
                        filterOption={(inputValue, option) => {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) >= 0
                          );
                        }}
                        value={this.state.driverId}
                        onSelect={this.selectDriver}
                      >
                        {this.driverStore?.getDriverName?.map((driver) => {
                          return (
                            <Option value={parseInt(driver.driverId)}>
                              {driver.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    <Form.Item name=" " label={" "}>
                      <Tooltip
                        // title="Add New Driver"
                        title={this.context.translate(
                          "_FLEET_VEHICLE_VEHICLEDETAIL_ADDDRIVER_"
                        )}
                        placement="bottom"
                        color={config.tooltipColorEdit}
                      >
                        <Button
                          style={{ height: "48px" }}
                          type="primary"
                          onClick={(e) => {
                            this.setState({ openDriverDetailsForm: true });
                          }}
                        >
                          <PlusOutlined />
                        </Button>
                      </Tooltip>
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="vehicleId"
                    label={this.context.translate("_ASSET_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      // fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                      optionFilterProp="children"
                      // options={this.jobManagementStore?.assetVehicleList}
                      filterOption={(inputValue, option) => {
                        if (option.children) {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) >= 0
                          );
                        }
                      }}
                    >
                      {this.jobManagementStore?.assetVehicleList?.map((val) => {
                        return (
                          <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      style={{ width: "30%" }}
                      name="mobilenoCountryCode"
                      // label="Contact 1"
                      label={" "}
                      // rules={[{ required: true }]}
                      // rules={[{ required: true }]}
                      initialValue={+966}
                    >
                      <Select>
                        <Select.Option value={91}>+91</Select.Option>
                        <Select.Option value={966}>+966</Select.Option>

                        <Select.Option value={971}>+971</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      style={{ width: "65%" }}
                      name="mobileno"
                      className="disabledLabel"
                      label={this.context.translate(
                        "_FLEET_DRIVER_DRIVERDETAIL_MOBILE_"
                      )}
                    >
                      <Input
                        disabled
                        onChange={(e) => {
                          this.validateNumber(e.target.value, "mobileno");
                        }}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="customerName"
                    label={this.context.translate("_CUSTOMER_NAME_")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="customerCode"
                    label={this.context.translate("_CUSTOMER_CODE_")}
                  >
                    <Input />
                  </Form.Item>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      style={{ width: "30%" }}
                      name="countryCode"
                      // label="Contact 1"
                      label={" "}
                      initialValue={+966}
                    >
                      <Select>
                        <Select.Option value={91}>+91</Select.Option>
                        <Select.Option value={966}>+966</Select.Option>

                        <Select.Option value={971}>+971</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      style={{ width: "65%" }}
                      name="customerMobile"
                      // label="Phone Number 1"
                      label={this.context.translate("_CUST_MOBILENO_")}
                      rules={[
                        {
                          // required: true,
                          pattern: /^(\d{7}|\d{10})$/,
                          // message: "Enter a 7 or 10 digit number",
                          message: this.context.translate(
                            "_Enter_7_or_10_Digit_Number_"
                          ),
                        },
                      ]}
                      // rules={[{ required: true}]}
                    >
                      <Input
                        maxlength={10}
                        onChange={(e) => {
                          this.validateNumber(e.target.value, "customerMobile");
                        }}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="notificationemail"
                    label={this.context.translate("_NOTIFICATION_MAIL_")}
                    rules={[{ type: "email" }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="SearchByOption"
                    label={this.context.translate("_SEARCHBY_")}
                  >
                    <Select onSelect={this.searchBy}>
                      <Select.Option value="1">
                        {this.context.translate("_SEARCH_MAP_")}
                      </Select.Option>
                      <Select.Option value="2">
                        {this.context.translate("_GEOFENCE_")}
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  {this.state.customerAddress && (
                    <>
                      <Form.Item
                        name="location"
                        label={this.context.translate("_CUSTOMER_ADDRESS_")}
                      >
                        <Autocomplete
                          style={{
                            width: "100%",
                            height: "48px",
                            boxShadow: "none",
                            padding: "4px 11px",
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: "#d9d9d9",
                            borderRadius: "5px",
                          }}
                          placeholder={this.context.translate(
                            "_ENTER_LOCATION_"
                          )}
                          apiKey={config.googleMapKey}
                          onPlaceSelected={async (place) => {
                            this.SetLatLong(place.geometry.location);
                            this.formRef.current.setFieldsValue({
                              location: place.formatted_address,
                            });
                            await this.setState({
                              addressInfo: [
                                place.geometry.location.lat(),
                                place.geometry.location.lng(),
                              ],
                            });
                            await this.setState({ addressMap: true });
                          }}
                        />
                      </Form.Item>
                    </>
                  )}

                  {this.state.selectGeofence && (
                    <>
                      <Form.Item
                        name="zoneId"
                        label={this.context.translate(
                          "_FLEET_RULESELECTORDETAIL_RULE6_GEOFENCE_"
                        )}
                      >
                        <Select
                          placeholder={this.context.translate(
                            "_FLEET_RULESELECTORDETAIL_RULE6_GEOFENCE_"
                          )}
                          // placeholder="Select Geofence"
                          showSearch
                          fieldNames={{ label: "name", value: "zoneId" }}
                          optionFilterProp="children"
                          options={this.jobManagementStore?.geofenceList}
                        />
                      </Form.Item>
                    </>
                  )}
                </Col>
                <Col span={1} />
                <Col span={11}>
                  <Form.Item
                    name="noteToDriver"
                    label={this.context.translate("_NOTE_TO_DRIVER_")}
                  >
                    <Input />
                  </Form.Item>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      name="invoiceNo"
                      label={this.context.translate("_INVOICE_NO_")}
                      style={{ width: "80%" }}
                    >
                      <Input readOnly={true} />
                    </Form.Item>

                    <Form.Item name=" " label={" "}>
                      {/* <Tooltip
                        // title="Add New Driver"
                        title={this.context.translate(
                          "_FLEET_VEHICLE_VEHICLEDETAIL_ADDDRIVER_"
                        )}
                        placement="bottom"
                        color={config.tooltipColorEdit}
                      > */}
                      <Button
                        style={{ height: "48px" }}
                        type="primary"
                        onClick={(e) => {
                          this.setState({ openInvoiceNoForm: true });
                        }}
                      >
                        <PlusOutlined />
                      </Button>
                      {/* </Tooltip> */}
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="invoiceAmount"
                    label={this.context.translate("_INVOICE_AMOUNT_")}
                  >
                    <Input readOnly={true} />
                  </Form.Item>

                  <Form.Item
                    name="invoiceDate"
                    label={this.context.translate("_INVOICE_DATE_")}
                  >
                    <Input readOnly={true} />
                  </Form.Item>

                  <Form.Item
                    name="materialDesc"
                    label={this.context.translate("_DESCRIPTION_")}
                  >
                    <Input readOnly={true} />
                  </Form.Item>

                  <Form.Item
                    name="depositor"
                    label={this.context.translate("_DEPOSITOR_")}
                  >
                    <Input readOnly={true} />
                  </Form.Item>

                  <Row>
                    <Col span={11}>
                      <Form.Item
                        name="noOfCartons"
                        label={this.context.translate("_QUANTITY_")}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={2} />
                    <Col span={11}>
                      <Form.Item
                        name="deliveryDate"
                        label={this.context.translate("_DELIVERY_DATE_")}
                      >
                        <DatePicker format={"YYYY-MM-DD"} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="contactName"
                    label={this.context.translate("_CONTACT_PERSON_NAME_")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="landLineNo"
                    label={this.context.translate("_LANDLINE_NUMBER_")}
                    rules={[
                      {
                        pattern: /^(\d{9})$/,
                        // message: "Only 9 digits allowed",
                        message: this.context.translate(
                          "_ONLY_9_DIGITS_ALLOWED_"
                        ),
                      },
                    ]}
                  >
                    <Input
                      maxLength={9}
                      onChange={(e) => {
                        this.validateNumber(e.target.value, "landLineNo");
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="deliveryTime"
                    label={this.context.translate("_DELIVERY_TIME_")}
                    rules={[{ required: true }]}
                  >
                    <Select>{this.timeOptions()}</Select>
                  </Form.Item>

                  <Form.Item
                    name="haltTime"
                    label={this.context.translate("_HALT_TIME_")}
                    rules={[{ required: true }]}
                    initialValue={dayjs("00:00", "HH:mm")}
                  >
                    <TimePicker format="HH:mm" style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    name="autoFinish"
                    label=" "
                    valuePropName="checked"
                  >
                    <Checkbox>
                      {this.context.translate("_AUTO_FINISH_")}
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    // name="logo"
                    label=" "
                    className="formCheckboxCss"
                    //  valuePropName="fileList"

                    getValueFromEvent={this.normFile}
                  >
                    <Upload
                      name="invoice"
                      action={
                        config.baseURL + "/index.php?c=company&a=fileupload"
                      }
                      listType="picture"
                      data={{ fileType: "invoice", fieldName: "invoice" }}
                      onChange={this.getUploadData}
                      // onRemove={()=>this.setState({photo:"",fileList:[]})}
                      // onRemove={async() =>{await this.setState({ photo: "", fileList: [] });await this.driverStore.setPhotoFromDb([])}}
                      maxCount={1}
                      onRemove={async () => {
                        await this.setState({ invoice: "", fileList: [] });
                        await this.jobManagementStore.setPhotoFromDb([]);
                      }}
                      // accept=".jpg,.png"
                      // Add the beforeUpload prop to perform file extension validation
                      // beforeUpload={(file) => this.validateFile(file)}
                      {...this.jobManagementStore.photoFromDb}
                    >
                      <Button className="imgupload" icon={<UploadOutlined />}>
                        {this.context.translate("_UPLOAD_INVOICE_")}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>

              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal();
                    // this.formRef.current?.resetFields();
                  }}
                >
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="formSaveButton"
                  loading={this.state.loading}
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>

          <Modal
            bodyStyle={{ height: "100%" }}
            className="formShowModal" //
            // title="Driver Details"
            title={this.context.translate("_FLEET_DRIVER_DRIVERDETAIL_TITLE_")}
            centered
            open={this.state.openDriverDetailsForm}
            footer={null}
            onCancel={() => {
              this.setState({ openDriverDetailsForm: false });
            }}
            width={665}
          >
            <DriverAdd
              response={this.responseMsg}
              closeModal={this.closeDriverModal}
            ></DriverAdd>
          </Modal>

          <Modal
            className="multiTracking-popup"
            style={{ padding: "0px !important" }}
            title=<div>
              Location On Map
              <CloseOutlined
                onClick={() => this.setState({ addressMap: false })}
              />
            </div>
            maskClosable={false}
            centered
            bodyStyle={{ height: "60vh", padding: "0px" }} // Adjust the dimensions as needed
            width={1000}
            visible={this.state.addressMap}
            onCancel={() => this.setState({ addressMap: false })}
            footer={null}
          >
            <DisplayLocationMap
              addressInfo={
                this.state.addressInfo?.length > 0 && this.state.addressInfo
              }
              address={(address) =>
                this.formRef.current.setFieldsValue({ location: address })
              }
              formRef={this.formRef}
            />
          </Modal>

          <Modal
            bodyStyle={{ height: "100%" }}
            // title="Driver Details"
            title={this.context.translate("_ADD_INVOICE_")}
            centered
            open={this.state.openInvoiceNoForm}
            footer={null}
            onCancel={() => {
              this.setState({ openInvoiceNoForm: false });
            }}
            width={655}
          >
            <Form
              name="invoiceAdd"
              layout="vertical"
              onFinish={this.onInvoiceSubmit}
              ref={this.invoiceFormRef}
              style={{ padding: "0px 10px" }}
            >
              <Form.Item
                label={this.context.translate("_INVOICE_NO_")}
                name="invoiceNo"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={this.context.translate("_INVOICE_AMOUNT_")}
                name="invoiceAmount"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={this.context.translate("_DESCRIPTION_")}
                name="description"
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="depositor"
                label={this.context.translate("_DEPOSITOR_")}
              >
                <Select
                  fieldNames={{ label: "name", value: "userId" }}
                  options={this.jobManagementStore.depositorsList}
                />
              </Form.Item>

              <Form.Item
                name="invoiceDate"
                label={this.context.translate("_INVOICE_DATE_")}
                rules={[{ required: true }]}
              >
                <DatePicker format={"YYYY-MM-DD"} style={{ width: "100%" }} />
              </Form.Item>

              <div className="formBtnGroup">
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  // loading={this.state.loading}
                  onClick={() => {
                    this.setState({ showTable: true });
                  }}
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
            <br />
            {(this.state.invoices?.length > 0 && this.state.showTable) ||
              (this.props.Id && (
                <Table
                  bordered
                  columns={this.columns}
                  dataSource={this.state.invoices}
                  pagination={false}
                  rowKey={"invoiceId"}
                />
              ))}
          </Modal>
        </div>
      </div>
    );
  }
}

JobManagementAdd.contextType = StoreContext;
export default observer(withRouter(JobManagementAdd));
