import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class TaxiTypeStore {
  loading = false;
  total = 0;
  taxiTypeList = [];

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      total: observable,
      taxiTypeList: observable,

      loadData: action,
      deleteRecord: action,
      addData: action,
      getTaxiData:action
    });
    this.rootStore = rootStore;
  }

  async loadData({ offset, limit }) {
    this.loading = true;
    var postData = {
      offset: offset,
      limit: limit,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=traxi_vehicle&a=getTaxiTypeNew",
        "postData=" + base64Input
      );

    this.taxiTypeList = response?.data?.data;
    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
  }

  async addData(taxiDetails) {
    var postData = taxiDetails;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=addTaxiType", "postData=" + base64Input);

    return response.data;
  }

  async getTaxiData(vehicleTypeId) {
   
    var postData = {
      vehicleTypeId: vehicleTypeId,
      limit: 1,
      offset: 0,
    };
    // console.log(postData)

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=traxi_vehicle&a=getTaxiTypeNew",
        "postData=" + base64Input
      );

    return response?.data?.data[0];
  }

  async deleteRecord({ id }) {
    const postData = {
      id: id,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=taxiTypeDelete", "postData=" + base64Input);

    return response;
  }
}

export default TaxiTypeStore;
