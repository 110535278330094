import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  makeObservable,
  observable,

} from 'mobx';
import { act } from 'react-dom/test-utils';

class GPRSCommandStore {
  grpsCommand = []
  getVehiclesUsingCompanyId=[]
  generateSMSCommand=[]
  total = 0
  loading = false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
      grpsCommand: observable,
      getVehiclesUsingCompanyId:observable,
      generateSMSCommand:observable,
      total: observable,
      loading: observable,
      loadData: action,
      addData:action,
      getVehicles:action,
      getGPRSCommand:action

    });
    this.rootStore = rootStore;

  }

  async loadData({ offset, limit, }) {
    // this.loading = true;
    console.log("GRPS command")

    // var postData = {"companyId":null,"offset":0,"limit":50}
    // const base64Input = await base64_encode(JSON.stringify(postData));
    // var response = await postApi.getClient().post("index.php?c=driverbsetting&a=getDriverBSettings", "postData="+base64Input);

    // this.changePassword =response
    // this.total = parseInt(response?.data?.data?.TotalCount);
    // this.loading=false;
  }

  async addData(fieldsValue) {
    var postData = fieldsValue
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=sendGPRSCommand", "postData=" + base64Input);
    console.log(response)
    return response.data
  }

  async getVehicles( {companyId} ) {
    console.log("companyId",companyId);
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehiclesGPRSCommand", "postData=" + base64Input);
    console.log(response, "post");
    this.getVehiclesUsingCompanyId = response?.data?.data;
    return response?.data?.data;
  }

  async getGPRSCommand({smsInput,deviceName,commandType}) {
    console.log("companyId",smsInput,deviceName,commandType);
    var postData ={"smsInput":smsInput,"deviceName":deviceName,"commandType":commandType}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=generateSMSCommand", "postData=" + base64Input);
    console.log(response, "post");
    this.generateSMSCommand = response?.data?.command;
    console.log(response?.data?.command)
    return response?.data?.command;

  }


}

export default GPRSCommandStore;
