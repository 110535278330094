import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class MenuSettingStore {
  menuSetting = [];
  modules = [];
  checkedKeys = [];
  menuSettingEditedArray = [];
  selectedKeysInEditedArray = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      menuSetting: observable,
      total: observable,
      loading: observable,
      modules: observable,
      selectedKeysInEditedArray: observable,
      checkedKeys: observable,
      loadData: action,
      getMenuSettingData: action,
      addData: action,
      getModules: action,
      setModules: action,
      deleteRecord: action,
      getUserModules: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ offset, limit, companyId, roleId }) {
    console.log(roleId);
    this.loading = true;
    console.log("menuSetting");

    var postData = {
      companyId: companyId,
      page: "rolelist",
      isadmin: "-1",
      userId: 1,
      roleId,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=user&a=getroles", "postData=" + base64Input);
    console.log("menusetting ", response?.data?.data);
    // console.log("company Data",response?.data?.data?.companydata)
    this.menuSetting = response?.data?.data;

    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }
  async getUserModules(roleId) {
    var postData = { roleId: roleId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=user&a=usermodules2", "postData=" + base64Input);
    console.log("menusetting ", response?.data.data);

    function addKeyToObjects(data) {
      return data.map((item) => {
        const { moduleId, children, checked, ...rest } = item;
        const updatedItem = { ...rest, moduleId };
        // updatedItem.title=updatedItem.text
        updatedItem.key = moduleId;
        if (checked) {
          updatedItem.selectedKey = moduleId;
        }

        if (children && children.length > 0) {
          updatedItem.children = addKeyToObjects(children);
        }

        return updatedItem;
      });
    }
    console.log(response?.data.data);
    const newObj = await addKeyToObjects(response?.data?.data.modules);
    newObj.checkedKeys = response?.data?.data.checkedKeys;
    this.menuSettingEditedArray = newObj;

    return newObj;
  }
  async addData(menuSettingData) {
    var postData = menuSettingData;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=manageroles&a=role", "postData=" + base64Input);
    console.log(response.data.message);
    return response.data;
  }

  async deleteRecord({ roleId }) {
    const postData = { tokenId: "80bovn8u800tg1aq9bvr73kbhh", roleId: roleId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=manageroles&a=delete", "postData=" + base64Input);
    console.log(response);
    return response;
  }

  async getMenuSettingData(roleId) {
    var postData = { rid: roleId, offset: 0, limit: 1 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=user&a=getroles", "postData=" + base64Input);
    console.log(response.data.data[0]);
    return response.data.data[0];
  }
  async getModules(roleId) {
    const postData = { userId: null, roleId: roleId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=user&a=usermodules2", "postData=" + base64Input);
    let checkedKeys = response?.data?.data.checkedKeys.map((i) => Number(i));
    return { ...response?.data?.data, checkedKeys };
  }
  async setModules(checkedKeys, modules) {
    this.modules = modules;
    this.checkedKeys = checkedKeys;
    console.log(checkedKeys, modules, "re");
  }
}

export default MenuSettingStore;
