import { CloseOutlined } from "@ant-design/icons";
import { Card, Image, Input, Row, Table } from "antd";
import React, { Component } from "react";
import Draggable from "react-draggable";
import withRouter from "../withRouter";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";

const headerCss = {
  borderRadius: "50%",
  border: "1px solid",
  padding: "5px",
  background: "rgb(154, 153, 153)",
  width: "30px",
  height: "30px",
  display: "inline-block",
  textAlign: "center",
};

class MarkerClusterPopup extends Component {
  constructor(props) {
    super(props);
    this.state = { searchInput: "" };
    this.infoObj = this.props.infoObj.vehicleFeature;
    console.log();
  }
  componentDidMount() {
    console.log(this.props);
  }
  render() {
    const { searchInput } = this.state;
    const filteredDataSource = this.props.infoObj?.vehicleObj?.filter(
      (record) => {
        // Check if values are truthy before calling toLowerCase()
        const vehicleNo = record.vehicleNo
          ? record.vehicleNo.toLowerCase()
          : "";
        const name = record.name ? record.name.toLowerCase() : "";
        const formattedAddress = record.formatted_address
          ? record.formatted_address.toLowerCase()
          : "";
        return (
          vehicleNo.includes(searchInput.toLowerCase()) ||
          name.includes(searchInput.toLowerCase()) ||
          formattedAddress.includes(searchInput.toLowerCase())
        );
      }
    );
    return (
      <div>
        <Draggable handle=".handle">
          <Card
            // className="right-menu-panel"

            title={
              <div
                style={{
                  color: "white",
                  fontSize: "18px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Vehicles
                <CloseOutlined
                  onClick={() => {
                    this.props.infoObj.This.setState({
                      markerClusterGroupPopup: false,
                    });
                  }}
                />
              </div>
            }
            style={{
              background: " rgba(24, 23, 34, 0.50)",
              border: "solid 1px #CBCBCB",
              padding: "15px",
              borderRadius: "5px",
              position: "absolute",
              insetInlineStart: "26%",
              bottom: "5%",
              width: "30%",
            }}
          >
            <div style={{ padding: "10px" }}>
              <b style={{ color: "white" }}>
                All{" "}
                <small style={{ ...headerCss, background: "#FFFFFF" }}>
                  <small style={{ color: "black" }}>
                    {Number(this.infoObj.TotalVehCount)}
                  </small>
                </small>
              </b>
              <b style={{ marginLeft: "5px", color: "white" }}>
                Moving{" "}
                <small style={{ ...headerCss, background: "#75bd84" }}>
                  {Number(this.infoObj.Moving)}
                </small>
              </b>
              <b style={{ marginLeft: "5px", color: "white" }}>
                {" "}
                Idling
                <small style={{ ...headerCss, background: "#f7b215" }}>
                  {Number(this.infoObj.Idle)}
                </small>
              </b>
              <b style={{ marginLeft: "5px", color: "white" }}>
                No Data{" "}
                <small style={{ ...headerCss, background: "#9a9999" }}>
                  {Number(this.infoObj.NoData)}
                </small>
              </b>
              <b style={{ marginLeft: "5px", color: "white" }}>
                Stopped{" "}
                <small style={{ ...headerCss, background: "#e85756" }}>
                  {Number(this.infoObj.Stopped)}
                </small>
              </b>
            </div>
            <Input
              value={this.state.searchInput}
              style={{ marginBottom: "10px", width: "100%", height: "37px" }}
              onChange={(e) => this.setState({ searchInput: e.target.value })}
              placeholder="Search By Vehicle No"
            />
            <Table
              bordered
              scroll={{ x:200, y: 180 }}
              rowKey="vehicleId"
              pagination={false}
              columns={[
                {
                  title: "",
                  dataIndex: "listImage",
                  key: "image",
                  width: 50,
                  render: (record) => {
                    return (
                      <Image
                        style={{ marginTop: "10px" }}
                        width={35}
                        src={require("../assets/images/detailtracking/" +
                          record)}
                      ></Image>
                    );
                  },
                },
                {
                  title: "Information",
                  dataIndex: "formatted_address",
                  key: "name",
                  render: (_, record) => {
                    return (
                      <span
                        style={{ display: "flex", verticalAlign: "middle" }}
                      >
                        <div style={{ marginLeft: "15px" }}>
                          <span>
                            <b style={{ margin: "0px" }}>
                              {record.vehicleNo} / {record.name}
                            </b>
                          </span>

                          <p style={{ margin: "0px" }}>
                            {record.formatted_address}
                          </p>
                        </div>
                      </span>
                    );
                  },
                },
              ]}
              dataSource={filteredDataSource}
            />
          </Card>
        </Draggable>
      </div>
    );
  }
}
MarkerClusterPopup.contextType = StoreContext;
export default observer(withRouter(MarkerClusterPopup));
