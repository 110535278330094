import { observer } from "mobx-react";
import React, { Component } from "react";
import withRouter from "../withRouter";
import { Button, Col, DatePicker, Empty, Row, Select, Space, Spin } from "antd";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { StoreContext } from "../store/RootStore";
import DistanceGraphStore from "../store/DistanceGraphStore";
import dayjs from "dayjs";
// import { Bar, Column, tooltip } from "@ant-design/plots";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line,
  LineChart,
} from "recharts";

import SpeedGraphStore from "../store/SpeedGraphStore";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
import VehicleStore from "../store/VehicleStore";

const { Option } = Select;
class SpeedGraph extends Component {
  constructor(props, context) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.distanceGraphStore = new DistanceGraphStore(this);
    this.vehicleStore = new VehicleStore(this);
    this.speedGraphStore = new SpeedGraphStore(this);

    this.isArabic = context.isArabic;

    this.state = {
      selectAllVehicles: false,
      selectedVehicles: "",
      update: 0,
      companyId: "",
      distance: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      loadData: 0,

      displayVehicleNo: [],

      newFromDate: dayjs().startOf("day").format("YYYY-MM-DD hh:mmA"),

      newToDate: dayjs().format("YYYY-MM-DD hh:mm A"),
      showFuelGraph: false,
      Notcompany: false,
      Notasset: false,
      loadingSpin: false,
    };
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });
    await this.setState({ selectedVehicles: "" });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);

    this.setState({ update: 1 });
  }

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (!this.state.selectedVehicles) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles) {
      // this.setState({mandatory:false})
      await this.setState({ loadingSpin: true });

      this.setState({ Notcompany: false, Notasset: false });
      const data = await this.speedGraphStore.loadSpeedGraphData({
        // offset: (this.state.pageNo - 1) * this.state.pageSize,
        // limit: this.state.pageSize,
        companyId: this.state.companyId,
        vehicleId: this.state.selectedVehicles,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,

        // pageNo: this.state.pageNo,
      });
      console.log(data);
      this.setState({ loadData: 1 });
      await this.setState({ loadingSpin: false });
    }
  };

  handleDateChange = (date) => {
    if (date === null) {
      this.setState({ fromDate: "" });
    } else {
      // const formattedDate = date.startOf("day");
      const format = "YYYY-MM-DD HH:mm:ss ";
      this.setState({ fromDate: date.format(format) });
      console.log(date.format(format));
      const newFromDate = date;
      const newDateFormat = "YYYY-MM-DD hh:mmA";
      this.setState({ newFromDate: newFromDate.format(newDateFormat) });
    }
  };

  handleToDateChange = (date) => {
    if (date === null) {
      this.setState({ toDate: "" });
    } else {
      const formattedDate = date;
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({ toDate: formattedDate.format(format) });
      console.log(formattedDate.format(format));
      const newToDate = date;
      const newDateFormat = "YYYY-MM-DD hh:mmA";
      this.setState({ newToDate: newToDate.format(newDateFormat) });
    }
  };

  CustomTooltip = ({ active, payload }) => {
    console.log(active, payload);
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      console.log(data);
      return (
        <div
          style={{ border: "1px solid", background: "white", padding: "10px" }}
        >
          {/* <div>{`Speed: ${data.speed}`}</div>
          <div>{`Driver Name: ${data.name}`}</div>
          <div>{`Driver Mobile No: ${data.driverphone}`}</div> */}
          <div>{`${this.context.translate("_FLEET_DASHBORD_SETTING_SPEED_")}: ${
            data.speed ? data.speed : "-"
          }`}</div>
          <div>{`${this.context.translate(
            "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
          )}: ${data.name ? data.name : "-"}`}</div>
          <div>{`${this.context.translate("_DRIVER_MOBILE_NO_")}: ${
            data.driverphone ? data.driverphone : "-"
          }`}</div>
        </div>
      );
    }
    return null;
  };

  customTickFunction = (props) => {
    const { x, y, payload, visibleTicksCount, tickFormatter } = props;
    // console.log(x,y,)

    // Customize the tick appearance or position here
    return (
      <text
        x={x - 20}
        y={this.context.locale.language === "english" ? y + 5 : y}
        textAnchor="end"
        fontSize={12}
        dy={-4}
        style={{ lineHeight: "1.2" }}
      >
        {tickFormatter ? tickFormatter(payload.value) : payload.value}
      </text>
    );
  };

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.vehicleStore.getVehiclesCompanyId(
        this.props.getUserData().userCompanyId
      );
      this.setState({ update: 1 });
    }
  }

  render() {
    return (
      <>
        <Spin spinning={this.state.loadingSpin} fullscreen>
          <div style={{ padding: 0 }}>
            <Row className="headerFixed" style={{ height: "50px" }}>
              <Col span={12}>
                {/* <div className="heading">Speed Graph</div> */}
                <div className="heading">
                  {this.context.translate("_SPEED_GRAPH_")}
                </div>
              </Col>
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>
          </div>
          <div
            style={{
              paddingTop: "10px",
              paddingRight: "24px",
              paddingLeft: "24px",
            }}
          >
            <Row className="listInputRow">
              <Col span={22}>
                <Space>
                  <CompanyAutocomplete
                    onChange={this.handleChange}
                    allowClear={true}
                    style={{ width: 175 }}
                    status={this.state.Notcompany ? "error" : false}
                  />
                  {this.props.getUserData().isadmin === 1 && (
                    <DeviceGroupsForVehicles
                      vehicleStore={this.vehicleStore}
                      setLoadDta={() => this.setState({ loadData: 1 })}
                      resetVehicles={() =>
                        this.setState({
                          selectedVehicles: "",
                        })
                      }
                    />
                  )}
                  <Select
                    value={
                      this.state.selectedVehicles
                        ? this.state.selectedVehicles
                        : null
                    }
                    // onChange={(val) => {
                    //   this.setState({ selectedVehicles: val });
                    //   this.setState({ Notasset: false });
                    // }}
                    onChange={(val, x) => {
                      this.setState({
                        selectedVehicles: val,
                        displayVehicleNo: x.children,
                      });
                    }}
                    style={{ width: 200 }}
                    showSearch
                    status={this.state.Notasset ? "error" : false}
                    placeholder={this.context.translate(
                      "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                    )}
                    filterOption={(inputValue, option) => {
                      if (option.children) {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {this.vehicleStore?.getVehiclesUsingCompanyId?.map(
                      (val) => {
                        return (
                          <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                        );
                      }
                    )}
                  </Select>
                  {/* 
                  <Select
                    // mode="multiple"
                    // maxTagCount="responsive"
                    value={this.state.selectedVehicles}
                    onChange={(val, x) => {
                      this.setState({
                        selectedVehicles: val,
                        displayVehicleNo: x.vehicleNo,
                      });

                      // if (
                      //   this.vehicleStore?.getVehiclesUsingCompanyId.length >
                      //   val.length
                      // ) {
                      //   this.setState({ selectAllVehicles: false });
                      // } else {
                      //   this.setState({ selectAllVehicles: true });
                      // }
                    }}
                    fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                    // placeholder="Asset Name"
                    placeholder={this.context.translate(
                      "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                    )}
                    optionFilterProp="children"
                    options={this.distanceGraphStore?.vehiclesArray}
                    style={{ width: 175 }}
                    status={this.state.Notasset ? "error" : false}
                  /> */}
                  <DatePicker
                    showTime
                    format="DD-MM-YYYY hh:mm A"
                    placeholder={this.context.translate("_FROM_DATE_")}
                    disabledDate={(current) => {
                      return current && current > dayjs();
                    }}
                    //   onChange={this.handleDateChange}
                    value={
                      this.state.fromDate ? dayjs(this.state.fromDate) : null
                    }
                    onChange={this.handleDateChange}
                  />
                  <DatePicker
                    showTime
                    format="DD-MM-YYYY hh:mm A"
                    placeholder={this.context.translate("_TO_DATE_")}
                    onChange={this.handleToDateChange}
                    value={this.state.toDate ? dayjs(this.state.toDate) : null}
                    // value={dayjs(this.state.toDate)}
                    disabledDate={(current) => {
                      return current && current > dayjs();
                    }}
                  />
                  <Button
                    type="primary"
                    onClick={async () => {
                      await this.showData();
                      await this.setState({ showSpeedGraph: true });
                      // console.log(this.distanceGraphStore?.distanceGraph);
                    }}
                  >
                    {this.context.translate("_FLEET_SHOW_BTN_")}
                  </Button>
                </Space>
              </Col>
              <Col span={2}>
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>

            <div
              style={{
                paddingTop: "10px",
                paddingRight: "24px",
                paddingLeft: "24px",
              }}
            >
              {this.state.showSpeedGraph &&
                this.speedGraphStore?.speedGraph?.length > 0 && (
                  <Row className="listInputRow">
                    <Col span={18}>
                      {/* <div className="heading">Speed Vs Time Graph</div> */}
                      <div className="heading">
                        {this.context.translate("_SPEED_VS_TIME_")}
                      </div>

                      {/* <b>Vehicle No : {this.state.displayVehicleNo}</b> */}
                      <b>
                        {this.context.translate(
                          "_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"
                        )}
                        :  </b>
                        {this.state.displayVehicleNo}
                     
                      <br />
                      {/* <b>From DateTime : {this.state.newFromDate}</b> */}
                      <b>
                        {this.context.translate("_FROM_DATETIME_")} :{" "}
                        {this.state.newFromDate}
                      </b>
                      <br />
                      {/* <b>To DateTime : {this.state.newToDate}</b> */}
                      <b>
                        {this.context.translate("_TO_DATETIME_")} :{" "}
                        {this.state.newToDate}
                      </b>
                      <br />
                    </Col>
                    <Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    ></Col>
                  </Row>
                )}
            </div>
            {/* {this.state.showSpeedGraph && (
              <>
            
              <ResponsiveContainer height={400}>
                <LineChart
                  width={500}
                  //   height={300}
                  data={this.speedGraphStore?.speedGraph}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 50,
                  }}
                >
                  

                  <XAxis
                    maxHeight={100}
                    angle={
                      this.context.locale.language === "english" ? -90 : 90
                    }
                    interval={Math.ceil(
                      this.speedGraphStore?.speedGraph?.length / 35
                    )} // Adjust MAX_ALLOWED_LABELS as needed
                    textAnchor="end"
                    label={{
                      value: this.context.translate("_TIME_"),
                      position: "bottom",
                    }}
                    height={150}
                    dataKey="timestamp"
                  />

                  <YAxis
                    // angle={
                    //   this.context.locale.language === "english" ? -360 : 360
                    // }
                    label={{
                      value: this.context.translate("_SPEED_"),
                      // value:"Speed",
                      angle: -90,
                      position: "insideLeft",
                      // offset: -10
                      // offset: -10, // Adjust this value as needed
                    }}
                    // tick={this.customTickFunction}
                    style={{ direction: this.context.locale.language === "arabic" ? "ltr" : "ltr" }}
                  />
                  <Tooltip content={<this.CustomTooltip />} />

                  <Line
                    type="monotone"
                    dataKey="speed"
                    stroke="blue"
                    dot={{ fill: "orange" }}
                  />
                </LineChart>
              </ResponsiveContainer>
              </>
              
            )} */}

            <ResponsiveContainer height={400}>
              {this.speedGraphStore?.speedGraph &&
              this.speedGraphStore.speedGraph.length > 0 ? (
                <LineChart
                  width={500}
                  data={this.speedGraphStore.speedGraph}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 50,
                  }}
                >
                  <XAxis
                    maxHeight={100}
                    angle={
                      this.context.locale.language === "english" ? -90 : 90
                    }
                    interval={Math.ceil(
                      this.speedGraphStore.speedGraph.length / 35
                    )}
                    textAnchor="end"
                    label={{
                      value: this.context.translate("_TIME_"),
                      position: "bottom",
                      fontWeight: "bold",
                      fontSize: "17px",
                    }}
                    height={150}
                    dataKey="timestamp"
                  />
                  <YAxis
                    label={{
                      value: this.context.translate("_SPEED_"),
                      angle: -90,
                      position: "insideLeft",
                      fontWeight: "bold",
                      fontSize: "17px",
                    }}
                    style={{
                      direction:
                        this.context.locale.language === "arabic"
                          ? "ltr"
                          : "ltr",
                    }}
                  />
                  <Tooltip content={<this.CustomTooltip />} />
                  <Line
                    type="monotone"
                    dataKey="speed"
                    stroke="blue"
                    dot={{ fill: "orange" }}
                  />
                </LineChart>
              ) : (
                <Empty></Empty>
              )}
            </ResponsiveContainer>
          </div>
        </Spin>
      </>
    );
  }
}
SpeedGraph.contextType = StoreContext;
export default observer(withRouter(SpeedGraph));
