import { encode as base64_encode } from "base-64";
import config from "../config";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import moment from "moment";
import utf8 from "utf8";

class DetailTrackingStore {
  total = 0;
  loading = false;
  trackData = [];
  totalVehicalState = [];

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      trackData: observable,
      loading: observable,
      loadData: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    companyId,
    offset,
    limit,
    status,
    driver,
    vehicleNo,
    searchField,
    isadmin,
    userCompany,
  }) {
    this.loading = true;
    var postData = {
      tokenId: "",
      searchField: searchField,
      companyId: companyId,
      isadmin: isadmin,
      userCompany: userCompany,
      offset: offset,
      limit: limit,
      status: status,
    };
    const base64Input = await utf8.encode(JSON.stringify(postData));
    const base2 = JSON.stringify(base64_encode(base64Input));

    // const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicletracking&a=detailtrackdata",
        "postData=" + base2
      );
    var liveTrackingData = response?.data?.data?.detailtrack;

    // console.log(response?.data?.data?.detailtrack, "vehicle");
    Object.keys(liveTrackingData).map((e, i) => {
      let r = liveTrackingData[i];
      let defaultImage = "";
      //  var diff = new Date(r.currtime) - new Date(r.updatedate);
      const diff = moment(r.currtime).diff(r.updatedate, "minutes");
      var vehicleTypeNew = r.vehicleTypeNew.split(" ").join("-");
      if (diff >= 90) {
        liveTrackingData[i].listImage = vehicleTypeNew + "-gray.png";
        liveTrackingData[i].rowBackground = "gray";
        liveTrackingData[i].currentStatus = "nodata";
        defaultImage = "default-gray.png";
      } else if (r.acc == 1 && r.speed == 0) {
        //idle)
        liveTrackingData[i].listImage = vehicleTypeNew + "-yellow.png";
        liveTrackingData[i].rowBackground = "yellow";
        liveTrackingData[i].currentStatus = "idle";
        defaultImage = "default-yellow.png";
      } else if (r.acc == 0) {
        liveTrackingData[i].listImage = vehicleTypeNew + "-red.png";
        liveTrackingData[i].rowBackground = "red";
        liveTrackingData[i].currentStatus = "stop";
        defaultImage = "default-red.png";
      } else if (r.acc == 1 && r.speed > 0) {
        liveTrackingData[i].listImage = vehicleTypeNew + "-green.png";
        liveTrackingData[i].rowBackground = "green";
        liveTrackingData[i].currentStatus = "running";
        defaultImage = "default-green.png";
      }
      liveTrackingData[i].mapImage = liveTrackingData[i].listImage;

      try {
        require("../assets/images/detailtracking/" +
          liveTrackingData[i].listImage);
      } catch (err) {
        liveTrackingData[i].listImage = defaultImage;
      }

      try {
        require("../assets/images/livetracking/" +
          liveTrackingData[i].mapImage);
      } catch (err) {
        // console.log(defaultImage)
        // liveTrackingData[i].mapImage = "car-icon-blue.png";
        liveTrackingData[i].mapImage = defaultImage
        // liveTrackingData[i].listImage =defaultImage
      }
    });
    this.trackData = liveTrackingData;
    this.totalVehicalState = response?.data?.data?.totalvehstate;
    this.loading = false;

    this.total = parseInt(response?.data?.data?.TotalCount);
    // console.log(this.total, "total");
  }

  async exportExcel(getData) {
    // window.location.href = config.baseURL + "index.php?c=vehicle&a=exportloginexcel&cid=" +getDta.companyId + '&fD=' + getDta.fromDate + '&tD=' + getDta.toDate + '&uId=' + getDta.userid + '&colList=' + getDta.colList
    window.location.href =
      config.baseURL +
      "index.php?c=vehicletracking&a=exportDetailTrackingExcel&cid=" +
      getData.cid +
      // "&userCompanyId=" +
      // 1 +
      "&isadmin=" +
      getData.isadmin +
      "&colList=" +
      getData.colList +
      "&searchField=" +
      getData.searchField +
      "&status=" +
      getData.status +
      "&userId=" +
      getData.userId;

    // http://192.168.1.100:8088/awtl_react/app/index.php?c=vehicletracking&a=exportDetailTrackingExcel&cid=118&userCompanyId=1&isadmin=-1&colList=companyName,vehicleNo,rfid,vehicleTypeNew,name,formatted_address,acc,speed,updatedate,GPS,alert,distance,temp1,temp2,temp3,,&vehicleNo=&driver=&status=normal&userId=1
  }
}

export default DetailTrackingStore;
