import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi ,baseURL} from '../components/Utility'
import {
  action,
  
  makeObservable,
  observable,
 
} from 'mobx';


class WaslBusTripReportStore {
    waslBusTripResponseStore=[]
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        waslBusTripResponseStore: observable,
      total:observable,
      loading:observable,
      loadData:action,
     
    });
     this.rootStore = rootStore;
    
  }

  async loadData({offset,limit,companyId,vehicleId,fromDate,toDate}) {
    console.log("workingHoursReport")
    var postData = {"companyId":companyId,"vehicleId":vehicleId,"fromDate":fromDate,"toDate":toDate,"offset":offset,"limit":limit,"page":1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=trip&a=getWaslBusTripReport", "postData="+base64Input);
    console.log("sim " ,response?.data?.data)
   
    this.waslBusTripResponseStore =response?.data?.data?.waslData
  
     this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }


  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=trip&a=exportwaslBustripexcel&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&userId=" +
      getData.userId +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=trip&a=exportwaslBustrippdf&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      "&int="+
      getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&userId=" +
      getData.userId +
      "&language=" +
      lang;
  }

  
  

}

export default WaslBusTripReportStore;
