// const url = "http://192.168.1.100:8088/awtl_react/";

// const url = "http://192.168.1.100:8088/starling_react/";
// const url = 'http://13.126.174.243/awtl_react/';

// const url = "http://13.126.174.243/awtl_react/";

// const url = 'https://staging.pmistrack.com'

// const url = "https://awtltrack.com/newuiapi";

//const url = "https://portal.awtltrack.com/api";
// const url = "http://13.126.174.243/pmistrackreact/api";

// const url = "http://13.126.174.243/pmistrackreact/api";

const host = window.location.protocol + "//" + window.location.host;
var project = "";
var smallLogo = "";
var url = "";
var innerLogo = "";

var launchingDashboardLogo = "";
var dashboardLogo = "";
var menuLogo = "";
// if( host==='https://portal.awtltrack.com'){

//   project = "awtltrack";
//   smallLogo = "sml-logo.png";
//   innerLogo  = "inner-logo.png";
//   launchingDashboardLogo = "awtl-logo.png";
//   url = "https://portal.awtltrack.com/api";
// }
// if(host==='http://localhost:3000' || host==='http://13.126.174.243/pmistrackreact'){

project = "default";

var loginlogo = "trackeazy-logo.png";

innerLogo = "white-logo.png";

smallLogo = "trackeazy-sml.png";
// innerLogo = "trackeazy-logo.png";
dashboardLogo = "trackeazy-logo.png";
// launchingDashboardLogo = "trackeazy-logo.png";
url = "https://pro.trackeazy.com/api/";

if (
  // host === "http://localhost:3000" ||
  host == "https://pmispro.starlingtechs.com"
) {
  // if(  host=="https://pro.trackeazy.com/api/"){

  smallLogo = "starling-eye-small.png";
  innerLogo = "inner-logo-starling.png";
  // launchingDashboardLogo='awtllogo.png'
  launchingDashboardLogo = "trackeazy-logo.png";

  url = "https://pmispro.starlingtechs.com/api/";

  project = "starlingeye";

  loginlogo = "Starling2logo.png";

  dashboardLogo = "starlingLogo1.png";
}


//url = "http://13.126.174.243/pmistrackreact/api";
// url = "https://pro.trackeazy.com/api/"

// }

//  url = "http://192.168.1.100:8088/starling_react/";

const conf = {
  project: project,
  smallLogo: smallLogo,
  innerLogo: innerLogo,
  launchingDashboardLogo: launchingDashboardLogo,
  dashboardLogo: dashboardLogo,
  loginlogo: loginlogo,
  menuLogo: menuLogo,
  typeColor1: "#2db7f5",
  primaryColor: "#0090e7",
  typeColor2: "#45B39D",
  typeColor3: "#2db7f5",
  typeColor4: "#2db7f5",
  typeColor5: "red",
  activeColor: "#87d068",
  inActiveColor: "#808080",
  expirySoonColor: "#FFA500",

  locationColor: "violet",
  userColor: "#3c5a7e",
  adminColor: "#2db7f5",
  driverColor: "#45B39D",
  salesPersonColor: "#f56a00",
  deleteColor: "#f03939",
  defaultTimezoneId: 30,
  tooltipColorEdit: "#45B39D",
  tooltipColorDelete: "#f03939",
  tooltipColorExport: "#87d068",
  whiteColor: "white",
  googleMapKey: "AIzaSyAYh_6XQRV70kMX2LdZ9B1fd4qOhTgvfIo",

  // mapCenter: [24.7136, 46.6753],
  mapCenter: [25.276987, 55.296249],
  newCenter :[24.268935, 55.7653933],



  mapZoom: 14,

  uploadBoxType: "dashed",
  photoUploadSize: 10 * 1024 * 1024,
  UploadExcelSize: 1 * 1024 * 1024,
  mediaUrl: "https://media.awtltrack.com",
  //   exportBoxColor:"#00FF00",

  headerColor: "#ebebeb",
  fleetURL: url + "/public/fleet",
  baseURL: url + "/app/",
  fleetURLAdvertise: url + "/public/fleet/",
  //   #3c5a7e
};

export default conf;
