import React from "react";
import {
  Input,
  Select,
  Button,
  Space,
  DatePicker,
  Row,
  Col,
  Dropdown,
  Checkbox,
  Modal,
} from "antd";
import Cookies from "js-cookie";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { decode as base64_decode } from "base-64";
import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import withRouter from "../withRouter";

import ListTable from "./ListTable";

import CompanyAutocomplete from "./CompanyAutocomplete";
import moment from "moment";
import config from "../config";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import VehicleStore from "../store/VehicleStore";
import PassengerLogReportStore from "../store/PassengerLogReportStore";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
const { RangePicker } = DatePicker;

dayjs.extend(customParseFormat);
const { Search } = Input;
const dateFormat = "DD-MM-YYYY hh:mmA";
const defaultValue = moment().startOf("day").hour(0).minute(0).second(0);
const { Option } = Select;
class PassengerLogReportList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.passengerLogReportStore = new PassengerLogReportStore();
    this.vehicleStore = new VehicleStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      companyId: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      selectedVehicles: [],
      selectAllVehicles: false,

      modalVisible: false,
      column: "",
      filter: "",
      Notcompany: false,
      Notasset: false,
      showMonthPopup: false,

      selectedColumns: [
        {
          // title: "Vehicle No",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",
          width: "15%",
        },
        {
          // title: "Passenger TagId",
          title: context.translate("_PASSENGER_TAGID_"),
          dataIndex: "spTagId",
          key: "passengertagid",
          width: "15%",
        },

        {
          // title: "Passenger Name",
          title: context.translate("_PASSENGER_NAME_"),
          dataIndex: "spName",
          key: "passengername",
          width: "15%",
        },
        {
          // title: "Login Location",
          title: context.translate("_LOGIN_LOCATION_"),
          dataIndex: "spInLocation",
          key: "loginLocation",
          width: "15%",
        },
        {
          // title: "Log Out Location",
          title: context.translate("_LOGOUT_LOCATION_"),
          dataIndex: "spOutLocation",
          key: "logoutlocation",
          width: "15%",
        },
        {
          // title: "Login Time",
          title: context.translate("_LOGIN_TIME_"),
          dataIndex: "spInTime",
          key: "logintime",
          width: "15%",
          render: (value) => {
            if(value){
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            }
           
          },
        },
        {
          // title: "Logout Time",
          title: context.translate("_LOGOUT_TIME_"),
          dataIndex: "spOutTime",
          key: "logouttime",
          width: "15%",

          render: (value) => {
            if(value){
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            }
            
          },
        },
        {
          // title: "Status",
          title: context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
          dataIndex: "inOutStatus",
          key: "status",
          width: "15%",
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
          icon: <PlusOutlined />,
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
          icon: <PlusOutlined />,
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];

        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            intervel: this.state.intervel,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            // userId: 1,
            filter: this.state.filter,

            colList: result.toString(),
          };
          if (val.key == "excel") {
            this.passengerLogReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.passengerLogReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.passengerLogReportStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.passengerLogReportStore.exportxml(getData);
          }
        }
      },
    };

    this.newColumns = [...this.state.selectedColumns];
  }

  async componentDidMount() {
    this.setState({ pageSize: 50 });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }

    await this.setState({ column: this.state.selectedColumns });
  }

  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.showData();
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ currentPage: 1, companyId: comp?.value });
    await this.setState({ selectedVehicles: [], selectAllVehicles: false });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    //  await this.handleSearch();
    await this.setState({ listUpdated: 1 });
  }

  async handleSearch() {
    this.setState({ loading: true });
    this.setState({ Notasset: false });
    await this.passengerLogReportStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });

    await this.setState({ listUpdated: 1 }); //need to reload data
  }
  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss,";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0]?.format(format),
        toDate: dates[1]?.format(format),
      });
    }
  };

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };
  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.passengerLogReportStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
        });
        this.setState({ loading: false });
      }
    }
  };
  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  handleCheckboxChange = async (column) => {
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );

      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Passenger Log</div> */}
              <div className="heading">
                {this.context.translate("_PASSENGER_LOG_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.passengerLogReportStore?.passengers?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          {/* <Row style={{ padding: "10px 0px" }}> */}
          <Row className="listInputRow">
            <Space>
              <Col span={16}>
                <CompanyAutocomplete
                  onChange={(value) => this.handleChange(value)}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
              </Col>
              {this.props.getUserData().isadmin === 1 && (
                <DeviceGroupsForVehicles
                  vehicleStore={this.vehicleStore}
                  setLoadDta={() => this.setState({ loadData: 1 })}
                  resetVehicles={() =>
                    this.setState({
                      selectedVehicles: [],
                      selectAllVehicles: false,
                    })
                  }
                />
              )}

              <Checkbox
                checked={this.state.selectAllVehicles}
                onChange={async (e) => {
                  await this.setState({
                    selectAllVehicles: e.target.checked,
                  });

                  await this.handleAllSelectVehicles();
                }}
              />
              {/* All */}
              {this.context.translate("_ALL_")}
              {"  "}

              <Select
                maxTagCount="responsive"
                value={this.state.selectedVehicles}
                onChange={(val) => {
                  this.setState({ selectedVehicles: val });
                  if (
                    this.vehicleStore?.getVehiclesUsingCompanyId.length >
                    val.length
                  ) {
                    this.setState({ selectAllVehicles: false });
                  } else {
                    this.setState({ selectAllVehicles: true });
                  }

                  if (val?.length > 1) {
                    this.setState({ showAlertPopup: true });
                  }
                }}
                style={{ width: 200 }}
                showSearch
                status={this.state.Notasset ? "error" : false}
                mode="multiple"
                placeholder={this.context.translate(
                  "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                )}
                filterOption={(inputValue, option) => {
                  if (option.children) {
                    return (
                      option.children
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0
                    );
                  }
                }}
              >
                {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                  return <Option value={val.vehicleId}>{val.vehicleNo}</Option>;
                })}
              </Select>

              <Col>
                <RangePicker
                  placeholder={[
                    this.context.translate("_FROM_DATE_TIME_"),
                    this.context.translate("_TO_DATETIME_"),
                  ]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
              </Col>

              <Col>
                <Select
                  // placeholder="Pickup"
                  placeholder={this.context.translate("_PICKuP_")}
                  style={{ width: 100 }}
                >
                  <Select.Option value="All">
                    {this.context.translate("_ALL_")}
                  </Select.Option>
                  <Select.Option value="Pickup">
                    {this.context.translate("_PICKuP_")}
                  </Select.Option>
                  <Select.Option value="Drop">
                    {this.context.translate("_DROP_")}
                  </Select.Option>
                </Select>
              </Col>
              <Col>
                <Button type="primary" onClick={() => this.showData()}>
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Col>
            </Space>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            current={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.passengerLogReportStore?.total}
            columns={this.state.column}
            dataSource={this.passengerLogReportStore?.passengers}
            loading={this.passengerLogReportStore?.loading}
            onChange={this.handleTableChange}
            scroll={{
              // y: this.state.clientHeight-240,
              x: 100,
            }}
          />

          <Modal
            width={350}
            className="alert-model"
            title={
              <div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-exclamation-triangle"
                ></i>{" "}
                {this.context.translate("_FLEET_ALERT_")}
              </div>
            }
            open={this.state.showMonthPopup}
            onOk={() => this.setState({ showMonthPopup: false })}
            onCancel={() => this.setState({ showMonthPopup: false })}
            footer={false}
          >
            {/* For reports prior to 6 months please contact administrator */}
            {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
          </Modal>
        </div>
      </>
    );
  }
}
PassengerLogReportList.contextType = StoreContext;
export default observer(withRouter(PassengerLogReportList));
