import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class VehicleTrailerStore {
  vehicleTrailerVehicles=[]
  loading=false
  total=0
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        vehicleTrailerVehicles: observable,
        loading:observable,
        total:observable,
        loadData:action,
        addData:action,
        getTrailerDetails:action,
        deleteRecord:action

      
    });
     this.rootStore = rootStore;
    
  }
  async loadData({ companyId,offset, limit}) {
    console.log("vehicle listffff")
    this.loading=true
    var postData = {"companyId":companyId,"offset":offset,"limit":limit}
   
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=trailer&a=TrailerList", "postData="+base64Input);
   
    console.log(response?.data?.data)
    this.vehicleTrailerVehicles = response?.data?.data?.trailers
    this.total =parseInt(response?.data?.data?.TotalCount)
    this.loading=false
  }

 async addData(vehicletrailer){
    console.log("data",vehicletrailer)
     var postData = vehicletrailer 
     const base64Input = await base64_encode(JSON.stringify(postData));
     var response = await postApi.getClient().post("index.php?c=trailer&a=update", "postData="+base64Input);
    console.log(response)
    return response
  }

  async getTrailerDetails(Id){
    console.log("gettrailerdata",Id)
    var postData = {"tId":Id,limit:1,offset:0}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=trailer&a=TrailerList", "postData="+base64Input);
    console.log("response",response?.data?.data[0])
    return response?.data?.data?.trailers[0]

    // let data = response.data.data.find((record)=>record.tId === parseInt(Id))
    // console.log("data",data)
    // return data
 }

async deleteRecord({ tId }) {
  var postData = { "tId": tId }
  const base64Input = await base64_encode(JSON.stringify(postData));
  var response = await postApi.getClient().post("index.php?c=trailer&a=delete", "postData=" + base64Input);
  return response;

}
}

export default VehicleTrailerStore;
