import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Table,
  TimePicker,
  Tooltip,
} from "antd";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";
import config from "../config";

import CompanyAutocomplete from "./CompanyAutocomplete";
import dayjs from "dayjs";
import CompanyStore from "../store/CompanyStore";
import FairDetailsStore from "../store/FareDetailsStore";
import { Link } from "react-router-dom";
import { DeleteFilled } from "@ant-design/icons";
import { Calendar, DateObject } from "react-multi-date-picker";

const { Column } = Table;

var timeOptions = [
  {
    value: "12:00:00",
    label: "12:00:00",
  },
  {
    value: "01:00:00",
    label: "01:00:00",
  },
  {
    value: "02:00:00",
    label: "02:00:00",
  },
  {
    value: "03:00:00",
    label: "03:00:00",
  },
  {
    value: "04:00:00",
    label: "04:00:00",
  },
  {
    value: "05:00:00",
    label: "05:00:00",
  },
  {
    value: "06:00:00",
    label: "06:00:00",
  },
  {
    value: "07:00:00",
    label: "07:00:00",
  },
  {
    value: "08:00:00",
    label: "08:00:00",
  },
  {
    value: "09:00:00",
    label: "09:00:00",
  },
  {
    value: "10:00:00",
    label: "10:00:00",
  },
  {
    value: "11:00:00",
    label: "11:00:00",
  },
  {
    value: "13:00:00",
    label: "13:00:00",
  },
  {
    value: "14:00:00",
    label: "14:00:00",
  },
  {
    value: "15:00:00",
    label: "15:00:00",
  },
  {
    value: "16:00:00",
    label: "16:00:00",
  },
  {
    value: "17:00:00",
    label: "17:00:00",
  },
  {
    value: "18:00:00",
    label: "18:00:00",
  },
  {
    value: "19:00:00",
    label: "19:00:00",
  },
  {
    value: "20:00:00",
    label: "20:00:00",
  },
  {
    value: "21:00:00",
    label: "21:00:00",
  },
  {
    value: "22:00:00",
    label: "22:00:00",
  },
  {
    value: "23:00:00",
    label: "23:00:00",
  },
  {
    value: "24:00:00",
    label: "24:00:00",
  },
];

class FareDetailsAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loadingSpin: false,
      companyId: "",
      parameterId: -1,
      tableData: [],
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      showWorkingDaysChk: false,
      showCalender: false,
      selectedDates: [],
    };

    this.fareDetailsStore = new FairDetailsStore(this);
    this.companyStore = new CompanyStore(this);
  }
  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      this.context.viewStateStore.setFareDetails({
        companyId: this.props.getUserData().userCompanyId,
      });

      await this.fareDetailsStore.vehicleTypeData({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
    }

    const parameterId = this.props.parameterId;
    console.log(parameterId);
    if (parameterId) {
      await this.filldata(parameterId);
    }

    this.setState({ update: 1 });
  }

  async filldata(parameterId) {
    const getGridData = await this.fareDetailsStore.getFareGridData(
      parameterId
    );
    console.log(getGridData);
    const getFareData = await this.fareDetailsStore.getFareData({
      parameterId,
      companyId: this.props.companyId,
    });

    await this.fareDetailsStore.vehicleTypeData(getFareData.companyId);
    await this.setState({
      companyId: getFareData.companyId,
      parameterId: getFareData.parameterId,
      tableData: getGridData,
    });
    const singleData = await this.companyStore.getCompanyDataById(
      getFareData.companyId
    );

    const date = getFareData.SchoolCalHoliday.map((val) => val.termHoliday);

    this.formRef.current.setFieldsValue({
      companyId: singleData,
      parameterId: getFareData.parameterId,
      basicFare: getFareData.basicFare,
      bookingCharges: getFareData.bookingCharges,
      distanceFare: getFareData.distanceFare,
      fairType: getFareData.fairType,
      fromDate: getFareData.fromDate ? dayjs(getFareData.fromDate) : "",
      longDistance: getFareData.longDistance,
      longDistanceFare: getFareData.longDistanceFare,
      minFare: getFareData.minFare,
      tariffName: getFareData.tariffName,
      taxPercentage: getFareData.taxPercentage,
      timeFare: getFareData.timeFare,
      toDate: getFareData.toDate ? dayjs(getFareData.toDate) : "",
      vehicleTypeId: getFareData.vehicleTypeId,
      peakTimeDays: getFareData.peakTimeDays,
      holidayDate: date,
    });

    if (getFareData.fairType === "1") {
      this.setState({ showWorkingDaysChk: true });
      this.setState({ showCalender: false });
    } else if (getFareData.fairType === "2") {
      this.setState({ showCalender: true });
      this.setState({ showWorkingDaysChk: false });
    }
  }

  onFareDetailSubmit = async (fieldsValue) => {
    const data = {
      ...fieldsValue,
      parameterId: this.state.parameterId,
      companyId: this.state.companyId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    };

    const fareDetails = {
      ...data,
      GridDetails: this.state.tableData,
    };

    this.setState({ loading: true });
    const response = await this.fareDetailsStore.addData(fareDetails);
    await this.props.response(response);

    this.setState({ loading: false });
  };
  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    if (!this.props.parameterId) {
      this.context.viewStateStore.setFareDetails(comp.value);
    }

    await this.fareDetailsStore.vehicleTypeData(comp.value);
    await this.setState({ companyId: comp.value });
  }

  closeModal = () => {
    this.props.closeModal();
  };

  handleAddRecord = () => {
    const { tableData } = this.state;
    this.setState({
      tableData: [
        ...tableData,
        {
          Id: 0,
          tid: "",
          peakTimeFrom: "",
          peakTimeTo: "",
          bookingCharges1: "",
          peakDistanceFare: "",
          peakTimeFare: "",
          peakBasicFare: "",
          peakMinFare: "",
        },
      ],
    });
  };

  handleInputChange = (e, index, fieldName) => {
    const newData = [...this.state.tableData];
    if (fieldName === "peakTimeFrom" || fieldName === "peakTimeTo") {
      newData[index][fieldName] = e;
    } else {
      newData[index][fieldName] = e.target.value;
    }
    this.setState({ tableData: newData });
  };

  handleFairType = async (value) => {
    // console.log(value);
    if (value === "1") {
      this.setState({ showWorkingDaysChk: true });
      this.setState({ showCalender: false });
    } else if (value === "2") {
      this.setState({ showCalender: true });
      this.setState({ showWorkingDaysChk: false });
    }
  };

  selectCalenderDates = (value) => {
    const newDates = value.map((date) => date.format("YYYY-MM-DD"));
    console.log(newDates);
    this.formRef.current.setFieldsValue({ holidayDate: newDates });
  };

  validateNumberAndDecimal = (value, field) => {
    const value1 = value.replace(/[^0-9.,]+/g, "");
    console.log(value1);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  onDeleteRow = (index) => {
    const filterData = this.state.tableData.filter((val, ind) => ind !== index);

    this.setState({ tableData: filterData });
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              ref={this.formRef}
              layout="vertical"
              name="nest-messages"
              onFinish={this.onFareDetailSubmit}
              style={{ maxWidth: "100%", padding: 0 }}
              className="formClass"
              initialValues={{ fromDate: dayjs(), toDate: dayjs() }}
            >
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate(
                        "_FLEET_DRIVER_DRIVERDETAIL_COMPANY_"
                      )}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => this.handleChange(value)}
                        style={{ width: "100%" }}
                        value={this.state.companyId}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="fairType"
                    // label="Account Name"
                    label={this.context.translate("_TYPE_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={[
                        {
                          value: "1",
                          label: this.context.translate("_NORMAL_"),
                        },
                        {
                          value: "2",
                          label: this.context.translate("_HOLIYDAY_"),
                        },
                      ]}
                      onSelect={(value) => this.handleFairType(value)}
                    ></Select>
                  </Form.Item>

                  {this.state.showWorkingDaysChk && (
                    <Form.Item
                      className="formCheckboxCss"
                      name="peakTimeDays"
                      style={{ marginTop: "15px" }}
                      label={this.context.translate("_WORKING_DAYS_")}
                    >
                      <Checkbox.Group
                        style={{
                          width: "100%",
                        }}
                        // onChange={this.onCheckboxChange}
                      >
                        <Row>
                          <Col span={4}>
                            <Checkbox value="0">S</Checkbox>
                          </Col>
                          <Col span={4}>
                            <Checkbox value="1">M</Checkbox>
                          </Col>
                          <Col span={4}>
                            <Checkbox value="2">T</Checkbox>
                          </Col>
                          <Col span={4}>
                            <Checkbox value="3">W</Checkbox>
                          </Col>
                          <Col span={4}>
                            <Checkbox value="4">T</Checkbox>
                          </Col>
                          <Col span={4}>
                            <Checkbox value="5">F</Checkbox>
                          </Col>
                          <Col span={4}>
                            <Checkbox value="6">S</Checkbox>
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  )}

                  {this.state.showCalender && (
                    <Form.Item
                      // className="formCheckboxCss"
                      name="holidayDate"
                      style={{
                        marginTop: "90px",
                        marginBottom: "150px",
                        marginLeft: "10px",
                      }}
                      // label={this.context.translate("_WORKING_DAYS_")}
                    >
                      <Calendar
                        style={{ marginTop: "86px" }}
                        value={this.selectedDates?.map(
                          (date) => new DateObject(date)
                        )}
                        multiple
                        onChange={this.selectCalenderDates}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="tariffName"
                    label={this.context.translate("_TARIFF_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="fromDate"
                    label={this.context.translate("_VALID_FROM_DATETIME_")}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format="DD-MM-YYYY 12:mmA"
                      defaultValue={dayjs().startOf("day")}
                      onChange={(date) => {
                        if (date === null) {
                          this.setState({ fromDate: "" });
                        } else {
                          this.setState({
                            toDate: date.format("YYYY-MM-DD HH:mm:ss"),
                          });
                        }
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="toDate"
                    label={this.context.translate("_VALID_TO_DATETIME_")}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format="DD-MM-YYYY HH:mmA"
                      defaultValue={dayjs()}
                      placeholder="To Date"
                      onChange={(date) => {
                        if (date === null) {
                          this.setState({ toDate: "" });
                        } else {
                          this.setState({
                            toDate: date.format("YYYY-MM-DD HH:mm:ss"),
                          });
                        }
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="vehicleTypeId"
                    label={this.context.translate("_VEHICLE_TYPE_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={this.fareDetailsStore.vehicleTypeArr}
                      fieldNames={{
                        value: "vehicleTypeId",
                        label: "vehicleType",
                      }}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    name="taxPercentage"
                    label={this.context.translate("_TAX_PERCENTAGE_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      onChange={(e) =>
                        this.validateNumberAndDecimal(
                          e.target.value,
                          "taxPercentage"
                        )
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="basicFare"
                    label={this.context.translate("_BASIC_FARE_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      onChange={(e) =>
                        this.validateNumberAndDecimal(
                          e.target.value,
                          "basicFare"
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="minFare"
                    label={this.context.translate("_MINIMUM_FARE_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      onChange={(e) =>
                        this.validateNumberAndDecimal(e.target.value, "minFare")
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="distanceFare"
                    label={this.context.translate("_DISTANCE_FARE_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      onChange={(e) =>
                        this.validateNumberAndDecimal(
                          e.target.value,
                          "distanceFare"
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="timeFare"
                    label={this.context.translate("_TIME_FARE_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      onChange={(e) =>
                        this.validateNumberAndDecimal(
                          e.target.value,
                          "timeFare"
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="longDistance"
                    label={this.context.translate("_LONG_DISTANCE_KM_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      onChange={(e) =>
                        this.validateNumberAndDecimal(
                          e.target.value,
                          "longDistance"
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="longDistanceFare"
                    label={this.context.translate("_LONG_DISTANCE_FARE_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      onChange={(e) =>
                        this.validateNumberAndDecimal(
                          e.target.value,
                          "longDistanceFare"
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="bookingCharges"
                    label={this.context.translate("_BOOKING_CHARGES_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      onChange={(e) =>
                        this.validateNumberAndDecimal(
                          e.target.value,
                          "bookingCharges"
                        )
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Row>
                    <Button
                      type="primary"
                      onClick={this.handleAddRecord}
                      style={{ margin: "20px 0px 20px 0px" }}
                    >
                      {this.context.translate("_ADD_RECORD_")}
                    </Button>
                  </Row>

                  <Table
                    // scroll={{ x: 200 }}
                    scroll={{ x: "max-content" }}
                    dataSource={this.state.tableData}
                    bordered
                  >
                    <Column
                      title={this.context.translate("_Id_")}
                      dataIndex="tid"
                      key="tid"
                      width={100}
                      render={(text, record, index) => (
                        <Input
                          value={text}
                          onChange={(e) =>
                            this.handleInputChange(e, index, "tid")
                          }
                        />
                      )}
                    />
                    <Column
                      title={this.context.translate("_PEAK_TIME_FROM_")}
                      dataIndex="peakTimeFrom"
                      key="peakTimeFrom"
                      width={150}
                      render={(text, record, index) => (
                        <Select
                          value={record.peakTimeFrom}
                          onSelect={async (e, f) => {
                            await this.handleInputChange(
                              e,
                              index,
                              "peakTimeFrom"
                            );
                          }}
                          style={{ width: "140px" }}
                          options={timeOptions}
                        ></Select>
                      )}
                    />
                    <Column
                      title={this.context.translate("_PEAK_TIME_TO_")}
                      dataIndex="peakTimeTo"
                      key="peakTimeTo"
                      width={130}
                      render={(text, record, index) => (
                        <Select
                          value={record.peakTimeTo}
                          onSelect={async (e, f) => {
                            await this.handleInputChange(
                              e,
                              index,
                              "peakTimeTo"
                            );
                          }}
                          style={{ width: "120px" }}
                          options={timeOptions}
                        ></Select>
                      )}
                    />
                    <Column
                      title={this.context.translate("_PEAK_DISTANCE_FARE_")}
                      dataIndex="peakDistanceFare"
                      key="peakDistanceFare"
                      width={170}
                      render={(text, record, index) => (
                        <Input
                          value={text}
                          onChange={(e) => {
                            this.handleInputChange(
                              e,
                              index,
                              "peakDistanceFare"
                            );
                          }}
                        />
                      )}
                    />

                    <Column
                      title={this.context.translate("_PEAK_TIME_FARE_")}
                      dataIndex="peakTimeFare"
                      key="peakTimeFare"
                      width={150}
                      render={(text, record, index) => (
                        <Input
                          value={text}
                          onChange={(e) => {
                            this.handleInputChange(e, index, "peakTimeFare");
                          }}
                        />
                      )}
                    />
                    <Column
                      title={this.context.translate("_PEAK_BASIC_FARE_")}
                      dataIndex="peakBasicFare"
                      key="peakBasicFare"
                      width={150}
                      render={(text, record, index) => (
                        <Input
                          value={text}
                          onChange={(e) => {
                            this.handleInputChange(e, index, "peakBasicFare");
                          }}
                        />
                      )}
                    />
                    <Column
                      // title="Gross Price"
                      title={this.context.translate("_PEAK_MIN_FARE_")}
                      dataIndex="peakMinFare"
                      key="peakMinFare"
                      width={150}
                      render={(text, record, index) => (
                        <Input
                          value={text}
                          onChange={(e) => {
                            this.handleInputChange(e, index, "peakMinFare");
                          }}
                        />
                      )}
                    />

                    <Column
                      // title="Gross Price"
                      title={this.context.translate("_BOOKING_CHARGES_")}
                      dataIndex="bookingCharges1"
                      key="bookingCharges1"
                      width={150}
                      render={(text, record, index) => (
                        <Input
                          value={text}
                          onChange={(e) => {
                            this.handleInputChange(e, index, "bookingCharges1");
                          }}
                        />
                      )}
                    />
                    <Column
                      title={this.context.translate("_OPERATION_")}
                      dataIndex=""
                      key=""
                      width={100}
                      render={(text, record, index) => (
                        <div>
                          <Space>
                            <Link
                              onClick={async () => {
                                await this.onDeleteRow(index);

                                console.log(record, text);
                              }}
                            >
                              <Tooltip
                                title={this.context.translate(
                                  "_FLEET_DASHBORD_RULETRIGGER_DELETE_"
                                )}
                                color={config.tooltipColorDelete}
                              >
                                <DeleteFilled
                                  style={{ color: config.deleteColor }}
                                />
                              </Tooltip>
                            </Link>
                          </Space>
                        </div>
                      )}
                    />
                  </Table>
                </Col>
              </Row>

              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal();
                  }}
                >
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="formSaveButton"
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}
FareDetailsAdd.contextType = StoreContext;
export default observer(withRouter(FareDetailsAdd));
