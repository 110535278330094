import React, { useState, useContext, memo, useEffect } from "react";
import { observer } from "mobx-react";
import DebounceSelect from "../components/DebounceSelect";
import CompanyStore from "../store/CompanyStore";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import { useCookies } from "react-cookie";
import { decode as base64_decode, encode } from "base-64";

const CompanyAutocomplete = observer((props) => {
  const companyStore = new CompanyStore();
  const storeContext = useContext(StoreContext); // Using useContext to access the context
  const [cookies] = useCookies();
  if (props.companyId > 0) {
    // return await companyStore.getCompanyDataById(props.companyId);
  }
  async function fetchCompanyList(companyName) {
    return await companyStore.getCompanyData(
      companyName,
      props.fromAccountList,
      props.showAll ?? props.showAll
    );
  }
  useEffect(() => {}, []);
  return (
    <DebounceSelect
      {...(Number(JSON.parse(base64_decode(cookies.data)).isadmin === -1) ||
      Number(JSON.parse(base64_decode(cookies.data)).isadmin === 3)
        ? { ...props }
        : { ...props, style: { display: "none" } })}
      placeholder={storeContext.translate(
        "_FLEET_CREATEROUTE_ROUTEADD_COMPANY_"
      )}
      fetchOptions={(companyName) => fetchCompanyList(companyName)}
    />
  );
});

export default withRouter(CompanyAutocomplete);
