import React, { Component } from "react";

import {
  Form,
  Select,
  Button,
  Input,
  DatePicker,
  TimePicker,
  Modal,
  Checkbox,
  Upload,
  Row,
  Col,
  Space,
  message,
  Table,
  Card,
  Tooltip,
  Spin,
} from "antd";
import { Autocomplete } from "@react-google-maps/api";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";

import config from "../config";
import { format, addMinutes } from "date-fns";
import { Link } from "react-router-dom";
import CompanyAutocomplete from "./CompanyAutocomplete";
import GoogleMapForDirection from "./GoogleMapForDirection";
import AssignTripStore from "../store/AssignTripStore";
import dayjs from "dayjs";
import CompanyStore from "../store/CompanyStore";
import DeleteConfirm from "./DeleteConfirm";
import {
  CloseOutlined,
  DeleteFilled,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import axios from "axios";
const inputStyle = {
  boxSizing: "border-box",
  border: "1px solid transparent",

  height: "32px",
  padding: "0 12px",
  borderRadius: "3px",
  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
  fontSize: "14px",
  outline: "none",
  textOverflow: "ellipses",
  // position: "absolute",
  left: "50%",
  // marginLeft: "-120px",
};

const formCss = {
  width: "285px",
  background: "rgb(255, 255, 255)",
  margin: "20px",
  padding: "13px",
  border: "1px solid",
  height: "620px",
  "overflow-y": "scroll",
};
class AssignTripAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      pointsWay: [],
      companyId: "",
      loadList: 0,
      offset: "",
      limit: "",
      geofence: "",
      searchOnMap: "",
      history: "",
      fromDate: dayjs(),
      toDate: dayjs(),
      fromTime: "",
      toTime: "",
      vehicleId: "",
      routePoints: [],
      startPoint: false,
      endPoint: false,
      pickUpDrop: false,
      startObj: "",
      endObj: "",
      loadState: 0,
      origin: "",
      destination: "",
      companyCustomer: false,
      studentCustomer: false,
      addAddress: false,
      stId: "",
      pickUpDropObj: {},
      endTimeShowingTbl: "00:00",
      addressInputText: "",
      routePopupShow: false,
      geofencePointsArrayObj: [],
      clearMapBtn: false,
      routeDeviationAlert: true,
      geofenceInfoObj: {},
      geofenceFormatedPoints: [],
      tripId: "",
      dayofweeks: [],
      historyInfoObj: {},
      positionLatLongArray: [],
      loadingSpinner: false,
      wayPointId: 1,
      travelDistance: "",
    };
    this.originRef = React.createRef();
    this.autocompleteRef = React.createRef();

    // this.state = { dateFormatList: ["DD/MM/YYYY"], loading: false };
    this.assignTripStore = new AssignTripStore(this);
    this.companyStore = new CompanyStore(this);
  }
  onSequenceTableRecordDelete = async (record) => {
    console.log(record, "ca");

    if (record.sequenceId) {
      if (Number(record.sequenceId) === 1) {
        console.log("called");
        this.setState({ origin: null, startObj: "" });
      } else if (Number(record.sequenceId) === 2) {
        this.setState({ destination: null, endObj: "" });
      }
      const filterRecord = this.state.routePoints.filter((val) => {
        return Number(record.sequenceId) !== Number(val.sequenceId);
      });
      await this.setState({ routePoints: filterRecord });
      if (this.state.geofencePointsArrayObj?.length > 0) {
        let filterGeofence = await this.state.geofencePointsArrayObj.filter(
          (val) => {
            return Number(record.sequenceId) !== Number(val.sequenceId);
          }
        );
        await this.setState({ geofencePointsArrayObj: filterGeofence });
        console.log(filterGeofence, this.state.geofencePointsArrayObj, record);
      }
      console.log(filterRecord);
    } else if (Number(record.wayPointId)) {
      console.log(record);
      const filterRecord = this.state.routePoints.filter((val) => {
        return Number(record.wayPointId) !== Number(val.wayPointId);
      });
      await this.setState({ routePoints: filterRecord });
      const pointsWay = this.state.pointsWay.filter((val) => {
        return Number(record.wayPointId) !== Number(val.wayPointId);
      });
      // pointsWay
      console.log(pointsWay);
      await this.setState({ pointsWay: pointsWay });
    }

    // this.setState({
    //   origin: "",
    //   destination: "",
    //   pointsWay: [],
    //   clearMapBtn: true,
    // });
  };

  layout = {
    labelCol: { span: 16 },
    wrapperCol: { span: 16 },
  };

  componentDidMount() {
    this.setState({ companyCustomer: true });
    this.columns = [
      {
        title: "Sequence No",
        dataIndex: "sequenceNo",
        key: "sequenceNo",
      },
      {
        title: "PickUp Time",
        dataIndex: "picktime",
        key: "age",
      },
      {
        title: "Action",
        dataIndex: "",
        key: "",
        render: (_, record) => {
          return (
            <>
              <Space>
                <DeleteConfirm
                  onConfirm={(e) => {
                    // this.onDelete(record);
                    console.log(record);
                    this.onSequenceTableRecordDelete(record);
                  }}
                >
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <DeleteFilled style={{ color: config.deleteColor }} />
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
    const id = this.props.params.id;

    if (id) {
      this.filldata(id);
    }
  }

  componentDidUpdate() {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    });
  }
  filldata = async (id) => {
    await this.setState({ loadingSpinner: true });
    await this.setState({ addAddress: true });
    const getTripData = await this.assignTripStore.getAssignTripRecord(id);
    await this.setState({ companyId: getTripData.companyId });
    const singleData = await this.companyStore.getCompanyDataById(
      getTripData.companyId
    );
    await this.assignTripStore.getGeoData(getTripData.companyId);
    console.log(getTripData);
    this.setState({ routePoints: getTripData.TripPoints });
    // await this.assignTripStore.getGeoData(getTripData.companyId,getTripData.geofenceId,getTripData.vehicleId);
    console.log(this.assignTripStore.geoDataArray);
    await this.setState({ stId: getTripData.stId, tripId: getTripData.stId });
    await this.assignTripStore.getVehicles(getTripData.companyId);
    await this.formRef.current.setFieldsValue({
      companyId: singleData,
      vehicleId: Number(getTripData.vehicleId),
      emailId: getTripData.emailId,
      TripName: getTripData.tripName,
      Tripcode: getTripData.tripCode,
      routeZone: getTripData.routeZone,
      routestartDate: getTripData.routestartDate
        ? dayjs(getTripData.routestartDate)
        : null,
      routestopDate: getTripData.routestartDate
        ? dayjs(getTripData.routestopDate)
        : null,

      dayofweeks: getTripData.dayofweeks?.split(","),
    });
    let arr = [];
    this.setState({ routeDeviationAlert: Number(getTripData.routDeviation) });
    console.log(getTripData.dayofweeks?.split(","));
    this.setState({ dayofweeks: getTripData.dayofweeks?.split(",") });
    let routePoints = [];
    getTripData.TripPoints.forEach((element) => {
      if (element.sequenceNo.includes("Start")) {
        console.log({ ...element, sequenceId: 1 });
        this.setState({
          startObj: element,
          origin: {
            lat: parseFloat(element.latitude),
            lng: parseFloat(element.longitude),
          },
        });
      } else if (element.sequenceNo.includes("End")) {
        this.setState({
          endObj: element,
          destination: {
            lat: parseFloat(element.latitude),
            lng: parseFloat(element.longitude),
          },
        });
      } else {
        routePoints = [
          ...routePoints,
          {
            location: {
              lat: Number(element.latitude),
              lng: Number(element.longitude),
            },
            wayPointId: element.wayPointId,
          },
        ];

        // arr = [
        //   ...arr,
        //   {
        //     location: {
        //       lat: Number(element.latitude),
        //       lng: Number(element.longitude),
        //     },
        //   },
        // ];
        this.setState({ pointsWay: routePoints });
        console.log(arr);
        // this.setState({ pickUpDrop: element });
      }
    });

    await this.setState({ routePoints: getTripData.TripPoints });
    await this.setState({ loadingSpinner: false });
  };

  async handleChange(comp) {
    console.log(comp.value);
    await this.setState({ companyId: comp.value });
    await this.formRef.current.setFieldsValue({ companyId: comp.value });
    await this.assignTripStore.getVehicles(comp.value);
    await this.assignTripStore.getGeoData(comp.value);
    if (!this.props.params.userId) {
      this.context.viewStateStore.setAssignTripStates({
        companyId: comp.value,
      });
    }
    await this.handleSearch();
  }

  async handleSearch() {
    await this.assignTripStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  onFinish = async (values) => {
    // this.setState({ loading: true });
    console.log(values, values.startTime?.format("HH:mm"));
    const obj = {
      ...values,
      companyId: this.state.companyId,
      routDeviation: this.state.routeDeviationAlert ? 1 : 0,
      userId: 1,
      tripId: this.state.tripId,
      stId: this.state.stId,
      startTime: values.startTime?.format("HH:mm"),
      routestartDate: values.routestartDate?.format("YYYY-MM-DD"),
      routestopDate: values.routestopDate?.format("YYYY-MM-DD"),
    };
    console.log(obj);
    console.log(this.state.routePoints);
    const routePoints = this.state.routePoints;
    // console.log(obj)
    const resp = await this.assignTripStore.createTrip({ obj, routePoints });
    if (resp.data.success === "S") {
      message.success("Trip created successfully");
      this.props.navigate("/assigntriplist");
    } else if (resp.data.success === "F0") {
      message.error("Error");
    }
  };
  handleAddressChange = (val) => {
    if (val === "geofence") {
      this.setState({ geofence: true, searchOnMap: false, history: false });
    } else if (val === "searchOnMap") {
      this.setState({ geofence: false, searchOnMap: true, history: false });
    } else if (val === "history") {
      this.setState({ geofence: false, searchOnMap: false, history: true });
    }
  };

  timeOptions = () => {
    const timeArray = [];

    let startTime = new Date(2000, 0, 1, 0, 0); // 12:00 AM
    console.log();
    let endTime = new Date(2000, 0, 1, 23, 30);
    for (let time = startTime; time <= endTime; time = addMinutes(time, 30)) {
      // const formattedTime = format(time, 'h:mm aa');
      const formattedTime = format(time, "HH:mm:ss");
      var ob = {
        formattedTime: formattedTime,
        time: time,
      };
      timeArray.push(ob);
    }
    console.log(timeArray);
    return timeArray.map((data) => {
      return (
        <Select.Option key={data.formattedTime} value={data.formattedTime}>
          {format(data.time, "h:mm aa")}
        </Select.Option>
      );
    });
  };
  LoadClick = async () => {
    const data = {
      companyId: this.state.companyId,
      vehicleId: this.state.vehicleId,
      fromDate:
        this.state.fromDate.format("YYYY-MM-DD") + " " + this.state.fromTime,
      toDate: this.state.toDate.format("YYYY-MM-DD") + " " + this.state.toTime,
    };
    console.log(data);
    const response = await this.assignTripStore.getStopeIdle(data);
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      console.log(response);
    } else {
      message.error();
    }
    this.setState({ update: 1 });
    console.log(data);
  };

  handlePlaceSelected = async (placeDetails) => {
    console.log(placeDetails);

    // console.log(this.state.start, this.state.end);
    if (this.state.startPoint) {
      const obj = {
        sequenceId: 1,
        routeZoneId: "ext-gen4321",
        routePoint: "Search on Map",
        sequenceNo: "Start Point-" + placeDetails.formattedAddress,
        address: placeDetails.formattedAddress,
        lati: placeDetails.latitude,
        longi: placeDetails.longitude,
        arriveTime: null,
        departureTime: dayjs(
          this.formRef.current.getFieldValue("startTime")
        ).format("HH:mm"),
        endTime: null,
        locOption: "Start-Time",
        time: dayjs(this.formRef.current.getFieldValue("startTime")).format(
          "HH:mm"
        ),
      };
      this.setState({ startObj: obj });
    } else if (this.state.endPoint) {
      const obj = {
        sequenceId: 2,
        routeZoneId: "ext-gen2264",
        routePoint: "Search on Map",
        sequenceNo: "End Point-" + placeDetails.formattedAddress,
        address: placeDetails.formattedAddress,
        lati: placeDetails.latitude,
        longi: placeDetails.longitude,
        arriveTime: dayjs(this.formRef.current.getFieldValue("endTime")).format(
          "HH:mm"
        ),
        departureTime: null,
        endTime: null,
        time: dayjs(this.formRef.current.getFieldValue("endTime")).format(
          "HH:mm"
        ),
        locOption: "End-Time",
      };
      console.log("hello");
      this.setState({ endObj: obj });
    } else if (this.state.pickUpDrop) {
      await this.setState({ wayPointId: this.state.wayPointId + 1 });
      const obj = {
        routeZoneId: "ext-gen2264",
        routePoint: "Search on Map",
        sequenceNo: "PickUp Point-" + placeDetails.formattedAddress,
        address: placeDetails.formattedAddress,
        lati: placeDetails.latitude,
        longi: placeDetails.longitude,
        arriveTime: null,
        departureTime: null,
        endTime: null,
        time: "00:00",
        locOption: "Pickup/Drop-Time",
        wayPointId: this.state.wayPointId,
      };

      await this.setState({ pickUpDropObj: obj });
      console.log([
        ...this.state.pointsWay,
        {
          location: { lat: placeDetails.latitude, lng: placeDetails.longitude },
          wayPointId: this.state.wayPointId,
        },
      ]);
      await this.setState({
        pointsWay: [
          ...this.state.pointsWay,
          {
            location: {
              lat: placeDetails.latitude,
              lng: placeDetails.longitude,
            },
            wayPointId: this.state.wayPointId,
          },
        ],
      });

      // this.setState({ pointsWay: [...this.state.pointsWay,{location:{lat:placeDetails.latitude,lng:placeDetails.longitude}}] });
    }
    this.setState({ loadState: 1 });
  };

  handleSequenceChange = (val) => {
    // startPoint,endpoint,pickup/drop
    if (val === "startPoint") {
      this.setState({ startPoint: true, endPoint: false, pickUpDrop: false });
    } else if (val === "endPoint") {
      if (!this.state.origin) {
        // Modal.warning({ content: "Please Select Start Point First" });
        Modal.warning({
          content: this.context.translate("_PLEASE_SELECT_START_POINT_FIRST_"),
        });
      } else {
        this.setState({ startPoint: false, endPoint: true, pickUpDrop: false });
      }
    } else if (val === "pickup/drop") {
      if (!this.state.origin) {
        // Modal.warning({ content: "Please Select Start Point First" });
        Modal.warning({
          content: this.context.translate("_PLEASE_SELECT_START_POINT_FIRST_"),
        });
      } else {
        this.setState({ startPoint: false, endPoint: false, pickUpDrop: true });
      }
    }
  };

  addSequenceIntoTable = async () => {
    // console.log(this.state.startObj, this.state.endObj);
    // console.log(this.state.startObj);
    if (!this.state.startObj) {
      Modal.warning({ content: "Please Select Start Point and address." });
    }
    console.log(this.state.startObj);
    let startObj = this.state.startObj;
    console.log(this.state.startObj);
    let endObj = this.state.endObj;
    let pickUpDrop = this.state.pickUpDrop;
    console.log(this.state.endObj, this.state.startObj);
    console.log(pickUpDrop, startObj, endObj);
    // await this.setState({routePoints:[...this.state.routePoints,startObj,endObj,pickUpDrop]})
    if (this.state.companyId) {
      let arr;
      if (this.state.endPoint) {
        console.log(this.state.routePoints, "hello");
        arr = [...this.state.routePoints, endObj];
        console.log(endObj);
        await this.setState({
          routePoints: arr,
          destination: { lat: endObj.lati, lng: endObj.longi },
        });
        // this.setState({ endObj: "", });
      } else if (this.state.startPoint) {
        arr = [startObj];
        console.log(startObj);
        await this.setState({
          routePoints: arr,
          origin: { lat: startObj.lati, lng: startObj.longi },
        });
      } else if (this.state.pickUpDrop) {
        arr = [...this.state.routePoints, this.state.pickUpDropObj];
        console.log(arr);
        await this.setState({
          routePoints: arr,
          // origin: {
          //   lat: this.state.pickUpDropObj?.lati,
          //   lng: this.state.pickUpDropObj?.longi,
          // },
        });
      }
      console.log(arr);
      await this.setState({ addressInputText: "" });
    } else {
      Modal.warning({
        // title: "Alert",
        title: this.context.translate("_FLEET_ALERT_"),
        // content: "Please select Account",
        content: this.context.translate("_PLEASE_SELECT_ACCOUNT_"),
      });
    }
    console.log(this.state.routePoints);
    this.setState({ update: 1 });
  };
  selectCustomer = (val) => {
    if (val === "company") {
      this.setState({ companyCustomer: true, studentCustomer: false });
    } else {
      this.setState({ companyCustomer: false, studentCustomer: true });
    }
  };

  placeChange = async () => {
    if (this.originRef.current) {
      const place = this.originRef.current.getPlace();

      if (place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        await this.setState({
          positionLatLongArray: [
            ...this.state.positionLatLongArray,
            { lat: lat, lng: lng },
          ],
        });

        if (this.state.endPoint) {
          this.setState({ destination: { lat: lat, lng: lng } });
        } else if (this.state.startPoint) {
          this.setState({ origin: { lat: lat, lng: lng } });
        }
        console.log("Formatted Address:", place.formatted_address);
        console.log("Latitude:", lat);
        console.log("Longitude:", lng);
        const addressObject = {
          formattedAddress: place.formatted_address,
          latitude: lat,
          longitude: lng,
        };
        this.setState({ addressInputText: place.formatted_address });
        this.handlePlaceSelected(addressObject);
      } else {
        console.log("Location information not available.");
      }
    }
  };
  handleGeofenceObj = async (record) => {
    if (
      !this.state.startPoint &&
      !this.state.endPoint &&
      !this.state.pickUpDrop
    ) {
      // Modal.warning({ content: "Select Sequence First", title: "Alert" });
      Modal.warning({ content: this.context.translate("_SELECT_SEQUENCE_FIRST_"), title: this.context.translate("_FLEET_ALERT_")});

    }

    await this.setState({ geofenceInfoObj: record });
    const pointsArray = record.points
      .split(",")
      .map(Number)
      .filter((_, index) => index % 3 !== 2);

    console.log(pointsArray);
    const formattedPoints = [];
    let arrayPoints = [];
    for (let i = 0; i < pointsArray.length; i += 2) {
      if (pointsArray.length === 2) {
        arrayPoints.push({ lat: pointsArray[i], lng: pointsArray[i + 1] });
        formattedPoints.push({
          latLong: arrayPoints,
          radius: Number(record.radius),
          routepointId: Number(record.routepointId),
        });
      } else {
        arrayPoints.push({ lat: pointsArray[i], lng: pointsArray[i + 1] });
        formattedPoints.push({
          latLong: arrayPoints,
          type: Number(record.type),
          routepointId: Number(record.routepointId),
        });
      }
    }
    console.log(arrayPoints);
    await this.setState({ geofenceFormatedPoints: formattedPoints });

    let centerPoint = await this.calculateCenterPoint(formattedPoints);
    console.log(centerPoint);
    await this.setState({
      positionLatLongArray: [
        ...this.state.positionLatLongArray,
        { lat: centerPoint.lat, lng: centerPoint.lng },
      ],
    });

    if (this.state.startPoint) {
      this.setState({ origin: { lat: centerPoint.lat, lng: centerPoint.lng } });
      await this.setState({
        geofencePointsArrayObj: [
          ...this.state.geofencePointsArrayObj,
          { ...formattedPoints[0], sequenceId: 1 },
        ],
      });
    } else if (this.state.endPoint) {
      this.setState({
        destination: { lat: centerPoint.lat, lng: centerPoint.lng },
      });
      await this.setState({
        geofencePointsArrayObj: [
          ...this.state.geofencePointsArrayObj,
          { ...formattedPoints[0], sequenceId: 2 },
        ],
        sequenceId: 2,
      });
    } else if (this.state.pickUpDrop) {
      // remaining part
    }

    //  let centerPnt=  await this.calculateCenterPoint(formattedPoints)
    //  await this.setState({destination:{lat:centerPnt.lat,lng:centerPnt.lng}})
  };

  calculateCenterPoint = (coordinates) => {
    console.log(coordinates);
    if (coordinates && coordinates.length > 0) {
      const latSum = coordinates.reduce(
        (sum, point) => sum + point.latLong[0].lat,
        0
      );
      const lngSum = coordinates.reduce(
        (sum, point) => sum + point.latLong[0].lng,
        0
      );
      const latAvg = latSum / coordinates.length;
      const lngAvg = lngSum / coordinates.length;
      return { lat: latAvg, lng: lngAvg };
    }
    return null;
  };
  handleGeofencePlaceSelect = async (obj) => {
    console.log(obj);
    let placeDetails = obj;
    console.log(this.state.geofenceFormatedPoints);
    let centerPoint = await this.calculateCenterPoint(
      this.state.geofenceFormatedPoints
    );
    console.log(centerPoint);
    await this.setState({
      positionLatLongArray: [
        ...this.state.positionLatLongArray,
        { lat: centerPoint.lat, lng: centerPoint.lng },
      ],
    });

    await this.handleSetPlaces({
      address: placeDetails.address,
      latitude: centerPoint.lat,
      longitude: centerPoint.lng,
    });

    await this.setState({ update: 1 });

    // remaining

    //  let address = await this.assignTripStore.getAddressUsingLatLng(centerPoint.lat,centerPoint.lng)
    //  console.log(address)
  };

  handleHistoryPlaceSelect = (obj) => {
    console.log(obj);
    let placeDetails = obj;
    this.handleSetPlaces({
      address: placeDetails.stopIdleLocation,
      latitude: Number(placeDetails.latitude),
      longitude: Number(placeDetails.longitude),
    });
  };
  handleSetPlaces = async ({ address, latitude, longitude }) => {
    console.log(address, latitude, longitude, this.state.startPoint);
    console.log(this.state.start, this.state.end);
    if (this.state.startPoint) {
      const obj = {
        sequenceId: 1,
        routeZoneId: "ext-gen4321",
        routePoint: "Geofence",
        sequenceNo: "Start Point-" + address,
        address: address,
        lati: latitude,
        longi: longitude,
        arriveTime: null,
        departureTime: dayjs(
          this.formRef.current.getFieldValue("startTime")
        ).format("HH:mm"),
        endTime: null,
        locOption: "Start-Time",
        time: dayjs(this.formRef.current.getFieldValue("startTime")).format(
          "HH:mm"
        ),
      };
      console.log(obj);
      await this.setState({ startObj: obj });
      await this.setState({ update: 1 });
    } else if (this.state.endPoint) {
      const obj = {
        sequenceId: 2,
        routeZoneId: "ext-gen2264",
        routePoint: "Geofence",
        sequenceNo: "End Point-" + address,
        address: address,
        lati: latitude,
        longi: longitude,
        arriveTime: dayjs(this.formRef.current.getFieldValue("endTime")).format(
          "HH:mm"
        ),
        departureTime: null,
        endTime: null,
        time: dayjs(this.formRef.current.getFieldValue("endTime")).format(
          "HH:mm"
        ),
        locOption: "End-Time",
      };
      console.log("hello");
      this.setState({ endObj: obj });
    } else if (this.state.pickUpDrop) {
      await this.setState({ wayPointId: this.state.wayPointId + 1 });
      const obj = {
        routeZoneId: "ext-gen2264",
        routePoint: "Geofence",
        sequenceNo: "PickUp Point-" + address,
        address: address,
        lati: latitude,
        longi: longitude,
        arriveTime: null,
        departureTime: null,
        endTime: null,
        time: "00:00",
        locOption: "Pickup/Drop-Time",
        wayPointId: this.state.wayPointId,
      };

      await this.setState({ pickUpDropObj: obj });
      console.log([
        ...this.state.pointsWay,
        {
          location: { lat: latitude, lng: longitude },
          wayPointId: this.state.wayPointId,
        },
      ]);
      await this.setState({
        pointsWay: [
          ...this.state.pointsWay,
          {
            location: {
              lat: latitude,
              lng: longitude,
            },
            wayPointId: this.state.wayPointId,
          },
        ],
      });

      // this.setState({ pointsWay: [...this.state.pointsWay,{location:{lat:placeDetails.latitude,lng:placeDetails.longitude}}] });
    }

    // await this.setState({ geofencePointsArrayObj: formattedPoints });
    await this.setState({ loadState: 1 });
  };

  render() {
    return (
      <>
        <GoogleMapForDirection
          origin={this.state.origin}
          destination={this.state.destination}
          wayPoints={this.state.pointsWay}
          onPlaceSelected={this.handlePlaceSelected}
          geofencePointsArrayObj={this.state.geofencePointsArrayObj}
          This={this}
          positions={this.state.positionLatLongArray}
        ></GoogleMapForDirection>
        {/* <div className="assign-trip-details-panel"> */}
        {/* <div className="formBorder"> */}
        {/* <div className="headingTitle"> Create/Assign Trip</div> */}
        {/* <Row style={{ padding: "24px" }}> */}

        {this.state.routePopupShow && (
          <Modal
            centered
            className="multiTracking-popup"
            visible={this.state.routePopupShow}
            title=<div>
              <Button onClick={() => this.setState({ routePopupShow: false })}>
                {/* SetRoute */}
                {this.context.translate("_FLEET_DASHBORD_ROUTEADD_SUGGROUTE_SETROUTEBTN_")}
              </Button>{" "}
              <CloseOutlined
                onClick={() => this.setState({ routePopupShow: false })}
              />
            </div>
            bodyStyle={{
              height: "446px",
              //width: "900px",
              padding: "0px",
            }}
            width={1000}
            footer={null}
          >
            {/* Map Container */}
            <div>
              <GoogleMapForDirection
                origin={this.state.origin}
                destination={this.state.destination}
                wayPoints={this.state.pointsWay}
                onPlaceSelected={this.handlePlaceSelected}
                This={this}
                heightWidth={true}
                geofencePointsArrayObj={this.state.geofencePointsArrayObj}
              ></GoogleMapForDirection>
            </div>
          </Modal>
        )}

        <Form
          ref={this.formRef}
          style={{ padding: 0 }}
          layout="vertical"
          onFinish={this.onFinish}
          // {...this.layout}

          name="nest-message"
          initialValues={{
            fromDate: dayjs(),
            toDate: dayjs(),
            validFrom: dayjs(),
            validTo: dayjs(),
            routestartDate: dayjs(),
            routestopDate: dayjs(),
            startTime: dayjs("00:00", "HH:mm"),
            endTime: dayjs("00:00", "HH:mm"),
          }}
        >
          {/* <h1>Create/Assign Trip</h1> */}
          {/* <Spin spinning={this.state.loadingSpinner} fullscreen > */}
          <div style={{ display: "flex" }}>
            <div className="leftWhite-form-panel">
              <Form.Item
                name="customer"
                label={this.context.translate("_FLEET_CUSTOMER_")}
                rules={[{ required: true }]}
                style={{ marginBottom: "8px" }}
                initialValue={"company"}
              >
                <Select onSelect={(val) => this.selectCustomer(val)}>
                  {/* <Select.Option value="school"> School</Select.Option>
                    <Select.Option value="company">Company</Select.Option> */}
                  <Select.Option value="school">
                    {this.context.translate("_SCHOOL_")}
                  </Select.Option>
                  <Select.Option value="company">
                    {this.context.translate("_FLEET_DASHBORD_NEWPOI_COMPANY_")}
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="companyId"
                label={this.context.translate("_ACCOUNT_")}
                rules={[{ required: true }]}
                style={{ marginBottom: "8px" }}
              >
                <CompanyAutocomplete
                  onChange={(val) => this.handleChange(val)}
                  style={{ width: "100%" }}
                  // companyId={this.state.companyId}
                />
              </Form.Item>

              {this.state.companyCustomer && (
                <>
                  <Form.Item
                    name="vehicleId"
                    style={{ marginBottom: "8px" }}
                    label={this.context.translate("_VEHICLE_NO_")}
                    rules={[{ required: true }]}
                  >
                    <Select>
                      {this.assignTripStore?.vehicles?.map((val) => {
                        return (
                          <Select.Option value={Number(val.vehicleId)}>
                            {val.vehicleNo}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </>
              )}

              <div
                style={{
                  display: this.state.studentCustomer ? "" : "none",
                }}
              >
                <Form.Item
                  name="vehicleId"
                  style={{ marginBottom: "8px" }}
                  // label={"Bus No"}
                  label={this.context.translate("_BUSNO_")}
                >
                  <Select>
                    {this.assignTripStore?.vehicles?.map((val) => {
                      return (
                        <Select.Option value={val.vehicleId}>
                          {val.vehicleNo}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>

              <Form.Item
                name="emailId"
                style={{ marginBottom: "8px" }}
                label={this.context.translate("_EMAIL_ID_")}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="Tripcode"
                style={{ marginBottom: "8px" }}
                label={this.context.translate("_TRIP_CODE_")}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="TripName"
                style={{ marginBottom: "8px" }}
                label={this.context.translate("_TRIP_NAME_")}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              {/* <Form.Item name="all" label=" "   style={{ marginBottom: '8px' }}> */}
              <Checkbox
                checked={this.state.routeDeviationAlert}
                onChange={(e) =>
                  this.setState({ routeDeviationAlert: e.target.checked })
                }
                style={{ marginTop: "15px" }}
              >
                {/* Rout Deviation Alert */}
                {this.context.translate("_ROUT_DEVIATION_ALERT_")}
              </Checkbox>
              {/* </Form.Item> */}
              <Button
                className="plusBtn"
                style={{ color: "#1677ff" }}
                icon=<PlusOutlined />
                onClick={() =>
                  this.setState({ addAddress: !this.state.addAddress })
                }
              ></Button>
              {/* {
        title: "Sequence No",
        dataIndex: "sequenceNo",
        key: "sequenceNo",
      },
      {
        title: "PickUp Time",
        dataIndex: "picktime",
        key: "age",
      }, */}
              <div className="location-list-wrapper">
                {this.state.routePoints?.map((val, ind) => {
                  return (
                    <div className="location-list">
                      <span style={{ width: "10%" }}>{Number(ind) + 1}</span>

                      <div className="locationDate" style={{ width: "75%" }}>
                        {/* <p>
                          {val.sequenceNo.includes("Start Point-")
                            ? "Start Point"
                            : "End Point"}
                        </p> */}
                        <b style={{ fontSize: "10px" }}>{val.sequenceNo}</b>
                        <div>
                          {val.locOption === "End-Time" ? (
                            <small>
                              {val.locOption}:
                              {this.formRef.current
                                .getFieldValue("endTime")
                                .format("HH:mm")}
                            </small>
                          ) : (
                            <small>
                              {val.locOption}:{val.time}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* <div style={{ position: "relative", top: "25px" }}> */}
                      <div style={{ width: "10%", marginLeft: "10px" }}>
                        <DeleteConfirm
                          onConfirm={(e) => {
                            e.preventDefault();
                            this.onSequenceTableRecordDelete(val);
                          }}
                        >
                          <Link>
                            <Tooltip
                              // title="Delete Trip Details"
                              title={this.context.translate(
                                "_DELETE_TRIP_DETAILS_"
                              )}
                              color={config.tooltipColorDelete}
                              placement="bottom"
                            >
                              <DeleteFilled
                                style={{ color: config.deleteColor }}
                              />
                            </Tooltip>
                          </Link>
                        </DeleteConfirm>
                      </div>

                      {/* </div> */}
                    </div>
                  );
                })}
              </div>
            </div>

            {this.state.addAddress && (
              <div
                className="leftWhite-form-panel"
                style={{
                  marginLeft: "25px",
                  width: "25%",
                  paddingRight: "0px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "650px",
                    overflowX: "hidden",
                    overflowY: "auto",
                    paddingRight: "10px",
                  }}
                >
                  {/* <label>Search Address</label> */}
                  <div
                    className="add-location"
                    style={{ marginBottom: "10px" }}
                  >
                    <Autocomplete
                      onLoad={(autocomplete) => {
                        autocomplete.setFields([
                          "formatted_address",
                          "geometry",
                        ]); // Adding 'geometry' field
                        this.originRef.current = autocomplete;
                      }}
                      onPlaceChanged={this.placeChange}
                      componentRestrictions={{ country: "us" }}
                      options={{
                        types: ["geocode", "establishment"],
                      }}
                      style={{ width: "100%" }}
                    >
                      {/* <lable>Search Address</lable> */}

                      <input
                        style={{ width: "335px" }}
                        ref={this.autocompleteRef}
                        type="text"
                        value={this.state.addressInputText}
                        onChange={(e) =>
                          this.setState({ addressInputText: e.target.value })
                        }
                        // placeholder="Enter a location"
                        placeholder={this.context.translate("_ENTER_LOCATION_")}
                      />
                    </Autocomplete>
                  </div>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      gap: "10px",
                    }}
                  >
                    {this.state.origin && this.state.endPoint ? (
                      <Button
                        // className="addBlue-btn"
                        type="primary"
                        onClick={async () => {
                          // this.setState({ addressInputText: "" });
                          this.formRef.current.setFieldsValue({
                            endTime: dayjs("00:00", "HH:mm"),
                            geofence: undefined,
                            routePoint: undefined,
                            pointName: undefined,
                            sequence: undefined,
                            startTime: dayjs("00:00", "HH:mm"),
                          });

                          if (this.state.geofence) {
                            console.log("inn");
                            await this.handleGeofencePlaceSelect(
                              this.state.geofenceInfoObj
                            );
                          }
                          if (this.state.origin && this.state.endPoint) {
                            this.setState({ positionLatLongArray: [] });
                          }
                          if (this.state.history) {
                            await this.handleHistoryPlaceSelect(
                              this.state.historyInfoObj
                            );
                          }
                          await this.addSequenceIntoTable();
                          // await this.setState({update:1})

                          // await this.setState({update:1})
                          (
                            this.state.geofence
                              ? this.state.origin &&
                                this.state.endPoint &&
                                this.state.geofence
                              : this.state.origin && this.state.endPoint
                          )
                            ? this.setState({ routePopupShow: true })
                            : //
                              Modal.warning({
                                content:
                                  "Please Select Address and sequence both",
                              });
                        }}
                      >
                        {/* Add */}
                        {this.state.origin && this.state.endPoint
                          ? //
                            this.context.translate("_SUGGEST_ROUTE_")
                          : this.context.translate(
                              "_FLEET_ROLE_ROLELIST_ADDBTN_"
                            )}
                      </Button>
                    ) : (
                      <Button
                        // className="addBlue-btn"
                        type="primary"
                        onClick={async () => {
                          this.formRef.current.setFieldsValue({
                            endTime: dayjs("00:00", "HH:mm"),
                            geofence: undefined,
                            routePoint: undefined,
                            pointName: undefined,
                            sequence: undefined,
                            startTime: dayjs("00:00", "HH:mm"),
                          });

                          if (this.state.geofence) {
                            await this.handleGeofencePlaceSelect(
                              this.state.geofenceInfoObj
                            );
                          }
                          // if (this.state.origin && this.state.endPoint) {
                          //   await this.setState({ positionLatLongArray: [] });
                          // }
                          if (this.state.history) {
                            await this.handleHistoryPlaceSelect(
                              this.state.historyInfoObj
                            );
                          }

                          await this.addSequenceIntoTable();
                          console.log(this.state.startObj);
                          await this.setState({ update: 1 });
                        }}
                      >
                        {/* Add */}
                        {this.state.origin && this.state.endPoint
                          ? this.context.translate("_SUGGEST_ROUTE_")
                          : this.context.translate(
                              "_FLEET_ROLE_ROLELIST_ADDBTN_"
                            )}
                      </Button>
                    )}{" "}
                    <Button
                      onClick={() =>
                        this.setState({
                          clearMapBtn: true,
                          pointsWay: [],
                          positionLatLongArray: [],
                          geofencePointsArrayObj: [],
                        })
                      }
                    >
                      {this.context.translate("_CLEAR_MAP_")}
                      {/* Clear Map */}
                    </Button>
                  </span>
                  {/* <Button>Suggest route</Button> */}
                  <Form.Item
                    name="sequence"
                    style={{ marginBottom: "8px" }}
                    label={this.context.translate("_SEQUENCE_")}
                  >
                    <Select onChange={(val) => this.handleSequenceChange(val)}>
                      {!this.state.origin && (
                        <Select.Option value="startPoint">
                          {/* Start Point */}
                          {this.context.translate("_START_POINT_")}
                        </Select.Option>
                      )}
                      <Select.Option value="endPoint">
                        {this.context.translate("_END_POINT_")}
                      </Select.Option>
                      <Select.Option value="pickup/drop">
                        {/* PickUp/Drop */}
                        {this.context.translate("_PICKUP_DROP_")}
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  {this.state.pickUpDrop && (
                    <>
                      <Form.Item
                        name="travelDistance"
                        style={{ marginBottom: "8px" }}
                        // label={"Travel Distance"}
                        label={this.context.translate("_TRAVEL_DISTANCE_")}
                      >
                        <Input value={this.state.travelDistance}></Input>
                      </Form.Item>
                      <Row>
                        <Col span={11}>
                          <Form.Item
                            name="departureTime"
                            style={{ marginBottom: "8px" }}
                            // label={"Departure Time"}
                            label={this.context.translate("_FLEET_REPORTS_STOPREPORT_DEPARTURETIME_")}
                          >
                            <Input></Input>
                          </Form.Item>
                        </Col>
                        <Col span={2} />
                        <Col span={11}>
                          <Form.Item
                            name="arriveTime"
                            style={{ marginBottom: "8px" }}
                            // label={"Arrive Time"}
                            label={this.context.translate("_FLEET_REPORTS_STOPREPORT_ARRIVALTIME_")}
                          >
                            <Input></Input>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}

                  <Row>
                    <Col span={11}>
                      <Form.Item
                        name="tolerance"
                        style={{ marginBottom: "8px" }}
                        label={this.context.translate("_TOLERANCE_")}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={2} />
                    <Col span={11}>
                      <Form.Item
                        name="routeZone"
                        style={{ marginBottom: "8px" }}
                        label={this.context.translate("_ZONE_")}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="routePoint"
                    style={{ marginBottom: "8px" }}
                    label={this.context.translate("_SELECT_ADDRESS_")}
                  >
                    <Select onSelect={(val) => this.handleAddressChange(val)}>
                      <Select.Option value="geofence">
                        {this.context.translate("_GEOFENCE_")}
                      </Select.Option>
                      <Select.Option value="searchOnMap">
                        {" "}
                        {/* Search On Map{" "} */}
                        {this.context.translate("_SEARCH_ON_MAP_")}
                      </Select.Option>
                      <Select.Option value="history">
                        {this.context.translate("_HISTORY_")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <div style={{ display: this.state.geofence ? "" : "none" }}>
                    <Form.Item
                      name={"geofence"}
                      // label="Select Geofence"
                      label={this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_RULE6_GEOFENCE_"
                      )}
                      style={{ marginBottom: "8px" }}
                    >
                      <Select
                        onSelect={async (val, e) => {
                          await this.handleGeofenceObj(e.obj);
                        }}
                      >
                        {this.assignTripStore?.geoDataArray?.map((val) => {
                          return (
                            <Select.Option
                              key={val.vehicleId}
                              obj={val}
                              value={val.zoneId}
                            >
                              {val.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>

                  <div style={{ display: this.state.history ? "" : "none" }}>
                    <Form.Item
                      style={{ marginBottom: "8px" }}
                      name="vehicleId"
                      label={this.context.translate("_VEHICLE_NO_")}
                    >
                      <Select
                        onSelect={(val) => {
                          this.setState({ vehicleId: val });
                        }}
                      >
                        {this.assignTripStore?.vehicles?.map((val) => {
                          return (
                            <Select.Option value={val.vehicleId}>
                              {val.vehicleNo}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    <Row>
                      <Col span={11}>
                        <Form.Item
                          style={{ marginBottom: "8px" }}
                          // label="From Date"
                          label={this.context.translate("_FROM_DATE_")}
                          name="routestartDate"
                          // style={formItemStyle}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format={"MM-DD-YYYY"}
                            defaultValue={this.state.fromDate}
                            onChange={(val) => {
                              val !== null &&
                                this.setState({
                                  fromDate: val,
                                });
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={11}>
                        <Form.Item
                          style={{ marginBottom: "8px" }}
                          // label="To Date"
                          label={this.context.translate("_TO_DATE_")}
                          name="routestopDate"
                          // style={formItemStyle}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format={"MM-DD-YYYY"}
                            defaultValue={this.state.toDate}
                            onChange={(val) => {
                              val !== null &&
                                this.setState({
                                  toDate: val,
                                });
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={11}>
                        <Form.Item
                          // label="From Time"
                          label={this.context.translate(
                            "_FLEET_RULESELECTORDETAIL_RULE4_FROMTIME_"
                          )}
                          name="fromTime"
                          style={{ marginBottom: "8px" }}
                          // style={formItemStyle}
                        >
                          <Select
                            onChange={this.setFromTime}
                            onSelect={(val) => this.setState({ fromTime: val })}
                            // value={this.state.fromTime}
                            allowClear={true}
                          >
                            {this.timeOptions()}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={11}>
                        <Form.Item
                          // label="To Time"
                          label={this.context.translate(
                            "_FLEET_RULESELECTORDETAIL_RULE4_TOTIME_"
                          )}
                          style={{ marginBottom: "8px" }}
                          name="toTime"
                          // style={formItemStyle}
                        >
                          <Select
                            onSelect={(val) => this.setState({ toTime: val })}
                            onChange={(e) => console.log(e)}
                            // value={this.state.fromTime}
                            allowClear={true}
                          >
                            {this.timeOptions()}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <br />

                    <Button
                      style={{ width: "100%" }}
                      type="primary"
                      onClick={this.LoadClick}
                    >
                      {/* Load */}
                      {this.context.translate("_LOAD_")}
                    </Button>

                    <Form.Item
                      name="stopIdlePoint"
                      // label={"Stop Idle Point"}
                      // label={"Stop / Idle Points"}
                      label={this.context.translate("_STOP_IDLE_POINTS_")}
                    >
                      <Select
                      placeholder={this.context.translate("_PLEASE_SELECT_VEHICLE_")}
                      
                        // placeholder="Select Vehicle"
                        onSelect={async (v, w) => {
                          console.log(v, w.obj);
                          await this.setState({ historyInfoObj: w.obj });
                        }}
                      >
                        {this.assignTripStore.idlePoints?.map((val) => {
                          return (
                            <Select.Option
                              value={val.stopIdleLocation}
                              obj={val}
                            >
                              {val.stopIdleLocation}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                  <Row>
                    <Col span={11}>
                      <Form.Item
                        name="pointName"
                        style={{ marginBottom: "8px" }}
                        label={this.context.translate("_POINT_NAME_")}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={2} />
                    <Col span={11}>
                      <div
                        style={{
                          display: this.state.studentCustomer ? "" : "none",
                        }}
                      >
                        <Form.Item
                          name="NrOfStudent"
                          style={{ marginBottom: "8px" }}
                          // label={"No Of Students"}
                          label={this.context.translate("_NO_OF_STUDENTS_")}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  {/* <Row>
                <Col span={11}>
                <Form.Item
                  name="startTime"
                  style={{ marginBottom: "8px" }}
                  label={"Start Time"}
                >
               <TimePicker defaultValue={dayjs('00:00', 'HH:mm')} format={'HH:mm'} style={{width:"100%"}}/>
                </Form.Item>
                </Col>
                <Col span={2}/>
                <Col span={11}> */}

                  {this.state.startPoint && (
                    <Form.Item
                      name={"startTime"}
                      // label="Start Time"
                      label={this.context.translate("_FLEET_START_TIME_")}
                    >
                      <TimePicker
                        onChange={(v) => console.log(v)}
                        format={"HH:mm"}
                      />
                    </Form.Item>
                  )}

                  {this.state.endPoint && (
                    <Form.Item
                      name={"endTime"}
                      // label="End Time"
                      label={this.context.translate("_END_TIME_")}
                    >
                      <TimePicker format={"HH:mm"} />
                    </Form.Item>
                  )}
                  {/* </Col>
              </Row> */}

                  <Row>
                    <Col span={11}>
                      <Form.Item
                        style={{ marginBottom: "8px" }}
                        name="routestartDate"
                        label={this.context.translate("_VALID_FROM_")}
                        rules={[{ required: true }]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format={"MM-DD-YYYY"}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2} />
                    <Col span={11}>
                      <Form.Item
                        style={{ marginBottom: "8px" }}
                        name="routestopDate"
                        label={this.context.translate("_VALID_TO_")}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format={"MM-DD-YYYY"}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <Form.Item
                    name="dayofweeks"
                    label=""
                    style={{ marginBottom: "20px", marginTop: "20px" }}
                  >
                    <span>{this.context.translate("_SELECT_DAYS_")}</span>
                    <Checkbox.Group
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                      value={this.state.dayofweeks}
                      onChange={(val) => {
                        console.log(val);
                        this.formRef.current.setFieldsValue({
                          dayofweeks: val,
                        });
                        this.setState({ dayofweeks: val });
                      }}
                    >
                      <Row>
                        <Col span={10}>
                          <Checkbox value="1">
                            {this.context.translate("_SUNDAY_")}
                          </Checkbox>
                        </Col>
                        <Col span={10}>
                          <Checkbox value="2">
                            {this.context.translate("_MONDAY_")}
                          </Checkbox>
                        </Col>
                        <Col span={10}>
                          <Checkbox value="3">
                            {this.context.translate("_TUESDAY_")}
                          </Checkbox>
                        </Col>

                        <Col span={10}>
                          <Checkbox value="4">
                            {this.context.translate("_WEDNESDAY_")}
                          </Checkbox>
                        </Col>
                        <Col span={10}>
                          <Checkbox value="5">
                            {this.context.translate("_THURSDAY_")}
                          </Checkbox>
                        </Col>
                        <Col span={10}>
                          <Checkbox value="6">
                            {this.context.translate("_FRIDAY_")}
                          </Checkbox>
                        </Col>

                        <Col span={10}>
                          <Checkbox value="7">
                            {this.context.translate("_SATURDAY_")}
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <br />
                  <br />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                    borderTop: "1px solid rgba(0,0,0,.1)",
                    paddingTop: "20px",
                  }}
                >
                  <Link to={"/assigntriplist"}>
                    <Button style={{ background: "#d9d9d9" }}>
                      {"   "}
                      {/* Back{" "} */}
                      {this.context.translate(
                        "_FLEET_USER_USERLIST_ASSIGNVEHICLE_BACKBTN_"
                      )}
                    </Button>
                  </Link>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={this.state.loading}
                    disabled={this.state.endObj ? false : true}
                  >
                    {/* Save */}
                    {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                  </Button>
                  <Button
                    style={{ background: "#d9d9d9", marginRight: "10px" }}
                    onClick={(e) => {
                      this.formRef.current?.resetFields();
                    }}
                  >
                    {/* Cancel */}
                    {this.context.translate(
                      "_FLEET_DASHBORD_SHOWPOI_CANCELBTN_"
                    )}
                  </Button>
                </div>
              </div>
            )}

            {/* <Form.Item wrapperCol={{ ...this.layout.wrapperCol, offset: 8 }}>
                        <Button htmlType="submit">
                            Save
                        </Button>
                    </Form.Item> */}

            {this.state.addAddress && <></>}
          </div>
          {/* </Spin> */}
        </Form>

        {/* </div> */}
        {/* </div> */}
      </>
    );
  }
}

AssignTripAdd.contextType = StoreContext;
export default observer(withRouter(AssignTripAdd));

// import React, { useState, useEffect } from 'react';
// import {
//   GoogleMap,
//   LoadScript,
//   Marker,
//   Polyline,
//   useLoadScript,
// } from '@react-google-maps/api';
// import { Modal } from 'antd'; // Import Ant Design Modal
// // import 'antd/dist/antd.css'; // Import Ant Design styles
// import conf from '../config';

// const containerStyle = {
//   width: '100%',
//   height: '100vh',
// };

// const center = {
//   lat: 37.7749,
//   lng: -122.4194,
// };

// const Map = () => {
//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: conf.googleMapKey,
//     libraries: ['places'],
//   });

//   const [map, setMap] = useState(null);
//   const [directions, setDirections] = useState(null);
//   const [origin, setOrigin] = useState({ lat: 37.7749, lng: -122.4194 });
//   const [destination, setDestination] = useState({ lat: 37.773972, lng: -122.431297 });
//   const [waypoints, setWaypoints] = useState([
//     { id: 'waypoint1', position: { lat: 37.772585, lng: -122.429504 } },
//     { id: 'waypoint2', position: { lat: 37.771736, lng: -122.431762 } },
//   ]);

//   const [selectedMarker, setSelectedMarker] = useState(null);
//   const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
//   const calculateRoute = () => {
//     if (!window.google.maps || !map) return;

//     const waypointsArray = waypoints.map((waypoint) => ({
//       location: waypoint.position,
//       stopover: true,
//     }));

//     const directionsService = new window.google.maps.DirectionsService();

//     if (destination) {
//       directionsService.route(
//         {
//           origin,
//           destination,
//           waypoints: waypointsArray,
//           travelMode: window.google.maps.TravelMode.DRIVING,
//         },
//         (result, status) => {
//           if (status === window.google.maps.DirectionsStatus.OK) {
//             setDirections(result);
//           } else {
//             console.error(`Directions request failed: ${status}`);
//           }
//         }
//       );
//     } else {
//       // Handle the case where the destination is not present
//       const originAndWaypoints = [origin, ...waypoints.map((waypoint) => waypoint.position)];
//       const dummyDestination = originAndWaypoints[originAndWaypoints.length - 1];

//       directionsService.route(
//         {
//           origin,
//           destination: dummyDestination,
//           waypoints: waypointsArray,
//           travelMode: window.google.maps.TravelMode.DRIVING,
//         },
//         (result, status) => {
//           if (status === window.google.maps.DirectionsStatus.OK) {
//             setDirections(result);
//           } else {
//             console.error(`Directions request failed: ${status}`);
//           }
//         }
//       );
//     }
//   };

//   const onMapLoad = (map) => {
//     setMap(map);
//   };

//   const onMarkerClick = (marker) => {
//     setSelectedMarker(marker);
//     setDeleteModalVisible(true);
//   };
//   const isWaypointMarker = (marker) => {
//     return waypoints.some((waypoint) => waypoint.id === marker.id);
//   };

//   const isOriginOrDestinationMarker = (marker) => {
//     return marker.id === 'origin' || marker.id === 'destination';
//   };

//   const handleDeleteMarker = () => {
//     if (selectedMarker && isWaypointMarker(selectedMarker)) {
//       const updatedWaypoints = waypoints.filter((waypoint) => waypoint.id !== selectedMarker.id);
//       setWaypoints(updatedWaypoints);
//     }
//     setDeleteModalVisible(false);
//   };

//   const handleCancelDeleteMarker = () => {
//     setDeleteModalVisible(false);
//   };

//   const onMarkerDragEnd = (id, position) => {
//     const newPosition = {
//       lat: position.lat instanceof Function ? position.lat() : position.lat,
//       lng: position.lng instanceof Function ? position.lng() : position.lng,
//     };

//     if (id === 'origin') {
//       setOrigin(newPosition);
//     } else if (id === 'destination') {
//       setDestination(newPosition);
//     } else {
//       const updatedWaypoints = waypoints.map((waypoint) =>
//         waypoint.id === id ? { ...waypoint, position: newPosition } : waypoint
//       );
//       setWaypoints(updatedWaypoints);
//     }
//   };

//   useEffect(() => {
//     if (isLoaded && map) {
//       calculateRoute();
//     }
//   }, [isLoaded, map, origin, destination, waypoints]);

//   if (loadError) {
//     return <div>Error loading maps</div>;
//   }

//   if (!isLoaded) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <GoogleMap
//       mapContainerStyle={containerStyle}
//       center={center}
//       zoom={14}
//       onLoad={onMapLoad}
//     >
//       <Marker
//         position={origin}
//         draggable={true}
//         onDragEnd={(e) => onMarkerDragEnd('origin', e.latLng.toJSON())}
//         onClick={() => onMarkerClick({ id: 'origin', position: origin })}
//       />
//       <Marker
//         position={destination}
//         draggable={true}
//         onDragEnd={(e) => onMarkerDragEnd('destination', e.latLng.toJSON())}
//         onClick={() => onMarkerClick({ id: 'destination', position: destination })}
//       />
//       {waypoints.map((waypoint) => (
//         <Marker
//           key={waypoint.id}
//           position={waypoint.position}
//           draggable={true}
//           onDragEnd={(e) => onMarkerDragEnd(waypoint.id, e.latLng.toJSON())}
//           onClick={() => onMarkerClick(waypoint)}
//         />
//       ))}
//       {directions && (
//         <Polyline
//           path={directions.routes[0].overview_path.map((point) => ({
//             lat: point.lat(),
//             lng: point.lng(),
//           }))}
//           options={{
//             strokeColor: '#FF0000',
//             strokeOpacity: 0.8,
//             strokeWeight: 4,
//             geodesic: true,
//           }}
//         />
//       )}

//       <Modal
//         title="Delete Marker"
//         visible={isDeleteModalVisible}
//         onOk={handleDeleteMarker}
//         onCancel={handleCancelDeleteMarker}
//       >
//         <p>Are you sure you want to delete this location?</p>
//       </Modal>
//     </GoogleMap>
//   );
// };

// export default Map;
