import React from "react";
import { Row, Col, Space, Input, Button } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import ListTable from "./ListTable";

import withRouter from "../withRouter";

import CompanyAutocomplete from "./CompanyAutocomplete";

import QueueListStore from "../store/QueueListStore";

class QueueList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.queeueListStore = new QueueListStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      loadData: 1,
      companyId: "",
      searchField: "",
      queueStatus: "",
    };
  }
  async componentDidMount() {

    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
     
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });

      await this.handleSearch();
    }
    this.setState({ pageSize: 50 });

    this.columns = [
      {
        title: this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        width: "15%",
      },
      {
        title: this.context.translate("_DRIVER_NAME_"),
        dataIndex: "name",
        width: "15%",
      },
      {
        title: this.context.translate("_POSITION_"),
        dataIndex: "position2",
        width: "15%",
      },
      {
        title: this.context.translate("_AREA_"),
        dataIndex: "area",
        width: "15%",
      },
      {
        title: this.context.translate("_IN_TIME_"),
        dataIndex: "datetime",
        width: "15%",
      },
    ];
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.queeueListStore.loadData({
      companyId: this.state.companyId,
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      page: this.state.currentPage,
      searchField: this.state.searchField,
      queueStatus: this.state.queueStatus,
    });
    await this.setState({ loadData: 1, loading: false });
  }

  async searchInput(e) {
    const value = e.target.value;
    if (value.length >= 2) {
      this.setState({ loading: true });
      await this.queeueListStore.loadData({
        companyId: this.state.companyId,
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        page: this.state.currentPage,
        searchField: value,
        queueStatus: this.state.queueStatus,
      });
    } else {
      await this.queeueListStore.loadData({
        companyId: this.state.companyId,
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        page: this.state.currentPage,
        searchField: value,
        queueStatus: this.state.queueStatus,
      });
    }
    await this.setState({ loadData: 1, loading: false });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      currentPage: 1,
      pageSize: 50,
    });

    await this.handleSearch();
    await this.setState({ loadList: 1 });
  }

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_QUEUE_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={18}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  style={{ width: 200 }}
                />

                <Input
                  style={{ width: 250 }}
                  placeholder={this.context.translate(
                    "_SEARCH_BY_DRIVER_VEHICLE_"
                  )}
                  onChange={async (e) => {
                    await this.setState({ searchField: e.target.value });
                    await this.searchInput(e);
                  }}
                />
              </Space>
            </Col>
            <Col span={6}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Button
                  style={{ backgroundColor: "#5dc36f" }}
                  onClick={async () => {
                    await this.setState({ queueStatus: 0 });
                    await this.handleSearch();
                  }}
                >
                  All
                </Button>
                {this.queeueListStore?.counts?.AllCount}

                <Button
                  style={{ backgroundColor: "#ffc511" }}
                  onClick={async () => {
                    await this.setState({ queueStatus: 1 });
                    await this.handleSearch();
                  }}
                >
                  In Queue
                </Button>
                {this.queeueListStore?.counts?.QueueCount}

                <Button
                  style={{ backgroundColor: "#85d1fc" }}
                  onClick={async () => {
                    await this.setState({ queueStatus: 2 });
                    await this.handleSearch();
                  }}
                >
                  Waiting Area
                </Button>
                {this.queeueListStore?.counts?.WaitingCount}
              </Space>
            </Col>
          </Row>
          <ListTable
            currentPage={this.state.currentPage}
            total={this.queeueListStore?.total}
            columns={this.columns}
            pageSize={this.state.pageSize}
            dataSource={this.queeueListStore?.queueList}
            loading={this.queeueListStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>
      </>
    );
  }
}
QueueList.contextType = StoreContext;
export default observer(withRouter(QueueList));
