import React from "react";
import {
  Select,
  Checkbox,
  Table,
  Row,
  Col,
  Button,
  Space,
  DatePicker,
  Dropdown,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import config from "../config";
import { DownOutlined } from "@ant-design/icons";
import GeofenceVisitReportStore from "../store/GeofenceVisitReportStore";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import withRouter from "../withRouter";

import VehicleStore from "../store/VehicleStore";
import GeofenceReportStore from "../store/GeofenceReportStore";

import dayjs from "dayjs";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Cookies from "js-cookie";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";

const { Option } = Select;
class GeofenceVisitReportList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.geofenceVisitReportStore = new GeofenceVisitReportStore();
    this.vehicleStore = new VehicleStore();
    this.geofencePOIReportStore = new GeofenceReportStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      selectedVehicles: [],
      selectAllVehicles: false,

      selectedgeofenceData: [],
      selectAllGeofenceData: false,
      data: "",

      companyId: "",
      // fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      // toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      fromDate: dayjs().format("YYYY-MM-DD"),
      toDate: dayjs().format("YYYY-MM-DD"),
      Notcompany: false,
      Notasset: false,
      Notgeofence: false,

      selectedColumns: [
        {
          // title: "Date",
          title: context.translate("_FLEET_REPORTS_DATE_"),
          dataIndex: "date",
          // width: "15%",
          key: "date",
        },
        {
          // title: "Name",
          title: context.translate("_FLEET_DASHBORD_NEWPOI_NAME_"),
          dataIndex: "geofenceName",
          // width: "15%",
          key: "geofenceName",
        },
        {
          // title: "In Time",
          title: context.translate("_FLEET_REPORTS_GEOFENCEREPORT_INTIME_"),
          dataIndex: "inTime",
          //fixed: 'left',
          // width: "15%",
          key: "inTime",
        },
        {
          // title: "Out Time",
          title: context.translate("_FLEET_REPORTS_GEOFENCEREPORT_OUTTIME_"),
          dataIndex: "outTime",
          //fixed: 'left',
          // width: "15%",
          key: "outTime",
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
        },
        // {
        //   label: "CSV",
        //   key: "2",
        // },
        // {
        //   label: "XML",
        //   key: "3",
        // },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            interval: this.state.interval,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            // colList: result.toString(),
            // colList: "vehicleNo,date,geofenceName,visitStatus",
            colList: result.toString(),
            language: null,
            geoZoneId: this.state.selectedgeofenceData,
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.geofenceVisitReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.geofenceVisitReportStore.exportpdf(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }

  async componentDidMount() {
    // await this.geofenceVisitReportStore.loadData({ offset: 0, limit: 50 });

    this.setState({ pageSize: 50 });

    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
    await this.setState({ column: this.state.selectedColumns });

    // this.columns = [
    //   {
    //     title: "Date",
    //     dataIndex: "date",
    //     width: "15%",
    //   },
    //   {
    //     title: "Name",
    //     dataIndex: "geofenceName",
    //     width: "15%",
    //   },
    //   {
    //     title: "In Time",
    //     dataIndex: "inTime",
    //     //fixed: 'left',
    //     width: "15%",
    //   },
    //   {
    //     title: "Out Time",
    //     dataIndex: "outTime",
    //     //fixed: 'left',
    //     width: "15%",
    //   },
    // ];
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });
    await this.setState({
      selectedVehicles: [],
      selectAllVehicles: false,
      selectedgeofenceData: [],
      selectAllGeofenceData: false,
    });

    // const geofenceTableData = this.geofenceVisitReportStore?.geofenceVisitStore.map((items)=>items.tableData);
    // console.log(geofenceTableData);
    // this.setState({data:geofenceTableData})

    await this.vehicleStore.getVehiclesCompanyId(comp?.value);

    await this.geofencePOIReportStore.getGeofenceDataByCompany({
      companyId: comp?.value,
    });
    await this.setState({ loadData: 1 });

    // await this.handleSearch();

    // console.log("data", data);
  }

  async handleSearch() {
    this.setState({ Notasset: false });
    this.setState({ loading: true });
    await this.geofenceVisitReportStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      pageNo: this.state.currentPage,
    });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  // vehicleSelect = async (val) => {
  //   console.log(val);
  //   await this.setState({ selectedVehicles: val });
  // };

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.selectedgeofenceData.length === 0) {
      this.setState({ Notgeofence: true });
    } else {
      this.setState({ Notgeofence: false });
    }
    if (
      this.state.companyId &&
      this.state.selectedVehicles.length > 0 &&
      this.state.selectedgeofenceData.length > 0
    ) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false, Notgeofence: false });
      console.log("click");
      await this.geofenceVisitReportStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
        vehicleId: this.state.selectedVehicles,
        geoZoneId: this.state.selectedgeofenceData,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        pageNo: this.state.currentPage,
      });
      await this.setState({ loadData: 1 });
    }
  };

  handleSelectAllGeofenceData = async (e) => {
    if (this.state.selectAllGeofenceData) {
      const allGeofenceData = this.geofencePOIReportStore?.geofenceData.map(
        (val) => {
          return val.zoneId;
        }
      );
      await this.setState({ selectedgeofenceData: allGeofenceData });
    } else {
      await this.setState({ selectedgeofenceData: [] });
    }
  };

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };
  // onDateTimeChange = (dates) => {
  //   console.log(dates);

  //   if (dates) {
  //     const format = "YYYY-MM-DD";
  //     // const format = "YYYY-MM-DD HH:mm:ss";
  //     this.setState({
  //       fromDate: dates[0].format(format),
  //       toDate: dates[1].format(format),
  //     });
  //   }
  // };

  // expandedRowRender = (x) => {
  //   console.log(x);
  //   const columns = [
  //     {
  //       // title: "Asset No",
  //       title: this.context.translate("_FLEET_VEHICLE_VEHICLELIST_VEHICLENO_"),
  //       dataIndex: "vehicleNo",
  //       key: "vehicleNo",
  //     },
  //     {
  //       // title: "Driver",
  //       title: this.context.translate("_FLEET_VEHICLE_VEHICLELIST_DRIVER_"),
  //       dataIndex: "driverName",
  //       key: "driverName",
  //     },
  //     {
  //       // title: "Start Time",
  //       title: this.context.translate("_FLEET_START_TIME_"),
  //       dataIndex: "startTime",
  //       key: "startTime",
  //     },
  //     {
  //       // title: "End Time",
  //       title: this.context.translate("_END_TIME_"),
  //       dataIndex: "endTime",
  //       key: "endTime",
  //     },
  //     {
  //       // title: "Travel Time",
  //       title: this.context.translate("_TRAVEL_TIME_"),
  //       dataIndex: "travelTime",
  //       key: "travelTime",
  //     },
  //     {
  //       // title: "In Time",
  //       title: this.context.translate("_FLEET_REPORTS_GEOFENCEREPORT_INTIME_"),
  //       dataIndex: "inTime",
  //       key: "inTime",
  //     },
  //     {
  //       // title: "In Time",
  //       title: this.context.translate("_OUT_TIME_"),
  //       dataIndex: "outTime",
  //       key: "outTime",
  //     },
  //     {
  //       // title: "Halt Time",
  //       title: this.context.translate("_HALT_TIME_"),
  //       dataIndex: "elapsedTime",
  //       key: "elapsedTime",
  //     },

  //     // {
  //     //   title: 'Expand',
  //     //   dataIndex: 'expand',
  //     //   key: 'expand',
  //     //   render: (text, record) => (
  //     //     console.log(record)
  //     //   // <>{record}</>

  //     //   ),
  //     // },
  //   ];

  //   return (
  //     <Table
  //       className="geofenceReportTbl"
  //       bordered
  //       columns={columns}
  //       dataSource={x?.tableData}
  //       pagination={false}
  //     />
  //   );
  // };

  render() {
    // this.setState({data:geofenceTableData})

    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const assetInputStyle = this.state.Notasset
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const geofenceInputStyle = this.state.Notgeofence
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed">
            <Col span={12}>
              {/* <div className="heading">Geofence Visit Report</div> */}
              <div className="heading">
                {this.context.translate("_GEOFENCE_VISIT_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.geofenceVisitReportStore?.geofenceVisitStore?.length >
                    0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
                {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles: [],
                        selectAllVehicles: false,
                      })
                    }
                  />
                )}
                <Checkbox
                  checked={this.state.selectAllVehicles}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllVehicles: e.target.checked,
                    });
                    if (this.state.selectAllVehicles) {
                      await this.setState({
                        Notasset: false,
                      });
                    }
                    console.log(e.target.checked);
                    await this.handleAllSelectVehicles();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                {/* <Select
                  mode="multiple"
                  onSearch={this.handleSearch}
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val, Notasset: false });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }
                  }}
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 150 }}
                  // style={assetInputStyle}
                  status={this.state.Notasset ? "error" : false}
                /> */}
                <Select
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }

                    if (val?.length > 1) {
                      console.log(val?.length > 1);
                      this.setState({ showAlertPopup: true });
                    }
                  }}
                  style={{ width: 200 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  mode="multiple"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
                <Checkbox
                  checked={this.state.selectAllGeofenceData}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllGeofenceData: e.target.checked,
                    });
                    if (this.state.selectAllGeofenceData) {
                      await this.setState({
                        Notgeofence: false,
                      });
                    }
                    console.log(e.target.checked);
                    await this.handleSelectAllGeofenceData();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                <Select
                  mode="multiple"
                  onSearch={this.handleSearch}
                  maxTagCount="responsive"
                  value={this.state.selectedgeofenceData}
                  onChange={(val) => {
                    this.setState({
                      selectedgeofenceData: val,
                      Notgeofence: false,
                    });
                    if (
                      this.geofencePOIReportStore?.geofenceData.length >
                      val.length
                    ) {
                      this.setState({ selectAllGeofenceData: false });
                    } else {
                      this.setState({ selectAllGeofenceData: true });
                    }
                  }}
                  fieldNames={{ label: "name", value: "zoneId" }}
                  // placeholder=" Geofence"
                  placeholder={this.context.translate("_GEOFENCE_")}
                  optionFilterProp="children"
                  options={this.geofencePOIReportStore?.geofenceData}
                  style={{ width: 150 }}
                  // style={geofenceInputStyle}
                  status={this.state.Notgeofence ? "error" : false}
                />

                <DatePicker
                  format={"MM-DD-YYYY"}
                  defaultValue={dayjs()}
                  placeholder="From Date"
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  onChange={(date) => {
                    console.log(date);
                    if (date === null) {
                      this.setState({ fromDate: "" });
                    } else {
                      this.setState({ fromDate: date.format("YYYY-MM-DD") });
                    }
                  }}
                />

                <DatePicker
                  format={"MM-DD-YYYY"}
                  defaultValue={dayjs()}
                  placeholder="To Date"
                  onChange={(date) => {
                    console.log(date);
                    if (date === null) {
                      this.setState({ toDate: "" });
                    } else {
                      this.setState({ toDate: date.format("YYYY-MM-DD") });
                    }
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                />
                {/* <RangePicker
                  placeholder={["From Date", "To Date "]}
                  // format="DD-MM-YYYY hh:mmA"
                  format="DD-MM-YYYY"
                  style={{ width: 250 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                /> */}
                <Button
                  type="primary"
                  onClick={() => {
                    this.showData();
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          {/* <ListTable */}
          <ListTable
            // style={{maxHight:"499px"}}
            // bordered
            rowKey="ID"
            // expandable={{
            //   expandedRowRender: (record) => this.expandedRowRender(record), // Pass record as a parameter
            // }}
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.geofenceVisitReportStore?.total}
            columns={this.state.column}
            dataSource={this.geofenceVisitReportStore?.geofenceVisitStore}
            // pagination={{

            //   //  current:this.state.pageNo
            //   total: this.geofenceVisitReportStore?.total || 0,
            // }}
            loading={this.geofenceVisitReportStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>
      </>
    );
  }
}
GeofenceVisitReportList.contextType = StoreContext;
export default observer(withRouter(GeofenceVisitReportList));
