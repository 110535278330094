import React, { useState } from 'react';
import { Form, Input, InputNumber, Popconfirm, Select, Table, Button, Typography, Space, Row, Col, Dropdown } from 'antd';
import { observer } from 'mobx-react';
import { StoreContext, StoreProvider } from '../store/RootStore';
import { Link } from "react-router-dom";
import { DeleteFilled, DeleteOutlined, DownOutlined, EditFilled, EditOutlined, ExportOutlined, PlusOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import PoliceOfficerStore from '../store/PoliceOfficerStore';
import withRouter from '../withRouter';
import DeleteConfirm from './DeleteConfirm';
import config from '../config';
import AddButton from './AddButton';
import CompanyAutocomplete from './CompanyAutocomplete';
import ListTable from './ListTable';

class PoliceOfficerList extends React.Component {
    constructor(props) {
        super(props)

        this.handleTableChange = this.handleTableChange.bind(this);
        this.PoliceOfficerStore = new PoliceOfficerStore()

        this.state = {
            loading: false,
            pageSize: 50,
            currentPage: 1,
            openConfirm: false,
            confirmLoading: false,
            loadList: 0,
            companyId: ""


        }
    }
    onEdit = (record) => {
        console.log(record)
        this.props.navigate("/policeofficeradd/" + record.id)

    }
    async componentDidMount() {
        // await this.PoliceOfficerStore.loadData();
        this.setState({ pageSize: 50 });
        this.columns = [
            {
                title: 'Company',
                dataIndex: 'company',
                width: '15%'
            },
            {
                title: 'Name',
                dataIndex: 'name',
                width: '15%'
            },
            {
                title: 'User Name',
                dataIndex: 'userName',
                width: '15%'
            },
            {
                title: 'Mobile No',
                dataIndex: 'mobileno',
                width: '15%'
            },
            {
                title: 'Email Id',
                dataIndex: 'emailId',
                width: '15%',

            },
            {
                title: 'Department',
                dataIndex: 'department',
                width: '15%',

            }, {
                title: 'Designation',
                dataIndex: 'designation',
                width: '15%',

            },
            {
                title: 'Operation',
                dataIndex: '',
                width: '15%',
                render: (_, record) => {
                    return (
                        <>
                            <Space>
                                <Link onClick={(e) => { e.preventDefault(); this.onEdit(record) }}><EditFilled /></Link>
                                <DeleteConfirm

                                    onConfirm={(e) => { e.preventDefault(); this.onDelete(record) }}

                                >
                                    <Link><DeleteFilled style={{ color: config.deleteColor }} /></Link>
                                </DeleteConfirm>
                            </Space>
                        </>
                    )
                }

            },

        ];


    }

    async handleTableChange(pagination, filters, sorter) {
        console.log(pagination, "pagi");
        await this.setState({ currentPage: pagination.current, pageSize: pagination.pageSize });
        this.handleSearch();

    }
    async handleChange(comp) {
        await this.setState({ companyId: comp.value, loadList: 1 });
        await this.handleSearch();

    };


    async handleSearch() {
        await this.PoliceOfficerStore.loadData({
            offset: ((this.state.currentPage - 1) * this.state.pageSize),
            limit: this.state.pageSize,
            searchField: this.state.searchField,
            companyId: this.state.companyId,

        });
        await this.setState({ loadList: 0 })
    }

    render() {
        return (
            <div style={{padding:"24px"}}>
                <div className='heading'>Police Officers List</div>
                <Row style={{ padding: "10px 0px" }}>
                    <Col span={16}>
                        <Space>

                            <Input placeholder="Search by Police Officer Name,Mobile No,UserName"
                                style={{ width: 500 }}
                                onChange={(e) => { this.setState({ searchField: e.target.value }) }}
                            />
                            <Button icon={<SearchOutlined />} type="primary" onClick={() => { this.handleSearch() }} />

                            <CompanyAutocomplete
                                onChange={(value) => this.handleChange(value)}
                                allowClear={true}
                                style={{ width: 200 }}
                            />
                        </Space>
                    </Col>
                    <Col span={8}>
                        <Space style={{ float: this.context.locale.direction == "ltr" ? "right" : "left" }}>
                            <AddButton title={this.context.translate('_ADD_')} link="/policeofficeradd" />

                        </Space>
                    </Col>
                </Row>
                
                <Form form={this.form} component={false}> 
                   <Table
                        bordered
                        dataSource={this.PoliceOfficerStore?.policeOfficers}
                        columns={this.columns}
                        pagination={{
                            current: this.state.currentPage,
                            pageSize: this.state.pageSize,
                            total: this.PoliceOfficerStore?.total
                        }}
                        loading={this.PoliceOfficerStore?.loading}
                        onChange={this.handleTableChange}
                    />
                    </Form>

                {/* <ListTable
                    currentPage={this.state.currentPage}
                    pageSize={this.state.pageSize}
                    total={this.PoliceOfficerStore?.total}
                    columns={this.columns}
                    dataSource={this.PoliceOfficerStore?.policeOfficers}
                    loading={this.PoliceOfficerStore?.loading}
                    onChange={this.handleTableChange}
                /> */}

            </div>
        );
    }
};
PoliceOfficerList.contextType = StoreContext;
export default observer(withRouter(PoliceOfficerList));
