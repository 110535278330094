import React, { memo, useState } from "react";
import { GoogleMap, LoadScript, Polyline } from "@react-google-maps/api";
import {
  useLoadScript,
  Marker,
  Autocomplete,
  DirectionsRenderer,
  Polygon,
  Circle,
} from "@react-google-maps/api";
import { Button, Input, Modal, Space } from "antd";
import { useRef, useEffect } from "react";
import L from "leaflet";
import conf from "../config";
import dayjs from "dayjs";
import { AlertOutlined } from "@ant-design/icons";
// import { Polygon } from 'react-leaflet';
// import { useLoadScript } from '@react-google-maps/api';
const containerStyle = {
  width: "100%",
  height: "100%",
  // position:"fixed !important",
};

const center = {
  lat: conf.mapCenter[0],
  lng: conf.mapCenter[1],
};

const GoogleMapForDirection = ({
  onPlaceSelected,
  origin,
  destination,
  positions,
  getDistance,
  distanceBoolean,
  wayPoints,
  This,
  heightWidth,
  geofencePointsArrayObj,

 // googleMapRef
}) => {
  // Your existing component code here
  const originRef = useRef();
  const google = window.google;
  const googleMapRef = useRef();
  const destinationRef = useRef();
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionResponse, setDirectonResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [grownPolygonPoints, setGrownPolygonPoints] = useState([]);

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: conf.googleMapKey, // Replace with your API key
    libraries: ["places"],
  });

  const calculateRoute = async () => {
    console.log("hellk", wayPoints);
    const waypointsArray = wayPoints?.map((waypoint) => ({
      location: waypoint.location,
      stopover: true,
    }));
    console.log(waypointsArray)
  


  const directionsService = new window.google.maps.DirectionsService();

  if (destination) {
    directionsService.route(
      {
        origin,
        destination,
        waypoints: waypointsArray,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        
       /* const routePath = result.routes[0].overview_path;
        const distanceToGrow = 1000; // Distance to grow in meters
        let totalDistance = 0;
        let grownPolygonPoints = [];

        for (let i = 0; i < routePath.length - 1; i++) {
          const from = routePath[i];
          const to = routePath[i + 1];
          const segmentDistance = window.google.maps.geometry.spherical.computeDistanceBetween(from, to);
          totalDistance += segmentDistance;

          if (totalDistance < distanceToGrow) {
            grownPolygonPoints.push(from);
          } else {
            const bearing = window.google.maps.geometry.spherical.computeHeading(from, to);
            const offset = distanceToGrow - (totalDistance - segmentDistance);
            const newPoint = window.google.maps.geometry.spherical.computeOffset(from, offset, bearing);
            grownPolygonPoints.push(newPoint);
            //break; // Stop growing the polygon once distanceToGrow is reached
          }
        }
        setGrownPolygonPoints(grownPolygonPoints);*/

        /*if (result && result.routes && result.routes.length > 0) {

          const overviewPath = result.routes[0].overview_path;

          const distanceToGrow = 0; // Distance to grow in meters

          const grownPoints = overviewPath.map((point, index) => {
            if (index === 0) {
              return point;
            } else {
              const previousPoint = overviewPath[index - 1];
              const distance = window.google.maps.geometry.spherical.computeDistanceBetween(previousPoint, point);
    
              if (distance >= distanceToGrow) {
                const bearing = window.google.maps.geometry.spherical.computeHeading(previousPoint, point);
                const newPoint = window.google.maps.geometry.spherical.computeOffset(point, distanceToGrow, bearing);
                return newPoint;
              } else {
                return point;
              }
            }
          });


          setGrownPolygonPoints(grownPoints);
        }*/

       

        if (status === window.google.maps.DirectionsStatus.OK) {
          if(origin){
            setDirectonResponse(result);
          }else{
            console.log("1")
            setDirectonResponse(null)
          }
          console.log(result.routes[0].legs[0].distance.text,"dist")
       setDistance(result.routes[0].legs[0].distance.text);
      setDuration(result.routes[0].legs[0].duration.text);

      if (This) {
        const totalSeconds = result.routes[0].legs[0].duration.value;

        const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const timeObject = dayjs().set("hour", hours).set("minute", minutes);

        const additionalDurationString = dayjs(
          This.formRef.current.getFieldValue("startTime")
        ).format("HH:mm");
        const [additionalHours, additionalMinutes] =
          additionalDurationString.split(":");
        const additionalDuration = dayjs.duration({
          hours: parseInt(additionalHours, 10),
          minutes: parseInt(additionalMinutes, 10),
        });

        // Add the additional time duration
        const updatedTimeObject = timeObject.add(additionalDuration);

        // Format the result as HH:mm
        const formattedTime = dayjs(updatedTimeObject, "HH:mm");
        This.setState({ endTimeShowingTbl: formattedTime.format("HH:mm") });
        This.formRef.current.setFieldsValue({ endTime: formattedTime });
      }
       
      if (distanceBoolean) {
        getDistance({
          distance: parseFloat(
            result.routes[0].legs[0].distance.value / 1000
          ).toFixed(2),
        });
      }

        } else {
          console.error(`Directions request failed: ${status}`);
        }
      }
    );
  } else {
    // Handle the case where the destination is not present
    console.log("cal")
    const originAndWaypoints = [origin, ...wayPoints.map((waypoint) => waypoint.location)];
    const dummyDestination = originAndWaypoints[originAndWaypoints.length - 1];

    directionsService.route(
      {
        origin,
        destination: dummyDestination,
        waypoints: waypointsArray,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          if(origin && (destination ||wayPoints) ){
            setDirectonResponse(result);
            console.log(result.routes[0].legs[0].duration.value,"dist")
            This.formRef.current.setFieldsValue({travelDistance:(Number(result.routes[0].legs[0].distance.value)/1000).toFixed(2)})
            const seconds =result.routes[0].legs[0].duration.value
            const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0')
  console.log(formattedHours,formattedMinutes)
  
  This.formRef.current.setFieldsValue({departureTime:`${formattedHours}:${formattedMinutes}`,arriveTime:`${formattedHours}:${formattedMinutes}`})
          }else{
            console.log("2")
            setDirectonResponse(null)
          }
         



        } else {
          console.error(`Directions request failed: ${status}`);
        }
      }
    );
  }
};
  // useEffect(() => {
  //   console.log(This.state.positionLatLongArray, "call");

  // if(This.state.positionLatLongArray.length>0){
  //   let arr= This.state.positionLatLongArray
  //   let length =This.state.positionLatLongArray.length
  //   const bounds = new window.google.maps.LatLngBounds();

  //     bounds.extend(new window.google.maps.LatLng(arr[length-1].lat, arr[length-1].lng));
  //     console.log(bounds)
  //   map.fitBounds(bounds);
  // }

  // }, [This.state.positionLatLongArray,map]);

  useEffect(() => {
    if (geofencePointsArrayObj && geofencePointsArrayObj.length > 0 && map) {
      const bounds = new window.google.maps.LatLngBounds();

      // Extend bounds based on route coordinates
      if (directionResponse) {
        const routePath = directionResponse.routes[0].overview_path;
        routePath.forEach((point) => {
          bounds.extend(
            new window.google.maps.LatLng(point.lat(), point.lng())
          );
        });
      }
      console.log(geofencePointsArrayObj);

      // Extend bounds based on geofence coordinates
      geofencePointsArrayObj.forEach((point) => {
        bounds.extend(
          new window.google.maps.LatLng(
            point.latLong[0].lat,
            point.latLong[0].lng
          )
        );
      });
      // console.log(geofencePointsArrayObj,)
      // const geofenceCenter = calculateCenterPoint(geofencePointsArrayObj);
      // console.log("Geofence Center:", geofenceCenter,geofencePointsArrayObj);

      map.fitBounds(bounds);
    }
  }, [geofencePointsArrayObj, directionResponse, map]);


  
  useEffect(() => {
    // This will be triggered whenever origin or destination changes
   
  if(This){
    if (This?.state.clearMapBtn) {
      console.log("clear");

      setDirectonResponse(null);
      This.setState({
        origin: "",
        destination: "",
        routePoints: [],
        clearMapBtn: false,
      });

    
    } else  {
      calculateRoute();
      if(This.state.routePoints?.length===1){
        setDirectonResponse(null)
      }
    }
  }
  }, [origin, destination, wayPoints]);


 
  if (!isLoaded) {
    return <div></div>;
  }

  // if(origin && destination){
  // getDistance({distance:distance})

  // }

  const clearMap = () => {
    console.log("clr map");
    setDistance("");
    setDuration("");
    originRef.current.value = "";
    // destinationRef.current.value = "";
    setDirectonResponse(null);
  };
  // This& This.state.clearMapBtn && clearMap()


  const onMarkerDragEnd = async(id, position) => {
    const newPosition = {
      lat: position.lat instanceof Function ? position.lat() : position.lat,
      lng: position.lng instanceof Function ? position.lng() : position.lng,
    };

    if (id === 'origin') {
      This.setState({origin:newPosition});
      This.setState({selectedItemId:"1"});
    } else if (id === 'destination') {
      This.setState({destination:newPosition});
      // setDestination(newPosition);
      This.setState({selectedItemId:  String(This.state.dragItems.length) });
    } else {
      const updatedWaypoints = wayPoints.map((waypoint) =>
        waypoint.wayPointId === id ? { ...waypoint, location: newPosition } : waypoint
      );
      // setWaypoints(updatedWaypoints);
      This.setState({pointsWay:updatedWaypoints,selectedItemId:id});
    }

    let address = await This.assignTripStore.getAddressUsingLatLng(position.lat,position.lng);
    console.log(address)

    const addressObject = {
      formattedAddress: address,
      latitude: position.lat instanceof Function ? position.lat() : position.lat,
      longitude: position.lng instanceof Function ? position.lng() : position.lng
    };

    This.handlePointsAddressChange(addressObject);

  };
  const isWaypointMarker = (marker) => {
    return wayPoints.some((waypoint) => waypoint.wayPointId === marker.wayPointId);
  };
  
  const onMarkerClick = (marker) => {
    setSelectedMarker(marker);
    setDeleteModalVisible(true);
  };



  const handleDeleteMarker =async () => {
   
    if(This.state.geofencePointsArrayObj?.length>0){
      console.log(This.state.geofencePointsArrayObj)
      let filterGeofence =await This.state.geofencePointsArrayObj?.filter((val) => {
        return selectedMarker.sequenceId!==Number(  val.sequenceId);
      });
      console.log(filterGeofence)
     await This.setState({geofencePointsArrayObj:filterGeofence})
    }

    if (selectedMarker && isWaypointMarker(selectedMarker)) {
      const updatedWaypoints =await wayPoints.filter((waypoint) => waypoint.wayPointId !== selectedMarker.wayPointId);
      if(This.state.routePoints?.length<2){
        setDirectonResponse(null)
      }
     
      // setWaypoints(updatedWaypoints);
      console.log(This.state.routePoints)
     await This.setState({pointsWay:updatedWaypoints})
     if(This.state.geofencePointsArrayObj?.length>0){
      let filterGeofence =await This.state.geofencePointsArrayObj?.filter((val) => {
        return selectedMarker.sequenceId!==Number(  val.sequenceId);
      });
     await This.setState({geofencePointsArrayObj:filterGeofence})

    }
    let filterRecords =await This.state.routePoints?.filter((val)=>Number(  val.wayPointId) !== selectedMarker.wayPointId)
    await This.setState({routePoints:filterRecords})
    
    }else if(selectedMarker.sequenceId===1){
      This.setState({origin:null})
      let filterRecords =await This.state.routePoints?.filter((val)=>Number(  val.sequenceId) !== selectedMarker.sequenceId)
     await This.setState({routePoints:filterRecords})
      console.log(This.state.routePoints?.length)
      if(This.state.routePoints?.length===1){
        setDirectonResponse(null)
      }
     
    }else if(selectedMarker.sequenceId ===2){
      This.setState({destination:null})
      let filterRecords = This.state.routePoints?.filter((val)=>Number(  val.sequenceId) !== selectedMarker.sequenceId)
     await This.setState({routePoints:filterRecords})
      if(This.state.routePoints?.length<1){
        setDirectonResponse(null)
      }
    }

    if(This.state.routePoints?.length===1){
      setDirectonResponse(null)
    }
    setDeleteModalVisible(false);
  };
  



  const handleCancelDeleteMarker = () => {
    setDeleteModalVisible(false);
  };
  return (
    <>
      <div
        style={{
          position: "absolute",
          height: heightWidth ? "100%" : "100vh",
          width: heightWidth ? "100%" : "94vw",
        }}
      >
        <GoogleMap
          onClick={(e) => console.log(e)}
          
          mapContainerStyle={containerStyle}
          // options={{ gestureHandling: 'none'}}
          ref={googleMapRef}
          center={This?.state.positionLatLongArray?.length > 0 ? This.state.positionLatLongArray[This.state.positionLatLongArray.length - 1]: { lat: conf.mapCenter[0], lng: conf.mapCenter[1] }
          }
          zoom={This?.state.zoomLevel}
          onLoad={(map) =>{
            setMap(map)}
          }
          // options={{
          //   zoomControl: false,
          //  streetViewControl: false,
          //  mapTypeControl: false,
          //  zoomControlOptions: true,
          //  scrollwheel: true,

          //  scaleControl: true,
          // //  gestureHandling: "greedy"      
          //     }}
          options={{
            
            zoomControl: true,
            streetViewControl: true,
            mapTypeControl: true,
            zoomControlOptions: true,
    //         zoomControlOptions: {
    //   position: google.maps.ControlPosition.LEFT_CENTER,
    // },
            scrollwheel: true,
            scaleControl: true,
            mapTypeControlOptions: {
              style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: google.maps.ControlPosition.RIGHT_TOP,
            },
            // fullscreenControl: true,
            
          }}
        >
        
          {/* {origin && destination && calculateRoute} */}
          {/* {This.state.routePopupShow && directionResponse && (
            <DirectionsRenderer directions={directionResponse} />
          )} */}

   { directionResponse && (
        <Polyline
          path={directionResponse.routes[0].overview_path.map((point) => ({
            lat: point.lat(),
            lng: point.lng(),
          }))}
          options={{
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 4,
            geodesic: true
          }}
        />
      )} 
 { grownPolygonPoints.length > 0 && (
         <Polygon
            paths={grownPolygonPoints.map((point) => ({ lat: point.lat(), lng: point.lng() }))}
            options={{
              fillColor: '#FF0000',
              fillOpacity: 0.35,
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
            }}
          />
 )}

          {This?.state.geofencePointsArrayObj?.map((val) =>
            val.radius ? (
              <Circle
                center={{ lat: val.latLong[0].lat, lng: val.latLong[0].lng }}
                radius={Number(val.radius)}
                options={{
                  fillColor: "#0000FF",
                  fillOpacity: 0.2,
                  strokeColor: "#0000FF",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              ></Circle>
            ) : (
              <Polygon
                paths={val.latLong}
                options={{
                  fillColor: "#0000FF",
                  fillOpacity: 0.2,
                  strokeColor: "#0000FF",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              ></Polygon>
            )
          )}

{This?.state.origin && (
          <Marker
        position={This?.state.origin}
        draggable={true}
        onDragEnd={(e) => onMarkerDragEnd('origin', e.latLng.toJSON())}
        onClick={() => onMarkerClick({ sequenceId: 1, position: origin })}
      />)}

{This?.state.destination && (
      <Marker
        position={This?.state.destination}
        draggable={true}
        onDragEnd={(e) => onMarkerDragEnd('destination', e.latLng.toJSON())}
        onClick={() => onMarkerClick({ sequenceId:2, position: destination })}
      />)}

  {This?.state.pointsWay?.map((waypoint,ind) => (
        <Marker
          key={waypoint.ind}
          position={waypoint.location}
          draggable={true}
          onDragEnd={(e) => onMarkerDragEnd(waypoint.wayPointId, e.latLng.toJSON())}
          onClick={() => onMarkerClick(waypoint)}
        />
      ))}
          {/* {This.state.positionLatLongArray.map((v) => (
            <Marker position={v} />
          ))} */}


          <Modal
        title=<div> Remove Marker </div>
        visible={isDeleteModalVisible}
        onOk={handleDeleteMarker}
        onCancel={handleCancelDeleteMarker}
      >
        <p>are you want to sure remove?</p>
      </Modal>

        </GoogleMap>
      </div>
    </>
  );
};

export default memo(GoogleMapForDirection);





// import React, { useState, useEffect } from 'react';
// import {
//   GoogleMap,
//   LoadScript,DirectionsRenderer,
//   Marker,
//   Polyline,
//   useLoadScript,
// } from '@react-google-maps/api';
// import { Modal } from 'antd'; // Import Ant Design Modal
// // import 'antd/dist/antd.css'; // Import Ant Design styles
// import conf from '../config';

// const containerStyle = {
//   width: '100%',
//   height: '100vh',
// };

// const center = {
//   lat: 37.7749,
//   lng: -122.4194,
// };

// const Map = () => {
//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: conf.googleMapKey,
//     libraries: ['places'],
//   });
//   const google = window.google;
//   const [map, setMap] = useState(null);
//   const [directions, setDirections] = useState(null);
//   const [origin, setOrigin] = useState({ lat: 37.7749, lng: -122.4194 });
//   const [destination, setDestination] = useState({ lat: 37.773972, lng: -122.431297 });
//   const [waypoints, setWaypoints] = useState([
//     { id: 'waypoint1', position: { lat: 37.772585, lng: -122.429504 } },
//     { id: 'waypoint2', position: { lat: 37.771736, lng: -122.431762 } },
//   ]);

//   const [selectedMarker, setSelectedMarker] = useState(null);
//   const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
//   const calculateRoute = () => {
//     if (!window.google.maps || !map) return;
  
//     const waypointsArray = waypoints.map((waypoint) => ({
//       location: waypoint.position,
//       stopover: true,
//     }));
  
//     const directionsService = new window.google.maps.DirectionsService();
//     const DirectionsRenderer = new google.maps.DirectionsRenderer({
//       draggable: true,
//       map,
//       panel: document.getElementById("panel"),
//     });
  
//     if (destination) {
//       directionsService.route(
//         {
//           origin,
//           destination,  
//           waypoints: waypointsArray,
//           travelMode: window.google.maps.TravelMode.DRIVING,
//         },
//         (result, status) => {
//           if (status === window.google.maps.DirectionsStatus.OK) {
//             setDirections(result);
//           } else {
//             console.error(`Directions request failed: ${status}`);
//           }
//         }
//       );
//     } else {
//       // Handle the case where the destination is not present
//       const originAndWaypoints = [origin, ...waypoints.map((waypoint) => waypoint.position)];
//       const dummyDestination = originAndWaypoints[originAndWaypoints.length - 1];
  
//       directionsService.route(
//         {
//           origin, 
//           destination: dummyDestination,
//           waypoints: waypointsArray,
//           travelMode: window.google.maps.TravelMode.DRIVING,
//         },
//         (result, status) => {
//           if (status === window.google.maps.DirectionsStatus.OK) {
//             setDirections(result);
//           } else {
//             console.error(`Directions request failed: ${status}`);
//           }
//         }
//       );
//     }
//   };
  

//   const onMapLoad = (map) => {
//     setMap(map);
//   };

//   const onMarkerClick = (marker) => {
//     setSelectedMarker(marker);
//     setDeleteModalVisible(true);
//   };
//   const isWaypointMarker = (marker) => {
//     return waypoints.some((waypoint) => waypoint.id === marker.id);
//   };

//   const isOriginOrDestinationMarker = (marker) => {
//     return marker.id === 'origin' || marker.id === 'destination';
//   };

//   const handleDeleteMarker = () => {
//     if (selectedMarker && isOriginOrDestinationMarker(selectedMarker)) {
//       const updatedWaypoints = waypoints.filter((waypoint) => waypoint.id !== selectedMarker.id);
//       setWaypoints(updatedWaypoints);
//     }
//     setDeleteModalVisible(false);
//   };
  



//   const handleCancelDeleteMarker = () => {
//     setDeleteModalVisible(false);
//   };

//   const onMarkerDragEnd = (id, position) => {
//     const newPosition = {
//       lat: position.lat instanceof Function ? position.lat() : position.lat,
//       lng: position.lng instanceof Function ? position.lng() : position.lng,
//     };

//     if (id === 'origin') {
//       setOrigin(newPosition);
//     } else if (id === 'destination') {
//       setDestination(newPosition);
//     } else {
//       const updatedWaypoints = waypoints.map((waypoint) =>
//         waypoint.id === id ? { ...waypoint, position: newPosition } : waypoint
//       );
//       setWaypoints(updatedWaypoints);
//     }
//   };

//   useEffect(() => {
//     if (isLoaded && map) {
//       calculateRoute();
//     }
//   }, [isLoaded, map, origin, destination, waypoints]);

//   if (loadError) {
//     return <div>Error loading maps</div>;
//   }

//   if (!isLoaded) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <GoogleMap
//       mapContainerStyle={containerStyle}
//       center={center}
//       zoom={14}
//       onLoad={onMapLoad}
//     >
//       <Marker
//         position={origin}
//         draggable={true}
//         onDragEnd={(e) => onMarkerDragEnd('origin', e.latLng.toJSON())}
//         onClick={() => onMarkerClick({ id: 'origin', position: origin })}
//       />
//       <Marker
//         position={destination}
//         draggable={true}
//         onDragEnd={(e) => onMarkerDragEnd('destination', e.latLng.toJSON())}
//         onClick={() => onMarkerClick({ id: 'destination', position: destination })}
//       />
//       {waypoints.map((waypoint) => (
//         <Marker
//           key={waypoint.id}
//           position={waypoint.position}
//           draggable={true}
//           onDragEnd={(e) => onMarkerDragEnd(waypoint.id, e.latLng.toJSON())}
//           onClick={() => onMarkerClick(waypoint)}
//         />
//       ))}
//       {/* {directions && (
//         <Polyline
//           path={directions.routes[0].overview_path.map((point) => ({
//             lat: point.lat(),
//             lng: point.lng(),
//           }))}
//           options={{
//             strokeColor: '#FF0000',
//             strokeOpacity: 0.8,
//             strokeWeight: 4,
//             geodesic: true,
//           }}
//         />
//       )} */}


//       {directions && (
//             <DirectionsRenderer options={{draggable:true}} directions={directions} />
//           )}


//       <Modal
//         title="Delete Marker"
//         visible={isDeleteModalVisible}
//         onOk={handleDeleteMarker}
//         onCancel={handleCancelDeleteMarker}
//       >
//         <p>Are you sure you want to delete this location?</p>
//       </Modal>
//     </GoogleMap>
//   );
// };

// export default Map;
