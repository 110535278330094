import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class WareHouseStore {
  wareHouse = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      wareHouse: observable,
      total: observable,
      loading: observable,
      loadData: action,
      addData: action,
      getWareHouse: action,
      deleteRecord: action,
      addWarehouse: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit }) {
    // this.loading = true;
    console.log(companyId, "compid");

    var postData = { companyId: companyId, offset: offset, limit: limit };
    // var postData = { "offset": 0, "limit": 50 }
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=warehouse&a=warehouselist", "postData=" + base64Input);
    console.log("warehouse data ", response.data.data);
    // console.log("company Data",response?.data?.data?.companydata)
    this.wareHouse = response?.data?.data?.warehousedata;

    this.total = parseInt(response?.data?.data?.TotalCount);
    console.log(parseInt(response?.data?.data?.TotalCount))
    // this.loading=false;
  }
  async addData(wareHouseDetails) {
    console.log(wareHouseDetails);
    var postData = wareHouseDetails;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=warehouse&a=addwarehouse", "postData=" + base64Input);
    console.log(response);
    return response.data;
  }

  // async getWareHouse(companyId) {
  //   console.log("companyId", companyId)
  // }

  async deleteRecord({ warehouseId, companyId }) {
    var postData = { warehouseId: warehouseId, companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=warehouse&a=deletewarehouseNew",
        "postData=" + base64Input
      );
    return response;
  }
  async getWareHouse(wid) {
    console.log("wid", wid);
    var postData = { wid: wid, limit: 1, offset: 0 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=warehouse&a=getWarehouseByCompany",
        "postData=" + base64Input
      );
    console.log(response);
    return response?.data?.data[0];
  }

  async addWarehouse(warehouse) {
    console.log(warehouse);
    var postData = warehouse;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=wasl&a=registerWarehouse", "postData=" + base64Input);
    console.log(response);
    return response.data;
  }
}

export default WareHouseStore;
