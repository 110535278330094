import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi, baseURL } from "../components/Utility";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from "mobx";
import { act } from "react-dom/test-utils";

class FleetDaySummaryReportStore {
  fleetDaySummaryReport = [];
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      fleetDaySummaryReport: observable,
      loading: observable,
      // total : action,
      loadData: action,
      exportExcel: action,
      exportpdf: action,
      exportcsv: action,
    //   exportxml: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit, vehicleId, fromDate, toDate,pageNo }) {
    this.loading = true;
    // console.log(offset,limit)
    console.log("vehicle list");
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      offset: offset,
      limit: limit,
      page: pageNo,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=daysummary", "postData=" + base64Input);

    // console.log(response?.data?.data?.TotalCount)
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.fleetDaySummaryReport = response?.data?.data?.daysummary;
    console.log(response?.data?.data?.daysummary);
    this.loading = false;
  }

  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    let distanceInt = 0;
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportdaysummaryexcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportdaysummarypdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportdaysummarycsv&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
//   async exportxml(getData) {
//     let lang = "english";
//     window.location.href =
//       baseURL +
//       "index.php?c=vehicle&a=exportdaysummaryxml&cid=" +
//       getData.companyId +
//       "&userId=" +
//       getData.userId +
//       "&vid=" +
//       getData.vehicleId +
//       // "&int="+
//       // getData.interval+
//       "&fD=" +
//       getData.fromDate +
//       "&tD=" +
//       getData.toDate +
//       "&colList=" +
//       getData.colList +
//       "&language=" +
//       lang;
//   }
}

export default FleetDaySummaryReportStore;
