import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi,baseURL } from '../components/Utility'
import {
    action,
    computed,
    makeObservable,
    observable,
    autorun,
    runInAction,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class TempReportStore {
    total=0
    tempReportStore = []
    rootStore
    loading=false

    constructor(rootStore) {
        makeObservable(this, {
            total:observable,
            tempReportStore: observable,
            loadData: action,
            loading:observable

        });
        this.rootStore = rootStore;

    }



    async loadData({companyId,vehicleId,offset,limit,fromDate,toDate,pageNo}) {
      console.log(pageNo)
       this.loading=true
        console.log("vehicle list")
        var postData = { "companyId": companyId, "vehicleId": vehicleId, "temp1min": "", "temp1max": "", "temp2min": "", "temp2max": "", "temp3min": "", "temp3max": "", "intervel": 5, "fromDate": fromDate, "showFlag": 0, "toDate": toDate, "offset": offset, "limit": limit, "page": pageNo }
        const base64Input = await base64_encode(JSON.stringify(postData));
        var response = await postApi.getClient().post("index.php?c=vehicle&a=temprep", "postData=" + base64Input);

        console.log(response?.data?.data)
        this.tempReportStore = response?.data?.data?.temprep
        this.total=parseInt(response?.data?.data?.TotalCount)
        this.loading=false

    }
 
    async exportExcel(getData) {
        console.log("getData", getData);
        let lang = "english";
        window.location.href =
          baseURL +
          "index.php?c=vehicle&a=exporttempexcel&cid=" +
          getData.companyId +
          "&userId=" +
          getData.userId+
          "&vid=" +
          getData.vehicleId +
          "&int=" +
         5 +
          "&t1max" +
          " "+
          "&t1min" +
          " "+
          "&t2min" +
          " "+
          "&t2max" +
          " "+
          "&t3min" +
          " "+
          "&t3max" +
          " "+

          "&fD=" +
          getData.fromDate +
          "&tD=" +
          getData.toDate +
          "&colList=" +
          getData.colList +
          ",&language=" +
          lang;
      }
      async exportpdf(getData) {
        let lang = "english";
        window.location.href =
          baseURL +
          "index.php?c=vehicle&a=exportcrTempPdf&cid=" +
          getData.companyId +
          "&userId=" +
          getData.userId+
          "&vid=" +
          getData.vehicleId +
          "&int=" +
         5 +
          "&t1max" +
          " "+
          "&t1min" +
          " "+
          "&t2min" +
          " "+
          "&t2max" +
          " "+
          "&t3min" +
          " "+
          "&t3max" +
          " "+

          "&fD=" +
          getData.fromDate +
          "&tD=" +
          getData.toDate +
          "&colList=" +
          getData.colList ;
      }
      async exportcsv(getData) {
        window.location.href =
          baseURL +
          "index.php?c=vehicle&a=exporttempcsv&cid=" +
          getData.companyId +
          "&userId=" +
          getData.userId+
          "&vid=" +
          getData.vehicleId +
          "&int=" +
         5 +
          "&t1max" +
          " "+
          "&t1min" +
          " "+
          "&t2min" +
          " "+
          "&t2max" +
          " "+
          "&t3min" +
          " "+
          "&t3max" +
          " "+

          "&fD=" +
          getData.fromDate +
          "&tD=" +
          getData.toDate +
          "&colList=" +
          getData.colList ;
        //   "&language=" +
        //   lang;
      }
      async exportxml(getData) {
        window.location.href =
          baseURL +
          "index.php?c=vehicle&a=exporttempxml&cid=" +
          getData.companyId +
          "&userId=" +
          getData.userId+
          "&vid=" +
          getData.vehicleId +
          "&int=" +
         5 +
          "&t1max" +
          " "+
          "&t1min" +
          " "+
          "&t2min" +
          " "+
          "&t2max" +
          " "+
          "&t3min" +
          " "+
          "&t3max" +
          " "+

          "&fD=" +
          getData.fromDate +
          "&tD=" +
          getData.toDate +
          "&colList=" +
          getData.colList ;
      }

}

export default TempReportStore;
