import { decode as base64_decode, encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import Cookies from "js-cookie";

class AlertStore {
  alertData = [];
  vehicleList = [];
  total = 0;
  loading = false;
  alertRecordArray = [];
  vehicleListArr=[];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      alertData: observable,
      loading: observable,
      vehicleList: observable,
      total: observable,
      alertRecordArray: observable,
      vehicleListArr:observable,
      loadData: action,
      addData: action,
      deleteRecord: action,
      getVehicles: action,
      getAlertData: action,
      updateStatus: action,
      getAllVehicles:action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId }) {
    this.loading = true;

    var postData = {
      companyId: companyId,
      offset: 0,
      limit: 50,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()

      .post("index.php?c=rule&a=getRuleList", "postData=" + base64Input);

    this.total = parseInt(response?.data?.data?.TotalCount);
    this.alertData = response?.data?.data?.rules;
    this.loading = false;
  }

  async addData(values) {
    var postData = values;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=rule&a=create", "postData=" + base64Input);

    return response.data;
  }

  async getAlertData(ruleId) {
    var postData = { ruleId: ruleId, limit: 1, offset: 0 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=rule&a=getRuleList", "postData=" + base64Input);

    const resp = response?.data?.data?.rules[0];
    this.alertRecordArray = resp;

    return resp;

    // return response?.data?.data[0]
  }

  async deleteRecord({ ruleId }) {
    const postData = { tokenId: "", ruleId: ruleId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=rule&a=delete", "postData=" + base64Input);

    return response;
  }

  //Vehicle dropdown

  async getVehicles(companyId) {
    var postData = {
      companyId: companyId,
      userId: 1,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=rule&a=getvehiclegeofence", "postData=" + base64Input);

    this.vehicleList = response?.data?.data?.vehicles;
    this.geofencesList = response?.data?.data?.geofences;
    return response?.data?.data?.vehicles;
  }

  async updateStatus(data) {
    //update active in-active
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=alert&a=updateActive", "postData=" + base64Input);
    return response.data;
  }

  async getAllVehicles(companyId) {
   
    var postData = {
      companyId: companyId,
      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getAllVehicles", "postData=" + base64Input);
    this.vehicleListArr = response.data.data
    return response.data.data
  }
}

export default AlertStore;
