import { observer } from "mobx-react";
import React, { Component } from "react";
import withRouter from "../withRouter";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Empty,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { StoreContext } from "../store/RootStore";

import dayjs from "dayjs";
// import { Bar, Column, tooltip } from "@ant-design/plots";
import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

import DriverScoreGraphStore from "../store/DriverScoreGraphStore";
// import { Column } from "@ant-design/plots";
const { Option } = Select;

class DriverScoreGraph extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleLegendClick = this.handleLegendClick.bind(this);

    this.driverScoreGraph = new DriverScoreGraphStore(this);

    this.state = {
      selectAllDrivers: false,
      selectedDriver: [],
      update: 0,
      companyId: "",
      distance: "",
      fromDate: dayjs().format("YYYY-MM-DD"),
      toDate: dayjs().format("YYYY-MM-DD"),
      loadData: 0,
      showDriverGraphHorizontal: false,
      showDriverGraphVertical: false,
      displayVehicleNo: "",
      Notcompany: false,
      Nodriver: false,
      loadingSpin: false,
      showaccurate: true,
      showbrakerate: true,
      showexidlerate: true,
      showrpmrate: true,
      showspeedrate: true,
    };
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });
    await this.setState({ selectedDriver: [], selectAllDrivers: false });
    if(this.state.companyId){
      await this.driverScoreGraph.toGetDriverName(comp?.value);

    }

    // console.log("data", data);

    this.setState({ loadData: 1 });
  }

  handleAllSelectDrivers = async (e) => {
    if (this.state.selectAllDrivers) {
      const allDrivers = this.driverScoreGraph?.getDriverName.map((val) => {
        return val.driverId;
      });

     
      await this.setState({ selectedDriver: allDrivers });
    } else {
      await this.setState({ selectedDriver: [] });
    }
  };

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedDriver.length === 0) {
      this.setState({ Nodriver: true });
    } else {
      this.setState({ Nodriver: false });
    }
    if (this.state.companyId && this.state.selectedDriver.length > 0) {
      this.setState({ loadingSpin: true });
      this.setState({ Notcompany: false, Nodriver: false });
      const data = await this.driverScoreGraph.loadDriverScoreGraphData({
        // offset: (this.state.pageNo - 1) * this.state.pageSize,
        // limit: this.state.pageSize,
        companyId: this.state.companyId,
        driverId: this.state.selectedDriver,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        // pageNo: this.state.pageNo,
      });
    }
    // console.log(data);

    this.setState({ loadData: 1 });
    this.setState({ loadingSpin: false });
  };

  handleLegendClick = (legendItem, index) => {
    const dataKey = legendItem.dataKey;

    this.setState((prevState) => ({
      [dataKey]: !prevState[dataKey],
    }));
  };

  CustomTooltip = ({ active, payload }) => {
    console.log(active, payload);
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      console.log(data);
      return (
        <div
          style={{ border: "1px solid", background: "white", padding: "10px" }}
        >
         
          {<>
            <div><b>{this.context.translate("_ACCELERATION_FOR_")}{data.driver ? data.driver : "-"}</b> : {data.accrate ? data.accrate : "-"}</div>
            <div><b>{this.context.translate("_BRAKING_FOR_")}{data.driver ? data.driver : "-"}</b> : {data.brakerate ? data.brakerate : "-"}</div>
            <div><b>{this.context.translate("_EX_IDLING_FOR_")}{data.driver ? data.driver : "-"}</b> : {data.exidlerate ? data.exidlerate : "-"}</div>
            <div><b>{this.context.translate("_RPM_FOR_")}{data.driver ? data.driver : "-"}</b> : {data.rpmrate ? data.rpmrate : "-"}</div>
            <div><b>{this.context.translate("_SPEEDING_FOR_")}{data.driver ? data.driver : "-"}</b> : {data.speedrate ? data.speedrate : "-"}</div>

          </>
          }

          {/* <div><b>{`${this.context.translate("_ACCELERATION_FOR_")}: ${
            data.driver ? data.driver : "-"
          } ${data.accrate ? data.accrate : "-"}`}</b></div>
          <div>{`${this.context.translate("_BRAKING_FOR_")}:${
            data.driver ? data.driver : "-"
          } ${data.brakerate ? data.brakerate : "-"}`}</div>
          <div>{`${this.context.translate("_EX_IDLING_FOR_")}:${
            data.driver ? data.driver : "-"
          }  ${data.exidlerate ? data.exidlerate : "-"}`}</div>
          <div>{`${this.context.translate("_RPM_FOR_")}:${
            data.driver ? data.driver : "-"
          } ${data.rpmrate ? data.rpmrate : "-"}`}</div>
          <div>{`${this.context.translate("_SPEEDING_FOR_")}:${
            data.driver ? data.driver : "-"
          }${data.speedrate ? data.speedrate : "-"}`}</div> */}
        </div>
      );
    }
    return null;
  };

  CustomLegend = ({ data }) => (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      {data.map((entry, index) => (
        <div
          key={`legend-${index}`}
          style={{
            color: entry.color,
            display: "inline-block",
            marginRight: "10px",
          }}
        >
          ■ {entry.name}
        </div>
      ))}
    </div>
  );

  customTickFunction = (props) => {
    const { x, y, payload, visibleTicksCount, tickFormatter } = props;
    // console.log(x,y,)

    // Customize the tick appearance or position here
    return (
      <text
        x={x - 20}
        y={this.context.locale.language === "english" ? y + 5 : y}
        textAnchor="end"
        fontSize={12}
        dy={-4}
        style={{ lineHeight: "1.2" }}
      >
        {tickFormatter ? tickFormatter(payload.value) : payload.value}
      </text>
    );
  };

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.driverScoreGraph.toGetDriverName(
        this.props.getUserData().userCompanyId
      );
      await this.setState({ update: 1 });
    }
  }

  render() {
    const legendProps = {
      onClick: this.handleLegendClick,

      // onMouseEnter: () => this.setState({ hoveredLegendKey: null }),
      // onMouseLeave: () => this.setState({ hoveredLegendKey: null }),
    };
    const customLegendPayload = [
      {
        // value: "Temp Sensor1",
        value: this.context.translate("_ACCELERATION_RATE_"),
        color: "rgb(247, 198, 96)",//"rgb(240, 165, 10)",
        dataKey: "showaccurate",
      },
      {
        // value: "Temp Sensor1",
        value: this.context.translate("_BRAKE_RATE_"),
        color: "rgb(199, 90, 110)",
        dataKey: "showbrakerate",
      },
      {
        // value: "Temp Sensor1",
        value: this.context.translate("_IDLE_RATE_"),
        color: "rgb(102, 122, 189)",
        dataKey: "showexidlerate",
      },
      {
        // value: "Temp Sensor1",
        value: this.context.translate("_RPM_RATE_"),
        color: "rgb(181, 114, 167)",
        dataKey: "showrpmrate",
      },
      {
        // value: "Temp Sensor1",
        value: this.context.translate("_SPEED_RATE_"),
        color: "rgb(157, 196, 88)",
        dataKey: "showspeedrate",
      },
    ];
    // const legends = [
    //   // { name: "Acceleration Rate", color: "orange" },
    //   // { name: "Brake Rate", color: "pink" },
    //   // { name: "Idle Rate", color: "blue" },
    //   // { name: "RPM Rate", color: "violet" },
    //   // { name: "Speed Rate", color: "green" },
    //   { name: this.context.translate("_ACCELERATION_RATE_"), color: "orange" },
    //   { name: this.context.translate("_BRAKE_RATE_"), color: "pink" },
    //   { name: this.context.translate("_IDLE_RATE_"), color: "blue" },
    //   { name: this.context.translate("_RPM_RATE_"), color: "violet" },
    //   { name: this.context.translate("_SPEED_RATE_"), color: "green" },
    // ];
    return (
      <>
        <Spin spinning={this.state.loadingSpin} fullscreen>
          <div style={{ padding: 0 }}>
            <Row className="headerFixed" style={{ height: "50px" }}>
              <Col span={12}>
                {/* <div className="heading">Driver Score Graph</div> */}
                <div className="heading">
                  {this.context.translate("_DRIVER_SCORE_GRAPH_")}
                </div>
              </Col>
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>
          </div>
          <div
            style={{
              paddingTop: "10px",
              paddingRight: "24px",
              paddingLeft: "24px",
            }}
          >
            <Row className="listInputRow">
              <Col span={20}>
                <Space>
                  <CompanyAutocomplete
                    onChange={this.handleChange}
                    allowClear={true}
                    style={{ width: 175 }}
                    status={this.state.Notcompany ? "error" : false}
                  />
                  <Checkbox
                    checked={this.state.selectAllDrivers}
                    onChange={async (e) => {
                      await this.setState({
                        selectAllDrivers: e.target.checked,
                      });
                      console.log(e.target.checked);
                      await this.handleAllSelectDrivers();
                    }}
                  />
                  {/* All */}
                  {this.context.translate("_ALL_")}
                  <Select
                    mode="multiple"
                    maxTagCount="responsive"
                    value={this.state.selectedDriver}
                    onChange={(val, x) => {
                      this.setState({
                        selectedDriver: val,
                        Nodriver: false,
                        // displayVehicleNo: x.vehicleNo,
                      });
                      if (
                        this.driverScoreGraph?.getDriverName.length > val.length
                      ) {
                        this.setState({ selectAllDrivers: false });
                      } else {
                        this.setState({ selectAllDrivers: true });
                      }
                      // x.forEach((val)=>this.setState({displayDriverName:[...this.state?.displayDriverName,val?.name]}))
                    }}
                    // fieldNames={{ label: "name", value: "driverId" }}
                    // placeholder="Driver Name"
                    placeholder={this.context.translate(
                      "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
                    )}
                    optionFilterProp="children"
                    // options={this.driverScoreGraph?.getDriverName}
                    style={{ width: 175 }}
                    status={this.state.Nodriver ? "error" : false}
                    filterOption={(inputValue, option) => {
                      if (option.children) {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {this.state.companyId ? this.driverScoreGraph?.getDriverName?.map((val) => {
                      return <Option value={val.driverId}>{val.name}</Option>
                    }):""}
                  </Select>
                  <DatePicker
                    format={"DD-MM-YYYY"}
                    defaultValue={dayjs()}
                    placeholder={this.context.translate("_FROM_DATE_")}
                    onChange={(date) => {
                      if (date === null) {
                        this.setState({ fromDate: "" });
                      } else {
                        const format = "YYYY-MM-DD";
                        this.setState({ fromDate: date.format(format) });
                        //   console.log(date.format("YYYY-MM-DD HH:mm:ss"));
                      }
                    }}
                    disabledDate={(current) => {
                      return current && current > dayjs();
                    }}
                  />
                  <DatePicker
                    format={"DD-MM-YYYY"}
                    defaultValue={dayjs()}
                    placeholder={this.context.translate("_TO_DATE_")}
                    onChange={(date) => {
                      if (date === null) {
                        this.setState({ toDate: "" });
                      } else {
                        const format = "YYYY-MM-DD";
                        this.setState({ toDate: date.format(format) });
                        //   console.log(date.format("YYYY-MM-DD HH:mm:ss"));
                      }
                    }}
                    disabledDate={(current) => {
                      return current && current > dayjs();
                    }}
                  />
                  <Button
                    type="primary"
                    onClick={async () => {
                      await this.showData();
                      await this.setState({
                        showDriverGraphVertical: true,
                        showDriverGraphHorizontal: false,
                      });
                      console.log(this.driverScoreGraph?.driverScoreGraph);
                    }}
                  >
                    {/* Vertical */}
                    {this.context.translate("_VERTICAL_")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={async () => {
                      await this.showData();
                      await this.setState({
                        showDriverGraphHorizontal: true,
                        showDriverGraphVertical: false,
                      });
                      // console.log(this.distanceGraphStore?.distanceGraph);
                    }}
                  >
                    {/* Horizontal */}
                    {this.context.translate("_HORIZONTAL_")}
                  </Button>
                </Space>
              </Col>
              <Col span={4}>
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>
            {this.state.showDriverGraphHorizontal ||
            this.state.showDriverGraphVertical ? (
              <>
                {this.state.showDriverGraphHorizontal &&
                  this.driverScoreGraph?.driverScoreGraph.length > 0 && (
                    <div>
                      <ResponsiveContainer height={550}>
                        <BarChart
                          layout="vertical"
                          width={600}
                          data={this.driverScoreGraph?.driverScoreGraph}
                          margin={{
                            top: 20,
                            right: 20,
                            left: 20,
                            bottom: 50,
                          }}
                        >
                          <YAxis
                            type="category"
                            dataKey="driver"
                            fontSize={"10px"}
                            label={{
                              value: this.context.translate("_DRIVER_NAME_"),

                              fontWeight: "bold",
                              fontSize: "17px",
                              position: "insideLeft",
                              angle: -90,
                            }}
                            style={{
                              direction:
                                this.context.locale.language === "arabic"
                                  ? "ltr"
                                  : "ltr",
                            }}
                          />
                          <XAxis
                            type="number"
                            height={50}
                            label={{
                              value: this.context.translate("_RATE_"),
                              fontWeight: "bold",
                              fontSize: "17px",
                              position: "bottom",
                            }}
                          />
                          {/* {legends.map((legend, index) => (
                          <Bar
                            key={`bar-${index}`}
                            dataKey={legend.name.toLowerCase()}
                            stackId="a"
                            fill={legend.color}
                          />
                        ))} */}
                          <Tooltip content={<this.CustomTooltip />} />
                          <Legend
                            verticalAlign="top"
                            {...legendProps}
                            payload={customLegendPayload}
                            iconType="square"
                          />
                         <Bar
                            dataKey="accrate"
                            stackId="a"
                            fill="rgb(247, 198, 96)"
                            hide={!this.state.showaccurate}
                          ></Bar>
                          <Bar
                            dataKey="brakerate"
                            stackId="a"
                            fill="rgb(199, 90, 110)"
                            hide={!this.state.showbrakerate}
                          />
                          <Bar
                            dataKey="exidlerate"
                            stackId="a"
                            fill="rgb(102, 122, 189)"
                            hide={!this.state.showexidlerate}
                          />
                          <Bar
                            dataKey="rpmrate"
                            stackId="a"
                            fill="rgb(181, 114, 167)"
                            hide={!this.state.showrpmrate}
                          />
                          <Bar
                            dataKey="speedrate"
                            stackId="a"
                            fill="rgb(157, 196, 88)"
                            hide={!this.state.showspeedrate}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                      {/* {this.CustomLegend({ data: legends })} */}
                    </div>
                  )}

                {this.state.showDriverGraphVertical &&
                  this.driverScoreGraph?.driverScoreGraph.length > 0 && (
                    <div>
                      <ResponsiveContainer height={550}>
                        <BarChart
                          layout="horizontal"
                          width={600}
                          data={this.driverScoreGraph?.driverScoreGraph}
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 50,
                          }}
                        >
                          <YAxis
                            type="number"
                            fontSize={"10px"}
                            label={{
                              value: this.context.translate("_RATE_"),
                              fontWeight: "bold",
                              fontSize: "17px",
                              angle: -90,
                              position: "insideLeft",
                            }}
                            // tick={this.customTickFunction}
                            style={{
                              direction:
                                this.context.locale.language === "arabic"
                                  ? "ltr"
                                  : "ltr",
                            }}
                          />
                          <XAxis
                            maxHeight={100}
                            angle={
                              this.context.locale.language === "english"
                                ? -90
                                : 90
                            }
                            textAnchor="end"
                            //  interval={Math.ceil(
                            //    this.driverScoreGraph?.driverScoreGraph / 35
                            //  )}
                            //  type="number"
                            height={100}
                            type="category"
                            dataKey="driver"
                            // height={50}
                            label={{
                              value: this.context.translate("_DRIVER_NAME_"),
                              fontWeight: "bold",
                              fontSize: "17px",
                              position: "bottom",
                            }}
                          />
                          
                          <Tooltip content={<this.CustomTooltip />} />
                          <Legend
                            verticalAlign="top"
                            {...legendProps}
                            payload={customLegendPayload}
                            iconType="square"
                          />
                          {/* <Legend content={(data) => console.log(data)} /> */}
                          <Bar
                            dataKey="accrate"
                            stackId="a"
                            fill="rgb(247, 198, 96)"
                            hide={!this.state.showaccurate}
                          ></Bar>
                          <Bar
                            dataKey="brakerate"
                            stackId="a"
                            fill="rgb(199, 90, 110)"
                            hide={!this.state.showbrakerate}
                          />
                          <Bar
                            dataKey="exidlerate"
                            stackId="a"
                            fill="rgb(102, 122, 189)"
                            hide={!this.state.showexidlerate}
                          />
                          <Bar
                            dataKey="rpmrate"
                            stackId="a"
                            fill="rgb(181, 114, 167)"
                            hide={!this.state.showrpmrate}
                          />
                          <Bar
                            dataKey="speedrate"
                            stackId="a"
                            fill="rgb(157, 196, 88)"
                            hide={!this.state.showspeedrate}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                      {/* {this.CustomLegend({ data: legends })} */}
                    </div>
                  )}
              </>
            ) : (
              <Empty></Empty>
            )}
          </div>
        </Spin>
      </>
    );
  }
}
DriverScoreGraph.contextType = StoreContext;
export default observer(withRouter(DriverScoreGraph));
