import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
  observe,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class PoliceOfficerStore {
  
  policeOfficers=[]
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
     
    policeOfficers:observable,
      total:observable,
      loading:observable,
      loadData:action,
     
    
     
    });
     this.rootStore = rootStore;
    
  }

  async loadData({offset,limit,companyId}) {
    console.log("called")
    // this.loading = true;
    var postData = {"companyId":companyId,"offset":0,"limit":50}  
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=policeofficer&a=getPoliceOfficerList", "postData="+base64Input);
    console.log(response?.data?.data?.policeofficerdetails[0])
    this.policeOfficers =response?.data?.data?.policeofficerdetails[0] 
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }
}

export default PoliceOfficerStore;
