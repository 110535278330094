import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi, baseURL } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class DistanceReportStore {
  distanceReport = [];
  total = 0;
  loading = false;
  loadingData=false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      distanceReport: observable,
      total: observable,
      loading: observable,
      loadData: action,
      exportcsv: action,
      exportExcel: action,
      exportpdf: action,
      exportxml: action,
      loadDataTotal : action,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    companyId,
    offset,
    limit,
    vehicleId,
    fromDate,
    toDate,
    pageNo,
  }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      offset: offset,
      limit: limit,
      page: pageNo,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=distancereport", "postData=" + base64Input);

    this.distanceReport = response?.data?.data?.distanceReport;

    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async loadDataTotal({
    companyId,
    vehicleId,
    fromDate,
    toDate,
    
   
  }) {
    this.loadingData = true;
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
     
     
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=distanceTotal", "postData=" + base64Input);

    this.totalReport = response?.data?.data;

    
    this.loadingData = false;

  }
  async exportExcel(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportdistanceexcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId+
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportdistancecsv&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId+
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportdistancepdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportxml(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportdistancexml&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId+
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
}

export default DistanceReportStore;
