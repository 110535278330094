import React, { Component } from "react";

import {
  Form,
  Select,
  Button,
  Input,
  DatePicker,
  Space,
  message,
  Upload,
  Row,
  Col,
  Spin,
  InputNumber,
} from "antd";

import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { StoreContext } from "../store/RootStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import config from "../config";
import VehicleDistanceStore from "../store/VehicleDistanceStore";
import VehicleStore from "../store/VehicleStore";
import moment from "moment";
import withRouter from "../withRouter";
import CompanyStore from "../store/CompanyStore";
import VehicleWorkingHoursStore from "../store/VehicleWorkingHoursStore";
import dayjs from "dayjs";

const dateFormat = "YYYY-MM-DD";
const currentDate = dayjs().format(dateFormat);

class VehicleDistanceAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      showProjectDetail: false,
      showAddDriver: false,
      startOdometer: "",
      endOdometer: "",
      Id: "",
      vehiclesAssetArray: [],
      manualDistanceId: -1,
      companyId: "",
      loadData: 0,
      loadingSpin: false,
    };
    this.vehicleDistanceStore = new VehicleDistanceStore();
    this.vehicleStore = new VehicleStore();
    this.companyStore = new CompanyStore();
    this.workingHoursStore = new VehicleWorkingHoursStore();
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  onVehicleDistanceSubmit = async (values) => {
    this.setState({ loading: true });
    console.log(values);

    const data = {
      ...values,
      startOdometer: this.state.startOdometer,
      endOdometer: this.state.endOdometer,
      companyId: this.state.companyId,
      manualDistanceId: this.state.manualDistanceId,
      entryDate: values?.entryDate.format("YYYY-MM-DD"),
    };

    const resp = await this.vehicleDistanceStore.addData(data);
   await this.props.response(resp)
    // if (resp.data.success == "S") {
    //   message.success(resp.data.message);
    //   this.props.navigate("/vehicledistancelist");
    // } else if (resp.data.success == "F0") {
    //   resp.data.errors.map((value) => {
    //     message.error("You have already entered readings for selected vehicle for this date");
    //   });
    // }
    this.setState({ loading: false });
  };

  setFieldValue = () => {
    this.formRef.current.setFieldsValue({
      distanceTravel:
        parseFloat(this.state.endOdometer) -
        parseFloat(this.state.startOdometer),
    });
  };

  async componentDidMount() {
    if(Number(this.props.getUserData().isadmin)!==-1){
      this.context.viewStateStore.setVehicleDistanceStates({ companyId: this.props.getUserData().userCompanyId});
      await this.setState({companyId:this.props.getUserData().userCompanyId})
      await this.setState({isadmin:this.props.getUserData().isadmin})
      const data = await this.workingHoursStore.getAssignAsset({
        companyId: this.props.getUserData().userCompanyId,
      });
    }
    await this.setState({ loadList: 1 });

    const manualDistanceId = this.props.vehicleDistanceId

    console.log("id", manualDistanceId);

    if (manualDistanceId) {
      this.filldata(manualDistanceId);
      this.setState({ manualDistanceId: manualDistanceId });
    }
  }

  async filldata(manualDistanceId) {
    await this.setState({ loadingSpin: true })
    const getDistanceDetails =
      await this.vehicleDistanceStore.getVehicleDistanceData(manualDistanceId);
    console.log(getDistanceDetails, "id");
    await this.setState({ companyId: getDistanceDetails.companyId });
    const singleData = await this.companyStore.getCompanyDataById(
      getDistanceDetails.companyId
    );

    console.log("single", singleData);
    await this.setState({
      startOdometer: getDistanceDetails.startOdometer,
      endOdometer: getDistanceDetails.endOdometer,
    });
    await this.workingHoursStore.getAssignAsset({
      companyId: getDistanceDetails.companyId,
    });
    await this.formRef.current.setFieldsValue({
      companyId: singleData,
      name: getDistanceDetails.name,
      vehicleId: getDistanceDetails.vehicleId,
      startOdometer: getDistanceDetails.startOdometer,
      endOdometer: getDistanceDetails.endOdometer,
      distanceTravel: getDistanceDetails.distanceTravel,
      entryDate: dayjs(getDistanceDetails.entryDate),
    });
    await this.setState({ loadingSpin: false })
    await this.setState({ loadData: 1 });
  }

  async handleChange(comp) {
    console.log(comp.value);
    await this.setState({ companyId: comp.value });
    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });

    const data = await this.workingHoursStore.getAssignAsset({
      companyId: comp.value,
    });

    if (!this.props.vehicleDistanceId) {
      this.context.viewStateStore.setVehicleDistanceStates({
        companyId: comp.value,
      });
    }
    // const vehicleAsset = await this.vehicleStore.getVehicles(comp.value)
    // await this.setState({ vehiclesAssetArray: vehicleAsset })
    // console.log(comp.value)

    await this.handleSearch();
  }

  async handleSearch() {
    await this.vehicleDistanceStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  // fillAssets = () => {
  //     console.log(this.state.vehiclesAssetArray)
  //     if (this.state.vehiclesAssetArray) {
  //         return this.state.vehiclesAssetArray.map((value) => {
  //             return (
  //                 <Select.Option value={value.vehicleId}>
  //                     {value.vehicleNo}
  //                 </Select.Option>
  //             )
  //         })
  //     }
  // }

  validateFieldsValue = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9.]*/g, "");
    console.log(value1);
    if (value1) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
      this.setState({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
      this.setState({ [field]: value1 });
    }
  };

  closeModal=()=>{
    this.props.closeModal()
  }

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
        <Spin spinning={this.state.loadingSpin} fullscreen >
        <Form
          ref={this.formRef}
          style={{ maxWidth: "100%", padding: 0 }}
          layout="vertical"
          onFinish={this.onVehicleDistanceSubmit}
          // {...this.layout}
        //   initialValues = {{entryDate:currentDate}}
          name="nest-message"

        >
          {/* <h1></h1> */}

         
            {/* <div className="headingTitle">Vehicle Distance Travelled Details</div> */}
            <Row className="overflowFormInputs">
              {/* css class */}
              <Col span={12} className="formColumnClass"> 
              {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3)  && 
              <>
                {this.props.vehicleDistanceId ? (
                  <Form.Item
                    disabled
                    name="companyId"
                    rules={[{ required: true }]}
                    label={this.context.translate("_ACCOUNT_")}
                  >
                    <CompanyAutocomplete
                      disabled={this.props.vehicleDistanceId ? true : false}
                      onChange={(value) => this.handleChange(value)}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="companyId"
                    rules={[{ required: true }]}
                    label={this.context.translate("_ACCOUNT_")}
                  >
                    <CompanyAutocomplete
                      disabled={this.props.vehicleDistanceId ? true : false}
                      onChange={(value) => this.handleChange(value)}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                )}
                </>}

                {this.props.vehicleDistanceId ? (
                  <Form.Item
                  
                    name="vehicleId"
                    label={this.context.translate("_ASSET_")}
                    rules={[{ required: true }]}
                  >
                    {/* <Select placeholder="Select Asset">
                                    {this.fillAssets()}
                                </Select> */}
                    <Select
                      disabled
                      // placeholder="Select Asset"
                      placeholder={this.context.translate("_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_")}
                      style={{ width: "100%" }}
                      // mode="multiple"
                      showSearch
                      fieldNames={{ value: "vehicleId", label: "vehicleNo" }}
                      optionFilterProp="children"
                      options={this.workingHoursStore?.assignAssetList}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="vehicleId"
                    label={this.context.translate("_ASSET_")}
                    rules={[{ required: true }]}
                  >
                    {/* <Select placeholder="Select Asset">
                                    {this.fillAssets()}
                                </Select> */}
                    <Select
                      // placeholder="Select Asset"
                      placeholder={this.context.translate("_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_")}
                      style={{ width: "100%" }}
                      // mode="multiple"
                      showSearch
                      fieldNames={{ value: "vehicleId", label: "vehicleNo" }}
                      optionFilterProp="children"
                      options={this.workingHoursStore?.assignAssetList}
                    />
                  </Form.Item>
                )}

                {this.props.vehicleDistanceId ? (
                  <Form.Item
                    
                    name="entryDate"
                    label={this.context.translate("_DATE_")}
                    rules={[{ required: true }]}
                    
                  >
                    <DatePicker disabled format="YYYY-MM-DD" style={{ width: "100%" }} />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="entryDate"
                    label={this.context.translate("_DATE_")}
                    rules={[{ required: true }]}
                    initialValue={dayjs()}
                  >
                    <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
                  </Form.Item>
                )}
              </Col>
              <Col span={12} className="formColumnClass">
                <Form.Item
                  name="startOdometer"
                  label={this.context.translate("_START_ODOMETER_")}
                  rules={[{ required: true }]}
                >
                  <Input
                    value={this.state.startOdometer}
                    onChange={async (e) => {
                      await this.validateFieldsValue(
                        e.target.value,
                        "startOdometer"
                      );
                      this.setFieldValue();

                    }}
                  />

                  {/* <Input value={this.state.startOdometer} onChange={async (e) => {await this.validateFieldsValue(
                        e.target.value,
                        "startOdometer"
                      ); await this.setState({ startOdometer: e.target.value }) }}  /> */}
                </Form.Item>

                <Form.Item
                  name="endOdometer"
                  label={this.context.translate("_STOP_ODOMETER_")}
                  rules={[{ required: true }]}
                >
                  <Input
                    value={this.state.endOdometer}
                    onChange={async (e) => {
                      await this.setState({ endOdometer: e.target.value });
                      this.setFieldValue();
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="distanceTravel"
                  label={this.context.translate("_FLEET_RULESELECTORDETAIL_RULE13_TITLE_")}
                  className="disabledLabel"
                  // label="Distance Travelled"
                >
                  {/* <Input name='distanceTravel1' value={this.state.distanceTravel}  /> */}
                  <Input disabled />
                </Form.Item>
              </Col>
              </Row>
              <div className="formBtnGroup">
                {/* <Link to={"/vehicledistancelist"}>
                  {" "}
                  <Button ghost type="primary" htmlType="">
                    {" "}
                    Back{" "}
                  </Button>
                </Link> */}
                <Button
                 className="formCancelButton"
                  type="primary"
                 
                  onClick={(e) => {
                    this.closeModal()
                    // this.formRef.current?.resetFields();
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                type="primary"className="formSaveButton"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              
              </div>
           
        
        </Form>
        </Spin>
        </div>
      </div>
    );
  }
}

VehicleDistanceAdd.contextType = StoreContext;
export default observer(withRouter(VehicleDistanceAdd));
