import React from "react";
import { Row, Col, Space, Tooltip, message, Button, Modal } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import ListTable from "./ListTable";

import config from "../config";

import withRouter from "../withRouter";
import DeleteConfirm from "./DeleteConfirm";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
import EventStore from "../store/EventStore";
import EventAdd from "./EventAdd";

class EventList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.eventStore = new EventStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      loadData: 1,
      companyId: "",
      comp_value: "",
      listDataUpdate: false,
      openEventForm: false,
    };
  }
  async componentDidMount() {
    await this.handleSearch();

    this.setState({ pageSize: 50, loadData: 1 });

    this.columns = [
      {
        title: this.context.translate("_TITLE_"),
        dataIndex: "title",
        width: "20%",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        title: this.context.translate("_FLEET_DASHBORD_SETTING_DATE_"),
        dataIndex: "createDate",
        width: "15%",
      },
      {
        title: this.context.translate("_CREATED_BY_"),
        dataIndex: "createdBy",
        width: "15%",
      },
      {
        title: this.context.translate("_FLEET_DASHBORD_NEWPOI_TYPE_"),
        dataIndex: "type",
        width: "15%",
        render: (value) => {
          if (value == 1) {
            return <>Event</>;
          }
        },
      },

      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "action",
        width: "5%",
        render: (_, record) => {
          return (
            <>
              <Space>
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    // title="Delete Record"
                    title={this.context.translate("_DELETE_RECORD_")}
                    color={config.tooltipColorDelete}
                  >
                    <DeleteFilled style={{ color: config.deleteColor }} />
                  </Tooltip>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  onEdit = async (record) => {
    
    await this.setState({ id: record.id });
    await this.setState({ openEventForm: true });
  };

  async onDelete(record) {
    const response = await this.eventStore.deleteRecord({
      id: record.id,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);
      await this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.eventStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      page: this.state.currentPage,
    });
    await this.setState({ loadData: 1, loading: false });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }


  closeModal = () => {
    this.setState({ openEventForm: false });
  };

  responseMsg = async (resp) => {

    console.log(resp)
    if (resp.success === "S") {
      message.success(resp.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openEventForm: false });
      await this.handleSearch();
    }else if (resp.success == "F2000") {
      resp.errors.map((value) => {
        message.error(value["error"]);
      });
    }
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">{this.context.translate("_EVENT_")}</div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Tooltip
                  placement="left"
                  //  title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => this.setState({ openEventForm: true,id:'' })}
                  ></Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <ListTable
            currentPage={this.state.currentPage}
            total={this.eventStore?.total}
            columns={this.columns}
            pageSize={this.state.pageSize}
            dataSource={this.eventStore?.contentList}
            loading={this.eventStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openEventForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal"
            title={this.context.translate("_ADD_EVENT_")}
            centered
            open={this.state.openEventForm}
            footer={null}
            onCancel={() => {
              this.setState({ openEventForm: false,id:'' });
            }}
            width={665}
          >
            <div>
              <EventAdd
                id={this.state.id}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
EventList.contextType = StoreContext;
export default observer(withRouter(EventList));
