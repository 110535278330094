import { encode as base64_encode } from "base-64";
import config from "../config";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class AdvertisementStore {
  loading = false;
  total = 0;
  advertisementList = [];
  photoFromDb={}

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      total: observable,
      advertisementList: observable,
      photoFromDb:observable,
      loadData: action,
      deleteRecord: action,
      addData: action,
      getAdvertisementData: action,
      setPhotoFromDb:action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit, page }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      offset: offset,
      limit: limit,
      page: page,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=advertisement&a=getAdvertisement",
        "postData=" + base64Input
      );

    this.advertisementList = response?.data?.data?.advertisement;
    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
  }

  async addData(advertisementDetails) {
    var postData = advertisementDetails;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=advertisement&a=uploadAdvertise",
        "postData=" + base64Input
      );
    console.log(response);
    return response.data;
  }

  async getAdvertisementData({ id, companyId }) {
    var postData = {
      adId: id,
      companyId: companyId,
      limit: 1,
      offset: 0,
    };
    // console.log(postData)

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=advertisement&a=getAdvertisement",
        "postData=" + base64Input
      );
      const singleRow = response?.data?.data?.advertisement[0];
      
      if(singleRow.path){
          
        const fileList = [{
            name: singleRow.path,
            status: 'success',
            url: config.fleetURL+singleRow.path,
        }]
      
        this.setPhotoFromDb(fileList)
            
      }
      return singleRow

    // return response?.data?.data?.advertisement[0];
  }

  async setPhotoFromDb(photo){
    console.log(photo)
    this.photoFromDb = {fileList :photo}
  }

  async deleteRecord({ id }) {
    const postData = {
      id: id,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=advertisement&a=delete", "postData=" + base64Input);

    return response;
  }
}

export default AdvertisementStore;
