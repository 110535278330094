import React, { Component } from "react";
import {
  Button,
  Space,
  Col,
  message,
  TimePicker,
  Form,
  Input,
  Row,
  Select,
  Upload,
  Modal,
  Spin,
} from "antd";
import "./Form.css";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import UsersStore from "../store/UsersStore";
import CompanyStore from "../store/CompanyStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import DepartmentStore from "../store/DepartmentStore";
import { Link } from "react-router-dom";
import config from "../config";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import RoleStore from "../store/RoleStore";
import TripCostStore from "../store/TripCostStore";
import dayjs from "dayjs";

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 20 },
};

class TripCostAdd extends Component {
  formRef = React.createRef();

  constructor(prop) {
    super(prop);
    this.state = {
      companyId: "",
      loadList: 0,
      offset: "",
      limit: "",
      searchField: "",

      loading: false,
      Id: -1,
      loadingSpin: false,
    };

    this.companyStore = new CompanyStore();

    this.tripCostStore = new TripCostStore(this);

    this.handleChange = this.handleChange.bind(this);
  }

  onTripCostSubmit = async (fieldsValue) => {
    console.log(fieldsValue);
    // const obj = { ...fieldsValue, companyId: fieldsValue.companyId.value ? fieldsValue.companyId.value : fieldsValue.companyId, Id: this.state.Id, tripDuration: fieldsValue.tripDuration?.format("HH:mm") }
    const obj = {
      ...fieldsValue,
      companyId:fieldsValue.companyId.value ? fieldsValue.companyId.value : this.state.companyId,
      Id: this.state.Id,
      tripDuration: fieldsValue.tripDuration?.format("HH:mm"),
    };
    console.log(obj);
    const resp = await this.tripCostStore.addTripCost(obj);
    await this.props.response(resp);
    // if (resp.data.success == "S") {
    //   message.success(resp.data.message);
    //   this.props.navigate("/tripCostList");
    // } else if (resp.data.success == "F0") {

    //   message.error("error");

    // }
    console.log("click");
    this.setState({ loading: false });
  };

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      this.context.viewStateStore.setTripCostStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      console.log(this.state.companyId);
      // await this.setState({userId:this.props.getUserData().userId})
      // await this.setState({isadmin:this.props.getUserData().isadmin})
    }

    await this.setState({ loadList: 1 });
    const Id = this.props.Id;
    this.setState({ Id: Id });

    if (Id) {
      this.filldata(Id);
      // this.setState({ userId: userId });
    }
  }

  async filldata(Id) {
    await this.setState({ loadingSpin: true });
    const getTripData = await this.tripCostStore.getTripData(Id);
    console.log(getTripData);
    const singleData = await this.companyStore.getCompanyDataById(
      getTripData.companyId
    );
    console.log(singleData);
    // const data = await this.roleStore.getRole(this.state.companyId);
    // await this.setState({ getRolesList: data });
    // console.log(data);
    await this.setState({
      companyId: getTripData.companyId,
    
    });
    this.formRef.current.setFieldsValue({
      companyId: singleData,
      vendorCode: getTripData.vendorCode,
      tripDuration: getTripData.tripDuration
        ? dayjs(getTripData.tripDuration, "HH:mm")
        : null,
      tripCost: getTripData.tripCost,
      tripMinKm: getTripData.tripMinKm,
      name: getTripData.name,

      // password:getUserData.password,
    });
    await this.setState({ loadingSpin: false });
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    await this.formRef.current.setFieldsValue({ companyId: comp.value });
    if (!this.props.Id) {
      this.context.viewStateStore.setTripCostStates({ companyId: comp.value });
    }

    await this.handleSearch();
  }

  async handleSearch() {
    await this.tripCostStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  validateNumber = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9.]*/g, "");
    console.log(value1);
    if (value1 > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              //   {...layout}
              ref={this.formRef}
              layout="vertical"
              name="nest-messages"
              onFinish={this.onTripCostSubmit}
              // style={{ maxWidth: "100%", padding: 0 }}
              className="formClass"
            >
              {/* <div className="formBorder">
         <div className="headingTitle">Add Trip Cost</div> */}
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3)  && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={this.handleChange}
                        style={{ width: "100%" }}
                        companyId={this.state.companyId}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    name="name"
                    label={this.context.translate("_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="vendorCode"
                    // label="Vendor Code"
                    label={this.context.translate("_VENDOR_CODE_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="tripDuration"
                    // label="Trip Duration"
                    label={this.context.translate("_TRIP_DURATION_")}
                  >
                    <TimePicker format={"HH:mm"} style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item
                    name="tripCost"
                    // label="Trip Cost"
                    label={this.context.translate("_TRIP_COST_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      onChange={(e) =>
                        this.validateNumber(e.target.value, "tripCost")
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="tripMinKm"
                    // label="Trip Min Km"
                    label={this.context.translate("_TRIP_MIN_KM_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      onChange={(e) =>
                        this.validateNumber(e.target.value, "tripMinKm")
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div className="formBtnGroup">
                {/* <Link to="/tripCostList">
                <Button ghost type="primary" htmlType="">
                  Back
                </Button>
              </Link> */}
                <Button
                  className="formCancelButton"
                  type="primary"
                  htmlType=""
                  onClick={(e) => {
                    this.closeModal();
                    // this.formRef.current?.resetFields();
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="formSaveButton"
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>

              {/* </div> */}
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}
TripCostAdd.contextType = StoreContext;
export default observer(withRouter(TripCostAdd));
