import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class VehicleTempSensorStore {
  loading=false
  total=0
  tempSensorVehicles=[]
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
      loading:observable,
      total:observable,
        tempSensorVehicles: observable,
        loadData:action,
      
    });
     this.rootStore = rootStore;
    
  }

 

  async loadData({companyId,offset,limit,searchField}) {
    console.log("vehicle list",searchField)
    this.loading=true
    var postData = {"companyId":companyId,"offset":offset,"limit":limit,"searchField":searchField}
   
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=vehicleSensor", "postData="+base64Input);
   
    console.log(response?.data?.data?.vehiclesensor)
    this.tempSensorVehicles = response?.data?.data?.vehiclesensor
    this.total=parseInt(response?.data?.data?.TotalCount)

    this.loading=false
  }


}

export default VehicleTempSensorStore;
