import React from "react";
import {
  Input,
  DatePicker,
  Select,
  Table,
  Button,
  Typography,
  Space,
  Row,
  Col,
  Checkbox,
  Dropdown,
  Modal,
  Image,
} from "antd";
import { observer } from "mobx-react";
import L from "leaflet";
import config from "../config";
import withRouter from "../withRouter";
import { StoreContext } from "../store/RootStore";

import { CaretDownOutlined, CaretUpOutlined, DownOutlined } from "@ant-design/icons";
import StoppageReportStore from "../store/StoppageReportStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { decode as base64_decode } from "base-64";
import Cookies from "js-cookie";
import ListTable from "./ListTable";
import VehicleStore from "../store/VehicleStore";

import dayjs from "dayjs";
import MapForVehiclePosition from "./MapForVehiclePosition";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
const dotIcon = L.divIcon({
  className: "dot-icon",
  iconSize: [8, 8],
  html: ' <div style="width: 20px; height: 20px; background-color: red; border-radius: 50%;"></div>',
});
const { RangePicker } = DatePicker;
const { Text } = Typography;
const { Option } = Select;
class StoppageReportList extends React.Component {
  constructor(props, context) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.stoppageReportStore = new StoppageReportStore();
    this.vehicleStore = new VehicleStore();

    this.state = {
      loading: false,
      pageSize: 50,
      pageNo: 1,
      offset: 0,
      limit: 50,
      loadData: 0,
      companyId: "",
      selectedVehicles: [],
      selectAllVehicles: false,
      allVehicle: false,
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      interval: 0,
      modalVisible: false,
      column: "",
      filter: "",
      userId: "",
      modalInfoObj: {},
      viewMap: false,
      Notcompany: false,
      Notasset: false,

      Notinterval: false,

      showMonthPopup: false,
      vehicleObj: false,
      heading: "",
      showTable: true,
      showtotaltable: false,

      // loadData:0,

      selectedColumns: [
        {
          // title: "Vehicle No",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          width: "15%",
          key: "vehicleNo",
        },
        {
          // title: "Driver",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
          dataIndex: "name",
          width: "15%",
          key: "name",
        },

        {
          // title: "Address",
          title: context.translate("_FLEET_DASHBORD_NEWPOI_ADDRESS_"),
          dataIndex: "stopLocation",
          width: "15%",
          key: "stopLocation",
        },
        {
          // title: "Halted From",
          title: context.translate("_HALTED_FROM_"),
          dataIndex: "arrivalTime",
          width: "15%",
          key: "arrivalTime",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
        },
        {
          // title: "Halted Till",
          title: context.translate("_HALTED_TILL_"),
          dataIndex: "departureTime",
          width: "15%",
          key: "departureTime",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
        },
        {
          // title: "Time Stopped",
          title: context.translate("_FLEET_REPORTS_STOPREPORT_TIMESTOP_"),
          dataIndex: "stopTime",
          width: "15%",
          key: "stopTime",
        },
        {
          // title: "Customer Name",
          title: context.translate("_CUSTOMER_NAME_"),
          dataIndex: "zoneName",
          width: "15%",
          key: "customername",
        },
        {
          // title: "View On Map",
          title: context.translate("_FLEET_SERVICE_POILIST_VIEWMAP_"),
          dataIndex: "viewonmap",
          width: "15%",
          key: "viewonmap",
          render: (value, obj) => {
            return (
              <>
                <Image
                  src={require("../assets/images/mapIcon/gps_icon.png")}
                  width={25}
                  height={25}
                  onClick={async () => {
                    await this.setState({
                      vehicleObj: {
                        ...obj,
                        icon: "map-car-icon-green.png",
                        latitude: obj.stopLat,
                        longitude: obj.stopLon,
                      },
                      // heading: "Asset Position",
                      heading: this.context.translate("_FLEET_CHAT_REPORT_"),
                    });
                    await this.setState({ loadData: 1 });
                  }}
                />
              </>
            );
          },
        },
      ],
    };

    this.menuProps = {
      items: [
        {
          label: "Excel",
          key: "excel",
        },
        {
          label: "CSV",
          key: "csv",
        },
        {
          label: "XML",
          key: "xml",
        },
        {
          label: "PDF",
          key: "pdf",
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          console.log(result.toString());

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            interval: this.state.interval,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: parseInt(
              JSON.parse(base64_decode(Cookies.get("data"))).userId
            ),
            // userId: 1,
            // userId:parseInt( this.state.userId),

            colList: result.toString(),
          };
          if (val.key == "excel") {
            this.stoppageReportStore.exportExcel(getData);
          }
          if (val.key == "csv") {
            this.stoppageReportStore.exportcsv(getData);
          }
          if (val.key == "pdf") {
            this.stoppageReportStore.exportpdf(getData);
          }
          if (val.key == "xml") {
            this.stoppageReportStore.exportxml(getData);
          }
        }
      },
    };

    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    this.setState({ pageSize: 50 });
    await this.setState({ column: this.state.selectedColumns });

    this.totalStoppageReportCol = [
      {
        // title: "Asset Name ",
        title: this.context.translate(
          "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
        ),
        dataIndex: "vehicleNo",
        // width: '15%'
      },
      {
        // title: "From Date",
        title: this.context.translate("_FROM_DATE_"),
        dataIndex: "fromDate",
      },
      {
        // title: "To Date",
        title: this.context.translate("_TO_DATE_"),
        dataIndex: "toDate",
        //fixed: 'left',
        // width: '15%'
        // render: (value) => {
        //   if (value) {
        //     const formattedDateTime = dayjs(value).format(
        //       "DD-MM-YYYY hh:mm:ss A"
        //     );
        //     return <>{formattedDateTime}</>;
        //   } else {
        //     return <></>;
        //   }
        // },
      },
      {
        // title: "Total Stoppage",
        title: this.context.translate("_TOTAL_STOPPAGE_"),
        dataIndex: "totalStopage",
        //fixed: 'left',
        // width: '15%'
        // render: (value) => {
        //   if (value) {
        //     const formattedDateTime = dayjs(value).format(
        //       "DD-MM-YYYY hh:mm:ss A"
        //     );
        //     return <>{formattedDateTime}</>;
        //   } else {
        //     return <></>;
        //   }
        // },
      },
      {
        // title: "Total Duration",
        title: this.context.translate("_TOTAL_DURATION_"),
        dataIndex: "totalDuration",
        //fixed: 'left',
        // width: '15%'
        // summaryType: (records) => {
        //   var suma = 0;

        //   records.map((record) => {
        //     if (record.totalDist) {
        //       suma = parseInt(suma) + parseInt(record.totalDist);
        //     }
        //   });

        //   return "<b>" + suma + "</b>";
        // },
      },
    ];
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;
      // console.log(companyId)
      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
    await this.setState({ loadData: 1 });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.vehicleObj !== prevState.vehicleObj) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }
  }
  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });
    await this.setState({ selectedVehicles: [], selectAllVehicles: false });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    this.setState({ Notasset: false });
    // await this.stoppageReportStore.loadData({
    //   offset: (this.state.pageNo - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,
    //   pageNo: this.state.pageNo,
    // });

    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination, "pagination");
    await this.setState({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.showData();
  }

  // vehicleSelect = async (val) => {
  //   console.log(val);
  //   await this.setState({ selectedVehicles: val });
  // };

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.interval === "") {
      this.setState({ Notinterval: true });
    } else {
      this.setState({ Notinterval: false });
    }
    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      this.setState({ Notcompany: false, Notasset: false, Notinterval: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.stoppageReportStore.loadData({
          offset: (this.state.pageNo - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          interval: this.state.interval,
          pageNo: this.state.pageNo,
        });
        await this.setState({ loadData: 1 });
      }
    }
    // else if(this.vehicleStore?.getVehiclesUsingCompanyId) {
    //   await this.stoppageReportStore.loadData({
    //     offset: 0,
    //     limit: this.state.pageSize,
    //     companyId: this.state.companyId,
    //     vehicleId: this.state.selectedVehicles,
    //     interval: this.state.interval,
    //     pageNo: 1,
    //   });
    //   this.setState({pageNo:1})
    //   await this.setState({ loadData: 1 });
    // }
  };
  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };
  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  handleCheckboxChange = async (column) => {
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
    } else {
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
    }
  };

  showTotal = async () => {
    await this.stoppageReportStore.totalStoppageReport({
      offset: (this.state.pageNo - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      vehicleId: this.state.selectedVehicles,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      interval: this.state.interval,
    });
    await this.setState({ loadData: 1 });
  };

  render() {
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const assetInputStyle = this.state.Notasset
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    const intervalInputStyle = this.state.Notinterval
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Stoppage Report</div> */}
              <div className="heading">
                {this.context.translate("_FLEET_REPORTS_STOPREPORT_TITLE_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Button
                  onClick={async () => {
                    await this.showTotal();
                    await this.setState({
                      showTable: false,
                      showtotaltable: true,
                    });
                  }}
                >
                  {this.context.translate("_TOTAL_")}
                </Button>

                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.stoppageReportStore?.stoppageReport?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={this.handleChange}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
                {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles: [],
                        selectAllVehicles: false,
                      })
                    }
                  />
                )}
                <Checkbox
                  checked={this.state.selectAllVehicles}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllVehicles: e.target.checked,
                    });
                    // console.log(e.target.checked);
                    await this.handleAllSelectVehicles();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                {/* <Select
                  mode="multiple"
                  onSearch={this.handleSearch}
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    this.setState({ Notasset: false });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }
                  }}
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  maxTagCount="responsive"
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 150 }}
                  // style={assetInputStyle}
                  status={this.state.Notasset ? "error" : false}
                /> */}

                <Select
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }

                    if (val?.length > 1) {
                      console.log(val?.length > 1);
                      this.setState({ showAlertPopup: true });
                    }
                  }}
                  style={{ width: 150 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  mode="multiple"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
                <RangePicker
                  placeholder={[
                    this.context.translate("_FROM_DATE_TIME_"),
                    this.context.translate("_TO_DATETIME_"),
                  ]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
                <Input
                  style={intervalInputStyle}
                  value={this.state.interval}
                  placeholder={this.context.translate("_INTERVAL_")}
                  onChange={(e) => this.setState({ interval: e.target.value })}
                />
                <Button
                  type="primary"
                  onClick={async () => {
                    this.setState({ pageNo: 1 });
                    this.showData();
                    this.setState({ pageNo: 1 });
                    await this.setState({
                      showTable: true,
                      showtotaltable: false,
                    });
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_REPORTS_SHOWBTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <div
             className="accordion-list"
          >
                {this.context.translate("_FLEET_REPORTS_STOPREPORT_TITLE_")}
            <Button
              onClick={() =>
                this.setState({
                  showTable: !this.state.showTable,
                  showtotaltable: !this.state.showtotaltable,
                })
              }
            >
              {this.state.showTable ? <CaretDownOutlined /> :<CaretUpOutlined />}
            </Button>
          </div>

          {this.state.showTable && (
            <ListTable
              currentPage={this.state.pageNo}
              pageSize={this.stoppageReportStore.pageSize}
              total={this.stoppageReportStore?.total}
              columns={this.state.column}
              dataSource={
                this.stoppageReportStore?.stoppageReport?.length > 0 && [
                  ...this.stoppageReportStore?.stoppageReport,
                ]
              }
              loading={this.stoppageReportStore?.loading}
              onChange={this.handleTableChange}
            />
          )}

          <div
            className="accordion-list"
          >
            {" "}
            {this.context.translate("_TOTAL_STOP_REPORT_")}
            <Button
              onClick={() =>
                this.setState({
                  showtotaltable: !this.state.showtotaltable,
                  showTable: !this.state.showTable,
                })
              }
            >
              {this.state.showtotaltable ? <CaretDownOutlined /> :<CaretUpOutlined />}
            </Button>{" "}
          </div>

          {/* <div
            style={{
              backgroundColor: "Transparent ",
              color: "#0090e7",
              fontSize: "16px",
              padding: "8px 8px",
              fontWeight: "600",
              marginTop: "8px",
              marginBottom: "5px",
            }}
          >
         
          </div> */}
          {this.state.showtotaltable && (
            <ListTable
              currentPage={this.state.pageNo}
              // pageSize={this.state.pageSize}
              // total={this.stoppageReportStore?.total}
              columns={this.totalStoppageReportCol}
              dataSource={this.stoppageReportStore?.stoppageArray}
              loading={this.stoppageReportStore?.loadingData}
              pagination={false}
              // onChange={this.handleTableChange}
              summary={(records) => {
                var sum = 0;
                var suma = 0;

                records.map((record) => {
                  if (record.totalStopage) {
                    sum = parseInt(sum) + parseInt(record.totalStopage);
                  }

                  if (record.totalDuration) {
                    var dt = record.totalDuration.split(":");
                    var ts =
                      parseInt(dt[0] * 60 * 60) +
                      parseInt(dt[1] * 60) +
                      parseInt(dt[2]);
                    suma = parseInt(parseInt(suma) + parseInt(ts));
                  }
                });

                var d, h, m, s;

                s = suma;
                m = Math.floor(s / 60);
                s = s % 60;
                h = Math.floor(m / 60);
                m = m % 60;
                d = Math.floor(h / 24);
                h = h % 24;

                var result = (d < 10 ? "0" + d : d) + " days,";
                result += h < 10 ? "0" + h : h;
                result += ":" + (m < 10 ? "0" + m : m);
                result += ":" + (s < 10 ? "0" + s : s);

                return (
                  <>
                    {records.length != 0 && (
                      <Table.Summary.Row>
                        <Table.Summary.Cell>
                          <Text style={{ fontWeight: "bold" }}>Total</Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell />
                        <Table.Summary.Cell />

                        <Table.Summary.Cell>
                          <Text style={{ fontWeight: "bold" }}>{sum}</Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text style={{ fontWeight: "bold" }}>{result}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    )}
                  </>
                );
              }}
            />
          )}
        </div>

        {this.state.vehicleObj && (
          <MapForVehiclePosition
            vehicleObj={this.state.vehicleObj}
            heading={this.state.heading}
            closeMapModal={(resp) => {
              this.setState({ vehicleObj: resp });
            }}
          />
        )}

        <Modal
          width={350}
          className="alert-model"
          title={
            <div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              {this.context.translate("_FLEET_ALERT_")}
            </div>
          }
          open={this.state.showMonthPopup}
          onOk={() => this.setState({ showMonthPopup: false })}
          onCancel={() => this.setState({ showMonthPopup: false })}
          footer={false}
        >
          {/* For reports prior to 6 months please contact administrator */}
          {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
        </Modal>
      </>
    );
  }
}
StoppageReportList.contextType = StoreContext;
export default observer(withRouter(StoppageReportList));
