import {
  Button,
  Card,
  Col,
  FloatButton,
  Image,
  Input,
  Modal,
  Popover,
  Radio,
  Row,
  Space,
  Spin,
  Table,
  message,
} from "antd";
import React, { Component } from "react";
import CompanyAutocomplete from "./CompanyAutocomplete";
import MultiTrackingStore from "../store/MultiTrackingStore";
import "./Tracking.css";
import "leaflet/dist/leaflet.css";

import {
  MapContainer,
  Polyline,
  TileLayer,
  Marker,
  Popup,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Icon, divIcon, point } from "leaflet";
import Draggable from "react-draggable";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import {
  ArrowsMove,
  Bezier2,
  CarFront,
  GlobeCentralSouthAsia,
  Pencil,
} from "react-bootstrap-icons";
import {
  ArrowsAltOutlined,
  CloseOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import PolyLineAndMapComponent from "./PolyLineAndMapComponent";
import { isThisSecond } from "date-fns";
import withRouter from "../withRouter";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import dayjs from "dayjs";

const limeOptions = { color: "lime" };

class MultiTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: "",
      loading: false,
      pageSize: 50,
      currentPage: 1,
      position: [],
      mapShow: false,
      latLongForModal: [],
      updateState: 0,
      newVehiclePosition: [],
      vehicleIds: [],
      updatedPos: false,
      showSlider: false,
      mapModalIndex: null,

      mapType: "google",
      listUpdated: 0,
      poupSearch: "",
      selectedRowKeys: [],
      alertModal: false,
      searchText: "",

      count: 0,
      clientHeight: 0,
      searchData: [],

      selectedRowsInfo: [],
    };
    this.mapOptions = [
      {
        label: "Google",
        value: "google",
      },
      {
        label: "Openstreet",
        value: "openstreet",
      },
    ];

    this.mapOptions = [
      {
        label: "Google",
        value: "google",
      },
      {
        label: "Openstreet",
        value: "openstreet",
      },
    ];
    this.multiTrackingStore = new MultiTrackingStore(this);
    this.mapRef = React.createRef();
    this.mapContainerRef = React.createRef();
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1);
  }

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1 && Number(this.props.getUserData().isadmin) !== 3) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleSearch();
    }

    this.context.viewStateStore.setSliderChecker(true);
    let interval = 30000; // 30 seconds in milliseconds
    await this.multiTrackingStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    //  await this.liveLatLongGetting()
    this.intervalId = setInterval(async () => {
      await this.multiTrackingStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        searchField: this.state.searchField,
        companyId: this.state.companyId,
      });
      await this.multiTrackingStore.getSelectedVehicles({
        vehicleIds: this.state.vehicleIds,
      });

      console.log(this.multiTrackingStore.vehicleUpdatedPosition, "re");
      await this.setState({ updatedPos: true });

      const filterVehicles = await this.state.position.map((objB) =>
        this.multiTrackingStore.multiTrackingArray.find(
          (objA) => objB.vehicleId === objA.vehicleId
        )
      );
      console.log(filterVehicles);
      let liveLatLong = filterVehicles.map((val) => {
        return {
          ...val,
          polyLine:
            this.multiTrackingStore.vehiCleLiveLatLongObj[val.vehicleId],
        };
      });

      // await this.setState({ position: liveLatLong });
      this.multiTrackingStore.vehicleLiveLatLongArray = liveLatLong;
      this.context.viewStateStore.setLiveLatLong(liveLatLong);
      console.log(liveLatLong);
      await this.setState({ updateState: 1 });
    }, interval);

    this.setState({ pageSize: 50 });
    // console.log(this.multiTrackingStore.vehiclePosition)
    this.columns = [
      {
        key: "1afd",
        // title: "Asset",
        title: this.context.translate("_FLEET_DASHBORD_SEARCHVEHICLE_VEHICLE_"),
        dataIndex: "vehicleNo",
        width: "40%",
        render: (_, record) => {
          return (
            <Space>
              <Image
                width={38}
                src={require("../assets/images/detailtracking/" +
                  record.listImage)}
              />
              {record.vehicleNo}
            </Space>
          );
          console.log(record);
        },
      },

      {
        key: "2",
        // title: "Track Date",
        title: this.context.translate("_TRACK_DATE_"),
        dataIndex: "updatedate",
        render: (val) => {
          return dayjs(val).format("DD-MM-YYYY h:mm:ss A");
        },
      },
    ];

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1);

    this.setState({ updateState: 1 });

    //   this.setState({ clientHeight: window.innerHeight });

    // window.addEventListener("resize", () => {
    //   console.log(window.innerHeight);
    //   this.setState({
    //     clientHeight: window.innerHeight,
    //   });
    // });
  }

  // componentWillUnmount() {
  //     clearInterval(this.intervalId);
  //   }
  handleMapTypeChange = async (comp) => {
    await this.setState({ mapType: comp.target.value, listUpdated: 1 });
    // console.log(comp.target.value)
  };

  async componentDidUpdate(prevProps, prevState) {
    // When the modal is visible, trigger a map refresh to ensure it renders properly
    // await this.liveLatLongGetting()

    if (
      this.state.mapModalIndex &&
      prevState.mapModalIndex !== this.state.mapModalIndex &&
      this.state.count == 0
    ) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    });
  }

  liveLatLongGetting = async () => {
    await this.multiTrackingStore.getSelectedVehicles({
      vehicleIds: this.state.vehicleIds,
    });

    console.log(this.multiTrackingStore.vehicleUpdatedPosition, "re");
    await this.setState({ updatedPos: true });
    console.log(this.state.position);
    const filterVehicles = await this.state.position.map((objB) =>
      this.multiTrackingStore.multiTrackingArray.find(
        (objA) => objB.vehicleId === objA.vehicleId
      )
    );
    console.log(filterVehicles);
    let liveLatLong = filterVehicles.map((val) => {
      return {
        ...val,
        polyLine: this.multiTrackingStore.vehiCleLiveLatLongObj[val.vehicleId],
      };
    });

    // await this.setState({ position: liveLatLong });
    this.multiTrackingStore.vehicleLiveLatLongArray = liveLatLong;
    await this.context.viewStateStore.setLiveLatLong(liveLatLong);
    console.log(liveLatLong);
    await this.setState({ updateState: 1 });
  };

  async handleChange(comp) {
    await this.setState({ selectedRowKeys: [] });
    await this.setState({ companyId: comp.value, loadList: 1 });
    await this.handleSearch();
  }

  async handleSearch() {
    this.context.viewStateStore.setLiveLatLong("");
    // this.setState({ position: [] });
    await this.multiTrackingStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({
      searchData: this.multiTrackingStore?.multiTrackingArray,
      loadList: 1,
    });
  }
  customIcon = new Icon({
    // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
    iconUrl: require("../assets/images/car.png"),
    iconSize: [38, 38], // size of the icon
  });
  createClusterCustomIcon = function (cluster) {
    return new divIcon({
      html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
      className: "custom-marker-cluster",
      iconSize: point(45, 45, true),
    });
  };

  openMapModal = async (index) => {
    // Close the previous map modal if it is open
    if (this.state.mapModalIndex !== null) {
      this.setState({ mapModalIndex: null });
    }

    console.log(index);
    // Open the new map modal and trigger resize event to ensure proper map rendering
    if (index === 0) {
      await this.setState({ mapModalIndex: index });
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 1);
      console.log("hello dammek");
      await this.setState({ count: 2 });
    } else {
      this.setState({ mapModalIndex: index, count: 0 });
    }
  };

  // Function to close the map modal
  closeMapModal = () => {
    this.setState({ mapModalIndex: null });
    if (this.state.count === 2) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 1);
    }
  };

  openModal = (value) => {
    this.setState({
      showModal: true,
      modalValue: value,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  mapShowFun = () => {
    this.setState({
      mapShow: true,
      // latLongForModal: val,
    });
  };

  toggleDrawer = () => {
    this.setState({ showSlider: !this.state.showSlider });
  };
  searchInput = async (e) => {
    const value = e.target.value;
    await this.setState({ poupSearch: value });
    if (value.length > 3 || value.length === 0) {
      if (this.state.companyId) {
        await this.multiTrackingStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          searchField: this.state.searchField,
          companyId: this.state.companyId,
          poupSearch: value,
        });
        await this.setState({ loadList: 0 });
      }
    }
  };

  searchClick = async () => {
    await this.multiTrackingStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
      poupSearch: this.state.poupSearch,
    });
    await this.setState({ loadList: 0 });
  };

  searchByVehicleNo = async (value) => {
    let inputData = value.toLowerCase();
    if (inputData.length === 0) {
      await this.setState({
        searchData: this.multiTrackingStore?.multiTrackingArray,
      });
    } else {
      const data = this.multiTrackingStore?.multiTrackingArray.filter(
        (vehicleNo) => {
          return vehicleNo.vehicleNo.toLowerCase().includes(inputData);
        }
      );
      await this.setState({ searchData: data });

      const filteredRowKeys = data.map((vehicle) => vehicle.vehicleId);

      // Update the selected row keys to reflect the filtered data
      // const updatedSelectedRowKeys = await this.state.selectedRowKeys.filter(
      //   (key) => filteredRowKeys.includes(key)
      // );
      // console.log(updatedSelectedRowKeys);
      // // Update the state with the filtered data and selected row keys
      // await this.setState({
      //   // searchData: data,
      //   selectedRowKeys: updatedSelectedRowKeys,
      // });
    }

    // else{
    //   return(
    //     this.temperatureDashboardStore.temperatureArray
    //   )
    // }
  };

  handleLoading = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  };
  render() {
    return (
      <div>
        <Row className="headerFixed" style={{ height: "50px" }}>
          {/* <div className="heading">Multi Tracking</div> */}
          <div className="heading">
            {this.context.translate("_MULTI_TRACKING_")}
          </div>
        </Row>
        {/* <Row className="headerFixed" style={{ height: "50px" }}>
          <div className="heading">Multi Tracking</div>
        </Row> */}
        <Row>
          <Col span={6} className="left-panel">
            <Card>
              <div className="sml-search-input">
                <CompanyAutocomplete
                  onChange={(val) => this.handleChange(val)}
                  style={{ width: "100%", marginBottom: "15px" }}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    style={{ width: "100%" }}
                    onChange={async (e) => {
                      let searchedArray = await this.searchByVehicleNo(
                        e.target.value
                      );
                      await this.setState({
                        searchText: e.target.value,
                      });
                    }}
                    placeholder={this.context.translate(
                      "_FLEET_DASHBORD_NAVIRIGHT_SEARCHVEHICLE_"
                    )}
                    // onChange={this.searchInput}
                  />
                  <Button
                    type="primary"
                    style={{ marginInlineStart: "8px", height: "38px" }} // Adjust margin as needed
                    onClick={async () => {
                      this.context.viewStateStore.setLiveLatLong("");
                      this.setState({ position: [], selectedRowKeys: [] });
                    }}
                  >
                    {this.context.translate("_CLEAR_ALL_")}
                  </Button>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="dataTbl-wrapper" style={{ height: "70vh" }}>
                  <Spin spinning={this.state.loading}>
                    <Table
                      bordered
                      // style={{ height: "460px", marginTop: "15px" }}
                      style={{ marginTop: "15px" }}
                      className="mainTbl"
                      rowSelection={{
                        selectedRowKeys: this.state.selectedRowKeys, // selected checkbox state
                        // if select vehicle id that time call
                        onSelectAll: (selected, selectedRows, changeRows) => {
                          // Function called when all rows are selected/deselected
                          console.log(selected, selectedRows, changeRows);
                        },
                        onSelect: async (
                          record,
                          selected,
                          selectedRows,
                          nativeEvent
                        ) => {
                          // Function called when a row is selected/deselected
                          console.log(
                            record,
                            selected,
                            selectedRows,
                            nativeEvent
                          );
                          if (!selected) {
                            let filter =
                              await this.state.selectedRowKeys.filter(
                                (val) => val !== record.vehicleId
                              );
                            let filterInfo =
                              await this.state.selectedRowsInfo.filter(
                                (val) => val.vehicleId !== record.vehicleId
                              );
                            await this.setState({
                              selectedRowKeys: filter,
                              selectedRowsInfo: filterInfo,
                            });
                          }

                          await this.multiTrackingStore.getSelectedVehicles({
                            vehicleIds: this.state.selectedRowKeys,
                            selectedRowsInfo: this.state.selectedRowsInfo,
                          });
                          const filteredRowKeys =
                            await this.state.selectedRowKeys.filter(
                              (key) => key !== undefined && key !== null
                            );
                          await this.setState({
                            position: this.state.selectedRowsInfo,
                            vehicleIds: filteredRowKeys,
                          });

                          await this.liveLatLongGetting();
                          await this.setState({ update: 1 });
                        },

                        //onchange function
                        onChange: async (selectedRowKeys, selectedRows) => {
                          const allRowsSelected =
                            selectedRowKeys.length ===
                            this.multiTrackingStore?.multiTrackingArray.length;

                          if (allRowsSelected) {
                            // Display information for the first 8 vehicles on the map
                            const first8VehicleIds = selectedRowKeys.slice(
                              0,
                              8
                            );
                            await this.multiTrackingStore.getSelectedVehicles({
                              vehicleIds: first8VehicleIds,
                              selectedVehicles: selectedRows.slice(0, 8),
                            });
                            await this.setState({
                              position: selectedRows.slice(0, 8),
                              vehicleIds: first8VehicleIds,
                              selectedRowKeys: selectedRowKeys.slice(0, 8),
                              selectedRowsInfo: selectedRows.slice(0, 8),
                            });
                            await this.liveLatLongGetting();

                            // You can display a message if needed
                            // if (selectedRowKeys.length > 8) {
                            //   Modal.warning({
                            //     title: "Alert",
                            //     content: this.context.translate(
                            //       "_ALERT_MSG_DISPLAY_8_VEHICLES_AT_TIME_"
                            //     ),
                            //   });
                            // }
                          } else if (selectedRowKeys.length > 8) {
                            Modal.warning({
                              title: "Alert",
                              content: this.context.translate(
                                "_ALERT_MSG_DISPLAY_8_VEHICLES_AT_TIME_"
                              ),
                            });
                          } else {
                            // Process selected rows as before
                            console.log(selectedRowKeys, selectedRows);

                            if (this.state.searchText.length > 0) {
                              let vehicleIds = [
                                ...this.state.selectedRowKeys,
                                ...selectedRowKeys,
                              ];
                              const uniqVehicleIds = [...new Set(vehicleIds)];
                              let selectedRowInfo = [
                                ...this.state.selectedRowsInfo,
                                ...selectedRows,
                              ];
                              let uniqueVehicleRecords = selectedRowInfo.filter(
                                (record, index, self) =>
                                  index ===
                                  self.findIndex(
                                    (r) => r.vehicleId === record.vehicleId
                                  )
                              );

                              console.log(
                                uniqueVehicleRecords,
                                this.state.selectedRowsInfo
                              );
                              if (uniqVehicleIds.length > 8) {
                                Modal.warning({
                                  title: "Alert",
                                  content: this.context.translate(
                                    "_ALERT_MSG_DISPLAY_8_VEHICLES_AT_TIME_"
                                  ),
                                });
                                await this.setState({
                                  selectedRowKeys: uniqVehicleIds.slice(0, 8),
                                  selectedRowsInfo: uniqueVehicleRecords.slice(
                                    0,
                                    8
                                  ),
                                  position: uniqueVehicleRecords.slice(0, 8),
                                });
                              } else {
                                await this.setState({
                                  selectedRowKeys: uniqVehicleIds,
                                  selectedRowsInfo: uniqueVehicleRecords,
                                  position: uniqueVehicleRecords,
                                });
                              }
                            } else {
                              console.log(selectedRows);
                              await this.setState({
                                position: selectedRows,
                                selectedRowKeys: selectedRowKeys,
                                selectedRowsInfo: selectedRows,
                              });
                            }
                          }

                          this.handleLoading();
                        },
                      }}
                      pagination={false}
                      rowKey="vehicleId"
                      columns={this.columns}
                      dataSource={[...this.state.searchData]}
                      // pagination={{ size: "small" }}
                      scroll={{ y: 490 }}
                      // scroll={{
                      //   y: this.state.clientHeight - 270,
                      //   // x: 1200,
                      // }}
                      loading={this.LiveTrackingStore?.loading}
                    />
                  </Spin>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={18}>
            <Image
              style={{ height: "93vh" }}
              src={require("../assets/images/mapBg.jpg")}
            />

            <Popover
              content={
                <Radio.Group
                  options={this.mapOptions}
                  onChange={this.handleMapTypeChange}
                  value={this.state.mapType}
                  optionType="button"
                  buttonStyle="solid"
                />
              }
              placement="right"
            >
              <FloatButton
                style={{ bottom: "15px" }}
                icon={<GlobeCentralSouthAsia />}
              />
            </Popover>
          </Col>
          {this.context.viewStateStore?.vehicleLiveLatLong?.length > 0 &&
            this.context.viewStateStore?.vehicleLiveLatLong?.map((val, ind) => (
              <>
                <Draggable handle=".handle">
                  <Card
                    className="multiTracking-card"
                    title={
                      <div className="title handle">
                        {val?.vehicleNo}
                        <Button
                          icon=<i class="bi bi-arrows-fullscreen"></i>
                          onClick={async () => {
                            // this.mapShowFun();
                            this.openMapModal(ind);
                            // await this.setState({
                            //   mapShow: true,
                            //   // latLongForModal: val,
                            // });
                            // await this.latLongForModal(val);
                          }}
                        ></Button>
                      </div>
                    }
                    style={{
                      width: "15%",
                      height: "38%",
                      marginLeft: "5&",
                      position: "absolute",
                      bottom: `${ind > 3 ? 51 : 10}%`,

                      insetInlineStart: `${
                        ind > 3
                          ? ind < 4
                            ? 34
                            : ind > 4
                            ? ind - 4 + 15 + 16 * (ind - 3)
                            : 15 + 16 * (ind - 3)
                          : 82 - 17 * ind
                      }%`,
                    }}
                  >
                    <PolyLineAndMapComponent
                      position={val}
                      mapType={this.state.mapType}
                      smallMap={true}
                    />
                  </Card>
                </Draggable>

                <Modal
                  className="multiTracking-popup"
                  key={`mapModal_${ind}`}
                  title=<div>
                    {val?.vehicleNo}
                    <CloseOutlined onClick={this.closeMapModal} />
                  </div>
                  centered
                  bodyStyle={{
                    height: "500px",
                    //width: "900px",
                    padding: "0px",
                  }} // Adjust the dimensions as needed
                  width={1000}
                  visible={this.state.mapModalIndex === ind}
                  onCancel={this.closeMapModal}
                  footer={null}
                  maskClosable={false}
                >
                  <PolyLineAndMapComponent
                    position={val}
                    mapType={this.state.mapType}
                    mapWidth={true}
                  />
                </Modal>
              </>
            ))}
        </Row>
      </div>
    );
  }
}

MultiTracking.contextType = StoreContext;
export default observer(withRouter(MultiTracking));
