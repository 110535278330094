import React from "react";
import { Col, Row, message, Space, Tooltip, Modal, Button } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import DriverBehaviourSettingStore from "../store/DriverBehaviourSettingStore";
import { DeleteFilled, EditFilled, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";
import AddButton from "./AddButton";
import ListTable from "./ListTable";
import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import DriverBehaviourSettingsAdd from "./DriverBehaviourSettingsAdd";

class CompanyDriverBehaviourSetting extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.driverBehaviorStore = new DriverBehaviourSettingStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      offset: 0,
      limit: 50,
      openConfirm: false,
      confirmLoading: false,
      loadList: 0,
      openDriverBehaviourForm: false,
      driverBehaviourId: "",
      listDataUpdate: false,
    };

    this.columns = [
      {
        // title: "Account",
        title: context.translate("_FLEET_DASHBORD_NEWPOI_CUSTOMER_"),
        dataIndex: "company",
        width: "20%",
        render: (value, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        // title: "Min. Mileage(km)",
        title: context.translate("_Min_Mileage_"),
        dataIndex: "minMileage",
        width: "13%",
        render: (value) => {
          
          if (value) {
            return <>{value} Km</>;
          } else {
            return " ";
          }
        },
      },
      {
        // title: "Top Score",
        title: context.translate("_TOP_SCORE_"),
        dataIndex: "topScore",
        width: "13%",
      },
      {
        // title: "Absolute Value",
        title: context.translate("_ABSOLUTE_VAL_"),
        dataIndex: "absValue",
        width: "13%",
        render: (value) => {
          console.log(value);
          if (value == 0 || value == null) {
            return "OFF";
          } else {
            return "ON";
          }
        },
      },
      {
        // title: "User Customized Score",
        title: context.translate("_USER_COSTIMIZED_SCORE_"),
        dataIndex: "custScore",
        width: "17%",
        render: (value) => {
          console.log(value);
          if (value == 0 || value == null) {
            return "OFF";
          } else {
            return "ON";
          }
        },
      },
      {
        // title: "Harsh Accel",
        title: context.translate("_HARSH_ACCEL_"),
        dataIndex: "harshAcce",
        width: "10%",
        render: (value) => {
          return <>{value}%</>;
        },
      },
      {
        // title: "Harsh Braking",
        title: context.translate("_HARSH_BRACKING_"),
        dataIndex: "harshBrack",
        width: "10%",
        render: (value) => {
          return <>{value}%</>;
        },
      },
      {
        // title: "EX-idling",
        title: context.translate("_EX_IDELING_"),
        dataIndex: "exIdeling",
        width: "10%",
        render: (value) => {
          return <>{value}%</>;
        },
      },
      {
        // title: "Speeding",
        title: context.translate("_SPEEDING_"),
        dataIndex: "speeding",
        width: "10%",
        render: (value) => {
          return <>{value}%</>;
        },
      },
      {
        // title: "SeatBelt",
        title: context.translate("_RPM_EXCESS_"),
        dataIndex: "rpmExcess",
        width: "12%",
        render: (value) => {
          return <>{value}%</>;
        },
      },
      {
        // title: "SeatBelt",
        title: context.translate("_FLEET_DASHBORD_SETTING_SEATBELT_"),
        dataIndex: "seatBelt",
        width: "12%",
        render: (value) => {
          return <>{value}%</>;
        },
      },
      {
        // title: "Action",
        title: context.translate("_ACTION_"),
        dataIndex: "Action",
        width: "7%",
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    // title="Edit Behaviour Setting"
                    title={this.context.translate("_EDIT_BEHAVIOUR_SETTING_")}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    this.onDelete(record);
                  }}
                >
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Tooltip
                      // title="Delete Behaviour Setting"
                      title={this.context.translate(
                        "_DELETE_BEHAVIOUR_SETTING_"
                      )}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }
  onEdit = async (record) => {
    console.log(record);
    // this.props.navigate("/driverbehavioursettingadd/" + record.id);
    await this.setState({ driverBehaviourId: record.id });
    await this.setState({ openDriverBehaviourForm: true });
  };
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      // console.log(this.props.getUserData().userCompanyId)
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      //  console.log(this.state.companyId)
      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleSearch();
    }

    await this.driverBehaviorStore.loadData({ offset: 0, limit: 50 });
    this.setState({ pageSize: 50 });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (Number(this.props.getUserData().isadmin) === 0) {
      // console.log('innnnnn');
      this.props.navigate("/login");
    }
  }
  async onDelete(record) {
    console.log(record);
    const response =
      await this.driverBehaviorStore.deleteDriverBehaviourSetting({
        id: record.id,
      });
    // console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }
  async handleSearch() {
    this.setState({ loading: true });
    await this.driverBehaviorStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }
  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination, "pagi");
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  responseMsg = async (obj) => {
    const { userCustomizedScoreShow, total, response } = obj;

    if (!userCustomizedScoreShow) {
      console.log(response, "res");
      if (response?.success === "S") {
        message.success(response.message);
        await this.driverBehaviorStore.loadData({ offset: 0, limit: 50 });
        await this.setState({ openDriverBehaviourForm: false });
      } else {
        message.error(response.message);
      }
    } else {
      if (total !== 100) {
        message.error("Kindly check Total %, Total should be 100%");
      } else {
        console.log(response, "res");
        if (response?.success === "S") {
          message.success(response.message);
          await this.driverBehaviorStore.loadData({ offset: 0, limit: 50 });
          await this.setState({ openDriverBehaviourForm: false });

          // this.props.navigate("/companydriverbehaviorsetting");
        } else {
          message.error(response.message);
        }
      }
    }
  };

  closeModal = () => {
    this.setState({ openDriverBehaviourForm: false, userId: "" });
  };
  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Driver Behaviour List</div> */}
              <div className="heading">
                {this.context.translate("_DRIVER_BEHAVIOR_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  link="/driverbehavioursettingadd"
                  title={this.context.translate("_ADD_")}
                /> */}
                <Tooltip
                  placement="left"
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      this.setState({
                        driverBehaviourId: "",
                        openDriverBehaviourForm: true,
                      });
                    }}
                  ></Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.driverBehaviorStore?.total}
            columns={this.columns}
            dataSource={this.driverBehaviorStore?.driverBehavior}
            loading={this.driverBehaviorStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openDriverBehaviourForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Driver Behaviour Settings"
            title={this.context.translate("_DRIVER_BEHAVIOR_SETTINGS_")}
            centered
            visible={this.state.openDriverBehaviourForm}
            footer={null}
            onCancel={() => {
              this.setState({ openDriverBehaviourForm: false, userId: "" });
            }}
            width={665}
          >
            <div>
              <DriverBehaviourSettingsAdd
                driverBehaviourId={this.state.driverBehaviourId}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
CompanyDriverBehaviourSetting.contextType = StoreContext;
export default observer(withRouter(CompanyDriverBehaviourSetting));
