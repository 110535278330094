import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import config from "../config";
import {
  action,

  makeObservable,
  observable,

} from 'mobx';


class FuelFilledStore {
  fuelFilled = []
  // users=[]
  // drivers=[]
  // staff=[]
  // passengers=[]
  total = 0
  loading = false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
      fuelFilled: observable,
      total: observable,
      loading: observable,
      loadData: action,
      getFuelFilledData:action,
      exportExcel:action

    });
    this.rootStore = rootStore;

  }

  async loadData({ offset, limit,companyId }) {
    this.loading=true
    console.log("fuel filled ")

    var postData = { "companyId": companyId, "offset": 0, "limit": 15, "page": 1 }
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=fuel&a=getFuelfilled", "postData=" + base64Input);

    console.log("company Data", response?.data?.data)
    this.fuelFilled = response?.data?.data
this.loading=false

  }
  async addData(values) {
    var postData = values
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=fuel&a=fuelFilled", "postData=" + base64Input);
    console.log(response)
    return response.data
  }

  async deleteRecord({fuel_Id}){
    var postData = {"tokenId":"","FuelFilled":{"fuel_Id":fuel_Id}}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=fuel&a=deleteFuel", "postData=" + base64Input);
    return response
  }
  async getFuelFilledData({fuel_Id,offset,limit}) {
    var postData = {fuel_Id:fuel_Id,offset:0,limit:1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=fuel&a=getFuelfilled", "postData=" + base64Input);
    
    return response.data?.data[0]
  }

  async exportExcel(getData) {
    window.location.href =
      config.baseURL +
      "index.php?&c=fuel&a=fuelfilledExportexcel&cid=" +
      getData.cid +
      "&colList=" +
      getData.colList;
  }
  

  

}

export default FuelFilledStore;
