import React from "react";
import {
  Row,
  Col,
  Space,
  Tooltip,
  message,
  Button,
  Modal,
  Form,
  Input,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import ListTable from "./ListTable";

import config from "../config";

import withRouter from "../withRouter";
import DeleteConfirm from "./DeleteConfirm";
import {
  CarOutlined,
  DeleteFilled,
  DownloadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { Link } from "react-router-dom";
import CompanyStore from "../store/CompanyStore";

import FairDetailsStore from "../store/FareDetailsStore";
import FareDetailsAdd from "./FareDetailsAdd";
import dayjs from "dayjs";
import AssignZoneToFare from "./AssignZoneToFare";

let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 230;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 140;
    } else if (val.title?.length > 8) {
      val.width = 130;
    } else {
      val.width = 90;
    }
  });

  return arr;
};

class FareDetails extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.fareDetailsStore = new FairDetailsStore(this);
    this.companyStore = new CompanyStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      loadData: 1,
      companyId: "",
      comp_value: "",
      listDataUpdate: false,
      openFareDetailsForm: false,
      addVersionModal: false,
      openAssignZoneForm: false,
      parameterId: "",
      assignParameterId: "",
      assignData: {},
    };
  }
  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });

      await this.handleSearch();
    }
    this.setState({ pageSize: 50 });

    this.columns = widthAdderFunc([
      {
        title: this.context.translate("_COMPANY_NAME_"),
        dataIndex: "name",
        width: "10%",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        title: this.context.translate("_VEHICLE_TYPE_"),
        dataIndex: "vehicleType",
        width: "7%",
      },
      {
        title: this.context.translate("_TARIFF_NAME_"),
        dataIndex: "tariffName",
        width: "8%",
      },
      {
        title: this.context.translate("_BASIC_FARE_"),
        dataIndex: "basicFare",
        width: "7%",
      },
      {
        title: this.context.translate("_MINIMUM_FARE_"),
        dataIndex: "minFare",
        width: "8%",
      },
      {
        title: this.context.translate("_DISTANCE_FARE_"),
        dataIndex: "distanceFare",
        width: "7%",
      },
      {
        title: this.context.translate("_TIME_FARE_"),
        dataIndex: "timeFare",
        width: "7%",
      },
      {
        title: this.context.translate("_VALID_FROM_DATETIME_"),
        dataIndex: "fromDate",
        width: "11%",
        render: (value) => {
          if (value) {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        title: this.context.translate("_VALID_TO_DATETIME_"),
        dataIndex: "toDate",
        width: "9%",
        render: (value) => {
          if (value) {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        title: this.context.translate("_WORKING_DAYS_"),
        dataIndex: "peakTimeDays",
        width: "8%",
        render: (val) => {
          const daysList = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];

          const daysValues = val?.split(",");

          const dayNames = daysValues?.map((index) => daysList[index]);

          return dayNames?.join(", ");
        },
      },
      {
        title: this.context.translate("_FAIR_TYPE_"),
        dataIndex: "fairType",
        width: "6%",
        render: (val) => {
          if (val === "1") {
            return <>Normal</>;
          } else if (val === "2") {
            return <>Holiday</>;
          }
        },
      },
      {
        title: this.context.translate("_TAX_PERCENTAGE_"),
        dataIndex: "taxPercentage",
        width: "9%",
      },
      {
        title: this.context.translate("_BOOKING_CHARGES_"),
        dataIndex: "bookingCharges",
        width: "9%",
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "action",
        width: "5%",
        render: (_, record) => {
          return (
            <>
              <Space>
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    // title="Delete Record"
                    title={this.context.translate("_DELETE_RECORD_")}
                    color={config.tooltipColorDelete}
                  >
                    <DeleteFilled style={{ color: config.deleteColor }} />
                  </Tooltip>
                </DeleteConfirm>
                <Tooltip
                  placement="bottom"
                  title={this.context.translate("_ASSIGN_ZONE_")}
                  color={config.tooltipColorEdit}
                >
                  <CarOutlined
                    onClick={(e) => {
                      this.setState({
                        openAssignZoneForm: true,
                        assignParameterId: record.parameterId,
                        assignData: record,
                      });
                    }}
                  />{" "}
                </Tooltip>
              </Space>
            </>
          );
        },
      },
    ]);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const setFareDetailsData = this.context.viewStateStore.fareDetailsObject;

      if (setFareDetailsData !== "") {
        await this.fareDetailsStore.loadData({
          companyId: this.state.companyId,
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          page: this.state.currentPage,
        });

        const singleData = await this.companyStore.getCompanyDataById(
          setFareDetailsData
        );

        await this.setState({ loadList: 1 });

        await this.setState({
          comp_value: singleData,
          companyId: setFareDetailsData,
        });
      }
      await this.setState({ listDataUpdate: false });
    }
  }

  onEdit = async (record) => {
    await this.context.viewStateStore.setFareDetails({
      companyId: record.companyId,
    });
    await this.setState({ parameterId: record.parameterId });
    await this.setState({ openFareDetailsForm: true });
  };

  async onDelete(record) {
    const response = await this.fareDetailsStore.deleteRecord({
      parameterId: record.parameterId,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.fareDetailsStore.loadData({
      companyId: this.state.companyId,
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      page: this.state.currentPage,
    });
    await this.setState({ loadData: 1, loading: false });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      currentPage: 1,
      pageSize: 50,
    });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);

    await this.setState({ comp_value: singleData });

    await this.handleSearch();
    await this.setState({ loadList: 1 });
  }

  closeModal = () => {
    this.setState({ openFareDetailsForm: false });
  };

  closeAssignZoneModal = () => {
    this.setState({ openAssignZoneForm: false });
  };

  responseMsg = async (resp) => {
    if (resp.success === "S") {
      message.success(resp.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openFareDetailsForm: false });
      await this.handleSearch();
    }
  };

  handleAddVersionForm = async (values) => {
    console.log(values);
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_TAXI_PARAMETER_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Tooltip
                  placement="left"
                  //  title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({
                        openFareDetailsForm: true,
                        parameterId: "",
                      })
                    }
                  ></Button>
                </Tooltip>
                <Tooltip
                  title={this.context.translate("_DOWNLOAD_")}
                  color={config.tooltipColorEdit}
                >
                  <Button
                    className="addButton"
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={() => {
                      this.setState({ addVersionModal: true });
                    }}
                  ></Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  value={this.state.comp_value ? this.state.comp_value : null}
                  style={{ width: 200 }}
                />
              </Space>
            </Col>
          </Row>
          <ListTable
            currentPage={this.state.currentPage}
            total={this.fareDetailsStore?.total}
            columns={this.columns}
            pageSize={this.state.pageSize}
            dataSource={this.fareDetailsStore?.taxiParameterList}
            loading={this.fareDetailsStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openFareDetailsForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal"
            title={this.context.translate("_TAXI_PARAMETERS_")}
            centered
            open={this.state.openFareDetailsForm}
            footer={null}
            onCancel={() => {
              this.setState({ openFareDetailsForm: false, parameterId: "" });
            }}
            width={685}
          >
            <div>
              <FareDetailsAdd
                parameterId={this.state.parameterId}
                companyId={this.state.companyId}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}

        <Modal
          width={400}
          title={this.context.translate("_ADD_VERSION_")}
          open={this.state.addVersionModal}
          onCancel={() => this.setState({ addVersionModal: false })}
          footer={null}
        >
          <Form layout="vertical" onFinish={this.handleAddVersionForm}>
            <Form.Item
              label={this.context.translate("_VERSION_")}
              name="reason"
              rules={[{ required: true }]}
            >
              <Input></Input>
            </Form.Item>

            <Form.Item label="CRC32" name="reason" rules={[{ required: true }]}>
              <Input></Input>
            </Form.Item>

            <div className="formBtnGroup">
              <Button
                type="primary"
                // className="formSaveButton"
                htmlType="submit"
                // loading={this.state.loading}
                style={{ height: "40px", marginInlineEnd: "10px" }}
              >
                Gennerate CRC32
              </Button>

              <Button
                type="primary"
                // className="formSaveButton"
                htmlType="submit"
                style={{
                  // color: "#fff",
                  // backgroundColor: "#4096ff",
                  width: "105px",
                  height: "40px",
                }}
                // loading={this.state.loading}
              >
                {this.context.translate("_DOWNLOAD_")}
              </Button>
            </div>
          </Form>
        </Modal>

        {this.state.openAssignZoneForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal"
            // title="Rule Details"
            title={this.context.translate("_ASSIGN_ZONE_")}
            centered
            open={this.state.openAssignZoneForm}
            footer={null}
            onCancel={() => {
              this.setState({ openAssignZoneForm: false });
            }}
            width={665}
          >
            <div>
              <AssignZoneToFare
                companyId={this.state.companyId}
                parameterId={this.state.assignParameterId}
                assignData={this.state.assignData}
                closeModal={this.closeAssignZoneModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
FareDetails.contextType = StoreContext;
export default observer(withRouter(FareDetails));
