import React from "react";
import {
  Select,
  Checkbox,
  Table,
  Row,
  Col,
  Button,
  Typography,
  Space,
  DatePicker,
  Dropdown,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import config from "../config";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import DriverPerformanceReportStore from "../store/DriverPerformanceReportStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import DriverLoginReportStore from "../store/DriverLoginReportStore";
import dayjs from "dayjs";
import { decode as base64_decode } from "base-64";
import Cookies from "js-cookie";
import withRouter from "../withRouter";
const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;
let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 250;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 150;
    } else if (val.title?.length > 8) {
      val.width = 125;
    } else {
      val.width = 110;
    }
    // console.log(val.title.length)
  });

  return arr;
};

class DriverPerformanceReportList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.driverPerformanceReportStore = new DriverPerformanceReportStore();
    this.driverLoginReportStore = new DriverLoginReportStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      selectedDriver: [],
      selectAllDrivers: false,
      companyId: "",
      modalVisible: false,
      column: "",
      filter: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      showMonthPopup: false,
      Notcompany: false,
      Notdriver: false,
      showTable: true,
      showtotaltable: false,

      selectedColumns: widthAdderFunc([
        {
          // title: "  Driver ",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
          dataIndex: "driver",
          key: "driver",
          ind: 0,
          // width: '15%'
        },
        {
          // title: "  Vehicle No ",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",
          ind: 1,
          // width: '15%'
        },
        {
          // title: "Km",
          title: context.translate("_KM_"),
          dataIndex: "totalDistance",
          key: "totalDistance",
          ind: 2,
          // width: '15%'
        },
        {
          // title: "  Login Time",
          title: context.translate("_LOGIN_TIME_"),
          dataIndex: "LoginTime",
          key: "LoginTime",
          ind: 3,
          //fixed: 'left',
          // width: '15%'
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
        },
        {
          // title: "  Logout Time",
          title: context.translate("_LOGOUT_TIME_"),
          dataIndex: "LogoutTime",

          key: "LogoutTime",
          ind: 4,
          //fixed: 'left',
          // width: '15%'
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
        },
        {
          // title: " Travel  ",
          title: context.translate("_TRAVEL_"),
          dataIndex: "travel",
          key: "travel",
          ind: 5,
          //fixed: 'left',
          // width: '15%'
        },
        {
          // title: " Idle  ",
          title: context.translate("_IDLE_"),
          dataIndex: "idle",
          key: "idle",
          ind: 6,
          //fixed: 'left',
          // width: '15%'
        },
        {
          // title: " Exc Idle  ",
          title: context.translate("_EXC_IDLE_"),
          dataIndex: "exidle",
          key: "exidle",
          ind: 7,
          //fixed: 'left',
          // width: '15%'
        },
        {
          // title: " Harsh Break   ",
          title: context.translate("_HARSH_BREAK_"),
          dataIndex: "harshBreak",
          key: "harshBreak",
          ind: 8,
          //fixed: 'left',
          // width: '15%'
        },
        {
          // title: " Harsh Acceleration   ",
          title: context.translate("_HARSH_ACCELERATION_"),
          dataIndex: "Harshaccelcounter",
          key: "Harshaccelcounter",
          ind: 9,
          //fixed: 'left',
          // width: '15%'
        },
        {
          // title: "Over Speed   ",
          title: context.translate("_OVER_SPEED_"),
          dataIndex: "overSpeed",
          key: "overSpeed",
          ind: 10,
          //fixed: 'left',
          // width: '15%'
        },
        {
          // title: "Seatbelt Violation   ",
          title: context.translate("_SEATBELT_VIOLATION_"),
          dataIndex: "seatbeltCount",
          key: "seatbeltCount",
          ind: 11,
        },
        {
          // title: " Maximum Speed as per the limit set   ",
          title: context.translate("_MAX_SPEED_AS_LIMIT_SET_"),
          dataIndex: "maxSpeed",
          key: "maxSpeed",
          ind: 12,
          //fixed: 'left',
          // width: '15%'
        },
        // {
        //     title: ' Over Speed As Per The Road Speed  ',
        //     dataIndex: 'exceedRoadSpeed',
        //     key: 'exceedRoadSpeed',

        //     //fixed: 'left',
        //     // width: '15%'
        // },
      ]),
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            Driver: this.state.selectedDriver,
            reportType: this.state.reportType,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            // userId: 1,
            // filter: this.state.filter,

            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.driverPerformanceReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.driverPerformanceReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.driverPerformanceReportStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.driverPerformanceReportStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    // await this.driverPerformanceReportStore.loadData({ offset: 0, limit: 50 });

    this.setState({ pageSize: 50 });
    await this.setState({ column: this.state.selectedColumns });

    this.totalDriverPColumns = [
      {
        // title: "Driver",
        title: this.context.translate(
          "_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"
        ),
        dataIndex: "driver",
        key: "driver",

        // width: '15%'
      },
      {
        // title: "No. of Vehicles Used ",
        title: this.context.translate("_NO_OF_VEHICLES_USED_"),
        dataIndex: "vehicleNo",
        key: "vehicleNo",

        // width: '15%'
      },
      {
        // title: "Km",
        title: this.context.translate("_KM_"),
        dataIndex: "totalDist",
        key: "totalDist",

        // width: '15%'
      },
      {
        // title: "Login Time",
        title: this.context.translate("_LOGIN_TIME_"),
        dataIndex: "tripStart",
        key: "LoginTime",
        render: (value) => {
          const formattedDateTime = dayjs(value).format(
            "DD-MM-YYYY hh:mm:ss A"
          );
          return <>{formattedDateTime}</>;
        },
      },
      {
        // title: "Logout Time",
        title: this.context.translate("_LOGOUT_TIME_"),
        dataIndex: "tripEnd",
        key: "LogoutTime",
        render: (value) => {
          const formattedDateTime = dayjs(value).format(
            "DD-MM-YYYY hh:mm:ss A"
          );
          return <>{formattedDateTime}</>;
        },
      },
      {
        // title: "Travel",
        title: this.context.translate("_TRAVEL_"),
        dataIndex: "totalTravel",
        key: "travel",

        //fixed: 'left',
        // width: '15%'
      },
      {
        // title: "Idle",
        title: this.context.translate("_IDLE_"),
        dataIndex: "totalIdle",
        key: "idle",

        //fixed: 'left',
        // width: '15%'
      },
      {
        // title: "ExIdle",
        title: this.context.translate("_EX_IDLE_"),
        dataIndex: "totalExIdle",
        key: "exidle",

        //fixed: 'left',
        // width: '15%'
      },
      {
        // title: "Harsh Break",
        title: this.context.translate("_HARSH_BREAK_"),
        dataIndex: "totalHarshBreak",
        key: "harshBreak",

        //fixed: 'left',
        // width: '15%'
      },
      {
        // title: "Harsh Acceleration",
        title: this.context.translate("_HARSH_ACCELERATION_"),
        dataIndex: "totalHarshaccel",
        key: "totalHarshaccel",
      },
      {
        // title: "Over Speed",
        title: this.context.translate("_OVER_SPEED_"),
        dataIndex: "totalOverspeed",
        key: "overSpeed",
      },
      {
        // title: "Over Speed As Per The Road Speed",
        title: this.context.translate("_OVER_SPEED_AS_PER_THE_ROAD_SPEED_"),
        dataIndex: "seatbeltCount",
        key: "exceedRoadSpeed",
      },
    ];
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }

    await this.setState({ loadData: 1 });
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false, currentPage: 1 });
    await this.setState({ companyId: comp?.value });
    await this.setState({ selectedDriver: [], selectAllDrivers: false });
    await this.driverLoginReportStore.getDriversByCompanyId({
      companyId: comp?.value,
    });
    await this.handleSearch();
    //  console.log(data,"sdcd")
  }

  async handleSearch() {
    this.setState({ Notdriver: false });
    // await this.driverPerformanceReportStore.loadData({
    //   offset: (this.state.currentPage - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,
    //   page: this.state.currentPage,
    // });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, page, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  driverSelect = async (val) => {
    console.log(val);
    await this.setState({ selectedDriver: val });
  };
  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedDriver.length === 0) {
      this.setState({ Notdriver: true });
    } else {
      this.setState({ Notdriver: false });
    }
    if (this.state.companyId && this.state.selectedDriver.length > 0) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notdriver: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.driverPerformanceReportStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,

          driverId: this.state.selectedDriver,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
        });
        // this.handleSearch();
        await this.setState({ loadData: 1 });
      }
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      // await this.setState({
      //   selectedColumns: [...this.state.selectedColumns, column],
      //   column: [...this.state.selectedColumns, column],
      // });
      const newData = this.state.selectedColumns.slice(0);
      newData.splice(column.ind, 0, column);
      await this.setState({ selectedColumns: newData, column: newData });
      console.log(this.state.selectedColumns);
    }
    await this.setState({ loadData: 1 });
  };

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };

  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }

  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss,";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  handleSelectAllDrivers = async (e) => {
    if (this.state.selectAllDrivers) {
      const allDrivers = this.driverLoginReportStore?.getDrivers.map((val) => {
        return val.driverId;
      });
      await this.setState({ selectedDriver: allDrivers });
    } else {
      await this.setState({ selectedDriver: [] });
    }
  };

  showTotal = async () => {
    await this.driverPerformanceReportStore?.driverPerformanceTotal({
      companyId: this.state.companyId,
      driverId: this.state.selectedDriver,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    });
    // console.log(this.state.fromDate,this.state.toDate,)
    await this.setState({ loadData: 1 });
  };

  render() {
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const DriverInputStyle = this.state.Notdriver
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Driver Performance</div> */}
              <div className="heading">
                {this.context.translate("_DRIVER_PERFORMANCE_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Button
                  onClick={async () => {
                    await this.showTotal();
                    await this.setState({
                      showTable: false,
                      showtotaltable: true,
                    });
                  }}
                >
                  {this.context.translate("_TOTAL_")}
                </Button>
                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.driverPerformanceReportStore?.driverPerformanceStore
                      ?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
                <Checkbox
                  checked={this.state.selectAllDrivers}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllDrivers: e.target.checked,
                    });
                    if (this.state.selectAllDrivers) {
                      await this.setState({
                        Notdriver: false,
                      });
                    }
                    console.log(e.target.checked);
                    await this.handleSelectAllDrivers();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                {/* <Select
                  onSearch={this.handleSearch}
                  mode="multiple"
                  maxTagCount="responsive"
                  value={this.state.selectedDriver}
                  onChange={(val) => {
                    this.setState({
                      selectedDriver: val,
                      // selectAllDrivers: false,
                      Notdriver: false,
                    });
                    if (
                      this.driverLoginReportStore?.getDrivers.length >
                      val.length
                    ) {
                      this.setState({ selectAllDrivers: false });
                    } else {
                      this.setState({ selectAllDrivers: true });
                    }
                  }}
                  // onChange={(val) => this.driverSelect(val)}
                  fieldNames={{ label: "name", value: "driverId" }}
                  // placeholder="Driver name"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
                  )}
                  optionFilterProp="children"
                  options={this.driverLoginReportStore?.getDrivers}
                  style={{ width: 150 }}
                  // style={DriverInputStyle}
                  status={this.state.Notdriver ? "error" : false}
                /> */}

                <Select
                  // onSearch={this.handleSearch}
                  mode="multiple"
                  value={this.state.selectedDriver}
                  maxTagCount="responsive"
                  onChange={(val) => {
                    this.setState({
                      selectedDriver: val,
                      Notdriver: false,
                    });
                    if (
                      this.driverLoginReportStore?.getDrivers.length >
                      val.length
                    ) {
                      this.setState({ selectAllDrivers: false });
                    } else {
                      this.setState({ selectAllDrivers: true });
                    }
                  }}
                  // fieldNames={{ label: "name", value: "driverId" }}
                  // placeholder="Driver name"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
                  )}
                  optionFilterProp="children"
                  // options={this.driverLoginReportStore?.getDrivers}
                  // options={this.state.filterby=='Driver' ? this.driverLoginReportStore?.getDrivers :this.driverScoreReportStore?.getVehicles }
                  style={{ width: 150 }}
                  // style={DriverInputStyle}
                  status={this.state.Notdriver ? "error" : false}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.driverLoginReportStore?.getDrivers?.map((val) => {
                    return <Option value={val.driverId}>{val.name}</Option>;
                  })}
                </Select>
                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
                <Button
                  type="primary"
                  onClick={async () => {
                    await this.showData();
                    await this.setState({
                      showTable: true,
                      showtotaltable: false,
                    });
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={350}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>
          <div>
            <div
              className="accordion-list"
              onClick={() =>
                this.setState({
                  showTable: !this.state.showTable,
                  showtotaltable: !this.state.showtotaltable,
                })
              }
            >
              {this.context.translate("_DRIVER_PERFORMANCE_")}

              <Button>
                {this.state.showTable ? (
                  <CaretDownOutlined />
                ) : (
                  <CaretUpOutlined />
                )}
              </Button>
            </div>
            {this.state.showTable && (
              <ListTable
                currentPage={this.state.currentPage}
                pageSize={this.state.pageSize}
                total={this.driverPerformanceReportStore?.total}
                columns={this.state.column}
                dataSource={
                  this.driverPerformanceReportStore?.driverPerformanceStore
                }
                loading={this.driverPerformanceReportStore?.loading}
                onChange={this.handleTableChange}
              />
            )}
          </div>

          <div>
            <div
              className="accordion-list"
              onClick={() =>
                this.setState({
                  showtotaltable: !this.state.showtotaltable,
                  showTable: !this.state.showTable,
                })
              }
            >
              {" "}
              {this.context.translate("_DRIVER_PERFORMANCE_TOTAL_")}
              <Button>
                {this.state.showtotaltable ? (
                  <CaretDownOutlined />
                ) : (
                  <CaretUpOutlined />
                )}
              </Button>{" "}
            </div>

            <>
              {this.state.showtotaltable && (
                <ListTable
                  // currentPage={this.state.currentPage}
                  // total={this.driverLoginReportStore?.total}
                  pagination={false}
                  columns={this.totalDriverPColumns}
                  dataSource={
                    this.driverPerformanceReportStore?.driverPerformanceArray
                  }
                  loading={this.driverPerformanceReportStore?.loadingData}
                  // onChange={this.handleTableChange}
                  summary={(records) => {
                    var sum = 0;

                    var totalSum = 0;
                    var totalIdleSum = 0;
                    var totalExidleSum = 0;
                    var sumofVehicles = 0;

                    var sumofKm = 0;
                    var sumofHarshBreak = 0;
                    var sumofHarshAccel = 0;

                    records.map((record) => {
                      if (record.vehicleNo) {
                        sumofVehicles =
                          parseInt(sumofVehicles) + parseInt(record.vehicleNo);
                      }

                      if (record.totalDist) {
                        sumofKm =
                          parseInt(sumofKm) + parseInt(record.totalDist);
                      }

                      if (record.totalOverspeed) {
                        sum = parseInt(sum) + parseInt(record.totalOverspeed);
                      }

                      if (record.totalHarshBreak) {
                        sumofHarshBreak =
                          parseInt(sumofHarshBreak) +
                          parseInt(record.totalHarshBreak);
                      }

                      if (record.totalHarshaccel) {
                        sumofHarshAccel =
                          parseInt(sumofHarshAccel) +
                          parseInt(record.totalHarshaccel);
                      }

                      if (record.totalTravel) {
                        var dt1 = record.totalTravel.split(":");
                        var ts1 =
                          parseInt(dt1[0] * 60 * 60) +
                          parseInt(dt1[1] * 60) +
                          parseInt(dt1[2]);
                        totalSum = parseInt(parseInt(totalSum) + parseInt(ts1));
                      }

                      if (record.totalIdle) {
                        var dt2 = record.totalIdle.split(":");
                        var ts2 =
                          parseInt(dt2[0] * 60 * 60) +
                          parseInt(dt2[1] * 60) +
                          parseInt(dt2[2]);
                        totalIdleSum = parseInt(
                          parseInt(totalIdleSum) + parseInt(ts2)
                        );
                      }

                      if (record.totalExIdle) {
                        var dt3 = record.totalExIdle.split(":");
                        var ts3 =
                          parseInt(dt3[0] * 60 * 60) +
                          parseInt(dt3[1] * 60) +
                          parseInt(dt3[2]);
                        totalExidleSum = parseInt(
                          parseInt(totalExidleSum) + parseInt(ts3)
                        );
                      }
                    });

                    var d1, h1, m1, s1;

                    s1 = totalSum;
                    m1 = Math.floor(s1 / 60);
                    s1 = s1 % 60;
                    h1 = Math.floor(m1 / 60);
                    m1 = m1 % 60;
                    d1 = Math.floor(h1 / 24);
                    h1 = h1 % 24;

                    var totalTrips = (d1 < 10 ? "0" + d1 : d1) + " days,";
                    totalTrips += h1 < 10 ? "0" + h1 : h1;
                    totalTrips += ":" + (m1 < 10 ? "0" + m1 : m1);
                    totalTrips += ":" + (s1 < 10 ? "0" + s1 : s1);

                    var d2, h2, m2, s2;

                    s2 = totalIdleSum;
                    m2 = Math.floor(s2 / 60);
                    s2 = s2 % 60;
                    h2 = Math.floor(m2 / 60);
                    m2 = m2 % 60;
                    d2 = Math.floor(h2 / 24);
                    h2 = h2 % 24;

                    var totalIdle = (d2 < 10 ? "0" + d2 : d2) + " days,";
                    totalIdle += h2 < 10 ? "0" + h2 : h2;
                    totalIdle += ":" + (m2 < 10 ? "0" + m2 : m2);
                    totalIdle += ":" + (s2 < 10 ? "0" + s2 : s2);

                    var d3, h3, m3, s3;

                    s3 = totalExidleSum;
                    m3 = Math.floor(s3 / 60);
                    s3 = s3 % 60;
                    h3 = Math.floor(m3 / 60);
                    m3 = m3 % 60;
                    d3 = Math.floor(h3 / 24);
                    h3 = h3 % 24;

                    var totalExidle = (d3 < 10 ? "0" + d3 : d3) + " days,";
                    totalExidle += h3 < 10 ? "0" + h3 : h3;
                    totalExidle += ":" + (m3 < 10 ? "0" + m3 : m3);
                    totalExidle += ":" + (s3 < 10 ? "0" + s3 : s3);

                    return (
                      <>
                        {records.length != 0 && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell>
                              <Text style={{ fontWeight: "bold" }}>
                                {this.context.translate("_TOTAL_")}
                              </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell>
                              <Text style={{ fontWeight: "bold" }}>
                                {sumofVehicles}
                              </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell>
                              <Text style={{ fontWeight: "bold" }}>
                                {sumofKm}
                              </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell />
                            <Table.Summary.Cell />

                            <Table.Summary.Cell>
                              <Text style={{ fontWeight: "bold" }}>
                                {totalTrips}
                              </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell>
                              <Text style={{ fontWeight: "bold" }}>
                                {totalIdle}
                              </Text>
                            </Table.Summary.Cell>

                            {/* <Table.Summary.Cell >
                        <Text style={{ fontWeight: "bold" }}>{totalIdle}</Text>
                      </Table.Summary.Cell> */}

                            <Table.Summary.Cell>
                              <Text style={{ fontWeight: "bold" }}>
                                {totalExidle}
                              </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell>
                              <Text style={{ fontWeight: "bold" }}>
                                {sumofHarshBreak}
                              </Text>
                            </Table.Summary.Cell>

                            {/* <Table.Summary.Cell>
                        <Text style={{ fontWeight: "bold" }}>{sumofHarshBreak}</Text>
                      </Table.Summary.Cell> */}

                            <Table.Summary.Cell>
                              <Text style={{ fontWeight: "bold" }}>
                                {sumofHarshAccel}
                              </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell>
                              <Text style={{ fontWeight: "bold" }}>{sum}</Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                      </>
                    );
                  }}
                />
              )}
            </>
          </div>
        </div>

        <Modal
          width={350}
          className="alert-model"
          title={
            <div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              Alert
            </div>
          }
          open={this.state.showMonthPopup}
          // title="Alert"
          onOk={() => this.setState({ showMonthPopup: false })}
          onCancel={() => this.setState({ showMonthPopup: false })}
          footer={
            [
              // <Button
              //   type="primary"
              //   onClick={() => this.setState({ showMonthPopup: false })}
              // >
              //   OK
              // </Button>,
            ]
          }
        >
          {/* For reports prior to 6 months please contact administrator */}
          {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
        </Modal>
      </>
    );
  }
}
DriverPerformanceReportList.contextType = StoreContext;
export default observer(withRouter(DriverPerformanceReportList));
