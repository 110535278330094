import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import config from "../config";
import {
  action,

  makeObservable,
  observable,

} from 'mobx';


class SimStore {
  sim = []
  total = 0
  loading = false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
      sim: observable,
      total: observable,
      loading: observable,
      loadData: action,
      addData:action,
      getSimData:action,
      SampleExcel:action,
      addSimNoByExcel:action

    });
    this.rootStore = rootStore;

  }

  async loadData({ offset, limit, }) {
    this.loading = true;
    console.log("sim")

    var postData = {}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=sim&a=getDetails", "postData=" + base64Input);
    console.log("sim ", response?.data?.data)

    this.sim = response?.data?.data
    this.loading=false
  }

  // async SampleExcel() {
  //   window.location.href =
  //     "http://192.168.1.100:8088/awtl_react/public/fleet/appRes/excel/Sim_Upload.xlsx";
  // }

  async SampleExcel() {
  // var FLEET_SERVER_URL = window.location.protocol + "//" + window.location.host + "/public/fleet/appRes/excel/Sim_Upload.xlsx";
  // console.log(FLEET_SERVER_URL)

  window.location.href =
  config.fleetURL +
      "/appRes/excel/Sim_Upload.xlsx";
  }
   
  async addData(values) {
      // console.log("store",simDetails)
        var postData = values
        const base64Input = await base64_encode(JSON.stringify(postData));
        var response = await postApi.getClient().post("index.php?c=sim&a=update", "postData=" + base64Input);
        // console.log(response)
        return response.data
    }

    async getSimData(id) {
      console.log("Id",id)
      var postData = {"id":id,limit:1,offset:0}
      const base64Input = await base64_encode(JSON.stringify(postData));
      var response = await postApi.getClient().post("index.php?c=sim&a=getDetails", "postData="+base64Input);
      console.log("response",response?.data?.data[0])
      return response?.data?.data[0]
    }
  

    async deleteRecord({ simId }) {
      console.log(simId)
      var postData = {"tokenId":"","simId":simId}
      const base64Input = await base64_encode(JSON.stringify(postData));
      var response = await postApi.getClient().post("index.php?c=sim&a=delete", "postData=" + base64Input);
      return response;
    
    }

    async addSimNoByExcel(excelFile) {
      this.excelLoadig = true;
      var postData = excelFile;
   
      const base64Input = await base64_encode(JSON.stringify(postData));
      var response = await postApi
        .getClient()
        .post("index.php?c=sim&a=simexcel", "postData=" + base64Input);
      this.excelLoadig = false;
      return response.data;
    }



}

export default SimStore;
