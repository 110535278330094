import { encode as base64_encode } from "base-64";
import * as d3 from "d3";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import moment from "moment";
import { baseURL } from "../components/Utility";

class AdvanceDashboardStore {
  total = 0;
  loading = false;
  counts = [];
  vehPer = [];
  jobState = [];
  fleetHours = 0;
  fuelWastage = 0;
  dayHours = 0;
  totalWorkingHrs = 0;
  nightHours = 0;
  dueCount = 0;
  overDue = 0;
  reminderDueCount = 0;
  reminderOverDue = 0;
  workinghour = [];
  MaxSpeedGraph = [];
  distanceTravelled = 0;
  avgDistperVeh = 0;
  vehicleScore = [];
  totalvehstate = [];
  MaxSpeedBars = "";
  fleetStatusArr = [];
  // trackData = []
  // totalVehicalState=[]
  // usageGraphData=[]
  // totalvehstate=[]
  // livetrack=[]

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      counts: observable,
      vehPer: observable,
      jobState: observable,
      fleetHours: observable,
      fuelWastage: observable,
      dueCount: observable,
      overDue: observable,
      reminderDueCount: observable,
      reminderOverDue: observable,
      workinghour: observable,
      MaxSpeedGraph: observable,
      distanceTravelled: observable,
      avgDistperVeh: observable,
      vehicleScore: observable,
      totalvehstate: observable,
      MaxSpeedBars: observable,
      fleetStatusArr: observable,

      loadData: action,
      getAlertData: action,
      getFleetIdleData: action,
      getJobInfoData: action,
      getWorkingHoursData: action,
      getMaintenanceRemiderData: action,
      getRenewalRemiderData: action,
      getDistClsfctnData: action,
      exportExcel: action,
      getFleetCardsStatusData: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, vehicleStatus, userId, isadmin }) {
    // this.loading = true

    var postData = {
      companyId: companyId,
      userCompany: userId,
      vehicleStatus: vehicleStatus,
      isadmin: isadmin,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicletracking&a=getDetailTrackDashboard",
        "postData=" + base64Input
      );

    console.log(response?.data?.data?.detailtrack, "vehicle");
    var liveTrackingData = response?.data?.data?.detailtrack;

    // console.log(response?.data?.data, "vehicle");
    Object.keys(liveTrackingData).map((e, i) => {
      let r = liveTrackingData[i];

      //  var diff = new Date(r.currtime) - new Date(r.updatedate);
      const diff = moment(r.currtime).diff(r.updatedate, "minutes");
      var vehicleTypeNew = r.vehicleTypeNew.split(" ").join("-");
      if (diff >= 90) {
        liveTrackingData[i].listImage = vehicleTypeNew + "-gray.png";
        liveTrackingData[i].rowBackground = "gray";
        liveTrackingData[i].currentStatus = "nodata";
      } else if (r.acc == 1 && r.speed == 0) {
        //idle)
        liveTrackingData[i].listImage = vehicleTypeNew + "-yellow.png";
        liveTrackingData[i].rowBackground = "yellow";
        liveTrackingData[i].currentStatus = "idle";
      } else if (r.acc == 0) {
        liveTrackingData[i].listImage = vehicleTypeNew + "-red.png";
        liveTrackingData[i].rowBackground = "red";
        liveTrackingData[i].currentStatus = "stop";
      } else if (r.acc == 1 && r.speed > 0) {
        liveTrackingData[i].listImage = vehicleTypeNew + "-green.png";
        liveTrackingData[i].rowBackground = "green";
        liveTrackingData[i].currentStatus = "running";
      }
      liveTrackingData[i].mapImage = liveTrackingData[i].listImage;

      try {
        require("../assets/images/detailtracking/" +
          liveTrackingData[i].listImage);
      } catch (err) {
        liveTrackingData[i].listImage = "car-icon-blue.png";
      }

      try {
        require("../assets/images/livetracking/" +
          liveTrackingData[i].mapImage);
      } catch (err) {
        liveTrackingData[i].mapImage = "car-icon-blue.png";
      }
    });
    console.log(liveTrackingData);
    this.trackData = liveTrackingData;

    this.vehicalState = response?.data?.data?.totalvehstate;

    this.total = parseInt(response?.data?.data?.TotalCount);
    console.log(this.total);
    this.loading = false;
  }

  async getFleetCardsStatusData({ companyId, userId, isadmin, fleetStatus }) {
    // this.loading = true;
    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
      status: fleetStatus,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=fleetDashboard&a=getDetailDashboardData",
        "postData=" + base64Input
      );

    var vehicleStatus = response.data?.data;
    Object.keys(vehicleStatus).map((e, i) => {
      let r = vehicleStatus[i];

      //  var diff = new Date(r.currtime) - new Date(r.updatedate);
      const diff = moment(r.currtime).diff(r.updatedate, "minutes");
      var vehicleTypeNew = r.vehicleTypeNew.split(" ").join("-");
      if (diff >= 90) {
        vehicleStatus[i].listImage = vehicleTypeNew + "-gray.png";
        vehicleStatus[i].rowBackground = "gray";
        vehicleStatus[i].currentStatus = "nodata";
      } else if (r.acc == 1 && r.speed == 0) {
        //idle)
        vehicleStatus[i].listImage = vehicleTypeNew + "-yellow.png";
        vehicleStatus[i].rowBackground = "yellow";
        vehicleStatus[i].currentStatus = "idle";
      } else if (r.acc == 0) {
        vehicleStatus[i].listImage = vehicleTypeNew + "-red.png";
        vehicleStatus[i].rowBackground = "red";
        vehicleStatus[i].currentStatus = "stop";
      } else if (r.acc == 1 && r.speed > 0) {
        vehicleStatus[i].listImage = vehicleTypeNew + "-green.png";
        vehicleStatus[i].rowBackground = "green";
        vehicleStatus[i].currentStatus = "running";
      }
      vehicleStatus[i].mapImage = vehicleStatus[i].listImage;

      try {
        require("../assets/images/detailtracking/" +
          vehicleStatus[i].listImage);
      } catch (err) {
        vehicleStatus[i].listImage = "car-icon-blue.png";
      }

      try {
        require("../assets/images/livetracking/" + vehicleStatus[i].mapImage);
      } catch (err) {
        vehicleStatus[i].mapImage = "car-icon-blue.png";
      }
    });
    this.fleetStatusArr = vehicleStatus;
    // console.log(response?.data?.data?.counts, "counts");
    // this.vehPer = response.data?.data;
  }

  async getAlertData({ companyId, userId, isadmin, offset, limit }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
      offset: 0,
      limit: 50,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=fleetDashboard&a=alertCounts",
        "postData=" + base64Input
      );
    this.counts = response.data?.data?.counts;
    console.log(response?.data?.data?.counts, "counts");
    this.vehPer = response.data?.data?.vehPer;
  }

  async getFleetIdleData({ companyId, userId, isadmin, offset, limit }) {
    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
      offset: 0,
      limit: 50,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=fleetDashboard&a=fleetIdle",
        "postData=" + base64Input
      );
    this.fleetHours = response.data?.data?.fleetHours;
    this.fuelWastage = response.data?.data?.fuelWastage;
    console.log(response?.data?.data?.fleetHours, "fleetHours");
    console.log(response?.data?.data?.fuelWastage, "fuelWastage");
  }

  async getJobInfoData({ companyId, userId, isadmin, offset, limit }) {
    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
      offset: 0,
      limit: 50,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=fleetDashboard&a=jobInfo", "postData=" + base64Input);
    console.log(response?.data?.data?.jobState);
    // this.jobState = response?.data?.data?.jobState;
    this.jobState = response?.data?.data?.jobState;
    console.log(this.jobState);

    console.log(response?.data?.data?.jobState, "jobState");
  }

  async getWorkingHoursData({ companyId, userId, isadmin, offset, limit }) {
    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
      offset: 0,
      limit: 50,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=fleetDashboard&a=workingHours",
        "postData=" + base64Input
      );
    this.dayHours = response.data?.data?.dayHours;
    this.totalWorkingHrs = response.data?.data?.totalWorkingHrs;
    this.nightHours = response.data?.data?.nightHours;

    console.log(response?.data?.data?.dayHours, "dayHours");
  }

  async getMaintenanceRemiderData({
    companyId,
    userId,
    isadmin,
    offset,
    limit,
  }) {
    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
      offset: 0,
      limit: 50,
    };
    var base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=fleetDashboard&a=maitenanceReminder",
        "postData=" + base64Input
      );

    this.dueCount = response?.data?.data?.dueCount;
    this.overDue = response?.data?.data?.overdue;
    console.log(response?.data?.data?.dueCount, "dueCount");
  }
  async getRenewalRemiderData({ companyId, userId, isadmin, offset, limit }) {
    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
      offset: 0,
      limit: 50,
    };
    var base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=fleetDashboard&a=renewalReminder",
        "postData=" + base64Input
      );
    this.reminderDueCount = response?.data?.data?.dueCount;
    this.reminderOverDue = response?.data?.data?.overdue;
    console.log(response?.data?.data?.dueCount, "reminderDueCount");
  }

  async getDistClsfctnData({ companyId, userId, isadmin, offset, limit }) {
    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
      offset: 0,
      limit: 50,
    };
    var base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=fleetDashboard&a=distanceClassification",
        "postData=" + base64Input
      );
    this.workinghour = response?.data?.data?.workinghour;

    console.log(response?.data?.data?.workinghour, "workinghour");
  }

  async getMaxSpeedData({ companyId, isadmin, offset, limit }) {
    var postData = {
      companyId: companyId,
      type: "maxspeed",
      isadmin: isadmin,
      offset: 0,
      limit: 50,
    };
    var base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=fleetDashboard&a=maxSpeedGraph",
        "postData=" + base64Input
      );
    this.MaxSpeedGraph = response?.data?.data?.MaxSpeedGraph;
    this.distanceTravelled = response?.data?.data?.distanceTravelled;
    this.avgDistperVeh = response?.data?.data?.avgDistperVeh;

    console.log(response?.data?.data?.MaxSpeedGraph, "MaxSpeedGraph");

    var root = document.documentElement;
    var MaxSpeedBars = Object.keys(this.MaxSpeedGraph).map((e, i) => {
      let r = this.MaxSpeedGraph[i];
      var cnt = i + 1;

      console.log(r);
      var MaxSpeedPrcnt = (r.maxSpeed * 100) / 240;
      root.style.setProperty("--changeMS" + cnt, MaxSpeedPrcnt + "%");
      var vehclass = "";
      var length = r.vehNo?.length;
      console.log(length);

      if (length > 15) {
        vehclass = "vehicleNum-20";
      } else if (length > 11) {
        vehclass = "vehicleNum-15";
      } else if (length > 7) {
        vehclass = "vehicleNum-8";
      } else if (length > 4) {
        vehclass = "vehicleNum-5";
      }

      return (
        <div class="bar-row">
          <span>
            <div class={`prog${cnt}`}>
              <div class="divprog-bar progress-moved">
                <div class={`divprog-bar${cnt}`}></div>
              </div>
            </div>
          </span>
          <strong class={vehclass}>{r.vehNo}</strong>
        </div>
      );
    });

    this.MaxSpeedBars = MaxSpeedBars;
  }

  async getVehScoreData({ companyId, userId, isadmin, offset, limit }) {
    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
      offset: 0,
      limit: 50,
    };
    console.log("sfasfasdfadadfadffdadsf");
    var base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=fleetDashboard&a=vehicleScore",
        "postData=" + base64Input
      );
    // this.vehicleScore = response?.data?.data?.vehicleScore;
    console.log(response?.data?.data?.vehicleScore, "vehicleScore");

    var VehicleScoreGraphData = response?.data?.data?.vehicleScore;

    if (VehicleScoreGraphData) {
      VehicleScoreGraphData.forEach((element) => {
        element.acccount = Number(element.acccount);
        element.brakecount = Number(element.brakecount);
        element.speedcount = Number(element.speedcount);
      });

      this.vehicleScore = VehicleScoreGraphData;
    }

    this.loading = false;
  }

  // async getVehScoreData({ companyId, userId, isadmin, offset, limit }) {
  //   var postData = {
  //     companyId: companyId,
  //     userId: userId,
  //     isadmin: isadmin,
  //     offset: 0,
  //     limit: 50,
  //   };
  //   console.log("sfasfasdfadadfadffdadsf")
  //   var base64Input = await base64_encode(JSON.stringify(postData));
  //   var response = await postApi
  //     .getClient()
  //     .post(
  //       "index.php?c=fleetDashboard&a=vehicleScore",
  //       "postData=" + base64Input
  //     );
  //   this.vehicleScore = response?.data?.data?.vehicleScore;
  //   console.log(response?.data?.data?.vehicleScore, "vehicleScore");

  //   this.loading = false;
  //   // $("#my_dataviz").empty();

  //   // set the dimensions and margins of the graph
  //   var margin = { top: 10, right: 80, bottom: 30, left: 20 },
  //     width = 490 - margin.left - margin.right,
  //     height = 230 - margin.top - margin.bottom;

  //   // append the svg object to the body of the page
  //   var svg = d3
  //     .select("#my_dataviz")
  //     .append("svg")
  //     .attr("width", width + margin.left + margin.right)
  //     .attr("height", height + margin.top + margin.bottom)
  //     .append("g")
  //     .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  //   // var dataByT = [
  //   //   {
  //   //     vehicleNo: "0",
  //   //     acccount: "2",
  //   //     brakecount: "2",
  //   //     exidlecount: "3",
  //   //     rpmcount: "1",
  //   //     speedcount: "2",
  //   //   },
  //   //   {
  //   //     vehicleNo: "1",
  //   //     acccount: "2",
  //   //     brakecount: "2",
  //   //     exidlecount: "3",
  //   //     rpmcount: "1",
  //   //     speedcount: "2",
  //   //   },
  //   //   {
  //   //     vehicleNo: "2",
  //   //     acccount: "3",
  //   //     brakecount: "4",
  //   //     exidlecount: "1",
  //   //     rpmcount: "1",
  //   //     speedcount: "2",
  //   //   },
  //   //   {
  //   //     vehicleNo: "3",
  //   //     acccount: "1",
  //   //     brakecount: "4",
  //   //     exidlecount: "5",
  //   //     rpmcount: "1",
  //   //     speedcount: "2",
  //   //   },
  //   //   {
  //   //     vehicleNo: "4",
  //   //     acccount: "4",
  //   //     brakecount: "4",
  //   //     exidlecount: "3",
  //   //     rpmcount: "1",
  //   //     speedcount: "2",
  //   //   },
  //   //   {
  //   //     vehicleNo: "5",
  //   //     acccount: "5",
  //   //     brakecount: "3",
  //   //     exidlecount: "4",
  //   //     rpmcount: "1",
  //   //     speedcount: "2",
  //   //   },
  //   //   {
  //   //     vehicleNo: "6",
  //   //     acccount: "1",
  //   //     brakecount: "1",
  //   //     exidlecount: "5",
  //   //     rpmcount: "1",
  //   //     speedcount: "2",
  //   //   },
  //   //   {
  //   //     vehicleNo: "7",
  //   //     acccount: "5",
  //   //     brakecount: "2",
  //   //     exidlecount: "3",
  //   //     rpmcount: "1",
  //   //     speedcount: "2",
  //   //   },
  //   //   {
  //   //     vehicleNo: "8",
  //   //     acccount: "4",
  //   //     brakecount: "1",
  //   //     exidlecount: "2",
  //   //     rpmcount: "1",
  //   //     speedcount: "2",
  //   //   },
  //   //   {
  //   //     vehicleNo: "9",
  //   //     acccount: "2",
  //   //     brakecount: "3",
  //   //     exidlecount: "1",
  //   //     rpmcount: "1",
  //   //     speedcount: "2",
  //   //   },
  //   //   {
  //   //     vehicleNo: "10",
  //   //     acccount: "4",
  //   //     brakecount: "5",
  //   //     exidlecount: "1",
  //   //     rpmcount: "2",
  //   //     speedcount: "7",
  //   //   },
  //   // ];

  //   // var dataByT = [
  //   //   {
  //   //     vehicleId: "40244",
  //   //     vehicleNo: "4693 RBA",
  //   //     vehNo: "4693",
  //   //     speedcount: 1,
  //   //     brakecount: 0,
  //   //     acccount: 0,
  //   //     rpmcount: 0,
  //   //     exidlecount: 0
  //   //   },
  //   //   {
  //   //     vehicleId: "19882",
  //   //     vehicleNo: "6401 ZDA",
  //   //     vehNo: "6401",
  //   //     speedcount: 0,
  //   //     brakecount: 0,
  //   //     acccount: 0,
  //   //     rpmcount: 0,
  //   //     exidlecount: 0
  //   //   }
  //   // ]
  //   var dataByT = this.vehicleScore;
  //   dataByT.columns = 0;

  //   // List of groups (here I have one group per column)
  //   var allGroup = [
  //     "acccount",
  //     "brakecount",
  //     "exidlecount",
  //     "rpmcount",
  //     "speedcount",
  //   ];

  //   // Reformat the data: we need an array of arrays of {x, y} tuples
  //   var dataReady = allGroup.map(function (grpName) {
  //     // .map allows to do something for each element of the list
  //     return {
  //       name: grpName,
  //       values: dataByT.map(function (d) {
  //         return { vehNo:54555, value: +d[grpName] };
  //       }),
  //     };
  //   });
  //   // I strongly advise to have a look to dataReady with
  //   // console.log(dataReady)

  //   // A color scale: one color for each group
  //   var myColor = d3.scaleOrdinal().domain(allGroup).range(d3.schemeSet2);

  //   // Add X axis --> it is a date format
  //   // var x = d3.scaleLinear()
  //   //     .domain([0, 10])
  //   //     .range([0, width]);
  //   const groups = d3
  //     .map(dataByT, function (d) {
  //       return d["vehNo"];
  //     })
  //     .keys();

  //   var x = d3.scaleBand().domain(groups).range([0, width]);
  //   console.log(x)

  //   svg
  //     .append("g")
  //     .attr("transform", "translate(0," + height + ")")
  //     .call(d3.axisBottom(x));

  //   var y = d3.scaleLinear().domain([0, 50]).range([height, 0]);
  //   svg.append("g").call(d3.axisLeft(y));

  //   // Add the lines
  //   var line = d3
  //     .line()
  //     .x(function (d) {

  //       return x(545455);
  //     })
  //     .y(function (d) {
  //       console.log("asasasasasasasas",d.values)
  //       return y(+d.values);

  //     });

  //   svg
  //     .selectAll("myLines")
  //     .data(dataReady)
  //     .enter()
  //     .append("path")
  //     .attr("d", function (d) {
  //       return line(4444);
  //     })
  //     .attr("stroke", function (d) {
  //       return myColor(d.name);
  //     })
  //     .style("stroke-width", 4)
  //     .style("fill", "none");

  //   // Add the points
  //   svg
  //     // First we need to enter in a group
  //     .selectAll("myDots")
  //     .data(dataReady)
  //     .enter()
  //     .append("g")
  //     .style("fill", function (d) {
  //       return myColor(d.name);
  //     })
  //     // Second we need to enter in the 'values' part of this group
  //     .selectAll("myPoints")
  //     .data(function (d) {
  //       return d.values;
  //     })
  //     .enter()
  //     .append("circle")
  //     .attr("cx", function (d) {
  //       return x(54555);
  //     })
  //     .attr("cy", function (d) {
  //       return y(d.value);
  //     })
  //     .attr("r", 5)
  //     .attr("stroke", "white");
  // }

  async getFleetStatusData({ companyId, userId, isadmin, offset, limit }) {
    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
      offset: 0,
      limit: 50,
    };
    var base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicletracking&a=getLiveRouteData",
        "postData=" + base64Input
      );
    this.totalvehstate = response?.data?.data?.totalvehstate;
    console.log(response?.data?.data?.totalvehstate, "totalvehstate");

    var running = this.totalvehstate?.Moving;
    var Idle = this.totalvehstate?.Idle;
    var Stopped = this.totalvehstate?.Stopped;
    var NoData = this.totalvehstate?.NoData;
    var expired = this.totalvehstate?.expired;
    var total = this.totalvehstate?.TotalVehCount;
    var expired = this.totalvehstate?.expired;

    var RunningPrcnt = (running * 100) / total;
    var StoppedPrcnt = (Stopped * 100) / total;
    var IdlePrcnt = (Idle * 100) / total;
    var NoDataPrcnt = (NoData * 100) / total;
    // var InActivePrcnt = (expired * 100) / total;
    var InActivePrcnt = (expired * 100) / (parseInt(total) + parseInt(expired));

    console.log(InActivePrcnt, NoDataPrcnt, IdlePrcnt);

    var root = document.documentElement;
    root.style.setProperty("--change2", RunningPrcnt + "%");
    root.style.setProperty("--change3", StoppedPrcnt + "%");
    root.style.setProperty("--change4", IdlePrcnt + "%");
    root.style.setProperty("--change5", NoDataPrcnt + "%");
    root.style.setProperty("--change6", InActivePrcnt + "%");
  }

  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=fleetDashboard&a=fleetStatusExcel&cid=" +
      getData.cid +
      "&userId=" +
      getData.userId +
      "&isadmin=" +
      getData.isadmin +
      "&vNo=" +
      "" +
      "&status=" +
      getData.vehicleStatus +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportPowerExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=fleetDashboard&a=getDetailDashboardDataExcel&cid=" +
      getData.cid +
      "&userId=" +
      getData.userId +
      "&isadmin=" +
      getData.isadmin +
      "&vNo=" +
      "" +
      "&status=" +
      getData.fleetStatus +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
}

export default AdvanceDashboardStore;
