import React from "react";
import {
  Input,
  Button,
  Space,
  Row,
  Col,
  Dropdown,
  Checkbox,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import withRouter from "../withRouter";

import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import GeofencesStore from "../store/GeofencesStore";
import {
  MapContainer,
  Popup,
  Circle,
  Polygon,
  Rectangle,
  ZoomControl,
} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import L from "leaflet";
import conf from "../config";
const dotIcon = L.divIcon({
  className: "dot-icon",
  iconSize: [8, 8],
  html: ' <div style="width: 20px; height: 20px; background-color: red; border-radius: 50%;"></div>',
});
dayjs.extend(customParseFormat);

class GeofencesList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.geofenceStore = new GeofencesStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      companyId: "",
      geofenceName: "",

      modalVisible: false,
      column: "",
      filter: "",
      showMap: false,
      company: false,
      modalInfoObj: [],

      selectedColumns: [
        {
          // title: 'Geofence Id',
          title: context.translate("_GEOFENCE_ID_"),
          dataIndex: "zoneId",
          key: "zoneId",
          width: "15%",
        },
        {
          // title: 'Geofence Name',
          title: context.translate("_FLEET_REPORTS_GEOFENCELIST_GEOFENCENAME_"),
          dataIndex: "name",
          key: "name",
          width: "15%",
        },

        {
          // title: 'Creation Date',
          title: context.translate("_FLEET_REPORTS_GEOFENCELIST_CREATIONDATE_"),
          dataIndex: "createDate",
          key: "createDate",
          width: "15%",
          render: (value) => {
            if (value) {
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            } else {
              return <></>;
            }
          },
        },
        {
          // title: 'Geofence Type',
          title: context.translate("_GEOFENCE_TYPE_"),
          dataIndex: "type",
          key: "type",
          width: "15%",
          render: (value) => {
            if (value == 1) {
              return "Polygon";
            } else if (value == 2) {
              return "Circle";
            } else if (value == 3) {
              return "Rectangle";
            }
          },
        },
        {
          // title: 'Customer Type',
          title: context.translate("_CUSTOMER_TYPE_"),
          dataIndex: "typeName",
          key: "typeName",
          width: "15%",
          render: (value) => {
            if (value == 1) {
              return "Retails";
            } else if (value == 2) {
              return "Food Service";
            }
          },
        },
        {
          // title: 'Bounds ',
          title: context.translate("_BOUNDS_"),
          dataIndex: "points",
          key: "points",
          width: "15%",
        },
        {
          // title: 'Radius ',
          title: context.translate("_RADIUS_"),
          dataIndex: "radius",
          key: "radius",
          width: "15%",
        },

        {
          // title: 'View On Map ',
          title: context.translate("_FLEET_SERVICE_POILIST_VIEWMAP_"),
          dataIndex: "viewonmap",
          key: "viewonmap",
          width: "15%",
          render: (value, obj) => {
            return (
              <>
                <Link
                  onClick={(e) =>
                    this.setState({ showMap: true, modalInfoObj: obj })
                  }
                >
                  {" "}
                  <img
                    src={require("../assets/images/mapIcon/gps_icon.png")}
                    width={25}
                    height={25}
                  />
                </Link>
              </>
            );
          },
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
          icon: <PlusOutlined />,
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
          icon: <PlusOutlined />,
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            interval: this.state.interval,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.geofenceStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.geofenceStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.geofenceStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.geofenceStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleSearch();
    }
    this.setState({ pageSize: 50 });
    await this.setState({ column: this.state.selectedColumns });

    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
  }
  async componentDidUpdate() {
    if (this.state.showMap) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 10);
    }
  }
  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async handleChange(comp) {
    this.setState({ company: false });
    await this.setState({ currentPage: 1, companyId: comp?.value });

    await this.handleSearch();
  }

  // async onSearch(value) {
  //     console.log(value)
  //     await this.setState({ emailId: value });
  //     this.handleSearch();
  // };

  async handleSearch() {
    this.setState({ loading: true });
    await this.geofenceStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });

    await this.setState({ listUpdated: 1 });
    this.setState({ loading: false }); //need to reload data
  }

  showData = async () => {
    console.log(this.state.company);
    if (!this.state.companyId) {
      this.setState({ company: true });
    } else {
      this.setState({ company: false });
    }
    if (this.state.companyId) {
      this.setState({ company: false });
      await this.geofenceStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
        geofence: this.state.geofenceName,
      });
      await this.setState({ listUpdated: 1 });
    }
    // console.log(this.state.fromDate,this.state.toDate,)
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  searchInput = async (e) => {
    const value = e.target.value;

    // await this.setState({searchField:value})
    if (value.length >= 3) {
      await this.geofenceStore.loadData({
        geofence: value,
        companyId: this.state.companyId,
      });
      await this.setState({ loadList: 1 });
    } else if (value.length === 0) {
      await this.geofenceStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        geofence: value,
        companyId: this.state.companyId,
      });
      await this.setState({ currentPage: 1 });
    }
  };

  render() {
    //console.log('in render1')
    // const rectangle = [
    //   [28.049209508810367, 35.214376853134176],
    //   [28.053943739820305, 35.214376853134176],
    //   [28.053943739820305, 35.22102873149111],
    //   [28.049209508810367, 35.22102873149111],
    // ];

    // const data = this.state.modalInfoObj.points
    // console.log(data)

    const mapData = this.state.modalInfoObj.points?.split(",0,").map((val) => {
      return val.split(",").map((val) => Number(val));
    });

    //   const boundString = (this.geofenceStore?.geofencesReport || [])
    //     .map((val) => val.points)
    //     .join(", ");

    //   const pointsArray = boundString.split(",");

    //   const points = [];
    //   for (let i = 0; i < pointsArray.length; i += 3) {
    //     const latitude = parseFloat(pointsArray[i]);
    //     const longitude = parseFloat(pointsArray[i + 1]);
    //  if(latitude && longitude){
    //   points.push([latitude, longitude]);
    //  }

    //   }

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Geofences List</div> */}
              <div className="heading">
                {this.context.translate("_FLEET_REPORTS_GEOFENCELIST_TITLE_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Dropdown menu={this.menuProps}>
                  <Button>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Space>
              <Col span={16}>
                <CompanyAutocomplete
                  onChange={(value) => this.handleChange(value)}
                  // allowClear={true}
                  style={{ width: 200 }}
                  status={this.state.company ? "error" : false}
                />
              </Col>

              <Col>
                <Input
                  value={this.state.geofenceName}
                  style={{ width: 200 }}
                  // placeholder='Search By Geofence Name'
                  placeholder={this.context.translate(
                    "_SEARCH_BY_GEOFENCE_NAME_"
                  )}
                  onChange={(e) => {
                    this.setState({ geofenceName: e.target.value });
                    this.searchInput(e);
                  }}
                />
              </Col>
              {/* <Col>
                <Button
                  type="primary"
                  onClick={async () => {
                    await this.showData();
                    this.setState({ currentPage: 1 });
                  }}
                >
               
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Col> */}
            </Space>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.geofenceStore?.total}
            columns={this.state.column}
            dataSource={this.geofenceStore?.geofencesReport}
            loading={this.geofenceStore?.loading}
            onChange={this.handleTableChange}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />

          {this.state.showMap && (
            <Modal
              // title="Geofence on Map"
              title={this.context.translate("_FLEET_GEOFENCE_REPORT_")}
              open={this.state.showMap}
              centered
              // bodyStyle={{ height: 1000 }}
              width={850}
              height={400}
              onOk={() => this.setState({ showMap: false })}
              onCancel={() => this.setState({ showMap: false })}
              footer={false}
            >
              {this.state.modalInfoObj?.points?.length > 2 ? (
                <MapContainer
                  ref={this.mapRef}
                  zoom={15.3}
                  // center={[24.7136, 46.6753]}
                  center={[
                    this.state.modalInfoObj?.points?.split(",")[0] ||
                      conf.mapCenter,
                    this.state.modalInfoObj?.points?.split(",")[1] ||
                      conf.mapCenter,
                  ]}
                  style={{ height: "400px", width: "800px" }}
                  zoomControl={false}

                  // bounds={this.state.modalInfoObj ? [[this.state.modalInfoObj.latitude, this.state.modalInfoObj.longitude], [this.state.modalInfoObj.latitude, this.state.modalInfoObj.longitude]] : []}
                >
                  {/* <ZoomControl position="bottomRight"></ZoomControl> */}
                  <ReactLeafletGoogleLayer />

                  {this.state.modalInfoObj?.points &&
                    this.state.modalInfoObj?.type == 1 && (
                      <Polygon
                        // positions={this.state.modalInfoObj?.points}
                        positions={mapData}
                        pathOptions={{ color: "red" }}
                      >
                        <Popup>{this.state.modalInfoObj?.name}</Popup>
                      </Polygon>
                    )}

                  {this.state.modalInfoObj?.type == 2 &&
                    this.state.modalInfoObj?.points && (
                      <Circle
                        center={{
                          lat:
                            this.state.modalInfoObj?.points.split(",")[0] || 0,
                          lng:
                            this.state.modalInfoObj?.points.split(",")[1] || 0,
                        }}
                        radius={this.state.modalInfoObj?.radius}
                        pathOptions={{ color: "red" }}
                      >
                        <Popup>{this.state.modalInfoObj?.name}</Popup>
                      </Circle>
                    )}

                  {this.state.modalInfoObj?.type == 3 &&
                    this.state.modalInfoObj?.points && (
                      <Rectangle
                        bounds={mapData}
                        pathOptions={{ color: "red" }}
                      ></Rectangle>
                    )}
                </MapContainer>
              ) : (
                <MapContainer
                  ref={this.mapRef}
                  zoom={15.3}
                  // center={[24.7136, 46.6753]}
                  center={conf.mapCenter}
                  style={{ height: "400px", width: "800px" }}
                  zoomControl={false}

                  // bounds={this.state.modalInfoObj ? [[this.state.modalInfoObj.latitude, this.state.modalInfoObj.longitude], [this.state.modalInfoObj.latitude, this.state.modalInfoObj.longitude]] : []}
                >
                  {/* <ZoomControl position="bottomRight"></ZoomControl> */}
                  <ReactLeafletGoogleLayer />
                </MapContainer>
              )}
            </Modal>
          )}
        </div>
      </>
    );
  }
}
GeofencesList.contextType = StoreContext;
export default observer(withRouter(GeofencesList));
