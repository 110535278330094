import React, { Component } from "react";
import {
  Button,
  Space,
  Col,
  message,
  Form,
  Input,
  Row,
  Select,
  Upload,
  Modal,
  Tooltip,
  Spin,
  Checkbox,
} from "antd";
import "./Form.css";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import UsersStore from "../store/UsersStore";
import CompanyStore from "../store/CompanyStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import DepartmentStore from "../store/DepartmentStore";
import { Link } from "react-router-dom";
import config from "../config";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import RoleStore from "../store/RoleStore";
import conf from "../config";
import Cookies from "universal-cookie";
import { decode as base64_decode, encode as base64_encode } from "base-64";

const layout = {
  labelCol: { span: 23 },
  wrapperCol: { span: 30 },
};
const { Option } = Select;
class UserAdd extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      companyId: "",
      loadList: 0,
      offset: "",
      limit: "",
      searchField: "",
      getRolesList: [],
      departmentId: "",
      userId: -1,
      fileList: [],
      showDeptForm: false,
      loading: false,
      isadmin: 0,
      userType: 1,
      loadingSpin: false,
      disabledField: false,
    };
    this.userStore = new UsersStore();
    this.roleStore = new RoleStore();
    this.companyStore = new CompanyStore();
    this.departmentStore = new DepartmentStore(this);
    // this.selectDepartment = this.selectDepartment.bind(this);
    this.beforeFileupload = this.beforeFileupload.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.cookies = new Cookies();
    //  console.log(this.props.getUserData().roleId)
    console.log(JSON.parse(base64_decode(this.cookies.get("data"))).roleId);
  }

  normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  onUserDetailsSubmit = async (fieldsValue) => {
    if (this.state.fileList.size > conf.photoUploadSize) {
      message.error(this.context.translate("_FILE_SIZE_UPTO_10_MB"));
      return;
    }
    console.log(this.state.companyId);
    // }

    //const formData = new FormData();
    // this.state.fileList.forEach((file) => {
    //   fieldsValue.append("files[]", file);
    // });
    // this.setState({ loading: true });
    // console.log(fieldsValue);
    console.log(fieldsValue);

    const data = {
      ...fieldsValue,
      userId: this.state.userId,
      isadmin: this.state.isadmin,
      companyId: this.state.companyId,
      userType: this.state.userType,
      // departmentId:
      //   fieldsValue.departmentId?.length > 0
      //     ? fieldsValue.departmentId[0].value
      //     : fieldsValue.departmentId === undefined
      //     ? null
      //     : fieldsValue.departmentId,

      photo: this.state.photo,
      roleId:
        JSON.parse(base64_decode(this.cookies.get("data"))).isadmin == 0
          ? JSON.parse(base64_decode(this.cookies.get("data"))).roleId
          : fieldsValue.roleId,
          // isCustomer:true? 1:0
    };
    console.log(data);
    const resp = await this.userStore.addData(data);
    //console.log(resp.data.success)
    await this.props.response(resp);
    // if (resp.data.success == "S") {
    //   message.success(resp.data.message);
    //   this.props.navigate("/companyuserlist");
    // } else if (resp.data.success == "F0") {
    //   resp.data.errors.map((value) => {
    //     message.error(value["username"]);
    //   });
    // }
    this.setState({ loading: false });
  };

  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      this.context.viewStateStore.setUserAddStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });

      // await this.setState({userId:this.props.getUserData().userId})
      await this.setState({ isadmin: this.props.getUserData().isadmin });
    }
    await this.departmentStore.getUserDepartmentData();
    await this.setState({ loadList: 1 });
    console.log(this.props.userId);
    const userId = this.props.userId;

    if (userId) {
      this.filldata(userId);
      this.setState({ userId: userId });
    }
  }
  async filldata(userId) {
    await this.setState({ loadingSpin: true });
    const getUserData = await this.userStore.getUserData(userId);
    await this.setState({ companyId: getUserData.companyId });
    const singleData = await this.companyStore.getCompanyDataById(
      getUserData.companyId
    );

    const data = await this.roleStore.getRole(this.state.companyId);
    await this.setState({ getRolesList: data });
    console.log(data);
    this.formRef.current.setFieldsValue({
      // photo: getUserData.photo,
      mobileno: getUserData.mobileno,
      name: getUserData.name,
      updateDate: getUserData.updateDate,
      username: getUserData.username,
      emailId: getUserData.emailId,
      companyId: singleData,
      userId: getUserData.userId,

      status: Number(getUserData.status),
      password: getUserData.password,
      reEnterPass: getUserData.password,
      address: getUserData.address,
      roleId: getUserData.roleId ? Number(getUserData.roleId) : null,
      // departmentId: getUserData.departmentId ? getUserData.departmentId : "",
      departmentId: getUserData.departmentId,
      countryCode: getUserData.countryCode,
      isCustomer :getUserData.isCustomer ? 1 :0
      // password:getUserData.password,
    });

    this.setState({
      photo: getUserData.photo,
      userId: getUserData.userId,
      isadmin: getUserData.isadmin,
      userType: getUserData.userType,
    });

    if (this.props.userId && this.props.getUserData().isadmin === 3) {
      this.setState({ disabledField: true });
    }
    await this.setState({ loadingSpin: false });
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    // this.formRef.current.setFieldsValue({companyId: comp.value})
    if (!this.props.userId) {
      this.context.viewStateStore.setUserAddStates({ companyId: comp.value });
    }
    this.getRolesFromStore();
  }

  // async handleSearch() {
  //   await this.userStore.loadData({
  //     offset: (this.state.currentPage - 1) * this.state.pageSize,
  //     limit: this.state.pageSize,
  //     searchField: this.state.searchField,
  //     companyId: this.state.companyId,
  //   });
  //   await this.setState({ loadList: 1 });
  // }

  getUploadData = async (fileUp) => {
    console.log(fileUp.fileList);
    // if (fileUp.fileList[0]?.type == "image/png") {
    let fileinfo = fileUp.file.response;
    if (fileinfo?.success == "S") {
      // console.log(fileinfo.data)
      console.log("innnnnnnn");
      await this.setState({ photo: fileinfo.data });
    }
    fileUp.fileList[0].url = config.fleetURL + fileinfo?.folderName;
    this.userStore.setPhotoFromDb(fileUp.fileList);
    this.setState({ update: 1 });
    // }
  };

  async beforeFileupload(file) {
    this.setState({ ...this.state.fileList, file });
    return false;
  }

  getRolesFromStore = async () => {
    await this.roleStore.getRole(this.state.companyId);
    console.log(this.roleStore.roles);
    await this.setState({ loadList: 1 });
    //await this.setState({getRolesList:getRole})
    // console.log(getRole)
  };
  getRoles() {
    const role = this.state.getRolesList;
    console.log("heollo", role);
    console.log(role, "role");
    if (role) {
      return role.map((value) => {
        return <Select.Option value={value.roleId}>{value.name}</Select.Option>;
      });
    }
  }

  onDeptDetailsSubmit = async (fieldValues) => {
    console.log(fieldValues);
    const data = { department: fieldValues };
    // await this.departmentStore.addDepartment(data);
    await this.departmentStore.getUserDepartmentData();
    const response = await this.departmentStore.addDepartment(data);

    console.log(response);
    if (response.data.success === "S") {
      await this.formRef.current.setFieldsValue({
        // departmentName: {
        //        label: response.data.data.departmentName,
        //        value: response.data.data.departmentId,
        //      }
        departmentId: Number(response.data.data.departmentId),
      });

      // this.formRef.current.setFieldsValue({departmentName:parseInt(response.data.data.departmentId)})
      message.success(response.data.message);
      await this.setState({ showDeptForm: false });
      this.setState({ loadList: 1 });
      // await console.log(response);
    } else {
      await message.error(response.data.message);
    }
    await this.departmentStore.getUserDepartmentData();
    // await this.setState({ loadList: 1});
    this.setState({ loadList: 1 });
  };

  selectDepartment = async (value) => {
    console.log(value);
    await this.setState({ departmentId: value });
    this.formRef.current.setFieldsValue({ departmentId: value });
  };

  validateReenterPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error("The two passwords that you entered do not match!")
      );
    },
  });

  validatePhoneNumber = (value) => {
    // Check if value contains only numbers
    const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1.length);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ mobilenoes: value1 });
    } else {
      this.formRef.current.setFieldsValue({ mobileno: "" });
    }
  };
  validateFile = (file) => {
    this.setState({ fileList: file });
    console.log(file);
    const allowedExtensions = [".jpeg", ".jpg", ".png"];
    const fileName = file.name;
    const fileExtension = fileName.slice(
      ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
    );
    if (!allowedExtensions.includes("." + fileExtension.toLowerCase())) {
      message.error("Only JPEG and PNG files are allowed");
      return false;
    }
    const maxSize = conf.photoUploadSize;
    if (file.size > maxSize) {
      message.error(this.context.translate("_FILE_SIZE_UPTO_10_MB"));
      return false;
    }
    return true;
  };
  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          {/* change style */}
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              {...layout}
              ref={this.formRef}
              layout="vertical"
              name="nest-messages"
              onFinish={this.onUserDetailsSubmit}
              className="formClass" //change
            >
              <Row className="overflowFormInputs">
                {/* css class */}
                <Col span={12} className="formColumnClass">
                  {/* css class */}

                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                      // style={this.context.isAdmin===1? {display:'none'}:''}
                    >
                      <CompanyAutocomplete
                        onChange={this.handleChange}
                        style={{ width: "100%" }}
                        companyId={this.state.companyId}
                      />
                    </Form.Item>
                  )}
                  {/* <Form.Item
                    name="companyId"
                    label={this.context.translate("_ACCOUNT_")}
                    rules={[{ required: true }]}
                    // style={this.context.isAdmin===1? {display:'none'}:''}
                  >
                    <CompanyAutocomplete
                      onChange={this.handleChange}
                      style=  {{ width: "100%" }}
                      companyId={this.state.companyId}
                    />
                  </Form.Item> */}
                  <Form.Item
                    name="name"
                    label={this.context.translate("_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      style={{ width: "30%" }}
                      name="countryCode"
                      // label="Contact 1"
                      label={" "}
                      // rules={[{ required: true }]}
                      rules={[{ required: true }]}
                      initialValue={+966}
                    >
                      <Select>
                        <Select.Option value={91}>+91</Select.Option>
                        <Select.Option value={966}>+966</Select.Option>
                        {/* 966 = saudi arabia */}
                        <Select.Option value={971}>+971</Select.Option>
                        {/* 971 = uae */}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      style={{ width: "65%" }}
                      name="mobileno"
                      // label="Phone Number 1"
                      label={this.context.translate("_MOBILE_NUMBER_")}
                      rules={[
                        {
                          pattern: /^\d{8,10}$/,
                          // message: "Enter a 7 or 10 digit number",
                          message: this.context.translate(
                            "_Enter_7_or_10_Digit_Number_"
                          ),
                        },
                      ]}
                      // rules={[{ required: true}]}
                    >
                      <Input
                        onChange={(e) =>
                          this.validatePhoneNumber(e.target.value)
                        }
                      />
                    </Form.Item>
                  </div>

                  {/* <Form.Item
                  name="mobileno"
                  label={this.context.translate("_MOBILE_NUMBER_")}
                  // rules={[{min:7,max: 10, message: "Mobile number should be 7 or 10 digits only"}]}
                  rules={[
                    {
                      pattern: /^(\d{7}|\d{10})$/,
                      message: "Enter a 7 or 10 digit number",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => this.validatePhoneNumber(e.target.value)}
                  />
                </Form.Item> */}

                  <Form.Item
                    name="emailId"
                    label={this.context.translate("_EMAIL_")}
                    rules={[{ type: "email", required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="address"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_NEWPOI_ADDRESS_"
                    )}
                  >
                    <Input />
                  </Form.Item>

                  {/* <Form.Item
                         label="Re-Enter Password"
                         name={['account', 'reEnterPassword']}
                         rules={[{ required: true, message: 'Please input your password!' }]}
                     >
                         <Input.Password />
                     </Form.Item> */}

                  <Form.Item
                    name="status"
                    label={this.context.translate("_STATUS_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      // placeholder="Select Status"
                      placeholder={this.context.translate(
                        "_FLEET_USER_USERDETAIL_STATUSDDL_SELECT_"
                      )}
                      autoClearSearchValue
                    >
                      <Select.Option value={1}>
                        {this.context.translate(
                          "_FLEET_USER_USERLIST_STATUS_ACTIVE_"
                        )}
                      </Select.Option>

                      <Select.Option value={0}>
                        {this.context.translate(
                          "_FLEET_USER_USERLIST_STATUS_INACTIVE_"
                        )}
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  {this.props.getUserData().isadmin === 1 && (
                    <>
                      <Form.Item name="isCustomer" label=""  valuePropName="checked" style={{marginTop:"20px"}}>
                        <Checkbox  >{this.context.translate('_DEPOSITOR_')}</Checkbox>
                      </Form.Item>
                    </>
                  )}
                </Col>

                <Col span={12} className="formColumnClass">
                  <Row
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {/* <Col span={12} > */}
                    <Form.Item
                      name="departmentId"
                      label={this.context.translate("_DEPARTMENT_")}
                      style={{ width: "70%" }}
                    >
                      <Select
                        // fieldNames={{
                        //   label: "departmentName",
                        //   value: "departmentId",
                        // }}
                        value={this.state.departmentId}
                        // placeholder="Departments"
                        placeholder={this.context.translate(
                          "_SELECT_DEPARTMENT_"
                        )}
                        onSelect={this.selectDepartment}
                        // optionFilterProp="children"
                        // options={this.departmentStore?.departments}
                        showSearch
                        filterOption={(inputValue, option) => {
                          if (option.children) {
                            return (
                              option.children
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            );
                          }
                        }}
                      >
                        {this.departmentStore?.departments?.map((val) => {
                          return (
                            <Option value={val.departmentId}>
                              {val.departmentName}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    {/* </Col> */}
                    <Col span={2} />
                    <Form.Item label=" ">
                      <div>
                        <Tooltip
                          // title="Add new Department"
                          title={this.context.translate("_ADD_NEW_DEPARTMENT_")}
                          placement="bottom"
                          color={config.tooltipColorEdit}
                        >
                          <Button
                            type="primary"
                            style={{ height: "48px" }}
                            onClick={() => {
                              console.log("click");
                              this.setState({ showDeptForm: true });
                            }}
                          >
                            <PlusOutlined />
                          </Button>
                        </Tooltip>
                      </div>
                    </Form.Item>
                  </Row>
                  {this.props.getUserData().isadmin !== 0 && (
                    <Form.Item
                      name="roleId"
                      label={this.context.translate(
                        "_FLEET_USER_USERDETAIL_ROLE_"
                      )}
                    >
                      <Select>
                        {this.roleStore?.roles.map((val) => {
                          return (
                            <Select.Option value={val.roleId}>
                              {val.name}
                            </Select.Option>
                          );
                        })}
                        {/* <Select.Option value={-1}>No Role</Select.Option> */}
                        <Select.Option value={-1}>
                          {this.context.translate("_NO_ROLE_")}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item
                    autoComplete="off"
                    name="username"
                    label={this.context.translate(
                      "_FLEET_COMPANY_USERDETAIL_USERNAME_"
                    )}
                    rules={[{ required: true }]}
                  >
                    <Input
                      autoComplete="off"
                      disabled={this.state.disabledField}
                    />
                  </Form.Item>

                  <Form.Item
                    label={this.context.translate(
                      "_FLEET_USER_USERDETAIL_PASSWORD_"
                    )}
                    name="password"
                    rules={[{ required: true }]}
                  >
                    <Input.Password id="form-password" />
                  </Form.Item>
                  {/* <Form.Item
                name="reEnterPass"
                label={this.context.translate(
                  "_FLEET_USER_USERDETAIL_REPASSWORD_"
                )}
                dependencies={["password"]}
                rules={[
                  { required: true, message: "Please re-enter your password!" },
                  this.validateReenterPassword,
                ]}
              >
                <Input.Password />
              </Form.Item> */}

                  <br />

                  <Upload
                    style={{ marginTop: "15px" }}
                    name="photo"
                    action={
                      config.baseURL + "/index.php?c=company&a=fileupload"
                    }
                    listType="picture"
                    data={{ fileType: "userphoto", fieldName: "photo" }}
                    onChange={this.getUploadData}
                    onRemove={async () => {
                      await this.setState({ photo: "", fileList: [] });
                      await this.userStore.setPhotoFromDb([]);
                    }}
                    maxCount={1}
                    accept=".jpg,.png"
                    // Add the beforeUpload prop to perform file extension validation
                    beforeUpload={(file) => this.validateFile(file)}
                    {...this.userStore.photoFromDb}
                  >
                    <Button className="imgupload" icon={<UploadOutlined />}>
                      {this.context.translate("_FLEET_USER_USERDETAIL_PHOTO_")}
                    </Button>
                  </Upload>
                </Col>
              </Row>

              <div className="formBtnGroup">
                {/* css class */}
                <Button
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal();
                    // this.formRef.current?.resetFields();
                  }}
                >
                  {/* css class */}
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  {/* css class */}
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
        <Modal
          open={this.state.showDeptForm}
          // title="Add Department"
          title={this.context.translate("_ADD_DEPARTMENT_")}
          maskClosable={false}
          onOk={() => this.setState({ showDeptForm: false })}
          onCancel={() => this.setState({ showDeptForm: false })}
          footer={false}
          width={400}
        >
          <Form
            name="nest-messages"
            layout="vertical"
            onFinish={this.onDeptDetailsSubmit}
            style={{ padding: "0px 10px" }}
          >
            <Form.Item
              name="dptname"
              label={this.context.translate("_NAME_")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <div className="formBtnGroup">
              {/* css class */}
              <Button
                className="formCancelButton"
                onClick={() => this.setState({ showDeptForm: false })}
              >
                {/* css class */}
                {/* Cancel */}
                {this.context.translate("_FLEET_DASHBORD_ROUTEADD_CANCELBTN_")}
              </Button>
              <Button
                type="primary"
                className="formSaveButton"
                htmlType="submit"
                loading={this.state.loading}
              >
                {/* css class */}
                {/* Save */}
                {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
              </Button>
            </div>
            {/* <Row justify={"end"}>
              <Space style={{ padding: "10px" }}>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button onClick={() => this.setState({ showDeptForm: false })}>
                  Cancel
                </Button>
              </Space>
            </Row> */}
          </Form>
        </Modal>
      </div>
    );
  }
}
UserAdd.contextType = StoreContext;
export default observer(withRouter(UserAdd));
