import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi ,baseURL} from '../components/Utility'
import {
  action,
  
  makeObservable,
  observable,
 
} from 'mobx';


class OverTimeReportStore {
  overTimeReport=[]
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        overTimeReport: observable,
      // total:observable,
      loading:observable,
      loadData:action,
     
    });
     this.rootStore = rootStore;
    
  }

  async loadData({companyId,driverId,offset,limit,fromDate,toDate}) {
    this.loading = true;
    // console.log("vehicle list")
    var postData = {"companyId":companyId,"driverId":driverId,"fromDate":fromDate,"toDate":toDate,"offset":offset,"limit":limit,"page":1}

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=driver&a=overTimecharge", "postData=" + base64Input);

    console.log(response?.data?.data)
    this.overTimeReport = response?.data?.data?.overtimecharge;
    this.total = parseInt(response?.data?.data?.TotalCount);
    console.log(this.total)
    this.loading = false;
}

async exportExcel(getData) {
  console.log("getData", getData);
  let lang = "english";
  window.location.href =
    baseURL +
    "index.php?c=driver&a=exportovertimeexcel&cid=" +
    getData.companyId +
    "&userId=" +
      getData.userId +
    "&did=" +
    getData.vehicleId +
    // "&int="+
    // getData.interval+
    "&fD=" +
    getData.fromDate +
    "&tD=" +
    getData.toDate +
    "&colList=" +
    getData.colList +
    "&language=" +
    lang;
}

async exportpdf(getData) {
  let lang = "english";
  window.location.href =
    baseURL +
    "index.php?c=driver&a=exportovertimepdf&cid=" +
    getData.companyId +
    "&userId=" +
      getData.userId +
    "&did=" +
    getData.vehicleId +
    // "&int="+
    // getData.interval+
    "&fD=" +
    getData.fromDate +
    "&tD=" +
    getData.toDate +
    "&colList=" +
    getData.colList +
    "&language=" +
    lang;
}

async exportcsv(getData) {
  let lang = "english";
  window.location.href =
    baseURL +
    "index.php?c=driver&a=exportovertimecsv&cid=" +
    getData.companyId +
    "&userId=" +
      getData.userId +
    "&did=" +
    getData.vehicleId +
    // "&int="+
    // getData.interval+
    "&fD=" +
    getData.fromDate +
    "&tD=" +
    getData.toDate +
    "&colList=" +
    getData.colList +
    "&language=" +
    lang;
}
async exportxml(getData) {
  let lang = "english";
  window.location.href =
    baseURL +
    "index.php?c=driver&a=exportovertimexml&cid=" +
    getData.companyId +
    "&userId=" +
      getData.userId +
    "&did=" +
    getData.vehicleId +
    // "&int="+
    // getData.interval+
    "&fD=" +
    getData.fromDate +
    "&tD=" +
    getData.toDate +
    "&colList=" +
    getData.colList +
    "&language=" +
    lang;
}

}

export default OverTimeReportStore;
