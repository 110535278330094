import React, { Component } from "react";
import { Button, Col, Form, Input, Row, Select, Spin, Upload } from "antd";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";

import CompanyAutocomplete from "./CompanyAutocomplete";
import config from "../config";

import CompanyStore from "../store/CompanyStore";
import AdvertisementStore from "../store/AdvertisementStore";
import { UploadOutlined } from "@ant-design/icons";

class AdvertisementAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loadingSpin: false,
      companyId: "",
      id: -1,
      photo: "",
      showType: "",
      folderName: "",
    };

    this.advertisementStore = new AdvertisementStore(this);
    this.companyStore = new CompanyStore(this);
  }
  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      this.context.viewStateStore.setAdvertisementStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
    }

    const id = this.props.id;

    if (id) {
      await this.filldata(id);
    }

    this.setState({ update: 1 });
  }

  async filldata(id) {
    const advertisementData =
      await this.advertisementStore.getAdvertisementData({
        id,
        companyId: this.props.companyId,
      });

    await this.setState({
      companyId: advertisementData.companyId,
      id: advertisementData.id,
    });
    const singleData = await this.companyStore.getCompanyDataById(
      advertisementData.companyId
    );

    this.formRef.current.setFieldsValue({
      companyId: singleData,
      id: advertisementData.id,
      description: advertisementData.description,
      name: advertisementData.name,

      sequence: advertisementData.sequence,
      status: Number(advertisementData.status),
      showType: advertisementData.showType,
    });
    this.setState({ folderName: advertisementData.path });
  }

  handleFormSubmit = async (values) => {
    const data = {
      ...values,
      id: this.state.id,
      companyId: this.state.companyId,
      folderName: this.state.folderName,
      photo: this.state.photo,
    };
    const response = await this.advertisementStore.addData(data);
    await this.props.response(response);
  };
  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    if (!this.props.id) {
      this.context.viewStateStore.setAdvertisementStates(comp.value);
    }
    await this.setState({ companyId: comp.value });
  }

  closeModal = () => {
    this.props.closeModal();
  };

  getUploadData = async (fileUp) => {
    // if (fileUp.fileList[0]?.type == "image/png") {
    let fileinfo = fileUp.file.response;

    if (fileinfo?.success == "S") {
      await this.setState({ photo: fileinfo.data });
      // console.log(fileinfo.folderName)
      this.setState({ folderName: fileinfo.folderName });
    }
    // console.log(config.fleetURL,fileinfo?.folderName)
    // fileUp.fileList[0].url = config.fleetURL + fileinfo?.folderName;

    this.advertisementStore.setPhotoFromDb(fileUp.fileList);
    this.setState({ update: 1 });
    // }
  };

  validateNumber = (value, field) => {
    const value1 = value.replace(/[^0-9+]*/g, "");
    if (value1 > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              name="fileimport"
              layout="vertical"
              onFinish={this.handleFormSubmit}
              style={{ padding: "0px 10px" }}
              ref={this.formRef}
              initialValues={{ photo: this.state.photo }}
              className="formClass"
            >
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => this.handleChange(value)}
                        style={{ width: "100%" }}
                        value={this.state.companyId}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="showType"
                    label={this.context.translate("_IMAGE_VIDEO_TYPE_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      onSelect={(val) => this.setState({ showType: val })}
                      options={[
                        {
                          value: "1",
                          label: this.context.translate("_FULL_BANNER_"),
                        },
                        {
                          value: "2",
                          label: this.context.translate("_MIDDLE_BANNER_"),
                        },
                        {
                          value: "3",
                          label: this.context.translate("_BOTTOM_BANNER_"),
                        },
                      ]}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    name="name"
                    label={this.context.translate("_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="description"
                    label={this.context.translate("_DESCRIPTION_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
           
             
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="sequence"
                    label={this.context.translate("_SEQUENCE_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      onChange={(e) =>
                        this.validateNumber(e.target.value, "sequence")
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name="status"
                    label={this.context.translate("_STATUS_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={[
                        {
                          value: -1,
                          label: this.context.translate(
                            "_FLEET_USER_USERDETAIL_STATUSDDL_SELECT_"
                          ),
                        },
                        {
                          value: 1,
                          label: this.context.translate("_ACTIVE_"),
                        },
                        {
                          value: 0,
                          label: this.context.translate(
                            "_FLEET_USER_USERLIST_STATUS_INACTIVE_"
                          ),
                        },
                      ]}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    name="photo"
                    label="Upload"
                    style={{ marginTop: "16px" }}
                    className="formCheckboxCss"
                    getValueFromEvent={this.normFile}
                    rules={[
                      {
                        required: !this.state.folderName,
                        message: "Please upload a photo or video",
                      },
                    ]}
                  >
                    <Upload
                      name="photo"
                      action={
                        config.baseURL + "/index.php?c=company&a=fileupload"
                      }
                      listType="picture"
                      data={{
                        fileType: "advertisement",
                        fieldName: "photo",
                        showType: this.state.showType,
                      }}
                      onChange={this.getUploadData}
                      onRemove={async () => {
                        await this.setState({ photo: "", fileList: [] });
                        await this.advertisementStore.setPhotoFromDb([]);
                      }}
                      maxCount={1}
                      accept=".jpg,.png,.mp4,.avi"
                      // accept=".jpg,.png"
                      // Add the beforeUpload prop to perform file extension validation

                      {...this.advertisementStore.photoFromDb}
                    >
                      <Button className="imgupload" icon={<UploadOutlined />}>
                        {this.context.translate("_PLEASE_SELECT_IMAGE_VIDEO_")}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <div className="formBtnGroup">
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  // loading={this.state.loading}
                >
                  {this.context.translate("_UPLOAD_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}
AdvertisementAdd.contextType = StoreContext;
export default observer(withRouter(AdvertisementAdd));
