import React from "react";
import {
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Select,
  Table,
  Button,
  Typography,
  Space,
  DatePicker,
  TimePicker,
  Tag,
  Avatar,
  Row,
  Col,
  Dropdown,
  Checkbox,
  Modal,
  Image,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import config from "../config";
import {
  DeleteFilled,
  DownloadOutlined,
  EditFilled,
  PlusOutlined,
  UserOutlined,
  DownOutlined,
  ExportOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from "react-leaflet";
import moment from "moment";
import EventReportStore from "../store/EventReportStore";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import VehicleStore from "../store/VehicleStore";
import L from "leaflet";
import MapForVehiclePosition from "./MapForVehiclePosition";
import AdditionalContentInMap from "./AdditionalContentInMap";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
const dotIcon = L.divIcon({
  className: "dot-icon",
  iconSize: [8, 8],
  html: ' <div style="width: 20px; height: 20px; background-color: red; border-radius: 50%;"></div>',
});
const { RangePicker } = DatePicker;
const { Option } = Select;
dayjs.extend(customParseFormat);
const { Search } = Input;
const dateFormat = "DD-MM-YYYY hh:mmA";
const defaultValue = moment().startOf("day").hour(0).minute(0).second(0);

class EventReportList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.eventReportStore = new EventReportStore();
    this.vehicleStore = new VehicleStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      companyId: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      // selectedVehicles: "",
      selectedVehicles: "",
      eventCode: "",
      listData: 0,
      modalVisible: false,
      column: "",
      filter: "",
      showMap: false,
      Notcompany: false,
      Notasset: false,
      NotEvent: false,
      showMonthPopup: false,

      selectedColumns: [
        {
          // title: "Vehicle No",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",
          width: "15%",
        },
        {
          // title: "Date/Time",
          title: context.translate("_FLEET_REPORTS_EVENTREPORT_DATETIME_"),
          dataIndex: "updatedate",
          key: "updatedate",
          width: "15%",
          render: (value) => {
            if (value) {
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            } else {
              return <></>;
            }
          },
        },
        {
          // title: "Address",
          title: context.translate("_FLEET_REPORTS_EVENTREPOR_ADDRESS_"),
          dataIndex: "formatted_address",
          key: "formatted_address",
          width: "15%",
        },
        {
          // title: "Speed",
          title: context.translate("_FLEET_REPORTS_EVENTREPOR_SPEED_"),
          dataIndex: "speed",
          key: "speed",
          width: "15%",
        },

        {
          // title: "Odometer",
          title: context.translate("_FLEET_REPORTS_EVENTREPOR_ODOMETER_"),
          dataIndex: "distance",
          key: "distance",

          width: "15%",
        },
        {
          // title: "Event",
          title: context.translate("_FLEET_REPORTS_EVENTREPOR_EVENT_"),
          dataIndex: "eventType",
          key: "eventType",
          width: "15%",
        },
        {
          // title: "Ignation Status",
          title: context.translate("_FLEET_REPORTS_EVENTREPOR_IGNITION_"),
          dataIndex: "acc",
          key: "acc",
          width: "15%",
          render: (acc) => {
            if (acc == 1) {
              return "ON";
            } else if (acc == 0) {
              return "OFF";
            } else if (acc == -1) {
              return "NA";
            }
            return "NA";
          },
        },
        {
          // title: "View On Map",
          title: context.translate("_FLEET_SERVICE_POILIST_VIEWMAP_"),
          dataIndex: "ViewOnMap",
          key: "ViewOnMap",
          width: "15%",
          render: (value, obj) => {
            return (
              <>
                <Image
                  src={require("../assets/images/mapIcon/gps_icon.png")}
                  width={25}
                  height={25}
                  onClick={async () => {
                    this.setState({ showMap: true, modalInfoObj: obj });
                    await this.setState({ loadData: 1 });
                  }}
                />
                {/* <Link
                  onClick={(e) =>
                    this.setState({ showMap: true, modalInfoObj: obj })
                  }
                >
                  {" "}
                  <img
                    src={require("../assets/images/mapIcon/gps_icon.png")}
                    width={25}
                    height={25}
                  />
                </Link> */}
              </>
            );
          },
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },

        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            interval: this.state.interval,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            colList: result.toString(),
            eventCode: this.state.eventCode,
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.eventReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.eventReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.eventReportStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.eventReportStore.exportxml(getData);
          }
        }
      },
    };

    this.newColumns = [
      ...this.state.selectedColumns,
      {
        // title: "Event Description",
        title: context.translate("_EVENT_DESCRIPTION_"),
        dataIndex: "EventDescription",
        key: "EventDescription",
        width: "15%",
      },
      {
        // title: "RFID",
        title: context.translate("_FLEET_DASHBORD_SETTING_RFID_"),
        dataIndex: "RFID",
        key: "RFID",
        width: "15%",
      },
      {
        // title: "RFID1",
        title: context.translate("_RFID1_"),
        dataIndex: "RFID1",
        key: "RFID1",
        width: "15%",
      },
    ];
  }

  async componentDidMount() {
    this.setState({ pageSize: 50 });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }

    await this.setState({ column: this.state.selectedColumns });
  }
  async componentDidUpdate(prevProps, prevState) {
    if (this.state.showMap) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }
  }
  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ currentPage: 1, companyId: comp?.value });

    await this.setState({ selectedVehicles: "" });

    await this.vehicleStore.getVehiclesCompanyId(comp?.value);

    await this.setState({ listUpdated: 1 });

    // this.handleSearch();
  }

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };
  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }

  async handleSearch() {
    this.setState({ Notasset: false });
    await this.eventReportStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });

    await this.setState({ listUpdated: 1 }); //need to reload data
  }

  showData = async () => {
    console.log("show data");
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }

    if (!this.state.selectedVehicles) {
      this.setState({ Notasset: true });
    }
    // if(!this.state.selectedVehicles){
    //   this.setState({Notasset:true})
    // }
    else {
      this.setState({ Notasset: false });
    }
    if (!this.state.eventCode) {
      this.setState({ NotEvent: true });
    } else {
      this.setState({ NotEvent: false });
    }

    if (
      this.state.companyId &&
      this.state.selectedVehicles &&
      this.state.eventCode
    ) {
      // if(this.state.companyId && this.state.selectedVehicles && this.state.eventCode){
      // this.setState({mandatory:false})
      console.log("true");
      this.setState({ Notcompany: false, Notasset: false, NotEvent: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.eventReportStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          // toDate: defaultValue.format("YYYY-MM-DD HH:mm:ss"),
          eventCode: this.state.eventCode,
        });
        await this.setState({ listData: 1 });
        // console.log(this.state.fromDate,this.state.toDate,)
      }
    }
  };
  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss,";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0]?.format(format),
        toDate: dates[1]?.format(format),
      });
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  render() {
    //console.log('in render1')
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const assetInputStyle = this.state.Notasset
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const eventInputStyle = this.state.NotEvent
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Event Report</div> */}
              <div className="heading">
                {this.context.translate("_FLEET_REPORTS_EVENTREPORT_TITLE_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Dropdown
                  disabled={
                    this.eventReportStore?.eventReport?.length > 0
                      ? false
                      : true
                  }
                  menu={this.menuProps}
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    {/* Export */}
                    {this.context.translate("_EXPORT_")}
                  
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Space>
              <Col span={16}>
                <CompanyAutocomplete
                  onChange={(value) => this.handleChange(value)}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
              </Col>
              {this.props.getUserData().isadmin === 1 && (
                <DeviceGroupsForVehicles
                  vehicleStore={this.vehicleStore}
                  setLoadDta={() => this.setState({ loadData: 1 })}
                  resetVehicles={() =>
                    this.setState({
                      selectedVehicles: "",
                    })
                  }
                />
              )}

              {/* <Select
                  // direction="vertical"
                  // placeholder="Asset Name"
                  placeholder={this.context.translate(
                    "_FLEET_REPORTS_VEHICLENO_"
                  )}
                  onSearch={this.handleSearch}
                  value={
                    this.state.selectedVehicles
                      ? this.state.selectedVehicles
                      : null
                  }
                  onSelect={(val) => {
                    this.setState({ selectedVehicles: val });
                    this.setState({ Notasset: false });
                  }}
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 150 }}
                  // style={assetInputStyle }
                  status={this.state.Notasset ? "error" : false}
                /> */}

              <Select
                value={
                  this.state.selectedVehicles
                    ? this.state.selectedVehicles
                    : null
                }
                onChange={(val) => {
                  this.setState({ selectedVehicles: val });
                  this.setState({ Notasset: false });
                }}
                style={{ width: 150 }}
                showSearch
                status={this.state.Notasset ? "error" : false}
                placeholder={this.context.translate(
                  "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                )}
                filterOption={(inputValue, option) => {
                  if (option.children) {
                    return (
                      option.children
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0
                    );
                  }
                }}
              >
                {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                  return <Option value={val.vehicleId}>{val.vehicleNo}</Option>;
                })}
              </Select>

              <Col>
                <Select
                  // placeholder="All Events"
                  placeholder={this.context.translate("_ALL_EVENTS_")}
                  onSelect={(val) =>
                    this.setState({ eventCode: val, NotEvent: false })
                  }
                  style={{ width: 150 }}
                  // style={eventInputStyle }
                  status={this.state.NotEvent ? "error" : false}
                >
                  {/* <Select.Option value="1">Harsh Braking</Select.Option>
                  <Select.Option value="2">Harsh Acceleration</Select.Option>
                  <Select.Option value="3">Harsh Turning</Select.Option>
                  <Select.Option value="5">Overspeeding</Select.Option>
                  <Select.Option value="4">Accident</Select.Option> */}
                  <Select.Option value="1">
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE1_TITLE_"
                    )}
                  </Select.Option>
                  <Select.Option value="2">
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE2_TITLE_"
                    )}
                  </Select.Option>
                  <Select.Option value="3">
                    {this.context.translate("_HARSH_TURNING_")}
                  </Select.Option>
                  <Select.Option value="5">
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE7_TITLE_"
                    )}
                  </Select.Option>
                  <Select.Option value="4">
                    {this.context.translate("_ACCIDENT_")}
                  </Select.Option>
                </Select>
              </Col>
              <Col>
                {/* <RangePicker
                  style={{ width: 300 }}
                  onChange={(dates) => {
                    // console.log(val)
                    // this.onDateTimeChange(val)
                    const format = "YYYY-MM-DD HH:mm:ss";
                    this.setState({
                      fromDate: dates[0].format(format),
                      toDate: dates[1].format(format),
                    });
                  }}
                  // renderExtraFooter={() => "extra footer"}
                  showTime={{ format: "h:mm A" }}
                /> */}

                <RangePicker
                  placeholder={[
                    this.context.translate("_FROM_DATE_TIME_"),
                    this.context.translate("_TO_DATETIME_"),
                  ]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
              </Col>

              <Col></Col>
              <Col>
                {" "}
                <Button
                  type="primary"
                  onClick={() => {
                    this.showData();
                    this.setState({ currentPage: 1 });
                  }}
                >
                  {" "}
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Col>
            </Space>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.eventReportStore?.total}
            columns={this.state.column}
            dataSource={this.eventReportStore?.eventReport}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   //  current:this.state.pageNo
            //   total:this.eventReportStore?.total || 0

            // }}
            loading={this.eventReportStore?.loading}
            onChange={this.handleTableChange}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />
          {/* {this.state.vehicleObj && (
          <MapForVehiclePosition
            vehicleObj={this.state.vehicleObj}
            heading={this.state.heading}
            closeMapModal={(resp) => {
              this.setState({ vehicleObj: resp });
            }}
          />
          
        )} */}
          {this.state.showMap && (
            <Modal
              className="multiTracking-popup"
              title={
                <div>
                  {this.context.translate("_FLEET_EVENT_REPORT_")}
                  <CloseOutlined
                    onClick={() => this.setState({ showMap: false })}
                  />
                </div>
              }
              centered
              maskClosable={false}
              bodyStyle={{
                height: "60vh",
                //width: "900px",
              }} // Adjust the dimensions as needed
              width={1000}
              open={this.state.showMap}
              onCancel={() => this.setState({ closeModal: true })}
              closable={false}
              footer={null}
            >
              {/* <Modal
           title=<div>
          {this.context.translate("_FLEET_RULETRIGGER_REPORT_")}
           <CloseOutlined onClick={() => this.setState({ showMap: false })} />
         </div>
            // title="Event View on Map"
            open={this.state.showMap}
            centered
            // bodyStyle={{ height: 1000 }}
            width={850}
            height={400}
            onOk={() => this.setState({ showMap: false })}
            onCancel={() => this.setState({ showMap: false })}
            footer={false}
          > */}
              <div
                dir={this.context.locale.language === "arabic" ? "ltr" : "ltr"}
                className={
                  this.context.locale.language === "arabic"
                    ? "arabic-map-container"
                    : ""
                }
              >
                <MapContainer
                  ref={this.mapRef}
                  zoom={30}
                  center={[
                    this.state.modalInfoObj?.latitude,
                    this.state.modalInfoObj?.longitude,
                  ]}
                  style={{
                    position: "relative",
                    height: "60vh",

                    // borderRadius: "0px 0px 5px 5px",
                  }}
                  bounds={
                    this.state.modalInfoObj
                      ? [
                          [
                            this.state.modalInfoObj.latitude,
                            this.state.modalInfoObj.longitude,
                          ],
                          [
                            this.state.modalInfoObj.latitude,
                            this.state.modalInfoObj.longitude,
                          ],
                        ]
                      : []
                  }
                >
                  <ReactLeafletGoogleLayer />

                  <Marker
                    icon={L.icon({
                      iconUrl: require("../assets/images/map-car-icon-green.png"),
                      iconSize: [25, 40], // size of the icon
                    })}
                    position={[
                      this.state.modalInfoObj?.latitude,
                      this.state.modalInfoObj?.longitude,
                    ]}
                    eventHandlers={{
                      mouseover: (event) => event.target.openPopup(),
                      mouseout: (event) => event.target.closePopup(),
                    }}
                  >
                    <Tooltip permanent={true} direction="top">
                      <div
                        style={{
                          backgroundColor: "#00ff00",
                          border: "solid 1px #000",
                          textAlign: "center",
                          padding: 0,
                        }}
                      >
                        <b>Vehicle No : {this.state.modalInfoObj?.vehicleNo}</b>
                        <br />
                        <b>Speed : {this.state.modalInfoObj?.speed}Kmh</b>
                      </div>
                    </Tooltip>
                    <Popup direction="top">
                      <b>Event : {this.state.modalInfoObj?.eventType}</b>
                      <br />
                      <b>
                        Location : {this.state.modalInfoObj?.formatted_address}
                      </b>
                      <br />
                      <b>Speed : {this.state.modalInfoObj?.speed}Kmh</b>
                    </Popup>
                  </Marker>
                  <AdditionalContentInMap This={this} size="small" />
                </MapContainer>
              </div>
            </Modal>
          )}
          <Modal
            width={350}
            className="alert-model"
            title={
              <div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-exclamation-triangle"
                ></i>{" "}
                {this.context.translate("_FLEET_ALERT_")}
              </div>
            }
            open={this.state.showMonthPopup}
            onOk={() => this.setState({ showMonthPopup: false })}
            onCancel={() => this.setState({ showMonthPopup: false })}
            footer={false}
          >
            {/* For reports prior to 6 months please contact administrator */}
            {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
          </Modal>
        </div>
      </>
    );
  }
}
EventReportList.contextType = StoreContext;
export default observer(withRouter(EventReportList));
