import React from "react";
import {
  Select,
  Button,
  Space,
  DatePicker,
  Row,
  Col,
  Dropdown,
  Checkbox,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import withRouter from "../withRouter";

import ListTable from "./ListTable";

import UserActivityReportStore from "../store/UserActivityReportStore";
import dayjs from "dayjs";
import config from "../config";
import customParseFormat from "dayjs/plugin/customParseFormat";
import VehicleStore from "../store/VehicleStore";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Cookies from "js-cookie";
const { RangePicker } = DatePicker;
dayjs.extend(customParseFormat);

const { Option } = Select;
let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 250;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 150;
    } else if (val.title?.length > 8) {
      val.width = 125;
    } else {
      val.width = 110;
    }
    // console.log(val.title.length)
  });

  return arr;
};

class UserActivityReport extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.userActivityReportStore = new UserActivityReportStore();
    this.vehicleStore = new VehicleStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      companyId: "",
      // fromDate: "",
      // toDate: "",
      selectDistributor: [],
      selectAllVehicles: false,
      activity: "",
      modalVisible: false,
      column: "",
      filter: "",

      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      Notdistributer: false,
      Notactivity: false,

      selectedColumns: widthAdderFunc([
        {
          // title: "Account Name",
          title: context.translate("_FLEET_COMPANY_COMPANYLIST_COMPANYNAME_"),
          dataIndex: "companyName",
          key: "companyName",
          width: "15%",
          render: (value) => {
            if (!value || value == "") {
              return "NA";
            }
            return value;
          },
        },
        {
          // title: "Asset Name",
          title: context.translate("_FLEET_REPORTS_VEHICLENO_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",
          width: "15%",
          render: (value) => {
            if (!value || value == "") {
              return "NA";
            }
            return value;
          },
        },
        {
          // title: "Activity",
          title: context.translate("_ACTIVITY_"),
          dataIndex: "fromTable",
          key: "fromTable",
          width: "15%",
        },
        {
          // title: "Driver",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
          dataIndex: "driverName",
          key: "driverName",
          width: "15%",
          render: (value) => {
            if (!value || value == "") {
              return "NA";
            }
            return value;
          },
        },

        {
          // title: "Status",
          title: context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
          dataIndex: "status",
          key: "status",
          width: "15%",
        },
        {
          // title: "Registered At",
          title: context.translate("_REGISTERED_AT_"),
          dataIndex: "registeredAt",
          key: "registeredAt",
          width: "15%",
        },
        {
          // title: "Registered By",
          title: context.translate("_REGISTERED_BY_"),
          dataIndex: "registeredBy",
          key: "registeredBy",
          width: "15%",
        },
        {
          // title: "Registered By IP",
          title: context.translate("_REGISTERED_BY_IP"),
          dataIndex: "registeredByIp",
          key: "registeredByIp",
          width: "15%",
        },
        {
          // title: "Updated At",
          title: context.translate("_UPDATED_AT_"),
          dataIndex: "updatedAt",
          key: "updatedAt",
          width: "15%",
        },
        {
          // title: "Updated By",
          title: context.translate("_UPDATED_BY_"),
          dataIndex: "updatedBy",
          key: "updatedBy",
          width: "15%",
          render: (value) => {
            if (!value || value == "" || value == 0) {
              return "NA";
            }
            return value;
          },
        },
        {
          // title: "Updated By Ip",
          title: context.translate("_UPDATED_BY_IP"),
          dataIndex: "updatedByIp",
          key: "updatedByIp",
          width: "15%",
          render: (value) => {
            if (!value || value == "" || value == 0) {
              return "NA";
            }
            return value;
          },
        },
        {
          // title: "Deleted At ",
          title: context.translate("_DELETED_AT_"),
          dataIndex: "deletedAt",
          key: "deletedAt",
          width: "15%",
        },
        {
          // title: "Deleted By",
          title: context.translate("_DELETED_BY_"),
          dataIndex: "deletedBy",
          key: "deletedBy",
          width: "15%",
        },
        {
          // title: "Deleted By Ip",
          title: context.translate("_DELETED_BY_IP_"),
          dataIndex: "deletedByIp",
          key: "deletedByIp",
          width: "15%",
        },
      ]),
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        // {
        //   // label: "CSV",
        //   label: context.translate("_CSV_"),
        //   key: "csv",
        //   icon: <PlusOutlined />,
        // },
        // {
        //   label: "XML",
        //   key: "xml",
        //   icon: <PlusOutlined />,
        // },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            companyId:
              this.state.selectDistributor == -1
                ? 0
                : this.state.selectDistributor,
            vehicleId: this.state.selectedVehicles,
            // interval: this.state.interval,
            activity: this.state.activity,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.userActivityReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.userActivityReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.userActivityReportStore.exportcsv(getData);
          }
          // if (val.key == "xml") {
          //   this.userActivityReportStore.exportxml(getData);
          // }
        }
      },
    };

    this.newColumns = [...this.state.selectedColumns];
  }

  async componentDidMount() {
    await this.userActivityReportStore.getDistributors(
      Number(this.props.getUserData().isadmin)
    );
    this.setState({ pageSize: 50 });
    await this.setState({ column: this.state.selectedColumns });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
  }
  async componentDidUpdate() {
    if (Number(this.props.getUserData().isadmin) === 1) {
      this.props.navigate("/login");
    }
  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  async handleChange(comp) {
    await this.setState({ Notdistributer: false });
    await this.setState({ currentPage: 1, companyId: comp?.value });
    await this.vehicleStore.getVehiclesCompanyId({ companyId: comp?.value });
    this.handleSearch();
  }

  // async onSearch(value) {
  //     console.log(value)
  //     await this.setState({ emailId: value });
  //     this.handleSearch();
  // };

  async handleSearch() {
    this.setState({ Notactivity: false });
    await this.userActivityReportStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });

    await this.setState({ listUpdated: 1 }); //need to reload data
  }
  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };
  showData = async () => {
    console.log(this.state.selectDistributor);

    if (this.state.selectDistributor.length === 0) {
      this.setState({ Notdistributer: true });
    } else {
      this.setState({ Notdistributer: false });
    }
    if (!this.state.activity) {
      this.setState({ Notactivity: true });
    } else {
      this.setState({ Notactivity: false });
    }

    if (this.state.selectDistributor != 0 && this.state.activity) {
      // this.setState({mandatory:false})
      console.log(this.state.selectDistributor);
      this.setState({ Notdistributer: false, Notactivity: false });
      await this.userActivityReportStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId:
          this.state.selectDistributor == -1 ? 0 : this.state.selectDistributor,
        activity: this.state.activity,
        fromDate: this.state.fromDate,
        // toDate: defaultValue.format("YYYY-MM-DD HH:mm:ss"),
        toDate: this.state.toDate,
        currentPage: this.state.currentPage,
      });
      console.log();
      await this.setState({ listUpdated: 1 });
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  render() {
    //console.log('in render1')
    const distributerInputStyle = this.state.Notdistributer
      ? { border: "1px solid red", width: "170px", borderRadius: "8px" }
      : { width: "170px" };
    const activityInputStyle = this.state.Notactivity
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">User Activity Report</div> */}
              <div className="heading">
                {this.context.translate("_USER_ACTIVITY_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Dropdown
                  disabled={
                    this.userActivityReportStore?.userActivityReport?.length > 0
                      ? false
                      : true
                  }
                  menu={this.menuProps}
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Space>
              <Col span={16}>
                <Select
                  onSearch={this.handleSearch}
                  // onSearch={this.handleChange}
                  value={this.state.selectDistributor}
                  onChange={(val) =>
                    this.setState({
                      selectDistributor: val,
                      Notdistributer: false,
                    })
                  }
                  showSearch
                  maxTagCount="responsive"
                  // fieldNames={{ label: "name", value: "companyId" }}
                  // placeholder="Select Distributor"
                  placeholder={this.context.translate("_SELECT_DISTRIBUTOR_")}
                  optionFilterProp="children"
                  // options={this.userActivityReportStore?.distributorsArray}
                  style={{ width: 200 }}
                  // style={distributerInputStyle}
                  status={this.state.Notdistributer ? "error" : false}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.userActivityReportStore?.distributorsArray?.map(
                    (val) => {
                      return <Option value={val.companyId}>{val.name}</Option>;
                    }
                  )}
                </Select>
              </Col>

              <Col>
                <Select
                  // placeholder="Activity"
                  placeholder={this.context.translate("_ACTIVITY_")}
                  onSelect={(val) =>
                    this.setState({ activity: val, Notactivity: false })
                  }
                  style={{ width: 150 }}
                  // style={activityInputStyle}
                  status={this.state.Notactivity ? "error" : false}
                  showSearch
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  <Select.Option value="All">
                    {this.context.translate("_ALL_")}
                  </Select.Option>
                  <Select.Option value="Company">
                    {this.context.translate("_FLEET_DASHBORD_NEWPOI_COMPANY_")}
                  </Select.Option>
                  <Select.Option value="Vehicle">
                    {this.context.translate("_VEHICLES_")}
                  </Select.Option>
                  <Select.Option value="WASL Company">
                    {/* WASL Company */}
                    {this.context.translate("_WASL_COMPANY_")}
                  </Select.Option>
                  <Select.Option value="WASL Vehicle">
                    {/* WASL Vehicle */}
                    {this.context.translate("_WASL_VEHICLE_")}
                  </Select.Option>
                  <Select.Option value="WASL Driver">
                    {this.context.translate("_WASL_DRIVER_")}
                    {/* WASL Driver */}
                  </Select.Option>
                </Select>
              </Col>
              <Col>
                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
              </Col>
              <Button
                type="primary"
                onClick={async () => {
                  await this.setState({ currentPage: 1, pageSize: 50 });
                  await this.showData();
                }}
              >
                {/* Show */}
                {this.context.translate("_FLEET_SHOW_BTN_")}
              </Button>
            </Space>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.userActivityReportStore?.total}
            columns={this.state.column}
            dataSource={this.userActivityReportStore?.userActivityReport}
            loading={this.userActivityReportStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>
      </>
    );
  }
}
UserActivityReport.contextType = StoreContext;
export default observer(withRouter(UserActivityReport));
