import React from "react";
import {
  Space,
  DatePicker,
  Dropdown,
  Row,
  Col,
  message,
  Tooltip,
  Button,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";

import { PushpinFilled, EditFilled, DeleteFilled, PlusOutlined } from "@ant-design/icons";

import EmailReportStore from "../store/EmailReportStore";

import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import withRouter from "../withRouter";
import CompanyStore from "../store/CompanyStore";
import EmailReportAdd from "./EmailReportAdd";
class EmailReportList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.emailReportStore = new EmailReportStore();
    this.companyStore = new CompanyStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      companyId: "",
      loadList: 0,
      companyVal_Lab: "",
      Id: "",
      listDataUpdate: false,
      openEmailAddForm: false,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const emailStates = this.context.viewStateStore.emailReportObject;
      console.log(emailStates);
      if (emailStates.companyId !== "") {
        this.setState({ pageSize: 50 });
        await this.emailReportStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: emailStates.companyId,
        });
        const singleData = await this.companyStore.getCompanyDataById(
          emailStates.companyId
        );

        console.log(singleData);
        await this.setState({
          companyVal_Lab: singleData,
        });
        await this.setState({ loadList: 1 });
      }
    }
  }

  async componentDidMount() {
    // await this.emailReportStore.loadData({ offset: 0, limit: 50 });
    console.log(this.context.viewStateStore.value, "vale");
    if(Number(this.props.getUserData().isadmin)!==-1 ){
      // console.log(this.props.getUserData().userCompanyId)
      await this.setState({companyId:this.props.getUserData().userCompanyId})
     //  console.log(this.state.companyId)
      await this.setState({userId:this.props.getUserData().userId})
      await this.handleSearch();
 }

    // const emailStates = this.context.viewStateStore.emailReportObject;
    // console.log(emailStates);
    // if(emailStates.companyId!==""){
    // this.setState({ pageSize: 50 });
    // await this.emailReportStore.loadData({
    //   offset: (this.state.currentPage - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: emailStates.companyId,
    // });
    // const singleData = await this.companyStore.getCompanyDataById(
    //   emailStates.companyId
    // );

    // console.log(singleData);
    // await this.setState({
    //   companyVal_Lab: singleData,
    // });
    // }
    this.setState({ pageSize: 50 });
    this.columns = [
      {
        // title: "Email Details ",
        title: this.context.translate("_EMAIL_DETAILS_"),
        dataIndex: "emailId",
        width: "25%",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        // title: "CC Details",
        title: this.context.translate("_CC_DETAILS_"),
        dataIndex: "cc",
        width: "25%",
      },
      {
        // title: "CC Details",
        title: this.context.translate("_REPORT_NAME_"),
        dataIndex: "reportName",
        width: "30%",
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        // fixed : 'right',
        width: 10,
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip 
                  // title="Edit" 
                  title={this.context.translate("_FLEET_ROLE_ROLEDETAIL_EDIT_")}
                  placement="bottom"  color={config.tooltipColorEdit}>
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                    placement="bottom" 
                      // title="Delete"
                      title={this.context.translate("_FLEET_ROLE_ROLEDETAIL_DELETE_")}
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
    // await this.setState({ loadList: 1 });
  }

  async onDelete(record) {
    console.log(record.er_Id, "record");
    const response = await this.emailReportStore.deleteRecord({
      er_Id: record.er_Id,
    });
    console.log(response.data.success);
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  onEdit = async (record) => {
    console.log(record.er_Id);
    const id = record.er_Id;
    if (id) {
      this.context.viewStateStore.setEmailReportStates({
        companyId: record.companyId,
      });
      // this.props.navigate("/emailreportadd/" + id);
    }
    await this.setState({ Id: record.er_Id });
    await this.setState({ openEmailAddForm: true });
    // if (id) {
    //   this.props.navigate("/emailreportadd/" + id);
    // }
  };

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
   await this.handleSearch();
  }

  handleChange = async (comp) => {
    // console.log(comp.value);
    // await this.setState({ companyId: comp?.value });
    // await this.handleSearch();
    console.log(comp.value);

    await this.setState({ companyId: comp.value, loadList: 1 });

    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ companyVal_Lab: singleData });

    await this.handleSearch();
    this.setState({ loadList: 1 });
  };

  async handleSearch() {
    this.setState({ loading: true });
    await this.emailReportStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.success == "S") {
      message.success(resp.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openEmailAddForm: false });

       await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    } else {
      message.error(resp.message);
    }
  };

closeModal=()=>{
  this.setState({ openEmailAddForm: false, Id: "" });
}
  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Email Report</div> */}
              <div className="heading">{this.context.translate("_EMAIL_REPORT_")}</div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                {/* <AddButton
                title={this.context.translate("_ADD_")}
                link="/emailreportadd"
              /> */}

                {/* <Button className="addButton" type="primary" icon={<PlusOutlined/>} onClick={() => this.setState({ openEmailAddForm: true, Id: '' })} ></Button> */}

                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  placement="left"
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({ openEmailAddForm: true, Id: "" })
                    }
                  ></Button>
                </Tooltip>

              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <CompanyAutocomplete
                onChange={this.handleChange}
                value={
                  this.state.companyVal_Lab ? this.state.companyVal_Lab : null
                }
                // allowClear={true}
                style={{ width: 200 }}
              />
            </Col>
            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.emailReportStore?.total}
            columns={this.columns}
            dataSource={this.emailReportStore?.emailStore}
            loading={this.emailReportStore?.loading}
            onChange={this.handleTableChange}
          />

          {this.state.openEmailAddForm && (
            <Modal
              bodyStyle={{ height: "645px" }}
              className="formShowModal" //  {/* css class */}
              // title="Mail Settings"
              title={this.context.translate("_MAIL_SETTINGS_")}
              centered
              visible={this.state.openEmailAddForm}
              footer={null}
              onCancel={() => {
                this.setState({ openEmailAddForm: false, Id: "" });
              }}
              width={665}
            >
              <div>
                <EmailReportAdd
                  Id={this.state.Id}
                  response={this.responseMsg}
                  companyId={this.state.companyId}
                  closeModal={this.closeModal}
                />
              </div>
            </Modal>
          )}
        </div>
      </>
    );
  }
}
EmailReportList.contextType = StoreContext;
export default observer(withRouter(EmailReportList));
