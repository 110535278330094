import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi,baseURL } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from 'mobx';


class MileageReportStore {
  mileageReportStore = []
  loading=false
  total = 0
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
      mileageReportStore: observable,
      loading:observable,
      total:observable,
      loadData: action,

    });
    this.rootStore = rootStore;

  }



  async loadData({companyId,vehicleId,offset,limit,fromDate,toDate,mileage,fuelprice,pageNo}) {
    this.loading=true;
    console.log("vehicle list")
    var postData = { "companyId": companyId, "vehicleId":vehicleId, "mileage": mileage, "fuelprice": fuelprice, "fromDate": fromDate, "toDate": toDate, "offset": offset, "limit": limit,"pageNo":pageNo }

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=mileagereport", "postData=" + base64Input);
    this.total = parseInt(response?.data?.data?.TotalCount);
    console.log(response?.data?.data?.mileagereport)
    this.mileageReportStore = response?.data?.data?.mileagereport
    this.loading=false;
  }


  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportmileageexcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&mileage="+
      getData.mileage +
      "&price="+
      getData.fuelprice +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportmileagepdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&mileage="+
      getData.mileage +
      "&price="+
      getData.fuelprice +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportmileagecsv&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&mileage="+
      getData.mileage +
      "&price="+
      getData.fuelprice +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportxml(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportmileagexml&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&mileage="+
      getData.mileage +
      "&price="+
      getData.fuelprice +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

}

export default MileageReportStore;
