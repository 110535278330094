import { decode as base64_decode, encode as base64_encode } from "base-64";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import Cookies from "js-cookie";

class VehicleWorkingHoursStore {
  vehicleWoringHoursVehicles = [];
  loading = false;
  assignAssetList = [];
  assignDriverList = [];
  groupList = [];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      vehicleWoringHoursVehicles: observable,
      loading: observable,
      assignDriverList: observable,
      assignAssetList: observable,
      groupList: observable,
      loadData: action,
      addData: action,
      getWokingHoursData: action,
      getAssignAsset: action,
      deleteRecord: action,
      getDriver: action,
      getAssignVehicles: action,
      getVehicleSubGroup: action,
      getVehicleGroup: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit, filter, vehicleId, driverId }) {
    console.log("vehicle list");
    this.loading = true;
    var postData = {
      companyId: companyId,
      offset: offset,
      limit: limit,
      // filter: filter,
      // vehicleId: vehicleId,
      // driverId: driverId,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=workinghour&a=workinghourslist",
        "postData=" + base64Input
      );

    console.log("dfdf", response?.data?.data);
    let allResp = response?.data?.data;
    // allResp.forEach((val)=>{
    //   if(val.vehicleNo?.length>10){
    //     val.children = [{vehicleNo : val.vehicleNo,}, {
    //       title: "",
    //       dataIndex:"" ,}];
    //     val.vehicleNo = val.vehicleNo.slice(0,10)
    //   }
    // })

    this.vehicleWoringHoursVehicles = allResp;
    this.loading = false;
  }

  async addData(workingHourData) {
    console.log("working Hour data", workingHourData);
    var postData = workingHourData;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=workinghour&a=update", "postData=" + base64Input);
    console.log(response);
    return response;
  }

  async getWokingHoursData(wid, companyId) {
    console.log("wid");
    var postData = { wid: wid, limit: 1, offset: 0, companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=workinghour&a=workinghourslist",
        "postData=" + base64Input
      );
    return response.data.data[0];
  }

  //To get asset list
  async getAssignAsset(companyId) {
    var postData = companyId;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);
    console.log(response, response?.data?.data, "post");

    // let allAsset = [{ vehicleId: -1, vehicleNo: "ALL" }];
    let allAsset = [...response?.data?.data]; // [...allAsset, ...response?.data?.data];
    console.log(allAsset);

    this.assignAssetList = response?.data?.data;
    return response?.data?.data[0];
  }

  async getAssignVehicles(companyId) {
    var postData = companyId;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);
    console.log(response, response?.data?.data, "post");

    this.assignAssetList = response?.data?.data;
    return response?.data?.data;
  }

  async deleteRecord({ workingHourId }) {
    var postData = { workingHourId: workingHourId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=workinghour&a=delete", "postData=" + base64Input);
    return response;
  }

  async getDriver(companyId) {
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=driver&a=getDriver", "postData=" + base64Input);

    let allAsset = [{ driverId: -1, driverName: "ALL" }];
    allAsset = [...allAsset, ...response?.data?.data];

    this.assignDriverList = allAsset;
    return response?.data?.data;
  }

  async getAssignDriver(companyId) {
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=driver&a=getDriver", "postData=" + base64Input);


    this.assignDriverList = response?.data?.data;
    return response?.data?.data;
  }

  async getVehicleSubGroup({
    deviceGroupId,
    deviceSubGroupId,
    companyId,
    userId,
    isadmin,
    subgroup,
  }) {
    var postData = {
      deviceGroupId: deviceGroupId,
      deviceSubGroupId: deviceSubGroupId,
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));

    var response = await postApi
      .getClient()
      .post(
        "index.php?c=workinghour&a=getdistgroupvehicle",
        "postData=" + base64Input
      );

    if (subgroup) {
      this.subGroupList = response.data.data?.subgroup;
      this.assignAssetList = response.data.data?.vehicles;
    } else {
      this.vehicles = response.data.data?.vehicles;
      this.assignAssetList = response.data.data?.vehicles;
    }

    return response.data.data?.vehicles;
  }

  async getVehicleGroup({ companyId, userId, isadmin }) {
    let isAdmin = JSON.parse(base64_decode(Cookies.get("data"))).isadmin;

    if (isAdmin === -1 || isAdmin === 3) {
      var postData = {
        companyId: companyId,
        userId: userId,
        isadmin: isadmin,
        offset: 0,
        limit: 200,
        page: 1,
      };
      const base64Input = await base64_encode(JSON.stringify(postData));
      var response = await postApi
        .getClient()
        .post(
          "index.php?c=company&a=getDeviceGroups",
          "postData=" + base64Input
        );
        this.groupList = response?.data?.data?.devicegroups;
        return  response?.data?.data?.devicegroups;
        // console.log(response?.data?.data?.devicegroups)
    } else {
      var postData = { companyId: companyId, userId: userId, isadmin: isadmin };
      const base64Input = await base64_encode(JSON.stringify(postData));
      var response = await postApi
        .getClient()
        .post(
          "index.php?c=devicegroup&a=getVehicleGropups",
          "postData=" + base64Input
        );
        this.groupList = response?.data?.data;
    }

    
  }
}

export default VehicleWorkingHoursStore;
