import React, { Component } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Spin } from "antd";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";
import dayjs from "dayjs";
import EventStore from "../store/EventStore";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

class EventAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loadingSpin: false,
      companyId: "",
      id: -1,
      setData: "",
      editorInstance: null,
    };

    this.eventStore = new EventStore(this);
  }

  async componentDidMount() {
    const id = this.props.id;

    if (id) {
      await this.filldata(id);
    }

    this.setState({ update: 1 });
  }

  async filldata(id) {
    const getEventData = await this.eventStore.getEventData(id);

    this.setState({ id: getEventData.id });

    this.formRef.current.setFieldsValue({
      title: getEventData.title,
      expiryDate: getEventData.expiryDate
        ? dayjs(getEventData.expiryDate)
        : null,
      content: getEventData.content,
    });
    if (this.state.editorInstance) {
      this.state.editorInstance.setData(getEventData.content);
    }
    // console.log(getEventData.content)
  }

  onEventDetailSubmit = async (fieldsValue) => {
    const data = {
      ...fieldsValue,
      id: this.state.id,
      content: this.state.setData,
      expiryDate: fieldsValue.expiryDate
        ? fieldsValue.expiryDate.format("YYYY-MM-DD")
        : "",
      type: 1,
      userId: this.props.getUserData().userId,
    };
  
    this.setState({ loading: true });
    const response = await this.eventStore.addData(data);
    await this.props.response(response);

    this.setState({ loading: false });
  };

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
  }

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              ref={this.formRef}
              layout="vertical"
              name="nest-messages"
              onFinish={this.onEventDetailSubmit}
              style={{ maxWidth: "100%", padding: 0 }}
              className="formClass"
            >
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="title"
                    label={this.context.translate("_TITLE_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="expiryDate"
                    label={this.context.translate("_EXPIRY_DATE_")}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                      disabledDate={(current) => {
                        return current && current < dayjs();
                      }}
                    />
                  </Form.Item>
                </Col>

                <Row>
                  <Form.Item
                    name="content"
                    // label={this.context.translate("_DESCRIPTION_")}
                    // rules={[{ required: true, message: "Please enter data" }]}
                  >
                    <div style={{ width: "600px", height: "400px", overflowY: "auto"  }}>
                      <CKEditor
                        // style={{ width: "100%", minHeight: "200px" }}
                        editor={ClassicEditor}
                        // data="<p>Initial content</p>"
                        onReady={(editor) => {
                          this.setState({ editorInstance: editor });
                          if (this.state.setData) {
                            editor.setData(this.state.setData);
                          }
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          console.log(data);
                          this.setState({ setData: data });
                        }}
                        // config={{
                        //   toolbar: ['bold', 'italic', '|', 'undo', 'redo'], // Add other toolbar items as needed
                        // }}
                      />
                    </div>
                  </Form.Item>
                </Row>
              </Row>

              <div className="formBtnGroup">
                <Button className="formCancelButton" onClick={this.closeModal}>
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="formSaveButton"
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

EventAdd.contextType = StoreContext;
export default observer(withRouter(EventAdd));
