import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,

  makeObservable,
  observable,

} from 'mobx';

import Cookies from "js-cookie";
class NotificationStore {
  notification = []
  getClass=[]
  getDriverName=[]
  allCompanyArray=[]
  // users=[]
  // drivers=[]
  // staff=[]
  // passengers=[]
  total = 0
  loading = false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
      notification: observable,
      getDriverName:observable,
      total: observable,
      loading: observable,
      loadData: action,
      getAllClass:action,
      addData:action,
      toGetDriverName:action,
      getNotificationDetails:action,
      getCompanies:action
    });
    this.rootStore = rootStore;

  }

  async loadData({ offset, limit,companyId,warehouseId }) {
    this.loading = true;
    console.log("notification",warehouseId)

    var postData = { "companyId": companyId, "offset": offset, "limit": limit, "page": 1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=notification&a=getNotification", "postData=" + base64Input);
    console.log("notification ", response?.data?.data)

    this.notification = response?.data?.data
    // // //  this.setCompanies(response?.data?.data?.companydata);
    //  this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }

  async addData(values) {

    var postData = values
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=notification&a=update", "postData=" + base64Input);
    // console.log(response)
    return response.data
  }

async  getNotificationDetails(id){
    console.log("Id",id)
    var postData = {"id":id,limit:1,offset:0}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=notification&a=getNotification", "postData="+base64Input);
    console.log("response",response?.data?.data[0])
    return response?.data?.data[0]

  }

  async getAllClass() {
    // console.log("vehicle list")
    var postData = null;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=notification&a=getAllClass",
        "postData=" + base64Input
      );
    // console.log(response?.data?.data, "post")
    let all = [{classId: -1, className: "All"},...response?.data?.data]
    this.getClass =all
    return response?.data?.data;
  }

  async getClassNames() {
    // console.log("vehicle list")
    var postData = null;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=notification&a=getAllClass",
        "postData=" + base64Input
      );
    // console.log(response?.data?.data, "post")
   
    this.getClass =response?.data?.data
    return response?.data?.data;
  }


  async toGetDriverName(companyId,isadmin) {
    // console.log(companyId,"dwee")
    var postData = {
      tokenId: "",
      companyId: companyId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      deviceGrpOffSet: 0,
      deviceGrpSize: 10,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=getdata", "postData=" + base64Input);
    console.log(response, "response2135");
    this.getDriverName = await response?.data?.data?.company[0]?.drivers;
    console.log(this.getDriverName);
    // this.total = parseInt(response?.data?.data?.TotalCount);
    return this.getDriverName;
  }

  async deleteRecord({ notification_Id, companyId}) {
    var postData = {"tokenId":"","notification_Id":notification_Id,"companyId":companyId}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=notification&a=delete", "postData=" + base64Input);
    return response;
  }

  async getCompanies() {
 
    this.loading = true;
    var postData = {"value":"",isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
    companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=company&a=getCompanyList", "postData="+base64Input);
    console.log(response)
   this.allCompanyArray = response.data.data.companies
   this.loading = false
  }

  async getCompanyDataById(companyId){
    var postData = {"value":companyId,"getBy":"companyId"}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=company&a=getCompanyList", "postData="+base64Input);
    return response.data.data.companies.map((user) => ({
            label: user.name,
             value: user.companyId
           }));

  }




}

export default NotificationStore;
