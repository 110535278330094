import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import moment from "moment";

class TrackingDashboardStore {
  total = 0;
  loading = false;
  trackData = [];
  vehicalState = [];
  usageGraphData = [];
  totalvehstate = [];
  livetrack = [];
  totalvehiclestate = [];
  notificationData=[]

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      trackData: observable,
      loading: observable,
      notificationData:observable,
      loadData: action,
      getUsageGraph: action,
      getMaxSpeedGraph: action,
      getNotificationData:action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, status }) {
    this.loading = true;

    var postData = {
      companyId: companyId,
      userCompany: 1,
      vehicleStatus: status,
      isadmin: -1,
      limit: 500,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicletracking&a=getDetailTrackDashboard",
        "postData=" + base64Input
      );

    var liveTrackingData = response?.data?.data?.detailtrack;
    var totalCount = response?.data?.data?.TotalCount;
    if (liveTrackingData && Number(totalCount) > 0) {
      Object.keys(liveTrackingData).map((e, i) => {
        let r = liveTrackingData[i];

        //  var diff = new Date(r.currtime) - new Date(r.updatedate);
        const diff = moment(r.currtime).diff(r.updatedate, "minutes");
        var vehicleTypeNew = r.vehicleTypeNew?.split(" ").join("-");
        if (diff >= 90) {
          liveTrackingData[i].listImage = vehicleTypeNew + "-gray.png";
          liveTrackingData[i].rowBackground = "gray";
          liveTrackingData[i].currentStatus = "nodata";
        } else if (r.acc == 1 && r.speed == 0) {
          //idle)
          liveTrackingData[i].listImage = vehicleTypeNew + "-yellow.png";
          liveTrackingData[i].rowBackground = "yellow";
          liveTrackingData[i].currentStatus = "idle";
        } else if (r.acc == 0) {
          liveTrackingData[i].listImage = vehicleTypeNew + "-red.png";
          liveTrackingData[i].rowBackground = "red";
          liveTrackingData[i].currentStatus = "stop";
        } else if (r.acc == 1 && r.speed > 0) {
          liveTrackingData[i].listImage = vehicleTypeNew + "-green.png";
          liveTrackingData[i].rowBackground = "green";
          liveTrackingData[i].currentStatus = "running";
        }
        liveTrackingData[i].mapImage = liveTrackingData[i].listImage;

        try {
          require("../assets/images/detailtracking/" +
            liveTrackingData[i].listImage);
        } catch (err) {
          liveTrackingData[i].listImage = "car-icon-blue.png";
        }

        try {
          require("../assets/images/livetracking/" +
            liveTrackingData[i].mapImage);
        } catch (err) {
          liveTrackingData[i].mapImage = "car-icon-blue.png";
        }
      });
      console.log(liveTrackingData);
      this.trackData = liveTrackingData;

      this.vehicalState = response?.data?.data?.totalvehstate;

      this.total = parseInt(Number(response?.data?.data?.TotalCount));
      console.log(this.total);
    }

    this.loading = false;
  }

  async getLiveRouteData({ companyId, offset, limit, searchField }) {
    var postData = {
      companyId: companyId,
      offset: 0,
      limit: 50,
      searchField: searchField,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicletracking&a=getLiveRouteData",
        "postData=" + base64Input
      );

    var liveTrackingData = response.data?.data?.livetrack;

    Object.keys(liveTrackingData).map((e, i) => {
      let r = liveTrackingData[i];

      //  var diff = new Date(r.currtime) - new Date(r.updatedate);
      const diff = moment(r.currtime).diff(r.updatedate, "minutes");
      var vehicleTypeNew = r.vehicleTypeNew?.split(" ").join("-");
      if (diff >= 90) {
        liveTrackingData[i].listImage = vehicleTypeNew + "-gray.png";
        liveTrackingData[i].rowBackground = "gray";
        liveTrackingData[i].currentStatus = "nodata";
      } else if (r.acc == 1 && r.speed == 0) {
        //idle)
        liveTrackingData[i].listImage = vehicleTypeNew + "-yellow.png";
        liveTrackingData[i].rowBackground = "yellow";
        liveTrackingData[i].currentStatus = "idle";
      } else if (r.acc == 0) {
        liveTrackingData[i].listImage = vehicleTypeNew + "-red.png";
        liveTrackingData[i].rowBackground = "red";
        liveTrackingData[i].currentStatus = "stop";
      } else if (r.acc == 1 && r.speed > 0) {
        liveTrackingData[i].listImage = vehicleTypeNew + "-green.png";
        liveTrackingData[i].rowBackground = "green";
        liveTrackingData[i].currentStatus = "running";
      }
      liveTrackingData[i].mapImage = liveTrackingData[i].listImage;

      try {
        require("../assets/images/detailtracking/" +
          liveTrackingData[i].listImage);
      } catch (err) {
        liveTrackingData[i].listImage = "car-icon-blue.png";
      }

      try {
        require("../assets/images/livetracking/" +
          liveTrackingData[i].mapImage);
      } catch (err) {
        liveTrackingData[i].mapImage = "car-icon-blue.png";
      }
    });
    console.log(liveTrackingData);
    this.livetrack = liveTrackingData;

    //    console.log("response",response)
    console.log(response?.data?.data?.livetrack, "vehicle");
    console.log(response.data?.data?.totalvehstate);
    this.totalvehiclestate = response.data?.data?.totalvehstate;
  }

  async getUsageGraph({ companyId }) {
    var postData = { companyId: companyId };
    var base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=usageGraphDashboard",
        "postData=" + base64Input
      );
    console.log(response?.data?.data?.usagedata, "vehicle");

    var data = response?.data?.data?.usagedata;
    if (data && data) {
      data.forEach((element, index) => {
        element.TotalTravelDistance = parseFloat(element.TotalTravelDistance);
      });
    }
    console.log(data);
    this.usageGraphData = data;
    console.log(response?.data?.data?.usagedata);
    return response?.data?.data?.usagedata;
  }

  async getNotificationData(companyId){
    var postData = { companyId: companyId};
    var base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=user&a=getNotificationDashboard",
        "postData=" + base64Input
      );

      console.log(response)
      this.notificationData = response.data.data
  }

  async getMaxSpeedGraph({ companyId, maxspeed }) {
    var postData = { companyId: companyId, type: maxspeed };
    var base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=maxSpeedGraphDashboard",
        "postData=" + base64Input
      );
    console.log(response?.data?.data?.MaxSpeedGraph, "vehicle");
    var maxSpeedGraphData = response?.data?.data?.MaxSpeedGraph;
    if (maxSpeedGraphData && maxSpeedGraphData) {
      maxSpeedGraphData.forEach((element) => {
        // Convert specific properties to floats
        element.maxSpeed = parseFloat(element.maxSpeed);
      });
    }
    this.maxSpeedGraph = response?.data?.data?.MaxSpeedGraph;
    return response?.data?.data?.MaxSpeedGraph;
  }

  async getDistanceGraph({ companyId, distance }) {
    var postData = { companyId: companyId, type: distance };
    var base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=maxSpeedGraphDashboard",
        "postData=" + base64Input
      );
    console.log(response?.data?.data?.distancegraph, "vehicle");
    var distanceGraphData = response?.data?.data?.distancegraph;
    if (distanceGraphData && distanceGraphData) {
      distanceGraphData.forEach((element) => {
        // Convert specific properties to floats
        element.distanceTravel = parseFloat(element.distanceTravel);
      });
    }
    // this.distaceGraph = response?.data?.data?.distancegraph;
    this.distaceGraph = distanceGraphData;
    return response?.data?.data?.distancegraph;
  }

 
}

export default TrackingDashboardStore;
