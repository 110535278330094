import { Col, Row, Select, Spin } from "antd";
import React, { Component } from "react";
import CompanyAutocomplete from "./CompanyAutocomplete";
import MultiVideoMonitoringStore from "../store/MultiVideoMonitoringStore";
import conf from "../config";
import withRouter from "../withRouter";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { observer } from "mobx-react";

 class MultiVideoMonitoring extends Component {
  constructor(props) {
    super(props);
    this.state = { companyId: "" ,deviceURLList:[],spin:false};
    this.multiVideoMonitoringStore = new MultiVideoMonitoringStore(this);
  }
  handleChange = async (data) => {
    await this.multiVideoMonitoringStore.getVehicles({ companyId: data.value });
    await this.setState({ update: 1, companyId: data.value });
  };
  async componentDidMount(){
    if (Number(this.props.getUserData().isadmin) !== -1 && Number(this.props.getUserData().isadmin) !== 3) {
      console.log(this.props.getUserData().userCompanyId)
      await this.setState({ companyId: this.props.getUserData().userCompanyId })
      await this.multiVideoMonitoringStore.getVehicles({companyId:this.props.getUserData().userCompanyId} )
      await this.setState({ update: 1 });
    }
  }

  showDevices=async()=>{
   let info = await this.multiVideoMonitoringStore.deviceObj
   let arr=[]
    for(let i=1;i<=40;i++){
        if(i<13){
            let url =
        conf.mediaUrl +
        "/vss/apiPage/RealVideo.html?token=" +
        info?.token +
        "&deviceId=" +
        info?.full_imei +
        "&chs=" +
        i +
        "&stream=0&wnum=1&panel=0";
        console.log("asd")
      arr.push(url)
        }else{
            let url =
            conf.mediaUrl +
            "/vss/apiPage/RealVideo.html?token=" +
            info?.token +
            "&deviceId=" +
            info?.full_imei +
            "&chs=" +
            i +
            "&stream=0&wnum=1&panel=0";
            arr.push(url)
        }
    }
   await this.setState({deviceURLList:arr})
    console.log(arr)
   await this.spinningFunc()
   await this.setState({update:1})
  }

  spinningFunc=()=>{
this.setState({spin:true})
setTimeout(() => {
    this.setState({spin:false})
}, 10000);
  }
  render() {
    return (
      <div>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" ,overflow:"auto"}}>
            <Col span={12}>
              {/* <div className="heading">WASL Driver List</div> */}
              {/* <div className="heading">{"Multi Video Monitoring"}</div> */}
              <div className="heading">{this.context.translate("_MULTI_VIDEO_MONITORING_")}</div>
            </Col>
          </Row>
        </div>

        <Row className="video-monitoring">
          <Col span={4} className="live-video-streaming-block">
            {/* <div className="heading">Live Video Streaming</div> */}
            <div className="heading">{this.context.translate("_LIVE_VIDEO_STREAMING_")}</div>
            <CompanyAutocomplete
              onChange={(v) => this.handleChange(v)}
              style={{ width: "100%", padding: "10px" }}
            />
           <div style={{padding:"10px"}}>
           <Select 
              style={{ width: "100%", }}
              fieldNames={{
                value: "vehicleId",
                label: "vehicleNo",
              }}
              // placeholder="Select Vehicle"
              placeholder={this.context.translate("_SELECT_VEHICLE_")}
              optionFilterProp="children"
              onSelect={async (vehicleId) => {
                await this.multiVideoMonitoringStore.getMultiDevice({
                  companyId: this.state.companyId,
                  vehicleId: vehicleId,
                });
                await this.setState({ update: 1 });
                await this.showDevices()
              }}
              options={this.multiVideoMonitoringStore.vehicleArray}
            />
           </div>
          </Col>
          <Col span={20} style={{height:"90vh"}}>
          <Spin spinning={this.state.spin} fullscreen >
<div style={{height:"90vh",overflow:"auto"}}>
{this.state.deviceURLList?.map((val)=>{
   return( <iframe
                      style={{ padding: "20px",height:"262px" }}
                      width="33%"
                      height="33%"
                      src={val}
                      title="YouTube Video"
                      allowFullScreen
                      // poster="../assets/images/devicePosterImage.jpg"
                    ></iframe>)
})}
</div>
</Spin>
          </Col>
        </Row>
      </div>
    );
  }
}
MultiVideoMonitoring.contextType = StoreContext;
export default observer(withRouter(MultiVideoMonitoring));