import React from "react";
import {
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Select,
  Table,
  Button,
  Typography,
  Space,
  DatePicker,
  TimePicker,
  Tag,
  Avatar,
  Row,
  Col,
  Dropdown,
  Checkbox,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import {
  DeleteFilled,
  DownloadOutlined,
  EditFilled,
  PlusOutlined,
  UserOutlined,
  DownOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import withRouter from "../withRouter";
import CommandSentReportStore from "../store/CommandSentReportStore";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import WaslBusTripReportStore from "../store/WaslBusTripReportStore";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Cookies from "js-cookie";

import moment from "moment";
import config from "../config";

import { format } from "date-fns";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import VehicleStore from "../store/VehicleStore";
const { RangePicker } = DatePicker;
dayjs.extend(customParseFormat);
const { Search } = Input;
const dateFormat = "DD-MM-YYYY hh:mmA";
const defaultValue = moment().startOf("day").hour(0).minute(0).second(0);
const { Option } = Select;
class WaslBusTripReport extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.waslBusTripResponseStore = new WaslBusTripReportStore(this);
    this.vehicleStore = new VehicleStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      companyId: "",
      fromDate: "",
      toDate: "",
      selectedVehicles: "",
      selectAllVehicles: false,
      modalVisible: false,
      column: "",
      filter: "",
      Notcompany: false,
      Notasset: false,

      selectedColumns: [
        {
          // title: "Vehicle No",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",
          width: "15%",
        },
        {
          // title: "Trip No",
          title: context.translate("_TRIP_NO_"),
          dataIndex: "tripNumber",
          key: "tripNumber",
          width: "15%",
        },
        {
          // title: "Driver",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
          dataIndex: "driverName",
          key: "driverName",
          width: "15%",
        },

        {
          // title: "Passenger In",
          title: context.translate("_PASSENGE_IN_"),
          dataIndex: "numberOfPassengersIn",
          key: "numberOfPassengersIn",
          width: "15%",
        },
        {
          // title: "Passenger Out",
          title: context.translate("_PASSENGER_OUT_"),
          dataIndex: "numberOfPassengersOut",
          key: "numberOfPassengersOut",
          width: "15%",
        },
        {
          // title: "Trip Status",
          title: context.translate("_TRIP_STATUS_"),
          dataIndex: "waslTripStatus",
          key: "waslTripStatus",
          width: "15%",
        },
        {
          // title: "Station Code",
          title: context.translate("_STATION_CODE_"),
          dataIndex: "StationCode",
          key: "StationCode",
          width: "15%",
        },
        {
          // title: "Update Date",
          title: context.translate("_UPDATE_DATE_"),
          dataIndex: "tripUpdateDateTime",
          key: "tripUpdateDateTime",
          width: "15%",
        },
        {
          // title: "Activity",
          title: context.translate("_ACTIVITY_"),
          dataIndex: "activity",
          key: "activity",
          width: "15%",
        },
        {
          // title: "Response",
          title: context.translate("_RESPONSE_"),
          dataIndex: "response",
          key: "response",
          width: "15%",
        },
        {
          // title: "Reply",
          title: context.translate("_REPLY_"),
          dataIndex: "reply",
          key: "reply",
          width: "15%",
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },

        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            // interval: this.state.interval,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            colList: result.toString(),
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.waslBusTripResponseStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.waslBusTripResponseStore.exportpdf(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }

  async componentDidMount() {
    this.setState({ pageSize: 50 });

    await this.setState({ column: this.state.selectedColumns });
    if(Number(this.props.getUserData().isadmin)!==-1){
    
      let companyId = this.props.getUserData().userCompanyId;
   
        this.setState({companyId:companyId})
        this.handleChange({value:companyId});
    }

  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }
  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };
  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ currentPage: 1, companyId: comp?.value });
    await this.setState({ selectedVehicles: "", selectAllVehicles: false });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    await this.setState({ listUpdated: 1 });

    // this.handleSearch();
  }

  // async onSearch(value) {
  //     console.log(value)
  //     await this.setState({ emailId: value });
  //     this.handleSearch();
  // };

  async handleSearch() {
    this.setState({ Notasset: false });
    await this.waslBusTripResponseStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });

    await this.setState({ listUpdated: 1 }); //need to reload data
  }

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (!this.state.selectedVehicles) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false });
      await this.waslBusTripResponseStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
        vehicleId: this.state.selectedVehicles,
        fromDate: this.state.fromDate,
        toDate: defaultValue.format("YYYY-MM-DD HH:mm:ss"),
      });
      // console.log(this.state.fromDate,this.state.toDate,)
      await this.setState({ listUpdated: 1 });
    }
  };
  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  render() {
    //console.log('in render1')
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const assetInputStyle = this.state.Notasset
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Wasl Bus Trip Report</div> */}
              <div className="heading">
                {this.context.translate("_WASL_BUS_TRIP_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.waslBusTripResponseStore?.waslBusTripResponseStore
                      ?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Space>
              <Col span={16}>
                <CompanyAutocomplete
                  onChange={(value) => this.handleChange(value)}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
              </Col>

              <Col>
                {/* <Select
                  onSearch={this.handleSearch}
                  value={this.state.selectedVehicles}
                  onChange={(val) => this.setState({ selectedVehicles: val ,Notasset:false})}
                  maxTagCount="responsive"
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_")}
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 150 }}
                  // style={assetInputStyle }
                  status={this.state.Notasset ? 'error' : false}
                /> */}

                <Select
                  value={this.state.selectedVehicles ? this.state.selectedVehicles : null}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    this.setState({ Notasset: false });
                  }}
                  style={{ width: 150 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
              </Col>
              <Col>
                {/* <RangePicker
                  style={{ width: 300 }}
                  onChange={(dates) => {
                    // console.log(val)
                    // this.onDateTimeChange(val)
                    const format = "YYYY-MM-DD HH:mm:ss";
                    this.setState({
                      fromDate: dates[0].format(format),
                      toDate: dates[1].format(format),
                    });
                  }}
                  // renderExtraFooter={() => "extra footer"}
                  showTime={{ format: "h:mm A" }}
                /> */}
                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
              </Col>

              <Col>
                <Button type="primary" onClick={() => this.showData()}>
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Col>
            </Space>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={250}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.waslBusTripResponseStore?.total}
            columns={this.state.column}
            dataSource={this.waslBusTripResponseStore?.waslBusTripResponseStore}
            loading={this.waslBusTripResponseStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>
      </>
    );
  }
}
WaslBusTripReport.contextType = StoreContext;
export default observer(withRouter(WaslBusTripReport));
