import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'

import config from "../config";
import {
  action,

  makeObservable,
  observable,

} from 'mobx';

import dayjs from "dayjs";
class DistanceGraphStore {
    vehiclesArray=[]
  total = 0
  loading = false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        vehiclesArray:observable,
      total: observable,
      loading: observable,
      loadDistanceGraphData: action,
    
      getVehiclesUsingCompanyId:action,
    });
    this.rootStore = rootStore;

  }

  async loadDistanceGraphData({ offset, limit,companyId,vehicleId,distance,fromDate,toDate}) {
    this.loading = true;
    var postData = {"companyId":companyId,"vehicleId":vehicleId,"distance":distance,"fromDate":fromDate,"toDate":toDate,"offset":0,"limit":15,"page":1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=distancegraph", "postData=" + base64Input);
    console.log("fuel detis ", response?.data?.data?.distancegraph)
    var distanceGraphData =response?.data?.data?.distancegraph
    if (distanceGraphData && (distanceGraphData)) {
      distanceGraphData.forEach((element) => {
          if(element.startTime){
            const formattedStartTime = dayjs(element.startTime).format('YYYY-MM-DD');
            element.startTime=formattedStartTime;
          }
     
        // Convert specific properties to floats
        element.distanceTravelled = parseFloat(element.distanceTravelled);
       
      });
    }
    this.distanceGraph = distanceGraphData

    // this.distanceGraph = response?.data?.data?.distancegraph;
    return response?.data?.data?.distancegraph;
  }

  async getVehiclesUsingCompanyId(companyId) {
    console.log("companyId" + companyId);
    // var postData = { companyId: "96" };
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);
    console.log(response, "post");
    this.vehiclesArray = response?.data?.data;
    return response?.data?.data;
  }





 
}

export default DistanceGraphStore;
