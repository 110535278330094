import React, { Component } from "react";
import { Button, Col, Form, Input, Row, Spin, Upload } from "antd";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";

import ServiceTemplateStore from "../store/ServiceTemplateStore";
import { UploadOutlined } from "@ant-design/icons";
import config from "../config";

class ServiceTemplateAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = { loadingSpin: false, photo: "", serviceId: -1, };

    this.serviceTemplateStore = new ServiceTemplateStore(this);
  }
  async componentDidMount() {
    const serviceId = this.props.serviceId;

    if (serviceId) {
      await this.filldata(serviceId);
    }

    this.setState({ update: 1 });
  }

  async filldata(serviceId) {
    const getServiceTempData =
      await this.serviceTemplateStore.getServiceTempData(serviceId);

    this.setState({ serviceId: getServiceTempData.serviceId });

    this.formRef.current.setFieldsValue({
      serviceId: getServiceTempData.serviceId,
      serviceName: getServiceTempData.serviceName,
      photo: getServiceTempData.logo,
    });

    this.setState({ photo: getServiceTempData.logo });
  }

  onServiceDetailSubmit = async (fieldsValue) => {
    const data = {
      ...fieldsValue,
      serviceId: this.state.serviceId,
      photo: this.state.photo,
    };

    this.setState({ loading: true });
    const response = await this.serviceTemplateStore.addData(data);
    await this.props.response(response);

    this.setState({ loading: false });
  };

  getUploadData = async (fileUp) => {
    // if (fileUp.fileList[0]?.type == "image/png") {
    let fileinfo = fileUp.file.response;
    if (fileinfo?.success == "S") {
      await this.setState({ photo: fileinfo.data });
    }
    this.serviceTemplateStore.setPhotoFromDb(fileUp.fileList);
    this.setState({ update: 1 });
    // }
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              ref={this.formRef}
              layout="vertical"
              name="nest-messages"
              onFinish={this.onServiceDetailSubmit}
              style={{ maxWidth: "100%", padding: 0 }}
              className="formClass"
            >
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="serviceName"
                    label={this.context.translate("_NEW_SERVICE_TYPE_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    // name="logo"
                    label=" "
                    className="formCheckboxCss"
                    getValueFromEvent={this.normFile}
                  >
                    <Upload
                      name="photo"
                      action={
                        config.baseURL + "/index.php?c=company&a=fileupload"
                      }
                      listType="picture"
                      data={{ fileType: "driverphoto", fieldName: "photo" }}
                      onChange={this.getUploadData}
                      onRemove={async () => {
                        await this.serviceTemplateStore.setPhotoFromDb([]);
                        await this.setState({ photo: "", fileList: [] });
                      }}
                      maxCount={1}
                      // accept=".jpg,.png"
                      // Add the beforeUpload prop to perform file extension validation

                      {...this.serviceTemplateStore.photoFromDb}
                    >
                      <Button className="imgupload" icon={<UploadOutlined />}>
                        {this.context.translate(
                          "_FLEET_DASHBORD_NEWPOI_PHOTO_"
                        )}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>

              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal();
                  }}
                >
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="formSaveButton"
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}
ServiceTemplateAdd.contextType = StoreContext;
export default observer(withRouter(ServiceTemplateAdd));
