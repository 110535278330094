import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi,baseURL } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class FuelFilledReportStore {
  loading=false
  fuelFilledReport = []
  getDrivers=[]
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
      fuelFilledReport: observable,
      loadData: action,

    });
    this.rootStore = rootStore;

  }



  async loadData({ companyId,vehicleId,offset, limit,driverId,fromDate,toDate,pageNo }) {
    this.loading=true
    console.log("vehicle list")
    var postData = { "companyId": companyId, "vehicleId": vehicleId, "driverId": driverId, "fromDate": fromDate, "toDate": toDate, "offset": offset,"limit":limit, "page":pageNo }

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=FuelfillRep", "postData=" + base64Input);

    console.log(response?.data?.data?.fuelfillrep)
    this.fuelFilledReport = response?.data?.data?.fuelfillrep
    this.total = response?.data?.data?.TotalCount
    this.loading=false
  }

  async getDriversByCompanyId({companyId}){
    var postData = {companyId:companyId}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=driver&a=getdatabycompany", "postData=" + base64Input);
    console.log(response, "post")
    this.getDrivers =response?.data?.data
    return response?.data?.data
  }

  

  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    let distanceInt= 0
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportfuelrepexcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&did="+
      getData.DriverId+
      "&fD=" +
      getData.fromDate +
     
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportfuelreppdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&did="+
      getData.DriverId+
      "&fD=" +
      getData.fromDate +
     
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportfuelrepcsv&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&did="+
      getData.DriverId+
      "&fD=" +
      getData.fromDate +
     
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportxml(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportfuelrepxml&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&did="+
      getData.DriverId+
      "&fD=" +
      getData.fromDate +
     
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
}

export default FuelFilledReportStore;
