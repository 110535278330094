import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi, baseURL } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class GeofenceVisitReportStore {

  total = 0
  geofenceVisitStore = []
  rootStore
  loading = false

  constructor(rootStore) {
    makeObservable(this, {
      total: observable,
      geofenceVisitStore: observable,
      loadData: action,
      loading: observable
    });
    this.rootStore = rootStore;

  }



  async loadData({ companyId, vehicleId, geoZoneId, fromDate, toDate, offset, limit,pageNo }) {
    this.loading = true
    console.log("vehicle list")
    // var postData = { "companyId": companyId, "vehicleId": vehicleId, "geoZoneId": geoZoneId, "fromDate": fromDate, "toDate": toDate, "offset": offset, "limit": limit, "page": pageNo, }
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      geoZoneId:geoZoneId,
      fromDate: fromDate,
      toDate: toDate,
      // intervel: intervel,
      // filter: filter,
      offset: offset,
      limit: limit,
      page: pageNo,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=geofence&a=geofencevisit", "postData=" + base64Input);

    console.log(response?.data?.data?.data)
    this.geofenceVisitStore = response?.data?.data?.geofencevisit;
    this.geofenceData= response?.data?.data?.geofencevisit.map((items)=>items.tableData);
    console.log(this.geofenceData)
    this.total = response?.data?.data?.TotalCount;
    this.loading = false
  }


  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=geofence&a=exportgeofencevisitexcel&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      //   "&int="+
      //   getData.interval+
      "&userId=" +
      getData.userId +
      " &geoZoneId=" +
      getData.geoZoneId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    console.log(getData)
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=geofence&a=exportgeofencevisitpdf&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      //   "&int="+
      //   getData.interval+
      "&userId=" +
      getData.userId +
      " &geoZoneId=" +
      getData.geoZoneId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +      
      getData.colList +
      "&language=" +
      lang;

    // var postData = getData;

    // const base64Input = await base64_encode(JSON.stringify(postData));
    
    // var response = await postApi.getClient().post("index.php?c=geofence&a=exportgeofencevisitpdf", "postData=" + base64Input);
    // console.log(response)
  }
}

export default GeofenceVisitReportStore;
