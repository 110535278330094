import React from "react";
import { Button, Modal } from "antd";
import conf from "../config";

const HelpModal = ({ show, closeModal }) => {
  const shareToWhatsApp = () => {
    window.open(
      "https://api.whatsapp.com/send/?phone=966561869239&text&type=phone_number&app_absent=0"
    );
  };

  const sendEmail = () => {
    window.open("https://www.google.com/intl/en/gmail/about/");
  };

  return (
    <>
      <Modal
        title="Support"
        open={show}
        onCancel={() => closeModal()}
        footer={null}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* {conf.project === "starlingeye" ? ( */}
            <Button
              onClick={() => sendEmail()}
              icon={
                <i
                  class="bi bi-envelope"
                  style={{ fontSize: "18px", color: "red" }}
                ></i>
              }
            >
             { conf.project === "starlingeye" ? 'support@starlingtechs.com' : 'vijeta@pointmatrix.ae'}
            </Button>
          {/* )
           : ( */}
            {/* <Button
              onClick={() => shareToWhatsApp()}
              icon={
                <i
                  class="bi bi-whatsapp"
                  style={{ fontSize: "18px", color: "teal" }}
                ></i>
              }
            >
              +966561869239
            </Button>
          )
          } */}

          <Button
            icon={
              <i
                class="bi bi-telephone"
                style={{ fontSize: "18px", color: "teal" }}
              ></i>
            }
          >
            {conf.project === "starlingeye" ? "+97142734585" :'+971503606930'}
          </Button>
        </div>
        <br />
        <p>
          If you need further assistance, please contact the provided support
          numbers.
        </p>
        {/* <button onClick={handleSignOut}>Sign Out</button> */}
      </Modal>
    </>
  );
};

export default HelpModal;
