import React from "react";
import { Button, Col, Modal, Row, Select, Space,message } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import withRouter from "../withRouter";

import dayjs from "dayjs";
import CompanyStore from "../store/CompanyStore";

import Cookies from "universal-cookie";
import VehicleOdometerRecordsStore from "../store/VehicleOdometerRecordsReports";
import { Link } from "react-router-dom";

class VehicleOdometerRecords extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.odometerRecordsStore = new VehicleOdometerRecordsStore();

    this.companyStore = new CompanyStore();
    this.cookies = new Cookies();
    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      showDeleteModal: false,
      showProcessDataModal: false,
      showAlertModal: false,
      selectedVehicle: "",
      companyId: "",
      loadData: 0,
      offset: 0,
      limit: 50,
      selectvehProData: [],
    };
  }
  // async componentDidUpdate(prevProps, prevState) {

  // }
  async componentDidMount() {
    // console.log(this.viewStateStore.)
    if (Number(this.props.getUserData().isadmin) !== -1) {
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });

      await this.handleSearch();
    }

    this.setState({ pageSize: 50 });

    this.columns = [
      {
        // title: "Account",
        title: this.context.translate("_FLEET_DASHBORD_NEWPOI_CUSTOMER_"),
        dataIndex: "company",
        width: "20%",
      },
      {
        // title: " Asset Name",
        title: this.context.translate("_FLEET_REPORTS_VEHICLENO_"),
        dataIndex: "vehicleNo",
        width: "20%",
      },

      {
        // title: "Change Odometer",
        title: this.context.translate("_CHANGE_ODOMETER_"),
        dataIndex: "odometer",
        width: "20%",
      },
      {
        // title: "Date Time  ",
        title: this.context.translate("_DATE_TIME_"),
        dataIndex: "datetime",
        width: "20%",
        render: (value) => {
          if (value) {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        // title: "Date Time  ",
        title: this.context.translate("_CREATED_DATE_"),
        dataIndex: "createDate",
        width: "20%",
        render: (value) => {
          if (value) {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          } else {
            return <></>;
          }
        },
      },
    ];
  }

  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      currentPage: 1,
      pageSize: 50,
    });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);

    await this.setState({ companyVal_Lab: singleData });
    // await this.setState({ companyId: comp.value });
    await this.setState({ selectedVehicles: "" });

    await this.setState({ loadData: 1 });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.odometerRecordsStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      isadmin: this.props.getUserData().isadmin,
      issuperadmin: this.props.getUserData().issuperadmin,
    });

    await this.setState({ loadData: 1 });
  }

  handleOdometerProcessData = async () => {
    const data = {
      recId: this.state.selectvehProData,
    };
    const response = await this.odometerRecordsStore.processOdometerData(data);
    console.log(response);

    if (response?.success == "S") {
      message.success(response.message);
    } else {
      message.error(response.errors[0][0]);
    }
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_ODOMETER_RECORDS_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Link to="/vehicleodometerlist">
                  <Button>{this.context.translate("_BACK_")}</Button>{" "}
                </Link>

                <Button
                  onClick={async () => {
                    this.state.selectedVehicle.length > 0
                      ? await this.setState({ showDeleteModal: true })
                      : await this.setState({ showAlertModal: true });
                  }}
                >
                  <i class="bi bi-trash3" style={{ marginRight: "5px" }}></i>
                  {/* Delete */}
                  {this.context.translate("_FLEET_COMPANY_COMPANYLIST_DELETE_")}
                </Button>

                <Button
                  onClick={async () => {
                    this.state.selectvehProData.length > 0
                      ? await this.setState({ showProcessDataModal: true })
                      : await this.setState({ showAlertModal: true });

                    await this.handleOdometerProcessData();
                  }}
                >
                  {this.context.translate("_PROCESS_DATA_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  // allowClear={true}
                  onChange={(value) => {
                    this.handleChange(value);
                  }}
                  style={{ width: 200 }}
                />
              </Space>
            </Col>
            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            rowSelection={{
              // columnWidth: 2,
              fixed: true,

              selectedRowKeys: this.state.selectedVehicle,
              onChange: async (selectedRowKeys, selectedRows) => {
                console.log(selectedRowKeys, selectedRows);
                await this.setState({
                  selectedVehicle: selectedRowKeys,
                  selectvehProData: selectedRowKeys,
                });
              },
            }}
            // value={this.state.selectedRowKeys}
            rowKey="vehicleId"
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.odometerRecordsStore?.total}
            columns={this.columns}
            dataSource={this.odometerRecordsStore?.odometerData}
            loading={this.odometerRecordsStore?.loading}
            onChange={this.handleTableChange}
          />

          <Modal
            width={350}
            className="alert-model"
            title=<div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-trash3"
              ></i>{" "}
              {/* Remove Asset? */}
              {this.context.translate(
                "_FLEET_VEHICLE_VEHICLELIST_REMOVETITLE_"
              )}
            </div>
            open={
              this.state.selectedVehicle.length !== 0 &&
              this.state.showDeleteModal
            }
            onCancel={() => this.setState({ showDeleteModal: false })}
            footer={[
              <Button
                // key="no"
                onClick={() => this.setState({ showDeleteModal: false })}
              >
                {this.context.translate("_FLEET_RULELIST_CRONRULE_NO_")}
                {/* No */}
              </Button>,
              <Button
                type="primary"
                onClick={(e) => {
                  // this.deleteMultipleVehicles();

                  console.log(this.state.selectedVehicle);
                  this.setState({
                    showDeleteModal: false,
                    selectedVehicle: "",
                  });
                }}
              >
                {this.context.translate("_FLEET_RULEDETAIL_ACTIVEDDL_YES_")}
                {/* Yes */}
              </Button>,
            ]}
          >
            {this.context.translate("_SURE_TO_DELETE_")}
            {/* <p>Are you sure you want to delete?</p> */}
          </Modal>

          <Modal
            width={350}
            className="alert-model"
            title={
              <div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-exclamation-triangle"
                ></i>{" "}
                {this.context.translate("_FLEET_ALERT_")}
              </div>
            }
            open={this.state.showAlertModal}
            onOk={() => this.setState({ showAlertModal: false })}
            onCancel={() => this.setState({ showAlertModal: false })}
            okText="OK"
            cancelText="Cancel"
            footer={[
              <Button
                type="primary"
                onClick={() => this.setState({ showAlertModal: false })}
              >
                {/* OK */}
                {this.context.translate(
                  "_FLEET_DASHBORD_VEHICLE_COMMAND_OKBTN_"
                )}
              </Button>,
            ]}
          >
            {/* <p>Please select vehicles</p> */}
            {this.state.showDeleteModal
              ? this.context.translate("_SELECT_VEHICLES_")
              : this.context.translate("_SELECT_RECORD_")}
          </Modal>
        </div>
      </>
    );
  }
}
VehicleOdometerRecords.contextType = StoreContext;
export default observer(withRouter(VehicleOdometerRecords));
