import { encode as base64_encode } from "base-64";
import config from "../config";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class PointofInterest {
  loading = false;
  total = 0;
  poiList = [];
  poiTypeArr=[]
  photoFromDb={}
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      total: observable,
      poiList: observable,
      poiTypeArr:observable,
      photoFromDb:observable,
      loadData: action,
      deleteRecord: action,
      addData: action,
      poiTypeData:action,
      getPOIData:action,
      setPhotoFromDb:action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      offset: offset,
      limit: limit,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=poidata&a=getGridPoi", "postData=" + base64Input);

    this.poiList = response?.data?.data?.poidata;
    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
  }

  async addData(poiDetails) {
    var postData = poiDetails;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=poidata&a=update", "postData=" + base64Input);
    console.log(response);
    return response.data;
  }

  async getPOIData({ Id, companyId }) {
  
    var postData = {
      pId: Id,
      companyId: companyId,
      limit: 1,
      offset: 0,
    };
    // console.log(postData)

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=poidata&a=getGridPoi",
        "postData=" + base64Input
      );

      const singleRow = response?.data?.data?.poidata[0];
      
      if(singleRow.path){
          
        const fileList = [{
            name: singleRow.path,
            status: 'success',
            url: config.fleetURL+singleRow.path,
        }]
      
        this.setPhotoFromDb(fileList)
            
      }
      return singleRow

    // return response?.data?.data?.poidata[0];
  }

  async setPhotoFromDb(photo){
    console.log(photo)
    this.photoFromDb = {fileList :photo}
  }

  async poiTypeData() {
    var response = await postApi
      .getClient()
      .post("index.php?c=poidata&a=getPoiType");
    this.poiTypeArr = response.data.data;
  }

  async deleteRecord({ id }) {
    const postData = {
      id: id,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=poidata&a=delete",
        "postData=" + base64Input
      );

    return response;
  }
}

export default PointofInterest;
