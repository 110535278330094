import React from "react";
import {
  Form,
  Button,
  Space,
  Row,
  Col,
  Upload,
  Modal,
  message,
  Tooltip,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import config from "../config";

import { DeleteFilled, PlusOutlined, UploadOutlined } from "@ant-design/icons";

import DeviceListStore from "../store/DeviceListStore";
import withRouter from "../withRouter";
import DeleteConfirm from "./DeleteConfirm";

import ListTable from "./ListTable";
import DeviceDetailsAdd from "./DeviceDetailsAdd";

class DeviceList extends React.Component {
  constructor(props) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.deviceListStore = new DeviceListStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      showUpload: false,
      openDeviceDetailsForm: false,
      Id: "",
      listDataUpdate: false,
    };

    // this.menuProps = {
    //     items: [
    //         {
    //             label: 'Sample Excel',
    //             key: '1',
    //             icon: <DownloadOutlined />,
    //         },
    //         {
    //             label: 'Upload',
    //             key: '2',
    //             icon: <PlusOutlined />,
    //         },

    //     ],
    //     onClick: () => {

    //     },
    // };
  }

  onEdit = async (record) => {
    console.log(record.Id);
    // this.props.navigate("/devicedetailsadd/" + record.Id);
    await this.setState({ Id: record.Id });
    await this.setState({ openDeviceDetailsForm: true });
  };

  async componentDidUpdate(prevProps, prevState) {
    if (Number(this.props.getUserData().isadmin) === 1) {
      this.props.navigate("/login");
    }
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    });
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      await this.deviceListStore.loadData({ offset: 0, limit: 50 });
      await this.setState({ listDataUpdate: false });
    }
  }
  async componentDidMount() {
    // await this.context.companyStore.companyList({ offset: 0, limit: 50 });
    // await this.context.distributorStore.distributorList();

    await this.deviceListStore.loadData({ offset: 0, limit: 50 });
    this.setState({ pageSize: 50 });

    this.columns = [
      {
        // title: "IMEI",
        title: this.context.translate("_FLEET_DASHBORD_SETTING_IMEI_"),
        dataIndex: "imeiNo",
        width: "15%",
        render: (value, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        // title: "Model",
        title: this.context.translate(
          "_FLEET_DASHBORD_VEHICLE_INFORMATION_MODEL_"
        ),
        dataIndex: "model",
        width: "15%",
      },
      {
        // title: "Shipment Date",
        title: this.context.translate("_SHIPMENT_DATE_"),
        dataIndex: "shipment",
        width: "15%",
      },
      {
        // title: "Warranty Date",
        title: this.context.translate("_WARRANTY_DATE_"),
        dataIndex: "warranty",
        width: "15%",
      },
      {
        // title: "Status",
        title: this.context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "stockstatus",
        width: "10%",
        render: (value, record) => {
          console.log(value);
          if (value === "Used") {
            return (
              <Tooltip
                title={`${this.context.translate("_VEHICLE_NO_")} ${
                  record.vehicleNo
                }`}
                color="green"
              >
                {value}
              </Tooltip>
            );
          } else if (value === "In Stock") {
            return (
              <Tooltip
                title={`${this.context.translate("_VEHICLE_NO_")} `}
                color="green"
              >
                {value}
              </Tooltip>
            );
          }
        },
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        fixed: "right",
        width: "5%",
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                  placement="bottom"
                    // title="Edit Device Record"
                    title={this.context.translate("_EDIT_DEVICE_RECORD_")}
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Tooltip
                      placement="bottom"
                      // title="Delete Device Record"
                      title={this.context.translate("_DELETE_DEVICE_RECORD_")}
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination, "pagi");
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.deviceListStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      // total:2882
    });
    this.setState({ loading: false });
  }

  async onDelete(record) {
    console.log(record.Id, "record");
    const response = await this.deviceListStore.deleteRecord({
      Id: record.Id,
    });
    //  console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.success == "S") {
      message.success(resp.message);
      await this.setState({ openDeviceDetailsForm: false });
      await this.setState({ listDataUpdate: true, currentPage: 1 });

      //  await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    } else {
      message.error(resp.message);
    }
  };

  // validateFile = (file) => {
  //   this.setState({ fileList: file });
  //   console.log(file);
  //   const allowedExtensions = [".xlsx", ".xls",".csv"];
  //   const fileName = file.name;
  //   const fileExtension = fileName.slice(
  //     ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
  //   );
  //   if (!allowedExtensions.includes("." + fileExtension.toLowerCase())) {

  //     message.error("Allowed File Extension are xls,xlsx,csv and size upto 1mb");
  //     return false;
  //   }
  //   const maxSize = conf.UploadExcelSize
  //   if (file.size > maxSize) {
  //     message.error("File size must be less than or equal to 1MB");
  //     return false;
  //   }

  //   return true;
  // };

  getUploadData = async (fileUp) => {
    let fileinfo = fileUp.file.response;

    console.log(fileinfo);

    if (fileinfo?.success == "S") {
      // console.log(fileinfo.data)

      await this.setState({ excelFile: fileinfo.data });
    }

    this.setState({ update: 1 });
  };

  onExcelSubmit = async (fieldsValue) => {
    const data = {
      ...fieldsValue,
      companyId: this.state.companyId,
      excelFile: this.state.excelFile,
    };

    const resp = await this.deviceListStore.addDeviceByExcel(data);
    console.log(resp.message);
    if (resp?.success == "S") {
      message.success(resp.message);
      this.setState({ showUpload: false });
      this.setState({ excelFile: "" });
    } else {
      message.error(resp.errors[0].error);
    }
  };

  closeModal = () => {
    this.setState({ openDeviceDetailsForm: false, Id: "" });
  };
  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Device List</div> */}
              <div className="heading">
                {this.context.translate("_FLEET_GROUP_GROUPLIST_TITLE_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                {/* <AddButton
                  title={this.context.translate("_ADD_")}
                  link="/devicedetailsadd"
                /> */}
                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  placement="left"
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({ openDeviceDetailsForm: true, Id: "" })
                    }
                  ></Button>
                </Tooltip>

                <Tooltip
                  // title="Upload"
                  title={this.context.translate("_UPLOAD_")}
                  color={config.tooltipColorEdit}
                >
                  <Button
                    type={config.uploadBoxType}
                    onClick={() => this.setState({ showUpload: true })}
                  >
                    <UploadOutlined />
                  </Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row style={{ padding: "10px 0px" }}>
            <Col span={16}></Col>
            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            // pagination={false}
            total={this.deviceListStore?.total}
            columns={this.columns}
            dataSource={this.deviceListStore?.deviceList}
            loading={this.deviceListStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.showUpload && (
          <>
            {" "}
            <Modal
              open={this.state.showUpload}
              maskClosable={false}
              // title="File Upload"
              title={this.context.translate("_FILE_UPLOAD_")}
              onOk={() => this.setState({ showUpload: false })}
              onCancel={() => this.setState({ showUpload: false })}
              footer={false}
              width={400}
            >
              <Form
                name="excelimport"
                layout="vertical"
                onFinish={this.onExcelSubmit}
                style={{ maxWidth: "100%" }}
              >
                <Row>
                  <Col span={12}></Col>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  ></Col>
                </Row>
                <Form.Item
                  name="excelFile"
                  // label="Excel File"
                  style={{ marginTop: "15px" }}
                  rules={[{ required: true }]}
                >
                  <Upload
                    name="excelFile"
                    action={
                      config.baseURL + "/index.php?c=company&a=fileupload"
                    }
                    listType="text"
                    data={{ fileType: "accountimport", fieldName: "excelFile" }}
                    onChange={this.getUploadData}
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />}>
                      {/* Click To Upload Excel File */}
                      {this.context.translate("_CLICKTOUPLOAD_EXCELFILE_")}
                    </Button>
                  </Upload>
                </Form.Item>
                <div className="formBtnGroup">
                  {/* css class */}

                  <Button
                    className="formCancelButton"
                    // style={{ backgroundColor: config.activeColor }}
                    onClick={() => this.deviceListStore.sampleExcel()}
                  >
                    {this.context.translate("_SAMPLE_")}
                    {/* {this.context.translate("_SAMPLE_EXCEL_")} */}
                  </Button>

                  <Button
                    type="primary"
                    className="formSaveButton"
                    htmlType="submit"
                    // loading={this.state.loading}
                  >
                    {/* css class */}
                    {/* Upload */}
                    {this.context.translate("_UPLOAD_")}
                  </Button>
                </div>
                {/* <Row justify={"end"}>
              <Space style={{ padding: "10px" }}>
                <Button type="primary" htmlType="submit">
                  Upload
                </Button>
              </Space>
            </Row> */}
              </Form>
            </Modal>
          </>
        )}

        {this.state.openDeviceDetailsForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Add Device Details"
            title={this.context.translate("_ADD_DEVICE_DETAILS_")}
            centered
            visible={this.state.openDeviceDetailsForm}
            footer={null}
            onCancel={() => {
              console.log("asfasdfasdasas");
              this.setState({ openDeviceDetailsForm: false, Id: "" });
            }}
            width={665}
          >
            <div>
              {this.state.openDeviceDetailsForm && (
                <DeviceDetailsAdd
                  Id={this.state.Id}
                  response={this.responseMsg}
                  closeModal={this.closeModal}
                />
              )}
            </div>
          </Modal>
        )}
      </>
    );
  }
}
DeviceList.contextType = StoreContext;
export default observer(withRouter(DeviceList));
