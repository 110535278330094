import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

import config from "../config";

class RouteManagementStore {
  jobManagement = [];
  getSalesPersonExecutiveArray = [];
  assetVehicleList = [];
  getSalesPersonExArr = [];
  geofenceList = [];
  VehicleList = [];
  getDrivers = [];
  getSalesPersonCoordinatorArray;
  routeData = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      jobManagement: observable,
      getSalesPersonExArr: observable,
      total: observable,
      loading: observable,
      geofenceList: observable,
      VehicleList: observable,
      routeData: observable,
      loadData: action,
      addData: action,
      
     
      deleteRecord: action,
    
    });
    this.rootStore = rootStore;
  }

  async loadData({companyId   
  }) {
    this.loading = true;

    var postData = {
      tokenId: "",
      companyId: companyId,
      
    };
    // var postData = { "companyId": companyId, "vehicleId": null, "deliveryDateFrom": "2023-04-10", "deliveryDateTo": "2023-04-10", "driverName": null, "salesExecutive": null, "customerName": "", "offset": 0, "limit": 50 }
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=routeManagement&a=getRouteManagement", "postData=" + base64Input);

    this.RouteManagement = response.data.data.jobRequest;

    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }
  async getRouteData(id,companyId) {
    // console.log("Id", id);
    var postData = { Id: id,companyId:companyId, offset: 0, limit: 1 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=routeManagement&a=getRouteManagement", "postData=" + base64Input);
    return response.data?.data?.jobRequest[0];
  }
  async addData(values) {
    var postData = values;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=routeManagement&a=update", "postData=" + base64Input);

    return response.data;
  }

  async deleteRecord({ Id, companyId }) {
    var postData = { tokenId: "", companyId: companyId, Id: Id };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=routeManagement&a=delete", "postData=" + base64Input);
    return response;
  }
  async getGeofenceData({ companyId }) {
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=geofence&a=geoData", "postData=" + base64Input);

    this.geofenceList = response?.data?.data.geofence;
    return response?.data?.data;
  }

  
}

export default RouteManagementStore;
