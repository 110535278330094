import React from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Space,
  Dropdown,
  Table,
  Checkbox,
  Modal,
  Tooltip,
  Image,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import withRouter from "../withRouter";

import VehicleNotPollReportStore from "../store/VehicleNotPollReportStore";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import config from "../config";
import { Link } from "react-router-dom";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import MapForVehiclePosition from "./MapForVehiclePosition";
const dotIcon = L.divIcon({
  className: "dot-icon",
  iconSize: [8, 8],
  html: ' <div style="width: 20px; height: 20px; background-color: red; border-radius: 50%;"></div>',
});
class VehicleNotPollReportList extends React.Component {
  constructor(props, context) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.vehicleNotPollReportStore = new VehicleNotPollReportStore();

    this.state = {
      loading: false,
      pageSize: 50,
      // currentPage: 1,
      pageNo: 1,
      offset: 0,
      limit: 50,
      companyId: "",

      modalVisible: false,
      column: "",
      filter: "",

      Notcompany: false,
      Nottime: false,
      vehicleObj: false,
      heading: "",
      selectedColumns: [
        {
          // title: " Vehicle No ",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          // fixed: 'left',
          width: "15%",
          key: "vehicleNo",
        },
        {
          // title: " Vehicle Type",
          title: context.translate("_VEHICLE_TYPE_"),
          dataIndex: "vehicleTypeNew",
          // fixed: 'left',
          width: "15%",
          key: "vehicleTypeNew",
        },

        {
          // title: "  Last Polled Date/Time",
          title: context.translate("_LAST_POLLED_DATETIME_"),
          dataIndex: "timestamp",
          key: "timestamp",

          //fixed: 'left',
          width: "15%",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
        },
        {
          // title: "   Last Polled Location",
          title: context.translate("_LAST_POLLED_LOCATION_"),
          dataIndex: "location",
          key: "location",

          //fixed: 'left',
          width: "15%",
        },
        {
          // title: " View On map",
          title: context.translate("_FLEET_REPORTS_STOPREPORT_VIEWMAP_"),
          dataIndex: "View",
          key: "View",

          // fixed: 'left',
          width: "15%",
          render: (value, obj) => {
            return (
              <>
                <Image
                  src={require("../assets/images/mapIcon/gps_icon.png")}
                  width={25}
                  height={25}
                  onClick={async () => {
                    await this.setState({
                      vehicleObj: { ...obj, icon: "map-car-icon-green.png" },
                      heading: "Vehicle Not Poll Map",
                    });
                    await this.setState({ loadData: 1 });
                  }}
                />
              </>
            );
          },
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
          icon: <PlusOutlined />,
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
          icon: <PlusOutlined />,
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            polltime: this.state.polltime,
            uId: null,
            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.vehicleNotPollReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.vehicleNotPollReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.vehicleNotPollReportStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.vehicleNotPollReportStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    // await this.vehicleNotPollReportStore.loadData({ offset: 0, limit: 50 });
    // await this.vehicleNotPollReportStore.loadData({ offset: 0, limit: 50 });
    this.setState({ pageSize: 50 });
    await this.setState({ column: this.state.selectedColumns });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
  }
  async componentDidUpdate(prevProps, prevState) {
    if (this.state.vehicleObj !== prevState.vehicleObj) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 10);
    }
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false, pageNo: 1 });
    await this.setState({ companyId: comp?.value });

    // await this.handleSearch();
  }

  async handleSearch() {
    // this.setState({ loading: true });
    await this.vehicleNotPollReportStore.loadData({
      offset: (this.state.pageNo - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      pageNo: this.state.pageNo,
    });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    // await this.setState({pageNo:pagination.current})
    console.log(pagination);
    await this.setState({
      // currentPage: pagination.current,
      pageNo: pagination.current,

      pageSize: pagination.pageSize,

      // pageNo: pagination?.current ? pagination?.current : this.state.pageNo,
      // pageSize: pagination?.pageSize ? pagination?.pageSize : this.state.pageSize,
    });
    await this.showData();
  }

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (!this.state.polltime) {
      this.setState({ Nottime: true });
    } else {
      this.setState({ Nottime: false });
    }
    if (this.state.companyId && this.state.polltime) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Nottime: false });

      await this.vehicleNotPollReportStore.loadData({
        offset: (this.state.pageNo - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
        polltime: this.state.polltime,
        pageNo: this.state.pageNo,
      });

      this.setState({ loadData: 1 });
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  render() {
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const timeInputStyle = this.state.Nottime
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Vehicle Not Poll Report</div>{" "} */}
              <div className="heading">
                {this.context.translate("_VEHICLE_NOT_POLL_REPORT_")}
              </div>{" "}
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.vehicleNotPollReportStore?.vehicleNotPollReportStore
                      ?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={this.handleChange}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
                <Input
                  style={{ width: 150 }}
                  status={this.state.Nottime ? "error" : false}
                  // style={timeInputStyle}
                  // placeholder="Time(Min)"
                  placeholder={this.context.translate("_TIME_MIN_")}
                  value={this.state.polltime}
                  onChange={(e) =>
                    this.setState({ polltime: e.target.value, Nottime: false })
                  }
                />
                <Button
                  type="primary"
                  onClick={async () => {
                    // await this.setState({pageSize:,pageNo: 1})
                    // this.handleTableChange();
                    await this.setState({ pageNo: 1, pageSize: 50 });
                    await this.showData();
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.pageNo}
            pageSize={this.state.pageSize}
            total={this.vehicleNotPollReportStore?.total}
            columns={this.state.column}
            dataSource={
              this.vehicleNotPollReportStore?.vehicleNotPollReportStore
            }
            loading={this.vehicleNotPollReportStore?.loading}
            onChange={this.handleTableChange}
            //   pagination={{
            //   pageSize: this.state.pageSize,
            //   //  current:this.state.pageNo
            //   total:this.vehicleNotPollReportStore?.total || 0

            // }}

            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />

          {/* <Modal
          title="Vehicle Not Poll Map"
          open={this.state.showMap}
          centered
          
          width={850}
          height={400}
          onOk={() => this.setState({ showMap: false })}
          onCancel={() => this.setState({ showMap: false })}
          footer={false}
        >
          <MapContainer
            ref={this.mapRef}
            zoom={16}
            center={[
              this.state.modalInfoObj?.latitude,
              this.state.modalInfoObj?.longitude,
            ]}
            style={{ height: "400px", width: "800px" }}


            bounds={this.state.modalInfoObj ? [[this.state.modalInfoObj.latitude, this.state.modalInfoObj.longitude], [this.state.modalInfoObj.latitude, this.state.modalInfoObj.longitude]] : []}

          >

            <ReactLeafletGoogleLayer />
          

            <Marker
              icon={dotIcon}
              position={[
                this.state.modalInfoObj?.latitude,
                this.state.modalInfoObj?.longitude,
              ]}
            >
                              <Tooltip permanent= {true} direction="top">{this.state.modalInfoObj?.vehicleNo}</Tooltip>
             
            </Marker>
          </MapContainer>
        </Modal> */}

          {this.state.vehicleObj && (
            <MapForVehiclePosition
              vehicleObj={this.state.vehicleObj}
              heading={this.state.heading}
              closeMapModal={(resp) => {
                this.setState({ vehicleObj: resp });
              }}
            />
          )}
        </div>
      </>
    );
  }
}
VehicleNotPollReportList.contextType = StoreContext;
export default observer(withRouter(VehicleNotPollReportList));
