import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class FAQStore {
  loading = false;
  total = 0;
  faqList = [];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      total: observable,
      faqList: observable,

      loadData: action,
      deleteRecord: action,

      addData: action,
      getFaqData: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ offset, limit, page }) {
    this.loading = true;
    var postData = {
      offset: offset,
      limit: limit,
      page: page,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=faq&a=FAQList", "postData=" + base64Input);

    this.faqList = response?.data?.data;
    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
  }

  async addData(faqData) {
    var postData = faqData;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=faq&a=update", "postData=" + base64Input);

    return response.data;
  }

  async getFaqData( Id ) {
    var postData = {
      Id: Id,
      limit: 1,
      offset: 0,
    };
    // console.log(postData)

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=faq&a=FAQList",
        "postData=" + base64Input
      );

    return response?.data?.data[0];
  }

  async deleteRecord({ Id }) {
    const postData = { Id: Id };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=faq&a=delete", "postData=" + base64Input);

    return response;
  }
}

export default FAQStore;
