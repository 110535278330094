import React from "react";
import {
  Row,
  Col,
  Input,
  Tag,
  Avatar,
  Button,
  Space,
  Dropdown,
  Popconfirm,
  message,
  Tooltip,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import ShareModalWindow from "./ShareModalWindow";
import {
  PushpinFilled,
  EditFilled,
  DeleteFilled,
  QuestionCircleOutlined,
  DownOutlined,
  SearchOutlined,
  DownloadOutlined,
  CloudUploadOutlined,
  DashboardOutlined,
  PlusOutlined,
  CarOutlined,
  CopyOutlined,
  ShareAltOutlined,
  FacebookOutlined,
  TwitterOutlined,
  MailOutlined,
  SkypeOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";

import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import AddButton from "./AddButton";
import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import withRouter from "../withRouter";

import AssignTripStore from "../store/AssignTripStore";
import CompanyStore from "../store/CompanyStore";
import AssignBusToStudent from "./AssignBusToStudent";
import Icon from "react-multi-date-picker/components/icon";

class AssignTripList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.companyStore = new CompanyStore(this);
    this.onDelete = this.onDelete.bind(this);

    this.assignTripStore = new AssignTripStore();

    // console.log(this.context);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      companyId: "",
      loadList: 0,
      company_value_label: "",
      openShareModal: false,
    };
    this.menuProps = {
      items: [
        {
          label: "Sample Excel",
          key: "1",
          icon: <DownloadOutlined />,
        },
        {
          label: "Upload",
          key: "2",
          icon: <CloudUploadOutlined />,
        },
        {
          label: "Dashboard",
          key: "2",
          icon: <DashboardOutlined />,
        },
      ],
      onClick: () => {},
    };
  }
  async componentDidMount() {
    this.setState({ pageSize: 50 });
    // await this.assignTripStore.loadData({ offset: 0, limit: 50 });
    //this.handleSearch();
    const beforeEditCompanyId = await this.context.viewStateStore
      .assignTripObject.companyId;

    await this.assignTripStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: beforeEditCompanyId,
    });

    this.setState({companyId:beforeEditCompanyId})
    const singleData = await this.companyStore.getCompanyDataById(
      this.context.viewStateStore.assignTripObject.companyId
    );
    await this.setState({ loadList: 1 }); //need to reload data
    await this.setState({ company_value_label: singleData });

    this.columns = [
      {
        // title: "Trip Code",
        title: this.context.translate("_TRIP_CODE_"),
        // width: 150,
        dataIndex: "tripCode",
        key: "tripCode",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
        // fixed: "left",
      },
      {
        // title: "Trip Name",
        title: this.context.translate("_TRIP_NAME_"),
        // width: 150,
        dataIndex: "tripName",
        key: "tripname",
        // fixed: "left",
      },
      {
        // title: "Zone Details",
        title: this.context.translate("_ZONE_DETAILS_"),
        // width: 150,
        dataIndex: "routeZone",
        key: "zonedetails",
        // fixed: "left",
      },
      {
        // title: "Vehicle No",
        title: this.context.translate("_VEHICLE_NO_"),
        // width: 150,
        dataIndex: "vehicleNo",
        key: "vehicleno",
        // fixed: "left",
      },
      {
        // title: "Seater",
        title: this.context.translate("_SERATER_"),
        // width: 150,
        dataIndex: "seater",
        key: "seater",
        // fixed: "left",
      },
      {
        // title: "Available Seats",
        title: this.context.translate("_AVAILABLE_SEATS_"),
        // width: 150,
        dataIndex: "seats",
        key: "seats",
        // fixed: "left",
      },

      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        // key: "Action",
        fixed: "right",
        // width: 50,
        render: (_, record) => {
          let tripPoints = record.TripPoints;
          let data = [];
          tripPoints.forEach((val) =>
            data.push(`${val.latitude},${val.longitude}`)
          );

          return (
            <>
              <Space>
                <Tooltip
                  // title="Copy Trip"
                  title={this.context.translate("_COPY_TRIP_")}
                  placement="bottom"
                  color={config.tooltipColorEdit}
                >
                  <Link to="/assigntripaddnew">
                    <CopyOutlined />
                  </Link>
                </Tooltip>
                {/* <Link
 to={{
   pathname: `/assignbustostudent/`,
   query: {
    title: "asdf",
   
  } 
 }}
              
                  
                > */}
              { /* <Link to={`/assignbustostudent`}>
                  {" "}
                  <Tooltip
                    // title="Assign Bus To Student"
                    title={this.context.translate("_ASSIGN_BUS_TO_STUDENT_")}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <CarOutlined />
                  </Tooltip>
              </Link>*/}
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    // title="Edit Trip Details"
                    title={this.context.translate("_EDIT_TRIP_DETAILS_")}
                    color={config.tooltipColorEdit}
                    placement="bottom"
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      // title="Delete Trip Details"
                      title={this.context.translate("_DELETE_TRIP_DETAILS_")}
                      color={config.tooltipColorDelete}
                      placement="bottom"
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>

                <Tooltip
                  // title="Share Location"
                  title={this.context.translate("_SHARE_LOCATION_")}
                  placement="bottom"
                  color={config.tooltipColorEdit}
                >
                  <ShareAltOutlined
                    onClick={() => {
                      this.setState({ openShareModal: data });
                      console.log(record.TripPoints[0].latitude);
                    }}
                  />
                </Tooltip>
              </Space>
            </>
          );
        },
      },
    ];
  }
  onEdit = async (record) => {
    console.log(record);
    const stId = record.stId;
    await this.context.viewStateStore.setAssignTripStates({
      companyId: record.companyId,
    });
    if (stId) {
      this.props.navigate("/assigntripaddnew/" + stId);
    }
  };
  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    console.log(comp);
    // await this.assignTripStore.loadData(comp.value)
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    console.log(singleData);
    await this.setState({ company_value_label: singleData });

    await this.setState({ loadList: 1 });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.assignTripStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }
  async onDelete(record) {
    console.log(record);
    const response = await this.assignTripStore.deleteTrip({
      stId: record.stId,
    });
    // console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  render() {
    console.log("in render");
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_TRIP_DETAILS_ASSIGN_")}
                {/* Create/Assign Trip Details */}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Tooltip
                  placement="left"
                  title="Add"
                  color={config.primaryColor}
                >
                  {/* <AddButton
                    type="primary"
                    className="addButton"
                    icon={<PlusOutlined />}
                    link="/assigntripadd"
                  /> */}

                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => this.props.navigate("/assigntripaddnew")}
                  ></Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          {/* <div style={{padding:"24px"}}> */}
          {/* <div className='heading'>Create/Assign Trip Details</div> */}
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  value={
                    this.state.company_value_label
                      ? this.state.company_value_label
                      : ""
                  }
                  // allowClear={true}
                  style={{ width: 200 }}
                />
              </Space>
            </Col>
            {/* <Col span={8}>
                  <Space style={{float:this.context.locale.direction=="ltr"?"right":"left"}}> 
                    <AddButton title={this.context.translate('_ADD_')} link ="/assigntripaddnew" />
                    
                  </Space>
                </Col> */}
          </Row>

          <ListTable
            current={this.state.currentPage}
            total={this.assignTripStore?.total}
            columns={this.columns}
            dataSource={this.assignTripStore?.tripDataArray}
            loading={this.assignTripStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openShareModal && (
          <ShareModalWindow
            shareLink={this.state.openShareModal}
            shareModalCloseFunc={(resp) =>
              this.setState({ openShareModal: resp })
            }
            shareModalVisible={this.state.openShareModal}
          />
        )}
      </>
    );
  }
}
AssignTripList.contextType = StoreContext;
export default observer(withRouter(AssignTripList));

//
