import React from "react";
import {
  Form,
  Select,
  Button,
  Space,
  DatePicker,
  Row,
  Col,
  Dropdown,
  Checkbox,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";

import WASLReportListStore from "../store/WASLReportListStore";
import config from "../config";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import VehicleStore from "../store/VehicleStore";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Cookies from "js-cookie";
import MachineryReportStore from "../store/MachineryReportStore";
const { RangePicker } = DatePicker;
dayjs.extend(customParseFormat);

const { Option } = Select;

let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 250;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 150;
    } else if (val.title?.length > 8) {
      val.width = 125;
    } else {
      val.width = 110;
    }
    // console.log(val.title.length)
  });

  return arr;
};

class WASLReportList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.machineryReportStore = new MachineryReportStore(this);
    this.waslReportStore = new WASLReportListStore(this);
    this.vehicleStore = new VehicleStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      companyId: "",
      // fromDate: "",
      // toDate: "",
      selectedVehicles: "",
      selectAllVehicles: false,
      modalVisible: false,
      column: "",
      filter: "",
      showEmail: false,
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      Notcompany: false,
      Notasset: false,

      selectedColumns: widthAdderFunc([
        {
          // title: "Asset Name",
          title: context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",
          width: "15%",
        },
        {
          // title: "Vehicle Reference Key",
          title: context.translate("_VEHICLE_REFERENCE_KEY_"),
          dataIndex: "referenceKey",
          key: "referenceKey",
          width: "15%",
        },
        {
          // title: "Driver Reference Key",
          title: context.translate("_DRIVER_REFERENCE_KEY_"),
          dataIndex: "driverReferenceKey",
          key: "driverReferenceKey",
          width: "15%",
        },

        {
          // title: "Latitude",
          title: context.translate("_FLEET_DASHBORD_SETTING_LATITUDE_"),
          dataIndex: "latitude",
          key: "latitude",
          width: "15%",
        },
        {
          // title: "Longitude",
          title: context.translate("_FLEET_DASHBORD_SETTING_LONGITUDE_"),
          dataIndex: "longitude",
          key: "longitude",
          width: "15%",
        },
        {
          // title: "Velocity",
          title: context.translate("_VELOCITY_"),
          dataIndex: "velocity",
          key: "velocity",
          width: "15%",
        },
        {
          // title: "Weight",
          title: context.translate("_WEIGHT_"),
          dataIndex: "weight",
          key: "weight",
          width: "15%",
        },

        {
          // title: "Location Time",
          title: context.translate("_LOCATION_TIME_"),
          dataIndex: "locationTime",
          key: "locationTime",
          width: "15%",
        },
        {
          // title: "Vehicle Status",
          title: context.translate("_VEHICLE_STATUS_"),
          dataIndex: "vehicleStatus",
          key: "vehicleStatus",
          width: "15%",
        },
        {
          // title: "Address",
          title: context.translate("_FLEET_DASHBORD_NEWPOI_ADDRESS_"),
          dataIndex: "address",
          key: "address",
          width: "15%",
        },
        {
          // title: "Role Code ",
          title: context.translate("_ROLE_CODE_"),
          dataIndex: "roleCode",
          key: "roleCode",
          width: "15%",
        },
        {
          // title: "Response",
          title: context.translate("_RESPONSE_"),
          dataIndex: "waslResponse",
          key: "waslResponse",
          width: "15%",
        },
        {
          // title: "Status",
          title: context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
          dataIndex: "waslReply",
          key: "waslReply",
          width: "15%",
        },
        {
          // title: "Posting Time",
          title: context.translate("_POSTING_TIME"),
          dataIndex: "sendAt",
          key: "sendAt",
          width: "15%",
        },
        {
          // title: "Response Time",
          title: context.translate("_RESPONSE_TIME_"),
          dataIndex: "receivedAt",
          key: "receivedAt",
          width: "15%",
        },
        {
          // title: "Activity",
          title: context.translate("_ACTIVITY_"),
          dataIndex: "activity",
          key: "activity",
          width: "15%",
        },
        {
          // title: "Temperature",
          title: context.translate("_FLEET_DASHBORD_SETTING_TEMPERATURE_"),
          dataIndex: "temperature",
          key: "temperature",
          width: "15%",
        },
        {
          // title: "Humidity",
          title: context.translate("_HUMIDITY_"),
          dataIndex: "humidity",
          key: "humidity",
          width: "15%",
        },
      ]),
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
          icon: <PlusOutlined />,
        },
        // {
        //   // label: "XML",
        //   label: context.translate("_XML_"),
        //   key: "xml",
        //   icon: <PlusOutlined />,
        // },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            // interval: this.state.interval,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            colList: result.toString(),
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.waslReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.waslReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.waslReportStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.waslReportStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }

  async componentDidMount() {
    this.setState({ pageSize: 50 });
    await this.setState({ column: this.state.selectedColumns });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ currentPage: 1, companyId: comp?.value });
    await this.setState({ selectedVehicles: "", selectAllVehicles: false });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    await this.machineryReportStore.getEmailIds({
      companyId: comp.value,
    });
    await this.setState({ listUpdated: 1 });
    // this.handleSearch();
  }

  // async onSearch(value) {
  //     console.log(value)
  //     await this.setState({ emailId: value });
  //     this.handleSearch();
  // };

  async handleSearch() {
    this.setState({ Notasset: false });
    await this.waslReportStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });

    await this.setState({ listUpdated: 1 }); //need to reload data
  }
  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromDate: dates[0]?.format(format),
        toDate: dates[1]?.format(format),
      });
    }
  };
  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (!this.state.selectedVehicles) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false });
      await this.waslReportStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
        vehicleId: this.state.selectedVehicles,
        fromDate: this.state.fromDate,
        // toDate: defaultValue.format("YYYY-MM-DD HH:mm:ss"),
        toDate: this.state.toDate,
      });
      // console.log(this.state.fromDate,this.state.toDate,)
      await this.setState({ listUpdated: 1 });
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  render() {
    //console.log('in render1')
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const assetInputStyle = this.state.Notasset
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">WASL Report</div> */}
              <div className="heading">
                {this.context.translate("_WASL_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Button
                  style={{ backgroundColor: config.activeColor }}
                  onClick={() => this.setState({ showEmail: true })}
                  disabled={
                    this.state.companyId && this.state.selectedVehicles
                      ? false
                      : true
                  }
                >
                  {" "}
                  Email
                </Button>

                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.waslReportStore?.waslReportList?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          {/* <Row style={{ padding: "10px 0px" }}> */}
          <Row className="listInputRow">
            <Space>
              <Col span={18}>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={(value) => this.handleChange(value)}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
              </Col>

              <Col>
                {/* <Select
                  onSearch={this.handleSearch}
                  value={this.state.selectedVehicles}
                  onChange={(val) =>
                    this.setState({ selectedVehicles: val, Notasset: false })
                  }
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 150 }}
                  // style={assetInputStyle }
                  status={this.state.Notasset ? "error" : false}
                /> */}
                <Select
                  value={
                    this.state.selectedVehicles
                      ? this.state.selectedVehicles
                      : null
                  }
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    this.setState({ Notasset: false });
                  }}
                  style={{ width: 150 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
              </Col>
              <Col>
                {/* <RangePicker
                  style={{ width: 300 }}
                  onChange={(dates) => {
                    // console.log(val)
                    // this.onDateTimeChange(val)
                    const format = "YYYY-MM-DD HH:mm:ss";
                    this.setState({
                      fromDate: dates[0].format(format),
                      toDate: dates[1].format(format),
                    });
                  }}
                  // renderExtraFooter={() => "extra footer"}
                  showTime={{ format: "h:mm A" }}
                /> */}

                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
              </Col>

              <Col>
                <Button
                  type="primary"
                  onClick={async () => {
                    await this.setState({ currentPage: 1, pageSize: 50 });
                    await this.showData();
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Col>
            </Space>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={250}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.waslReportStore?.total}
            columns={this.state.column}
            dataSource={this.waslReportStore?.waslReportList}
            loading={this.waslReportStore?.loading}
            onChange={this.handleTableChange}
          />

          <Modal
            open={this.state.showEmail}
            // title="Send Email"
            title={this.context.translate("_SEND_EMAIL_")}
            onOk={() => this.setState({ showEmail: false })}
            onCancel={() => this.setState({ showEmail: false })}
            footer={false}
            width={400}
          >
            <Form
              name="Email"
              layout="vertical"
              onFinish={this.onEmailSend}
              style={{ maxWidth: "100%" }}
            >
              <Row>
                <Col span={12}></Col>
                <Col
                  span={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                ></Col>
              </Row>
              <Form.Item
                name="Email"
                // label="Email"
                title={this.context.translate("_FLEET_LOGIN_EMAILLBL_")}
                rules={[{ required: true }]}
              >
                <Select
                  fieldNames={{ label: "emailIds", value: "emailIds" }}
                  // placeholder="Email Ids"
                  placeholder={this.context.translate("_EMAIL_IDS_")}
                  optionFilterProp="children"
                  options={this.machineryReportStore?.emailIdsList}
                  style={{ width: 355 }}
                />
              </Form.Item>
              <div className="formBtnGroup">
                {/* css class */}

                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
      </>
    );
  }
}
WASLReportList.contextType = StoreContext;
export default observer(withRouter(WASLReportList));
