
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class DistributorStore {
  distributors=[]
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
      distributors: observable,
        distributorList:action,
      setDistributor:action,
      getDistributorData :action,
      
    });
     this.rootStore = rootStore;
    
  }

  setDistributor(value){
    	this.distributors=value;
  }

  async distributorList() {
    console.log("distributor list")
    // var postData = {"companyId":"1","cstatus":null,"isadmin":"-1","cdType":null,"distCompanyId":null,"cname":"","email":"","offset":0,"limit":50}  
     var postData={"type":"Distributor","companyId":null}
    const base64Input = await base64_encode(JSON.stringify(postData))
    var response = await postApi.getClient().post("index.php?c=company&a=getdistributors", "postData="+base64Input);
    // console.log(response?.data?.data?.companies,"distributor")
    let distributors = [{"companyId":-1,"name":"All"}];
    distributors = [...distributors,...response?.data?.data?.companies];
    this.setDistributor(distributors);
    console.log(distributors);

  }
  getDistributorData(){
    console.log("compay",this.getDistributorData)
    // return this.companyData
  }


}

export default DistributorStore;
