import React from "react";
import BlackListPersonStore from "../store/BlackListPersonStore";
import {
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Select,
  Table,
  Button,
  Typography,
  Space,
  Tag,
  Avatar,
  Row,
  Col,
  Dropdown,
  Tooltip,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import MenuSettingAdd from "./MenuSettingAdd";
import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import CompanyAutocomplete from "./CompanyAutocomplete";
import MenuSettingStore from "../store/MenuSettingStore";
import CompanyStore from "../store/CompanyStore";
const { Search } = Input;

class CompanyMenuSettingList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSearch = this.onSearch.bind(this);

    this.menuSettingStore = new MenuSettingStore(this);
    this.companyStore = new CompanyStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      companyId: "",
      companyId_value_label: "",
      openMenuSettingForm: false,
      roleId: "",
      listDataUpdate: false,
    };
  }
  onEdit = async (record) => {
    console.log(record.roleId, "dd");
    this.context.viewStateStore.setMenuSettingStates({
      companyId: this.state.companyId,
    });
    // this.props.navigate("/menusettingadd/" + record.roleId);
    await this.setState({ roleId: record.roleId });
    await this.setState({ openMenuSettingForm: true });
  };
  async onDelete(record) {
    const response = await this.menuSettingStore.deleteRecord({
      roleId: record.roleId,
    });
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }
  async componentDidUpdate(prevProps, prevState) {
    console.log("didmount");
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const menuSettingStates =
        this.context.viewStateStore.menuSettingStateObject;

      const singleData = await this.companyStore.getCompanyDataById(
        menuSettingStates.companyId
      );
      await this.setState({
        companyId_value_label: singleData,
        companyId: menuSettingStates.companyId,
      });
      await this.menuSettingStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: menuSettingStates.companyId,
      });
      this.setState({ listDataUpdate: false });
    }
  }
  async componentDidMount() {
    // await this.companyStore.loadData({ offset: 0, limit: 50 });
    this.setState({ pageSize: 50 });

    // console.log(menuSettingStates)
    // await this.handleSearch();
    this.columns = [
      {
        // title: "Role Name",
        title: this.context.translate("_FLEET_ROLE_ROLELIST_ROLENAME_"),
        dataIndex: "name",
        width: "50%",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        // title: "Status",
        title: this.context.translate("_FLEET_ROLE_ROLELIST_STATUS_"),
        dataIndex: "status",
        width: "45%",
        render: (value) => {
          if (value == 1) {
            return <Tag color={config.activeColor}>Active</Tag>;
          } else if (value == 2) {
            return <Tag color="red">Inactive</Tag>;
          }
        },
      },
      {
        // title: "Action",
        title: this.context.translate("_ACTION_"),
        dataIndex: "",
        width: "5%",
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    // title="Edit Setting"
                    title={this.context.translate("_EDIT_SETTING_")}
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    this.onDelete(record);
                  }}
                >
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Tooltip
                      placement="bottom"
                      // title="Delete Setting"
                      title={this.context.translate("_DELETE_SETTING_")}
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleSearch();
    }
    await this.setState({ listUpdated: 1 }); //need to reload data
  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ currentPage: 1, companyId: comp.value });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ companyId_value_label: singleData });
    await this.handleSearch();
  }

  async onSearch(value) {
    console.log(value);
    await this.setState({ emailId: value });
    this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.menuSettingStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });
    await this.setState({ listUpdated: 1 }); //need to reload data
  }
  responseMsg = async (response) => {
    if (response?.success === "S") {
      message.success(response.message);
      // this.props.navigate("/companymenusetting");
      await this.setState({ listDataUpdate: true });
      await this.setState({ openMenuSettingForm: false });
    } else {
      message.error(response.message);
    }
  };

  closeModal = () => {
    this.setState({ openMenuSettingForm: false });
  };
  render() {
    //console.log('in render1')
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {
                /* <div className="heading">Menu Settings List</div> */
                <div className="heading">
                  {this.context.translate("_MENU_SETTING_LIST_")}
                </div>
              }
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  link="/menusettingadd"
                  title={this.context.translate("_ADD_")}
                /> */}
                <Tooltip
                  placement="left"
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({ openMenuSettingForm: true, roleId: "" })
                    }
                  ></Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  value={
                    this.state.companyId_value_label
                      ? this.state.companyId_value_label
                      : null
                  }
                  onChange={this.handleChange}
                  style={{ width: 200 }}
                  // allowClear={true}
                />
              </Space>
            </Col>
          </Row>

          <ListTable
            current={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.menuSettingStore?.total}
            columns={this.columns}
            dataSource={this.menuSettingStore?.menuSetting}
            loading={this.menuSettingStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openMenuSettingForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Role Setting"
            title={this.context.translate("_ROLE_SETTING_")}
            centered
            visible={this.state.openMenuSettingForm}
            footer={null}
            onCancel={() => {
              this.setState({ openMenuSettingForm: false });
            }}
            width={665}
          >
            <div>
              {/* <AssignVehicleToUser
                  companyId={this.state.companyId}
                  userId={this.state.assignId}
                  response={this.assignresponseMsg}
                /> */}
              <MenuSettingAdd
                roleId={this.state.roleId}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
CompanyMenuSettingList.contextType = StoreContext;
export default observer(withRouter(CompanyMenuSettingList));
