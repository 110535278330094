import React, { useState } from "react";
import {
  Row,
  Col,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  message,
  Button,
  Form,
  Typography,
  Space,
  DatePicker,
  Tooltip,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import AddButton from "./AddButton";
import CompanyAutocomplete from "./CompanyAutocomplete";

import ListTable from "./ListTable";
import VehicleTrailerStore from "../store/VehicleTrailerStore";
import withRouter from "../withRouter";
import CompanyStore from "../store/CompanyStore";
import VehicleTrailerAdd from "./vehicleTrailerAdd";

class VehicleTrailerList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.vehicleTrailerStore = new VehicleTrailerStore();
    this.companyStore = new CompanyStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      companyId: "",
      loadData: 0,
      companyVal_Lab: "",
      trailerId:"",
      openTrailerForm:false,
      listDataUpdate:false,
    };
  }

  async componentDidUpdate(prevProps,prevState){
    if(prevState.listDataUpdate !== this.state.listDataUpdate){
      
      const setVehicleTrailerStates =
      this.context.viewStateStore.vehicleTrailerObject;
      if(setVehicleTrailerStates.companyId!==""){
    console.log(setVehicleTrailerStates);
    await this.vehicleTrailerStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: setVehicleTrailerStates.companyId,
    });

    const singleData = await this.companyStore.getCompanyDataById(
      setVehicleTrailerStates.companyId
    );

    await this.setState({ loadList: 1 });
    console.log("singleData", singleData);
    await this.setState({ companyVal_Lab: singleData,companyId: setVehicleTrailerStates.companyId});
    console.log("singleData", singleData);

    }
    this.setState({listDataUpdate:false})
  
    }
  }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId)
      await this.setState({ companyId: this.props.getUserData().userCompanyId })
      await this.setState({ userId: this.props.getUserData().userId })
      await this.handleSearch();
    }
    console.log(this.context.viewStateStore.value, "vale");
   
   
    // await this.vehicleTrailerStore.loadData({ offset: 0, limit: 50 });

    this.setState({ pageSize: 50 });

    this.columns = [
      {
        // title: " Company ",
        title:this.context.translate("_FLEET_DASHBORD_NEWPOI_COMPANY_"),
        dataIndex: "name",
        width: "25%",
        render: (value, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        // title: "Trailer Id ",
        title:this.context.translate("_TRAILER_ID_"),
        dataIndex: "trailerId",
        width: "25%",
      },
      {
        // title: "Trailer Type ",
        title:this.context.translate("_TRAILER_TYPE_"),
        dataIndex: "trailerType",
        width: "25%",
      },
      {
        // title: "Trailer Empty Weight  ",
        title:this.context.translate("_TRAILER_EMPTY_WEIGHT_"),
        dataIndex: "trailerEmptyWeight",
        width: "20%",
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        // fixed: 'right',
        width: "5%",
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip 
                  // title="Edit Record"
                  title={this.context.translate("_EDIT_RECORD_")}
                  placement="bottom" color={config.tooltipColorEdit}>
                  <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                  <Tooltip 
                  // title="Delete Record" 
                  title={this.context.translate("_DELETE_RECORD_")}
                  placement="bottom" color={config.tooltipColorDelete}>
                    <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value, companyLoading: true });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ companyVal_Lab: singleData });
    // await this.setState({ companyId: comp.value });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.vehicleTrailerStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      // searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadData: 1 });
  }

  onEdit =async (record) => {
    console.log(record);
    const tId = record.tId;
    if (tId) {
      this.context.viewStateStore.setVehicleTrailerStates({
        companyId: record.companyId,
      });
      
    await this.setState({trailerId:tId})
     await this.setState({openTrailerForm:true})
      // this.props.navigate("/vehicletraileradd/" + tId);
    }
  };

  async onDelete(record) {
    console.log(record.tId, "record");
    const response = await this.vehicleTrailerStore.deleteRecord({
      tId: record.tId,
    });
    //  console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  responseMsg = async(resp) => {
    console.log(resp);
    if (resp.data.success == "S") {
      message.success(resp.data.message);
    //  this.props.navigate("/vehicletrailerlist");
    this.setState({openTrailerForm:false})
    this.setState({listDataUpdate:true})
   } else if (resp.data.success == "F0") {
     resp.data.errors.map((value) => {
       message.error(value["username"]);
     });
   }
  };

  closeModal=()=>{
    this.setState({ openTrailerForm: false, trailerId: "" });
  }

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_TRAILER_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  title={this.context.translate("_ADD_")}
                  link="/vehicletraileradd"
                /> */}
                  <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  placement="left"
                  color={config.primaryColor}
                >
                <Button  className="addButton" icon={< PlusOutlined />}
                type="primary" onClick={()=>this.setState({openTrailerForm:true,trailerId:""})}></Button></Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  // allowClear={true}
                  value={this.state.companyVal_Lab?this.state.companyVal_Lab:null}
                  onChange={(value) => this.handleChange(value)}
                  style={{ width: 200 }}
                 
                />
              </Space>
            </Col>
            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            current={this.state.currentPage}
            total={this.vehicleTrailerStore?.total}
            columns={this.columns}
            dataSource={this.vehicleTrailerStore?.vehicleTrailerVehicles}
            loading={this.vehicleTrailerStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openTrailerForm && (
            <Modal
              bodyStyle={{ height: "645px" }}
              className="formShowModal" //  {/* css class */}
              // title="Add Trailer"
              title={this.context.translate("_ADD_TRAILER_")}
              centered
              visible={this.state.openTrailerForm}
              footer={null}
              onCancel={() => {
                this.setState({ openTrailerForm: false, trailerId: "" });
              }}
              width={665}
            >
              <div>
                <VehicleTrailerAdd
                  trailerId={this.state.trailerId}
                  response={this.responseMsg}
                  closeModal={this.closeModal}
                />
              </div>
            </Modal>
          )}
      </>
    );
  }
}
VehicleTrailerList.contextType = StoreContext;
export default observer(withRouter(VehicleTrailerList));
