import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import config from "../config";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from "mobx";
import { act } from "react-dom/test-utils";

class VehicleAllocateStore {
  allocateVehicles = [];
  loading = false;
  total = 0;
  // for asset dropdown
  allocateVehicleAsset = [];
  // for project dropdwn
  projectList = [];

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      allocateVehicles: observable,
      loading: observable,
      total: observable,
      loadData: action,
      addData: action,
      getAllocateVehicles: action,
      getProjectDetails: action,
      addProjectData: action,
      exportExcel: action,
      // getAssetData:action
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit, searchField, isadmin, userId }) {
    this.loading = true;
    var postData = {
      tokenId: "80bovn8u800tg1aq9bvr73kbhh",
      companyId: companyId,
      offset: offset,
      limit: limit,
      searchField: searchField,
      isadmin: isadmin,
      userId: userId,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=allocatevehicle&a=getallocateList",
        "postData=" + base64Input
      );
    console.log("allocate", response);
    console.log(response?.data?.data);
    this.allocateVehicles = response?.data?.data?.assignvehicles;
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async addData(allocateVehicleData) {
    console.log(allocateVehicleData);
    var postData = allocateVehicleData;

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=allocatevehicle&a=assignVehicle",
        "postData=" + base64Input
      );
    console.log(response, "post");
    return response;
  }

  async getAllocateVehicles({ companyId, asset }) {
    console.log(asset, "sadfsdfd");
    if (asset === 1) {
      var postData = {
        tokenId: "",
        companyId: companyId,
        userid: null,
        isadmin: null,
        asset: asset,
      };

      const base64Input = await base64_encode(JSON.stringify(postData));
      var response = await postApi
        .getClient()
        .post(
          "index.php?c=allocatevehicle&a=getallocateVehicles",
          "postData=" + base64Input
        );
      console.log(response, "post");
      this.assetList = response?.data?.data?.vehicles;
      return response?.data?.data?.vehicles;
    } else {
      var postData = {
        tokenId: "",
        companyId: companyId,
        userid: null,
        isadmin: null,
        asset: asset,
      };

      const base64Input = await base64_encode(JSON.stringify(postData));
      var response = await postApi
        .getClient()
        .post(
          "index.php?c=allocatevehicle&a=getallocateVehicles",
          "postData=" + base64Input
        );
      console.log(response, "post");
      this.assetList = response?.data?.data?.vehicles;
      return response?.data?.data?.vehicles;
    }
  }

  // to get asset data

  // async getAllocateVehicles({ companyId, asset }) {
  //   console.log(asset,"asssset");
  //   if (asset==1) {

  //     var postData = {
  //       tokenId: "80bovn8u800tg1aq9bvr73kbhh",
  //       companyId: companyId,
  //       userid: null,
  //       isadmin: null,
  //       asset: asset,
  //     };
  //     const base64Input = await base64_encode(JSON.stringify(postData));
  //     var response = await postApi
  //       .getClient()
  //       .post(
  //         "index.php?c=allocatevehicle&a=getallocateVehicles",
  //         "postData=" + base64Input
  //       );
  //     // console.log(response, "post");
  //     // if (asset == "1") {
  //     //   this.allocateVehicleAsset = response?.data?.vehicles;
  //     // } else {
  //     //   this.allocateVehicleAsset = response?.data?.data?.vehicles;
  //     // }
  //   } else {
  //     var postData = {
  //       tokenId: "80bovn8u800tg1aq9bvr73kbhh",
  //       companyId: companyId,
  //       userid: null,
  //       isadmin: null,
  //       asset: asset,
  //     };
  //   }
  //   const base64Input = await base64_encode(JSON.stringify(postData));
  //   var response = await postApi
  //     .getClient()
  //     .post(
  //       "index.php?c=allocatevehicle&a=getallocateVehicles",
  //       "postData=" + base64Input
  //     );
  //   console.log(response, "post");
  //   this.allocateVehicleAsset = response?.data?.data?.vehicles;

  //   return response?.data?.data?.vehicles;
  // }

  async getAllocateVehicleData(Id) {
    console.log("Id", Id);
    var postData = { aid: Id, limit: 1, offset: 0 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=allocatevehicle&a=getallocateList",
        "postData=" + base64Input
      );
    //  console.log(response)
    return response.data.data.assignvehicles[0];
  }

  async deleteRecord({ Id }) {
    var postData = { tokenId: "", Id: Id };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=allocatevehicle&a=deleteAssignvehicle",
        "postData=" + base64Input
      );
    return response;
  }

  // To get project details
  async getProjectDetails(companyId) {
    var postData = { companyId: companyId };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=project&a=getProject", "postData=" + base64Input);
    console.log(response, "post");
    this.projectList = response?.data?.data;
    return response?.data?.data;
  }

  // To add project details
  async addProjectData(projectDetails) {
    console.log(projectDetails);
    var postData = projectDetails;

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=project&a=addproject", "postData=" + base64Input);
    console.log(response, "post");
    return response;
  }

  async exportExcel(getData) {
    console.log(getData);
    window.location.href =
      config.baseURL +
      "index.php?c=allocatevehicle&a=allocateExportexcel&cid=" +
      getData.cid +
      "&searchField=" +
      getData.searchField +
      "&colList=" +
      getData.colList;
  }
}

export default VehicleAllocateStore;
