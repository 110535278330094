import {
  Button,
  DatePicker,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Table,
  Select,
} from "antd";
import "leaflet-arrowheads";
import dayjs from "dayjs";
import React, { Component } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import withRouter from "../withRouter";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { CloseOutlined } from "@ant-design/icons";
import "@elfalem/leaflet-curve";
import { Icon } from "leaflet";
import { MapContainer, TileLayer } from "react-leaflet";
import config from "../config";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import { addMinutes, format } from "date-fns";
import Draggable from "react-draggable";

import HistoryTrackingStore from "../store/HistoryTrackingStore";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../store/RootStore";

import "leaflet-curve"; // Include the Leaflet-Curve library

import CompanyStore from "../store/CompanyStore";
import AdditionalContentInMap from "./AdditionalContentInMap";
// import "leaflet-curve/dist/leaflet.curve.css"; // Include the CSS
const { RangePicker } = DatePicker;
const { Option } = Select;
const cardCss = {
  background: "#ffffff",
  border: "solid 1px #CBCBCB",
  // padding: "15px",
  borderRadius: "5px",
  position: "absolute",
  // left: "28%",
  // top: "2%",
  // width: "25%",
  update: 0,
  isPlaying: true,
  type: "",

  traffic: false,
  flag: true,
  mapBtn: false,
  satelliteBtn: false,
  trafficBtn: false,
};

class HistoryTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historyTrackingWindow: true,
      // fromTime: "00:00:00",
      mapType: "google",
      offset: "",
      limit: "",
      loadList: 0,
      speed: 180,
      routeOption: 0,
      // toTime: dayjs().format("HH:mm:ss"),
      showBtn: false,
      updateRouteOption: 0,
      update2: 0,
      // fromDate: "",
      pauseBtnFeature: false,
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      showMapOptions: true,
      tripEventOption: "",
    };
    console.log("sammek");
    this.mapRef = React.createRef();
    this.polyline2 = null;
    this.marker2 = [];
    this.companyStore = new CompanyStore(this);
    this.mapOptions = [
      {
        label: "Google",
        value: "google",
      },
      {
        label: "Openstreet",
        value: "openstreet",
      },
    ];
    // this.columns = [
    //   {
    //     title: "Location",
    //     dataIndex: "formatted_address",
    //     width: "15%",
    //   },
    //   {
    //     title: "Speed(Km)",
    //     dataIndex: "speed",
    //     width: "15%",
    //   },
    //   {
    //     title: "Ignition",
    //     dataIndex: "ignition",
    //     width: "15%",
    //     render: (val) => {
    //       return "ON";
    //     },
    //   },
    //   {
    //     title: "Date Time",
    //     dataIndex: "updatedate",
    //     width: "15%",
    //   },
    // ];
    this.formRef = React.createRef();
    this.removeMarkerPoints = [];
    this.historyTrackingStore = new HistoryTrackingStore(this);
  }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });

      await this.historyTrackingStore.getVehicles({
        companyId: this.props.getUserData().userCompanyId,
      });
    }

    this.columns = [
      {
        // title: "Location",
        title: this.context.translate(
          "_FLEET_DASHBORD_HISTORY_TOOLTIP_LOCATION_"
        ),
        dataIndex: "formatted_address",
        width: "60%",
      },
      {
        // title: "Speed(Km)",
        title: this.context.translate("_SPEED_KM_"),
        dataIndex: "speed",
        width: "15%",
      },
      {
        // title: "Ignition",
        title: this.context.translate("_IGNITION_"),
        dataIndex: "ignition",
        width: "15%",
        render: (val, record) => {
          if (Number(record.acc) === 1) {
            return <>ON</>;
          } else {
            return <>OFF</>;
          }
        },
      },
      {
        // title: "Date Time",
        title: this.context.translate("_DATE_TIME_"),
        dataIndex: "updatedate",
        render: (value) => {
          const formattedDateTime = dayjs(value).format(
            "DD-MM-YYYY hh:mm:ss A"
          );
          return <>{formattedDateTime}</>;
        },
        width: "10%",
      },
    ];
    if (this.props.vehicleInfoObj) {
      const obj = this.props.vehicleInfoObj;

      const getCompanyData = await this.companyStore.getSingleCompanyData(
        obj.companyId
      );

      await this.setState({ companyId: getCompanyData.companyId });
      await this.historyTrackingStore.getVehicles({
        companyId: getCompanyData.companyId,
      });
      await this.formRef.current.setFieldsValue({
        companyId: [
          { value: getCompanyData.companyId, label: getCompanyData.name },
        ],
        vehicleId: obj.vehicleId,
      });
    }
    this.setState({ update: 1 });
  }
  async componentDidUpdate(prevProps, prevState) {
    // if (this.state.updateRouteOption === 5 && this.state.update2 ) {
    //  await this.drawingPolyLineFunc();
    // await this.setState({update2:false})
    // }
  }

  timeOptions = () => {
    const timeArray = [];
    let startTime = new Date(2000, 0, 1, 0, 0); // 12:00 AM
    let endTime = new Date(2000, 0, 1, 23, 30);
    for (let time = startTime; time <= endTime; time = addMinutes(time, 30)) {
      // const formattedTime = format(time, 'h:mm aa');
      const formattedTime = format(time, "HH:mm:ss");
      var ob = {
        formattedTime: formattedTime,
        time: time,
      };
      timeArray.push(ob);
    }
    // console.log(timeArray);
    return timeArray.map((data) => {
      return (
        <Select.Option key={data.formattedTime} value={data.formattedTime}>
          {format(data.time, "h:mm aa")}
        </Select.Option>
      );
    });
  };

  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  // Modal Function for polt the polyline with timer
  drawPolylineUsingTimerFunc = async ({
    vehicleObj,
    polyLineArray,
    callBackFunc,
  }) => {
    let speed = this.state.speed;
    if (this.state.speed === 1) {
      speed = 125;
    } else if (this.state.speed === 2) {
      speed = 400;
    } else if (this.state.speed === 3) {
      speed = 750;
    }

    if (this.state.isPlaying === false) {
      return;
    }
    setTimeout(() => {
      if (this.state.updateRouteOption === 0) {
        this.clearMap();
        return;
      }
      let speed = Number(vehicleObj?.speed);
      let speedLimit = Number(vehicleObj?.speedLimit);
      let toolTipDta;
      let activity;
      if (this.state.routeOption !== 5) {
        const polyline = L.polyline(
          polyLineArray,
          {
            color: "blue",
          },
          20
        ).addTo(
          this.props.mapRef ? this.props.mapRef.current : this.mapRef.current
        );
      }

      if (
        this.state.routeOption === 1 ||
        this.state.routeOption === 3 ||
        this.state.routeOption === 4
      ) {
        if (this.state.tripEventOption > 1) {
          // <Select.Option value={1}>All</Select.Option>
          // <Select.Option value={2}>Travel</Select.Option>
          // <Select.Option value={3}>Stopped</Select.Option>
          // <Select.Option value={4}>Idle</Select.Option>
          let obj = { 1: "All", 2: "Green", 3: "Red", 4: "Orange" };
          activity = obj[this.state.tripEventOption];
          toolTipDta = 2;
        } else {
          if (
            vehicleObj.activity === "Travel" ||
            (Number(vehicleObj.acc) === 1 && Number(vehicleObj.speed) > 0)
          ) {
            activity = "Green";
            toolTipDta = 1;
          } else if (
            vehicleObj.activity === "Idle" ||
            (Number(vehicleObj.acc === 1) && Number(vehicleObj.speed) === 0)
          ) {
            activity = "Orange";
            toolTipDta = 2;
          } else if (
            vehicleObj.activity === "Stop" ||
            Number(vehicleObj.acc) === 0
          ) {
            activity = "Red";
            toolTipDta = 2;
          }
        }

        // const marker = L.marker([d.latitude, d.longitude], {
        //   icon: this.customIconFunc(d),
        // })
        //   .bindTooltip(
        //     "<div style='background:#00ff00;border:solid 1px #000;text-align:center;padding:0'><b>" +
        //     d.driverName + "/" + d.vehicleNo +
        //     "</b></div>",
        //     {
        //       permanent: true,
        //       direction: "bottom",
        //       // className: "my-labels"
        //     }
        //   )
        //   .addTo(this.mapRef.current);

        if (activity) {
          let rotationAngle = Number(vehicleObj.direction);
          var direction;

          if (rotationAngle >= 0 && rotationAngle < 22.5) {
            direction = "north";
          } else if (rotationAngle >= 22.5 && rotationAngle < 67.5) {
            direction = "north-east";
          } else if (rotationAngle >= 67.5 && rotationAngle < 112.5) {
            direction = "east";
          } else if (rotationAngle >= 112.5 && rotationAngle < 157.5) {
            direction = "south-east";
          } else if (rotationAngle >= 157.5 && rotationAngle < 202.5) {
            direction = "south";
          } else if (rotationAngle >= 202.5 && rotationAngle < 247.5) {
            direction = "south-west";
          } else if (rotationAngle >= 247.5 && rotationAngle < 292.5) {
            direction = "west";
          } else if (rotationAngle >= 292.5 && rotationAngle < 337.5) {
            direction = "north-west";
          } else if (rotationAngle >= 337.5 && rotationAngle < 360) {
            direction = "north";
          } else {
            direction = "north-east";
          }

          const activityImage = require(`../assets/images/BaloonIcons/${activity}-${direction}.png`);

          this.marker2 = L.marker([vehicleObj.latitude, vehicleObj.longitude], {
            icon: L.divIcon({
              className: "custom-marker",
              iconSize: [25, 25],
              html: `
              <img src=${activityImage} alt="Icon" style=  "width: 100%; height: 100%;">`,
            }),
          })
            .bindTooltip(
              // "<div style='background:#00ff00;border:solid 1px #000;text-align:center;padding:0'><b>Sammek</b></div>",
              toolTipDta === 2 && vehicleObj.activity
                ? `<div style='background:#00ff00;border:solid 1px #000;text-align:left;padding:10px;'>
      <b>Vehicle No:</b> ${vehicleObj.vehicleNo} <br/>
      <b>${vehicleObj.activity} From:</b> ${
                    vehicleObj.fromdate ? vehicleObj.fromdate : ""
                  } <br/>
      <b>${vehicleObj.activity} To:</b> ${
                    vehicleObj.todate ? vehicleObj.todate : ""
                  } <br/>
                  <b>Location:</b> ${vehicleObj.formatted_address ? vehicleObj.formatted_address : ''} <br/>
      <b>Duration:</b> ${
        vehicleObj.elapsedTime ? vehicleObj.elapsedTime : ""
      }  <br/>
      <b>Weight:</b> ${
        vehicleObj.emptyWeight ? vehicleObj.emptyWeight : ""
      } <br/>
    </div>`
                : `<div style='background:#00ff00;border:solid 1px #000;text-align:left;padding:10px; width:200;'>
    <b>Vehicle No:</b> ${vehicleObj.vehicleNo} <br/>
    <b>Date Time:</b> ${vehicleObj?.updatedate} <br/>
    <b>Location:</b> ${vehicleObj?.formatted_address} <br/>
    <b>Speed:</b> ${vehicleObj.speed ? vehicleObj.speed : ""} <br/>
    <b>Weight:</b> ${vehicleObj.emptyWeight ? vehicleObj.emptyWeight : ""} <br/>
  </div>`,

              {
                maxWidth: 300,
                direction: "top",
                // className: "my-labels"
              }
            )

            .addTo(
              this.props.mapRef
                ? this.props.mapRef.current
                : this.mapRef.current
            );
          this.removeMarkerPoints.push([
            Number(vehicleObj.latitude),
            Number(vehicleObj.longitude),
          ]);
        }
      }

      if (polyLineArray.length > 2) {
        const bounds = [
          [
            polyLineArray[polyLineArray.length - 1][0],
            polyLineArray[polyLineArray.length - 1][1],
          ],
          [polyLineArray[0][0], polyLineArray[1][1]],
        ];
        if (this.props.mapRef) {
          this.props.mapRef.current.fitBounds(bounds, { maxZoom: 15 });
        } else {
          this.mapRef.current.fitBounds(bounds, { maxZoom: 15 });
        }
      }

      this.historyTrackingStore.count++;
      if (typeof callBackFunc === "function") {
        callBackFunc();
      }
    }, speed);
  };

  overSpeedDrawPolyline = async () => {
    if (
      this.historyTrackingStore.count ===
      this.historyTrackingStore.polyLineArray.length
    ) {
      return;
    }
    let vehicleObj =
      this.historyTrackingStore.allArray[this.historyTrackingStore.count];
    let newAry = this.historyTrackingStore.newPolyLineArray.push([
      vehicleObj?.latitude,
      vehicleObj?.longitude,
    ]);

    if (this.state.routeOption === 5) {
      this.historyTrackingStore.allArray?.forEach((val) => {
        if (Number(val.speed) > Number(val.speedLimit)) {
          const marker = L.marker([val.latitude, val.longitude], {
            icon: new Icon({
              iconUrl: require("../assets/images/BaloonIcons/Orange-north-east.png"),
              iconSize: [25, 25], // size of the icon
              rotationAngle: 50,
            }),

            // icon: L.divIcon({
            //   className: 'custom-marker',
            //   iconSize: [25, 25],
            //   html: ` <img src=${require("../assets/images/BaloonIcons/Orange-north-east.png")} alt="Icon" style="transform: rotate(${50}deg) ;  width: 20px; height: 35px;";">`
            // }),
          })
            .bindTooltip(
              // "<div style='background:#00ff00;border:solid 1px #000;text-align:center;padding:0'><b>Sammek</b></div>",
              `<div style='background:#00ff00;border:solid 1px #000;text-align:left;padding:10px;'>
            <b>Vehicle No:</b> ${val.vehicleNo} <br/>
            <b>Speed:</b> ${val.speed} <br/>
           
            <b>Date Time:</b> ${val.updatedate} <br/>
            <b>Speed Limit:</b> ${val.speedLimit} <br/>
            <b>Weight:</b> ${val.emptyWeight} <br/>
            <!-- Add more fields as needed -->
          </div>`
            )
            .addTo(
              this.props.mapRef
                ? this.props.mapRef.current
                : this.mapRef.current
            );
        }
      });
    } else {
      // Call drawPolylineUsingTimerFunc with a callback
      await this.drawPolylineUsingTimerFunc({
        vehicleObj: vehicleObj,
        polyLineArray: this.historyTrackingStore.newPolyLineArray,
        callBackFunc: this.overSpeedDrawPolyline, // Pass the callback function
      });
    }
  };

  overSpeedAndFullPathFunc = async () => {
    try {
      const polyline = await L.polyline(
        this.historyTrackingStore.polyLineArray.length > 0
          ? this.historyTrackingStore.polyLineArray
          : [],
        {
          color: "green",
          smoothFactor: 5,
        },
        15
      ).addTo(
        this.props.mapRef ? this.props.mapRef.current : this.mapRef.current
      );

      const bounds = polyline.getBounds();

      polyline
        .arrowheads({ size: "6px" })
        .bindPopup
        //   `<pre><code>L.polyline(coords)
        // .arrowheads({
        //   size: '6px',
        //   // frequency: '15px',
        //   // fill: true,
        //   yawn: 20
        // });</code></pre>`
        // { maxWidth: 2000, minWidth: 400 }
        ();
      // Zoom to fit the bounds of the polyline
      if (this.props.mapRef) {
        await this.props.mapRef.current.fitBounds(bounds, { maxZoom: 20 });
      } else {
        await this.mapRef.current.fitBounds(bounds, { maxZoom: 20 });
      }

      await this.overSpeedDrawPolyline();
      // this.setState({ updateRouteOption: 5 });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  straightLineFunc = async () => {
    if (
      this.historyTrackingStore?.offlineDataNewObj?.length > 0 &&
      (this.state.routeOption === 1 || this.state.routeOption === 4)
    ) {
      let vehicleObj =
        this.historyTrackingStore.offlineDataNewObj[
          this.historyTrackingStore.count
        ];
      let newAry = this.historyTrackingStore.newPolyLineArray.push([
        vehicleObj.latitude,
        vehicleObj.longitude,
      ]);
      await this.setState({ update2: 0 });
      // Call drawPolylineUsingTimerFunc with a callback
      await this.drawPolylineUsingTimerFunc({
        vehicleObj: vehicleObj,
        polyLineArray: this.historyTrackingStore.newPolyLineArray,
        callBackFunc: this.straightLineFunc, // Pass the callback function
      });
    }
  };

  // Submit  History Tracking Form
  onFinishHistoryTracking = async (data) => {
    await this.clearMap();

    this.historyTrackingStore.checked = true;
    console.log(data);
    let infoObj = {
      ...data,
      companyId: this.state.companyId,
      // toDate: dayjs(data.toD).format("YYYY-MM-DD") + " " + this.state.toTime,
      // fromDate:
      //   dayjs(data.fromD).format("YYYY-MM-DD") + " " + this.state.fromTime,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      tableName: "mt90",
      offset: 0,
      limit: 10,
      page: 1,
    };

    const obj = {
      companyId: this.state.companyId,
      vehicleId: data.vehicleId,
      // fromDate:
      //   dayjs(data.fromD).format("YYYY-MM-DD") + " " + this.state.fromTime,
      // toDate: dayjs(data.toD).format("YYYY-MM-DD") + " " + this.state.toTime,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      SelRoutOption: data.SelRoutOption,
      tableName: "mt90",
      TripEventdata: data.TripEventdata,
    };
    console.log(obj);
    if (data.SelRoutOption === 5 || data.SelRoutOption === 3) {
      let response = await this.historyTrackingStore.getHistoryTrackingData(
        obj
      );

      if (response.length > 0) {
        await this.setState({
          showBtn: this.state.routeOption === 5 ? false : true,
          updateRouteOption: 1,
        });
        await this.overSpeedAndFullPathFunc();
      } else {
        Modal.warning({
          content: "Vehicle Data Not Available...",
          title: "Alert",
        });
      }
    }
    if (data.SelRoutOption === 1 || data.SelRoutOption === 4) {
      await this.historyTrackingStore.setOfflineData(obj);
      if (Number(this.historyTrackingStore.offlineData.totalcount) > 0) {
        await this.setState({
          showBtn: this.state.routeOption === 5 ? false : true,
          updateRouteOption: 1,
        });
        await this.straightLineFunc();
      } else {
        Modal.warning({
          content: "Vehicle Data Not Available...",
          title: "Alert",
        });
      }
    }

    await this.setState({ update2: 1 });
  };

  clearMap = async () => {
    await this.setState({ updateRouteOption: 0, showBtn: false });
    this.historyTrackingStore.checked = false;
    if (this.props.mapRef) {
      if (this.props.mapRef.current && this.removeMarkerPoints.length > 0) {
        this.props.mapRef.current.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            const markerLatLng = layer.getLatLng();
            // Check if the marker's coordinates are in the removeMarkerPoints array
            const isMarkerInRemovePoints = this.removeMarkerPoints.some(
              (point) => markerLatLng.equals(L.latLng(point))
            );
            if (isMarkerInRemovePoints) {
              // If the marker's coordinates are in the removeMarkerPoints array, remove it from the map
              this.props.mapRef.current.removeLayer(layer);
            }
          }
        });
      }

      await this.props.mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Polyline) {
          this.props.mapRef.current.removeLayer(layer);
        }
      });
    } else {
      if (this.props.mapRef?.current && this.removeMarkerPoints?.length > 0) {
        this.props.mapRef.current.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            const markerLatLng = layer.getLatLng();
            // Check if the marker's coordinates are in the removeMarkerPoints array
            const isMarkerInRemovePoints = this.removeMarkerPoints.some(
              (point) => markerLatLng.equals(L.latLng(point))
            );
            if (isMarkerInRemovePoints) {
              // If the marker's coordinates are in the removeMarkerPoints array, remove it from the map
              this.props.mapRef.current.removeLayer(layer);
            }
          }
        });
      }

      await this.mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Polyline) {
          this.mapRef.current.removeLayer(layer);
        }
      });
    }
    if (this.mapRef) {
      await this.mapRef?.current?.eachLayer((layer) => {
        if (layer instanceof L.Polyline) {
          this.mapRef?.current?.removeLayer(layer);
        }
      });
      await this.mapRef?.current?.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          this.mapRef?.current?.removeLayer(layer);
        }
      });
    }
    await this.setState({ pauseBtnFeature: false });
    this.historyTrackingStore.allArray = [];
    this.historyTrackingStore.count = 0;
    this.historyTrackingStore.newPolyLineArray = [];
    this.historyTrackingStore.historyTrackingTableData = [];
    this.historyTrackingStore.polyLineArray = [];
    this.historyTrackingStore.offlineData = [];
    this.historyTrackingStore.offlineDataNewObj = [];
    this.historyTrackingStore.totalCount = null;
    this.historyTrackingStore.total = 0;
    this.historyTrackingStore.pgcount = 1;
    this.historyTrackingStore.offset = 0;
    this.removeMarkerPoints = [];

    await this.setState({ showBtn: false, isPlaying: true });
  };

  playFunc = async () => {
    await this.setState({ isPlaying: true });
    if (this.state.routeOption === 1 || this.state.routeOption === 4) {
      await this.straightLineFunc();
    } else if (this.state.routeOption === 5 || this.state.routeOption === 3) {
      await this.overSpeedAndFullPathFunc();
    }
  };

  render() {
    const formItemStyle = { marginBottom: "2px", marginTop: "2px" };
    return (
      <div>
        {/* {!this.props.trackingMapBoolean && (
          <FloatButton.Group
            shape="square"
            style={{
              // zIndex: 9999,
              // top: 60,
              // right: 10,
              // bottom: 847,
              // position:"relative",
              zIndex: 9999,
              top: 9,
              right: "346px",
              bottom: "847px",
            }}
          >
            <Popover
              content={
                <Radio.Group
                  options={this.mapOptions}
                  onChange={async (comp) =>
                    // console.log(comp.target.value)
                    await this.setState({
                      mapType: comp.target.value,
                      listUpdated: 1,
                    })
                  }
                  value={this.state.mapType}
                  optionType="button"
                  buttonStyle="solid"
                />
              }
              placement="left"

              //   onOpenChange={handleOpenChange}
            >
              <FloatButton icon={<GlobeCentralSouthAsia />} />
            </Popover>
          </FloatButton.Group>
        )} */}
        {!this.props.trackingMapBoolean && (
          <div
            dir={this.context.locale.language === "arabic" ? "ltr" : "ltr"}
            className={
              this.context.locale.language === "arabic"
                ? "arabic-map-container"
                : ""
            }
          >
            <MapContainer
              ref={this.mapRef}
              zoom={config.mapZoom}
              // minZoom={5}
              center={config.mapCenter}
              style={{ position: "sticky", height: "70vh", width: "100wh" }}
            >
              {/* <Polyline
            smoothFactor={5}
            color="darkred"
            positions={
              this.historyTrackingStore.polyLineArray.length > 0
                ? this.historyTrackingStore.polyLineArray
                : []
            }
          >
            <Popup>
              <code>
                &lt;Polyline smoothFactor={5}
                color="darkred" positions=
                {this.historyTrackingStore.polyLineArray.length > 0
                  ? this.historyTrackingStore.polyLineArray
                  : []}
                arrowheads={{ size: "300px" }}
                &gt;
              </code>
            </Popup>
          </Polyline> */}

              {/* {this.state.type || this.state.traffic ? (
                <ReactLeafletGoogleLayer
                  key={this.state.type ? this.state.type : ""}
                  type={this.state.type ? this.state.type : "roadmap"}
                  googleMapsAddLayers={
                    this.state.traffic ? [{ name: "TrafficLayer" }] : null
                  }
                />
              ) : (
                <ReactLeafletGoogleLayer
                  googleMapsAddLayers={
                    this.state.traffic ? [{ name: "TrafficLayer" }] : null
                  }
                />
              )} */}
              {/* this.state.mapType == "openstreet" && (
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="Map data © OpenStreetMap contributors"
                  /> */}

              {this.state.mapType === "openstreet" ? (
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              ) : (
                <ReactLeafletGoogleLayer />
              )}

              <AdditionalContentInMap
                This={this}
                size="small"
                showMapOptions={this.state.showMapOptions}
                mapType={this.state.mapType}
              />
            </MapContainer>
          </div>
        )}
        {(this.props.trackingMapBoolean ||
          this.state.historyTrackingWindow) && (
          <div>
            <Draggable handle=".handle">
              <Card
                className={
                  this.props.trackingMapBoolean ? "" : "history-tracking-panel"
                }
                style={{
                  ...cardCss,
                  insetInlineStart: this.props.trackingMapBoolean
                    ? "34%"
                    : "75%",
                  top: this.props.trackingMapBoolean ? "2%" : "0%",
                  width: this.props.trackingMapBoolean ? "25%" : "26%",
                  padding: this.props.trackingMapBoolean ? "15px" : "0px",
                }}
                title={
                  <div>
                    {this.props.trackingMapBoolean ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5 className="handle" style={{ cursor: "move" }}>
                          {/* History Tracking */}
                          {this.context.translate("_HISTORY_TRACKING_")}
                        </h5>
                        <CloseOutlined
                          style={{ fontSize: "20px" }}
                          onClick={async () => {
                            await this.clearMap();
                            await this.setState({
                              historyTrackingWindow: false,
                            });

                            await this.props?.closeTrackingMapWindow(false);
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        className="history-heading"
                        // style={{
                        //   height: "55px",
                        //   backgroundColor: "#e0e0e0",
                        //   display: "flex",
                        //   alignItems: "center",
                        //   justifyContent: "center",

                        // }}
                      >
                        <h5>
                          {/* History Tracking */}
                          {this.context.translate("_HISTORY_TRACKING_")}
                        </h5>
                        {/* <CloseOutlined
                    style={{ fontSize: "20px" }}
                    onClick={async () => {
                      await this.clearMap();
                      await this.setState({ historyTrackingWindow: false });
                      await this.props?.closeTrackingMapWindow(false);
                    }}
                  /> */}
                      </div>
                    )}
                  </div>
                }
              >
                <Form
                  onFinish={this.onFinishHistoryTracking}
                  layout="vertical"
                  ref={this.formRef}
                  style={{ padding: "10px" }}
                  initialValues={{ filterBy: 2 }}
                >
                  <div
                    style={{
                      overflow: "auto",
                      maxHeight: "380px",
                      // padding: "0 12px 0 0",
                      paddingBlock: "0",
                      paddingInlineEnd: "12px",
                      paddingInlineStart: "0",
                    }}
                  >
                    {(this.props.getUserData().isadmin === -1 ||
                      this.props.getUserData().isadmin === 3) && (
                      <>
                        <Row>
                          <Col span={11}>
                            <Form.Item
                              name="companyId"
                              // label="Account"
                              label={this.context.translate(
                                "_FLEET_DASHBORD_NEWPOI_CUSTOMER_"
                              )}
                              rules={[{ required: true }]}
                            >
                              <CompanyAutocomplete
                                onChange={async (value) => {
                                  //   await this.handleChangeForDrawingGeofence(value);
                                  await this.setState({
                                    companyId: value.value,
                                  });
                                  await this.historyTrackingStore.getVehicles({
                                    companyId: this.state.companyId,
                                  });
                                  this.setState({ update: 1 });
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={2} />
                          <Col span={11}>
                            <Form.Item
                              name="filterBy"
                              // label="Filter By"
                              label={this.context.translate("_FILTER_BY_")}
                              rules={[{ required: true }]}
                            >
                              <Select
                                // defaultValue={2}
                                onSelect={async (val) => {
                                  await this.historyTrackingStore.getVehicles({
                                    companyId: this.state.companyId,
                                    selectionId: val,
                                  });
                                  this.formRef.current.setFieldsValue({
                                    vehicleId: "",
                                  });
                                  await this.setState({ loadList: 1 });
                                }}
                              >
                                {/* <Select.Option value={1}>Vehicle Name</Select.Option>
                      <Select.Option value={2}>Vehicle No</Select.Option> */}
                                <Select.Option value={1}>
                                  {this.context.translate(
                                    "_FLEET_VEHICLENAME_"
                                  )}
                                </Select.Option>
                                <Select.Option value={2}>
                                  {this.context.translate("_VEHICLE_NO_")}
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                    {this.props.getUserData().isadmin !== -1 &&
                      this.props.getUserData().isadmin !== 3 && (
                        <Form.Item
                          name="filterBy"
                          // label="Filter By"
                          label={this.context.translate("_FILTER_BY_")}
                          rules={[{ required: true }]}
                        >
                          <Select
                            // defaultValue={2}
                            onSelect={async (val) => {
                              await this.historyTrackingStore.getVehicles({
                                companyId: this.state.companyId,
                                selectionId: val,
                              });
                              this.formRef.current.setFieldsValue({
                                vehicleId: "",
                              });
                              await this.setState({ loadList: 1 });
                            }}
                          >
                            {/* <Select.Option value={1}>Vehicle Name</Select.Option>
                      <Select.Option value={2}>Vehicle No</Select.Option> */}
                            <Select.Option value={1}>
                              {this.context.translate("_FLEET_VEHICLENAME_")}
                            </Select.Option>
                            <Select.Option value={2}>
                              {this.context.translate("_VEHICLE_NO_")}
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      )}
                    <Form.Item
                      label={this.context.translate("_VEHICLES_")}
                      name="vehicleId"
                      style={formItemStyle}
                      rules={[{ required: true }]}
                    >
                      {/* <Select
                        onSelect={(val) => {
                          this.setState({ vehicleId: val });
                        }}
                      >
                        {this.historyTrackingStore?.vehiclesArray?.map(
                          (val) => {
                            return (
                              <Select.Option value={val.vehicleId}>
                                {val.vehicleNo}
                              </Select.Option>
                            );
                          }
                        )}
                      </Select> */}
                      <Select
                        onSelect={(val) => {
                          this.setState({ vehicleId: val });
                        }}
                        showSearch
                        filterOption={(inputValue, option) => {
                          if (option.children) {
                            return (
                              option.children
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            );
                          }
                        }}
                      >
                        {this.historyTrackingStore?.vehiclesArray?.map(
                          (val) => {
                            return (
                              <Option vehicleObj={val} value={val.vehicleId}>
                                {val.vehicleNo}
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={this.context.translate("_SELECT_DATE_")}
                      name=""
                      // style={formItemStyle}
                      // rules={[{ required: true }]}
                    >
                      <RangePicker
                        placeholder={[
                          this.context.translate("_FROM_DATE_TIME_"),
                          this.context.translate("_TO_DATETIME_"),
                        ]}
                        format="DD-MM-YYYY hh:mmA"
                        // style={{ width: 400 }}
                        onChange={(val) => {
                          this.onDateTimeChange(val);
                        }}
                        disabledDate={(current) => {
                          return current && current > dayjs();
                        }}
                        showTime={{
                          format: "h:mmA",
                          defaultValue: [dayjs().startOf("day"), dayjs()],
                        }}
                        defaultValue={[dayjs().startOf("day"), dayjs()]}
                      />
                    </Form.Item>
                    {/* <Row>
                    <Col span={11}>
                      <Form.Item
                        label="From Date"
                        name="fromD"
                        style={formItemStyle}
                      >
                        <DatePicker
                          allowClear={false}
                          format={"MM-DD-YYYY"}
                          defaultValue={dayjs()}
                          onChange={async (val) => {
                            // await this.setState({
                            //     fromDate: val?.format("YYYY-MM-DD"),
                            //   })
                            await this.formRef.current.setFieldsValue({
                              fromD: val,
                            });
                            await this.formRef.current.setFieldsValue({
                              toD: val,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={11}>
                      <Form.Item
                        label="To Date"
                        name="toD"
                        style={formItemStyle}
                      >
                        <DatePicker
                          allowClear={false}
                          format={"MM-DD-YYYY"}
                          defaultValue={dayjs()}
                          onChange={(val) =>
                            //   this.setState({
                            //     toDate: val?.format("YYYY-MM-DD"),
                            //   })
                            this.formRef.current.setFieldsValue({ toD: val })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row> */}
                    {/* 
                  <Row>
                    <Col span={11}>
                      <Form.Item
                        label="From Time"
                        name="fromTime"
                        style={formItemStyle}
                        initialValue={"00:00:00"}
                      >
                        <Select
                          onChange={this.setFromTime}
                          onSelect={(val) => this.setState({ fromTime: val })}
                          // value={this.state.fromTime}

                          allowClear={true}
                        >
                          {this.timeOptions()}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={11}>
                      <Form.Item
                        label="To Time"
                        name="toTime"
                        style={formItemStyle}
                        initialValue={dayjs().format("hh:mm A")}
                      >
                        <Select
                          onSelect={(val) => this.setState({ toTime: val })}
                          onChange={(e) => console.log(e)}
                          // value={this.state.fromTime}
                          allowClear={true}
                        >
                          {this.timeOptions()}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row> */}
                    <Form.Item
                      // label="Select Route Options"
                      label={this.context.translate("_SELECT_ROUT_OPTION_")}
                      name="SelRoutOption"
                      style={formItemStyle}
                      rules={[
                        {
                          required: true,
                          message: this.context.translate(
                            "_SELECT_ROUT_OPTION_"
                          ),
                        },
                      ]}
                    >
                      <Select
                        onSelect={(val) => this.setState({ routeOption: val })}
                      >
                        <Select.Option value={1}>Straight Line</Select.Option>
                        <Select.Option value={3}>Full Path</Select.Option>
                        <Select.Option value={4}>Trip</Select.Option>
                        <Select.Option value={5}>Overspeed</Select.Option>
                      </Select>
                    </Form.Item>

                    <Row
                      style={{
                        display:
                          this.state.routeOption === 5 ||
                          this.state.routeOption === 4
                            ? "none"
                            : "",
                      }}
                    >
                      <Col span={11}>
                        <Form.Item
                          // label="Select Trip Event"
                          label={this.context.translate("_SELECT_TRIP_EVENT_")}
                          name="TripEventdata"
                          style={formItemStyle}
                        >
                          <Select
                            onSelect={(val) =>
                              this.setState({ tripEventOption: val })
                            }
                          >
                            <Select.Option value={1}>All</Select.Option>
                            <Select.Option value={2}>Travel</Select.Option>
                            <Select.Option value={3}>Stopped</Select.Option>
                            <Select.Option value={4}>Idle</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2} />

                      <Col
                        span={11}
                        style={{
                          display:
                            this.state.routeOption === 5 ||
                            this.state.routeOption === 4
                              ? "none"
                              : "",
                        }}
                      >
                        <Form.Item
                          // label="Select Speed"
                          label={this.context.translate("_SELECT_SPEED_")}
                          name="speed"
                          style={formItemStyle}
                        >
                          <Select
                            onSelect={(val) => this.setState({ speed: val })}
                          >
                            <Select.Option value={1}>Fast</Select.Option>
                            <Select.Option value={2}>Medium</Select.Option>
                            <Select.Option value={3}>Slower</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <div
                      style={{
                        display: this.state.routeOption === 4 ? "" : "none",
                      }}
                    >
                      <Form.Item
                        // label="Event Name"
                        label={this.context.translate("_EVENT_NAME_")}
                        name="speed"
                        style={formItemStyle}
                      >
                        <Select defaultValue={1}>
                          <Select.Option value={1}>No Event</Select.Option>
                          <Select.Option value={2}>
                            Harsh Acceleration
                          </Select.Option>
                          <Select.Option value={3}>
                            Harsh Deacceleration
                          </Select.Option>
                          <Select.Option value={4}>
                            Load Sensor Disconnect Alarm
                          </Select.Option>
                          <Select.Option value={5}>
                            Power Cut Alarm
                          </Select.Option>
                          <Select.Option value={6}>
                            High Impact(Accident)
                          </Select.Option>
                          <Select.Option value={7}>
                            Low Impact(Incident)
                          </Select.Option>
                          <Select.Option value={8}>
                            GSM Jamming Alarm
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "12px",
                      borderTop: "1px solid gray",
                      width: "100%",
                      alignItems: "center",
                      paddingBottom: "12px",
                    }}
                  >
                    {this.state.showBtn && (
                      <Button
                        disabled={this.state.pauseBtnFeature}
                        className="formCancelButton"
                        onClick={() => {
                          this.setState({
                            isPlaying: false,
                            pauseBtnFeature: true,
                          });
                        }}
                      >
                        Pause
                      </Button>
                    )}
                    {
                      <div
                        style={{
                          display: this.state.showBtn === false ? "" : "none",
                        }}
                      >
                        <Button
                          htmlType="submit"
                          className="formCancelButton"
                          type="primary"
                        >
                          {/* Show */}
                          {this.context.translate("_FLEET_SHOW_BTN_")}
                        </Button>
                      </div>
                    }
                    {this.state.showBtn && (
                      <Button
                        type="primary"
                        className="formCancelButton"
                        onClick={() => {
                          this.playFunc();
                          this.setState({ pauseBtnFeature: false });
                        }}
                        disabled={!this.state.pauseBtnFeature}
                      >
                        Play
                      </Button>
                    )}

                    <Button
                      onClick={this.clearMap}
                      className="formCancelButton"
                    >
                      {/* Clear */}
                      {this.context.translate("_FLEET_CLEAR_BTN_")}
                    </Button>
                    {/* <Button type="primary" className="formSaveButton">
                  Close
                </Button> */}
                  </div>
                </Form>
              </Card>
            </Draggable>
          </div>
        )}
        {!this.props.trackingMapBoolean && (
          <Table
            pagination={false}
            scroll={{ y: 160 }}
            bordered
            columns={this.columns}
            dataSource={
              (this.historyTrackingStore.offlineDataNewObj.length > 0 && [
                ...this.historyTrackingStore.offlineDataNewObj,
              ]) ||
              this.historyTrackingStore.historyTrackingTableData
            }
          />
        )}
      </div>
    );
  }
}

HistoryTracking.contextType = StoreContext;
export default observer(withRouter(HistoryTracking));
