import React from "react";
import { Row, Col, Space, Tooltip, message, Button, Modal } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import ListTable from "./ListTable";

import config from "../config";

import withRouter from "../withRouter";
import DeleteConfirm from "./DeleteConfirm";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import CompanyAutocomplete from "./CompanyAutocomplete";
import AudioMeassage from "../store/AudiomessageStore";
import AudioMeassageAdd from "./AudioMeassageAdd";
import { Link } from "react-router-dom";
import CompanyStore from "../store/CompanyStore";

class Audiomessage extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.audiomessageStore = new AudioMeassage(this);
    this.companyStore = new CompanyStore(this);
    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      loadData: 1,
      companyId: "",
      openAudioMsgForm: false,
      listDataUpdate: false,
      comp_value: "",
    };
  }
  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });

      await this.handleSearch();
    }
    this.setState({ pageSize: 50 });

    this.columns = [
      {
        title: this.context.translate("_NAME_"),
        dataIndex: "name",
        width: "15%",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },

      {
        title: this.context.translate("_DESCRIPTION_"),
        dataIndex: "description",
        width: "20%",
      },
      {
        title: this.context.translate("_AUDIO_VIEW_"),
        dataIndex: "path",
        width: "20%",
        render: (val, record) => {
          // console.log(config.fleetURLAdvertise + record.path);
          return (
            <audio controls>
              <source
                src={config.fleetURLAdvertise + record.path}
                type="audio/mpeg"
              />
              {/* <source src={config.fleetURL + "/audio/" +"1643361492Most_Popular.mp3"} type="audio/mpeg" /> */}
            </audio>
          );
        },
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "action",
        width: "5%",
        render: (_, record) => {
          return (
            <>
              <Space>
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    // title="Delete Record"
                    title={this.context.translate("_DELETE_RECORD_")}
                    color={config.tooltipColorDelete}
                  >
                    <DeleteFilled style={{ color: config.deleteColor }} />
                  </Tooltip>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const setAudioData = this.context.viewStateStore.audioMsgObject;
      // console.log(setAudioData)
      if (setAudioData !== "") {
        await this.audiomessageStore.loadData({
          companyId: this.state.companyId,
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
        });

        const singleData = await this.companyStore.getCompanyDataById(
          setAudioData
        );

        await this.setState({ loadList: 1 });

        await this.setState({
          comp_value: singleData,
          companyId: setAudioData,
        });
      }
      await this.setState({ listDataUpdate: false });
    }
  }

  onEdit = async (record) => {
    await this.context.viewStateStore.setAudioMsgStates({
      companyId: record.companyId,
    });
    await this.setState({ id: record.id });
    await this.setState({ openAudioMsgForm: true });
  };

  async onDelete(record) {
    console.log(record);
    const response = await this.audiomessageStore.deleteRecord({
      id: record.id,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.audiomessageStore.loadData({
      companyId: this.state.companyId,
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
    });
    await this.setState({ loadData: 1, loading: false });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async handleChange(comp) {
    console.log(comp.value);

    await this.setState({
      companyId: comp.value,
      currentPage: 1,
      pageSize: 50,
    });

    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    console.log(singleData);

    await this.setState({ comp_value: singleData });

    await this.handleSearch();
    await this.setState({ loadList: 1 });
  }

  closeModal = () => {
    this.setState({ openAudioMsgForm: false });
  };

  responseMsg = async (resp) => {
    if (resp.success === "S") {
      message.success(resp.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openAudioMsgForm: false });
      await this.handleSearch();
    }
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_AUDIO_MESSAGE_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Tooltip
                  placement="left"
                  //  title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({ openAudioMsgForm: true, id: "" })
                    }
                  ></Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  style={{ width: 200 }}
                  value={this.state.comp_value ? this.state.comp_value : null}
                />
              </Space>
            </Col>
          </Row>
          <ListTable
            currentPage={this.state.currentPage}
            total={this.audiomessageStore?.total}
            columns={this.columns}
            pageSize={this.state.pageSize}
            dataSource={this.audiomessageStore?.audiomessageList}
            loading={this.audiomessageStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openAudioMsgForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal"
            // title="Driver Details"
            title={this.context.translate("_FILE_UPLOAD_")}
            centered
            open={this.state.openAudioMsgForm}
            footer={null}
            onCancel={() => {
              this.setState({ openAudioMsgForm: false, id: "" });
            }}
            width={665}
          >
            <AudioMeassageAdd
              id={this.state.id}
              companyId={this.state.companyId}
              response={this.responseMsg}
              closeModal={this.closeModal}
            />
          </Modal>
        )}
      </>
    );
  }
}
Audiomessage.contextType = StoreContext;
export default observer(withRouter(Audiomessage));
