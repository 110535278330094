import { observer } from "mobx-react";
import React, { Component } from "react";
import withRouter from "../withRouter";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Empty,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { StoreContext } from "../store/RootStore";
import DistanceGraphStore from "../store/DistanceGraphStore";
import dayjs from "dayjs";
import { Column } from "@ant-design/plots";

import MaxSpeedGraphStore from "../store/MaxSpeedGraphStore";
import VehicleStore from "../store/VehicleStore";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
const { Option } = Select;
class MaxSpeedGraph extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.maxSpeedGraphStore = new MaxSpeedGraphStore(this);
    this.distanceGraphStore = new DistanceGraphStore(this);
    this.vehicleStore = new VehicleStore(this);

    this.state = {
      selectAllVehicles: false,
      selectedVehicles: [],
      update: 0,
      companyId: "",
      distance: "",
      fromDate: dayjs().format("YYYY-MM-DD"),
      toDate: dayjs().format("YYYY-MM-DD"),
      Date: dayjs().format("YYYY-MM-DD"),
      loadData: 0,
      showMaxSpeedGraph: false,
      showToDate: false,
      showFromDate: false,
      showDate: false,
      Notcompany: false,
      Notasset: false,
      loadingSpin: false,
      xlabel: "",
    };
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });
    // await this.setState({ selectedVehicles: [] });
    await this.setState({ selectedVehicles: [], selectAllVehicles: false });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);

    this.setState({ update: 1 });
  }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.vehicleStore.getVehiclesCompanyId(
        this.props.getUserData().userCompanyId
      );
      await this.setState({ update: 1 });
    }
  }

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      const allVehiclesArr = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      this.setState({ showMaxSpeedGraph: true });
      if (this.state.selectedVehicles.length > 1) {
        this.setState({ xlabel: "vehicleNo" });
      } else {
        this.setState({ xlabel: "startTime" });
      }
      await this.setState({ loadingSpin: true });
      this.setState({ Notcompany: false, Notasset: false });

      const data = await this.maxSpeedGraphStore.loadMaxSpeedGraphData({
        companyId: this.state.companyId,
        vehicleId: this.state.selectedVehicles,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        Date: this.state.Date,

        // pageNo: this.state.pageNo,
      });
    }

    this.setState({ loadData: 1 });
    await this.setState({
      loadingSpin: false,
      fromDate: dayjs().format("YYYY-MM-DD"),
      toDate: dayjs().format("YYYY-MM-DD"),
    });
  };

  render() {
    return (
      <>
        <Spin spinning={this.state.loadingSpin} fullscreen>
          <div style={{ padding: 0 }}>
            <Row className="headerFixed" style={{ height: "50px" }}>
              <Col span={12}>
                {/* <div className="heading">Max Speed Graph</div> */}
                <div className="heading">
                  {this.context.translate("_MAX_SPEED_GRAPH_")}
                </div>
              </Col>
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>
          </div>
          <div
            style={{
              paddingTop: "10px",
              paddingRight: "24px",
              paddingLeft: "24px",
            }}
          >
            <Row className="listInputRow">
              <Col span={20}>
                <Space>
                  <CompanyAutocomplete
                    onChange={this.handleChange}
                    allowClear={true}
                    style={{ width: 175 }}
                    status={this.state.Notcompany ? "error" : false}
                  />

                  {this.props.getUserData().isadmin === 1 && (
                    <DeviceGroupsForVehicles
                      vehicleStore={this.vehicleStore}
                      setLoadDta={() => this.setState({ loadData: 1 })}
                      resetVehicles={() =>
                        this.setState({
                          selectedVehicles: [],
                          selectAllVehicles: false,
                        })
                      }
                    />
                  )}

                  <Checkbox
                    checked={this.state.selectAllVehicles}
                    onChange={async (e) => {
                      await this.setState({
                        selectAllVehicles: e.target.checked,
                        showToDate: false,
                      });
                      if (this.state.selectAllVehicles === true) {
                        this.setState({
                          showToDate: false,
                          showFromDate: false,
                          showDate: true,
                        });
                      } else {
                        this.setState({
                          showToDate: false,
                          showFromDate: false,
                          showDate: false,
                        });
                      }
                      console.log(e.target.checked);
                      await this.handleAllSelectVehicles();
                    }}
                  />
                  {/* All */}
                  {this.context.translate("_ALL_")}
                  <Select
                    mode="multiple"
                    maxTagCount="responsive"
                    value={this.state.selectedVehicles}
                    onChange={(val) => {
                      console.log(val?.length);
                      this.setState({ selectedVehicles: val, Notasset: false });

                      if (val?.length == 1) {
                        this.setState({
                          showToDate: true,
                          showFromDate: true,
                          showDate: false,
                        });
                      } else if (val?.length >= 1) {
                        this.setState({
                          showToDate: false,
                          showFromDate: false,
                          showDate: true,
                        });
                      } else if (val?.length <= 1) {
                        this.setState({
                          showToDate: false,
                          showFromDate: false,
                          showDate: false,
                        });
                      }
                    }}
                    // fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                    // placeholder="Asset Name"
                    placeholder={this.context.translate(
                      "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                    )}
                    optionFilterProp="children"
                    // options={this.distanceGraphStore?.vehiclesArray}
                    style={{ width: 175 }}
                    status={this.state.Notasset ? "error" : false}
                    filterOption={(inputValue, option) => {
                      if (option.children) {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {this.vehicleStore?.getVehiclesUsingCompanyId?.map(
                      (val) => {
                        return (
                          <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                        );
                      }
                    )}
                  </Select>
                  {this.state.showToDate && (
                    <DatePicker
                    format={"DD-MM-YYYY"}
                      defaultValue={dayjs()}
                      placeholder={this.context.translate("_FROM_DATE_")}
                      onChange={(date) => {
                        console.log(date);
                        if (date === null) {
                          this.setState({ fromDate: "" });
                        } else {
                          const format = "YYYY-MM-DD";
                          this.setState({ fromDate: date.format(format) });
                          console.log(date.format("YYYY-MM-DD HH:mm:ss"));
                        }
                      }}
                      disabledDate={(current) => {
                        return current && current > dayjs();
                      }}
                    />
                  )}
                  {this.state.showFromDate && (
                    <DatePicker
                    format={"DD-MM-YYYY"}
                      defaultValue={dayjs()}
                      placeholder={this.context.translate("_TO_DATE_")}
                      onChange={(date) => {
                        console.log(date);
                        if (date === null) {
                          this.setState({ toDate: "" });
                        } else {
                          const format = "YYYY-MM-DD";
                          this.setState({ toDate: date.format(format) });
                          console.log(date.format("YYYY-MM-DD HH:mm:ss"));
                        }
                      }}
                      disabledDate={(current) => {
                        return current && current > dayjs();
                      }}
                    />
                  )}
                  {this.state.showDate && (
                    <DatePicker
                    format={"DD-MM-YYYY"}
                      defaultValue={dayjs()}
                      placeholder="Date"
                      onChange={(date) => {
                        console.log(date);
                        if (date === null) {
                          this.setState({ Date: "" });
                        } else {
                          const format = "YYYY-MM-DD";
                          this.setState({ Date: date.format(format) });
                          console.log(date.format("YYYY-MM-DD HH:mm:ss"));
                        }
                      }}
                      disabledDate={(current) => {
                        return current && current > dayjs();
                      }}
                    />
                  )}
                  <Button
                    type="primary"
                    onClick={async () => {
                      await this.showData();
                      // await this.setState({ showMaxSpeedGraph: true });
                    }}
                  >
                    {/* Show */}
                    {this.context.translate("_FLEET_SHOW_BTN_")}
                  </Button>
                </Space>
              </Col>
              <Col span={4}>
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>

            {this.state.showMaxSpeedGraph &&
            this.maxSpeedGraphStore?.maxSpeedGraph?.length > 0 ? (
              <Column
                style={{ marginTop: 50 }}
                {...{
                  data:
                    this.maxSpeedGraphStore?.maxSpeedGraph?.length > 0
                      ? this.maxSpeedGraphStore?.maxSpeedGraph
                      : [],

                  yField: "maxSpeed",
                  animation: false,
                  xField: this.state.xlabel,
                  // xField: (this.state.selectedVehicles.length > 1) ? 'vehicleNo' : 'startTime',
                  legend: false,
                  seriesField: "maxSpeed",
                  yAxis: {
                    title: {
                      // text: "Max Speed",
                      text: this.context.translate(
                        "_FLEET_REPORTS_FLEETREPORT_MAXSPEED_"
                      ),
                      style: {
                        fontWeight: "bold",
                        fontSize: 17,
                      },
                    },
                  },
                  xAxis: {
                    title: {
                      // text: "Asset Name",
                      // text:this.context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"),
                      text:
                        this.state.xlabel === "vehicleNo"
                          ? this.context.translate(
                              "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                            )
                          : this.context.translate("_DATE_"),
                      style: {
                        fontWeight: "bold",
                        fontSize: 17,
                      },
                    },
                  },

                  tooltip: {
                    // showMarkers: false,
                    // enterable: true,

                    customContent: (title, items) => {
                      const data = items[0]?.data || {};
                      console.log(data.driverphone)
                      const vehicleNo = (
                        <div class="custom-tooltip-title">
                          {this.context.translate("_ASSET_NAME_")}:
                          {data.vehicleNo}
                        </div>
                      );
                      const maxSpeed = (
                        <div class="custom-tooltip-title">
                          {this.context.translate("_MAX_SPEED_1")}:
                          {data.maxSpeed ? data.maxSpeed : "-"}
                        </div>
                      );
                      const name = (
                        <div class="custom-tooltip-title">
                          {this.context.translate("_DRIVER_NAME_")}:
                          {data.driverName ? data.driverName : "-"}
                        </div>
                      );
                      const driverPhone = (
                        <div class="custom-tooltip-title">
                          {this.context.translate("_DRIVER_MOBILE_NO_")}:
                          {data.driverphone ? data.driverphone : "-"}
                        </div>
                      );
                      const date = (
                        <div class="custom-tooltip-title">
                          {this.context.translate("_DATE_")}:
                          {data.date ? data.date : "-"}
                        </div>
                      );

                      return (
                        <div style={{ background: "white", padding: "10px" }}>
                          {vehicleNo}
                          {maxSpeed}
                          {name}
                          {driverPhone}
                          {date}
                        </div>
                      );
                    },
                  },
                }}
              />
            ) : (
              <Empty></Empty>
            )}
          </div>
        </Spin>
      </>
    );
  }
}
MaxSpeedGraph.contextType = StoreContext;
export default observer(withRouter(MaxSpeedGraph));
