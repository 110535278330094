import React, { Component } from "react";
import {
  Form,
  Select,
  DatePicker,
  Input,
  message,
  Table,
  Button,
  Space,
  Row,
  Col,
  Modal,
  Tooltip,
  Spin,
} from "antd";

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";

import config from "../config";
import CompanyAutocomplete from "./CompanyAutocomplete";

import DriversStore from "../store/DriversStore";
import CompanyStore from "../store/CompanyStore";
import VehicleWorkingHoursStore from "../store/VehicleWorkingHoursStore";

import dayjs from "dayjs";

import DriverAdd from "./DriverAdd";
import NewMaintenanceStore from "../store/NewMaintenanceStore";
const { Option } = Select;
const { Column } = Table;
class VehicleMaintenanceAdd extends Component {
  formRef = React.createRef();
  driverformRef = React.createRef();
  mainFormRef = React.createRef();
  serviceFormRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      tableData: [],
      companyId: "",
      loadList: 0,
      vehiclesAssetArray: [],
      vehicleId: "",

      serviceData: [],
      companyId: "",
      search: false,
      newserviceType: false,
      openDriverDetailsForm: false,
      driverDataArray: [],
      Id: -1,
      serviceId: -1,
      company_value_label: "",
      listUpdated: 0,
      formLoading: false,
      pageSize: 50,
      currentPage: 0,
      openNewMainDetails: false,
      showDeleteModal: false,
      gridaId: "",
      gridmId: "",
      filterData: [],
    };

    this.newMainStore = new NewMaintenanceStore(this);
    this.driverStore = new DriversStore();
    this.companyStore = new CompanyStore();
    this.workingHoursStore = new VehicleWorkingHoursStore();
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      this.context.viewStateStore.setNewVehMaintenanceStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ isadmin: this.props.getUserData().isadmin });
      const fillDriver = await this.driverStore.toGetDriverName(
        this.props.getUserData().userCompanyId
      );
      await this.setState({ formLoading: fillDriver ? false : true });
      const data = await this.workingHoursStore.getAssignAsset({
        companyId: this.props.getUserData().userCompanyId,
      });
    }

    // await this.newMainStore.getServiceData();

    await this.newMainStore.getMaintenanceData();

    await this.setState({ loadList: 1 });

    const Id = this.props.vehicleMaintenanceId;

    if (Id) {
      await this.filldata(Id);
      this.setState({ Id: Id });
    }
  }

  filldata = async (Id) => {
    await this.setState({ formLoading: true });
    const getVehicleMaitenance =
      await this.newMainStore.getVehicleMaitenanceData({
        Id,
        companyId: this.props.companyId,
      });
    const gridDetails = await this.newMainStore.getMaintenanceGridData(Id);

    const filteredGridDetails = gridDetails?.map((detail) =>
      // console.log(detail.discountTypeGrid)
      ({
        gId: detail.gId,
        item: detail.item,
        quantity: detail.quantity,
        grossPrice: detail.grossPrice,
        price: detail.price,
        discountGrid: detail.discountGrid,
        discountTypeGrid: Number(detail.discountTypeGrid),
        aId: detail.aId,
        maintenanceId: detail.maintenanceId,
      })
    );

    const filterData = gridDetails?.map((detail) =>
      // console.log(detail.discountTypeGrid)
      ({
        aId: detail.aId,
        maintenanceId: detail.maintenanceId,
      })
    );
    console.log(filterData);
    await this.setState({ filterData: filterData });
    await this.newMainStore.getServiceData();

    await this.setState({
      companyId: getVehicleMaitenance.companyId,
      Id: getVehicleMaitenance.Id,
      serviceId: Number(getVehicleMaitenance.serviceId),
      tableData: filteredGridDetails,
    });
    const singleData = await this.companyStore.getCompanyDataById(
      getVehicleMaitenance.companyId
    );

    await this.workingHoursStore.getAssignAsset({
      companyId: this.state.companyId,
    });
    let driverResp = await this.driverStore.toGetDriverName(
      getVehicleMaitenance.companyId
    );

    // await this.setState({ driverDataArray: fillDriver });

    await this.formRef.current.setFieldsValue({
      Id: getVehicleMaitenance.Id,
      companyId: singleData,
      currentOdometer: getVehicleMaitenance.currentOdometer,
      discount: getVehicleMaitenance.discount,
      driverId: getVehicleMaitenance.driverId,
      billNo: getVehicleMaitenance.billNo,

      extraWorksDone: getVehicleMaitenance.extraWorksDone,

      garageAddress: getVehicleMaitenance.garageAddress,
      garageName: getVehicleMaitenance.garageName,
      serviceDate: dayjs(getVehicleMaitenance.serviceDate),
      serviceType: Number(getVehicleMaitenance.serviceId),
      totalAmmount: getVehicleMaitenance.totalAmmount,
      vehicleId: getVehicleMaitenance.vehicleId,
      subTotalAmount: getVehicleMaitenance.subTotalAmount,
      discountType: getVehicleMaitenance.discountType,
      maintenanceTypeId: getVehicleMaitenance.maintenanceTypeId,
      serviceTypeId: getVehicleMaitenance.serviceId,
    });
    console.log(gridDetails.itemid);
    if (gridDetails.itemid === "1") {
      this.setState({ newserviceType: true });
    }

    await this.setState({ formLoading: driverResp ? false : true });
  };

  responseMsg = async (response) => {
    const DriverName = await this.driverStore.toGetDriverName(
      this.state.companyId
    );

    if (response.success == "S") {
      message.success(response.message);
      await this.driverStore.toGetDriverName(this.state.companyId);
      await this.formRef.current.setFieldsValue({
        driverId: Number(response?.data.driverId),
        // driverId:response.data.name
      });

      await this.setState({ openDriverDetailsForm: false });
      // this.formRef.current?.resetFields();
    } else if (response.success === "F0") {
      response.errors.map((value) => {
        message.error(this.context.translate(value["username"]));
        // console.log(this.context.translate(value["driver"]),value["driver"])
      });
    }

    this.setState({ loadData: 1 });
    this.setState({ loading: false });

    const companyData = await this.companyStore.getCompanyDataById(
      this.state.companyId
    );

    await this.setState({ loadData: 1 });
  };

  onMaintenanceSubmit = async (values) => {
    this.setState({ loading: true });

    const MaintenanceDetails = {
      ...values,
      // driverId: values.driverId?.label
      //   ? values.driverId.value
      //   : values.driverId,
      returnStatus: 0,
      Id: this.state.Id,
      companyId: this.state.companyId,
      serviceId: this.state.serviceId,
      serviceDate: values?.serviceDate.format("YYYY-MM-DD"),
    };

    const data = {
      tokenId: "",
      MaintenanceDetails: MaintenanceDetails,
      GridDetails: this.state.tableData,
    };
    console.log(data);
    const resp = await this.newMainStore.addData(data);
    await this.props.response(resp);
    // if (resp.data.success == "S") {
    //  message.success(resp.data.message);
    //   this.props.navigate("/vehiclemaintenancelist");
    // } else if (resp.data.success == "F0") {
    //   resp.data.errors.map((value) => {
    //     message.error(["This Service Type already exists"]);
    //   });
    // }
    this.setState({ loading: false });
  };

  handleInputChange = (e, index, fieldName) => {
    var grosstotal = 0;
    const newData = [...this.state.tableData];
    if (fieldName === "grossPrice") {
      // Use the current values from newData for quantity and price
      const quantity = newData[index]["quantity"];
      const price = newData[index]["price"];

      newData[index]["grossPrice"] = parseFloat(quantity) * parseFloat(price);

      // var grossprice = parseFloat(quantity) * parseFloat(price);
    } else if (fieldName === "discountTypeGrid") {
      newData[index][fieldName] = e;
    } else {
      newData[index][fieldName] = e.target.value;
    }

    const discountType = newData[index]["discountTypeGrid"];
    const discountValue = newData[index]["discountGrid"];
    const quantity = newData[index]["quantity"];
    const price = newData[index]["price"];
    const grossprice = parseFloat(quantity) * parseFloat(price);

    if (discountType === "1") {
      // Percentage discount
      if (discountValue) {
        const discount = parseFloat(discountValue);
        grosstotal = grossprice - (discount * grossprice) / 100;
        newData[index]["grossPrice"] = grosstotal;
      }
    } else if (discountType === "2") {
      // Flat amount discount
      if (discountValue) {
        const discount = parseFloat(discountValue);
        grosstotal = grossprice - discount;
        newData[index]["grossPrice"] = grosstotal;
      }
    }

    this.setState({ tableData: newData });
  };

  handleAddRecord = () => {
    const { tableData } = this.state;
    this.setState({
      tableData: [
        ...tableData,
        {
          gId: "",
          item: "",
          quantity: "",
          price: "",
          discountGrid: "",
          discountTypeGrid: "",
          grossPrice: "",
          aId: "",
          maintenanceId: "",
        },
      ],
    });
  };

  async handleChange(comp) {
    console.log(comp.value);
    await this.setState({ formLoading: true });
    await this.setState({ companyId: comp.value });
    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });
    if (!this.props.vehicleMaintenanceId) {
      this.context.viewStateStore.setNewVehMaintenanceStates({
        companyId: comp.value,
      });
    }
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ company_value_label: singleData });

    // const vehicleAsset = await this.vehicleStore.getVehicles(comp.value);
    // await this.setState({ vehiclesAssetArray: vehicleAsset });
    // console.log(comp.value);

    const fillDriver = await this.driverStore.toGetDriverName(comp.value);
    // console.log(fillDriver, "ee");
    // await this.setState({ driverDataArray: fillDriver });
    await this.setState({ formLoading: fillDriver ? false : true });
    const data = await this.workingHoursStore.getAssignAsset({
      companyId: comp.value,
    });

    this.setState({ update: 1 });
  }

  validateFieldsValue = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9.]*/g, "");

    if (value1) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  onDeleteRow = async (index, record) => {
    console.log(record);
    const filterDat = this.state.tableData.filter((val, ind) => ind !== index);

    this.setState({ tableData: filterDat });

    // const response = await this.newMainStore.deleteGridRecord({
    //   aId: record.aId,
    //   maintenanceId: record.maintenanceId,
    // });

    // if (response?.data?.success === "S") {
    //   message.success(response.data.message);
    //   this.setState({ tableData: response.data.data });
    //   this.setState({ showDeleteModal: false });
    // } else {
    //   message.error(response.data.message);
    // }
  };

  closeDriverModal = () => {
    this.setState({ openDriverDetailsForm: false, driverId: "" });
  };

  closeModal = () => {
    this.props.closeModal();
  };

  handleNewMainDetails = async (values) => {
    const response = await this.newMainStore.addMaintenance(values);

    if (response.success == "S") {
      message.success(response.message);
      await this.newMainStore.getMaintenanceData();
      await this.formRef.current.setFieldsValue({
        maintenanceTypeId: Number(response?.data.maintenanceTypeId),
      });

      await this.setState({ openNewMainDetails: false });
      this.mainFormRef.current?.resetFields();
    }
  };

  handleServiceDetails = async (value) => {
    const response = await this.newMainStore.addService(value);
    if (response.success == "S") {
      message.success(response.message);
      await this.newMainStore.getServiceData();
      await this.formRef.current.setFieldsValue({
        serviceTypeId: Number(response?.data.serviceId),
      });

      await this.setState({ openServiceForm: false });
      this.serviceFormRef.current?.resetFields();
    }
  };

  getServiceData = async (val) => {
    const data = await this.newMainStore.getServiceData(val);

    this.setState({ loadData: 1 });
  };

  onValuesChange = (changedValues, allValues) => {
    const { subTotalAmount, discount } = allValues;
    if (subTotalAmount !== undefined && discount !== undefined) {
      const totalAmount = subTotalAmount - discount;
      this.formRef.current.setFieldsValue({
        totalAmmount: totalAmount,
      });
    }
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.formLoading} fullscreen>
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              layout="vertical"
              ref={this.formRef}
              onFinish={this.onMaintenanceSubmit}
              // {...this.layout}
              className="formClass" //change
              name="nest-message"
              onValuesChange={this.onValuesChange}

              // initialValues={{ serviceDate: moment(currentDate) }}
            >
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => {
                          this.handleChange(value);
                          this.formRef.current.setFieldsValue({
                            vehicleId: undefined,
                            driverId: undefined,
                            currentOdometer: undefined,
                          });
                        }}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="vehicleId"
                    label={this.context.translate("_ASSET_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      // placeholder="Select Asset"
                      placeholder={this.context.translate(
                        "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                      )}
                      style={{ width: "100%" }}
                      onSelect={(val, currentOdometer) => {
                        this.formRef.current.setFieldsValue({
                          currentOdometer:
                            currentOdometer.currentOdometer -
                            -currentOdometer.diffOdo,
                        });
                      }}
                      showSearch
                      // fieldNames={{ value: "vehicleId", label: "vehicleNo" }}
                      optionFilterProp="children"
                      //   options={this.workingHoursStore?.assignAssetList}
                      // />
                      filterOption={(inputValue, option) => {
                        if (option.children) {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) >= 0
                          );
                        }
                      }}
                    >
                      {this.workingHoursStore?.assignAssetList?.map((val) => {
                        return (
                          <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Row
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Form.Item
                      name="driverId"
                      // label="Driver"
                      label={this.context.translate(
                        "_FLEET_MANAGEVEHICLE_DRIVER_TAB_"
                      )}
                      style={{ width: "80%" }}
                    >
                      <Select
                        // placeholder="Select Driver"
                        placeholder={this.context.translate("_SELECT_DRIVER_")}
                        name="driverId"
                        value={this.state.driverId}
                        // onSelect={(e) => console.log(e)}
                        // onSearch={this.handleSearch}
                        showSearch
                        // fieldNames={{ value: "driverId", label: "name" }}
                        optionFilterProp="children"
                        //   options={this.driverStore?.getDriverName}
                        // />
                        filterOption={(inputValue, option) => {
                          if (option.children) {
                            return (
                              option.children
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            );
                          }
                        }}
                      >
                        {this.driverStore?.getDriverName?.map((val) => {
                          return (
                            <Option value={val.driverId}>{val.name}</Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    <Form.Item name=" " label={" "}>
                      <Tooltip
                        // title="Add New Driver"
                        title={this.context.translate(
                          "_FLEET_VEHICLE_VEHICLEDETAIL_ADDDRIVER_"
                        )}
                        placement="bottom"
                        color={config.tooltipColorEdit}
                      >
                        <Button
                          style={{ height: "48px" }}
                          type="primary"
                          onClick={(e) => {
                            this.setState({ openDriverDetailsForm: true });
                          }}
                        >
                          <PlusOutlined />
                        </Button>
                      </Tooltip>
                    </Form.Item>
                  </Row>

                  <Form.Item
                    // initialValue={0}
                    name="currentOdometer"
                    label={this.context.translate("_CURRENT_ODOMETER_")}
                  >
                    <Input
                      onChange={(e) =>
                        this.validateFieldsValue(
                          e.target.value,
                          "currentOdometer"
                        )
                      }
                    />
                  </Form.Item>

                  <Row
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Form.Item
                      name="maintenanceTypeId"
                      // label="Driver"
                      label={this.context.translate("_MAINTENANCE_TYPE_")}
                      style={{ width: "80%" }}
                    >
                      <Select
                        value={this.state.maintenanceTypeId}
                        showSearch
                        // fieldNames={{
                        //   value: "maintenanceTypeId",
                        //   label: "maintenanceType",
                        // }}
                        onSelect={(val) => {
                          this.formRef.current.setFieldsValue({
                            serviceTypeId: undefined,
                          });
                          this.getServiceData(val);
                        }}
                        optionFilterProp="children"
                        // options={this.newMainStore?.maintenanceData}
                        filterOption={(inputValue, option) => {
                          if (option.children) {
                            return (
                              option.children
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            );
                          }
                        }}
                      >
                        {this.newMainStore?.maintenanceData?.map((val) => {
                          return (
                            <Option value={val.maintenanceTypeId}>
                              {val.maintenanceType}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    <Form.Item name=" " label={" "}>
                      <Tooltip
                        // title="Add New Driver"
                        title={this.context.translate("_ADD_NEW_")}
                        placement="bottom"
                        color={config.tooltipColorEdit}
                      >
                        <Button
                          style={{ height: "48px" }}
                          type="primary"
                          onClick={(e) => {
                            this.setState({ openNewMainDetails: true });
                          }}
                        >
                          <PlusOutlined />
                        </Button>
                      </Tooltip>
                    </Form.Item>
                  </Row>

                  <Row
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Form.Item
                      name="serviceTypeId"
                      // label="Driver"
                      label={this.context.translate("_SERVICE_TYPE_")}
                      style={{ width: "80%" }}
                      rules={[{ required: true }]}
                    >
                      <Select
                        value={this.state.serviceId}
                        showSearch
                        // fieldNames={{
                        //  value: "serviceId",
                        //   label: "serviceName",
                        // }} 
                        optionFilterProp="children"
                      //   options={this.newMainStore?.serviceData}
                      // />
                      filterOption={(inputValue, option) => {
                        if (option.children) {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) >= 0
                          );
                        }
                      }}
                    >
                      {this.newMainStore?.serviceData?.map((val) => {
                        return (
                          <Option value={val.serviceId}>
                            {val.serviceName}
                          </Option>
                        );
                      })}
                    </Select>
                    </Form.Item>

                    <Form.Item name=" " label={" "}>
                      <Tooltip
                        // title="Add New Driver"
                        title={this.context.translate("_ADD_NEW_")}
                        placement="bottom"
                        color={config.tooltipColorEdit}
                      >
                        <Button
                          style={{ height: "48px" }}
                          type="primary"
                          onClick={(e) => {
                            this.setState({ openServiceForm: true });
                          }}
                        >
                          <PlusOutlined />
                        </Button>
                      </Tooltip>
                    </Form.Item>
                  </Row>

                  <Form.Item
                    name="serviceDate"
                    label={this.context.translate("_SERVICE_DATE_")}
                    rules={[{ required: true }]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>

                <Col span={12} className="formColumnClass">
                  {this.state.newserviceType && (
                    <Form.Item
                      name="serviceName"
                      label={this.context.translate("_NEW_SERVICE_TYPE_")}
                    >
                      <Input />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="garageName"
                    label={this.context.translate("_GARAGE_NAME_")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="garageAddress"
                    label={this.context.translate("_GARAGE_ADDRESS_")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="billNo"
                    label={this.context.translate("_BILL_NO_")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="extraWorksDone"
                    label={this.context.translate("_EXTRA_WORK_DONE_")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="subTotalAmount"
                    label={this.context.translate("_SUBTOTAL_AMOUNT_")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="discountType"
                    // label="Account Name"
                    label={this.context.translate("_DISCOUNT_TYPE_")}
                  >
                    <Select
                      options={[
                        {
                          value: "1",
                          label: "%",
                        },
                        {
                          value: "2",
                          label: this.context.translate("_FLAT_AMOUNT_"),
                        },
                      ]}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    name="discount"
                    label={this.context.translate("_DISCOUNT_")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="totalAmmount"
                    label={this.context.translate("_TOTAL_AMOUNT_")}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Row>
                    <Button
                      type="primary"
                      onClick={this.handleAddRecord}
                      style={{ margin: "20px 0px 20px 0px" }}
                    >
                      {this.context.translate("_ADD_SERVICE_")}
                    </Button>
                  </Row>

                  <Table dataSource={this.state.tableData} bordered>
                    <Column
                      title={this.context.translate("_Id_")}
                      dataIndex="gId"
                      key="gId"
                      render={(text, record, index) => (
                        <Input
                          value={text}
                          onChange={(e) =>
                            this.handleInputChange(e, index, "gId")
                          }
                        />
                      )}
                    />
                    <Column
                      title={this.context.translate("_ITEM_")}
                      dataIndex="item"
                      key="item"
                      render={(text, record, index) => (
                        <Input
                          value={text}
                          onChange={(e) =>
                            this.handleInputChange(e, index, "item")
                          }
                        />
                      )}
                    />
                    <Column
                      title={this.context.translate("_QUANTITY_")}
                      dataIndex="quantity"
                      key="quantity"
                      render={(text, record, index) => (
                        <Input
                          value={text}
                          onChange={(e) => {
                            this.handleInputChange(e, index, "quantity");
                          }}
                        />
                      )}
                    />
                    <Column
                      // title="Price"
                      title={this.context.translate("_PRICE_")}
                      dataIndex="price"
                      key="price"
                      render={(text, record, index) => (
                        <Input
                          value={text}
                          onChange={(e) => {
                            this.handleInputChange(e, index, "price");
                          }}
                        />
                      )}
                    />

                    <Column
                      title={this.context.translate("_DISCOUNT_TYPE_")}
                      dataIndex="discountTypeGrid"
                      key="discountTypeGrid"
                      render={(text, record, index) => (
                        <Select
                          value={record.discountTypeGrid.toString()}
                          onSelect={async (e, f) => {
                            await this.handleInputChange(
                              e,
                              index,
                              "discountTypeGrid"
                            );
                          }}
                          style={{ width: "80px" }}
                          options={[
                            {
                              value: "1",
                              label: "%",
                            },
                            {
                              value: "2",
                              label: this.context.translate("_FLAT_AMOUNT_"),
                            },
                          ]}
                        ></Select>
                      )}
                    />
                    <Column
                      title={this.context.translate("_DISCOUNT_")}
                      dataIndex="discountGrid"
                      key="discountGrid"
                      render={(text, record, index) => (
                        <Input
                          value={text}
                          onChange={(e) => {
                            this.handleInputChange(e, index, "discountGrid");
                          }}
                        />
                      )}
                    />
                    <Column
                      // title="Gross Price"
                      title={this.context.translate("_GROSS_PRICE_")}
                      dataIndex="grossPrice"
                      key="grossPrice"
                      render={(text, record, index) => (
                        <Input
                          value={text}
                          onChange={(e) =>
                            this.handleInputChange(e, index, "grossPrice")
                          }
                        />
                      )}
                    />
                    <Column
                      // title="Operation"
                      title={this.context.translate("_OPERATION_")}
                      dataIndex=""
                      key=""
                      render={(text, record, index) => (
                        <div>
                          <Space>
                            <Link
                              onClick={async () => {
                                await this.onDeleteRow(index, record);
                                this.setState({ showDeleteModal: true });
                                // console.log(record, text);
                              }}
                            >
                              <Tooltip
                                // title="Delete"
                                title={this.context.translate(
                                  "_FLEET_DASHBORD_RULETRIGGER_DELETE_"
                                )}
                                color={config.tooltipColorDelete}
                              >
                                <DeleteFilled
                                  style={{ color: config.deleteColor }}
                                />
                              </Tooltip>
                            </Link>
                          </Space>
                        </div>
                      )}
                    />
                  </Table>
                </Col>
              </Row>

              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  type="primary"
                  onClick={(e) => {
                    this.closeModal();
                    // this.formRef.current?.resetFields();
                  }}
                >
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
        <Modal
          bodyStyle={{ height: "100%" }}
          className="formShowModal" //
          // title="Driver Details"
          title={this.context.translate("_FLEET_DRIVER_DRIVERDETAIL_TITLE_")}
          centered
          open={this.state.openDriverDetailsForm}
          footer={null}
          onCancel={() => {
            this.setState({ openDriverDetailsForm: false });
          }}
          width={665}
        >
          <DriverAdd
            response={this.responseMsg}
            closeModal={this.closeDriverModal}
          ></DriverAdd>
        </Modal>

        <Modal
          open={this.state.openNewMainDetails}
          title={this.context.translate("_ADD_NEW_MAINTENANCE_")}
          onOk={() => this.setState({ openNewMainDetails: false })}
          onCancel={() => this.setState({ openNewMainDetails: false })}
          footer={false}
        >
          <Form
            name="nest-messages"
            layout="vertical"
            onFinish={this.handleNewMainDetails}
            ref={this.mainFormRef}
            style={{ maxWidth: "100%" }}
          >
            <Form.Item
              name="maintenanceType"
              label={this.context.translate("_MAINTENANCE_TYPE_")}
              rules={[
                {
                  required: true,
                  //   message: this.context.translate("_ENTER_PROJECT_NAME_"),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <div className="formBtnGroup">
              <Button
                className="formCancelButton"
                onClick={() => this.setState({ openNewMainDetails: false })}
              >
                {this.context.translate("_FLEET_DASHBORD_SHOWPOI_CANCELBTN_")}
              </Button>
              <Button
                type="primary"
                className="formSaveButton"
                htmlType="submit"
                loading={this.state.loading}
              >
                {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
              </Button>
            </div>
          </Form>
        </Modal>

        <Modal
          open={this.state.openServiceForm}
          title={this.context.translate("_ADD_NEW_SERVICE_")}
          onOk={() => this.setState({ openServiceForm: false })}
          onCancel={() => this.setState({ openServiceForm: false })}
          footer={false}
        >
          <Form
            name="nest-messages"
            layout="vertical"
            ref={this.serviceFormRef}
            onFinish={this.handleServiceDetails}
            style={{ maxWidth: "100%" }}
          >
            <Form.Item
              name="maintenanceTypeId"
              label={this.context.translate("_MAINTENANCE_TYPE_")}
              rules={[
                {
                  required: true,
                  //   message: this.context.translate("_ENTER_PROJECT_NAME_"),
                },
              ]}
            >
              <Select
                value={this.state.maintenanceTypeId}
                showSearch
                fieldNames={{
                  value: "maintenanceTypeId",
                  label: "maintenanceType",
                }}
                optionFilterProp="children"
                options={this.newMainStore?.maintenanceData}
              />
            </Form.Item>

            <Form.Item
              name="serviceName"
              label={this.context.translate("_SERVICE_TYPE_")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input></Input>
            </Form.Item>

            <div className="formBtnGroup">
              <Button
                className="formCancelButton"
                onClick={() => this.setState({ openServiceForm: false })}
              >
                {this.context.translate("_FLEET_DASHBORD_SHOWPOI_CANCELBTN_")}
              </Button>
              <Button
                type="primary"
                className="formSaveButton"
                htmlType="submit"
                loading={this.state.loading}
              >
                {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
              </Button>
            </div>
          </Form>
        </Modal>

        {/* <Modal
          width={350}
          className="alert-model"
          title=<div>
            {" "}
            <i
              style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
              class="bi bi-trash3"
            ></i>{" "}
            {this.context.translate("_REMOVE_RECORD_")}
          </div>
          open={this.state.showDeleteModal}
          onCancel={() => this.setState({ showDeleteModal: false })}
          footer={[
            <Button
              // key="no"
              onClick={() => this.setState({ showDeleteModal: false })}
            >
              {this.context.translate("_FLEET_RULELIST_CRONRULE_NO_")}
            </Button>,
            <Button
              type="primary"
              onClick={async (e) => {
                await this.onDeleteRow();

                this.setState({
                  showDeleteModal: false,
                });
              }}
            >
              {this.context.translate("_FLEET_RULEDETAIL_ACTIVEDDL_YES_")}
            </Button>,
          ]}
        >
          {this.context.translate("_SURE_TO_DELETE_")}
        </Modal> */}
      </div>
    );
  }
}

VehicleMaintenanceAdd.contextType = StoreContext;
export default observer(withRouter(VehicleMaintenanceAdd));
