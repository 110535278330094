import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class WeightReportStore {
  weightReport = [];
  totalvehstate=[];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      weightReport: observable,
      total: observable,
      loading: observable,
      loadData: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit, weight, pageNo }) {
    this.loading = true;
    console.log("weight Report");
    var postData = {
      companyId: companyId,
      weight: weight,
      usercompany: "1",
      isadmin: "-1",
      offset: offset,
      limit: limit,
      status: "normal",
      vehicleNo: "",
      page: pageNo,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicletracking&a=detailtrackdata",
        "postData=" + base64Input
      );
    console.log("WeightRepost", response);

    this.weightReport = response?.data?.data?.detailtrack;

    this.total = parseInt(response?.data?.data?.TotalCount);
   this.totalvehstate=response?.data?.data?.totalvehstate;

    this.loading = false;
  }
}

export default WeightReportStore;
