import React, { Component } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import MarkerClusterGroup from "react-leaflet-cluster";
import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
} from "react-leaflet";
import config from "../config";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { FullscreenControl } from "react-leaflet-fullscreen";
import { Button, Checkbox, Popover } from "antd";
import AdditionalContentInMap from "./AdditionalContentInMap";
import withRouter from "../withRouter";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
class PolyLineAndMapComponent extends Component {
  constructor(props) {
    super(props);
    this.mapContainerRef = React.createRef();
    this.groupRef = React.createRef();
    this.previousControl = null;
    this.customControl = null;
    this.state = {
      show: false,
      map: null,
      addressInfo: this.props.addressInfo,
      update: 0,
      type: "",
      key: new Date(),
      traffic: false,
      flag: true,
      mapBtn: false,
      satelliteBtn: false,
    };
    // this.iconCreateFunction = (cluster) => {
    //   return L.divIcon({
    //     html: `<div>${cluster.getChildCount()}</div>`,
    //     className: 'marker-cluster-custom',
    //     iconSize: L.point(40, 40),
    //   });
    // };
    // console.log(props)
  }
  // Custom icon for the marker
  dotIcon = L.divIcon({
    className: "dot-icon",
    iconSize: [8, 8],
    html: '<div class="dot" style="background-color: red;"></div>',
  });

  //   componentDidMount() {
  //     if (this.mapContainerRef.current) {
  //       this.mapContainerRef.current = null;
  //     }
  //     // console.log(this.props)
  //   }

  //   componentDidUpdate(prevProps) {
  // //     if (prevProps.position !== this.props.position) {
  // //       const bounds = L.latLngBounds(
  // //         [51.5074, -0.1278], // Southwest corner
  // //         [51.5074, -0.1278],
  // //       );
  // // console.log(bounds)
  // //       if (bounds.isValid()) {
  // //         this.mapContainerRef.current.fitBounds(bounds);
  // //       }
  // //     }

  //     const bounds = L.latLngBounds(
  //       [51.5074, -0.1278], // Southwest corner
  // //         [51.5074, -0.1278],
  //   );

  //   if (bounds._northEast) {
  //       this.mapRef.current.fitBounds(bounds);
  //   }
  //   }

  componentDidUpdate(prevProps) {
    const polyLine = this.props.position.polyLine?.liveLatLongArray;
    const lastElement =
      polyLine && polyLine.length > 0 ? polyLine[polyLine.length - 1] : null;
    const firstElement = polyLine && polyLine.length > 0 ? polyLine[0] : null;

    if (this.mapContainerRef.current && lastElement) {
      const bounds = L.latLngBounds([
        [this.props.position?.latitude, this.props.position?.longitude],
        [lastElement],
      ]);
      this.mapContainerRef.current.fitBounds(bounds, {
        maxZoom: 15, // Adjust this value as needed
      });
    }

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    });
  }
  handleBtnFunc = (state) => {
    this.setState({ [state]: true });
    setTimeout(() => {
      this.setState({ [state]: false });
    }, 1000);
  };
  render() {
    const polyLine = this.props.position?.polyLine?.liveLatLongArray || [];
    // const polyLine = this.props.position.polyLine?.liveLatLongArray;
    // console.log(this.props.position.polyLine.formatted_address[5]);
    // console.log(this.props.position.polyLine.mapImage[0])
    const lastElement =
      polyLine && polyLine.length > 0 ? polyLine[polyLine.length - 1] : null;
    const lastButFifth =
      polyLine && polyLine.length > 0 ? polyLine[polyLine.length - 5] : null;
    const firstElement = polyLine && polyLine.length > 0 ? polyLine[0] : null;

    // console.log(firstElement);
    // console.log(this.props);

    // console.log(this.props.position.latitude, this.props.position.longitude);
    return (
      <>
      <div dir={this.context.locale.language === "arabic" ? "ltr" : "ltr"}  className={
            this.context.locale.language === "arabic"
              ? "arabic-map-container"
              : ""
          }>
      <MapContainer
        className="bigMap"
        id={this.props.position?.id}
        ref={this.mapContainerRef}
        center={[this.props.position?.latitude, this.props.position?.longitude]}
        zoom={15}
        minZoom={3}
        style={{
          position: "absolute",
          height: "85%",
          left: "2px",
          width: this.props.mapWidth ? "96%" : "98%",
          padding: "0px",

          // borderRadius: "0px 0px 5px 5px",
        }}
        zoomControl={false}
        // bounds={[[this.props.position.latitude, this.props.position.longitude],[this.props.position.latitude, this.props.position.longitude]]}
        // bounds={[]}

        bounds={[lastElement, lastButFifth]}
      >
        {this.props.mapType !== "openstreet" ? (
          this.state.type || this.state.traffic ? (
            <ReactLeafletGoogleLayer
              key={this.state.type ? this.state.type : ""}
              type={this.state.type ? this.state.type : "roadmap"}
              googleMapsAddLayers={
                this.state.traffic ? [{ name: "TrafficLayer" }] : null
              }
            />
          ) : (
            <ReactLeafletGoogleLayer
              googleMapsAddLayers={
                this.state.traffic ? [{ name: "TrafficLayer" }] : null
              }
            />
          )
        ) : (
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="Map data © OpenStreetMap contributors"
          />
        )}
        {/* <ReactLeafletGoogleLayer /> */}

        {/* <Marker position={[this.props.position.latitude,this.props.position.longitude]} ></Marker> */}
        {this.props.position?.polyLine?.liveLatLongArray?.map((val, ind) => {
          return (
            <Marker
              key={this.props.position.id}
              position={val}
              icon={L.icon({
                iconUrl: require(`../assets/images/livetracking/${
                  this.props.position.polyLine.mapImage
                    ? this.props.position.polyLine.mapImage[ind]
                    : this.props.position.mapImage
                }`),
                iconSize: [20, 35],
              })}
              currentStatus={this.props.position.currentStatus}
            >
              <Popup>
                {this.props.position?.polyLine?.formatted_address
                  ? this.props.position?.polyLine.formatted_address[ind]
                  : this.props.position?.formatted_address}
              </Popup>
            </Marker>
          );
        })}

        {/* {console.log(this.props.position.polyLine)} */}

        <Polyline
          color="purple"
          positions={
            this.props.position.polyLine
              ? this.props.position.polyLine?.liveLatLongArray
              : []
          }
        ></Polyline>

       

        <AdditionalContentInMap size="small" This={this} />
      </MapContainer>
      </div>
      </>
    );
  }
}
PolyLineAndMapComponent.contextType = StoreContext;
export default observer(withRouter(PolyLineAndMapComponent));
