import React, { useState } from "react";
import config from "../config";



import {
  Button,
 
  Space,
  Row,
  Col,
  Upload,
  Form,
  Modal,
  message,
  Tooltip,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import {
  DeleteFilled,
 
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import SimStore from "../store/SimStore";
import withRouter from "../withRouter";
import DeleteConfirm from "./DeleteConfirm";

import ListTable from "./ListTable";
import SimDetailsAdd from "./SimDetailsAdd";
class SimList extends React.Component {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.simStore = new SimStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      showUpload: false,
      loadData: 0,
      simId: "",
      listDataUpdate: false,
      openSimForm: false,
    };
  }
  async componentDidUpdate(prevProps, prevState) {
    if(Number(this.props.getUserData().isadmin)===1){
      // console.log('innnnnn');
        this.props.navigate("/login");
       
     }
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      await this.simStore.loadData({ offset: 0, limit: 50 });
      await this.setState({ listDataUpdate: false });
    }
  }
  async componentDidMount() {
    // await this.context.companyStore.companyList({ offset: 0, limit: 50 });
    // await this.context.distributorStore.distributorList();
    await this.simStore.loadData({ offset: 0, limit: 50 });
    this.setState({ pageSize: 50 });

    this.columns = [
      {
        // title: "Sim No",
        title:this.context.translate("_FLEET_VEHICLE_VEHICLELIST_SIMNO_"),
        dataIndex: "simNo",
        width: "25%",
        render: (value, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        // title: "Serial No",
        title:this.context.translate("_SERIAL_NO_"),
        dataIndex: "serialNo",
        width: "25%",
      },
      {
        // title: "Service Provider",
        title:this.context.translate("_SERVICE_PROVIDER_"),
        dataIndex: "serviceProvider",
        width: "25%",
      },
      {
        // title: "Status",
        title:this.context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "simstatus",
        width: "20%",
        render: (value, record) => {
          console.log(value);
          if (value === "Used") {
            return (
              <Tooltip title={`${this.context.translate("_VEHICLE_NO_")} ${record.vehicleNo}`} color="green">
                {value}
              </Tooltip>
            );
          } else if (value === "In Stock") {
            return (
              <Tooltip title={`${this.context.translate("_VEHICLE_NO_")} `} color="green">
                {value}
              </Tooltip>
            );
          }
        },
      },
      {
        // title: "Action",
        title:this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        width: "5%",
        // fixed : 'right',
        // width: 50,
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip  placement="bottom"
                  //  title="Edit Record" 
                  title={this.context.translate("_EDIT_RECORD_")}
                   color={config.tooltipColorEdit}>
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                    placement="bottom"
                      // title="Delete Record"
                      title={this.context.translate("_DELETE_RECORD_")}
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  closeModal=()=>{
    this.setState({
      openSimForm: false,
       simId: ""
    });
  }

  onEdit = async (record) => {
    console.log("Id", record.simId);
    // this.props.navigate("/simdetailsadd/" + record.simId);
    await this.setState({ simId: record.simId });
    await this.setState({ openSimForm: true });
  };

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination, "pagi");
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.simStore.loadData({
      offset: this.state.currentPage - 1,
      limit: this.state.pageSize,
    });
    await this.setState({ loadData: 1 });
  }

  async onDelete(record) {
    console.log(record.simId, "record");
    const response = await this.simStore.deleteRecord({
      simId: record.simId,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }
  getUploadData = async (fileUp) => {
   
    let fileinfo = fileUp.file.response;

    console.log(fileinfo);

    if (fileinfo?.success == "S") {
      // console.log(fileinfo.data)

      await this.setState({ excelFile: fileinfo.data });
    }

    this.setState({ update: 1 });
  };

  // validateFile = (file) => {
  //   this.setState({ fileList: file });
  //   console.log(file);
  //   const allowedExtensions = [".xlsx", ".xls"];
  //   const fileName = file.name;
  //   const fileExtension = fileName.slice(
  //     ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
  //   );
  //   if (!allowedExtensions.includes("." + fileExtension.toLowerCase())) {
    
  //     message.error("Only .xlsx and .xls files are allowed");
  //     return false; 
  //   }
    
  //   return true; 
  // };

  onExcelSubmit = async (fieldsValue) => {

    const data = {
      ...fieldsValue,
      companyId: this.state.companyId,
      excelFile: this.state.excelFile,
    };

    const resp = await this.simStore.addSimNoByExcel(data);
    console.log(resp.message);
    if (resp?.success == "S") {
      message.success(resp.message);
      this.setState({ showUpload: false });
      this.setState({ excelFile: "" });
    } else {
      message.error(resp.errors[0].error);
    }
  };

  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.success == "S") {
      message.success(resp.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openSimForm: false });

      //  await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    } else {
      message.error(resp.message);
    }
  };
  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Sim List</div> */}
              <div className="heading">{this.context.translate("_SIM_LIST_")}</div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  title={this.context.translate("_ADD_")}
                  link="/simdetailsadd"
                /> */}
                <Tooltip
                  placement="left"
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({ openSimForm: true, simId: "" })
                    }
                  ></Button>
                </Tooltip>

                
                <Tooltip 
                title= {this.context.translate("_UPLOAD_")}
                color={config.tooltipColorEdit}>
                  <Button
                    type={config.uploadBoxType}
                    onClick={() => this.setState({ showUpload: true })}
                  >
                    <i
                          class="bi bi-upload"
                          style={{ marginRight: "5px" }}
                        ></i>
                    {/* <UploadOutlined /> */}
                  </Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row style={{ padding: "10px 0px" }}>
            <Col span={16}></Col>
            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.simStore?.total}
            columns={this.columns}
            dataSource={this.simStore?.sim}
            loading={this.simStore?.loading}
            onChange={this.handleTableChange}
          />

        {this.state.showUpload && <><Modal
            open={this.state.showUpload}
            // title="File Upload"
            title={this.context.translate("_FILE_UPLOAD_")}
            maskClosable={false}
            onOk={() => this.setState({ showUpload: false })}
            onCancel={() => this.setState({ showUpload: false })}
            footer={false}
            width={400}
          >
            <Form
              name="excelimport"
              layout="vertical"
              onFinish={this.onExcelSubmit}
              style={{ maxWidth: "100%" }}
            >
              <Form.Item
                name="excelFile"
                className="uploadFile"
                // className="label-seprator"
                // style={{ marginTop: "15px" }}
                // label="Excel File"
                label={this.context.translate("_EXCEL_FILE_")}
                rules={[{ required: true }]}
              >
                <Upload
                    // beforeUpload={(file) => this.validateFile(file)}
                    onRemove={()=>this.setState({excelFile:"",fileList:[]})}
                  name="excelFile"
                  action={config.baseURL + "/index.php?c=company&a=fileupload"}
                  listType="text"
                  data={{ fileType: "accountimport", fieldName: "excelFile" }}
                  onChange={this.getUploadData}
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>
                    {/* Click To Upload Excel File */}
                    {this.context.translate("_CLICKTOUPLOAD_EXCELFILE_")}
                  </Button>
                </Upload>
              </Form.Item>
              
              <div className="formBtnGroup">
                {/* css class */}

                <Button
                 className="formCancelButton"
                  // style={{ backgroundColor: config.activeColor }}
                  onClick={() => this.simStore.SampleExcel()}
                >
                   {/* <i
                          class="bi bi-download"
                          style={{ marginRight: "5px" }}
                        ></i> */}
               {this.context.translate("_SAMPLE_")}
                  {/* {this.context.translate("_SAMPLE_EXCEL_")} */}
                </Button>

                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  // loading={this.state.loading}
                >
                  {/* css class */}
                  {/* Upload */}
                  {this.context.translate("_UPLOAD_")}
                </Button>
              </div>

              {/* <Row justify={"end"}>
                <Space style={{ padding: "10px" }}>
                  <Button type="primary" htmlType="submit">
                    Upload
                  </Button>
                </Space>
              </Row> */}
            </Form>
          </Modal></> }

          {this.state.openSimForm && (
            <Modal
              bodyStyle={{ height: "645px" }}
              className="formShowModal" //  {/* css class */}
              // title="Add Sim Details"
              title={this.context.translate("_ADD_SIM_DETAILS_")}
              centered
              visible={this.state.openSimForm}
              footer={null}
              onCancel={() => {
                this.setState({ openSimForm: false, simId: "" });
              }}
              width={665}
            >
              <div>
                <SimDetailsAdd
                  simId={this.state.simId}
                  response={this.responseMsg}
                  closeModal={this.closeModal}
                />
              </div>
            </Modal>
          )}
        </div>
      </>
    );
  }
}
SimList.contextType = StoreContext;
export default observer(withRouter(SimList));
