import React, { useState } from "react";
import { Table } from "antd";

class ListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientHeight: 0,
    };
    // console.log(window.innerHeight )
  }

  componentDidMount() {
    this.setState({ clientHeight: window.innerHeight });

    window.addEventListener("resize", () => {
      // console.log(window.innerHeight);
      this.setState({
        clientHeight: window.innerHeight,
      });
    });
  }

  render() {
    return (
      // <div>
      //   <Table
      //   className="dataTable"
      //     {...this.props}
      //     bordered
      //     size="small"
      //     // scroll={{ y: 'calc(80vh - 218px)' }}
      //     // scroll={{
      //     //   y:500,
      //     //   x: 500,
      //     // }}
      //     scroll={{
      //       y: this.state.clientHeight - 240,
      //       x: 1200,
      //     }}
      //     pagination={
      //       this.props.pagination === false
      //         ? false
      //         : {
      //             current: this.props.currentPage,
      //             pageSize: this.props.pageSize,
      //             total: this.props.total,
      //           }
      //     }
      //   />
      // </div>

      <div className="dataTbl-wrapper" style={{ height: "80vh" }}>
        <Table
          className="list-tbl-height"
          {...this.props}
          bordered
          size="small"
          // dataSource={data}
          // pagination={{
          //   pageSize: 50,
          // }}
          // scroll={{ y: "calc(80vh - 120px)" }}
          scroll={{
            y: this.state.clientHeight - 240,
            x: this.props.scroll?.x ? this.props.scroll?.x : 1200,
          }}
          pagination={
            this.props.pagination === false
              ? false
              : {
                  current: this.props.currentPage,
                  pageSize: this.props.pageSize,
                  total: this.props.total,
                }
          }
        />
      </div>
    );
  }
}

export default ListTable;

// import React from 'react';
// import { Table } from 'antd';
// const columns = [
//   {
//     title: 'Name',
//     dataIndex: 'name',
//     width: 150,
//   },
//   {
//     title: 'Age',
//     dataIndex: 'age',
//     width: 150,
//   },
//   {
//     title: 'Address',
//     dataIndex: 'address',
//   },
// ];
// const data = [];
// for (let i = 0; i < 100; i++) {
//   data.push({
//     key: i,
//     name: `Edward King ${i}`,
//     age: 32,
//     address: `London, Park Lane no. ${i}`,
//   });
// }
// const App = () => (
//   <div className='dataTbl-wrapper' style={{ height: "80vh" }}>
//   <Table
//     columns={columns}
//     // dataSource={data}
//     pagination={{
//       pageSize: 50,
//     }}
//     scroll={{ y: 'calc(80vh - 120px)' }}
//   />
// </div>
// );
// export default App;
