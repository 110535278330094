import { observer } from "mobx-react";
import React, { Component } from "react";
import withRouter from "../withRouter";
import { Button, Col, DatePicker, Empty, Row, Select, Space, Spin } from "antd";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { StoreContext } from "../store/RootStore";
import DistanceGraphStore from "../store/DistanceGraphStore";
import dayjs from "dayjs";

import IdleGraphStore from "../store/IdleGraphStore";
import IdleExIdleGraphComponent from "./IdleExIdleGraphComponent";
import VehicleStore from "../store/VehicleStore";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
// import { Column } from "@ant-design/plots";
const { Option } = Select;

class IdleGraph extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.distanceGraphStore = new DistanceGraphStore(this);
    this.idleGraphStore = new IdleGraphStore(this);
    this.vehicleStore = new VehicleStore(this);

    this.state = {
      selectAllVehicles: false,
      selectedVehicles: "",
      update: 0,
      companyId: "",
      distance: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      loadData: 0,
      showIdleGraph: false,
      displayVehicleNo: "",
      newToDate: dayjs().format("DD-MM-YYYY hh:mm A"),
      newFromDate: dayjs().startOf("day").format("YYYY-MM-DD hh:mmA"),
      // newFromDate: dayjs().format("DD-MM-YYYY 12:00 A"),
      Notcompany: false,
      Notasset: false,
      loadingSpin: false,
    };
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });
    await this.setState({ selectedVehicles: "" });
    await this.vehicleStore.getVehiclesCompanyId(comp.value);

    this.setState({ update: 1 });
  }

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (!this.state.selectedVehicles) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles) {
      // this.setState({mandatory:false})
      await this.setState({ loadingSpin: true });

      this.setState({ Notcompany: false, Notasset: false });
      const data = await this.idleGraphStore.loadIdleGraphData({
        companyId: this.state.companyId,
        vehicleId: this.state.selectedVehicles,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        distance: this.state.distance,
        // pageNo: this.state.pageNo,
      });
      console.log(data);
      this.setState({ loadData: 1 });
      await this.setState({ loadingSpin: false });
    }
  };

  handleDateChange = (date) => {
    if (date === null) {
      this.setState({ fromDate: "" });
    } else {
      // const formattedDate = date.startOf("day");
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({ fromDate: date.format(format) });
      console.log(date.format(format));
      const newFromDate = date;
      const newDateFormat = "DD-MM-YYYY hh:mm A";
      this.setState({ newFromDate: newFromDate.format(newDateFormat) });
    }
  };

  handleToDateChange = (date) => {
    if (date === null) {
      this.setState({ toDate: "" });
    } else {
      const formattedDate = date;
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({ toDate: formattedDate.format(format) });
      console.log(formattedDate.format(format));
      const newToDate = date;
      const newDateFormat = "DD-MM-YYYY hh:mm A";
      this.setState({ newToDate: newToDate.format(newDateFormat) });
    }
  };

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.vehicleStore.getVehiclesCompanyId(
        this.props.getUserData().userCompanyId
      );
      await this.setState({ update: 1 });
    }
  }

  render() {
    return (
      <>
        <Spin spinning={this.state.loadingSpin} fullscreen>
          <div style={{ padding: 0 }}>
            <Row className="headerFixed" style={{ height: "50px" }}>
              <Col span={12}>
                {/* <div className="heading">Idle Graph</div> */}
                <div className="heading">
                  {this.context.translate("_IDLE_GRAPH_")}
                </div>
              </Col>
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>
          </div>
          <div
            style={{
              paddingTop: "10px",
              paddingRight: "24px",
              paddingLeft: "24px",
            }}
          >
            <Row className="listInputRow">
              <Col span={20}>
                <Space>
                  <CompanyAutocomplete
                    onChange={this.handleChange}
                    allowClear={true}
                    style={{ width: 175 }}
                    status={this.state.Notcompany ? "error" : false}
                  />

                  {this.props.getUserData().isadmin === 1 && (
                    <DeviceGroupsForVehicles
                      vehicleStore={this.vehicleStore}
                      setLoadDta={() => this.setState({ loadData: 1 })}
                      resetVehicles={() =>
                        this.setState({
                          selectedVehicles: "",
                        })
                      }
                    />
                  )}

                  <Select
                    value={
                      this.state.selectedVehicles
                        ? this.state.selectedVehicles
                        : null
                    }
                    // onChange={(val) => {
                    //   this.setState({ selectedVehicles: val });
                    //   this.setState({ Notasset: false });
                    // }}
                    onChange={(val, x) => {
                      this.setState({
                        selectedVehicles: val,
                        displayVehicleNo: x.children,
                      });
                    }}
                    style={{ width: 200 }}
                    showSearch
                    status={this.state.Notasset ? "error" : false}
                    placeholder={this.context.translate(
                      "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                    )}
                    filterOption={(inputValue, option) => {
                      if (option.children) {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {this.vehicleStore?.getVehiclesUsingCompanyId?.map(
                      (val) => {
                        return (
                          <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                        );
                      }
                    )}
                  </Select>

                  {/* <Select
                  value={this.state.selectedVehicles}
                  onChange={(val, x) => {
                    this.setState({
                      selectedVehicles: val,
                      displayVehicleNo: x.vehicleNo,
                    });
                  }}
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_")}
                  optionFilterProp="children"
                  options={this.distanceGraphStore?.vehiclesArray}
                  style={{ width: 175 }}
                  status={this.state.Notasset ? "error" : false}
                /> */}
                  <DatePicker
                    showTime
                    format="DD-MM-YYYY hh:mmA"
                    placeholder={this.context.translate("_FROM_DATE_")}
                    //   onChange={this.handleDateChange}
                    value={
                      this.state.fromDate ? dayjs(this.state.fromDate) : null
                    }
                    onChange={(val) => {
                      this.handleDateChange(val);
                    }}
                    disabledDate={(current) => {
                      return current && current > dayjs();
                    }}
                  />
                 
                  <DatePicker
                    showTime
                    format="DD-MM-YYYY hh:mmA"
                    placeholder={this.context.translate("_TO_DATE_")}
                    onChange={this.handleToDateChange}
                    value={this.state.toDate ? dayjs(this.state.toDate) : null}
                    disabledDate={(current) => {
                      return current && current > dayjs();
                    }}
                  />
                  <Button
                    type="primary"
                    onClick={async () => {
                      await this.showData();
                      await this.setState({ showIdleGraph: true });
                      // console.log(this.distanceGraphStore?.distanceGraph);
                    }}
                  >
                    {/* Show */}
                    {this.context.translate("_FLEET_SHOW_BTN_")}
                  </Button>
                </Space>
              </Col>
              <Col span={4}>
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>

            <div
              style={{
                paddingTop: "10px",
                paddingRight: "24px",
                paddingLeft: "24px",
              }}
            >
              {this.state.showIdleGraph &&
                this.idleGraphStore.idleGraph?.length > 0 && (
                  <Row className="listInputRow">
                    <Col span={18}>
                      {/* <div className="heading">Idle Graph</div> */}
                      <div className="heading">
                        {this.context.translate("_IDLE_GRAPH_")}
                      </div>

                      {/* <b>Vehicle No : {this.state.displayVehicleNo}</b> */}
                      <b>
                        {this.context.translate(
                          "_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"
                        )}{" "}
                        : {this.state.displayVehicleNo}
                      </b>
                      <br />
                      {/* <b>From DateTime : {this.state.newFromDate}</b> */}
                      <b>
                        {this.context.translate("_FROM_DATETIME_")} :{" "}
                        {this.state.newFromDate}
                      </b>
                      <br />
                      {/* <b>To DateTime : {this.state.newToDate}</b> */}
                      <b>
                        {this.context.translate("_TO_DATETIME_")} :{" "}
                        {this.state.newToDate}
                      </b>
                      <br />
                    </Col>
                    <Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      <Space
                        style={{
                          float:
                            this.context.locale.direction == "ltr"
                              ? "right"
                              : "left",
                        }}
                      ></Space>
                    </Col>
                  </Row>
                )}
            </div>
            {this.state.showIdleGraph &&
            this.idleGraphStore.idleGraph?.length > 0 ? (
              <IdleExIdleGraphComponent
                data={this.idleGraphStore.idleGraph}
                // yAxisLabel={"Idle Duration(In Min)"}
                yAxisLabel={this.context.translate("_IDLE_DURATION_IN_MINUTE_")}
                graphName={this.context.translate("_IDLE_")}
              />
            ) : (
              <Empty></Empty>
            )}
          </div>
        </Spin>
      </>
    );
  }
}
IdleGraph.contextType = StoreContext;
export default observer(withRouter(IdleGraph));
