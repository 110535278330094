import React, { memo, useState } from "react";
import { GoogleMap } from "@react-google-maps/api";
import Autocomplete from "react-google-autocomplete";
import {
  useLoadScript,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { Button, Card, Modal, Space, Table } from "antd";
import { useRef, useEffect } from "react";
import conf from "../config";
import Draggable from "react-draggable";

import { CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { StoreContext } from "../store/RootStore";

import { useContext } from "react";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: conf.mapCenter[0],
  lng: conf.mapCenter[1],
};

const MeasureDist = (props) => {
  const storeContext = useContext(StoreContext);

  const originRef = useRef();
  const googleMapRef = useRef();
  const destinationRef = useRef();
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionResponse, setDirectonResponse] = useState(null);
  const [clickedMap, setClickedMap] = useState(false);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [latLngs, setLatLngs] = useState([]);
  const [addressText, setAddressText] = useState("");

  const [addressArray, setAddressArray] = useState([]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [markerPosition, setMarkerPosition] = useState([]);
  const [center, setCenter] = useState([]);
  const [data2, setdata2SEt] = useState([54, 87, 6]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: conf.googleMapKey, // Replace with your API key
    libraries: ["places"],
  });

  const columns = [
    {
      // title: "Address",
      title: storeContext.translate("_FLEET_DASHBORD_NEWPOI_ADDRESS_"),
      dataIndex: "address",
      key: "address",
      width: "65%",
      render: (text, record, key) => (
        <Autocomplete
          style={{
            width: "93%",
            height: "33px",
            marginLeft: "10px",

            boxShadow: "none",

            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#d9d9d9",
            borderRadius: "6px",
            paddingLeft:"5px",
            paddingRight:"5px"
          }}
          defaultValue={text}
          onPlaceSelected={async (add) => {
            // console.log(data);
            await handleAddressChange(record, null, add.formatted_address, key);
            //   console.log(data)
          }}
          // onSelect={(e) => console.log(data)}
          //   onChange={(e,w)=>{
          //  console.log(data)
          //     handleAddressChange(record,e,null,key)
          //   }}
        />
      ),
    },
    {
      // title: "Actions",
      title: storeContext.translate("_ACTION_"),
      key: "actions",
      render: (_, record, rowIndex) => (
        <Space size="small">
          <Button onClick={() => handleAddAfterRow(rowIndex)} className="action-btn">
            <PlusOutlined />
          </Button>
          <Button onClick={(x) => handleDeleteRow(record.key)} className="action-btn deleteBtn">
            <DeleteOutlined />
          </Button>
          
        </Space>
      ),
    },
  ];
  useEffect(() => {
    if (data.length) {
      setClickedMap(true);
    }
  }, [data]);

  useEffect(() => {
    setCenter({ lat: conf.mapCenter[0], lng: conf.mapCenter[1] });
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    });
  }, []);
  useEffect(() => {
    // console.log(markerPosition);
    markerPosition.length > 1 && calculateRoute();
  }, [markerPosition]);

 
  //  useEffect(() => {
  //   const calculateRoute = async () => {
    
  //     if (markerPosition.length >= 2) {
  //       const google = window.google;
  //       const directionsService = new google.maps.DirectionsService();
  //       const waypoints = markerPosition
  //         .slice(1, markerPosition.length - 1)
  //         .map((position) => ({
  //           location: position,
  //           stopover: true,
  //         }))
  //         .map((val) => {
  //           return {
  //             location: new google.maps.LatLng(
  //               val.location.lat,
  //               val.location.lng
  //             ),
  //           };
  //         });
        
  //       try {
  //         const result = await directionsService.route({
  //           origin: new google.maps.LatLng(
  //             markerPosition[0].lat,
  //             markerPosition[0].lng
  //           ),
  //           destination: new google.maps.LatLng(
  //             markerPosition[markerPosition.length - 1].lat,
  //             markerPosition[markerPosition.length - 1].lng
  //           ),
  //           travelMode: google.maps.TravelMode.DRIVING,
  //           waypoints: waypoints,
  //         });
          
  //         if (result.status === "OK") {
  //           setDirectonResponse(result);
  //           const legs = result.routes[0].legs;
            
  //           const totalDistance =
  //             legs.reduce((acc, leg) => acc + leg.distance.value, 0) / 1000;
    
  //           const totalDuration = legs.reduce(
  //             (acc, leg) => acc + leg.duration.value,
  //             0
  //           );
    
  //           const totalMinutes = Math.floor(totalDuration / 60);
  //           setDuration(`${totalMinutes} Mins`);
  //           setDistance(totalDistance.toFixed(2) + " Km");
  //         } else {
  //           Modal.warning({
  //             content: "No route found between the origin and destination.",
  //             title: "Alert",
  //           });
  //           // console.log("Calculating route...");
  //           // alert("No route found between the origin and destination.");
  //         }
  //       } catch (error) {
  //         Modal.warning({
  //           content: "An error occurred while calculating the route.",
  //           title: "Alert",
  //         });
  //         // console.error("Error calculating route:", error);
  //         // alert("An error occurred while calculating the route.");
  //       }
  //     }
  //   };
  //   // Invoke calculateRoute when markerPosition changes
  //   markerPosition.length > 1 && calculateRoute();
  // }, [markerPosition]);
  

  const handleAdd = (address) => {
    console.log(address)
    const newData = {
      key: count,
      address: address,
    };
    setData([...data, newData]);
    setCount(count + 1);
  };

  const handleDeleteRow = (key) => {
    let updatedMarkerPositions = markerPosition.filter((val) => val.key !== key);
    setMarkerPosition(updatedMarkerPositions);
  
    if (updatedMarkerPositions.length <=1) {
      // If fewer than two markers, clear the route and reset distance/duration
      setDirectonResponse(null);
      setDistance("");
      setDuration("");
    } else {
      // Otherwise, recalculate the route
      calculateRoute();
    }
  };

  // const handleDeleteRow = (key) => {
  //   // console.log(key)
  //   let x = markerPosition.filter((val) => val.key !== key);
  //   setMarkerPosition(x);
  //   calculateRoute();
  //   // distance
  //   // setMarkerPosition([])
  //   setDirectonResponse(null);

  //   setDistance("");
  //   setDuration("");
  // };

  // const handleAddAfterRow =async (rowIndex) => {
  //   console.log(rowIndex)
  //   const newData = {
  //     key: new Date(),
  //     address: "",
  //   };

  //   await setMarkerPosition((prvState)=> {
  //     const updatedData = [...markerPosition];
  //     markerPosition.splice(rowIndex + 1, 0, newData);
  //     return updatedData
  //    });

  //  console.log(data)

  // };

  const handleAddAfterRow = (rowIndex) => {
    const newMarkerPosition = {
      lat: 0, // Set the default latitude
      lng: 0, // Set the default longitude
      address: "", // Set the default address
      key: new Date(),
    };

    // Insert the new marker position at the specified index in markerPosition
    const updatedMarkerPosition = [...markerPosition];
    updatedMarkerPosition.splice(rowIndex + 1, 0, newMarkerPosition);
    setMarkerPosition(updatedMarkerPosition);
  };

  const handleAddressChange = (record, e, address, key) => {
    const updatedData = data.map((val, ind) => {
      if (ind === key) {
        if (address) {
          val.address = address;
        } else if (e) {
          val.address = e.target.value;
        }
        return val;
      }
      return val;
    });

    return updatedData;
  };

  const handleInputChange = (e, index, fieldName) => {
    const newData = [...this.state.tableData];
  };

  if (!isLoaded) {
    return <h1>adsf</h1>;
  }



  const calculateRoute = async () => {
    // console.log("Calculating route...");
    // console.log("Marker position length:", markerPosition.length);
    if (markerPosition.length >= 2) {
      const google = window.google;
      const directionsService = new google.maps.DirectionsService();
      // console.log(directionsService)
      const waypoints = markerPosition
        .slice(1, markerPosition.length - 1)
        .map((position) => ({
          location: position,
          stopover: true,
        }))
        .map((val) => {
          return {
            location: new google.maps.LatLng(
              val.location.lat,
              val.location.lng
            ),
          };
        });
     
      const result = await directionsService.route({
        // origin: markerPosition[0],
        // destination: markerPosition[markerPosition.length - 1],
        // travelMode: google.maps.TravelMode.DRIVING,
        origin: new google.maps.LatLng(
          markerPosition[0].lat,
          markerPosition[0].lng
        ),
        destination: new google.maps.LatLng(
          markerPosition[markerPosition.length - 1].lat,
          markerPosition[markerPosition.length - 1].lng
        ),
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: waypoints,
      });
    
      if (result.status === "OK") {
        setDirectonResponse(result);
        const legs = result.routes[0].legs;
        
        const totalDistance =
          legs.reduce((acc, leg) => acc + leg.distance.value, 0) / 1000; // Convert to kilometers
        // console.log(totalDistance)

        const totalDuration = legs.reduce(
          (acc, leg) => acc + leg.duration.value,
          0
        ); // in seconds
        // console.log(totalDuration)
        // const hours = Math.floor(totalDuration / 3600);
        // const minutes = Math.floor((totalDuration % 3600) / 60);
        // setDuration(`${minutes} Mins`);

        const totalMinutes = Math.floor(totalDuration / 60); // Total duration in minutes
        setDuration(`${totalMinutes} Mins`);
        setDistance(totalDistance.toFixed(2) + " Km"); // Round to 2 decimal places
      } else {
        console.error("Directions request failed due to " + result.status);
        // console.log("Calculating route...");
        // console.log("Marker position length:", markerPosition.length);
        // alert("No route found between the origin and destination.");
      }
    }
  };

  const clearMap = () => {
    setDistance("");
    setDuration("");
    originRef.current.value = "";
    // destinationRef.current.value = "";
    setDirectonResponse(null);
  };

  const getCurrentLocation = async (event) => {
    console.log("call", event);
    let address;

    const geocoder = await new window.google.maps.Geocoder();
    console.log(geocoder)
    await geocoder.geocode(
      { location: event.latLng },
      async (results, status) => {
        console.log(results[0].formatted_address);
        address = results[0].formatted_address;
        handleAdd(results[0].formatted_address);
      }
    );

    const newMarkerPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
      address: address,
      key: new Date(),
    };
    // console.loadData(newMarkerPosition)

    await setMarkerPosition((prevState) => [...prevState, newMarkerPosition]);
  };

  return (
    <>
      <GoogleMap
        onClick={(e) => getCurrentLocation(e)}
        mapContainerStyle={containerStyle}
        ref={googleMapRef}
        center={center}
        zoom={12.5}
        // zoom={13}
        onLoad={(map) => {
          googleMapRef.current = map; // Set the ref
          setMap(map); // Set the map state
        }}
        options={{
          zoomControl: true,
          streetViewControl: false,
          mapTypeControl: false,
          zoomControlOptions: true,
          scrollwheel: true,
          fullscreenControl: false,
          scaleControl: true,
        }}
      >
        {/* {/* {markerPosition?.map((position, index) => (<> */}
        {/* <Marker position={markerPosition[0]} /> */}
        {/* ))} */}
        {markerPosition.map((marker) => (
          // console.log(marker.key)
          <Marker
            key={marker.key}
            position={{ lat: marker.lat, lng: marker.lng }}
            // onClick={() => setSelectedMarker(marker.key)} // Select the marker for deletion on click
          />
        ))}

      {directionResponse && (
          <DirectionsRenderer directions={directionResponse} />
       )}
      </GoogleMap>

      <Draggable handle=".handle">
        <Card
          className="right-menu-panel headerGroup-panel"
          style={{ minHeight: "350px", insetInlineStart: "5%" }}
          // title={<h1 style={{ alignItems: "center" }}>Distance Measure</h1>}
          title={
            <div
              className="handle"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {/* <div>Distance Measure</div>  */}
              <div>{storeContext.translate("_DISTANCE_MEASUREMENT_")}</div>

              <CloseOutlined
                onClick={async () => await props.closeFun(false)}
              />
            </div>
          }
        >
          {/* { !clickedMap&& <p>Please click on map to select locations.</p>} */}
          {!clickedMap && (
            <p>
              {storeContext.translate("_PLEASE_CLICK_MAP_TO_SELECT_LOCATIONS_")}
            </p>
          )}
          {clickedMap && (
            <>
              <Table
                bordered
                scroll={{ x: 300, y: 280 }}
                dataSource={markerPosition}
                columns={columns}
                rowKey={(record) => record.key} // Set the row key to the 'key' property of the record
                onRow={(record, rowIndex) => ({
                  onClick: () => setSelectedRowIndex(rowIndex + 1),
                })}
                pagination={false}
              />

              <div className="distance-total-block">
                <p>
                  <b>
                    {storeContext.translate(
                      "_FLEET_CREATEROUTE_ROUTEADD_TOTALDISTANCE_"
                    )}
                    :
                  </b>{" "}
                  {distance}
                </p>
                <p>
                  <b>
                    {storeContext.translate(
                      "_FLEET_CREATEROUTE_ROUTEADD_TOTALTIME_"
                    )}{" "}
                    :
                  </b>{" "}
                  {duration}
                </p>
              </div>
            </>
          )}
        </Card>
      </Draggable>
    </>
  );
};

export default memo(MeasureDist);
