import { Button, Checkbox, Form, Input } from 'antd';


const Home = () => {
  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div >
        Welcome...
    </div>
  );
};

export default Home;