import {useParams,useNavigate,useLocation} from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Cookies from "js-cookie";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { useForm } from 'antd/es/form/Form';


function withRouter( Child ) {
  return ( props ) => {
   
    const params = useParams();
    const navigate = useNavigate();
    const form = useForm();


	const location = useLocation();
  const [cookies,setCookie,removeCookie] = useCookies();
	
 let isSuperAdmin;

 isSuperAdmin = function ( cookies ) {

      let isSAdmin= false;
      try
      {
        let data = JSON.parse (base64_decode(cookies.data)) ;
      
        if(data.isSuperAdmin==1)
          isSAdmin= true;

      }
      catch(ex)
      {

      }
      

      return isSAdmin;

 }

 let getUserData;

 getUserData = function ( cookies ) {

      try {
      //  console.log(Cookies)
        let data = JSON.parse(base64_decode(Cookies.get("data")))
      
        return data;

      }
      catch(ex){

      }

      return null;

 }

 let setUserData;

 setUserData = function ( cookiesData ) {

      try {
         
        let encoded = base64_encode(JSON.stringify(cookiesData));
        setCookie("data",encoded,{path:"/"});

      }
      catch(ex){

      }

      return null;

 }
   

  return <Child { ...props } setUserData = { setUserData } getUserData = { getUserData } isSuperAdmin ={ isSuperAdmin } params ={ params } navigate={navigate} location={location} cookies={cookies} setsetCookie={setCookie}
  removeCookie={removeCookie} form={form} />;
  }
}


export default withRouter;