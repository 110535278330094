import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import { toDate } from "date-fns";
import conf from "../config";

class StationStore {
  StationListArray = [];
  cityCodeArray = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      StationListArray: observable,
      cityCodeArray: observable,
      // total:observable,
      // loading:observable,
      loadData: action,
      getCityCode: action,
      addRegistration:action,
      exportExcel:action
    });
    this.rootStore = rootStore;
  }

  async loadData({ offset, limit, stationCode }) {
    this.loading = true;
    var postData = { offset: offset, limit: limit, stationCode: stationCode };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=station&a=stationList", "postData=" + base64Input);
    console.log("track ", response?.data?.data?.completereport);
    this.StationListArray = response?.data?.company;
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async getCityCode() {
    var postData = {};
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=station&a=getWaslCity", "postData=" + base64Input);
    console.log(response);
    this.cityCodeArray = response?.data?.data;
    this.total = parseInt(response?.data?.data?.TotalCount);
  }

  async addRegistration(data){
    console.log(data)
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=station&a=registerStation", "postData=" + base64Input);
    console.log(response);
    
    // this.total = parseInt(response?.data?.data?.TotalCount);
    return response
  }

  async exportExcel(getData){
    
    // window.location.href = conf.baseURL + "index.php?c=company&a=exportCompanyExcel&dType=" +getData.dType+ '&isadmin='+getData.isadmin+ '&cid='+getData.cid+ '&cType='+getData.cType +'&cName='+getData.cName + '&email='+getData.email+ '&colList='+getData.colList
    window.location.href = conf.baseURL + "index.php?c=station&a=exportwaslstationexcel&stationCode="+getData.stationCode +'&colList='+getData.colList
  
  }


}

export default StationStore;
