import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class TaxiCustomersStore {
  loading = false;
  total = 0;
  taxiCustList = [];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      total: observable,
      taxiCustList: observable,
      loadData: action,
      deleteRecord: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ offset, limit, page }) {
    this.loading = true;
    var postData = {
      offset: offset,
      limit: limit,
      page: page,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=traxi_vehicle&a=getTraxiCustomer",
        "postData=" + base64Input
      );

    this.taxiCustList = response?.data?.data?.traxiCustomerData;
    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
  }

  async deleteRecord({ userId }) {
    const postData = {
      userId: userId,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=traxi_vehicle&a=deleteTraxiCustomer",
        "postData=" + base64Input
      );

    return response;
  }
}

export default TaxiCustomersStore;
