import { decode as base64_decode, encode as base64_encode } from "base-64";
import { postApi, baseURL } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class PassengerLogReportStore {
  passengerLogReport = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      passengerLogReport: observable,
      total: observable,
      loading: observable,
      loadData: action,
      exportExcel: action,
      exportpdf:action,
      exportcsv:action,
      exportxml:action
    });
    this.rootStore = rootStore;
  }

  async loadData({ offset, limit, companyId, fromDate, toDate, vehicleId }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      offset: offset,
      PickDrop: "All",
      limit: limit,
      page: 1,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=passengerlog", "postData=" + base64Input);

    this.passengers = response?.data?.data?.passengerLog;

    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async exportExcel(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportpasslogexcel&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportpasslogpdf&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }

  async exportcsv(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportpasslogcsv&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportxml(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportpasslogxml&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }



}

export default PassengerLogReportStore;
