import React, { useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  InputNumber,
  message,
  Select,
  Table,
  Button,
  Typography,
  Space,
  DatePicker,
  Tooltip,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  EditFilled,
  DeleteFilled,
  FileOutlined,
} from "@ant-design/icons";
import DeleteConfirm from "./DeleteConfirm";
import ListTable from "./ListTable";
import AddButton from "./AddButton";
import CompanyAutocomplete from "./CompanyAutocomplete";

import config from "../config";
import VehicleGroupStore from "../store/VehicleGroupStore";
import withRouter from "../withRouter";
import VehicleGroupAdd from "./VehicleGroupAdd";

class VehicleGroupList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.vehicleGroupStore = new VehicleGroupStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      companyId: "",
      loadData: 0,
      listDataUpdate: false,
      openGroupAddFom: false,
      vehicleGroupId: "",
    };
  }
  onEdit = (record) => {
    console.log(record);
    // this.props.navigate("/vehiclegroupadd/" + record.deviceGroupId);
    this.setState({ openGroupAddFom: true });
    this.setState({ vehicleGroupId: record.deviceGroupId });
  };

  async onDelete(record) {
    console.log(record.deviceGroupId, "record");
    const response = await this.vehicleGroupStore.deleteRecord({
      deviceGroupId: record.deviceGroupId,
    });
    //  console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
    }
  }
  async componentDidMount() {
    // await this.vehicleGroupStore.loadData({ offset: 0, limit: 50 });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId)
      await this.setState({ companyId: this.props.getUserData().userCompanyId })
      await this.setState({ userId: this.props.getUserData().userId })
      await this.handleSearch();
    }
    this.setState({ pageSize: 50 });

    this.columns = [
      {
        // title: "  Device",
        title: this.context.translate("_DEVICE_"),
        dataIndex: "groupname",
        width: "80%",
        render: (value, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              <FileOutlined /> &nbsp;{value}
            </Link>
          );
        },
        // render: (val) => {
        //   return (
        //     <>
        //       <FileOutlined />
        //       &nbsp;{val}
        //     </>
        //   );
        // },
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        // fixed: 'right',
        width: "20%",
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip 
                  // title="Edit"  
                  title={this.context.translate("_FLEET_COMPANY_COMPANYLIST_EDIT_")}
                  placement="bottom" color={config.tooltipColorEdit}>
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      // title="Remove Device"
                      title={this.context.translate("_FLEET_GROUP_GROUPLIST_DELETE_")}
                      color={config.tooltipColorDelete}
                      placement="bottom"
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  async handleTableChange(pagination, filters, sorter) {
    // console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value,pageSize:50,currentPage:1 });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.vehicleGroupStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      // searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadData: 1 });
  }
  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.data.success == "S") {
      message.success(resp.data.message);
      this.setState({ openGroupAddFom: false });
      this.setState({ listDataUpdate: true });
      await this.handleSearch();
      // this.props.navigate("/vehiclegrouplist");
    } else if (resp.data.success == "F0") {
      resp.data.errors.map((value) => {
        message.error(this.context.translate(value["dgerror"]));
      });
    }
  };

  closeModal=()=>{
    this.setState({ openGroupAddFom: false, vehicleGroupId: "" });
  }

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_FLEET_GROUP_GROUPLIST_TITLE_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  title={this.context.translate("_ADD_")}
                  link="/vehiclegroupadd"
                /> */}
                   <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  placement="left"
                  color={config.primaryColor}
                >
                <Button
                  className="addButton"
                  icon={<PlusOutlined />}
                  type="primary"
                  onClick={() =>
                    this.setState({ openGroupAddFom: true, vehicleGroupId: "" })
                  }
                ></Button></Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  // allowClear={true}
                  onChange={(value) => this.handleChange(value)}
                  style={{ width: 200 }}
                />
              </Space>
            </Col>
            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.vehicleGroupStore?.total}
            columns={this.columns}
            dataSource={this.vehicleGroupStore?.vehicleGroupVehicles}
            loading={this.vehicleGroupStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openGroupAddFom && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Device Details"
            title={this.context.translate("_FLEET_GROUP_GROUPDETAIL_TITLE_")}
            centered
            visible={this.state.openGroupAddFom}
            footer={null}
            onCancel={() => {
              this.setState({ openGroupAddFom: false, vehicleGroupId: "" });
            }}
            width={665}
          >
            <div>
              <VehicleGroupAdd
                vehicleGroupId={this.state.vehicleGroupId}
                companyId={this.state.companyId}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
VehicleGroupList.contextType = StoreContext;
export default observer(withRouter(VehicleGroupList));
