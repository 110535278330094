import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
  observe,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class GradeTimingStore {
  gradeTiming=[]
  getClass=[]
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
      getClass:observable,
        gradeTiming: observable,
      total:observable,
      loading:observable,
      loadData:action,
      deleteRecord:action,
      addData:action,
      getAllClass:action,
     
    });
     this.rootStore = rootStore;
    
  }

  async getCompany({companyName}){

    var postData = {"name":companyName}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=company&a=searchCompany", "postData="+base64Input);
    console.log(response);

  }
  async loadData({offset,limit,searchField,companyId}) {
    
    this.loading = true;
  
    var postData = {"companyId":companyId,"isadmin":"-1","deviceGrpOffSet":0,offset:offset,limit:limit,"deviceGrpSize":10}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=grade&a=getgradeList", "postData="+base64Input);
    // console.log("gradetimeing",response.data.data.grades)
    this.gradeTiming=response?.data?.data?.grades
    console.log(response?.data?.data?.grades)
    // console.log(response?.data?.data.grades,"dd")
     this.total = parseInt(response?.data?.data?.TotalCount);
   
    this.loading=false;
  }
  async deleteRecord({grade_Id}){
    console.log(grade_Id,"dd")
    var postData = {"tokenId":"","gradeTime":{"grade_Id":grade_Id}}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=grade&a=delete", "postData="+base64Input);
    return response;
  }

  async addData(gradeDetails){
    var postData ={gradeTime:gradeDetails}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=grade&a=gradeUpdate", "postData="+base64Input);
    console.log(response)
    return response.data;

  }
  async getGradeTimingData({grade_Id}){
    console.log(grade_Id,"eee")
    var postData = {grade_Id:grade_Id,limit:1,offset:0}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=grade&a=getgradeList", "postData="+base64Input);
    console.log("gradetimeindfdfdfdfg",response.data.data.grades[0])
    return response.data.data.grades[0]
    
    
  }

  
  async getAllClass() {
    // console.log("vehicle list")
    var postData = null;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=notification&a=getAllClass",
        "postData=" + base64Input
      );
    // console.log(response?.data?.data, "post")
    
    this.getClass =response?.data?.data;
    return response?.data?.data;
  }
  
  

}

export default GradeTimingStore;
