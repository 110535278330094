import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
  observe,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class SchoolCalenderStore {
  schoolCalender=[]
  // users=[]
  // drivers=[]
  // staff=[]
  // passengers=[]
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        schoolCalender: observable,
      total:observable,
      loading:observable,
      loadData:action,
      deleteRecord:action,
      addData:action,
      getSchoolCalender:action

     
    });
     this.rootStore = rootStore;
    
  }
  async getCompany({companyName}){

    var postData = {"name":companyName}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=company&a=searchCompany", "postData="+base64Input);
    console.log(response);

  }
  async loadData({offset,limit,searchField,companyId}) {
    
    this.loading = true;

    var postData = {"companyId":companyId}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=calender&a=getcalender", "postData="+base64Input);
    
     console.log("schoold Data",response?.data?.data)
    this.schoolCalender = response?.data?.data
    //  this.setCompanies(response?.data?.data?.companydata);
     this.total = parseInt(response?.data?.data?.TotalCount);
    // console.log(this.total);
    this.loading=false;
  }
  async deleteRecord({calenderId}){
    var postData = {"calenderId":calenderId}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=calender&a=delete", "postData="+base64Input);
    return response;
  }

  async addData(schoolCalenderData){
    var postData ={"tokenId":"80bovn8u800tg1aq9bvr73kbhh",schoolCal:schoolCalenderData}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=calender&a=update", "postData="+base64Input);
    console.log(response)
    return response.data;

  }

  async getSchoolCalender(calendarId){
   console.log(calendarId)
    var postData = {"calendarId":calendarId,limit:1,offset:0}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=calender&a=getcalender", "postData="+base64Input);
    console.log(response.data.data[0],"")
    return response.data.data[0]
  }

  

  
  

}

export default SchoolCalenderStore;
