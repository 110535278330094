import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi,baseURL } from "../components/Utility";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from "mobx";
import { act } from "react-dom/test-utils";

class GeofenceSummaryStore {
  total ="";
  geofenceSummaryStore = [];
  geofenceSummary=[];
  rootStore;
  loading=false
  loadingData=false

  constructor(rootStore) {
    makeObservable(this, {
      total: observable,
      geofenceSummaryStore: observable,
      loadingData:observable,
      loadData: action,
      exportpdf:action,
      exportExcel:action,
      exportcsv:action,
      exportxml:action,
      loading:observable,
      geofenceSummary:observable,
      loadGeofenceSummaryData:action



    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, vehicleId, offset, limit, fromDate, toDate }) {
    console.log("vehicle list");
    this.loading=true
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      offset: offset,
      limit: limit,
      page: 1,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=geofencesummary", "postData=" + base64Input);

    console.log(response?.data?.data?.geofencesummary);
    this.geofenceSummaryStore = response?.data?.data?.geofencesummary;
    this.total = parseInt(response?.data?.data?.TotalCount);
    console.log(this.total)
    this.loading=false
  }


  async loadGeofenceSummaryData({ companyId, vehicleId, offset, limit, fromDate, toDate }) {
    console.log("vehicle list");
    this.loadingData=true
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=geosummtotal", "postData=" + base64Input);

    console.log(response?.data?.data);
    this.geofenceSummary = response?.data?.data;
    // this.total = parseInt(response?.data?.data?.TotalCount);
    // console.log(this.total)
    this.loadingData=false
  }

  
  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportgeosumexcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      // "&int=" +
      // getData.intervel +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportgeosumpdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      // "&int=" +
      // getData.intervel +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportcsv(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportgeosumcsv&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      // "&int=" +
      // getData.intervel +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exportxml(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportgeosumxml&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      // "&int=" +
      // getData.intervel +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
}

export default GeofenceSummaryStore;
