import { encode as base64_encode } from "base-64";
import { postApi, baseURL } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import moment from "moment";

class TrackDataStore {
  trackData = [];
  total = 0;
  loading = false;
  rootStore;
  // rec = 0;

  constructor(rootStore) {
    makeObservable(this, {
      trackData: observable,
      // rec: observable,
      total: observable,
      loading: observable,
      loadData: action,
      // exportData:action,
      exportExcel: action,
      exportcsv: action,
      exportpdf: action,
      exportxml: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    companyId,
    offset,
    limit,
    vehicleId,
    fromDate,
    toDate,
    filter,
    intervel,
    pageNo,
  }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      intervel: intervel,
      filter: filter,
      offset: offset,
      limit: limit,
      page: pageNo,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=completereport", "postData=" + base64Input);

    this.total = parseInt(response?.data?.data?.TotalCount);

    var liveTrackingData = response?.data?.data?.completereport;

    Object.keys(liveTrackingData).map((e, i) => {
      let r = liveTrackingData[i];
      // console.log(r.updatedate,r.currtime)
      //  var diff = new Date(r.currtime) - new Date(r.updatedate);
      const diff =( moment(r.currtime).diff(r.updateDate, "minutes"));
      console.log(diff)
      var vehicleTypeNew = r.vehicleTypeNew.split(" ").join("-");
      // if (diff >= 90) {
      //   liveTrackingData[i].listImage = vehicleTypeNew + "-gray.png";
      //   liveTrackingData[i].rowBackground = "gray";
      //   liveTrackingData[i].currentStatus = "nodata";
      // } else 
      if (r.acc == 1 && r.speed == 0) {
        //idle)
        liveTrackingData[i].listImage = vehicleTypeNew + "-yellow.png";
        liveTrackingData[i].rowBackground = "yellow";
        liveTrackingData[i].currentStatus = "idle";
      } else if (r.acc == 0) {
        liveTrackingData[i].listImage = vehicleTypeNew + "-red.png";
        liveTrackingData[i].rowBackground = "red";
        liveTrackingData[i].currentStatus = "stop";
      } else if (r.acc == 1 && r.speed > 0) {
        liveTrackingData[i].listImage = vehicleTypeNew + "-green.png";
        liveTrackingData[i].rowBackground = "green";
        liveTrackingData[i].currentStatus = "running";
      }
      liveTrackingData[i].mapImage = liveTrackingData[i].listImage;

      try {
        require("../assets/images/detailtracking/" +
          liveTrackingData[i].listImage);
      } catch (err) {
        liveTrackingData[i].listImage = "car-icon-blue.png";
      }

      try {
        require("../assets/images/livetracking/" +
          liveTrackingData[i].mapImage);
      } catch (err) {
        liveTrackingData[i].mapImage = "car-icon-blue.png";
      }
    });

    this.trackData = liveTrackingData;

    this.loading = false;
  }

  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportcrexcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.intervel +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportcrpdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.intervel +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportcsv(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportcrcsv&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.intervel +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exportxml(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportcrxml&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.intervel +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exporttemppdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportcrTempPdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.intervel +
      "&filter=" +
      getData.filter +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportweightpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportcrWeightPdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.intervel +
      "&filter=" +
      getData.filter +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&userId=" +
      1 +
      "&language=" +
      lang;
  }
}

export default TrackDataStore;
