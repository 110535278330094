import React from "react";
import {
  Form,
  Row,
  Col,
  Input,
  message,
  Button,
  Space,
  DatePicker,
  Tooltip,
  Modal,
  Upload,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";

import {
  DeleteFilled,
  PlusOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import CompanyAutocomplete from "./CompanyAutocomplete";

import ListTable from "./ListTable";

import VehicleMaintenanceStore from "../store/VehicleMaintenanceStore";
import withRouter from "../withRouter";
import CompanyStore from "../store/CompanyStore";
import VehicleMaintenanceAdd from "./VehicleMaintenanceAdd";

class VehicleMaintenanceList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.maitenanceStore = new VehicleMaintenanceStore();
    this.companyStore = new CompanyStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      offset: 0,
      limit: 50,
      companyId: "",
      loadData: 0,
      openConfirm: false,
      confirmLoading: false,
      company_value_label: "",
      searchField: "",
      searchFieldDate: "",
      openVehicleMaintenanceForm: false,
      vehicleMaintenanceId: "",
      listDataUpdate: false,
      companyFieldHighlight: false,
      excelCompanyId: "",
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const beforeEditCompanyId =
        this.context.viewStateStore.VehicleMaintenanceObject.companyId;
      await this.maitenanceStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: beforeEditCompanyId,
        searchField: this.state.searchField,
      });
      const singleData = await this.companyStore.getCompanyDataById(
        this.context.viewStateStore.VehicleMaintenanceObject.companyId
      );
      await this.setState({ listUpdated: 1 }); //need to reload data
      await this.setState({
        company_value_label: singleData,
        companyId: beforeEditCompanyId,
      });
      this.setState({ listDataUpdate: false });
    }
  }

  async componentDidMount() {
    // await this.maitenanceStore.loadData({ offset: 0, limit: 50 });
    if (
      Number(this.props.getUserData().isadmin) !== -1 ||
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        excelCompanyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleSearch();
    }

    this.setState({ pageSize: 50 });

    this.columns = [
      {
        // title: " Vehicle No",
        title: this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        width: "20%",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        // title: "Driver Name",
        title: this.context.translate("_DRIVER_NAME_"),
        dataIndex: "name",
        width: "20%",
      },
      {
        // title: "Current Odometer ",
        title: this.context.translate("_CURRENT_ODOMETER_"),
        dataIndex: "currentOdometer",
        width: "20%",
      },
      {
        // title: " Garage Name",
        title: this.context.translate("_GARAGE_NAME_"),
        dataIndex: "garageName",
        width: "20%",
      },
      {
        // title: "Service Date",
        title: this.context.translate("_SERVICE_DATE_"),
        dataIndex: "serviceDate",
        width: "20%",
      },
      // {
      //   // title: "Service Type ",
      //   title:this.context.translate("_SERVICE_TYPE_"),
      //   dataIndex: "serviceName",
      //   width: "20%",
      // },
      {
        // title: " Garage Address ",
        title: this.context.translate("_GARAGE_ADDRESS_"),
        dataIndex: "garageAddress",
        width: "20%",
      },
      {
        // title: "  Amount ",
        title: this.context.translate("_AMOUNT_"),
        dataIndex: "totalAmmount",
        width: "20%",
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        // fixed: 'right',
        width: "10%",
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    // title="Edit Maintenance"
                    title={this.context.translate("_EDIT_MAINTENANCE_")}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      // title="Delete Maintenance"
                      title={this.context.translate("_DELETE_MAINTENANCE_")}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      currentPage: 1,
      pageSize: 50,
    });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ company_value_label: singleData });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.maitenanceStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
      searchFieldDate: this.state.searchFieldDate,
    });
    await this.setState({ loadData: 1 });
  }

  onEdit = async (record) => {
    await this.context.viewStateStore.setVehicleMaintenanceStates({
      companyId: record.companyId,
    });
    this.setState({ vehicleMaintenanceId: record.Id });
    this.setState({ openVehicleMaintenanceForm: true });
    // await this.props.navigate("/vehiclemaintenanceadd/" + record.Id);
  };

  async onDelete(record) {
    const response = await this.maitenanceStore.deleteRecord({
      Id: record.Id,
    });
    //  console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  searchInput = async (e) => {
    const value = e.target.value;
    if (value.length > 2 || value.length === 0) {
      await this.maitenanceStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        searchFieldDate: this.state.searchFieldDate,
        searchField: value,
        companyId: this.state.companyId,
      });
      await this.setState({ loadList: 0 });
    }
  };

  async searchInputButton() {
    if (!this.state.companyId) {
      this.setState({ companyFieldHighlight: true });
    } else {
      this.setState({ companyFieldHighlight: false });
    }
    if (this.state.companyId) {
      this.setState({ companyFieldHighlight: false, loading: true });
      await this.maitenanceStore.loadData({
        offset: 0,
        limit: 50,
        searchField: this.state.searchField,
        searchFieldDate: this.state.searchFieldDate,
        companyId: this.state.companyId,
      });
      await this.setState({ loadData: 1 });
    }
  }

  responseMsg = async (resp) => {
    if (resp.data.success == "S") {
      message.success(resp.data.message);
      this.setState({ openVehicleMaintenanceForm: false });
      this.setState({ listDataUpdate: true });
      //  this.props.navigate("/vehiclemaintenancelist");
    } else if (resp.data.success == "F0") {
      resp.data.errors.map((value) => {
        message.error(["This Service Type already exists"]);
      });
    }
  };

  closeModal = () => {
    this.setState({
      openVehicleMaintenanceForm: false,
      vehicleMaintenanceId: "",
    });
  };

  getUploadData = async (fileUp) => {
    // console.log(fileUp.file.name)
    let fileinfo = fileUp.file.response;

    console.log(fileinfo);

    if (fileinfo?.success == "S") {
      // console.log(fileinfo.data)

      await this.setState({ excelFile: fileinfo.data });
    }

    this.setState({ update: 1 });
  };

  onExcelSubmit = async (fieldsValue) => {
    const data = {
      ...fieldsValue,
      companyId: this.state.excelCompanyId,
      excelFile: this.state.excelFile,
    };
    const resp = await this.maitenanceStore.addVehMainByExcel(data);

    if (resp?.success == "S") {
      console.log(resp.data.length > 0);
      message.success(resp.message);

      this.setState({ showUpload: false });
      this.setState({ excelFile: "" });
      this.handleSearch();
      // this.formRef.current?.resetFields();
    } else {
      message.error(resp.errors[0].error);
    }
    if (resp.message) {
      this.setState({ showUpload: false });
      this.formRef.current?.resetFields();
    }
  };
  render() {
    const companyInputBox = this.state.companyFieldHighlight
      ? { border: "2px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_MAINTENANCE_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  title={this.context.translate("_ADD_")}
                  link="/vehiclemaintenanceadd"
                /> */}
                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  placement="left"
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({
                        openVehicleMaintenanceForm: true,
                        vehicleMaintenanceId: "",
                      })
                    }
                  ></Button>
                </Tooltip>
                <Tooltip
                  // title="Upload"
                  title={this.context.translate("_UPLOAD_")}
                  color={config.tooltipColorEdit}
                >
                  <Button
                    type={config.uploadBoxType}
                    onClick={() => this.setState({ showUpload: true })}
                  >
                    <UploadOutlined />
                  </Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  //  allowClear={true}
                  onChange={(value) => this.handleChange(value)}
                  value={
                    this.state.company_value_label
                      ? this.state.company_value_label
                      : null
                  }
                  // style={{ width: 200 }}
                  style={companyInputBox}
                />
                <Input
                  // placeholder="Search by Garage,Service Type,Vehicle"
                  placeholder={this.context.translate(
                    "_SEARCH_BY_GARAGE_SERVICE_VEHICLE_"
                  )}
                  style={{ width: 400 }}
                  onKeyUp={(e) => this.searchInput(e)}
                  onChange={(e) => {
                    this.setState({ searchField: e.target.value });
                  }}
                />

                {/* <DatePicker
                  style={{ width: "160px" }}
                  // placeholder="Search By Date"
                  placeholder={this.context.translate("_SEARCH_DATE_")}
                  allowClear
                  onChange={async (val) => {
                    if (val === null) {
                      await this.setState({ searchFieldDate: "" });
                      await this.handleSearch();
                    } else {
                      await this.setState({
                        searchFieldDate: val.format("YYYY-MM-DD"),
                      });
                      await this.handleSearch();
                    }
                  }}
                /> */}
                <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  onClick={() => {
                    this.searchInputButton();
                  }}
                />
              </Space>
            </Col>
            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.maitenanceStore?.total}
            columns={this.columns}
            dataSource={this.maitenanceStore?.maintainVehicles}
            loading={this.maitenanceStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openVehicleMaintenanceForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Add Maintenance Details"
            title={this.context.translate("_ADD_MAINTENANCE_DETAILS_")}
            centered
            visible={this.state.openVehicleMaintenanceForm}
            footer={null}
            onCancel={() => {
              this.setState({
                openVehicleMaintenanceForm: false,
                vehicleMaintenanceId: "",
              });
            }}
            width={665}
          >
            <div>
              <VehicleMaintenanceAdd
                vehicleMaintenanceId={this.state.vehicleMaintenanceId}
                companyId={this.state.companyId}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}

        {this.state.showUpload && (
          <>
            {" "}
            <Modal
              open={this.state.showUpload}
              // title="File Upload"
              title={this.context.translate("_FILE_UPLOAD_")}
              maskClosable={false}
              onOk={() => this.setState({ showUpload: false })}
              onCancel={() =>
                this.setState({
                  showUpload: false,
                  excelFile: "",
                  companyId: "",
                })
              }
              footer={false}
              width={400}
              // style={{width:100}}
            >
              <Form
                name="excelimport"
                layout="vertical"
                onFinish={this.onExcelSubmit}
                style={{ padding: "0px 10px" }}
              >
                {(this.props.getUserData().isadmin === -1 ||
                  this.props.getUserData().isadmin === 3) && (
                  <Form.Item
                    name="companyId"
                    label={this.context.translate("_ACCOUNT_")}
                    rules={[{ required: true }]}
                  >
                    <CompanyAutocomplete
                      onChange={(companyId) => {
                        this.setState({ excelCompanyId: companyId.value });
                        // this.formRef.current.setFieldsValues({excelFile:undefined})
                      }}
                      style={{ width: 300 }}
                    />
                  </Form.Item>
                )}

                <Form.Item
                  name="excelFile"
                  // className="label-seprator"

                  className="uploadFile"
                  // label="Excel File"
                  label={this.context.translate("_EXCEL_FILE_")}
                  rules={[{ required: true }]}
                >
                  <Upload
                    // beforeUpload={(file) => this.validateFile(file)}
                    onRemove={() =>
                      this.setState({ excelFile: "", fileList: [] })
                    }
                    name="excelFile"
                    action={
                      config.baseURL + "/index.php?c=company&a=fileupload"
                    }
                    listType="text"
                    data={{
                      fileType: "accountimport",
                      fieldName: "excelFile",
                    }}
                    onChange={this.getUploadData}
                    // beforeUpload={(file) => this.validateFile(file)}
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />}>
                      {/* Click To Upload Excel File */}
                      {this.context.translate("_CLICKTOUPLOAD_EXCELFILE_")}
                    </Button>
                  </Upload>
                </Form.Item>

                <div className="formBtnGroup">
                  <Button
                    className="formCancelButton"
                    // style={{ backgroundColor: config.activeColor }}
                    onClick={() => this.maitenanceStore.sampleExcel()}
                  >
                    {this.context.translate("_SAMPLE_")}
                  </Button>
                  <Button
                    type="primary"
                    className="formSaveButton"
                    htmlType="submit"
                    // loading={this.state.loading}
                  >
                    {/* css class */}
                    {/* Upload */}
                    {this.context.translate("_UPLOAD_")}
                  </Button>
                </div>
              </Form>
            </Modal>{" "}
          </>
        )}
      </>
    );
  }
}
VehicleMaintenanceList.contextType = StoreContext;
export default observer(withRouter(VehicleMaintenanceList));
