import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";

import { postApi, baseURL } from "../components/Utility";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
  observe,
} from "mobx";
import { act } from "react-dom/test-utils";

class DriverScoreReportStore {
  driverScoreReport = [];

  total = 0;
  loading = false;
  rootStore;
  getVehicles=[]

  constructor(rootStore) {
    makeObservable(this, {
      driverScoreReport: observable,
      total: observable,
      loading: observable,
      getVehiclesByCompanyId: action,
      loadData: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, driverId,vehicleId,filterBy,offset, limit,returnType,reportType,pageNo,fromDate,toDate,}) {
    console.log("vehicle list",returnType)
    this.loading = true;
   if(reportType == 1){
    var postData = {
      companyId: companyId,
      driverId: driverId,
      vehicleId:vehicleId,
      filterBy:filterBy,
      // fromDate: fromDate,
      // toDate: toDate,
      
      fromMonth: fromDate,
      toMonth: toDate,
      reportType: reportType,
      offset: offset,
      limit: limit,
      page: pageNo,
    };
  }
  if(reportType == 3 || reportType == 2){
    var postData = {
      companyId: companyId,
      driverId: driverId,
      vehicleId:vehicleId,
      filterBy:filterBy,
      // fromDate: fromDate,
      // toDate: toDate,
      
      fromDate: fromDate,
      toDate: toDate,
      reportType: reportType,
      offset: offset,
      limit: limit,
      page: pageNo,
    };
  }
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=driver&a=getdriverscorelist",
        "postData=" + base64Input
      );

    console.log(response?.data?.data);
    this.driverScoreReport = response?.data?.data?.driverscore;
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async getVehiclesByCompanyId({ companyId }) {
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);
    console.log(response, "post");
    this.getVehicles = response?.data?.data;
    return response?.data?.data;
  }
  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=driver&a=exportDriverscoreExcel&cid=" +
      getData.companyId +
      
      "&did=" +
      getData.Driver +
      "&rt=" +
      getData.reportType +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=driver&a=exportDriverscorePdf&cid=" +
      getData.companyId +
      "&did=" +
      getData.Driver +
      "&rt=" +
      getData.reportType +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportcsv(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=driver&a=exportDriverscoreCsv&cid=" +
      getData.companyId +
      "&did=" +
      getData.Driver +
      "&rt=" +
      getData.reportType +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportxml(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=driver&a=exportDriverscoreXml&cid=" +
      getData.companyId +
      "&did=" +
      getData.Driver +
      "&rt=" +
      getData.reportType +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
}

export default DriverScoreReportStore;
