import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi,baseURL} from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import { act } from "react-dom/test-utils";
import  config  from '../config';
import utf8 from 'utf8';

class DriversStore {
  drivers = [];
  getDrivers = [];
  getDriverName = []; //using company id
  vehicles=[]
  total = 0;
  loading = false;
  excelLoadig=false;
  photoFromDb={}
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      drivers: observable,
      total: observable,
      loading: observable,
      getDriverName: observable,
      excelLoadig:observable,
      vehicles:observable,
      photoFromDb:observable,
      loadData: action,
      addData: action,
      getDriverData: action,
      getDriverId: action,
      deleteRecord: action,
      getDriversByCompanyId: action,
      sampleExcel: action,
      toGetDriverName: action,
      exportExcel:action,
      addDriverByExcel:action,
      getDriverFunc:action,
      getSearchedDriver:action,
      getVehicles:action,
      setPhotoFromDb:action,
      addDeviceByExcel:action
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit, searchField,isadmin }) {
    console.log(companyId,isadmin, "id");
    this.loading = true;

    

    
    var postData = {
      searchField: searchField,
      companyId: companyId,
      isadmin:isadmin,
      deviceGrpOffSet: 0,
      deviceGrpSize: 10,
      limit: limit,
      offset: offset,
    };

    const base64Input = await  utf8.encode(JSON.stringify(postData));
    const base2 = JSON.stringify( base64_encode(base64Input));

    //const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=driver&a=getdriverList", "postData=" + base2);
    console.log("driver Data", response);
    this.drivers = response?.data?.data?.drivers;

    this.total = parseInt(response?.data?.data?.TotalCount);
    console.log(response?.data?.data?.drivers)
    this.loading = false;
  }

  async addData(driver) {
    console.log("driver data", driver);
    var postData = { tokenId: "80bovn8u800tg1aq9bvr73kbhh", driver };
    // const base64Input = await base64_encode(JSON.stringify(postData));
    const base64Input = await  utf8.encode(JSON.stringify(postData));
    const base2 = JSON.stringify( base64_encode(base64Input));
    var response = await postApi
      .getClient()
      .post("index.php?c=driver&a=update", "postData=" + base2);
    console.log(response);
    return response.data;
  }
  async setPhotoFromDb(photo){
    console.log(photo)
    this.photoFromDb = {fileList :photo}
  }

  async getDriverData(driverId) {
    console.log("getdriverdata", driverId);
    var postData = { driverId: driverId, limit: 1, offset: 0 };
   // console.log(postData)


    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=driver&a=getdriverList", "postData=" + base64Input);
    console.log(response);
    const singleRow = response?.data?.data?.drivers[0];
    if(singleRow.photo){
        
      const fileList = [{
          name: singleRow.photo,
          status: 'success',
          url: config.fleetURL+'/images/drivers/'+singleRow.photo,
      }]
      console.log(fileList);
      this.setPhotoFromDb(fileList)
          
    }
    return singleRow
  }

  async getDriverId(driverInfo) {
    var postData = driverInfo;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=driver&a=update", "postData=" + base64Input);
    console.log(response);
    return response.data.data.driverId;
  }

  async deleteRecord({ driverId, userId }) {
    console.log(driverId, userId, "details");
    const postData = {
      tokenId: "80bovn8u800tg1aq9bvr73kbhh",
      driver: { userId: userId, driverId: driverId },
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=driver&a=delete", "postData=" + base64Input);
    console.log(response);
    return response;
  }

  // get Driver Data using Company Id
  async getDriversByCompanyId(companyId) {
    console.log("vehicle list");
    var postData = {
      tokenId: "",
      companyId: companyId,
      isadmin: -1,
      deviceGrpOffSet: 0,
      deviceGrpSize: 10,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=getdata", "postData=" + base64Input);

    console.log(response?.data?.data.company, "post");
    // console.log("EEEE",response?.data?.data)
    let allDrivers = [{ driverId: 0, name: "ALL" }];
    allDrivers = [...allDrivers, ...response?.data?.data.company[0].drivers];
    this.getDrivers = allDrivers;
    console.log(allDrivers);
    return response?.data?.data.company[0].drivers;
  }

  //To get driver dropdown
  async toGetDriverName(companyId) {
    // console.log(companyId,"dwee")
    var postData = {
      tokenId: "",
      companyId: companyId,
      offset:0,
      limit:500
     
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=driver&a=getdriverList", "postData=" + base64Input);
    console.log(response, "response2135");
    this.getDriverName = response?.data?.data?.drivers;
    console.log(this.getDriverName);
    // this.total = parseInt(response?.data?.data?.TotalCount);
    return response?.data?.data?.drivers;
  }

  async sampleExcel() {
    // window.location.href =
    //   "http://192.168.1.100:8088/awtl_react/public/fleet/appRes/excel/driver.xlsx";

      window.location.href =
      config.fleetURL +
          "/appRes/excel/driver.xlsx";
  }
  async exportExcel(getData) {
    console.log("getData", getData);
    window.location.href =
      baseURL +
      "index.php?c=driver&a=driverExportexcel&cid=" +
      getData.companyId +
      "&searchField=" +
      getData.searchField+
      "&colList=" +
      getData.colList;
  }

  async addDriverByExcel(excelFile){

    this.excelLoadig  = true;
    var postData = excelFile
    console.log(postData);
    console.log(excelFile)
    console.log(this.excelLoadig);
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=driver&a=driverexcel", "postData="+base64Input);
    this.excelLoadig  = false;
    return response.data;
  }

  async getDriverFunc(data){
    // console.log("companyId"+companyId);
    var postData = data;
    // var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=driver&a=getDriver", "postData=" + base64Input);
    console.log(response, "post");
    // this.getVehiclesUsingCompanyId = response?.data?.data;
    return response?.data?.data;
  }

  async getSearchedDriver(data){
    var postData = data;
    // var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=trip&a=getDriver", "postData=" + base64Input);
    console.log(response, "post");
    // this.getVehiclesUsingCompanyId = response?.data?.data;
    return response?.data?.data;
  }

  async getVehicles(companyId){
    var postData = {"companyId":companyId}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=getVehicles", "postData="+base64Input);
    this.vehicles = response.data.data
  }

  async addDeviceByExcel(excelFile) {
    this.excelLoadig = true;
    var postData = excelFile;
    console.log(postData);
    console.log(excelFile);
    console.log(this.excelLoadig);
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=device&a=deviceexcel", "postData=" + base64Input);
    this.excelLoadig = false;
    return response.data;
  }


  async updateStatus(data){ 
    console.log(data) //update active in-active
    var postData = data
     const base64Input = await base64_encode(JSON.stringify(postData));
     var response = await postApi.getClient().post("index.php?c=driver&a=updateActive", "postData="+base64Input);
     return response.data;
   }

   async logoutDriver({driverId}){ 
    console.log(driverId) //update active in-active
    var postData = {"tokenId":"",driverId}
     const base64Input = await base64_encode(JSON.stringify(postData));
     var response = await postApi.getClient().post("index.php?c=user&a=forcelogout", "postData="+base64Input);
     return response.data;
   }
}

export default DriversStore;
