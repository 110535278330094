import React, { Component } from 'react'

import { Form, Select, Button, Input, DatePicker, Modal, Checkbox, Upload, Row, Col } from 'antd'

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from '../withRouter';
import CompanyAutocomplete from './CompanyAutocomplete';
import PassengerStore from '../store/PassengerStore';

class StudentAdd extends Component {

    formRef = React.createRef()
    constructor(props) {
        super(props)
        this.state = ({
            companyId: ""
        })
        this.passengerStore = new PassengerStore()
    }


    layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    async handleChange(comp) {
        console.log(comp)
        await this.setState({ companyId: comp.value });
        this.formRef.current.setFieldsValue({
            companyId: this.state.companyId,

        })
    };


    onFinish = async (values) => {
        console.log(values);
        const data = {...values,passengerId:-1,}
        await this.passengerStore.addData(data)
    }

    render() {
        return (
            <div>
                <h1>Add Passenger</h1>
                <Form style={{ maxWidth: "100%" }}
                ref={this.formRef}
                    layout='vertical'
                    onFinish={this.onFinish}
                    {...this.layout}
                    name="nest-message">

                    <Row>
                        <Col span={11}>
                            <Form.Item name='companyId' label={this.context.translate("_ACCOUNT_")} rules={[{ required: true }]}>
                                <CompanyAutocomplete
                                    onChange={async (value) => { await this.handleChange(value); this.getVehicles() }}
                                    style={{ width: 440 }}
                                />

                            </Form.Item>

                            <Form.Item name='type' label={this.context.translate("_SELECT_TYPE_")} >
                                <Select>
                                    <Select.Option value="Student">Student</Select.Option>
                                    <Select.Option value="Employee">Employee</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item name='studentName' label={this.context.translate("_STUDENT_NAME_")}>
                                <Input />
                            </Form.Item>

                            <Form.Item name='schoolName' label={this.context.translate("_SCHOOL_NAME_")} >
                                <Input />
                            </Form.Item>

                            <Form.Item name='className' label={this.context.translate("_CLASS_NAME_")} >
                                <Select>
                                    <Select.Option value="1">Grade1</Select.Option>
                                    <Select.Option value="2">Grade2</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item name='classDiv' label={this.context.translate("_CLASS_DIV_")} >
                                <Select>
                                    <Select.Option value="A">A</Select.Option>
                                    <Select.Option value="B">B</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item name='term' label={this.context.translate("_TERM_")} >
                                <Select>
                                    <Select.Option value="First Term">First Term</Select.Option>
                                    <Select.Option value="Second Term">Second Term</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item name='fee' label={this.context.translate("_FEE_")} >
                                <Input />
                            </Form.Item>

                            <Form.Item name='pickUpPoint' label={this.context.translate("_LOCATION_")} >
                                <Input placeholder={this.context.translate("_ENTER_LOCATION_")} />
                            </Form.Item>

                            <Form.Item initialValue={"00:00"} name='pickUpTime' label={this.context.translate("_PICKUP_TIME_")} >
                                <Input />
                            </Form.Item>

                            <Form.Item initialValue={"00:00"} name='dropOffTime' label={this.context.translate("_DROP_OFF_TIME_")} >
                                <Input />
                            </Form.Item>

                            {/* <Form.Item name='busno' label={this.context.translate("_BUSNO_")} >
                        <Select>
                           
                        </Select>
                    </Form.Item> */}

                            <Form.Item name="all" >
                        <Checkbox value="mobileapp">{this.context.translate("_MOOBILE_APP_")}</Checkbox>
                    </Form.Item>



                            <Form.Item name='idNo' label={this.context.translate("_IDNO_")}  >
                                <Input />
                            </Form.Item>

                            <Form.Item name='bloodGroup' label={this.context.translate("_BLOOD_GROUP_")}>
                                <Input />
                            </Form.Item>



                            <Form.Item name='tagId' label={this.context.translate("_TAG_ID_")} >
                                <Input />
                            </Form.Item>

                            <Form.Item name='siblingsName1' label={this.context.translate("_SIBLING1_NAME_")} >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={11}>

                            <Form.Item name='siblingsName1' label={this.context.translate("_SIBLING1_NAME_")} >
                                <Input />
                            </Form.Item>

                            <Form.Item name='siblingsClass1' label={this.context.translate("_SIBLING1_CLASS_")} >
                                <Input />
                            </Form.Item>




                            <Form.Item name='siblingsName2' label={this.context.translate("_SIBLING2_NAME_")} >
                                <Input />
                            </Form.Item>

                            <Form.Item name='siblingsId2' label={this.context.translate("_SIBLING2_ID_")} >
                                <Input />
                            </Form.Item>

                            <Form.Item name='siblingsClass2' label={this.context.translate("_SIBLING2_CLASS_")} >
                                <Input />
                            </Form.Item>

                            <Form.Item name='fatherName' label={this.context.translate("_FATHER_NAME_")} >
                                <Input />
                            </Form.Item>


                            <Form.Item name='fatherContactNo' label={this.context.translate("_FATHER_CONTACT_NO_")} >
                                <Input />
                            </Form.Item>

                            <Form.Item name='motherName' label={this.context.translate("_MOTHER_NAME_")} >
                                <Input />
                            </Form.Item>

                            <Form.Item name='motherContactNo' label={this.context.translate("_MOTHER_CONTACT_NO_")} >
                                <Input />
                            </Form.Item>

                            <Form.Item name='guardianName' label={this.context.translate("_GUARDIAN_NAME_")} >
                                <Input />
                            </Form.Item>

                            <Form.Item name='guardianContactNo' label={this.context.translate("_GUARDIAN_CONTACT_NO_")} >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                // name='uploadImage'
                                label={this.context.translate("_FLEET_USER_USERDETAIL_PHOTO_")}>
                                <Upload name="logo"
                                    action="/upload.do"
                                    accept=".png,.jpeg"
                                    listType="picture">

                                    <Button>Browse</Button>
                                </Upload>
                                <Button>Remove</Button>

                            </Form.Item>


                            <Form.Item wrapperCol={{ ...this.layout.wrapperCol, offset: 8 }}>
                                <Button htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>



            </div>
        )
    }
}

StudentAdd.contextType = StoreContext;
export default observer(withRouter(StudentAdd));
