import React, { Component } from "react";
import withRouter from "../withRouter";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Button, Col, Modal, Row, Select, Space } from "antd";
import ListTable from "./ListTable";

import AssignBusToStudentStore from "../store/AssignBusToStudentStore";
import { Link } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import GoogleMapForDirection from "./GoogleMapForDirection";

class AssignBusToStudent extends Component {
  constructor(props) {
    super(props);
    // this.handleTableChange = this.handleTableChange.bind(this);
    // this.handleSearch = this.handleSearch.bind(this);
    // this.handleChange = this.handleChange.bind(this);

    this.assignBusToStudent = new AssignBusToStudentStore(this);

    // console.log(this.context);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      companyId: "",
      loadList: 0,
      company_value_label: "",
      openAssignForm: false,
      //   type:"All"
    };
  }
  async componentDidMount() {
    
    console.log(JSON.parse(atob(this.props.params.tripData)))
    const data = await this.assignBusToStudent.getAllClass();
    console.log(data);
    console.log(this.context.viewStateStore.value, "vale");

    this.setState({ pageSize: 50 });
    this.columns = [
      {
        // title: "Student Id",
        title:this.context.translate("_STUDENT_ID_"),
        dataIndex: "stId",
        // width: "30%",
      },
      {
        // title: "Student Name",
        title:this.context.translate("_STUDENT_NAME_"),
        dataIndex: "description",
        // width: "30%",
      },

      {
        // title: "Grade",
        title:this.context.translate("_GRADE_"),
        dataIndex: "active",
        // width: "15%",
      },
      {
        // title: "Bus No",
        title:this.context.translate("_BUSNO_"),
        dataIndex: "description",
        // width: "30%",
      },

      {
        // title: "Status",
        title:this.context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "active",
        // width: "15%",
      },

      {
        // title: "Address",
        title:this.context.translate("_FLEET_DASHBORD_DROWGEO_ADDRESS_"),
        dataIndex: "active",
        // width: "15%",
      },
    ];
    await this.setState({ loadList: 1 });
  }

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Assign Bus to Student</div> */}
              <div className="heading">{this.context.translate("_ASSIGN_BUS_TO_STUDENT_")}</div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Link to="/assigntriplist">
                  <Button> {this.context.translate("_FLEET_USER_USERLIST_ASSIGNVEHICLE_BACKBTN_")}</Button>
                </Link>

                <Button type="primary" htmlType="submit">
                  {/* Save To Assign */}
                  {this.context.translate("_SAVE_TO_ASSIGN_")}
                </Button>

                <Button>{this.context.translate("_REMOVE_")}</Button>
              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <Select
                  //   defaultValue="All"
                  style={{ width: 125 }}
                  name="classId"
                  // placeholder="Grade"
                  placeholder={this.context.translate("_GRADE_")}
                  //   showSearch
                  // onChange={async (val) => {
                  //   await this.setState({ classId: val });
                  //   console.log(val);
                  // }}
                  fieldNames={{ value: "classId", label: "className" }}
                  optionFilterProp="children"
                  options={this.assignBusToStudent?.getClass}
                />
                <Select
                  // onSelect={(type) => this.setState({ type: type })}

                  defaultValue="All"
                  style={{ width: 150 }}
                  //   onSelect={async (value) => {
                  //     await this.setState({ status: value });
                  //     await this.handleSearch();
                  //   }}
                  placeholder="Assigned Type"
                >
                  {/* <Select.Option value={0}>All</Select.Option>
                  <Select.Option value={1}>Assigned</Select.Option>
                  <Select.Option value={2}>Not Assigned</Select.Option> */}
                   <Select.Option value={0}>{this.context.translate("_ALL_")}</Select.Option>
                  <Select.Option value={1}>{this.context.translate("_ASSIGNED_")}</Select.Option>
                  <Select.Option value={2}>{this.context.translate("_NOT_ASSIGNED_")}</Select.Option>
                </Select>
                <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  //   onClick={() => {
                  //     this.inputSearchButton();
                  //     console.log("asdf");
                  //   }}
                />
                {/* <p>Capacity:</p> &nbsp;&nbsp;
                <p>Available Seat:</p> */}
                 <p>{this.context.translate("_CAPACITY_")}:</p> &nbsp;&nbsp;
                <p>{this.context.translate("_AVAILABLE_SEAT_")}:</p>
              </Space>
            </Col>
            {/* <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >

              </Space>
            </Col> */}
          </Row>
          <Row style={{minHeight:"600px"}}>
            <Col span={11}>
              <ListTable
                //   rowSelection={{
                //   selectedRowKeys: this.state.StudentId,

                //       onChange: async (selectedRowKeys, selectedRows) => {
                //         console.log(selectedRowKeys, selectedRows);
                //         // await this.setState({})
                //         await this.setState({ StudentId: selectedRowKeys });

                //         console.log(selectedRowKeys);
                //       },
                //     }}
                //     rowKey="StudentId"
                // currentPage={this.state.currentPage}
                // pageSize={this.state.pageSize}
                // total={this.alertStore?.total}
                columns={this.columns}
                // dataSource={this.alertStore?.alertData}
                // loading={this.alertStore?.loading}
                // onChange={this.handleTableChange}
              />
            </Col>
           <Col span={1}/>
            <Col span={11}>
            <GoogleMapForDirection
          origin={this.state.origin}
          destination={this.state.destination}
          onPlaceSelected={this.handlePlaceSelected}
        ></GoogleMapForDirection>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

AssignBusToStudent.contextType = StoreContext;
export default observer(withRouter(AssignBusToStudent));
