import React, { memo, useEffect, useState,useContext} from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import conf from "../config";
import { Button } from "antd";
import withRouter from "../withRouter";
import { StoreContext } from "../store/RootStore";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const DisplayLocationMap = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: conf.googleMapKey,
  });

  const [map, setMap] = useState(null);
  const [trafficLayer, setTrafficLayer] = useState(null);
  const [isTrafficVisible, setIsTrafficVisible] = useState(false);
  const [draggedMarkerPosition, setDraggedMarkerPosition] = useState(null);
  const storeContext = useContext(StoreContext);

  useEffect(() => {
    if (props && map && !draggedMarkerPosition) {
      // Set the initial marker position when the component mounts
      const initialMarkerPosition = {
        lat: props.addressInfo[0],
        lng: props.addressInfo[1],
      };
      setDraggedMarkerPosition(initialMarkerPosition);

      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(
        new window.google.maps.LatLng(
          initialMarkerPosition.lat,
          initialMarkerPosition.lng
        )
      );
      map.fitBounds(bounds, { maxZoom: 20 });
    }
  }, [props.addressInfo, map, draggedMarkerPosition]);

  useEffect(() => {
    if (map && isTrafficVisible) {
      const newTrafficLayer = new window.google.maps.TrafficLayer();
      newTrafficLayer.setMap(map);
      setTrafficLayer(newTrafficLayer);
    } else if (trafficLayer) {
      trafficLayer.setMap(null);
    }
  }, [map, isTrafficVisible]);

  const options = {
    zoomControl: false,
    streetViewControl: false,
    traffic: isTrafficVisible,
  };

  const onMarkerDragEnd = (e) => {
    const newMarkerPosition = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    setDraggedMarkerPosition(newMarkerPosition);

    // Call reverse geocoding function with the new coordinates
    reverseGeocode(newMarkerPosition);
  };

  const reverseGeocode = async (coordinates) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ location: coordinates }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          const address = results[0].formatted_address;
          console.log("New Address:", address);
          if (props.address) {
            props.address(address);
          }
          props.formRef.current.setFieldsValue({
            pickUpPoint: address,
          });
        } else {
          console.error("No results found");
        }
      } else {
        console.error(`Geocoder failed due to: ${status}`);
      }
    });
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={draggedMarkerPosition}
      zoom={25}
      options={options}
      onLoad={(map) => setMap(map)}
    >
      {draggedMarkerPosition && (
        <Marker
          position={draggedMarkerPosition}
          draggable={true}
          onDragEnd={(e) => onMarkerDragEnd(e)}
        />
      )}
      <Button
        style={{
          position: "absolute",
          top: "10px",
          left: "700px",
        }}
        onClick={() => setIsTrafficVisible(!isTrafficVisible)}
      >
        {/* Traffic */}
        {storeContext.translate("_FLEET_DASHBORD_MAP_TRAFFIC_")}
      </Button>
    </GoogleMap>
  ) : null;
};

export default withRouter(DisplayLocationMap);
