import React from "react";
import { Button, Space, Row, Col, message, Tooltip, Modal, Switch } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";

import { DeleteFilled, PlusOutlined } from "@ant-design/icons";

import AlertStore from "../store/AlertStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import withRouter from "../withRouter";
import CompanyStore from "../store/CompanyStore";
import AlertAdd from "./AlertAdd";

class AlertList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.alertStore = new AlertStore();
    this.companyStore = new CompanyStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      showDeleteModal: false,
      ruleId: [],
      releId: "",
      companyVal_Lab: "",
      loadList: 0,

      listDataUpdate: false,
      openRuleDetailsForm: false,
      ruleIdForEdit: "",
      newRuleId: "",
    };
  }
  async componentDidMount() {
    
    if (Number(this.props.getUserData().isadmin) !== -1) {
      // console.log(this.props.getUserData().userCompanyId)
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      //  console.log(this.state.companyId)
      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleSearch();
      await this.setState({ loadList: 1 });
    }
    //   const setAlert =
    //   this.context.viewStateStore.alertObject;
    //   if(setAlert.companyId!==""){
    // console.log(setAlert);
    // await this.alertStore.loadData({
    //   offset: (this.state.currentPage - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: setAlert.companyId,
    // });

    // const singleData = await this.companyStore.getCompanyDataById(
    //   setAlert.companyId
    // );

    // await this.setState({ loadList: 1 });
    // console.log("singleData", singleData);
    // await this.setState({ companyVal_Lab: singleData,companyId: setAlert.companyId});
    // console.log("singleData", singleData);

    // }

    // await this.alertStore.loadData({ offset: 0, limit: 50 });
    this.setState({ pageSize: 50 });
    this.columns = [
      {
        // title: "Rule Id",
        title: this.context.translate("_FLEET_RULEADMIN_RULELIST_RULEID_"),
        dataIndex: "name",
        width: "30%",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        // title: "Description",
        title: this.context.translate("_FLEET_DASHBORD_ROUTELIST_DISCRIPTION_"),
        dataIndex: "description",
        width: "30%",
      },
      {
        // title: "Cron Rule",
        title: this.context.translate("_FLEET_RULEADMIN_RULELIST_CRONRULE_"),
        dataIndex: "cronRule",
        width: "20%",
        render: (val) => {
          if (val == 0) {
            return "No";
          } else if (val == 30) {
            return "30 Minute";
          } else if (val == 10080) {
            return "Weekly";
          } else if (val == 5) {
            return "5 Minute";
          } else if (val == 15) {
            return "15 Minute";
          } else if (val == 60) {
            return "Hourly";
          } else if (val == 1440) {
            return "Daily";
          }
        },
      },
      {
        // title: "Active",
        title: this.context.translate("_FLEET_USER_USERLIST_STATUS_ACTIVE_"),
        dataIndex: "active",
        width: "15%",
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        // fixed : 'right',
        width: "5%",
        render: (_, record) => {
          console.log(record.ruleId);
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                 
                  <Tooltip
                    // title="Edit"
                    title={this.context.translate("_FLEET_COMPANY_COMPANYLIST_EDIT_")}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}

                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      // title="Delete"
                      title={this.context.translate(
                        "_FLEET_COMPANY_COMPANYLIST_DELETE_"
                      )}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>

                <Tooltip
                  title={
                    record.status == "1"
                      ? this.context.translate("_ACTIVE_")
                      : this.context.translate(
                          "_FLEET_USER_USERLIST_STATUS_INACTIVE_"
                        )
                  }
                  placement="top"
                  color={
                    record.status == "1"
                      ? config.activeColor
                      : config.inActiveColor
                  }
                >
                  <Switch
                    size="small"
                    className="form-offlineAlerts"
                    checked={Number(record.status)}
                    // checkedChildren="Yes"
                    // unCheckedChildren="No"
                    // checkedChildren={this.context.translate(
                    //   "_ACTIVE_"
                    // )}
                    // unCheckedChildren={this.context.translate(
                    //   "_IN_ACTIVE_"
                    // )}
                    onChange={async (val) => {
                      this.setState({ newRuleId: record.ruleId });
                      this.setState({ isActive: val });
                      this.setState({ showStatusConfirm: true }); // open modal
                    }}
                    // defaultChecked
                  />
                </Tooltip>
              </Space>
            </>
          );
        },
      },
    ];
  }

  confirmStatus = async (status) => {
    const data = {};

    data.ruleId = this.state.newRuleId;
    data.activeStatus = this.state.isActive === true ? 1 : 0;

    await this.alertStore.updateStatus(data);

    console.log(data);

    this.setState({ showStatusConfirm: false });
    await this.handleSearch();
    //  await this.setState({ loading: true });
    //   await this.driversStore.loadData({ offset: 0, limit: 50 });
    // await this.setState({ loading: false });
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const setAlert = this.context.viewStateStore.alertObject;
      if (setAlert.companyId !== "") {
        console.log(setAlert);
        await this.alertStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: setAlert.companyId,
        });

        if (Number(this.props.getUserData().isadmin) === -1) {
          let singleData = await this.companyStore.getCompanyDataById(
            setAlert.companyId
          );

          this.setState({
            companyVal_Lab: singleData,
            companyId: setAlert.companyId,
          });
          console.log("singleData", singleData);
        }
      }
      await this.setState({ listDataUpdate: false });
    }
  }
  async onDelete(record) {
    console.log(record.ruleId, "record");
    const response = await this.alertStore.deleteRecord({
      ruleId: record.ruleId,
    });
    console.log(response.data.success);
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  onEdit = async (record) => {
    console.log(record);
    const ruleId = record.ruleId;
    console.log(ruleId);
    if (ruleId) {
      // this.context.viewStateStore.setAlertStates({
      //   companyId: record.companyId,
      // });s
      // this.props.navigate("/alertadd/" + ruleId);
      await this.setState({ ruleIdForEdit: ruleId });
      await this.setState({ openRuleDetailsForm: true });
    }
  };

  async handleChange(comp) {
    console.log(comp.value);

    await this.setState({
      companyId: comp.value,
      currentPage: 1,
      pageSize: 50,
      ruleId: [],
    });

    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ companyVal_Lab: singleData });

    await this.handleSearch();
    await this.setState({ loadList: 1 });
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.alertStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }
  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  // deleteMultipleRules = async (value) => {
  //   let flag = 0;
  //   console.log(value);
  //   const deletePassenger = value?.forEach(async (id) => {
  //     const data = {
  //       ruleId: id,
  //     };
  //     console.log(data);
  //     const response = await this.alertStore.deleteRecord(data);
  //     if (response?.data?.success === "S") {
  //       if (flag == 0) {
  //         message.success(response.data.message);
  //       }
  //       this.handleSearch();
  //       flag++;
  //     } else {
  //       message.error(response.data.message);
  //     }
  //   });
  // };

  warningModalVehicle = async () => {
    Modal.warning({
      // title: "Alert",
      title: this.context.translate("_FLEET_DASHBORD_SHOWGEO_REMOVEALERT_"),
      // content: "Please select vehicles",
      content: this.context.translate("_PLEASE_SELECT_VEHICLE_"),
    });
  };

  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.success == "S") {
      message.success(resp.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openRuleDetailsForm: false });

      //  await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    } else {
      message.error(resp.message);
    }
  };
  closeModal = () => {
    this.setState({ openRuleDetailsForm: false, ruleId: "" });
  };
  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Rule List</div> */}
              <div className="heading">
                {this.context.translate("_FLEET_RULEADMIN_RULELIST_TITLE_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                {/* <AddButton
                  title={this.context.translate("_ADD_")}
                  link="/alertadd"
                /> */}
                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  placement="left"
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({
                        openRuleDetailsForm: true,
                        ruleIdForEdit: "",
                      })
                    }
                  ></Button>
                </Tooltip>
                {/* <Tooltip
                  // title="Delete"
                  title={this.context.translate(
                    "_FLEET_DASHBORD_RULETRIGGER_DELETE_"
                  )}
                  color={config.tooltipColorDelete}
                >
                  <Button
                    type="primary"
                    onClick={async (e) => {
                      this.state.ruleId.length > 0
                        ? await this.setState({ showDeleteModal: true })
                        : await this.warningModalVehicle();
                    }}
                  >
                    
                    <i class="bi bi-trash3"></i>
                  </Button>
                </Tooltip> */}
              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  value={
                    this.state.companyVal_Lab ? this.state.companyVal_Lab : null
                  }
                  // allowClear={true}
                  style={{ width: 200 }}
                />
              </Space>
            </Col>
          </Row>

          <ListTable
            // rowSelection={{
            //   selectedRowKeys: this.state.ruleId,

            //   onChange: async (selectedRowKeys, selectedRows) => {
            //     console.log(selectedRowKeys, selectedRows);
            //     // await this.setState({})
            //     await this.setState({ ruleId: selectedRowKeys });

            //     console.log(selectedRowKeys);
            //   },
            // }}
            // rowKey="ruleId"
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.alertStore?.total}
            columns={this.columns}
            dataSource={this.alertStore?.alertData}
            loading={this.alertStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {/* <Modal
          // title="Remove Asset?"
          title={this.context.translate(
            "_FLEET_VEHICLE_VEHICLELIST_REMOVETITLE_"
          )}
          open={this.state.showDeleteModal}
          width={300}
          onCancel={() => this.setState({ showDeleteModal: false })}
          footer={[
            <Button
              type="primary"
              // key="yes"
              onClick={(e) => {
                this.deleteMultipleRules(this.state.ruleId);

                console.log(this.state.ruleId);
                this.setState({ showDeleteModal: false, ruleId: "" });
              }}
            >
              {this.context.translate("_FLEET_RULEDETAIL_ACTIVEDDL_YES_")}
            
            </Button>,
            <Button
              // key="no"
              onClick={() => this.setState({ showDeleteModal: false })}
            >
              {this.context.translate("_FLEET_RULEDETAIL_ACTIVEDDL_NO_")}
            
            </Button>,
          ]}
        >
          {this.context.translate("_FLEET_RULEADMIN_RULELIST_REMOVEMSG_")}
          
        </Modal> */}

        {this.state.showStatusConfirm && (
          <Modal
            maskClosable={false}
            title={this.context.translate("_CONFIRM_")}
            description="Are you sure to change this status?"
            centered
            open={this.state.showStatusConfirm}
            //footer={null}
            onOk={this.confirmStatus}
            onCancel={() => {
              this.setState({ showStatusConfirm: false });
            }}
            width={400}
          >
            {this.state.isActive === true ? (
              <p>{this.context.translate("_STATUS_ACTIVE_")}</p>
            ) : (
              <p>{this.context.translate("_STATUS_INACTIVE_")}</p>
            )}
          </Modal>
        )}

        {this.state.openRuleDetailsForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Rule Details"
            title={this.context.translate("_FLEET_RULEADMIN_RULEDETAIL_TITLE_")}
            centered
            open={this.state.openRuleDetailsForm}
            footer={null}
            onCancel={() => {
              this.setState({ openRuleDetailsForm: false, ruleId: "" });
            }}
            width={665}
          >
            <div>
              <AlertAdd
                ruleId={this.state.ruleIdForEdit}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
AlertList.contextType = StoreContext;
export default observer(withRouter(AlertList));
