import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi, baseURL } from "../components/Utility";

import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from "mobx";
import { act } from "react-dom/test-utils";

class DriverComplainReportStore {
  driverComplainStore = [];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      driverComplainStore: observable,
      loadData: action,

      exportExcel:action
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, driverId, offset, limit, fromDate, toDate }) {
    console.log("vehicle list");
    var postData = {
      companyId: companyId,
      driverId: driverId,
      fromDate: fromDate,
      toDate: toDate,
      offset: offset,
      limit: limit,
      page: 1,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=driver&a=getdrivercomplain",
        "postData=" + base64Input
      );

    this.driverComplainStore = response?.data?.data?.complains;
  }

  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=driver&a=exportdrivercomplainExcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&did=" +
      getData.DriverId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }

  async exportpdf(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=driver&a=exportdrivercomplainPdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&did=" +
      getData.DriverId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exportcsv(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=driver&a=exportdrivercomplainCsv&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&did=" +
      getData.DriverId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exportxml(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=driver&a=exportdrivercomplainXml&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&did=" +
      getData.DriverId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }

  
}

export default DriverComplainReportStore;
