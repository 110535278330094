import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi, baseURL } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class UserActivityReportStore {
  userActivityReport = [];
  distributorsArray = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      userActivityReport: observable,
      distributorsArray: observable,
      total: observable,
      loading: observable,
      loadData: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    offset,
    limit,
    companyId,
    fromDate,
    toDate,
    activity,
    currentPage,
  }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      activity: activity,
      fromDate: fromDate,
      toDate: toDate,
      offset: offset,
      limit: limit,
      page: currentPage,
    };
    console.log(postData);

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=getActivityDetails",
        "postData=" + base64Input
      );
    console.log("user Activity ", response?.data?.data);

    this.userActivityReport = response?.data?.data?.ActivityDetails;

    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async getDistributors(isadmin) {
    console.log("distributors");
    var postData = { type: "Distributor", companyId: null };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=getdistributors", "postData=" + base64Input);
    console.log("user Activity ", response?.data?.data?.companies);
    let allDistributors = 0;
    if (isadmin == -1) {
      allDistributors = [{ companyId: '-1', name: "All" }];
    } else {
      allDistributors = [{ companyId: '1', name: "B2BTracking" }];

    }


    allDistributors = [...allDistributors, ...response?.data?.data?.companies];
    console.log("All distr ", allDistributors);
    this.distributorsArray = allDistributors;
    console.log(this.distributorsArray)
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportDistActivityExcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      // "&vid=" +
      // getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&activity=" +
      getData.activity +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportDistActivityPdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&activity=" +
      getData.activity +
      // "&vid=" +
      // getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportDistActivityCsv&cid=" +
      getData.companyId +
      "&activity=" +
      getData.activity +
      // "&vid=" +
      // getData.vehicleId +
      // "&int="+
      // getData.interval+
      " &userId=" +
      getData.userId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
}

export default UserActivityReportStore;
