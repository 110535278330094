import React, { useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table,
  Button,
  Typography,
  Space,
  Row,
  Col,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  PlusOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import DeviceLogStore from "../store/DeviceLogStore";
import ListTable from "./ListTable";
class DeviceLogList extends React.Component {
  constructor(props) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.deviceLogStore = new DeviceLogStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      imei: "",
      loadData: 0,
    };
  }
 
  async componentDidMount() {
    // await this.context.companyStore.companyList({ offset: 0, limit: 50 });
    // await this.context.distributorStore.distributorList();
    await this.deviceLogStore.loadData({ offset: 0, limit: 50 });
    this.setState({ pageSize: 50 });

    this.columns = [
      {
        // title: "Modified By",
        title:this.context.translate("_MODIFIEDBY_"),
        dataIndex: "username",
        width: "15%",
      },
      {
        // title: "Company Name",
        title:this.context.translate("_COMPANY_NAME_"),
        dataIndex: "companyName",
        width: "15%",
      },
      {
        // title: "Vehicle No",
        title:this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        width: "15%",
      },
      {
        // title: "IMEI",
        title:this.context.translate("_FLEET_DASHBORD_SETTING_IMEI_"),
        dataIndex: "imei",
        width: "15%",
      },
      {
        // title: "Field Name",
        title:this.context.translate("_FIELD_NAME_"),
        dataIndex: "field",
        width: "15%",
      },
      {
        // title: "Before",
        title:this.context.translate("_BEFORE_"),
        dataIndex: "before",
        width: "15%",
      },
      {
        // title: "After",
        title:this.context.translate("_AFTER_"),
        dataIndex: "after",
        width: "15%",
      },
      {
        // title: "Date",
        title:this.context.translate("_FLEET_REPORTS_DATE_"),
        dataIndex: "entryDate",
        width: "15%",
      },
      {
        // title: "Status",
        title:this.context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "status",
        width: "20%",
      },
    ];
  }
  async componentDidUpdate(prevProps, prevState){
    if(Number(this.props.getUserData().isadmin)===1){
      this.props.navigate("/login");
    }
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination, "pagi");
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.deviceLogStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
    });
    console.log(this.state.imei);
    await this.setState({ loadData: 1 });
  }

  async inputSearchButton() {
    await this.deviceLogStore.loadData({
      offset: 0,
      limit: 50,
      searchField: this.state.searchField,
    });

    await this.setState({ loadList: 1,currentPage: 1 });
  }

  searchInput = async (e) => {
    const value = e.target.value;
    console.log(value);
    await this.setState({ searchField: value });
    if (value.length > 3) {
      await this.deviceLogStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        searchField: value,
      });
      await this.setState({ loadList: 1, companyLoading: false });
    } else if (value.length === 0) {
      await this.deviceLogStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        searchField: value,
      });
      await this.setState({ currentPage: 1 });
    }
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Device Log List</div> */}
              <div className="heading">{this.context.translate("_DEVICE_LOG_LIST_")}</div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Space>
              <Input
                style={{
                  width: 200,
                }}
                // placeholder="Search IMEI"
                placeholder={this.context.translate("_SEARCH_IMEI_")}
                onChange={async (e) => {
                  await this.setState({ searchField: e.target.value });
                  console.log(e.target.value);
                  await this.searchInput(e);
                }}
              />

              <Button
                icon={<SearchOutlined />}
                type="primary"
                onClick={async () => {
                  await this.inputSearchButton();
                }}
              />
            </Space>
            <Col span={16}></Col>
            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.deviceLogStore?.total}
            columns={this.columns}
            dataSource={this.deviceLogStore?.deviceLog}
            loading={this.deviceLogStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>
      </>
    );
  }
}
DeviceLogList.contextType = StoreContext;
export default observer(DeviceLogList);
