import {
  FacebookOutlined,
  MailOutlined,
  SkypeOutlined,
  TwitterOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { Input, Modal, Tag, Tooltip } from "antd";
import React, { Component } from "react";
import withRouter from "../withRouter";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";

class ShareModalWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    console.log(this.props);
    console.log(this.props.shareLiveLocation);
  }

  shareToFacebook = () => {
    window.open(
      "https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer.php%3Fu%3Dhttps%253A%252F%252Fwww.google.com%252Fmaps%253Fq%253D24.5957733%252C46.5927766&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB"
    );
  };

  shareToEmail = () => {
    window.open("https://www.google.com/gmail/about/");
  };

  shareToTwitter = () => {
    window.open(
      "https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Furl%3Dhttps%253A%252F%252Fwww.google.com%252Fmaps%253Fq%253D24.5957733%252C46.5927766"
    );
  };

  shareToSkype = () => {
    window.open(
      "https://web.skype.com/share?url={https%3A%2F%2Fwww.google.com%2Fmaps%3Fq%3D24.5957733%2C46.5927766}"
    );
  };

  shareToWhatsApp = () => {
    window.open(
      "https://api.whatsapp.com/send/?text=https%3A%2F%2Fwww.google.com%2Fmaps%3Fq%3D24.5957733%2C46.5927766&type=custom_url&app_absent=0"
    );
  };
  render() {
    return (
      <div>
        <Modal
          // title="SHARE"
          title={this.context.translate("_FLEET_SERVICE_POILIST_SHARE_")}
          open={
            this.props?.shareModalVisible || this.props?.shareSingleLocation || this.props?.shareLiveLocation
          }
          onCancel={() => this.props?.shareModalCloseFunc(false)}
          footer={null}
          maskClosable={false}
          width={200}
          style={{
            top: 10,
            right: "30px",
          }}
          centered
        >
          <div style={{ display: "inline-grid", gap: "9px" }}>
            <Tooltip title="Share on Twitter" placement="right">
              {/* <Button icon={<FacebookOutlined />} /> */}
              <Tag
                icon={<TwitterOutlined />}
                color="#55acee"
                onClick={this.shareToTwitter}
              >
                Twitter
              </Tag>
            </Tooltip>
            <Tooltip title="Share via Email" placement="right">
              {/* <Button icon={<MailOutlined />} /> */}
              <Tag
                icon={<MailOutlined />}
                color="#cd201f"
                onClick={this.shareToEmail}
              >
                Email
              </Tag>
            </Tooltip>
            <Tooltip title="Share on Facebook" placement="right">
              {/* <Button icon={<TwitterOutlined />} onClick={shareToFacebook} /> */}
              <Tag
                icon={<FacebookOutlined />}
                color="#3b5999"
                onClick={this.shareToFacebook}
              >
                Facebook
              </Tag>
            </Tooltip>
            <Tooltip title="Share on Skype" placement="right">
              {/* <Button icon={<SkypeOutlined />} onClick={shareToSkype} /> */}
              <Tag
                icon={<SkypeOutlined />}
                color="#55acee"
                onClick={this.shareToSkype}
              >
                Skype
              </Tag>
            </Tooltip>
            <Tooltip title="Share on WhatsApp" placement="right">
              {/* <Button icon={<WhatsAppOutlined />} onClick={shareToWhatsApp} /> */}
              <Tag
                icon={<WhatsAppOutlined />}
                color="teal "
                onClick={this.shareToWhatsApp}
              >
                WhatsApp
              </Tag>
            </Tooltip>

            <h6 style={{ margin: "0px" }}>
              {this.context.translate("_LINK_TO_SHARE_")}
            </h6>
            <Input
              style={{ height: "36px" }}
              value={
                this.props.shareLiveLocation
                  ? this.props.shareLiveLocation
                  : this.props.shareLink
                  ? `https://www.google.com/maps/dir/ ${this.props.shareLink[0]}/${this.props.shareLink[1]}`
                  : `https://www.google.com/maps?q=${this.props.shareSingleLocation}`
              }
            />
          </div>
        </Modal>
      </div>
    );
  }
}
ShareModalWindow.contextType = StoreContext;
export default observer(withRouter(ShareModalWindow));

// https://www.google.com/maps/dir/37.7749,-122.4194/34.0522,-118.2437
