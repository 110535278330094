import React from "react";
import {
  Row,
  Col,
  Input,
  Tag,
  Avatar,
  Button,
  Space,
  Dropdown,
  Popconfirm,
  message,
  Tooltip,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import {
  PushpinFilled,
  EditFilled,
  DeleteFilled,
  QuestionCircleOutlined,
  DownOutlined,
  SearchOutlined,
  DownloadOutlined,
  CloudUploadOutlined,
  DashboardOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import passengerStore from "../store/PassengerStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import AddButton from "./AddButton";
import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import withRouter from "../withRouter";
import GradeTimingStore from "../store/GradeTimingStore";
import SchoolCalenderStore from "../store/SchoolCalenderStore";
import CompanyStore from "../store/CompanyStore";
import SchoolCalenderAdd from "./SchoolCalenderAdd";

class SchoolCalenderList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.onDelete = this.onDelete.bind(this);
    this.companyStore = new CompanyStore(this);

    this.schoolCalenderStor = new SchoolCalenderStore();

    // console.log(this.context);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      companyId: "",
      loadList: 0,
      company_value_label: "",
      calenderId: "",
      openCalenderForm: false,
      listDataUpdate: false,
    };
  }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId)
      await this.setState({ companyId: this.props.getUserData().userCompanyId })
      await this.setState({ userId: this.props.getUserData().userId })
      await this.handleSearch();
    }
    // await this.gradTimingStore.loadData({ offset: 0, limit: 50 });
    //this.handleSearch();

    // const beforeEditCompanyId = this.context.viewStateStore.schoolCalendarObject.companyId;
    // if (beforeEditCompanyId) {
    //   await this.schoolCalenderStor.loadData({
    //     offset: (this.state.currentPage - 1) * this.state.pageSize,
    //     limit: this.state.pageSize,
    //     companyId: beforeEditCompanyId,
    //   });
    //   const singleData = await this.companyStore.getCompanyDataById(
    //     this.context.viewStateStore.schoolCalendarObject.companyId
    //   );
    //   await this.setState({ listUpdated: 1 }); //need to reload data
    //   await this.setState({ company_value_label: singleData });
    // }
    this.setState({ pageSize: 50 });
    this.columns = [
      {
        // title: "Academic Year From",
        title: this.context.translate("_ACADEMIC_YEAR_FROM_"),
        width: 150,
        dataIndex: "yearFrom",

        fixed: "left",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        // title: "Academic Year To",
        title: this.context.translate("_ACADEMIC_YEAR_TO_"),
        width: 150,
        dataIndex: "yearTo",

        fixed: "left",
      },

      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        fixed: "right",
        width: 50,
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    // title="Edit Calendar"
                    title={this.context.translate("_EDIT_SCHOOL_CALENDER_")}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      // title="Delete Calendar"
                      title={this.context.translate("_DELETE_SCHOOL_CALENDER_")}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }
  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const beforeEditCompanyId =
        this.context.viewStateStore.schoolCalendarObject.companyId;
      if (beforeEditCompanyId) {
        await this.schoolCalenderStor.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: beforeEditCompanyId,
        });
        const singleData = await this.companyStore.getCompanyDataById(
          this.context.viewStateStore.schoolCalendarObject.companyId
        );
        await this.setState({ listUpdated: 1 }); //need to reload data
        await this.setState({ company_value_label: singleData });
        await this.setState({ listDataUpdate: false });
      }
    }
  }

  onEdit = async (record) => {
    // console.log(record.passengerId)
    const calenderId = record.calenderId;
    await this.context.viewStateStore.setSchoolCalendarStates({
      companyId: record.companyId,
    });
    if (calenderId) {
      // this.props.navigate("/schoolcalenderadd/" + calenderId
      await this.setState({ calenderId: calenderId });
      await this.setState({ openCalenderForm: true });
    }
  };
  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ company_value_label: singleData });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.schoolCalenderStor.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }
  async onDelete(record) {
    const response = await this.schoolCalenderStor.deleteRecord({
      calenderId: record.calenderId,
    });
    // console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.success == "S") {
      message.success(resp.message);
      // message.success("Calender Details Saved");
      await this.setState({ openCalenderForm: false });
      await this.setState({ listDataUpdate: true });

      //  await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    } else {
      message.error(resp.message);
    }
  };
closeModal=()=>{
  this.setState({ openCalenderForm: false, calenderId: "" });
}
 

  render() {
    console.log("in render");
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_SCHOOL_CALENDER_")}
                {/* School Calendar */}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton title={this.context.translate('_ADD_')} link ="/schoolcalenderadd" /> */}
                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  placement="left"
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({ openCalenderForm: true, calenderId: "" })
                    }
                  ></Button>
                </Tooltip>

                <Button type="primary">
                  <i
                    class="bi bi-speedometer2"
                    style={{ marginRight: "5px" }}
                  ></i>

                  {this.context.translate("_DASH_BOARD_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          {/* <div className='heading'>Grade Bus Timings</div> */}
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  // allowClear={true}
                  value={
                    this.state.company_value_label
                      ? this.state.company_value_label
                      : null
                  }
                  onChange={this.handleChange}
                  style={{ width: 200 }}
                />
              </Space>
            </Col>
            {/* <Col span={8}>
                  <Space style={{float:this.context.locale.direction=="ltr"?"right":"left"}}> 
                    <AddButton title={this.context.translate('_ADD_')} link ="/schoolcalenderadd" />
                 
                      <Button>  
                       DashBoard
                      </Button>
                   
                  </Space>
                </Col> */}
          </Row>

          <ListTable
            pageSize={this.state.pageSize}
            current={this.state.currentPage}
            total={this.schoolCalenderStor?.total}
            columns={this.columns}
            dataSource={this.schoolCalenderStor?.schoolCalender}
            loading={this.schoolCalenderStor?.loading}
            onChange={this.handleTableChange}
          />

          {this.state.openCalenderForm && (
            <Modal
              bodyStyle={{ height: "637px" }}
              className="formShowModal" //  {/* css class */}
              // title="School Calendar Details"
              title={this.context.translate("_SCHOOL_CALENDER_DETAILS_")}
              centered
              visible={this.state.openCalenderForm}
              footer={null}
              onCancel={() => {
                this.setState({ openCalenderForm: false, calenderId: "" });
              }}
              width={665}
            >
              <div>
                <SchoolCalenderAdd
                  calenderId={this.state.calenderId}
                  response={this.responseMsg}
                  closeModal={this.closeModal}
                />
              </div>
            </Modal>
          )}
        </div>
      </>
    );
  }
}
SchoolCalenderList.contextType = StoreContext;
export default observer(withRouter(SchoolCalenderList));
