import React from "react";
import {
  Select,
  Checkbox,
  Table,
  Row,
  Col,
  Button,
  Space,
  DatePicker,
  Dropdown,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import config from "../config";
import { DownOutlined } from "@ant-design/icons";
import GeofenceVisitReportStore from "../store/GeofenceVisitReportStore";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import withRouter from "../withRouter";

import VehicleStore from "../store/VehicleStore";

import dayjs from "dayjs";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Cookies from "js-cookie";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
import DetailedJobStore from "../store/DetailedJobReportStore";
const { RangePicker } = DatePicker;

const { Option } = Select;
class DetailedJobReport extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.geofenceVisitReportStore = new GeofenceVisitReportStore();
    this.vehicleStore = new VehicleStore();
    this.detailedJobReportStore = new DetailedJobStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      selectedVehicles: [],
      selectAllVehicles: false,
      showMonthPopup: false,
      data: "",
      companyId: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      Notcompany: false,
      Notasset: false,
      expandedRowKeys: [],
      loadedInvoiceData: {},

      selectedColumns: [
        {
          title: context.translate("_FLEET_REPORTS_DATE_"),
          dataIndex: "date",
          key: "date",
        },
        {
          title: context.translate("_DRIVERID_"),
          dataIndex: "driverName",
          key: "driverName",
        },
        {
          title: context.translate("_VEHICLE_NO_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",
        },
        {
          title: context.translate("_VEHICLE_START_TIME_"),
          dataIndex: "startTime",
          key: "startTime",
        },
        {
          title: context.translate("_VEHICLE_STOP_TIME_"),
          dataIndex: "stopTime",
          key: "stopTime",
        },
        {
          title: context.translate("_START_ODOMETER_"),
          dataIndex: "startDistance",
          key: "startDistance",
        },
        {
          title: context.translate("_END_ODOMETER_"),
          dataIndex: "stopDistance",
          key: "stopDistance",
        },
        {
          title: context.translate("_TOTAL_KM_DAY_"),
          dataIndex: "distanceTravel",
          key: "distanceTravel",
        },
        {
          title: context.translate("_TOTAL_CUSTOMER_"),
          dataIndex: "totalcust",
          key: "totalcust",
        },
        {
          title: context.translate("_FLEET_REPORTS_FLEETREPORT_AVERAGESPEED_"),
          dataIndex: "avgspeed",
          key: "avgspeed",
        },
        {
          title: context.translate("_MAXIMUM_SPEED_"),
          dataIndex: "maxSpeed",
          key: "maxSpeed",
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
        },

        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          var getData = {
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            colList: result.toString(),
            language: null,
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.detailedJobReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.detailedJobReportStore.exportpdf(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }

  async componentDidMount() {
    this.setState({ pageSize: 50 });

    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
    await this.setState({ column: this.state.selectedColumns });
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });
    await this.setState({
      selectedVehicles: [],
      selectAllVehicles: false,
    });

    await this.vehicleStore.getVehiclesCompanyId(comp?.value);

    await this.setState({ loadData: 1 });
  }

  async handleSearch() {
    this.setState({ Notasset: false });
    this.setState({ loading: true });
    await this.detailedJobReportStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      pageNo: this.state.currentPage,
    });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };
  checkFromDate(dates) {
    
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
     
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }

  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };
  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }

    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
      await this.detailedJobReportStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
        vehicleId: this.state.selectedVehicles,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        pageNo: this.state.currentPage,
      });
      await this.setState({ loadData: 1 });
    }
}
  };

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  onExpand = async (expanded, record) => {
    const expandedRowKeys = expanded ? [record.startTime] : [];
    this.setState({ expandedRowKeys });

    if (expanded) {
      await this.handleExpandRow(record);
    }
  };

  handleExpandRow = async (record) => {
    // console.log(record);
    if (!this.state.loadedInvoiceData[record.startTime]) {
      await this.detailedJobReportStore.loadTripData({
        vehicleId: record.vehicleId,
        fromDate: record.startTime,
        toDate: record.stopTime,
      });
      this.setState((prevState) => ({
        loadedInvoiceData: {
          ...prevState.loadedInvoiceData,
          [record.startTime]: true,
        },
      }));
    }
  };

  expandedRowRender = (id) => {
    // this.handleExpandRow(id);
    // console.log(x);
    const columns = [
      {
        title: this.context.translate("Sr No."),
        dataIndex: "sno",
        key: "sno",
        width: "10%",
      },
      {
        title: this.context.translate("_INVOICE_NO_"),
        dataIndex: "invoiceNo",
        key: "invoiceNo",
        width: "10%",
      },
      {
        title: this.context.translate("_INVOICE_AMOUNT_"),
        dataIndex: "invoiceAmount",
        key: "invoiceAmount",
        width: "10%",
      },
      {
        title: this.context.translate("_QUANTITY_"),
        dataIndex: "quantity",
        key: "quantity",
        width: "10%",
      },
      {
        title: this.context.translate("_CUSTOMER_NAME_"),
        dataIndex: "customerName",
        key: "customerName",
        width: "10%",
      },
      {
        title: this.context.translate("_DELIVERY_STATUS_"),
        dataIndex: "deliveryStatus",
        key: "deliveryStatus",
        width: "10%",
      },
      {
        title: this.context.translate("_JOB_STARTED_TIME_"),
        dataIndex: "jobStartedTime",
        key: "jobStartedTime",
        width: "10%",
      },

      {
        title: this.context.translate("_JOB_FINISHED_TIME_"),
        dataIndex: "jobFinishedTime",
        key: "jobFinishedTime",
        width: "10%",
      },
      {
        title: this.context.translate("_FLEET_CREATEROUTE_ROUTEADD_TOTALTIME_"),
        dataIndex: "totalduration",
        key: "totalduration",
        width: "10%",
      },
      {
        title: this.context.translate("_TRAVEL_KM_"),
        dataIndex: "travelKm",
        key: "travelKm",
        width: "10%",
      },
    ];

    return (
      <Table
        bordered
        columns={columns}
        // scroll={{ x: 50 }}
        dataSource={this.detailedJobReportStore.tripData}
        pagination={false}
      />
    );
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed">
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_DETAILED_JOB_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.detailedJobReportStore?.detailedJobData?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
                {/* {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles: [],
                        selectAllVehicles: false,
                      })
                    }
                  />
                )} */}
                <Checkbox
                  checked={this.state.selectAllVehicles}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllVehicles: e.target.checked,
                    });
                    if (this.state.selectAllVehicles) {
                      await this.setState({
                        Notasset: false,
                      });
                    }
                    console.log(e.target.checked);
                    await this.handleAllSelectVehicles();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}

                <Select
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }

                    if (val?.length > 1) {
                      console.log(val?.length > 1);
                      this.setState({ showAlertPopup: true });
                    }
                  }}
                  style={{ width: 200 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  mode="multiple"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>

                <RangePicker
                  placeholder={[
                    this.context.translate("_FROM_DATE_TIME_"),
                    this.context.translate("_TO_DATETIME_"),
                  ]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    this.showData();
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            rowKey="startTime"
            expandable={{
              expandedRowRender: this.expandedRowRender,
              onExpand: this.onExpand,
              expandedRowKeys: this.state.expandedRowKeys,
            }}
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.detailedJobReportStore?.total}
            columns={this.state.column}
            dataSource={this.detailedJobReportStore?.detailedJobData}
            loading={this.detailedJobReportStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        <Modal
            width={350}
            className="alert-model"
            title={
              <div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-exclamation-triangle"
                ></i>{" "}
                {this.context.translate("_FLEET_ALERT_")}
              </div>
            }
            open={this.state.showMonthPopup}
            onOk={() => this.setState({ showMonthPopup: false })}
            onCancel={() => this.setState({ showMonthPopup: false })}
            footer={false}
          >
            {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
          </Modal>
      </>
    );
  }
}
DetailedJobReport.contextType = StoreContext;
export default observer(withRouter(DetailedJobReport));
