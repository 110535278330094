import React, { useContext } from "react";
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from "recharts";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import { observer } from "mobx-react";

const IdleExIdleGraphComponent = (props) => {
  const storeContext = useContext(StoreContext);
  let x = props.data;

  const data = x;

  const CustomTooltip = ({ active, payload }) => {
    console.log(active, payload);
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      console.log(data);
      return (
        <div
          style={{ border: "1px solid", background: "white", padding: "10px" }}
        >
          <div>{`${props.graphName} ${storeContext.translate("_DURATION_")}: ${
            data.duration.toFixed(2) ? data.duration.toFixed(2) : "-"
          }`}</div>
          <div>{`${storeContext.translate("_VEHICLE_NO_")}: ${
            data.vehicleNo ? data.vehicleNo : "-"
          }`}</div>
          <div>{`${storeContext.translate("_DRIVER_NAME_")}: ${
            data.name ? data.name : "-"
          }`}</div>
          <div>{`${storeContext.translate("_DATE_")}: ${
            data.idleFrom ? data.idleFrom : "-"
          }`}</div>
          <div>{`${storeContext.translate("_DRIVER_MOBILE_NO_")}: ${
            data.driverphone ? data.driverphone : "-"
          }`}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer height={400}>
      <BarChart width={500} data={data}   margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 50,
                  }}>
        <XAxis
          type="category"
          dataKey="idleFrom"
          height={150}
          textAnchor="end"
          label={{
            value: storeContext.translate("_DATE_"),
            position: "bottom",
            fontWeight: "bold",
            fontSize: "17px",
          }}
          angle={
            storeContext.locale.language === "english" ? -90 : 90
          }
          // interval={Math.ceil(
          //   data.length / 35
          // )}
          maxHeight={100}
        />

        <YAxis
          label={{
            value: props.yAxisLabel,
            angle: -90,
            // position: "insideLeft",
            fontWeight: "bold",
            fontSize: "17px",
            // position: "insideMiddle",
          }}
          style={{
            direction:
              storeContext.locale.language === "arabic" ? "ltr" : "ltr",
          }}
        />

        {/* <Legend /> */}
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="duration" stackId="a" fill="orange"></Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

IdleExIdleGraphComponent.contextType = StoreContext;
export default observer(withRouter(IdleExIdleGraphComponent));
