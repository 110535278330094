import React, { Component } from "react";

import {
  Form,
  message,
  Select,
  Button,
  Input,
  DatePicker,
  Checkbox,
  Space,
  Row,
  Col,
  Modal,
  Alert,
  Spin,
} from "antd";

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";

import WareHouseStore from "../store/WareHouseStore";
import { Link } from "react-router-dom";
import CompanyAutocomplete from "./CompanyAutocomplete";
import CompanyStore from "../store/CompanyStore";
import Autocomplete from "react-google-autocomplete";
import moment from "moment";
import config from "../config";
import { stubTrue } from "lodash";
import dayjs from "dayjs";
import DisplayLocationMap from "./DisplayLocationMap";
import { CloseOutlined } from "@ant-design/icons";

class WarehouseAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      companyId: "",
      loadList: 0,
      search: false,
      warehouseId: -1,
      offset: 0,
      limit: 50,
      addressInfo: [],
      addressMap: false,
      update: 0,
      errorShow: false,
      loadingSpin: false,
    };
    this.wareHouseStore = new WareHouseStore();
    this.companyStore = new CompanyStore(this);
  }

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      this.context.viewStateStore.setWarehouseStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      console.log(this.state.companyId);
      // await this.setState({userId:this.props.getUserData().userId})
      // await this.setState({isadmin:this.props.getUserData().isadmin})
    }
    console.log(this.props.params);
    // const companyId = this.props.params.companyId;
    const vehicleId = this.props.vehicleId;

    if (vehicleId) {
      await this.setState({ loadingSpin: true });
      const getWarehouse = await this.wareHouseStore.getWareHouse(vehicleId);
      console.log(getWarehouse);
      await this.setState({
        companyId: getWarehouse.companyId,
        warehouseId: getWarehouse.id,
      });
      const singleData = await this.companyStore.getCompanyDataById(
        getWarehouse.companyId
      );

      console.log(getWarehouse);
      await this.formRef.current.setFieldsValue({
        companyId: singleData,
        wname: getWarehouse.warehouse,
        city: getWarehouse.city,
        address: getWarehouse.address,
        longitude: getWarehouse.longitude,
        latitude: getWarehouse.latitude,
        landcordinate: getWarehouse.landCoordinates,
        licenseno: getWarehouse.licenseNo,
        issueDate: getWarehouse.licenseIssueDate
          ? dayjs(getWarehouse.licenseIssueDate)
          : null,

        expiryDate: getWarehouse.licenseExpiryDate
          ? dayjs(getWarehouse.licenseExpiryDate)
          : null,
        Phone: getWarehouse.phone,
        managermobno: getWarehouse.managerMobile,
        email: getWarehouse.email,
        landarea: getWarehouse.landArea,
        managermobnoCountryCode: getWarehouse.managermobnoCountryCode,
      });
      console.log(getWarehouse.licenseExpiryDate);
      await this.setState({ loadingSpin: false });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.addressMap &&
      prevState.addressMap !== this.state.addressMap
    ) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 10);
    }
  }
  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  onWarehouseDetailsSubmit = async (values) => {
    this.setState({ loading: true });
    console.log(values);
    const data = {
      ...values,
      companyId: this.state.companyId,
      warehouseId: this.state.warehouseId,

      issueDate: values.issueDate
        ? values.issueDate.format("YYYY-MM-DD")
        : null,
      expiryDate: values.issueDate
        ? values.expiryDate.format("YYYY-MM-DD")
        : null,
    };
    console.log(data);
    const response = await this.wareHouseStore.addData(data);
    this.props.response(response);
    // if (response?.success === "S") {
    //   message.success("Warehouse Details Saved");
    //   this.props.navigate("/warehouselist");
    // } else {
    //   message.error(response.message);
    // }
    this.setState({ loading: false });
  };

  async handleChange(comp) {
    console.log(comp.value);
    console.log(this.props.companyId, "EDITRRRRR");
    await this.setState({ companyId: comp.value });
    if (!this.props.vehicleId) {
      this.context.viewStateStore.setWarehouseStates({ companyId: comp.value });
    }
    // console.log(comp.value);
    await this.setState({ companyId: comp.value });
    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });
    await this.handleSearch();
  }

  async handleSearch() {
    await this.notificationStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  validateNumber = (value, field1) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1.length);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field1]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field1]: "" });
    }
  };
  validateFieldsValue = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9.]*/g, "");
    console.log(value1);
    if (value1) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  closeModal = () => {
    this.props.closeModal();
  };
  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              ref={this.formRef}
              layout="vertical"
              onFinish={this.onWarehouseDetailsSubmit}
              onFinishFailed={(e) => this.setState({ errorShow: true })}
              // {...this.layout}
              name="nest-message"
              className="formClass"
            >
              {/* <div className="formBorder"> */}
              {/* <div className="headingTitle"> Warehouse</div> */}
              <Row className="overflowFormInputs">
                {/* {this.state.errorShow && (
                  <Alert
                    style={{ marginLeft: "140px", width: "60%" }}
                    // message="Please fill correct data in the fields that are marked red."
                    message={this.context.translate(
                      "_FLEET_GROUP_GROUPDETAIL_ERRORMSG_"
                    )}
                    type="error"
                  />
                )} */}

                <Col span={12} className="formColumnClass">
                  {/* Extra field warehouseId */}

                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate(
                        "_FLEET_DASHBORD_SEARCHPOI_COMPANY_"
                      )}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => this.handleChange(value)}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="wname"
                    label={this.context.translate("_NAME_WAREHOUSE_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="city"
                    label={this.context.translate("_CITY_OF_WAREHOUSE_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item name="select" label=" ">
                    <Checkbox
                      checked={this.state.search}
                      onChange={(e) => {
                        this.setState({ search: e.target.checked });
                      }}
                    >
                      {/* Select From Map */}
                      {this.context.translate("_SELECT_FROM_MAP")}
                    </Checkbox>
                  </Form.Item>

                  <div style={{ display: this.state.search ? "" : "none" }}>
                    <Form.Item
                      name="searchAddress"
                      label={this.context.translate("_SEARCH_ADDRESS_")}
                    >
                      <Autocomplete
                        style={{
                          width: "100%",
                          height: "48px",
                          boxShadow: "none",
                          padding: "4px 11px",
                          borderWidth: 1,
                          borderStyle: "solid",
                          borderColor: "#d9d9d9",
                          borderRadius: "6px",
                        }}
                        // apiKey={"AIzaSyAu2h2n_gORgPafUoADJBdEuyjN-zlr8VM"}
                        apiKey={config.googleMapKey}
                        onPlaceSelected={async (place) => {
                          console.log(place);
                          await this.formRef.current.setFieldsValue({
                            searchAddress: place.formatted_address,
                            latitude: place.geometry.location.lat(),
                            longitude: place.geometry.location.lng(),
                          });
                          await this.setState({ addressMap: true });
                          await this.setState({
                            addressInfo: [
                              place.geometry.location.lat(),
                              place.geometry.location.lng(),
                            ],
                          });
                        }}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="address"
                    label={this.context.translate("_ADDRESS_OF_WAREHOUSE_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Row>
                    <Col span={11}>
                      <Form.Item
                        name="latitude"
                        label={this.context.translate(
                          "_FLEET_DASHBORD_SETTING_LATITUDE_"
                        )}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={2} />
                    <Col span={11}>
                      <Form.Item
                        name="longitude"
                        label={this.context.translate(
                          "_FLEET_DASHBORD_SETTING_LONGITUDE_"
                        )}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    name="landcordinate"
                    label={this.context.translate("_LAND_CORDINATES_")}
                  >
                    <Autocomplete
                      style={{
                        width: "100%",
                        height: "48px",
                        boxShadow: "none",
                        padding: "4px 11px",
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#d9d9d9",
                        borderRadius: "6px",
                      }}
                      // defaultValue={this.state.addressInfo}
                      // apiKey={"AIzaSyAu2h2n_gORgPafUoADJBdEuyjN-zlr8VM"}
                      placeholder="e.g lat1:long1,lat2:long2,lat3:long3,lat4:long4"
                      apiKey={config.googleMapKey}
                      onPlaceSelected={async (place) => {
                        // console.log(place);
                        await this.formRef.current.setFieldsValue({
                          landcordinate: place.formatted_address,
                          // latitude: place.geometry.location.lat(),
                          // longitude: place.geometry.location.lng(),
                        });
                        await this.setState({ addressMap: true });
                        await this.setState({
                          addressInfo: [
                            place.geometry.location.lat(),
                            place.geometry.location.lng(),
                          ],
                        });
                      }}
                    />
                    {/* <Input placeholder="e.g lat1:long1,lat2:long2,lat3:long3,lat4:long4" /> */}
                  </Form.Item>
                </Col>

                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="licenseno"
                    label={this.context.translate("_LICENSE_NUMBER_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="issueDate"
                    label={this.context.translate("_LICENSE_ISSUE_DATE_")}
                    rules={[{ required: true }]}
                  >
                    <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
                  </Form.Item>

                  <Form.Item
                    name="expiryDate"
                    label={this.context.translate("_LICENSE_EXPIRY_DATE_")}
                    rules={[{ required: true }]}
                    style={{ width: "100%" }}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format="YYYY-MM-DD"
                      disabledDate={(current) => {
                        return current && current < dayjs();
                      }}
                    />
                  </Form.Item>

                  {/* <Form.Item
                    name="Phone"
                    label={this.context.translate("_PHONE_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      type="tel"
                      maxLength={13}
                      onChange={(e) => {
                        this.validateNumber(e.target.value, "Phone");
                      }}
                    />
                  </Form.Item> */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      style={{ width: "30%" }}
                      name="countryCode"
                      // label="Contact 1"
                      label={" "}
                      // rules={[{ required: true }]}
                      rules={[{ required: true }]}
                      initialValue={+966}
                    >
                      <Select>
                        <Select.Option value={91}>+91</Select.Option>
                        <Select.Option value={966}>+966</Select.Option>

                        <Select.Option value={971}>+971</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      style={{ width: "65%" }}
                      name="Phone"
                      // label="Phone Number 1"
                      label={this.context.translate("_PHONE_")}
                      rules={[
                        {
                          required: true,
                          pattern: /^\d{8,10}$/,
                          // message: "Enter a 7 or 10 digit number",
                          message: this.context.translate(
                            "_Enter_7_or_10_Digit_Number_"
                          ),
                        },
                      ]}
                      // rules={[{ required: true}]}
                    >
                      <Input
                        onChange={(e) =>
                          this.validateNumber(e.target.value, "Phone")
                        }
                        // maxLength={13}
                      />
                    </Form.Item>
                  </div>

                  {/* <Form.Item
                    name="managermobno"
                    label={this.context.translate("_MANAGER_MOBILE_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      type="tel"
                      maxLength={13}
                      onChange={(e) => {
                        this.validateNumber(e.target.value, "managermobno");
                      }}
                    />
                  </Form.Item> */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      style={{ width: "30%" }}
                      name="managermobnoCountryCode"
                      // label="Contact 1"
                      label={" "}
                      // rules={[{ required: true }]}
                      rules={[{ required: true }]}
                      initialValue={+966}
                    >
                      <Select>
                        <Select.Option value={91}>+91</Select.Option>
                        <Select.Option value={966}>+966</Select.Option>

                        <Select.Option value={971}>+971</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      style={{ width: "65%" }}
                      name="managermobno"
                      // label="Phone Number 1"
                      label={this.context.translate("_MANAGER_MOBILE_")}
                      rules={[
                        {
                          required: true,
                          pattern: /^\d{8,10}$/,
                          // message: "Enter a 7 or 10 digit number",
                          message: this.context.translate(
                            "_Enter_7_or_10_Digit_Number_"
                          ),
                        },
                      ]}
                      // rules={[{ required: true}]}
                    >
                      <Input
                        onChange={(e) =>
                          this.validateNumber(e.target.value, "managermobno")
                        }
                        // maxLength={13}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="email"
                    label={this.context.translate("_WAREHOUSE_EMAIL_")}
                    rules={[{ required: true, type: "email" }]}
                    // rules={[{  }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="landarea"
                    label={this.context.translate("_WAREHOUSE_LAND_AREA_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      onChange={(e) => {
                        this.validateFieldsValue(e.target.value, "landarea");
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal();
                    // this.formRef.current?.resetFields();
                    this.setState({ search: false });
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="formSaveButton"
                  loading={this.state.loading}
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>

              {/* </div> */}
            </Form>
          </Spin>
        </div>

        {this.state.addressMap && (
          <Modal
            style={{ padding: "0px !important" }}
            className="multiTracking-popup"
            title=<div>
              Location On Map
              <CloseOutlined
                onClick={() => this.setState({ addressMap: false })}
              />
            </div>
            maskClosable={false}
            centered
            bodyStyle={{ height: "60vh", padding: "0px" }} // Adjust the dimensions as needed
            width={1000}
            open={this.state.addressMap}
            onCancel={() => this.setState({ addressMap: false })}
            footer={null}
          >
            <DisplayLocationMap
              addressInfo={
                this.state.addressInfo.length > 0 && this.state.addressInfo
              }
              formRef={this.formRef}
              address={(address) =>
                this.formRef.current.setFieldsValue({
                  searchAddress: address,
                  landcordinate: address,
                })
              }
            />
          </Modal>
        )}
      </div>
    );
  }
}

WarehouseAdd.contextType = StoreContext;
export default observer(withRouter(WarehouseAdd));
