import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import { act } from "react-dom/test-utils";
import Cookies from "js-cookie";
class EmailMessageStore {
  emailMessage = [];
  allCompanyArray = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      emailMessage: observable,
      allCompanyArray: observable,
      total: observable,
      loading: observable,
      loadData: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ offset, limit }) {
    this.loading = true;
    var postData = {
      value: "",
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=getCompanyList", "postData=" + base64Input);
    this.allCompanyArray = response.data.data.companies;
    //  console.log(response.data.data.companies)
    //  return response
  }

  async sendEmailMessage(obj) {
    // this.loading = true;

    var postData = obj;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=user&a=sendEmailMessage", "postData=" + base64Input);
    return response;
  }
}

export default EmailMessageStore;
