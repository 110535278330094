import React from "react";
import BlackListPersonStore from "../store/BlackListPersonStore";
import {
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Select,
  Table,
  Button,
  Typography,
  Space,
  Tag,
  Avatar,
  Row,
  Col,
  Dropdown,
  Tooltip,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Await, Link } from "react-router-dom";
import {
  DeleteFilled,
  DownloadOutlined,
  EditFilled,
  PlusOutlined,
  UserOutlined,
  DownOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import AddButton from "./AddButton";
import DeleteConfirm from "./DeleteConfirm";
import FuelDetailsStore from "../store/FuelDetailsStore";
import FuelDetailsAdd from "./FuelDetailsAdd";
import config from "../config";
import CompanyAutocomplete from "./CompanyAutocomplete";
import CompanyStore from "../store/CompanyStore";

const { Search } = Input;

class FuelDetailsList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.companyStore = new CompanyStore(this);
    // this.onSearch = this.onSearch.bind(this);
    this.fuelDetailsStore = new FuelDetailsStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      company_value_label: "",
      openFuelForm: false,
      fuelId: "",
      listDataUpdate: false,
    };
  }
  onEdit = async (record) => {
    console.log(record.roleId, "dd");
    await this.context.viewStateStore.setFuelDetailsStates({
      companyId: record.companyId,
    });
    await this.setState({ fuelId: record.fuelId });
    await this.setState({ openFuelForm: true });
    console.log(record.fuelId);

    //  await this.props.navigate("/fueldetailsadd/" + record.fuelId);
  };
  async onDelete(record) {
    const response = await this.fuelDetailsStore.deleteRecord({
      fuelId: record.fuelId,
    });
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  async componentDidMount() {
    if(Number(this.props.getUserData().isadmin)!==-1 && Number(this.props.getUserData().isadmin)!==3 ){
       console.log(this.props.getUserData().userCompanyId)
       await this.setState({companyId:this.props.getUserData().userCompanyId})
      //  console.log(this.state.companyId)
       await this.setState({userId:this.props.getUserData().userId})
       await this.handleSearch();
  }
    // await this.companyStore.loadData({ offset: 0, limit: 50 });

    const companyId = this.props.params.id;
    // if (companyId) {
    //   await this.fuelDetailsStore.loadData({
    //     offset: (this.state.currentPage - 1) * this.state.pageSize,
    //     limit: this.state.pageSize,
    //     companyId: companyId,
    //   });
    // }
    this.setState({ pageSize: 50 });
    // await this.handleSearch();

    this.columns = [
      {
        // title: "Company Name",
        title:this.context.translate("_COMPANY_NAME_"),
        dataIndex: "company",
        width: "35%",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        // title: "Petrol",
        title:this.context.translate("_FLEET_VEHICLEDETAIL_FUELTYPEDDL_PETROL"),
        dataIndex: "petrol",
        width: "30%",
      },
      {
        // title: "Diesel",
        title:this.context.translate("_FLEET_VEHICLEDETAIL_FUELTYPE_DIESEL_"),
        dataIndex: "Diesel",
        width: "30%",
      },
      {
        // title: "Action",
        title:this.context.translate("_ACTION_"),
        dataIndex: "groupname",
        width: "5%",
        render: (_, record) => {
          return (
            <>
              <Space>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    // title="Edit Fuel"
                    title={this.context.translate("_EDIT_FUEL_DETAILS_")}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link>
                <DeleteConfirm
                  onConfirm={(e) => {
                    this.onDelete(record);
                  }}
                >
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Tooltip
                      // title="Delete Fuel Details"
                      title={this.context.translate("_DELETE_FUEL_DETAILS_")}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ currentPage: 1, companyId: comp.value });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ company_value_label: singleData });

    await this.handleSearch();
  }

  // async onSearch(value) {
  //     console.log(value)
  //     await this.setState({ emailId: value });
  //     this.handleSearch();
  // };

  async handleSearch() {
    this.setState({ loading: true });
    await this.fuelDetailsStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });
    await this.setState({ listUpdated: 1 }); //need to reload data
  }

  responseMsg = async (response) => {
    console.log(response);
    if (response?.success === "S") {
      message.success("Fuel Details Saved");
      await this.setState({ listDataUpdate: true });
      await this.setState({ openFuelForm: false });

      //  await this.handleSearch();
      //   this.props.navigate("/fueldetailslist/" + this.state.companyId);
      // this.props.navigate("/fueldetailslist");
    } else {
      message.error(response.message);
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.state.openFuelForm &&
      prevState.openFuelForm !== this.state.openFuelForm
    ) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 10);
    }

    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const beforeEditCompanyId =
        this.context.viewStateStore.fuelDetailsObject.companyId;

      if (beforeEditCompanyId !== "") {
        await this.fuelDetailsStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: beforeEditCompanyId,
        });
        const singleData = await this.companyStore.getCompanyDataById(
          this.context.viewStateStore.fuelDetailsObject.companyId
        );
        await this.setState({ listUpdated: 1 }); //need to reload data
        await this.setState({ company_value_label: singleData });
      }
      await this.setState({ listDataUpdate: false });
    }
  }

  closeModal=()=>{
    this.setState({ openFuelForm: false, userId: "" });
  }
  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Fuel Detail List</div> */}
              <div className="heading">{this.context.translate("_FUEL_DETAILS_LIST_")}</div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                {/* <AddButton
                  link="/fueldetailsadd"
                  title={this.context.translate("_ADD_")}
                /> */}
                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  color={config.primaryColor}
                  placement="left"
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      this.setState({ openFuelForm: true, fuelId: "" });
                      console.log("Sammek");
                    }}
                  ></Button>
                </Tooltip>

                <Button
                  disabled={
                    this.fuelDetailsStore?.fuelDetail?.length > 0 ? false : true
                  }
                  style={{ backgroundColor: config.activeColor }}
                  onClick={(val) => {
                    console.log(val);
                    const result = [];
                    this.columns.map((obj) => result.push(obj.dataIndex));
                    //  console.log(result.toString())
                    var getData = {
                      cid: this.state.companyId,
                      colList: result,
                    };
                    console.log(getData);
                    if (val) {
                      this.fuelDetailsStore.exportExcel(getData);
                    }
                  }}
                >
                  {/* Export */}
                  {this.context.translate("_EXPORT_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={(value) => this.handleChange(value)}
                  value={
                    this.state.company_value_label
                      ? this.state.company_value_label
                      : null
                  }
                  // allowClear={true}
                  style={{ width: 200 }}
                />
              </Space>
            </Col>
          </Row>

          <ListTable
            current={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.fuelDetailsStore?.total}
            columns={this.columns}
            dataSource={this.fuelDetailsStore?.fuelDetail}
            loading={this.fuelDetailsStore?.loading}
            onChange={this.handleTableChange}
          />

          {this.state.openFuelForm && (
            <Modal
              bodyStyle={{ height: "637px" }}
              className="formShowModal" //  {/* css class */}
              // title="Fuel Details"
              title={this.context.translate("_FUEL_DETAIL_")}
              centered
              visible={this.state.openFuelForm}
              footer={null}
              onCancel={() => {
                this.setState({ openFuelForm: false, userId: "" });
              }}
              width={665}
            >
              <div>
                <FuelDetailsAdd
                  fuelId={this.state.fuelId}
                  response={this.responseMsg}
                  closeModal={this.closeModal}
                />
              </div>
            </Modal>
          )}
        </div>
      </>
    );
  }
}
FuelDetailsList.contextType = StoreContext;
export default observer(withRouter(FuelDetailsList));
