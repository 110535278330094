import React, { Component } from "react";
import { Button, Col, Form, Input, Row, Spin } from "antd";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";

import TaxiTypeStore from "../store/TaxiTypeStore";

class TaxiTypeAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = { loadingSpin: false, Id: -1 };

    this.taxiTypeStore = new TaxiTypeStore(this);
  }
  async componentDidMount() {
   

    const vehicleTypeId = this.props.vehicleTypeId;

    if (vehicleTypeId) {
      await this.filldata(vehicleTypeId);
    }

    this.setState({ update: 1 });
  }

  async filldata(vehicleTypeId) {
    const getTaxiData = await this.taxiTypeStore.getTaxiData(vehicleTypeId);

    this.setState({ Id: getTaxiData.vehicleTypeId });

    this.formRef.current.setFieldsValue({
      Id: getTaxiData.vehicleTypeId,
      taxiType: getTaxiData.vehicleType,
    });
  }

  onTaxiDetailSubmit = async (fieldsValue) => {
    const data = {
      ...fieldsValue,
      Id: this.state.Id,
      isDelete: 0,
    };

    this.setState({ loading: true });
    const response = await this.taxiTypeStore.addData(data);
    await this.props.response(response);

    this.setState({ loading: false });
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              ref={this.formRef}
              layout="vertical"
              name="nest-messages"
              onFinish={this.onTaxiDetailSubmit}
              style={{ maxWidth: "100%", padding: 0 }}
              className="formClass"
            >
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="taxiType"
                    label={this.context.translate("_TAXI_TYPE_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal();
                  }}
                >
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="formSaveButton"
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}
TaxiTypeAdd.contextType = StoreContext;
export default observer(withRouter(TaxiTypeAdd));
