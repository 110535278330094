import React, { Component } from "react";

import {
  Form,
  Select,
  Button,
  message,
  Input,
  DatePicker,
  Modal,
  Checkbox,
  Upload,
  Row,
  Col,
  Space,
  TimePicker,
  Spin,
} from "antd";
import config from "../config";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import FuelFilledStore from "../store/FuelFilledStore";
import { Link } from "react-router-dom";
import CompanyAutocomplete from "./CompanyAutocomplete";
import VehicleStore from "../store/VehicleStore";
import moment from "moment";
import DriversStore from "../store/DriversStore";
import CompanyStore from "../store/CompanyStore";

import dayjs from "dayjs";
const { Option } = Select;
const currentDate = dayjs().format("YYYY-MM-DD");
console.log(currentDate);
class FuelFilledAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      companyId: "",
      fuel_Id: -1,
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      stateLoad: 0,
      formLoading: false,
    };

    this.fuelFilledStore = new FuelFilledStore(this);
    this.vehicleStore = new VehicleStore(this);
    this.driverStore = new DriversStore(this);
    this.companyStore = new CompanyStore(this);
  }

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      this.context.viewStateStore.setFuelFilledStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.vehicleStore.getVehiclesCompanyId(
        this.props.getUserData().userCompanyId
      );
      await this.driverStore.toGetDriverName(
        this.props.getUserData().userCompanyId
      );
      console.log(this.state.companyId);
      // await this.setState({userId:this.props.getUserData().userId})
      // await this.setState({isadmin:this.props.getUserData().isadmin})
    }
    console.log(this.props.params);
    const Id = this.props.fuel_Id;
    console.log(Id);
    // console.log("id",Id)
    await this.setState({ formLoading: true });
    if (Id) {
      const getFuelFilledDetails = await this.fuelFilledStore.getFuelFilledData(
        { fuel_Id: Id }
      );
      await this.setState({
        companyId: getFuelFilledDetails.companyId,
        fuel_Id: getFuelFilledDetails.fuel_Id,
      });
      const singleData = await this.companyStore.getCompanyDataById(
        getFuelFilledDetails.companyId
      );
      // await this.fuelFilledStore.getFuelFilledData({fuel_Id:Id})
      const data = await this.driverStore.toGetDriverName(
        getFuelFilledDetails.companyId
      );
      console.log(data);
      await this.vehicleStore.getVehiclesCompanyId(
        getFuelFilledDetails.companyId
      );
      await this.setState({ stateLoad: 1 });
      console.log("getdata", getFuelFilledDetails);

      this.formRef.current.setFieldsValue({
        companyId: singleData,
        dateTime: getFuelFilledDetails.dateTime
          ? dayjs(getFuelFilledDetails.dateTime)
          : null,
        //
        //    driverId:getFuelFilledDetails.driverName,
        driverId: getFuelFilledDetails.driverId,
        fromTime: getFuelFilledDetails.fromTime
          ? dayjs(getFuelFilledDetails.fromTime, "HH:mm")
          : null,
        odometer: getFuelFilledDetails.odometer,
        vehicleId: getFuelFilledDetails.vehicleId,
        fuelFilled: getFuelFilledDetails.fuelFilled,
        amount: getFuelFilledDetails.amount,
      });
    }
  //  console.log(typeof getFuelFilledDetails.vehicleId)
    await this.setState({ formLoading: false });
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  onFuelFilledDetailsSubmit = async (values) => {
    this.setState({ loading: true });
    console.log(values);
    const FuelFilled = {
      ...values,
      companyId: this.state.companyId,
      fuel_Id: this.state.fuel_Id,
      dateTime: values?.dateTime?.format("YYYY-MM-DD"),
      fromTime: values?.fromTime?.format("HH:mm"),
    };
    const data = {
      tokenId: "80bovn8u800tg1aq9bvr73kbhh",
      FuelFilled: FuelFilled,
    };
    const response = await this.fuelFilledStore.addData(data);
    // if (response?.success === "S") {
    //   message.success("Fuel Filled Details Saved");
    //   await this.props.navigate("/fuelfilledlist");
    // } else {
    //   message.error(response.message);
    // }
    this.props.response(response);
    this.setState({ loading: false });
  };
  async handleChange(comp) {
    await this.setState({ companyId: comp.value });

    await this.vehicleStore.getVehiclesCompanyId(comp.value);
    await this.driverStore.toGetDriverName(comp.value);
    if (!this.props.fuel_Id) {
      console.log("inn");
      this.context.viewStateStore.setFuelFilledStates({
        companyId: comp.value,
      });
    }
    await this.handleSearch();
    console.log(this.vehicleStore.assetVehicleList);
  }

  async handleSearch() {
    await this.fuelFilledStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  validateFieldsValue = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9.]*/g, "");
    console.log(value1);
    if (value1) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
      //   this.setState({ [field]: value1 })
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
      //   this.setState({ [field]: value1 })
    }
  };

  closeModal = () => {
    this.props.closeModal();
  };
  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.formLoading} fullscreen>
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              // style={{ maxWidth: "100%", padding: 0 }}
              layout="vertical"
              onFinish={this.onFuelFilledDetailsSubmit}
              // {...this.layout}
              initialValues={{ dateTime: dayjs(currentDate) }}
              name="nest-message"
              className="formClass" //change
              ref={this.formRef}
            >
              {/* <h1>Fuel Filled Details</h1> */}

              {/* <div className="formBorder">
            <div className="headingTitle"> Fuel Filled Details</div> */}
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => {
                          this.handleChange(value);
                          this.formRef.current.setFieldsValue({
                            vehicleId: undefined,
                            driverId: undefined,
                          });
                        }}
                        style={{ width: "100%" }}
                        companyId={this.state.companyId}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="vehicleId"
                    label={this.context.translate("_ASSET_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      // placeholder="Select VehicleNo"
                      onSelect={(val, obj) => {
                        console.log(obj);
                        this.formRef.current.setFieldsValue({
                          odometer: obj.currentOdometer - -obj.diffOdo,
                        });
                      }}
                      showSearch
                      // fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                      optionFilterProp="children"
                      // options={this.vehicleStore?.getVehiclesUsingCompanyId}
                      filterOption={(inputValue, option) => {
                        if (option.children) {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) >= 0
                          );
                        }
                      }}
                    >
                      {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                        return <Option value={val.vehicleId}>{val.vehicleNo}</Option>;
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="dateTime"
                    label={this.context.translate("_DATE_")}
                  
                  >
                    <DatePicker
                      format={"YYYY-MM-DD"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="fromTime"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_ROUTELIST_TIME_"
                    )}
                    rules={[{ required: true }]}
                  >
                    <TimePicker format={"HH:mm"} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>

                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="driverId"
                    label={this.context.translate("_DRIVER_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      // placeholder="Select Driver"
                      placeholder={this.context.translate("_SELECT_DRIVER_")}
                      showSearch
                      fieldNames={{ label: "name", value: "driverId" }}
                      optionFilterProp="children"
                      options={this.driverStore?.getDriverName}
                    />
                  </Form.Item>

                  <Form.Item
                    name="fuelFilled"
                    label={this.context.translate("_FUEL_FILLED_LITERS_")}
                  >
                    <Input
                      onChange={async (e) => {
                        await this.validateFieldsValue(
                          e.target.value,
                          "fuelFilled"
                        );
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="odometer"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_SETTING_ODOMETER_"
                    )}
                  >
                    <Input
                      onChange={async (e) => {
                        await this.validateFieldsValue(
                          e.target.value,
                          "odometer"
                        );
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="amount"
                    label={this.context.translate("_AMOUNT_")}
                  >
                    <Input
                      onChange={async (e) => {
                        await this.validateFieldsValue(
                          e.target.value,
                          "amount"
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="formBtnGroup">
                {/* <Link to="/fuelfilledlist">
                  {" "}
                  <Button ghost type="primary">
                    Back
                  </Button>
                </Link> */}

                <Button
                  className="formCancelButton"
                  // type="primary"
                  onClick={(e) => {
                    // this.formRef.current?.resetFields();
                    this.closeModal();
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  className="formSaveButton"
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>

              {/* </div> */}
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

FuelFilledAdd.contextType = StoreContext;
export default observer(withRouter(FuelFilledAdd));
