import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import config from "../config";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
  observe,
} from "mobx";
import { act } from "react-dom/test-utils";
import Cookies from "js-cookie";
import utf8 from 'utf8';
class CompanyStore {
  companies = [];

  logoFromDb = [];
  rec = 0;

  editedRecord_Id = "";

  // users=[]
  // drivers=[]
  // staff=[]
  // passengers=[]
  total = 0;
  loading = false;
  excelLoadig = false;

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      companies: observable,

      logoFromDb: observable,

      editedRecord_Id: observable,

      rec: observable,

      total: observable,
      loading: observable,
      excelLoadig: observable,
      loadData: action,
      addData: action,
      editData: action,
      updateData: action,
      setEditedRecordId: action,

      getCompanyData: action,
      getSingleCompanyData: action,
      exportExcel: action,
      exportPdf: action,
      sampleExcel: action,
      setLogoFromDb: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    offset,
    limit,

    emailId,
    status,
    company_name,
    distributorType,
    searchCompanyId,
    distCompanyId,
  }) {
    this.loading = true;

    var postData = {
      companyId: Number(
        JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId
      ),
      // searchCompanyId: searchCompanyId,
      cstatus: status,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      cdType: distributorType,
      distCompanyId:
        JSON.parse(base64_decode(Cookies.get("data"))).isadmin === 1
          ? Number(JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId)
          : distCompanyId,
      // distCompanyId:distCompanyId,
      // distCompanyId:JSON.parse(base64_decode(Cookies.get("data"))).isadmin ===1 ? Number(
      //   JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId
      // ) :0,
      cname: company_name,
      email: emailId,
      offset: offset,
      limit: limit,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=newCompanyList2", "postData=" + base64Input);
    console.log("company Data", response?.data?.data?.companydata);
    this.companies = response?.data?.data?.companydata;
    console.log(response?.data?.data?.companydata);
    if (this.companies?.length > 0) {
      this.rec = 1;
    }

    //  this.setCompanies(response?.data?.data?.companydata);
    this.total = parseInt(response?.data?.data?.TotalCount);
    console.log(this.total);
    this.loading = false;
  }
  async addData(company) {
    var postData = { company: company };
    console.log(postData);
    const base64Input = await  utf8.encode(JSON.stringify(postData));
    const base2 = JSON.stringify( base64_encode(base64Input));
    // const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=create", "postData=" + base2);
    return response.data;
  }
  async updateData(company) {
    var postData = { tokenId: "80bovn8u800tg1aq9bvr73kbhh", company: company };
    // const base64Input = await base64_encode(JSON.stringify(postData));
    const base64Input = await  utf8.encode(JSON.stringify(postData));
    const base2 = JSON.stringify( base64_encode(base64Input));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=update", "postData=" + base2);
    return response.data;
  }

  async editData(companyId) {
    var postData = { companyId: companyId };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=company&a=getCompanySingle",
        "postData=" + base64Input
      );
    console.log("editRecord", response);
    return response.data.data.companydetails;
  }

  async updateStatus(data) {
    //update active in-active
    // var postData ={ data };
    const base64Input = await base64_encode(JSON.stringify(data));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=updateActive", "postData=" + base64Input);
    return response.data;
  }

  async getSingleCompanyData(companyId) {
    console.log("edee");
    var postData = { getCompanyId: companyId, limit: 10, offset: 0 };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=newCompanyList2", "postData=" + base64Input);

    var singleRow = response.data.data.companydata[0];

    //this.logoFromDb.disabled = true;

    if (singleRow?.logo) {
      console.log(config.fleetURL + "/images/companylogo/" + singleRow.logo);

      const fileList = [
        {
          name: singleRow.logo,
          status: "success",
          url: config.fleetURL + "/images/companylogo/" + singleRow.logo,
        },
      ];
      this.setLogoFromDb(fileList);
      //  }
      console.log(this.logoFromDb);
    }

    console.log("editRecord", response);

    return response.data.data.companydata[0];
  }

  async getCompanyData(companyName, fromAccountList, showAll) {
    var postData = {
      value: companyName,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
      showAll: showAll,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=getCompanyList", "postData=" + base64Input);
    return response.data.data.companies?.map((user) => ({
      label: user.name,
      value: user.companyId,
      fromAccountList: fromAccountList,
      contact1Email: user.contact1Email,
      contact1No: user.contact1No,
    }));
  }
  async setLogoFromDb(logo) {
    this.logoFromDb = { fileList: logo };
    console.log(logo);
  }
  async getCompanyDataById(companyId, showAll) {
    // console.log(isadmin);
    var postData = {
      value: companyId,
      getBy: "companyId",
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      companyId:
        JSON.parse(base64_decode(Cookies.get("data"))).isadmin === 3
          ? companyId
          : null,
      showAll: showAll ? showAll : null,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=getCompanyList", "postData=" + base64Input);
    return response.data.data.companies.map((user) => ({
      label: user.name,
      value: user.companyId,
    }));
  }

  async deleteRecord({ companyId }) {
    var postData = { company: { companyId: companyId } };
    console.log(postData);
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=delete", "postData=" + base64Input);
    return response;
  }

  async setEditedRecordId(id) {
    console.log(id);
    this.editedRecord_Id = id;
    console.log(this.editedRecord_Id);
  }

  async exportExcel(getData) {
    // window.location.href = config.baseURL + "index.php?c=vehicle&a=exportloginexcel&cid=" +getDta.companyId + '&fD=' + getDta.fromDate + '&tD=' + getDta.toDate + '&uId=' + getDta.userid + '&colList=' + getDta.colList
    window.location.href =
      config.baseURL +
      "index.php?c=company&a=exportCompanyExcel&dType=" +
      getData.dType +
      "&isadmin=" +
      getData.isadmin +
      "&cid=" +
      getData.cid +
      "&cType=" +
      getData.cType +
      "&cName=" +
      getData.cName +
      "&cstatus=" +
      getData.cstatus +
      "&email=" +
      getData.email +
      "&colList=" +
      getData.colList;
  }
  async exportPdf(getData) {
    // window.location.href = config.baseURL + "index.php?c=vehicle&a=exportloginexcel&cid=" +getDta.companyId + '&fD=' + getDta.fromDate + '&tD=' + getDta.toDate + '&uId=' + getDta.userid + '&colList=' + getDta.colList
    // window.location.href = config.baseURL + "index.php?c=company&a=exportCompanyPdf&dType=" +getData.dType+ '&isadmin='+getData.isadmin+ '&cid='+getData.cid+ '&cType='+getData.cType +'&cName='+getData.cName + '&email='+getData.email+ '&colList='+getData.colList
    window.location.href =
      config.baseURL +
      "index.php?c=company&a=exportCompanyPdf&dType=" +
      getData.dType +
      "&isadmin=" +
      getData.isadmin +
      "&cid=" +
      getData.cid +
      "&cType=" +
      getData.cType +
      "&cName=" +
      getData.cName +
      "&email=" +
      getData.email +
      "&cstatus=" +
      getData.cstatus +
      "&colList=" +
      getData.colList;
  }

  async sampleExcel() {
    window.location.href = config.fleetURL + "/appRes/excel/companyList.xlsx";
  }

  async accountImport(excelFile, userId) {
    console.log(userId);
    this.excelLoadig = true;
    var postData = { fileName: excelFile, userId: userId };
    console.log(postData);
    console.log(this.excelLoadig);
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=company&a=importCompanyNew2",
        "postData=" + base64Input
      );
    this.excelLoadig = false;
    return response.data;
  }
}

export default CompanyStore;
