import React, { useState } from "react";
import {
  Row,
  Col,
  Space,
  DatePicker,
  message,
  Tooltip,
  Modal,
  Button,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { EditFilled, DeleteFilled, PlusOutlined } from "@ant-design/icons";
import AddButton from "./AddButton";

import VehicleFuelCalibrationStore from "../store/VehicleFuelCalibrationStore";

import withRouter from "../withRouter";
import DeleteConfirm from "./DeleteConfirm";
import config from "../config";

import ListTable from "./ListTable";
import VehicleFuelCalibrationAdd from "./VehicleFuelCalibrationAdd";

class VehicleFuelCaliborationList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.vehicleFuelCaliborationStore = new VehicleFuelCalibrationStore();

    this.state = {
      loading: false,
      offset: 0,
      limit: 50,
      pageSize: 50,
      currentPage: 1,
      fuelCalibrationId: "",
      openVehicleCalibrationForm: false,
      listDataUpdate: false,
    };
  }

  onEdit = async (record) => {
    console.log("id", record.Id);
    // this.props.navigate("/vehiclefuelcalibration/" + record.Id);
    await this.setState({ fuelCalibrationId: record.Id });
    await this.setState({ openVehicleCalibrationForm: true });
  };

  async componentDidMount() {
    
    await this.vehicleFuelCaliborationStore.loadData({ offset: 0, limit: 50 });

    this.setState({ pageSize: 50 });

    this.columns = [
      {
        // title: " Fuel Calibration Name ",
        title:this.context.translate("_FUEL_CALIBRATION_NAME_"),
        dataIndex: "fuelCalibrationName",
        width: "25%",
        render: (value, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        // title: "Fuel Tank Capacity ",
        title:this.context.translate("_FUEL_TANK_CAPACITY_"),
        dataIndex: "fuelTankCapacity",
        width: "25%",
      },
      {
        // title: "Max Voltage ",
        title:this.context.translate("_MAX_VOLTAGE_"),
        dataIndex: "maxVoltage",
        width: "25%",
      },
      {
        // title: "Min Voltage ",
        title:this.context.translate("_MIN_VOLTAGE_"),
        dataIndex: "minVoltage",
        width: "20%",
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        // fixed: 'right',
        width: "5%",
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    // title="Edit"
                    title={this.context.translate("_FLEET_COMPANY_COMPANYLIST_EDIT_")}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      // title="Delete"
                      title={this.context.translate("_FLEET_DASHBORD_RULETRIGGER_DELETE_")}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.vehicleFuelCaliborationStore.loadData({
      offset: this.state.currentPage - 1,
      limit: this.state.pageSize,
      currentPage: this.state.currentPage,
    });
    this.setState({ loading: false });
  }

  async onDelete(record) {
    console.log(record.Id, "record");
    const response = await this.vehicleFuelCaliborationStore.deleteRecord({
      Id: record.Id,
    });
    //  console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.data.success == "S") {
      message.success(resp.data.message);
      // this.props.navigate("/vehiclefuelcalibrationlist");

      this.setState({ openVehicleCalibrationForm: false });
      await this.vehicleFuelCaliborationStore.loadData({
        offset: 0,
        limit: 50,
      });
      this.setState({ listDataUpdate: true });
    } else if (resp.data.success == "F0") {
      resp.data.errors.map((value) => {
        message.error(value["username"]);
      });
    }
  };

  closeModal=()=>{
    this.setState({
      openVehicleCalibrationForm: false,
      fuelCalibrationId: "",
    });
  }
  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_FUEL_CALIBRATION_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  title={this.context.translate("_ADD_")}
                  link="/vehiclefuelcalibration"
                /> */}
                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  placement="left"
                  color={config.primaryColor}
                >
                <Button
                  className="addButton"
                  icon={<PlusOutlined />}
                  type="primary"
                  onClick={() =>
                    this.setState({
                      openVehicleCalibrationForm: true,
                      fuelCalibrationId: "",
                    })
                  }
                ></Button></Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row style={{ padding: "10px 0px" }}>
            <Col span={16}></Col>
            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize= {this.state.pageSize}
            total={this.vehicleFuelCaliborationStore?.total}
            columns={this.columns}
            dataSource={
              this.vehicleFuelCaliborationStore?.vehicleFuelCalibrationVehicles
            }
            loading={this.vehicleFuelCaliborationStore?.loading}
            onChange={this.handleTableChange}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   total: this.vehicleFuelCaliborationStore.total || 0,
            // }}
          />
        </div>

        {this.state.openVehicleCalibrationForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Fuel Calibration"
            title={this.context.translate("_FUEL_CALIBRATION_")}
            centered
            visible={this.state.openVehicleCalibrationForm}
            footer={null}
            onCancel={() => {
              this.setState({
                openVehicleCalibrationForm: false,
                fuelCalibrationId: "",
              });
            }}
            width={665}
          >
            <div>
              <VehicleFuelCalibrationAdd
                fuelCalibrationId={this.state.fuelCalibrationId}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
VehicleFuelCaliborationList.contextType = StoreContext;
export default observer(withRouter(VehicleFuelCaliborationList));
