import React, { Component } from "react";

import {
  Form,
  Select,
  Button,
  Input,
  message,
  DatePicker,
  Modal,
  Checkbox,
  Row,
  Col,
  Upload,
  Tooltip,
  Spin,
  TimePicker,
} from "antd";
import VehicleAllocateStore from "../store/VehicleAllocateStore";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import CompanyAutocomplete from "./CompanyAutocomplete";
import moment from "moment";
import config from "../config";
import conf from "../config";

import DriversStore from "../store/DriversStore";

import VehicleStore from "../store/VehicleStore";
import CountryStore from "../store/CountryStore";
import withRouter from "../withRouter";
import CompanyStore from "../store/CompanyStore";
import VehicleWorkingHoursStore from "../store/VehicleWorkingHoursStore";
import dayjs from "dayjs";
import DriverAdd from "./DriverAdd";
const { Option } = Select;

const dateFormat = "YYYY-MM-DD";
const currentDate = moment().format(dateFormat);

class AllocateVehicleAdd extends Component {
  formRef = React.createRef();
  driverformRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      openDriverDetailsForm: false,
      companyId: "",
      projectShow: false,
      projectInfoShow: false,
      showProjectDetail: false,
      showAddDriver: false,
      getVehicles: "",
      vehiclesAssetArray: [],
      loadState: 0,
      Id: -1,
      projectName: "",
      tagIdShow: false,
      editVehicleId: "",
      formLoading: false,
    };
    this.allocateStore = new VehicleAllocateStore();
    this.driverStore = new DriversStore();
    this.vehicleStore = new VehicleStore();
    this.countryStore = new CountryStore();
    this.companyStore = new CompanyStore();
    this.workingHoursStore = new VehicleWorkingHoursStore();
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  onAllocateVehicleSubmit = async (values) => {
   
    this.setState({ loading: true });
   
    const allcatevehicle = {
      ...values,
      vehicleId: this.state.editVehicleId
        ? this.state.editVehicleId
        : values.vehicleId,
      Id: this.state.Id,
      companyId: this.state.companyId,
      returnStatus: 0,
      pcId: this.state.projectName.pcId,
      pcName: this.state.projectName.pcName,

      dueDate: values?.dueDate ? values?.dueDate?.format(dateFormat) : null,
      allocateDate: values?.allocateDate
        ? values?.allocateDate?.format("YYYY-MM-DD HH:mm:ss")
        : null,
        // allocateTime:values.allocateTime ? values.allocateTime.format("HH:mm:ss") :null
    };
    const data = { tokenId: "", allcatevehicle: allcatevehicle };
   
    const resp = await this.allocateStore.addData(data);
    await this.props.response(resp);
    // if (resp.data.success == "S") {
    //   message.success(resp.data.message);
    //   this.props.navigate("/vehicleallocatelist");
    // } else if (resp.data.success == "F0") {
    //   resp.data.errors.map((value) => {
    //     message.error(value["username"]);
    //   });
    // }
    this.setState({ loading: false });
  };

  validatePhoneNumber = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
   
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  projectInfoSubmit = async (values) => {
    
    const data = { project: { ...values, companyId: this.state.companyId } };

    const response = await this.allocateStore.addProjectData(data);
    await this.allocateStore.getProjectDetails(this.state.companyId);

  

    if (response.data.success == "S") {
      message.success(response.data.message);
      await this.formRef.current.setFieldsValue({
        pcId: [
          {
            label: response.data.data.projectName,
            value: response.data.data.projectId,
          },
        ],
      });
     
      this.setState({ projectInfoShow: false });
      this.setState({
        projectName: {
          pcName: response.data.data.projectName,
          pcId: response.data.data.projectId,
        },
      });
    } else {
      message.error(response.data.message);
    }
    if (response.data.message) {
      this.setState({ projectInfoShow: false });
      // this.formRef.current?.resetFields();
    }
  };

  allocateTo = (value) => {
   
    if (value === "2") {
      this.setState({ projectShow: true });
    } else if (value === "1") {
      this.setState({ projectShow: false });
    }
  };

  async handleChange(comp) {
   
    await this.setState({ companyId: comp.value });
    if (!this.props.allocateVehicleId) {
      this.context.viewStateStore.setVehicleAllocateStates({
        companyId: comp.value,
      });
    }

    await this.setState({ companyId: comp.value });
    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });

    const data = await this.allocateStore.getProjectDetails(comp.value);

    // await this.setState({loadState:1})

   

    await this.driverStore.toGetDriverName(comp.value);

    await this.allocateStore.getAllocateVehicles({ companyId: comp.value });

   

    const vehicleData = await this.driverStore.getVehicles(comp.value);
    
    await this.handleSearch();
  }

  async handleSearch() {
    await this.allocateStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      this.context.viewStateStore.setVehicleAllocateStates({
        companyId: this.props.getUserData().userCompanyId,
      });

     
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
     
      await this.setState({ isadmin: this.props.getUserData().isadmin });

      const data = await this.allocateStore.getProjectDetails(
        this.props.getUserData().userCompanyId
      );

      // await this.setState({loadState:1})

     

      await this.driverStore.toGetDriverName(
        this.props.getUserData().userCompanyId
      );

      await this.allocateStore.getAllocateVehicles({
        companyId: this.props.getUserData().userCompanyId,
      });

    
      const vehicleData = await this.driverStore.getVehicles(
        this.props.getUserData().userCompanyId
      );
    }
    await this.countryStore.getCountry();
    await this.setState({ loadList: 1 });

    const aid = this.props.allocateVehicleId;
   

    if (aid) {
      await this.filldata(aid);
      this.setState({ aid: aid });
    }
  }

  responseMsg = async (response) => {
    const DriverName = await this.driverStore.toGetDriverName(
      this.state.companyId
    );

    if (response.success == "S") {
      message.success(response.message);
      await this.driverStore.toGetDriverName(this.state.companyId);
      await this.formRef.current.setFieldsValue({
        driverId: Number(response?.data.driverId),
        // driverId:response.data.name
      });

      await this.setState({ openDriverDetailsForm: false });
      // this.formRef.current?.resetFields();
    } else if (response.success === "F0") {
      response.errors.map((value) => {
        message.error(this.context.translate(value["username"]));
        
      });
    }

    this.setState({ loadData: 1 });
    this.setState({ loading: false });

    const companyData = await this.companyStore.getCompanyDataById(
      this.state.companyId
    );

    await this.setState({ loadData: 1 });
  };

  closeDriverModal = () => {
    this.setState({ openDriverDetailsForm: false, driverId: "" });
  };


  filldata = async (aid) => {
    await this.setState({ formLoading: true });
    const getAllocateVehicle = await this.allocateStore.getAllocateVehicleData(
      aid
    );
  
    await this.setState({
      companyId: getAllocateVehicle.companyId,
      Id: getAllocateVehicle.Id,
    });
    const singleData = await this.companyStore.getCompanyDataById(
      getAllocateVehicle.companyId
    );
    let driverResp = await this.driverStore.toGetDriverName(
      getAllocateVehicle.companyId
    );
    // await this.driverStore.toGetDriverName(this.state.companyId);
    const data = await this.allocateStore.getAllocateVehicles({
      companyId: getAllocateVehicle.companyId,
      asset: getAllocateVehicle.allocateTo,
    });
    console.log( getAllocateVehicle.allocateDate,"asdfgh")
   
    await this.allocateStore.getProjectDetails(getAllocateVehicle.companyId);

    await this.formRef.current.setFieldsValue({
      Id: getAllocateVehicle.Id,
      allocateDate: getAllocateVehicle.allocateDate
        ? dayjs(getAllocateVehicle.allocateDate,"YYYY-MM-DD HH:mm:ss")
        : null,
      allocateTo: getAllocateVehicle.allocateTo,
      amount: getAllocateVehicle.amount,
      companyId: singleData,
      currentOdometer: getAllocateVehicle.currentOdometer,
      currentVehicleCondition: getAllocateVehicle.currentVehicleCondition,
      // allocateTime:getAllocateVehicle.allocateTime,
      dayUsed: getAllocateVehicle.dayUsed,
      driverId: getAllocateVehicle.driverId,
      // drivername: getAllocateVehicle.drivername,
      dueDate: getAllocateVehicle.dueDate
        ? dayjs(getAllocateVehicle.dueDate)
        : null,
      employeeId: getAllocateVehicle.employeeId,
      //   expiryDate: moment(getAllocateVehicle.expiryDate),
      licensenumber: getAllocateVehicle.licensenumber,
      mobileno: getAllocateVehicle.mobileno,
      paymentMode: getAllocateVehicle.paymentMode,

      // projectName: getAllocateVehicle.pcName,
      // pcName:getAllocateVehicle.projectName,
      pcId: this.props.allocateVehicleId
        ? getAllocateVehicle.pcName
        : getAllocateVehicle.projectId,

      returnOdometer: getAllocateVehicle.returnOdometer,
      // vehicleId: parseInt(getAllocateVehicle.vehicleId),
      vehicleId: [
        {
          label: getAllocateVehicle.vehicleNo,
          value: parseInt(getAllocateVehicle.vehicleId),
        },
      ],
      vehicleType: getAllocateVehicle.vehicleType,
    });
    this.setState({ editVehicleId: parseInt(getAllocateVehicle.vehicleId) });
    if (getAllocateVehicle.allocateTo == "2") {
      this.setState({ projectShow: true });
    }
    this.setState({
      projectName: {
        pcName: getAllocateVehicle.pcName,
        pcId: getAllocateVehicle.projectId,
      },
    });
    
    await this.setState({ formLoading: driverResp ? false : true });
    await this.setState({ loadData: 1 });
  };

  setFieldsValues = async (driverId) => {
   
    const data = await this.driverStore?.getDriverName.filter(
      (val) => val.driverId === driverId
    );
   
    await this.formRef.current.setFieldsValue({ mobileno: data[0].mobileno });
    await this.formRef.current.setFieldsValue({
      licensenumber: data[0].licensenumber,
    });
    await this.formRef.current.setFieldsValue({
      employeeId: data[0].employeeId,
    });
  };

  getOdometer = (getValue, data) => {
    this.formRef.current.setFieldsValue({
      currentOdometer: data.currentOdometer,
    });
  };

  setReturnStatus = async (value) => {
   
    if (value === "1") {
      await this.allocateStore.getAllocateVehicles({
        companyId: this.state.companyId,
        asset: "1",
      });
    
    } else {
      const vehilesGet = await this.allocateStore.getAllocateVehicles({
        companyId: this.state.companyId,
        asset: "2",
      });
    }
    this.setState({ loadForm: 1 });
  };

  validateFieldsValue = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9.]*/g, "");
 
    if (value1) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  showTagId = async (e) => {
  
    if (e.target.checked) {
      await this.setState({ tagIdShow: e.target.checked });
    } else {
      await this.setState({ tagIdShow: 0 });
    }
  };

  validateNumberAndDecimal = (value, field) => {
    const value1 = value.replace(/[^0-9.,]+/g, "");
   
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  closeModal = () => {
    this.props.closeModal();
  };
  render() {
   
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.formLoading} fullscreen>
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              onFinish={this.onAllocateVehicleSubmit}
              layout="vertical"
              ref={this.formRef}
              // {...this.layout}
              name="nest-message"
              className="formClass" //change
            >
              {/* <div className="headingTitle">Allocate Vehicle</div> */}

              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <>
                      {this.props.allocateVehicleId ? (
                        <Form.Item
                          name="companyId"
                          // label="Account"
                          label={this.context.translate("_ACCOUNT_")}
                          rules={[{ required: true }]}
                        >
                          <CompanyAutocomplete
                            disabled
                            onChange={(value) => {
                              this.handleChange(value);
                            }}
                            companyId={this.state.companyId}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          name="companyId"
                          // label="Account"
                          label={this.context.translate("_ACCOUNT_")}
                          rules={[{ required: true }]}
                        >
                          <CompanyAutocomplete
                            onChange={(value) => {
                              this.handleChange(value);
                            }}
                            companyId={this.state.companyId}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      )}
                    </>
                  )}

                  {this.props.allocateVehicleId ? (
                    <Form.Item
                      name="allocateTo"
                      label={this.context.translate("_ALLOCATE_TO_")}
                      rules={[{ required: true }]}
                    >
                      <Select
                        disabled
                        onChange={async (value) => {
                         
                          await this.allocateTo(value);
                          await this.setReturnStatus(value);
                        }}
                        options={[
                          {
                            //  label: "Driver",
                            label: this.context.translate(
                              "_FLEET_VEHICLE_VEHICLELIST_DRIVER_"
                            ),
                            value: "1",
                          },
                          {
                            // label: "Project",
                            label: this.context.translate("_PROJECT_"),
                            value: "2",
                          },
                        ]}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="allocateTo"
                      label={this.context.translate("_ALLOCATE_TO_")}
                      rules={[{ required: true }]}
                    >
                      <Select
                        onChange={async (value) => {
                          await this.allocateTo(value);
                          await this.setReturnStatus(value);
                        }}
                        options={[
                          {
                            // label: "Driver",
                            label: this.context.translate(
                              "_FLEET_VEHICLE_VEHICLELIST_DRIVER_"
                            ),
                            value: "1",
                          },
                          {
                            // label: "Project",
                            label: this.context.translate("_PROJECT_"),
                            value: "2",
                          },
                        ]}
                      />
                    </Form.Item>
                  )}

                  {/* <div style={{ display: this.state.projectShow ? "" : "none" }}> */}
                  {this.state.projectShow && (
                    <>
                      {this.props.allocateVehicleId ? (
                        <Form.Item
                          name="pcId"
                          label={this.context.translate("_PROJECT_NAME_")}
                          rules={[{ required: true }]}
                        >
                          <Input disabled />
                        </Form.Item>
                      ) : (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Form.Item
                              name="pcId"
                              // label="Project Name"
                              label={this.context.translate("_PROJECT_NAME_")}
                              rules={[{ required: true }]}
                              style={{ width: "80%" }}
                            >
                              <Select
                                // value={this.state.projectId}
                                // placeholder="Select Project"
                                placeholder={this.context.translate(
                                  "_SELECT_PROJECT_"
                                )}
                                //  onChange={(value)=>this.setProjectName(value)}
                                onSelect={(val, obj) => {
                                 
                                  this.setState({
                                    projectName: {
                                      pcId: val,
                                      pcName: obj.projectName,
                                    },
                                  });
                                }}
                                // style={{ width: 370 }}
                                showSearch
                                fieldNames={{
                                  value: "projectId",
                                  label: "projectName",
                                  // label: this.context.translate("_PROJECT_NAME_")
                                  // data:"pcName"
                                }}
                                optionFilterProp="children"
                                options={this.allocateStore?.projectList}
                              />
                            </Form.Item>

                            <Form.Item name=" " label={" "}>
                              <Tooltip
                                // title="Add New Project"
                                title={this.context.translate(
                                  "_ADD_NEW_PROJECT_"
                                )}
                                placement="bottom"
                                color={config.tooltipColorEdit}
                              >
                                <Button
                                  style={{ height: "48px" }}
                                  type="primary"
                                  onClick={() => {
                                    this.setState({ projectInfoShow: true });
                                  }}
                                >
                                  <PlusOutlined />
                                </Button>
                              </Tooltip>
                            </Form.Item>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {/* </div> */}

                  {this.props.allocateVehicleId ? (
                    <Form.Item
                      name="vehicleId"
                      label={this.context.translate("_ASSET_")}
                      rules={[{ required: true }]}
                    >
                      <Select
                        disabled
                        // placeholder="Select Asset"
                        placeholder={this.context.translate(
                          "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                        )}
                        showSearch
                        // onSelect={this.getOdometer}
                        onSelect={(val, currentOdometer) => {
                         
                          this.formRef.current.setFieldsValue({
                            currentOdometer:
                              currentOdometer.currentOdometer -
                              -currentOdometer.diffOdo,
                          });
                        }}
                        fieldNames={{
                          value: "vehicleId",
                          label: "vehicleNo",
                          data: "currentOdometer",
                        }}
                        optionFilterProp="children"
                        options={this.allocateStore?.assetList}
                      ></Select>
                      {/* <Select>
                      {this.allocateStore?.assetList?.map((val)=>{
                        return(<Select.Option value={val.vehicleId}>{val.vehicleNo}</Select.Option>)
                      })}
                    </Select> */}
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="vehicleId"
                      label={this.context.translate("_ASSET_")}
                      rules={[{ required: true }]}
                    >
                      <Select
                        // placeholder="Select Asset"
                        placeholder={this.context.translate(
                          "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                        )}
                        showSearch
                        // onSelect={this.getOdometer}
                        onSelect={(val, currentOdometer) => {
                         
                          this.formRef.current.setFieldsValue({
                            currentOdometer:
                              currentOdometer.currentOdometer -
                              -currentOdometer.diffOdo,
                          });
                        }}
                        fieldNames={{
                          value: "vehicleId",
                          label: "vehicleNo",
                          data: "currentOdometer",
                        }}
                        optionFilterProp="children"
                        options={this.allocateStore?.assetList}
                      ></Select>
                    </Form.Item>
                  )}
                  {this.props.allocateVehicleId ? (
                    <>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Form.Item
                          style={{ width: "80%" }}
                          value={this.state.driverId}
                          name="driverId"
                          label={this.context.translate("_DRIVER_NAME_")}
                          rules={[{ required: true }]}
                        >
                          <Select
                            disabled
                            // placeholder="Select Driver"
                            placeholder={this.context.translate(
                              "_SELECT_DRIVER_"
                            )}
                            // style={{ width: 370 }}
                            // name="driverId"
                            onChange={(e) => this.setFieldsValues(e)}
                            // onSelect={(e) => console.log(e)}
                            // onSearch={this.handleSearch}
                            showSearch
                            fieldNames={{ value: "driverId", label: "name" }}
                            optionFilterProp="children"
                            options={this.driverStore?.getDriverName}
                          />
                        </Form.Item>

                        <Form.Item name=" " label={" "}>
                          <Tooltip
                            // title="Add New Driver"
                            title={this.context.translate(
                              "_FLEET_VEHICLE_VEHICLEDETAIL_ADDDRIVER_"
                            )}
                            placement="bottom"
                            color={config.tooltipColorEdit}
                          >
                            <Button
                              style={{ height: "48px" }}
                              type="primary"
                              onClick={(e) => {
                                this.setState({ openDriverDetailsForm: true });
                              }}
                            >
                              <PlusOutlined />
                            </Button>
                          </Tooltip>
                        </Form.Item>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Form.Item
                          style={{ width: "80%" }}
                          value={this.state.driverId}
                          name="driverId"
                          label={this.context.translate("_DRIVER_NAME_")}
                          rules={[{ required: true }]}
                        >
                          <Select
                            // placeholder="Select Driver"
                            placeholder={this.context.translate(
                              "_SELECT_DRIVER_"
                            )}
                            // style={{ width: 370 }}
                            // name="driverId"
                            onChange={(e) => this.setFieldsValues(e)}
                            // onSelect={(e) => console.log(e)}
                            // onSearch={this.handleSearch}
                            showSearch
                            fieldNames={{ value: "driverId", label: "name" }}
                            optionFilterProp="children"
                            options={this.driverStore?.getDriverName}
                          />
                        </Form.Item>

                        <Form.Item name=" " label={" "}>
                          <Tooltip
                            // title="Add New Driver"
                            title={this.context.translate(
                              "_FLEET_VEHICLE_VEHICLEDETAIL_ADDDRIVER_"
                            )}
                            placement="bottom"
                            color={config.tooltipColorEdit}
                          >
                            <Button
                              style={{ height: "48px" }}
                              type="primary"
                              onClick={(e) => {
                                this.setState({ openDriverDetailsForm: true });
                              }}
                            >
                              <PlusOutlined />
                            </Button>
                          </Tooltip>
                        </Form.Item>
                      </div>
                    </>
                  )}
                  <Form.Item
                    name="licensenumber"
                    className="disabledLabel"
                    label={this.context.translate("_LICENSE_NO_")}
                  >
                    <Input disabled />
                  </Form.Item>

                  <Form.Item
                    name="allocateDate"
                    label={this.context.translate("_ALLOCATE_DATE_")}
                    rules={[{ required: true }]}
                  >
                    {/* <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} /> */}
                    <DatePicker
                      // value="topLeft"
                      // showNow={false}
                      showTime
                      style={{ width: "100%" }}
                      format="DD-MM-YYYY hh:mmA"
                      onChange={(date) => {
                        // Check if the date is valid
                        if (date && date.isValid()) {
                          // Update the form field value
                          this.formRef.current.setFieldsValue({ allocateDate: date });
                        }
                      }}
                    />
                  </Form.Item>

                  {/* <Form.Item
                    name="allocateTime"
                    // label={"End Time"}
                    label={this.context.translate("_ALLOCATE_TIME_")}
                    initialValue={dayjs("00:00", "HH:mm")}
                    // rules={[{ required: true }]}
                  >
                    <TimePicker format="HH:mm" style={{ width: "100%" }} />
                  </Form.Item> */}
                </Col>

                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="mobileno"
                    className="disabledLabel"
                    label={this.context.translate("_MOBILE_NUMBER_")}
                  >
                    <Input disabled />
                  </Form.Item>

                  <Form.Item
                    name="employeeId"
                    className="disabledLabel"
                    label={this.context.translate("_EMPLOYEE_ID_")}
                  >
                    <Input disabled />
                  </Form.Item>

                  <Form.Item
                    name="currentOdometer"
                    className="disabledLabel"
                    label={this.context.translate("_CURRENT_ODOMETER_")}
                  >
                    <Input disabled />
                  </Form.Item>

                  <Form.Item
                    name="currentVehicleCondition"
                    // className="disabledLabel"
                    label={this.context.translate(
                      "_CURRENT_VEHICLE_CONDITION_"
                    )}
                  >
                    <Input />
                  </Form.Item>

                  <div
                    style={{ display: this.state.projectShow ? "" : "none" }}
                  >
                    <Row>
                      <Col span={11}>
                        <Form.Item
                          name="dueDate"
                          //  label="Due Date"
                          label={this.context.translate("_DUEDATE_")}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format={dateFormat}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2} />
                      <Col span={11}>
                        <Form.Item
                          name="paymentMode"
                          // label="Payment Modes"
                          label={this.context.translate("_PAYMENT_MODE_")}
                        >
                          <Select
                            options={[
                              {
                                // label: "Daily",
                                label: this.context.translate(
                                  "_FLEET_RULELIST_CRONRULE_DAILY_"
                                ),
                                value: "1",
                              },
                              {
                                //  label: "Weekly",
                                label: this.context.translate(
                                  "_FLEET_RULELIST_CRONRULE_WEEKLY_"
                                ),
                                value: "2",
                              },
                              {
                                // label: "Monthly",
                                label: this.context.translate("_MONTHLY_"),
                                value: "3",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      name="amount"
                      // label="Amount"
                      label={this.context.translate("_AMOUNT_")}
                    >
                      <Input
                        onChange={(e) =>
                          this.validateFieldsValue(e.target.value, "amount")
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <div className="formBtnGroup">
                {/* <Link to={"/vehicleallocatelist"}>
                {" "}
                <Button ghost type="primary">
                  {" "}
                  Back{" "}
                </Button>
              </Link> */}
                <Button
                  className="formCancelButton"
                  type="primary"
                  onClick={(e) => {
                    this.closeModal();
                    // this.formRef.current?.resetFields();
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
        <Modal
          bodyStyle={{ height: "100%" }}
          className="formShowModal" //
          // title="Driver Details"
          title={this.context.translate("_FLEET_DRIVER_DRIVERDETAIL_TITLE_")}
          centered
          open={this.state.openDriverDetailsForm}
          footer={null}
          onCancel={() => {
            this.setState({ openDriverDetailsForm: false });
          }}
          width={665}
        >
          <DriverAdd
            response={this.responseMsg}
            closeModal={this.closeDriverModal}
          ></DriverAdd>
        </Modal>

        <Modal
          open={this.state.projectInfoShow}
          // title="Add Project"
          title={this.context.translate("_ADD_PROJECT_")}
          onOk={() => this.setState({ projectInfoShow: false })}
          onCancel={() => this.setState({ projectInfoShow: false })}
          footer={false}
        >
          <Form
            name="nest-messages"
            layout="vertical"
            onFinish={this.projectInfoSubmit}
            style={{ maxWidth: "100%" }}
          >
            <Row>
              <Col span={12}></Col>
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              ></Col>
            </Row>
            <Form.Item
              name="proname"
              title={this.context.translate("_PROJECT_DETAILS_")}
              // label="Project Details"
              rules={[
                {
                  required: true,
                  message: this.context.translate("_ENTER_PROJECT_NAME_"),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <div className="formBtnGroup">
              {/* css class */}
              <Button
                className="formCancelButton"
                onClick={() => this.setState({ projectInfoShow: false })}
              >
                {/* css class */}
                {/* Cancel */}
                {this.context.translate("_FLEET_DASHBORD_SHOWPOI_CANCELBTN_")}
              </Button>
              <Button
                type="primary"
                className="formSaveButton"
                htmlType="submit"
                loading={this.state.loading}
              >
                {/* css class */}
                {/* Save */}
                {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
              </Button>
            </div>

            {/* <Row justify={"end"}>
              <Space style={{}}>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button
                  onClick={() => this.setState({ projectInfoShow: false })}
                >
                  Cancel
                </Button>
              </Space>
            </Row> */}
          </Form>
        </Modal>
      </div>
    );
  }
}

AllocateVehicleAdd.contextType = StoreContext;
export default observer(withRouter(AllocateVehicleAdd));
