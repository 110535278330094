import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi, baseURL } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class StationaryReportStore {
  stationaryReport = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      stationaryReport: observable,
      total: observable,
      loading: observable,
      loadData: action,
      exportcsv: action,
      exportExcel: action,
      exportpdf: action,
      exportxml: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit, hours, pageNo }) {
    this.loading = true;
  
    console.log("Stationary Report ", offset, limit, pageNo);
    var postData = {
      companyId: companyId,
      hours: hours,
      offset: offset,
      limit: limit,
      page: pageNo,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=StationaryReport",
        "postData=" + base64Input
      );
    console.log("stationary ", response?.data?.data?.stationary);

    this.stationaryReport = response?.data?.data?.stationary;

    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportstationaryxcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&hours="+
      getData.hours+
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportstationarycsv&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&hours="+
      getData.hours+
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportstationarypdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&hours="+
      getData.hours+
      "&colList=" +
      getData.colList;
  }

  async exportxml(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportstationaryxml&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&hours="+
      getData.hours+
      "&colList=" +
      getData.colList;
  }
}

export default StationaryReportStore;
