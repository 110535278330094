import React from "react";
import { decode as base64_decode } from "base-64";
import {
  Select,
  Button,
  Space,
  Row,
  Col,
  DatePicker,
  Dropdown,
  Modal,
  Checkbox,
  Image,
} from "antd";

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import withRouter from "../withRouter";

import { Link } from "react-router-dom";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import TrackDataStore from "../store/TrackDataStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";

import VehicleStore from "../store/VehicleStore";
import dayjs from "dayjs";

import MapForVehiclePosition from "./MapForVehiclePosition";
import config from "../config";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
// const currentDate = dayjs().startOf("day");
// const defaultStartTime = currentDate;
// const nextDay = currentDate.add(1, "day");
// const defaultEndTime = nextDay;

const { RangePicker } = DatePicker;
const { Option } = Select;

const cMargins = {
  vehicleNo: -20,
  driverName: 5,
  status: -5,
  speed: 7,
  maxspeed: -5,
  distance: -1,
  latitude: 8,
  ibuttonid: -5,
  rfid: 5,
  emptyWeight: -35,
  weightFactor: -55,
  totalWeight: -40,
  viewonmap: 10,
  input2: -10,
  BLEHumidity: -20,
  direction: -1,
  power: 5,
  eventType: 5,
  temperature: -17,
  fuelPer1: -45,
  fuelPer2: -45,
  fuelPer3: -45,
  fuelHeight1: -40,
  fuelHeight2: -36,
  fuelHeight3: -40,
  GPS: -5,
  GSMSignal: -5,
  door: 7,
  acc: 15,
  seatbelt: -5,
  analogInput: 5,
  extAlarm: 5,
  echoBrakecount: -20,
  echoHarshbrakecounter: -20,
  echoHarshaccelcounter: -20,
  echoIdlingevent: -20,
  echoMaxspeed: -20,
  echoEngineontimer: -20,
  echoIdlingtimer: -20,
  echoOverspeedtimer: -20,
  temp1: 10,
  temp2: 10,
  temp3: 10,
  ibuttonPassengerid: -20,
  ac: 15,
  loadVoltage: 5,
  sensorId1: 5,
  BLE1Custom2: -10,
  BLE1Custom3: -10,
  BLE1Custom4: -10,
  BLE1Custom5: -10,
  BLE2Custom2: -10,
  BLE2Custom3: -10,
  BLE2Custom4: -10,
  BLE2Custom5: -10,
  BLE3Custom2: -10,
  BLE3Custom3: -10,
  BLE3Custom4: -10,
  BLE3Custom5: -10,
  BLE4Custom2: -10,
  BLE4Custom3: -10,
  BLE4Custom4: -10,
  BLE4Custom5: -10,
  updateDate: 5,
};

let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    // console.log(val.dataIndex)

    val.width =
      Number(val.title.length) * 11 +
      (cMargins[val.dataIndex] ? cMargins[val.dataIndex] : 0);
    //val.width = ((Number(val.title.length) * 10) );

    // console.log(val.title.length,val.width)

    // if (val.title?.length > 24) {
    //   val.width = 190;
    // } else if (val.title?.length > 15) {
    //   val.width = 150;
    // } else if (val.title?.length > 12) {
    //   val.width = 125;
    // } else if (val.title?.length > 8) {
    //   val.width = 115;
    // } else {
    //   val.width = 95;
    // }
    // console.log(val.title.length)
  });

  return arr;
};

class TrackDataList extends React.Component {
  constructor(props, context) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.vehicleStore = new VehicleStore();

    this.trackDataStore = new TrackDataStore();
    this.mapRef = React.createRef();

    this.state = {
      loading: false,
      pageSize: 50,
      selectedVehicles: "",
      current: 1,
      offset: 0,
      limit: 50,
      showMap: false,
      companyId: "",
      intervel: "All",
      modalVisible: false,
      filter: "All Data",
      column: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      showMonthPopup: false,
      Notcompany: false,
      Notasset: false,
      vehicleObj: false,
      modalInfoObj: {},
      heading: "",
      // viewMap:{},

      selectedColumns: widthAdderFunc([
        {
          // title: "Vehicle No",
          title: context.translate("_VEHICLE_NO_"),
          dataIndex: "vehicleNo",
          // width: 200,
          key: "vehicleNo",
          fixed: "left",
          rowScope: "row",
        },
        {
          // title: "Driver",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
          dataIndex: "driverName",
          //
          key: "driver",
        },
        {
          // title: "Status",
          title: context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
          dataIndex: "status",
          align: "center",
          //
          key: "status",
          render: (s, r) => {
            // console.log(r, "value");

            if (parseInt(r.acc) == 1 && parseInt(r.speed) == 0) {
              // return "Idle";
              return (
                <div
                  style={{
                    backgroundColor: "yellow",
                    display: "inline-block",
                    width: 20,
                    height: 15,
                  }}
                ></div>
              );
            } else if (parseInt(r.acc) == 1 && parseInt(r.speed) > 0) {
              // return "Running";
              return (
                <div
                  style={{
                    backgroundColor: "green",
                    display: "inline-block",
                    width: 20,
                    height: 15,
                  }}
                ></div>
              );
            } else if (parseInt(r.acc) == 0) {
              // return "Stop";
              return (
                <div
                  style={{
                    backgroundColor: "red",
                    display: "inline-block",
                    width: 20,
                    height: 15,
                  }}
                ></div>
              );
            }
            return "NA";
          },
        },
        {
          // title: "DateTime",
          title: context.translate("_FLEET_DASHBORD_SETTING_DATE_"),
          dataIndex: "updatedate",
          //
          key: "updatedate1",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
        },

        {
          // title: "Address",
          title: context.translate("_FLEET_DASHBORD_NEWPOI_ADDRESS_"),
          dataIndex: "formatted_address",
          ellipsis: true,
          key: "formatted_address",
          render: (value, obj) => {
            return (
              <Link
                onClick={async (e) => {
                  // await this.setState({ showMap: true, modalInfoObj: obj });
                  await this.setState({
                    vehicleObj: { ...obj },
                    heading: this.context.translate("_LOCATION_ON_MAP_"),
                  });
                  //  await this.setState({ vehicleObj: value });

                  //   await this.setState({ loadData: 1 });
                }}
              >
                {value}
              </Link>
            );
          },
        },
        {
          // title: "Speed",
          title: context.translate("_FLEET_DASHBORD_SETTING_SPEED_"),
          dataIndex: "speed",
          //
          key: "speed",
        },
        {
          // title: "Max Speed",
          title: context.translate("_FLEET_REPORTS_FLEETREPORT_MAXSPEED_"),
          dataIndex: "maxspeed",
          //
          key: "maxspeed",
          render: (value) => {
            // console.log(value, "value");

            if (value) {
              return value;
            } else {
              return parseFloat(0).toFixed(2);
            }
          },
        },
        {
          // title: "Odometer",
          title: context.translate("_FLEET_DASHBORD_SETTING_ODOMETER_"),
          dataIndex: "distance",
          //
          key: "odometer",
          render: (value) => {
            // console.log(value, "value");

            if (value) {
              return value.toFixed(2);
            }
          },
        },

        {
          // title: "Panic",
          title: context.translate("_FLEET_DASHBORD_SETTING_PANIC_"),
          dataIndex: "panic",
          //
          key: "panic",
          align: "center",
          render: (value) => {
            // console.log(value, "value");

            if (value == 1) {
              // console.log("dtyfuyvgu");
              // return "Red";
              return (
                <div
                  style={{
                    backgroundColor: "red",
                    display: "inline-block",
                    width: 20,
                    height: 15,
                  }}
                ></div>
              );
            }
            return (
              <div
                style={{
                  backgroundColor: "green",
                  display: "inline-block",
                  width: 20,
                  height: 15,
                }}
              ></div>
            );
          },
        },

        {
          // title: "Latitude",
          title: context.translate("_FLEET_DASHBORD_SETTING_LATITUDE_"),
          dataIndex: "latitude",
          //
          key: "latitude",
          render: (value) => {
            if (!value || value == "") {
              return "NA";
            }
            return value;
          },
        },
        {
          // title: "Longitude",
          title: context.translate("_FLEET_DASHBORD_SETTING_LONGITUDE_"),
          dataIndex: "longitude",
          //
          key: "longitude",
          render: (value) => {
            if (!value || value == "") {
              return "NA";
            }
            return value;
          },
        },
        {
          // title: "Ibuttonid",
          title: context.translate("_IBUTTONID_"),
          dataIndex: "ibuttonid",
          //
          key: "ibuttonid",
          render: (value) => {
            if (!value || value == "") {
              return "NA";
            }
            return value;
          },
        },
        {
          // title: "RFID",
          title: context.translate("_FLEET_DASHBORD_SETTING_RFID_"),
          dataIndex: "rfid",
          //
          key: "rfid",
          render: (value) => {
            if (!value || value == "") {
              return "NA";
            }
            return value;
          },
        },
        {
          // title: "Empty Weight(Kg)",
          title: context.translate("_EMPTY_TRUCK_WEIGHT_"),
          dataIndex: "emptyWeight",
          //
          key: "emptyWeight",
          render: (value) => {
            if (value) {
              return Math.round(value);
            } else {
              return "NA";
            }
          },
        },
        {
          // title: "Load/Luggage Weight(Kg)",
          title: context.translate("_LOAD_LUGGAGE_WEIGHT_"),
          dataIndex: "weightFactor",
          //
          key: "loadLuggage",
          render: (value, c, r) =>
            //  {
            //   var loadVoltage = c.loadVoltage;
            //   var weightFactor = c.weightFactor;

            //   if (loadVoltage != null && weightFactor != null) {
            //     var loadKg = loadVoltage * weightFactor;

            //     if (loadKg > c.emptyWeight) {
            //       return Math.round(loadKg);
            //     } else {
            //       return Math.round(c.emptyWeight);
            //     }
            //   } else {
            //     return "NA";
            //   }
            // },

            {
              var loadVoltage = c.loadVoltage;
              var weightFactor = c.weightFactor;
              var emptyWeight = c.emptyWeight;
              if (
                loadVoltage != null &&
                weightFactor != null &&
                emptyWeight != null
              ) {
                if (loadVoltage >= c.minVolt && loadVoltage <= c.maxVolt) {
                  return 0;
                }
                var totalWeightKg = loadVoltage * weightFactor;
                if (totalWeightKg < emptyWeight) {
                  totalWeightKg = emptyWeight;
                }

                var loadWeight = totalWeightKg - emptyWeight;
                return Math.round(loadWeight);
              } else {
                return "NA";
              }
            },
        },
        {
          // title: "Total Weight(Kg)",
          title: context.translate("_TOTAL_TRUCK_WEIGHT_"),
          dataIndex: "totalWeight",
          //
          key: "totalWeight",
          render: (value, c, r) => {
            var loadVoltage = c.loadVoltage;
            var weightFactor = c.weightFactor;

            if (loadVoltage != null && weightFactor != null) {
              //r.get('totalWeight') is v.weightFactor
              var loadKg = loadVoltage * weightFactor;

              if (loadKg > c.emptyWeight) {
                return Math.round(loadKg);
              } else {
                return Math.round(c.emptyWeight);
              }
            } else {
              return "NA";
            }
          },
        },
        {
          // title: "View On Map",
          title: context.translate("_FLEET_ROLE_ROLEDETAIL_VIEW_"),
          dataIndex: "viewonmap",
          align: "center",

          key: "viewonmap",
          ellipsis: true,
          render: (value, obj) => {
            // console.log(obj)
            return (
              <>
                <Image
                  src={require("../assets/images/mapIcon/gps_icon.png")}
                  width={25}
                  height={25}
                  onClick={async () => {
                    await this.setState({
                      vehicleObj: { ...obj, icon: "map-car-icon-green.png" },
                      heading: this.context.translate("_DETAIL_TRACKING_MAP_"),
                    });
                    await this.setState({ loadData: 1 });
                  }}
                />
              </>
            );
          },
        },
        {
          // title: "Input 2",
          title: context.translate("_INPUT_2_"),
          dataIndex: "input2",
          //
          key: "input2",
          render: (value) => {
            if (!value || value == "") {
              return "NA";
            }
            return value;
          },
        },
      ]),
    };
    // this.menuProps = {
    //   items: [
    //     {
    //       // label: "Excel",
    //       label:this.context.translate("_EXCEL_"),
    //       key: "excel",
    //       icon: <PlusOutlined />,
    //     },
    //     {
    //       label: "CSV",
    //       key: "csv",
    //       icon: <PlusOutlined />,
    //     },
    //     {
    //       label: "XML",
    //       key: "xml",
    //       icon: <PlusOutlined />,
    //     },
    //     {
    //       label: "PDF",
    //       key: "pdf",
    //       icon: <PlusOutlined />,
    //     },
    //     {
    //       label: "Temp PDF",
    //       key: "temppdf",
    //       icon: <PlusOutlined />,
    //     },
    //     {
    //       label: "Weight PDF",
    //       key: "weightpdf",
    //       icon: <PlusOutlined />,
    //     },
    //   ],
    //   onClick: (val) => {
    //     let result = [];
    //     console.log(val);
    //     if (true) {
    //       this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

    //       //  console.log(result.toString())

    //       var getData = {
    //         companyId: this.state.companyId,
    //         vehicleId: this.state.selectedVehicles,
    //         intervel: this.state.intervel,
    //         fromDate: this.state.fromDate,
    //         toDate: this.state.toDate,
    //         // userId:JSON.parse(base64_decode(Cookies.get("data"))).userId,
    //         userId: 1,
    //         filter: this.state.filter,

    //         colList: result.toString(),
    //       };
    //       if (val.key == "excel") {
    //         // console.log("afd")
    //         this.trackDataStore.exportExcel(getData);
    //       }
    //       if (val.key == "pdf") {
    //         this.trackDataStore.exportpdf(getData);
    //       }
    //       if (val.key == "csv") {
    //         this.trackDataStore.exportcsv(getData);
    //       }
    //       if (val.key == "xml") {
    //         this.trackDataStore.exportxml(getData);
    //       }
    //       if (val.key == "temppdf") {
    //         this.trackDataStore.exporttemppdf(getData);
    //       }
    //       if (val.key == "weightpdf") {
    //         this.trackDataStore.exportweightpdf(getData);
    //       }
    //     }
    //   },
    // };

    this.newColumns = widthAdderFunc([
      ...this.state.selectedColumns,
      {
        // title: "Heading",
        title: context.translate("_FLEET_DASHBORD_SETTING_HEADING_"),
        dataIndex: "direction",

        key: "heading",
        render: this.directions,
      },
      {
        // title: "Ignition Status",
        title: context.translate("_FLEET_DASHBORD_SETTING_INGNITION_"),
        dataIndex: "power",

        key: "iginitionStatus",
        render: (value, r) => {
          console.log("ewerrewtr", r);
          if (r.acc == 1) {
            return "ON";
          } else if (r.acc == 0) {
            return "OFF";
          } else if (r.acc == -1) {
            return "NA";
          }
          return "NA";
        },
      },
      {
        // title: "Event",
        title: context.translate("_FLEET_DASHBORD_SETTING_EVENT_"),
        dataIndex: "eventType",

        key: "eventType",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Temperature",
        title: context.translate("_FLEET_DASHBORD_SETTING_TEMPERATURE_"),
        dataIndex: "temperature",

        key: "temperature",
        render: (value) => {
          if (value == -1000) {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Fuel Level 1(%)",
        title: context.translate("_FLEET_DASHBORD_SETTING_FUELLEVEL1_"),
        dataIndex: "fuelPer1",

        key: "fuelLevel1",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Fuel Level 2(%)",
        title: context.translate("_FLEET_DASHBORD_SETTING_FUELLEVEL2_"),
        dataIndex: "fuelPer2",

        key: "fuelLevel2",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Fuel Level 3(%)",
        title: context.translate("_FLEET_DASHBORD_SETTING_FUELLEVEL3_"),
        dataIndex: "fuelPer3",

        key: "fuelLevel3",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Analog Input 1",
        title: context.translate("_FLEET_DASHBORD_SETTING_FUELVOLUME1_"),
        dataIndex: "fuelHeight1",

        key: "fuelHeight1",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Analog Input 2",
        title: context.translate("_FLEET_DASHBORD_SETTING_FUELVOLUME2_"),
        dataIndex: "fuelHeight2",

        key: "fuelHeight2",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Fuel Volume 3(Lt)",
        title: context.translate("_FLEET_DASHBORD_SETTING_FUELVOLUME3_"),
        dataIndex: "fuelHeight3",

        key: "volume3",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "GPS Status",
        title: context.translate("_FLEET_DASHBORD_SETTING_GPS_"),
        dataIndex: "GPS",

        key: "GPSStatus",
        render: (value) => {
          if (value == 1) {
            return "Valid";
          } else {
            return "InValid";
          }
          return value;
        },
      },
      {
        // title: "GSM Signal",
        title: context.translate("_FLEET_DASHBORD_SETTING_GSM_"),
        dataIndex: "GSMSignal",

        key: "GSMSignal",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Battery",
        title: context.translate("_FLEET_DASHBORD_SETTING_BATTERY_"),
        dataIndex: "power",

        key: "power",
        render: (value) => {
          if (value == 0) {
            return (
              <img
                src={require("../assets/images/battery-green.png")}
                width={25}
                height={25}
              />
            );
          } else {
            return (
              <img
                src={require("../assets/images/battery-red.png")}
                width={25}
                height={25}
              />
            );
          }
        },
      },
      {
        // title: "Door",
        title: context.translate("_FLEET_DASHBORD_SETTING_DOOR_"),
        dataIndex: "door",

        key: "Door",
        render: (value) => {
          if (value == "1") {
            return "Open";
          } else if (value == "0") {
            return "Close";
          } else if (value == "-1") {
            return "NA";
          }
          return "NA";
        },
      },
      {
        // title: "ACC",
        title: context.translate("_FLEET_DASHBORD_SETTING_ACC_"),
        dataIndex: "acc",

        key: "ACC",
        render: (value) => {
          if (value == 1) {
            return "ON";
          } else if (value == 0) {
            return "OFF";
          } else if (value == -1) {
            return "NA";
          }
          return "NA";
        },
      },

      {
        // title: "SeatBelt",
        title: context.translate("_FLEET_DASHBORD_SETTING_SEATBELT_"),
        dataIndex: "seatbelt",

        key: "seatBelt",
        render: (value) => {
          if (value == "1") {
            return "Open";
          } else if (value == "0") {
            return "Close";
          } else if (value == "-1") {
            return "NA";
          }
          return "NA";
        },
      },
      {
        // title: "BLE Temp",
        title: context.translate("_BLE_TEMP_"),
        dataIndex: "BLETemp",
        //
        key: "BLETemp",
        render: (value) => {
          if (!value || value === "") {
            return "NA";
          }
          var humArr = value.split(", ");
          var formattedHumidity = [];

          for (var i = 0; i < humArr.length; i++) {
            if (humArr !== "NA") {
              var j = i + 1;
              formattedHumidity.push(
                <div key={j}>
                  <b>{`${this.context.translate("_SENSOR_")}${j}: `}</b>
                  {humArr[i]}
                </div>
              );
            }
          }

          return formattedHumidity;
        },
      },
      {
        // title: "BLE Humidity",
        title: context.translate("_BLE_HUMIDITY_"),
        dataIndex: "BLEHumidity",
        // fixed: 'right',
        //
        key: "BLEHumidity",
        render: (value) => {
          if (!value || value === "") {
            return "NA";
          }
          var humArr = value.split(", ");
          var formattedHumidity = [];

          for (var i = 0; i < humArr.length; i++) {
            if (humArr !== "NA") {
              var j = i + 1;
              formattedHumidity.push(
                <div key={j}>
                  <b>{`${this.context.translate("_SENSOR_")}${j}: `}</b>
                  {humArr[i]}
                </div>
              );
            }
          }

          return formattedHumidity;
        },
      },
      {
        // title: "Analog Input",
        title: context.translate("_FLEET_DASHBORD_SETTING_ANALOG_"),
        dataIndex: "analogInput",

        key: "analogInput",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "External Alarm Data",
        title: context.translate("_FLEET_DASHBORD_SETTING_EXTERNAL_"),
        dataIndex: "extAlarm",

        key: "externalAlarmData",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Echo Break Count",
        title: context.translate("_BREAK_COUNT_"),
        dataIndex: "echoBrakecount",

        key: "echoBrakecount",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Echo Harsh Break Count",
        title: context.translate("_HARSH_BRAKE_COUNT_"),
        dataIndex: "echoHarshbrakecounter",

        key: "echoHarshbrakecounter",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Echo Harsh Acceleration Counter",
        title: context.translate("_HARSH_ACCELERATION_COUNTER_"),
        dataIndex: "echoHarshaccelcounter",

        key: "echoHarshaccelcounter",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Echo Idling Event",
        title: context.translate("_IDLING_EVENT_"),
        dataIndex: "echoIdlingevent",

        key: "echoIdlingevent",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Echo Max Speed",
        title: context.translate("_MAX_SPEED_"),
        dataIndex: "echoMaxspeed",

        key: "echoMaxspeed",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Echo Engine On Timer",
        title: context.translate("_ENGINEER_TIMER_"),
        dataIndex: "echoEngineontimer",

        key: "echoEngineontimer",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Echo Idling Timer",
        title: context.translate("_IDLING_TIMER_"),
        dataIndex: "echoIdlingtimer",

        key: "echoIdlingtimer",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Echo Overspeed Timer",
        title: context.translate("_OVERSPEED_TIMER_"),
        dataIndex: "echoOverspeedtimer",

        key: "echoOverspeedtimer",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Temp1",
        title: context.translate("_FLEET_DASHBORD_SETTING_TEMP1_"),
        dataIndex: "temp1",

        key: "temp1",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Temp2",
        title: context.translate("_FLEET_DASHBORD_SETTING_TEMP2_"),
        dataIndex: "temp2",

        key: "temp2",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Temp3",
        title: context.translate("_FLEET_DASHBORD_SETTING_TEMP3_"),
        dataIndex: "temp3",

        key: "temp3",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Passenger Id",
        title: context.translate("_PASSENGER_ID_"),
        dataIndex: "ibuttonPassengerid",

        key: "ibuttonPassengerid",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "AC",
        title: context.translate("_FLEET_DASHBORD_SETTING_AC_"),
        dataIndex: "ac",

        key: "ac",
        render: (value) => {
          if (value == "1") {
            return "ON";
          } else if (value == "0") {
            return "OFF";
          } else if (value == "-1") {
            return "NA";
          }
          return "NA";
        },
      },
      {
        // title: "Load Voltage",
        title: context.translate("_LOAD_VOLTAGE_"),
        dataIndex: "loadVoltage",

        key: "loadVoltage",
      },
      // {
      //   title: "Empty Weight(Kg)",
      //   dataIndex: "emptyWeight",
      //
      //   key: "emptyWeight",
      //   render: (value) => {
      //     if (value) {
      //       return Math.round(value);
      //     } else {
      //       return "NA";
      //     }
      //   },
      // },
      // {
      //   title: "Load/Luggage Weight(Kg)",
      //   dataIndex: "weightFactor",
      //
      //   key: "loadLuggage",
      //   render: (value, c, r) =>
      //   //  {
      //   //   var loadVoltage = c.loadVoltage;
      //   //   var weightFactor = c.weightFactor;

      //   //   if (loadVoltage != null && weightFactor != null) {
      //   //     var loadKg = loadVoltage * weightFactor;

      //   //     if (loadKg > c.emptyWeight) {
      //   //       return Math.round(loadKg);
      //   //     } else {
      //   //       return Math.round(c.emptyWeight);
      //   //     }
      //   //   } else {
      //   //     return "NA";
      //   //   }
      //   // },

      //    {
      // 		var loadVoltage = c.loadVoltage;
      // 		var weightFactor = c.weightFactor;
      // 		var emptyWeight = c.emptyWeight;
      // 		if (loadVoltage != null && weightFactor != null && emptyWeight != null) {
      // 			if(loadVoltage >= c.minVolt  && loadVoltage <= c.maxVolt){
      // 				return 0;
      // 			}
      // 			var totalWeightKg = loadVoltage*weightFactor;
      // 			if(totalWeightKg < emptyWeight){
      // 				totalWeightKg = emptyWeight;
      // 			}

      // 			var loadWeight = totalWeightKg - emptyWeight;
      // 			return Math.round(loadWeight);

      //               }else {
      // 			return "NA";
      // 		}
      //           }
      // },
      // {
      //   title: "Total Weight(Kg)",
      //   dataIndex: "totalWeight",
      //
      //   key: "totalWeight",
      //   render:  (value, c, r) =>{
      // 		var loadVoltage = c.loadVoltage;
      // 		var weightFactor = c.weightFactor;

      //               if (loadVoltage != null && weightFactor != null){//r.get('totalWeight') is v.weightFactor
      // 			var loadKg = loadVoltage*weightFactor;

      // 			if(loadKg > c.emptyWeight){
      // 				return Math.round(loadKg);
      // 			}else{
      // 				return Math.round(c.emptyWeight);
      // 			}

      //               }else {
      // 			return "NA";
      // 		}
      //           }
      // },
      {
        // title: "Passenger Seatbelt",
        title: context.translate(
          "_FLEET_VEHICLEDETAIL_DIGITALIPDDL_SEATBELT2_"
        ),
        dataIndex: "sensorId1",

        key: "sensorId1",
        render: (value) => {
          if (value == "1") {
            return "Buckled";
          } else if (value == "0") {
            return "Unbuckled";
          } else if (value == "-1") {
            return "NA";
          }
          return "NA";
        },
      },
      {
        // title: "BLE1 Custom2",
        title: context.translate("_BLE1CUSTOM2_"),
        dataIndex: "BLE1Custom2",

        key: "BLE1Custom2",
      },
      {
        // title: "BLE1 Custom3",
        title: context.translate("_BLE1CUSTOM3_"),
        dataIndex: "BLE1Custom3",

        key: "BLE1Custom3",
      },
      {
        // title: "BLE1 Custom4",
        title: context.translate("_BLE1CUSTOM4_"),
        dataIndex: "BLE1Custom4",

        key: "BLE1Custom4",
      },
      {
        // title: "BLE1 Custom5",
        title: context.translate("_BLE1CUSTOM5_"),
        dataIndex: "BLE1Custom5",

        key: "BLE1Custom5",
      },
      {
        // title: "BLE2 Custom2",
        title: context.translate("_BLE2CUSTOM2_"),
        dataIndex: "BLE2Custom2",

        key: "BLE2Custom2",
      },
      {
        // title: "BLE2 Custom3",
        title: context.translate("_BLE2CUSTOM3_"),
        dataIndex: "BLE2Custom3",

        key: "BLE2Custom3",
      },
      {
        // title: "BLE2 Custom4",
        title: context.translate("_BLE2CUSTOM4_"),
        dataIndex: "BLE2Custom4",

        key: "BLE2Custom4",
      },
      {
        // title: "BLE2 Custom5",
        title: context.translate("_BLE2CUSTOM5_"),
        dataIndex: "BLE2Custom5",

        key: "BLE2Custom5",
      },
      {
        // title: "BLE3 Custom2",
        title: context.translate("_BLE3CUSTOM2_"),
        dataIndex: "BLE3Custom2",

        key: "BLE3Custom2",
      },
      {
        // title: "BLE3 Custom3",
        title: context.translate("_BLE3CUSTOM3_"),
        dataIndex: "BLE3Custom3",

        key: "BLE3Custom3",
      },
      {
        // title: "BLE3 Custom4",
        title: context.translate("_BLE3CUSTOM4_"),
        dataIndex: "BLE3Custom4",

        key: "BLE3Custom4",
      },
      {
        // title: "BLE3 Custom5",
        title: context.translate("_BLE3CUSTOM5_"),
        dataIndex: "BLE3Custom5",

        key: "BLE3Custom5",
      },
      {
        // title: "BLE4 Custom2",
        title: context.translate("_BLE4CUSTOM2_"),
        dataIndex: "BLE4Custom2",

        key: "BLE4Custom2",
      },
      {
        // title: "BLE4 Custom3",
        title: context.translate("_BLE4CUSTOM3_"),
        dataIndex: "BLE4Custom3",

        key: "BLE4Custom3",
      },
      {
        // title: "BLE4 Custom4",
        title: context.translate("_BLE4CUSTOM4_"),
        dataIndex: "BLE4Custom4",

        key: "BLE4Custom4",
      },
      {
        // title: "BLE4 Custom5",
        title: context.translate("_BLE4CUSTOM5_"),
        dataIndex: "BLE4Custom5",

        key: "BLE4Custom5",
      },
      {
        // title: "Update Date",
        title: context.translate("_UPDATE_DATE_"),
        dataIndex: "updateDate",
        //
        key: "updateDate",
        render: (value) => {
          const formattedDateTime = dayjs(value).format(
            "DD-MM-YYYY hh:mm:ss A"
          );
          return <>{formattedDateTime}</>;
        },
      },
    ]);
  }

  directions = (value) => {
    console.log(value, "value");
    var directions = ["N", "NE", "E", "SE", "S", "SW", "W", "NW", "N"];
    return directions[Math.round((value % 360) / 45)];
  };
  // handleMenuProps = (val) => {
  //   console.log(val);
  // };
  async componentDidMount() {
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: this.context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: this.context.translate("_CSV_"),
          key: "csv",
          icon: <PlusOutlined />,
        },
        {
          // label: "XML",
          label: this.context.translate("_XML_"),
          key: "xml",
          icon: <PlusOutlined />,
        },
        {
          // label: "PDF",
          label: this.context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
        {
          // label: "Temp PDF",
          label: this.context.translate("_TEMP_PDF_"),
          key: "temppdf",
          icon: <PlusOutlined />,
        },
        {
          // label: "Weight PDF",
          label: this.context.translate("_WEIGHT_PDF_"),
          key: "weightpdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            intervel: this.state.intervel,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            // userId: 1,
            filter: this.state.filter,

            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.trackDataStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.trackDataStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.trackDataStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.trackDataStore.exportxml(getData);
          }
          if (val.key == "temppdf") {
            this.trackDataStore.exporttemppdf(getData);
          }
          if (val.key == "weightpdf") {
            this.trackDataStore.exportweightpdf(getData);
          }
        }
      },
    };
    await this.setState({ pageSize: 50 });

    // await this.trackDataStore.loadData({ offset: 0, limit: 50 });
    await this.setState({ column: this.state.selectedColumns });
    //console.log(this.props)
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;
      // console.log(companyId)
      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.vehicleObj !== prevState.vehicleObj) {
      // console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });
    await this.setState({ selectedVehicles: "" });
    // await this.vehicleStore.getVehiclesCompanyId({ companyId: comp.value });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    await this.handleSearch();

    // console.log("data", data);
  }

  async handleSearch() {
    this.setState({ loading: true });
    // this.setState({ Notasset: false });
    // await this.trackDataStore.loadData({
    //   offset: (this.state.current - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,

    //   pageNo: this.state.current,
    //   vehicleId: this.state.selectedVehicles,
    // });

    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    console.log("pagination", pagination);

    await this.setState({
      // current: pagination?.current ? pagination?.current : this.state.current,
      // pageSize: pagination?.pageSize ? pagination?.pageSize : this.state.pageSize,

      current: pagination?.current,
      pageSize: pagination?.pageSize,
    });
    await this.showData();
  }

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (!this.state.selectedVehicles) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }

    if (this.state.companyId && this.state.selectedVehicles) {
      // this.setState({mandatory:false})

      this.setState({ Notcompany: false, Notasset: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.trackDataStore.loadData({
          offset: (this.state.current - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,
          pageNo: this.state.current,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          filter: this.state.filter,
          intervel: this.state.intervel,
        });
        console.log(this.trackDataStore.total);
        this.setState({ loadData: 1 });
      }
    }
  };
  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };
  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }
  onDateTimeChange = (dates) => {
    console.log(dates);
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }

    // const [fromDate, toDate] = dates;

    // if (fromDate&& toDate) {
    //   const beforeSixMonths = dayjs().subtract(6, "month");
    //   if (toDate.isBefore(beforeSixMonths)) {
    //     Modal.warning({
    //       title: "Alert",
    //       content: "For Reports prior to 6 months please contact administrator",
    //     });
    //   }
    // }
  };

  // renderCheckboxes = () => {
  //   console.log(this.newColumns, "Dfdf");
  //   return this.newColumns.map((column, v) => (
  //     <Checkbox
  //       key={v}
  //       onChange={() => this.handleCheckboxChange(column)}
  //       // checked={this.state.selectedColumns.some((c) => c.key === column.key)}
  //     >
  //       {column.title}
  //     </Checkbox>
  //   ));
  // };

  render() {
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const assetInputStyle = this.state.Notasset
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Track Data</div> */}
              <div className="heading">
                {this.context.translate("_FLEET_REPORTS_COMPLETEREPORT_TITLE_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.trackDataStore?.trackData?.length > 0 ? false : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  onChange={(value) => this.handleChange(value)}
                  allowClear={true}
                  style={{ width: "150px" }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
                {/* <Select
                  onSearch={this.handleSearch}
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    this.setState({ Notasset: false });
                  }}
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate(
                    "_FLEET_REPORTS_VEHICLENO_"
                  )}
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 150 }}
                  // style={assetInputStyle}
                  status={this.state.Notasset ? "error" : false}
                /> */}
                {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles: "",
                      })
                    }
                  />
                )}

                <Select
                  value={
                    this.state.selectedVehicles
                      ? this.state.selectedVehicles
                      : null
                  }
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    this.setState({ Notasset: false });
                    this.setState({ loadData: 1 });
                  }}
                  style={{ width: 150 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {[...this.vehicleStore?.getVehiclesUsingCompanyId]?.map(
                    (val) => {
                      return (
                        <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                      );
                    }
                  )}
                </Select>

                <RangePicker
                  placeholder={[
                    this.context.translate("_FROM_DATE_TIME_"),
                    this.context.translate("_TO_DATETIME_"),
                  ]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />

                <Select
                  defaultValue="All"
                  style={{ width: 100 }}
                  onSelect={(intervel) => this.setState({ intervel: intervel })}
                  placeholder="Interval"
                >
                  <Select.Option value="All">
                    {this.context.translate("_ALL_")}
                  </Select.Option>
                  <Select.Option value="1">1</Select.Option>
                  <Select.Option value="5">5</Select.Option>
                  <Select.Option value="10">10</Select.Option>
                  <Select.Option value="15">15</Select.Option>
                  <Select.Option value="20">20</Select.Option>
                  <Select.Option value="25">25</Select.Option>
                  <Select.Option value="30">30</Select.Option>
                </Select>

                <Select
                  defaultValue="All Data"
                  style={{ width: 100 }}
                  onSelect={(filter) => this.setState({ filter: filter })}
                  placeholder="Filter"
                >
                  {/* <Select.Option value="All Data">All Data</Select.Option>
                  <Select.Option value="Tag Id">Tag Id</Select.Option>
                  <Select.Option value="Temp Id">Temp Id</Select.Option>
                  <Select.Option value="Moving">Moving</Select.Option>
                  <Select.Option value="Idle">Idle</Select.Option>
                  <Select.Option value="Stop">Stop</Select.Option> */}
                  <Select.Option value="All Data">
                    {this.context.translate("_ALL_DATA_")}
                  </Select.Option>
                  <Select.Option value="Tag Id">
                    {this.context.translate("_TAG_ID_")}
                  </Select.Option>
                  <Select.Option value="Temp Id">
                    {this.context.translate("_TEMP_ID_")}
                  </Select.Option>
                  <Select.Option value="Moving">
                    {this.context.translate("_MOVING_")}
                  </Select.Option>
                  <Select.Option value="Idle">
                    {this.context.translate("_IDLE_")}
                  </Select.Option>
                  <Select.Option value="Stop">
                    {this.context.translate("_STOP_")}
                  </Select.Option>
                </Select>

                <Button
                  type="primary"
                  onClick={async (pagination) => {
                    await this.setState({ pageSize: 50, current: 1 });
                    await this.handleSearch();
                    // this.handleTableChange();
                    await this.showData();
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.current}
            pageSize={this.state.pageSize}
            total={this.trackDataStore.total}
            columns={this.state.column}
            dataSource={this.trackDataStore?.trackData}
            loading={this.trackDataStore?.loading}
            onChange={this.handleTableChange}
            style={{ borderWidth: 0 }}
          />

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <Modal
            width={350}
            className="alert-model"
            title={
              <div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-exclamation-triangle"
                ></i>{" "}
                {this.context.translate("_FLEET_ALERT_")}
              </div>
            }
            open={this.state.showMonthPopup}
            onOk={() => this.setState({ showMonthPopup: false })}
            onCancel={() => this.setState({ showMonthPopup: false })}
            footer={false}
          >
            {/* For reports prior to 6 months please contact administrator */}
            {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
          </Modal>
        </div>
        {this.state.vehicleObj && (
          <MapForVehiclePosition
            vehicleObj={this.state.vehicleObj}
            heading={this.state.heading}
            closeMapModal={(resp) => {
              this.setState({ vehicleObj: resp });
            }}
          />
        )}
      </>
    );
  }
}
TrackDataList.contextType = StoreContext;
export default observer(withRouter(TrackDataList));
