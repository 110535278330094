import React, { Component } from "react";
import {
  Select,
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Space,
  message,
  Spin
} from "antd";

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import VehicleReturnAssetsStore from "../store/VehicleReturnAssetsStore";
import withRouter from "../withRouter";
import moment from "moment";
import { Link } from "react-router-dom";
import CompanyAutocomplete from "./CompanyAutocomplete";
import VehicleAllocateStore from "../store/VehicleAllocateStore";
import DriversStore from "../store/DriversStore";
import CompanyStore from "../store/CompanyStore";

import config from "../config";
import dayjs from "dayjs";
const dateFormat = "YYYY-MM-DD";
const currentDate = dayjs().format(dateFormat);

class VehicleReturnAssetsAdd extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      returnStatus: "",
      asset: "",
      companyId: "",
      loadForm: 0,
      salikCharges: 0,
      Id: "",
      vehicleDamageCharge: 0,
      salikUsed: 0,

      trafficFine: 0,
      totalUsedDays: 0,
      totalAmountCharge: 0,
      loadData: 0,
      loadingSpin: false
      // returnDate: "",

    };
    this.vehicleReturnAssetStore = new VehicleReturnAssetsStore();
    this.vehicleAllocateStore = new VehicleAllocateStore(this);
    this.driverStore = new DriversStore();
    this.companyStore = new CompanyStore();
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  onReturnAssetSubmit = async (values) => {
    this.setState({ loading: true });
    console.log(values);

    const allcatevehicle = {
      ...values,
      Id: this.state.Id,
      companyId: this.state.companyId,
      returnStatus: 1,
      //   dueDate: values?.dueDate ? values?.dueDate?.format(dateFormat) : null,
      returnDate: values.returnDate
        ? values.returnDate.format("YYYY-MM-DD")
        : null,

      // Accountexpirydate: fieldsValue.Accountexpirydate
      // ? fieldsValue.Accountexpirydate.format("YYYY-MM-DD")
      // : null,
    };
    const data = { tokenId: "", allcatevehicle: allcatevehicle };

    const resp = await this.vehicleReturnAssetStore.addData(data);
    await this.props.response(resp);
    // if (resp.data.success == "S") {
    //   message.success(resp.data.message);
    //   this.props.navigate("/vehiclereturnassetlist");
    // } else if (resp.data.success == "F0") {
    //   resp.data.errors.map((value) => {
    //     message.error(value["username"]);
    //   });
    // }
    this.setState({ loading: false });
  };

  async componentDidMount() {
    if(Number(this.props.getUserData().isadmin)!==-1){
      this.context.viewStateStore.setReturnAssetStates({ companyId: this.props.getUserData().userCompanyId});
      await this.setState({companyId:this.props.getUserData().userCompanyId})
      console.log(this.state.companyId)
     
      await this.setState({isadmin:this.props.getUserData().isadmin})
    }
    await this.setState({ loadList: 1 });

    const rid = this.props.returnAssetId;
    console.log("id", rid);

    if (rid) {
      await this.filldata(rid);
      this.setState({ rid: rid });
    }
  }

  filldata = async (rid) => {
    await this.setState({ loadingSpin: true })
    const getReturnAssetDetails =
      await this.vehicleReturnAssetStore.getReturnAssetData(rid);
    console.log("getReturnAssetDetails", getReturnAssetDetails);


    await this.setState({
      companyId: getReturnAssetDetails.companyId,
      Id: getReturnAssetDetails.Id,
    });
    if (getReturnAssetDetails.allocateTo === "1") {
      await this.vehicleReturnAssetStore.getAllocateVehicles({
        companyId: getReturnAssetDetails.companyId,
        asset: "returntoDriver",
      });
      console.log("1")
    } else if (getReturnAssetDetails.allocateTo === "2") {
      await this.vehicleReturnAssetStore.getAllocateVehicles({
        companyId: getReturnAssetDetails.companyId,
        asset: "returntoProject",
      });
      console.log("2")
    }
    const singleData = await this.companyStore.getCompanyDataById(
      getReturnAssetDetails.companyId
    );
    // await this.vehicleReturnAssetStore.getAllocateVehicles({
    //   companyId: getReturnAssetDetails.companyId,
    // // asset:getReturnAssetDetails.allocateTo
    // });

    console.log(getReturnAssetDetails.allocateTo);

    // if(getReturnAssetDetails.allocateTo===1){

    //   await this.vehicleReturnAssetStore.getAllocateVehicles({
    //     companyId: this.state.companyId,
    //     asset: "returntoDriver",
    //   });
    //   // console.log(this.vehicleAllocateStore.allocateVehicleAsset,"array")
    // // } else if (value === "2") {
    // //   const vehilesGet = await this.vehicleReturnAssetStore.getAllocateVehicles(
    // //     { companyId: this.state.companyId, asset: "returntoProject" }
    // //   );
    // //   }
    // }else if (getReturnAssetDetails.allocateTo === 2) {
    //     const vehilesGet = await this.vehicleReturnAssetStore.getAllocateVehicles(
    //       { companyId: this.state.companyId, asset: "returntoProject" }
    //     );
    //     }

    await this.formRef.current.setFieldsValue({
      Id: getReturnAssetDetails.Id,
      allocateTo: getReturnAssetDetails.allocateTo,
      companyId: singleData,
      currentOdometer: getReturnAssetDetails.currentOdometer,
      currentVehicleCondition: getReturnAssetDetails.currentVehicleCondition,
      driverId: getReturnAssetDetails.driverId,
      drivername: getReturnAssetDetails.drivername,
      employeeId: getReturnAssetDetails.employeeId,
      licensenumber: getReturnAssetDetails.licensenumber,
      mobileno: getReturnAssetDetails.mobileno,
      returnDate: getReturnAssetDetails.returnDate
        ? dayjs(getReturnAssetDetails.returnDate, "DD-MM-YYYY")
        : null,
      returnOdometer: getReturnAssetDetails.returnOdometer,
      returnStatus: getReturnAssetDetails.returnStatus,
      salikCharges: getReturnAssetDetails.salikCharges,

      salikUsed: getReturnAssetDetails.salikUsed,
      totalAmountCharge: getReturnAssetDetails.totalAmountCharge,
      // totalKilometers: getReturnAssetDetails.totalDistanceTravelled,
      totalUsedDays: getReturnAssetDetails.totalUsedDays,
      trafficFine: getReturnAssetDetails.trafficFine,
      vehicleDamageCharge: getReturnAssetDetails.vehicleDamageCharge,
      vehicleId: getReturnAssetDetails.vehicleNo,
      totalDistanceTravelled: getReturnAssetDetails.totalDistanceTravelled,
      vehiclePresentCondition: getReturnAssetDetails.vehiclePresentCondition
    });
    await this.setState({ loadingSpin: false })
    this.setState({
      totalUsedDays: parseInt(getReturnAssetDetails.totalUsedDays) + 1,
      trafficFine: parseFloat(getReturnAssetDetails.trafficFine),
      vehicleDamageCharge: parseFloat(
        getReturnAssetDetails.vehicleDamageCharge
      ),
      salikUsed: parseFloat(getReturnAssetDetails.salikUsed),
      // salikCharges:parseFloat(getReturnAssetDetails.salikCharges),
      totalAmountCharge: parseFloat(getReturnAssetDetails.totalAmountCharge),
    });
    console.log(getReturnAssetDetails.allocateDate);
    console.log(
      Math.abs(
        dayjs(getReturnAssetDetails.allocateDate, "DD-MM-YYYY").diff(
          dayjs(),
          "day"
        )
      )
    );

    // console.log(this.props.params);

    console.log(getReturnAssetDetails.totalAmountCharge);

    // this.formRef.current.setFieldsValue({
    //   salikCharges: getReturnAssetDetails.salikUsed * 4,

    // totalAmountCharge:
    //     parseFloat(getReturnAssetDetails.salikUsed) * 4 +
    //     parseFloat(getReturnAssetDetails.vehicleDamageCharge) +
    //     parseFloat(getReturnAssetDetails.trafficFine),
    // });
    // this.setState({totalAmountCharge:})
    this.setState({ loadData: 1 })

  };

  async handleChange(comp) {
    console.log(comp.value);
    // console.log(this.props.params.id, "EDITRRRRR");
    await this.formRef.current?.resetFields();
    const singleData = await this.companyStore.getCompanyDataById(
      comp.value
    );
    await this.setState({ companyId: comp.value });
    if (!this.props.returnAssetId) {
      this.context.viewStateStore.setReturnAssetStates({
        companyId: comp.value,
      });
    }
    await this.setState({ companyId: comp.value });
    await this.formRef.current.setFieldsValue({
      companyId: singleData,
    });
    await this.handleSearch();
  }

  async handleSearch() {
    await this.vehicleReturnAssetStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  setReturnStatus = async (value) => {
    console.log(value, "value");
    if (value === 1) {
      await this.vehicleReturnAssetStore.getAllocateVehicles({
        companyId: this.state.companyId,
        asset: "returntoDriver",
      });
      // console.log(this.vehicleAllocateStore.allocateVehicleAsset,"array")
    } else if (value === 2) {
      const vehilesGet = await this.vehicleReturnAssetStore.getAllocateVehicles(
        { companyId: this.state.companyId, asset: "returntoProject" }
      );
    }
    this.setState({ loadForm: 1 });
  };

  setFieldsValues = async (vehicleId) => {
    console.log("vehicleId", vehicleId);
    const getVehicle =
      this.vehicleReturnAssetStore?.allocateVehicleAsset.filter(
        (val) => val.vehicleId === vehicleId
      );
    console.log(getVehicle, "sfsdfghdgfh");
    await this.setState({ Id: getVehicle[0].Id });
    await this.formRef.current.setFieldsValue({
      drivername: getVehicle[0].drivername,
    });
    await this.formRef.current.setFieldsValue({
      licensenumber: getVehicle[0].licensenumber,
    });
    console.log();
    await this.formRef.current.setFieldsValue({
      // const date = diff(currentDate-allocateDate)
      totalUsedDays: this.props.returnAssetId
        ? this.state.totalUsedDays
        : Math.abs(dayjs(getVehicle[0].allocateDate).diff(dayjs(), "day")),
    });
    await this.setState({
      totalUsedDays: Math.abs(
        dayjs(getVehicle[0].allocateDate).diff(dayjs(), "day")
      ),
    });
    await this.formRef.current.setFieldsValue({
      mobileno: getVehicle[0].mobileno,
    });
    await this.formRef.current.setFieldsValue({
      employeeId: getVehicle[0].employeeId,
    });
    await this.formRef.current.setFieldsValue({
      currentOdometer: getVehicle[0].currentOdometer,
    });
    await this.formRef.current.setFieldsValue({
      currentVehicleCondition: getVehicle[0].currentVehicleCondition,
    });
    // await this.formRef.current.setFieldsValue({ vehicleId: data[0].vehicleId });
    await this.formRef.current.setFieldsValue({
      returnOdometer: getVehicle[0].returnOdometer - -getVehicle[0].diffOdo,
    });
    // await this.formRef.current.setFieldsValue({
    //   returnOdometer: data[0].returnOdometer,
    // });
    await this.formRef.current.setFieldsValue({
      returnDate: dayjs(),
    });
    await this.formRef.current.setFieldsValue({
      totalDistanceTravelled:
        getVehicle[0].returnOdometer -
        getVehicle[0].currentOdometer -
        -getVehicle[0].diffOdo,
    });
    console.log(
      ("getV", getVehicle[0].returnOdometer) - -getVehicle[0].diffOdo
    );
  };

  setTotalAmount = () => {
    this.formRef.current.setFieldsValue({
      salikCharges: (this.state.salikUsed ? this.state.salikUsed : 0) * 4,

      totalAmountCharge:
        parseFloat((this.state.salikUsed ? this.state.salikUsed : 0) * 4) +
        parseFloat(this.state.vehicleDamageCharge ? this.state.vehicleDamageCharge : 0) +
        parseFloat(this.state.trafficFine ? this.state.trafficFine : 0),
    });
  };

  settotalUsedDays = (date, stringDate) => {
    console.log(parseInt(Math.abs(Math.round(dayjs().diff(date, "day")))));
    console.log(
      parseInt(this.state.totalUsedDays) +
      parseInt(Math.abs(Math.round(dayjs().diff(date, "day"))))
    );
    this.formRef.current.setFieldsValue({
      totalUsedDays:
        this.state.totalUsedDays +
        parseInt(Math.abs(Math.round(dayjs().diff(date, "day", dayjs())))),
    });
    console.log(Math.abs(Math.round(dayjs().diff(date, "day", dayjs()))));
    // console.log(Math.abs(now.diff(date, 'day')));
  };

  validateNumber = (value, field) => {
    const value1 = value.replace(/[^0-9.,]+/g, "");
    console.log(value1);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  closeModal = () => {
    this.props.closeModal()
  }

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen >
            <Form
              onFinish={this.onReturnAssetSubmit}
              layout="vertical"
              ref={this.formRef}
              // {...this.layout}
              name="nest-message"
              // initialValues={{ returnDate: dayjs(currentDate) }}
              className="formClass" //change
            >
              {/* <div className="headingTitle">Return Asset</div> */}
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 || this.props.getUserData().isadmin === 3) &&
                    <Form.Item
                      name="companyId"
                      // label="Account"
                      label={this.context.translate("_FLEET_VEHICLE_VEHICLELIST_COMPANYDDL_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => {
                          this.handleChange(value);
                        }}
                        companyId={this.state.companyId}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  }

                  <Form.Item
                    name="allocateTo"
                    label={this.context.translate("_RETURNED_FROM_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      onChange={async (value) =>
                        await this.setReturnStatus(value)
                      }
                      onSelect={async (value) => {
                        console.log(value);
                        if (value == 1) {
                          await this.vehicleReturnAssetStore.getAllocateVehicles({
                            companyId: this.state.companyId,
                            asset: "returntoDriver",
                          });
                        } else if (value == 2) {
                          await this.vehicleReturnAssetStore.getAllocateVehicles({
                            companyId: this.state.companyId,
                            asset: "returntoProject",
                          });
                        }
                        this.setState({ loadData: 1 })
                      }}
                      options={[
                        {
                          // label: "Driver",
                          label: this.context.translate("_FLEET_VEHICLE_VEHICLELIST_DRIVER_"),
                          value: "1"
                        },
                        {
                          // label: "Project",
                          label: this.context.translate("_PROJECT_"),
                          value: "2"
                        },
                      ]}
                    />
                  </Form.Item>

                  <Form.Item
                    name="vehicleId"
                    label={this.context.translate("_ASSET_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      // placeholder="Select Asset"
                      placeholder={this.context.translate("_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_")}
                      onSelect={(value) => {
                        this.setFieldsValues(value);
                      }}
                      // onChange={(e)=>this.setFieldsValues(e)}
                      showSearch
                      fieldNames={{ value: "vehicleId", label: "vehicleNo" }}
                      optionFilterProp="children"
                      options={this.vehicleReturnAssetStore?.allocateVehicleAsset}
                    />

                    {/* <Select onChange={this.getValueObj}>
                                    {this.vehicleAllocateStore?.allocateVehicleAsset.map((value)=>{
                                        return(<Select.Option key="vehicleId" value={value}>{value.vehicleNo}</Select.Option>)
                                    })}
                                </Select> */}
                  </Form.Item>

                  <Form.Item
                    name="drivername"
                    className="disabledLabel"
                    label={this.context.translate("_DRIVER_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Input disabled />
                  </Form.Item>

                  <Form.Item
                    name="licensenumber"
                    className="disabledLabel"
                    label={this.context.translate("_LICENSE_NUMBER_")}
                  >
                    <Input disabled />
                  </Form.Item>

                  <Form.Item
                    name="totalUsedDays"
                    className="disabledLabel"
                    label={this.context.translate("_TOTAL_DAYS_USED_")}
                  >
                    <Input
                      disabled
                    // value={this.state.totalUsedDays}
                    // onChange={async (e) => {
                    //   await this.setState({ totalUsedDays: e.target.value });
                    //   await this.settotalUsedDays();
                    // }}
                    />
                  </Form.Item>

                  <Form.Item name="salikUsed"
                    // label="Salik Used"
                    label={this.context.translate("_SALIK_USED_")}
                  >
                    <Input
                      value={this.state.salikUsed}
                      onChange={async (e) => {
                        await this.setState({ salikUsed: e.target.value });
                        this.validateNumber(e.target.value, "salikUsed");
                        await this.setTotalAmount();
                      }}
                    />
                  </Form.Item>

                  {/* <Row>
                  <Col span={11}> */}
                  <Form.Item
                    name="salikCharges"
                    className="disabledLabel"
                    // label="Salik Charges"
                    label={this.context.translate("_SALIK_CHARGES_")}
                  >
                    <Input
                      disabled
                      value={this.state.salikCharges}
                      onChange={async (e) => {
                        await this.setState({ salikCharges: e.target.value });
                        await this.setTotalAmount();
                      }}
                    />
                  </Form.Item>
                  {/* </Col>
                  <Col span={2} />
                  <Col span={11}> */}
                  <Form.Item
                    name="vehicleDamageCharge"
                    label={this.context.translate("_VEHICLE_DAMAGE_CHARGE_")}
                    style={{ fontSize: "11px" }}
                  >
                    <Input
                      value={this.state.vehicleDamageCharge}
                      onChange={async (e) => {
                        await this.setState({
                          vehicleDamageCharge: e.target.value,
                        });
                        await this.setTotalAmount();
                        this.validateNumber(
                          e.target.value,
                          "vehicleDamageCharge"
                        );
                      }}
                    />
                  </Form.Item>
                  {/* </Col>
                </Row> */}
                </Col>
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="trafficFine"
                    label={this.context.translate("_TRAFFIC_FINE_")}
                  >
                    <Input
                      value={this.state.trafficFine}
                      onChange={async (e) => {
                        await this.setState({
                          trafficFine: e.target.value,
                        });
                        await this.setTotalAmount();
                        this.validateNumber(e.target.value, "trafficFine");
                      }}
                    />
                  </Form.Item>
                  {/* 
                <Row>
                  <Col span={11}> */}
                  <Form.Item
                    className="disabledLabel"
                    name="totalDistanceTravelled"
                    label={this.context.translate("_TOTAL_KM_")}
                  >
                    <Input disabled />
                  </Form.Item>
                  {/* </Col>
                  <Col span={2} />
                  <Col span={11}> */}
                  <Form.Item
                    name="totalAmountCharge"
                    className="disabledLabel"
                    label={this.context.translate("_TOTAL_AMOUNT_CHARGABLE_")}
                  >
                    <Input
                      disabled
                      onChange={async (e) => {
                        console.log(e);
                        await this.setState({
                          totalAmountCharge: e.target.value,
                        });
                        await this.setTotalAmount();
                      }}
                    //style={{ fontSize: "11px" }}
                    />
                  </Form.Item>
                  {/* </Col>
                </Row> */}
                  <Row>
                    <Col span={11}>
                      <Form.Item
                        name="mobileno"
                        className="disabledLabel"
                        label={this.context.translate("_MOBILE_NUMBER_")}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col span={2} />
                    <Col span={11}>
                      <Form.Item
                        name="employeeId"
                        className="disabledLabel"
                        label={this.context.translate("_EMPLOYEE_ID_")}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="currentOdometer"
                    className="disabledLabel"
                    label={this.context.translate("_INITIAL_ODOMETER_")}
                  >
                    <Input disabled />
                  </Form.Item>

                  <Form.Item
                    name="currentVehicleCondition"
                    className="disabledLabel"
                    label={this.context.translate("_INITIAL_VEHICLE_CONDTION_")}
                  >
                    <Input disabled />
                  </Form.Item>

                  <Form.Item
                    name="vehiclePresentCondition"
                    label={this.context.translate("_VEHICLE_PRESENT_CONDTION_")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="returnDate"
                    label={this.context.translate("_RETURN_DATE_")}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      // value={this.state.returnDate}
                      style={{ width: "100%" }}
                      format="YYYY-MM-DD"
                      onChange={this.settotalUsedDays}
                    />
                  </Form.Item>

                  <Form.Item
                    name="returnOdometer"
                    label={this.context.translate("_RETURN_ODOMETER_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  {/* <Form.Item wrapperCol={{ ...this.layout.wrapperCol, offset: 8 }}>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item> */}
                </Col>
              </Row>
              <div className="formBtnGroup">
                {/* <Link to={"/vehiclereturnassetlist"}>
                  {" "}
                  <Button ghost type="primary">
                    {" "}
                    Back{" "}
                  </Button>
                </Link> */}
                <Button
                  className="formCancelButton"
                  type="primary"
                  onClick={(e) => {
                    this.closeModal()
                    // this.formRef.current?.resetFields();
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

VehicleReturnAssetsAdd.contextType = StoreContext;
export default observer(withRouter(VehicleReturnAssetsAdd));
