import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class VehicleOdometerRecordsStore {
  odometerData = [];
  loading = false;
  total = 0;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      odometerData: observable,
      loading: observable,
      total: observable,
      loadData: action,
      processOdometerData:action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit,issuperadmin,isadmin }) {
    this.loading = true;

    var postData = {
      companyId: companyId,
      issuperadmin: issuperadmin,
      isadmin:isadmin,
      offset: offset,
      limit: limit,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=odometer&a=getOdometerGridDetails",
        "postData=" + base64Input
      );

    this.odometerData = response?.data?.data?.odometerGrid;
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async processOdometerData(data){
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=odometer&a=processOdometer", "postData=" + base64Input);

    return response.data.data;
  }
}

export default VehicleOdometerRecordsStore;
