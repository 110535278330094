import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class VideoMonitoringStore {
  deviceListArray = [];

  constructor(rootStore) {
    makeObservable(this, {
      deviceListArray: observable,
      getDevices: action,
      getPlayBackVideoNew: action,
    });
    this.rootStore = rootStore;
  }

  async getDevices(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=videoStreaming&a=getdevices2",
        "postData=" + base64Input
      );
    // console.log(response.data.data?.vehicles);

    this.deviceListArray = response.data.data?.vehicles;

    return response.data.data?.vehicles[0];
  }

  async getPlayBackVideoNew(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=videoStreaming&a=getPlayBackVideoNew",
        "postData=" + base64Input
      );
    console.log();
    return response.data.data[0];
  }

  // async getChannelVideo(data) {
  //   var postData = data;
  //   const base64Input = await base64_encode(JSON.stringify(postData));
  //   var response = await postApi
  //     .getClient()
  //     .post(
  //       "index.php?c=videoStreaming&a=bsjVideoMonitoring",
  //       "postData=" + base64Input
  //     );

  //  return response
  // }
}

export default VideoMonitoringStore;
