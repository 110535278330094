import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import config from "../config";
import {
  action,
  makeObservable,
  observable,

} from 'mobx';
import { act } from 'react-dom/test-utils';

class DeviceListStore {
  deviceList = []
  total = 0
  loading = false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
      deviceList: observable,
      total: observable,
      loading: observable,
      loadData: action,
      getDeviceDetails: action,
      addData: action,
      deleteRecord:action,
      sampleExcel:action,
      addDeviceByExcel:action

    });
    this.rootStore = rootStore;

  }

  async loadData({offset,limit,total}) {
    this.loading = true;
    console.log("Device list")

    var postData = {offset:offset,limit:limit}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=device&a=getDetails", "postData=" + base64Input);
    console.log(response?.data?.data?.devices)
    this.deviceList = response?.data?.data?.devices
    // this.total=100
    this.total = parseInt(response?.data?.data?.TotalCount);
    console.log(response?.data?.data?.TotalCount)
    this.loading = false;
  }

  async deleteRecord({ Id }) {
    var postData = {"tokenId":"","Id":Id}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=device&a=delete", "postData=" + base64Input);
    return response;
  }

  async addData(values) {
    var postData = values
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=device&a=update", "postData=" + base64Input);
    // console.log(response)
    return response.data
  }

  async getDeviceDetails(id) {
    console.log("id", id)
    var postData = {"id":id,limit:1,offset:0}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=device&a=getDetails", "postData="+base64Input);
    console.log("response",response?.data?.data.devices[0])
    return response?.data?.data.devices[0]
  }

 
  async sampleExcel() {
    window.location.href =
    config.fleetURL +
        "/appRes/excel/Device_Upload.xlsx";
    // window.location.href =
    // "http://192.168.1.100:8088/awtl_react/public/fleet/appRes/excel/Device_Upload.xlsx";
  }


  

  async addDeviceByExcel(excelFile) {
    this.excelLoadig = true;
    var postData = excelFile;
    console.log(postData);
    console.log(excelFile);
    console.log(this.excelLoadig);
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=device&a=deviceexcel", "postData=" + base64Input);
    this.excelLoadig = false;
    return response.data;
  }


}
export default DeviceListStore;
