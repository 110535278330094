import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import config from "../config";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
  observe,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class TripCostStore {
 
tripCostArray=[]



 
  total=0
  loading=false
 
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
   
        tripCostArray:observable,
      total:observable,
      loading:observable,
   
     
      loadData : action,
      addTripCost : action,
      getTripData:action
    });
     this.rootStore = rootStore;
    
  }

  async loadData({offset,limit,companyId,}){
    // this.loading=true;
    var postData={"companyId":companyId,"offset":offset,"limit":limit,}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=trip&a=getTripCostdata", "postData="+base64Input);
    console.log(response.data.data[0])
    this.tripCostArray = response.data?.data
    this.total = parseInt(response?.data?.data.TotalCount);
  }

  async getTripData(Id){
    var postData={Id:Id,}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=trip&a=getTripCostdata", "postData="+base64Input);
   console.log(response.data.data)
    return response.data.data[0]
  }
  async addTripCost(obj){
    // this.loading=true;
    var postData={TripDetails:obj}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=trip&a=addTripCost", "postData="+base64Input);
    console.log(response)
   return response
  }

  async deleteTripCost(Id){
    var postData={Id:Id}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=trip&a=deleteTripCost", "postData="+base64Input);
    console.log(response)
   return response
  }
}
export default TripCostStore