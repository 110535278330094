import React from "react";
import {
  Select,
  Button,
  Space,
  DatePicker,
  Row,
  Col,
  Dropdown,
  Checkbox,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import SensorReportStore from "../store/SensorReportStore";

import moment from "moment";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import VehicleStore from "../store/VehicleStore";
const { RangePicker } = DatePicker;

dayjs.extend(customParseFormat);

const defaultValue = moment().startOf("day").hour(0).minute(0).second(0);
const { Option } = Select;

let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 250;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 150;
    } else if (val.title?.length > 8) {
      val.width = 125;
    } else {
      val.width = 110;
    }
    // console.log(val.title.length)
  });

  return arr;
};
class SensorReportList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.sensorReportStore = new SensorReportStore(this);
    this.vehicleStore = new VehicleStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      companyId: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      selectedVehicles: '',
      selectAllVehicles: false,
      modalVisible: false,
      column: "",
      filter: "",
      Notcompany: false,
      Notasset: false,

      selectedColumns: widthAdderFunc([
        {
          // title: "Company",
          title: context.translate("_FLEET_DASHBORD_NEWPOI_COMPANY_"),
          dataIndex: "company",
          key: "company",
          width: "15%",
        },
        {
          // title: "Asset Name",
          title: context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",
          width: "15%",
        },
        {
          // title: "Time Stamp",
          title: context.translate("_TIMESTAMP_"),
          dataIndex: "timestamp",
          key: "timestamp",
          width: "15%",
          render: (value) => {
            if (value) {
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            } else {
              return <></>;
            }
          },
        },

        {
          // title: "Alarm Type",
          title: context.translate("_ALARMTYPE_"),
          dataIndex: "alarmType",
          key: "alarmType",
          width: "15%",
        },
        {
          // title: "Power",
          title: context.translate("_POWER_"),
          dataIndex: "power",
          key: "power",
          width: "15%",
        },
        {
          // title: "Battery Voltage",
          title: context.translate("_BATTORY_VOLTAGE_"),
          dataIndex: "batteryVoltage",
          key: "batteryVoltage",
          width: "15%",
        },
        {
          // title: "Power Voltage",
          title: context.translate("_POWER_VOLTAGE_"),
          dataIndex: "powerVoltage",
          key: "powerVoltage",
          width: "15%",
        },
        {
          // title: "Sensor Type",
          title: context.translate("_SENSOR_TYPE_"),
          dataIndex: "sensorType",
          key: "sensorType",
          width: "15%",
        },
        {
          // title: "Sensor Id",
          title: context.translate("_SENSOR_ID_"),
          dataIndex: "sensorId",
          key: "sensorId",
          width: "15%",
        },
        {
          // title: "Battery Voltage Status",
          title: context.translate("_BATTORY_VOLTAGE_STATUS_"),
          dataIndex: "batteryVoltageStatus",
          key: "batteryVoltageStatus",
          width: "15%",
        },
        {
          // title: "Sensor Battery Voltage",
          title: context.translate("_SENSOR_BATTORY_VOLTAGE_"),
          dataIndex: "sensorBatteryVoltage",
          key: "sensorBatteryVoltage",
          width: "15%",
        },
        {
          // title: "Temperature Alert Status",
          title: context.translate("_TEMPRATURE_ALERT_STATUS_"),
          dataIndex: "temperatureAlertStatus",
          key: "temperatureAlertStatus",
          width: "15%",
        },
        {
          // title: "Sensor Temperature",
          title: context.translate("_SENSORE_TEMPERATURE_"),
          dataIndex: "sensorTemperature",
          key: "sensorTemperature",
          width: "15%",
        },
        {
          // title: "Sensor Button Status",
          title: context.translate("_SENSOR_BUTTON_STATUS_"),
          dataIndex: "sensorButtonStatus",
          key: "sensorButtonStatus",
          width: "15%",
        },
        {
          // title: "Sensor Status",
          title: context.translate("_SENSOR_STATUS_"),
          dataIndex: "sensorStatus",
          key: "sensorStatus",
          width: "15%",
        },
        {
          // title: "Humidity",
          title: context.translate("_HUMIDITY_"),
          dataIndex: "humidity",
          key: "humidity",
          width: "15%",
        },
        {
          // title: "RSSI",
          title: context.translate("_RSSI_"),
          dataIndex: "RSSI",
          key: "RSSI",
          width: "15%",
        },
        {
          // title: "Sensor Time",
          title: context.translate("_SENSOR_TIME_"),
          dataIndex: "sensorTime",
          key: "sensorTime",
          width: "15%",
          render: (value) => {
            if (value) {
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            } else {
              return <></>;
            }
          },
        },
      ]),
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
          icon: <PlusOutlined />,
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
          icon: <PlusOutlined />,
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];

        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          console.log(this.state.fromDate, this.state.toDate);

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            interval: this.state.interval,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.sensorReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.sensorReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.sensorReportStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.sensorReportStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId)
      await this.setState({ companyId: this.props.getUserData().userCompanyId })
      await this.setState({ userId: this.props.getUserData().userId })
      await this.vehicleStore.getVehiclesCompanyId(this.props.getUserData().userCompanyId );
      await this.handleSearch();
    }
    this.setState({ pageSize: 50 });

    await this.setState({ column: this.state.selectedColumns });
  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }
  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };
  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ currentPage: 1, companyId: comp?.value });
    await this.setState({ selectedVehicles: '' });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    await this.setState({ listUpdated: 1 });
    // this.handleSearch();
  }

  // async onSearch(value) {
  //     console.log(value)
  //     await this.setState({ emailId: value });
  //     this.handleSearch();
  // };

  async handleSearch() {
    this.setState({ loading: true });
    this.setState({ Notasset: false });
    await this.sensorReportStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });

    await this.setState({ listUpdated: 1 }); //need to reload data
    this.setState({ loading: false });
  }

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (!this.state.selectedVehicles){
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false });
      await this.sensorReportStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
        vehicleId: this.state.selectedVehicles,
        fromDate: this.state.fromDate,
        toDate: defaultValue.format("YYYY-MM-DD HH:mm:ss"),
        pageNo:this.state.currentPage
      });
      // console.log(this.state.fromDate,this.state.toDate,)
      await this.setState({ listUpdated: 1 });
    }
  };
  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  render() {
    //console.log('in render1')
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const assetInputStyle = this.state.Notasset
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Sensor Report</div> */}
              <div className="heading">
                {this.context.translate("_SENSOR_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Dropdown menu={this.menuProps}>
                  <Button>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Space>
              <Col span={16}>
                <CompanyAutocomplete
                  onChange={(value) => this.handleChange(value)}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
              </Col>

              <Col>
                {/* <Select
                  onSearch={this.handleSearch}
                  value={this.state.selectedVehicles}
                  onChange={(val) => this.setState({ selectedVehicles: val ,Notasset:false})}
                  maxTagCount="responsive"
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_")}
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 150 }}
                  status={this.state.Notasset ? 'error' : false}
                  // style={assetInputStyle }
                /> */}

                <Select
                  value={
                    this.state.selectedVehicles
                      ? this.state.selectedVehicles
                      : null
                  }
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    this.setState({ Notasset: false });
                  }}
                  style={{ width: 150 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
              </Col>
              <Col>
                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
              </Col>

              <Col>
                <Button
                  type="primary"
                  onClick={async() => {
                    await this.setState({ currentPage: 1,pageSize:50});
                    await this.showData();
                    
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Col>
            </Space>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
             
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.sensorReportStore?.total}
            columns={this.state.column}
            dataSource={this.sensorReportStore?.sensorReport}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   //  current:this.state.pageNo
            //   total: this.sensorReportStore?.total || 0,
            // }}
            loading={this.sensorReportStore?.loading}
            onChange={this.handleTableChange}
            scroll={{
              // y: this.state.clientHeight-240,
              x: 100,
            }}
          />
        </div>
      </>
    );
  }
}
SensorReportList.contextType = StoreContext;
export default observer(withRouter(SensorReportList));
