import React from "react";
import { Button, Space, Row, Col } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";
import PaymentStore from "../store/PaymentStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import CompanyStore from "../store/CompanyStore";
class PaymentList extends React.Component {
  constructor(props) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.paymentStore = new PaymentStore();
    this.companyStore = new CompanyStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      showDetails: false,
      companyId: "",
      companyVal_Lab: "",
      loadList:0
    };

    // this.query = new URLSearchParams(this.props.location.search)
    // this.queryId = this.query.get("oo")

    // console.log(this.queryId,this.query)
  }

  async componentDidUpdate(prevProps, prevState){
    if( Number(this.props.getUserData().isadmin)===1 ||  Number(this.props.getUserData().isadmin)===0){
      // console.log('innnnnn');
        this.props.navigate("/login");
       
     }
  }
  async componentDidMount() {

    // this.handleSearch();
    // console.log(this.context.viewStateStore.value, "vale");

    // const setPayment =
    //   this.context.viewStateStore.paymentObject;
    // if (setPayment.companyId !== "") {
    //   console.log(setPayment);
    //   await this.paymentStore.loadData({
    //     offset: (this.state.currentPage - 1) * this.state.pageSize,
    //     limit: this.state.pageSize,
    //     companyId: setPayment.companyId,
    //   });

    //   const singleData = await this.companyStore.getCompanyDataById(
    //     setPayment.companyId
    //   );

    //   await this.setState({ loadList: 1 });
    //   console.log("singleData", singleData);
    //   await this.setState({
    //     companyVal_Lab: singleData,
    //     companyId: setPayment.companyId,
    //   });
    //   console.log("singleData", singleData);
    // }
    

    // await this.paymentStore.loadData({ offset: 0, limit: 50 });

    this.setState({ pageSize: 50 });

    this.columns = [
      {
        // title: "Account Name",
        title:this.context.translate("_FLEET_COMPANY_COMPANYLIST_COMPANYNAME_"),
        dataIndex: "name",
        width: "15%",
      },
      {
        // title: "Payment Id",
        title:this.context.translate("_PAYMENT_ID_"),
        dataIndex: "paymentId",
        width: "15%",
      },
      {
        // title: "Payment Method",
        title:this.context.translate("_PAYMENT_METHOD_"),
        dataIndex: "type",
        width: "15%",
      },
      {
        // title: "Total Vehicle",
        title:this.context.translate("_TOTAL_VEHICLE_"),
        dataIndex: "count",
        width: "15%",
      },
      {
        // title: "Total Amount",
        title:this.context.translate("_TOTAL_AMOUNT_"),
        dataIndex: "amount",
        width: "15%",

       
      },
      {
        // title: "Payment Date",
        title:this.context.translate("_PAYMENT_DATE_"),
        dataIndex: "created_at",
        width: "15%",
      },

      {
        // title: "Details",
        title:this.context.translate("_PAYMENT_DATE_"),
        dataIndex: "finishedAt",
        width: "15%",
        render: (value,record) => {

          console.log(value,record)
          return (
            <Link
              to={`/vehiclepaymentlist/${record.companyId}`}
              // {`/assignvehicletouser/${this.state.companyId}/${record.userId}
            >
              <Button
                onClick={async () => {
                  this.setState({ showDetails: true });
                  this.context.viewStateStore.setPaymentStates({companyId:record.companyId})
                  // await this.paymentStore.loadPaymentList({  companyId: this.state.companyId, })
                }}
              >
                {/* Details */}
                {this.context.translate("_DETAILS_")}
              </Button>
            </Link>
          );
        },
      },
    ];


        
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId)
      await this.setState({ companyId: this.props.getUserData().userCompanyId })
      await this.setState({ userId: this.props.getUserData().userId })
      await this.handleSearch();
    }
    else{
      this.handleSearch();
      console.log(this.context.viewStateStore.value, "vale");
  
      const setPayment =
        this.context.viewStateStore.paymentObject;
      if (setPayment.companyId !== "") {
        console.log(setPayment);
        await this.paymentStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: setPayment.companyId,
        });
  
        const singleData = await this.companyStore.getCompanyDataById(
          setPayment.companyId
        );
  
        await this.setState({ loadList: 1 });
        console.log("singleData", singleData);
        await this.setState({
          companyVal_Lab: singleData,
          companyId: setPayment.companyId,
        });
        console.log("singleData", singleData);
      }
      
  
    }
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination, "pagi");
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleSearch() {
   
    await this.paymentStore.loadData({
      companyId: this.state.companyId,
      offset: this.state.currentPage - 1,
      limit: this.state.pageSize,
    });
   await this.setState({ loading: true });
  }
  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ companyVal_Lab: singleData });
    // await this.setState({ currentPage: 1, companyId: comp.value });
   await this.handleSearch();
  }

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Payment List</div> */}
              <div className="heading">{this.context.translate("_PAYMENT_LIST_")} </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  value={
                    this.state.companyVal_Lab ? this.state.companyVal_Lab : null
                  }
                  // allowClear={true}
                  onChange={(value) => this.handleChange(value)}
                  style={{ width: 200 }}
                />
              </Space>
            </Col>
          </Row>

          <ListTable
            current={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.paymentStore?.total}
            columns={this.columns}
            dataSource={this.paymentStore?.payment}
            loading={this.paymentStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>
      </>
    );
  }
}
PaymentList.contextType = StoreContext;
export default observer(withRouter(PaymentList));
