import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  makeObservable,
  observable,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class CountryStore {

  country=[]
  timeZones=[]
  
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        
      country:observable,
      total:observable,
      loading:observable,
      
    
      getCountry:action,
      getTimeZone:action,
     
     
    });
     this.rootStore = rootStore;
    
  }

  

  async getCountry(){
      
      var postData = {}
      const base64Input = await base64_encode(JSON.stringify(postData));
      var response = await postApi.getClient().post("index.php?c=company&a=getCountries", "postData="+base64Input);
      console.log(response)
      this.country = [...response?.data?.data?.countries]
      
      return response?.data?.data?.countries
  }

  async getTimeZone(){
    var postData = {}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=company&a=getTimezone", "postData="+base64Input);
    console.log(response)
    const timeZone = [...response?.data?.data?.timezones]

    const res  = timeZone.map((country) => {
      return (
         {abbr:country.abbr + " - " + "GMT" + country.offset,timezoneId:country.timezoneId}
      );
    })
    
    
    this.timeZones = res
  }

  

}

export default CountryStore;
