import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";

import CompanyAutocomplete from "./CompanyAutocomplete";

import dayjs from "dayjs";
import CompanyStore from "../store/CompanyStore";
import VehicleAuditSore from "../store/VehicleAuditStore";

class DiscountAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = { loadingSpin: false, companyId: "", Id: -1, accidentType: 2 };

    this.vehicleAuditStore = new VehicleAuditSore(this);
    this.companyStore = new CompanyStore(this);
  }
  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      this.context.viewStateStore.setVehAuditStates({
        companyId: this.props.getUserData().userCompanyId,
      });

      await this.vehicleAuditStore.getVehicleData(
        this.props.getUserData().userCompanyId
      );

      await this.vehicleAuditStore.getDriverData(
        this.props.getUserData().userCompanyId
      );

      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
    }

    const Id = this.props.Id;

    if (Id) {
      await this.filldata(Id);
    }

    this.setState({ update: 1 });
  }

  async filldata(Id) {
    const getVehAuditData = await this.vehicleAuditStore.getVehAuditData({
      Id,
      companyId: this.props.companyId,
    });

    await this.vehicleAuditStore.getVehicleData(getVehAuditData.companyId);

    await this.vehicleAuditStore.getDriverData(getVehAuditData.companyId);

    await this.setState({
      companyId: getVehAuditData.companyId,
      Id: getVehAuditData.Id,
    });
    const singleData = await this.companyStore.getCompanyDataById(
      getVehAuditData.companyId
    );

    this.formRef.current.setFieldsValue({
      companyId: singleData,
      Id: getVehAuditData.Id,
      vehicleAuditScore: getVehAuditData.vehicleAuditScore,
      driverId: getVehAuditData.driverId,
      vehicleIds: getVehAuditData.vehicleId,
      Date: getVehAuditData.date ? dayjs(getVehAuditData.date) : null,
      outsidersn: getVehAuditData.outsidersn,
      comments: getVehAuditData.comments,
      accidentType: getVehAuditData.accidentType,
    });
  }

  onVehAuditDetailSubmit = async (fieldsValue) => {
    const data = {
      ...fieldsValue,
      Id: this.state.Id,
      companyId: this.state.companyId,
      Date: fieldsValue?.Date
        ? fieldsValue?.Date?.format("YYYY-MM-DD HH:mm:ss")
        : null,
      accidentType: this.state.accidentType,
    };
    //  "2024-07-02 16:27:00
    this.setState({ loading: true });
    const response = await this.vehicleAuditStore.addData(data);
    await this.props.response(response);

    this.setState({ loading: false });
  };
  async handleChange(comp) {
    await this.vehicleAuditStore.getVehicleData(comp.value);

    await this.vehicleAuditStore.getDriverData(comp.value);

    await this.setState({ companyId: comp.value });
    if (!this.props.Id) {
      this.context.viewStateStore.setVehAuditStates(comp.value);
    }
    await this.setState({ companyId: comp.value });
  }

  closeModal = () => {
    this.props.closeModal();
  };

  accidentType = (e) => {
    console.log(e.target.value);
    this.setState({ accidentType: e.target.value });
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              ref={this.formRef}
              layout="vertical"
              name="nest-messages"
              onFinish={this.onVehAuditDetailSubmit}
              style={{ maxWidth: "100%", padding: 0 }}
              className="formClass"
              initialValues={{ Date: dayjs() }}
            >
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate(
                        "_FLEET_DRIVER_DRIVERDETAIL_COMPANY_"
                      )}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => this.handleChange(value)}
                        style={{ width: "100%" }}
                        value={this.state.companyId}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="vehicleIds"
                    label={this.context.translate("_ASSET_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={this.vehicleAuditStore.vehicleArr}
                      fieldNames={{
                        value: "vehicleId",
                        label: "vehicleNo",
                      }}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    name="driverId"
                    label={this.context.translate("_DRIVER_")}
                  >
                    <Select
                      options={this.vehicleAuditStore.driverArr}
                      fieldNames={{
                        value: "driverId",
                        label: "driverName",
                      }}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    name="vehicleAuditScore"
                    label={this.context.translate("_VEHICLE_AUDIT_SCORE_")}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="outsidersn"
                    label=" "
                    valuePropName="checked"
                  >
                    <Checkbox>
                      {this.context.translate("_OUTSIDE_PARTY_")}
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="accidentType"
                    label={this.context.translate("_ACCIDENT_TYPE_")}
                  >
                    <Radio.Group onChange={this.accidentType} defaultValue={2}>
                      <Radio value={1}>Major Accident</Radio>
                      <Radio value={2}>Minor Accident</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="Date"
                    label={this.context.translate("_DATE_")}
                    rules={[{ type: Date }]}
                  >
                    <DatePicker
                      showTime
                      style={{ width: "100%" }}
                      format="DD-MM-YYYY hh:mmA"
                      onChange={(date) => {
                        if (date) {
                          this.formRef.current.setFieldsValue({ date: date });
                        }
                      }}
                      disabledDate={(current) => {
                        return current && current > dayjs();
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="comments"
                    label={this.context.translate("_ACCIDENT_REASON_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal();
                  }}
                >
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="formSaveButton"
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}
DiscountAdd.contextType = StoreContext;
export default observer(withRouter(DiscountAdd));
