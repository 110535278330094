import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
// import { postApi } from '../components/Utility'
import {
    action,
    computed,
    makeObservable,
    observable,
    autorun,
    runInAction,
} from 'mobx';
import { act } from 'react-dom/test-utils';
import { postApi, baseURL } from "../components/Utility";

class VehicleNotPollReportStore {
    total=0
    vehicleNotPollReportStore = []
    rootStore
    loading=false

    constructor(rootStore) {
        makeObservable(this, {

            total:observable,
            vehicleNotPollReportStore: observable,
            loadData: action,
            loading:observable,
            exportExcel:action,
            exportcsv:action,
            exportxml:action,
            exportpdf:action
        });
        this.rootStore = rootStore;

    }

    async loadData({companyId,offset,limit,polltime,pageNo}) {
        this.loading=true
        console.log("vehicle list")
        var postData = { "companyId": companyId, "polltime": polltime, "userid": null, "offset": offset, "limit": limit, "page": pageNo }
        const base64Input = await base64_encode(JSON.stringify(postData));
        var response = await postApi.getClient().post("index.php?c=vehicle&a=vehiclenotpolled", "postData=" + base64Input);

        console.log(response?.data?.data?.vehiclenotpool)
        this.vehicleNotPollReportStore = response?.data?.data?.vehiclenotpool
        this.total = parseInt(response?.data?.data?.TotalCount)
        console.log(this.total)
        this.loading=false
    }

    async exportExcel(getData) {
        console.log("getData", getData);
        let lang = "english";
        window.location.href =
          baseURL +
          "index.php?c=vehicle&a=exportnotpollexcel&cid=" +
          getData.companyId +
          "&polltime=" +
          getData.polltime +
          "&uId=" +
          getData.uId+
          "&colList=" +
          getData.colList;
        //   "&language=" +
        //   lang;
      }

      async exportcsv(getData) {
        console.log("getData", getData);
        let lang = "english";
        window.location.href =
          baseURL +
          "index.php?c=vehicle&a=exportnotpollcsv&cid=" +
          getData.companyId +
          "&polltime=" +
          getData.polltime +
          "&uId=" +
          getData.uId+
          "&colList=" +
          getData.colList;
        //   "&language=" +
        //   lang;
      }

      
      async exportxml(getData) {
        console.log("getData", getData);
        let lang = "english";
        window.location.href =
          baseURL +
          "index.php?c=vehicle&a=exportnotpollxml&cid=" +
          getData.companyId +
          "&polltime=" +
          getData.polltime +
          "&uId=" +
          getData.uId+
          "&colList=" +
          getData.colList;
        //   "&language=" +
        //   lang;
      }

      async exportpdf(getData) {
        console.log("getData", getData);
        let lang = "english";
        window.location.href =
          baseURL +
          "index.php?c=vehicle&a=exportnotpollpdf&cid=" +
          getData.companyId +
          "&polltime=" +
          getData.polltime +
          "&uId=" +
          getData.uId+
          "&colList=" +
          getData.colList;
        //   "&language=" +
        //   lang;
      }



    
}

export default VehicleNotPollReportStore;
