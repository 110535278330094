import React from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Space,
  message,
  Modal,
  Form,
  Select,
  DatePicker,
  Dropdown,
  Tooltip,
  Table,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import config from "../config";
import moment from "moment/moment";
import {
  DeleteFilled,
  DownloadOutlined,
  ExportOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CompanyAutocomplete from "./CompanyAutocomplete";
import dayjs from "dayjs";
import ListTable from "./ListTable";
import withRouter from "../withRouter";
import WASLCompanyStore from "../store/WASLCompanyStore";
import DeleteConfirm from "./DeleteConfirm";
const dateFormat = "YYYY-MM-DD";
const cMargins ={name:100,identityNo:-50,reply:0,commercialRecordNo:-100,commercialRecordIssueDate:-250,phoneNo:20,
                 extensionNo:-40,emailAddress:50,managerName:15,managerPhone:-20,managerMobile:-35,activity:-20,action:-15};

let widthAdderFunc = (arr) => {
  let array = arr;
  array.forEach((val) => {
    console.log(val.dataIndex)
    val.width = ((Number(val.title.length) * 11) + (cMargins[val.dataIndex]?cMargins[val.dataIndex]:0));
  });
  console.log(arr);
  return arr;
};
class WASLCompanyList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.waslCompanyStore = new WASLCompanyStore(this);

    // console.log(this.context);

    this.state = {
      showWASLRegistration: false,
      loading: false,
      offset: 0,
      limit: 50,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      loadList: 0,
      Company: true,
      Individual: false,
      cname: "",
      identityNo: "",
      crNo: "",
      loadData: 0,
      companyId: "",
      SFDAActivity: false,
      UcompanyId: "",
      cid: "",
      Individual2: true,
      showAlertModal: false,
      searchField: "",
    };

    this.columns = widthAdderFunc([
      {
        title: context.translate("_ACCOUNT_"),
        fixed: "left",
        dataIndex: "name",
        key: "name",
       
        // ellipsis: true,
      },
      {
        title: context.translate("_IDENTITY_NUMBER_"),
        dataIndex: "identityNo",
        key: "identityNo",
        
        // ellipsis: true,
      },
      {
        title: context.translate("_COMMERCIAL_RECORD_NUMBER_"),
        dataIndex: "commercialRecordNo",
        key: "commercialRecordNo",
        ellipsis: true,
       
      },
      {
        title: context.translate("_COMMERCIAL_RECORD_ISSUE_DATE_"),
        dataIndex: "commercialRecordIssueDate",
        key: "commercialRecordIssueDate",
        ellipsis: true,
       
      },
      {
        title: context.translate("_FLEET_DASHBORD_NEWPOI_PHONE_"),
        dataIndex: "phoneNo",
        key: "phoneno",
        // ellipsis: true,
      
      },
      {
        title: context.translate("_EXTENSION_NUMBER_"),
        dataIndex: "extensionNo",
        key: "extensionNumber",
        // ellipsis: true,
       
      },
      {
        title: context.translate("_EMAIL_ID_"),
        dataIndex: "emailAddress",
        key: "emailId",
       
      },
      {
        title: context.translate("_MANAGER_NAME_"),
        dataIndex: "managerName",
        key: "managerName",
        // ellipsis: true,
       
      },
      {
        title: context.translate("_MANAGER_PHONE_"),
        dataIndex: "managerPhone",
        key: "managerPhone",
        // ellipsis: true,
      
      },
      {
        title: context.translate("_MANAGER_MOBILE_"),
        dataIndex: "managerMobile",
        key: "managerMobile",
        // ellipsis: true,
    
      },
      {
        title: context.translate("_REPLY_"),
        dataIndex: "reply",
        key: "reply",
      
      },
      // {
      //   title: context.translate("_WASL_KEY_"),
      //   dataIndex: "waslKey",
      //   key: "waslKey",
      //   ellipsis: true,
      // },
      {
        title: context.translate("_ACTIVITY_"),
        dataIndex: "activity",
        key: "activity",
       
      },
      {
        title: context.translate("_ACTION_"),
        dataIndex: "Action",
        fixed: "right",
       
        render: (_, record) => {
          return (
            <>
              <Space>
              {(Number(this.props.getUserData().isadmin) === -1 ||
                Number(this.props.getUserData().isadmin) === 3) && (
                <>
                <Tooltip
                  // title="WASL Status"
                  title={this.context.translate("_WASL_STATUS_")}
                  placement="bottom"
                  color={config.tooltipColorEdit}
                >
                  <SearchOutlined
                    onClick={async () => {
                      await this.WASLCompanyStatus(record);
                      await this.setState({ showAlertModal: true });
                    }}
                  />
                </Tooltip>
               
                <DeleteConfirm
                  onConfirm={(e) => {
                    this.onDelete(record);
                  }}
                >
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Tooltip
                      // title="Delete"
                      title={this.context.translate(
                        "_FLEET_DASHBORD_RULETRIGGER_DELETE_"
                      )}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
                </>)}
              </Space>
            </>
          );
        },
      },
    ]);
    this.formRef = React.createRef();

    // this.ExportMenuProps = {
    //   items: [
    //     {
    //       label: <Button>Excel</Button>,
    //       key: "excel",
    //     },
    //   ],
    //   onClick: (val) => {
    //     const result = [];
    //     this.columns.map((obj) => result.push(obj.dataIndex));
    //     //  console.log(result.toString())
    //     var getData = {
    //       cid: this.state.cid,
    //       UcompanyId: this.state.UcompanyId,
    //       colList: result,
    //     };
    //     console.log(getData);
    //     if (val.key == "excel") {
    //       this.waslCompanyStore.exportExcel(getData);
    //     }
    //   },
    // };
  }
  async componentDidMount() {
    //await this.waslCompanyStore.loadData({ limit: 50, offset: 0 });
    this.setState({ pageSize: 50 });
    this.handleSearch();
  }


  async componentDidUpdate(prevProps, prevState){
    if(Number(this.props.getUserData().isadmin)===0 || Number(this.props.getUserData().isadmin)===1){
      // console.log('innnnnn');
        this.props.navigate("/login");
       
     }
  }

  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      loadList: 1,
      currentPage: 1,
      pageSize: 50,
    });

    const getData = await this.waslCompanyStore.getCompanyInfo(comp.value);
    await this.setState({ getData: getData });
    // console.log("getData",getData)

    await this.formRef.current.setFieldsValue({ phoneNo: getData.contact1No });
    await this.formRef.current.setFieldsValue({
      emailAddress: getData.contact1Email,
      identityNo: getData.identityNo,
      DOBFormat: getData.dobFormat,
    });
    console.log(getData.dobFormat);
    console.log(getData.dob);
    this.formRef.current.setFieldsValue({
      commercialRecordIssueDate: getData.commercialRecordIssueDate
        ? dayjs(getData.commercialRecordIssueDate)
        : null,
      dob: getData.dob ? dayjs(getData.dob) : null,
      commercialRecordNo: getData.commercialRecordNo,
    });
    await this.formRef.current.setFieldsValue({
      managerName: getData.contact1,
    });
    await this.formRef.current.setFieldsValue({
      managerPhone: getData.contact1No,
    });

    await this.handleSearch();
    await this.setState({ loadList: 1 });
  }
  // searchFieldsFun = async (e) => {
  //   //console.log(e.type)
  //   const searchFields = e.target.value;
  //   this.setState({ searchField: searchFields, offset: 0 });

  //   if (
  //     (searchFields.length > 3 || searchFields.length === 0) &&
  //     e.type == "change"
  //   ) {
  //     //  await this.waslCompanyStore.loadData({
  //     //    offset: (this.state.currentPage - 1) * this.state.pageSize,
  //     //    limit: this.state.pageSize,
  //     //    searchField: searchFields,
  //     //  });
  //     await this.handleSearch();
  //   }
  //   if (searchFields.length > 1 && e.type == "keydown") {
  //     await this.handleSearch();
  //   }

  //   await this.setState({ loadList: 1 });
  // };

  async handleSearch() {
    this.setState({ loading: true });
    await this.waslCompanyStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      UcompanyId : this.props.getUserData().isadmin===3?this.props.getUserData().userCompanyId:0
    });

    this.setState({ loading: false });
    await this.setState({ loadList: 1 });
  }

  searchInput = async (e) => {
    const value = e.target.value;
    console.log(value);
    await this.setState({ searchField: value });
    if (value.length > 3) {
      await this.waslCompanyStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        searchField: value,
        companyId: this.state.companyId,
      });
      await this.setState({ loadList: 1, companyLoading: false });
    } else if (value.length === 0) {
      await this.waslCompanyStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
      });
      await this.setState({
        loadList: 1,
        companyLoading: false,
        currentPage: 1,
      });
    }
  };

  async inputSearchButton() {
    if (this.state.searchField.length > 0) {
      await this.waslCompanyStore.loadData({
        offset: 0,
        limit: 50,
        searchField: this.state.searchField,
      });
    }
    await this.setState({ loadList: 1 });
  }

  registerAccountType = (value) => {
    // console.log(value);
    if (value === "Individual") {
      this.setState({ Individual: true });
      this.setState({ Individual2: false });

      this.setState({ Company: false });
    } else if (value === "Company") {
      this.setState({ Individual: false });
      this.setState({ Individual2: false });

      this.setState({ Company: true });
    }
  };

  onWASLRegistrationFinish = async (value) => {
    // console.log(value);
    const data = {
      ...value,
      userId: 1,

      commercialRecordIssueDate:
        value?.commercialRecordIssueDate?.format(dateFormat),
      companyId: this.state.companyId,
      dobindividual: value?.dobindividual?.format(dateFormat),
    };
    console.log(data);
    const response = await this.waslCompanyStore.registerCompany(data);
    if (response?.success === "S") {
      message.success(response.data.message);
      await this.handleSearch();
      console.log(response);
    } else {
      message.error(response.data.message);
      await this.handleSearch();
    }
    if (response.data.message) {
      this.setState({ showWASLRegistration: false });
      this.formRef.current?.resetFields();
    }
  };

  async onDelete(record) {
    console.log(record);
    const response = await this.waslCompanyStore.deleteRecord({
      Id: record.Id,
      companyId: record.companyId,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  // showData = async () => {
  //   // console.log("assdfds")
  //   await this.waslCompanyStore.loadData({
  //     offset: (this.state.currentPage - 1) * this.state.pageSize,
  //     limit: this.state.pageSize,
  //     cname: this.state.cname,
  //     identityNo: this.state.identityNo,
  //     crNo: this.state.crNo,
  //   });
  //   // console.log(this.state.cname,)
  //   this.setState({ loadData: 1 });
  // };

  validatePhoneNumber = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1.length);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  validateNumber = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1);
    if (value1) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  // warning = () => {
  //   console.log(this.waslCompanyStore?.WASLCompanyStatus);
  //   Modal.warning({
  //     title: "Alert",
  //     content: this.waslCompanyStore?.WASLCompanyStatus,

  //   });
  // };

  WASLCompanyStatus = async (record) => {
    await this.waslCompanyStore.getWASLCompanyStatus({ Id: record.Id });
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_WASL_COMPANY_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                <Link to={"/companylist"}>
                  <Button type="primary">
                    {this.context.translate("_BACK_")}
                  </Button>
                </Link>
                <Button
                  type="primary"
                  onClick={() => this.setState({ showWASLRegistration: true })}
                >
                  + {this.context.translate("_REGISTER_")}
                </Button>
                {/* <Dropdown menu={this.ExportMenuProps} disabled={this.companyStore?.companies.length > 0 ? false : true}> */}
                {/* <Dropdown menu={this.ExportMenuProps}>
                <Button>
                  <Space>
                    <DownloadOutlined />
                  </Space>
                </Button>
              </Dropdown> */}
                <Button
                  style={{ backgroundColor: config.activeColor }}
                  disabled={
                    this.waslCompanyStore?.WASLCompanyArray.length > 0
                      ? false
                      : true
                  }
                  onClick={async (val) => {
                    const result = [];
                    await this.columns.map((obj) => result.push(obj.dataIndex));
                    //  console.log(result.toString())
                    var getData = {
                      cid: this.state.cid,
                      UcompanyId: this.state.UcompanyId,
                      colList: result,
                      searchField: this.state.searchField,
                    };
                    await this.waslCompanyStore.exportExcel(getData);
                    await this.setState({ loadList: 1 });
                  }}
                >
                  <DownloadOutlined />
                  {this.context.translate("_EXPORT_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={22}>
              <Space>
                <Input
                  style={{ width: 400 }}
                  // placeholder="Account Name,Identity Number,CR Number"
                  placeholder={this.context.translate(
                    "_Account_Name_Identity_Number_CR_Number_"
                  )}
                  onChange={(e) => this.searchInput(e)}
                  // onChange={async (e) => {
                  //   this.searchFieldsFun(e);
                  //   // this.handleSearch();
                  // }}
                  // onPressEnter={async (e) => {
                  //   this.searchFieldsFun(e);
                  //   // this.handleSearch();
                  // }}
                ></Input>

                <Button
                  type="primary"
                  onClick={async () => await this.inputSearchButton()}
                >
                  <SearchOutlined />
                </Button>
              </Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            total={this.waslCompanyStore?.total}
            pageSize={this.state.pageSize}
            columns={this.columns}
            // dataSource={this.dataSource}
            dataSource={this.waslCompanyStore?.WASLCompanyArray}
            loading={this.waslCompanyStore?.loading}
            onChange={this.handleTableChange}
            scroll={{
              x: 1800,
            }}
          />
        </div>
        <Modal
          bodyStyle={{ height: "645px" }}
          className="formShowModal"
          title={this.context.translate("_WASL_REGISTRATION_")}
          destroyOnClose={true}
          open={this.state.showWASLRegistration}
          onCancel={() => this.setState({ showWASLRegistration: false })}
          footer={null}
          width={665}
          centered
          // onOk={}
        >
          <Form
            ref={this.formRef}
            layout="vertical"
            name="companyFrm"
            onFinish={this.onWASLRegistrationFinish}
            // initialValues={{ mapSetting: false, mapSetting2: false }}
            style={{ maxWidth: "100%", padding: 0 }}
            className="formClass"
          >
            <Row className="overflowFormInputs">
              <Col span={12} className="formColumnClass">
                <Form.Item
                  name="companyId"
                  label={this.context.translate("_ACCOUNT_")}
                  rules={[{ required: true }]}
                >
                  <CompanyAutocomplete
                    onChange={(value) => {
                      this.handleChange(value);
                    }}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  name="registerAccountType"
                  label={this.context.translate("_REGISTRATION_TYPE_")}
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder={this.context.translate("_SELECT_TYPE_")}
                    onChange={(value) => this.registerAccountType(value)}
                    autoClearSearchValue
                  >
                    <Select.Option value="Company">
                      {this.context.translate("_COMPANY_")}
                    </Select.Option>
                    <Select.Option value="Individual">
                      {/* Individual */}
                      {this.context.translate("_INDIVIDUAL_")}
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="identityNo"
                  label={this.context.translate("_IDENTITY_NUMBER_")}
                  rules={[{ required: true }]}
                >
                  <Input
                    onChange={(e) =>
                      this.validateNumber(e.target.value, "identityNo")
                    }
                  />
                </Form.Item>

                {this.state.Company && (
                  <>
                    <Form.Item
                      name="commercialRecordNo"
                      label={this.context.translate(
                        "_COMMERCIAL_RECORD_NUMBER_"
                      )}
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="commercialRecordIssueDate"
                      label={this.context.translate(
                        "_COMMERCIAL_RECORD_ISSUE_DATE_"
                      )}
                      rules={[{ required: true }]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        format={dateFormat}
                      />
                    </Form.Item>
                  </>
                )}

                <Form.Item
                  name="extensionNo"
                  label={this.context.translate("_EXTENSION_NUMBER_")}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="activity"
                  label={this.context.translate("_ACTIVITY_")}
                  initialValue={"DEFAULT"}
                  rules={[{ required: true }]}
                >
                  <Select
                    autoClearSearchValue
                    onSelect={(val) =>
                      val === "SFDA"
                        ? this.setState({ SFDAActivity: true })
                        : this.setState({ SFDAActivity: false })
                    }
                  >
                    <Select.Option value="DEFAULT">
                      {/* DEFAULT */}
                      {this.context.translate("_DEFAULT_")}
                    </Select.Option>
                    <Select.Option value="TOW_CAR">
                      {/* TOW CAR */}
                      {this.context.translate("_TOW_CAR_")}
                    </Select.Option>
                    <Select.Option value="SPECIALITY_TRANSPORT">
                      {/* SPECIALITY TRANSPORT */}
                      {this.context.translate("_SPECIALITY_TRANSPORT_")}
                    </Select.Option>
                    <Select.Option value="BUS_RENTAL">
                      {/* BUS RENTAL */}
                      {this.context.translate("_BUS_RENTAL_")}
                    </Select.Option>
                    <Select.Option value="EDUCATIONAL_TRANSPORT">
                      {/* EDUCATIONAL TRANSPORT */}
                      {this.context.translate("_EDUCATIONAL_TRANSPORT_")}
                    </Select.Option>
                    <Select.Option value="SFDA">
                      {/* SFDA */}
                      {this.context.translate("_SFDA_")}
                    </Select.Option>
                    <Select.Option value="INTERNATIONAL_TRANSPORT">
                      {/* SFDA */}
                      INTERNATIONAL TRANSPORT
                      {/* {this.context.translate("_SFDA_")} */}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col span={2}></Col> */}
              <Col span={12} className="formColumnClass">
                {this.state.SFDAActivity && (
                  <>
                    {/* <div
                        style={{
                          display: this.state.SFDAActivity ? "" : "none",
                        }}
                      > */}
                    <Form.Item
                      name="sfdaCompanyActivity"
                      label={this.context.translate("_SFDA_COMPANY_ACTIVITY_")}
                      rules={[{ required: true }]}
                    >
                      <Select
                        placeholder={this.context.translate(
                          "_SELECT_ACTIVITY_"
                        )}
                      >
                        <Select.Option value="STORE">STORE</Select.Option>
                        <Select.Option value="TRANSPORT">
                          TRANSPORT
                        </Select.Option>
                        <Select.Option value="STORE_TRANSPORT">
                          STORE TRANSPORT
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    {/* </div> */}
                  </>
                )}

                {/* <div style={{ display: this.state.Individual ? "" : "none" }}> */}
                {this.state.Individual && (
                  <>
                    <Form.Item
                      name="DOBFormat"
                      // label="DOB Format"
                      label={this.context.translate("_DOB_FORMATE_")}
                      rules={[{ required: true }]}

                      // initialValue={"Hijri"}
                    >
                      <Select>
                        <Select.Option value="Hijri">
                          {/* Hijri */}
                          {this.context.translate("_HIJRI_")}
                        </Select.Option>
                        <Select.Option value="Gregorian">
                          {/* Gregorian */}
                          {this.context.translate("_GREGORIAN_")}
                        </Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="dobindividual"
                      label={this.context.translate("_DOB_LABEL_")}
                      rules={[{ required: true }]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        format={dateFormat}
                        disabledDate={(current) => {
                          return current && current > dayjs();
                        }}
                      />
                    </Form.Item>
                  </>
                )}

                {/* </div> */}

                <div style={{ display: this.state.Individual2 ? "" : "none" }}>
                  <Form.Item
                    name="DOBFormat"
                    // label="DOB Format"
                    label={this.context.translate("_DOB_FORMATE_")}
                    // initialValue={"Hijri"}
                  >
                    <Select>
                      <Select.Option value="Hijri">
                        {/* Hijri */}
                        {this.context.translate("_HIJRI_")}
                      </Select.Option>
                      <Select.Option value="Gregorian">
                        {/* Gregorian */}
                        {this.context.translate("_GREGORIAN_")}
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="dobindividual"
                    label={this.context.translate("_DOB_LABEL_")}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format={dateFormat}
                      disabledDate={(current) => current.isAfter()}
                    />
                  </Form.Item>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Form.Item
                    style={{ width: "30%" }}
                    name="countryCode"
                    // label="Contact 1"
                    label={" "}
                    // rules={[{ required: true }]}
                    rules={[{ required: true }]}
                    initialValue={+966}
                  >
                    <Select>
                      <Select.Option value={91}>+91</Select.Option>
                      <Select.Option value={966}>+966</Select.Option>

                      <Select.Option value={971}>+971</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    style={{ width: "65%" }}
                    name="phoneNo"
                    // label="Phone Number 1"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_NEWPOI_PHONE_"
                    )}
                    rules={[
                      {
                        pattern: /^\d{8,10}$/,
                        required: true,
                        message: this.context.translate(
                          "_Enter_7_or_10_Digit_Number_"
                        ),
                      },
                    ]}
                    // rules={[{ required: true}]}
                  >
                    <Input
                      onChange={(e) =>
                        this.validatePhoneNumber(e.target.value, "phoneNo")
                      }
                      // maxLength={13}
                    />
                  </Form.Item>
                </div>

                {/* <Form.Item
                    name="phoneNo"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_NEWPOI_PHONE_"
                    )}
                    // min={0}
                    maxLength={10}
                    rules={[{ required: true }]}
                  >
                    <Input
                      onChange={(e) =>
                        this.validatePhoneNumber(e.target.value, "phoneNo")
                      }
                      maxLength={12}
                    />
                  </Form.Item> */}

                <Form.Item
                  name="emailAddress"
                  label={this.context.translate("_EMAIL_ID_")}
                  rules={[{ required: true, type: "email" }]}
                >
                  <Input />
                </Form.Item>

                {this.state.Company && (
                  <>
                    <Form.Item
                      name="managerName"
                      label={this.context.translate("_MANAGER_NAME_")}
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>

                    {/* <Form.Item
                        name="managerPhone"
                        label={this.context.translate("_MANAGER_PHONE_")}
                        rules={[{required:true}]}
                      >
                        <Input
                          onChange={(e) =>
                            this.validatePhoneNumber(
                              e.target.value,
                              "managerPhone"
                            )
                          }
                          maxLength={13}
                        />
                      </Form.Item> */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Form.Item
                        style={{ width: "30%" }}
                        name="managerPhoneCountryCode"
                        // label="Contact 1"
                        label={" "}
                        // rules={[{ required: true }]}
                        rules={[{ required: true }]}
                        initialValue={+966}
                      >
                        <Select>
                          <Select.Option value={91}>+91</Select.Option>
                          <Select.Option value={966}>+966</Select.Option>

                          <Select.Option value={971}>+971</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        style={{ width: "65%" }}
                        name="managerPhone"
                        // label="Phone Number 1"
                        label={this.context.translate("_MANAGER_PHONE_")}
                        rules={[
                          {
                            pattern: /^\d{8,10}$/,
                            required: true,
                            message: this.context.translate(
                              "_Enter_7_or_10_Digit_Number_"
                            ),
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            this.validatePhoneNumber(
                              e.target.value,
                              "managerPhone"
                            )
                          }
                          // maxLength={13}
                        />
                      </Form.Item>
                    </div>

                    {/* <Form.Item
                      name="managerMobile"
                      label={this.context.translate("_MANAGER_MOBILE_")}
                      rules={[{ required: true }]}
                    >
                      <Input
                        maxLength={10}
                        onChange={(e) =>
                          this.validatePhoneNumber(
                            e.target.value,
                            "managerMobile"
                          )
                        }
                      />
                    </Form.Item> */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Form.Item
                        style={{ width: "30%" }}
                        name="managerMobileCountryCode"
                        // label="Contact 1"
                        label={" "}
                        // rules={[{ required: true }]}
                        rules={[{ required: true }]}
                        initialValue={+966}
                      >
                        <Select>
                          <Select.Option value={91}>+91</Select.Option>
                          <Select.Option value={966}>+966</Select.Option>

                          <Select.Option value={971}>+971</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        style={{ width: "65%" }}
                        name="managerMobile"
                        // label="Phone Number 1"
                        label={this.context.translate("_MANAGER_MOBILE_")}
                        rules={[
                          {
                            pattern: /^\d{8,10}$/,
                            required: true,
                            message: this.context.translate(
                              "_Enter_7_or_10_Digit_Number_"
                            ),
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            this.validatePhoneNumber(
                              e.target.value,
                              "managerMobile"
                            )
                          }
                          // maxLength={13}
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
              </Col>
            </Row>
            {/* <Row justify="end"> */}
            <div className="formBtnGroup">
              <Button
                type="primary"
                htmlType="submit"
                className="formSaveButton"
                // onClick={(val) => console.log(val)}
              >
                {this.context.translate("_REGISTER_")}
              </Button>
            </div>
            {/* </Row> */}
          </Form>
        </Modal>
        <Modal
          width={350}
          className="alert-model"
          title={
            <div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              {/* Alert */}
              {this.context.translate("_FLEET_ALERT_")}
            </div>
          }
          open={this.state.showAlertModal}
          onOk={() => this.setState({ showAlertModal: false })}
          onCancel={() => this.setState({ showAlertModal: false })}
          okText="OK"
          cancelText="Cancel"
          footer={[
            <Button
              type="primary"
              onClick={() => this.setState({ showAlertModal: false })}
            >
              {this.context.translate("_FLEET_DASHBORD_VEHICLE_COMMAND_OKBTN_")}
            </Button>,
          ]}
        >
          <p>{this.waslCompanyStore?.WASLCompanyStatus}</p>
        </Modal>
      </>
    );
  }
}
WASLCompanyList.contextType = StoreContext;
export default observer(withRouter(WASLCompanyList));
