import React from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Space,
  message,
  Modal,
  Form,
  Select,
  DatePicker,
  Checkbox,
  Dropdown,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import config from "../config";
import {
  DeleteFilled,
  DownloadOutlined,
  ExportOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import withRouter from "../withRouter";
import WASLTripStore from "../store/WASLTripStore";
import WASLVehicleStore from "../store/WASLVehicleStore";
const dateFormat = "YYYY-MM-DD";
const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";

let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 250;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 150;
    } else if (val.title?.length > 8) {
      val.width = 125;
    } else {
      val.width = 110;
    }
    // console.log(val.title.length)
  });

  return arr;
};

class WASLTrip extends React.Component {
  constructor(props,context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.onDelete = this.onDelete.bind(this);

    this.waslTripStore = new WASLTripStore(this);
    this.waslVehicleStore = new WASLVehicleStore(this);

    this.state = {
      showWASLRegistration: false,
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      loadList: 0,
      Company: true,
      Individual: false,
      cname: "",
      identityNo: "",
      crNo: "",
      loadData: 0,
      companyId: "",
      tripNumber: 1,

      UcompanyId: "",
      cid: "",

      modalVisible:false,
      selectedColumns :  widthAdderFunc([
        {
          // title: "Account",
          title:context.translate("_FLEET_DASHBORD_NEWPOI_CUSTOMER_"),
          dataIndex: "name",
          key: "name",
        },
        {
          // title: "Vehicle No",
          title:context.translate("_VEHICLE_NO_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",
        },
        {
          // title: "Sequence Number",
          title:context.translate("_SEQUENCE_NUMBER_"),
          dataIndex: "sequenceNumber",
          key: "sequenceNumber",
        },
        {
          // title: "Primary Driver",
          title:context.translate("_PRIMARY_DRIVER_"),
          dataIndex: "primaryDriver",
          key: "primaryDriver",
        },
        {
          // title: "Assistant Driver",
          title:context.translate("_ASSISTANT_DRIVER_"),
          dataIndex: "assistantDriver",
          key: "assistantDriver",
        },
        {
          // title: "Trip Number",
          title:context.translate("_TRIP_NUMBER_"),
          dataIndex: "tripNumber",
          key: "tripNumber",
        },
        {
          // title: "Distance In Meters",
          title:context.translate("_DISTANCE_IN_METERS_"),
          dataIndex: "distanceInMeters",
          key: "distanceInMeters",
        },
        {
          // title:"No Of Passengers",
          title:context.translate("_NO_OF_PASSENGERS_"),
          dataIndex: "noOfPassenger",
          key: "noOfPassenger",
        },
        {
          // title: "Trip Type",
          title:context.translate("_WASL_TRIP_TYPE_"),
          dataIndex: "tripType",
          key: "tripType",
        },
        {
          // title: "Activity",
          title:context.translate("_ACTIVITY_"),
          dataIndex: "activity",
          key: "activity",
        },
        {
          // title: "Response",
          title:context.translate("_RESPONSE_"),
          dataIndex: "response",
          key: "response",
        },
        {
          // title: "Reply",
          title:context.translate("_REPLY_"),
          dataIndex: "reply",
          key: "reply",
        },
        {
          // title:"Status",
          title:context.translate("_ORDER_STATUS_"),
          dataIndex: "status",
          key: "status",
        },
  
        {
          // title:"Action",
          title:context.translate("_ACTION_"),
          dataIndex: "Action",
          // fixed: "right",
          width: "5%",
          // render: (_, record) => {
          //   return (
          //     <>
          //       <Space>
          //         <SearchOutlined
          //           onClick={async() => {
          //            await this.WASLCompanyStatus(record);
          //            await this.warning();
          //           }}
          //         />
  
          //         <DeleteConfirm
          //           onConfirm={(e) => {
          //             this.onDelete(record);
          //           }}
          //         >
          //           <Link
          //             onClick={(e) => {
          //               e.preventDefault();
          //             }}
          //           >
          //             <DeleteFilled style={{ color: config.deleteColor }} />
          //           </Link>
          //         </DeleteConfirm>
          //       </Space>
          //     </>
          //   );
          // },
        },
      ])
  
     
    };
    this.formRef = React.createRef();

  

    this.newColumns =  widthAdderFunc([
      ...this.state.selectedColumns,
      {
        // title: "Primary Driver Identity No.",
        title:context.translate("_PRIMARY_DRIVER_IDENTITY_"),
        dataIndex: "primaryDriver",
        key: "primaryDriverNo",
      },
      {
        // title: "Assistant Driver Identity No.",
        title:context.translate("_ASSISTANT_DRIVER_IDENTITY_"),
        dataIndex: "primaryIdentityNumber",
        key: "primaryIdentityNumber",
      },
      {
        // title: "Expected Arrival Time",
        title:context.translate("_WASL_TRIP_ARRIVAL_TIME"),
        dataIndex: "arrivalTime",
        key: "arrivalTime",
      },
      {
        // title: "Expected Departure Time",
        title:context.translate("_WASL_TRIP_DEPARTURE_TIME"),
        dataIndex: "departureTime",
        key: "departureTime",
      },
      {
        // title: "Departure Station Code",
        title:context.translate("_WASL_TRIP_DEPARTURE_STATION_CODE_"),
        dataIndex: "depStationCode",
        key: "depStationCode",
      },
      {
        // title: "Arrival Station Code",
        title:context.translate("_WASL_TRIP_ARRIVAL_STATION_CODE_"),
        dataIndex: "arStationCode",
        key: "arStationCode",
      },
    ])
    // this.ExportMenuProps = {
    //   items: [
    //     {
    //       label: <Button>Excel</Button>,
    //       key: "excel",
    //     },
    //   ],
    //   onClick: (val) => {
    //     const result = [];
    //     this.columns.map((obj) => result.push(obj.dataIndex));
    //     //  console.log(result.toString())
    //     var getData = {
    //       cid: this.state.cid,
    //       UcompanyId: this.state.UcompanyId,
    //       colList: result,
    //     };
    //     console.log(getData);
    //     if (val.key == "excel") {
    //       this.waslCompanyStore.exportExcel(getData);
    //     }
    //   },
    // };
  }
  async componentDidMount() {
    // await this.waslTripStore.loadData({ limit: 50, offset: 0 });

    // this.selectedColumns = [
    //   {
    //     title: this.context.translate("_ACCOUNT_"),
    //     dataIndex: "name",
    //     key: "name",
    //   },
    //   {
    //     title: this.context.translate("_VEHICLE_NO_"),
    //     dataIndex: "identityNo",
    //     key: "identityNo",
    //   },
    //   {
    //     title: this.context.translate("_SEQUENCE_NUMBER_"),
    //     dataIndex: "commercialRecordNo",
    //     key: "commercialRecordNo",
    //   },
    //   {
    //     title: "Primary Driver",
    //     dataIndex: "commercialRecordIssueDate",
    //     key: "commercialRecordIssueDate",
    //   },
    //   {
    //     title: "Assistant Driver",
    //     dataIndex: "phoneNo",
    //     key: "phoneno",
    //   },
    //   {
    //     title: this.context.translate("_TRIP_NUMBER_"),
    //     dataIndex: "extensionNo",
    //     key: "extensionNumber",
    //   },
    //   {
    //     title: this.context.translate("_DISTANCE_IN_METERS_"),
    //     dataIndex: "emailAddress",
    //     key: "emailId",
    //   },
    //   {
    //     title: this.context.translate("_NO_OF_PASSENGER"),
    //     dataIndex: "managerName",
    //     key: "managerName",
    //   },
    //   {
    //     title: this.context.translate("_WASL_TRIP_TYPE_"),
    //     dataIndex: "managerPhone",
    //     key: "managerPhone",
    //   },
    //   {
    //     title: this.context.translate("_ACTIVITY_"),
    //     dataIndex: "activity",
    //     key: "activity",
    //   },
    //   {
    //     title: this.context.translate("_RESPONSE_"),
    //     dataIndex: "waslKey",
    //     key: "waslKey",
    //   },
    //   {
    //     title: this.context.translate("_REPLY_"),
    //     dataIndex: "reply",
    //     key: "reply",
    //   },
    //   {
    //     title: this.context.translate("_STATUS_"),
    //     dataIndex: "reply",
    //     key: "reply",
    //   },

    //   {
    //     title: this.context.translate("_ACTION_"),
    //     dataIndex: "Action",
    //     fixed: "right",
    //     width: "5%",
    //     // render: (_, record) => {
    //     //   return (
    //     //     <>
    //     //       <Space>
    //     //         <SearchOutlined
    //     //           onClick={async() => {
    //     //            await this.WASLCompanyStatus(record);
    //     //            await this.warning();
    //     //           }}
    //     //         />

    //     //         <DeleteConfirm
    //     //           onConfirm={(e) => {
    //     //             this.onDelete(record);
    //     //           }}
    //     //         >
    //     //           <Link
    //     //             onClick={(e) => {
    //     //               e.preventDefault();
    //     //             }}
    //     //           >
    //     //             <DeleteFilled style={{ color: config.deleteColor }} />
    //     //           </Link>
    //     //         </DeleteConfirm>
    //     //       </Space>
    //     //     </>
    //     //   );
    //     // },
    //   },
    // ]

    await this.setState({ column: this.state.selectedColumns });
    this.setState({ pageSize: 50 });

    const stationData = await this.waslTripStore.getAllStation();
    await this.setState({ stationData: stationData });

    
  }

  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });

    await this.waslVehicleStore.getVehiclesByCompanyId({
      companyId: comp.value,
    });

    await this.waslTripStore.getDriverName({
      companyId: comp.value,
    });

    // console.log(this.waslTripStore.getDrivers)

    await this.handleSearch();
    // await this.setState({loadList:1})
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.waslTripStore.loadData({
      companyId: this.state.companyId,
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
    });
    await this.setState({ loadList: 0 });
    // this.setState({ loading: false });
  }

  onWASLTripRegistrationFinish = async (value) => {
    console.log(value);

    // console.log(value.departureTime.format("YYYY-MM-DD HH:mm:ss"));
    const data = {
      ...value,
      userId: 1,

      departureTime: value.departureTime.format("YYYY-MM-DD HH:mm:ss"),
      arrivalTime: value.arrivalTime.format("YYYY-MM-DD HH:mm:ss"),

      companyId: this.state.companyId,
    };
    console.log(data);
    const response = await this.waslTripStore.registerTrip(data);
    if (response?.success === "S") {
      message.success(response.data.message);
      console.log(response);
    } else {
      message.error(response.data.message);
    }
    if (response.data.message) {
      this.setState({ showWASLRegistration: false });
      this.formRef.current?.resetFields();
    }
  };

  validateNumber = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1);
    if (value1) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  searchFieldsFun = async (e) => {
    const searchFields = e.target.value;
    this.setState({ searchField: searchFields });
    if (searchFields.length > 3 || searchFields.length === 0) {
      await this.waslCompanyStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        searchField: searchFields,
      });
      await this.setState({ loadList: 0 });
    }
  };

  setPrimaryIdentityNumber = async (driverId) => {
    console.log("driverId", driverId);
    const data = await this.waslTripStore.getDriverInfo(driverId);
    console.log(data, "dta");

    await this.formRef.current.setFieldsValue({
      primaryIdentityNumber: data.licensenumber,
    });
  };

  setAssistantIdentityNumber = async (driverId) => {
    console.log("driverId", driverId);
    const data = await this.waslTripStore.getDriverInfo(driverId);
    console.log(data, "dta");

    await this.formRef.current.setFieldsValue({
      assistantIdentityNumber: data.licensenumber,
    });
  };

  setVehicleInfo = async (vehicleId) => {
    console.log(vehicleId);
    const data = await this.waslVehicleStore.getVehicleInfo(vehicleId);
    console.log(data.sequenceNo, "dta");

    await this.formRef.current.setFieldsValue({ imeiNumber: data.full_imei });
    await this.formRef.current.setFieldsValue({ number: data.vehicleNo });
    await this.formRef.current.setFieldsValue({
      sequenceNumber: data.sequenceNo,
    });
    await this.formRef.current.setFieldsValue({ plateType: data.plateType });
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_WASL_TRIP_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                <Link to={"/vehiclelist"}>
                  <Button ghost type="primary">
                    {this.context.translate("_BACK_")}
                  </Button>
                </Link>
                <Button
                  type="primary"
                  onClick={() => this.setState({ showWASLRegistration: true })}
                >
                  + {this.context.translate("_REGISTER_")}
                </Button>

                <Button
                  style={{ backgroundColor: config.activeColor }}
                  disabled={
                    this.waslCompanyStore?.WASLCompanyArray.length > 0
                      ? false
                      : true
                  }
                  onClick={async (val) => {
                    const result = [];
                    await this.columns.map((obj) => result.push(obj.dataIndex));
                    //  console.log(result.toString())
                    var getData = {
                      cid: this.state.cid,
                      UcompanyId: this.state.UcompanyId,
                      colList: result,
                    };
                    await this.waslCompanyStore.exportExcel(getData);
                    await this.setState({ loadList: 1 });
                  }}
                >
              <i class="bi bi-file-arrow-down" style={{marginRight:"5px"}}></i>
                  {this.context.translate("_EXPORT_")}
                </Button>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow" >
            <Space>
              <CompanyAutocomplete
                onChange={(value) => {
                  this.handleChange(value);
                }}
                style={{ width: 200 }}
              />
              <Input
                style={{ width: 300 }}
                // placeholder="Plate No,Sequence Number"
                placeholder={this.context.translate("_PLATE_NO_SEQUENCENO_")}
                // onChange={async (e) => {
                //   this.searchFieldsFun(e);
                //   // this.handleSearch();
                // }}
              ></Input>

              <Button type="primary" onClick={() => this.handleSearch()}>
                <SearchOutlined />
              </Button>
            </Space>
          </Row>

          <ListTable
            current={this.state.currentPage}
            total={this.waslTripStore?.total}
            columns={this.state.selectedColumns}
            dataSource={this.waslTripStore?.WASLTripArray}
            loading={this.waslTripStore?.loading}
            onChange={this.handleTableChange}
          />

          <Modal
            title={this.context.translate("_WASL_TRIP_REGISTRATION_")}
            // title="WASL Trip Registration"
            destroyOnClose={true}
            open={this.state.showWASLRegistration}
            onCancel={() => this.setState({ showWASLRegistration: false })}
            footer={null}
            width={800}
            style={{
              top: 20,
            }}
            // onOk={}
          >
            <Form
              ref={this.formRef}
              layout="vertical"
              name="companyFrm"
              onFinish={this.onWASLTripRegistrationFinish}
              // initialValues={{ mapSetting: false, mapSetting2: false }}
              style={{ maxWidth: "100%", padding: 0 }}
              className="formClass"
            >
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="companyId"
                    label={this.context.translate("_ACCOUNT_")}
                    rules={[{ required: true }]}
                  >
                    <CompanyAutocomplete
                      onChange={(value) => {
                        this.handleChange(value);
                      }}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="vehicleId"
                    // label="Asset Number"
                    label={this.context.translate("_FLEET_DASHBORD_VEHICLE_HISTORY_VEHICLE_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      name="vehicleId"
                      onChange={async (e) => await this.setVehicleInfo(e)}
                      fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                      // placeholder="Asset Name"
                      placeholder={this.context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_")}
                      optionFilterProp="children"
                      options={this.waslVehicleStore?.getVehiclesUsingCompanyId}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="sequenceNumber"
                    label={this.context.translate("_SEQUENCE_NUMBER_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      onChange={(e) =>
                        this.validateNumber(e.target.value, "sequenceNumber")
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name="primaryDriver"
                    // label="Primary Driver"
                    label={this.context.translate("_PRIMARY_DRIVER_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      name="driverId"
                      onChange={async (e) =>
                        await this.setPrimaryIdentityNumber(e)
                      }
                      fieldNames={{ label: "name", value: "driverId" }}
                      optionFilterProp="children"
                      options={this.waslTripStore?.getDrivers}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="primaryIdentityNumber"
                    label={this.context.translate("_IDENTITY_NUMBER_")}
                    // rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="assistantDriver"
                    // label="Assistant Driver"
                    label={this.context.translate("_ASSISTANT_DRIVER_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      name="driverId"
                      onChange={async (e) =>
                        await this.setAssistantIdentityNumber(e)
                      }
                      fieldNames={{ label: "name", value: "driverId" }}
                      optionFilterProp="children"
                      options={this.waslTripStore?.getDrivers}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="assistantIdentityNumber"
                    label={this.context.translate("_IDENTITY_NUMBER_")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="tripNumber"
                    label={this.context.translate("_TRIP_NUMBER_")}
                    initialValue={1}
                  >
                    <Input
                      disabled
                      // onChange={(e) => {
                      //   let value = e.target.value.replace(/\D/g, " ");
                      //   this.setState({ tripNumber: value });
                      // }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="departureTime"
                    label={this.context.translate("_WASL_TRIP_DEPARTURE_TIME")}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      showTime={{ format: "HH:mm:ss" }}
                      format={"YYYY-MM-DD HH:mm:ss"}
                      style={{ width: "100%", fontsize: "12px" }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="arrivalTime"
                    label={this.context.translate("_WASL_TRIP_ARRIVAL_TIME")}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      showTime={{ format: "HH:mm:ss" }}
                      format={dateTimeFormat}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="distanceInMeters"
                    label={this.context.translate("_DISTANCE_IN_METERS_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="noOfPassenger"
                    label={this.context.translate("_NO_OF_PASSENGER_")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      maxLength={2}
                      onChange={(e) => {
                        this.validateNumber(e.target.value, "noOfPassenger");
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="tripType"
                    // label="Trip Type"
                    label={this.context.translate("_WASL_TRIP_TYPE_")}
                    rules={[{ required: true }]}
                  >
                    <Select style={{ width: "100%" }} 
                    // placeholder="Select Type"
                    placeholder={this.context.translate("_SELECT_TYPE_")}
                    >
                      {/* <Select.Option value="1">INSIDE CITY</Select.Option>
                      <Select.Option value="2">BETWEEN CITIES</Select.Option> */}
                      <Select.Option value="1">{this.context.translate("_INSIDE_CITY_")}</Select.Option>
                      <Select.Option value="2">{this.context.translate("_BETWN_CITIES_")}</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="activity"
                    label={this.context.translate("_ACTIVITY_")}
                    // initialValue={"DEFAULT"}
                    rules={[{ required: true }]}
                  >
                    <Select autoClearSearchValue 
                    // placeholder="Select Activity"
                    placeholder={this.context.translate("_SELECT_ACTIVITY_")}
                    >
                      <Select.Option value="EDUCATIONAL_TRANSPORT">
                        {/* EDUCATIONAL TRANSPORT */}
                        {this.context.translate("_EDUCATIONAL_TRANSPORT_")}
                      </Select.Option>
                      <Select.Option value="BUS_RENTAL">
                        {/* BUS RENTAL */}
                        {this.context.translate("_BUS_RENTAL_")}
                      </Select.Option>
                      <Select.Option value="SPECIALITY_TRANSPORT">
                        {/* SPECIALITY TRANSPORT */}
                        {this.context.translate("_SPECIALITY_TRANSPORT_")}
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="depStationCode"
                    // label="Departure Station Code"
                    label={this.context.translate("_WASL_TRIP_DEPARTURE_STATION_CODE_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      name="depStationCode"
                      // onChange={async () => await this.handleChange()}
                      fieldNames={{ label: "stationCode", value: "id" }}
                      // placeholder="Select Station Code"
                      placeholder={this.context.translate("_SELECT_STATION_CODE_")}
                      optionFilterProp="children"
                      options={this.waslTripStore?.getStationList}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="arStationCode"
                    // label="Arrival Station Code"
                    label={this.context.translate("_WASL_TRIP_ARRIVAL_STATION_CODE_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      name="arStationCode"
                      // onChange={async () => await this.handleChange()}
                      fieldNames={{ label: "stationCode", value: "id" }}
                      // placeholder="Select Station Code"
                      placeholder={this.context.translate("_SELECT_STATION_CODE_")}
                      optionFilterProp="children"
                      options={this.waslTripStore?.getStationList}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div className="formBtnGroup">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="formSaveButton"
                  // onClick={(val) => console.log(val)}
                >
                  {this.context.translate("_REGISTER_")}
                </Button>
              </div>
            </Form>
          </Modal>

          <Modal
          open={this.state.modalVisible}
          width={300}
          // title="Select Columns"
          title={this.context.translate("_SELECT_COLUMNS_")}
          onCancel={() => this.setState({ modalVisible: false })}
          onOk={() => {
            this.setState({ modalVisible: false });
          }}
          footer={null}
        >
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            {this.newColumns.map((column, v) => {
              return (
                <Row key={v}>
                  <Col>
                    <Checkbox
                      onChange={() => this.handleCheckboxChange(column)}
                      checked={this.state.selectedColumns.some(
                        (c) => c.key === column.key
                      )}
                    >
                      {column.title}
                    </Checkbox>
                  </Col>
                </Row>
              );
            })}
          </div>
        </Modal>
        </div>
      </>
    );
  }
}
WASLTrip.contextType = StoreContext;
export default observer(withRouter(WASLTrip));
