import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import { decode as base64_decode } from "base-64";
const ProtectedRoute = ({ path, children }) => {
  let cookies = Cookies.get("data");
  let data = JSON.parse(base64_decode(cookies));
  if (!(data.rolePermission.includes(path) || data.rolePermission === "All")) {
    return <Navigate to={"/lounchingDashboard"} replace />;
  }
  return children ? children : <Outlet />;
};
export default ProtectedRoute;
