import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi,baseURL} from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class WASLDriverStore {
  WASLDriverArray = [];
  WASLDriverName =[];
  CompanyIdentityNumbers =[];
  WASLDriverStatus=[];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      WASLDriverArray: observable,
      WASLDriverName:observable,
      WASLDriverStatus:observable,
      CompanyIdentityNumbers : observable,
      total: observable,
      loading: observable,
      loadData: action,
      registerDriver:action,
      toGetDriverName:action,
      getDriverInfo:action,
      deleteRecord:action,
      getWASLDriverStatus:action
    });
    this.rootStore = rootStore;
  }

  async loadData({companyId,searchField, offset, limit,UcompanyId,pageNo}) {
    this.loading = true;
    // console.log(companyId,searchField, offset, limit)
    var postData= {"companyId":companyId,"searchField": searchField,"offset": offset, "limit": limit,"page":pageNo,UcompanyId:UcompanyId}
    var base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=wasl&a=driverList", "postData=" + base64Input);

    console.log("company Data", response?.data?.data?.wasldriver);
    this.WASLDriverArray = response?.data?.data?.wasldriver;
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async registerDriver(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=wasl&a=registerDriver", "postData=" + base64Input);
    console.log(response);

//    this.total = parseInt(response?.data?.data?.TotalCount);
    return response;
  }

  async getCompanyIdentityNo(companyId) {
    var postData = {"companyId":companyId}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=company&a=getIdentityNo", "postData=" + base64Input);
   
      console.log(response.data.data);

    // this.CompanyIdentityNumbers = parseInt(response?.data?.data);
    this.CompanyIdentityNumbers = response?.data?.data;
    return response;
  }

  // To get driver name
  async toGetDriverName(companyId) {
    // console.log(companyId,"dwee")
    var postData ={"tokenId":"","companyId":companyId,"isadmin":-1,"deviceGrpOffSet":0,"deviceGrpSize":10}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=getdata", "postData=" + base64Input);
    console.log(response,"response");
    this.WASLDriverName = response?.data?.data?.company[0]?.drivers;
    console.log(this.WASLDriverName)
    // this.total = parseInt(response?.data?.data?.TotalCount);
    return response;
  }


  async getDriverInfo(driverId) {
    console.log("driverId",driverId);
    var postData = {"driverId":driverId};
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=wasl&a=getDriverInfo", "postData=" + base64Input);
    console.log(response);

    this.total = parseInt(response?.data?.data?.TotalCount);
    return response.data.data;
  }
  async deleteRecord({Id}){
    console.log(Id)

    var postData = {"Id":Id,"waslDriverKey":null,"userId":1}
    console.log(postData);
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=wasl&a=deleteDriver", "postData="+base64Input);
    return response;
  }

  async getWASLDriverStatus({Id}){
    console.log(Id)
    
    var postData = {"Id":Id,"waslKey":null}
    console.log(postData);
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=wasl&a=getwasldriverstatus", "postData="+base64Input);
    this.WASLDriverStatus = response.data.errors[0][0]
    // return response;
  }

  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=wasl&a=exportwasldriverexcel&cid=" +
      getData.cid +
      "&UcompanyId" +
      getData.UcompanyId +
      "&colList=" +
      getData.colList +
      "&searchField=" +
      getData.searchField +
      "&language=" +
      lang;
  }
}

export default WASLDriverStore;
