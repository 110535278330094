import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
  observe,
} from "mobx";
import config from "../config";
import { act } from "react-dom/test-utils";

class ParentStore {
  parents = [];

  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      parents: observable,
      total: observable,
      loading: observable,
      loadData: action,
      deleteRecord: action,
      addData: action,
      getParent: action,
    });
    this.rootStore = rootStore;
  }
  async getCompany({ companyName }) {
    var postData = { name: companyName };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=searchCompany", "postData=" + base64Input);
    console.log(response);
  }
  async loadData({ offset, limit, searchField, companyId ,classId}) {
    this.loading = true;

    var postData = {
      tokenId: "80bovn8u800tg1aq9bvr73kbhh",
      companyId: companyId,
      offset: 0,
      limit: 50,
      searchField:searchField,
      classId:classId
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=parent&a=parentsdetails", "postData=" + base64Input);

    console.log("schoold Data", response?.data?.data);
    this.parents = response?.data?.data?.passdetails;
    //  this.setCompanies(response?.data?.data?.companydata);
    this.total = parseInt(response?.data?.data?.TotalCount);
    // console.log(this.total);
    this.loading = false;
  }
  async deleteRecord({ parentId }) {
    var postData = { parentId: parentId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=parent&a=deleteParents", "postData=" + base64Input);
    return response;
  }
  async addData(parentData) {
    console.log(parentData)
    var postData = { AddParents: parentData };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=parent&a=update", "postData=" + base64Input);
    console.log(response);
    return response?.data;
  }

  async getParent(parentId) {
    console.log("parent Id", parentId);
    var postData = { parentId: parentId, offset: 0, limit: 1 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=parent&a=parentsdetails", "postData=" + base64Input);
    console.log(response?.data?.data?.passdetails[0], "");
    return response?.data?.data?.passdetails[0];
  }

  async exportExcel(getData) {
    console.log(getData)
    window.location.href =
      config.baseURL +
      "index.php?c=parent&a=parentsExportExcel&cid=" +
      getData.cid +
      "&colList=" +
      getData.colList +
      "&classId=" +
      getData.classId +
      "&searchField=" +
      getData.searchField;
  }
  async exportPdf(getData) {
    console.log(getData)
    window.location.href =
      config.baseURL +
      "index.php?c=parent&a=parentsExportPdf&cid=" +
      getData.cid +
      "&colList=" +
      getData.colList  +
      "&classId=" +
      getData.classId+
      "&searchField=" +
      getData.searchField;
  }
}

export default ParentStore;
