import React from "react";
import BlackListPersonStore from "../store/BlackListPersonStore";
import {
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Select,
  Table,
  Button,
  Typography,
  Space,
  Tag,
  Avatar,
  Row,
  Col,
  Dropdown,
  Tooltip,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import {
  DeleteFilled,
  DownloadOutlined,
  EditFilled,
  PlusOutlined,
  UserOutlined,
  DownOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import AddButton from "./AddButton";
import DeleteConfirm from "./DeleteConfirm";
import FuelFilledStore from "../store/FuelFilledStore";

import config from "../config";
import CompanyAutocomplete from "./CompanyAutocomplete";
import CompanyStore from "../store/CompanyStore";
import FuelFilledAdd from "./FuelFilledAdd";
const { Search } = Input;

class FuelFilledList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.companyStore = new CompanyStore(this);
    this.onSearch = this.onSearch.bind(this);

    this.fuelFilledStore = new FuelFilledStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      companyId: "",
      company_value_label: "",
      openFuelFilledForm: false,
      fuel_Id: "",
      listDataUpdate: false,
    };

    this.menuProps = {
      items: [
        {
          label: "WASL",
          key: "1",
          icon: <PlusOutlined />,
        },
        {
          label: "WASL Station",
          key: "2",
          icon: <PlusOutlined />,
        },
      ],
      onClick: () => {},
    };
  }
  onEdit = async (record) => {
    console.log(record.fuel_Id, "dd");
    await this.context.viewStateStore.setFuelFilledStates({
      companyId: record.companyId,
    });
    await this.setState({ fuel_Id: record.fuel_Id });
    await this.setState({ openFuelFilledForm: true });
    console.log(record.fuel_Id);
    // await this.props.navigate("/fuelfilledadd/" + record.fuel_Id);
  };
  async onDelete(record) {
    const response = await this.fuelFilledStore.deleteRecord({
      fuel_Id: record.fuel_Id,
    });
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  async componentDidMount() {
    if(Number(this.props.getUserData().isadmin)!==-1 ){
       console.log(this.props.getUserData().userCompanyId)
       await this.setState({companyId:this.props.getUserData().userCompanyId})
      //  console.log(this.state.companyId)
       await this.setState({userId:this.props.getUserData().userId})
       await this.handleSearch();
  }
    console.log(this.props.params.companyId);
    const companyId = this.props.params.id;
    // const beforeEditCompanyId =
    //   this.context.viewStateStore.fuelFilledObject.companyId;
    // if (beforeEditCompanyId !== "") {
    //   await this.fuelFilledStore.loadData({
    //     offset: (this.state.currentPage - 1) * this.state.pageSize,
    //     limit: this.state.pageSize,
    //     companyId: beforeEditCompanyId,
    //   });
    //   const singleData = await this.companyStore.getCompanyDataById(
    //     this.context.viewStateStore.fuelFilledObject.companyId
    //   );
    //   await this.setState({ listUpdated: 1 }); //need to reload data
    //   await this.setState({ company_value_label: singleData });
    // }

    // const companyId = this.props.params.companyId
    // if(companyId){
    //     await this.fuelFilledStore.loadData({
    //         offset: ((this.state.currentPage - 1) * this.state.pageSize),
    //         limit: this.state.pageSize,
    //         // searchField: this.state.searchField,
    //         companyId: companyId,
    //     });
    // }
    // await this.companyStore.loadData({ offset: 0, limit: 50 });
    this.setState({ pageSize: 50 });

    this.columns = [
      {
        // title: "Vehicle No",
        title: this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        width: "20%",
      },
      {
        // title: "Driver Name",
        title: this.context.translate(
          "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
        ),
        dataIndex: "driverName",
        width: "20%",
        render: (value, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        // title: "Date Time ",
        title: this.context.translate("_DATE_TIME_"),
        dataIndex: "dateTime",
        width: "20%",
      },
      {
        // title: "Fuel Filled ",
        title: this.context.translate("_FUEL_FILLED_"),
        dataIndex: "fuelFilled",
        width: "20%",
      },
      {
        // title: "Odometer",
        title: this.context.translate("_FLEET_DASHBORD_SETTING_ODOMETER_"),
        dataIndex: "odometer",
        width: "15%",
      },
      {
        // title: "Action",
        title: this.context.translate("_ACTION_"),
        dataIndex: "groupname",
        width: "5%",
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    // title="Edit Fuel Filled"
                    title={this.context.translate("_EDIT_FUEL_FILLED_")}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    this.onDelete(record);
                  }}
                >
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Tooltip
                      // title="Delete Fuel Filled"
                      title={this.context.translate("_DELETE_FUEL_FILLED_")}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ company_value_label: singleData });
    await this.handleSearch();
  }

  async onSearch(value) {
    console.log(value);
    await this.setState({ emailId: value });
    this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.fuelFilledStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });
    await this.setState({ listUpdated: 1 }); //need to reload data
  }
  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.success == "S") {
      message.success(resp.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openFuelFilledForm: false });

      //  await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    } else {
      message.error(resp.message);
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    // if (
    //   this.state.openFuelFilledForm &&
    //   prevState.openFuelFilledForm !== this.state.openFuelFilledForm
    // ) {
    //   console.log("ilocasd");
    //   setTimeout(() => {
    //     window.dispatchEvent(new Event("resize"));
    //   }, 10);
    // }

    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const beforeEditCompanyId =
        this.context.viewStateStore.fuelFilledObject.companyId;
      console.log(this.context.viewStateStore.fuelDetailsObject);

      await this.fuelFilledStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: beforeEditCompanyId,
      });
      const singleData = await this.companyStore.getCompanyDataById(
        this.context.viewStateStore.fuelFilledObject.companyId
      );
      await this.setState({ listUpdated: 1 }); //need to reload data
      console.log(singleData);
      await this.setState({ company_value_label: singleData });

      await this.setState({ listDataUpdate: false });
    }
  }

  closeModal=()=>{
    this.setState({ openFuelFilledForm: false, fuel_Id: "" });
  }

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {/* {this.context.translate("_FLEET_USER_USERLIST_TITLE_")} */}
                {/* Fuel Filled List */}
                {this.context.translate("_FUEL_FILLED_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  color={config.primaryColor}
                  placement="left"
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      this.setState({ openFuelFilledForm: true, fuel_Id: "" });
                      console.log("Sammek");
                    }}
                  ></Button>
                </Tooltip>

                <Button
                  disabled={
                    this.fuelFilledStore?.fuelFilled?.length > 0 ? false : true
                  }
                  style={{ backgroundColor: config.activeColor }}
                  onClick={(val) => {
                    console.log(val);
                    const result = [];
                    this.columns.map((obj) => result.push(obj.dataIndex));
                    //  console.log(result.toString())
                    var getData = {
                      cid: this.state.companyId,
                      colList: result,
                    };
                    console.log(getData);
                    if (val) {
                      this.fuelFilledStore.exportExcel(getData);
                    }
                  }}
                >
                  {/* Export */}
                  {this.context.translate("_EXPORT_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          {/* <div className='heading'>Fuel Filled List </div> */}
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={(value) => this.handleChange(value)}
                  value={
                    this.state.company_value_label
                      ? this.state.company_value_label
                      : null
                  }
                  // allowClear={true}
                  style={{ width: 200 }}
                />
              </Space>
            </Col>
          </Row>

          <ListTable
            current={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.fuelFilledStore?.total}
            columns={this.columns}
            dataSource={this.fuelFilledStore?.fuelFilled}
            loading={this.fuelFilledStore?.loading}
            onChange={this.handleTableChange}
          />

          {this.state.openFuelFilledForm && (
            <Modal
              bodyStyle={{ height: "637px" }}
              className="formShowModal" //  {/* css class */}
              // title="Fuel Filled Details"
              title={this.context.translate("_FUEL_FILLED_DETAILS_")}
              centered
              visible={this.state.openFuelFilledForm}
              footer={null}
              onCancel={() => {
                this.setState({ openFuelFilledForm: false, fuel_Id: "" });
              }}
              width={665}
            >
              <div>
                <FuelFilledAdd
                  fuel_Id={this.state.fuel_Id}
                  response={this.responseMsg}
                  closeModal={this.closeModal}
                />
              </div>
            </Modal>
          )}
        </div>
      </>
    );
  }
}
FuelFilledList.contextType = StoreContext;
export default observer(withRouter(FuelFilledList));
