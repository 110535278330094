import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import config from "../config";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
  observe,
} from 'mobx';
import { act } from 'react-dom/test-utils';
import axios from 'axios';

class AssignTripStore {
  assignTrip=[]
  vehicles=[]
  geoDataArray=[]
  tripDataArray=[]
  total=0
  loading=false
  rootStore
  idlePoints=[]
  addr=""

  constructor(rootStore) {
    makeObservable(this, {
      idlePoints:observable,
        assignTrip: observable,
        geoDataArray:observable,
        vehicles:observable,
        tripDataArray:observable,
      total:observable,
      loading:observable,
      addr:observable,


      loadData:action,
      getAddressUsingLatLng:action,
      getAssignTrip:action,
      getVehicles:action,
      getGeoData:action,
      getStopeIdle:action,
      deleteTrip:action


     
    });
     this.rootStore = rootStore;
    
  }
  async getCompany({companyName}){

    var postData = {"name":companyName}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=company&a=searchCompany", "postData="+base64Input);
    console.log(response);

  }
  // async loadData({offset,limit,searchField,companyId}) {
  //     this.loading = true;
  //   var postData = {"companyId":companyId}
  //   const base64Input = await base64_encode(JSON.stringify(postData));
  //   var response = await postApi.getClient().post("index.php?c=calender&a=getcalender", "postData="+base64Input);
    
  //    console.log("Assign Trip",response?.data?.data)
  //   this.assignTrip = response?.data?.data
  //   //  this.setCompanies(response?.data?.data?.companydata);
  //    this.total = parseInt(response?.data?.data?.TotalCount);
  //   // console.log(this.total);
  //   this.loading=false;
  // }

  async loadData({companyId}){
    var postData = {"companyId":companyId}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=trip&a=getTripdata", "postData="+base64Input);
    console.log(response)
    this.tripDataArray= response.data.data
  }


async createTrip({obj,routePoints}){
  var postData = {"tokenId":"","AddRoute":obj, routePoints:routePoints}
  const base64Input = await base64_encode(JSON.stringify(postData));
  var response = await postApi.getClient().post("index.php?c=trip&a=create", "postData="+base64Input);
  console.log(response)
  return response
}
  async getVehicles(companyId){
    var postData = {"companyId":companyId}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=getVehicles", "postData="+base64Input);
    this.vehicles = response.data.data
  }
async getGeoData(companyId,geoZoneId, vehicleId){
  var postData = {"companyId":companyId,geoZoneId:geoZoneId,vehicleId:vehicleId}
  const base64Input = await base64_encode(JSON.stringify(postData));
  var response = await postApi.getClient().post("index.php?c=geofence&a=geoData", "postData="+base64Input);
  this.geoDataArray = response.data.data.geofence;
}

async getStopeIdle(object){
  var postData = object
  const base64Input = await base64_encode(JSON.stringify(postData));
  var response = await postApi.getClient().post("index.php?c=trip&a=getstopidle", "postData="+base64Input);
  this.idlePoints = response.data?.data.StopIdleData
  console.log(response.data?.data)
  return response
}

async deleteTrip({stId}){
  var postData = {stId:stId}
  console.log(postData);
  const base64Input = await base64_encode(JSON.stringify(postData));
  var response = await postApi.getClient().post("index.php?c=trip&a=delete", "postData="+base64Input);
  return response;
}
 
  async getAssignTrip(schoolCalenderId){
    console.log("school Calender Id ", schoolCalenderId)
  }

  async getAssignTripRecord(stId){
    var postData = {stId:stId}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=trip&a=getTripdata", "postData="+base64Input);
    console.log(response)
    return response.data?.data[0]
    // this.tripDataArray= response.data.data
  }

async getAddressUsingLatLng(lat,lng){
  let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${config.googleMapKey}`;
     const response = await axios(url);
     let address = await response.data.results[0].formatted_address;

     this.addr = address
     return address
}

  

  
  

}

export default AssignTripStore;
