import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";

import CompanyAutocomplete from "./CompanyAutocomplete";

import config from "../config";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import PointofInterest from "../store/PointofInterestStore";

import Autocomplete from "react-google-autocomplete";
import DisplayLocationMap from "./DisplayLocationMap";
import CompanyStore from "../store/CompanyStore";

class PointofInterestAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loadingSpin: false,
      companyId: "",
      id: -1,
      search: false,
      addressMap: false,
      photo: "",
      folderName: "",
    };

    this.poiStore = new PointofInterest(this);
    this.companyStore = new CompanyStore(this);
  }
  async componentDidMount() {
    await this.poiStore.poiTypeData();
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      this.context.viewStateStore.setpoiStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
    }

    const Id = this.props.id;

    if (Id) {
      await this.filldata(Id);
    }

    this.setState({ update: 1 });
  }

  async filldata(Id) {
    const getpoiData = await this.poiStore.getPOIData({
      Id,
      companyId: this.props.companyId,
    });

    await this.setState({
      companyId: getpoiData.companyId,
      id: getpoiData.id,
    });
    const singleData = await this.companyStore.getCompanyDataById(
      getpoiData.companyId
    );

    this.formRef.current.setFieldsValue({
      companyId: singleData,
      id: getpoiData.id,
      description: getpoiData.description,
      Id: getpoiData.Id,
      poiType: getpoiData.poiType,
      showType: getpoiData.showType,
      status: getpoiData.status,
      url: getpoiData.url,
      sequence: getpoiData.sequence,
      poiname: getpoiData.poiname,
      playInterval: getpoiData.playInterval,
      latitude: getpoiData.latitude,
      longitude: getpoiData.longitude,
    });
    this.setState({ folderName: getpoiData.path });
  }

  onPOIDetailSubmit = async (fieldsValue) => {
    const data = {
      ...fieldsValue,
      id: this.state.id,
      companyId: this.state.companyId,
      folderName: this.state.folderName,
      photo: this.state.photo,
    };
    console.log(data);
    this.setState({ loading: true });
    const response = await this.poiStore.addData(data);
    await this.props.response(response);

    this.setState({ loading: false });
  };
  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    if (!this.props.id) {
      this.context.viewStateStore.setpoiStates(comp.value);
    }
  }

  closeModal = () => {
    this.props.closeModal();
  };

  getUploadData = async (fileUp) => {
    // if (fileUp.fileList[0]?.type == "image/png") {
    let fileinfo = fileUp.file.response;

    if (fileinfo?.success == "S") {
      await this.setState({ photo: fileinfo.data });
      this.setState({ folderName: fileinfo.folderName });
    }
    fileUp.fileList[0].url = config.fleetURL + fileinfo?.folderName;
    this.poiStore.setPhotoFromDb(fileUp.fileList);
    this.setState({ update: 1 });
    // }
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              ref={this.formRef}
              layout="vertical"
              name="nest-messages"
              onFinish={this.onPOIDetailSubmit}
              style={{ maxWidth: "100%", padding: 0 }}
              className="formClass"
            >
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate(
                        "_FLEET_DRIVER_DRIVERDETAIL_COMPANY_"
                      )}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => this.handleChange(value)}
                        style={{ width: "100%" }}
                        value={this.state.companyId}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="showType"
                    label={this.context.translate("_IMAGE_VIDEO_TYPE_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      onSelect={(val) => this.setState({ showType: val })}
                      options={[
                        {
                          value: "1",
                          label: this.context.translate("_FULL_BANNER_"),
                        },
                        {
                          value: "2",
                          label: this.context.translate("_MIDDLE_BANNER_"),
                        },
                        {
                          value: "3",
                          label: this.context.translate("_BOTTOM_BANNER_"),
                        },
                      ]}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    name="poiname"
                    label={this.context.translate("_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="description"
                    label={this.context.translate("_DESCRIPTION_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="playInterval"
                    label={this.context.translate("_INTERVAL_SEC_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="sequence"
                    label={this.context.translate("_SEQUENCE_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="url"
                    label={this.context.translate("URL")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="status"
                    label={this.context.translate("_STATUS_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={[
                        {
                          value: -1,
                          label: this.context.translate(
                            "_FLEET_USER_USERDETAIL_STATUSDDL_SELECT_"
                          ),
                        },
                        {
                          value: 1,
                          label: this.context.translate("_ACTIVE_"),
                        },
                        {
                          value: 0,
                          label: this.context.translate(
                            "_FLEET_USER_USERLIST_STATUS_INACTIVE_"
                          ),
                        },
                      ]}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    name="poiType"
                    label={this.context.translate("_POINT_OF_INTEREST_TYPE_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={this.poiStore.poiTypeArr}
                      fieldNames={{
                        value: "Id",
                        label: "poiName",
                      }}
                    ></Select>
                  </Form.Item>

                  <Form.Item name="select" label=" ">
                    <Checkbox
                      checked={this.state.search}
                      onChange={(e) => {
                        this.setState({ search: e.target.checked });
                      }}
                    >
                      {this.context.translate("_SELECT_FROM_MAP")}
                    </Checkbox>
                  </Form.Item>

                  {this.state.search && (
                    <Form.Item
                      name="searchAddress"
                      label={this.context.translate("_SEARCH_ADDRESS_")}
                    >
                      <Autocomplete
                        style={{
                          width: "100%",
                          height: "48px",
                          boxShadow: "none",
                          padding: "4px 11px",
                          borderWidth: 1,
                          borderStyle: "solid",
                          borderColor: "#d9d9d9",
                          borderRadius: "6px",
                        }}
                        // apiKey={"AIzaSyAu2h2n_gORgPafUoADJBdEuyjN-zlr8VM"}
                        apiKey={config.googleMapKey}
                        onPlaceSelected={async (place) => {
                          await this.formRef.current.setFieldsValue({
                            searchAddress: place.formatted_address,
                            latitude: place.geometry.location.lat().toFixed(3),
                            longitude: place.geometry.location.lng().toFixed(3),
                          });
                          await this.setState({ addressMap: true });
                          await this.setState({
                            addressInfo: [
                              place.geometry.location.lat(),
                              place.geometry.location.lng(),
                            ],
                          });
                        }}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="latitude"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_SETTING_LATITUDE_"
                    )}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="longitude"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_SETTING_LONGITUDE_"
                    )}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="photo"
                    label="Upload"
                    className="formCheckboxCss"
                    getValueFromEvent={this.normFile}
                    style={{ marginTop: "16px" }}
                    rules={[
                      {
                        required: !this.state.folderName,
                        message: "Please upload a photo or video",
                      },
                    ]}
                  >
                    <Upload
                      name="photo"
                      action={
                        config.baseURL + "/index.php?c=company&a=fileupload"
                      }
                      listType="picture"
                      data={{
                        fileType: "advertisement",
                        fieldName: "photo",
                        showType: this.state.showType,
                      }}
                      onChange={this.getUploadData}
                      // onRemove={()=>this.setState({photo:"",fileList:[]})}
                      onRemove={async () => {
                        await this.setState({ photo: "", fileList: [] });
                        await this.poiStore.setPhotoFromDb([]);
                      }}
                      maxCount={1}
                      accept=".jpg,.png,.mp4,.avi"
                      // accept=".jpg,.png"
                      // Add the beforeUpload prop to perform file extension validation

                      {...this.poiStore.photoFromDb}
                    >
                      <Button className="imgupload" icon={<UploadOutlined />}>
                        {this.context.translate("_PLEASE_SELECT_IMAGE_VIDEO_")}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>

              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal();
                  }}
                >
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="formSaveButton"
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>

        {this.state.addressMap && (
          <Modal
            style={{ padding: "0px !important" }}
            className="multiTracking-popup"
            title=<div>
              Location On Map
              <CloseOutlined
                onClick={() => this.setState({ addressMap: false })}
              />
            </div>
            maskClosable={false}
            centered
            bodyStyle={{ height: "60vh", padding: "0px" }} // Adjust the dimensions as needed
            width={1000}
            open={this.state.addressMap}
            onCancel={() => this.setState({ addressMap: false })}
            footer={null}
          >
            <DisplayLocationMap
              addressInfo={
                this.state.addressInfo.length > 0 && this.state.addressInfo
              }
              formRef={this.formRef}
              address={(address) =>
                this.formRef.current.setFieldsValue({
                  searchAddress: address,
                  landcordinate: address,
                })
              }
            />
          </Modal>
        )}
      </div>
    );
  }
}
PointofInterestAdd.contextType = StoreContext;
export default observer(withRouter(PointofInterestAdd));
