import React from "react";
import {
  Row,
  Col,
  Input,
  Tag,
  Avatar,
  Button,
  Space,
  message,
  Upload,
  Form,
  Select,
  Modal,
  Tooltip,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import {
  PushpinFilled,
  DeleteFilled,
  SearchOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";

import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import withRouter from "../withRouter";
import PassengerStore from "../store/PassengerStore";
import CompanyStore from "../store/CompanyStore";
import VehicleStore from "../store/VehicleStore";
import PassengerAdd from "./PassengerAdd";

import conf from "../config";
const { Option } = Select;
class PassengerList extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.vehicleStore = new VehicleStore(this);

    this.passengerStore = new PassengerStore();

    // console.log(this.context);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      loadList: 0,
      showUpload: false,
      companyVal_Lab: "",
      openModal: false,
      passengerId: "",
      passengerId: [],
      oldvehicleId: "",
      showDeleteModal: false,
      openPassengerForm: false,
      passengerIdEdit: "",
      // newPassengerId:""
      schollPassenger: false,
      listDataUpdate: false,
      deletetooltip: true,
      showAlertModal: false,
      companyFieldHighlight: false,
      reAssignVehicle: "",
      companyId: "",
    };
    this.companyStore = new CompanyStore(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const passengerStates = this.context.viewStateStore.passengerStatesObj;

      await this.passengerStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        searchField: passengerStates.searchText,
        companyId: passengerStates.companyId,
      });
      const singleData = await this.companyStore.getCompanyDataById(
        passengerStates.companyId
      );

      await this.setState({ loadList: 0 });

      await this.setState({
        companyVal_Lab: singleData,
        searchField: passengerStates.searchText,
      });
      await this.setState({ listDataUpdate: false });
      // await this.passengerStore.loadData({ offset: 0, limit: 50 });
      // this.setState({pageSize:50,clientHeight:window.innerHeight});
      //this.handleSearch();
    }
  }

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });

      await this.setState({ userId: this.props.getUserData().userId });
      await this.vehicleStore.getVehiclesCompanyId(
        this.props.getUserData().userCompanyId
      );
      await this.handleSearch();
    }

    this.setState({ pageSize: 50 });
    this.columns = [
      {
        title: this.context.translate("_PASSENGER_TYPE_"),
        width: 80,
        dataIndex: "type",
        key: "type",
        // fixed: "left",
        render: (value) => {
          if (value === "Student") {
            return <Tag color={config.typeColor1}>{value}</Tag>;
          }
          if (value === "Employee") {
            return <Tag color={config.typeColor2}>{value}</Tag>;
          }
        },
      },
      {
        title: this.context.translate("_NAME_"),
        width: 80,
        dataIndex: "studentName",
        key: "studentName",
        // fixed: "left",
        render: (value, row) => {
          if (row.employeeName !== null) {
            return (
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  this.onEdit(row);
                }}
              >
                {row.employeeName}
              </Link>
            );
          } else {
            return (
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  this.onEdit(row);
                }}
              >
                {value}
              </Link>
            );
          }
        },
      },

      {
        // title: "Class / Department",
        title: this.context.translate("_CLASS_DEPARTMENT_"),
        dataIndex: "className",
        key: "className",
        width: 100,
        render: (val, row) => {
          if (row.employeeDepartment !== null) {
            return row.employeeDepartment;
          } else {
            return val;
          }
        },
      },
      {
        title: this.context.translate("_TAG_ID_"),
        dataIndex: "tagId",
        key: "tagId",
        width: 60,
      },
      {
        title: this.context.translate("_MOBILE_NUMBER_"),
        dataIndex: "mobileNo",
        key: "mobileNo",
        width: 70,
      },
      {
        title: this.context.translate("_BUSNO_"),
        dataIndex: "vehicleNo",
        key: "vehicleNo",
        width: 70,
      },
      {
        title: this.context.translate("_LOCATION_"),
        dataIndex: "pickUpPoint",
        key: "location",
        width: 130,
        render: (value) => {
          if (value) {
            return (
              <span>
                <Avatar
                  style={{ backgroundColor: config.locationColor, margin: 2 }}
                  size={15}
                  icon={<PushpinFilled />}
                />
                {value}
              </span>
            );
          }
        },
      },
      {
        title: this.context.translate("_PICKUP_TIME_"),
        dataIndex: "pickUpTime",
        key: "6",
        width: 70,
      },
      {
        title: this.context.translate("_DROP_OFF_TIME_"),
        dataIndex: "dropOffTime",
        key: "dropOffTime",
        width: 70,
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        // fixed: "right",
        width: 40,
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    // title="Edit Passenger Details"
                    title={this.context.translate("_EDIT_PASSENGER_DETAILS_")}
                    color={config.tooltipColorEdit}
                    placement="bottom"
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                    this.setState({ deletetooltip: true });
                  }}
                  onCancel={() => {
                    this.setState({ deletetooltip: true });
                  }}
                >
                  <Link>
                    <Tooltip
                      placement="bottom"
                      // title="Delete Passenger"
                      title={this.context.translate("_DELETE_PASSENGER_")}
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>

                    {/* {this.state.deletetooltip ? (
                      <Tooltip title="Delete Passenger" color={config.tooltipColorDelete}>
                        <DeleteFilled
                          style={{ color: config.deleteColor }}
                          onClick={() => this.setState({ deletetooltip: false })}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Delete Passenger" color={config.tooltipColorDelete}>
                      <DeleteFilled style={{ color: config.deleteColor }}
                        />
                        </Tooltip>
                    )} */}
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }
  onEdit = async (record) => {
    const passengerId = record.passengerId;
    if (passengerId) {
      await this.context.viewStateStore.setPassengerStates({
        companyId: record.companyId,
        searchText: this.state.searchField,
      });
      await this.setState({ passengerIdEdit: passengerId });
      await this.setState({ openPassengerForm: true });

      // this.props.navigate(
      //   this.props.params.id
      //     ? "/passengeradd/" + passengerId + "?schollPassenger=1"
      //     : "/passengeradd/" + passengerId
      // );
    }
  };
  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      loadList: 1,
      currentPage: 1,
      pageSize: 50,
      passengerId: [],
    });

    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ companyVal_Lab: singleData, companyId: comp.value });

    await this.handleSearch();
    this.setState({ loadList: 1 });
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.passengerStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }
  async onDelete(record) {
    this.setState({ deletetooltip: true });
    const response = await this.passengerStore.deleteRecord({
      passengerId: record.passengerId,
    });
    // console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }
  searchInput = async (e) => {
    const value = e.target.value;
    if (value.length > 3) {
      await this.passengerStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        searchField: value,
        // companyId: this.state.companyVal_Lab[0].value,
        companyId: this.state.companyId,
      });
      await this.setState({ loadList: 0 });
    } else if (value.length === 0) {
      await this.passengerStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        // searchField: value,
        // companyId: this.state.companyVal_Lab[0].value,
        companyId: this.state.companyId,
      });
      await this.setState({ loadList: 0, currentPage: 1 });
    }
  };

  async searchInputButton() {
    if (!this.state.companyId) {
      this.setState({ companyFieldHighlight: true });
    } else {
      this.setState({ companyFieldHighlight: false });
    }
    if (this.state.companyId) {
      this.setState({ companyFieldHighlight: false, loading: true });
      await this.passengerStore.loadData({
        offset: 0,
        limit: 50,
        searchField: this.state.searchField,
        searchFieldDate: this.state.searchFieldDate,
        companyId: this.state.companyId,
      });
      await this.setState({ loadData: 1 });
    }
  }

  getUploadData = async (fileUp) => {
    let fileinfo = fileUp.file.response;

    if (fileinfo?.success == "S") {
      // console.log(fileinfo.data)

      await this.setState({ excelFile: fileinfo.data });
    }

    this.setState({ update: 1 });
  };

  onExcelSubmit = async (fieldsValue) => {
    const data = {
      ...fieldsValue,
      companyId: this.state.companyId,
      excelFile: this.state.excelFile,
    };

    const resp = await this.passengerStore.addStudentByExcel(data);

    if (resp?.success == "S") {
      message.success(resp.message);
      this.setState({ excelFile: "" });
    } else {
      message.error(resp.errors[0].error);
    }
    if (resp.message) {
      this.setState({ showUpload: false });
      this.formRef.current?.resetFields();
    }
  };

  async companySearch(companyId) {
    const data = await this.vehicleStore.getVehiclesCompanyId(companyId.value);

    await this.setState({ loadList: 1 });
  }

  // onReassignVehicle = async (value) => {
  //   console.log(this.state.passengerId.join(","));

  //   const assignVehicle = this.state.passengerId.forEach(async (value1) => {
  //     const data = {
  //       passengerId: value1,
  //       newvehicleId: value.vehicleId,
  //       oldvehicleId: this.state.oldvehicleId,
  //     };
  //     console.log(data);
  //     const resp = await this.passengerStore.reAssignVehicle(data);
  //     console.log(resp);
  //     if (resp?.success === "S") {
  //       console.log(resp.message);
  //       message.success(resp.message);
  //       console.log(resp.message);
  //       this.setState({ openModal: false });
  //       this.formRef.current?.resetFields();
  //     } else {
  //       message.error(resp.message);
  //       this.setState({ openModal: false });
  //       this.formRef.current?.resetFields();
  //     }
  //   });
  //   this.handleSearch();
  // };

  onReassignVehicle = async (value) => {
    const assignVehicle = this.state.reAssignVehicle.forEach(async (value1) => {
      const data = {
        passengerId: value1.passengerId,
        newvehicleId: value.vehicleId,
        oldvehicleId: value1.oldvehicleId,
      };

      const resp = await this.passengerStore.reAssignVehicle(data);

      if (resp?.success === "S") {
        message.success(resp.message);

        this.setState({ openModal: false });
        this.formRef.current?.resetFields();
      } else {
        message.error(resp.message);
      }
    });
    await this.handleSearch();
  };

  deleteMultiplePassenger = async (value) => {
    const companyIdArray = this.state.reAssignVehicle.map(
      (val) => val.companyId
    );
    const companyIdData = companyIdArray.join(",");

    let flag = 0;

    for (const id of value) {
      const data = {
        passengerId: id,
      };

      const response = await this.passengerStore.deleteRecord(data);
      // this.setState({deleteResponse:response})
      if (response?.data?.success === "S") {
        if (flag === 0) {
          message.success(response.data.message);
        }
        await this.passengerStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          searchField: this.state.searchField,
          companyId: companyIdData,
        });

        await this.setState({ loadList: 1 });
        flag++;
      } else {
        message.error(response.data.message);
      }
    }
  };

  responseMsg = async (response) => {
    if (response?.success === "S") {
      message.success(response.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openPassengerForm: false });
      // await this.props.navigate(this.queryId?"/passengerList2/1":"/passengerList");
    } else {
      message.error(response.errors[0].tagId);
    }
  };

  validateFile = (file) => {
    this.setState({ fileList: file });

    const maxSize = conf.UploadExcelSize;
    if (file.size > maxSize) {
      message.error("File size must be less than or equal to 1MB");
      return false;
    }
    return true;
  };

  closeModal = () => {
    this.setState({ openPassengerForm: false, passengerIdEdit: "" });
  };

  render() {
    const companyInputBox = this.state.companyFieldHighlight
      ? { border: "2px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_PASSENGER_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  title={this.context.translate("_ADD_")}
                  link={
                    this.props.params.id
                      ? "/passengeradd?schollPassenger=1"
                      : "/passengeradd"
                  }
                /> */}
                <Tooltip
                  placement="left"
                  // title="Add"
                  title={this.context.translate(
                    "_FLEET_DRIVER_DRIVERLIST_ADDBTN_"
                  )}
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({
                        openPassengerForm: true,
                        passengerIdEdit: "",
                      })
                    }
                  ></Button>
                </Tooltip>
                {this.props.params.id && (
                  <>
                    <div
                      style={{ display: this.props.params.id ? "" : "none" }}
                    >
                      <Button
                        type={config.uploadBoxType}
                        onClick={() => {
                          this.setState({ showUpload: true });
                        }}
                      >
                        <i
                          class="bi bi-upload"
                          style={{ marginRight: "5px" }}
                        ></i>
                        {/* <UploadOutlined />  */}
                        {/* Upload */}
                        {this.context.translate("_UPLOAD_")}
                      </Button>
                    </div>
                    <div
                      style={{ display: this.props.params.id ? "" : "none" }}
                    >
                      <Button type="primary">
                        <i
                          class="bi bi-speedometer2"
                          style={{ marginRight: "5px" }}
                        ></i>
                        {/* <DashboardOutlined /> */}
                        {/* DASHBOARD */}
                        {this.context.translate("_DASH_BOARD_")}
                      </Button>
                    </div>
                    <div
                      style={{ display: this.props.params.id ? "" : "none" }}
                    >
                      <Tooltip
                        // title="Reassign Vehicle"
                        title={this.context.translate("_REASSIGN_VEHICLE_")}
                        color={config.tooltipColorEdit}
                      >
                        <Button
                          type="primary"
                          onClick={async (e) => {
                            this.state.passengerId.length > 0
                              ? await this.setState({ openModal: true })
                              : await this.setState({ showAlertModal: true });
                            await this.vehicleStore.getVehiclesCompanyId(
                              this.state.companyId
                            );
                            this.setState({ loadList: 1 });
                          }}
                        >
                          <i
                            class="bi bi-car-front"
                            style={{ marginRight: "5px" }}
                          ></i>
                          {/* <CarOutlined /> */}
                        </Button>
                      </Tooltip>
                    </div>
                  </>
                )}
                <Tooltip
                  //  title="Delete"
                  title={this.context.translate(
                    "_FLEET_DASHBORD_RULETRIGGER_DELETE_"
                  )}
                  color={config.tooltipColorDelete}
                >
                  <Button
                    type="primary"
                    onClick={async (e) => {
                      this.state.passengerId.length > 0
                        ? await this.setState({ showDeleteModal: true })
                        : await this.setState({ showAlertModal: true });
                    }}
                  >
                    <i class="bi bi-trash3" style={{ marginRight: "5px" }}></i>
                    {/* <DeleteOutlined /> */}
                  </Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  value={
                    this.state.companyVal_Lab ? this.state.companyVal_Lab : null
                  }
                  style={companyInputBox}
                  // allowClear={true}
                  // style={{ width: 200 }}
                />
                <Input
                  // placeholder="Search by Name,Mobile No,Tag Id,Bus No"
                  placeholder={this.context.translate(
                    "_SEARCH_BY_NAME_MOBILE_TAGID_BUSNO_"
                  )}
                  value={this.state.searchField ? this.state.searchField : ""}
                  style={{ width: 500 }}
                  onKeyUp={(e) => this.searchInput(e)}
                  onChange={(e) => {
                    this.setState({ searchField: e.target.value });
                  }}
                />
                <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  onClick={() => {
                    this.searchInputButton();
                  }}
                />
              </Space>
            </Col>
          </Row>

          <ListTable
            rowSelection={{
              fixed: true,
              //  columnWidth: 2,
              selectedRowKeys: this.state.passengerId,
              onChange: async (selectedRowKeys, selectedRows) => {
                // await this.setState({})
                console.log(selectedRowKeys, selectedRows);
                await this.setState({ passengerId: selectedRowKeys });
                // await this.setState({
                //   oldvehicleId: selectedRows[0]?.vehicleId,
                // });
                await this.setState({ reAssignVehicle: selectedRows });
              },
            }}
            rowKey="passengerId"
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.passengerStore?.total}
            columns={this.columns}
            dataSource={this.passengerStore?.passengers}
            loading={this.passengerStore?.loading}
            onChange={this.handleTableChange}
          />

          {this.state.showUpload && (
            <>
              <Modal
                open={this.state.showUpload}
                maskClosable={false}
                // title="File Upload"
                placeholder={this.context.translate("_FILE_UPLOAD_")}
                onOk={() => this.setState({ showUpload: false })}
                onCancel={() => this.setState({ showUpload: false })}
                footer={false}
                width={400}
                // style={{width:100}}
              >
                <Form
                  name="excelimport"
                  ref={this.formRef}
                  layout="vertical"
                  onFinish={this.onExcelSubmit}
                  style={{ maxWidth: "100%" }}
                >
                  {this.props.getUserData().isadmin === -1 && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        // allowClear={true}
                        onChange={(companyId) => {
                          this.setState({ companyId: companyId.value });
                          this.companySearch(companyId);
                          this.vehicleStore.getVehiclesCompanyId(
                            companyId.value
                          );
                        }}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    // label="Select Vehicle"
                    label={this.context.translate("_SELECT_VEHICLE_")}
                    name="vehicleId"
                    rules={[
                      {
                        required: true,
                        message: this.context.translate("_SELECT_VEHICLE_"),
                      },
                    ]}
                  >
                    <Select
                      // placeholder="Select Asset"
                      showSearch
                      // fieldNames={{ value: "vehicleId", label: "vehicleNo" }}
                      optionFilterProp="children"
                      // options={this.vehicleStore?.getVehiclesUsingCompanyId}
                      filterOption={(inputValue, option) => {
                        if (option.children) {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) >= 0
                          );
                        }
                      }}
                    >
                      {this.vehicleStore?.getVehiclesUsingCompanyId?.map(
                        (val) => {
                          return (
                            <Option value={val.vehicleId}>
                              {val.vehicleNo}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="excelFile"
                    // label="Excel File"
                    label={this.context.translate("_EXCEL_FILE_")}
                    className="uploadFile"
                    rules={[{ required: true }]}
                  >
                    <Upload
                      // status= 'error'
                      showProgress="true"
                      name="excelFile"
                      action={
                        config.baseURL + "/index.php?c=company&a=fileupload"
                      }
                      listType="text"
                      data={{
                        fileType: "passengerimport",
                        fieldName: "excelFile",
                      }}
                      onChange={this.getUploadData}
                      beforeUpload={(file) => this.validateFile(file)}
                      maxCount={1}
                    >
                      <Button icon={<UploadOutlined />}>
                        {/* Click To Upload Excel File */}
                        {this.context.translate("_CLICKTOUPLOAD_EXCELFILE_")}
                      </Button>
                    </Upload>
                  </Form.Item>

                  {/* 
              <Form.Item
                name="excelFile"
                // label="Excel File"
                label={this.context.translate("_EXCEL_FILE_")}
                rules={[
                  { required: true, message: "Please select excel file" },
                ]}
               
                className="label-seprator"
              >

                <Upload
                  name="excelFile"
                  action={config.baseURL + "/index.php?c=company&a=fileupload"}
                  // listType="text"
                  listType="picture"
                  data={{ fileType: "passengerimport", fieldName: "excelFile" }}
                  onChange={this.getUploadData}
                  beforeUpload={(file) => this.validateFile(file)}
                  maxCount={1}
                >
                
                  <Button icon={<UploadOutlined />}>{this.context.translate("_CLICKTOUPLOAD_EXCELFILE_")}</Button>
                </Upload>

              </Form.Item> */}

                  <div className="formBtnGroup">
                    <div
                      style={{ display: this.props.params.id ? "" : "none" }}
                    >
                      <Button
                        className="formCancelButton"
                        // style={{ backgroundColor: config.activeColor }}
                        onClick={() => this.passengerStore?.sampleExcelFormat()}
                      >
                        {/* <i
                          class="bi bi-download"
                          style={{ marginRight: "5px" }}
                        ></i> */}
                        {this.context.translate("_SAMPLE_")}
                        {/* {this.context.translate("_SAMPLE_EXCEL_")} */}
                      </Button>
                    </div>
                    <Button
                      type="primary"
                      className="formSaveButton"
                      htmlType="submit"
                      // loading={this.state.loading}
                    >
                      {/* css class */}
                      {/* Upload */}
                      {this.context.translate("_UPLOAD_")}
                    </Button>
                  </div>

                  {/* <Row justify={"end"}>
                <Space style={{margin:"10px"}}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={this.passengerStore.excelLoadig}
                  >
                    Upload
                  </Button>
                </Space>
              </Row> */}
                </Form>
              </Modal>
            </>
          )}
          <Modal
            open={this.state.passengerId.length !== 0 && this.state.openModal}
            // open={this.state.openModal}
            // title="Reassign Vehicle"
            title={this.context.translate("_REASSIGN_VEHICLE_")}
            onOk={() => this.setState({ openModal: false })}
            onCancel={() => this.setState({ openModal: false })}
            footer={false}
          >
            {/* {console.log(this.state.selectedVehicle.length !==0 ,this.state.renewVehicle,"sfgdfg")} */}
            <Form
              name="renewVehicle"
              layout="vertical"
              ref={this.formRef}
              onFinish={this.onReassignVehicle}
              style={{ maxWidth: "100%" }}
            >
              <Form.Item
                // label="Select Vehicle"
                label={this.context.translate("_SELECT_VEHICLE_")}
                name="vehicleId"
                rules={[
                  {
                    required: true,
                    message: this.context.translate("_SELECT_VEHICLE_"),
                  },
                ]}
              >
                <Select
                  // placeholder="Select Asset"
                  placeholder={this.context.translate(
                    "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                  )}
                  showSearch
                  // fieldNames={{ value: "vehicleId", label: "vehicleNo" }}
                  optionFilterProp="children"
                  // options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Row justify={"end"}>
                <Space style={{ margin: "10px" }}>
                  <Button type="primary" htmlType="submit">
                    {/* Submit */}
                    {this.context.translate("_SUBMIT_")}
                  </Button>
                </Space>
              </Row>
            </Form>
          </Modal>

          {/* <Modal
            title="Remove Passenger?"
            open={this.state.showDeleteModal}
            width={400}
            onCancel={() => this.setState({ showDeleteModal: false })}
            footer={[
              <div style={{padding:"10px"}}>
              <Button
                // key="yes"
                onClick={(e) => {
                  this.deleteMultiplePassenger(this.state.passengerId);

                  console.log(this.state.passengerId);
                  this.setState({ showDeleteModal: false });
                }}
              >
                Yes
              </Button>
              <Button
                // key="no"
                onClick={() => this.setState({ showDeleteModal: false })}
              >
                No
              </Button>
              </div>
            ]}
          >
            <p style={{margin:"0px 20px"}}>Are you sure you want to delete passenger?</p>
          </Modal> */}

          <Modal
            width={350}
            className="alert-model"
            title={
              <div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-trash3"
                ></i>{" "}
                {/* Remove Passenger? */}
                {this.context.translate("_REMOVE_PASSENGER_")}
              </div>
            }
            open={this.state.showDeleteModal}
            onCancel={() => this.setState({ showDeleteModal: false })}
            footer={[
              <Button
                // key="no"
                onClick={() => this.setState({ showDeleteModal: false })}
              >
                {/* No */}
                {this.context.translate("_FLEET_RULELIST_CRONRULE_NO_")}
              </Button>,
              <Button
                type="primary"
                onClick={(e) => {
                  this.deleteMultiplePassenger(this.state.passengerId);

                  this.setState({ showDeleteModal: false, passengerId: "" });
                }}
              >
                {/* Yes */}
                {this.context.translate("_FLEET_RULEDETAIL_ACTIVEDDL_YES_")}
              </Button>,
            ]}
          >
            <p style={{ margin: "0px 20px" }}>
              {this.context.translate("_PASSENGER_DELETE_POPUP_")}
              {/* Are you sure you want to delete passenger? */}
            </p>
          </Modal>
        </div>

        {this.state.openPassengerForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Add Passenger"
            title={this.context.translate("_ADD_PASSENGER_")}
            centered
            visible={this.state.openPassengerForm}
            footer={null}
            onCancel={() => {
              this.setState({ openPassengerForm: false, passengerIdEdit: "" });
            }}
            width={665}
          >
            <div>
              <PassengerAdd
                passengerIdEdit={this.state.passengerIdEdit}
                response={this.responseMsg}
                schollPassenger={this.state.schollPassenger}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}

        <Modal
          width={350}
          className="alert-model"
          title={
            <div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              {/* Alert */}
              {this.context.translate("_FLEET_ALERT_")}
            </div>
          }
          open={this.state.showAlertModal}
          onOk={() => this.setState({ showAlertModal: false })}
          onCancel={() => this.setState({ showAlertModal: false })}
          okText="OK"
          cancelText="Cancel"
          footer={[
            <Button
              type="primary"
              onClick={() => this.setState({ showAlertModal: false })}
            >
              {/* Ok */}
              {this.context.translate("_FLEET_DASHBORD_VEHICLE_COMMAND_OKBTN_")}
            </Button>,
          ]}
        >
          {/* <p>Please Select Vehicles</p> */}
          {this.context.translate("_SELECT_VEHICLES_")}
        </Modal>
      </>
    );
  }
}
PassengerList.contextType = StoreContext;
export default observer(withRouter(PassengerList));
