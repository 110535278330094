import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,

  makeObservable,
  observable,

} from 'mobx';


class AssignBusToStudentStore {
  getClass=[]
  total = 0
  loading = false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
    //   notification: observable,
      total: observable,
      loading: observable,
    //   loadData: action,
      getAllClass:action,
      addData:action,
      
    });
    this.rootStore = rootStore;

  }

//   async loadData({ offset, limit,companyId,warehouseId }) {
//     this.loading = true;
//     console.log("notification",warehouseId)

//     var postData = { "companyId": companyId, "offset": offset, "limit": limit, "page": 1}
//     const base64Input = await base64_encode(JSON.stringify(postData));
//     var response = await postApi.getClient().post("index.php?c=notification&a=getNotification", "postData=" + base64Input);
//     console.log("notification ", response?.data?.data)

//     this.notification = response?.data?.data
//     // // //  this.setCompanies(response?.data?.data?.companydata);
//     //  this.total = parseInt(response?.data?.data?.TotalCount);
//     this.loading=false;
//   }

  async addData(values) {

    var postData = values
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=notification&a=update", "postData=" + base64Input);
    // console.log(response)
    return response.data
  }

  async getAllClass() {
    // console.log("vehicle list")
    var postData = null;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=notification&a=getAllClass",
        "postData=" + base64Input
      );
      let allClass = [{ classId: 0, className: "ALL" }];
      allClass = [...allClass, ...response?.data?.data];
      this.getClass = allClass;
    // console.log(response?.data?.data, "post")
    // this.getClass = response?.data?.data;
    return response?.data?.data;
  }


  



}

export default AssignBusToStudentStore;
