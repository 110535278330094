import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, DirectionsRenderer, Marker } from '@react-google-maps/api';
import conf from '../config';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const MapComponent = ({origin,destination,getDistance,showCalculatedDist}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: conf.googleMapKey,
  });
useEffect(()=>{
    console.log(origin,destination)
})
  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState(null);

//   const origin = { lat: 37.7749, lng: -122.4194 }; // San Francisco, CA
//   const destination = { lat: 34.0522, lng: -118.2437 }; // Los Angeles, CA

  const options = {
    zoomControl: false,
    streetViewControl: false,
  };

  const directionsCallback = (result, status) => {
    if (status === 'OK') {
      setDirections(result);
      const legs = result.routes[0].legs;
      const totalDistance = legs.reduce((acc, leg) => acc + leg.distance.value, 0);
      const distanceInKm = totalDistance / 1000; // Convert meters to kilometers
        console.log(distanceInKm.toFixed(2))
        getDistance({distance:distanceInKm.toFixed(2)})
    } else {
      console.error(`Error fetching directions: ${status}`);
    }
  };

  useEffect(() => {
    if(!origin && !destination){
        setDirections(null);
    }
  if(origin && destination){
    if (isLoaded && map) {
        const directionsService = new window.google.maps.DirectionsService();
  
        directionsService.route(
          {
            origin: origin,
            destination: destination,
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          directionsCallback
        );
      }
  }
  }, [isLoaded, map,origin,destination]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{lat:conf.mapCenter[0],lng:conf.mapCenter[1]}}
      zoom={7}
      options={options}
      onLoad={(map) => setMap(map)}
    >
   {origin && <Marker
        position={origin}
      
      />}
   {destination &&  <Marker
        position={destination}
    
      />} 
      { (showCalculatedDist && directions) &&  <DirectionsRenderer directions={directions} />}
    </GoogleMap>
  ) : null;
};

export default MapComponent;
