import React, { useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Popconfirm,
  Select,
  Table,
  Button,
  Typography,
  Space,
  DatePicker,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";

import VehicleTempSensorStore from "../store/VehicleTempSensorStore";
import withRouter from "../withRouter";
import { SearchOutlined } from "@ant-design/icons";

class VehicleTempSensorList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.tempSensorStore = new VehicleTempSensorStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      // offset: 0,
      // limit: 50,
      loadData: 0,
    };
  }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId)
      await this.setState({ companyId: this.props.getUserData().userCompanyId })
      await this.setState({ userId: this.props.getUserData().userId })
      await this.handleSearch();
    }

    // await this.tempSensorStore.loadData({ offset: 0, limit: 50 });

    this.setState({ pageSize: 50 });

    this.columns = [
      {
        // title: " Vehicle No",
        title:this.context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
        dataIndex: "vehicleNo",
      },
      {
        // title: "°C Sensor1 Name",
        title:this.context.translate("_SENSOR1_NAME_"),
        dataIndex: "sensor1Name",
      },
      {
        // title: " °C Sensor1 Low Limit ",
        title:this.context.translate("_SENSOR1_LOW_LIMIT_"),
        dataIndex: " sensor1LowLimit ",
      },
      {
        // title: "°C Sensor1 High Limit ",
        title:this.context.translate("_SENSOR1_HIGH_LIMIT_"),
        dataIndex: "sensor1HighLimit",
      },
      {
        // title: "°C Sensor2 Name",
        title:this.context.translate("_SENSOR2_NAME_"),
        dataIndex: "sensor2Name",
      },
      {
        // title: "°C Sensor2 Low Limit ",
        title:this.context.translate("_SENSOR2_LOW_LIMIT_"),
        dataIndex: "sensor2LowLimit ",
      },
      {
        // title: "°C Sensor2 High Limit ",
        title:this.context.translate("_SENSOR2_HIGH_LIMIT_"),
        dataIndex: "sensor2HighLimit",
      },
      {
        // title: "°C Sensor3 Name",
        title:this.context.translate("_SENSOR3_NAME_"),
        dataIndex: "sensor3Name",
      },
      {
        // title: "°C Sensor3 Low Limit ",
        title:this.context.translate("_SENSOR3_LOW_LIMIT_"),
        dataIndex: " sensor3LowLimit ",
      },
      {
        // title: "°C Sensor3 High Limit ",
        title:this.context.translate("_SENSOR3_HIGH_LIMIT_"),
        dataIndex: "sensor3HighLimit",
      },
    ];
  }
  async handleChange(comp) {
    await this.setState({ companyId: comp.value,currentPage:1,pageSize:50});
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.tempSensorStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
      // vehicleNo: this.state.vehicleNo,
    });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  searchInput = async (e) => {
    const value = e.target.value;
    // console.log(value);
    // await this.setState({searchField:value})
    if (value.length >= 3) {
      await this.tempSensorStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        searchField: value,
        companyId: this.state.companyId,
        
        // vehicleNo: this.state.vehicleNo,
      });
      await this.setState({ loadList: 1 });
    }else if(value.length === 0){
      await this.tempSensorStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        searchField: value,
        companyId: this.state.companyId,
      });
     await this.setState({currentPage:1}) 
    }
  };

  async inputSearchButton() {
    if (!this.state.companyId) {
      console.log(this.state.companyId, "sfsdgdfgdfhfg", this.state.companyFieldHighlight)
      this.setState({ companyFieldHighlight: true });
    } else {
      this.setState({ companyFieldHighlight: false });
    }
    if (this.state.companyId) {
      this.setState({ companyFieldHighlight: false, loading: true })
    await this.tempSensorStore.loadData({
      offset: 0,
      limit: 50,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
  
  await this.setState({ loadList: 1 });
}
  }

  render() {
    const companyInputBox = this.state.companyFieldHighlight
    ? { border: "2px solid red", width: "200px", borderRadius: "8px" }
    : { width: "200px" };
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_TEMP_SENSOR_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  // allowClear={true}
                  onChange={(value) => this.handleChange(value)}
                  style={companyInputBox}
                />
                <Input
                  style={{ width: "100%" }}
                  // placeholder="Search By Vehicle No"
                  placeholder={this.context.translate("_SEARCH_BY_VEHICLE_NO_")}
                  onChange={async(e) => {
                    this.setState({ searchField: e.target.value });
                  await  this.searchInput(e);
                  console.log(e.target.value)
                  }}
                />
                <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  onClick={async(e) => {
                    console.log("sdsss",e)
                  await this.inputSearchButton();
                  }}
                />
              </Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            total={this.tempSensorStore?.total}
            columns={this.columns}
            pageSize= {this.state.pageSize}
            dataSource={this.tempSensorStore?.tempSensorVehicles}
            loading={this.tempSensorStore?.loading}
            onChange={this.handleTableChange}
           
          />
        </div>
      </>
    );
  }
}
VehicleTempSensorList.contextType = StoreContext;
export default observer(withRouter(VehicleTempSensorList));
