import React, { Component } from "react";
import {
  Button,
  Checkbox,
  message,
  Col,
  DatePicker,
  Radio,
  Form,
  Input,
  Row,
  Upload,
  Space,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import BlackListPersonStore from "../store/BlackListPersonStore";
import withRouter from "../withRouter";
import moment from "moment";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { Link } from "react-router-dom";
import CompanyStore from "../store/CompanyStore";
import config from "../config";
/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const dateFormat = "YYYY-MM-DD";
const currentDate = moment().format(dateFormat);
class BlackListPersonAdd extends Component {
  formRef = React.createRef();
  constructor(prop) {
    super(prop);
    this.state = {
      loading: false,
      companyId: "",
      loadList: 0,
      offset: "",
      limit: "",
      searchField: "",
      userId: -1,
      gender: 1,
      id: -1,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.blackListPersonStore = new BlackListPersonStore(this);
    this.companyStore = new CompanyStore(this);
  }
  normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  async componentDidMount() {
    console.log(this.props.params.id, "id");
    const blackListPersonId = this.props.params.id;
    console.log("called", blackListPersonId);

    if (blackListPersonId) {
      this.fillData(blackListPersonId);
    }
  }
  async fillData(blacklistPersonId) {
    const getBlaklistPersonData =
      await this.blackListPersonStore.getBlackListPerson(blacklistPersonId);
    console.log(getBlaklistPersonData, "bla");
    await this.setState({ id: getBlaklistPersonData.id });
    await this.setState({
      companyId: getBlaklistPersonData.companyId,
      Id: getBlaklistPersonData.id,
    });
    const singleData = await this.companyStore.getCompanyDataById(
      getBlaklistPersonData.companyId
    );
    console.log(getBlaklistPersonData);
    this.formRef.current.setFieldsValue({
      name: getBlaklistPersonData.name,

      blacklistedAt:getBlaklistPersonData?.blacklistedAt ? moment(getBlaklistPersonData?.blacklistedAt):null,
      block: getBlaklistPersonData.block,
      companyId: singleData,
      companyName: getBlaklistPersonData.companyName,
      description: getBlaklistPersonData.description,
      faceId: getBlaklistPersonData.faceId,
      gendercheckbox: getBlaklistPersonData.gender,
      photo: getBlaklistPersonData.photo,
      status: getBlaklistPersonData.status,
    });
  }

  onBlacklistPersonSubmit = async (fieldsValue) => {
    this.setState({ loading: true });
    console.log(fieldsValue.block, "blok");
    const fieldsValue1 = {
      ...fieldsValue,
      block: fieldsValue.block ? 1 : 0,
      blacklistedAt:fieldsValue.blacklistedAt ? fieldsValue.blacklistedAt.format("YYYY-MM-DD"):null,
      Id: this.state.id,
      companyId: this.state.companyId,
    };
    console.log(fieldsValue1);
    const response = await this.blackListPersonStore.addData(fieldsValue1);
    console.log(response.data.data, "e");
    if (response?.success === "S") {
       message.success(response.message);
      this.props.navigate("/companyblacklistpersonlist");
    } else {
      message.error(response.message);
    }
    this.setState({ loading: false });
  };

  async handleChange(comp) {
   
   await this.setState({ companyId: comp.value });
    console.log(this.state.companyId);
    await this.formRef.current.setFieldsValue({ companyId: comp.value });
    // await this.handleSearch();
  }

  async handleSearch() {
    await this.blackListPersonsStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
     
      companyId: this.state.companyId,
    });
   
  }
//   async handleChange(comp) {
//     await this.setState({ companyId: comp.value });
//     this.formRef.current.setFieldsValue({ companyId: comp.value });
//     await this.handleSearch();
//   }

//   async handleSearch() {
//     await this.blackListPersonsStore.loadData({
//       offset: (this.state.currentPage - 1) * this.state.pageSize,
//       limit: this.state.pageSize,
//       companyId: this.state.companyId,
//     });
//     await this.setState({ loadList: 1 });
//   }

  render() {
    return (
      <div style={{ padding: 0 }}>
        <Form
          ref={this.formRef}
          name="nest-messages"
          layout="vertical"
          onFinish={this.onBlacklistPersonSubmit}
          style={{ maxWidth: "100%" }}
          validateMessages={validateMessages}
          // initialValues={{ blacklistedAt: moment(currentDate) }}
        >
          <Row
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "50px",
              background: config.headerColor,
              padding: "0 24px",
            }}
          >
            <Col span={12}>
            <div className="heading">Blacklist Person</div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                <Link to="/companyblacklistpersonlist">
                  <Button ghost type="primary">
                    Back
                  </Button>
                </Link>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  Save
                </Button>
                <Button
                  ghost
                  danger
                  type="primary"
                  htmlType=""
                  onClick={(e) => {
                    this.formRef.current?.resetFields();
                  }}
                >
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>

          <Row style={{ padding: "24px" }}>
            <Col span={10}>
              <Form.Item
                name="companyId"
                label="Account"
                rules={[{ required: true }]}
              >
                  <CompanyAutocomplete
                  onChange={(value) => this.handleChange(value)}
                  style={{ width: "100%" }}
                  companyId={this.state.companyId}
                />
              </Form.Item>

              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item
                name="gendercheckbox"
                label="Gender"
                rules={[{ required: true }]}
              >
                <Radio.Group
                  onChange={(e) => {
                    this.setState({ gender: e.target.value });
                  }}
                >
                  <Radio value={1}>Male</Radio>
                  <Radio value={2}>Female</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="photo"
                label="Upload Photo"
                valuePropName="fileList"
                getValueFromEvent={this.normFile}
              
              >
                <Upload name="photo" action="/upload.do" listType="picture">
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={3}></Col>
            <Col span={10}>
              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true }]}
              >
                <Input.TextArea/>
              </Form.Item>

              <Form.Item
                name="block"
                label="Black List"
                valuePropName="checked"
                rules={[{ type: Checkbox }]}
              >
                <Checkbox>Black List </Checkbox>
              </Form.Item>

              <Form.Item
                name="blacklistedAt"
                label="Black Listed At"
                rules={[{ type: Date }]}
               
              >
                <DatePicker format={dateFormat}  style={{width:"100%"}}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
BlackListPersonAdd.contextType = StoreContext;
export default observer(withRouter(BlackListPersonAdd));
