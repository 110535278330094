import React, { Component } from "react";

import { Form, Button, Input, Row, Col, Spin, Modal, Select,TimePicker } from "antd";
import config from "../config";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import axios from "axios";
import dayjs from "dayjs";

import RouteManagementStore from "../store/RouteManagementStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { Link } from "react-router-dom";
import CompanyStore from "../store/CompanyStore";
import Autocomplete from "react-google-autocomplete";

class RouteManagementAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      fuelId: -1,
      companyId: "",
      loadingSpin: false,
      disabledSaveButton: false,
      selectGeofence: false,
      selectGeofence2: false,
      selectedPlace: '',
      startLocationlat: null,
      stopLocationlat: null,
      addressInfo: [],
      Id:-1
    };


    this.companyStore = new CompanyStore(this);

    this.startLat = 0;
    this.startLng = 0;


    this.RouteManagementStore = new RouteManagementStore(this);
    this.handleChange = this.handleChange.bind(this);

  }

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      this.context.viewStateStore.setRouteManagementStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      console.log(this.state.companyId);

    }
    console.log(this.props.Id);
    const Id = this.props.Id;
    if (Id) {
      this.filldata(Id);
      this.setState({ Id: Id });
    }

  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  onRouteDetailSubmit = async (values) => {
    this.setState({ loading: true });
    console.log(values);

    const RouteDetails = {
      ...values,
      companyId: this.state.companyId,
      Id: this.state.Id,
      startPoint: values.startPoint,
      endPoint: values.stopPoint,
      routeName: values.routeName,
      tripCost: values.tripCost,
      tripDistance: values.tripDistance,
      tripDuration: values.tripDuration,
      startLocationlat: this.state.startLocationlat,
      startLocationlong: this.state.startLocationlong,
      stopLocationlat: this.state.stopLocationlat,
      stopLocationlong: this.state.stopLocationlong,
      startAddSelect: values.SearchByOption == 1 ? 'Search Map' : 'Geofence',
      stopAddSelect: values.SearchByOption1 == 1 ? 'Search Map' : 'Geofence',
    };
    const data = {
      tokenId: "",
      RouteManagement: RouteDetails,
    };
    console.log('DATA ', data)

    const response = await this.RouteManagementStore.addData(data);
    this.props.response(response);

    this.setState({ loading: false });
  };
  async handleChange(comp) {
    await this.setState({ companyId: comp.value });

    await this.formRef.current.setFieldsValue({ companyId: comp.value });
    if (!this.props.Id) {
      this.context.viewStateStore.setRouteManagementStates({
        companyId: comp.value,
      });
    }
    const data = await this.RouteManagementStore.getGeofenceData({
      companyId: comp.value,
    });


    await this.handleSearch();
  }

  async handleSearch() {
    await this.RouteManagementStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }
  async filldata(Id) {
    // await this.setState({ loadingSpin: true });
    console.log(this.props.companyId)
    var routedata = await this.RouteManagementStore.getRouteData(Id, this.props.companyId);
    const singleData = await this.companyStore.getCompanyDataById(
      this.props.companyId
    );
    console.log(singleData)
    this.setState({Id:routedata.Id,companyId:routedata.companyId})
    this.formRef.current.setFieldsValue({
      Id: routedata.Id,
      companyId: singleData,
      routeName: routedata.routeName,
      tripDistance: routedata.tripDistance,
      // tripDuration: routedata.tripDuration,
      tripDuration: routedata.tripDuration
        ? dayjs(routedata.tripDuration, "HH:mm:ss")
        : null,
      tripCost: routedata.tripCost,
      startPoint: routedata.startPoint,
      stopPoint: routedata.endPoint,
      SearchByOption: routedata.startAddressSelect,
      SearchByOption1: routedata.stopAddressSelect,

    });




  }
  closeModal = () => {
    this.props.closeModal();
  };
  searchByStartAdd = (value) => {
    if (value === "1") {
      this.setState({ selectGeofence: false });
    } else if (value === "2") {
      this.setState({ selectGeofence: true });
    }
  };
  searchByEndAdd = (value) => {
    if (value === "1") {
      this.setState({ selectGeofence2: false });
    } else if (value === "2") {
      this.setState({ selectGeofence2: true });
    }
  };
  SetLatLongStart = async (place) => {
    console.log(this)
    console.log(place?.geometry?.location?.lng())
    this.startLat = place?.geometry?.location?.lat()
    this.startLng = place?.geometry?.location?.lng()
  };

  SetLatLongEnd = (place) => {

    this.calculateDistance(this.startLat, this.startLng, place?.geometry?.location?.lat(), place?.geometry?.location?.lng())
      .then(({ distanceKm, travelTimeMinutes }) => {
        // console.log(`The road distance is ${distanceKm.toFixed(2)} km.`);
        // console.log(`The estimated travel time is ${travelTimeMinutes.toFixed(2)} minutes.`);
        this.formRef.current.setFieldsValue({
          tripDistance: distanceKm.toFixed(2),
          tripDuration: travelTimeMinutes.toFixed(2)
        });
      })
      .catch((error) => {
        console.error('Error calculating road distance and travel time:', error);
      });
   
  };

  getRoadDistanceAndTime = async (lat1, lon1, lat2, lon2) => {
    // Construct the OSRM API URL
    const url = `http://router.project-osrm.org/route/v1/driving/${lon1},${lat1};${lon2},${lat2}?overview=false`;


    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.code === 'Ok') {
        const distanceMeters = data.routes[0].distance;
        const distanceKm = distanceMeters / 1000;
        const travelTimeMinutes = distanceMeters / 1000 / 60
        // const durationSeconds = data.routes[0].duration;
        // const travelTimeMinutes = durationSeconds / 60;

        return {
          distanceKm: parseFloat(distanceKm.toFixed(2)), // Optional: Format to 2 decimal places
          travelTimeMinutes: parseFloat(travelTimeMinutes.toFixed(2)) // Optional: Format to 2 decimal places
        };
      } else {
        throw new Error('Failed to calculate distance and time');
      }
    } catch (error) {
      console.error('Error fetching distance and time:', error);
      throw error;
    }
  }
  calculateDistance = async (lat1, lon1, lat2, lon2) => {
    const origins = `${lat1},${lon1}`;
    const destinations = `${lat2},${lon2}`;
    // const url = `https://maps.googleapis.com/maps/api/distancematrix/json?units=metric&origins=${origins}&destinations=${destinations}&key=${config.googleMapKey}`;
    const url = `https://api.openrouteservice.org/v2/directions/driving-car?api_key=${config.googleMapKey}&start=${lat1},${lat2}&end=${lon1},${lon2}`;

    try {
      const response = await axios.get(url);
      const data = response.data;

      if (data.rows[0].elements[0].status === 'OK') {
        const distance = data.rows[0].elements[0].distance.text;
        const duration = data.rows[0].elements[0].duration.text;
        this.formRef.current.setFieldsValue({
          tripDistance: distance.toFixed(2),
          tripDuration: duration.toFixed(2)
        });

        console.log(distance);console.log(duration)
      } else {
        console.log('Could not calculate distance');
      }
    } catch (error) {
      console.log('Error fetching data from Google Maps API');
    }
  }

  setStartPoint = (value) => {

    var geoData = this.RouteManagementStore?.geofenceList?.filter((geo) =>
      geo.zoneId == value
    )
    this.formRef.current.setFieldsValue({
      startPoint: geoData[0].name,
    });
    console.log("points", geoData[0].points)
    var pa = geoData[0].points.split(",");
    // console.log(pa[0])
    this.setState({ startLocationlat: parseFloat(pa[0]), startLocationlong: parseFloat(pa[1]) });


  };
  setStopPoint = (value) => {

    var geoData = this.RouteManagementStore?.geofenceList?.filter((geo) =>
      geo.zoneId == value
    )
    this.formRef.current.setFieldsValue({
      stopPoint: geoData[0].name,
    });
    var pa = geoData[0].points.split(",");
    this.setState({ stopLocationlat: parseFloat(pa[0]), stopLocationlong: parseFloat(pa[1]) });

  };
  render() {
    const { selectedPlace, latitude, longitude } = this.state;
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              layout="vertical"
              onFinish={this.onRouteDetailSubmit}
              // {...this.layout}
              name="nest-message"
              ref={this.formRef}
              className="formClass" //change
            >

              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                      <Form.Item
                        name="companyId"
                        label={this.context.translate("_ACCOUNT_")}
                        rules={[{ required: true }]}
                      >
                        <CompanyAutocomplete
                          onChange={(value) => {
                            this.handleChange(value);
                          }}
                          companyId={this.state.companyId}
                        />
                      </Form.Item>
                    )}
                  <Form.Item
                    name="routeName"
                    label={this.context.translate("_ROUTE_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="SearchByOption"
                    label={this.context.translate("_START_ADDRESS_")}
                  >
                    <Select onSelect={this.searchByStartAdd}>
                      <Select.Option value="1">
                        {this.context.translate("_SEARCH_MAP_")}
                      </Select.Option>
                      <Select.Option value="2">
                        {this.context.translate("_GEOFENCE_")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  {this.state.selectGeofence && (
                    <>
                      <Form.Item
                        name="zoneIdStart"
                        label={this.context.translate(
                          "_FLEET_RULESELECTORDETAIL_RULE6_GEOFENCE_"
                        )}
                      >
                        <Select
                          placeholder={this.context.translate(
                            "_FLEET_RULESELECTORDETAIL_RULE6_GEOFENCE_"
                          )}
                          // placeholder="Select Geofence"
                          showSearch
                          fieldNames={{ label: "name", value: "zoneId" }}
                          optionFilterProp="children"
                          options={this.RouteManagementStore?.geofenceList}
                          onSelect={this.setStartPoint}
                        />
                      </Form.Item>
                    </>
                  )}
                  <Form.Item
                    name="startPoint"
                    label={this.context.translate("_START_POINT_")}
                  >
                    <Autocomplete

                      style={{
                        width: "100%",
                        height: "48px",
                        boxShadow: "none",
                        padding: "4px 11px",
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#d9d9d9",
                        borderRadius: "5px",
                      }}
                      placeholder={this.context.translate(
                        "_ENTER_LOCATION_"
                      )}
                      apiKey={config.googleMapKey}
                      onPlaceSelected={this.SetLatLongStart}
                    />
                  </Form.Item>
                  <Form.Item
                    name="SearchByOption1"
                    label={this.context.translate("_END_ADDRESS_")}
                  >
                    <Select onSelect={this.searchByEndAdd}>
                      <Select.Option value="1">
                        {this.context.translate("_SEARCH_MAP_")}
                      </Select.Option>
                      <Select.Option value="2">
                        {this.context.translate("_GEOFENCE_")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  {this.state.selectGeofence2 && (
                    <>
                      <Form.Item
                        name="zoneIdEnd"
                        label={this.context.translate(
                          "_FLEET_RULESELECTORDETAIL_RULE6_GEOFENCE_"
                        )}
                      >
                        <Select
                          placeholder={this.context.translate(
                            "_FLEET_RULESELECTORDETAIL_RULE6_GEOFENCE_"
                          )}
                          // placeholder="Select Geofence"
                          showSearch
                          fieldNames={{ label: "name", value: "zoneId" }}
                          optionFilterProp="children"
                          options={this.RouteManagementStore?.geofenceList}
                          onSelect={this.setStopPoint}
                        />
                      </Form.Item>
                    </>
                  )}

                </Col>
                <Col span={1} />
                <Col span={11} className="formColumnClass">
                  <Form.Item
                    name="stopPoint"
                    label={this.context.translate("_END_POINT_2_")}
                  >
                    <Autocomplete
                      style={{
                        width: "100%",
                        height: "48px",
                        boxShadow: "none",
                        padding: "4px 11px",
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#d9d9d9",
                        borderRadius: "5px",
                      }}
                      placeholder={this.context.translate(
                        "_ENTER_LOCATION_"
                      )}
                      apiKey={config.googleMapKey}
                      onPlaceSelected={
                        this.SetLatLongEnd

                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="tripDistance"
                    label={this.context.translate("_TRIP_DISTANCE_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="tripDuration"
                    label={this.context.translate("_TRIP_DURATION_")}
                    rules={[{ required: true }]}
                  >
                    <TimePicker
                      format="HH:mm:ss"
                      style={{ width: "100%" }}
                    ></TimePicker>
                  </Form.Item>
                  {/* <Form.Item
                    name="tripDuration"
                    label={this.context.translate("_TRIP_DURATION_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item> */}
                  <Form.Item
                    name="tripCost"
                    label={this.context.translate("_TRIP_COST_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal();
                    // this.formRef.current?.resetFields();
                  }}
                >
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="formSaveButton"
                  loading={this.state.loading}
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>

            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

RouteManagementAdd.contextType = StoreContext;
export default observer(withRouter(RouteManagementAdd));
