import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi ,baseURL} from '../components/Utility'
import {
  action,
  
  makeObservable,
  observable,
 
} from 'mobx';


class SensorReportStore {
  sensorReport=[]
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        sensorReport: observable,
      total:observable,
      loading:observable,
      loadData:action,
     
    });
     this.rootStore = rootStore;
    
  }

  async loadData({offset,limit,companyId,vehicleId,fromDate,toDate,pageNo}) {
    this.loading=true;
    console.log("workingHoursReport")
    var postData = {"companyId":companyId,"vehicleId":vehicleId,"fromDate":fromDate,"toDate":toDate,"offset":offset,"limit":limit,"page":pageNo}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=report&a=sensorReport", "postData="+base64Input);
    
    this.sensorReport =response?.data?.data?.sensordata
  
     this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }

  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=report&a=exportSensorReportexcel&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=report&a=exportSensorReportpdf&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=report&a=exportSensorReportcsv&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
     
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportxml(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=report&a=exportSensorReportxml&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  

}

export default SensorReportStore;
