import React, { Component } from "react";
import { Button, Col, Form, Input, Row, Spin } from "antd";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";

import FAQStore from "../store/FAQListStore";
import dayjs from "dayjs";
const { TextArea } = Input;

class FAQAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loadingSpin: false,
      Id: -1,
    };

    this.faqStore = new FAQStore(this);
  }

  async componentDidMount() {
    const Id = this.props.Id;
console.log(Id)
    if (Id) {
      await this.filldata(Id);
    }

    this.setState({ update: 1 });
  }

  async filldata(Id) {
    const getFAQData = await this.faqStore.getFaqData(Id);

    this.setState({ Id: getFAQData.Id });

    this.formRef.current.setFieldsValue({
      question: getFAQData.question,
      answer: getFAQData.answer,
    });

    // console.log(getEventData.content)
  }

  onFAQDetailSubmit = async (fieldsValue) => {
    const data = {
      tokenId: "",
      FAQDetails: {
        companyId: this.props.getUserData().userCompanyId,
        Id: this.state.Id,
        question: fieldsValue.question,
        answer: fieldsValue.answer,
        dateTime:dayjs().format("YYYY-MM-DD HH:mm:ss"),
        status: 1,
      },
    };

    console.log(data);

    this.setState({ loading: true });
    const response = await this.faqStore.addData(data);
    console.log(response);
    await this.props.response(response);

    this.setState({ loading: false });
  };

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
  }

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              ref={this.formRef}
              layout="vertical"
              name="nest-messages"
              onFinish={this.onFAQDetailSubmit}
              style={{ maxWidth: "100%", padding: 0 }}
              //   className="formClass"
            >
              <Row className="overflowFormInputs">
                <Col span={24} className="formColumnClass">
                  <Form.Item
                    name="question"
                    label={this.context.translate("_QUESTION_")}
                    rules={[{ required: true }]}
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <TextArea
                      style={{
                        position: "relative",
                        bottom: "-25px",
                        zIndex: 999,
                      }}
                      autoSize={{
                        minRows: 3,
                        maxRows: 5,
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="answer"
                    label={this.context.translate("_ANSWER_")}
                    rules={[{ required: true }]}
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    <TextArea
                      style={{
                        position: "relative",
                        bottom: "-25px",
                        zIndex: 999,
                      }}
                      autoSize={{
                        minRows: 3,
                        maxRows: 5,
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div className="formBtnGroup">
                <Button className="formCancelButton" onClick={this.closeModal}>
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="formSaveButton"
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

FAQAdd.contextType = StoreContext;
export default observer(withRouter(FAQAdd));
