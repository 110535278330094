import React from "react";
import {
  Input,
  Checkbox,
  Radio,
  Space,
  Button,
  Col,
  Row,
  Image,
  Modal,
  Tooltip,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { SearchOutlined } from "@ant-design/icons";
// import ReactTooltip from 'react-tooltip';
import withRouter from "../withRouter";
import { decode as base64_decode } from "base-64";
import CompanyAutocomplete from "./CompanyAutocomplete";
import config from "../config";
import ListTable from "./ListTable";
import DetailTrackingStore from "../store/DetailTrackingStore";

import dayjs from "dayjs";
import MapForVehiclePosition from "./MapForVehiclePosition";
import Cookies from "js-cookie";
import conf from "../config";
import ShareModalWindow from "./ShareModalWindow";
let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title === "Location") {
      val.width = 270;
    } else if (val.title?.length > 24) {
      val.width = 250;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 150;
    } else if (val.title?.length > 8) {
      val.width = 125;
    } else {
      val.width = 110;
    }
    // console.log(val.title.length)
  });

  return arr;
};

class DetailTrackingList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.isadmin = Number(this.props.getUserData().isadmin);
    this.detailTrackingStore = new DetailTrackingStore();
    this.state = {
      offset: "",
      limit: "",
      status: "normal",
      loading: false,
      pageSize: 50,
      currentPage: 1,
      NoData: "",
      Normal: "",
      Idle: "",
      // Moving: "",
      Running: "",
      Stopped: "",
      searchvod: "",
      show: false,
      modalVisible: false,
      column: "",
      vehicleObj: false,
      shareModalVisible: false,
      searchField: "",
      isAllVehicle: false,

      selectedColumns: widthAdderFunc([
        {
          // title: "Company",
          title: context.translate("_FLEET_DASHBORD_NEWPOI_COMPANY_"),
          dataIndex: "companyName",
          width: "10%",
          fixed: "left",
          key: "companyName",
        },

        {
          // title: "Vehicle No",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          width: "10%",
          fixed: "left",
          key: "vehicleNo",
          render: (_, record) => {
            // console.log("recrd", record.listImage);
            return (
              <Space>
                <Image
                  width={30}
                  height={15}
                  src={require("../assets/images/detailtracking/" +
                    record.listImage)}
                />

                {record.vehicleNo}
              </Space>
            );
          },
        },
        {
          // title: "Driver Id",
          title: context.translate("_FLEET_REPORTS_DRIVER_ID_"),
          dataIndex: "driverId",
          key: "driverId",

          // width:"10%"
        },
        {
          // title: "Vehicle Type",
          title: context.translate("_VEHICLE_TYPE_"),
          dataIndex: "vehicleTypeNew",
          key: "vehicleTypeNew",
        },
        {
          // title: "Driver Name",
          title: context.translate(
            "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
          ),
          dataIndex: "name",
          key: "name",
          // width:"10%",
        },
        {
          // title: "Location",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_LOCATION_"),
          dataIndex: "formatted_address",
          key: "formatted_address",
          // width:"10%",
        },
        {
          // title: "Ignition",
          title: context.translate("_IGNITION_"),
          dataIndex: "acc",
          key: "acc",
          // width:"10%",
          render: (value) => {
            if (value == 1) {
              var img = "ignition-icon-on.png";
            } else {
              var img = "ignition-icon-off-red.png";
            }
            return (
              <>
                <Image
                  src={require("../assets/images/detailtracking/" + img)}
                  width={20}
                  height={20}
                />
              </>
            );
          },
        },
        {
          // title: "Speed(Km)",
          title: context.translate("_SPEED_KM_"),
          dataIndex: "speed",
          key: "speed",
          // width:"10%"
        },
        {
          // title: "Time",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_TIME_"),
          dataIndex: "updatedate",
          render: (val) => {
            return dayjs(val).format("DD-MM-YYYY h:mm:ss A");
          },
          key: "updatedate",
          // width:"10%"
        },
        {
          // title: "GPS",
          title: context.translate("_GPS_"),
          dataIndex: "GPS",
          key: "GPS",
          // width:"10%"
          render: (value) => {
            return (
              <>
                <Image
                  src={require("../assets/images/detailtracking/gps-icon-on-green.png")}
                  width={20}
                  height={20}
                />
              </>
            );
          },
        },
        {
          // title: "Alert",
          title: context.translate("_FLEET_ALERT_"),
          dataIndex: "power",
          key: "power",
          // width:"10%",
          render: (value) => {
            if (value == 1) {
              var img = "alert-icon-red.png";
            } else {
              var img = "alert-icon-green.png";
            }
            return (
              <>
                <Image
                  src={require("../assets/images/detailtracking/" + img)}
                  width={20}
                  height={20}
                />
              </>
            );
          },
        },
        {
          // title: "Alert",
          title: context.translate("_POWER_"),
          dataIndex: "power",
          key: "power",
          // width:"10%",
          render: (value) => {
            if (value == 1) {
              var img = "battery-red-icon.png";
            } else {
              var img = "battery-green-icon.png";
            }
            return (
              <>
                <Image
                  src={require("../assets/images/detailtracking/" + img)}
                  width={20}
                  // height={20}
                />
              </>
            );
          },
        },
        {
          // title: "Odometer",
          title: context.translate("_FLEET_DASHBORD_SETTING_ODOMETER_"),
          dataIndex: "distance",
          key: "distance",
          // width:"10%"
        },
        {
          // title: "Temp1",
          title: context.translate("_FLEET_DASHBORD_SETTING_TEMP1_"),
          dataIndex: "temp1",
          key: "temp1",
          // width:"10%"
        },
        {
          // title: "Temp2",
          title: context.translate("_FLEET_DASHBORD_SETTING_TEMP2_"),
          dataIndex: "temp2",
          key: "temp2",
          // width:"10%"
        },
        {
          // title: "Temp3",
          title: context.translate("_FLEET_DASHBORD_SETTING_TEMP3_"),
          dataIndex: "temp3",
          key: "temp3",
          // width:"10%"
        },
        {
          // title: "Maps",
          title: context.translate("_FLEET_SERVICE_POILIST_MAP_"),
          // dataIndex: "Maps",
          key: "Maps",
          // width:"10%"
          render: (value) => {
            // console.log(value)
            return (
              <>
                {/* <Tooltip tiitle={value.vehicleNo}> */}
                <Image
                  src={require("../assets/images/detailtracking/map-icon.png")}
                  height={17}
                  width={17}
                  onClick={() => {
                    this.setState({ vehicleObj: value });
                  }}
                />
                {/* </Tooltip> */}
              </>
            );
          },
        },
        {
          // title: "Share",
          title: context.translate("_FLEET_SERVICE_POILIST_SHARE_"),
          dataIndex: "Share",
          //   fixed: "right",
          key: "Share",
          // width:"10%",
          render: (value, record) => {
            // console.log(record);

            let data = [];
            data.push(`${Number(record.latitude)},${Number(record.longitude)}`);
            // console.log(data);
            return (
              <>
                <Image
                  src={require("../assets/images/detailtracking/share-icon.png")}
                  height={15}
                  width={15}
                  onClick={() => {
                    this.setState({ shareModalVisible: data });
                    // console.log(data);
                  }}
                />
              </>
            );
          },
        },
      ]),
    };

    this.newColumns = widthAdderFunc([
      ...this.state.selectedColumns,
      {
        title: context.translate("_VEHICLEID_"),
        dataIndex: "vehicleId",
        key: "vehicleId",
      },
      {
        title: context.translate("_FLEET_DASHBORD_MAP_SATELLITE_"),
        dataIndex: "GSMSignal",
        key: "GSMSignal",
      },
      {
        // title: "Load Voltage",
        title: context.translate("_LOAD_VOLTAGE_"),
        dataIndex: "loadVoltage",
        key: "loadVoltage",
      },
      {
        // title: "Total Weight(kg)",
        title: context.translate("_TOTAL_TRUCK_WEIGHT_"),
        dataIndex: "totalWeight",
        key: "totalWeight",
        render: (value, c, r) => {
          console.log(r, c);
          var loadVoltage = c.loadVoltage;
          var weightFactor = c.weightFactor;

          if (loadVoltage != null && weightFactor != null) {
            //r.get('totalWeight') is v.weightFactor
            var loadKg = loadVoltage * weightFactor;

            if (loadKg > c.emptyWeight) {
              return Math.round(loadKg);
            } else {
              return Math.round(c.emptyWeight);
            }
          } else {
            return "NA";
          }
        },
      },
      {
        // title: "BLE Temp",
        title: context.translate("_BLE_TEMP_"),
        dataIndex: "BLETemp",
        key: "bleTemp",
        render: (value) => {
          if (!value || value === "") {
            return "NA";
          }
          var humArr = value.split(", ");
          var formattedHumidity = [];

          for (var i = 0; i < humArr.length; i++) {
            if (humArr[i] !== "NA") {
              var j = i + 1;
              formattedHumidity.push(
                <div key={j}>
                  <b>{`Sensor${j}: `}</b>
                  {humArr[i]}
                </div>
              );
            }
          }
          return formattedHumidity;
        },
      },
      {
        // title: "BLE Humidity",
        title: context.translate("_BLE_HUMIDITY_"),
        dataIndex: "BLEHumidity",
        key: "bleHumidity",
        render: (value) => {
          if (!value || value === "") {
            return "NA";
          }
          var humArr = value.split(", ");
          var formattedHumidity = [];

          for (var i = 0; i < humArr.length; i++) {
            if (humArr[i] !== "NA") {
              var j = i + 1;
              formattedHumidity.push(
                <div key={j}>
                  <b>{`Sensor${j}: `}</b>
                  {humArr[i]}
                </div>
              );
            }
          }

          return formattedHumidity;
        },
      },

      {
        // title: "IMEI ",
        title: context.translate("_FLEET_DASHBORD_SETTING_IMEI_"),
        dataIndex: "full_imei",
        key: "imei",
      },
      {
        // title: "SIM No ",
        title: context.translate("_SIM_NO_"),
        dataIndex: "simNo",
        key: "simNo",
      },
    ]);

    // let column = this.newColumns;
    // console.log(column)
    // if (this.isadmin === -1 || this.isadmin === 3) {
    //   console.log("aaaaa")
    //   column = this.newColumns;
    // } else {
    //   column = this.newColumns.filter((val) => val.dataIndex !== "simNo" || "full_imei");
    //   console.log(column)
    // }
    // this.columns = column;
  }
  async componentDidUpdate(prevProps, prevState) {
    // When the modal is visible, trigger a map refresh to ensure it renders properly
    // await this.liveLatLongGetting()

    if (this.state.vehicleObj !== prevState.vehicleObj) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }
  }

  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleSearch();
    }
    // await this.detailTrackingStore.loadData({ offset: 0, limit: 50 });
    await this.setState({ pageSize: 50 });
    await this.setState({ column: this.state.selectedColumns });
  }
  async handleChange(comp) {
    console.log(comp);
    if (comp) {
      await this.setState({
        companyId: comp ? comp?.value : null,
        currentPage: 1,
        status: "normal",
      });
      await this.handleSearch();
    } else {
      await this.setState({
        companyId: null,
      });
      this.detailTrackingStore.trackData = [];
      this.detailTrackingStore.totalVehicalState = [];
      this.detailTrackingStore.total = 0;
      this.setState({ loadData: 1 });
    }
  }

  handleCheckboxChange = async (column) => {
    console.log(column);

    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });

      // await  this.updateVisibleColumns();
    }
  };

  callingMethod = async () => {
    let x = await this.detailTrackingStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      status: this.state.status,
      searchField: this.state.searchField,
      userCompany: this.props.getUserData().userCompanyId,
      isadmin: this.props.getUserData().isadmin,
      //   vehicleNo:this.state.vehicleNo,
      //   driver:this.state.driver
    });
    await this.setState({ loadData: 1 });
    setTimeout(() => {
      this.callingMethod();
    }, 60000);
  };
  async handleSearch() {
    if (this.state.companyId) {
      this.callingMethod();
    }
  }
  searchInput = async (e) => {
    if (e === 0 && this.state.companyId) {
      await this.detailTrackingStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
        status: this.state.status,
        searchField: this.state.searchField,
        userCompany: this.props.getUserData().userCompanyId,
        isadmin: this.props.getUserData().isadmin,
        //   vehicleNo:this.state.vehicleNo,
        //   driver:this.state.driver
      });
      await this.setState({ currentPage: 1 });
    } else {
      const value = e.target.value;
      // await this.setState({searchField:value})
      if (value.length > 3 && this.state.companyId) {
        await this.detailTrackingStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          status: this.state.status,
          searchField: this.state.searchField,
          userCompany: this.props.getUserData().userCompanyId,
          isadmin: this.props.getUserData().isadmin,
          //   vehicleNo:this.state.vehicleNo,
          //   driver:this.state.driver
        });
        await this.setState({ loadData: 1 });

        await this.setState({ loadList: 1 });
      } else if (value.length === 0 && this.state.companyId) {
        await this.detailTrackingStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          status: this.state.status,
          searchField: "",
          userCompany: this.props.getUserData().userCompanyId,
          isadmin: this.props.getUserData().isadmin,
          //   vehicleNo:this.state.vehicleNo,
          //   driver:this.state.driver
        });
        await this.setState({ currentPage: 1 });
      }
    }
  };

  exportFuncClick = async () => {
    const result = [];

    this.state.selectedColumns?.map((obj) => {
      result.push(obj.dataIndex);
    });
    //  console.log(result.toString())
    // vehicleNo=&driver=&status=searchvod&userId=1
    var getData = {
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      cid: this.state.companyId,
      colList: result,
      searchField: this.state.searchField,
      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      status: this.state.status,
    };

    await this.detailTrackingStore.exportExcel(getData);
  };

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  render() {
    return (
      <>
        <Row className="headerFixed" style={{ height: "50px" }}>
          <Col
            span={24}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="heading" style={{ marginTop: "5px" }}>
              {this.context.translate("_DETAIL_TRACKING_")}
            </div>
            <div>
              <Button onClick={() => this.setState({ modalVisible: true })}>
                {/* Columns */}
                {this.context.translate("_COLUMNS_")}
              </Button>
            </div>
          </Col>
        </Row>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={24}>
              <Space>
                <Radio.Group
                  onChange={async (e) => {
                    await this.setState({ status: e.target.value });
                    await this.handleSearch();
                  }}
                >
                  <Space>
                    <Radio.Button
                      title=""
                      value={"Stop"}
                      style={{ color: "black", backgroundColor: "#EF5350" }}
                    >
                      {/* Stopped{" "} */}
                      {this.context.translate("_STOPPED_")}{" "}
                      {this.detailTrackingStore.totalVehicalState?.Stopped}
                    </Radio.Button>
                    <Radio.Button
                      value={"Running"}
                      style={{ color: "black", backgroundColor: "#66BB6A" }}
                    >
                      {/* Running */}
                      {this.context.translate("_RUNNING_")}{" "}
                      {this.detailTrackingStore.totalVehicalState?.Moving}
                    </Radio.Button>
                    <Radio.Button
                      value={"Idle"}
                      style={{ color: "black", backgroundColor: "#FFEE58" }}
                    >
                      {/* Idle  */}
                      {this.context.translate("_IDLE_")}{" "}
                      {this.detailTrackingStore.totalVehicalState?.Idle}
                    </Radio.Button>
                    <Radio.Button
                      value={"powerCut"}
                      style={{ color: "black", backgroundColor: "#03A9F4" }}
                    >
                      {" "}
                      {/* NoGPS */}
                      {this.context.translate("_POWER_CUT_")}{" "}
                      {this.detailTrackingStore.totalVehicalState?.PowerCut}
                    </Radio.Button>

                    <Radio.Button
                      value={"NoData"}
                      style={{ color: "black", backgroundColor: "#839192" }}
                    >
                      {/* NoData  */}
                      {this.context.translate("_NoData_")}{" "}
                      {this.detailTrackingStore.totalVehicalState?.NoData}
                    </Radio.Button>
                    <Radio.Button
                      value={"normal"}
                      style={{ color: "black", backgroundColor: "#D0D3D4" }}
                    >
                      {/* All  */}
                      {this.context.translate("_ALL_")}{" "}
                      {this.detailTrackingStore.total}
                    </Radio.Button>

                    <Radio.Button
                      onClick={() => this.searchInput(0)}
                      style={{ color: "black", backgroundColor: "#D0D3D4" }}
                    >
                      {/* / Refresh */}
                      {this.context.translate("_REFRESH_")}
                    </Radio.Button>
                  </Space>
                </Radio.Group>
                {/* <Button onClick={()=>this.searchInput(0)}>Refresh</Button> */}
                <CompanyAutocomplete
                  showAll={1}
                  allowClear={true}
                  onChange={(value) => this.handleChange(value)}
                  style={{ width: 200 }}
                  //   allowClear={true}
                  // mode="multiple"
                />
                {/* {(this.props.getUserData().isadmin === 3 ||
                  this.props.getUserData().isadmin === -1) && (
                  <Checkbox
                    checked={this.state.isAllVehicle}
                    onChange={async (e) => {
                      await this.setState({ isAllVehicle: e.target.checked });

                      if (this.state.isAllVehicle) {
                        await this.setState({
                          currentPage: 1,
                          pageSize: 50,
                          companyId: [0],
                        });

                        await this.callingMethod();
                      } else {
                        await this.setState({
                          companyId: null,
                        });
                        this.detailTrackingStore.trackData = [];
                        this.detailTrackingStore.total = 0;
                        this.setState({ loadData: 1 });
                        await this.setState({ loadData: 1 });
                      }
                    }}
                  >
                    All Vehicle
                  </Checkbox>
                )} */}
                <Input
                  // placeholder="Search by Vehicle,Driver"

                  placeholder={this.context.translate(
                    "_SEARCH_BY_DRIVER_VEHICLE_"
                  )}
                  style={{ width: 200 }}
                  onChange={async (e) => {
                    await this.setState({ searchField: e.target.value });
                    await this.searchInput(e);
                    // await this.setState({ status: "searchvod" });
                  }}
                />

                <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  onClick={async () => {
                    // await this.setState({ status: "searchvod" });
                    await this.searchInput(0);
                  }}
                />
                <Tooltip
                  // title="Export"
                  title={this.context.translate("_EXPORT_")}
                  color={config.tooltipColorEdit}
                  placement="right"
                >
                  <Button
                    onClick={() => this.exportFuncClick()}
                    style={{ background: conf.tooltipColorExport }}
                  >
                    {/* Export */}
                    {this.context.translate("_EXPORT_")}
                  </Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>

          <ListTable
            style={{ height: "500px" }}
            currentPage={this.state.currentPage}
            total={this.detailTrackingStore?.total}
            columns={this.state.column}
            pageSize={this.state.pageSize}
            dataSource={this.detailTrackingStore?.trackData}
            loading={this.detailTrackingStore?.loading}
            onChange={this.handleTableChange}
            scroll={{
              x: "calc(700px + 70%)",
              y: 240,
            }}
          />
        </div>

        {/* <Modal
          open={this.state.show}
          centered
          
          style={{ height: 100 }}
          width={300}
          // height={500}
          onCancel={() => this.setState({ show: false })}
        >
          <div className="heading">SHARE</div>
        </Modal> */}

        <Modal
          open={this.state.modalVisible}
          width={300}
          // title="Select Columns"
          title={this.context.translate("_SELECT_COLUMNS_")}
          onCancel={() => this.setState({ modalVisible: false })}
          onOk={() => {
            this.setState({ modalVisible: false });
          }}
          footer={null}
        >
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            {this.newColumns.map((column, v) => {
              if (this.props.getUserData().isadmin === 1) {
                if (
                  column.dataIndex !== "simNo" &&
                  column.dataIndex !== "full_imei"
                ) {
                  return (
                    <Row key={v}>
                      <Col>
                        <Checkbox
                          onChange={() => this.handleCheckboxChange(column)}
                          checked={this.state.selectedColumns.some(
                            (c) => c.key === column.key
                          )}
                        >
                          {column.title}
                        </Checkbox>
                      </Col>
                    </Row>
                  );
                }
              } else {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              }
            })}

            {/* {console.log(this.newColumns, "Df")} */}
            {/* { if(this.props.getUserData().isadmin===1){
                column.filter((val) => val.dataIndex !== "simNo" || "full_imei");
                // console.log(column)
              }} */}
            {/* {this.newColumns.map((column, v) => {
              return (
                <Row key={v}>
                  <Col>
                    <Checkbox
                      onChange={() => this.handleCheckboxChange(column)}
                      checked={this.state.selectedColumns.some(
                        (c) => c.key === column.key
                      )}
                    >
                      {column.title}
                    </Checkbox>
                  </Col>
                </Row>
              );
            })} */}
          </div>
        </Modal>

        {this.state.vehicleObj && (
          <MapForVehiclePosition
            vehicleObj={this.state.vehicleObj}
            heading={this.context.translate("_DETAIL_TRACKING_MAP_")}
            closeMapModal={(resp) => {
              this.setState({ vehicleObj: resp });
            }}
          />
        )}

        {this.state.shareModalVisible && (
          <ShareModalWindow
            shareModalCloseFunc={(resp) =>
              this.setState({ shareModalVisible: resp })
            }
            shareSingleLocation={this.state.shareModalVisible}
          />
        )}
      </>
    );
  }
}
DetailTrackingList.contextType = StoreContext;
export default observer(withRouter(DetailTrackingList));
