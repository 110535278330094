import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  
  makeObservable,
  observable,
 
} from 'mobx';
import config from '../config';
import utf8 from 'utf8';

class VehicleReturnAssetsStore {
  returnAsset=[]
 
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        returnAsset: observable,
      total:observable,
      loading:observable,
      loadData:action,
      getReturnAssetData:action,
      addData:action,
      exportExcel:action,
      getAllocateVehicles:action
     
    });
     this.rootStore = rootStore;
    
  }

  async loadData({ companyId,offset,limit,searchField}) {
    this.loading = true;
    console.log("ReturnAssetsStore")
    
   var postData = {"tokenId":"","companyId":companyId,"offset":offset,"limit":limit,searchField:searchField}
   
    //const base64Input = await base64_encode(JSON.stringify(postData));
    const base64Input = await  utf8.encode(JSON.stringify(postData));
    const base2 = JSON.stringify( base64_encode(base64Input));

    var response = await postApi.getClient().post("index.php?c=allocatevehicle&a=getreturnAssetList", "postData="+base2);
   console.log("allocate",response)
    console.log(response?.data?.data?.returnAsset)
    this.returnAsset = response?.data?.data?.returnAsset
    this.total= parseInt(response?.data?.data?.TotalCount)
    this.loading = false;
    
  }
  async addData(returnAsset) {
    console.log("returnAsset", returnAsset)
    var postData = returnAsset
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=allocatevehicle&a=returnasset", "postData=" + base64Input);
    console.log(response)
    return response
  }

  async getReturnAssetData(Id,limit,offset){
    console.log(Id,"id")
    const postData = {"rid":Id,limit:1,offset:0}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=allocatevehicle&a=getreturnAssetList", "postData="+base64Input);
    console.log(response)
    return response?.data?.data.returnAsset[0]
  }

  async deleteRecord({Id}) {
    var postData = {"tokenId":"","Id":Id}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=allocatevehicle&a=deleteAssignvehicle", "postData=" + base64Input);
    return response;
  }

  //TO get asset dropdown
  async getAllocateVehicles({companyId,asset}) {
    console.log(asset,"sadfsdfd")
    if(asset==="returntoDriver"){
    var postData ={"tokenId":"","companyId":companyId,"userid":null,"isadmin":null,"asset":asset};

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=allocatevehicle&a=getallocateVehicles", "postData=" + base64Input);
    console.log(response, "post");
    this.allocateVehicleAsset = response?.data?.data?.vehicles;
    return response?.data?.data?.vehicles;
  }else{
    var postData ={"tokenId":"","companyId":companyId,"userid":null,"isadmin":null,"asset":asset};

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=allocatevehicle&a=getallocateVehicles", "postData=" + base64Input);
    console.log(response, "post");
    this.allocateVehicleAsset = response?.data?.data?.vehicles;
    return response?.data?.data?.vehicles;
  }
}


async exportExcel(getData) {
  window.location.href =
    config.baseURL +
    "index.php?c=vehicle&a=returnExportexcel&cid=" +
    getData.cid +
    "&searchField=" +
    getData.searchField+
    "&colList=" +
    getData.colList;
}

   // to get asset data

  //  async getAllocateVehicles({ companyId, asset }) {
  //   console.log(asset,"asssset");
  //   if (asset) {
     
  //     var postData = {
  //       tokenId: "80bovn8u800tg1aq9bvr73kbhh",
  //       companyId: companyId,
  //       userid: null,
  //       isadmin: null,
  //       asset: asset,
  //     };
  //     const base64Input = await base64_encode(JSON.stringify(postData));
  //     var response = await postApi
  //       .getClient()
  //       .post(
  //         "index.php?c=allocatevehicle&a=getallocateVehicles",
  //         "postData=" + base64Input
  //       );
  //     console.log(response, "post");
  //     if (asset == "returntoDriver") {
  //       this.allocateVehicleAsset = response?.data?.vehicles;
  //     } else {
  //       this.allocateVehicleAsset = response?.data?.data?.vehicles;
  //     }
  //   } else {
  //     var postData = {
  //       tokenId: "80bovn8u800tg1aq9bvr73kbhh",
  //       companyId: companyId,
  //       userid: null,
  //       isadmin: null,
  //       asset: 1,
  //     };
  //   }
  //   const base64Input = await base64_encode(JSON.stringify(postData));
  //   var response = await postApi
  //     .getClient()
  //     .post(
  //       "index.php?c=allocatevehicle&a=getallocateVehicles",
  //       "postData=" + base64Input
  //     );
  //   console.log(response, "post");
  //   this.allocateVehicleAsset = response?.data?.data?.vehicles;

  //   return response?.data?.data?.vehicles;
  // }


  

  
  

}

export default VehicleReturnAssetsStore;
