import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'

import config from "../config";
import {
  action,

  makeObservable,
  observable,

} from 'mobx';


class FuelDetailsStore {
  fuelDetail = []
  // users=[]
  // drivers=[]
  // staff=[]
  // passengers=[]
  total = 0
  loading = false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
      fuelDetail: observable,
      total: observable,
      loading: observable,
      loadData: action,
      addData:action,
      getFuelDetails:action,
      deleteRecord:action,
      exportExcel:action,
      checkCompany:action
    });
    this.rootStore = rootStore;

  }

  async loadData({ offset, limit,companyId }) {
    this.loading = true;
    console.log("fuel Details")
    var postData = { "companyId": companyId, "offset": 0, "limit": 15, "page": 1 }
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=fuel&a=getFueldetails", "postData=" + base64Input);
    console.log("fuel detis ", response?.data?.data)
    this.fuelDetail = response?.data?.data
    // //  this.setCompanies(response?.data?.data?.companydata);
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async addData(values) {
    var postData = values
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=fuel&a=update", "postData=" + base64Input);
    console.log(response)
    return response.data
  }

  async getFuelDetails({fuelId,offset,limit}){
    var postData = {"fuelId":fuelId,offset:0,limit:1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=fuel&a=getFueldetails", "postData=" + base64Input);
    console.log(response)
    return response.data.data[0]
  }

  async deleteRecord({fuelId}){
    var postData =  {"tokenId":"","FuelDetails":{"fuelId":fuelId}}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=fuel&a=delete", "postData=" + base64Input);
    console.log(response)
    return response
  }

  async exportExcel(getData) {
    window.location.href =
      config.baseURL +
      "index.php?c=fuel&a=fueldetailsExportexcel&cid=" +
      getData.cid +
      "&colList=" +
      getData.colList;
  }
  
  async checkCompany(data){
   
    var postData =  data
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=fuel&a=checkFuel", "postData=" + base64Input);
    
    return response.data.data
  }

 
}

export default FuelDetailsStore;
