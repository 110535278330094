import React from "react";
import { Row, Col, Space, Tooltip, message, Button, Modal } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import ListTable from "./ListTable";

import config from "../config";

import withRouter from "../withRouter";
import DeleteConfirm from "./DeleteConfirm";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
import FAQStore from "../store/FAQListStore";
import FAQAdd from "./FAQAdd";

class FAQList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.faqStore = new FAQStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      loadData: 1,
      Id:'',
      openfaqForm: false,
    };
  }
  async componentDidMount() {
    await this.handleSearch();
    this.setState({ pageSize: 50 });

    this.columns = [
      {
        title: this.context.translate("_QUESTION_"),
        dataIndex: "question",
        width: "20%",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        title: this.context.translate("_ANSWER_"),
        dataIndex: "answer",
        width: "20%",
      },
      {
        title: this.context.translate("_FLEET_DASHBORD_SETTING_DATE_"),
        dataIndex: "dateTime",
        width: "10%",
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "action",
        width: "5%",
        render: (_, record) => {
          return (
            <>
              <Space>
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    // title="Delete Record"
                    title={this.context.translate("_DELETE_RECORD_")}
                    color={config.tooltipColorDelete}
                  >
                    <DeleteFilled style={{ color: config.deleteColor }} />
                  </Tooltip>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  onEdit = async (record) => {
    await this.setState({ Id: record.Id });
    await this.setState({ openfaqForm: true });
  };

  async onDelete(record) {
    const response = await this.faqStore.deleteRecord({
      Id: record.Id,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.faqStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      page: this.state.currentPage,
    });
    await this.setState({ loadData: 1, loading: false });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  closeModal = () => {
    this.setState({ openfaqForm: false });
  };

  responseMsg = async (resp) => {
    if (resp.success === "S") {
      message.success(resp.message);
      await this.setState({ openfaqForm: false });
      await this.handleSearch();
    }
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("FAQ List")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Tooltip
                  placement="left"
                  //  title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => this.setState({ openfaqForm: true, Id: "" })}
                  ></Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <ListTable
            currentPage={this.state.currentPage}
            total={this.faqStore?.total}
            columns={this.columns}
            pageSize={this.state.pageSize}
            dataSource={this.faqStore?.faqList}
            loading={this.faqStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openfaqForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal"
            title={this.context.translate("Add FAQ")}
            centered
            open={this.state.openfaqForm}
            footer={null}
            onCancel={() => {
              this.setState({ openfaqForm: false, Id: "" });
            }}
            width={665}
          >
            <div>
              <FAQAdd
                Id={this.state.Id}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
FAQList.contextType = StoreContext;
export default observer(withRouter(FAQList));
