import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
  observe,
} from "mobx";
import { act } from "react-dom/test-utils";
import Cookies from "js-cookie";
class DriverBehaviourSettingStore {
  driverBehavior = [];
  // users=[]
  // drivers=[]
  // staff=[]
  // passengers=[]
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      driverBehavior: observable,
      total: observable,
      loading: observable,
      loadData: action,
      addData: action,
      getDriverBehaviourSetting: action,
      deleteDriverBehaviourSetting: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ offset, limit, companyId }) {
    this.loading = true;

    var postData = {
      companyId:
        JSON.parse(base64_decode(Cookies.get("data"))).isadmin === 1 ||
        JSON.parse(base64_decode(Cookies.get("data"))).isadmin === 0
          ? Number(JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId)
          : null,
      offset: offset,
      limit: limit,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=driverbsetting&a=getDriverBSettings",
        "postData=" + base64Input
      );
    console.log("behavior ", response);
    // console.log("company Data",response?.data?.data?.companydata)
    this.driverBehavior = response?.data?.data;

    //  this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async addData(DBData) {
    console.log(DBData);
    var postData = {
      companyId: DBData.companyId,
      minMileage: DBData.minMileage,
      topScore: DBData.topScore,
      absValue: DBData.absValue,
      custScore: DBData.custScore,
      harshAcce: DBData.harshAcce,
      harshBrack: DBData.harshBrack,
      exIdeling: DBData.exIdeling,
      speeding: DBData.speeding,
      rpmExcess: DBData.rpmExcess,
      seatBelt:DBData.seatBelt,
      id: DBData.id,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    const response = await postApi
      .getClient()
      .post("index.php?c=driverbsetting&a=update", "postData=" + base64Input);
    console.log(response);
    return response.data;
  }

  async getDriverBehaviourSetting(id) {
    console.log(id);
    var postData = { bid: id, limit: 1, offset: 0 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=driverbsetting&a=getDriverBSettings",
        "postData=" + base64Input
      );
    console.log(response);
    return response.data.data[0];
  }

  async deleteDriverBehaviourSetting({ id }) {
    console.log(id);
    var postData = {
      tokenId: "80bovn8u800tg1aq9bvr73kbhh",
      FuelDetails: {},
      id: id,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=driverbsetting&a=delete", "postData=" + base64Input);
    return response;
  }
}

export default DriverBehaviourSettingStore;
