import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Space,
  Button,
  Select,
  Input,
  Table,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";

import AssignVehicleToUserStore from "../store/AssignVehicleToUserStore";
import UsersStore from "../store/UsersStore";
import { DeleteFilled } from "@ant-design/icons";
import config from "../config";

class AssignVehicleToAdvertise extends Component {
  constructor(props) {
    super(props);
    // this.handleChange = this.handleChange.bind(this);
    this.assignVehicleToUserStore = new AssignVehicleToUserStore();
    this.UsersStore = new UsersStore();
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      loadList: 0,
      companyId: "",
      selectedDevices: "",
      text: "",
      selectedVehicle: "",
      all: false,
      assignvehicles: [],
      selectedVehiclesData: [],
      selectedRemove: [],
      groupId: "",
      openAssignForm: false,
      allAssignVehiclesArra: [],
      allUnAssignedVehicles: [],
      hideTblDta: false,
      assignAllSelectedGroup: false,
      assignVehicleSelectedGroupsBoolean: false,

      swapCounter: 0,
      // selectedRowKeys:[]
    };
  }

  // selectedVehicle
  onDeleteRow = (index, vehicleadd) => {
    if (this.state.assignAllSelectedGroup) {
      return;
    }
    const filterSelectedVehicle = this.state.selectedVehicle?.filter(
      (val) => val !== vehicleadd
    );
    const filterData = this.state.assignvehicles.filter(
      (val, indexx) => indexx !== index
    );
    const filterData1 = this.state.allAssignVehiclesArra.filter(
      (val, indexx) => indexx !== index
    );
    console.log(filterData);
    this.setState({
      selectedVehiclesData: filterData1,
      assignvehicles: filterData,
      allAssignVehiclesArra: filterData1,
      selectedVehicle: filterSelectedVehicle,
    });
  };

  handleRemoveSelected = () => {
    if (this.state.assignAllSelectedGroup) {
      return;
    }
    let filteredX = this.state.selectedVehicle?.filter((element) => {
      if (!this.state.selectedRemove?.includes(element)) return element;
    });

    this.setState({ selectedVehicle: filteredX });

    let arr = [];
    this.state.allAssignVehiclesArra.forEach((val) => {
      if (!this.state.selectedRemove.includes(val.vehicleId)) {
        arr.push(val);
      }
    });
    this.setState({ selectedVehiclesData: arr, allAssignVehiclesArra: arr });
    console.log(arr);
  };

  async componentDidMount() {
    console.log(this.props);
    this.columns = [
      {
        // title: "Vehicles",
        title: this.context.translate("_VEHICLE_"),
        dataIndex: "vehicleNo",
        // width: "15%",
      },
    ];
    this.columns1 = [
      {
        // title: "Assigned Vehicles",
        title: this.context.translate("_ASSIGNED_VEHICLES_"),
        dataIndex: "vehicleNo",
        key: "vehicleId",
        // width: "15%",
      },
      {
        // title: "Remove",
        title: this.context.translate("_REMOVE_"),
        dataIndex: "action",
        render: (text, record, index) => {
          // console.log(record, index);
          return (
            <>
              <Space>
                <Tooltip
                  // title="Remove"
                  title={this.context.translate("_REMOVE_")}
                  color={config.tooltipColorDelete}
                >
                  <Link
                    onClick={async () => {
                      await this.onDeleteRow(index, Number(record.vehicleId));
                      console.log(record, text);
                    }}
                  >
                    {" "}
                    <DeleteFilled style={{ color: config.deleteColor }} />
                  </Link>
                </Tooltip>
              </Space>
            </>
          );
        },
      },
    ];

    const data = await this.UsersStore.getDeviceGroups({
      companyId: this.props.companyId,
    });
    //  await this.setState({all:this.props.vehicleSelection})
    let vehicleSelection = this.props.vehicleSelection;

    if (vehicleSelection === 1) {
      this.setState({ hideTblDta: true, assignAllSelectedGroup: true });
    } else if (vehicleSelection === 2) {
      this.setState({ hideTblDta: true, assignAllSelectedGroup: false });
    } else if (vehicleSelection === 3) {
      this.setState({ hideTblDta: false, assignAllSelectedGroup: true });
    } else if (vehicleSelection === 4) {
      this.setState({ hideTblDta: false, assignAllSelectedGroup: false });
    }
    console.log(vehicleSelection);

    // const res =  await this.getAllVehicleFunc(Number(1))
    const res = await this.assignVehicleToUserStore.getAllUserVehicleFunc({
      companyId: this.props.companyId,
      userId: this.props.userId,
    });
    console.log(res);

    if (res.assignvehicles) {
      if (res.devicegroupId?.length > 0) {
        const deviceContainVehicles = await this.UsersStore.getGroupVehicles({
          groupNameId: res.devicegroupId,
        });
        const allVehicles = await res?.assignvehicles?.map((val) => {
          console.log(val);
          return val.vehicleId;
        });
        await this.setState({ selectedDevices: res.devicegroupId });
        await this.formRef.current.setFieldsValue({
          deviceGroupId: res.devicegroupId,
        });
        await this.setState({
          allUnAssignedVehicles: deviceContainVehicles,
          selectedVehicle: allVehicles,
          allAssignVehiclesArra: res?.assignvehicles,
        });
        // await this.setState({ allUnAssignedVehicles: res?.Vehicles });
      } else {
        const allVehicles = await res?.assignvehicles?.map((val) => {
          console.log(val);
          return val.vehicleId;
        });
        await this.setState({
          allUnAssignedVehicles: res?.Vehicles,
          selectedVehicle: allVehicles,
          allAssignVehiclesArra: res?.assignvehicles,
        });
        // await this.setState({ allUnAssignedVehicles: res?.Vehicles });
      }
    }
    if (this.props.vehicleSelection > 0) {
      this.setState({ all: this.props.vehicleSelection });
      await this.formRef.current.setFieldsValue({
        optionsForAssign: this.props.vehicleSelection,
      });
    } else if (this.props.vehicleSelection === 0) {
      this.setState({ all: 2 });
      await this.formRef.current.setFieldsValue({ optionsForAssign: 2 });
    }

    console.log(this.UsersStore?.devicegroupId, this.props.userId, "datat1");
  }

  getAllVehicleFunc = async (val) => {
    if (val === 1) {
      const getAllUserVehicle =
        await this.assignVehicleToUserStore.getAllUserVehicleFunc({
          companyId: this.props.companyId,
          userId: this.props.userId,
        });
      console.log(getAllUserVehicle.Vehicles);
      await this.setState({
        allUnAssignedVehicles: getAllUserVehicle.Vehicles,
        hideTblDta: true,
      });
      return getAllUserVehicle;
    } else if (val === 2) {
      // const getAllUserVehicle = await this.assignVehicleToUserStore.getAllUserVehicleFunc({companyId:this.props.companyId,userId:this.props.userId})
      // console.log(getAllUserVehicle.Vehicles )
      await this.setState({ allUnAssignedVehicles: [], hideTblDta: false });
    }
    this.setState({ update: 1 });
  };

  onAssignVehicleToUser = async (values) => {
    this.setState({ loading: true });
    // console.log(this.props);
    // {"tokenId":"","companyId":"499","advertiseId":421,"isadmin":"3","vehicleId":"225935,219489","all":2}
    const data = {
      tokenId: "",
      companyId: this.props.companyId,
     
      isadmin: -1,
      vehicleId: (this.state.allAssignVehiclesArra.length > 0
        ? this.state.allAssignVehiclesArra
        : this.state.selectedVehiclesData
      )?.map((val) => {
        return val.vehicleId;
      }),

      all: this.state.all,
      // groupId:
      //   this.state.selectedDevices?.length > 0
      //     ? this.state.selectedDevices
      //     : null,
    };
    console.log(data)
    // console.log(data, this.props.companyId);
    // const response = await this.assignVehicleToUserStore.addData(data);
    // await this.context.viewStateStore.setUserAddStates({
    //   companyId: this.props.companyId,
    // });
    // // this.setState({companyId:this.state.companyId})
    // console.log(response);
    // await this.props.response(response);
    await this.setState({ loading: false });
  };

  searchInput = async (e) => {
    const value = e.target.value;
    let swap;
    // await this.setState({searchField:value})
    if (value.length >= 3) {
      let resp = await this.UsersStore.searchVehicle({
        text: value,
        companyId: this.props.companyId,
        userId: this.props.userId,
      });

      console.log(resp);
      if (this.state.swapCounter === 0) {
        this.setState({ swapCounter: this.state.allUnAssignedVehicles });
      }

      await this.setState({ allUnAssignedVehicles: resp });
      await this.setState({ loadList: 1 });
    } else if (value.length === 0) {
      console.log(swap);
      await this.setState({ allUnAssignedVehicles: this.state.swapCounter });
    }
  };

  async getGroupofVehicles(deviceGroupId) {
    // console.log(comp.value);
    const data = await this.UsersStore.getGroupVehicles({
      groupNameId: deviceGroupId,
    });
    console.log(data);

    if (deviceGroupId.length === 0) {
      await this.setState({
        selectedVehicle: [],
        allAssignVehiclesArra: [],
      });
    }
    if (this.state.all === 3) {
      let vIds = data?.map((val) => {
        return val.vehicleId;
      });
      await this.setState({
        allUnAssignedVehicles: data,
        selectedVehicle: vIds,
        allAssignVehiclesArra: data,
      });
    } else {
      await this.setState({
        allUnAssignedVehicles: data,
        // selectedVehicle: vIds,
        // allAssignVehiclesArra: data,
      });
    }
    await this.setState({ groupId: data });
  }

  selectAllAssignVehicles = async (e) => {
    // console.log(this.UsersStore?.getVehiclesUsingCompanyId)
    // if (this.state.all) {
    if (e) {
      const allVehicles = e?.Vehicles?.map((val) => {
        console.log(val);
        return val.vehicleId;
      });
      console.log(allVehicles, e);
      await this.setState({
        selectedVehicle: allVehicles,
        // allAssignVehiclesArra: this.UsersStore?.getVehiclesUsingCompanyId,
        allAssignVehiclesArra: e.Vehicles,
      });
    } else {
      const allVehicles = this.state.allUnAssignedVehicles?.map((val) => {
        console.log(val);
        return val.vehicleId;
      });

      console.log(allVehicles, this.state.allUnAssignedVehicles);

      await this.setState({
        selectedVehicle: allVehicles,
        // allAssignVehiclesArra: this.UsersStore?.getVehiclesUsingCompanyId,
        allAssignVehiclesArra: this.state.allUnAssignedVehicles,
      });
    }
  };

  handleRemoveAll = () => {
    if (this.state.assignAllSelectedGroup) {
      return;
    }
    this.setState({
      selectedVehiclesData: [],
      assignvehicles: [],
      selectedRemove: [],
      selectedVehicle: [],
      allAssignVehiclesArra: [],
      all: false,
    });
  };

  selectOptionForAssign = async (val) => {
    if (val === 1) {
      await this.setState({
        all: 1,
        allUnAssignedVehicles: [],
        selectedVehicle: [],
        allAssignVehiclesArra: [],
      });
      // await this.setState({allAssignVehiclesArra:[]})

      const resp = await this.getAllVehicleFunc(Number(1));
      await this.selectAllAssignVehicles(resp);

      await this.setState({ hideTblDta: true, selectedDevices: [] });
      await this.formRef.current.setFieldsValue({ deviceGroupId: [] });

      this.setState({ selectedDevices: [], assignAllSelectedGroup: true });
    } else if (val === 2) {
      this.handleRemoveAll();
      this.getAllVehicleFunc(Number(1));
      this.setState({
        all: 2,
        hideTblDta: true,
        assignAllSelectedGroup: false,
        selectedVehicle: [],
        allUnAssignedVehicles: [],
        allAssignVehiclesArra: [],
      });
      this.formRef.current.setFieldsValue({ deviceGroupId: [] });
    } else if (val === 3) {
      this.getAllVehicleFunc(Number(2));
      this.formRef.current.setFieldsValue({ deviceGroupId: [] });
      this.setState({
        all: 3,
        hideTblDta: false,
        assignAllSelectedGroup: true,
        allAssignVehiclesArra: [],
        selectedDevices: [],
      });
    } else if (val === 4) {
      this.getAllVehicleFunc(Number(2));
      this.setState({
        all: 4,
        assignAllSelectedGroup: false,
        allAssignVehiclesArra: [],
        selectedVehicle: [],
        assignVehicleSelectedGroupsBoolean: true,
        selectedDevices: [],
      });
    }
    this.setState({ update: 1 });
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Form
            style={{ maxWidth: "100%" }}
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onAssignVehicleToUser}
            {...this.layout}
            name="nest-message"
            className="formClass"
          >
            <Row className="overflowFormInputs">
              <Col span={24} className="formColumnClass">
                <Form.Item name="all" label="">
                  {/* <Checkbox
                    checked={this.state.all}
                    onChange={async (e) => {
                      await this.setState({
                        all: e.target.checked,
                      });
                      console.log(e.target.checked);
                      await this.selectAllAssignVehicles();
                    }}
                  >
                    Assign All Vehicles
                  </Checkbox> */}
                </Form.Item>
                <Form.Item name={"optionsForAssign"}>
                  <Select onSelect={(val) => this.selectOptionForAssign(val)}>
                    <Select.Option value={Number(1)}>
                      {/* Assign all vehicles of company */}
                      {this.context.translate(
                        "_ASSIGN_ALL_VEHICLES_OF_COMPANY_"
                      )}
                    </Select.Option>
                    <Select.Option value={Number(2)}>
                      {/* Assign vehicles of company(user selection) */}
                      {this.context.translate(
                        "_ASSIGN_VEHICLES_COMPANY_USERSELECTION_"
                      )}
                    </Select.Option>
                    <Select.Option value={Number(3)}>
                      {/* Assign all vehicles of selected groups */}
                      {this.context.translate(
                        "_ASSIGN_ALL_VEHICLES_SELECTEDGRP_"
                      )}
                    </Select.Option>
                    <Select.Option value={Number(4)}>
                      {/* Assign Vehicles of selected groups(user selection) */}
                      {this.context.translate(
                        "_ASSIGN_VEHCLES_SELECTEDGRP_USERSELECTION_"
                      )}
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="deviceGroupId"
                  // label="Device Group"
                  label={this.context.translate(
                    "_FLEET_GROUP_GROUPDETAIL_DEVICEGROUP_"
                  )}
                  className="search-field"
                >
                  <Select
                    disabled={this.state.hideTblDta}
                    mode="multiple"
                    // style={{maxHeight:"130px"}}
                    dropdownStyle={{ maxHeight: "130px", overflowY: "auto" }}
                    maxTagCount="responsive"
                    fieldNames={{ label: "groupname", value: "deviceGroupId" }}
                    // placeholder="Device Group"
                    placeholder={this.context.translate(
                      "_FLEET_GROUP_GROUPDETAIL_DEVICEGROUP_"
                    )}
                    optionFilterProp="children"
                    options={this.UsersStore?.getDeviceGroupsUsingCompanyId}
                    // onSelect={async (e) =>await this.UsersStore?.getVehiclesUsingCompanyId}
                    value={this.state.selectedDevices}
                    onChange={async (deviceGroupId) => {
                      await this.getGroupofVehicles(deviceGroupId);
                      await this.setState({ selectedDevices: deviceGroupId });

                      console.log(deviceGroupId);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name=""
                  // label="Search Vehicle"
                  label={this.context.translate("_SEARCH_VEHICLE_")}
                >
                  <Input
                    onChange={async (e) => {
                      await this.setState({ text: e.target.value });
                      await this.searchInput(e);
                      console.log(e.target.value);
                    }}
                  />
                </Form.Item>
                <br></br>
                <Row>
                  <Col span={11}>
                    <Table
                      // style={{height:300}}
                      scroll={{
                        x: 240,
                        y: 300,
                      }}
                      pagination={false}
                      bordered
                      rowKey="vehicleId"
                      columns={this.columns}
                      rowSelection={{
                        selectedRowKeys: this.state.selectedVehicle,
                        onChange: async (selectedRowKeys, selectedRows) => {
                          if (this.state.assignAllSelectedGroup) {
                            return;
                          }
                          if (this.state.text?.length > 0) {
                            let x = [
                              ...this.state.allAssignVehiclesArra,
                              ...selectedRows,
                            ];
                            //   console.log(x)
                            //  let arr=  [...this.state.allAssignVehiclesArra,...selectedRows].filter((element) => !this.state.allUnAssignedVehicles.includes(element));
                            //   console.log(arr)
                            let uniqueRecords = {};
                            let resultArray = [];

                            // Filter array to get unique records based on vehicleId
                            x.forEach((item) => {
                              if (!uniqueRecords[item.vehicleId]) {
                                uniqueRecords[item.vehicleId] = true;
                                resultArray.push(item);
                              }
                            });
                            console.log(resultArray);

                            await this.setState({
                              selectedVehicle: [
                                ...this.state.selectedVehicle,
                                ...selectedRowKeys,
                              ],
                              assignvehicles: selectedRowKeys,
                              selectedVehiclesData: selectedRows,
                              allAssignVehiclesArra: resultArray,
                            });
                          } else {
                            await this.setState({
                              selectedVehicle: selectedRowKeys,
                              assignvehicles: selectedRowKeys,
                              selectedVehiclesData: selectedRows,
                              allAssignVehiclesArra: selectedRows,
                            });
                          }

                          console.log(selectedRowKeys);
                        },
                        // selectedRowKeys: this.state.assignvehicles,
                      }}
                      dataSource={
                        this.state.allUnAssignedVehicles
                        // ? this.UsersStore?.searchVehicles
                        // : this.UsersStore?.allUnAssignedVehicles
                      }
                    />
                    {console.log(this.state.assignvehicles)}
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Table
                      scroll={{
                        x: 240,
                        y: 300,
                      }}
                      pagination={false}
                      bordered
                      rowSelection={{
                        selectedRowKeys: this.state.selectedRowKeys?.map(
                          (val) => {
                            return val.vehicleId;
                          }
                        ),
                        onChange: async (selectedRowKeys, selectedRows) => {
                          console.log(selectedRowKeys);
                          this.setState({ selectedRemove: selectedRowKeys });

                          // this.handleRemoveSelected(selectedRowKeys);
                        },
                      }}
                      total={this.UsersStore?.total}
                      rowKey="vehicleId"
                      columns={this.columns1}
                      dataSource={
                        this.state.allAssignVehiclesArra
                        // ? this.state.allAssignVehiclesArra
                        // : this.state.selectedVehiclesData
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className="formBtnGroup">
              <Button
                disabled={this.state.assignAllSelectedGroup}
                type="primary"
                className="formSaveButton"
                onClick={this.handleRemoveSelected}
              >
                {/* Remove */}
                {this.context.translate("_REMOVE_")}
              </Button>
              <Button
                disabled={this.state.assignAllSelectedGroup}
                type="primary"
                style={{ width: "113px" }}
                className="formSaveButton"
                onClick={this.handleRemoveAll}
              >
                {/* Remove All */}
                {this.context.translate("_REMOVE_ALL_")}
              </Button>

              <Button
                loading={this.state.loading}
                type="primary"
                htmlType="submit"
                className="formSaveButton"
              >
                {/* Save */}
                {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

AssignVehicleToAdvertise.contextType = StoreContext;
export default observer(withRouter(AssignVehicleToAdvertise));
