import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi,baseURL } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class VehiclePerformanceStore {
  vehiclePerformance=[]
  vehicleTotalPerformanceArray=[]
  totalReport = [];
  total=0;
  rootStore

  loading=false

  constructor(rootStore) {
    makeObservable(this, {
        vehiclePerformance: observable,
        vehicleTotalPerformanceArray: observable,
        total:observable,
        totalReport : observable,
      loadData:action,
      VehicleTotalPerformance:action,
      loading:observable

      
    });
     this.rootStore = rootStore;
    
  }


  async loadData({offset,limit,companyId,fromDate,toDate,vehicleId}) {
    this.loading=true
    console.log("vehiclePerformance")
    var postData = {"companyId":companyId,"vehicleId":vehicleId,"fromDate":fromDate,"toDate":toDate,"offset":offset,"limit":limit,"page":1}
   
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=vehiclePerformance", "postData="+base64Input);
  //  console.log("vehicleperformance",response.data.data.vehicleperformance)
    this.vehiclePerformance = (response?.data?.data?.vehicleperformance)
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
    
    
  }

  async VehicleTotalPerformance({offset,limit,companyId,fromDate,toDate,vehicleId}) {
    console.log("vehicletotalPerformance")
    var postData = {"companyId":companyId,"vehicleId":vehicleId,"fromDate":fromDate,"toDate":toDate}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=vehicleTotalperformance", "postData="+base64Input);   

    this.vehicleTotalPerformanceArray = (response?.data?.data)
    this.totalReport = parseInt(response?.data?.data?.TotalCount);

    this.loading=false;
    
    
  }
  

  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportvehiclePerformanceExcel&cid=" +
      getData.companyId +
      "&did=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportvehiclePerformancePdf&cid=" +
      getData.companyId +
      "&did=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportvehiclePerformanceCsv&cid=" +
      getData.companyId +
      "&did=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportxml(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportvehiclePerformanceXml&cid=" +
      getData.companyId +
      "&did=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
}

export default VehiclePerformanceStore;
