import React from "react";
import {
  Map,
  TileLayer,
  Marker,
  Popup,
  MapContainer,
  Polyline,
  FeatureGroup,
  ZoomControl,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";
import { Icon, divIcon, point } from "leaflet";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";
import { Component } from "react";
import L from "leaflet";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  DatePicker,
  Space,
  FloatButton,
  Popover,
  Menu,
  Radio,
  Drawer,
  Form,
  Table,
  Checkbox,
  Spin,
  message,
} from "antd";
import Draggable, { DraggableCore } from "react-draggable";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import moment from "moment/moment";
import { format, addMinutes } from "date-fns";
import CompanyAutocomplete from "./CompanyAutocomplete";
import LiveRootPathStore from "../store/LiveRootPathStore";
import { trace, autorun } from "mobx";
import config from "../config";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import {
  ArrowsMove,
  Pencil,
  Bezier2,
  CarFront,
  GlobeCentralSouthAsia,
} from "react-bootstrap-icons";
import { CaretRightOutlined, CloseOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import ListTable from "./ListTable";
import { FullscreenControl } from "react-leaflet-fullscreen";
import AdditionalContentInMap from "./AdditionalContentInMap";

const currentDate = moment().format("YYYY-MM-DD");
console.log(currentDate);
const startTime = new Date(2000, 0, 1, 0, 0); // 12:00 AM
const endTime = new Date(2000, 0, 1, 23, 30); // 11:45 P
const curreentDate = dayjs();

const timeArray = [];
for (let time = startTime; time <= endTime; time = addMinutes(time, 30)) {
  // const formattedTime = format(time, 'h:mm aa');
  const formattedTime = format(time, "HH:mm:ss");
  var ob = {
    formattedTime: formattedTime,
    time: time,
  };
  timeArray.push(ob);
}

class LiveRoutePath extends Component {
  constructor(prop) {
    super(prop);
    this.state = {
      companyId: null,
      VehicleId: null,
      offset: "",
      limit: "",
      loadList: 0,
      startDate: dayjs(),

      mapType: "google",
      showSlider: false,
      data: [],
      currentIndex: 0,
      polyLineData: [],
      showPolyLine: false,
      polylineArrayIndex: 0,
      fromTime: dayjs(),
      timeArray: [],
      liveRoutePathWindow: false,

      addressInfo: this?.props?.addressInfo,
      update: 0,
      type: "",
      key: new Date(),
      traffic: false,
      flag: true,
      mapBtn: false,
      satelliteBtn: false,
      spinLoading: false,
    };

    this.liveRootPathStore = new LiveRootPathStore(this);
    this.mapRef = React.createRef();
    this.formRef = React.createRef();
    this.groupRef = React.createRef();
    this.tableRef = React.createRef();
    this.mapOptions = [
      {
        label: "Google",
        value: "google",
      },
      {
        label: "Openstreet",
        value: "openstreet",
      },
    ];

    this.ShowClickBtn = this.ShowClickBtn.bind(this);
    this.setFromTime = this.setFromTime.bind(this);
    this.SelectedVehicle = this.SelectedVehicle.bind(this);
    this.VehicleList = this.VehicleList.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.closeBtnClick = this.closeBtnClick.bind(this);
    this.clearBtnClick = this.clearBtnClick.bind(this);
    this.handleMapTypeChange = this.handleMapTypeChange.bind(this);

    this.iconCreateFunction = (cluster) => {
      return L.divIcon({
        html: `<div>${cluster.getChildCount()}</div>`,
        className: "marker-cluster-custom",
        iconSize: L.point(40, 40),
      });
    };
  }

  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleSearch();
    }
    this.columns = [
      {
        // title: "Location",
        title: this.context.translate(
          "_FLEET_DASHBORD_HISTORY_TOOLTIP_LOCATION_"
        ),
        dataIndex: "formatted_address",
        width: "60%",
      },
      {
        // title: "Speed(Km)",
        title: this.context.translate("_SPEED_KM_"),
        dataIndex: "speed",
        width: "15%",
      },
      {
        // title: "Ignition",
        title: this.context.translate("_IGNITION_"),
        dataIndex: "ignition",
        width: "15%",
        render: (val) => {
          return "ON";
        },
      },
      {
        // title: "Date Time",
        title: this.context.translate("_DATE_TIME_"),
        dataIndex: "updatedate",
        width: "15%",
        render: (val) => {
          return dayjs(val).format("DD-MM-YYYY h:mm:ss A");
        },
      },
    ];
    if (this.liveRootPathStore?.totalCount > 0) {
      this.logValuesWithDelay();
    }

    this.setState({ update: 1 });
  }

  async componentDidUpdate() {
    if (this.state.showPolyLine) {
      await this.logValuesWithDelay();
    
      await this.setState({ showPolyLine: false });
    }
  }

  logValuesWithDelay = async () => {
    
    if (this.liveRootPathStore.checked) {
      let latlongs = await this.liveRootPathStore.offlineLatLongArray[
        this.liveRootPathStore.currentIndex
      ];

      this.liveRootPathStore.tableDataArray =
        this.liveRootPathStore.offlineLatLongArray?.slice(
          0,
          this.liveRootPathStore?.currentIndex
        );
      if (latlongs) {
        let polyLineArray = await this.liveRootPathStore.offlineShowingLatLong;
        await this.liveRootPathStore.offlineShowingLatLong?.push([
          latlongs.latitude,
          latlongs.longitude,
        ]);
        // if (polyLineArray.length > 2) {
        //   const bounds = [
        //     [
        //       polyLineArray[polyLineArray.length - 1][0],
        //       polyLineArray[polyLineArray.length - 1][1],
        //     ],
        //     [polyLineArray[0][0], polyLineArray[1][1]],
        //   ];
        //   this.mapRef.current.fitBounds(bounds,{ maxZoom: 16 });
        // }

        //    console.log(this.liveRootPathStore.currentIndex )
        //   if(this.state.currentIndex ===polyLineArray.length ){

        if (
          this.liveRootPathStore.offlineLatLongArray.length ===
          this.liveRootPathStore?.currentIndex
        ) {
          console.log("end loop");
          return "done";
        }

        if (this.liveRootPathStore.checked) {
          let timeout = setTimeout(async () => {
            // console.log(this.liveRootPathStore.offlineShowingLatLong,"sammekpont")
            L.polyline(this.liveRootPathStore.offlineShowingLatLong, 13).addTo(
              this.mapRef.current
            );

            let imgName = this.getColorMarker(latlongs);
            // console.log(imgName)
            const marker = L.marker([latlongs?.latitude, latlongs?.longitude], {
              icon: new Icon({
                iconUrl: require("../assets/images/BaloonIcons/" + imgName),
                iconSize: [32, 32], // size of the icon
              }),
            }).addTo(this.mapRef.current);
            this.mapRef.current.setView(
              [latlongs?.latitude, latlongs?.longitude],
              15
            );
            //   this.setState({ currentIndex: this.state.currentIndex + 1 });
            this.liveRootPathStore.currentIndex =
              this.liveRootPathStore.currentIndex + 1;
            this.setState({ currentIndex: this.state.currentIndex + 1 });
            // console.log('currentIndex***',this.liveRootPathStore.currentIndex);
           
            clearTimeout(timeout);
            await this.logValuesWithDelay();
          }, 1000);
        }
      } else {
        // this.setState({ currentIndex: this.state.currentIndex + 1 }, );
        this.liveRootPathStore.currentIndex =
          this.liveRootPathStore.currentIndex + 1;

        // this.logValuesWithDelay();
      }
    } else {
      this.liveRootPathStore.offlineShowingLatLong = [];

      this.mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          this.mapRef.current.removeLayer(layer);
        }
      });
    }
  };

  //  logValuesWithDelay = async () => {
  //     let latlongs = await this.liveRootPathStore.offlineLatLongArray;

  //   if ( this.liveRootPathStore.currentIndex < latlongs.length) {
  //     this.timeoutId = setTimeout(() => {
  //       this.liveRootPathStore.currentIndex =  this.liveRootPathStore.currentIndex+1

  //       let positions = latlongs.slice(0,  this.liveRootPathStore.currentIndex + 1)
  //       if(positions.length>2){
  //         L.polyline(latlongs.slice(0,  this.liveRootPathStore.currentIndex + 1), 15).addTo(
  //           this.mapRef.current
  //         );

  //         const marker = L.marker(positions[positions.length==1? positions[positions.length]: positions.length-1], {
  //           icon: new Icon({
  //             iconUrl: require("../assets/images/BaloonIcons/Green-north.png"),
  //             iconSize: [20, 20], // size of the icon
  //           }),
  //         }).addTo(this.mapRef.current);
  //       }

  //       this.logValuesWithDelay();
  //     }, 1000);
  //   }

  //  }

  async handleChange(comp) {
    console.log(comp.value, "chbnge");
    await this.setState({ companyId: comp.value });
    this.formRef.current.setFieldsValue({ vehicleId: "" });
    await this.handleSearch();
  }

  async handleSearch() {
    await this.liveRootPathStore.loadData({
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }
  toggleDrawer = () => {
    this.setState({ showSlider: !this.state.showSlider });
  };
  async handleMapTypeChange(comp) {
    await this.setState({ mapType: comp.target.value, listUpdated: 1 });
  }
  async setFromTime(value) {
    await this.setState({
      // date: value/
      fromTime: value,
    });
  }

  async SelectedVehicle(a) {
    await this.setState({
      // date: value/
      VehicleId: a,
    });
    // this.liveRootPathStore.setSelectesVehicle(a);
  }

  async VehicleList() {
    return this.liveRootPathStore?.vehicleList?.map((data) => {
      return (
        <Select.Option key={data} value={data}>
          {data}
        </Select.Option>
      );
    });
  }

  async handleDateChange(date) {
    this.setState({
      startDate: date,
    });
  }

  async ShowClickBtn(values) {
    this.liveRootPathStore.offlineShowingLatLong = [];
    this.liveRootPathStore.liveLatLongArray = [];
    this.liveRootPathStore.polylineArrayObject = {};
    this.liveRootPathStore.totalCount = null;
    await this.setState({ spinLoading: true });
    this.liveRootPathStore.checked = true;
    var fromDate = dayjs(values.fromDate).format("YYYY-MM-DD");
    var toDate = dayjs().format("YYYY-MM-DD");
    var fromTime = values.fromTime;
    var toTime = dayjs().format("HH:mm:ss");
    // console.log(this.state.startDate)
    var data = {
      companyId: Number(this.state.companyId),
      vehicleId: Number(this.state.VehicleId),
      fromDate: fromDate + " " + fromTime,
      // fromDate: "2023-07-12 11:45:09",
      toDate: toDate + " " + toTime,
      SelRoutOption: 1,
      TripEventdata: 1,
      tableName: "mt90",
      limit: 10,
    };
    console.log(data);
    if (data.SelRoutOption == 1) {
      const response = await this.liveRootPathStore.setOfflineData(data);
      console.log(this.liveRootPathStore.totalCount);
      if (Number(this.liveRootPathStore.totalCount) > 0) {
        await this.setState({ showPolyLine: true });
      } else {
        message.warning({ content: "Vehicle Data Not Available" });
      }
    }
    await this.setState({ spinLoading: false });
    await this.setState({ loadList: 1 });
  }

  async closeBtnClick() {
    await this.setState({ loadList: 1 });
    this.setState({
      data: [],
      position: [],
    });
    this.liveRootPathStore.setClearInterval();
    this.liveRootPathStore.setSelectesVehicle(null);
    this.liveRootPathStore.setCallOffline(false);
  }

  clearBtnClick() {
    // this.setState({
    //   data: [],
    //   position: [],
    //   fromTime: null,
    //   startDate: null,
    //   companyId: null,
    //   VehicleId: null,
    // });
    // this.liveRootPathStore.setClearInterval();
    // this.liveRootPathStore.setSelectesVehicle(null);
    // this.liveRootPathStore.setCallOffline(false);
    // this.liveRootPathStore.setVehicleList(null);
    // this.liveRootPathStore.offlineShowingLatLong = [];
    this.liveRootPathStore.checked = false;
    // this.formRef.current?.resetFields();
    if (this.liveRootPathStore.offlineShowingLatLong.length > 0) {
      this.liveRootPathStore.offlineShowingLatLong = [];
      this.mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          this.mapRef.current.removeLayer(layer);
        }
      });
      this.mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Polyline) {
          this.mapRef.current.removeLayer(layer);
        }
      });
    }
    this.logValuesWithDelay();
    this.liveRootPathStore.tableDataArray = [];
    this.liveRootPathStore.offlineShowingLatLong = [];
    this.liveRootPathStore.offlineLatLongArray = [];
    this.liveRootPathStore.currentIndex = 0;
    this.setState({ update: 1 });
    let store = this.liveRootPathStore;
    store.totalCount = null;
    store.offlineData = [];
    store.pgcount = 1;
    store.offset = 0;
    // offlineLatLongArray
  }

  getColorMarker = (v) => {
    var acc = v.acc;
    var idle = v.idleStatus;
    var Direction;
    if (v.direction != null) {
      Direction = v.direction;
    } else {
      Direction = 60;
    }
    var colorMarkr;
    if (idle == 1 && acc == 1) {
      if (Direction >= 337 && Direction < 23) {
        colorMarkr = "Yellow-north.png";
      } else if (Direction >= 23 && Direction < 68) {
        colorMarkr = "Yellow-north-east.png";
      } else if (Direction >= 68 && Direction < 113) {
        colorMarkr = "Yellow-east.png";
      } else if (Direction >= 113 && Direction < 158) {
        colorMarkr = "Yellow-south-east.png";
      } else if (Direction >= 158 && Direction < 203) {
        colorMarkr = "Yellow-south.png";
      } else if (Direction >= 203 && Direction < 248) {
        colorMarkr = "Yellow-south-west.png";
      } else if (Direction >= 248 && Direction < 293) {
        colorMarkr = "Yellow-west.png";
      } else if (Direction >= 293 && Direction < 337) {
        colorMarkr = "Yellow-north-west.png";
      } else {
        colorMarkr = "Yellow-north.png";
      }
    } else if (acc == 1 && v.speed == 0) {
      if (Direction >= 337 && Direction < 23) {
        colorMarkr = "Yellow-north.png";
      } else if (Direction >= 23 && Direction < 68) {
        colorMarkr = "Yellow-north-east.png";
      } else if (Direction >= 68 && Direction < 113) {
        colorMarkr = "Yellow-east.png";
      } else if (Direction >= 113 && Direction < 158) {
        colorMarkr = "Yellow-south-east.png";
      } else if (Direction >= 158 && Direction < 203) {
        colorMarkr = "Yellow-south.png";
      } else if (Direction >= 203 && Direction < 248) {
        colorMarkr = "Yellow-south-west.png";
      } else if (Direction >= 248 && Direction < 293) {
        colorMarkr = "Yellow-west.png";
      } else if (Direction >= 293 && Direction < 337) {
        colorMarkr = "Yellow-north-west.png";
      } else {
        colorMarkr = "Yellow-north.png";
      }
    } else if (acc == 1 && v.speed > 0) {
      if (Direction >= 337 && Direction < 23) {
        colorMarkr = "Green-north.png";
      } else if (Direction >= 23 && Direction < 68) {
        colorMarkr = "Green-north-east.png";
      } else if (Direction >= 68 && Direction < 113) {
        colorMarkr = "Green-east.png";
      } else if (Direction >= 113 && Direction < 158) {
        colorMarkr = "Green-south-east.png";
      } else if (Direction >= 158 && Direction < 203) {
        colorMarkr = "Green-south.png";
      } else if (Direction >= 203 && Direction < 248) {
        colorMarkr = "Green-south-west.png";
      } else if (Direction >= 248 && Direction < 293) {
        colorMarkr = "Green-west.png";
      } else if (Direction >= 293 && Direction < 337) {
        colorMarkr = "Green-north-west.png";
      } else {
        colorMarkr = "Green-north.png";
      }
    } else if (acc == 0) {
      if (Direction >= 337 && Direction < 23) {
        colorMarkr = "Red-north.png";
      } else if (Direction >= 23 && Direction < 68) {
        colorMarkr = "Red-north-east.png";
      } else if (Direction >= 68 && Direction < 113) {
        colorMarkr = "Red-east.png";
      } else if (Direction >= 113 && Direction < 158) {
        colorMarkr = "Red-south-east.png";
      } else if (Direction >= 158 && Direction < 203) {
        colorMarkr = "Red-south.png";
      } else if (Direction >= 203 && Direction < 248) {
        colorMarkr = "Red-south-west.png";
      } else if (Direction >= 248 && Direction < 293) {
        colorMarkr = "Red-west.png";
      } else if (Direction >= 293 && Direction < 337) {
        colorMarkr = "Red-north-west.png";
      } else {
        colorMarkr = "Red-north.png";
      }
    }
    return colorMarkr;
  };
  handleBtnFunc = (state) => {
    this.setState({ [state]: true });
    setTimeout(() => {
      this.setState({ [state]: false });
    }, 1000);
  };
  render() {
    return (
      <div>
        {!this.state.showSlider && (
          <FloatButton.Group
           // className="right-panel-btn-group-old"
            shape="square"
            style={{
              zIndex: 9999,
              top: "8%",
              insetInlineEnd: "1.2%",
              bottom: 847,
            }}
          >
            <FloatButton
              icon={<CarFront />}
              onClick={() => this.setState({ liveRoutePathWindow: true })}
            />
            <Popover
              content={
                <Radio.Group
                  options={this.mapOptions}
                  onChange={this.handleMapTypeChange}
                  value={this.state.mapType}
                  optionType="button"
                  buttonStyle="solid"
                />
              }
              placement="left"

              //   onOpenChange={handleOpenChange}
            >
              <FloatButton icon={<GlobeCentralSouthAsia />} />
            </Popover>
          </FloatButton.Group>
        )}
        <div
          dir={this.context.locale.language === "arabic" ? "ltr" : "ltr"}
          className={
            this.context.locale.language === "arabic"
              ? "arabic-map-container"
              : ""
          }
        >
          <MapContainer
            ref={this.mapRef}
            zoom={config.mapZoom}
            minZoom={3}
            center={config.mapCenter}
            style={{ position: "sticky", height: "68vh", width: "100wh" }}
            bounds={
              this.state.position?.length > 0
                ? [
                    [
                      this.state.position[0]?.latitude,
                      this.state.position[0]?.longitude,
                    ],
                    [
                      this.state.position[0]?.latitude,
                      this.state.position[0]?.longitude,
                    ],
                  ]
                : []
            }
            zoomControl={false}
          >
            <ZoomControl position="bottomright"></ZoomControl>
            {this.state.mapType === "google" &&
            (this.state.type || this.state.traffic) ? (
              <ReactLeafletGoogleLayer
                key={this.state.type ? this.state.type : ""}
                type={this.state.type ? this.state.type : "roadmap"}
                googleMapsAddLayers={
                  this.state.traffic ? [{ name: "TrafficLayer" }] : null
                }
              />
            ) : (
              <ReactLeafletGoogleLayer
                googleMapsAddLayers={
                  this.state.traffic ? [{ name: "TrafficLayer" }] : null
                }
              />
            )}
            {this.state.mapType == "openstreet" && (
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="Map data © OpenStreetMap contributors"
              />
            )}

            <AdditionalContentInMap size="small" This={this} />
          </MapContainer>
        </div>

        <Table
          bordered
          pagination={false} // Adjust page size as needed
          scroll={{ y: 180 }} // Set the desired height and enable vertical scrolling
          columns={this.columns}
          dataSource={this.liveRootPathStore.tableDataArray}
        />
        {this.state.liveRoutePathWindow && (
          <Draggable handle=".handle">
            <Card
              className="live-routepath-modal"
              title={
                <div
                  className="handle"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* <div> Live Route Path</div> */}
                  <div>{this.context.translate("_LIVE_ROUTE_PATH_")}</div>
                  <CloseOutlined
                    onClick={() => {
                      this.setState({ liveRoutePathWindow: false });
                      this.clearBtnClick();
                    }}
                  />
                </div>
              }
              // title={
              //   <>
              //     <CaretRightOutlined
              //       onClick={this.toggleDrawer}
              //       style={{
              //         fontSize: "24px",
              //         cursor: "pointer",
              //         marginRight: "8px",
              //       }}
              //     />
              //     Live Route Path
              //   </>
              // }
              // placement="right"
              // closable={false}
              // onClose={this.toggleDrawer}
              // visible={this.state.showSlider}
              //   style={{height:"53%",boxShadow:"none"}}
            >
              <Spin spinning={this.state.spinLoading}>
                <Form
                  ref={this.formRef}
                  layout="vertical"
                  name="nest-messages"
                  onFinish={this.ShowClickBtn}
                  // initialValues={{fromDate:curreentDate}}
                >
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      // label={"Company"}
                      label={this.context.translate(
                        "_FLEET_DASHBORD_NEWPOI_COMPANY_"
                      )}
                      className="blur-input"
                      rules={[{ required: true }]}
                      style={{ marginBottom: "2px", marginTop: "2px" }}
                    >
                      <CompanyAutocomplete
                        onChange={(val) => this.handleChange(val)}
                        value={this.state.companyId}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="vehicleId"
                    className="blur-input"
                    // label="Vehicle"
                    label={this.context.translate("_VEHICLES_")}
                    rules={[{ required: true }]}
                    style={{ marginBottom: "2px", marginTop: "2px" }}
                  >
                    <Select
                      // placeholder="Select Vehicle"
                      placeholder={this.context.translate("_SELECT_VEHICLE_")}
                      optionFilterProp="children"
                      onChange={this.SelectedVehicle}
                      showSearch
                      value={this.state.VehicleId}
                      // allowClear={true}
                      // onClear={this.handleClear}
                      filterOption={(inputValue, option) => {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {this.liveRootPathStore.vehicleList.map((v) => {
                        return (
                          <Select.Option value={v.vehicleId}>
                            {v.vehicleNo}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name="fromDate"
                        className="blur-input"
                        // label="From Date"
                        label={this.context.translate("_FROM_DATE_")}
                        initialValue={dayjs()}
                        style={{ marginInlineEnd: "10px" }}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format={"YYYY-MM-DD"}
                          disabledDate={(current) => {
                            return current && current > dayjs();
                          }}
                          //   defaultValue={this.state.startDate}

                          // onChange={(date) =>
                          //   this.formRef.current.setFieldsValue({ fromTime: date })
                          // }
                          // value={this.state.startDate}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="fromTime"
                        // label="From Time"
                        label={this.context.translate(
                          "_FLEET_RULESELECTORDETAIL_RULE4_FROMTIME_"
                        )}
                        className="blur-input"
                        rules={[{ required: true }]}
                        style={{ marginInlineStart: "10px" }}
                        initialValue={"00:00:00"}
                      >
                        <Select
                          onChange={(val) =>
                            this.formRef.current.setFieldsValue({
                              fromTime: val,
                            })
                          }
                          allowClear={true}
                        >
                          {timeArray.map((data) => {
                            return (
                              <Select.Option
                                key={data.formattedTime}
                                value={data.formattedTime}
                              >
                                {format(data.time, "h:mm aa")}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <div className="formBtnGroup">
                    <Button
                      className="formCancelButton"
                      onClick={async (e) => {
                        this.clearBtnClick();
                      }}
                    >
                      {/* Clear */}
                      {this.context.translate("_FLEET_CLEAR_BTN_")}
                    </Button>
                    <Button
                      className="formSaveButton"
                      type="primary"
                      htmlType="submit"
                    >
                      {/* Show */}
                      {this.context.translate("_FLEET_SHOW_BTN_")}
                    </Button>
                  </div>

                  {/*           
            <Row>
              <Space>
              
                <Button onClick={this.clearBtnClick}>Clear</Button>
              </Space>
            </Row> */}
                </Form>
              </Spin>
            </Card>
          </Draggable>
        )}
      </div>
    );
  }
}

LiveRoutePath.contextType = StoreContext;
export default observer(withRouter(LiveRoutePath));
