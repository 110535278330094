import React, { useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table,
  Button,
  Typography,
  Space,
  Row,
  Col,
  message,
  Tooltip,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import {
  DeleteFilled,
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  ExportOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import config from "../config";
import NotificationStore from "../store/NotificationStore";
import withRouter from "../withRouter";
import AddButton from "./AddButton";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import DeleteConfirm from "./DeleteConfirm";
import CompanyStore from "../store/CompanyStore";
import NotificationAdd from "./NotificationAdd";
class NotificationList extends React.Component {
  constructor(props) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.notificationStore = new NotificationStore();
    this.companyStore = new CompanyStore();
    this.onDelete = this.onDelete.bind(this);
    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      companyVal_Lab: "",
      notificationId: "",
      listDataUpdate: false,
      openNotificationAddForm: false,
      companyId: "",
    };
  }

  async componentDidMount() {
    // console.log(this.context.viewStateStore.value, "vale");
    console.log(this.props.getUserData().userCompanyId)
    // const data=JSON.parse(base64_decode(this.cookies.get("data")))
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId)
      await this.setState({ companyId: this.props.getUserData().userCompanyId })
      await this.setState({ userId: this.props.getUserData().userId })
      await this.handleSearch();
    }
    // const setNotificationStates = this.context.viewStateStore.notificationListObject
    // console.log(setNotificationStates)
    // await this.notificationStore.loadData({
    //   offset: (this.state.currentPage - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,

    //   companyId: setNotificationStates.companyId,
    // });
    // const singleData = await this.companyStore.getCompanyDataById(setNotificationStates.companyId);

    // await this.setState({ loadList: 1 });
    // console.log("singleData", singleData)
    // await this.setState({ companyVal_Lab: singleData, companyId: setNotificationStates.companyId })
    // console.log("singleData", singleData)
    this.setState({ pageSize: 50 });
    // await this.notificationStore.loadData({ offset: 0, limit: 50 });

    this.columns = [
      {
        // title: "Date Time ",
        title: this.context.translate("_DATE_TIME_"),
        dataIndex: "postedAt",
        width: "35%",
        render: (value, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        // title: "Notification",
        title: this.context.translate("_NOTIFICATION_"),
        dataIndex: "notification",
        width: "30%",
      },
      {
        // title: "Status",
        title: this.context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "sendNow",
        width: "30%",
        render: (value) => {
          if (value == 0) {
            return "Pending";
          } else if (value == 1) {
            return "Sent";
          }
        },
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        width: "5%",
        // fixed : 'right',
        // width: 50,
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    // title="Edit Notification"
                    title={this.context.translate("_EDIT_NOTIFICATION_")}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      //  title="Delete Notification"
                      title={this.context.translate("_DELETE_NOTIFICATION_")}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  async componentDidUpdate(prevProps, prevState) {
    if(Number(this.props.getUserData().isadmin)===1){
      // console.log('innnnnn');
        this.props.navigate("/login");
       
     }

    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const setNotificationStates =
        this.context.viewStateStore.notificationListObject;
      console.log(setNotificationStates);

      if (setNotificationStates.companyId !== "") {
      await this.notificationStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,

        companyId: setNotificationStates.companyId,
      });

      console.log(setNotificationStates.companyId);
      const singleData = await this.companyStore.getCompanyDataById(
        setNotificationStates.companyId
      );

      await this.setState({ loadList: 1 });
      console.log("singleData", singleData);
      await this.setState({
        companyVal_Lab: singleData,
        companyId: setNotificationStates.companyId,
      });
      console.log("singleData", singleData);
      await this.setState({ listDataUpdate: false });
    }
  }
  }
  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination, "pagi");
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.notificationStore.loadData({
      companyId: this.state.companyId,
      offset: this.state.currentPage - 1,
      limit: this.state.pageSize,
    });
    await this.setState({ loadList: 1 });
  }

  handleChange = async (comp) => {
    console.log(comp.value);
    await this.setState({
      companyId: comp.value,
      currentPage: 1,
      pageSize: 50,
    });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ companyVal_Lab: singleData, companyId: comp.value });
    // await this.setState({ companyId: comp?.value });
    await this.handleSearch();
  };

  onEdit = async (record) => {
    console.log(record.notification_Id, record, "drfghjkiuhy");
    const notificationId = record.notification_Id;
    // if(notificationId){
    //   await this.context.viewStateStore.setNotificationStates({
    //     companyId: record.companyId,
    //   });

    // }
    if (notificationId) {
      this.context.viewStateStore.setNotificationStates({
        companyId: record.companyId,
      });
      // this.props.navigate("/notificationadd/" + notificationId);
      await this.setState({ notificationId: notificationId });
      await this.setState({ openNotificationAddForm: true });
    }
  };

  async onDelete(record) {
    console.log(record.notification_Id, "record");
    const response = await this.notificationStore.deleteRecord({
      notification_Id: record.notification_Id,
      companyId: this.state.companyId,
    });
    //  console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.success == "S") {
      message.success(resp.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openNotificationAddForm: false });

      //  await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    }
    // else {
    //   message.error(resp.message);
    // }
    else if (resp?.data?.success == "F0") {
      resp.data.errors.map((value) => {
        message.error(value["inventoryNo"]);
      });
    }
  };

  closeModal=()=>{
    this.setState({
      openNotificationAddForm: false,
      notificationId: "",
    });
  }
  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Notification List</div> */}
              <div className="heading">
                {this.context.translate("_NOTIFICATION_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  title={this.context.translate("_ADD_")}
                  link="/notificationadd"
                /> */}
                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  color={config.primaryColor}
                  placement="left"
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({
                        openNotificationAddForm: true,
                        notificationId: "",
                      })
                    }
                  ></Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  // allowClear={true}
                  style={{ width: 200 }}
                  value={
                    this.state.companyVal_Lab ? this.state.companyVal_Lab : null
                  }
                />
              </Space>
            </Col>
            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.notificationStore?.total}
            columns={this.columns}
            dataSource={this.notificationStore?.notification}
            loading={this.notificationStore?.loading}
            onChange={this.handleTableChange}
          />

          {this.state.openNotificationAddForm && (
            <Modal
              bodyStyle={{ height: "645px" }}
              className="formShowModal" //  {/* css class */}
              // title="Add Notification"
              title={this.context.translate("_ADD_NOTIFICATION_")}
              centered
              visible={this.state.openNotificationAddForm}
              footer={null}
              onCancel={() => {
                this.setState({
                  openNotificationAddForm: false,
                  notificationId: "",
                });
              }}
              width={665}
            >
              <div>
                <NotificationAdd
                  notificationId={this.state.notificationId}
                  response={this.responseMsg}
                  closeModal={this.closeModal}
                />
              </div>
            </Modal>
          )}
        </div>
      </>
    );
  }
}
NotificationList.contextType = StoreContext;
export default observer(withRouter(NotificationList));
