import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import config from "../config";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
  observe,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class WarehouseMonitoringStore {
    CompanyWiseWarehouseData=[]
  warehouseData=[]
  loading=false
 
  constructor(rootStore) {
    makeObservable(this, {
        warehouseData:observable,
        CompanyWiseWarehouseData:observable,
        loadData : action,
        getWarehouseByCompany:action,
        exportExcel:action,
        exportPdf:action,
    });
     this.rootStore = rootStore;
    
  }

  async loadData(data){
    // this.loading=true;
    var postData=data
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=warehouse&a=warehouseMonitoring", "postData="+base64Input);
    this.warehouseData = response.data?.data?.warehouseData
 console.log(response.data.data?.warehouseData)
 this.total = Number(response.data?.data?.TotalCount
    )
    //  this.loading=false;
  }

  async getWarehouseByCompany(data){
    var postData=data
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=warehouse&a=getWarehouseByCompany", "postData="+base64Input);
    this.CompanyWiseWarehouseData= response.data?.data
    // console.log(response.data?.data)
    
  }

  async exportExcel(getData){
    // window.location.href = config.baseURL + "index.php?c=vehicle&a=exportloginexcel&cid=" +getDta.companyId + '&fD=' + getDta.fromDate + '&tD=' + getDta.toDate + '&uId=' + getDta.userid + '&colList=' + getDta.colList
    window.location.href = config.baseURL + "index.php?c=warehouse&a=exportWarehouseMonitoringExcel&cid="+ getData.cid+"&warehouseId="+ getData.warehouseId+"&colList=companyName,warehouse,licenseNo,inventory1,inventory2,inventory3,inventory4,address"
  
  }
  async exportPdf(getData){
    // window.location.href = config.baseURL + "index.php?c=vehicle&a=exportloginexcel&cid=" +getDta.companyId + '&fD=' + getDta.fromDate + '&tD=' + getDta.toDate + '&uId=' + getDta.userid + '&colList=' + getDta.colList
   // window.location.href = config.baseURL + "index.php?c=company&a=exportCompanyPdf&dType=" +getData.dType+ '&isadmin='+getData.isadmin+ '&cid='+getData.cid+ '&cType='+getData.cType +'&cName='+getData.cName + '&email='+getData.email+ '&colList='+getData.colList
     window.location.href = config.baseURL + "index.php?c=warehouse&a=exportWarehouseMonitoringPdf&cid="+ getData.cid+"&warehouseId="+ getData.warehouseId+"&colList=companyName,warehouse,licenseNo,inventory1,inventory2,inventory3,inventory4,address"
  }
  
}


export default WarehouseMonitoringStore;
