import { decode as base64_decode, encode as base64_encode } from "base-64";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import conf from "../config";
class WASLVehicleStore {
  WASLVehicleArray = [];
  getVehiclesUsingCompanyId = [];
  CompanyIdentityNumbers=[];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      WASLVehicleArray: observable,
      CompanyIdentityNumbers:observable,
      total: observable,
      loading: observable,
      loadData: action,
      registerVehicle: action,
      getVehiclesByCompanyId: action,
      getVehicleInfo: action,
      getCompanyIdentityNo:action
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit, vehicleId, sequenceNo,UcompanyId}) {
    console.log(UcompanyId,"UcompanyId")
    this.loading = true;
    // console.log(cname,offset, limit,identityNo,crNo)

    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      sequenceNo: sequenceNo,
      offset: offset,
      limit: limit,
      UcompanyId:UcompanyId
    };

    var base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=wasl&a=vehicleList", "postData=" + base64Input);

    console.log("company Data", response?.data?.data?.company);
    this.WASLVehicleArray = response?.data?.data?.company;
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async registerVehicle(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=wasl&a=registerVehicle", "postData=" + base64Input);
    console.log(response);

    
    return response;
  }

  async getVehiclesByCompanyId({ companyId }) {
    console.log(companyId);
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);
    console.log(response, "post");
    this.getVehiclesUsingCompanyId = response?.data?.data;
    console.log(response?.data?.data);
    // return response?.data?.data
  }

  async getVehicleInfo(vehicleId) {
    console.log(vehicleId);
    var postData = { vehicleId: vehicleId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=wasl&a=getVehicleInfo", "postData=" + base64Input);
    console.log(response);

    this.total = parseInt(response?.data?.data?.TotalCount);
    return response.data.data;
  }

  async exportExcel(getData) {
    console.log(getData,"sdfgh"
      )
    let lang = "english";
    window.location.href =
      conf.baseURL +
      "index.php?c=wasl&a=exportwaslvehicleexcel&cid=" +
      getData.companyId +
      "&UcompanyId=" +
      getData.UcompanyId +
      "&vid=" +
      getData.vehicleId +
      "&sno=" +
      getData.sequenceNo +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async getCompanyIdentityNo(companyId) {
    var postData = {"companyId":companyId}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=company&a=getIdentityNo", "postData=" + base64Input);
   
      console.log(response.data.data);

    // this.CompanyIdentityNumbers = parseInt(response?.data?.data);
    this.CompanyIdentityNumbers = response?.data?.data;
    return response;
  }

  //   async deleteRecord({Id,companyId}){
  //     console.log(Id,companyId)

  //     var postData = {"waslId":Id,"companyId":companyId,"waslKey":null,"userId":1}
  //     console.log(postData);
  //     const base64Input = await base64_encode(JSON.stringify(postData));
  //     var response = await postApi.getClient().post("index.php?c=wasl&a=deleteCompany", "postData="+base64Input);
  //     return response;
  //   }

  //   async getWASLCompanyStatus({Id}){
  //     console.log(Id)

  //     var postData = {"Id":Id,"waslKey":null}
  //     console.log(postData);
  //     const base64Input = await base64_encode(JSON.stringify(postData));
  //     var response = await postApi.getClient().post("index.php?c=wasl&a=getwaslcompanystatus", "postData="+base64Input);
  //     this.WASLCompanyStatus = response.data.errors[0][0]
  //     // return response;
  //   }
}

export default WASLVehicleStore;
