import { encode as base64_encode } from "base-64";

import { postApi,baseURL } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class DeliveryDashboardStore {
  loading = false;
  total = 0;
  dashboardDataCounts = [];
  dashboardTableData = [];
  dashboardDeliveryTblData=[]
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      total: observable,
      dashboardDataCounts: observable,
      dashboardTableData: observable,
      dashboardDeliveryTblData:observable,
      loadData: action,
      loadDeliveryLogData:action
    });
    this.rootStore = rootStore;
  }

  async loadData({
    companyId,
    fromDate,
    toDate,
    filter,
    datefilter,
    statusFilter,
    invoiceDate,
  }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      fromDate: fromDate,
      toDate: toDate,
      filter: filter,
      datefilter: datefilter,
      statusFilter: statusFilter,
      invoiceDate: invoiceDate,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=delivery&a=getDeliveryDashboardData",
        "postData=" + base64Input
      );

    this.dashboardTableData = response?.data?.data?.data;

    this.dashboardDataCounts = response?.data?.data?.deliveryStatusCounters[0];

    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
  }

  async exportExcel(getData) {
  
console.log(getData.colList)
    let lang = "english";

    // if (getData.isCustomer === "1") {
      // var postData = {
      //   companyId: getData.companyId,
      //   userId: getData.userId,
      //   isCustomer: getData.isCustomer,
      //   mobileno: getData.mobileno,
      //   filter: getData.filter,
      //   datefilter: getData.datefilter,
      //   statusFilter: getData.statusFilter,
      //   fromDate: getData.fromDate,
      //   toDate: getData.toDate,
      //   colList: getData.colList,
      //   language: lang,
      // };
    // } 
    // else {
      var postData = {
        companyId: getData.companyId,
        filter: getData.filter,
        datefilter: getData.datefilter,
        statusFilter: getData.statusFilter,
        fromDate: getData.fromDate,
        toDate: getData.toDate,
        colList: getData.colList,
        language: lang,
      };
    // }

    const newWindow1 = window.open("", "_blank");
    const form = newWindow1.document.createElement("form");
    form.action = baseURL + "index.php?c=delivery&a=exportDeliveryExcel";
    form.method = "POST";
    const dataInput = newWindow1.document.createElement("input");
    dataInput.type = "hidden";
    dataInput.name = "postData";
    dataInput.value = base64_encode(JSON.stringify(postData));
    form.appendChild(dataInput);
    newWindow1.document.body.appendChild(form);
    form.submit();
    setTimeout(() => {
      newWindow1.close();
    }, 3000);
  }

  async loadDeliveryLogData({
    jobNo
  }) {
    this.loading = true;
    var postData = {"jobNo":jobNo}

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=delivery&a=deliveryLogReport",
        "postData=" + base64Input
      );

    this.dashboardDeliveryTblData = [response?.data?.data];

   

    this.loading = false;
  }
}

export default DeliveryDashboardStore;
