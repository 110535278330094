import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
  observe,
} from 'mobx';
import { act } from 'react-dom/test-utils';
import conf from '../config';

class StaffStore {

  staff=[]
  vehicleList=[]
  // department = []
  total=0
  loading=false
  photoFromDb={}
  rootStore
  constructor(rootStore) {
    makeObservable(this, {
      vehicleList:observable,
        staff:observable,
        // department:observable,
        total:observable,
        loading:observable,
        photoFromDb:observable,
        loadData:action,
        getStaffData:action,
        addData:action,
        deleteRecord:action,
        setPhotoFromDb:action,
        getVehicleNo:action
     
    });
     this.rootStore = rootStore;
    
  }

  async loadData({offset,limit,companyId,salesPersonName,isadmin}) {
    this.loading = true;
   
    var postData = {"companyId":companyId,"salesPersonName":salesPersonName,"offset":offset,"limit":limit,"page":1,isadmin:isadmin}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=salesperson&a=getPersonsDetails", "postData="+base64Input);
    
    this.staff = response?.data?.data?.SalesPersonsData
    // //  this.setCompanies(response?.data?.data?.companydata);
    //  this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }

  async addData(staff){
    var postData = {"tokenId":"80bovn8u800tg1aq9bvr73kbhh","person":staff}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=salesperson&a=update", "postData="+base64Input);
    return response.data
  }

  async setPhotoFromDb(photo){
    console.log(photo)
    this.photoFromDb = {fileList :photo}
  }
  async getStaffData(staffId){
    var postData = {"sid":staffId,offset:0,limit:1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=salesperson&a=getPersonsDetails", "postData="+base64Input);

    console.log("response",response)
    // return response.data.data.SalesPersonsData[0]
    const singleRow = response.data.data.SalesPersonsData[0]
    
    if(singleRow.photo){
        
      const fileList = [{
          name: singleRow.photo,
          status: 'success',
          url: conf.fleetURL+'/images/salesperson/'+singleRow.photo,
      }]
      console.log(fileList);
      await  this.setPhotoFromDb(fileList)
      // this.photoFromDb = {fileList :fileList}
      // console.log(this.photoFromDb)
          
    }
    return singleRow

  }

  async deleteRecord({id}){
  const postData = {"id":id}
  const base64Input = await base64_encode(JSON.stringify(postData));
  var response = await postApi.getClient().post("index.php?c=salesperson&a=delete", "postData="+base64Input);
  console.log("response",response)
  return response
  }

  async getEmployeeType(){
    var postData = null
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=salesperson&a=getEmployeeType", "postData="+base64Input);
    console.log(response.data.data,"type")
    return response?.data?.data
  }

  async getDepartment(){
    var postData = null
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=salesperson&a=getDepartment", "postData="+base64Input);
    console.log(response.data.data,"deprt")
    return response?.data?.data
  }

  async addDepartment(departmentDetails){
    var postData = departmentDetails
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=salesperson&a=addDepartment", "postData="+base64Input);
    console.log(response,"adddept")
    return response?.data?.data
  }

  async getVehicleNo(companyId) {
    // console.log("companyId", companyId)
    var postData = {
      companyId: companyId,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);
    console.log(response, "post");
    // let allVehicles = [{ "vehicleId": 0, "vehicleNo": "ALL" }];

    this.vehicleList = response?.data?.data;
    return response?.data?.data;
  }

  

}

export default StaffStore;
