import React from "react";

import {
  Input,
  Select,
  Space,
  Button,
  Col,
  Row,
  message,
  Dropdown,
  Tag,
  Modal,
  Form,
  Tooltip,
  Checkbox,
  Switch,
  Upload,
} from "antd";
import { Link } from "react-router-dom";
import { decode as base64_decode } from "base-64";
import Cookies from "js-cookie";
import { baseURL } from "../components/Utility";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import {
  SearchOutlined,
  DeleteFilled,
  PlusOutlined,
  DownOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import VehicleStore from "../store/VehicleStore";
import withRouter from "../withRouter";
import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import CompanyAutocomplete from "./CompanyAutocomplete";

import ListTable from "./ListTable";

import CompanyStore from "../store/CompanyStore";
import AssignVehicleToCompany from "./AssignVehicleToCompany";
import VehicleAdd from "./VehicleAdd";
import dayjs from "dayjs";

let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    // console.log(val.title?.length)
    // if(val.finalWidth ===70){
    //   val.width =80
    // }else if(val.finalWidth ===100){
    //   val.width =150
    // }

    if (val.title?.length >= 18) {
      val.width = 180;
    } else if (val.title?.length > 15) {
      val.width = 160;
    } else if (val.title?.length > 12) {
      val.width = 130;
    } else if (val.title?.length > 8) {
      val.width = 115;
    } else {
      val.width = 105;
    }
    // console.log(val.title.length)
  });

  return arr;
};
class VehicleList extends React.Component {
  formRef = React.createRef();
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.user = this.props.getUserData().isadmin;

    this.vehicleStore = new VehicleStore();
    this.companyStore = new CompanyStore(this);
    this.selectedCol = [
      {
        key: "18",

        title: context.translate("_FLEET_REPORTS_VEHICLEID_"),
        dataIndex: "vehicleId",
        fixed: "left",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        key: "1",
        title: context.translate("_ASSET_NAME_"),

        dataIndex: "vehicleName",

        style: { fontSize: "12px" },
      },
      {
        key: "19",

        title: context.translate("ARABIC_VEHICLE_NO_"),
        dataIndex: "vehicleNoArabic",
      },

      {
        key: "2",

        title: context.translate("_ASSET_NUMBER_"),
        dataIndex: "vehicleNo",
        style: { fontSize: "12px" },
      },

      {
        key: "5",

        title: context.translate("_FLEET_DASHBORD_NEWPOI_COMPANY_"),
        dataIndex: "companyName",
      },
      {
        key: "6",
        // title: "Driver",
        title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
        dataIndex: "dname",
        finalWidth: 70,
      },
      {
        key: "7",
        // title: "Device",
        title: context.translate("_FLEET_GROUP_GROUPLIST_DEVICENAME_"),
        dataIndex: "deviceName",
        finalWidth: 70,
      },
      {
        key: "8",
        // title: "IMEI",
        title: context.translate("_FLEET_DASHBORD_SETTING_IMEI_"),
        dataIndex: "full_imei",
        style: { fontSize: "12px" },
        finalWidth: 100,
      },
      {
        key: "9",

        title: context.translate("_SIM_NUMBER_"),
        dataIndex: "simNo",
        style: { fontSize: "12px" },
      },
      {
        key: "10",

        title: context.translate("_INSTALLATION_DATE_"),
        dataIndex: "installDate",

        style: { fontSize: "12px" },
        render: (value) => {
          if (value) {
            const formattedDate = dayjs(value).format("DD-MM-YYYY");
            return <>{formattedDate}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        key: "11",

        title: context.translate("_EXPIRY_DATE_"),
        dataIndex: "expiryDate",
        style: { fontSize: "12px" },
        render: (value) => {
          if (value) {
            const formattedDate = dayjs(value).format("DD-MM-YYYY");
            return <>{formattedDate}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        key: "20",

        title: context.translate("_INSURANCE_TYPE_"),
        dataIndex: "insuranceRenewal",
        style: { fontSize: "12px" },
        render: (value) => {
          if (value) {
            const formattedDate = dayjs(value).format("DD-MM-YYYY");
            return <>{formattedDate}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        key: "21",

        title: context.translate("_MULKIYA_TYPE_"),
        dataIndex: "mulkiyaRenewal",
        style: { fontSize: "12px" },
        render: (value) => {
          if (value) {
            const formattedDate = dayjs(value).format("DD-MM-YYYY");
            return <>{formattedDate}</>;
          } else {
            return <></>;
          }
        },
      },

      {
        key: "16",
        // title: "Status",
        title: context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "status2",
        style: { fontSize: "12px" },

        render: (mainValue) => {
          let value = Number(mainValue);
          if (value === 3) {
            return (
              <Tag
                style={{ width: "60px", textAlign: "center" }}
                color={config.deleteColor}
              >
                {/* Expired */}
                {context.translate("_EXPIRED_")}
              </Tag>
            );
          } else if (value === 4) {
            return (
              <Tag
                style={{
                  width: "75px",
                  textAlign: "center",
                  fontSize: "10px",
                }}
                color={config.ExpirySoonColor}
              >
                {/* Expiring Soon */}
                {context.translate("_EXPIRINGSOON_")}
              </Tag>
            );
          } else if (value == 1) {
            return (
              <Tag
                style={{ width: "60px", textAlign: "center" }}
                color={config.activeColor}
              >
                {/* Active */}
                {context.translate("_FLEET_USER_USERLIST_STATUS_ACTIVE_")}
              </Tag>
            );
          } else if (value == 2) {
            return (
              <Tag
                style={{ width: "74px", textAlign: "center" }}
                color={config.inActiveColor}
              >
                {/* Uninstalled */}
                {context.translate("_UNINSTALLED_")}
              </Tag>
            );
          } else if (value == 5) {
            return (
              <Tag
                style={{ width: "60px", textAlign: "center" }}
                color={config.inActiveColor}
              >
                {/* Deleted */}
                {context.translate("_DELETED_")}
              </Tag>
            );
          } else if (value == 0) {
            return (
              <Tag
                style={{ width: "60px", textAlign: "center" }}
                color={config.inActiveColor}
              >
                Inactive
              </Tag>
            );
          }
          // else if (value == 2) {
          //   return <Tag color="red">Inactive</Tag>;
          // }
        },
      },
      {
        key: "17",

        title: context.translate("_ACTION_"),
        dataIndex: "Action",

        style: { fontSize: "12px" },

        render: (_, record) => {
          if (
            Number(record.status2) === 3 ||
            Number(record.status2) === 1 ||
            Number(record.status2) === 2 ||
            Number(record.status2) === 4 ||
            Number(record.status) === 0
          ) {
            return (
              <>
                {(Number(this.props.getUserData().isadmin) === -1 ||
                  Number(this.props.getUserData().isadmin) === 3) && (
                  <Space>
                    <DeleteConfirm
                      onConfirm={(e) => {
                        e.preventDefault();
                        this.onDelete(record);
                      }}
                    >
                      <Link>
                        <Tooltip
                          // title="Delete Asset"
                          title={this.context.translate(
                            "_FLEET_VEHICLE_VEHICLELIST_DELETE_"
                          )}
                          placement="bottom"
                          color={config.tooltipColorDelete}
                        >
                          <DeleteFilled style={{ color: config.deleteColor }} />
                        </Tooltip>
                      </Link>
                    </DeleteConfirm>

                    <Tooltip
                      title={
                        record.status == "1"
                          ? this.context.translate("_ACTIVE_")
                          : this.context.translate(
                              "_FLEET_USER_USERLIST_STATUS_INACTIVE_"
                            )
                      }
                      placement="top"
                      color={
                        record.status == "1"
                          ? config.activeColor
                          : config.inActiveColor
                      }
                    >
                      <Switch
                        size="small"
                        className="form-offlineAlerts"
                        checked={Number(record.status)}
                        onChange={async (val) => {
                          this.setState({ vehicleId: record.vehicleId });
                          this.setState({ isActive: val });
                          this.setState({ showStatusConfirm: true }); // open modal
                        }}
                        // defaultChecked
                      />
                    </Tooltip>
                  </Space>
                )}
              </>
            );
          }
        },
      },
    ];

    //check which user-------
    let column = this.selectedCol;
    if (this.user === -1 || this.user === 3) {
      column = this.selectedCol;
    } else {
      column = this.selectedCol?.filter((val) => val.dataIndex !== "Action");
    }
    this.selectedCol = column;
    // ------------------------------
    this.state = {
      status: 0,
      loading: false,
      pageSize: 50,
      currentPage: 1,
      companyLoading: false,
      renewVehicle: false,
      selectedVehicle: "",
      loadData: 0,
      showDeleteModal: false,
      companyVal_Lab: "",
      full_imei: "",
      // selectedVehicle1:false
      selectedRowKeys: [],
      openAssignForm: false,
      openVehicleAddForm: false,
      vehicleIdForEdit: "",
      showAlertModal: false,
      modalVisible: false,
      companyId: "",
      searchField: "",
      showStatusConfirm: false,
      selectedColumns: widthAdderFunc(this.selectedCol),
      isAllVehicle: false,
      showUpload: false,
      excelFile: "",
      excelCompanyId: "",
      // position:[]
    };

    // this.formRef = React.createRef();

    this.newColumns = widthAdderFunc([
      ...this.state.selectedColumns,
      {
        title: context.translate("_CREATE_DATE"),
        dataIndex: "createdate",
        key: "createdate",
      },
      {
        title: context.translate("_FLEET_VEHICLE_VEHICLEDETAIL_SERVICEEXPIRE_"),
        dataIndex: "serviceexpire",

        key: "serviceexpire",
      },
      {
        title: context.translate("_DEPARTMENT_"),
        dataIndex: "department",
        key: "department",
      },
      {
        title: context.translate("_AREA_"),
        dataIndex: "area",
        key: "area",
      },
      {
        // title: "Installation Type",
        title: context.translate("_RESPONSIBLE_MANAGER"),
        dataIndex: "responsibleManager",
        key: "responsibleManager",
      },
      {
        title: context.translate("_FLEET_DASHBORD_VEHICLE_INFORMATION_MODEL_"),
        dataIndex: "model",
        key: "model",
      },
      {
        title: context.translate("_VEHICLE_OWNERSHIP_"),
        dataIndex: "ownership",
        key: "ownership",
      },
      {
        title: context.translate("_CONTACT_NO_"),
        dataIndex: "contactNo",
        key: "contactNo",
      },
      {
        title: context.translate("_EMAIL_"),
        dataIndex: "email",
        key: "email",
      },
    ]);
  }

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const setVehicleStates = this.context.viewStateStore.vehicleDetailsObject;
      console.log(setVehicleStates);
      if (setVehicleStates.companyId !== "") {
        await this.vehicleStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          // searchField: setVehicleStates.searchText,
          companyId: setVehicleStates.companyId,
          isadmin: this.props.getUserData().isadmin,
          userId: this.props.getUserData().userId,

          usercompanyId: this.props.getUserData().userCompanyId,
        });
        const singleData = await this.companyStore.getCompanyDataById(
          setVehicleStates.companyId
        );

        await this.setState({ loadList: 1 });
        await this.setState({ companyVal_Lab: singleData });
      }
      this.setState({ listDataUpdate: false });
    }
  }
  async componentDidMount() {
    if (this.props.params.status === "4") {
      await this.setState({ status: 4 });
    }
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        excelCompanyId: this.props.getUserData().userCompanyId,
      });

      //  console.log(this.state.companyId)
      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleSearch();
    }

    await this.setState({ column: this.state.selectedColumns });

    this.setState({ pageSize: 50 });
  }
  async handleChange(comp) {
    if (comp) {
      await this.setState({
        companyId: comp.value,
        currentPage: 1,
        pageSize: 50,
      });
      const singleData = await this.companyStore.getCompanyDataById(
        comp.value,
        1
      );
      console.log(singleData, comp.value);
      await this.setState({
        companyVal_Lab: singleData,
        companyId: comp.value,
      });
      await this.handleSearch();
    } else {
      console.log("innn");
      await this.setState({
        companyId: null,
        companyVal_Lab: null,
      });
      this.vehicleStore.vehicles = [];
      this.vehicleStore.total = 0;
      this.setState({ loadData: 1 });
    }
  }

  async handleSearch() {
    // this.setState({ loading: true });
    await this.vehicleStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      status: this.state.status,
      searchField: this.state.searchField,
      isadmin: this.props.getUserData().isadmin,
      userId: this.props.getUserData().userId,
      usercompanyId: this.props.getUserData().userCompanyId,

      // usercompanyId: this.props.getUserData().userCompanyId,
    });

    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  onEdit = async (record) => {
    const vehicleId = record.vehicleId;
    if (vehicleId) {
      this.context.viewStateStore.setVehicleStates({
        companyId: record.companyId,
      });
      // this.props.navigate("/vehicleadd/" + vehicleId);
      await this.setState({ vehicleIdForEdit: vehicleId });
      await this.setState({ openVehicleAddForm: true });
    }
  };

  async onDelete(record) {
    console.log(record);
    console.log(record.vehicleId, "record");
    const response = await this.vehicleStore.deleteRecord({
      vehicleId: record.vehicleId,
      full_imei: record.full_imei,
    });
    console.log(response);
    //  console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  searchInput = async (e) => {
    const value = e.target.value;
    // await this.setState({searchField:value})
    if (value.length >= 3) {
      await this.vehicleStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        searchField: value,
        companyId: this.state.companyId,
        status: this.state.status,
        isadmin: this.props.getUserData().isadmin,
        userId: this.props.getUserData().userId,
        usercompanyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ loadList: 1 });
    } else if (value.length === 0) {
      await this.vehicleStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        searchField: value,
        companyId: this.state.companyId,
        status: this.state.status,
        isadmin: this.props.getUserData().isadmin,
        userId: this.props.getUserData().userId,
        usercompanyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ currentPage: 1 });
    }
  };

  async searchInputButton() {
    if (!this.state.companyId) {
      console.log(
        this.state.companyId,
        "sfsdgdfgdfhfg",
        this.state.companyFieldHighlight
      );
      this.setState({ companyFieldHighlight: true });
    } else {
      this.setState({ companyFieldHighlight: false });
    }
    if (this.state.companyId) {
      this.setState({ companyFieldHighlight: false, loading: true });
      await this.vehicleStore.loadData({
        offset: 0,
        limit: 50,
        searchField: this.state.searchField,
        companyId: this.state.companyId,
        status: this.state.status,
        isadmin: this.props.getUserData().isadmin,
        userId: this.props.getUserData().userId,
        usercompanyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ loadData: 1, currentPage: 1 });
    }
  }

  warningRenewVehicle = async () => {
    // await this.setState({selectedVehicle
    //   :true}) ?  await this.setState({renewVehicle:true}) :
    Modal.warning({
      // title: "Alert",
      title: this.context.translate("_FLEET_ALERT_"),
      // content: "Please select vehicles",
      content: this.context.translate("_SELECT_VEHICLES_"),
      style: {
        padding: "20px !important",
        // background: "pink",
        borderRadius: "10px",
        // Add more inline styles as needed
      },
    });
  };

  onRenewVehicleSubmit = async (value) => {
    // console.log(value);
    const data = { ...value, vehicleId: this.state.selectedVehicle };
    console.log(data);
    const resp = await this.vehicleStore.renewVehicle(data);
    console.log(resp);
    if (resp.data.success === "S") {
      message.success(resp.data.message);
      // this.setState({selectedRowKeys:[]})
      this.setState({ selectedVehicle: [] });
      console.log(resp);
    } else {
      message.error(resp.data.message);
    }
    if (resp.data.message) {
      this.setState({ renewVehicle: false });
      this.formRef.current?.resetFields();
    }

    // await this.setState({selectedRowKeys:[]})
    await this.handleSearch();
    await this.setState({ loadData: 1 });
    // console.log(this.state.selectedRowKeys,"dttyftyutyuiyuiuu")
  };

  // warningForDeleteVehicle = async () => {
  //   Modal.warning({
  //     title: "Alert",
  //     content: "Please select vehicles",
  //   });
  // };
  onExcelSubmit = async (fieldsValue) => {
    const data = {
      ...fieldsValue,
      companyId: this.state.excelCompanyId,
      excelFile: this.state.excelFile,
    };
    const resp = await this.vehicleStore.addVehicleByExcel(data);

    if (resp?.success === "S") {
      message.success(resp.message);

      this.setState({ showUpload: false });
      this.setState({ excelFile: "" });
      this.handleSearch();
      // this.formRef.current?.resetFields();
    }
    if (resp.message) {
      this.setState({ showUpload: false });
      this.formRef.current?.resetFields();
    }
  };

  getUploadData = async (fileUp) => {
    // console.log(fileUp.file.name)
    let fileinfo = fileUp.file.response;

    console.log(fileinfo);

    if (fileinfo?.success == "S") {
      // console.log(fileinfo.data)

      await this.setState({ excelFile: fileinfo.data });
    }

    this.setState({ update: 1 });
  };

  deleteMultipleVehicles = async (value) => {
    console.log(value);
    let flag = 0;
    const deleteVehicle = this.state.full_imei.forEach(async (val) => {
      console.log(val);

      const response = await this.vehicleStore.deleteRecord({
        vehicleId: val.vehicleId,
        full_imei: val.full_imei,
      });
      console.log(response);
      if (response?.data?.success === "S") {
        if (flag === 0) {
          message.success(response.data.message);
        }

        this.handleSearch();
        flag++;
      } else {
        message.error(response.data.message);
      }
    });
  };

  responseMsg = async (resp) => {
    if (resp.data.success == "S") {
      message.success(resp.data.message);
      console.log(resp.data.message);
      // this.props.navigate("/vehiclelist");
      this.setState({ openVehicleAddForm: false });
      await this.setState({ listDataUpdate: true });
    } else if (resp.data.success == "F0") {
      if (resp.data.errors[0]?.full_imei) {
        message.error(this.context.translate(resp.data.errors[0]?.full_imei));
      }
    }
  };

  confirmStatus = async (status) => {
    const data = {};

    data.vehicleId = this.state.vehicleId;
    data.activeStatus = this.state.isActive === true ? 1 : 0;

    await this.vehicleStore.updateStatus(data);

    console.log(data);

    this.setState({ showStatusConfirm: false });
    await this.handleSearch();
    //  await this.setState({ loading: true });
    //   await this.driversStore.loadData({ offset: 0, limit: 50 });
    // await this.setState({ loading: false });
  };

  closeModal = () => {
    this.setState({ openVehicleAddForm: false });
  };

  render() {
    const companyInputBox = this.state.companyFieldHighlight
      ? { border: "2px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_FLEET_VEHICLE_VEHICLELIST_TITLE_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  link="/vehicleadd"
                  title={this.context.translate("_ADD_")}
                /> */}
                {(Number(this.props.getUserData().isadmin) === -1 ||
                  Number(this.props.getUserData().isadmin) === 3) && (
                  <Tooltip
                    // title="Add"
                    title={this.context.translate(
                      "_FLEET_ROLE_ROLELIST_ADDBTN_"
                    )}
                    placement="left"
                    color={config.primaryColor}
                  >
                    <Button
                      className="addButton"
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() =>
                        this.setState({
                          openVehicleAddForm: true,
                          vehicleIdForEdit: "",
                        })
                      }
                    ></Button>
                  </Tooltip>
                )}

                {(Number(this.props.getUserData().isadmin) === -1 ||
                  Number(this.props.getUserData().isadmin) === 3) && (
                  <Button
                    onClick={async (e) => {
                      this.state.selectedVehicle.length > 0
                        ? await this.setState({ renewVehicle: true })
                        : await this.setState({ showAlertModal: true });
                    }}
                  >
                    <i class="bi bi-plus" style={{ marginRight: "5px" }}></i>
                    {/* Renew */}
                    {this.context.translate("_RENEW_")}{" "}
                  </Button>
                )}

                {(Number(this.props.getUserData().isadmin) === -1 ||
                  Number(this.props.getUserData().isadmin) === 3) && (
                  <Button
                    onClick={() => this.setState({ openAssignForm: true })}
                  >
                    <span>
                      {" "}
                      <i
                        class="bi bi-car-front"
                        style={{ marginRight: "5px" }}
                      ></i>
                      {/* Assign */}
                      {this.context.translate("_ASSIGN_")}
                    </span>
                  </Button>
                )}
                {(Number(this.props.getUserData().isadmin) === -1 ||
                  Number(this.props.getUserData().isadmin) === 3) && (
                  <Button
                    onClick={async () => {
                      this.state.selectedVehicle.length > 0
                        ? await this.setState({ showDeleteModal: true })
                        : await this.setState({ showAlertModal: true });
                    }}
                  >
                    <i class="bi bi-trash3" style={{ marginRight: "5px" }}></i>
                    {/* Delete */}
                    {this.context.translate(
                      "_FLEET_COMPANY_COMPANYLIST_DELETE_"
                    )}
                  </Button>
                )}

                <Button
                  disabled={
                    this.vehicleStore?.vehicles?.length > 0 ? false : true
                  }
                  style={{ backgroundColor: config.activeColor }}
                  onClick={(val) => {
                    const result = [];
                    this.state.selectedColumns.forEach((obj) => {
                      if (obj.dataIndex === "status2") {
                        result.push("status");
                      } else {
                        result.push(obj.dataIndex);
                      }
                    });
                    console.log(result);
                    var getData = {
                      cid:
                        this.state.companyId == -1 ? 0 : this.state.companyId,
                      UcompanyId: JSON.parse(base64_decode(Cookies.get("data")))
                        .userCompanyId,
                      isadmin: JSON.parse(base64_decode(Cookies.get("data")))
                        .isadmin,
                      searchField: this.state.searchField,
                      status: this.state.status,
                      colList: result,
                    };
                    console.log(getData);
                    if (val) {
                      this.vehicleStore.exportExcel(getData);
                    }
                  }}
                >
                  {this.context.translate("_EXPORT_")}
                </Button>

                {(this.props.getUserData().isadmin === 1 ||
                  this.props.getUserData().isadmin === 3) && (
                  <Tooltip
                    // title="Upload"
                    title={this.context.translate("_UPLOAD_")}
                    color={config.tooltipColorEdit}
                  >
                    <Button
                      type={config.uploadBoxType}
                      onClick={() => this.setState({ showUpload: true })}
                    >
                      <UploadOutlined />
                    </Button>
                  </Tooltip>
                )}

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                {/* <Dropdown menu={this.menuProps}>
                  <Button>
                    <Space>
                      {this.context.translate("_ACTION_")}

                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown> */}
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  showAll={1}
                  allowClear={true}
                  onChange={(value) => this.handleChange(value)}
                  // disabled={this.state.companyLoading}
                  value={
                    this.state.companyVal_Lab ? this.state.companyVal_Lab : null
                  }
                  // style={{ width: 200 }}
                  style={companyInputBox}
                />

                {/* {(this.props.getUserData().isadmin === 3 ||
                  this.props.getUserData().isadmin === -1) && (
                  <Checkbox
                    checked={this.state.isAllVehicle}
                    onChange={async (e) => {
                      await this.setState({ isAllVehicle: e.target.checked });

                      if (this.state.isAllVehicle) {
                        await this.setState({ companyId: -1 });
                        await this.handleSearch();
                      } else {
                        this.vehicleStore.vehicles = [];
                        this.vehicleStore.total = 0;
                      }
                      await this.setState({ loadData: 1 });
                    }}
                  >
                    All Vehicle
                  </Checkbox>
                )} */}
                <Select
                  style={{ width: 150 }}
                  onSelect={async (value) => {
                    await this.setState({ status: value });
                    await this.searchInputButton();
                  }}
                  defaultValue={
                    this.props.params.status === "4" ? 4 : this.state.status
                  }
                  // placeholder="Status"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"
                  )}
                >
                  <Select.Option value={0}>
                    {this.context.translate("_ALL_")}
                  </Select.Option>
                  <Select.Option value={1}>
                    {this.context.translate("_INSTALLED_")}
                  </Select.Option>
                  <Select.Option value={2}>
                    {this.context.translate("_UNINSTALLED_")}
                  </Select.Option>
                  <Select.Option value={3}>
                    {this.context.translate("_EXPIRED_")}
                  </Select.Option>
                  <Select.Option value={4}>
                    {this.context.translate("_EXPIRINGSOON_")}
                  </Select.Option>
                  <Select.Option value={5}>
                    {this.context.translate("_DELETED_")}
                  </Select.Option>
                </Select>
                <Input
                  // placeholder="Search By Brand,Driver Name,Sim No,IMEI,Asset"
                  placeholder={this.context.translate(
                    "_SEARCH_BY_BRAND_DRIVERNAME_SIMNO_IMEI_ASSET_"
                  )}
                  value={this.state.searchField ? this.state.searchField : ""}
                  style={{ width: 400 }}
                  onChange={(e) => {
                    this.setState({ searchField: e.target.value });
                    this.searchInput(e);
                  }}
                  // allowClear
                />
                <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  onClick={() => {
                    this.searchInputButton();
                  }}
                />
              </Space>
            </Col>
          </Row>

          <ListTable
            rowSelection={{
              // columnWidth: 2,
              fixed: true,

              selectedRowKeys: this.state.selectedVehicle,
              onChange: async (selectedRowKeys, selectedRows) => {
                console.log(selectedRowKeys, selectedRows);

                // await this.setState({})
                await this.setState({ selectedVehicle: selectedRowKeys });

                console.log(selectedRowKeys);
                // const selectedFullImei = selectedRows.map((row) => row.full_imei);
                // console.log(selectedFullImei)
                await this.setState({ full_imei: selectedRows });
              },
            }}
            // value={this.state.selectedRowKeys}
            rowKey="vehicleId"
            currentPage={this.state.currentPage}
            total={this.vehicleStore?.total}
            pageSize={this.state.pageSize}
            columns={this.state.column}
            dataSource={this.vehicleStore?.vehicles}
            loading={this.vehicleStore?.loading}
            onChange={this.handleTableChange}
            // scroll={{
            //   x:1800
            // }}
          />

          <Modal
            title={this.context.translate("_RENEW_VEHICLE_")}
            // title="Renew Vehicle"
            open={
              this.state.selectedVehicle.length !== 0 && this.state.renewVehicle
            }
            onOk={() => this.setState({ renewVehicle: false })}
            onCancel={() => this.setState({ renewVehicle: false })}
            footer={false}
            width={400}
          >
            {/* {console.log(this.state.selectedVehicle.length !==0 ,this.state.renewVehicle,"sfgdfg")} */}
            <Form
              name="renewVehicle"
              layout="vertical"
              onFinish={this.onRenewVehicleSubmit}
              style={{ maxWidth: "100%" }}
            >
              <Row>
                <Col span={12}></Col>
                <Col
                  span={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                ></Col>
              </Row>

              <Form.Item
                name="renewvalue"
                // label="Renewed For"
                label={this.context.translate("_RENEWAL_FOR_")}
                rules={[{ required: true }]}
              >
                <Select style={{ width: 350 }}>
                  {/* <Select.Option value="11">1 Month</Select.Option>
                  <Select.Option value="12">3 Month</Select.Option>
                  <Select.Option value="13">6 Month</Select.Option> */}
                  <Select.Option value="1">1 Year</Select.Option>
                  <Select.Option value="2">2 Year</Select.Option>
                  <Select.Option value="3">3 Year</Select.Option>
                  <Select.Option value="4">4 Year</Select.Option>
                  <Select.Option value="5">5 Year</Select.Option>
                  <Select.Option value="6">6 Year</Select.Option>
                  <Select.Option value="7">7 Year</Select.Option>
                  <Select.Option value="8">8 Year</Select.Option>
                  <Select.Option value="9">9 Year</Select.Option>
                  <Select.Option value="10">10 Year</Select.Option>
                </Select>
              </Form.Item>
              <div className="formBtnGroup">
                {/* css class */}
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  // loading={this.state.loading}
                >
                  {this.context.translate("_SUBMIT_")}
                  {/* css class */}
                  {/* Submit */}
                </Button>{" "}
              </div>

              {/* <Row justify={"end"}>
              <Space style={{ padding: "10px" }}>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Space>
              </Row> */}
            </Form>
          </Modal>

          <Modal
            width={350}
            className="alert-model"
            title=<div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-trash3"
              ></i>{" "}
              {/* Remove Asset? */}
              {this.context.translate(
                "_FLEET_VEHICLE_VEHICLELIST_REMOVETITLE_"
              )}
            </div>
            open={
              this.state.selectedVehicle.length !== 0 &&
              this.state.showDeleteModal
            }
            onCancel={() => this.setState({ showDeleteModal: false })}
            footer={[
              <Button
                // key="no"
                onClick={() => this.setState({ showDeleteModal: false })}
              >
                {this.context.translate("_FLEET_RULELIST_CRONRULE_NO_")}
                {/* No */}
              </Button>,
              <Button
                type="primary"
                onClick={(e) => {
                  this.deleteMultipleVehicles();

                  console.log(this.state.selectedVehicle);
                  this.setState({
                    showDeleteModal: false,
                    selectedVehicle: "",
                  });
                }}
              >
                {this.context.translate("_FLEET_RULEDETAIL_ACTIVEDDL_YES_")}
                {/* Yes */}
              </Button>,
            ]}
          >
            {this.context.translate("_SURE_TO_DELETE_")}
            {/* <p>Are you sure you want to delete?</p> */}
          </Modal>
        </div>

        {this.state.openAssignForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Assign Vehicle To Company"
            title={this.context.translate("_ASSIGN_VEHICLE_TO_COMPANY_")}
            centered
            visible={this.state.openAssignForm}
            footer={null}
            onCancel={() => {
              this.setState({ openAssignForm: false });
            }}
            width={665}
          >
            <div>
              <AssignVehicleToCompany />
              {/* companyId={this.state.companyId} ID={this.state.assignId} response={this.assignresponseMsg} */}
            </div>
          </Modal>
        )}

        {this.state.openVehicleAddForm && (
          <Modal
            bodyStyle={{ height: "100%" }}
            className="formShowModal" //  {/* css class */}
            // title="Add New Vehicle"
            maskClosable={false}
            title={this.context.translate("_ADD_NEW_VEHICLE_")}
            centered
            visible={this.state.openVehicleAddForm}
            footer={null}
            onCancel={() => {
              this.setState({ openVehicleAddForm: false });
            }}
            width={665}
          >
            <div>
              <VehicleAdd
                vehicleIdForEdit={this.state.vehicleIdForEdit}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
              {/* companyId={this.state.companyId} ID={this.state.assignId} response={this.assignresponseMsg} */}
            </div>
          </Modal>
        )}

        <Modal
          open={this.state.modalVisible}
          width={300}
          // title="Select Columns"
          title={this.context.translate("_SELECT_COLUMNS_")}
          onCancel={() => this.setState({ modalVisible: false })}
          onOk={() => {
            this.setState({ modalVisible: false });
          }}
          footer={null}
        >
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            {this.newColumns.map((column, v) => {
              return (
                <Row key={v}>
                  <Col>
                    <Checkbox
                      onChange={() => this.handleCheckboxChange(column)}
                      checked={this.state.selectedColumns.some(
                        (c) => c.key === column.key
                      )}
                    >
                      {column.title}
                    </Checkbox>
                  </Col>
                </Row>
              );
            })}
          </div>
        </Modal>

        {this.state.showUpload && (
          <>
            {" "}
            <Modal
              open={this.state.showUpload}
              // title="File Upload"
              title={this.context.translate("_FILE_UPLOAD_")}
              maskClosable={false}
              onOk={() => this.setState({ showUpload: false })}
              onCancel={() =>
                this.setState({
                  showUpload: false,
                  excelFile: "",
                  companyId: "",
                })
              }
              footer={false}
              width={400}
              // style={{width:100}}
            >
              <Form
                name="excelimport"
                layout="vertical"
                onFinish={this.onExcelSubmit}
                style={{ padding: "0px 10px" }}
              >
                {(this.props.getUserData().isadmin === -1 ||
                  this.props.getUserData().isadmin === 3) && (
                  <Form.Item
                    name="companyId"
                    label={this.context.translate("_ACCOUNT_")}
                    rules={[{ required: true }]}
                  >
                    <CompanyAutocomplete
                      onChange={(companyId) => {
                        console.log(companyId.value);
                        this.setState({ excelCompanyId: companyId.value });
                        // this.formRef.current.setFieldsValues({excelFile:undefined})
                      }}
                      style={{ width: 300 }}
                    />
                  </Form.Item>
                )}

                <Form.Item
                  name="excelFile"
                  // className="label-seprator"

                  className="uploadFile"
                  // label="Excel File"
                  label={this.context.translate("_EXCEL_FILE_")}
                  rules={[{ required: true }]}
                >
                  <Upload
                    // beforeUpload={(file) => this.validateFile(file)}
                    onRemove={() =>
                      this.setState({ excelFile: "", fileList: [] })
                    }
                    name="excelFile"
                    action={
                      config.baseURL + "/index.php?c=company&a=fileupload"
                    }
                    listType="text"
                    data={{
                      fileType: "accountimport",
                      fieldName: "excelFile",
                    }}
                    onChange={this.getUploadData}
                    // beforeUpload={(file) => this.validateFile(file)}
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />}>
                      {this.context.translate("_CLICKTOUPLOAD_EXCELFILE_")}
                    </Button>
                  </Upload>
                </Form.Item>

                <div className="formBtnGroup">
                  <Button
                    className="formCancelButton"
                    // style={{ backgroundColor: config.activeColor }}
                    onClick={() => this.vehicleStore.sampleExcel()}
                  >
                    {this.context.translate("_SAMPLE_")}
                  </Button>
                  <Button
                    type="primary"
                    className="formSaveButton"
                    htmlType="submit"
                    // loading={this.state.loading}
                  >
                    {this.context.translate("_UPLOAD_")}
                  </Button>
                </div>
              </Form>
            </Modal>{" "}
          </>
        )}

        {this.state.showStatusConfirm && (
          <Modal
            maskClosable={false}
            title={this.context.translate("_CONFIRM_")}
            description="Are you sure to change this status?"
            centered
            open={this.state.showStatusConfirm}
            //footer={null}
            onOk={this.confirmStatus}
            onCancel={() => {
              this.setState({ showStatusConfirm: false });
            }}
            width={400}
          >
            {this.state.isActive === true ? (
              <p>{this.context.translate("_STATUS_ACTIVE_")}</p>
            ) : (
              <p>{this.context.translate("_STATUS_INACTIVE_")}</p>
            )}
          </Modal>
        )}

        {/* title: "Alert",
      content: "Please select vehicles", */}
        <Modal
          width={350}
          className="alert-model"
          title={
            <div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              {this.context.translate("_FLEET_ALERT_")}
            </div>
          }
          open={this.state.showAlertModal}
          onOk={() => this.setState({ showAlertModal: false })}
          onCancel={() => this.setState({ showAlertModal: false })}
          okText="OK"
          cancelText="Cancel"
          footer={[
            <Button
              type="primary"
              onClick={() => this.setState({ showAlertModal: false })}
            >
              {/* OK */}
              {this.context.translate("_FLEET_DASHBORD_VEHICLE_COMMAND_OKBTN_")}
            </Button>,
          ]}
        >
          {/* <p>Please select vehicles</p> */}
          {this.context.translate("_SELECT_VEHICLES_")}
        </Modal>
      </>
    );
  }
}
VehicleList.contextType = StoreContext;
export default observer(withRouter(VehicleList));
