import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
  observe,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class DepartmentStore {

    departments=[]
  
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        
      
      total:observable,
      loading:observable,
      departments:observable,
      getUserDepartmentData:action,
      getDepartmentData:action,
      
      addDepartment:action
     
     
    });
     this.rootStore = rootStore;
    
  }


  async getDepartmentData(){
      var postData = {}
      const base64Input = {};
      var response = await postApi.getClient().post("index.php?c=salesperson&a=getDepartment", "postData="+base64Input);
      var departments = [...response?.data?.data];
      console.log(departments)
     
      var allDepartment = [{departmentId:"0",departmentName:"All"}]
   
      allDepartment = [...allDepartment,...response?.data?.data]
      console.log(allDepartment)
      this.departments = allDepartment;
  }

  async getUserDepartmentData(){
    var postData = {}
    const base64Input = {};
    var response = await postApi.getClient().post("index.php?c=salesperson&a=getDepartment", "postData="+base64Input);
    var departments = [...response?.data?.data];
    console.log(departments)
    this.departments =response?.data?.data;
}



  async addDepartment(departmentDetails){
    var postData = departmentDetails
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=salesperson&a=addDepartment", "postData="+base64Input);
    console.log(response,"adddept")
    return response
  }



  
  

}

export default DepartmentStore;
