import React, { useContext } from "react";
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
} from "recharts";
import UsageGraphStore from "../store/UsageGraphStore";
import { StoreContext } from "../store/RootStore";

const DashboardUsageGraph = (props) => {
  // const data = [
  //     { vehicleNo: 'Aafgdf', Idle: 12, stop: 8, Excessive: 5 },
  //     { vehicleNo: 'Badf', Idle: 22, stop: 15, Excessive: 7 },
  //     { vehicleNo: 'Cad', Idle: 13, stop: 10, Excessive: 15 },
  //     { vehicleNo: 'Ddasf', Idle: 12, stop: 8, Excessive: 5 },
  //     { vehicleNo: 'Eadfasf', Idle: 22, stop: 15, Excessive: 7 },
  //     { vehicleNo: 'Csafe', Idle: 13, stop: 10, Excessive: 15 },
  //     { vehicleNo: 'Asad', Idle: 12, stop: 8, Excessive: 5 },
  //     { vehicleNo: 'Bedd', Idle: 22, stop: 15, Excessive: 7 },
  //     { vehicleNo: 'sasdfcC', Idle: 13, stop: 10, Excessive: 15 },
  //     { vehicleNo: 'Bed', Idle: 22, stop: 15, Excessive: 7 },

  //     // Add more data as needed
  // ];
  const usageGraphStore = new UsageGraphStore();
  const storeContext = useContext(StoreContext);
  console.log(usageGraphStore);

  let x = props.data;
  if (props.data) {
    x.forEach((val) => {
      val.Travel = parseFloat(val.Travel);
      val.Idle = parseFloat(val.Idle);
      val.Stop = parseFloat(val.Stop);
      val.Excessive = parseFloat(val.Excessive);

      // val.Id = val.vehicleNo;
    });
  }

  const data = x;

  const CustomTooltip = ({ active, payload }) => {
    console.log(active, payload);
    // let unit = "";
    // payload.map((res) => {
    //   let Idle = parseFloat(res.payload.Idle);
    //   let Excessive = parseFloat(res.payload.Excessive);
    //   let Stop = parseFloat(res.payload.Stop);
    //   let Travel =parseFloat(res.payload.Travel);
    //   if (Idle === 0.0 || Idle < 1) {
    //     unit = "(Min)";
    //   } else {
    //     unit = "(Hrs)";
    //   }
    //   console.log(Idle)
    //   if (Excessive === 0.0 || Excessive < 1) {
    //     unit = "(Min)";
    //   } else {
    //     unit = "(Hrs)";
    //   }
    //   if (Stop === 0.0 || Stop < 1) {
    //     unit = "(Min)";
    //   } else {
    //     unit = "(Hrs)";
    //   }
    //   if (Travel === 0.0 || Travel < 1) {
    //     unit = "(Min)";
    //   } else {
    //     unit = "(Hrs)";
    //   }
    //   // console.log(res.payload.Idle)
    // });
    // console.log(unit);
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      console.log(data);
      return (
        <div
          style={{ border: "1px solid", background: "white", padding: "10px" }}
        >
          <b>{`${storeContext.translate("_VEHICLE_NO_")}: ${
            data.vehicleNo ? data.vehicleNo : ""
          } `}</b>
          <div>{`${storeContext.translate("_DRIVER_MOBILE_")}: ${
            data.driverphone ? data.driverphone : ""
          }`}</div>
          <div>{`${storeContext.translate("_NAME_")}: ${
            data.name ? data.name : ""
          }`}</div>
          <div>{`${storeContext.translate("_IDLE_")}: ${
            data.Idle ? data.Idle : ""
          } ${data.Idle ? (data.Idle > 1 ? storeContext.translate("_HOURS_") : storeContext.translate("_MIN_")):'' } 
          `}</div>
          <div>{`${storeContext.translate("_STOP_")}: ${
            data.Stop ? data.Stop : ""
          } ${data.Stop ? (data.Stop > 1 ? storeContext.translate("_HOURS_") : storeContext.translate("_MIN_")) : '' } 
           `}</div>
          <div>{`${storeContext.translate("_TRAVEL_")}: ${
            data.Travel ? data.Travel : ""
          } ${data.Travel ? (data.Travel > 1 ? storeContext.translate("_HOURS_") : storeContext.translate("_MIN_")) : '' } 
          `}</div>
          <div>{`${storeContext.translate("_EX_IDLE_")}: ${
            data.Excessive ? data.Excessive : ""
          } ${data.Excessive ? (data.Excessive > 1 ? storeContext.translate("_HOURS_") : storeContext.translate("_MIN_")) : '' }  
          `}</div>
          <div>{`${storeContext.translate("_TOTAL_")}: ${
            data.TotalTravelDistance ? data.TotalTravelDistance : ""
          } ${storeContext.translate("_KM_")}`}</div>
        </div>
      );
    }
    return null; 
  };

  return (
    <ResponsiveContainer height={400} style={{ padding: "16px" }}>
      <BarChart width={500} data={data} layout={props.layout}>
        {props.infoObj ? (
          <>
            <YAxis
              padding={20}
              label={{
                value: storeContext.translate("_TIME_"),
                angle: -90,
                position: "insideLeft",
                fontWeight: "bold",
                fontSize: "17px",
                offset: -20,
                // offset: "20px",
              }}
              type={props.infoObj.yNumberType}
              dataKey={props.infoObj.yDataKey}
              fontSize={"10px"}
              style={{
                direction:
                  storeContext.locale.language === "arabic" ? "ltr" : "ltr",
              }}
            />
            <XAxis
              label={{
                value: storeContext.translate("_ASSET_NAME_"),
                position: "insideBottom",
                fontWeight: "bold",
                fontSize: "17px",
              }}
              height={50}
              type={props.infoObj.xNumberType}
              dataKey={props.infoObj.xDataKey}
            />{" "}
          </>
        ) : (
          <>
            <YAxis
              type="category"
              dataKey="vehicleNo"
              fontSize={"10px"}
              label={{
                // value: storeContext.translate("_TIME_"),
                value: storeContext.translate("_ASSET_NAME_"),
                angle: -90,
                position: "insideLeft",
                fontWeight: "bold",
                fontSize: "17px",
                offset: -20,
              }}
              style={{
                direction:
                  storeContext.locale.language === "arabic" ? "ltr" : "ltr",
              }}
            />
            <XAxis
              type="number"
              height={50}
              label={{
                value: storeContext.translate("_TIME_"),
                // value:storeContext.translate("_ASSET_NAME_"),
                position: "insideBottom",
                fontWeight: "bold",
                fontSize: "17px",
              }}
            />
          </>
        )}

        {/* <YAxis type="category" dataKey="vehicleNo" fontSize={"10px"}  />
        <XAxis type="number"   />  */}

        <Tooltip
          content={(props) => (
            <CustomTooltip {...props} additionalInfo="Your Additional Info" />
          )}
        />
        {/* <Legend /> */}

        <Bar
          isAnimationActive={false}
          dataKey="Travel"
          stackId="a"
          fill="green"
        ></Bar>

        <Bar dataKey="Stop" stackId="a" fill="red" />
        <Bar dataKey="Idle" stackId="a" fill="yellow" />
        <Bar dataKey="Excessive" stackId="a" fill="orange" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default DashboardUsageGraph;
