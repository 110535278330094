import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi,baseURL } from '../components/Utility'
import {
  action,
  
  makeObservable,
  observable,
 
} from 'mobx';


class TravelReportStore {
  travelReport=[]
  totalStartStopArray=[]
  total=0
  loading=false
  rootStore
  loadingData=false
  pageSize=0

  constructor(rootStore) {
    makeObservable(this, {
    travelReport: observable,
    pageSize:observable,
    totalStartStopArray:observable,
      total:observable,
      loading:observable,
      loadingData:observable,
      loadData:action,
      exportExcel:action,
      exportcsv:action,
      exportpdf:action,
      exportxml:action,
      exporttotalexcel:action,
      exporttotalpdf:action,
      totalStartStopReport:action
    });
     this.rootStore = rootStore;
    
  }

  async loadData({companyId,offset,limit,vehicleId,fromDate,toDate,interval,pageNo}) {
    this.loading=true;
    console.log("Travel Report",interval,pageNo)
    var postData = {"companyId":companyId,"vehicleId":vehicleId,"fromDate":fromDate,"toDate":toDate,"interval":interval,"offset":offset,"limit":limit,"page":pageNo}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=startstop", "postData="+base64Input);
    this.total = parseInt(response?.data?.data?.TotalCount);
    console.log(response?.data?.data?.TotalCount);
    this.travelReport = response?.data?.data?.startStopData
    this.pageSize = this.travelReport?.length
    console.log("travel " ,response?.data?.data?.startStopData)
    this.loading=false;
  }

  async totalStartStopReport({offset,limit,companyId,fromDate,toDate,vehicleId,interval}) {
    this.loadingData=true
    console.log("vehicletotalPerformance")
    var postData = {"companyId":companyId,"vehicleId":vehicleId,"fromDate":fromDate,"toDate":toDate,interval:interval,offset:offset,limit:limit}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=totalStartStopRep", "postData="+base64Input);   
    this.totalStartStopArray = response?.data?.data
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loadingData=false;
  }

  async exportExcel(getData) {
    console.log("getData", getData);
     let lang = "english";
    console.log( baseURL +
      "index.php?c=vehicle&a=exportstartstopexcel&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      "&userId=" +
      getData.userId+
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang)
   
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportstartstopexcel&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      "&userId=" +
      getData.userId +
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportstartstopcsv&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportstartstoppdf&cid=" +
      getData.companyId+
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportxml(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportstartstopxml&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exporttotalexcel(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportTotalstartstopexcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList+
      "&language=" +
      lang;
  }
  async exporttotalpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportTotalstartstopPdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList+
      "&language=" +
      lang;
  }

}

export default TravelReportStore;
