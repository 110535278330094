import React from "react";
import { Row, Col, Space, Tooltip, message, Button, Modal } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import ListTable from "./ListTable";

import config from "../config";

import withRouter from "../withRouter";
import DeleteConfirm from "./DeleteConfirm";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { Link } from "react-router-dom";
import CompanyStore from "../store/CompanyStore";
import TaxiDefaultParameterStore from "../store/TaxiDefaultParameterStore";
import TaxiDefaultParameterAdd from "./TaxiDefaultParameterAdd";

let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 230;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 140;
    } else if (val.title?.length > 8) {
      val.width = 130;
    } else {
      val.width = 90;
    }
  });

  return arr;
};
class TaxiDefaultParameters extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.taxiDefaultParaStore = new TaxiDefaultParameterStore(this);
    this.companyStore = new CompanyStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      loadData: 1,
      companyId: "",
      comp_value: "",
      listDataUpdate: false,
      openTaxiForm: false,
    };
  }
  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });

      await this.handleSearch();
    }
    this.setState({ pageSize: 50 });

    this.columns = widthAdderFunc([
      {
        title: this.context.translate("_DAY_TIME_FROM_"),
        dataIndex: "dayTimeFrom",
        width: "14%",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        title: this.context.translate("_DAY_TIME_TO_"),
        dataIndex: "dayTimeTo",
        width: "10%",
      },
      {
        title: this.context.translate("_SPEED_DISTANCE_"),
        dataIndex: "Speed_Distance",
        width: "10%",
      },
      {
        title: this.context.translate("_MAX_SPEED_1"),
        dataIndex: "maxSpeed",
        width: "10%",
      },
      {
        title: this.context.translate("_MAX_SPEED_INTERVAL_SEC_"),
        dataIndex: "maxSpeedInterval",
        width: "14%",
      },
      {
        title: this.context.translate("_DIGITAL_INPUT_1"),
        dataIndex: "DigitalInput1",
        width: "9%",
      },
      {
        title: this.context.translate("_DIGITAL_INPUT_2"),
        dataIndex: "DigitalInput2",
        width: "9%",
      },
      {
        title: this.context.translate("_DIGITAL_INPUT_3"),
        dataIndex: "DigitalInput3",
        width: "9%",
      },
      {
        title: this.context.translate("_DIGITAL_INPUT_4"),
        dataIndex: "DigitalInput4",
        width: "9%",
      },
      {
        title: this.context.translate("_FLEET_DASHBORD_SETTING_FUELVOLUME1_"),
        dataIndex: "analogInput1",
        width: "8%",
      },
      {
        title: this.context.translate("_FLEET_DASHBORD_SETTING_FUELVOLUME2_"),
        dataIndex: "analogInput2",
        width: "8%",
      },
      {
        title: this.context.translate("_PRINTER_"),
        dataIndex: "printer",
        width: "6%",
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "action",
        width: "5%",
        render: (_, record) => {
          return (
            <>
              <Space>
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    // title="Delete Record"
                    title={this.context.translate("_DELETE_RECORD_")}
                    color={config.tooltipColorDelete}
                  >
                    <DeleteFilled style={{ color: config.deleteColor }} />
                  </Tooltip>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ]);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const setTaxiData = this.context.viewStateStore.taxiDefaultParaObject;

      if (setTaxiData !== "") {
        await this.taxiDefaultParaStore.loadData({
          companyId: this.state.companyId,
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          page: this.state.currentPage,
        });

        const singleData = await this.companyStore.getCompanyDataById(
          setTaxiData
        );

        await this.setState({ loadList: 1 });

        await this.setState({
          comp_value: singleData,
          companyId: setTaxiData,
        });
      }
      await this.setState({ listDataUpdate: false });
    }
  }

  onEdit = async (record) => {
    await this.context.viewStateStore.setTaxiDefaultParaStates({
      companyId: record.companyId,
    });
    await this.setState({ parameterId: record.parameterId });
    await this.setState({ openTaxiForm: true });
  };

  async onDelete(record) {
    const response = await this.taxiDefaultParaStore.deleteRecord({
      parameterId: record.parameterId,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.taxiDefaultParaStore.loadData({
      companyId: this.state.companyId,
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      page: this.state.currentPage,
    });
    await this.setState({ loadData: 1, loading: false });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      currentPage: 1,
      pageSize: 50,
    });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    console.log(singleData);

    await this.setState({ comp_value: singleData });

    await this.handleSearch();
    await this.setState({ loadList: 1 });
  }

  closeModal = () => {
    this.setState({ openTaxiForm: false });
  };

  responseMsg = async (resp) => {
    if (resp.success === "S") {
      message.success(resp.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openTaxiForm: false });
      await this.handleSearch();
    }
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_TAXI_DEFAULT_PARAMETERS_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Tooltip
                  placement="left"
                  //  title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({ openTaxiForm: true, parameterId: "" })
                    }
                  ></Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  value={this.state.comp_value ? this.state.comp_value : null}
                  style={{ width: 200 }}
                />
              </Space>
            </Col>
          </Row>
          <ListTable
            currentPage={this.state.currentPage}
            total={this.taxiDefaultParaStore?.total}
            columns={this.columns}
            pageSize={this.state.pageSize}
            dataSource={this.taxiDefaultParaStore?.taxiParameterList}
            loading={this.taxiDefaultParaStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openTaxiForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal"
            title={this.context.translate("_TAXI_DEFAULT_PARAMETERS_DETAILS_")}
            centered
            open={this.state.openTaxiForm}
            footer={null}
            onCancel={() => {
              this.setState({ openTaxiForm: false, parameterId: "" });
            }}
            width={665}
          >
            <div>
              <TaxiDefaultParameterAdd
                parameterId={this.state.parameterId}
                companyId={this.state.companyId}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
TaxiDefaultParameters.contextType = StoreContext;
export default observer(withRouter(TaxiDefaultParameters));
