import { Popconfirm } from "antd";
import React, { useState, useContext } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import { observer } from "mobx-react";

const DeleteConfirm = observer((props) => {
  const storeContext1 = useContext(StoreContext);
  return (
    <Popconfirm
      {...props}
      placement="topLeft"
      // title="Delete Record"
      title={storeContext1.translate("_DELETE_RECORD_")}
      // description="Are you sure you want to delete record?"
      description={storeContext1.translate("_confirmation_")}
      // cancelText="No"
      cancelText={storeContext1.translate("_FLEET_RULELIST_CRONRULE_NO_")}
      // okText="Yes"
      okText={storeContext1.translate("_FLEET_RULEDETAIL_ACTIVEDDL_YES_")}
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
    />
  );
});

export default withRouter(DeleteConfirm);
