import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from 'mobx';
import { act } from 'react-dom/test-utils';

class VehicleLiveLocationStore {
  vehicleLiveLocationVehicles=[]
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        vehicleLiveLocationVehicles: observable,
        loading:observable,
        total:observable,
        loadData:action,
        getLiveLocationData:action,
        addData:action,
        deleteRecord:action,
        getVehicleId:action
    });
     this.rootStore = rootStore;
    
  }

 

  async loadData({ companyId,offset,searchField, limit,page}) {
    console.log("vehicle list")
    this.loading=true
    var postData = {"companyId":companyId,"searchField":searchField,"offset":offset,"limit":limit,"page":page}
   
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=liveLocation&a=getLiveLocation", "postData="+base64Input);
   
    console.log(response?.data?.data?.liveLocation)
    this.vehicleLiveLocationVehicles = response?.data?.data?.liveLocation
    this.total=parseInt(response?.data?.data.TotalCount)
    this.loading=false
  }

  async addData(liveLocationData){
    console.log(" data",liveLocationData)
    var postData = liveLocationData
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=liveLocation&a=update", "postData="+base64Input);
     console.log(response)
     return response
  
  }

  async getLiveLocationData(id){
    console.log("getlivelocationdata",id)
    var postData = {"id":id,offset:0,limit:1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=liveLocation&a=getLiveLocation", "postData="+base64Input);
    console.log("response",response.data)
    return response.data.data.liveLocation[0]
}

async deleteRecord({ id }) {
  var postData = { "id": id }
  const base64Input = await base64_encode(JSON.stringify(postData));
  var response = await postApi.getClient().post("index.php?c=liveLocation&a=delete", "postData=" + base64Input);
  return response;

}

 //Use livelocation url

 async getVehicleId(vehicleId) {
  console.log("vehicleId", vehicleId)
  var postData = { vehicleId: vehicleId };
  const base64Input = await base64_encode(JSON.stringify(postData));
  var response = await postApi
    .getClient()
    .post(
      "index.php?c=liveLocation&a=getVehicleId",
      "postData=" + base64Input
    );
  console.log(response, "post");
  return response?.data?.data;
}


}

export default VehicleLiveLocationStore;
