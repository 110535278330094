import React from "react";
import {
  Form,
  DatePicker,
  Input,
  Select,
  Button,
  Space,
  Row,
  Col,
  Dropdown,
  message,
  Upload,
  Modal,
  Tooltip,
  Progress,
  Image,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import DisplayLocationMap from "./DisplayLocationMap";
import {
  DeleteFilled,
  SearchOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import config from "../config";

import withRouter from "../withRouter";
import DeleteConfirm from "./DeleteConfirm";
import CompanyAutocomplete from "./CompanyAutocomplete";
import RouteManagementAdd from "./RouteManagementAdd";
import ListTable from "./ListTable";

import RouteManagementStore from "../store/RouteManagementStore";

import DriversStore from "../store/DriversStore";
import VehicleStore from "../store/VehicleStore";
import dayjs from "dayjs";
import CompanyStore from "../store/CompanyStore";
let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 230;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 130;
    } else if (val.title?.length > 8) {
      val.width = 110;
    } else {
      val.width = 80;
    }
  });

  return arr;
};

class RouteManagementList extends React.Component {
  constructor(props) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.RouteManagementStore = new RouteManagementStore(this);
    this.companyStore = new CompanyStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      loadList: 0,
      warehouseList: [],
      companyId: "",
      showUpload: false,
      showDeleteModal: false,
      // list search component
      vehicleId: "",

      deliveryDateFrom: dayjs().format("YYYY-MM-DD"),
      deliveryDateTo: dayjs().format("YYYY-MM-DD"),
      driverName: "",
      salesExecutive: "",
      customerName: "",
      showModal: false,
      jobId: "",

      showmap: false,

      companyVal_Lab: "",
      openRouteManagementForm: false,
      listDataUpdate: false,
      Id: "",
      showAlertModal: false,

      fileList: [],
      uploading: false,
      uploadProgress: 0,
      showCancelJobModal: false,
      cancelJobId: "",
      // excelFile: '',
    };
  }
  onEdit = async (record) => {
    console.log(record)
    const Id = record.Id;

    if (Id) {
      await this.context.viewStateStore.setRouteManagementStates(
        this.state.companyId,
       
      );
      await this.setState({ Id: record.Id });
      await this.setState({ openRouteManagementForm: true });
    }

  };

  onDelete = async (record) => {
    const response = await this.RouteManagementStore.deleteRecord({
      Id: record.Id,
      companyId: record.companyId,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  };
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      
    }

    this.setState({ pageSize: 50 });
    this.menuProps = {
      items: [
        {
          // label: "Driver",
          label: this.context.translate("_FLEET_MANAGEVEHICLE_DRIVER_TAB_"),
          key: "driver",
        },
        {
          // label: "Sales Person",
          label: this.context.translate("_SALES_PERSON_"),
          key: "salesPerson",
        },
      ],
      onClick: (val) => {
        if (val.key == "driver") {
          this.RouteManagementStore.sampleExcelDriver();
        }
        if (val.key == "salesPerson") {
          this.RouteManagementStore.sampleExcelSalesPerson();
        }
      },
    };

    this.columns = widthAdderFunc([
           
      {
        title: this.context.translate("_ROUTE_ID_"),
        dataIndex: "Id",
        width: "15%",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        }
      },
      {
        title: this.context.translate("_ROUTE_NAME_"),
        dataIndex: "routeName",
        width: "15%",
      },
      {
        title: this.context.translate("_START_POINT_"),
        dataIndex: "startPoint",
        width: "15%",
      },
      {
        title: this.context.translate("_END_POINT_"),
        dataIndex: "endPoint",
        width: "15%",
      },
      {
        title: this.context.translate("_TRIP_DISTANCE_"),
        dataIndex: "tripDistance",
        width: "15%",
      },
      {
        title: this.context.translate("_TRIP_DURATION_"),
        dataIndex: "tripDuration",
        width: "15%",
      },
      {
        title: this.context.translate("_TRIP_COST_"),
        dataIndex: "tripCost",
        width: "15%",
      },
      {
        // title: "Action",
        title: this.context.translate("_ACTION_"),
        dataIndex: "groupname",
        render: (_, record) => {
          return (
            <>
              <Space>
                
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      // title="Delete"
                      title={this.context.translate(
                        "_FLEET_COMPANY_COMPANYLIST_DELETE_"
                      )}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
                
              </Space>
            </>
          );
        },
        width: "10%",
      },
    ]);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.showmap !== prevState.showma) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const setJobData = this.context.viewStateStore.RouteManagementObject;

      if (setJobData.companyId !== "") {
        await this.RouteManagementStore.loadData({
             companyId: setJobData.companyId,
        
        });

        const data = await this.RouteManagementStore.getAllVehicles(
          setJobData.companyId
        );

        

        const singleData = await this.companyStore.getCompanyDataById(
          setJobData.companyId
        );

        await this.setState({ loadList: 1 });

        await this.setState({
          companyVal_Lab: singleData,
          companyId: setJobData.companyId,
        });
      }
      await this.setState({ listDataUpdate: false });
    }
  }
  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }
  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      vehicleId: "",
      salesExecutive: "",
      driverName: "",
      jobId: "",
    });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ companyVal_Lab: singleData, companyId: comp.value });

    

    await this.handleSearch();
    await this.setState({ loadList: 1 });
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.RouteManagementStore.loadData({
         companyId: this.state.companyId,
     
    });

    await this.setState({ loadList: 1 });
  }
  searchClick = async () => {
    if (!this.state.companyId) {
      this.setState({ companyFieldHighlight: true });
    } else {
      this.setState({ companyFieldHighlight: false });
    }
    if (this.state.companyId) {
      this.setState({ companyFieldHighlight: false, loading: true });

      await this.RouteManagementStore.loadData({
       
        companyId: this.state.companyId,
        
      });
      await this.setState({ loadList: 1 });
    }
  };


 
  deleteJob = async (value) => {
    let flag = 0;

    const deleteJob = value.forEach(async (id) => {
      const data = {
        Id: id,
        companyId: this.state.companyId,
      };

      const response = await this.RouteManagementStore.deleteRecord(data);

      if (response?.data?.success === "S") {
        if (flag === 0) {
          message.success(response.data.message);
        }

        this.handleSearch();
        flag++;
      } else {
        message.error(response.data.message);
      }
    });
  };

  show = () => {
    this.setState({ showmap: true });
  };


  responseMsg = async (resp) => {
    if (resp.success == "S") {
      message.success(resp.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openRouteManagementForm: false });

      await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    } else {
      message.error(resp.message);
    }
  };

  closeModal = () => {
    this.setState({ openRouteManagementForm: false, Id: "" });
  };



  render() {
    const companyInputBox = this.state.companyFieldHighlight
      ? { border: "2px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_ROUTE_MANAGEMENT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  placement="left"
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({ openRouteManagementForm: true, Id: "" })
                    }
                  ></Button>
                </Tooltip>

              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={23}>
              <Space>
                <CompanyAutocomplete
                  value={
                    this.state.companyVal_Lab ? this.state.companyVal_Lab : null
                  }
                  onChange={(value) => this.handleChange(value)}
                  // allowClear={true}
                  style={{ width: 140 }}
                  // style={companyInputBox}
                  status={this.state.companyFieldHighlight ? "error" : false}
                />

              </Space>
            </Col>
            <Col span={2}>
              <Space
                style={{
                  float:
                    this.context.locale.direction === "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            rowSelection={{
              selectedRowKeys: this.state.jobId,
              onChange: async (selectedRowKeys, selectedRows) => {
                // await this.setState({})
                await this.setState({ jobId: selectedRowKeys });
              },
            }}
            rowKey="Id"
            current={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.RouteManagementStore?.total}
            columns={this.columns}
            dataSource={this.RouteManagementStore?.RouteManagement}
            loading={this.RouteManagementStore?.loading}
            onChange={this.handleTableChange}
          />

          {this.state.openRouteManagementForm && (
                      <Modal
                        bodyStyle={{ height: "645px" }}
                        className="formShowModal" //  {/* css class */}
                        // title="Add Job Details"
                        title={this.context.translate("_ADD_JOB_DETAILS")}
                        centered
                        open={this.state.openRouteManagementForm}
                        footer={null}
                        onCancel={() => {
                          this.setState({ openRouteManagementForm: false, Id: "" });
                        }}
                        width={665}
                      >
                        <div>
                          <RouteManagementAdd
                            Id={this.state.Id}
                            companyId={this.state.companyId}
                            response={this.responseMsg}
                            closeModal={this.closeModal}
                          />
                        </div>
                      </Modal>
                    )}

         
        </div>
      </>
    );
  }
}
RouteManagementList.contextType = StoreContext;
export default observer(withRouter(RouteManagementList));
