import { observer } from "mobx-react";
import React, { Component } from "react";
import withRouter from "../withRouter";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Empty,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { StoreContext } from "../store/RootStore";
import DistanceGraphStore from "../store/DistanceGraphStore";
import dayjs from "dayjs";

import DashboardUsageGraph from "./DashboardUsageGraph";
import UsageGraphStore from "../store/UsageGraphStore";
import VehicleStore from "../store/VehicleStore";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";

const { Option } = Select;
class UsageGraph extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.distanceGraphStore = new DistanceGraphStore(this);
    this.usageGraphStore = new UsageGraphStore(this);
    this.vehicleStore = new VehicleStore(this);

    this.state = {
      selectAllVehicles: false,
      selectedVehicles: [],
      update: 0,
      companyId: "",
      distance: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      loadData: 0,
      horizontal: false,
      vertical: false,
      displayVehicleNo: [],
      newToDate: dayjs().format("DD-MM-YYYY hh:mm A"),

      newFromDate: dayjs().startOf("day").format("YYYY-MM-DD hh:mmA"),
      // newFromDate:dayjs().format("DD-MM-YYYY 12:00 A"),
      Notcompany: false,
      Notasset: false,
      loadingSpin: false,
    };
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });

    await this.setState({ selectedVehicles: [], selectAllVehicles: false });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    console.log(this.distanceGraphStore?.vehiclesArray);
    // console.log("data", data);

    this.setState({ loadData: 1 });
  }

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      const allVehiclesArr = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleNo;
        }
      );
      await this.setState({
        selectedVehicles: allVehicles,
        displayVehicleNo: allVehiclesArr,
      });
    } else {
      await this.setState({ selectedVehicles: [], displayVehicleNo: [] });
    }
  };

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      // this.setState({mandatory:false})
      await this.setState({ loadingSpin: true });

      this.setState({ Notcompany: false, Notasset: false });
      const data = await this.usageGraphStore.loadUsageGraphData({
        companyId: this.state.companyId,
        vehicleId: this.state.selectedVehicles,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        distance: this.state.distance,
        // pageNo: this.state.pageNo,
      });
      console.log(data);
      this.setState({ loadData: 1 });
      await this.setState({ loadingSpin: false });
    }
  };

  handleDateChange = (date) => {
    if (date === null) {
      this.setState({ fromDate: "" });
    } else {
      // const formattedDate = date.startOf("day");
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({ fromDate: date.format(format) });
      console.log(date.format(format));
      // const displayFormat = "YYYY-MM-DD HH:mm:ssA";
      // console.log(displayFormat)
      const newFromDate = date;
      const newDateFormat = "DD-MM-YYYY hh:mm A";
      this.setState({ newFromDate: newFromDate.format(newDateFormat) });
    }
  };

  handleToDateChange = (date) => {
    if (date === null) {
      this.setState({ toDate: "" });
    } else {
      const formattedDate = date;
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({ toDate: formattedDate.format(format) });
      console.log(formattedDate.format(format));
      const newToDate = date;
      const newDateFormat = "DD-MM-YYYY hh:mm A";
      this.setState({ newToDate: newToDate.format(newDateFormat) });
    }
  };

  // CustomTooltip = ({ active, payload }) => {
  //   console.log(active, payload);
  //   if (active && payload && payload.length) {
  //     const data = payload[0].payload;
  //     console.log(data);
  //     return (
  //       <div
  //         style={{ border: "1px solid", background: "white", padding: "10px" }}
  //       >
  //         {/* <b>{`Vehicle No: ${data.vehicleNo}`}</b>
  //         <div>{`Driver Mob: ${data.driverphone}`}</div>
  //         <div>{`Name: ${data.name}`}</div>
  //         <div>{`Idle: ${data.Travel}`}</div>
  //         <div>{`Stop: ${data.Stop}`}</div>
  //         <div>{`Travel: ${data.Idle}`}</div>
  //         <div>{`ExIdle: ${data.Excessive}`}</div>
  //         <div>{`Total: ${data.TotalTravelDistance}`}</div> */}
  //          <b>{`: ${data.vehicleNo}`}</b>
  //         <div>{`${this.context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_")}: ${data.driverphone}`}</div>
  //         <div>{`${this.context.translate("_FLEET_DASHBORD_NEWPOI_NAME_")}: ${data.name}`}</div>
  //         <div>{`${this.context.translate("_IDLE_")}: ${data.Travel}`}</div>
  //         <div>{`${this.context.translate("_STOP_")}: ${data.Stop}`}</div>
  //         <div>{`${this.context.translate("_TRAVEL_")}: ${data.Idle}`}</div>
  //         <div>{`${this.context.translate("_EXIDLE_")}: ${data.Excessive}`}</div>
  //         <div>{`${this.context.translate("_TOTAL_")}: ${data.TotalTravelDistance}`}</div>
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.vehicleStore.getVehiclesCompanyId(
        this.props.getUserData().userCompanyId
      );
      await this.setState({ update: 1 });
    }
  }
  render() {
    return (
      <>
        <Spin spinning={this.state.loadingSpin} fullscreen>
          <div style={{ padding: 0 }}>
            <Row className="headerFixed" style={{ height: "50px" }}>
              <Col span={12}>
                {/* <div className="heading">Usage Graph</div> */}
                <div className="heading">
                  {this.context.translate("_USAGE_GRAPH_")}
                </div>
              </Col>
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>
          </div>
          <div
            style={{
              paddingTop: "10px",
              paddingRight: "24px",
              paddingLeft: "24px",
            }}
          >
            <Row className="listInputRow">
              <Col span={22}>
                <Space>
                  <CompanyAutocomplete
                    onChange={this.handleChange}
                    allowClear={true}
                    style={{ width: 175 }}
                    status={this.state.Notcompany ? "error" : false}
                  />

                  {this.props.getUserData().isadmin === 1 && (
                    <DeviceGroupsForVehicles
                      vehicleStore={this.vehicleStore}
                      setLoadDta={() => this.setState({ loadData: 1 })}
                      resetVehicles={() =>
                        this.setState({
                          selectedVehicles: [],
                          selectAllVehicles: false,
                        })
                      }
                    />
                  )}

                  <Checkbox
                    checked={this.state.selectAllVehicles}
                    onChange={async (e) => {
                      await this.setState({
                        selectAllVehicles: e.target.checked,
                      });
                      console.log(e.target.checked);
                      await this.handleAllSelectVehicles();
                    }}
                  />
                  {/* All */}
                  {this.context.translate("_ALL_")}

                  <Select
                    mode="multiple"
                    maxTagCount="responsive"
                    value={this.state.selectedVehicles}
                    onChange={async (val, x) => {
                      // console.log(val, "adasasasasd", x);
                      await this.setState({
                        selectedVehicles: val,
                        Notasset: false,
                        vertical: false,
                        horizontal: false,
                      });
                      // x.forEach(async(val) =>
                      //  await this.setState({
                      //     displayVehicleNo: [
                      //       ...this.state?.displayVehicleNo,
                      //       val?.children,
                      //     ],
                      //   })
                      // );
                      await this.setState({
                        displayVehicleNo: x.map((item) => item.children),
                      });

                      if (
                        this.vehicleStore?.getVehiclesUsingCompanyId.length >
                        val.length
                      ) {
                        this.setState({ selectAllVehicles: false });
                      } else {
                        this.setState({ selectAllVehicles: true });
                      }
                    }}
                    // fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                    // placeholder="Asset Name"
                    placeholder={this.context.translate(
                      "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                    )}
                    optionFilterProp="children"
                    // options={this.distanceGraphStore?.vehiclesArray}
                    style={{ width: 175 }}
                    status={this.state.Notasset ? "error" : false}
                    filterOption={(inputValue, option) => {
                      if (option.children) {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {this.vehicleStore?.getVehiclesUsingCompanyId?.map(
                      (val) => {
                        return (
                          <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                        );
                      }
                    )}
                  </Select>
                  <DatePicker
                    showTime
                    format="DD-MM-YYYY hh:mmA"
                    placeholder={this.context.translate("_FROM_DATE_")}
                    //   onChange={this.handleDateChange}
                    value={
                      this.state.fromDate ? dayjs(this.state.fromDate) : null
                    }
                    onChange={(val) => {
                      this.handleDateChange(val);
                    }}
                    disabledDate={(current) => {
                      return current && current > dayjs();
                    }}
                  />
                  <DatePicker
                    showTime
                    format="DD-MM-YYYY hh:mmA"
                    placeholder={this.context.translate("_TO_DATE_")}
                    onChange={this.handleToDateChange}
                    value={this.state.toDate ? dayjs(this.state.toDate) : null}
                    disabledDate={(current) => {
                      return current && current > dayjs();
                    }}
                  />
                  <Button
                    type="primary"
                    onClick={async () => {
                      await this.showData();
                      await this.setState({
                        vertical: true,
                        horizontal: false,
                      });
                      // console.log(this.distanceGraphStore?.distanceGraph);
                    }}
                  >
                    {/* Vertical */}
                    {this.context.translate("_VERTICAL_")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={async () => {
                      await this.showData();
                      await this.setState({
                        horizontal: true,
                        vertical: false,
                      });
                      // console.log(this.distanceGraphStore?.distanceGraph);
                    }}
                  >
                    {/* Horizontal */}
                    {this.context.translate("_HORIZONTAL_")}
                  </Button>
                </Space>
              </Col>
              <Col span={2}>
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>

            <div
              style={{
                paddingTop: "10px",
                paddingRight: "24px",
                paddingLeft: "24px",
              }}
            >
              {this.usageGraphStore.usageGraph?.length > 0 && (
                <Row className="listInputRow">
                  <Col span={22}>
                    {/* <div className="heading">Usage Graph</div> */}
                    <div className="heading">
                      {this.context.translate("_USAGE_GRAPH_")}
                    </div>
                    {/* <b>Vehicle No : {this.state.displayVehicleNo?.join(",")}</b><br/> */}
                    <b>
                      {this.context.translate(
                        "_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"
                      )}{" "}
                    </b>
                    :<span> {this.state.displayVehicleNo?.join(",")}</span>
                    <br />
                    <b>
                      {this.context.translate("_FROM_DATETIME_")} :{" "}
                      {this.state.newFromDate}
                    </b>
                    <br />
                    <b>
                      {this.context.translate("_TO_DATETIME_")} :{" "}
                      {this.state.newToDate}
                    </b>
                    <br />
                  </Col>
                  <Col
                    span={2}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <Space
                      style={{
                        float:
                          this.context.locale.direction == "ltr"
                            ? "right"
                            : "left",
                      }}
                    ></Space>
                  </Col>
                </Row>
              )}
            </div>
            {this.state.vertical || this.state.horizontal ? <>
            
            {this.usageGraphStore.usageGraph?.length > 0 && (
                
              <>
             
                {this.state.vertical && (
                  <DashboardUsageGraph
                    label={{
                      value: this.context.translate("_ASSET_NAME_"),
                      position: "insideBottom",
                    }}
                    data={this.usageGraphStore.usageGraph}
                    layout="horizontal"
                    infoObj={{
                      yNumberType: "number",
                      yDataKey: "",
                      xNumberType: "category",
                      xDataKey: "vehicleNo",
                    }}
                  />
                )}
                {this.state.horizontal && (
                  <DashboardUsageGraph
                    label={{
                      value: this.context.translate("_ASSET_NAME_"),
                      position: "insideBottom",
                    }}
                    data={this.usageGraphStore.usageGraph}
                    layout="vertical"
                  />
                )}
              </>
            )}
            </> : <Empty></Empty>} 
            {/* {this.usageGraphStore.usageGraph?.length > 0 ? (
              this.state.vertical ? (
                <DashboardUsageGraph
                  label={{
                    value: this.context.translate("_ASSET_NAME_"),
                    position: "insideBottom",
                  }}
                  data={this.usageGraphStore.usageGraph}
                  layout="horizontal"
                  infoObj={{
                    yNumberType: "number",
                    yDataKey: "",
                    xNumberType: "category",
                    xDataKey: "vehicleNo",
                  }}
                />
              ) : (
                <DashboardUsageGraph
                  label={{
                    value: this.context.translate("_ASSET_NAME_"),
                    position: "insideBottom",
                  }}
                  data={this.usageGraphStore.usageGraph}
                  layout="vertical"
                />
              )
            ) : (
              <Empty></Empty>
            )} */}
          </div>
        </Spin>
      </>
    );
  }
}
UsageGraph.contextType = StoreContext;
export default observer(withRouter(UsageGraph));
