import React, { Component } from "react";

import {
  Form,
  Button,
  Input,
  Row,
  Col,
  Spin,
  Table,
  Tooltip,
  Space,
  Select,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";

import CompanyAutocomplete from "./CompanyAutocomplete";

import CompanyStore from "../store/CompanyStore";
import { DeleteFilled } from "@ant-design/icons";

import config from "../config";
import SubGroupStore from "../store/VehicleSubgroupStore";
const { Option } = Select;
class VehicleSubGroupAdd extends Component {
  formRef = React.createRef();

  constructor(props, context) {
    super(props);
    this.state = {
      loading: false,

      companyId: "",
      deviceSubGroupId: -1,
      loadingSpin: false,
      assignVehiclesArray: [],
      selectedAssignedVehicleId: [],
      searchVehicleArray: [],
      selectedSearchVehicleId: [],

      tableData: [],
    };
    this.subGrpStore = new SubGroupStore(this);
    this.companyStore = new CompanyStore(this);

    this.columns = [
      {
        // title: context.translate("_VEHICLE_"),
        title: "Company Vehicles",
        dataIndex: "vehicleNo",
      },
    ];

    this.assignedColumns = [
      {
        title: "Group Vehicles",
        dataIndex: "vehicleNo",
      },
      {
        title: "Remove",
        dataIndex: "action",
        render: (text, record, index) => {
          // console.log(text, record, index);
          return (
            <>
              <Space>
                <Tooltip
                  // title="Remove"
                  title={this.context.translate("_REMOVE_")}
                  color={config.tooltipColorDelete}
                >
                  <DeleteFilled
                    style={{ color: config.deleteColor }}
                    onClick={async () => {
                      await this.onDeleteRow(index, Number(record.vehicleId));
                    }}
                  />
                </Tooltip>
              </Space>
            </>
          );
        },
      },
    ];
  }

  onDeleteRow = (index) => {
    const filterData = this.state.assignVehiclesArray.filter(
      (val, indexx) => indexx !== index
    );

    const selectedAssignedVehicleId = filterData.map((item) => item.vehicleId);
    const selectedSearchVehicleId = this.state.selectedSearchVehicleId.filter(
      (id) => selectedAssignedVehicleId.includes(id)
    );

    this.setState({
      assignVehiclesArray: filterData,
      selectedAssignedVehicleId: selectedAssignedVehicleId,
      selectedSearchVehicleId: selectedSearchVehicleId,
    });
  };

  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      this.context.viewStateStore.setSubgroupStates({
        companyId: this.props.getUserData().userCompanyId,
      });

      await this.subGrpStore.getGroupData(
       this.props.getUserData().userCompanyId,
      );
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
    }

    const deviceSubGroupId = this.props.deviceSubGroupId;

    console.log(deviceSubGroupId);
    // const companyId=this.props.companyId
    // console.log(companyId)

    if (deviceSubGroupId) {
      await this.filldata(deviceSubGroupId);
      await this.setState({ deviceSubGroupId: deviceSubGroupId });
    }
  }

  filldata = async (deviceSubGroupId) => {
    await this.setState({ loadingSpin: true });
    const getVehicleGroup = await this.subGrpStore.getSubGroupData(
      deviceSubGroupId,
      this.props.companyId
    );

    await this.subGrpStore.getGroupData(this.props.companyId);

    const vehicleTableData = await this.subGrpStore.getGroupVehiclesData(
      getVehicleGroup.groupId
    );

    this.setState({ tableData: vehicleTableData });

    const vehicleData =
      getVehicleGroup.vehicles?.map((val) => val.vehicleId) || [];

    const assignedVehiclesArray = vehicleTableData.filter((vehicle) =>
      vehicleData.includes(vehicle.vehicleId)
    );
    console.log(assignedVehiclesArray);
    await this.setState({
      assignVehiclesArray: assignedVehiclesArray,

      selectedAssignedVehicleId: vehicleData,
    });

    const singleData = await this.companyStore.getCompanyDataById(
      this.props.companyId
    );

    await this.setState({
      companyId: getVehicleGroup.companyId,
      deviceSubGroupId: getVehicleGroup.deviceSubGroupId,
    });
    this.formRef.current.setFieldsValue({
      deviceSubGroupId: getVehicleGroup.deviceSubGroupId,
      companyId: singleData,
      groupId: getVehicleGroup.groupId,
      inchargeName: getVehicleGroup.inchargeName,
      subgroupname: getVehicleGroup.subgroupname,
      email: getVehicleGroup.email,
      // vehicle: vehicleData,
    });

    await this.setState({ loadingSpin: false });
  };

  onDeviceDetailsSubmit = async (values) => {
    this.setState({ loading: true });

    const selectedData = this.state.assignVehiclesArray.map((val) => {
      return val.vehicleId;
    });
    console.log(selectedData);

    const data = {
      ...values,
      companyId: this.state.companyId,
      deviceSubGroupId: this.state.deviceSubGroupId,

      tokenId: "",
      vehicle: selectedData,
    };
    console.log(data);
    const resp = await this.subGrpStore.addData(data);
    await this.props.response(resp);
    // if (resp.data.success == "S") {
    //   message.success(resp.data.message);
    //   this.props.navigate("/vehiclegrouplist");
    // } else if (resp.data.success == "F0") {
    //   resp.data.errors.map((value) => {
    //     message.error(value["username"]);
    //   });
    // }
    this.setState({ loading: false });
  };

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    if (!this.props.deviceSubGroupId) {
      this.context.viewStateStore.setSubgroupStates(comp.value);
    }
    await this.subGrpStore.getGroupData(comp.value);

    this.setState({ loadData: 1 });
  }

  closeModal = () => {
    this.props.closeModal();
  };

  onSearchVehicleNo = async (value) => {
    let inputData = value.toLowerCase();

    const data = this.subGrpStore.groupVehicleList.filter((vehicleNo) => {
      return vehicleNo.vehicleNo.toLowerCase().includes(inputData);
    });

    await this.setState({ tableData: data });
  };

  handleVehGroupData = async (val) => {
    const data = await this.subGrpStore.getGroupVehiclesData(val);

    this.setState({ tableData: data });
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              layout="vertical"
              ref={this.formRef}
              onFinish={this.onDeviceDetailsSubmit}
              // {...this.layout}
              name="nest-message"
              className="formClass" //change
            >
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {/* css class */}
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => this.handleChange(value)}
                        style={{ width: "100%" }}
                        value={this.state.companyId}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="groupId"
                    label={this.context.translate("_GROUP_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      //   options={this.subGrpStore.vehGrpData}
                      //   fieldNames={{label:'groupname',value:'deviceGroupId'}}
                      filterOption={(inputValue, option) => {
                        if (option.children) {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) >= 0
                          );
                        }
                      }}
                      onSelect={(val) => this.handleVehGroupData(val)}
                    >
                      {this.subGrpStore.vehGrpData?.map((val) => {
                        return (
                          <Option value={val.deviceGroupId}>
                            {val.groupname}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="subgroupname"
                    label={this.context.translate("_SUB_GROUP_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Input
                    placeholder={this.context.translate("_SEARCH_VEHICLE_")}
                    style={{ marginBottom: "10px", marginTop: "22px" }}
                    onChange={(e) => {
                      this.onSearchVehicleNo(e.target.value);
                      this.setState({ searchInputData: e.target.value });
                    }}
                  />

                  <Table
                    bordered
                    columns={this.columns}
                    dataSource={this.state.tableData}
                    pagination={false}
                    rowKey={"vehicleId"}
                    scroll={{
                      x: 100,
                      y: 300,
                    }}
                    rowSelection={{
                      selectedRowKeys: this.state.selectedAssignedVehicleId,
                      onChange: async (selectedRowKeys, selectedRows) => {
                        await this.setState({
                          assignVehiclesArray: selectedRows,

                          selectedAssignedVehicleId: selectedRowKeys,
                        });
                      },
                    }}
                  />
                </Col>
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="inchargeName"
                    label={this.context.translate("_INCHARGE_NAME_")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    label={this.context.translate("_EMAIL_")}
                  >
                    <Input />
                  </Form.Item>

                  <br />

                  <Table
                    bordered
                    columns={this.assignedColumns}
                    dataSource={this.state.assignVehiclesArray}
                    pagination={false}
                    rowKey={"vehicleId"}
                    scroll={{
                      x: 100,
                      y: 350,
                    }}
                    rowSelection={{
                      selectedRowKeys: this.state.selectedSearchVehicleId,
                      onChange: async (selectedRowKeys, selectedRows) => {
                        console.log(selectedRowKeys, selectedRows);
                        // await this.setState({})
                        await this.setState({
                          selectedSearchVehicleId: selectedRowKeys,
                        });
                      },
                    }}
                  />
                </Col>
              </Row>
              <div className="formBtnGroup">
                <Button
                  type="primary"
                  className="formCancelButton"
                  onClick={(e) => {
                    // this.formRef.current?.resetFields();
                    this.closeModal();
                  }}
                >
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>

                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

VehicleSubGroupAdd.contextType = StoreContext;
export default observer(withRouter(VehicleSubGroupAdd));
