import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Modal,
  Row,
  Select,
  Tree,
} from "antd";
import React, { Component } from "react";
import CompanyAutocomplete from "./CompanyAutocomplete";
import moment from "moment";
import { addMinutes, format } from "date-fns";
import dayjs from "dayjs";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import { observer } from "mobx-react";
import VideoMonitoringStore from "../store/VideoMonitoringStore";
import conf from "../config";
import { ReactFlvPlayer } from "react-flv-player";
import { CarOutlined, DesktopOutlined } from "@ant-design/icons";
const timeArray = [];
const currentDate = moment().format("YYYY-MM-DD");

const startTime = new Date(2000, 0, 1, 0, 0); // 12:00 AM
const endTime = new Date(2000, 0, 1, 23, 30); // 11:45 P

for (let time = startTime; time <= endTime; time = addMinutes(time, 30)) {
  // const formattedTime = format(time, 'h:mm aa');
  const formattedTime = format(time, "HH:mm:ss");
  var ob = {
    formattedTime: formattedTime,
    time: time,
  };
  timeArray.push(ob);
}

class VideoMonitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoUrlArray: [],
      companyId: "",
      iframeSrcs: [],
      openVideoModal: false,
      selectedIframe: null,
    };
    this.videoMonitoringStore = new VideoMonitoringStore(this);
  }
  onSubmit = async (obj) => {
    console.log(obj);

    var fromDate = dayjs(obj.fromDate).format("YYYY-MM-DD");
    var toDate = dayjs().format("YYYY-MM-DD");
    var fromTime = obj.fromTime;
    var toTime = dayjs().format("HH:mm:ss");

    // console.log(fromDate, toDate, fromTime, toTime);
    var data = {
      channelId: obj.channelId,
      companyId: this.state.companyId,
      vehicleId: obj.vehicleId,
      starttime: fromDate + " " + fromTime,
      endtime: toDate + " " + toTime,
    };
    console.log(data);

    const resp = await this.videoMonitoringStore.getPlayBackVideoNew(data);
    console.log(resp);
    let x = [
      { url: resp.url1 },
      { url: resp.url2 },
      { url: resp.url3 },
      { url: resp.url4 },
    ];
    await this.setState({ videoUrlArray: x });
  };

  handleChange = async (v) => {
    await this.setState({ companyId: v.value });
    await this.videoMonitoringStore.getDevices({ companyId: v.value });
    await this.setState({ update: 1 });
  };

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.videoMonitoringStore.getDevices({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ update: 1 });
    }
  }

  handleVideoChange = async (info) => {
    const data = await this.videoMonitoringStore.getDevices({
      companyId: this.state.companyId,
    });

    const host = data.cmsvssWebHost;
    const imei = data.full_imei;
    const UserName = data.cmsvssUserName;
    const Password = data.cmsvssPassword;
    const channelId = info.node.channelId;

    if (info.checked) {
      if (data.MDVR === "CMS") {
        // Construct iframe source URL
        const iframeSrc = `${host}/808gps/open/player/video.html?lang=en&devIdno=${imei}&account=${UserName}&password=${Password}&channel=1&chns=${
          channelId - 1
        }`;
        {
          /* <iframe src='" + host + "/808gps/open/player/video.html?lang=en&devIdno=" + imei + "&account=" + username + "&password=" + password + "&channel=1&chns=" + (channelId - 1) + "' width='100%' height='100%'  scrolling='no' allow='fullscreen'></iframe> */
        }
        // Set iframe source in state
        this.setState({
          iframeSrc,
        });
      } else {
        const port = data.cmsvssWebPort;
        const token = data.token;

        let iframeSrc = "";

        if (port != null) {
          iframeSrc = `${host}:${port}/vss/apiPage/RealVideo.html?token=${token}&deviceId=${imei}&chs=${channelId}&stream=0&wnum=0&buffer=0&panel=1`;
        } else {
          iframeSrc = `${host}${token}&deviceId=${imei}&chs=${channelId}&stream=0&wnum=0&buffer=0&panel=1`;
        }

        // this.setState((prevState) => ({
        //   iframeSrcs: [...prevState.iframeSrcs, iframeSrc], // add new source
        // }));

        this.setState((prevState) => ({
          iframeSrcs: [
            ...prevState.iframeSrcs,
            { key: info.node.key, src: iframeSrc, title: info.node.title },
          ],
        }));

        // this.setState({
        //   iframeSrc,
        // });
      }
    } else {
      // Remove video source when the checkbox is unchecked
      this.setState((prevState) => ({
        iframeSrcs: prevState.iframeSrcs.filter(
          (iframe) => iframe.key !== info.node.key
        ),
      }));
    }
  };

  onVideoChange = async (info) => {
    // console.log(info)
    let selectedNodes = info.checkedNodes;
    // console.log(selectedNodes)
    if (info.checked) {
      selectedNodes.forEach(async (val) => {
        // console.log(val)
        if (val.deviceId === "16") {
          // (deviceId == "16") not need token
          var url =
            "http://jimi1.tracker.sa:8881/live/0/" + val?.full_imei + ".flv";
          await this.setState({
            videoUrlArray: [
              ...this.state.videoUrlArray,
              { flv: true, channelId: val.channelId, url: url },
            ],
          });
        } else if (val.deviceId === "6") {
          let url =
            conf.mediaUrl +
            "/vss/apiPage/RealVideo.html?token=" +
            val?.token +
            "&deviceId=" +
            val?.full_imei +
            "&chs=" +
            val.channelId +
            "&stream=0&wnum=1&panel=0";
          await this.setState({
            videoUrlArray: [
              ...this.state.videoUrlArray,
              { channelId: val.channelId, url: url },
            ],
          });
        }
      });
    } else {
      let filterRecord = this.state.videoUrlArray?.filter(
        (val) => Number(val.channelId) !== Number(info.node?.channelId)
      );
      console.log(filterRecord);
      await this.setState({ videoUrlArray: filterRecord });
    }
  };

  renderTreeNodes = (data) => {
    // console.log(data);
    // const vehicleName = data.map((val)=>{
    //   return val.text
    // })
    // console.log(vehicleName)
    return data?.map((item) => {
      // console.log(item,"item")
      const isParent = item?.children && item.children?.length > 0;

      if (isParent) {
        return {
          ...item,
          icon:
            item.onlineStatus === "online" ? (
              <CarOutlined style={{ color: "green" }} /> 
            ) : (
              <CarOutlined />
            ),
          children: this.renderTreeNodes(item.children),
        };
      } else {
        return {
          ...item,
          icon:
            item.onlineStatus === "online" ? (
              <DesktopOutlined style={{ color: "green" }} />
            ) : (
              <DesktopOutlined />
            ),
        };
      }
    });
  };

  openModal = (iframe) => {
    this.setState({ openVideoModal: true, selectedIframe: iframe });
  };

  closeModal = () => {
    this.setState({ openVideoModal: false, selectedIframe: null });
  };

  render() {
    return (
      <div>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_VIDEO_MONITORING_")}
              </div>
            </Col>
          </Row>
        </div>

        <Row className="video-monitoring">
          <Col span={4} className="live-video-streaming-block">
            <div className="heading">
              {this.context.translate("_LIVE_VIDEO_STREAMING_")}
            </div>
            <CompanyAutocomplete
              onChange={(v) => this.handleChange(v)}
              style={{ width: "100%", padding: "10px" }}
            />

            <Tree
              checkable
              showIcon
              showLine={true}
              onCheck={(e, info) => {
                // console.log(e, info);
                this.onVideoChange(info);

                // if (info.checked === true) {
                this.handleVideoChange(info);
                // }
              }}
              // onSelect={(v) => console.log(v)}
              treeData={this.renderTreeNodes(
                this.videoMonitoringStore.deviceListArray
              )}
            />
          </Col>

          <Col span={16} style={{ height: "90vh", overflow: "auto" }}>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {this.state.videoUrlArray?.map((val) => {
                return val.flv ? (
                  <Col span={12}>
                    <div style={{ padding: "20px" }}>
                      <ReactFlvPlayer
                        url={val.url}
                        width="100%"
                        height="100%"
                        showControls={false}
                        hasAudio={false}
                        hasVideo={false}
                        isLive={false}
                        isMuted={false}
                      />
                    </div>
                  </Col>
                ) : (
                  <Col span={12}>
                    <div>
                      <iframe
                        style={{ padding: "20px", height: "262px" }}
                        width="100%"
                        height="100%"
                        src={val.url}
                        title="YouTube Video"
                        allowFullScreen
                        // poster="../assets/images/devicePosterImage.jpg"
                      ></iframe>
                    </div>
                  </Col>
                );
              })}
            </div>
            <div
              style={{
                // width: "100%",
                // height: "100%",
                display: "flex", // Enables flexbox layout
                flexWrap: "wrap", // Allows cards to wrap to the next row if necessary
                gap: "20px", // Adds spacing between the cards
                margin: "12px",
              }}
            >
              {/* <div style={{ width: "100%", height: "100%" }}> */}
              {this.state.iframeSrcs.map((iframe) => {
                // console.log();
                return (
                  <Card
                    key={iframe.key}
                    title={
                      <div
                        style={{
                          fontSize: 17,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        // style={{ fontSize: 18 }}
                        className="videoCardTitle"
                      >
                        {iframe.title}
                        <i
                          class="bi bi-arrows-fullscreen"
                          style={{
                            border: "none",
                            fontSize: "17px",
                          }}
                          onClick={() => this.openModal(iframe)}
                        ></i>
                      </div>
                    }
                    style={{ width: 300 }}
                  >
                    <iframe
                      key={iframe.key}
                      src={iframe.src}
                      width="100%"
                      height="100%"
                      padding="10px"
                      scrolling="no"
                      allow="fullscreen"
                      title={`Video Player ${iframe.key}`}
                    />
                  </Card>
                );
              })}
            </div>
          </Col>

          <Col span={4} className="playback-options-block">
            {/* <div className="heading">Playback Options</div> */}
            <div className="heading">
              {this.context.translate("_PLAY_BACK_OPTION_")}
            </div>

            <Form
              // {...layout}
              ref={this.formRef}
              layout="vertical"
              name="nest-messages"
              onFinish={this.onSubmit}
              style={{ padding: "15px" }}
              className="formClass" //change
            >
              <Form.Item
                name="vehicleId"
                label={this.context.translate("_VEHICLES_")}
                rules={[{ required: true }]}
              >
                <Select
                  options={this.videoMonitoringStore.deviceListArray}
                  fieldNames={{ value: "vehicleId", label: "text" }}
                ></Select>
              </Form.Item>
              <Form.Item
                name="channelId"
                // label="Channel"
                label={this.context.translate("_CHANNEL_")}
                rules={[{ required: true }]}
              >
                <Select
                  style={{ height: "44px !important" }}
                  placeholder={this.context.translate("_SELECT_CHANNEL_")}
                  options={[
                    { label: "Channel1", value: 1 },
                    { label: "Channel2", value: 2 },
                    { label: "Channel3", value: 3 },
                    { label: "Channel4", value: 4 },
                    { label: "Channel5", value: 5 },
                    { label: "Channel6", value: 6 },
                    { label: "Channel7", value: 7 },
                    { label: "Channel8", value: 8 },
                  ]}
                  className="search-field"
                  mode="multiple"
                  maxTagCount={"responsive"}
                />
              </Form.Item>

              <Form.Item
                name="fromDate"
                className="blur-input"
                // label="From Date"
                label={this.context.translate("_FROM_DATE_")}
                initialValue={dayjs()}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format={"YYYY-MM-DD"}
                  //   defaultValue={this.state.startDate}

                  // onChange={(date) =>
                  //   this.formRef.current.setFieldsValue({ fromTime: date })
                  // }
                  // value={this.state.startDate}
                />
              </Form.Item>

              <Form.Item
                name="fromTime"
                // label="From Time"
                label={this.context.translate(
                  "_FLEET_RULESELECTORDETAIL_RULE4_FROMTIME_"
                )}
                className="blur-input"
                rules={[{ required: true }]}
                initialValue={"00:00:00"}
              >
                <Select
                  onChange={(val) =>
                    this.formRef.current.setFieldsValue({ fromTime: val })
                  }
                  // allowClear={true}
                >
                  {timeArray.map((data) => {
                    return (
                      <Select.Option
                        key={data.formattedTime}
                        value={data.formattedTime}
                      >
                        {format(data.time, "h:mm aa")}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                name="toDate"
                className="blur-input"
                // label="From Date"
                // label="To Date"
                label={this.context.translate("_TO_DATE_")}
                // initialValue={dayjs()}
              >
                <DatePicker
                  defaultValue={dayjs()}
                  style={{ width: "100%" }}
                  format={"YYYY-MM-DD"}
                  //   defaultValue={this.state.startDate}

                  // onChange={(date) =>
                  //   this.formRef.current.setFieldsValue({ toDate: date })
                  // }
                  // value={this.state.startDate}
                />
              </Form.Item>

              <Form.Item
                name="toTime"
                // label="From Time"
                // label={"To Time"}
                label={this.context.translate(
                  "_FLEET_RULESELECTORDETAIL_RULE4_TOTIME_"
                )}
                className="blur-input"
                rules={[{ required: true }]}
                initialValue={dayjs().format("hh:mm A ")}
              >
                <Select
                  onChange={(val) =>
                    this.formRef.current.setFieldsValue({ toTime: val })
                  }
                  // value={{formattedTime:dayjs().format("HH:mm:ss"),time:""}}
                  // allowClear={true}
                >
                  {timeArray.map((data) => {
                    return (
                      <Select.Option
                        key={data.formattedTime}
                        value={data.formattedTime}
                      >
                        {format(data.time, "h:mm aa")}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item label=" ">
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ width: "100%" }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        {/* {console.log(this.state.selectedIframe)} */}
        <Modal
          open={this.state.openVideoModal}
          onCancel={this.closeModal}
          footer={null}
          width={800}
          title={this.state.selectedIframe?.title}
        >
          {this.state.selectedIframe && (
            <iframe
              src={this.state.selectedIframe.src}
              width="100%"
              height="400px"
              allow="fullscreen"
              title={`Video Player ${this.state.selectedIframe.key}`}
            />
          )}
        </Modal>
      </div>
    );
  }
}
VideoMonitoring.contextType = StoreContext;
export default observer(withRouter(VideoMonitoring));

// this.videoContainer1.update("<iframe src='https://media.awtltrack.com/vss/apiPage/RealVideo.html?token="+token+"&deviceId="+imei+"&chs=1&stream=0&wnum=1&panel=0' width='550' height='300'></iframe>");

//  src='"+fleet.Config.mediaUrl+"/vss/apiPage/RealVideo.html?token="+token+"&deviceId="+imei+"&chs=1&stream=0&wnum=1&panel=0'

//  flv
