import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class SubGroupStore {
  vehicleSubgrpData = [];
  loading = false;
  assetList = [];
  vehicleArray = [];
  vehGrpData = [];
  groupVehicleList = [];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      vehicleSubgrpData: observable,
      loading: observable,
      vehicleArray: observable,
      vehGrpData: observable,
      groupVehicleList: observable,

      loadData: action,
      addData: action,
      getSubGroupData: action,
      deleteRecord: action,
      getGroupData: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId }) {
    this.loading = true;

    var postData = { companyId: companyId, userId: null };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=getsubgroupdata", "postData=" + base64Input);

    this.vehicleSubgrpData = response?.data?.data[0]?.deviceSubgroups;
    this.loading = false;
  }

  async getGroupData(companyId) {
    this.loading = true;

    var postData = { companyId: companyId, userId: null };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=getalldata", "postData=" + base64Input);

    this.vehGrpData = response?.data?.data[0]?.devicegroups;
    this.loading = false;
  }

  async getGroupVehiclesData(groupId) {
    this.loading = true;

    var postData = { groupId: groupId, userId: 1, isadmin: "-1" };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=getGroupVehicles",
        "postData=" + base64Input
      );

    this.groupVehicleList = response?.data?.data;
    this.loading = false;
    return response?.data?.data;
  }

  async addData(subGroupData) {
    var postData = subGroupData;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=devicesubgroup&a=vehicles", "postData=" + base64Input);

    return response;
  }
  //For Edit
  async getSubGroupData(deviceGroupId, companyId) {
    var postData = {
      deviceSubGroupId: deviceGroupId,
      limit: 1,
      offset: 0,
      companyId: companyId,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=getsubgroupdata", "postData=" + base64Input);
   
    return  response?.data?.data[0]?.deviceSubgroups[0];
  }

  async deleteRecord({ deviceSubGroupId }) {
    var postData = { tokenId: "", deviceSubGroupId: deviceSubGroupId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=deviceSubGrp&a=deviceSubGrpDelete",
        "postData=" + base64Input
      );
    return response;
  }
}

export default SubGroupStore;
