import React, { Component } from "react";
import {
  Button,
  message,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Upload,
  Modal,
  Space,
  Tooltip,
  Spin,
} from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import Password from "antd/es/input/Password";
import withRouter from "../withRouter";
import StaffStore from "../store/StaffStore";
import { Link } from "react-router-dom";
import CompanyAutocomplete from "./CompanyAutocomplete";
import VehicleStore from "../store/VehicleStore";
import moment from "moment";
import CompanyStore from "../store/CompanyStore";
import dayjs from "dayjs";

import DepartmentStore from "../store/DepartmentStore";
import { format, addMinutes } from "date-fns";
import CountryStore from "../store/CountryStore";
import config from "../config";
import conf from "../config";

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 20 },
};

const startTime = new Date(2000, 0, 1, 0, 0); // 12:00 AM
const endTime = new Date(2000, 0, 1, 23, 45); // 11:45 PM

const timeArray = [];

for (let time = startTime; time <= endTime; time = addMinutes(time, 30)) {
  const formattedTime = format(time, "h:mm aa");
  timeArray.push(formattedTime);
}

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const { Option } = Select;

class StaffAdd extends Component {
  formRef = React.createRef();
  constructor(prop) {
    super(prop);
    this.state = {
      loading: false,
      employeeType: [],
      department: [],
      showDeptForm: false,
      departmentId: "",
      loadList: 0,
      companyId: "",
      getVehicles: [],
      userId: -1,
      id: -1,
      photo: "",
      loadingSpin: false,
    };
    this.staffStore = new StaffStore(this);
    this.vehicleStore = new VehicleStore(this);
    this.companyStore = new CompanyStore(this);
    this.departmentStore = new DepartmentStore(this);
    this.countryStore = new CountryStore(this);
  }
  normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      // const data=JSON.parse(base64_decode(this.cookies.get("data")))
      this.context.viewStateStore.setStaffStates({
        companyId: this.props.getUserData().userCompanyId,
      });

      console.log(this.props.getUserData());
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      console.log(this.state.companyId);
      // await this.setState({userId:this.props.getUserData().userId})
      // await this.setState({isadmin:this.props.getUserData().isadmin})
    }
    console.log(this.props.params.staffId);
    await this.departmentStore.getUserDepartmentData();
    await this.countryStore.getCountry();
    const employeeType = await this.staffStore.getEmployeeType();
    await this.setState({ employeeType: employeeType });
    const department = await this.staffStore.getDepartment();
    await this.setState({ department: department });
    await this.setState({ loadList: 1 });

    const staffId = this.props.staffId;
    if (staffId) {
      this.filldata(staffId);
    }
  }
  async filldata(staffId) {
    await this.setState({ loadingSpin: true });
    const getStaffData = await this.staffStore.getStaffData(staffId);
    console.log(getStaffData, "stfdata");
    const getVehicles = await this.vehicleStore.getVehicles(
      getStaffData.companyId
    );
    await this.setState({ getVehicles: getVehicles });

    await this.setState({
      userId: getStaffData.userId,
      companyId: getStaffData.companyId,
    });
    await this.setState({ id: getStaffData.id });
    const singleData = await this.companyStore.getCompanyDataById(
      getStaffData.companyId
    );
    await this.staffStore.getVehicleNo(getStaffData.companyId);
    await this.departmentStore.getUserDepartmentData();
    console.log(singleData, "singl");
    this.formRef.current.setFieldsValue({
      name: getStaffData.name,

      departmentId:
        getStaffData.departmentId && Number(getStaffData.departmentId),
      companyId: singleData,
      bloodgroup: getStaffData.bloodGroup,
      alertInterval: getStaffData.alertInterval,
      alertDsateTime: moment(getStaffData.alertDateTime),
      address: getStaffData.address,
      UUID: getStaffData.UUID,
      Tagid: getStaffData.Tagid,
      Nationality: getStaffData.Nationality
        ? Number(getStaffData.Nationality)
        : null,
      Employementstatus: getStaffData.Employementstatus,

      mobilenumber: getStaffData.mobileno,
      licensenumber: getStaffData.licensenumber,
      id: getStaffData.id,

      licenseexpirydate: getStaffData.expireDate
        ? dayjs(getStaffData.expireDate)
        : null,
      employeeId: getStaffData.employeeId,
      emergencyContNumber: getStaffData.e_contactNo,
      emergencyContName: getStaffData.e_contactName,

      SalesPersonType: getStaffData.salesPersonTypeId,
      // photo: getStaffData.photo,
      permitNo: getStaffData.permitNo,
      permitExpiryDate: getStaffData.permitExpiryDate
        ? dayjs(getStaffData.permitExpiryDate)
        : null,
      overtimeCharges: getStaffData.overtimeCharges,
      otherPermit: getStaffData.otherPermit,
      notes: getStaffData.notes,
      userId: getStaffData.userId,

      workingHours: getStaffData.workingHours,
      visaExpiryDate: getStaffData.visaExpiryDate
        ? dayjs(getStaffData.visaExpiryDate)
        : null,
      vehicleId: getStaffData.vehicleId,
      // trackingTimeTo: getStaffData.trackingTimeTo,
      // trackingTimeFrom: getStaffData.trackingTimeFrom,
      trackingInterval: getStaffData.trackingInterval,
      status: getStaffData.status,
      userName: getStaffData.username,
      password: getStaffData.password,
      trackingTimeFrom: getStaffData.trackingTimeFrom
        ? dayjs(getStaffData.trackingTimeFrom, "hh:mm A")?.format("HH:mm")
        : null,
      trackingTimeTo: getStaffData.trackingTimeTo
        ? dayjs(getStaffData.trackingTimeTo, "hh:mm A")?.format("HH:mm")
        : null,
      countryCode: getStaffData.countryCode,
      emergencyConCountryCode: getStaffData.emergencyConCountryCode,
    });
    console.log(getStaffData.trackingTimeFrom, getStaffData.trackingTimeTo);
    this.setState({ photo: getStaffData.photo });
    this.setState({ trackingTimeFrom: getStaffData.trackingTimeFrom });
    this.setState({ trackingTimeTo: getStaffData.trackingTimeTo });
    await this.setState({ loadingSpin: false });
  }
  fillEmployeeType = () => {
    const employeeType = this.state.employeeType;
    if (employeeType) {
      return employeeType.map((value) => {
        return <Select.Option value={value.Id}>{value.type}</Select.Option>;
      });
    }
  };
  // fillDepartment = () => {
  //   const department = this.state.department;

  //   if (department) {
  //     return department.map((value) => {
  //       return (
  //         <Select.Option value={value.departmentId}>
  //           {value.departmentName}
  //         </Select.Option>
  //       );
  //     });
  //   }
  // };

  onStaffDetailsSubmit = async (fieldsValue) => {
    if (this.state.fileList?.size > conf.photoUploadSize) {
      message.error("File size must be less than or equal to 10MB");
      return;
    }
    console.log(this.state.fileList?.type, this.state.fileList?.length);
    let filterType = ["image/jpeg", "image/png", "image/jpg"];
    console.log(!filterType.includes(this.state.fileList?.type));
    if (
      this.state.photo?.length > 0 &&
      this.state.fileList?.type !== undefined &&
      !filterType.includes(this.state.fileList?.type)
    ) {
      message.error("Only JPG,JPEG,PNG Files are allowed");
      return;
    }

    console.log(this.state.fileList);
    this.setState({ loading: true });
    // const data = { ...fieldsValue, id: -1, userId: -1, trackingTimeFrom: fieldsValue?.trackingTimeFrom?.format("HH:mm"), trackingTimeTo: fieldsValue?.trackingTimeTo?.format("HH:mm"), visaExpiryDate: fieldsValue?.visaExpiryDate?.format("YYYY-MM-DD"), licenseExpiryDate: fieldsValue?.licenseExpiryDate?.format("YYYY-MM-DD"), permitExpiryDate: fieldsValue?.permitExpiryDate?.format("YYYY-MM-DD") }
    console.log(this.state.userId, this.state.companyId, "dd");
    const data = {
      ...fieldsValue,
      companyId: this.state.companyId,
      id: this.state.id,
      userId: this.state.userId,
      visaExpiryDate: fieldsValue?.visaExpiryDate
        ? fieldsValue?.visaExpiryDate?.format("YYYY-MM-DD")
        : null,
      licenseexpirydate: fieldsValue?.licenseexpirydate
        ? fieldsValue?.licenseexpirydate?.format("YYYY-MM-DD")
        : null,
      permitExpiryDate: fieldsValue?.permitExpiryDate
        ? fieldsValue?.permitExpiryDate?.format("YYYY-MM-DD")
        : null,
      photo: this.state.photo,
    };
    console.log(data);
    const response = await this.staffStore.addData(data);
    await this.props.response(response);
    // console.log(response);
    // if (response?.success === "S") {
    //   message.success(response.message);
    //   this.props.navigate("/companystafflist");
    // } else {
    //   message.error(response.errors[0].username);
    // }

    this.setState({ loading: false });
  };
  // onDeptDetailsSubmit = async (fieldValues) => {
  //   console.log(fieldValues);
  //   const data = { department: fieldValues };
  //   const response = await this.departmentStore.addDepartment(data);
  //   await this.departmentStore.getUserDepartmentData();
  //   // await this.setState({ loadList: 1});
  //   if (response.data.success === "S") {
  //     message.success(response.data.message);
  //     await this.setState({ showDeptForm: false });
  //     console.log(response);
  //   } else {
  //     await message.error(response.data.message);
  //   }
  //   this.setState({ loadList: 1 });
  // };

  onDeptDetailsSubmit = async (fieldValues) => {
    console.log(fieldValues);
    const data = { department: fieldValues };
    // await this.departmentStore.addDepartment(data);
    await this.departmentStore.getUserDepartmentData();
    const response = await this.departmentStore.addDepartment(data);

    console.log(response);
    if (response.data.success === "S") {
      await this.formRef.current.setFieldsValue({
        // departmentName: {
        //        label: response.data.data.departmentName,
        //        value: response.data.data.departmentId,
        //      }
        departmentId: Number(response.data.data.departmentId),
        // [
        //   {
        //     label: response.data.data.departmentName,
        //     value: response.data.data.departmentId,
        //   },
        // ],
      });
      this.setState({ departmentId: Number(response.data.data.departmentId) });
      await this.departmentStore.getUserDepartmentData();
      // this.formRef.current.setFieldsValue({departmentName:parseInt(response.data.data.departmentId)})
      message.success(response.data.message);
      await this.setState({ showDeptForm: false });
      this.setState({ loadList: 1 });
      // await console.log(response);
    } else {
      await message.error(response.data.message);
    }
    await this.departmentStore.getUserDepartmentData();
    // await this.setState({ loadList: 1});
    this.setState({ loadList: 1 });
  };

  selectDepartment = async (value) => {
    console.log(value);
    await this.setState({ departmentId: value });
    this.formRef.current.setFieldsValue({ departmentId: value });
  };

  async handleChange(comp) {
    const getVehicles = await this.staffStore.getVehicleNo(comp.value);
    console.log(getVehicles);

    console.log(this.props.params);
    if (!this.props.staffId) {
      this.context.viewStateStore.setStaffStates({ companyId: comp.value });
    }
    this.setState({ companyId: comp.value });
    console.log(this.state.companyId);
    this.formRef.current.setFieldsValue({ companyId: comp.value });
    await this.handleSearch();
  }

  async handleSearch() {
    await this.staffStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
      isadmin: this.props.getUserData().isadmin,
    });
    await this.setState({ loadList: 1 });
  }
  // fillVehicleDetails = () => {
  //   const vehicleData = this.state.getVehicles;
  //   console.log(vehicleData, "vehi");
  //   if (vehicleData) {
  //     return vehicleData?.map((value) => {
  //       return (
  //         <Select.Option value={value.vehicleId}>
  //           {value.vehicleNo}
  //         </Select.Option>
  //       );
  //     });
  //   }
  // };
  // async selectDepartment(value) {
  //     console.log(value)
  //     await this.setState({ departmentId: value });
  // }

  // timeOptions = () => {
  //   const TimeOptions = [];
  //   for (let hour = 0; hour <= 12; hour++) {
  //     for (let minute of [0, 30]) {
  //       let label = `${hour.toString().padStart(2, "0")}:${minute
  //         .toString()
  //         .padEnd(2, "0")} `;
  //       if (hour < 12) {
  //         label += "AM";
  //       } else {
  //         label += "PM";
  //       }
  //       TimeOptions.push({ value: label });
  //     }
  //   }
  //   console.log(TimeOptions, "tim");
  //   return TimeOptions.map((data) => {
  //     return (
  //       <Select.Option key={data.value} value={data.value}>
  //         {data.value}
  //       </Select.Option>
  //     );
  //   });
  // };

  timeOptions = () => {
    const timeArray = [];

    let startTime = new Date(2000, 0, 1, 0, 0); // 12:00 AM
    console.log();
    let endTime = new Date(2000, 0, 1, 23, 30);
    for (let time = startTime; time <= endTime; time = addMinutes(time, 30)) {
      // const formattedTime = format(time, 'h:mm aa');
      const formattedTime = format(time, "HH:mm");
      var ob = {
        formattedTime: formattedTime,
        time: time,
      };
      timeArray.push(ob);
    }
    console.log(timeArray);
    return timeArray.map((data) => {
      return (
        <Select.Option key={data.formattedTime} value={data.formattedTime}>
          {format(data.time, "h:mm aa")}
        </Select.Option>
      );
    });
  };

  validatePhoneNumber = (value, field1) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1.length);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field1]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field1]: "" });
    }
  };

  validateNumber = (value, field1) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1);
    if (value1 > 0) {
      this.formRef.current.setFieldsValue({ [field1]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field1]: "" });
    }
  };
  validateNumberAndDecimal = (value, field) => {
    const value1 = value.replace(/[^0-9.,]+/g, "");
    console.log(value1);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };
  validateFile = (file) => {
    this.setState({ fileList: file });
    console.log(file);
    const allowedExtensions = [".jpeg", ".jpg", ".png"];
    const fileName = file.name;
    const fileExtension = fileName.slice(
      ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
    );
    if (!allowedExtensions.includes("." + fileExtension.toLowerCase())) {
      message.error("Only JPEG and PNG files are allowed");
      return false;
    }
    const maxSize = conf.photoUploadSize;
    if (file.size > maxSize) {
      message.error("File size must be less than or equal to 10MB");
      return false;
    }
    return true;
  };
  getUploadData = async (fileUp) => {
    console.log(fileUp.fileList);
    // if (fileUp.fileList[0]?.type == "image/png") {
    let fileinfo = fileUp.file.response;
    if (fileinfo?.success == "S") {
      // console.log(fileinfo.data)
      console.log("innnnnnnn");
      await this.setState({ photo: fileinfo.data });
    }
    fileUp.fileList[0].url = config.fleetURL + fileinfo?.folderName;
    this.staffStore.setPhotoFromDb(fileUp.fileList);
    this.setState({ update: 1 });
    // }
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              ref={this.formRef}
              // {...layout}
              name="nest-messages"
              layout="vertical"
              onFinish={this.onStaffDetailsSubmit}
              style={{ maxWidth: "100%", padding: 0 }}
              // validateMessages={validateMessages}
              className="formClass"
            >
              {/* <div className="headingTitle">Staff Details</div> */}
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      // label="Account"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => this.handleChange(value)}
                        style={{ width: "100%" }}
                        companyId={this.state.companyId}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="SalesPersonType"
                    // label="Employee Type"
                    label={this.context.translate("_EMPLOYEE_TYPE_")}
                  >
                    <Select
                      // placeholder="Select Employee Type"
                      placeholder={this.context.translate(
                        "_SELECT_EMPLOYEE_TYPE"
                      )}
                    >
                      {this.fillEmployeeType()}
                    </Select>
                  </Form.Item>

                  <Row
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {/* <Col span={12} > */}
                    <Form.Item
                      name="departmentId"
                      label={this.context.translate("_DEPARTMENT_")}
                      style={{ width: "80%" }}
                    >
                      <Select
                        value={this.state.departmentId}
                        // placeholder="Departments"
                        placeholder={this.context.translate(
                          "_SELECT_DEPARTMENT_"
                        )}
                        showSearch
                        onSelect={this.selectDepartment}
                        filterOption={(inputValue, option) => {
                          if (option.children) {
                            return (
                              option.children
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            );
                          }
                        }}
                      >
                        {this.departmentStore?.departments?.map((val) => {
                          return (
                            <Option value={val.departmentId}>
                              {val.departmentName}
                            </Option>
                          );
                        })}
                      </Select>
                      {/* <Select
                        // fieldNames={{
                        //   label: "departmentName",
                        //   value: "departmentId",
                        // }}
                        value={this.state.departmentId}
                        // placeholder="Departments"
                        placeholder={this.context.translate(
                          "_SELECT_DEPARTMENT_"
                        )}
                        showSearch
                        onSelect={this.selectDepartment}
                        filterOption={(inputValue, option) => {
                          if (option.children) {
                            return (
                              option.children
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            );
                          }
                        }}
                      >
                        {this.departmentStore?.departments?.map((val) => {
                          return (
                            <Option value={Number(val.departmentId)}>
                              {val.departmentName}
                            </Option>
                          );
                        })}
                      </Select> */}
                    </Form.Item>
                    {/* </Col> */}
                    {/* <Col span={2} /> */}
                    <Form.Item label=" ">
                      <Tooltip
                        // title="Add New Department"
                        title={this.context.translate("_ADD_NEW_DEPARTMENT_")}
                        placement="bottom"
                        color={config.tooltipColorEdit}
                      >
                        <Button
                          type="primary"
                          style={{ height: "48px" }}
                          onClick={() => {
                            this.setState({ showDeptForm: true });
                          }}
                        >
                          <PlusOutlined />
                        </Button>
                      </Tooltip>
                    </Form.Item>
                  </Row>

                  <Form.Item
                    name="name"
                    // label="Name"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_NEWPOI_NAME_"
                    )}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="userName"
                    // label="User Name"
                    label={this.context.translate(
                      "_FLEET_USER_USERDETAIL_USERNAME_"
                    )}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    // label="Password"
                    label={this.context.translate("_FLEET_LOGIN_PASSWORDLBL_")}
                    rules={[{ required: true }]}
                  >
                    <Input.Password id="form-password" />
                  </Form.Item>

                  {/* <Form.Item
                  name="mobilenumber"
                  // label="Mobile No"
                  label={this.context.translate(
                    "_FLEET_USER_USERDETAIL_MOBILE_"
                  )}
                  rules={[
                    {
                      required: true,
                      pattern: /^(\d{7}|\d{10})$/,
                      // message: "Enter 7 or 10 Digit Number",
                      message:this.context.translate("_Enter_7_or_10_Digit_Number_")
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      this.validatePhoneNumber(e.target.value, "mobilenumber");
                    }}
                  />
                </Form.Item> */}

                  <Form.Item
                    name="employeeId"
                    // label="Employee Id "
                    label={this.context.translate("_EMPLOYEE_ID_")}
                    rules={[{ type: Password }]}
                  >
                    <Input />
                  </Form.Item>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      style={{ width: "30%" }}
                      name="countryCode"
                      // label="Contact 1"
                      label={" "}
                      // rules={[{ required: true }]}
                      rules={[{ required: true }]}
                      initialValue={+966}
                    >
                      <Select>
                        <Select.Option value={91}>+91</Select.Option>
                        <Select.Option value={966}>+966</Select.Option>

                        <Select.Option value={971}>+971</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      style={{ width: "65%" }}
                      name="mobilenumber"
                      // label="Phone Number 1"
                      label={this.context.translate("_MOBILE_NUMBER_")}
                      rules={[
                        {
                          pattern: /^(\d{7}|\d{10})$/,
                          // message: "Enter a 7 or 10 digit number",
                          message: this.context.translate(
                            "_Enter_7_or_10_Digit_Number_"
                          ),
                          required: true,
                        },
                      ]}
                      // rules={[{ required: true}]}
                    >
                      <Input
                        onChange={(e) =>
                          this.validatePhoneNumber(
                            e.target.value,
                            "mobilenumber"
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="Employementstatus"
                    //  label="Employment Status"
                    label={this.context.translate("_EMPLOYMENT_STATUS_")}
                  >
                    <Select>
                      <Select.Option value="Permanent">
                        {/* Permanent */}
                        {this.context.translate("_PERMANENT_")}
                      </Select.Option>
                      <Select.Option value="Temporary">
                        {/* Temporary */}
                        {this.context.translate("_TEMPORARY_")}
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  <Row>
                    <Col span={11}>
                      <Form.Item
                        name="vehicleId"
                        // label="Asset Name"
                        label={this.context.translate(
                          "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                        )}
                      >
                        {/* <Select placeholder="Select Asset">
                        {this.fillVehicleDetails()}
                      </Select> */}
                        <Select
                          // placeholder="Select Asset"
                          placeholder={this.context.translate(
                            "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                          )}
                          showSearch
                          fieldNames={{
                            value: "vehicleId",
                            label: "vehicleNo",
                          }}
                          optionFilterProp="children"
                          options={this.staffStore?.vehicleList}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={2} />
                    <Col span={11}>
                      <Form.Item
                        name="visaExpiryDate"
                        // label="Visa Expiry Date"
                        label={this.context.translate("_VISA_EXPIRY_DATE_")}
                        rules={[{ type: Date }]}
                      >
                        <DatePicker
                          format={"YYYY-MM-DD"}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="Nationality"
                    // label="Nationality"
                    label={this.context.translate("_NATIONALITY_")}
                    // rules={[{type:Number}]}
                  >
                    <Select
                      showSearch
                      // placeholder="Select Country"
                      placeholder={this.context.translate("_SELECT_COUNTRY_")}
                      filterOption={(inputValue, option) => {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {this.countryStore?.country.map((country) => {
                        return (
                          <Option value={parseInt(country.countryId)}>
                            {country.country}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="address"
                    // label="Address"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_NEWPOI_ADDRESS_"
                    )}
                    rules={[{}]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="workingHours"
                    // label="Working Hours"
                    label={this.context.translate("_WORKING_HOUR_")}
                    // rules={[{ type: Number }]}
                  >
                    <Input
                      onChange={(e) => {
                        this.validateNumberAndDecimal(
                          e.target.value,
                          "workingHours"
                        );
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="overtimeCharges"
                    // label="Overtime Charges"
                    label={this.context.translate("_OVERTIME_CHARGE_")}
                    rules={[{ type: Number }]}
                  >
                    <Input
                      onChange={(e) => {
                        this.validateNumberAndDecimal(
                          e.target.value,
                          "overtimeCharges"
                        );
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12} className="formColumnClass">
                  {/* <Form.Item
                    name="alertInterval"
                    // label="Alert Interval"
                    label={this.context.translate(
                      "_FLEET_RULEADMIN_RULEDETAIL_MININTERVAL_"
                    )}
                  >
                    <Select
                      // placeholder="Minutes"
                      placeholder={this.context.translate("_MINUTES_")}
                    >
                      <Select.Option value="30">30 </Select.Option>
                      <Select.Option value="60">60</Select.Option>
                      <Select.Option value="90">90</Select.Option>
                      <Select.Option value="120">120</Select.Option>
                      <Select.Option value="180">180</Select.Option>
                    </Select>
                  </Form.Item> */}

                  <Form.Item
                    name="trackingInterval"
                    // label="Tracking Interval(Min)"
                    label={this.context.translate("_TRACKING_INTERVAL_MIN_")}
                    rules={[{ type: Number }]}
                  >
                    <Input
                      onChange={(e) => {
                        this.validateNumber(e.target.value, "trackingInterval");
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="trackingTimeFrom"
                    // label="Tracking Time From"
                    label={this.context.translate("_TRACKING_TIME_FROM_")}
                  >
                    <Select
                      onSelect={(val) =>
                        this.setState({ trackingTimeFrom: val })
                      }
                      onChange={(e) => console.log(e)}
                      // value={this.state.fromTime}
                      allowClear={true}
                    >
                      {this.timeOptions()}
                    </Select>
                    {/* <Select>
                    {timeArray.map((val) => {
                      return (
                        <Select.Option key={val} value={val}>
                          {val}
                        </Select.Option>
                      );
                    })}
                  </Select> */}
                  </Form.Item>

                  <Form.Item
                    name="trackingTimeTo"
                    // label="Tracking Time To"
                    label={this.context.translate("_TRACKING_TIME_TO_")}
                  >
                    <Select
                      onSelect={(val) => this.setState({ trackingTimeTo: val })}
                      onChange={(e) => console.log(e)}
                      // value={this.state.fromTime}
                      allowClear={true}
                    >
                      {this.timeOptions()}
                    </Select>
                    {/* <Select>
                    {timeArray.map((val) => {
                      return (
                        <Select.Option key={val} value={val}>
                          {val}
                        </Select.Option>
                      );
                    })}
                  </Select> */}
                  </Form.Item>

                  <Form.Item
                    name="licensenumber"
                    // label="License No"
                    label={this.context.translate(
                      "_FLEET_DRIVER_DRIVERLIST_LICENCE_"
                    )}
                    rules={[{ type: Number }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="licenseexpirydate"
                    // label="License Expiry Date"
                    label={this.context.translate(
                      "_FLEET_DRIVER_DRIVERDETAIL_LICENSEEXPIRY_"
                    )}
                    rules={[{ type: Date }]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>

                  {/* <Row>
                    <Col span={11}>
                      <Form.Item
                        name="otherPermit"
                        // label="Other Permit "
                        label={this.context.translate("_OTHER_PERMIT_")}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={2} />
                    <Col span={11}>
                      <Form.Item
                        name="permitNo"
                        // label="Permit No "
                        label={this.context.translate("_PERMIT_NO_")}
                        rules={[{ type: Number }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row> */}

                  <Form.Item
                    name="permitExpiryDate"
                    // label="Permit Expiry Date "
                    label={this.context.translate("_PERMIT_EXPIRY_DATE_")}
                    rules={[{ type: Date }]}
                  >
                    <DatePicker
                      format={"YYYY-MM-DD"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="bloodgroup"
                    // label="Blood Group "
                    label={this.context.translate(
                      "_FLEET_DRIVER_DRIVERDETAIL_BLOODGROUP_"
                    )}
                    rules={[{}]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="emergencyContName"
                    // label="Emergency Contact Name "
                    label={this.context.translate(
                      "_FLEET_DRIVER_DRIVERDETAIL_EMERGENCYNAME_"
                    )}
                  >
                    <Input />
                  </Form.Item>

                  {/* <Form.Item
                    name="emergencyContNumber"
                    // label="Emergency Contact Number "
                    label={this.context.translate(
                      "_FLEET_DRIVER_DRIVERDETAIL_EMERGENCYNUMBER_"
                    )}
                    rules={[
                      {
                        pattern: /^(\d{7}|\d{10})$/,
                        message: "Enter 7 or 10 Digit Number",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        this.validatePhoneNumber(
                          e.target.value,
                          "emergencyContNumber"
                        );
                      }}
                    />
                  </Form.Item> */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      style={{ width: "30%" }}
                      name="emergencyConCountryCode"
                      // label="Contact 1"
                      label={" "}
                      // rules={[{ required: true }]}
                      initialValue={+966}
                    >
                      <Select>
                        <Select.Option value={91}>+91</Select.Option>
                        <Select.Option value={966}>+966</Select.Option>

                        <Select.Option value={971}>+971</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      style={{ width: "65%" }}
                      name="emergencyContNumber"
                      // label="Phone Number 1"
                      label={this.context.translate(
                        "_FLEET_DRIVER_DRIVERDETAIL_EMERGENCYNUMBER_"
                      )}
                      rules={[
                        {
                          // required: true,
                          pattern: /^(\d{7}|\d{10})$/,
                          message: this.context.translate(
                            "_Enter_7_or_10_Digit_Number_"
                          ),
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) =>
                          this.validatePhoneNumber(
                            e.target.value,
                            "emergencyContNumber"
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="notes"
                    // label="Notes "
                    label={this.context.translate(
                      "_FLEET_DRIVER_DRIVERDETAIL_NOTES_"
                    )}
                    rules={[{}]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="photo"
                    className="formCheckboxCss"
                    label=" "
                    // valuePropName="fileList"
                    // getValueFromEvent={this.normFile}
                  >
                    <Upload
                      name="photo"
                      action={
                        config.baseURL + "/index.php?c=company&a=fileupload"
                      }
                      listType="picture"
                      data={{
                        fileType: "salespersonphoto",
                        fieldName: "photo",
                      }}
                      onChange={this.getUploadData}
                      // onRemove={() => this.setState({ photo: "", fileList: [] })}
                      onRemove={async () => {
                        await this.staffStore.setPhotoFromDb([]);
                        await this.setState({ photo: "", fileList: [] });
                      }}
                      maxCount={1}
                      accept=".jpg,.jpeg,.png"
                      // Add the beforeUpload prop to perform file extension validation
                      beforeUpload={(file) => this.validateFile(file)}
                      {...this.staffStore.photoFromDb}
                    >
                      <Button className="imgupload" icon={<UploadOutlined />}>
                        {/* Upload Photo Id */}
                        {this.context.translate(
                          "_FLEET_DRIVER_DRIVERDETAIL_UPLOAD_"
                        )}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <div className="formBtnGroup">
                {/* css class */}
                <Button
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal();
                    // this.formRef.current?.resetFields();
                  }}
                >
                  {/* css class */}
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  {/* css class */}
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>

        <Modal
          open={this.state.showDeptForm}
          // title="Add Department"
          title={this.context.translate("_ADD_DEPARTMENT_")}
          onOk={() => this.setState({ showDeptForm: false })}
          onCancel={() => this.setState({ showDeptForm: false })}
          footer={false}
          width={400}
        >
          <Form
            name="nest-messages"
            layout="vertical"
            onFinish={this.onDeptDetailsSubmit}
            style={{ padding: "0px 10px" }}
          >
            <Row>
              <Col span={12}></Col>
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              ></Col>
            </Row>
            <Form.Item
              name="dptname"
              label={this.context.translate("_NAME_")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <div className="formBtnGroup">
              {/* css class */}
              <Button
                className="formCancelButton"
                onClick={() => this.setState({ showDeptForm: false })}
              >
                {/* css class */}
                {/* Cancel */}
                {this.context.translate("_FLEET_DASHBORD_ROUTEADD_CANCELBTN_")}
              </Button>
              <Button
                type="primary"
                className="formSaveButton"
                htmlType="submit"
                loading={this.state.loading}
              >
                {/* css class */}
                {this.context.translate("_FLEET_DASHBORD_SETTING_SAVEBTN_")}
              </Button>
            </div>

            {/* <Row justify={"end"}>
              <Space style={{ padding: "10px" }}>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button  className="formCancelButton" onClick={() => this.setState({ showDeptForm: false })}>
                  Cancel
                </Button>
              </Space>
            </Row> */}
          </Form>
        </Modal>
      </div>
    );
  }
}
StaffAdd.contextType = StoreContext;
export default observer(withRouter(StaffAdd));
