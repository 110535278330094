import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  makeObservable,
  observable,
  
} from 'mobx';
import { act } from 'react-dom/test-utils';

class ChangePasswordStore {
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        
      total:observable,
      loading:observable,
      setChangePassword:action,
     
    });
     this.rootStore = rootStore;
    
  }

  async setChangePassword(object) {
    var postData = object
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=user&a=changepass", "postData=" + base64Input);
    return response.data;
  }


}

export default ChangePasswordStore;
