import React, { Component } from "react";
import config from "../config";
import {
  Form,
  Select,
  Button,
  Input,
  DatePicker,
  Radio,
  Space,
  Checkbox,
  Upload,
  Row,
  Col,
  message,
  Spin,
} from "antd";

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import NotificationStore from "../store/NotificationStore";

import DriversStore from "../store/DriversStore";
import dayjs from "dayjs";
import EmailMessageStore from "../store/EmailMessageStore";
import CompanyStore from "../store/CompanyStore";
const dateFormat = "DD-MM-YYYY HH:mm";
const currentDateTime = dayjs();
const { Option } = Select;
class NotificationAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      parent: false,
      driver: false,
      user: false,
      companyId: "",
      loadList: 0,
      selectedDriver: [],
      selectAllDrivers: false,
      classId: [],
      selectAllClass: false,
      notification_Id: -1,
      sendNow: false,
      selectAllCompanies: false,
      selectedCompany: [],
      loadingSpin: false,
    };
    this.notificationStore = new NotificationStore();
    this.driverStore = new DriversStore();
    this.emailMsgStore = new EmailMessageStore();
    this.companyStore = new CompanyStore();
  }

  onNotificationDetailsSubmit = async (values) => {
    this.setState({ loading: true });
    console.log(values);
    const NotificationDetails = {
      ...values,
      postedAt: values.postedAt
        ? values.postedAt.format("DD-MM-YYYY HH:mm")
        : null,
      // postedAt: values?.postedAt?.format("DD-MM-YYYY HH:mm"),
      notification_Id: this.state.notification_Id,
      companyId: this.state.selectedCompany,
      sendNow: this.state.sendNow ? 1 : 0,
    };
    const data = {
      tokenId: "",
      NotificationDetails: NotificationDetails,
    };
    console.log(data);
    const response = await this.notificationStore.addData(data);
    await this.props.response(response);
    await this.setState({ loading: false });
    // if (response?.success === "S") {
    //   this.notificationStore.getNotificationDetails
    // }
    // if (response?.success === "S") {
    //   message.success(response.message);
    //   this.props.navigate("/notificationlist");
    // } else if (response?.data?.success == "F0") {
    //   response.data.errors.map((value) => {
    //     message.error(value["inventoryNo"]);
    //   });
    // }
  };

  // async componentDidMount() {
  //   await this.setState({ loadList: 1 });
  //   const tId = this.props.params.id;

  //   if (tId) {
  //     this.filldata(tId);
  //     this.setState({ tId: tId });
  //   }
  // }

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      this.context.viewStateStore.setNotificationStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        selectedCompany: this.props.getUserData().userCompanyId,
      });
      console.log(this.state.companyId);
      // await this.setState({userId:this.props.getUserData().userId})
      await this.setState({ isadmin: this.props.getUserData().isadmin });
      await this.notificationStore.toGetDriverName(
       this.props.getUserData().userCompanyId,
      //  this.props.getUserData().isadmin
      );
await this.setState({loadData:1})
    }
    if (this.props.notificationId) {
      this.setState({ loadingSpin: true });
    }

    const data = await this.notificationStore.getCompanies();
    // console.log(this.notificationStore.allCompanyArray, "ffefergretrttr");

    const getClass = await this.notificationStore.getClassNames();
    // console.log("storingCategory", getClass);
    await this.setState({ loadList: 1 });
    console.log(this.props.params);
    const Id = this.props.notificationId;

    console.log("id", Id);

    if (Id) {
      await this.filldata(Id);
      await this.setState({ Id: Id });
      await this.setState({ loadingSpin: false });
    }
  }

  async filldata(Id) {
    console.log(Id, "werwertyrtytutu");
    // const data = await this.notificationStore.getCompanies();

    const getnotificationData =
      await this.notificationStore.getNotificationDetails(Id);
    console.log("getnotificationData", getnotificationData);

    await this.setState({
      selectedCompany: getnotificationData.companyId,
      notification_Id: getnotificationData.notification_Id,
    });
    const singleData = await this.notificationStore.getCompanyDataById(
      getnotificationData.companyId
    );

    await this.notificationStore.getCompanies();

    await this.notificationStore.getClassNames();

    await this.notificationStore.toGetDriverName(getnotificationData.companyId);
    console.log(getnotificationData.classId);

    this.formRef.current.setFieldsValue({
      companyId: singleData,
      notification_Id: getnotificationData.notification_Id,
      postedAt: getnotificationData.postedAt
        ? dayjs(getnotificationData.postedAt, "DD-MM-YYYY HH:mm")
        : null,
      // postedAt:getnotificationData.postedAt,
      notification: getnotificationData.notification,
      sendNow: getnotificationData.sendNow,
      schoolDriver: getnotificationData.schoolDriver,
      classId: getnotificationData.classId,
      driverId: getnotificationData.driverId,
    });
    console.log(getnotificationData.postedAt);
    // console.log(getnotificationData.schoolDriver);
    // console.log( getnotificationData.companyId)

    if (getnotificationData.schoolDriver === "school") {
      // this.setState({selectAllClass:true})
      this.setState({ parent: true });
      this.setState({ driver: false });
    } else if (getnotificationData.schoolDriver === "driver") {
      this.setState({ driver: true });
      this.setState({ parent: false });
    } else if (getnotificationData.schoolDriver === "user") {
      this.setState({ driver: false });
      this.setState({ parent: false });
    }
    // this.setState({loadingSpin: false });
    // this.setState({ selectAllCompanies: true})s
  }
  onSelect = (e) => {
    const value = e.target.value;
    console.log(e.target.value);
    if (value === "school") {
      this.setState({ parent: true });
      this.setState({ driver: false });
    } else if (value === "driver") {
      this.setState({ driver: true });
      this.setState({ parent: false });
    } else if (value === "user") {
      this.setState({ driver: false });
      this.setState({ parent: false });
    }
  };

  async handleChange(comp) {
    console.log(comp.value);
    console.log(this.props.notificationId, "EDITRRRRR");
    await this.setState({ companyId: comp.value });
    if (!this.props.notificationId) {
      this.context.viewStateStore.setNotificationStates({
        companyId: this.state.companyId,
      });
    }
    await this.formRef.current.setFieldsValue({ companyId: comp.value });
    // await this.setState({ companyId: comp.value });

    await this.notificationStore.toGetDriverName(comp.value);
    await this.handleSearch();
  }

  async handleSearch() {
    await this.notificationStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      // vehicleId:this.state.selectedDriver
    });
    await this.setState({ loadList: 1 });
  }

  handleSelectAllDrivers = async (e) => {
    if (this.state.selectAllDrivers) {
      const allDrivers = this.notificationStore?.getDriverName.map((val) => {
        return val.driverId;
      });

      console.log(allDrivers);
      // await this.setState({ selectedDriver: [9707, 9715, 9718, 9710, 9751, 9754, 9755, 9738] });
      this.formRef.current.setFieldsValue({ driverId: allDrivers });
      this.setState({ loadList: 1 });
    } else {
      this.formRef.current.setFieldsValue({ driverId: [] });
    }
    this.setState({ loadList: 1 });
  };

  handleSelectAllClass = async (e) => {
    if (this.state.selectAllClass) {
      const allClass = this.notificationStore?.getClass.map((val) => {
        return val.classId;
      });

      console.log(allClass);
      this.formRef.current.setFieldsValue({ classId: allClass });
    } else {
      this.formRef.current.setFieldsValue({ classId: [] });
    }
  };

  handleSelectAllCompanies = async (e) => {
    if (this.state.selectAllCompanies) {
      const allCompanies = this.notificationStore?.allCompanyArray.map(
        (val) => {
          return val.companyId;
        }
      );

      console.log(allCompanies);
      this.formRef.current.setFieldsValue({ companyId: allCompanies });
    } else {
      this.formRef.current.setFieldsValue({ companyId: [] });
    }
  };

  closeModal = () => {
    this.props.closeModal();
  };
  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              ref={this.formRef}
              layout="vertical"
              onFinish={this.onNotificationDetailsSubmit}
              // {...this.layout}
              name="nest-message"
              initialValues={{ postedAt: currentDateTime }}
            >
              {/* <div className="formBorder">
            <div className="headingTitle">Add Notification</div> */}
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                     {(this.props.getUserData().isadmin === -1 || this.props.getUserData().isadmin === 3) &&
                     <>
                    <Form.Item
                      className="search-field"
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                      style={{ width: "80%" }}
                    >
                      <Select
                        maxTagCount="responsive"
                        value={this.state.selectedCompany}
                        onChange={async (val) => {
                          console.log(val);
                          await this.setState({ selectedCompany: val });
                          console.log(val);
                        }}
                        // style={{ width: "514px" }}
                        showSearch
                        onSelect={(val) =>{
                          console.log(val)
                          this.notificationStore.toGetDriverName(val)
                        }
                       
                        }
                        mode="multiple"
                        // placeholder="Company"
                        placeholder={this.context.translate("_COMPANY_")}
                        filterOption={(inputValue, option) => {
                          if (option.children) {
                            return (
                              option.children
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            );
                          }
                        }}
                      >
                        {this.notificationStore?.allCompanyArray?.map((val) => {
                          return (
                            <Option value={val.companyId}>{val.name}</Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item label=" ">
                      <Checkbox
                        checked={this.state.selectAllCompanies}
                        // style={{marginLeft:'10px'}}
                        onChange={async (e) => {
                          await this.setState({
                            selectAllCompanies: e.target.checked,
                          });
                          console.log(e.target.checked);
                          await this.handleSelectAllCompanies();
                        }}
                      >
                        {/* All */}
                        {this.context.translate("_ALL_")}
                      </Checkbox>
                    </Form.Item>
                    </>}
                  </div>

                  <Form.Item
                    name="postedAt"
                    label={this.context.translate("_POSTED_AT_")}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      showTime
                      format={dateFormat}
                      // placeholder="Select date and time"
                      style={{ width: "100%" }}
                      // defaultValue={currentDateTime}
                    />
                  </Form.Item>
                </Col>

                <Col span={12} className="formColumnClass">
                  {/* <Form.Item
                  name="notification"
                  label={this.context.translate("_NOTIFICATION_")}
                  rules={[{ required: true }]}
                >
                  <Input.TextArea />
                </Form.Item> */}
                  <Form.Item
                    name="notification"
                    label={this.context.translate("_NOTIFICATION_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label={this.context.translate(
                      "_FLEET_COMPANY_COMPANYDETAIL_SELECT_"
                    )}
                    name="schoolDriver"
                  >
                    <Radio.Group
                      onChange={(e) => {
                        this.onSelect(e);
                        console.log(e.target.value);
                      }}
                    >
                      <Radio value="school">
                        {this.context.translate("_PARENTS_")}
                      </Radio>
                      <Radio value="driver">
                        {this.context.translate("_DRIVER_")}
                      </Radio>
                      <Radio value="user">
                        {this.context.translate(
                          "_FLEET_REPORTS_CHATREPORT_User_"
                        )}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>

                  <div style={{ display: this.state.driver ? "" : "none" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Form.Item
                        name="driverId"
                        // label="Driver Name"
                        label={this.context.translate(
                          "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
                        )}
                        className="search-field"
                        style={{ width: "80%" }}
                      >
                        <Select
                          // style={{ width: "513px" }}
                          onSearch={this.handleSearch}
                          mode="multiple"
                          maxTagCount={"responsive"}
                          // placeholder="Select Driver"
                          placeholder={this.context.translate(
                            "_SELECT_DRIVER_"
                          )}
                          value={this.state.selectedDriver}
                          showSearch
                          onChange={async (val) => {
                            await this.setState({ selectedDriver: val });
                            console.log(val);
                          }}
                          // fieldNames={{ value: "driverId", label: "name" }}
                          optionFilterProp="children"
                          // options={this.notificationStore?.getDriverName}
                        >
                          {this.notificationStore?.getDriverName?.map((val) => {
                            return (
                              <Select.Option value={Number(val.driverId)}>
                                {val.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item label=" ">
                        <Checkbox
                          checked={this.state.selectAllDrivers}
                          onChange={async (e) => {
                            await this.setState({
                              selectAllDrivers: e.target.checked,
                            });
                            console.log(e.target.checked);
                            await this.handleSelectAllDrivers();
                          }}
                        >
                          {/* All */}
                          {this.context.translate("_ALL_")}
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </div>

                  <div style={{ display: this.state.parent ? "" : "none" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Form.Item
                        name="classId"
                        // label="Class Name"
                        label={this.context.translate("_CLASS_NAME_")}
                        className="search-field"
                        style={{ width: "80%" }}
                      >
                        <Select
                          // style={{ width: "513px" }}
                          mode="multiple"
                          name="classId"
                          maxTagCount={"responsive"}
                          onSearch={this.handleSearch}
                          // placeholder="Select Class"
                          placeholder={this.context.translate("_SELECT_CLASS_")}
                          value={this.state.classId}
                          showSearch
                          onChange={async (val) => {
                            await this.setState({ classId: val });
                            console.log(val);
                          }}
                          // fieldNames={{ value: "classId", label: "className" }}
                          optionFilterProp="children"
                          // options={this.notificationStore?.getClass}
                        >
                          {this.notificationStore?.getClass.map((val) => {
                            return (
                              <Select.Option value={Number(val.classId)}>
                                {val.className}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item label=" ">
                        <Checkbox
                          checked={this.state.selectAllClass}
                          onChange={async (e) => {
                            await this.setState({
                              selectAllClass: e.target.checked,
                            });
                            console.log(e.target.checked);
                            await this.handleSelectAllClass();
                          }}
                        >
                          {/* All */}
                          {this.context.translate("_ALL_")}
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </div>

                  {/* <Form.Item wrapperCol={{ ...this.layout.wrapperCol, offset: 8 }}>
                                <Button htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item> */}
                </Col>
              </Row>
              <div className="formBtnGroup">
                {/* <Link to={"/notificationlist"}>
                  {" "}
                  <Button ghost type="primary">
                    Back
                  </Button>
                </Link> */}

                <Button
                  // ghost
                  // danger
                  // type="primary"
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal();

                    // this.formRef.current?.resetFields();
                    this.setState({
                      selectAllClass: false,
                      selectAllDrivers: false,
                      selectAllCompanies: false,
                    });
                  }}
                >
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                  {/* Cancel */}
                  {/* {this.context.translate("_FLEET_DASHBORD_SHOWPOI_CANCELBTN_")} */}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="formSaveButton"
                  loading={this.state.loading}
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
                <Button
                  type="primary"
                  // type="primary"
                  htmlType="submit"
                  style={{ width: "113px" }}
                  className="formSaveButton"
                  onClick={() => this.setState({ sendNow: true })}
                >
                  {/* Send Now */}
                  {this.context.translate("_SEND_NOW_")}
                </Button>
              </div>

              {/* </div> */}
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

NotificationAdd.contextType = StoreContext;
export default observer(withRouter(NotificationAdd));
