import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";
import {decode as base64_decode, encode as base64_encode} from 'base-64';

 function RequireAuth({ children }) {
  
  const [cookies, setCookie] = useCookies();

 

  let pData = cookies.data;
  let data = pData ? JSON.parse (base64_decode(cookies.data)) : "";
  //const data = JSON.parse (base64_decode(cookies.data)) ;



  return data?.userToken  ? children : <Navigate to="/login" replace />;
}


export default RequireAuth;