import {  encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import {
  action,
  
  makeObservable,
  observable,
  
  
} from "mobx";

class AssignVehicleToJobRequestStore {
  total = 0;
  loading = false;

  getVehiclesUsingCompanyId = [];
  getDriversUsingCompanyId=[];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
        getVehiclesUsingCompanyId:observable,
        getDriversUsingCompanyId: observable,

      getAssetData: action,
      getDriverData:action,
      addData: action,
    });
    this.rootStore = rootStore;
  }

  async addData(data) {
    console.log("data", data);
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=jobRequest&a=JobRequestAssignVehicle", "postData=" + base64Input);
    console.log(response);
    return response;
  }

  async getAssetData({ companyId }) {
    console.log(companyId, "id");
    const postData = {"companyId":companyId,"userId":1,"isadmin":-1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=jobRequest&a=getCompanyVehicles", "postData=" + base64Input);
    // console.log(response);

    this.getVehiclesUsingCompanyId = response?.data?.data;
    return response?.data?.data;
  }
  async getDriverData({ companyId }) {
    console.log(companyId, "id");
    const postData = {"companyId":companyId,"userId":1,"isadmin":-1}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=jobRequest&a=getCompanyDrivers", "postData=" + base64Input);
    // console.log(response);

    this.getDriversUsingCompanyId = response?.data?.data;
    return response?.data?.data;
  }
}

export default AssignVehicleToJobRequestStore;
