import { CloseOutlined } from "@ant-design/icons";
import { Card, DatePicker, Image, Tabs, Spin, Tooltip } from "antd";
import L from "leaflet";

import React, { Component, memo } from "react";

import dayjs from "dayjs";

import VehicleOtherInfoStore from "../store/VehicleOtherInfoStore";
import Draggable from "react-draggable";
import ShareModalWindow from "./ShareModalWindow";
import withRouter from "../withRouter";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
const { RangePicker } = DatePicker;

class VehicleOtherInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      update: 0,
      showFullSummary: false,
      // vehicleSummaryInfo:[],
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD 23:59:59"),
      trackingDataObj: [],
      shareModalVisible: false,
    };
    this.polyline2 = null;
    this.marker2 = null;
    console.log("call");
    // console.log([this.vehicleOtherInfoStore.trackingDataNewObj.latitude,this.vehicleOtherInfoStore.trackingDataNewObj.longitude]);

    this.vehicleOtherInfoStore = new VehicleOtherInfoStore();
    this.calculateDays = this.calculateDays.bind(this);
    this.onRangeChange = this.onRangeChange.bind(this);
    this.removeMarkerPoints = [];

    this.rangePresets = {
      items: [
        {
          label: "Today",
          value: [dayjs(), dayjs().endOf("day")],
          key: 1,
        },
        {
          key: "yesterday",
          label: "Yesterday",
          value: [
            dayjs().subtract(1, "day").startOf("day"),
            dayjs().subtract(1, "day").endOf("day"),
          ],
        },
        {
          key: "Last 7 Days",
          label: "Last 7 Days",
          value: [dayjs().add(-6, "d"), dayjs()],
        },

        {
          label: "Last 30 Days",
          value: [dayjs().add(-29, "d"), dayjs()],
          key: 4,
        },
        {
          label: "This Month",
          value: [dayjs().startOf("month"), dayjs().endOf("month")],
          key: 5,
        },
        {
          label: "Last Month",
          value: [
            dayjs().subtract(1, "month").startOf("month"),
            dayjs().subtract(1, "month").endOf("month"),
          ],
          key: 6,
        },
      ],
      onClick: (val) => {
        console.log(this.rangePresets.items, "ssfsdfsdfsdf");
      },

      //  {
      //   label: "Custom Range",
      //   value: [dayjs().add(-90, "d"), dayjs()],
      // },
    };
  }
  componentDidMount() {
    console.log("didmount", this.props.info);
    console.log("hello");

    console.log(this.vehicleOtherInfoStore.vehiclesTriggerData);
  }
  componentWillUnmount() {
    console.log("sssssssdfghdgfh");
    this.clearMap();
  }

  async getVehicleHistInfo(record) {
    this.vehicleOtherInfoStore.vehiclesHistData = [];
    this.vehicleOtherInfoStore.vehicleSummaryData = [];
    this.vehicleOtherInfoStore.vehicleTripData = [];
    this.vehicleOtherInfoStore.timeWisePolylineArray = [];
    this.setState({ update: 1 });
    await this.vehicleOtherInfoStore.getVehicleHistoryData({
      vehicleId: this.props.info.vehicleId,
      companyId: this.props.info.companyId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    });

    await this.setState({ update: 1 });
  }

  async getVehicleOffilneInfo() {
    await this.vehicleOtherInfoStore.setOfflineData({
      vehicleId: this.props.info.vehicleId,
      companyId: this.props.info.companyId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    });
    // console.log(this.liveTrackingStore.vehicleTripData);

    await this.setState({ update: 1 });
  }

  // async getVehicleTrackingInfo() {

  // }
  async getVehicleAlertInfo(record) {
    await this.vehicleOtherInfoStore.getVehicleRuleTriggerData({
      vehicleId: this.props.info.vehicleId,
      companyId: this.props.info.companyId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    });

    // await this.setState({ vehicleHistInfo: vehicleHistData });
    await this.setState({ update: 1 });
  }

  convertToFormattedTime = (time) => {
    // console.log(time);
    // Assuming time is in the format "hh:mm"
    if (time) {
      const [hours, minutes] = time?.split(":");
      // Format the time as "00:20" or in your desired format
      const formattedTime = `${hours}h ${minutes}min`;
      return formattedTime;
    }
  };

  convertToFormattedDate = (dateString) => {
    console.log(dateString);
    const dateObject = new Date(dateString);
    const dateStringData = dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    console.log(dateStringData);

    return dateStringData;
  };

  calculateDays = () => {
    var lastReportingTime = this.props.info.updatedate;
    var serverCurrentTime = this.props.info.currtime;
    // console.log(lastReportingTime, serverCurrentTime);
    var diff = new Date(serverCurrentTime) - new Date(lastReportingTime);
    // console.log(diff);
    diff /= 60000;
    // // console.log(this.props.info.stopSince)
    let time;
    if (diff >= 90) {
      time = this.getSectoTime(this.props.info?.stopSince);
    } else if (this.props.info.acc == 1 && this.props.info.speed == 0) {
      time = this.getSectoTime(this.props.info?.idleSince);
    } else {
      if (this.props.info.acc == 0 || this.props.info.acc == null) {
        time = this.getSectoTime(this.props.info?.stopSince);
      } else {
        if (this.props.info.acc == 1 && this.props.info.speed > 0) {
          time = this.getSectoTime(this.props.info?.runSince);
        }
      }
    }
    return time;

    // console.log(date)
    // var currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
    // var duration = moment.duration(moment(currentDate).diff(date));
    // // console.log(currentDate);

    // var days = Math.floor(duration.asDays());
    // var hours = duration.hours();
    // var minutes = duration.minutes();
    // var seconds = duration.seconds();
    // return days + " Days, " + hours + ":" + minutes + ":" + seconds;
  };

  getSectoTime = (c) => {
    var g, f, b, e;
    e = c;
    b = Math.floor(e / 60);
    e = e % 60;
    f = Math.floor(b / 60);
    b = b % 60;
    g = Math.floor(f / 24);
    f = f % 24;
    var a = (g < 10 ? "0" + g : g) + " days,";
    a += f < 10 ? "0" + f : f;
    a += ":" + (b < 10 ? "0" + b : b);
    a += ":" + (e < 10 ? "0" + e : e);
    return a;
  };
  getColor = (status) => {
    // console.log(status)
    let statusColor;
    switch (status) {
      case "No Data":
        statusColor = "text-secondary";
        break;
      case "Running":
        statusColor = "text-success";
        break;
      case "Stopped":
        statusColor = "text-danger";
        break;
      case "Idle":
        statusColor = "text-warning";
        break;
    }
    return "bi bi-circle-fill " + statusColor;
  };
  converteToDaysFormat = (val) => {
    console.log(val);
    const stopDurationSeconds = val || 0;
    const days = Math.floor(stopDurationSeconds / (24 * 60 * 60));
    const hours = Math.floor(
      (stopDurationSeconds % (24 * 60 * 60)) / (60 * 60)
    );
    const minutes = Math.floor((stopDurationSeconds % (60 * 60)) / 60);
    const seconds = stopDurationSeconds % 60;

    const formattedStopDuration = `${days
      .toString()
      .padStart(2, "0")} days, ${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    return formattedStopDuration;
  };

  onRangeChange = async (dates, dateStrings) => {
    // console.log( dayjs(dates[0]).format("YYYY-MM-DD"), dayjs().endOf("day"),dateStrings)
    // console.log(dates[0] - dates[1], dateStrings);
    const startDate = new Date(dates[1]);
    const endDate = new Date(dates[0]);
    const diffrence = Math.round((startDate - endDate) / (1000 * 60 * 60 * 24));
    // console.log(Math.round(diffrence));

    if (diffrence === 0 || diffrence === 1) {
      this.setState({ showFullSummary: false });
    } else {
      this.setState({ showFullSummary: true });
    }

    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      await this.setState({
        fromDate: dayjs(dateStrings[0]).format("YYYY-MM-DD 00:00:00"),
        toDate: dayjs(dateStrings[1]).format("YYYY-MM-DD 23:59:59"),
      });

      await this.getVehicleHistInfo();
      await this.clearMap();

      await this.getVehicleOffilneInfo();
      await this.showPolyline();
      await this.setState({ update: 1 });
    }
  };

  clearMap = async () => {
    let mapRef = this.props.This.mapRef;
    if (this.props.mapRef.current && this.removeMarkerPoints.length > 0) {
      this.props.mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          const markerLatLng = layer.getLatLng();
          // Check if the marker's coordinates are in the removeMarkerPoints array
          const isMarkerInRemovePoints = this.removeMarkerPoints.some((point) =>
            markerLatLng.equals(L.latLng(point))
          );
          if (isMarkerInRemovePoints) {
            // If the marker's coordinates are in the removeMarkerPoints array, remove it from the map
            this.props.mapRef.current.removeLayer(layer);
          }
        }
      });
    }
    if (this.props.mapRef) {
      await this.props.mapRef?.current?.eachLayer((layer) => {
        if (layer instanceof L.Polyline) {
          this.props.mapRef.current.removeLayer(layer);
        }
      });
    }
    this.vehicleOtherInfoStore.offlineData = [];
    this.vehicleOtherInfoStore.offlineDataNewObj = [];
    this.vehicleOtherInfoStore.idleAndStopPointsArray = [];
    this.vehicleOtherInfoStore.totalCount = null;
    this.vehicleOtherInfoStore.total = 0;
    this.vehicleOtherInfoStore.pgcount = 1;
    this.vehicleOtherInfoStore.offset = 0;
  };

  showPolyline = async () => {
    const polyline = await L.polyline(
      this.vehicleOtherInfoStore.offlineDataNewObj.length > 0
        ? [...this.vehicleOtherInfoStore.offlineDataNewObj]
        : [],
      {
        color: "grey",
        smoothFactor: 5,
        weight: 4,
      },
      15
    ).addTo(this.props.This.mapRef.current);
    const bounds = polyline.getBounds();

    await this.props.This.mapRef.current.fitBounds(bounds, {
      maxZoom: 20,
    });

    polyline.arrowheads({
      size: "6px",
      color: "blue", // Set arrow color to blue
    });

    //   polyline.arrowheads({ size: "6px" }).bindPopup(
    //     `<pre><code>L.polyline(coords)
    // .arrowheads({
    //   size: '18px',
    //   color:'blue'
    //   // frequency: '15px',
    //   // fill: true,
    //   yawn: 20
    // });</code></pre>`
    //     // { maxWidth: 2000, minWidth: 400 }
    //   );
  };

  drawIdleAndStopIcon = (vehicleObj, activity) => {
    const activityImage = require(`../assets/images/BaloonIcons/${activity}-north.png`);
    const marker = L.marker([vehicleObj.latitude, vehicleObj.longitude], {
      icon: L.divIcon({
        className: "custom-marker",
        iconSize: [25, 25],
        html: `
          <img src=${activityImage} alt="Icon" style=  "width: 100%; height: 100%;">`,
      }),
    })
      .bindTooltip(
        // "<div style='background:#00ff00;border:solid 1px #000;text-align:center;padding:0'><b>Sammek</b></div>",

        `<div style='background:#00ff00;border:solid 1px #000;text-align:left;padding:10px;'>
  <b>Vehicle No:</b> ${vehicleObj.vehicleNo} <br/>
  <b>Idle From:</b> ${vehicleObj.fromdate} <br/>
  <b>Idle To:</b> ${vehicleObj.todate} <br/>
  <b>Duration:</b> ${vehicleObj.elapsedTime} <br/>
 
</div>`,
        {
          maxWidth: 300,
          direction: "top",
          // className: "my-labels"
        }
      )

      .addTo(this.props.This.mapRef.current);

    // console.log(marker)
  };

  showTrackingPolyline = async () => {
    if (this.polyline2) {
      this.props.This.mapRef.current.removeLayer(this.polyline2);
    }
    // console.log(
    //   this.vehicleOtherInfoStore.idleAndStopPointsArray[0].vehicleObj
    // );
    let dta = this.vehicleOtherInfoStore.idleAndStopPointsArray?.map(
      (val) => val.vehicleObj
    );
    this.removeMarkerPoints = dta.map((val) => [
      Number(val.latitude),
      Number(val.longitude),
    ]);

    const showMarkers = this.vehicleOtherInfoStore.idleAndStopPointsArray.map(
      async (val) => {
        await this.drawIdleAndStopIcon(val.vehicleObj, val.activity);
      }
    );
    this.polyline2 = await L.polyline(
      this.vehicleOtherInfoStore.timeWisePolylineArray.length > 0
        ? [...this.vehicleOtherInfoStore.timeWisePolylineArray]
        : [],
      {
        color: "#37d5ed",
        smoothFactor: 5,
        weight: 6,
      },
      15
    ).addTo(this.props.This.mapRef.current);
    const bounds2 = this.polyline2.getBounds();

    await this.props.This.mapRef.current.fitBounds(bounds2, {
      maxZoom: 20,
    });
  };

  getGPSStatus(status) {
    // console.log(status)
    if (status === 1) {
      return <>Running</>;
    }
    if (status === 0) {
      return <>Stop</>;
    }
    if (status === undefined || status === null) {
      return <>No Data</>;
    }
  }

  copyTextToClipboard = () => {
    const textToCopy = this.props.info.formatted_address;

    // Create a temporary input element to copy the text
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.value = textToCopy;
    tempInput.select();

    // Execute the copy command
    document.execCommand("copy");

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    // Optionally, you can provide user feedback (e.g., show a message)
    // alert('Text copied to clipboard: ' + textToCopy);
  };

  render() {
    return (
      <div>
        <Draggable>
          <Card
            className="vehicle-info-panel"
            title=<div>
              <span>{this.props.info.vehicleNo}</span>
              <span>
                <CloseOutlined
                  onClick={async () => {
                    await this.props.This.setState({ vehicleOtherInfo: false });
                    await this.clearMap();
                  }}
                />
              </span>
            </div>
            style={{
              zIndex: 1000,
              position: "absolute",
              top: "1%",
              height: "96vh",
              maxHeight: "96vh",
              padding: "10px",
              // right: "69%",
              width: "25%",
            }}
          >
            <div>
              <Tabs
                className="activityTab"
                defaultActiveKey="1"
                items={[
                  {
                    label: this.context.translate("_INFORMATION_"),
                    key: "1",
                    children: (
                      <>
                        <div class="vehicleInformation">
                          <div class="vehiclePic">
                            <Image
                              // src={require("../assets/images/detailtracking/" +
                              //   this.props.vehicleObj.listImage)}
                              src={require("../assets/images/vehicle-type-car.png")}
                            />
                          </div>
                          <div class="vehicleDetails">
                            <p>
                              <span>
                                {" "}
                                <i class="bi bi-person"></i>
                              </span>

                              {/* Adil Rehman */}
                              <span>{this.props.daySummary?.driverName}</span>
                            </p>
                            <p>
                              <i class="bi bi-telephone"></i>
                              {/* 656565656565 */}
                              {this.props.daySummary?.mobileno}
                            </p>
                            <p>
                              <i class="bi bi-broadcast-pin"></i>{" "}
                              {this.getGPSStatus(this.props.info.GPS)}
                            </p>
                          </div>
                        </div>

                        <div className="vehicle-info-address">
                          <p style={{ display: "flex", alignItems: "center" }}>
                            <h6>Location</h6>
                            <Tooltip
                              title="Copy Location"
                              color="green"
                              // style="margin-left: auto;"
                            >
                              <span style={{ marginLeft: "auto" }}>
                                <i
                                  class="bi bi-files"
                                  onClick={this.copyTextToClipboard}
                                ></i>
                              </span>
                            </Tooltip>
                          </p>
                          <p>
                            <span>
                              <i class="bi bi-geo-alt-fill"></i>
                            </span>

                            {this.props.info.formatted_address}
                          </p>
                        </div>

                        <div className="lat-long-block">
                          <p>
                            <span>
                              <i class="bi bi-globe2"></i>
                            </span>
                            {this.props.info.latitude
                              ? parseFloat(this.props.info.latitude).toFixed(7)
                              : ""}
                            ,
                            {this.props.info.longitude
                              ? parseFloat(this.props.info.longitude).toFixed(7)
                              : ""}
                          </p>
                        </div>
                        {/* <div className="lat-long-block">
                          {this.props.info.latitude},{this.props.info.longitude}
                        </div> */}
                        <ul className="vehicle-info-list">
                          <li>
                            <span>{this.context.translate("_VEHICLES_")}:</span>
                            <span>{this.props.info.vehicleNo}</span>
                          </li>

                          <li>
                            <span>
                              {this.context.translate("_CURRENT_STATUS_")}:
                            </span>
                            <span>
                              <i
                                class={this.getColor(
                                  this.props.info.currentStatus
                                )}
                              ></i>{" "}
                              &nbsp;
                              {this.props.info.currentStatus}
                              &nbsp;
                              {this.props.info.currentStatus === "Running"
                                ? "(" +
                                  Math.floor(this.props.info.speed) +
                                  " Km/h)"
                                : ""}
                            </span>
                          </li>

                          <li>
                            <span>
                              {" "}
                              {this.context.translate("_STATE_DURATION_")}:
                            </span>
                            <span>{this.calculateDays()}</span>
                          </li>

                          <li>
                            <span>
                              {this.context.translate("_LAST_SIGNAL_SYNC_")}:
                            </span>
                            <span> {this.props.info.updatedate}</span>
                          </li>

                          <li>
                            <span>{this.context.translate("_FUEL_")}:</span>
                            <span>{this.props.info.fuelHeight1} Liters</span>
                          </li>

                          <li>
                            <span>{this.context.translate("_FLEET_REPORTS_EVENTREPOR_ODOMETER_")}:</span>
                            <span>{this.props.info.odometer}</span>
                          </li>
                        </ul>

                        <div className="share-box">
                          <i
                            class="bi bi-share"
                            onClick={() => {
                              let data = [];
                              data.push(
                                `${Number(this.props.info.latitude)},${Number(
                                  this.props.info.longitude
                                )}`
                              );
                              // console.log(data)
                              this.setState({ shareModalVisible: data });
                            }}
                          ></i>

                          <i
                            class="bi bi-heptagon"
                            onClick={() =>
                              this.props?.This.setState({
                                drawGeofence: true,
                                editing: true,
                                liveTrackingMainModal: false,
                              })
                            }
                          ></i>
                          <i
                            class="bi bi-fuel-pump"
                            onClick={async () => {
                              await this.props.This.setState({
                                fuelStationCenterAreaLatLong: [
                                  this.props?.info?.latitude,
                                  this.props?.info?.longitude,
                                ],
                              });
                              await this.props?.This.SearchMenuItemClick(
                                "fuelstation"
                              );
                            }}
                          ></i>
                        </div>
                      </>
                    ),
                  },
                  {
                    label: (
                      <div
                        onClick={async () => {
                          this.vehicleOtherInfoStore.timeWisePolylineArray = [];

                          this.vehicleOtherInfoStore.offlineDataNewObj = [];
                          this.vehicleOtherInfoStore.idleAndStopPointsArray =
                            [];
                          await this.getVehicleHistInfo();

                          await this.getVehicleOffilneInfo();
                          await this.showPolyline();
                        }}
                      >
                        {this.context.translate("_HISTORY_")}
                      </div>
                    ),
                    key: "2",
                    children: (
                      <div className="hist-datepicker">
                        <RangePicker
                          allowClear={false}
                          format="YYYY-MM-DD"
                          size="small"
                          showTime={false}
                          presets={this.rangePresets.items}
                          onChange={this.onRangeChange}
                          style={{ width: "100%" }}
                          defaultValue={[dayjs().startOf("day"), dayjs()]}
                          // defaultValue={[this.state.fromDate,]}
                        />
                        <Spin spinning={this.vehicleOtherInfoStore.loading}>
                          <div className="summary-info-scroll">
                            {this.state.showFullSummary && (
                              <div className="summary-info-block">
                                {" "}
                                <div className="title">
                                  {this.context.translate("_FULL_SUMMARY_")}
                                </div>
                                <div className="summary-content-block">
                                  <p>
                                    <span className="loc-title">
                                      {this.context.translate(
                                        "_START_LOCATION_"
                                      )}
                                      :
                                    </span>
                                    <small>
                                      {
                                        this.vehicleOtherInfoStore
                                          .vehiclesHistData.startLocation
                                      }
                                    </small>
                                  </p>
                                  <p>
                                    <span className="loc-title">
                                      {" "}
                                      {this.context.translate(
                                        "_FINISH_LOCATION_"
                                      )}
                                      :
                                    </span>
                                    <small>
                                      {
                                        this.vehicleOtherInfoStore
                                          .vehiclesHistData.stopLocation
                                      }
                                    </small>
                                  </p>
                                  <p>
                                    <span>
                                      {this.context.translate("_WORK_TIME_")}:
                                    </span>
                                    <small>
                                      {this.converteToDaysFormat(
                                        this.vehicleOtherInfoStore
                                          ?.vehiclesHistData?.travelduration
                                      )}
                                      {/* {
                                        this.vehicleOtherInfoStore
                                          ?.vehiclesHistData?.travelduration
                                      } */}
                                    </small>
                                  </p>
                                  <p>
                                    <span>
                                      {this.context.translate(
                                        "_FLEET_STOP_TIME_"
                                      )}
                                      :
                                    </span>
                                    <small>
                                      {this.converteToDaysFormat(
                                        this.vehicleOtherInfoStore
                                          ?.vehiclesHistData?.stopduration
                                      )}
                                    </small>
                                  </p>
                                  <p>
                                    <span>
                                      {this.context.translate("_MILEAGE_")}:
                                    </span>
                                    <small>
                                      {this.vehicleOtherInfoStore?.vehiclesHistData?.distanceTravel?.toFixed(
                                        2
                                      )}
                                    </small>
                                  </p>
                                </div>
                              </div>
                            )}
                            <div className="summary-info-block">
                              {this.vehicleOtherInfoStore?.vehicleSummaryData?.map(
                                (val) => (
                                  <>
                                    <div
                                      onClick={async () => {
                                        // await this.clearMap();
                                        this.vehicleOtherInfoStore.histTrackingClickPolylineObj =
                                          [];
                                        this.vehicleOtherInfoStore.timeWisePolylineArray =
                                          [];
                                        this.vehicleOtherInfoStore.idleAndStopPointsArray =
                                          [];
                                        if (this.polyline2) {
                                          this.props.This.mapRef.current.removeLayer(
                                            this.polyline2
                                          );
                                        }
                                        await this.vehicleOtherInfoStore.getHistoryTrackingData(
                                          {
                                            vehicleId:
                                              this.props.info.vehicleId,
                                            companyId:
                                              this.props.info.companyId,
                                            fromDate: val.startTime,
                                            toDate: val.stopTime,
                                          }
                                        );
                                        // console.log(trackingData)
                                        // this.setState({trackingDataObj:trackingData})

                                        await this.showPolyline();
                                        await this.setState({ update: 1 });
                                      }}
                                    >
                                      <div className="title">
                                        {this.context.translate("_SUMMARY_")}
                                      </div>
                                      <div className="summary-content-block">
                                        <p>
                                          <span className="loc-title">
                                            {this.context.translate(
                                              "_START_LOCATION_"
                                            )}
                                            :
                                          </span>
                                          <small>{val.startLocation}</small>
                                        </p>
                                        <p>
                                          <span className="loc-title">
                                            {this.context.translate(
                                              "_FINISH_LOCATION_"
                                            )}
                                            :
                                          </span>
                                          <small>{val.stopLocation}</small>
                                        </p>

                                        <p>
                                          <span>
                                            {this.context.translate(
                                              "_WORK_TIME_"
                                            )}
                                            :
                                          </span>
                                          <small>
                                            {val.travelduration >= 3
                                              ? val.travelduration.toFixed(2)
                                              : val?.travelduration}
                                          </small>
                                        </p>

                                        <p>
                                          <span>
                                            {this.context.translate(
                                              "_FLEET_STOP_TIME_"
                                            )}
                                            :
                                          </span>
                                          <small>{val?.stopduration}</small>
                                        </p>
                                        <p>
                                          <span>
                                            {this.context.translate(
                                              "_MILEAGE_"
                                            )}
                                            :
                                          </span>
                                          <small>{val.distanceTravel}</small>
                                        </p>
                                      </div>
                                    </div>

                                    <div className="summary-info-block">
                                      <div className="title">{val?.sTime}</div>
                                    </div>

                                    {val?.tripData &&
                                      val?.tripData?.map((trip, ind) => (
                                        <div>
                                          <div className="summary-duration-block">
                                            {ind === 0 ? (
                                              <div
                                                onClick={async () => {
                                                  // await this.clearMap();
                                                  this.vehicleOtherInfoStore.timeWisePolylineArray =
                                                    [];
                                                  const resp =
                                                    await this.vehicleOtherInfoStore.getHistoryTrackingData2(
                                                      {
                                                        vehicleId:
                                                          this.props.info
                                                            .vehicleId,
                                                        companyId:
                                                          this.props.info
                                                            .companyId,
                                                        fromDate:
                                                          trip.startTime,
                                                        toDate: trip.stopTime,
                                                      }
                                                    );
                                                  console.log(resp);
                                                  resp > 0 &&
                                                    (await this.showTrackingPolyline());
                                                  // await this.showTrackingPolyline();
                                                  await this.setState({
                                                    update: 1,
                                                  });
                                                }}
                                              >
                                                <div className="duration-row">
                                                  <p>
                                                    <b className="status-stop">
                                                      00:00
                                                      <br />
                                                      {this.context.translate(
                                                        "_STOP_"
                                                      )}
                                                    </b>
                                                    <span>
                                                      {this.context.translate(
                                                        "_STOP_DURATION_"
                                                      )}
                                                    </span>
                                                  </p>

                                                  <small>
                                                    {this.convertToFormattedTime(
                                                      trip.stopSince
                                                    )}
                                                  </small>
                                                </div>

                                                <div className="duration-row">
                                                  <p>
                                                    <b className="status-start">
                                                      {trip.startTime
                                                        .split(" ")[1]
                                                        .slice(0, 5)}
                                                      <br />
                                                      {this.context.translate(
                                                        "_START_"
                                                      )}
                                                    </b>

                                                    <span>
                                                      {this.context.translate(
                                                        "_DRIVING_DURATION_"
                                                      )}
                                                      <br />
                                                      {this.context.translate(
                                                        "_MILEAGE_"
                                                      )}
                                                    </span>
                                                  </p>

                                                  <small>
                                                    {this.convertToFormattedTime(
                                                      trip.startTill
                                                    )}
                                                    <br />
                                                    {trip.distanceTravelled}Km
                                                  </small>
                                                </div>

                                                <div className="duration-row">
                                                  <p>
                                                    <b className="status-stop">
                                                      {trip.stopTime
                                                        ?.split(" ")[1]
                                                        .slice(0, 5)}
                                                      <br />
                                                      {this.context.translate(
                                                        "_STOP_"
                                                      )}
                                                    </b>
                                                    <span>
                                                      {this.context.translate(
                                                        "_STOP_DURATION_"
                                                      )}
                                                    </span>
                                                  </p>

                                                  <small>
                                                    {this.convertToFormattedTime(
                                                      trip.stopTill
                                                    )}
                                                  </small>
                                                </div>
                                              </div>
                                            ) : (
                                              <div
                                                onClick={async () => {
                                                  this.vehicleOtherInfoStore.timeWisePolylineArray =
                                                    [];
                                                  await this.props.This.setState(
                                                    {
                                                      update: 1,
                                                    }
                                                  );
                                                  // await this.showTrackingPolyline();
                                                  const resp =
                                                    await this.vehicleOtherInfoStore.getHistoryTrackingData2(
                                                      {
                                                        vehicleId:
                                                          this.props.info
                                                            .vehicleId,
                                                        companyId:
                                                          this.props.info
                                                            .companyId,
                                                        fromDate:
                                                          trip.startTime,
                                                        toDate: trip.stopTime,
                                                      }
                                                    );

                                                  // this.clearMap();
                                                  // await this.showPolyline();

                                                  resp > 0
                                                    ? await this.showTrackingPolyline()
                                                    : (this.vehicleOtherInfoStore.timeWisePolylineArray =
                                                        []);

                                                  // await this.setState({
                                                  //   update: 1,
                                                  // });
                                                }}
                                              >
                                                <div className="duration-row">
                                                  <p>
                                                    <b className="status-start">
                                                      {trip.startTime
                                                        ?.split(" ")[1]
                                                        .slice(0, 5)}
                                                      <br />
                                                      {this.context.translate(
                                                        "_START_"
                                                      )}
                                                    </b>
                                                    <span>
                                                      {this.context.translate(
                                                        "_DRIVING_DURATION_"
                                                      )}
                                                      <br />
                                                      {this.context.translate(
                                                        "_MILEAGE_"
                                                      )}
                                                    </span>
                                                  </p>

                                                  <small>
                                                    {this.convertToFormattedTime(
                                                      trip.startTill
                                                    )}
                                                    <br />
                                                    {trip?.distanceTravelled}Km
                                                  </small>
                                                </div>

                                                <div className="duration-row">
                                                  <p>
                                                    <b className="status-stop">
                                                      {trip.stopTime
                                                        ?.split(" ")[1]
                                                        .slice(0, 5)}
                                                      <br />
                                                      {this.context.translate(
                                                        "_STOP_"
                                                      )}
                                                    </b>
                                                    <span>
                                                      {this.context.translate(
                                                        "_STOP_DURATION_"
                                                      )}
                                                    </span>
                                                  </p>

                                                  <small>
                                                    {this.convertToFormattedTime(
                                                      trip.stopTill
                                                    )}
                                                  </small>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                  </>
                                )
                              )}
                            </div>
                          </div>
                        </Spin>
                      </div>
                    ),
                  },
                  {
                    label: (
                      <div
                        onClick={async () => {
                          await this.getVehicleAlertInfo();
                        }}
                      >
                        {this.context.translate("_ALERTS_")}
                      </div>
                    ),
                    key: "3",
                    children: (
                      <>
                        {this.vehicleOtherInfoStore.ruleCount == 0 ? (
                          <div className="no-data-block">
                            {this.context.translate("_NO_DATA_AVAILABLE_")}
                          </div>
                        ) : (
                          <div className="summary-info-scroll">
                            {this.vehicleOtherInfoStore.vehiclesTriggerData.map(
                              (val) => (
                                <>
                                  <div className="summary-info-block">
                                    <div className="summary-content-block">
                                      <p>
                                        <span className="loc-title">
                                          {this.context.translate(
                                            "_EVENT_NAME_"
                                          )}
                                          :
                                        </span>
                                        <small>{val.ruleselector}</small>
                                      </p>
                                      <p>
                                        <span className="loc-title">
                                          {this.context.translate("_DATE_")}:
                                        </span>
                                        <small>{val.Time}</small>
                                      </p>

                                      <p>
                                        <span>
                                          {this.context.translate("_LOCATION_")}
                                          :
                                        </span>
                                        <small>{val.Address}</small>
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )
                            )}
                          </div>
                        )}{" "}
                      </>
                    ),
                  },
                  {
                    label: this.context.translate("_ACTIVITY_"),
                    key: "4",
                    children: (
                      <div className="activityTab">
                        <div className="white-row total-activity">
                          <h3>{this.context.translate("_TODAY_ACTIVITY_")}</h3>
                          <p className="kms">
                            <i class="bi bi-geo"></i>{" "}
                            {this.props.daySummary?.distanceTravel} Km
                            <i class="bi bi-car-front"></i>
                          </p>

                          <p>
                            <span>{this.context.translate("_RUNNING_")}</span>
                            <span>{this.props.daySummary?.travelduration}</span>
                            <span>Hrs</span>
                          </p>
                          <p>
                            <span>{this.context.translate("_IDLE_")} </span>
                            <span>{this.props.daySummary?.idleduration} </span>
                            <span> Hrs</span>
                          </p>
                          <p>
                            <span> {this.context.translate("_STOP_")} </span>
                            <span>{this.props.daySummary?.stopduration} </span>
                            <span> Hrs</span>
                          </p>
                          <p>
                            <span>
                              {this.context.translate(
                                "_FLEET_USER_USERLIST_STATUS_INACTIVE_"
                              )}{" "}
                            </span>
                            <span>
                              {" "}
                              {this.props.daySummary?.exidleduration}{" "}
                            </span>
                            <span> Hrs</span>
                          </p>
                        </div>

                        <div className="white-row driver-performance">
                          <h3>
                            {this.context.translate("_DRIVER_PERFORMANCE_")} (
                            {new Date().toLocaleDateString()})
                          </h3>

                          <p className="performance-status harsh">
                            {this.context.translate("_HARSH_BRACKING_")}{" "}
                            <span>
                              {this.props.driverPerformanceObj?.harshbreak}{" "}
                            </span>
                          </p>
                          <p className="performance-status exidle">
                            {this.context.translate("_OVER_SPEED_")}{" "}
                            <span>
                              {this.props.driverPerformanceObj?.overspeedCount}
                            </span>
                          </p>
                          <p className="performance-status exidle">
                            {this.context.translate("_EXIDLE_")}{" "}
                            <span>
                              {this.props.driverPerformanceObj?.exIdleCount}
                            </span>
                          </p>
                          <p className="performance-status harsh-acceleration">
                            {this.context.translate("_HARSH_ACCELERATION_")}{" "}
                            <span>
                              {this.props.driverPerformanceObj?.harshAcc}
                            </span>
                          </p>
                          <p className="performance-status accident-count">
                            {this.context.translate("_ACCIDENT_COUNT_")}{" "}
                            <span>-</span>
                          </p>
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </Card>
        </Draggable>

        {this.state.shareModalVisible && (
          <ShareModalWindow
            shareModalCloseFunc={(resp) => {
              this.setState({ shareModalVisible: resp });
            }}
            shareSingleLocation={this.state.shareModalVisible}
          />
        )}
      </div>
      // <button
      //   onClick={() => {
      //     console.log("click");
      //     this.vehicleOtherInfoStore.offlineDataNewObj.forEach((layer) => {
      //       // this.props.This.
      //       if (layer instanceof L.Polyline) {
      //         this.props.This.mapRef.current.removeLayer(layer);
      //       }

      //     });
      //   }}
      // >
      //   clcc
      // </button>
    );
  }
}
VehicleOtherInfo.contextType = StoreContext;
export default observer(withRouter(VehicleOtherInfo));
// export default memo(VehicleOtherInfo);
