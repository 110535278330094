import React, { Component } from "react";

import {
  Form,
  Select,
  Button,
  message,
  Input,
  Space,
  DatePicker,
  Modal,
  Checkbox,
  Upload,
  Row,
  Col,
  Table,
  Tooltip,
  Spin
} from "antd";

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import config from "../config";
import InventoryStore from "../store/InventoryStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import WareHouseStore from "../store/WareHouseStore";
import { Link } from "react-router-dom";
import Column from "antd/es/table/Column";
import { DeleteFilled, DeleteOutlined } from "@ant-design/icons";
import CompanyStore from "../store/CompanyStore";
import VehicleStore from "../store/VehicleStore";

const { Option } = Select;
class InventoryAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      companyId: "",
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      loadList: 0,
      warehouseList: [],
      tableData: [],
      inventoryId: -1,
      selectedVehicleNo: "",
      warehouseId: "",
      vehicleIdIs: "",
      loadingSpin: false,
    };
    this.inventoryStore = new InventoryStore();
    this.wareHouseStore = new WareHouseStore();
    this.companyStore = new CompanyStore(this);
    this.vehicleStore = new VehicleStore(this);
  }

  async componentDidMount() {
    
    const storingCategory = await this.vehicleStore.getSFDACategories();
    console.log("storingCategory", storingCategory);

    console.log(this.props.params, '"');
    const Id = this.props.inventoryId;
    console.log(Id, "id");

    if (Id) {
      this.filldata(Id);
      this.setState({ Id: Id });
    }
  }

  async filldata(Id) {
    console.log(Id);
    await this.setState({ loadingSpin: true })
    const getInventoryDetails = await this.inventoryStore.getInventory(Id);
    console.log(getInventoryDetails);

    const getInventoryGridDetails =
      await this.inventoryStore.getInventoryGridData(Id);
    console.log(getInventoryGridDetails);
    await this.inventoryStore.getVehicles({
      companyId: getInventoryDetails?.companyId,
    });
    this.setState({ tableData: getInventoryGridDetails });
    console.log(getInventoryGridDetails);

    await this.setState({
      companyId: getInventoryDetails.companyId,
      inventoryId: getInventoryDetails.id,
    });
    const data = await this.inventoryStore.getWarehouselist({
      companyId: getInventoryDetails.companyId,
    });

    const vehicledata = await this.inventoryStore.getVehicles({
      companyId: getInventoryDetails.companyId,
    });

    console.log(data);

    await this.vehicleStore.getSFDACategories();
    await this.vehicleStore.getTypeByCategory(
      getInventoryDetails.storingCategoryId
    );
    //   await this.setState({ warehouseList: getWareHouse });
    const singleData = await this.companyStore.getCompanyDataById(
      getInventoryDetails.companyId
    );
    console.log(getInventoryDetails);
    await this.formRef.current.setFieldsValue({
      companyId: singleData,
      inventoryId: getInventoryDetails.id,
      warehouseId: getInventoryDetails.warehouseId,
      inventory: getInventoryDetails.inventory,
      inventoryNo: getInventoryDetails.inventoryNo,
      storingCategoryId: getInventoryDetails.storingCategoryId,
      storingTypeCode: getInventoryDetails.storingTypeCode,
    });
    await this.setState({ loadingSpin: false })
    
    console.log(getInventoryDetails.warehouseId);
    await this.setState({ loadList: 1 });
  }

  onAddInventorySubmit = async (values) => {
    this.setState({ loading: true });
    const data = {
      ...values,
      inventoryId: this.state.inventoryId,
      companyId: this.state.companyId,
      GridDetails: this.state.tableData,
    };
    console.log(this.state.tableData);
    console.log(data);
    console.log(values);
    const response = await this.inventoryStore.addData(data);
    console.log(this.props.params.id);
    if (!this.props.inventoryId) {
      await this.context.viewStateStore.setInventoryStates(
        this.state.companyId
        // this.state.warehouseId
      );
    }
    // if (response?.success === "S") {
    //   message.success(response.message);
    //   this.props.navigate("/inventorylist");
    // } else {
    //   message.error(["Inventory No Already Exist"]);
    // }
    this.props.response(response);
    this.setState({ loading: false });
  };

  async handleChange(comp) {
    console.log(comp.value);
    console.log(this.props.inventoryId, "EDITRRRRR");
    await this.setState({ companyId: comp.value });
    // if (!this.props.params.id) {
    //   this.context.viewStateStore.setInventoryStates({
    //     // companyId: comp.value,
    //     // warehouseId: this.state.warehouseId,
    //   });
    // }
    // await this.setState({ companyId: comp.value });
    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });

    // await this.setState({ companyId: comp.value });
    const data = await this.inventoryStore.getWarehouselist({
      companyId: comp.value,
    });
    const vehicledata = await this.inventoryStore.getVehicles({
      companyId: comp.value,
    });

    console.log(vehicledata);

    // await this.formRef.current.setFieldsValue({
    //   companyId: this.state.companyId,
    // });
    await this.handleSearch();
    await this.setState({ loadList: 1 });
    console.log(data);
  }

  async handleSearch() {
    await this.inventoryStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      // warehouseId:this.state.warehouseId,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }
  // getWareHouseList() {
  //   const wareHouseList = this.state.warehouseList;
  //   console.log(wareHouseList, "list");
  //   if (wareHouseList) {
  //     return wareHouseList.map((values) => {
  //       return (
  //         <Select.Option value={values.id}>{values.warehouse}</Select.Option>
  //       );
  //     });
  //   }
  // }
  handleAddRecord = () => {
    const { tableData } = this.state;
    console.log(this.state.tableData, "data");

    this.setState({
      tableData: [
        ...tableData,
        {
          Id: 0,
          rId: "",
          waslInventoryKey: "",
          vehicleId: "",
          sensorId: "",
          vehicleNo: "",
          inventoryId: "",
        },
        // console.log(this.inventoryStore?.vehicles?.vehicleNo)
      ],
    });
  };

  handleInputChange = (e, index, fieldName) => {
    const newData = [...this.state.tableData];

    if (fieldName === "vehicleId") {
      // Update the vehicleId for the specific row
      newData[index]["vehicleId"] = e.vehicleId;
      newData[index]["vehicleNo"] = e.vehicleNo;
    } else {
      newData[index][fieldName] = e.target.value;
    }

    this.setState({ tableData: newData });
  };

  getTypeByCategory = async (categoryId) => {
    const data = await this.vehicleStore.getTypeByCategory(categoryId);
    console.log(data, "data");
    await this.setState({ loadData: 1 });
  };

  onDeleteRow = (index) => {
    console.log(index);
    const filterData = this.state.tableData.filter((val, ind) => ind !== index);
    console.log(filterData);
    this.setState({ tableData: filterData });
  };

  closeModal=()=>{
    this.props.closeModal()
  }

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
        <Spin spinning={this.state.loadingSpin} fullscreen >
          <Form
            style={{ maxWidth: "100%", padding: 0 }}
            layout="vertical"
            ref={this.formRef}
            onFinish={this.onAddInventorySubmit}
            // {...this.layout}
            name="nest-message"
            className="formClass"
          >
            {/* <div className="formBorder">  */}
            {/* <div className="headingTitle">Add Inventory</div> */}

            <Row className="overflowFormInputs">
              <Col span={12} className="formColumnClass">
                <Form.Item
                  name="companyId"
                  label={this.context.translate("_ACCOUNT_")}
                  rules={[{ required: true }]}
                >
                  <CompanyAutocomplete
                    onChange={(value) => {
                      this.handleChange(value);
                      this.formRef.current.setFieldsValue({
                        warehouseId: undefined,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="warehouseId"
                  label={this.context.translate("_ADD_WAREHOUSE_")}
                  rules={[{ required: true }]}
                >
                  <Select
                    // style={{ width: 200 }}

                    // placeholder="Warehouse"
                    placeholder={this.context.translate("_ADD_WAREHOUSE_")}
                    fieldNames={{ value: "id", 
                    label: "warehouse"
                   }}
                    optionFilterProp="children"
                    options={this.inventoryStore?.warehouseList}
                  />
                </Form.Item>

                <Form.Item
                  name="inventory"
                  label={this.context.translate("_INVENTORY_NAME_")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12} className="formColumnClass">
                <Form.Item
                  name="inventoryNo"
                  label={this.context.translate("_INVENTORY_NUMBER_")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="storingCategoryId"
                  label={this.context.translate("_STORING_CATEGORY_")}
                  rules={[{ required: true }]}
                >
                  <Select
                    name="storingCategoryId"
                    onSelect={(e) => {
                      this.getTypeByCategory(e);
                      console.log(e);
                    }}
                    onChange={() => {
                      // Reset the value of the second field when the first field changes
                      this.formRef.current.setFieldsValue({
                        storingTypeCode: undefined,
                      });
                    }}
                    // onSearch={this.handleSearch}
                    showSearch
                    fieldNames={{ value: "id", label: "category" }}
                    optionFilterProp="children"
                    options={this.vehicleStore?.getSCategories}
                  />
                </Form.Item>

                <Form.Item
                  name="storingTypeCode"
                  label={this.context.translate("_STORING_TYPE_")}
                  rules={[{ required: true }]}
                >
                  <Select
                    name="storingTypeCode"
                    showSearch
                    fieldNames={{
                      value: "code",
                      label: "categorytype",
                    }}
                    optionFilterProp="children"
                    options={this.vehicleStore?.getTypeofCategory}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Row>
                  <Button
                    type="primary"
                    onClick={this.handleAddRecord}
                    style={{ margin: "20px 0px 20px 0px" }}
                  >
                    {/* Add Record */}
                    {this.context.translate("_ADD_RECORD_")}
                  </Button>
                </Row>
                <Table
                bordered
                  dataSource={this.state.tableData}
                 
                >
                  <Column
                    // title="Id"
                    title={this.context.translate("_Id_")}
                    dataIndex="rId"
                    key="rId"
                    render={(text, record, index) => (
                      <Input
                        value={text}
                        onChange={(e) =>
                          this.handleInputChange(e, index, "rId")
                        }
                      />
                    )}
                  />
                  <Column
                    // title="VehicleId"
                    title={this.context.translate("_FLEET_REPORTS_VEHICLEID_")}
                    dataIndex="vehicleId"
                    key="vehicleId"
                    render={(text, record, index) => (
                      <Select
                        style={{ width: "150px" }}
                        value={text} // Set the value to the vehicleId from the record
                        onSelect={(e, f) => {
                          console.log(f);
                          this.handleInputChange(f, index, "vehicleId");
                        }}
                        fieldNames={{
                          value: "vehicleId",
                          label: "vehicleNo",
                        }}
                        optionFilterProp="children"
                        options={this.inventoryStore?.vehicles}
                      />
                    )}
                  />

                  <Column
                    // title="SensorId"
                    title={this.context.translate("_SENSORID_")}
                    dataIndex="sensorId"
                    key="sensorId"
                    render={(text, record, index) => (
                      <Input
                        value={text}
                        onChange={(e) =>
                          this.handleInputChange(e, index, "sensorId")
                        }
                      />
                    )}
                  />

                  <Column
                    // title="Action"
                    title={this.context.translate("_ACTION_")}
                    dataIndex=""
                    key=""
                    render={(text, record, index) => (
                      <div>
                        <Space>
                          <Link
                            onClick={async () => {
                              await this.onDeleteRow(index);
                              // console.log(record, text);
                              // await this.onDeleteGridData(record, "record");
                            }}
                          >
                            <Tooltip
                              // title="Delete"
                              title={this.context.translate("_FLEET_COMPANY_COMPANYLIST_DELETE_")}
                              color={config.tooltipColorDelete}
                            >
                              <DeleteFilled
                                style={{ color: config.deleteColor }}
                              />
                            </Tooltip>
                          </Link>
                        </Space>
                      </div>
                    )}
                  />
                </Table>
              </Col>
            </Row>
            <div className="formBtnGroup">
              <Button
                // ghost
                // danger
                // type="primary"
                className="formCancelButton"
                onClick={(e) => {
                  this.closeModal()
                  // this.formRef.current?.resetFields();
                }}
              >
                {/* Cancel */}
                {this.context.translate("_FLEET_CLOSE_BTN_")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.loading}
                className="formSaveButton"
              >
                {/* Save */}
                {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
              </Button>
            </div>
          </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

InventoryAdd.contextType = StoreContext;
export default observer(withRouter(InventoryAdd));
