import { observer } from "mobx-react";
import React, { Component } from "react";
import withRouter from "../withRouter";
import {
  Button,
  Col,
  DatePicker,
  Empty,
  Input,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { StoreContext } from "../store/RootStore";
import DistanceGraphStore from "../store/DistanceGraphStore";
import dayjs from "dayjs";
// import { Bar, Column, tooltip } from "@ant-design/plots";
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  CartesianGrid,
  LineChart,
} from "recharts";

import TempGraphStore from "../store/TempGraphStore";
import VehicleStore from "../store/VehicleStore";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";

const { RangePicker } = DatePicker;
const { Option } = Select;

const legendStyle = {
  position: "absolute",
  top: "-25px",
  left: "89%",
  transform: "translateX(-50%)",
};

class TempGraph extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.distanceGraphStore = new DistanceGraphStore(this);
    this.tempGraphStore = new TempGraphStore(this);
    this.vehicleStore = new VehicleStore(this);

    this.state = {
      selectAllVehicles: false,
      selectedVehicles: "",
      update: 0,
      companyId: "",
      distance: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      loadData: 0,
      showTempGraph: false,
      displayVehicleNo: "",
      tempMax: "",
      tempMin: "",
      intervel: 20,
      // fromNewDate: dayjs().format("YYYY-MM-DD HH:mm:ss A"),
      fromNewDate: dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss A"),
      toNewDate: dayjs().format("YYYY-MM-DD HH:mm:ss A"),
      showLegend: true,
      showLegend1: true,
      Notasset: false,
      loadingSpin: false,
      showLegend4: true,
      showLegend3: true,
    };
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });
    await this.setState({ selectedVehicles: "" });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);

    this.setState({ update: 1 });
  }

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.vehicleStore.getVehiclesCompanyId(
        this.props.getUserData().userCompanyId
      );
      await this.setState({ update: 1 });
    }
  }

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (!this.state.selectedVehicles) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles) {
      // this.setState({mandatory:false})
      await this.setState({ loadingSpin: true });

      this.setState({ Notcompany: false, Notasset: false });
      const data = await this.tempGraphStore.loadTempGraphData({
        // offset: (this.state.pageNo - 1) * this.state.pageSize,
        // limit: this.state.pageSize,
        companyId: this.state.companyId,
        vehicleId: this.state.selectedVehicles,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        tempMax: this.state.tempMax,
        tempMin: this.state.tempMin,
        intervel: this.state.intervel,

        // pageNo: this.state.pageNo,
      });
      console.log(data);
      this.setState({ loadData: 1 });
      await this.setState({ loadingSpin: false });
    }
  };

  onDateTimeChange = (dates) => {
    console.log(dates);
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
      const dateFormat = "YYYY-MM-DD hh:mm:ss A";
      this.setState({
        fromdateStdTime: dates[0],
        fromNewDate: dates[0].format(dateFormat),
        toNewDate: dates[1].format(dateFormat),
      });
    }
  };

  customTickFunction = (props) => {
    const { x, y, payload, visibleTicksCount, tickFormatter } = props;
    // console.log(x,y,)

    // Customize the tick appearance or position here
    return (
      <text
        x={x - 20}
        y={this.context.locale.language === "english" ? y + 5 : y}
        textAnchor="end"
        fontSize={12}
        dy={-4}
        style={{ lineHeight: "1.2" }}
      >
        {tickFormatter ? tickFormatter(payload.value) : payload.value}
      </text>
    );
  };

  // CustomTooltip = ({ active, payload }) => {
  //   console.log(active, payload);
  //   if (active && payload && payload.length) {
  //     const data = payload[0].payload;
  //     console.log(data);
  //     return (
  //       <div
  //         style={{ border: "1px solid", background: "white", padding: "10px" }}
  //       >
  //         {/* <div>{`Temprature Sensor1: ${data.temp1}`}</div>
  //       <div>{`Driver Name: ${data.name}`}</div>
  //       <div>{`Driver Mobile No: ${data.driverphone}`}</div> */}
  //         <div>{`${this.context.translate("_TEMPERATURE_SENSOR1_")}: ${
  //           data.temp1 ? data.temp1 : ""
  //         }`}</div>
  //         <div>{`${this.context.translate(
  //           "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
  //         )}: ${data.name ? data.name : "-"}`}</div>
  //         <div>{`${this.context.translate("_DRIVER_MOBILE_NO_")}: ${
  //           data.driverphone ? data.driverphone : "-"
  //         }`}</div>
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  CustomTooltip = ({ active, payload, label }) => {
    let res = "";
    payload.map((entry, index) => {
      let speed = parseFloat(entry.payload.speed);
      let acc = parseInt(entry.payload.acc);

      if (acc === 0 && speed === 0.0) {
        res = "(Stop)";
      } else if (acc === 1 && speed === 0.0) {
        res = "(Idle)";
      } else if (acc === 1 && speed > 0) {
        res = "(Running)";
      }
    });

    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div className="custom-tooltip">
          {/* <p>{`Time: ${label}`}</p> */}

          {payload.map((entry, index) => (
            // console.log(entry.value, index)
            <>
              <p
                key={`value-${index}`}
                style={{ background: "white", padding: "10px" ,border: "1px solid"}}
              >
                {`Temperature Sensor : ${index + 1}: ${entry.value} ${res}`}
                <br />
                {`Driver Name : ${data.name ? data.name : "-"}`}
                <br />
                {`Driver Mobile No : ${
                  data.driverphone ? data.driverphone : "-"
                }`}
              </p>

              {/* <p style={{ background: "white", padding: "10px" }}>
                {`Driver Name ${data.name ? data.name : "-"}`}
              </p>
              <p style={{ background: "white", padding: "10px" }}>
                {`Driver Phoone ${data.driverphone ? data.driverphone : "-"}`}
              </p> */}
            </>
          ))}
        </div>
      );
      // }
    }
    return null;
  };

  // handleLegendClick = (dataKey) => {
  //   // Update the state to toggle the visibility of the clicked line
  //   this.setState((prevState) => ({
  //     showLegend: !prevState.showLegend,
  //   }));
  // };

  handleLegendClick = (legendItem, index) => {
    const dataKey = legendItem.dataKey;

    this.setState((prevState) => ({
      [dataKey]: !prevState[dataKey],
    }));
  };

  render() {
    const legendProps = {
      onClick: this.handleLegendClick,

      // onMouseEnter: () => this.setState({ hoveredLegendKey: null }),
      // onMouseLeave: () => this.setState({ hoveredLegendKey: null }),
    };
    const customLegendPayload = [
      {
        // value: "Temp Sensor1",
        value: this.context.translate("_TEMP_SENSOR1_"),
        color: "orange",
        dataKey: "showLegend",
      },

      {
        // value: "Temp Sensor1",
        value: this.context.translate("_TEMP_SENSOR2_"),
        color: "red",
        dataKey: "showLegend1",
      },
      {
        // value: "Temp Sensor1",
        value: this.context.translate("_TEMP_SENSOR3_"),
        color: "green",
        dataKey: "showLegend3",
      },
      {
        // value: "Temp Sensor1",
        value: this.context.translate("_TEMP_SENSOR4_"),
        color: "blue",
        dataKey: "showLegend4",
      },
    ];
    return (
      <>
        <Spin spinning={this.state.loadingSpin} fullscreen>
          <div style={{ padding: 0 }}>
            <Row className="headerFixed" style={{ height: "50px" }}>
              <Col span={12}>
                {/* <div className="heading">Temp Graph</div> */}
                <div className="heading">
                  {this.context.translate("_TEMP_GRAPH_")}
                </div>
              </Col>
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>
          </div>
          <div
            style={{
              paddingTop: "10px",
              paddingRight: "24px",
              paddingLeft: "24px",
            }}
          >
            <Row className="listInputRow">
              <Col span={23}>
                <Space>
                  <CompanyAutocomplete
                    onChange={this.handleChange}
                    allowClear={true}
                    style={{ width: 175 }}
                    status={this.state.Notcompany ? "error" : false}
                  />

                  {this.props.getUserData().isadmin === 1 && (
                    <DeviceGroupsForVehicles
                      vehicleStore={this.vehicleStore}
                      setLoadDta={() => this.setState({ loadData: 1 })}
                      resetVehicles={() =>
                        this.setState({
                          selectedVehicles: "",
                          selectAllVehicles: false,
                        })
                      }
                    />
                  )}
                  <Select
                    value={
                      this.state.selectedVehicles
                        ? this.state.selectedVehicles
                        : null
                    }
                    // onChange={(val) => {
                    //   this.setState({ selectedVehicles: val });
                    //   this.setState({ Notasset: false });
                    // }}
                    onChange={(val, x) => {
                      this.setState({
                        selectedVehicles: val,
                        displayVehicleNo: x.children,
                      });
                    }}
                    style={{ width: 170 }}
                    showSearch
                    status={this.state.Notasset ? "error" : false}
                    placeholder={this.context.translate(
                      "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                    )}
                    filterOption={(inputValue, option) => {
                      if (option.children) {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {this.vehicleStore?.getVehiclesUsingCompanyId?.map(
                      (val) => {
                        return (
                          <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                        );
                      }
                    )}
                  </Select>
                  <RangePicker
                    // placeholder={["From Datetime", "To Datetime"]}
                    placeholder={[
                      this.context.translate("_FROM_DATETIME_"),
                      this.context.translate("_TO_DATETIME_"),
                    ]}
                    format="DD-MM-YYYY hh:mmA"
                    style={{ width: 375 }}
                    onChange={(val) => {
                      this.onDateTimeChange(val);
                    }}
                    disabledDate={(current) => {
                      return current && current > dayjs();
                    }}
                    showTime={{
                      format: "h:mmA",
                      defaultValue: [dayjs().startOf("day"), dayjs()],
                    }}
                    defaultValue={[dayjs().startOf("day"), dayjs()]}
                  />
                  <Input
                    style={{ width: 125 }}
                    // placeholder="Min Temp"
                    placeholder={this.context.translate("_MIN_TEMP_")}
                    value={this.state.tempMin}
                    onChange={(e) => this.setState({ tempMin: e.target.value })}
                  />

                  <Input
                    style={{ width: 125 }}
                    // placeholder="Max Temp"
                    placeholder={this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE9_MAXTEMP_"
                    )}
                    value={this.state.tempMax}
                    onChange={(e) => this.setState({ tempMax: e.target.value })}
                  />

                  <Select
                    defaultValue={20}
                    style={{ width: 100 }}
                    onSelect={(intervel) =>
                      this.setState({ intervel: intervel })
                    }
                    // placeholder="Interval"
                    placeholder={this.context.translate("_INTERVAL_")}
                  >
                    <Select.Option value={1}>1</Select.Option>
                    <Select.Option value={5}>5</Select.Option>
                    <Select.Option value={10}>10</Select.Option>
                    <Select.Option value={15}>15</Select.Option>
                    <Select.Option value={20}>20</Select.Option>

                    <Select.Option value={30}>30</Select.Option>
                    <Select.Option value={60}>60</Select.Option>
                  </Select>

                  <Button
                    type="primary"
                    onClick={async () => {
                      console.log("safdsdfsdads");
                      await this.showData();
                      await this.setState({ showTempGraph: true });
                      // console.log(this.distanceGraphStore?.distanceGraph);
                    }}
                  >
                    {/* Show */}
                    {this.context.translate("_FLEET_SHOW_BTN_")}
                  </Button>
                </Space>
              </Col>
              <Col span={1}>
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>

            <div
              style={{
                paddingTop: "5px",
                paddingRight: "24px",
                paddingLeft: "24px",
              }}
            >
              {this.tempGraphStore?.tempGraph?.length > 0 && (
                <Row className="listInputRow">
                  <Col span={18}>
                    {/* <div className="heading">Temperature Graph</div> */}
                    <div className="heading">
                      {this.context.translate("_TEMPERATURE_GRAPH_")}
                    </div>

                    {/* <b>Vehicle No : {this.state.displayVehicleNo}</b> */}
                    <b>
                      {this.context.translate(
                        "_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"
                      )}{" "}
                      : {this.state.displayVehicleNo}
                    </b>
                    <br />
                    {/* <b>From DateTime : {this.state.fromNewDate}</b> */}
                    <b>
                      {this.context.translate("_FROM_DATETIME_")} :{" "}
                      {this.state.fromNewDate}
                    </b>
                    <br />
                    {/* <b>To DateTime : {this.state.toNewDate}</b> */}
                    <b>
                      {this.context.translate("_TO_DATETIME_")} :{" "}
                      {this.state.toNewDate}
                    </b>
                    <br />
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <Space
                      style={{
                        float:
                          this.context.locale.direction == "ltr"
                            ? "right"
                            : "left",
                      }}
                    ></Space>
                  </Col>
                </Row>
              )}
            </div>

            {this.state.showTempGraph &&
            this.tempGraphStore?.tempGraph?.length > 0 ? (
              <div>
                <ResponsiveContainer height={400}>
                  <LineChart
                    width={500}
                    //   height={300}
                    data={this.tempGraphStore?.tempGraph}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 20,
                      bottom: 60,
                    }}
                  >
                    <CartesianGrid strokeDasharray="2 2" />
                    {/* <CartesianGrid /> */}
                    <XAxis
                      angle={
                        this.context.locale.language === "english" ? -90 : 90
                      }
                      // interval={Math.ceil(
                      //   this.tempGraphStore?.tempGraph.length / 35
                      // )}
                      textAnchor="end"
                      dataKey="timestamp"
                      height={150}
                      label={{
                        value: this.context.translate("_TIME_"),
                        position: "bottom",
                        fontWeight: "bold",
                        fontSize: "17px",
                      }}
                    />
                    <YAxis
                      label={{
                        value: this.context.translate("_TEMPERATURE_"),
                        angle: -90,
                        fontWeight: "bold",
                        fontSize: "17px",

                        // position: "insideLeft",
                      }}
                      // tick={this.customTickFunction}
                      style={{
                        direction:
                          this.context.locale.language === "arabic"
                            ? "ltr"
                            : "ltr",
                      }}
                    />

                    {
                      <Legend
                        {...legendProps}
                        payload={customLegendPayload}
                        layout="horizontal"
                        align="top"
                        wrapperStyle={legendStyle}
                      />
                    }

                    <Tooltip cursor={false} content={<this.CustomTooltip />} />

                    <Line
                      type="monotone"
                      dataKey="temp1"
                      stroke="orange"
                      dot={{ fill: "orange" }}
                      hide={!this.state.showLegend}
                      activeDot={{ r: 8 }}
                    />
                    <Line
                      type="monotone"
                      dataKey="temp2"
                      stroke="red"
                      dot={{ fill: "red" }}
                      hide={!this.state.showLegend1}
                    />
                    <Line
                      type="monotone"
                      dataKey="temp4"
                      stroke="blue"
                      dot={{ fill: "blue" }}
                      hide={!this.state.showLegend4}
                    />
                    <Line
                      type="monotone"
                      dataKey="temp3"
                      stroke="green"
                      dot={{ fill: "green" }}
                      hide={!this.state.showLegend3}
                    />
                  </LineChart>
                </ResponsiveContainer>
                {/* {this.CustomLegend({ data: legends })} */}
              </div>
            ) : (
              <Empty></Empty>
            )}
          </div>
        </Spin>
      </>
    );
  }
}
TempGraph.contextType = StoreContext;
export default observer(withRouter(TempGraph));
