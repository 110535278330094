import { encode as base64_encode } from "base-64";

import { postApi, baseURL } from "../components/Utility";
import config from "../config";
import { action, makeObservable, observable } from "mobx";

class UsersStore {
  users = [];
  getDeviceGroupsUsingCompanyId = [];
  getVehiclesUsingCompanyId = [];
  groupVehicles = [];
  searchVehicles = [];
  assignvehicles = [];
  devicegroupId = [];

  beforeEditCompanyNo = "";

  total = 0;
  loading = false;

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      users: observable,
      beforeEditCompanyNo: observable,
      getDeviceGroupsUsingCompanyId: observable,
      getVehiclesUsingCompanyId: observable,
      groupVehicles: observable,
      total: observable,
      loading: observable,
      searchVehicles: observable,
      assignvehicles: observable,
      devicegroupId: observable,

      loadData: action,
      addData: action,
      getUserData: action,
      sendEmail: action,
      exportExcel: action,
      beforeEditCompanyId: action,
      setPhotoFromDb: action,

      getDeviceGroups: action,
      getVehiclesData: action,
      getGroupVehicles: action,
      searchVehicle: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    offset,
    limit,
    companyId,
    searchField,
    userType,
    userStatus,
    isadmin,
    userId,
  }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      offset: offset,
      limit: limit,
      searchField: searchField,
      userType: userType,
      userStatus: userStatus,
      isadmin: isadmin,
      userId: userId,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=user&a=getuserList", "postData=" + base64Input);
    // console.log("company Data",response?.data?.data?.companydata)
    //console.log("users list",response.data.data.company[0].users)

    if (response?.data?.data === null) {
      this.users = [];
      this.loading = false;
    } else {
      this.users = await response?.data?.data.users;
      this.loading = false;
    }

    //console.log(this.users)
    //  this.setCompanies(response?.data?.data?.companydata);
    this.total = parseInt(response?.data?.data.TotalCount);
    this.loading = false;
  }
  async setPhotoFromDb(photo) {
    console.log(photo);
    this.photoFromDb = { fileList: photo };
  }

  async addData(user) {
    console.log("user", user);
    // var postData= {"tokenId":"80bovn8u800tg1aq9bvr73kbhh","user":{"userId":-1,"companyId":24,"name":"Prakash raju","emailId":"prakash@gmail.com","mobileno":"04555802085","address":"hingoli","departmentId":"","username":"raghav","password":"pass","status":1,"photo":"","userType":1,"isadmin":0,"roleId":"","isdefaultrole":0}}
    var postData = { tokenId: "80bovn8u800tg1aq9bvr73kbhh", user };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=user&a=update", "postData=" + base64Input);
    console.log(response, "post");
    return response;
  }

  async getUserData(userId) {
    //console.log("users",userId,)
    var postData = { userId: userId, limit: 1, offset: 0 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=user&a=getuserList", "postData=" + base64Input);
    //  console.log(response)
    var singleRow = response.data.data.users[0];

    if (singleRow.photo && singleRow.isadmin === 2) {
      const fileList = [
        {
          name: singleRow.photo,
          status: "success",
          url: config.fleetURL + "/images/drivers/" + singleRow.photo,
        },
      ];

      this.setPhotoFromDb(fileList);
    } else if (singleRow.photo && singleRow.isadmin === 5) {
      const fileList = [
        {
          name: singleRow.photo,
          status: "success",
          url: config.fleetURL + "/images/salesperson/" + singleRow.photo,
        },
      ];

      this.setPhotoFromDb(fileList);
    } else if (singleRow.photo) {
      const fileList = [
        {
          name: singleRow.photo,
          status: "success",
          url: config.fleetURL + "/images/users/" + singleRow.photo,
        },
      ];

      this.setPhotoFromDb(fileList);
    }

    return singleRow;
  }

  async deleteRecord({ userId }) {
    var postData = { user: { userId: userId } };
    console.log(postData);
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=user&a=delete", "postData=" + base64Input);
    return response;
  }

  async sendEmail({ emailId, username, password }) {
    this.loading = true;
    var postData = { emailId: emailId, username: username, password: password };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=user&a=sendUserDetails", "postData=" + base64Input);
    this.loading = false;

    return response;
  }

  async beforeEditCompanyId(value) {
    this.beforeEditCompanyNo = value;
  }
  //For Assign vehicle to user
  async getDeviceGroups({ companyId }) {
    console.log(companyId, "id");
    const postData = { companyId: companyId, offset: 0, limit: 15, page: 1 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=getDeviceGroups", "postData=" + base64Input);
    // console.log(response);

    this.getDeviceGroupsUsingCompanyId = response?.data?.data?.devicegroups;

    return response?.data?.data?.devicegroups;
  }
  //For Assign vehicle to user
  async getGroupVehicles({ groupNameId }) {
    console.log(groupNameId, "id");
    const postData = { groupNameId: groupNameId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=company&a=getGroupVehicles",
        "postData=" + base64Input
      );
    // console.log(response);

    this.groupVehicles = response?.data?.data?.getDeviceGroupsVehicles;

    this.searchVehicles = response?.data?.data?.getDeviceGroupsVehicles;
    return response?.data?.data?.getDeviceGroupsVehicles;
  }
  //For Assign vehicle to user
  async getVehiclesData({ companyId, userId }) {
    console.log(companyId, "id");
    const postData = {
      ruleId: "",
      companyId: companyId,
      userId: userId,
      isadmin: -1,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=getAllUserVehicles",
        "postData=" + base64Input
      );

    this.getVehiclesUsingCompanyId = response?.data?.data?.Vehicles;
    this.assignvehicles = response?.data?.data?.assignvehicles;
    this.devicegroupId = response?.data?.data?.devicegroupId;

    this.total = parseInt(response?.data?.data?.TotalCount);
    return response?.data?.data?.Vehicles;
  }

  async searchVehicle({ userId, companyId, text }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: -1,
      text: text,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=searchVehicleUser",
        "postData=" + base64Input
      );
    this.searchVehicles = await response?.data?.data;

    return await response?.data?.data;
  }

  async updateStatus(data) {
    console.log(data); //update active in-active
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=user&a=updateActive", "postData=" + base64Input);
    return response.data;
  }

  async exportExcel(getData) {
    console.log("getData", getData);
    window.location.href =
      baseURL +
      "index.php?c=user&a=exportUsersExcel&cid=" +
      getData.companyId +
      "&searchField=" +
      getData.searchField +
      "&colList=" +
      getData.colList;
  }
}

export default UsersStore;
