import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi,baseURL} from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class MachineryReportStore {
  machineryReport = [];
  emailIdsList=[]
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      machineryReport: observable,
      total: observable,
      loading: observable,
      loadData: action,
      exportcsv:action,
      exportExcel:action,
      exportpdf:action,
      exportxml:action,
      getEmailIds:action
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId,offset, limit, vehicleId, fromDate, toDate,pageNo}) {
    this.loading = true;
    console.log("Machinery Report ");

    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      offset: offset,
      limit: limit,
      page: pageNo,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=machineryReport", "postData=" + base64Input);
    console.log("Machinery", response?.data?.data?.workinghour);
    // console.log("company Data",response?.data?.data?.workinghour)
    this.machineryReport = response?.data?.data?.workinghour;

    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }

  async getEmailIds({companyId}) {
    console.log("companyId", companyId)
    var postData = {"companyId":companyId}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=vehicle&a=getEmailIds", "postData=" + base64Input);
    console.log(response, "post")
  
    this.emailIdsList = response?.data?.data
    return response?.data?.data
  }

  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportMachineryExcel&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      "&userId="+
      getData.userId+
      "&int=" +
     0 +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportGensetWorkingHourcsv&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&userId="+
      getData.userId+
      "&colList=" +
      getData.colList;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportMachineryPdf&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&userId="+
      getData.userId+
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportxml(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicleidle&a=exportexidlexml&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      "&int=" +
      getData.interval +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }

  // async sendExcelByEmail(data) {
  //   var postData = data;
  //   console.log(postData);
  //   const base64Input = await base64_encode(JSON.stringify(postData));
  //   var response = await postApi
  //     .getClient()
  //     .post(
  //       "index.php?c=vehicle&a=exportstartstopexcel",
  //       "postData=" + base64Input
  //     );
  //   console.log(response);

  //   return response.data;
  // }
}

export default MachineryReportStore;
