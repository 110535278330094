import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

import config from "../config";

class JobManagementStore {
  jobManagement = [];
  getSalesPersonExecutiveArray = [];
  assetVehicleList = [];
  getSalesPersonExArr = [];
  geofenceList = [];
  VehicleList = [];
  getDrivers = [];
  getSalesPersonCoordinatorArray;
  routeData = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      jobManagement: observable,
      getSalesPersonExArr: observable,
      total: observable,
      loading: observable,
      geofenceList: observable,
      VehicleList: observable,
      routeData: observable,
      loadData: action,
      addData: action,
      getJobManagementData: action,
      getSalesPersonExecutive: action,
      getSalesPersonCoordinator: action,
      getSalesPersonEx: action,
      getVehicles: action,
      deleteRecord: action,
      finishJob: action,
      getGeofenceData: action,
      getAllVehicles: action,
      sampleExcelDriver: action,
      getDriversByCompanyId: action,
      sampleExcelSalesPerson: action,
      setPhotoFromDb: action,
      getRoutesData: action,
      cancelJob: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    offset,
    limit,
    companyId,
    vehicleId,
    deliveryDateFrom,
    deliveryDateTo,
    driverName,
    salesExecutive,
    customerName,
  }) {
    this.loading = true;

    var postData = {
      tokenId: "",
      companyId: companyId,
      vehicleId: vehicleId,
      deliveryDateFrom: deliveryDateFrom,
      deliveryDateTo: deliveryDateTo,
      driverName: driverName,
      salesExecutive: salesExecutive,
      customerName: customerName,
      offset: offset,
      limit: limit,
    };
    // var postData = { "companyId": companyId, "vehicleId": null, "deliveryDateFrom": "2023-04-10", "deliveryDateTo": "2023-04-10", "driverName": null, "salesExecutive": null, "customerName": "", "offset": 0, "limit": 50 }
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=delivery&a=getDelivery", "postData=" + base64Input);

    this.jobManagement = response.data.data.deliverymanagement;

    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async addData(values) {
    var postData = values;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=delivery&a=update", "postData=" + base64Input);

    return response.data;
  }

  async setPhotoFromDb(photo) {
    this.photoFromDb = { fileList: photo };
  }

  async getJobManagementData(id) {
    var postData = { did: id, offset: 0, limit: 1 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=delivery&a=getDelivery", "postData=" + base64Input);

    // return response.data?.data?.deliverymanagement[0];
    const singleRow = response.data?.data?.deliverymanagement[0];
    if (singleRow.invoice) {
      const fileList = [
        {
          name: singleRow.invoice,
          status: "success",
          url: config.fleetURL + "/images/invoices/" + singleRow.invoice,
        },
      ];

      this.setPhotoFromDb(fileList);
    }
    return singleRow;
  }

  // using in job management dropdown
  async getSalesPersonExecutive({ companyId }) {
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=salesperson&a=getPerson", "postData=" + base64Input);

    let allSalesPersonExecutive = [
      { salespersonId: "0", SalesExecutiveName: "ALL" },
    ];
    allSalesPersonExecutive = [
      ...allSalesPersonExecutive,
      ...response?.data?.data,
    ];
    this.getSalesPersonExecutiveArray = allSalesPersonExecutive;
  }

  async getSalesPersonEx({ companyId }) {
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=salesperson&a=getPerson", "postData=" + base64Input);

    this.getSalesPersonExArr = response?.data?.data;
  }

  async getSalesPersonCoordinator({ companyId }) {
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=salesperson&a=coordinator", "postData=" + base64Input);

    this.getSalesPersonCoordinatorArray = response?.data?.data;
  }

  async getVehicles(companyId) {
    var postData = {
      tokenId: "80bovn8u800tg1aq9bvr73kbhh",
      usercompanyId: 1,
      companyId: companyId,
      isadmin: -1,
      offset: 0,
      limit: 100,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);

    this.assetVehicleList = response?.data?.data;
    return response?.data?.data;
  }

  async sampleExcelDriver() {
    window.location.href =
      config.fleetURL + "/appRes/excel/DriverJobDetails.xlsx";
  }

  async sampleExcelSalesPerson() {
    window.location.href =
      config.fleetURL + "/appRes/excel/salesPersonJobDetails.xlsx";
  }

  async addDriverByExcel(excelFile) {
    this.excelLoadig = true;
    var postData = excelFile;

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=delivery&a=uploadExcel", "postData=" + base64Input);
    this.excelLoadig = false;
    return response.data;
  }

  async deleteRecord({ Id, companyId }) {
    var postData = { tokenId: "", companyId: companyId, Id: Id };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=delivery&a=delete", "postData=" + base64Input);
    return response;
  }

  async finishJob(values) {
    var postData = values;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=delivery&a=updateStatus", "postData=" + base64Input);

    return response.data;
  }

  async getGeofenceData({ companyId }) {
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=geofence&a=geoData", "postData=" + base64Input);

    this.geofenceList = response?.data?.data?.geofence;
    return response?.data?.data?.geofence;
  }

  async getAllVehicles(companyId) {
    var postData = {
      tokenId: "80bovn8u800tg1aq9bvr73kbhh",
      usercompanyId: 1,
      companyId: companyId,
      isadmin: -1,
      offset: 0,
      limit: 100,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);

    let allVehicles = [{ vehicleId: "0", vehicleNo: "ALL" }];
    allVehicles = [...allVehicles, ...response?.data?.data];
    this.VehicleList = allVehicles;
    return response?.data?.data;
  }

  async getDriversByCompanyId(companyId) {
    var postData = {
      companyId: companyId,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=driver&a=getDriver", "postData=" + base64Input);

    let allDrivers = [{ driverId: "0", driverName: "ALL" }];
    allDrivers = [...allDrivers, ...response?.data?.data];
    this.getDrivers = allDrivers;

    return response?.data?.data;
  }

  async getRoutesData(companyId) {
    var postData = {
      companyId: companyId,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=routeManagement&a=getRoutes",
        "postData=" + base64Input
      );

    this.routeData = response?.data?.data;
    return response?.data?.data;
  }

  async getDepositorsData(companyId) {
    var postData = {
      companyId: companyId,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=user&a=getDepositors", "postData=" + base64Input);

    this.depositorsList = response?.data?.data;
    return response?.data?.data;
  }

  async cancelJob(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=delivery&a=cancleJob", "postData=" + base64Input);

    return response?.data;
  }
}

export default JobManagementStore;
