import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class DiscountStore {
  loading = false;
  total = 0;
  discountList = [];
  vehicleTypeArr = [];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      total: observable,
      discountList: observable,
      vehicleTypeArr: observable,
      loadData: action,
      deleteRecord: action,
      vehicleTypeData: action,
      addData: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit, page }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      offset: offset,
      limit: limit,
      page: page,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=discount&a=getDiscount", "postData=" + base64Input);

    this.discountList = response?.data?.data?.discountdata;
    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
  }

  async vehicleTypeData() {
    var response = await postApi
      .getClient()
      .post("index.php?c=discount&a=getvehicletype");
    this.vehicleTypeArr = response.data.data;
  }

  async addData(discountDetails) {
    var postData = discountDetails;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=discount&a=update", "postData=" + base64Input);

    return response.data;
  }

  async getDiscountData({ discountId, companyId }) {
    var postData = {
      discountId: discountId,
      companyId: companyId,
      limit: 1,
      offset: 0,
    };
    // console.log(postData)

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=discount&a=getDiscount", "postData=" + base64Input);

    return response?.data?.data?.discountdata[0];
  }

  async deleteRecord({ discountId }) {
    const postData = {
      discountId: discountId,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=discount&a=delete", "postData=" + base64Input);

    return response;
  }
}

export default DiscountStore;
