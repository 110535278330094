import React from "react";
import moment from "moment/moment";
import Cookies from "js-cookie";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import {
  Row,
  Col,
  Input,
  Button,
  Space,
  message,
  Modal,
  Form,
  Select,
  DatePicker,
  Dropdown,
  Tooltip,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";

import {
  DeleteFilled,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import config from "../config";
import CompanyAutocomplete from "./CompanyAutocomplete";
import CompanyStore from "../store/CompanyStore";
import ListTable from "./ListTable";
import withRouter from "../withRouter";
import WASLDriverStore from "../store/WASLDriverStore";
import DeleteConfirm from "./DeleteConfirm";
import dayjs from "dayjs";
const dateFormat = "YYYY-MM-DD";

class WASLDriverList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.waslDriverStore = new WASLDriverStore(this);
    this.companyStore = new CompanyStore();
    // console.log(this.context);

    this.state = {
      showWASLRegistration: false,
      loading: false,
      pageSize: 50,
      limit:50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      loadList: 0,
      Company: true,
      Individual: false,
      cname: "",
      identityNo: "",
      crNo: "",
      loadData: 0,
      companyId: "",
      vehicleId: "",
      showAlertModal: false,
      searchField: "",
      currentPage: 1,
    };
    this.formRef = React.createRef();

    this.ExportMenuProps = {
      items: [
        {
          label: "Excel",
          key: "excel",
        },
      ],
      onClick: (val) => {
        const result = [];
        this.columns.map((obj) => result.push(obj.dataIndex));
        //  console.log(result.toString())
        var getData = {
          cid: this.state.companyId,
          UcompanyId: JSON.parse(base64_decode(Cookies.get("data"))).UcompanyId,
          searchField: this.state.searchField,
          colList: result,
        };
        console.log(getData);
        if (val.key == "excel") {
          this.waslDriverStore.exportExcel(getData);
        }
      },
    };
  }
  async componentDidMount() {
    // await this.waslDriverStore.loadData({ limit: 50, offset: 0 });
    await this.setState({ pageSize: 50 });
    await this.handleSearch();
    await this.setState({ loadData: 1 });
    this.columns = [
      {
        // title: "Account",
        title: this.context.translate("_FLEET_DASHBORD_NEWPOI_CUSTOMER_"),
        dataIndex: "companyName",
        key: "account",
      },
      {
        // title: "Driver",
        title: this.context.translate(
          "_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"
        ),
        dataIndex: "driverName",
        key: "driverName",
      },
      {
        // title: "Identity Number",
        title: this.context.translate("_IDENTITY_NUMBER_"),
        dataIndex: "identityNo",
        key: "identityNo",
      },
      {
        // title: "DOB",
        title: this.context.translate("_DOB_"),
        dataIndex: "dob",
        key: "dob",
      },
      {
        // title: "Mobile No",
        title: this.context.translate(
          "_FLEET_DASHBORD_VEHICLE_INFORMATION_MOBILE_"
        ),
        dataIndex: "mobileNo",
        key: "mobileNo",
      },
      {
        // title: "WASL Driver Key",
        title: this.context.translate("_WASL_DRIVER_KEY_"),
        dataIndex: "waslDriverKey",
        key: "waslDriverKey",
      },
      {
        // title: "Activity",
        title: this.context.translate("_ACTIVITY_"),
        dataIndex: "activity",
        key: "activity",
      },
      {
        // title: "Response",
        title: this.context.translate("_RESPONSE_"),
        dataIndex: "response",
        key: "response",
      },
      {
        // title: "Reply",
        title: this.context.translate("_REPLY_"),
        dataIndex: "reply",
        key: "reply",
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        fixed: "right",
        width: "5%",
        render: (_, record) => {
          // console.log(record);
          return (
            <>
              <Space>
                <Tooltip
                  // title="WASL Status"
                  title={this.context.translate("_WASL_STATUS_")}
                  color={config.tooltipColorEdit}
                >
                  <SearchOutlined
                    onClick={async () => {
                      await this.WASLDriverStatus(record);
                      await this.setState({ showAlertModal: true });
                    }}
                  />
                </Tooltip>
                <DeleteConfirm
                  onConfirm={(e) => {
                    this.onDelete(record);
                  }}
                >
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Tooltip
                      // title="Delete Record"
                      title={this.context.translate("_DELETE_RECORD_")}
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
   
  }

  // warning = () => {
  //   console.log("dsdfdgf");
  //   console.log(this.waslDriverStore?.WASLDriverStatus);
  //   Modal.warning({
  //     title: "Alert",
  //     content: this.waslDriverStore?.WASLDriverStatus,
  //   });
  // };

  WASLDriverStatus = async (record) => {
    await this.waslDriverStore.getWASLDriverStatus({ Id: record.Id });
  };

  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    if (comp) {
      await this.setState({ companyId: comp.value, loadList: 1 });

      await this.handleSearch();
      await this.setState({ loadData: 1 });
    } else {
      await this.setState({ companyId: null });
      await this.handleSearch();
     
      await this.setState({ loadData: 1 });
    }
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.waslDriverStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
      UcompanyId:
        this.props.getUserData().isadmin === 3
          ? this.props.getUserData().userCompanyId
          : 0,
      pageNo: this.state.currentPage,
    });
    await this.setState({ loadList: 1 });
    // this.setState({ loading: false });
  }

  onWASLRegistrationFinish = async (value) => {
    // console.log(value);
    const data = {
      ...value,
      userId: 1,
      companyId: this.state.companyId,
      dob: value?.dob?.format(dateFormat),
    };
    console.log(data);
    const response = await this.waslDriverStore.registerDriver(data);
    if (response?.success === "S") {
      message.success(response.data.message);
      console.log(response);
      await this.handleSearch();
    } else {
      message.error(response.data.message);
    }
    if (response.data.message) {
      this.setState({ showWASLRegistration: false });
      this.formRef.current?.resetFields();
    }
  };

  setFieldsValues = async (vehicleId) => {
    // console.log("vehicleId", vehicleId)
    const data = await this.waslDriverStore.getDriverInfo(vehicleId);
    console.log(data, "dta");

    await this.formRef.current.setFieldsValue({ dobFormat: data.dobFormat });
    await this.formRef.current.setFieldsValue({ mobileNumber: data.mobileno });
    await this.formRef.current.setFieldsValue({
      identityNumber: data.licensenumber,
    });

    await this.formRef.current.setFieldsValue({
      dob: data.dob ? dayjs(data.dob, "YYYY-MM-DD") : null,
    });
  };

  async onDelete(record) {
    console.log(record);
    const response = await this.waslDriverStore.deleteRecord({
      Id: record.Id,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  async companySearch(companyId) {
    console.log(companyId, "awerwerfsdzxcx");

    const data = await this.waslDriverStore.toGetDriverName(companyId.value);

    console.log(data, "sdfghfdsazx");
    const data1 = await this.waslDriverStore.getCompanyIdentityNo(
      companyId.value
    );
    console.log(data1, "sdfghfdsazx123");

    await this.setState({ loadList: 1 });
  }

  searchInput = async (e) => {
    const value = e.target.value;
    console.log(value);
    await this.setState({ searchField: value });
    if (value.length > 3) {
      await this.waslDriverStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        searchField: value,
        companyId: this.state.companyId,
      });
      await this.setState({ loadList: 1, companyLoading: false });
    } else if (value.length === 0) {
      await this.waslDriverStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        searchField: value,
        companyId: this.state.companyId,
      });
      await this.setState({ loadList: 1, companyLoading: false,currentPage:1});
    }
  };

  validatePhoneNumber = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1.length);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">WASL Driver List</div> */}
              <div className="heading">
                {this.context.translate("_WASL_DRIVER_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                <Link to={"/companydriverslist"}>
                  <Button type="primary">
                    {/* Back */}
                    {this.context.translate(
                      "_FLEET_USER_USERLIST_ASSIGNVEHICLE_BACKBTN_"
                    )}
                  </Button>
                </Link>
                <Button
                  style={{ width: "113px" }}
                  type="primary"
                  onClick={() => this.setState({ showWASLRegistration: true })}
                >
                  {/* + Register */}+ {this.context.translate("_REGISTER_")}
                </Button>

                <Dropdown
                  menu={this.ExportMenuProps}
                  disabled={
                    this.waslDriverStore?.WASLDriverArray.length > 0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownloadOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Space>
              <CompanyAutocomplete
                allowClear={true}
                onChange={(value) => this.handleChange(value)}
                style={{ width: 200 }}
                // companyId={this.state.companyId}
              />

              <Input
                style={{ width: 400 }}
                value={this.state.searchField ? this.state.searchField : ""}
                // placeholder="Search By Driver Name,Driver Identity Number"
                placeholder={this.context.translate(
                  "_Search_By_Driver_Name_Driver_Identity_Number_"
                )}
                onChange={async (e) => {
                  await this.setState({ searchField: e.target.value });
                  await this.searchInput(e);
                }}
              ></Input>

              <Button type="primary" onClick={async() =>{
                 await this.waslDriverStore.loadData({
                  offset: 0,
                  limit: this.state.pageSize,
                  searchField: this.state.searchField,
                  companyId: this.state.companyId,
                });
                // await this.onSearch()
                await this.setState({ loadData: 1 });
              } }>
                <SearchOutlined />
              </Button>
            </Space>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.waslDriverStore?.total}
            columns={this.columns}
            //   dataSource={this.dataSource}
            dataSource={this.waslDriverStore?.WASLDriverArray}
            loading={this.waslDriverStore?.loading}
            onChange={this.handleTableChange}
          />

          <Modal
            // title="WASL Registration"
            title={this.context.translate("_WASL_REGISTRATION_")}
            open={this.state.showWASLRegistration}
            onCancel={() => this.setState({ showWASLRegistration: false })}
            footer={null}
            width={665}
            style={{
              top: 20,
            }}
            // onOk={}
          >
            <Form
              ref={this.formRef}
              layout="vertical"
              name="companyFrm"
              onFinish={this.onWASLRegistrationFinish}
              // initialValues={{ mapSetting: false, mapSetting2: false }}
              style={{ maxWidth: "100%", padding: 0 }}
              className="formClass"
            >
              <Row
                style={{
                  overflowY: "scroll",
                  height: "548px",
                }}
              >
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="companyId"
                    label={this.context.translate("_ACCOUNT_")}
                    rules={[{ required: true }]}
                  >
                    {/* <CompanyAutocomplete
                allowClear={true}
                onChange={(value) => this.handleChange(value)}
                style={{ width: 200 }}
               
              /> */}

                    <CompanyAutocomplete
                      // allowClear={true}
                      onChange={(companyId) => {
                        console.log(companyId);
                        this.setState({ companyId: companyId.value });
                        this.companySearch(companyId);
                        this.formRef.current.setFieldsValue({
                          driverId: undefined,
                        });
                        this.waslDriverStore.toGetDriverName(companyId.value);
                        this.waslDriverStore.getCompanyIdentityNo(
                          companyId.value
                        );
                      }}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="cidentityNo"
                    label={this.context.translate("_COMPANY_IDENTITY_NUMBER_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      // placeholder="Select Company Identity No"
                      placeholder={this.context.translate(
                        "_SELECT_COMPANY_IDENTITY_NUMBER_"
                      )}
                      name="cidentityNo"
                      //onChange={(e) => this.setFieldsValues(e)}
                      // onSearch={this.handleSearch}
                      showSearch
                      fieldNames={{ value: "identityNo", label: "identityNo" }}
                      optionFilterProp="children"
                      options={this.waslDriverStore?.CompanyIdentityNumbers}
                    />
                  </Form.Item>

                  <Form.Item
                    name="driverId"
                    label={this.context.translate("_DRIVER_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      // placeholder="Select Driver"
                      placeholder={this.context.translate("_SELECT_DRIVER_")}
                      name="driverId"
                      onChange={(e) => this.setFieldsValues(e)}
                      // onSearch={this.handleSearch}
                      showSearch
                      fieldNames={{ value: "driverId", label: "name" }}
                      optionFilterProp="children"
                      options={this.waslDriverStore?.WASLDriverName}
                    />
                  </Form.Item>

                  <Form.Item
                    name="identityNumber"
                    // label="Identity Number"
                    label={this.context.translate("_IDENTITY_NUMBER_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="dobFormat"
                    // label="DOB Format"
                    label={this.context.translate("_DOB_FORMATE_")}
                    placeholder="Select Type"
                    //   initialValue={"Hijri"}
                    rules={[{ required: true }]}
                  >
                    {/* <Select>
                      <Select.Option value="Hijri">Hijri</Select.Option>
                      <Select.Option value="Gregorian">Gregorian</Select.Option>
                    </Select> */}
                    <Select>
                      <Select.Option value="Hijri">
                        {this.context.translate("_HIJRI_")}
                      </Select.Option>
                      <Select.Option value="Gregorian">
                        {this.context.translate("_GREGORIAN_")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col span={2}></Col> */}
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="dob"
                    // label="Date of Birth"
                    label={this.context.translate("_DOB_LABEL_")}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format={dateFormat}
                      rules={[{ required: true }]}
                    />
                  </Form.Item>

                  {/* <Form.Item
                    name="mobileNumber"
                    // label="Mobile No"
                    label={this.context.translate("_FLEET_DASHBORD_VEHICLE_INFORMATION_MOBILE_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item> */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      style={{ width: "30%" }}
                      name="countryCode"
                      // label="Contact 1"
                      label={" "}
                      // rules={[{ required: true }]}
                      rules={[{ required: true }]}
                      initialValue={+966}
                    >
                      <Select>
                        <Select.Option value={91}>+91</Select.Option>
                        <Select.Option value={966}>+966</Select.Option>

                        <Select.Option value={971}>+971</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      style={{ width: "65%" }}
                      name="mobileNumber"
                      // label="Phone Number 1"
                      label={this.context.translate("_MOBILE_NUMBER_")}
                      rules={[
                        {
                          required: true,
                          pattern: /^(\d{7}|\d{10})$/,
                          // message: "Enter a 7 or 10 digit number",
                          message: this.context.translate(
                            "_Enter_7_or_10_Digit_Number_"
                          ),
                        },
                      ]}
                      // rules={[{ required: true}]}
                    >
                      <Input
                        onChange={(e) =>
                          this.validatePhoneNumber(
                            e.target.value,
                            "mobileNumber"
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="registerType"
                    // label="Registration Type"
                    label={this.context.translate("_REGISTRATION_TYPE_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      // placeholder="Select Type"
                      placeholder={this.context.translate("_SELECT_TYPE_")}
                    >
                      {/* <Select.Option value="1">Company</Select.Option>
                      <Select.Option value="2">Individual</Select.Option> */}

                      <Select.Option value="1">
                        {this.context.translate(
                          "_FLEET_DASHBORD_NEWPOI_COMPANY_"
                        )}
                      </Select.Option>
                      <Select.Option value="2">
                        {this.context.translate("_INDIVIDUAL_")}
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="waslDriverActivity"
                    // label="Activity"
                    label={this.context.translate("_ACTIVITY_")}
                    initialValue={"DEFAULT"}
                    rules={[{ required: true }]}
                  >
                    <Select>
                      {/* <Select.Option value="DEFAULT">DEFAULT</Select.Option> */}
                      <Select.Option value="DEFAULT">
                        {this.context.translate("_DEFAULT_")}
                      </Select.Option>
                      {/* <Select.Option value="TOW_CAR">TOW CAR</Select.Option> */}
                      <Select.Option value="TOW_CAR">
                        {this.context.translate("_TOW_CAR_")}
                      </Select.Option>
                      <Select.Option value="SPECIALITY_TRANSPORT">
                        {/* SPECIALITY TRANSPORT */}
                        {this.context.translate("_SPECIALITY_TRANSPORT_")}
                      </Select.Option>
                      <Select.Option value="BUS_RENTAL">
                        {/* BUS RENTAL */}
                        {this.context.translate("_BUS_RENTAL_")}
                      </Select.Option>
                      <Select.Option value="EDUCATIONAL_TRANSPORT">
                        {/* EDUCATIONAL TRANSPORT */}
                        {this.context.translate("_EDUCATIONAL_TRANSPORT_")}
                      </Select.Option>
                      <Select.Option value="SFDA">
                        {this.context.translate("_SFDA_")}
                      </Select.Option>
                      <Select.Option value="INTERNATIONAL_TRANSPORT">
                        {/* {this.context.translate("_SFDA_")} */}
                        INTERNATIONAL TRANSPORT
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row justify="end"> */}
              <div className="formBtnGroup">
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100px",
                    height: "40px",
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    margin: "0px 10px",
                  }}
                  // onClick={(val) => console.log(val)}
                >
                  {/* REGISTER */}
                  {this.context.translate("_REGISTER_")}
                </Button>
              </div>
              {/* </Row> */}
            </Form>
          </Modal>
        </div>

        <Modal
          width={350}
          className="alert-model"
          title={
            <div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              {/* Alert */}
              {this.context.translate("_FLEET_ALERT_")}
            </div>
          }
          open={this.state.showAlertModal}
          onOk={() => this.setState({ showAlertModal: false })}
          onCancel={() => this.setState({ showAlertModal: false })}
          okText="OK"
          cancelText="Cancel"
          footer={[
            <Button
              type="primary"
              onClick={() => this.setState({ showAlertModal: false })}
            >
              {/* Ok */}
              {this.context.translate("_FLEET_DASHBORD_VEHICLE_COMMAND_OKBTN_")}
            </Button>,
          ]}
        >
          <p>{this.waslDriverStore?.WASLDriverStatus}</p>
        </Modal>
      </>
    );
  }
}
WASLDriverList.contextType = StoreContext;
export default observer(withRouter(WASLDriverList));
