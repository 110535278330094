import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi, baseURL } from "../components/Utility";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from "mobx";
import { act } from "react-dom/test-utils";

class RuleTriggerStore {
  ruleTriggerStore = [];
  rootStore;
  loading = false;
  total = 0;

  constructor(rootStore) {
    makeObservable(this, {
      ruleTriggerStore: observable,
      total: observable,
      loadData: action,
      loading: observable,
      getRuleSelectorNames:action
    });
    this.rootStore = rootStore;
  }

  async loadData({
    companyId,
    vehicleId,
    offset,
    limit,
    fromDate,
    toDate,
    pageNo,
    ruleSelectorId
  }) {
    this.loading = true;
    console.log("vehicle list");
    // var postData = { "companyId": companyId, "ruleSelectorId": ["7"], "vehicleId":vehicleId, "fromDate": fromDate, "toDate": toDate, "offset": offset, "limit":limit, "page": pageNo }
    var postData = {
      companyId: companyId,
      ruleSelectorId: ruleSelectorId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      offset: offset,
      limit: limit,
      page: pageNo,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=ruletrigger", "postData=" + base64Input);

    console.log(response?.data?.data?.ruletrigger);
    this.ruleTriggerStore = response?.data?.data?.ruletrigger;
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async getRuleSelectorNames() {
    console.log("getdata");
    var postData = { };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=rule&a=getruleselector", "postData=" + base64Input);
    console.log(response);
    // return response?.data?.data?.drivers[0];
    this.ruleList=response.data.data.reports
    // return response.data.data.reports

  }

  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    let distanceInt = 0;
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportruletriggerexcel&cid=" +
      getData.companyId +
      //   "&ruleId=" +
      //   getData.ruleId +
      "&ruleId=" +
      getData.ruleSelectorId+
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportruletriggerpdf&cid=" +
      getData.companyId +
      //   "&ruleId=" +
      //   getData.ruleId +
      "&ruleId=" +
      getData.ruleSelectorId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }

  async exportcsv(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportruletriggercsv&cid=" +
      getData.companyId +
      //   "&ruleId=" +
      //   getData.ruleId +
      "&ruleId=" +
      getData.ruleSelectorId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
  async exportxml(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportruletriggerxml&cid=" +
      getData.companyId +
      //   "&ruleId=" +
      //   getData.ruleId +
      "&ruleId=" +
      getData.ruleSelectorId +
      "&vid=" +
      getData.vehicleId +
      // "&int="+
      // getData.interval+
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList +
      "&language=" +
      lang;
  }
}

export default RuleTriggerStore;
