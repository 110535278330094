import React from "react";
import { Layout, Menu, Breadcrumb, Image, Space, Button, Popover } from "antd";
// import items from "./components/Menu";
import { Outlet } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import LounchingDashboard from "./components/LounchingDashboard";
import { useHistory } from "react-router-dom";
import Menu1 from "./components/Menu";
import logoIcon from "./assets/images/userPhoto.jpg";
// import logoIcon from './assets/images/userPhoto.jpg';
import { BiUser, BiHelpCircle } from "react-bootstrap-icons";
import withRouter from "./withRouter";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";

import { SettingOutlined, UserOutlined } from "@ant-design/icons";
import { StoreContext } from "./store/RootStore";
import { observer } from "mobx-react";
import Cookies from "js-cookie";

const { Content, Sider, Header, Footer } = Layout;

class App extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = { collapsed: false, marginLeft: 80, settingColor: "white" };
    console.log(props);
    let isArabic = Number(JSON.parse(JSON.stringify(Cookies.get())).isArabic);
    console.log(isArabic);
    isArabic ? context.setLocale("arabic") : context.setLocale("english");
  }
  handlePopState = () => {
    // For example, navigate to the login page
    this.props.navigate("/login");
  };
  async componentDidMount() {
    window.addEventListener("popstate", this.handlePopState);
  }

  // componentDidUpdate(){
  //   console.log(this.context.viewStateStore?.sliderChecker,"adf")
  // }
  render() {
    return (
      <>
        <div className="app-sammek">
          <Menu1 />
        </div>
      </>
    );
  }
}

// export default withRouter(App);
App.contextType = StoreContext;
export default observer(withRouter(App));
