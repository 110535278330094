import {
  Button,
  Card,
  DatePicker,
  Col,
  Row,
  Select,
  Spin,
  message,
  Form,
} from "antd";
import withRouter from "../withRouter";

import { Map, GoogleApiWrapper, HeatMap } from "google-maps-react";
import React from "react";
import Draggable from "react-draggable";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import { addMinutes, format } from "date-fns";
import moment from "moment";
import HeatMapStore from "../store/HeatMapStore";
import conf from "../config";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { StoreContext } from "../store/RootStore";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Cookies from "universal-cookie";

const currentDate = moment().format("YYYY-MM-DD");
const startTime = new Date(2000, 0, 1, 0, 0); // 12:00 AM
const endTime = new Date(2000, 0, 1, 23, 30); // 11:45 PM
const curreentDate = dayjs();

const timeArray = [];
for (let time = startTime; time <= endTime; time = addMinutes(time, 30)) {
  const formattedTime = format(time, "HH:mm:ss");
  var ob = {
    formattedTime: formattedTime,
    time: time,
  };
  timeArray.push(ob);
}

class HeatMapShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      traffic: false,
      flag: true,
      mapBtn: false,
      satelliteBtn: false,
      spinLoading: false,
      mapType: "google",
      mapReady: false,
      setOpacity: false,
      setRadius: false,
      setGradient: false,
      updateKey: "",
      toggleHeatmap: false,
      loadingSpin: false,
      vehicleId: "",
      mapBounds: null,
    };
    this.cookies = new Cookies();
    this.formRef = React.createRef();
    this.mapRef = React.createRef();
    this.heatMapStore = new HeatMapStore(this);
    this.isadmin = JSON.parse(base64_decode(this.cookies.get("data")));

    this.mapOptions = [
      {
        label: "Google",
        value: "google",
      },
      {
        label: "Openstreet",
        value: "openstreet",
      },
    ];
  }

  handleChange = async (comp) => {
    console.log(comp.value);
    this.heatMapStore.heatMapArray = [];
    await this.formRef.current.setFieldsValue({ vehicleId: undefined });
    await this.heatMapStore.getVehicle({ companyId: comp.value });
    await this.setState({ update: 1 });
  };

  heatMapShowFunc = async (obj) => {
    console.log(obj.toTime, this.state.vehicleId);
    this.heatMapStore.heatMapArray = [];

    var fromDate = dayjs(obj.fromDate).format("YYYY-MM-DD");
    var toDate = dayjs(obj.toDate).format("YYYY-MM-DD");
    var fromTime = obj.fromTime;
    var toTime = obj.toTime
      ? dayjs(obj.toTime, "hh:mm A").format("HH:mm:ss")
      : dayjs().format("HH:mm:ss");

    var data = {
      // companyId: obj.companyId?.value,:
      companyId: obj.companyId?.value
        ? obj.companyId?.value
        : this.state.companyId,
      vehicleId: obj.vehicleId, // Update vehicleId here
      fromDate: fromDate + " " + fromTime,
      toDate: toDate + " " + toTime,
    };
    await this.setState({ loadingSpin: true });
    const resp = await this.heatMapStore.getHeatMapData(data);
    if (resp === 0) {
      // message.warning({ content: "Vehicle Data Not Available..." });
      this.setState({ loadingSpin: false });
      message.warning({
        content: this.context.translate("_VEHICLE_DATA_NOT_AVAILABLE_"),
      });

      return;
    }
    await this.calculateBounds();
    await this.setState({
      update: 2,
      vehicleId: this.state.vehicleId,
      loadingSpin: false,
    }); // Update vehicleId in state
  };

  calculateBounds = () => {
    const positions = this.heatMapStore.heatMapArray;
    const bounds = new this.props.google.maps.LatLngBounds();

    positions.forEach((position) => {
      bounds.extend(position);
    });

    this.setState({ mapBounds: bounds });
  };

  async componentDidMount() {
    const data = JSON.parse(base64_decode(this.cookies.get("data")));
    console.log(JSON.parse(base64_decode(this.cookies.get("data"))));
    if (Number(data.isadmin) !== -1 && Number(data.isadmin) !== 3) {
      console.log(data.userCompanyId);
      await this.setState({ companyId: data.userCompanyId });

      // await this.setState({ userId: this.props.getUserData().userId })
      await this.heatMapStore.getVehicle({ companyId: data.userCompanyId });

      // await this.handleSearch();
    }
    this.setState({ update: 1 });
  }

  clearMap = () => {
    this.state.mapBounds = null;
    this.heatMapStore.heatMapArray = [];
    this.state.setGradient = null;
    this.setState({ update: 1 });
  };

  render() {
    const gradient = [
      "rgba(0, 255, 255, 0)",
      "rgba(0, 255, 255, 1)",
      "rgba(0, 191, 255, 1)",
      "rgba(0, 127, 255, 1)",
      "rgba(0, 63, 255, 1)",
      "rgba(0, 0, 255, 1)",
      "rgba(0, 0, 223, 1)",
      "rgba(0, 0, 191, 1)",
      "rgba(0, 0, 159, 1)",
      "rgba(0, 0, 127, 1)",
      "rgba(63, 0, 91, 1)",
      "rgba(127, 0, 63, 1)",
      "rgba(191, 0, 31, 1)",
      "rgba(255, 0, 0, 1)",
    ];

    return (
      <div style={{ overflow: "hidden" }}>
        <div>
          <Map
            ref={this.mapRef}
            google={this.props.google}
            bounds={this.state.mapBounds}
            style={{ width: "100%", height: "100%" }}
            containerStyle={{
              position: "static",
              // width: '95%',
              // left:"10%",
              // height: '100%'
            }}
            // mapTypeControlOptions= {
            //  { style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            //   position: google.maps.ControlPosition.TOP_CENTER,}
            // }
            mapTypeControlOptions={{
              style: this.props.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: this.props.google.maps.ControlPosition.RIGHT_TOP,
            }}
            fullscreenControl={false}
            // scaleControl= {true}
            zoomControl={true}
            className={"map"}
            // style={{ width: "100%", height: "100%", overflow: "hidden" }}

            initialCenter={{
              lat: 25.276987,
              lng: 55.296249,
            }}
            onReady={this.handleMapReady}
          >
            {this.heatMapStore.heatMapArray.length > 0 && (
              <HeatMap
                gradient={this.state.setGradient ? gradient : null}
                positions={
                  this.state.toggleHeatmap ? [] : this.heatMapStore.heatMapArray
                }
                opacity={this.state.setOpacity ? 0.5 : 1}
                radius={this.state.setRadius ? 20 : 10}
                key={this.state.updateKey}
              />
            )}
          </Map>
        </div>

        <Draggable handle=".handle">
          <Card
            className="right-menu-panel"
            style={{ left: "17%", top: "18%" }}
            title={
              <div
                className="handle"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {/* <div style={{ fontSize: "20px" }}>Heat Map</div> */}
                <div style={{ fontSize: "20px" }}>
                  {this.context.translate("_HEAT_MAP_")}
                </div>

                <Link to={"/livetracking"}>
                  <CloseOutlined style={{ fontSize: "20px" }} />
                </Link>
              </div>
            }
          >
            <Spin spinning={this.state.loadingSpin}>
              <Form
                ref={this.formRef}
                layout="vertical"
                name="nest-messages"
                onFinish={this.heatMapShowFunc}
              >
                {(Number(this.isadmin.isadmin) === -1 ||
                  Number(this.isadmin.isadmin) === 3) && (
                  <Form.Item
                    name="companyId"
                    // label={"Company"}
                    label={this.context.translate(
                      "_FLEET_DASHBORD_NEWPOI_COMPANY_"
                    )}
                    className="blur-input"
                    rules={[{ required: true }]}
                    style={{ marginBottom: "2px", marginTop: "2px" }}
                  >
                    <CompanyAutocomplete
                      onChange={(val) => this.handleChange(val)}
                      // value={this.state.companyId}
                    />
                  </Form.Item>
                )}

                <Form.Item
                  name="vehicleId"
                  className="blur-input"
                  // label="Vehicle"
                  label={this.context.translate("_VEHICLES_")}
                  rules={[{ required: true }]}
                  style={{ marginBottom: "6px", marginTop: "6px" }}
                >
                  <Select
                    // placeholder="Select Vehicle"
                    placeholder={this.context.translate("_SELECT_VEHICLE_")}
                    optionFilterProp="children"
                    onChange={(v) => {
                      this.setState({ vehicleId: v });
                    }}
                    showSearch
                    value={this.state.vehicleId}
                    filterOption={(inputValue, option) => {
                      if (option.children) {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        );
                      }
                    }}
                    // allowClear={true}
                    // onClear={this.handleClear}
                  >
                    {this.heatMapStore.vehicleArray?.map((v) => {
                      return (
                        <Select.Option value={v.vehicleId}>
                          {v.vehicleNo}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="fromDate"
                      className="blur-input"
                      // label="From Date"
                      label={this.context.translate("_FROM_DATE_")}
                      initialValue={dayjs()}
                      style={{
                        marginBottom: "6px",
                        marginTop: "6px",
                        marginInlineEnd: "10px",
                      }}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        format={"YYYY-MM-DD"}
                        //   defaultValue={this.state.startDate}

                        // onChange={(date) =>
                        //   this.formRef.current.setFieldsValue({ fromTime: date })
                        // }
                        // value={this.state.startDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="fromTime"
                      // label="From Time"
                      label={this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_RULE4_FROMTIME_"
                      )}
                      className="blur-input"
                      rules={[{ required: true }]}
                      style={{
                        marginBottom: "6px",
                        marginTop: "6px",
                        marginInlineStart: "10px",
                      }}
                      initialValue={"00:00:00"}
                    >
                      <Select
                        onChange={(val) =>
                          this.formRef.current.setFieldsValue({ fromTime: val })
                        }
                        allowClear={true}
                      >
                        {timeArray.map((data) => {
                          return (
                            <Select.Option
                              key={data.formattedTime}
                              value={data.formattedTime}
                            >
                              {format(data.time, "h:mm aa")}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="toDate"
                      className="blur-input"
                      // label="From Date"
                      // label="To Date"
                      label={this.context.translate("_TO_DATE_")}
                      initialValue={dayjs()}
                      // style={{ }}
                      style={{
                        marginBottom: "6px",
                        marginTop: "6px",
                        marginInlineEnd: "10px",
                      }}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        format={"YYYY-MM-DD"}
                        //   defaultValue={this.state.startDate}

                        // onChange={(date) =>
                        //   this.formRef.current.setFieldsValue({ toTime: date })
                        // }
                        // value={this.state.startDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="toTime"
                      // label="From Time"
                      // label={"To Time"}
                      label={this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_RULE4_TOTIME_"
                      )}
                      className="blur-input"
                      rules={[{ required: true }]}
                      style={{
                        marginBottom: "6px",
                        marginTop: "6px",
                        marginInlineStart: "10px",
                      }}
                      initialValue={dayjs().format("hh:mm A ")}
                    >
                      <Select
                        onChange={(val) => {
                          if (val) {
                            this.formRef.current.setFieldsValue({
                              toTime: dayjs(val, "HH:mm:ss").format("hh:mm A "),
                            });
                          } else {
                            this.formRef.current.setFieldsValue({
                              toTime: null,
                            });
                          }
                        }}
                        // value={{formattedTime:dayjs().format("HH:mm:ss"),time:""}}
                        allowClear={true}
                      >
                        {timeArray.map((data) => {
                          return (
                            <Select.Option
                              key={data.formattedTime}
                              value={data.formattedTime}
                            >
                              {format(data.time, "h:mm aa")}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <div className="formBtnGroup">
                  <Button className="formCancelButton" onClick={this.clearMap}>
                    {/* Clear */}
                    {this.context.translate("_FLEET_CLEAR_BTN_")}
                  </Button>
                  <Button
                    className="formSaveButton"
                    type="primary"
                    htmlType="submit"
                  >
                    {/* Show */}
                    {this.context.translate("_FLEET_SHOW_BTN_")}
                  </Button>
                </div>
              </Form>
            </Spin>
          </Card>
        </Draggable>

        <div className="button-container" style={{ textAlign: "center" }}>
          {/* Add buttons for heatmap controls */}
          <Button
            onClick={() =>
              this.setState({
                toggleHeatmap: !this.state.toggleHeatmap,
                updateKey: Date.now(),
              })
            }
          >
            {this.context.translate("_TOGGLE_HEATMAP_")}
          </Button>
          <Button
            onClick={() =>
              this.setState({
                setGradient: !this.state.setGradient,
                updateKey: Date.now(),
              })
            }
          >
            {this.context.translate("_CHANGE_GRADIENT_")}
          </Button>
          <Button
            onClick={() =>
              this.setState({
                setOpacity: !this.state.setOpacity,
                updateKey: Date.now(),
              })
            }
          >
            {this.context.translate("_CHANGE_OPACITY_")}
          </Button>
          <Button
            onClick={() =>
              this.setState({
                setRadius: !this.state.setRadius,
                updateKey: Date.now(),
              })
            }
          >
            {this.context.translate("_CHANGE_RADIUS_")}
          </Button>
        </div>
      </div>
    );
  }
}

HeatMapShow.contextType = StoreContext;
export default GoogleApiWrapper({
  apiKey: conf.googleMapKey,
  libraries: ["visualization"],
})(HeatMapShow);
observer(withRouter(HeatMapShow));
