import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class LiveRootPathStore {
  vehicleList = [];
  total = 0;
  loading = false;
  offlineData = undefined;
  loggingInProgress = true;
  pgcount = 1;
  offset = 0;
  tableDataArray = [];
  offlineLatLongArray = [];
  offlineShowingLatLong = [];
  historyPlottingIndex = 0;
  liveTrackInterval1 = 0;
  vehicleId = "";
  totalCount = null;
  postData = {};
  liveLatLongArray = [];
  polylineArrayObject = {};
  currentIndex = 0;
  vehiclePosition = [];
  checked = true;
  totalRecordCount = 0;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      total: observable,
      loading: observable,
      offlineData: observable,
      pgcount: observable,
      checked: observable,
      offset: observable,
      offlineLatLongArray: observable,
      offlineShowingLatLong: observable,
      totalCount: observable,
      postData: observable,
      loggingInProgress: observable,
      vehicleId: observable,
      liveLatLongArray: observable,
      polylineArrayObject: observable,
      currentIndex: observable,
      tableDataArray: observable,
      vehiclePosition: observable,
      totalRecordCount: observable,

      addData: action,
      getVehiclePosition: action,
      loadData: action,
      setOfflineData: action,
      handleOfflineData: action,
      loadVehiclePosition: action,
    });
    this.rootStore = rootStore;
  }

  async addData({ jTypeName }) {
    var postData = { jobType: { jTypeName: jTypeName } };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=delivery&a=addJobtype", "postData=" + base64Input);
    console.log("fuel detis ", response.data.data);
  }

  async loadData({ companyId }) {
    console.log(companyId);
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);
    this.vehicleList = response?.data?.data;
  }

  async setOfflineData(data) {
    this.vehicleId = data.vehicleId;
    this.postData = data;
    var postData = { ...data, page: this.pgcount, offset: this.offset };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=offlinedata", "postData=" + base64Input);

    let totalCount = response?.data?.data?.totalcount;
    this.offlineData = response?.data?.data;

    if (this.checked) {
      if (this.totalCount === totalCount) {
        this.loggingInProgress = false;
        this.getVehiclePosition();
        let interVal = setInterval(() => {
          this.getVehiclePosition();
        }, 30000);
      }

      if (this.totalCount === null) {
        console.log("calllllll count");
        this.totalRecordCount = response?.data?.data?.totalcount;
        this.totalCount = totalCount;
      }

      if (
        this.totalCount >= 10 ||
        (this.totalCount < 10 && this.totalCount >= 0)
      ) {
        await this.offlineData.vehicleposition.forEach((val) => {
          this.offlineLatLongArray.push(val);
        });

        await this.handleOfflineData();
      } else if (this.totalCount === 0) {
        console.log("end");
      }
    }
  }

  async handleOfflineData() {
    this.totalCount = this.totalCount - 10;
    this.pgcount = ++this.pgcount;
    this.offset = this.offset + 10;
    this.setOfflineData(this.postData);
  }

  async getVehiclePosition() {
    console.log("vehicleposition");
    var postData = {
      tokenId: "",
      vehicleId: this.vehicleId,
      userId: 1,
      isadmin: "-1",
      companyId: "1",
      refreshduration: 30,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=getvehicleposition",
        "postData=" + base64Input
      );
    const liveLatLong = response.data.data.vehicleposition[0];
    this.offlineLatLongArray.push([
      liveLatLong.latitude,
      liveLatLong.longitude,
    ]);
  }

  async loadVehiclePosition(vehicleId) {
    var postData = { tokenId: "", vehicleId: vehicleId, refreshduration: 30 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=getvehicleposition",
        "postData=" + base64Input
      );
    if (response?.data?.success) {
      this.vehiclePosition = response?.data?.data.vehicleposition;
    }

    // this.vehiclePosition = [[19,42],[20,44],[22,48],[23,49],[24,50]]
  }
}

export default LiveRootPathStore;
