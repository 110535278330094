import React from "react";
import { makeObservable, observable, computed, action, flow } from "mobx";
import CompanyStore from "./CompanyStore";
import Cookies from "js-cookie";

import VehicleStore from "./VehicleStore";

import DistributorStore from "./DistributorStore";

import VehicleAllocateStore from "./VehicleAllocateStore";

import UsersStore from "./UsersStore";
import DriversStore from "./DriversStore";
import PassengersStore from "./PassengerStore";
import StaffStore from "./StaffStore";
import BlackListPersonStore from "./BlackListPersonStore";
import PoliceOfficerStore from "./PoliceOfficerStore";
import { values } from "lodash";
import arEG from "antd/locale/ar_EG";
import enUS from "antd/locale/en_US";
import fleetLaguage from "../assets/js/lang/fleetLanguage";
import fleetLang_Arabic from "../assets/js/lang/fleetLang_Arabic";
import VehicleReturnAssetsStore from "./VehicleReturnAssetsStore";
import ViewStateStore from "./ViewStateStore";
import HistoryTrackingStore from "./HistoryTrackingStore";
import { decode as base64_decode, encode as base64_encode } from "base-64";

const locales = {
  english: {
    language: "english",
    locale: fleetLaguage,
    antLocale: enUS,
    direction: "ltr",
  },
  arabic: {
    language: "arabic",
    locale: fleetLang_Arabic,
    antLocale: arEG,
    direction: "rtl",
  },
};

export default class RootStore {
  companyStore;
  usersStore;
  driversStore;
  distributorStore;

  allocatevehiclestore;
  //vehicleStore
  vehiclereturnassetstore;
  // reportName

  // ruleSelector
  passengersStore;
  staffStore;
  blackListPersonsStore;
  policeOfficerStore;

  language = "english";
  rolePermission = [];
  menuShow = true;
  hideLounchDashboard = true;
  isAdmin = Number(JSON.parse(JSON.stringify(Cookies.get())).isAdmin);
  mapSetting = null;
  mapSetting2 = null;

  // beforeEditState=""

  locale = locales.english;

  constructor() {
    console.log(Number(JSON.parse(JSON.stringify(Cookies.get())).isAdmin));
    makeObservable(this, {
      language: observable,
      // reportName:observable,
      // ruleSelector:observable,
      // beforeEditState:observable,
      setLanguage: action,
      locale: observable,
      setLocale: action,
      translate: action,
      // setReportName:action
      // setRuleSelector:action,
      // setBeforeEditState:action
    });
    //this.companyStore = new CompanyStore(this);
    // this.vehicleStore = new VehicleStore(this);
    // this.allocatevehiclestore = new VehicleAllocateStore(this);
    this.vehiclereturnassetstore = new VehicleReturnAssetsStore(this);
    this.viewStateStore = new ViewStateStore(this);
    this.historyTrackingStore1 = new HistoryTrackingStore(this);
    //  this.distributorStore = new DistributorStore(this)
    //  this.usersStore = new UsersStore(this)
    // this.driversStore = new DriversStore(this)
    // this.passengersStore = new PassengersStore(this)
    // this.staffStore = new StaffStore(this)
    // this.blackListPersonsStore = new BlackListPersonStore(this)
    // this.policeOfficerStore = new PoliceOfficerStore(this)
  }

  // setBeforeEditState(value){
  //   console.log(value)
  //   this.beforeEditState= value
  // }
  setLanguage(value) {
    this.language = value;
  }

  setDirection(value) {
    this.direction = value;
  }

  setLocale(value) {
    this.locale = locales[value];
  }

  translate(key) {
    return this.locale.locale[key] || locales.english.locale[key] || key;
  }

  // setRuleSelector(value){
  //   console.log(value)
  //   // this.ruleSelector=value;
  // }

  // setReportName(value){
  //   console.log(value)
  //   this.reportName=value;
  // }
}
export const StoreContext = React.createContext();
export const StoreProvider = ({ children, rootStore }) => {
  return (
    <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
  );
};
