import React, { Component } from "react";

import {
  Form,
  Space,
  Button,
  Input,
  Radio,
  Modal,
  Checkbox,
  Upload,
  Row,
  Col,
  message,
  Spin
} from "antd";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import SimStore from "../store/SimStore";
import config from "../config";
class SimDetailsAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = { loading: false, simId: -1 ,Id:'',  loadingSpin: false,};
    this.simStore = new SimStore();
  }

  onSimDetailsSubmit = async (values) => {
    this.setState({ loading: true });
    console.log(values, "value");
    const SimDetails = { ...values, companyId: 1, simId: this.state.simId };
    const data = {
      tokenId: "",
      SimDetails: SimDetails,
      
    };
    const response = await this.simStore.addData(data);
    // if (response?.success === "S") {
    //   message.success(response.message);
    //   this.props.navigate("/simlist");
    // } else {
    //   message.error(response.message);
    // }
    await this.props.response(response);
    this.setState({ loading: false });
  };

  async componentDidMount() {
    console.log(this.props.params);
    const Id = this.props.simId;
    console.log(Id)

    if (Id) {
      this.filldata(Id);
      this.setState({ Id: Id });
    }
  }

  async filldata(Id) {
    await this.setState({ loadingSpin: true })
    const getSimDetails = await this.simStore.getSimData(Id);
    console.log("getdata",getSimDetails)

    await this.setState({
      selectedCompany: getSimDetails.companyId,
      simId: getSimDetails.simId,
    });

    this.formRef.current.setFieldsValue({
      simId:getSimDetails.simId,
      serialNo:getSimDetails.serialNo,
      serviceProvider: getSimDetails.serviceProvider,
      simNo: getSimDetails.simNo,
      simstatus: getSimDetails.simstatus,
    });
    await this.setState({ loadingSpin: false })


  }

  validateSimNumber = (value, field1) => {
    // Check if value contains only numbers
    const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1.length);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field1]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field1]: "" });
    }
  };

  closeModal=()=>{
    this.props.closeModal()
  }

  render() {
    return (
      <div style={{ padding: 0 }}>
      <div style={{ paddingBottom: "10px" }}>
      <Spin spinning={this.state.loadingSpin} fullscreen >
        <Form
          style={{ maxWidth: "100%", padding: 0 }}
          ref={this.formRef}
          layout="vertical"
          onFinish={this.onSimDetailsSubmit}
          {...this.layout}
          name="nest-message"
          className="formClass"
        >
          {/* <div className="formBorder">
            <div className="headingTitle">Add Sim Details</div> */}
            <Row className="overflowFormInputs">
              <Col span={12} className="formColumnClass">
                <Form.Item
                  name="simNo"
                  label={this.context.translate("_SIM_NO_")}
                  rules={[{ required: true }]}
                >
                  <Input 
                    onChange={(e) => {
                          this.validateSimNumber(e.target.value, "simNo");
                      }}
                  />
                </Form.Item>

                <Form.Item
                  name="serialNo"
                  label={this.context.translate("_SERIAL_NO_")}
                  rules={[{ required: true }]}
                >
                  <Input 
                     onChange={(e) => {
                          this.validateSimNumber(e.target.value, "serialNo");
                      }}
                  />
                </Form.Item>
                </Col>
                <Col span={12} className="formColumnClass">
                <Form.Item
                  name="serviceProvider"
                  label={this.context.translate("_SERVICE_PROVIDER_")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={13} />
              </Row>
              <div className="formBtnGroup">
                {/* <Link to={"/simlist"}>
                  {" "}
                  <Button ghost type="primary">
                    {" "}
                    Back
                  </Button>
                </Link> */}
                
                <Button
                  // ghost
                  // danger
                  // type="primary"
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal()
                  
                    // this.formRef.current?.resetFields();
                  }}

                >
                    {this.context.translate('_FLEET_CLOSE_BTN_')}
                  {/* Cancel */}
                  {/* {this.context.translate("_FLEET_DASHBORD_SHOWPOI_CANCELBTN_")} */}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="formSaveButton"
                  loading={this.state.loading}
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            
          {/* </div> */}
        </Form>
        </Spin>
      </div>
      </div>
    );
  }
}

SimDetailsAdd.contextType = StoreContext;
export default observer(withRouter(SimDetailsAdd));
