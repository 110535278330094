import React from "react";
import { Space, Row, Col, message, Tooltip, Button, Modal } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import {
  DeleteFilled,
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import withRouter from "../withRouter";
import InspectionStore from "../store/InspectionStore";
import AddButton from "./AddButton";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import CompanyStore from "../store/CompanyStore";
import InspectionAdd from "./InspectionAdd";
class InspectionList extends React.Component {
  constructor(props) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.inspectionStore = new InspectionStore();
    this.companyStore = new CompanyStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      companyVal_Lab: "",
      openInspectionForm: false,
      inspectionId: "",
      listDataUpdate: false,
    };
  }

  async componentDidMount() {
    // console.log(this.context.viewStateStore.value, "vale")
    // const setInspectionStates = this.context.viewStateStore.inspectionListObject
    // console.log(setInspectionStates)
    // await this.inspectionStore.loadData({
    //   offset: (this.state.currentPage - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,

    //   companyId: setInspectionStates.companyId,
    // });
    // const singleData = await this.companyStore.getCompanyDataById(setInspectionStates.companyId);

    // await this.setState({ loadList: 1 });
    // console.log("singleData", singleData)
    // await this.setState({ companyVal_Lab: singleData, companyId: setInspectionStates.companyId })
    // console.log("singleData", singleData)

    // await this.inspectionStore.loadData({ offset: 0, limit: 50 });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId)
      await this.setState({ companyId: this.props.getUserData().userCompanyId })
      await this.setState({ userId: this.props.getUserData().userId })
      await this.handleSearch();
    }
    this.setState({ pageSize: 50 });

    this.columns = [
      {
        // title: "Company",
        title:this.context.translate("_FLEET_DASHBORD_NEWPOI_COMPANY_"),
        dataIndex: "company",
        width: "15%",
        render: (value, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        // title: "Vehicle No",
        title:this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        width: "15%",
      },
      {
        // title: "Type",
        title:this.context.translate("_FLEET_DASHBORD_NEWPOI_TYPE_"),
        dataIndex: "types",
        width: "15%",
        render:(value)=>{
          console.log(value)
          if(value==="Array"){
            return 
          }else 
          return <>{value}</> 
        }
      },
      {
        // title: "Technician Name",
        title:this.context.translate("_TECHNICIAN_NAME_"),
        dataIndex: "techname",
        width: "15%",
      },
      {
        // title: "Comment",
        title:this.context.translate("_FLEET_CREATEROUTE_ROUTEADD_COMMENT_"),
        dataIndex: "comment",
        width: "15%",
      },
      {
        // title: "Date",
        title:this.context.translate("_FLEET_REPORTS_DATE_"),
        dataIndex: "date",
        width: "15%",
      },
      {
        // title: "Action",
        title:this.context.translate("_ACTION_"),
        dataIndex: "groupname",
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip 
                  // title="Edit Record" 
                  title={this.context.translate("_EDIT_RECORD_")}
                  placement="bottom" color={config.tooltipColorEdit}>
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      // title="Delete Record"
                      title={this.context.translate("_DELETE_RECORD_")}
                      color={config.tooltipColorDelete}
                      placement="bottom"
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  async componentDidUpdate(prevProps, prevState) {
    if(Number(this.props.getUserData().isadmin)===0){
      
        this.props.navigate("/login");
       
     }
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      console.log(this.context.viewStateStore.value, "vale");
      const setInspectionStates =
        this.context.viewStateStore.inspectionListObject;
      console.log(setInspectionStates);
      await this.inspectionStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,

        companyId: setInspectionStates.companyId,
      });
      const singleData = await this.companyStore.getCompanyDataById(
        setInspectionStates.companyId
      );

      await this.setState({ loadList: 1 });
      console.log("singleData", singleData);
      await this.setState({
        companyVal_Lab: singleData,
        companyId: setInspectionStates.companyId,
      });
      console.log("singleData", singleData);
      await this.setState({ listDataUpdate: false });
    }
  }
  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination, "pagi");
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }
  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      companyLoading: true,
      currentPage: 1,
      pageSize: 50,
    });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ companyVal_Lab: singleData });
    // await this.setState({ companyId: comp.value });
    await this.handleSearch();
  }

  async handleSearch() {
    await this.inspectionStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      // warehouseId:this.state.warehouseId,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  onEdit = async (record) => {
    console.log(record);
    const inspectionId = record.ins_Id;
    if (inspectionId) {
      this.context.viewStateStore.setInspectionStates({
        companyId: record.companyId,
      });
      // this.props.navigate("/inspectionadd/" + inspectionId);
    }
    await this.setState({ inspectionId: record.ins_Id });
    await this.setState({ openInspectionForm: true });
  };

  onDelete = async (record) => {
    console.log(record);
    const response = await this.inspectionStore.deleteRecord({
      ins_Id: record.ins_Id,
      companyId: record.companyId,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  };

  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.success == "S") {
      message.success(resp.message);
      await this.setState({ openInspectionForm: false });
      await this.setState({ listDataUpdate: true });

      //  await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    } else {
      message.error(resp.message);
    }
  };

  closeModal=()=>{
    this.setState({ openInspectionForm: false, inspectionId: "" });
  }

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_INSPECTION_LIST_")}
                {/* Inspection List */}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                {/* <AddButton
                  title={this.context.translate("_ADD_")}
                  link="/inspectionadd"
                /> */}
                <Tooltip
                  placement="left"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({
                        openInspectionForm: true,
                        inspectionId: "",
                      })
                    }
                  ></Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          {/* <div style={{padding:"24px"}}> */}
          {/* <div className='heading'>Inspection List</div> */}
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  // allowClear={true}
                  style={{ width: 200 }}
                  value={
                    this.state.companyVal_Lab ? this.state.companyVal_Lab : null
                  }
                />
              </Space>
            </Col>
            {/* <Col span={8}>
                        <Space style={{ float: this.context.locale.direction == "ltr" ? "right" : "left" }}>
                            <AddButton title={this.context.translate('_ADD_')} link="/inspectionadd" />

                        </Space>
                    </Col> */}
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.inspectionStore?.total}
            columns={this.columns}
            dataSource={this.inspectionStore?.inspection}
            loading={this.inspectionStore?.loading}
            onChange={this.handleTableChange}
          />

          {this.state.openInspectionForm && (
            <Modal
              bodyStyle={{ height: "645px" }}
              className="formShowModal" //  {/* css class */}
              // title="Add Inspection"
              title={this.context.translate("_ADD_INSPECTION_")}
              centered
              visible={this.state.openInspectionForm}
              footer={null}
              onCancel={() => {
                this.setState({ openInspectionForm: false, inspectionId: "" });
              }}
              width={665}
            >
              <div>
                <InspectionAdd
                  inspectionId={this.state.inspectionId}
                  response={this.responseMsg}
                  closeModal={this.closeModal}
                />
              </div>
            </Modal>
          )}
        </div>
      </>
    );
  }
}
InspectionList.contextType = StoreContext;
export default observer(withRouter(InspectionList));
