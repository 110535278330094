import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi, baseURL } from "../components/Utility";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from "mobx";
import { act } from "react-dom/test-utils";

class DriverLoginReportStore {
  total = 0;
  driverLoginStore = [];
  totalDriverArray=[]
  getDrivers = [];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      driverLoginStore: observable,
      total: observable,
      totalDriverArray:observable,
      loadData: action,
      getDriversByCompanyId: action,
      totalDriverReport:action,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    companyId,
    driverId,
    fromDate,
    toDate,
    offset,
    limit,
    pageNo,
  }) {
    console.log("vehicle list");
    var postData = {
      companyId: companyId,
      driverName: driverId,
      fromDate: fromDate,
      toDate: toDate,
      offset: offset,
      limit: limit,
      page: pageNo,
    };
    this.loading = true;

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=driver&a=driverLogin", "postData=" + base64Input);

    console.log(response?.data?.data?.driverLoginReport);
    this.driverLoginStore = response?.data?.data?.driverLoginReport;
    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;

  }

  async getDriversByCompanyId({ companyId }) {
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=driver&a=getDriverData", "postData=" + base64Input);
    console.log(response, "post");
    this.getDrivers = response?.data?.data;
    return response?.data?.data;
  }

  async totalDriverReport({
    companyId,
    driverId,
    fromDate,
    toDate,
    
  }) {
    this.loadingData=true
    console.log("vehicletotalPerformance")
    var postData = {
      companyId: companyId,
      driverName: driverId,
      fromDate: fromDate,
      toDate: toDate,
      
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=driver&a=driverTotal", "postData="+base64Input);   
    this.totalDriverArray = response?.data?.data
    // this.total = parseInt(response?.data?.data?.TotalCount);
    this.loadingData=false;
  }

  async exportExcel(getData) {
    console.log("getData", getData);
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=driver&a=exportDriverLoginExcel&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&did=" +
      getData.DriverId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exportpdf(getData) {
    let lang = "english";
    window.location.href =
      baseURL +
      "index.php?c=driver&a=exportDriverLoginPdf&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&did=" +
      getData.DriverId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exportcsv(getData) {
    window.location.href =
      baseURL +
      "index.php?c=driver&a=exportDriverLoginCsv&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&did=" +
      getData.DriverId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
  async exportxml(getData) {
    window.location.href =
      baseURL +
      "index.php?c=driver&a=exportDriverLoginXml&cid=" +
      getData.companyId +
      "&userId=" +
      getData.userId +
      "&did=" +
      getData.DriverId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&colList=" +
      getData.colList;
  }
}

export default DriverLoginReportStore;
