import React, { Component } from "react";
import {
  Button,
  Checkbox,
  message,
  Col,
  DatePicker,
  Radio,
  Form,
  Input,
  Row,
  Select,
  Upload,
  Slider,
  Space,
  InputNumber,
  Spin
} from "antd";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";
import DriverBehaviourSettingStore from "../store/DriverBehaviourSettingStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { Link } from "react-router-dom";
import config from "../config";
import CompanyStore from "../store/CompanyStore";
import "./Form.css";
import { responsiveArray } from "antd/es/_util/responsiveObserver";
const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 20 },
};

/* eslint-disable no-template-curly-in-string */
// const validateMessages = {
//   required: "${label} is required!",
//   types: {
//     email: "${label} is not a valid email!",
//     number: "${label} is not a valid number!",
//   },
//   number: {
//     range: "${label} must be between ${min} and ${max}",
//   },
// };
/* eslint-enable no-template-curly-in-string */

class DriverBehaviourSettingsAdd extends Component {
  formRef = React.createRef();
  constructor(prop) {
    super(prop);
    this.state = {
      responseMsg: "",
      editWindow: false,
      companyId: "",
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      loadList: 0,
      userCustomizedScoreShow: false,
      // absoluteValue:false,
      id: -1,
      absValue: 0,
      custScore: 0,
      // Slider Values:-
      harshAcce: 0,

      harshBrack: 0,
      exIdeling: 0,
      speeding: 0,
      rpmExcess: 0,
      total: 0,
      loadingSpin: false,
      seatBelt: 0,
    };
    this.driverBehaviorSettingStore = new DriverBehaviourSettingStore(this);
    this.companyStore = new CompanyStore(this);
  }

  async componentDidMount() {
    console.log("companyId", this.props.id);
    const id = this.props.driverBehaviourId;
    console.log(id);
    await this.setState({ loadingSpin: true })
    if (id) {
      //   await this.setState({ editWindow: true });
      const driverBehaviourSetting =
        await this.driverBehaviorSettingStore.getDriverBehaviourSetting(id);
      // const singleData =  await this.companyStore.getCompanyDataById(driverBehaviourSetting.companyId);
      console.log(driverBehaviourSetting, "eee");
      await this.setState({ id: driverBehaviourSetting.id });
      await this.formRef.current.setFieldsValue({
        // company: driverBehaviourSetting.company,

        minMileage: Number(driverBehaviourSetting.minMileage),
        id: Number(driverBehaviourSetting.id),
        harshBrack: Number(driverBehaviourSetting.harshBrack),
        harshAcce: Number(driverBehaviourSetting.harshAcce),
        exIdeling: Number(driverBehaviourSetting.exIdeling),
        custScore: Number(driverBehaviourSetting.custScore),
        companyId: driverBehaviourSetting.company,

        topScore: Number(driverBehaviourSetting.topScore),
        status: Number(driverBehaviourSetting.status),
        speeding: Number(driverBehaviourSetting.speeding),
        rpmExcess: Number(driverBehaviourSetting.rpmExcess),
        absValue: Number(driverBehaviourSetting.absValue),
        seatBelt: Number(driverBehaviourSetting.seatBelt),
      });
      // console.log(driverBehaviourSetting.userCustomizedScore);

      if (Number(driverBehaviourSetting.custScore) === 1) {
        this.setState({ userCustomizedScoreShow: true });
      } else {
        this.setState({ userCustomizedScoreShow: false });
      }
      console.log(driverBehaviourSetting.harshAcce);
      await this.setState({
        harshAcce: driverBehaviourSetting.harshAcce,

        harshBrack: driverBehaviourSetting.harshBrack,
        exIdeling: driverBehaviourSetting.exIdeling,
        speeding: driverBehaviourSetting.speeding,
        rpmExcess: driverBehaviourSetting.rpmExcess,
        seatBelt: driverBehaviourSetting.seatBelt,
      });

      let total =
        0 +
        parseInt(driverBehaviourSetting.harshAcce) +
        parseInt(driverBehaviourSetting.harshBrack) +
        parseInt(driverBehaviourSetting.exIdeling) +
        parseInt(driverBehaviourSetting.speeding) +
        parseInt(driverBehaviourSetting.rpmExcess)+
        parseInt(driverBehaviourSetting.seatBelt);
  
      await this.setState({ total: total });
      this.formRef.current.setFieldsValue({ total: `${total}%` });
    }
    await this.setState({ loadingSpin: false })
    //  console.log(total )
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value, loadList: 1 });
    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });
    await this.handleSearch();
  }

  async handleSearch() {
    await this.driverBehaviorSettingStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      // warehouseId:this.state.warehouseId,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 0 });
  }
  driverBehaviourSubmit = async (fieldsValue) => {
    console.log(fieldsValue)
    this.setState({ loading: true });

    if(Number(this.state.total)!==100 && fieldsValue.custScore===1){  
      // message.error("Kindly check Total %, Total should be 100%");
      message.error(this.context.translate("_TOTAL_SHOULD_BE_MORE_THAN_100_"));

    }else {
      console.log(fieldsValue);
      const data = {
        ...fieldsValue,
        id: this.state.id,
  
        companyId: this.state.companyId,
        harshAcce: this.state.harshAcce,
        harshBrack: this.state.harshBrack,
        exIdeling: this.state.exIdeling,
        speeding: this.state.speeding,
        rpmExcess: this.state.rpmExcess,
        seatBelt: this.state.seatBelt,
        total: this.state.total,
        custScore: this.state.userCustomizedScoreShow ? 1 : 0,
        //  absValue:
        absValue: this.state.absValue ? 1 :0,
      };
      console.log(this.state.userCustomizedScoreShow);
      console.log(this.state.absValue);
      console.log(data);
      const response = await this.driverBehaviorSettingStore.addData(data);
      await this.props.response({
        response: response,
        userCustomizedScoreShow: this.state.userCustomizedScoreShow,
        total: this.state.total,
      });
  
    }
  
    this.setState({ loading: false });
  };

  userCustomizedScore = async (e) => {
    console.log(e.target.value);
    const value = e.target.value;
    if (value === 1) {
      this.setState({ userCustomizedScoreShow: true });
    } else {
      this.setState({ userCustomizedScoreShow: false });
      await this.setState({
        harshAcce: 0,

        harshBrack: 0,
        exIdeling: 0,
        speeding: 0,
        rpmExcess: 0,
        seatBelt: 0,
      });
    }
  };
  //   totalChange = async () => {
  //     console.log(
  //       parseInt(this.state.harshBrack),
  //       parseInt(this.state.harshBrack)
  //     );
  //     let total =
  //      (parseInt(this.state.harshAcce) +
  //      parseInt(this.state.harshBrack) +
  //      parseInt(this.state.exIdeling) +
  //      parseInt(this.state.speeding) +
  //      parseInt(this.state.rpmExcess));
  //     await this.setState({ total: total });
  //     console.log(total, "t");
  //     await  this.formRef.current.setFieldsValue({ total: `${total}%` });
  //     // if (total <= 100) {
  //     // await  this.formRef.current.setFieldsValue({ total: `${total}%` });

  //     // }
  //   };

  totalChange = async () => {
    // const total =
    //   this.state.harshAcce +
    //   this.state.harshBrack +
    //   this.state.exIdeling +
    //   this.state.speeding +
    //   this.state.rpmExcess;
    // console.log(total);

    let getValues = this.formRef.current.getFieldsValue([
      "harshAcce",
      "harshBrack",
      "exIdeling",
      "speeding",
      "rpmExcess",
      "seatBelt",
    ]);
    console.log(getValues);
    let sum = Object.values(getValues).reduce(
      (acc, currentValue) => acc + currentValue,
      0
    );
    console.log(sum);

    await this.setState({ total: sum });
  };

  validateNumberAndDecimal = (value, field) => {
    const value1 = value.replace(/[^0-9.,]+/g, "");
    console.log(value1);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  handleAbsValueChange = (value) => {
    this.setState({ absValue: value });
  };

  closeModal=()=>{
  this.props.closeModal()
  }
  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
        <Spin spinning={this.state.loadingSpin} fullscreen >
          <Form
            ref={this.formRef}
            // {...layout}
            name="nest-messages"
            layout="vertical"
            onFinish={this.driverBehaviourSubmit}
            style={{ maxWidth: "100%" }}
            // validateMessages={validateMessages}
            className="formClass" //change
            initialValues={{
              harshAcce: 0,
              harshBrack: 0,
              exIdeling: 0,
              speeding: 0,
              rpmExcess: 0,
              seatBelt: 0,
              total: 0,
            }}
          >
            {/*         
              <div className="headingTitle">Driver Behaviour Settings</div> */}

            <Row className="overflowFormInputs">
              <Col span={12} className="formColumnClass">
                {/* Additional Field id */}

                {/* {this.state.} */}
                {(this.props.getUserData().isadmin ===-1 || this.props.getUserData().isadmin ===3) && <>
             
                {this.props.driverBehaviourId ? (
                  <Form.Item
                    name="companyId"
                    // label="Account"
                    label={this.context.translate("_FLEET_DASHBORD_NEWPOI_CUSTOMER_")}
                    rules={[{ required: true }]}
                    className="disabledLabel"
                  >
                    <CompanyAutocomplete
                      disabled
                      onChange={(value) => this.handleChange(value)}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="companyId"
                    // label="Account"
                    label={this.context.translate("_FLEET_DASHBORD_NEWPOI_CUSTOMER_")}
                    rules={[{ required: true }]}
                  >
                    <CompanyAutocomplete
                      onChange={(value) => this.handleChange(value)}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                )}
                   </>}
                <Form.Item
                  name="minMileage"
                  // label="Min. Mileage(Km)"
                  label={this.context.translate("_Min_Mileage_")}
                  rules={[{ type: Number }]}
                  initialValue={20}
                >
                  <Input
                    onChange={(e) =>
                      this.validateNumberAndDecimal(
                        e.target.value,
                        "minMileage"
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="topScore"
                  // label="Top Score"
                  label={this.context.translate("_TOP_SCORE_")}
                  rules={[{ type: Number }]}
                  initialValue={5}
                >
                  <Input
                    onChange={(e) =>
                      this.validateNumberAndDecimal(e.target.value, "topScore")
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="absValue"
                  // label="Display Absolute Value"
                  label={this.context.translate("_DISPLAY_ABSOLUTE_VAL_")}
                  className="label-seprator"
                >
                  <Radio.Group
                    defaultValue={0}
                  
                    onChange={(e) => this.handleAbsValueChange(e.target.value)}
                    
                  >
                    {/* <Radio value={1}>On</Radio>
                    <Radio value={0}>Off</Radio> */}
                    <Radio value={1}>{this.context.translate("_ON_")}</Radio>
                    <Radio value={0}>{this.context.translate("_OFF_")}</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="custScore"
                  // label="User Customized Score"
                  label={this.context.translate("_USER_COSTIMIZED_SCORE_")}
                  className="label-seprator"
                >
                  <Radio.Group
                    defaultValue={0}
                    onChange={(e) => {
                      this.userCustomizedScore(e);
                    }}
                  >
                    {/* <Radio value={1}>On</Radio>
                    <Radio value={0}>Off</Radio> */}
                    <Radio value={1}>{this.context.translate("_ON_")}</Radio>
                    <Radio value={0}>{this.context.translate("_OFF_")}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col span={12} className="formColumnClass">
                <div
                  style={{
                    display:
                      this.state.editWindow ||
                      this.state.userCustomizedScoreShow
                        ? ""
                        : "none",
                  }}
                >
                  {/* <Slider   onChange={async (value) => {
                        await this.setState({ harshAcce: value });
                        await this.totalChange();
                      }} value={this.state.harshAcce} />
      
                    <InputNumber
            min={1}
            max={20}
            style={{ marginLeft: 16 }}
            value={this.state.harshAcce}
            onChange={async (value) => {
              await this.setState({ harshAcce: value });
              await this.totalChange();
            }}
          /> */}
                  <Form.Item
                    name="harshAcce"
                    // label="Harsh Acceleration"
                    label={this.context.translate("_FLEET_RULESELECTORDETAIL_RULE2_TITLE_")}
                    className="label-seprator"
                    // style={{marginBottom:10}}
                  >
                    <div style={{padding:5}}></div>
                    <Slider
                      value={this.state.harshAcce}
                      onChange={async (value) => {
                        await this.setState({ harshAcce: value });
                        this.formRef.current.setFieldsValue({
                          harshAcce: value,
                        });
                        await this.totalChange();
                      }}
                    />
                    
                    {this.state.harshAcce}%
                  </Form.Item>
                </div>
                <div
                  style={{
                    display:
                      this.state.editWindow ||
                      this.state.userCustomizedScoreShow
                        ? ""
                        : "none",
                  }}
                >
                  <Form.Item
                    name="harshBrack"
                    // label="Harsh Braking"
                    label={this.context.translate("_FLEET_RULESELECTORDETAIL_RULE1_TITLE_")}
                    className="label-seprator"
                  >
                    <div style={{padding:5}}></div>
                    <Slider
                      value={this.state.harshBrack}
                      onChange={async (value) => {
                        await this.setState({ harshBrack: value });
                        this.formRef.current.setFieldsValue({
                          harshBrack: value,
                        });
                        await this.totalChange();
                      }}
                    />
                    {this.state.harshBrack}%
                  </Form.Item>
                  <Form.Item
                    name="exIdeling"
                    // label="Ex-Idling"
                    label={this.context.translate("_EX_IDELING_")}
                    className="label-seprator"
                  >
                    <div style={{padding:5}}></div>
                    <Slider
                      value={this.state.exIdeling}
                      onChange={async (value) => {
                        await this.setState({ exIdeling: value });
                        await this.formRef.current.setFieldsValue({
                          exIdeling: value,
                        });
                        await this.totalChange();
                      }}
                    />
                    {this.state.exIdeling}%
                  </Form.Item>
                  <Form.Item
                    name="speeding"
                    // label="Speeding"
                    label={this.context.translate("_SPEEDING_")}
                    className="label-seprator"
                  >
                    <div style={{padding:5}}></div>
                    <Slider
                      value={this.state.speeding}
                      onChange={async (value) => {
                        await this.setState({ speeding: value });
                        await this.formRef.current.setFieldsValue({
                          speeding: value,
                        });
                        await this.totalChange();
                      }}
                    />
                    {this.state.speeding}%
                  </Form.Item>
                  <Form.Item
                    name="rpmExcess"
                    // label="SeatBelt"
                    label={this.context.translate("_RPM_EXCESS_")}
                    className="label-seprator"
                  >
                    <div style={{padding:5}}></div>
                    <Slider
                      value={this.state.rpmExcess}
                      onChange={async (value) => {
                        await this.setState({ rpmExcess: value });
                        await this.formRef.current.setFieldsValue({
                          rpmExcess: value,
                        });
                        await this.totalChange();
                      }}
                    />
                    {this.state.rpmExcess}%
                  </Form.Item>

                  <Form.Item
                    name="seatBelt"
                    // label="SeatBelt"
                    label={this.context.translate("_FLEET_DASHBORD_SETTING_SEATBELT_")}
                    className="label-seprator"
                  >
                    <div style={{padding:5}}></div>
                    <Slider
                      value={this.state.seatBelt}
                      onChange={async (value) => {
                        await this.setState({ seatBelt: value });
                        await this.formRef.current.setFieldsValue({
                          seatBelt: value,
                        });
                        await this.totalChange();
                      }}
                    />
                    {this.state.seatBelt}%
                  </Form.Item>
                  <Form.Item
                    // name="total"
                    // label="Total"
                    label={this.context.translate("_TOTAL_")}
                    className="label-seprator"
                  >
                    <Input value={this.state.total} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <div className="formBtnGroup">
              {/* <Link to="/companydriverbehaviorsetting">
                    <Button ghost type="primary">
                      {" "}
                      Back
                    </Button>
                  </Link> */}
              <Button
                className="formCancelButton"
                onClick={(e) => {
                  this.closeModal()
                  // this.formRef.current?.resetFields();
                  this.setState({ userCustomizedScoreShow: false });
                }}
              >
                {/* Cancel */}
                {this.context.translate("_FLEET_CLOSE_BTN_")}
              </Button>
              <Button
                className="formSaveButton"
                type="primary"
                htmlType="submit"
                loading={this.state.loading}
              >
                {/* Save */}
                {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
              </Button>
            </div>
          </Form>
          </Spin>
        </div>
      </div>
    );
  }
}
DriverBehaviourSettingsAdd.contextType = StoreContext;
export default observer(withRouter(DriverBehaviourSettingsAdd));
