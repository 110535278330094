import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RootStore from './RootStore';
import { postApi } from '../components/Utility'
import {
  action,
  
  makeObservable,
  observable,
 
} from 'mobx';


class MDVRAlarmReportStore {
    mdvrlalarmReport=[]
  total=0
  loading=false
  rootStore

  constructor(rootStore) {
    makeObservable(this, {
        mdvrlalarmReport: observable,
      total:observable,
      loading:observable,
      loadData:action,
     
    });
     this.rootStore = rootStore;
    
  }

  async loadData({offset,limit,companyId,vehicleId,fromDate,toDate}) {
    console.log("mdvrl alarm Report")
    var postData = {"companyId":companyId,"vehicleId":vehicleId,"fromDate":fromDate,"toDate":toDate,"offset":offset,"limit":limit,"page":1}

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi.getClient().post("index.php?c=mdvr&a=mdvralarmreport", "postData="+base64Input);
    console.log("sim " ,response?.data?.data)
  //  data is not there 
    this.mdvrlalarmReport =response?.data?.data
  
     this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading=false;
  }


  

  
  

}

export default MDVRAlarmReportStore;
