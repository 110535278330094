import React, { Component } from "react";
import {
  Form,
  Select,
  DatePicker,
  Input,
  message,
  Table,
  Button,
  Space,
  Row,
  Col,
  Modal,
  Tooltip,
  Spin,
} from "antd";

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import VehicleMaintenanceStore from "../store/VehicleMaintenanceStore";
import withRouter from "../withRouter";

import config from "../config";
import CompanyAutocomplete from "./CompanyAutocomplete";

import DriversStore from "../store/DriversStore";
import CompanyStore from "../store/CompanyStore";
import VehicleWorkingHoursStore from "../store/VehicleWorkingHoursStore";
import CountryStore from "../store/CountryStore";

import dayjs from "dayjs";

import DriverAdd from "./DriverAdd";

const { Column } = Table;
class VehicleMaintenanceAdd extends Component {
  formRef = React.createRef();
  driverformRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      tableData: [],
      companyId: "",
      loadList: 0,
      vehiclesAssetArray: [],
      vehicleId: "",
      Id: "",
      serviceData: [],
      companyId: "",
      search: false,
      newserviceType: false,
      openDriverDetailsForm: false,
      driverDataArray: [],
      Id: -1,
      serviceId: -1,
      company_value_label: "",
      listUpdated: 0,
      formLoading: false,
      pageSize: 50,
      currentPage: 0,
    };
    this.vehicleMaintenanceStore = new VehicleMaintenanceStore();

    this.driverStore = new DriversStore();
    this.companyStore = new CompanyStore();
    this.workingHoursStore = new VehicleWorkingHoursStore();
    this.countryStore = new CountryStore();
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      this.context.viewStateStore.setVehicleMaintenanceStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ isadmin: this.props.getUserData().isadmin });
      const fillDriver = await this.driverStore.toGetDriverName(
        this.props.getUserData().userCompanyId
      );
      await this.setState({ formLoading: fillDriver ? false : true });
      const data = await this.workingHoursStore.getAssignAsset({
        companyId: this.props.getUserData().userCompanyId,
      });
    }

    await this.vehicleMaintenanceStore.getServiceData();

    await this.setState({ loadList: 1 });

    const Id = this.props.vehicleMaintenanceId;
    const companyId = this.props.companyId;
    console.log("id", Id, this.props.companyId);

    if (Id) {
      await this.filldata(Id);
      this.setState({ Id: Id });
    }

    // const driverDataArray = await this.companyStore.getServiceData()
    // console.log("serviceData", driverDataArray)
  }

  filldata = async (Id) => {
    await this.setState({ formLoading: true });
    const getVehicleMaitenance =
      await this.vehicleMaintenanceStore.getVehicleMaitenanceData({
        Id,
        companyId: this.props.companyId,
      });
    const gridDetails =
      await this.vehicleMaintenanceStore.getMaintenanceGridData(Id);
    console.log(gridDetails);

    const filteredGridDetails = gridDetails.map((detail) => ({
      gId: detail.gId,
      item: Number(detail.itemid),
      quantity: detail.quantity,
      grossPrice: detail.grossPrice,
      price: detail.price,
    }));

    await this.vehicleMaintenanceStore.getServiceData();

    await this.setState({
      companyId: getVehicleMaitenance.companyId,
      Id: getVehicleMaitenance.Id,
      serviceId: Number(getVehicleMaitenance.serviceId),
      tableData: filteredGridDetails,
    });
    const singleData = await this.companyStore.getCompanyDataById(
      getVehicleMaitenance.companyId
    );

    await this.workingHoursStore.getAssignAsset({
      companyId: this.state.companyId,
    });
    let driverResp = await this.driverStore.toGetDriverName(
      getVehicleMaitenance.companyId
    );

    // await this.setState({ driverDataArray: fillDriver });

    await this.formRef.current.setFieldsValue({
      Id: getVehicleMaitenance.Id,
      companyId: singleData,
      currentOdometer: getVehicleMaitenance.currentOdometer,
      distance: getVehicleMaitenance.distance,
      driverId: getVehicleMaitenance.driverId,
      billNo: getVehicleMaitenance.billNo,

      extraWorksDone: getVehicleMaitenance.extraWorksDone,

      garageAddress: getVehicleMaitenance.garageAddress,
      garageName: getVehicleMaitenance.garageName,
      // driverId: getVehicleMaitenance.name,
      serviceDate: dayjs(getVehicleMaitenance.serviceDate),
      serviceType: Number(getVehicleMaitenance.serviceId),
      // serviceName: getVehicleMaitenance.serviceName,
      totalAmmount: getVehicleMaitenance.totalAmmount,
      vehicleId: getVehicleMaitenance.vehicleId,
      vat: getVehicleMaitenance.vat,
      finalAmount: getVehicleMaitenance.finalAmount,
    });

    await this.setState({ formLoading: driverResp ? false : true });
  };

  responseMsg = async (response) => {
    const DriverName = await this.driverStore.toGetDriverName(
      this.state.companyId
    );

    if (response.success == "S") {
      message.success(response.message);
      await this.driverStore.toGetDriverName(this.state.companyId);
      await this.formRef.current.setFieldsValue({
        driverId: Number(response?.data.driverId),
        // driverId:response.data.name
      });

      await this.setState({ openDriverDetailsForm: false });
      // this.formRef.current?.resetFields();
    } else if (response.success === "F0") {
      response.errors.map((value) => {
        message.error(this.context.translate(value["username"]));
        // console.log(this.context.translate(value["driver"]),value["driver"])
      });
    }

    this.setState({ loadData: 1 });
    this.setState({ loading: false });

    const companyData = await this.companyStore.getCompanyDataById(
      this.state.companyId
    );

    await this.setState({ loadData: 1 });
  };

  onMaintenanceSubmit = async (values) => {
    this.setState({ loading: true });

    const MaintenanceDetails = {
      ...values,
      driverId: values.driverId?.label
        ? values.driverId.value
        : values.driverId,
      returnStatus: 0,
      Id: this.state.Id,
      companyId: this.state.companyId,
      serviceId: this.state.serviceId,
      serviceDate: values?.serviceDate.format("YYYY-MM-DD"),
    };

    const data = {
      tokenId: "80bovn8u800tg1aq9bvr73kbhh",
      MaintenanceDetails: MaintenanceDetails,
      GridDetails: this.state.tableData,
    };

    const resp = await this.vehicleMaintenanceStore.addData(data);
    await this.props.response(resp);
    // if (resp.data.success == "S") {
    //  message.success(resp.data.message);
    //   this.props.navigate("/vehiclemaintenancelist");
    // } else if (resp.data.success == "F0") {
    //   resp.data.errors.map((value) => {
    //     message.error(["This Service Type already exists"]);
    //   });
    // }
    this.setState({ loading: false });
  };

  handleInputChange = (e, index, fieldName) => {
    const newData = [...this.state.tableData];
    let subTotalAmmount = 0;

    if (fieldName === "grossPrice") {
      // Use the current values from newData for quantity and price
      const quantity = newData[index]["quantity"];
      const price = newData[index]["price"];

      newData[index]["grossPrice"] = parseFloat(quantity) * parseFloat(price);
    } else if (fieldName === "item") {
      newData[index][fieldName] = e;
    } else {
      newData[index][fieldName] = e.target.value;
    }

    this.setState({ tableData: newData });
    // console.log(this.state.tableData,parseFloat(quantity) * parseFloat(price))
    // this.formRef.current.setFieldsValue({subTotalAmmount:})

    newData.forEach((row) => {
      if (row.grossPrice) {
        subTotalAmmount += parseFloat(row.grossPrice);
      }
    });

    const fivePercentOfSubTotal = subTotalAmmount * 0.05;

    const totalAmount = subTotalAmmount + fivePercentOfSubTotal;

    this.formRef.current.setFieldsValue({
      totalAmmount: subTotalAmmount,
      vat: fivePercentOfSubTotal,
      finalAmount: totalAmount,
    });
  };

  handleAddRecord = () => {
    const { tableData } = this.state;
    this.setState({
      tableData: [
        ...tableData,
        { gId: "", item: "", quantity: "", grossPrice: "" },
      ],
    });
  };

  async handleChange(comp) {
    console.log(comp.value);
    await this.setState({ formLoading: true });
    await this.setState({ companyId: comp.value });
    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });
    if (!this.props.vehicleMaintenanceId) {
      this.context.viewStateStore.setVehicleMaintenanceStates({
        companyId: comp.value,
      });
    }
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ company_value_label: singleData });

    // const vehicleAsset = await this.vehicleStore.getVehicles(comp.value);
    // await this.setState({ vehiclesAssetArray: vehicleAsset });
    // console.log(comp.value);

    const fillDriver = await this.driverStore.toGetDriverName(comp.value);
    // console.log(fillDriver, "ee");
    // await this.setState({ driverDataArray: fillDriver });
    await this.setState({ formLoading: fillDriver ? false : true });
    const data = await this.workingHoursStore.getAssignAsset({
      companyId: comp.value,
    });

    await this.handleSearch();

    this.setState({ update: 1 });
  }

  async handleSearch() {
    await this.vehicleMaintenanceStore.loadData({
      offset: 0,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  selectServiceType = (e) => {
    const value = e;
    // console.log("value", value);
    if (value === 1) {
      this.setState({ newserviceType: true });
    } else {
      this.setState({ newserviceType: false });
    }
  };

  validateFieldsValue = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9.]*/g, "");

    if (value1) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  onDeleteRow = (index) => {
    const filterData = this.state.tableData.filter((val, ind) => ind !== index);

    this.setState({ tableData: filterData });
  };

  closeDriverModal = () => {
    this.setState({ openDriverDetailsForm: false, driverId: "" });
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.formLoading} fullscreen>
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              layout="vertical"
              ref={this.formRef}
              onFinish={this.onMaintenanceSubmit}
              // {...this.layout}
              className="formClass" //change
              name="nest-message"
              // initialValues={{ serviceDate: moment(currentDate) }}
            >
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => {
                          this.handleChange(value);
                          this.formRef.current.setFieldsValue({
                            vehicleId: undefined,
                            driverId: undefined,
                            currentOdometer: undefined,
                          });
                        }}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="vehicleId"
                    label={this.context.translate("_ASSET_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      // placeholder="Select Asset"
                      placeholder={this.context.translate(
                        "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                      )}
                      style={{ width: "100%" }}
                      onSelect={(val, currentOdometer) => {
                        console.log(
                          currentOdometer,
                          currentOdometer.currentOdometer,
                          currentOdometer.diffOdo
                        );
                        this.formRef.current.setFieldsValue({
                          currentOdometer:
                            currentOdometer.currentOdometer -
                            -currentOdometer.diffOdo,
                        });
                      }}
                      showSearch
                      fieldNames={{ value: "vehicleId", label: "vehicleNo" }}
                      optionFilterProp="children"
                      options={this.workingHoursStore?.assignAssetList}
                    />
                  </Form.Item>

                  <Row
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Form.Item
                      name="driverId"
                      // label="Driver"
                      label={this.context.translate(
                        "_FLEET_MANAGEVEHICLE_DRIVER_TAB_"
                      )}
                      style={{ width: "80%" }}
                    >
                      <Select
                        // placeholder="Select Driver"
                        placeholder={this.context.translate("_SELECT_DRIVER_")}
                        name="driverId"
                        value={this.state.driverId}
                        // onSelect={(e) => console.log(e)}
                        // onSearch={this.handleSearch}
                        showSearch
                        fieldNames={{ value: "driverId", label: "name" }}
                        optionFilterProp="children"
                        options={this.driverStore?.getDriverName}
                      />
                    </Form.Item>

                    <Form.Item name=" " label={" "}>
                      <Tooltip
                        // title="Add New Driver"
                        title={this.context.translate(
                          "_FLEET_VEHICLE_VEHICLEDETAIL_ADDDRIVER_"
                        )}
                        placement="bottom"
                        color={config.tooltipColorEdit}
                      >
                        <Button
                          style={{ height: "48px" }}
                          type="primary"
                          onClick={(e) => {
                            this.setState({ openDriverDetailsForm: true });
                          }}
                        >
                          <PlusOutlined />
                        </Button>
                      </Tooltip>
                    </Form.Item>
                  </Row>

                  <Form.Item
                    // initialValue={0}
                    name="currentOdometer"
                    label={this.context.translate("_CURRENT_ODOMETER_")}
                  >
                    <Input
                      onChange={(e) =>
                        this.validateFieldsValue(
                          e.target.value,
                          "currentOdometer"
                        )
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name="serviceDate"
                    label={this.context.translate("_SERVICE_DATE_")}
                    rules={[{ required: true }]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    name="billNo"
                    label={this.context.translate("_BILL_NO_")}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12} className="formColumnClass">
                  {this.state.newserviceType && (
                    <Form.Item
                      name="serviceName"
                      label={this.context.translate("_NEW_SERVICE_TYPE_")}
                    >
                      <Input />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="extraWorksDone"
                    label={this.context.translate("_EXTRA_WORK_DONE_")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="garageName"
                    label={this.context.translate("_GARAGE_NAME_")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="garageAddress"
                    label={this.context.translate("_GARAGE_ADDRESS_")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="totalAmmount"
                    label={this.context.translate("_SUBTOTAL_AMOUNT_")}
                  >
                    <Input disabled={true} />
                  </Form.Item>

                  <Form.Item
                    name="vat"
                    label={this.context.translate("_VAT5_")}
                  >
                    <Input disabled={true} />
                  </Form.Item>

                  <Form.Item
                    name="finalAmount"
                    label={this.context.translate("_TOTAL_AMOUNT_")}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Row>
                    <Button
                      type="primary"
                      onClick={this.handleAddRecord}
                      style={{ margin: "20px 0px 20px 0px" }}
                    >
                      {this.context.translate("_ADD_SERVICE_")}
                    </Button>
                  </Row>

                  <Table dataSource={this.state.tableData} bordered>
                    <Column
                      // title="Id"
                      title={this.context.translate("_Id_")}
                      dataIndex="gId"
                      key="gId"
                      render={(text, record, index) => (
                        <Input
                          value={text}
                          onChange={(e) =>
                            this.handleInputChange(e, index, "gId")
                          }
                        />
                      )}
                    />
                    <Column
                      // title="Item"
                      title={this.context.translate("_SERVICE_CATEGORY_")}
                      dataIndex="item"
                      key="item"
                      render={(text, record, index) => (
                        <Select
                          style={{ width: "150px" }}
                          value={text} // Set the value to the vehicleId from the record
                          onSelect={async (e, f) => {
                            await this.selectServiceType(e);
                            // console.log(e);

                            await this.handleInputChange(e, index, "item");
                          }}
                          fieldNames={{
                            value: "serviceId",
                            label: "serviceName",
                          }}
                          optionFilterProp="children"
                          options={this.vehicleMaintenanceStore?.serviceData}
                        />
                      )}
                    />
                    <Column
                      // title="Quantity"
                      title={this.context.translate("_QUANTITY_")}
                      dataIndex="quantity"
                      key="quantity"
                      render={(text, record, index) => (
                        <Input
                          value={text}
                          onChange={(e) => {
                            this.setState({ quantity: e.target.value });
                            this.handleInputChange(e, index, "quantity");
                          }}
                        />
                      )}
                    />
                    <Column
                      // title="Price"
                      title={this.context.translate("_PRICE_")}
                      dataIndex="price"
                      key="price"
                      render={(text, record, index) => (
                        <Input
                          value={text}
                          onChange={(e) => {
                            this.setState({ price: e.target.value });
                            this.handleInputChange(e, index, "price");
                          }}
                        />
                      )}
                    />
                    <Column
                      // title="Gross Price"
                      title={this.context.translate("_GROSS_PRICE_")}
                      dataIndex="grossPrice"
                      key="grossPrice"
                      render={(text, record, index) => (
                        <Input
                          value={text}
                          onFocus={(e) =>
                            this.handleInputChange(e, index, "grossPrice")
                          }
                        />
                      )}
                    />
                    <Column
                      // title="Operation"
                      title={this.context.translate("_OPERATION_")}
                      dataIndex=""
                      key=""
                      render={(text, record, index) => (
                        <div>
                          <Space>
                            <Link
                              onClick={() => {
                                this.onDeleteRow(index);
                                console.log(record, text);
                              }}
                            >
                              <Tooltip
                                // title="Delete"
                                title={this.context.translate(
                                  "_FLEET_DASHBORD_RULETRIGGER_DELETE_"
                                )}
                                color={config.tooltipColorDelete}
                              >
                                <DeleteFilled
                                  style={{ color: config.deleteColor }}
                                />
                              </Tooltip>
                            </Link>
                          </Space>
                        </div>
                      )}
                    />
                  </Table>
                </Col>
              </Row>

              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  type="primary"
                  onClick={(e) => {
                    this.closeModal();
                    // this.formRef.current?.resetFields();
                  }}
                >
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
        <Modal
          bodyStyle={{ height: "100%" }}
          className="formShowModal" //
          // title="Driver Details"
          title={this.context.translate("_FLEET_DRIVER_DRIVERDETAIL_TITLE_")}
          centered
          open={this.state.openDriverDetailsForm}
          footer={null}
          onCancel={() => {
            this.setState({ openDriverDetailsForm: false });
          }}
          width={665}
        >
          <DriverAdd
            response={this.responseMsg}
            closeModal={this.closeDriverModal}
          ></DriverAdd>
        </Modal>
      </div>
    );
  }
}

VehicleMaintenanceAdd.contextType = StoreContext;
export default observer(withRouter(VehicleMaintenanceAdd));
