import React from "react";
import {
  Input,
  Select,
  Checkbox,
  Row,
  Col,
  Button,
  Space,
  DatePicker,
  Dropdown,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";

import withRouter from "../withRouter";
import MapForVehiclePosition from "./MapForVehiclePosition";
import { DownOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import FleetSummaryReportStore from "../store/FleetSummaryReportStore";
import VehicleStore from "../store/VehicleStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import { decode as base64_decode } from "base-64";
import Cookies from "js-cookie";
import config from "../config";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
const { RangePicker } = DatePicker;
let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 250;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 150;
    } else if (val.title?.length > 8) {
      val.width = 125;
    } else {
      val.width = 110;
    }
    // console.log(val.title.length)
  });

  return arr;
};
const { Option } = Select;
class FleetSummaryReportList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.fleetReportSummaryStore = new FleetSummaryReportStore();
    this.vehicleStore = new VehicleStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      selectedVehicles: [],
      selectAllVehicles: false,
      companyId: "",
      modalVisible: false,
      column: "",
      filter: "",
      showMonthPopup: false,
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      Notcompany: false,
      Notasset: false,
      distance: 0,
      speed: "",
      vehicleObj: false,

      selectedColumns: widthAdderFunc([
        {
          // title: " Vehicle No ",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          // fixed: 'left',
          key: "vehicleNo",
        },
        // {
        //   // title: "Vehicle Type",
        //   title: context.translate("_VEHICLE_TYPE_"),
        //   dataIndex: "vehicleTypeNew",
        //   width: "15%",
        //   key: "vehicleTypeNew",
        // },
        // {
        //   // title: "Base Location",
        //   title: context.translate("_BASE_LOCATION_"),
        //   dataIndex: "baseLocation",
        //   width: "15%",
        //   key: "baseLocation",
        // },
        // {
        //   // title: "From Date",
        //   title: context.translate("_FROM_DATE_"),
        //   dataIndex: "fromDate",
        //   width: "15%",
        //   key: "fromDate",
        //   render: (value) => {
        //     return dayjs(this.state.fromDate).format("YYYY-MM-DD 12:00:00A");
        //   },
        // },
        {
          // title: "Trip Start Time",
          title: context.translate("_TRIP_START_TIME_"),
          dataIndex: "startTime",
          width: "15%",
          key: "startTime",
          render: (value) => {
            if (value) {
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            } else {
              return <></>;
            }
          },
        },
        {
          // title: "Start Address",
          title: context.translate(
            "_FLEET_REPORTS_STARTSTOPREPORT_STARTADDRESS_"
          ),
          dataIndex: "startLocation",
          width: "15%",
          key: "startLocation",
          // render: (value) => {
          //   if (value) {
          //     return value;
          //   } else {
          //     return "NA";
          //   }
          // },
          render: (value, obj) => {
            return (
              <Link
                onClick={async (e) => {
                  // await this.setState({ showMap: true, modalInfoObj: obj });
                  console.log(obj);
                  await this.setState({
                    vehicleObj: {
                      ...obj,
                      icon: "logo_icon.png",
                      latitude: obj.startLat,
                      longitude: obj.startLon,
                    },
                    heading: "Location On Map",
                  });
                  await this.setState({ loadData: 1 });
                }}
              >
                {value}
              </Link>
            );
          },
        },
        // {
        //   // title: "To Date"
        //   title: context.translate("_TO_DATE_"),
        //   dataIndex: "toDate",
        //   width: "15%",
        //   key: "toDate",
        //   render: (value) => {
        //     return this.state.toDate;
        //   },
        // },
        {
          // title: "Trip End Time",
          title: context.translate("_TRIP_END_TIME_"),
          dataIndex: "stopTime",
          width: "15%",
          key: "stopTime",
          render: (value) => {
            if (value) {
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            } else {
              return <></>;
            }
          },
        },
        {
          // title: "Stop Address",
          title: context.translate(
            "_FLEET_REPORTS_STARTSTOPREPORT_STOPADDRESS_"
          ),
          dataIndex: "stopLocation",
          width: "15%",
          key: "stopLocation",
          // render: (value) => {
          //   if (value) {
          //     return value;
          //   } else {
          //     return "NA";
          //   }
          // },
          render: (value, obj) => {
            return (
              <Link
                onClick={async (e) => {
                  // await this.setState({ showMap: true, modalInfoObj: obj });
                  console.log(obj);
                  await this.setState({
                    vehicleObj: {
                      ...obj,
                      icon: "logo_icon.png",
                      latitude: obj.stopLat,
                      longitude: obj.stopLon,
                    },
                    heading: this.context.translate("_LOCATION_ON_MAP_"),
                  });
                  await this.setState({ loadData: 1 });
                }}
              >
                {value}
              </Link>
            );
          },
        },
        {
          // title: "Start Odometer",
          title: context.translate("_FLEET_REPORTS_DISTANCEREPORT_STARTOD_"),
          dataIndex: "startDistance",
          width: "15%",
          key: "startDistance",
        },
        {
          // title: "Stop Odometer",
          title: context.translate("_FLEET_REPORTS_DISTANCEREPORT_STOPOD_"),
          dataIndex: "stopDistance",
          width: "15%",
          key: "stopDistance",
        },

        {
          // title: "Distance Travelled",
          title: context.translate("_FLEET_RULESELECTORDETAIL_RULE13_TITLE_"),
          dataIndex: "distanceTravel",
          width: "15%",
          key: "distanceTravel",
        },
        //         {
        //           title: " Start Time ",
        //           dataIndex: "startTime",
        //           key: "startTime",
        //           render: (value) => {
        //             if(value){
        //             const formattedDateTime = (dayjs(value).format(
        //               "DD-MM-YYYY hh:mm:ss A")
        //             );
        //             return <>{formattedDateTime}</>;
        //           }else{
        // return<></>
        //           }},
        //           // fixed: 'left',
        //         },
        // {
        //   title: " Start Location ",
        //   dataIndex: "startLocation",
        //   key: "startLocation",
        // },
        //         {
        //           title: " Stop Time ",
        //           dataIndex: "stopTime",
        //           key: "stopTime",
        //           render: (value) => {
        //             if(value){
        //             const formattedDateTime = (dayjs(value).format(
        //               "DD-MM-YYYY hh:mm:ss A")
        //             );
        //             return <>{formattedDateTime}</>;
        //           }else{
        // return<></>
        //           }},
        //         },
        // {
        //   title: " Stop Location ",
        //   dataIndex: "stopLocation",
        //   key: "stopLocation",
        // },
        // {
        //   title: " Start Odometer   ",
        //   dataIndex: "startDistance",
        //   key: "startDistance",
        // },
        // {
        //   title: "Stop Odometer",
        //   dataIndex: "stopDistance",
        //   key: "stopDistance",
        // },
        // {
        //   title: "Distance Travelled",
        //   dataIndex: "distanceTravel",
        //   key: "distanceTravel",
        // },
        // {
        //   // title: "Driver",
        //   title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
        //   dataIndex: "driverName",
        //   key: "driverName",
        // },
        // {
        //   // title: "Driver Mobile No",
        //   title: context.translate("_DRIVER_MOBILE_NO_"),
        //   dataIndex: "mobileno",
        //   key: "mobileno",
        // },
        {
          // title: "Max Speed",
          title: context.translate("_FLEET_REPORTS_FLEETREPORT_MAXSPEED_"),
          dataIndex: "maxSpeed",
          key: "maxSpeed",
        },
        {
          // title: "Travel Time",
          title: context.translate("_TRAVEL_TIME_"),
          dataIndex: "travelduration",
          key: "travelduration",
        },
        {
          // title: "Idle Time",
          title: context.translate("_IDLE_TIME_"),
          dataIndex: "idleduration",
          key: "idleduration",
        },
        {
          // title: " Exidle Time ",
          title: context.translate("_EXIDLE_TIME_"),
          dataIndex: "exidleduration",
          key: "exidleduration",
        },
        {
          // title: "Total Stop",
          title: context.translate("_TOTAL_STOP_"),
          dataIndex: "stopduration",
          key: "stopduration",
        },
        // {
        //   // title: "Ex Idle Count",
        //   title: context.translate("_EX_IDLE_COUNT_"),
        //   dataIndex: "idleCount",
        //   key: "idleCount",
        // },
        // {
        //   // title: "Over Speed",
        //   title: context.translate("_OVER_SPEED_"),
        //   dataIndex: "overspeedCount",
        //   key: "overspeedCount",

        //   // fixed: 'right',
        // },
        // {
        //   // title: "Seatbelt Violation",
        //   title: context.translate("_SEATBELT_VIOLATION_"),
        //   dataIndex: "seatbeltViolation",
        //   key: "seatbeltViolation",

        //   // fixed: 'right',
        // },
        // {
        //   title: context.translate("_FLEET_RULESELECTORDETAIL_RULE1_TITLE_"),
        //   dataIndex: "harshBraking",
        //   key: "harshBraking",

        //   // fixed: 'right',
        // },
        // {
        //   title: context.translate("_FLEET_RULESELECTORDETAIL_RULE2_TITLE_"),
        //   dataIndex: "harshAcc",
        //   key: "harshAcc",

        //   // fixed: 'right',
        // },
        // {
        //   title: context.translate("_HARSH_TURNING_"),
        //   dataIndex: "harshTurning",
        //   key: "harshTurning",

        //   // fixed: 'right',
        // },
        // {
        //   // title: "Start Odometer",
        //   title: context.translate("_FUEL_CONSUMED_"),
        //   dataIndex: "fuelConsumed",
        //   width: "15%",
        //   key: "fuelConsumed",
        // },
        // {
        //   // title: "Start Odometer",
        //   title: context.translate("_CURRENT_FUEL_LEVEL_"),
        //   dataIndex: "currentFuelLevel",
        //   width: "15%",
        //   key: "currentFuelLevel",
        // },
        // {
        //   // title: "Start Odometer",
        //   title: context.translate("_SCORE_"),
        //   dataIndex: "score",
        //   width: "15%",
        //   key: "score",
        // },
      ]),
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
        },
        // {
        //   // label: "CSV",
        //   label: context.translate("_CSV_"),
        //   key: "csv",
        // },
        // {
        //   // label: "XML",
        //   label: context.translate("_XML_"),
        //   key: "xml",
        // },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            interval: this.state.interval,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.fleetReportSummaryStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.fleetReportSummaryStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.fleetReportSummaryStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.fleetReportSummaryStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    // await this.fleetReportSummaryStore.loadData({ offset: 0, limit: 50 });

    this.setState({ pageSize: 50 });
    await this.setState({ column: this.state.selectedColumns });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({
      selectAllVehicles: false,
      selectedVehicles: [],
      // distance:'0'
    });

    // await this.handleAllSelectVehicles();
    await this.setState({ companyId: comp?.value });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    // await this.handleSearch();
    await this.setState({ loadData: 1 });

    // console.log("data", data);
  }

  async handleSearch() {
    this.setState({ Notasset: false });
    // await this.fleetReportSummaryStore.loadData({
    //   offset: (this.state.currentPage - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,
    // });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  vehicleSelect = async (val) => {
    console.log(val);
    await this.setState({ selectedVehicles: val });
  };

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      console.log(this.state.fromDate);
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.fleetReportSummaryStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          distance: this.state.distance,
          speed: this.state.speed,
        });
        await this.setState({ loadData: 1 });
      }
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };
  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }
  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss A";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0]?.format(format),
        toDate: dates[1]?.format(format),
      });
    }
  };

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Fleet Summary Report</div> */}
              <div className="heading">
                {this.context.translate("_FLEET_REPORTS_FLEETREPORT_TITLE_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.fleetReportSummaryStore?.fleetSummaryReport?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={22}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
                {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles: [],
                        selectAllVehicles: false,
                      })
                    }
                  />
                )}
                <Checkbox
                  checked={this.state.selectAllVehicles}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllVehicles: e.target.checked,
                    });
                    console.log(e.target.checked);
                    await this.handleAllSelectVehicles();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                {/* <Select
                  mode="multiple"
                  maxTagCount="responsive"
                  onSearch={this.handleSearch}
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val, Notasset: false });
                    if (this.vehicleStore?.getVehiclesUsingCompanyId.length > val.length) {
                      this.setState({ selectAllVehicles: false })
                    }
                    else {
                      this.setState({ selectAllVehicles: true })
                    }
                  }}
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_")}
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 150 }}
                  // style={assetInputStyle}
                  status={this.state.Notasset ? 'error' : false}

                /> */}

                <Select
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }

                    if (val?.length > 1) {
                      console.log(val?.length > 1);
                      this.setState({ showAlertPopup: true });
                    }
                  }}
                  style={{ width: 170 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  mode="multiple"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
                {/* <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 300 }}
                  onChange={(val) => {
                    console.log(val);
                    this.onDateTimeChange(val);
                  }}
                  // renderExtraFooter={() => "extra footer"}
                  showTime={{ format: "h:mm A" }}
                /> */}
                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                    console.log(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mm A",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />

                <Input
                  placeholder={this.context.translate(
                    "_FLEET_RULEDETAIL_SELECTPARAMETER_DISTANCE_"
                  )}
                  style={{ width: 110 }}
                  value={this.state.distance}
                  onChange={(e) => {
                    console.log(e.target.value);
                    this.setState({ distance: e.target.value });
                  }}
                />

                <Input
                  placeholder={this.context.translate(
                    "_FLEET_RULEDETAIL_SELECTPARAMETER_SPEED"
                  )}
                  style={{ width: 100 }}
                  value={this.state.speed}
                  onChange={(e) => this.setState({ speed: e.target.value })}
                />
                <Button
                  type="primary"
                  onClick={async () => {
                    await this.showData();
                    await this.setState({ currentPage: 1, pageSize: 50 });
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={2}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.fleetReportSummaryStore?.total}
            columns={this.state.column}
            dataSource={this.fleetReportSummaryStore?.fleetSummaryReport}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   //  current:this.state.pageNo
            //   total: this.fleetReportSummaryStore?.total || 0,
            // }}
            loading={this.fleetReportSummaryStore?.loading}
            onChange={this.handleTableChange}
            size="middle"
            scroll={{
              x: "calc(700px + 50%)",
              y: 280,
            }}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />

          <Modal
            width={350}
            className="alert-model"
            title={
              <div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-exclamation-triangle"
                ></i>{" "}
                {/* Alert */}
                {this.context.translate("_FLEET_ALERT_")}
              </div>
            }
            open={this.state.showMonthPopup}
            onOk={() => this.setState({ showMonthPopup: false })}
            onCancel={() => this.setState({ showMonthPopup: false })}
            footer={false}
          >
            {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
          </Modal>
          {this.state.vehicleObj && (
            <MapForVehiclePosition
              vehicleObj={this.state.vehicleObj}
              heading={this.state.heading}
              closeMapModal={(resp) => {
                this.setState({ vehicleObj: resp });
              }}
            />
          )}
        </div>
      </>
    );
  }
}
FleetSummaryReportList.contextType = StoreContext;
export default observer(withRouter(FleetSummaryReportList));
