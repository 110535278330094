import { Checkbox, Select } from "antd";
import React, { Component } from "react";
import Cookies from "js-cookie";
import { decode as base64_decode } from "base-64";
import withRouter from "../withRouter";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
const { Option } = Select;
class DeviceGroupsForVehicles extends Component {
  constructor(props) {
    super(props);
    this.state = { selectAllGroups: false, deviceGroupId: [] };
  }

  handleSelectAllDeviceGroups = async (e) => {
    if (this.state.selectAllGroups) {
      const allGroups = this.props.vehicleStore?.deviceGroups.map((val) => {
        return val.deviceGroupId;
      });
      console.log(allGroups);
      await this.setState({ deviceGroupId: allGroups });
    } else {
      await this.setState({ deviceGroupId: [] });
    }
  };

  async componentDidMount() {
    console.log(JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId);
    // if(Number(this.props.getUserData().isadmin)==1){
    const data = await this.props.vehicleStore.getDeviceGroups(
      JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId
    );

    this.setState({ loadData: 1 });
    // }
  }

  render() {
    return (
      <>
        {/* <div>GroupsForVehicles</div> */}
        <Checkbox
          checked={this.state.selectAllGroups}
          onChange={async (e) => {
            await this.setState({
              selectAllGroups: e.target.checked,
            });
            if (this.state.selectAllGroups) {
              await this.props.vehicleStore.getDeviceGroupsofVehicles({
                deviceGroupId: 0,
                companyId: JSON.parse(base64_decode(Cookies.get("data")))
                  .userCompanyId,
                userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
              });
              await this.props.setLoadDta();
            }
            
            await this.handleSelectAllDeviceGroups();
            await this.props.resetVehicles();
          }}
        />{" "}
       {this.context.translate('_ALL_')} &nbsp;
        <Select
          placeholder={this.context.translate('_GROUPS_')}
          style={{ width: 150 }}
          value={this.state.deviceGroupId}
          // fieldNames={{ label: "groupname", value: "deviceGroupId" }}
          mode="multiple"
          maxTagCount="responsive"
          // options={this.props.vehicleStore?.deviceGroups}
          onChange={async (val) => {
            // if (val?.length === 0) {
            //   console.log("asdfdafgsdfgs");
            //   {
            //     this.props.vehicleStore?.getVehiclesCompanyId(
            //       JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId
            //     );
            //       await this.props.setLoadDta();
            //   }
            // }
           
            // this.setState({selectedVehicles:this.props.selectedVehicles})
            if (this.props.vehicleStore?.deviceGroups.length > val.length) {
              this.setState({ selectAllGroups: false });
            } else {
              this.setState({ selectAllGroups: true });
            }

            await this.setState({ deviceGroupId: val });
            await this.props.vehicleStore.getDeviceGroupsofVehicles({
              deviceGroupId: this.state.deviceGroupId,
              companyId: JSON.parse(base64_decode(Cookies.get("data")))
                .userCompanyId,
              userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            });
            await this.props.resetVehicles();
            await this.props.setLoadDta();
            await this.setState({ loadData: 1 });
          }}
          filterOption={(inputValue, option) => {
            if (option.children) {
              return (
                option.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              );
            }
          }}
        >
          {this.props.vehicleStore?.deviceGroups?.map(
            (val) => {
              return (
                <Option value={val.deviceGroupId}>{val.groupname}</Option>
              );
            }
          )}
        </Select>
      </>
    );
  }
}
DeviceGroupsForVehicles.contextType = StoreContext;
export default observer(withRouter(DeviceGroupsForVehicles));
// export default DeviceGroupsForVehicles;
