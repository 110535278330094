import React from "react";
import {
  DatePicker,
  Select,
  Button,
  Space,
  Row,
  Col,
  message,
  Tooltip,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import {
  CarOutlined,
  DeleteFilled,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import config from "../config";

import withRouter from "../withRouter";
import DeleteConfirm from "./DeleteConfirm";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";

import JobRequestStore from "../store/JobRequestStore";

import moment from "moment";
import DepartmentStore from "../store/DepartmentStore";
import JobTypeStore from "../store/JobTypeStore";

import CompanyStore from "../store/CompanyStore";
import JobRequestAdd from "./JobRequestAdd";
import AssignVehicleToJobRequest from "./AssignVehicleToJobRequest";
const currentDate = moment().format("YYYY-MM-DD");
let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 230;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 130;
    } else if (val.title?.length > 8) {
      val.width = 110;
    } else {
      val.width = 95;
    }
    // console.log(val.title.length)
  });

  return arr;
};
class JobRequestList extends React.Component {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.jobRequestStore = new JobRequestStore(this);
    this.departmentStore = new DepartmentStore(this);
    this.jobTypeStore = new JobTypeStore(this);
    this.companyStore = new CompanyStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      loadList: 0,
      companyId: "",
      jobId: "",
      jobrequestDateFrom: "",
      jobrequestDateTo: "",
      jobTypeId: "",
      departmentId: "",
      companyVal_Lab: "",
      Id: "",
      openJobRequestForm: false,
      listDataUpdate: false,
      companyFieldHighlight: false,
      openAssignForm: false,
      assignId: "",
    };
  }
  onEdit = async (record) => {
    console.log(record.Id);
    const id = record.Id;
    if (id) {
      console.log(record.companyId);
      await this.context.viewStateStore.setJobRequestStates(record.companyId);
      // console.log(record.companyId)
      // this.props.navigate("/jobrequestadd/" + id);
      await this.setState({ Id: record.Id });
      await this.setState({ openJobRequestForm: true });
    }
  };

  onDelete = async (record) => {
    console.log(record);
    const response = await this.jobRequestStore.deleteRecord({
      companyId: record.companyId,
      Id: record.Id,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const setJobData = this.context.viewStateStore.jobRequestObject;
      console.log(setJobData);
      console.log(setJobData.companyId);
      if (setJobData.companyId !== "") {
        await this.jobRequestStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: setJobData.companyId,
        });

        const singleData = await this.companyStore.getCompanyDataById(
          setJobData.companyId
        );

        await this.setState({ loadList: 1 });

        await this.setState({
          companyVal_Lab: singleData,
          companyId: setJobData.companyId,
        });
      }
      await this.setState({ listDataUpdate: false });

      // this.setState({ pageSize: 50 });
      // await this.
      // await this.handleSearch()
    }
  }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleSearch();
    }

    // await this.inventoryStore.loadData({ offset: 0, limit: 50 });

    // console.log(this.context.viewStateStore.jobRequestObject, "vale");
    // console.log(this.context.viewStateStore.jobRequestObject.companyId)
    // const setJobData = this.context.viewStateStore.jobRequestObject;
    // console.log(setJobData);
    // if (setJobData.companyId !== "") {

    //   await this.jobRequestStore.loadData({
    //     offset: (this.state.currentPage - 1) * this.state.pageSize,
    //     limit: this.state.pageSize,
    //     companyId: setJobData.companyId,
    //   });

    //   const singleData = await this.companyStore.getCompanyDataById(
    //     setJobData.companyId
    //   );

    //   await this.setState({ loadList: 1 });
    //   console.log("singleData", singleData);
    //   await this.setState({
    //     companyVal_Lab: singleData,
    //     companyId: setJobData.companyId,
    //   });
    //   console.log("singleData", singleData);
    // }

    // await this.departmentStore.getDepartmentData();
    // await this.jobTypeStore.getJobTypeData();
    await this.departmentStore.getDepartmentData();
    await this.jobTypeStore.getJobTypeData();
    this.setState({ pageSize: 50 });
    // await this.
    // await this.handleSearch();
    this.columns = widthAdderFunc([
      {
        // title: "Department Name",
        title: this.context.translate("_DEPARTMENT_NAME_"),
        dataIndex: "departmentName",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
        // width: '15%'
      },
      {
        // title: "Job Type",
        title: this.context.translate("_JOB_TYPE_"),
        dataIndex: "jobName",
        // width: '15%',
      },
      {
        // title: "Contact Person",
        title: this.context.translate("_CONTACT_PERSON_"),
        dataIndex: "contactPerson",
        // width: '15%'
      },
      {
        // title: "Contact No",
        title: this.context.translate("_CONTACT_NO_"),
        dataIndex: "contactNo",
        // width: '15%'
      },
      {
        // title: "Address",
        title: this.context.translate("_FLEET_DASHBORD_NEWPOI_ADDRESS_"),
        dataIndex: "location",
        // width: '15%',
      },
      {
        // title: "Invoice Number",
        title: this.context.translate("_INVOICE_NUMBER_"),
        dataIndex: "invoiceNo",
        // width: '15%'
      },
      {
        // title: "Invoice Amount",
        title: this.context.translate("_INVOICE_AMOUNT_"),
        dataIndex: "invoiceAmt",
        // width: '15%'
      },
      {
        // title: "Request Date",
        title: this.context.translate("_REQUEST_DATE_"),
        dataIndex: "requestDate",
        // width: '15%'
      },
      {
        // title: "Request Time",
        title: this.context.translate("_REQUEST_TIME_"),
        dataIndex: "requestTime",
        // width: '15%'
      },
      {
        // title: "Vehicle No",
        title: this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        // width: '15%'
      },
      {
        // title: "Driver Name",
        title: this.context.translate(
          "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
        ),
        dataIndex: "name",
        // width: '15%'
      },
      {
        // title: "Allocate At",
        title: this.context.translate("_ALLOCATE_AT_"),
        dataIndex: "allocateAt",
        // width: '15%'
      },
      {
        // title: "Status ",
        title: this.context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "status",
        // width: '15%'
        render: (val) => {
          if (val == 1) {
            return "Pending";
          } else if (val == 3) {
            return "Processed";
          }
        },
      },
      {
        // title: "Finished At ",
        title: this.context.translate("_FINISHED_AT_"),
        dataIndex: "finishedAt",
        // width: '15%'
      },
      {
        // title: "Action",
        title: this.context.translate("_ACTION_"),
        dataIndex: "groupname",
        render: (_, record) => {
          // console.log(record.Id)
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip 
                  // title="Edit" 
                  title={this.context.translate("_FLEET_COMPANY_COMPANYLIST_EDIT_")}
                  placement="bottom"  color={config.tooltipColorEdit}>
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      // title="Delete"
                      title={this.context.translate(
                        "_FLEET_COMPANY_COMPANYLIST_DELETE_"
                      )}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>

                <Link
                  // to={`/assignvehicletojobrequest/${this.state.companyId}/${record.Id}`}
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   this.assign(this.state.companyId,record.Id);
                  // }}
                  onClick={() =>
                    this.setState({ openAssignForm: true, assignId: record.Id })
                  }
                >
                  <Tooltip
                    // title="Assign"
                    title={this.context.translate("_ASSIGN_")}
                    placement="bottom"
                    color="green"
                  >
                    <CarOutlined />
                  </Tooltip>
                </Link>
              </Space>
            </>
          );
        },
        // width: '20%',
      },
    ]);
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination, "pagi");
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }
  async handleChange(comp) {
    await this.setState({ companyId: comp.value, departmentId: "", jobId: "" });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ companyVal_Lab: singleData });
    // await this.setState({ companyId: comp.value });

    // await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.jobRequestStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      jobTypeId: this.state.jobId,
      departmentId: this.state.departmentId,
      jobrequestDateFrom: this.state.jobrequestDateFrom,
      jobrequestDateTo: this.state.jobrequestDateTo,
      proceesed: this.state.proceesed,
      finish: this.state.finish,
      pending: this.state.pending,
    });
    // console.log(this.state.proceesed);
    await this.setState({ loadList: 1 });
  }

  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.success == "S") {
      message.success(resp.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openJobRequestForm: false });

      // await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    } else {
      message.error(resp.message);
    }
  };

  assignresponseMsg = async (resp) => {
    console.log(resp);
    if (resp.data.success == "S") {
      message.success(resp.data.message);
      await this.setState({ Id: [] });
      await this.setState({ listDataUpdate: true });
      await this.setState({ openAssignForm: false });

      await this.handleSearch();
    } else {
      message.error(resp.data.message);
    }
  };

  async searchClick() {
    if (!this.state.companyId) {
      console.log(
        this.state.companyId,
        "sfsdgdfgdfhfg",
        this.state.companyFieldHighlight
      );
      this.setState({ companyFieldHighlight: true });
    } else {
      this.setState({ companyFieldHighlight: false });
    }

    if (this.state.companyId) {
      this.setState({ companyFieldHighlight: false, loading: true });
      // this.setState({  });

      await this.jobRequestStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
        jobTypeId: this.state.jobId,
        departmentId: this.state.departmentId,
        jobrequestDateFrom: this.state.jobrequestDateFrom,
        jobrequestDateTo: this.state.jobrequestDateTo,
        proceesed: this.state.proceesed,
        finish: this.state.finish,
        pending: this.state.pending,
      });
      // console.log(this.state.proceesed);
      await this.setState({ loadList: 1 });
    }
  }
  closeModal = () => {
    this.setState({ openJobRequestForm: false, Id: "" });
  };
  render() {
    const companyInputBox = this.state.companyFieldHighlight
      ? { border: "2px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Job Request List </div> */}
              <div className="heading">
                {this.context.translate("_JOB_REQUEST_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  link="/jobrequestadd"
                  title={this.context.translate("_ADD_")}
                /> */}
                <Tooltip
                  placement="left"
                  //  title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({ openJobRequestForm: true, Id: "" })
                    }
                  ></Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={18}>
              <Space>
                <CompanyAutocomplete
                  value={
                    this.state.companyVal_Lab ? this.state.companyVal_Lab : null
                  }
                  onChange={(value) => this.handleChange(value)}
                  // allowClear={true}
                  // style={{ width: 150, companyInputBox }}
                  style={companyInputBox}
                />

                <Select
                  value={
                    this.state.departmentId ? this.state.departmentId : null
                  }
                  // allowClear={true}
                  // placeholder="Select Department"
                  placeholder={this.context.translate("_SELECT_DEPARTMENT_")}
                  // name="department"

                  onSelect={async (val) => {
                    await this.setState({ departmentId: val });
                    // await this.handleSearch();
                  }}
                  style={{ width: 170 }}
                  onSearch={async () => await this.handleSearch()}
                  showSearch
                  fieldNames={{
                    value: "departmentId",
                    label: "departmentName",
                  }}
                  optionFilterProp="children"
                  options={this.departmentStore?.departments}
                />
                <Select
                  value={this.state.jobId ? this.state.jobId : null}
                  // allowClear={true}
                  // placeholder="Select Job Type"
                  placeholder={this.context.translate("_SELECT_JOB_TYPE")}
                  // name="jobTypeId"

                  style={{ width: 150 }}
                  onSelect={async (val) => {
                    await this.setState({ jobId: val });
                    // await this.handleSearch();
                  }}
                  onSearch={async () => await this.handleSearch()}
                  showSearch
                  fieldNames={{ value: "jobId", label: "jobName" }}
                  optionFilterProp="children"
                  options={this.jobTypeStore?.jobTypeList}
                />
                <DatePicker
                  placeholder={this.context.translate("_FROM_DATE_")}
                  style={{ width: 130 }}
                  name="jobrequestDateFrom"
                  onChange={(date) => {
                    if (date === null) {
                      this.setState({ jobrequestDateFrom: "" });
                    } else {
                      const format = "YYYY-MM-DD";
                      this.setState({
                        jobrequestDateFrom: date.format(format),
                      });
                      // console.log(date.format("YYYY-MM-DD"));
                    }
                  }}
                />

                <DatePicker
                  placeholder={this.context.translate("_TO_DATE_")}
                  style={{ width: 130 }}
                  name="jobrequestDateTo"
                  onChange={(date) => {
                    console.log(date);
                    if (date === null) {
                      this.setState({ jobrequestDateTo: "" });
                    } else {
                      const format = "YYYY-MM-DD";
                      this.setState({ jobrequestDateTo: date.format(format) });
                      //   console.log(date.format("YYYY-MM-DD"));
                    }
                  }}
                />
                <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  onClick={async () => {
                    await this.setState({
                      finish: null,
                      proceesed: null,
                      pending: null,
                    });
                    await this.searchClick();
                  }}
                >
                  {/* <SearchOutlined /> */}
                </Button>
              </Space>
            </Col>
            <Col span={6}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Button
                  onClick={async (e) => {
                    await this.setState({
                      finish: null,
                      proceesed: null,
                      pending: null,
                    });
                    await this.handleSearch();
                  }}
                  style={{ color: "black", backgroundColor: "#03A9F4" }}
                >
                  {" "}
                  {/* Total */}
                  {this.context.translate("_TOTAL_")}
                </Button>
                {this.jobRequestStore?.jobState?.TotalJob}
                <Button
                  onClick={async (e) => {
                    await this.setState({
                      pending: 1,
                      proceesed: null,
                      finish: null,
                    });
                    await this.handleSearch();
                  }}
                  value={"pending"}
                  style={{ color: "black", backgroundColor: "#FFEE58" }}
                >
                  {/* Pending */}
                  {this.context.translate("_PENDING_")}
                </Button>
                {this.jobRequestStore?.jobState?.TotalPendingJob}
                <Button
                  onClick={async () => {
                    await this.setState({
                      proceesed: 3,
                      pending: null,
                      finish: null,
                    });
                    await this.handleSearch();
                  }}
                  style={{ color: "black", backgroundColor: "#66BB6A" }}
                >
                  {/* Processed */}
                  {this.context.translate("_PROCESSED_")}
                </Button>{" "}
                {this.jobRequestStore?.jobState?.TotalProcessedJob}
                <Button
                  onClick={async (e) => {
                    await this.setState({
                      finish: 4,
                      proceesed: null,
                      pending: null,
                    });
                    await this.handleSearch();
                  }}
                  style={{ color: "black", backgroundColor: "#EF5350" }}
                >
                  {" "}
                  {/* Finished */}
                  {this.context.translate("_FINISHED_")}
                </Button>{" "}
                {this.jobRequestStore?.jobState?.TotalFinishedJob}
              </Space>
            </Col>
          </Row>

          <ListTable
            // rowSelection={{
            //   // selectedRowKeys:this.state.Id,
            //   onChange: async (selectedRowKeys, selectedRows) => {
            //     console.log(selectedRowKeys, selectedRows);
            //     // await this.setState({Id:selectedRowKeys})
            //     // await this.setState({ selectedVehicle: selectedRowKeys });

            //     // console.log(selectedRowKeys);
            //   },
            // }}
            // rowKey="Id"
            current={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.jobRequestStore?.total}
            columns={this.columns}
            dataSource={this.jobRequestStore?.jobRequest}
            loading={this.jobRequestStore?.loading}
            onChange={this.handleTableChange}
          />

          {this.state.openJobRequestForm && (
            <Modal
              bodyStyle={{ height: "645px" }}
              className="formShowModal" //  {/* css class */}
              // title="Add Job Request Details"
              title={this.context.translate("_ADD_JOB_REQUEST_DETAILS_")}
              centered
              visible={this.state.openJobRequestForm}
              footer={null}
              onCancel={() => {
                this.setState({ openJobRequestForm: false, Id: "" });
              }}
              width={665}
            >
              <div>
                <JobRequestAdd
                  Id={this.state.Id}
                  response={this.responseMsg}
                  closeModal={this.closeModal}
                />
              </div>
            </Modal>
          )}

          {this.state.openAssignForm && (
            <Modal
              bodyStyle={{ height: "645px" }}
              className="formShowModal" //  {/* css class */}
              // title="Assign Vehicle To Job Request"
              title={this.context.translate("_ASSIGN_VEHICLE_TOJOB_REQ_")}
              centered
              visible={this.state.openAssignForm}
              footer={null}
              onCancel={() => {
                this.setState({ openAssignForm: false });
              }}
              width={665}
            >
              <div>
                <AssignVehicleToJobRequest
                  companyId={this.state.companyId}
                  ID={this.state.assignId}
                  response={this.assignresponseMsg}
                />
              </div>
            </Modal>
          )}
        </div>
      </>
    );
  }
}
JobRequestList.contextType = StoreContext;
export default observer(withRouter(JobRequestList));
