import React, { Component } from 'react'
import { Form, Select, message, Button, Input, DatePicker, Modal, Checkbox, Upload, Row, Col, Space } from 'antd'
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from '../withRouter';
import ParentStore from '../store/ParentStore';
import { Link } from 'react-router-dom';
import config from '../config';

class ParentsAdd extends Component {

    formRef = React.createRef()
    constructor(props) {
        super(props)
        this.state = ({
            loading: false,
            companyId: "",
            username: "",
            parentId: ""

        })
        this.parentsStore = new ParentStore(this)

    }
    async componentDidMount() {
        // const id = this.props.params.id
        const id = this.props.parentId
        console.log(id)
        if (id) {

            const getParentData = await this.parentsStore.getParent(id)
            console.log(getParentData)
            await this.setState({ username: getParentData.username, parentId: getParentData.parentId })
            await this.formRef.current.setFieldsValue({
                mobileno: getParentData.mobileno,
                emailId: getParentData.emailId,
                password: getParentData.password
            })

        }
    }
    layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    onFinish = async (values) => {
        this.setState({ loading: true })
        console.log(values)
        const data = { ...values, parentId: this.state.parentId }
        const response = await this.parentsStore.addData(data)
        await this.props.response(response)
        // if (response?.success === 'S') {
        //     await message.success(response.message)
        //     this.props.navigate("/parentlist");

        // } else {
        //     message.error(response.message);
        // }
        this.setState({ loading: false })
    }

closeModal=()=>{
    this.props.closeModal()
}


    render() {
        return (
            <div style={{ padding: 0 }}>
                <div style={{ paddingBottom: "10px" }}>
                    <Form style={{ maxWidth: "100%", padding: 0 }}
                        ref={this.formRef}
                        layout='vertical'
                        onFinish={this.onFinish}
                        // {...this.layout}
                        name="nest-message" className="formClass" >

                        {/* <h1>Parents Details</h1> */}



                        {/* <div className="headingTitle"> Parents Details</div> */}
                        <Row className="overflowFormInputs">
                            {/* css class */}
                            <Col span={12} className="formColumnClass">
                                <Form.Item name="emailId"
                                    //    label="Email Id" 
                                    label={this.context.translate("_FLEET_USER_USERLIST_EMAIL_")}
                                    // type="Email" 
                                    rules={[
                                        {
                                          type: 'email',
                                        },
                                      ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item name="mobileno"
                                    //    label="Contact" 
                                    label={this.context.translate("_CONTACT_")}
                                    rules={[
                                        {
                                          required: true,
                                        },
                                      ]}
                                >
                                    <Input autoComplete='off' />
                                </Form.Item>
                            </Col>
                            <Col span={12} className="formColumnClass">
                                <Form.Item name="password"
                                    // label="Password"  
                                    label={this.context.translate("_FLEET_LOGIN_PASSWORDLBL_")}
                                >
                                    <Input.Password id='form-password' autoComplete='off' />
                                </Form.Item>

                            </Col>
                        </Row>
                        <div className="formBtnGroup">
                            {/* <Link to="/parentlist"> <Button>Back</Button></Link> */}

                            <Button className="formCancelButton" type="primary" onClick={(e) => {
                                this.closeModal()
                                // this.formRef.current?.resetFields();
                                this.setState({ upadate: 1 })
                                // this.formRef.current?.setFieldsValue({password:undefined,mobileno:undefined})
                            }}>
                                {/* Cancel */}
                                {this.context.translate("_FLEET_CLOSE_BTN_")}
                            </Button>
                            <Button type="primary"
                                className="formSaveButton" htmlType="submit" loading={this.state.loading}>
                                {/* Save */}
                                {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                            </Button>
                        </div>



                    </Form>
                </div>
            </div>
        )
    }
}

ParentsAdd.contextType = StoreContext;
export default observer(withRouter(ParentsAdd));
