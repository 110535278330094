import React from "react";
import {
  Select,
  Button,
  Space,
  DatePicker,
  Row,
  Col,
  Dropdown,
  Checkbox,
  Modal,
  Typography,
  Table,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import config from "../config";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import WorkingHoursReportStore from "../store/WorkingHoursReportStore";

import dayjs from "dayjs";
import VehicleStore from "../store/VehicleStore";
import EmailReportStore from "../store/EmailReportStore";
import { decode as base64_decode } from "base-64";
import Cookies from "js-cookie";
const { RangePicker } = DatePicker;
const { Text } = Typography;
const { Option } = Select;
class WorkingHoursReport extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.workingHoursReportStore = new WorkingHoursReportStore();
    this.vehicleStore = new VehicleStore(this);
    this.emailReportStore = new EmailReportStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      pageNo: 1,
      offset: 0,
      limit: 50,
      company_name: "",
      listUpdated: 0,
      companyId: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      selectedVehicles: [],
      selectAllVehicles: false,
      modalVisible: false,
      column: "",
      filter: "",
      Notcompany: false,
      Notasset: false,
      showMonthPopup: false,
      week: "0",
      showSelectAllChk: false,
      selectedColumns: [
        {
          // title: "Vehicle Id ",
          title: context.translate("_FLEET_REPORTS_VEHICLEID_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",
          width: "15%",
          // hidden: true,
        },
        {
          // title: "Date",
          title: context.translate("_FLEET_REPORTS_DATE_"),
          dataIndex: "date",
          key: "date",
          width: "15%",
        },

        {
          // title: "Driver Id",
          title: context.translate("_FLEET_REPORTS_DRIVER_ID_"),
          dataIndex: "driverName",
          key: "driverName",
          width: "15%",
        },
        {
          // title: "Start Time",
          title: context.translate("_FLEET_START_TIME_"),
          dataIndex: "startTime",
          key: "startTime",
          width: "15%",
          render: (value) => {
            if (value) {
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            } else {
              return <></>;
            }
          },
        },
        {
          // title: "Stop Time ",
          title: context.translate("_FLEET_STOP_TIME_"),
          dataIndex: "stopTime",
          key: "stopTime",
          width: "15%",
          render: (value) => {
            if (value) {
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            } else {
              return <></>;
            }
          },
        },
        {
          // title: "Duty Time Km ",
          title: context.translate("_FLEET_REPORTS_IN_HOURS_DISTANCE_"),
          dataIndex: "inHoursDistance",
          key: "inHoursDistance",
          width: "15%",
        },
        {
          // title: "Off Duty km",
          title: context.translate("_FLEET_REPORTS_OUT_HOURS_DISTANCE_"),
          dataIndex: "outHoursDistance",
          key: "outHoursDistance",
          width: "15%",
        },
        {
          // title: "Distance Km",
          title: context.translate("_FLEET_REPORTS_DISTANCE_"),
          dataIndex: "distanceTravel",
          key: "distanceTravel",
          width: "15%",
        },
        {
          // title: "Duty Time Duration",
          title: context.translate("_FLEET_REPORTS_IN_HOURS_DURATION_"),
          dataIndex: "inHoursDuration",
          key: "inHoursDuration",
          width: "15%",
          render: (text) => {
            const durationInMinutes = parseInt(text, 10);
            return this.convertMinutesToHHMMSS(durationInMinutes);
          },
        },
        {
          // title: "Off Duty Duration",
          title: context.translate("_FLEET_REPORTS_OUT_HOURS_DURATION_"),
          dataIndex: "outHoursDuration",
          key: "outHoursDuration",
          width: "15%",
          render: (text) => {
            const durationInMinutes = parseInt(text, 10);
            return this.convertMinutesToHHMMSS(durationInMinutes);
          },
        },
        {
          // title: "Duty Time From  ",
          title: context.translate("_DUTY_TIME_FROM_"),
          dataIndex: "dutyTimeFrom",
          key: "dutyTimeFrom",
          width: "15%",
        },
        {
          // title: "Duty Time To  ",
          title: context.translate("_DUTY_TIME_TO_"),
          dataIndex: "dutyTimeTo",
          key: "dutyTimeTo",
          width: "15%",
        },
        // {
        //   // title: "Groups",
        //   title: context.translate("_GROUPS_"),
        //   dataIndex: "Groups",
        //   key: "Groups",
        //   width: "15%",
        // },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
          icon: <PlusOutlined />,
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
          icon: <PlusOutlined />,
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            // userId: 1,
            week: this.state.week,
            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.workingHoursReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.workingHoursReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.workingHoursReportStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.workingHoursReportStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }

  convertMinutesToHHMMSS = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const seconds = 0; // Assuming seconds are always zero in your case

    // Pad hours and minutes with leading zeros if needed
    const paddedHours = String(hours).padStart(2, "0");
    const paddedMinutes = String(mins).padStart(2, "0");
    const paddedSeconds = String(seconds).padStart(2, "0");

    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  };

  async componentDidMount() {
    this.setState({ pageSize: 50 });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
    if (Number(this.props.getUserData().isadmin) === 1) {
      await this.vehicleStore.getVehicleGroup({
        companyId: this.props.getUserData().userCompanyId,
        userId: this.props.getUserData().userId,
        isadmin: this.props.getUserData().isadmin,
      });
    }

    await this.setState({ column: this.state.selectedColumns });
  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }
  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0]?.format(format),
        toDate: dates[1]?.format(format),
      });
    }
  };
  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ currentPage: 1, companyId: comp.value });
    await this.setState({ selectedVehicles: [], selectAllVehicles: false });
    await this.vehicleStore.getVehiclesCompanyId(comp.value);
    await this.setState({ listUpdated: 1 });
    // await this.handleSearch();
  }

  // async onSearch(value) {
  //     console.log(value)
  //     await this.setState({ emailId: value });
  //     this.handleSearch();
  // };

  async handleSearch() {
    this.setState({ Notasset: false });
    await this.workingHoursReportStore.loadData({
      offset: (this.state.pageNo - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      pageNo: this.state.pageNo,
      vehicleId: this.state.selectedVehicles,
    });

    await this.setState({ listUpdated: 1 }); //need to reload data
  }

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles?.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles?.length > 0) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.workingHoursReportStore.loadData({
          offset: (this.state.pageNo - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,
          pageNo: this.state.pageNo,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          week: this.state.week,
        });
        this.setState({ loadData: 1 });
        // console.log(this.state.fromDate,this.state.toDate,)
      }
    }
  };
  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state?.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  addMonths = (date, months) => {
    var indx = dayjs().month() + -6;
    return dayjs().month(indx);
  };
  checkFromDate(dates) {
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }

  getSubGroup = async (val) => {
    const subgroupData = await this.vehicleStore.getVehicleSubGroup({
      deviceGroupId: val,
      deviceSubGroupId: null,
      companyId: this.state.companyId,

      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      subgroup: val,
    });

    this.setState({ loadData: 1 });
  };

  getSubGroupVehicles = async (val) => {
    const subgroupData = await this.vehicleStore.getVehicleSubGroup({
      deviceGroupId: this.state.deviceGroupId,
      deviceSubGroupId: val,
      companyId: this.state.companyId,

      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
    });
    this.setState({ loadData: 1 });
  };

  expandedRowRender = (x) => {
    const columns = [
      {
        // title: "Vehicle Id ",
        title: this.context.translate("_FLEET_REPORTS_VEHICLEID_"),
        dataIndex: "vehicleNo",
        key: "vehicleNo",
        width: "15%",
      },
      {
        // title: "Date",
        title: this.context.translate("_FLEET_REPORTS_DATE_"),
        dataIndex: "date",
        key: "date",
        width: "15%",
      },

      {
        // title: "Driver Id",
        title: this.context.translate("_FLEET_REPORTS_DRIVER_ID_"),
        dataIndex: "driverName",
        key: "driverName",
        width: "15%",
      },
      {
        // title: "Start Time",
        title: this.context.translate("_FLEET_START_TIME_"),
        dataIndex: "startTime",
        key: "startTime",
        width: "15%",
        render: (value) => {
          if (value) {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        // title: "Stop Time ",
        title: this.context.translate("_FLEET_STOP_TIME_"),
        dataIndex: "stopTime",
        key: "stopTime",
        width: "15%",
        render: (value) => {
          if (value) {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        // title: "Duty Time Km ",
        title: this.context.translate("_FLEET_REPORTS_IN_HOURS_DISTANCE_"),
        dataIndex: "inHoursDistance",
        key: "inHoursDistance",
        width: "15%",
      },
      {
        // title: "Off Duty km",
        title: this.context.translate("_FLEET_REPORTS_OUT_HOURS_DISTANCE_"),
        dataIndex: "outHoursDistance",
        key: "outHoursDistance",
        width: "15%",
      },
      {
        // title: "Distance Km",
        title: this.context.translate("_FLEET_REPORTS_DISTANCE_"),
        dataIndex: "distanceTravel",
        key: "distanceTravel",
        width: "15%",
      },
      {
        // title: "Duty Time Duration",
        title: this.context.translate("_FLEET_REPORTS_IN_HOURS_DURATION_"),
        dataIndex: "inHoursDuration",
        key: "inHoursDuration",
        width: "15%",
        render: (text) => {
          const durationInMinutes = parseInt(text, 10);
          return this.convertMinutesToHHMMSS(durationInMinutes);
        },
      },
      {
        // title: "Off Duty Duration",
        title: this.context.translate("_FLEET_REPORTS_OUT_HOURS_DURATION_"),
        dataIndex: "outHoursDuration",
        key: "outHoursDuration",
        width: "15%",
        render: (text) => {
          const durationInMinutes = parseInt(text, 10);
          return this.convertMinutesToHHMMSS(durationInMinutes);
        },
      },
      {
        // title: "Duty Time From  ",
        title: this.context.translate("_DUTY_TIME_FROM_"),
        dataIndex: "dutyTimeFrom",
        key: "dutyTimeFrom",
        width: "15%",
      },
      {
        // title: "Duty Time To  ",
        title: this.context.translate("_DUTY_TIME_TO_"),
        dataIndex: "dutyTimeTo",
        key: "dutyTimeTo",
        width: "15%",
      },
      // {
      //   // title: "Groups",
      //   title: context.translate("_GROUPS_"),
      //   dataIndex: "Groups",
      //   key: "Groups",
      //   width: "15%",
      // },
    ];

    const totalColumns = [
      {
        // title: "Vehicle Id ",
        title: this.context.translate("_FLEET_REPORTS_VEHICLEID_"),
        dataIndex: "vehicleNo",
        key: "vehicleNo",
        width: "15%",
        render: () => {
          <>Total</>;
        },
      },
      {
        // title: "Date",
        title: this.context.translate("_FLEET_REPORTS_DATE_"),
        dataIndex: "",
        key: "date",
        width: "15%",
      },

      {
        // title: "Driver Id",
        title: this.context.translate("_FLEET_REPORTS_DRIVER_ID_"),
        dataIndex: "",
        key: "driverName",
        width: "15%",
      },
      {
        // title: "Start Time",
        title: this.context.translate("_FLEET_START_TIME_"),
        dataIndex: "startTime",
        key: "startTime",
        width: "15%",
        render: (value) => {
          if (value) {
            const formattedDateTime = dayjs(value).format("DD-MM-YYYY");
            return <>{formattedDateTime}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        // title: "Stop Time ",
        title: this.context.translate("_FLEET_STOP_TIME_"),
        dataIndex: "stopTime",
        key: "stopTime",
        width: "15%",
        render: (value) => {
          if (value) {
            const formattedDateTime = dayjs(value).format("DD-MM-YYYY");
            return <>{formattedDateTime}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        // title: "Duty Time Km ",
        title: this.context.translate("_FLEET_REPORTS_IN_HOURS_DISTANCE_"),
        dataIndex: "inHoursDistance",
        key: "inHoursDistance",
        width: "15%",
      },
      {
        // title: "Off Duty km",
        title: this.context.translate("_FLEET_REPORTS_OUT_HOURS_DISTANCE_"),
        dataIndex: "outHoursDistance",
        key: "outHoursDistance",
        width: "15%",
      },
      {
        // title: "Distance Km",
        title: this.context.translate("_FLEET_REPORTS_DISTANCE_"),
        dataIndex: "distanceTravel",
        key: "distanceTravel",
        width: "15%",
      },
      {
        // title: "Duty Time Duration",
        title: this.context.translate("_FLEET_REPORTS_IN_HOURS_DURATION_"),
        dataIndex: "inHoursDuration",
        key: "inHoursDuration",
        width: "15%",
        render: (text) => {
          const durationInMinutes = parseInt(text, 10);
          return this.convertMinutesToHHMMSS(durationInMinutes);
        },
      },
      {
        // title: "Off Duty Duration",
        title: this.context.translate("_FLEET_REPORTS_OUT_HOURS_DURATION_"),
        dataIndex: "outHoursDuration",
        key: "outHoursDuration",
        width: "15%",
        render: (text) => {
          const durationInMinutes = parseInt(text, 10);
          return this.convertMinutesToHHMMSS(durationInMinutes);
        },
      },
      {
        // title: "Duty Time From  ",
        title: this.context.translate("_DUTY_TIME_FROM_"),
        dataIndex: "",
        key: "dutyTimeFrom",
        width: "15%",
      },
      {
        // title: "Duty Time To  ",
        title: this.context.translate("_DUTY_TIME_TO_"),
        dataIndex: "",
        key: "dutyTimeTo",
        width: "15%",
      },
      // {
      //   // title: "Groups",
      //   title: context.translate("_GROUPS_"),
      //   dataIndex: "Groups",
      //   key: "Groups",
      //   width: "15%",
      // },
    ];

    return (
      <>
        <div>
          <p>{`Vehicle No: ${x.vehicleNo}`}</p>
        </div>
        <Table
          showHeader={false}
          bordered
          columns={columns}
          scroll={{ x: 50 }}
          dataSource={[x]}
          pagination={false}
        />
        <Table
          showHeader={false}
          bordered
          columns={totalColumns}
          scroll={{ x: 50 }}
          dataSource={[x]}
          pagination={false}
        />
      </>
    );
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_WORKING_HOURS_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.workingHoursReportStore?.workingHoursReport?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button style={{ backgroundColor: config.activeColor }}>
                    <Space>
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {this.context.translate("_COLUMNS_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={(value) => this.handleChange(value)}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />

                {(Number(this.props.getUserData().isadmin) === 1 ||
                  Number(this.props.getUserData().isadmin) === 0) && (
                  <>
                    <Select
                      style={{ width: 120 }}
                      showSearch
                      filterOption={(inputValue, option) => {
                        if (option.children) {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) >= 0
                          );
                        }
                      }}
                      placeholder="Group"
                      onSelect={async (val) => {
                        await this.getSubGroup(val);
                        await this.setState({ deviceGroupId: val });
                      }}
                    >
                      {this.vehicleStore.groupList?.map((val) => {
                        return (
                          <Select.Option
                            value={val.deviceGroupId}
                            key={val.deviceGroupId}
                          >
                            {val.text}
                          </Select.Option>
                        );
                      })}
                    </Select>

                    <Select
                      showSearch
                      style={{ width: 120 }}
                      placeholder="Sub Group"
                      filterOption={(inputValue, option) => {
                        if (option.children) {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) >= 0
                          );
                        }
                      }}
                      onSelect={async (val) => {
                        await this.setState({ deviceSubGroupId: val });
                        await this.getSubGroupVehicles(val);
                      }}
                    >
                      {this.vehicleStore.subGroupList?.map((val) => {
                        return (
                          <Select.Option
                            value={val.deviceSubGroupId}
                            key={val.deviceSubGroupId}
                          >
                            {val.subgroupname}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </>
                )}

                <Checkbox
                  checked={this.state.selectAllVehicles}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllVehicles: e.target.checked,
                    });
                    if (this.state.selectAllVehicles) {
                      await this.setState({
                        Notasset: false,
                      });
                    }
                    // console.log(e.target.checked);
                    await this.handleAllSelectVehicles();
                  }}
                />

                {this.context.translate("_ALL_")}
                {"  "}

                <Select
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }

                    if (val?.length > 1) {
                      console.log(val?.length > 1);
                      this.setState({ showAlertPopup: true });
                    }
                  }}
                  style={{ width: 170 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  mode="multiple"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>

                <Select
                  placeholder="Week"
                  style={{ width: 120 }}
                  onSelect={(val) => {
                    this.setState({ week: val });
                  }}
                  defaultValue={"0"}
                >
                  <Select.Option value="0">
                    {this.context.translate("All")}
                  </Select.Option>
                  <Select.Option value="1">
                    {this.context.translate("_SUNDAY_")}
                  </Select.Option>
                  <Select.Option value="2">
                    {this.context.translate("_MONDAY_")}
                  </Select.Option>
                  <Select.Option value="3">
                    {this.context.translate("_TUESDAY_")}
                  </Select.Option>
                  <Select.Option value="4">
                    {this.context.translate("_WEDNESDAY_")}
                  </Select.Option>
                  <Select.Option value="5">
                    {this.context.translate("_THURSDAY_")}
                  </Select.Option>
                  <Select.Option value="6">
                    {this.context.translate("_FRIDAY_")}
                  </Select.Option>
                  <Select.Option value="7">
                    {this.context.translate("_SATURDAY_")}
                  </Select.Option>
                </Select>
                <RangePicker
                  placeholder={[
                    this.context.translate("_FROM_DATE_TIME_"),
                    this.context.translate("_TO_DATETIME_"),
                  ]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />

                <Button
                  type="primary"
                  onClick={async () => {
                    await this.setState({ pageSize: 50, pageNo: 1 });
                    await this.showData();
                  }}
                >
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={350}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            //  expandable={{
            //   // expandedRowRender: (record) => <this.RowSummary record={record} />,
            //   rowExpandable: (record) => record.name !== 'Not Expandable', // Optionally control which rows can be expanded
            // }}
            // expandable={{
            //   expandedRowRender: (record) => this.expandedRowRender(record), // Pass record as a parameter
            // }}

            // expandable={{
            //   expandedRowRender: (record) => (
            //     <div>
            //       {/* Custom content inside the div */}
            //       <p>{`Name: ${record.name}`}</p>
            //       <p>{`Start Time: ${record.startTime}`}</p>
            //       {/* Add more fields as needed */}
            //     </div>
            //   ),
            //   rowExpandable: (record) => record.name !== 'Not Expandable', // Optionally control which rows can be expanded
            // }}
            rowKey="startTime"
            currentPage={this.state.pageNo}
            pageSize={this.state.pageSize}
            total={this.workingHoursReportStore?.total}
            columns={this.state.column}
            dataSource={this.workingHoursReportStore?.workingHoursReport}
            loading={this.workingHoursReportStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        <Modal
          width={350}
          className="alert-model"
          title={
            <div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              {/* Alert */}
              {this.context.translate("_FLEET_ALERT_")}
            </div>
          }
          open={this.state.showMonthPopup}
          onOk={() => this.setState({ showMonthPopup: false })}
          onCancel={() => this.setState({ showMonthPopup: false })}
          footer={false}
        >
          {/* For reports prior to 6 months please contact administrator */}
          {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
        </Modal>
      </>
    );
  }
}
WorkingHoursReport.contextType = StoreContext;
export default observer(withRouter(WorkingHoursReport));
