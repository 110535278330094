import React, { useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Select,
  Table,
  Button,
  Typography,
  Space,
  Tag,
  Avatar,
  Row,
  Col,
  Dropdown,
  Modal,
  Tooltip,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import {
  DeleteFilled,
  DownloadOutlined,
  EditFilled,
  PlusOutlined,
  UserOutlined,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import DepartmentStore from "../store/DepartmentStore";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import AddButton from "./AddButton";
import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import CompanyAutocomplete from "./CompanyAutocomplete";
import CompanyStore from "../store/CompanyStore";
import TripCostStore from "../store/TripCostStore";
import TripCostAdd from "./TripCostAdd";

const { Search } = Input;

class TripCostList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.onSearch = this.onSearch.bind(this);

    this.tripCostStore = new TripCostStore(this);

    this.companyStore = new CompanyStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      distributor: "",
      distributorType: "",
      status: "",
      company_name: "",
      emailId: "",
      listUpdated: 0,
      loadList: 0,
      company_value_label: "",
      // Id: "",
      openTripForm: false,
      listDataUpdate: false,
      Id: "",
    };
  }
  onEdit = async (record) => {
    // console.log(record.companyId)
    console.log(record);
    // await this.context.setBeforeEditState(record.companyId);
    await this.context.viewStateStore.setTripCostStates({
      companyId: record.companyId,
    });
    // await this.props.navigate("/useradd/" + record.userId);
    await this.setState({ Id: record.Id });
    await this.setState({ openTripForm: true });
  };
  async onDelete(record) {
    console.log(record);
    const response = await this.tripCostStore.deleteTripCost(record.Id);

    if (response?.data?.success === "S") {
      message.success(response.data.message);

      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }
  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const beforeEditCompanyId =
        this.context.viewStateStore.tripCostObject.companyId;
      if (beforeEditCompanyId) {
        await this.tripCostStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: beforeEditCompanyId,
        });
        const singleData = await this.companyStore.getCompanyDataById(
          this.context.viewStateStore.tripCostObject.companyId
        );
        console.log(beforeEditCompanyId);
        await this.setState({ listUpdated: 1 }); //need to reload data
        await this.setState({
          company_value_label: singleData,
          companyId: beforeEditCompanyId,
        });
      }
      this.setState({ listDataUpdate: false });
    }
  }
  async componentDidMount() {
    if(Number(this.props.getUserData().isadmin)!==-1 ){
      console.log(this.props.getUserData().userCompanyId)
      await this.setState({companyId:this.props.getUserData().userCompanyId})
     //  console.log(this.state.companyId)
      await this.setState({userId:this.props.getUserData().userId})
      await this.handleSearch();
 }
    // await this.companyStore.loadData({ offset: 0, limit: 50 });
    // console.log(this.tripCostStore.beforeEditCompanyNo,"era")

    this.setState({ pageSize: 50 });
    // await this.handleSearch();
    this.columns = [
      {
        // title: "Company Name",
        title: this.context.translate("_COMPANY_NAME_"),
        dataIndex: "companyName",
        width: "15%",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        // title: "Name",
        title: this.context.translate("_FLEET_DASHBORD_NEWPOI_NAME_"),
        dataIndex: "name",
        width: "15%",
      },
      {
        // title: "Vendor Code",
        title: this.context.translate("_VENDOR_CODE_"),
        dataIndex: "vendorCode",
        width: "15%",
      },
      {
        // title: "Trip Duration",
        title: this.context.translate("_TRIP_DURATION_"),
        dataIndex: "tripDuration",
        width: "15%",
      },
      {
        // title: "Trip Cost",
        title: this.context.translate("_TRIP_COST_"),
        dataIndex: "tripCost",
        width: "15%",
      },
      {
        // title: "Trip Min Km",
        title: this.context.translate("_TRIP_MIN_KM_"),
        dataIndex: "tripMinKm",
        width: "15%",
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        fixed: "right",
        width: 50,
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                    <Tooltip 
                    // title="Edit"  
                    title={this.context.translate("_FLEET_COMPANY_COMPANYLIST_EDIT_")}
                    placement="bottom" color={config.tooltipColorEdit}>
                  <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                  <Tooltip
                  //  title="Delete" 
                  title={this.context.translate("_FLEET_COMPANY_COMPANYLIST_DELETE_")}
                  placement="bottom" color={config.tooltipColorDelete}>
                    <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ currentPage: 1, companyId: comp.value,pageSize:50 });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({
      company_value_label: singleData,
      companyId: comp.value,
    });

    // await this.tripCostStore.beforeEditCompanyId(comp.value)
    await this.handleSearch();
  }

  async handleSearch() {
    await this.tripCostStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      searchField: this.state.searchField,
    });

    await this.setState({ listUpdated: 1 }); //need to reload data
  }

  searchInput = async (e) => {
    const value = e.target.value;

    // await this.setState({searchField:value})
    if (value.length > 3 || value.length === 0) {
      await this.tripCostStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        searchField: value,
        companyId: this.state.companyId,
      });

      await this.setState({ loadList: 1 });
    }
  };

  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.data.success == "S") {
      message.success(resp.data.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openTripForm: false });

      //  await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    } else if (resp.data.success == "F0") {
      message.error("error");
    }
  };

  closeModal=()=>{
    this.setState({ openTripForm: false, Id: "" });
  }

  render() {
    //console.log('in render1')
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Trip Cost</div> */}
              <div className="heading">{this.context.translate("_TRIP_COST_")}</div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                {/* <AddButton
                  link="/tripCostAdd"
                  title={this.context.translate("_ADD_")}
                /> */}
                <Tooltip 
                // title="Add" 
                title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                placement="left" color={config.primaryColor}>
                <Button
                  className="addButton"
                  icon={<PlusOutlined />}
                  type="primary"
                  onClick={() =>
                    this.setState({ openTripForm: true, Id: "" })
                  }
                ></Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  value={
                    this.state.company_value_label
                      ? this.state.company_value_label
                      : null
                  }
                  // allowClear={true}
                  style={{ width: 200 }}
                />
              </Space>
            </Col>
            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/*<Button  ><PlusOutlined />WASL</Button>
                <Button  ><PlusOutlined />WASL Station</Button>*/}
              </Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.tripCostStore?.total}
            columns={this.columns}
            dataSource={this.tripCostStore?.tripCostArray}
            loading={this.tripCostStore?.loading}
            onChange={this.handleTableChange}
          />

          {this.state.openTripForm && (
            <Modal
              bodyStyle={{ height: "645px" }}
              className="formShowModal" //  {/* css class */}
              // title="Add Trip Cost"
              title={this.context.translate("_ADD_TRIP_COST_")}
              centered
              visible={this.state.openTripForm}
              footer={null}
              onCancel={() => {
                this.setState({ openTripForm: false, Id: "" });
              }}
              width={665}
            >
              <div>
                <TripCostAdd Id={this.state.Id} response={this.responseMsg} closeModal={this.closeModal}/>
              </div>
            </Modal>
          )}
        </div>
      </>
    );
  }
}
TripCostList.contextType = StoreContext;
export default observer(withRouter(TripCostList));
