import React from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Space,
  message,
  Modal,
  Form,
  Select,
  DatePicker,
  Dropdown,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import config from "../config";
import {
  DeleteFilled,
  DownloadOutlined,
  ExportOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import withRouter from "../withRouter";
import WASLVehicleStore from "../store/WASLVehicleStore";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Cookies from "js-cookie";

const dateFormat = "YYYY-MM-DD";

class WASLVehicleList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.waslVehicleStore = new WASLVehicleStore();

    // console.log(this.context);

    this.state = {
      showWASLRegistration: false,
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      loadList: 0,
      Company: true,
      Individual: false,
      cname: "",
      identityNo: "",
      crNo: "",
      loadData: 0,
      companyId: "",
      selectVehicle: '',
      UcompanyId: "",
      cid: "",
    };
    this.formRef = React.createRef();

    // this.ExportMenuProps = {
    //   items: [
    //     {
    //       label: <Button>Excel</Button>,
    //       key: "excel",
    //     },
    //   ],
    //   onClick: (val) => {
    //     const result = [];
    //     this.columns.map((obj) => result.push(obj.dataIndex));
    //     //  console.log(result.toString())
    //     var getData = {
    //       cid: this.state.cid,
    //       UcompanyId: this.state.UcompanyId,
    //       colList: result,
    //     };
    //     console.log(getData);
    //     if (val.key == "excel") {
    //       this.waslCompanyStore.exportExcel(getData);
    //     }
    //   },
    // };
  }

  async componentDidUpdate(prevProps, prevState){
    if(Number(this.props.getUserData().isadmin)===1){
      // console.log('innnnnn');
        this.props.navigate("/login");
       
     }
  }
  async componentDidMount() {

    if(this.props.getUserData().isadmin===3){
   await this.setState({companyId:0,vehicleId:0})
    }
   
    // await this.waslVehicleStore.loadData({ limit: 50, offset: 0 });
    this.setState({ pageSize: 50 });

    this.columns = [
      {
        title: this.context.translate("_ACCOUNT_"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        key: "vehicleNo",
      },
      {
        title: this.context.translate("_SEQUENCE_NUMBER_"),
        dataIndex: "sequenceNo",
        key: "sequenceNo",
      },
      {
        title: this.context.translate("_NUMBER_"),
        dataIndex: "plateNo",
        key: "plateNo",
      },
      {
        title: this.context.translate("_RIGHT_LETTER"),
        dataIndex: "plateRightLetter",
        key: "plateRightLetter",
      },
      {
        title: this.context.translate("_MIDDLE_LETTER_"),
        dataIndex: "plateMiddleLetter",
        key: "plateMiddleLetter",
      },
      {
        title: this.context.translate("_LEFT_LETTER_"),
        dataIndex: "plateLeftLetter",
        key: "plateLeftLetter",
      },
      {
        title: this.context.translate("_PLATE_TYPE_"),
        dataIndex: "plateType",
        key: "plateType",
      },
      {
        title: this.context.translate("_IMEI_NUMBER_"),
        dataIndex: "imeiNo",
        key: "imeiNo",
      },
      {
        title: this.context.translate("_WASL_VEHICLE_KEY_"),
        dataIndex: "waslVehicleKey",
        key: "waslVehicleKey",
      },
      {
        title: this.context.translate("_RESPONSE_"),
        dataIndex: "response",
        key: "response",
      },
      // {
      //   title: this.context.translate("_WASL_KEY_"),
      //   dataIndex: "waslKey",
      //   key: "waslKey",
      // },
      {
        title: this.context.translate("_REGISTRATION_DATE_"),
        dataIndex: "createDate",
        key: "createDate",
      },
      {
        title: this.context.translate("_ACTIVITY_"),
        dataIndex: "activity",
        key: "activity",
      },
      {
        title: this.context.translate("_REPLY_"),
        dataIndex: "reply",
        key: "reply",
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        fixed: "right",
        width: "5%",
        // render: (_, record) => {
        //   return (
        //     <>
        //       <Space>
        //         <SearchOutlined
        //           onClick={async() => {
        //            await this.WASLCompanyStatus(record);
        //            await this.warning();
        //           }}
        //         />

        //         <DeleteConfirm
        //           onConfirm={(e) => {
        //             this.onDelete(record);
        //           }}
        //         >
        //           <Link
        //             onClick={(e) => {
        //               e.preventDefault();
        //             }}
        //           >
        //             <DeleteFilled style={{ color: config.deleteColor }} />
        //           </Link>
        //         </DeleteConfirm>
        //       </Space>
        //     </>
        //   );
        // },
      },
    ];
    await this.handleSearch()
  }

  
  // async componentDidUpdate(prevProps, prevState){
  //   if(Number(this.props.getUserData().isadmin)===0 || Number(this.props.getUserData().isadmin)===1){
  //     // console.log('innnnnn');
  //       this.props.navigate("/login");
       
  //    }
  // }

  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value, loadList: 1 });

    await this.waslVehicleStore.getVehiclesByCompanyId({
      companyId: comp.value,
    });

    await this.waslVehicleStore.getCompanyIdentityNo(comp.value)

    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.waslVehicleStore.loadData({
      companyId: this.state.companyId,
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      vehicleId: this.state.selectVehicle,
      sequenceNo: this.state.sequenceNo,
      UcompanyId : this.props.getUserData().isadmin===3?this.props.getUserData().userCompanyId:0
    });
    await this.setState({ loadList: 0 });
    console.log(this.props.getUserData().isadmin===3?this.props.getUserData().userCompanyId:0,"asdfghjkdfdfh")
    // this.setState({ loading: false });
  }

  onWASLVehicleRegistrationFinish = async (value) => {
    console.log(value);
    const data = {
      ...value,
      userId: 1,
      companyId: this.state.companyId,
    };
    console.log(data);
    const response = await this.waslVehicleStore.registerVehicle(data);
    if (response?.success === "S") {
      message.success(response.data.message);
      console.log(response);
    } else {
      message.error(response.data.message);
    }
    if (response.data.message) {
      this.setState({ showWASLRegistration: false });
      this.formRef.current?.resetFields();
    }
  };

  searchInput = async (e) => {
    const value = e.target.value;
    // await this.setState({searchField:value})
    if (value.length > 3 || value.length === 0) {
      await this.waslVehicleStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        sequenceNo: value,
        companyId: this.state.companyId,
      });
      await this.setState({ loadList: 1 });
    }
  };

  validateNumber = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1);
    if (value1) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  setFieldsValues = async (vehicleId) => {
    console.log("vehicleId", vehicleId);
    const data = await this.waslVehicleStore.getVehicleInfo(vehicleId);
    console.log(data, "dta");

    await this.formRef.current.setFieldsValue({ imeiNumber: data.full_imei });
    await this.formRef.current.setFieldsValue({ number: data.vehicleNo });
    await this.formRef.current.setFieldsValue({
      sequenceNumber: data.sequenceNo,
    });
    await this.formRef.current.setFieldsValue({ plateType: data.plateType });
  };

 
  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_WASL_VEHICLE_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                <Link to={"/vehiclelist"}>
                  <Button ghost type="primary">
                    {this.context.translate("_BACK_")}
                  </Button>
                </Link>
                <Button
                  type="primary"
                  onClick={() => this.setState({ showWASLRegistration: true })}
                >
                  + {this.context.translate("_REGISTER_")}
                </Button>
                {/* <Dropdown menu={this.ExportMenuProps} disabled={this.companyStore?.companies.length > 0 ? false : true}> */}
                {/* <Dropdown menu={this.ExportMenuProps}>
                <Button>
                  <Space>
                    <DownloadOutlined />
                  </Space>
                </Button>
              </Dropdown> */}
                <Button
                  style={{ backgroundColor: config.activeColor }}
                  // disabled={
                  //   this.waslVehicleStore?.WASLVehicleArray.length > 0
                  //     ? false
                  //     : true
                  // }
                  onClick={async (val) => {
                    const result = [];
                    await this.columns.map((obj) => result.push(obj.dataIndex));
                    //  console.log(result.toString())
                    var getData = {
                      companyId: this.state.companyId,
                      UcompanyId: JSON.parse(base64_decode(Cookies.get("data"))).UcompanyId,
                      vehicleId: this.state.selectVehicle,
                      sequenceNo: this.state.sequenceNo,
                      colList: result,
                    };
                    await this.waslVehicleStore.exportExcel(getData);
                    await this.setState({ loadList: 1 });
                  }}
                >
                   <i class="bi bi-file-arrow-down" style={{marginRight:"5px"}}></i>
                  {this.context.translate("_EXPORT_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Space>
              <CompanyAutocomplete
                onChange={(value) => {
                  this.handleChange(value);
                }}
                style={{ width: 200 }}
              />

              <Select
                onSelect={async (val) => {
                  await this.setState({ selectVehicle: val });

                  console.log(val);
                }}
                onSearch={() => this.handleSearch()}
                fieldNames={{ label: "vehicleNo", value: "vehicleId" }}

                placeholder={this.context.translate("_ASSET_NAME_")}
                // placeholder="Asset Name"

              
                optionFilterProp="children"
                options={this.waslVehicleStore?.getVehiclesUsingCompanyId}
                style={{ width: 160 }}
              />

              <Input
                style={{ width: 200 }}
              placeholder={this.context.translate("_SEQUENCE_NUMBER_")}
                // placeholder="Sequence Number"
                onChange={async (e) => {
                  await this.setState({ sequenceNo: e.target.value });
                  await this.searchInput(e);
                  // await this.handleSearch()
                }}
              ></Input>

              <Button type="primary" onClick={() => this.handleSearch()}>
                <SearchOutlined />
              </Button>
            </Space>
          </Row>

          <ListTable
            current={this.state.currentPage}
            total={this.waslVehicleStore?.total}
            columns={this.columns}
            // dataSource={this.dataSource}
            dataSource={this.waslVehicleStore?.WASLVehicleArray}
            loading={this.waslVehicleStore?.loading}
            onChange={this.handleTableChange}
          />

          <Modal
            title={this.context.translate("_WASL_REGISTRATION_")}
            destroyOnClose={true}
            open={this.state.showWASLRegistration}
            onCancel={() => this.setState({ showWASLRegistration: false })}
            footer={null}
            width={645}
          centered
            // onOk={}
          >
            <div>
              <Form
                ref={this.formRef}
                layout="vertical"
                name="companyFrm"
                onFinish={this.onWASLVehicleRegistrationFinish}
                // initialValues={{ mapSetting: false, mapSetting2: false }}
                style={{ maxWidth: "100%", }}
                className="formClass"
              >
                <Row >
                  <Col span={12} style={{padding:"10px"}}>
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => {
                          this.handleChange(value);
                        }}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>

                    <Form.Item
                    name="cidentityNo"
                    label={this.context.translate("_COMPANY_IDENTITY_NUMBER_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      // placeholder="Select Company Identity No"
                      placeholder={this.context.translate("_SELECT_COMPANY_IDENTITY_NUMBER_")}
                      name="cidentityNo"
                      //onChange={(e) => this.setFieldsValues(e)}
                      // onSearch={this.handleSearch}
                      showSearch
                      fieldNames={{ value: "identityNo", label: "identityNo" }}
                      optionFilterProp="children"
                      options={this.waslVehicleStore?.CompanyIdentityNumbers}
                    />
                  </Form.Item>

                    <Form.Item
                      name="vehicleId"
                      // label="Asset No"
                      label={this.context.translate("_FLEET_VEHICLE_VEHICLELIST_VEHICLENO_")}
                      rules={[{ required: true }]}
                    >
                      <Select
                        name="vehicleId"
                        onChange={async (e) => await this.setFieldsValues(e)}
                        fieldNames={{
                          label: "vehicleNo",
                          value: "vehicleId",
                        }}
                        placeholder={this.context.translate("_ASSET_NAME_")}
                        // placeholder="Asset Name"
                        optionFilterProp="children"
                        options={
                          this.waslVehicleStore?.getVehiclesUsingCompanyId
                        }
                        style={{ width: "100%" }}
                      />
                    </Form.Item>

                    <Form.Item
                      name="sequenceNumber"
                      label={this.context.translate("_SEQUENCE_NUMBER_")}
                      rules={[{ required: true }]}
                    >
                      <Input
                        onChange={(e) =>
                          this.validateNumber(e.target.value, "sequenceNumber")
                        }
                      />
                    </Form.Item>

                    
                  </Col>
                  {/* <Col span={2}></Col> */}
                  <Col span={12} style={{padding:"10px"}}>

                  <Form.Item
                      name="imeiNumber"
                      label={this.context.translate("_IMEI_NUMBER_")}
                      rules={[{ required: true }]}
                    >
                      <Input
                        onChange={(e) =>
                          this.validateNumber(e.target.value, "imeiNumber")
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      name="number"
                      label={this.context.translate("_PLATE_REGISTER_NUMBER_")}
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="plateType"
                      label={this.context.translate("_PLATE_TYPE_")}
                      // label="Plate Type"
                      rules={[{ required: true }]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        // placeholder="Select Type"
                        placeholder={this.context.translate("_SELECT_TYPE_")}
                      >
                        <Select.Option value="1">{this.context.translate("__PRIVATE_CAR_")}</Select.Option>
                        <Select.Option value="2">
                        {this.context.translate("_PUBLIC_TRANSPORT_")}
                        </Select.Option>
                        <Select.Option value="3">
                        {this.context.translate("_PRIVATE_TRANSPORT_")}
                        </Select.Option>
                        <Select.Option value="4">   {this.context.translate("_PUBLIC_MINIBUS_")}</Select.Option>
                        <Select.Option value="5">{this.context.translate("_PRIVATE_MINIBUS_")}</Select.Option>
                        <Select.Option value="6">{this.context.translate("_TAXI_")}</Select.Option>
                        <Select.Option value="7">{this.context.translate("_HEAVY_EQUIPMENT_")}</Select.Option>
                        <Select.Option value="8">{this.context.translate("_EXPORT_")}</Select.Option>
                        <Select.Option value="9">{this.context.translate("_DIPLOMATIC_")}</Select.Option>
                        <Select.Option value="10">{this.context.translate("_MOTORCYCLE_")}</Select.Option>
                        <Select.Option value="11">{this.context.translate("_TEMPORARY_")}</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="activity"
                      label={this.context.translate("_ACTIVITY_")}
                      initialValue={"DEFAULT"}
                      rules={[{ required: true }]}
                    >
                      <Select autoClearSearchValue>
                        <Select.Option value="DEFAULT"> {this.context.translate("_DEFAULT_")}</Select.Option>
                        <Select.Option value="TOW_CAR">{this.context.translate("_TOW_CAR_")}</Select.Option>
                        <Select.Option value="SPECIALITY_TRANSPORT">
                        {this.context.translate("_SPECIALITY_TRANSPORT_")}
                        </Select.Option>
                        <Select.Option value="BUS_RENTAL">
                        {this.context.translate("_BUS_RENTAL_")}
                        </Select.Option>
                        <Select.Option value="EDUCATIONAL_TRANSPORT">
                        {this.context.translate("_EDUCATIONAL_TRANSPORT_")}
                        </Select.Option>
                        <Select.Option value="SFDA">{this.context.translate("_SFDA_")}</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <div className="formBtnGroup">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="formSaveButton"
                    // onClick={(val) => console.log(val)}
                  >
                    {this.context.translate("_REGISTER_")}
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}
WASLVehicleList.contextType = StoreContext;
export default observer(withRouter(WASLVehicleList));
